import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withStyles from "@mui/styles/withStyles";
import actions from "../../actions";
import styles from "../../theme/globalStyles";
import Clinicians from "./Clinicians";
import {
  getCliniciansList,
  getSignedinState,
  getCliniciansPage,
  getCliniciansPerPage,
  getCliniciansCount,
  getClinicianSaving,
  getCliniciansSort,
  getCliniciansSortDirection,
  getCliniciansStatus,
  getUserPermissionsList,
  getCliniciansLoading,
} from "../../selectors";
import PageContainer from "elements/PageContainer";
import SectionContainer from "elements/SectionContainer";

const mapStateToProps = (state) => ({
  clinicians: getCliniciansList(state),
  page: getCliniciansPage(state),
  rowsPerPage: getCliniciansPerPage(state),
  count: getCliniciansCount(state),
  signedInState: getSignedinState(state),
  saving: getClinicianSaving(state),
  sort: getCliniciansSort(state),
  direction: getCliniciansSortDirection(state),
  status: getCliniciansStatus(state),
  userPermissions: getUserPermissionsList(state),
  loading: getCliniciansLoading(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadCliniciansList: actions.loadCliniciansList,
      setCliniciansList: actions.setCliniciansList,
      checkAuthState: actions.checkAuthState,
      setCliniciansPage: actions.setCliniciansPage,
      setCliniciansPerPage: actions.setCliniciansPerPage,
      setPageDetails: actions.setPageDetails,
      createClinician: actions.createClinician,
      setCliniciansSort: actions.setCliniciansSort,
      setCliniciansStatus: actions.setCliniciansStatus,
    },
    dispatch
  );

class CliniciansContainer extends Component {
  state = {
    page: 0,
    rowsPerPage: 5,
    open: false,
  };

  componentDidMount() {
    this.props.setPageDetails({
      pageName: "Clinicians",
      currentPage: "clinicians",
      menu: "entities",
    });
    this.props.loadCliniciansList();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.status !== this.props.status ||
      prevProps.page !== this.props.page ||
      prevProps.sort !== this.props.sort ||
      prevProps.direction !== this.props.direction ||
      prevProps.rowsPerPage !== this.props.rowsPerPage
    ) {
      this.props.loadCliniciansList();
    }
  }

  handleChangePage = (event, page) => {
    this.props.setCliniciansPage(page);
  };

  handleChangeRowsPerPage = (event) => {
    this.props.setCliniciansPage(0);
    this.props.setCliniciansPerPage(parseInt(event.target.value));
  };

  handleSortClick = (name) => {
    this.props.setCliniciansSort({
      sort: name,
    });
  };

  viewClinicianDetails = (id) => {
    let clinician = `/clinicians/${id}/profile`;
    this.props.history.push(clinician);
  };

  getStatus = (status) => {
    switch (status) {
      case "INVITED":
        return "Invited";
      case "APPLICATION_IN_PROGRESS":
        return "Completing Documentation";
      case "REQUEST_CHANGES":
        return "Request Changes";
      case "SUBMITTED":
        return "Submitted";
      case "CAQH":
        return "Updating CAQH Account";
      case "CREDENTIALING":
        return "Credentialing";
      case "ASSIGNABLE_PRIVATE_PAY":
        return "Credentialing";
      case "CREDENTIALED":
        return "Credentialed";
      default:
        return status;
    }
  };

  render() {
    const { loading } = this.props;

    return (
      <PageContainer loading={loading}>
        <SectionContainer columnDirection noPadding>
          <Clinicians
            {...this.props}
            {...this.state}
            viewClinicianDetails={this.viewClinicianDetails}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            rowsPerPage={this.props.rowsPerPage}
            page={this.props.page}
            count={this.props.count}
            handleSortClick={this.handleSortClick}
            getStatus={this.getStatus}
          />
        </SectionContainer>
      </PageContainer>
    );
  }
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(CliniciansContainer)
);
