import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import styles from "./styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import {
  sendingVideoSignatures,
  sendVideoSignaturesSuccess,
  getParentPendingSignatures,
  isCaregiver,
} from "../../selectors";
import * as AWS from "aws-sdk";
import { Buffer } from "buffer";
import actions from "../../actions";
import { getAWSCredentialsForCurrentUserSession } from "utils/aws";
import ClientSignaturesDialog from "../VideoChat/Dialogs/ClientSignaturesDialog";

const mapStateToProps = (state) => ({
  sendingVideoSignatures: sendingVideoSignatures(state),
  sendVideoSignaturesSuccess: sendVideoSignaturesSuccess(state),
  parentPendingSignatures: getParentPendingSignatures(state),
  isCaregiver: isCaregiver(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      sendParentVideoSignatures: actions.sendParentVideoSignatures,
      getUser: actions.getParentInfo,
    },
    dispatch
  );

class ForceSignatures extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sigError: false,
      videoId: null,
      loading: false,
    };
    this.sigRefs = {};
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sendingVideoSignatures && !this.props.sendingVideoSignatures) {
      if (!this.props.sendVideoSignaturesSuccess) {
        this.setState({ sigError: true, videoId: null, loading: false });
      } else {
        this.sigRefs = {};
        this.setState({ videoId: null });
      }
    }
    if (prevProps.parentPendingSignatures.length !== this.props.parentPendingSignatures.length) {
      this.setState({ loading: false });
    }
  }

  saveSignature = async (imageData, sigTime, videoId) => {
    const credentials = await getAWSCredentialsForCurrentUserSession();
    const s3 = new AWS.S3({
      credentials,
      region: "us-east-1",
      signatureVersion: "v2",
      apiVersion: "2006-03-01",
      params: { Bucket: process.env.AWS_DOC_BUCKET },
    });
    const buf = new Buffer(imageData.replace(/^data:image\/\w+;base64,/, ""), "base64");
    const params = {
      ACL: "public-read",
      Body: buf,
      ContentEncoding: "base64",
      ContentType: "image/png",
      Key: `${videoId}_signature_client_${sigTime}.png`,
    };
    try {
      const s3url = await s3.upload(params).promise();
      return s3url.Location;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  setSigURLSFromCanvas = async (videoId) => {
    try {
      this.setState({ videoId, loading: true, sigError: false });
      const startSigURL = this.sigRefs[`start${videoId}`].getTrimmedCanvas().toDataURL("image/png");
      let startTimeURL = await this.saveSignature(startSigURL, "start", videoId);
      this.props.sendParentVideoSignatures({
        startSignatureURL: startTimeURL,
        videoId: videoId,
      });
      this.sigRefs[`start${videoId}`].clear();
    } catch (error) {
      this.setState({ sigError: true, loading: false });
    }
  };

  mapBillingTypeToDisplay = (billingType) => {
    switch (billingType) {
      case "ASSESSMENT":
        return "Assessment";
      case "ORIENTATION":
        return "Orientation";
      case "DIRECT_THERAPY":
        return "Direct Therapy";
      case "CAREGIVER_TRAINING":
        return "Caregiver Training";
      default:
        return "";
    }
  };

  render() {
    const { classes, parentPendingSignatures } = this.props;

    const videoCall = parentPendingSignatures[0];
    return (
      <div className={classes.container}>
        <div className={classes.innerContainer}>
          <ClientSignaturesDialog
            open={true}
            sigPadStart={(ref) => (this.sigRefs[`start${videoCall.id}`] = ref)}
            serviceType={this.mapBillingTypeToDisplay(videoCall.billing_type)}
            oneTimeVideoInfo={videoCall}
            startDate={videoCall.start_date}
            endDate={videoCall.end_date}
            primaryClinicianFirstName={videoCall.clinician_first_name}
            onSubmitSignatures={() => this.setSigURLSFromCanvas(videoCall.id)}
            {...this.state}
            {...this.props}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ForceSignatures))
);
