import React from "react";
import { Box, Grid, Typography } from "@mui/material";

import getCognitoAuth from "hooks/api/user/useGetCognitoSession";
import useInitUserChat from "hooks/api/user/useInitUserChat";
import { useGetTeams } from "hooks/api/useGetTeams";
import { useUpdateTeamUser } from "hooks/api/useUpdateTeamUser";
import ANLogoSrc from "assets/an_purple_gray.png";
import ANLoadingScreen from "elements/ANLoadingScreen";
import NewTeamMemberForm from "./NewTeamMemberForm";
import { ClientCookie } from "../utils";

const cookieClientId = new ClientCookie();

function UpdateMemberInfo({ email, userId, clientId }) {
  let { session, sessionLoading } = getCognitoAuth();

  // Avoid calling the getTeams if there is no session
  const teamQuery = session ? { userId } : null;
  let { teams, teamsLoading, isSuccess } = useGetTeams(teamQuery);

  const teamName = isSuccess ? teams[0].name : "Client's team";

  // Mutations
  const { updateTeamUserMutation } = useUpdateTeamUser({
    onSuccess: () => {
      // After updating the user info, initialize the chat
      initUserChatMutation({ userId, clientId });
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const { initUserChatMutation } = useInitUserChat({
    onSuccess: () => {
      location.href = "/";
    },
    onError: (error) => {
      console.error(error);
    },
  });

  // Save the client ID to a cookie
  if (!cookieClientId.get() && session) {
    cookieClientId.set({
      clientId,
      userId,
    });
    cookieClientId.save();
  }

  const handleSubmit = async (values) => {
    const updatedValues = {
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values.phoneNumber,
      ...values.address,
    };

    return await updateTeamUserMutation({ userId, data: updatedValues });
  };

  if (teamsLoading || sessionLoading) {
    return <ANLoadingScreen />;
  }

  return (
    <Grid container justifyContent="center">
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        sx={{ width: "320px", maxWidth: "320px", height: "100vh", marginTop: "60px" }}
      >
        <Grid item xs={12}>
          <Box component="header" textAlign="center">
            <img src={ANLogoSrc} alt="logo purple and gray" width={198} height={48} />

            <Typography variant="h2" component="h2" sx={{ marginTop: 7 }}>
              Tell us about yourself
            </Typography>

            <Typography variant="body1" sx={{ marginTop: 3 }}>
              Let&apos;s start with just a few things before connecting you with {teamName}.
            </Typography>
          </Box>

          <Box component="section" sx={{ marginTop: 7 }}>
            <NewTeamMemberForm email={email} onSubmit={handleSubmit} />
          </Box>
        </Grid>

        <Grid item xs={12} component="footer" textAlign="center" sx={{ marginTop: 7 }}>
          <Typography component="p" variant="body2">
            Have questions? Reach us directly at
          </Typography>
          <Typography component="span" variant="body2" color="primary">
            804-215-5600
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default UpdateMemberInfo;
