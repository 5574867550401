import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../../actions";
import { getVideoCallsReport, getReportsLoading, getUserPermissionsList } from "../../selectors";
import withStyles from "@mui/styles/withStyles";
import styles from "../../theme/globalStyles";
import VideoCalls from "./VideoCalls";
import { format } from "date-fns";
import Auth from "@aws-amplify/auth";
import PageContainer from "elements/PageContainer";
import SectionContainer from "elements/SectionContainer";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { convertTokens } from "@date-fns/upgrade/v2";
import ANDatePicker from "elements/ANDatePicker";

const mapStateToProps = (state) => ({
  videoCalls: getVideoCallsReport(state),
  videoCallsLoading: getReportsLoading(state),
  userPermissions: getUserPermissionsList(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getVideoCallSummary: actions.getVideoCallSummary,
    },
    dispatch,
  );

class VideoCallSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
    };
  }
  componentDidMount() {
    this.props.getVideoCallSummary({});
  }

  downloadVideoCall = async (videoCallId) => {
    const time = new Date();
    const offset = time.getTimezoneOffset();
    const baseUrl = process.env.BASE_URL;
    const userSession = await Auth.currentSession();
    location.href = `${baseUrl}/download/${userSession.accessToken.jwtToken}/video-call/${videoCallId}?offset=${offset}`;
  };

  onStartDateChange = (date, name) => {
    this.setState({ startDate: date });
  };

  onEndDateChange = (date, name) => {
    this.setState({ endDate: date });
  };

  runVideoCallsReport = () => {
    const { startDate, endDate } = this.state;
    this.props.getVideoCallSummary({
      start: format(new Date(startDate), convertTokens("YYYY-MM-DD")),
      end: format(new Date(endDate), convertTokens("YYYY-MM-DD")),
    });
  };

  render() {
    const { classes, videoCallsLoading, ...otherProps } = this.props;
    const { startDate, endDate } = this.state;
    return (
      <PageContainer loading={videoCallsLoading}>
        <SectionContainer noPadding columnDirection>
          <div className={classes.header}>
            <div />
            <Typography component="h1" variant="h5">
              Video Calls Summary
            </Typography>
            <div />
          </div>
          <div className={classes.inputs} style={{ paddingLeft: 10 }}>
            <ANDatePicker
              value={new Date(startDate) || null}
              label="Start Date"
              onChange={(date) => this.onStartDateChange(date)}
              style={{ marginRight: 15 }}
            />
            <ANDatePicker
              value={new Date(endDate) || null}
              label="End Date"
              onChange={(date) => this.onEndDateChange(date)}
              style={{ marginRight: 15 }}
            />

            <Button
              onClick={this.runVideoCallsReport}
              disabled={!startDate || !endDate}
              style={{ minWidth: 130 }}
            >
              Run Report
            </Button>
          </div>
          <VideoCalls
            {...otherProps}
            {...this.state}
            onStartDateChange={this.onStartDateChange}
            onEndDateChange={this.onEndDateChange}
            runVideoCallsReport={this.runVideoCallsReport}
            downloadVideoCall={this.downloadVideoCall}
          />
        </SectionContainer>
      </PageContainer>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(VideoCallSummary));
