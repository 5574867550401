import React from "react";
import Modal from "elements/Modal";

const MarkAssessmentCompleteDialog = (props) => {
  const { open, onToggleAssessmentComplete, onSubmitMarkAssessmentComplete, isClinician } = props;
  return (
    <Modal
      open={open}
      onClose={onToggleAssessmentComplete}
      title="Mark Assessment Complete"
      titleCentered
      description={
        isClinician
          ? "Please confirm that you have all the information you need to complete a treatment plan"
          : "Please confirm that the clinician has enough information to complete a treatment plan"
      }
      primaryActionText="Confirm"
      primaryActionOnClick={onSubmitMarkAssessmentComplete}
      secondaryActionText="Cancel"
      secondaryActionOnClick={onToggleAssessmentComplete}
    />
  );
};

export default MarkAssessmentCompleteDialog;
