import React, { useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import Drawer from "@mui/material/Drawer";

import { useGetClientAssessments } from "hooks/api/useGetClientAssessements";
import ANLoadingScreen from "elements/ANLoadingScreen";
import { assessmentCard, description, previousPaper, space5 } from "./styled";
import VinelandBanner from "./VinelandBanner";
import LinesGraph from "./LinesGraph";
import PreviousPanel from "./PreviousPanel";
import EmptyAssessments from "./EmptyAssessments";
import AverageScore from "./AverageScore";

const panelWidth = 512;

/**
 * The Assessments component renders client assessment records with a side panel for additional details.
 * @component
 * @param {Object} props - The props component.
 * @param {Object} props.basicInfo - The basic information of the client
 * @returns {JSX.Element} - The `Assessments` component
 */
const Assessments = ({ basicInfo }) => {
  const { details } = basicInfo;
  const [openSidepanel, setOpenSidepanel] = useState(false);
  const { clientAssessments, assessmentsLoading } = useGetClientAssessments(details.client_id);

  const handleOpenSidepanel = () => {
    setOpenSidepanel(true);
  };

  const handleCloseSidepanel = () => {
    setOpenSidepanel(false);
  };

  if (assessmentsLoading) {
    return <ANLoadingScreen />;
  }

  if (!clientAssessments.length) {
    return (
      <Stack component="section" position="relative" direction="row" useFlexGap overflow="hidden">
        <EmptyAssessments />
      </Stack>
    );
  }

  return (
    <Stack component="section" position="relative" direction="row" useFlexGap overflow="hidden">
      <Box
        sx={{
          width: openSidepanel ? `calc(100% - ${panelWidth}px)` : "100%",
          flexGrow: 4,
          p: space5,
        }}
      >
        <Typography variant="body2" sx={description}>
          Manage clinical and self-reported assessment records
        </Typography>

        <Box sx={assessmentCard}>
          <VinelandBanner onClickChartButton={handleOpenSidepanel} />

          <AverageScore dataAssessments={clientAssessments} />

          <LinesGraph dataAssessments={clientAssessments.toReversed()} />
        </Box>
      </Box>

      <Drawer
        anchor="right"
        variant="persistent"
        open={openSidepanel}
        sx={{
          flexGrow: openSidepanel ? 1 : 0,
          flexBasis: openSidepanel ? panelWidth : 0,
          width: openSidepanel ? panelWidth : 0,
          "& .MuiDrawer-paper": previousPaper,
          height: "100vh",
        }}
      >
        <PreviousPanel onClosePanel={handleCloseSidepanel} dataAssessments={clientAssessments} />
      </Drawer>
    </Stack>
  );
};

export default Assessments;
