import { checkFeatureAccess } from "api/featureAccess";
import { useQuery } from "react-query";

/**
 * Custom hook to get the value of a feature flag for a specific user.
 *
 * @param {string} userId - The ID of the user.
 * @param {string} featureFlagName - The name of the feature flag.
 * @returns {boolean} - The value of the feature flag, false by default.
 */
export const useGetFeatureFlag = (userId, featureFlagName) => {
  return useQuery(
    ["featureFlag", userId, featureFlagName],
    () => checkFeatureAccess(userId, featureFlagName),
    {
      enabled: !!userId,
    },
  );
};
