import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const SessionNotesNotFoundDialog = (props) => {
  const { classes, open, closeDialog } = props;
  return (
    <Dialog open={open}>
      <DialogContent className={classes.noSessionNotesDialogContent}>
        <Typography component="h5" className={classes.noSessionNotesDialogTitle}>
          No Session Notes Exist
        </Typography>
        <Typography component="p" className={classes.noSessionNotesDialogContentText}>
          This session does not have any session notes associated with it.
          <br /> Please contact the clinical department for more information.
        </Typography>
      </DialogContent>
      <DialogActions className={classes.noSessionNotesDialogActionsContainer}>
        <Button onClick={closeDialog}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
export default SessionNotesNotFoundDialog;
