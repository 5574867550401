import React from "react";
import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import { format } from "date-fns";
import DetailSection from "elements/DetailSection";
import LoadingButton from "@mui/lab/LoadingButton";

import { convertTokens } from "@date-fns/upgrade/v2";
import { useSelector } from "react-redux";
import { useGetFeatureFlag } from "hooks/useGetFeatureFlag";
import * as selectors from "selectors";
import { useHistory } from "react-router-dom";

const StatusControl = (props) => {
  const {
    classes,
    customerDetails,
    userPermissions,
    workflowStatus,
    verified,
    insuranceProviders,
    onToggleScheduleClientVideo,
    onToggleAssignClinician,
    onToggleTreatmentPlanDialog,
    onToggleAssessmentComplete,
    onResendLoginEmail,
    submitEligibility,
    resendLoginSuccess,
    history,
    onOpenUpdateTreatmentPlan,
    submitApproval,
    isActive,
    currentUser,
    isDirector,
    videoCallsLoading,
    markingEligible,
    treatmentPlanSuccess,
    treatmentPlanUploading,
    userId,
    isSavedForLater,
  } = props;
  const { details, clinician, upcomingVideoCalls } = customerDetails;
  let isPrimaryClinician =
    currentUser?.id == customerDetails?.clinician?.clinician_user_id ? true : false;

  const mapStatusToDisplay = () => {
    if (isActive === false && !isSavedForLater) {
      return {
        title: "Deactivated Customer",
        subtitle: " ",
        date: `${
          details.deactivation && details.deactivation.created
            ? format(new Date(details.deactivation.created), convertTokens("MMM DD, h:mm a"))
            : "not indicated"
        }`,
        reason: `${
          details.deactivation && details.deactivation.reason
            ? details.deactivation.reason
            : "not indicated"
        } `,
        // note: `${
        //     details.deactivation && details.deactivation.note
        //         ? details.deactivation.note
        //         : "not indicated"
        // }`,
        buttons: [],
      };
    } else if (isActive === false && isSavedForLater) {
      return {
        title: "Awaiting Client Intake / Submitted Contact Request",
        subtitle: " ",
        buttons: [<CompleteClientIntake userId={userId} history={history} />],
      };
    } else {
      switch (workflowStatus) {
        case "AWAITING_ONBOARDING":
          return {
            attentionText: "A TASK IS WAITING FOR YOU",
            attentionTextColor: "#d97706",
            title: "Conduct Orientation Call",
            subtitle: "Please schedule a call with this client to walk them through the product.",
            hasTaskPermission:
              userPermissions?.mark_assessment_complete ||
              userPermissions?.move_client_through_workflow,
          };
        case "AWAITING_ELIGIBILITY":
          return {
            title: "Awaiting Insurance Assessment Approval",
            subtitle:
              "This client has completed their orientation call and we are waiting to hear back from their insurance company for approval.",
            buttons: [
              userPermissions?.move_client_through_workflow && (
                <MarkEligibileButton
                  key={0}
                  submitEligibility={submitEligibility}
                  classes={classes}
                  markingEligible={markingEligible}
                />
              ),
            ],
            hasTaskPermission: userPermissions?.move_client_through_workflow,
          };
        case "ELIGIBLE":
          return clinician
            ? {
                attentionText: "A TASK IS WAITING FOR YOU",
                attentionTextColor: "#d97706",
                title: "Schedule Assessment",
                subtitle:
                  "This client is approved for assessment with AnswersNow and has been assigned to a clinician. Click below to schedule an assessment call.",
                buttons: [
                  userPermissions?.schedule_call && (
                    <ScheduleCallButton
                      key={1}
                      onToggleScheduleClientVideo={onToggleScheduleClientVideo}
                      classes={classes}
                    />
                  ),
                ],
                hasTaskPermission: userPermissions?.schedule_call,
              }
            : {
                attentionText: "A TASK IS WAITING FOR YOU",
                attentionTextColor: "#d97706",
                title: "Assign Clinician",
                subtitle:
                  "This client is approved for assessment with AnswersNow. Next, let's assign their clinician",
                buttons: [
                  userPermissions?.assign_clinician && (
                    <AssignClinicianButton
                      key={2}
                      onToggleAssignClinician={onToggleAssignClinician}
                      classes={classes}
                    />
                  ),
                ],
                hasTaskPermission: userPermissions?.assign_clinician,
              };
        case "ELIGIBLE_NOW":
          return clinician
            ? {
                attentionText: "A TASK IS WAITING FOR YOU",
                attentionTextColor: "#d97706",
                title: "Schedule Assessment",
                subtitle:
                  "This client is approved for assessment with AnswersNow and has been assigned to a clinician. Click below to schedule an assessment call.",
                buttons: [
                  userPermissions?.schedule_call && (
                    <ScheduleCallButton
                      key={3}
                      onToggleScheduleClientVideo={onToggleScheduleClientVideo}
                    />
                  ),
                ],
                hasTaskPermission: userPermissions?.schedule_call,
              }
            : {
                attentionText: "A TASK IS WAITING FOR YOU",
                attentionTextColor: "#d97706",
                title: "Assign Clinician",
                subtitle:
                  "This client is approved for assessment with AnswersNow. Next, let's assign their clinician",
                buttons: [
                  userPermissions?.assign_clinician && (
                    <AssignClinicianButton
                      key={4}
                      onToggleAssignClinician={onToggleAssignClinician}
                      classes={classes}
                    />
                  ),
                ],
                hasTaskPermission: userPermissions?.assign_clinician,
              };
        case "ASSESSMENT_SCHEDULED":
          return upcomingVideoCalls?.length > 0 && upcomingVideoCalls[0].scheduled_date
            ? {
                title: "Assessment Scheduled",
                subtitle: (
                  <>
                    The client has an upcoming call on{" "}
                    <span style={{ fontWeight: 500 }}>
                      {format(
                        new Date(upcomingVideoCalls[0].scheduled_date),
                        convertTokens("MMMM DD, YYYY h:mm a")
                      )}
                    </span>
                  </>
                ),
                buttons: [
                  userPermissions?.mark_assessment_complete && (
                    <MarkAssessmentCompleteButton
                      key={6}
                      onToggleAssessmentComplete={onToggleAssessmentComplete}
                      userPermissions={userPermissions}
                    />
                  ),
                ],
                hasTaskPermission: userPermissions?.mark_assessment_complete,
              }
            : {
                attentionText: "A TASK IS WAITING FOR YOU",
                attentionTextColor: "#d97706",
                title: "No Assessment Scheduled",
                subtitle:
                  "This client currently has no assessment scheduled. Please schedule a call with this client.",
                buttons: [
                  userPermissions?.schedule_call && (
                    <ScheduleCallButton
                      key={7}
                      onToggleScheduleClientVideo={onToggleScheduleClientVideo}
                    />
                  ),
                  userPermissions?.mark_assessment_complete && (
                    <MarkAssessmentCompleteButton
                      key={8}
                      onToggleAssessmentComplete={onToggleAssessmentComplete}
                      userPermissions={userPermissions}
                    />
                  ),
                ],
                hasTaskPermission:
                  userPermissions?.schedule_call || userPermissions?.mark_assessment_complete,
              };
        case "ASSESSMENT_SCHEDULED_NOW":
          return upcomingVideoCalls?.length > 0 && upcomingVideoCalls[0].scheduled_date
            ? {
                title: "Assessment Scheduled",
                subtitle: (
                  <>
                    The client has an upcoming call on{" "}
                    <span style={{ fontWeight: 500 }}>
                      {format(
                        new Date(upcomingVideoCalls[0].scheduled_date),
                        convertTokens("MMMM DD, YYYY h:mm a")
                      )}
                    </span>
                  </>
                ),
                buttons: [
                  userPermissions?.mark_assessment_complete && (
                    <MarkAssessmentCompleteButton
                      key={10}
                      onToggleAssessmentComplete={onToggleAssessmentComplete}
                      userPermissions={userPermissions}
                    />
                  ),
                ],
                hasTaskPermission: userPermissions?.mark_assessment_complete,
              }
            : {
                attentionText: "A TASK IS WAITING FOR YOU",
                attentionTextColor: "#d97706",
                title: "No Assessment Scheduled",
                subtitle:
                  "This client currently has no Assessment scheduled. Please schedule a call for this client.",
                buttons: [
                  userPermissions?.schedule_call && (
                    <ScheduleCallButton
                      key={11}
                      onToggleScheduleClientVideo={onToggleScheduleClientVideo}
                      classes={classes}
                    />
                  ),
                  userPermissions?.mark_assessment_complete && (
                    <MarkAssessmentCompleteButton
                      key={12}
                      onToggleAssessmentComplete={onToggleAssessmentComplete}
                      userPermissions={userPermissions}
                    />
                  ),
                ],
                hasTaskPermission:
                  userPermissions?.schedule_call || userPermissions?.mark_assessment_complete,
              };
        case "ASSESSMENT_COMPLETED":
          return {
            attentionText: isPrimaryClinician ? "A TASK IS WAITING FOR YOU" : null,
            attentionTextColor: "#d97706",
            title: treatmentPlanSuccess
              ? "Treatment Plan Uploaded Successfully!"
              : isPrimaryClinician
                ? "Upload Treatment Plan"
                : "Awaiting Treatment Plan",
            subtitle:
              isPrimaryClinician || isDirector
                ? "Now that you have marked the assessment complete, create and submit the treatment plan for the client's review."
                : "Clinician is working on the treatment plan to be submitted for the client's review.",
            buttons: [
              userPermissions?.upload_treatment_plan && (isPrimaryClinician || isDirector) && (
                <UploadTreatmentPlanButton
                  key={13}
                  onToggleTreatmentPlanDialog={onToggleTreatmentPlanDialog}
                  classes={classes}
                  loading={treatmentPlanUploading}
                />
              ),
            ],
            hasTaskPermission:
              userPermissions?.upload_treatment_plan && (isPrimaryClinician || isDirector),
          };
        case "APPROVE_PLAN":
          return {
            title: treatmentPlanSuccess
              ? "Treatment Plan Updated Successfully!"
              : "Client needs to approve the plan",
            subtitle:
              "You've uploaded the treatment plan and now we're waiting for the client to approve it before continuing. You can update the plan if needed at this time.",
            buttons: [
              userPermissions?.upload_treatment_plan && (isPrimaryClinician || isDirector) && (
                <UpdateTreatmentPlanButton
                  key={14}
                  onOpenUpdateTreatmentPlan={onOpenUpdateTreatmentPlan}
                  customerDetails={customerDetails}
                  classes={classes}
                  loading={treatmentPlanUploading}
                />
              ),
            ],
            hasTaskPermission:
              userPermissions?.upload_treatment_plan && (isPrimaryClinician || isDirector),
          };
        case "AWAITING_INSURANCE_APPROVAL":
          let insuranceName = insuranceProviders.find(
            (provider) => provider.id == details.insurance_id,
          )
            ? insuranceProviders.find((provider) => provider.id == details.insurance_id).name
            : "provider";
          return {
            title: "Almost there! Treatment Plan Under Review by Insurance",
            subtitle: `We're waiting for ${
              insuranceName ? insuranceName : "insurance"
            } to approve the treatment plan`,
            buttons: [
              userPermissions?.move_client_through_workflow && (
                <MarkTreatmentPlanApprovedButton
                  key={15}
                  submitApproval={submitApproval}
                  classes={classes}
                />
              ),
            ],
            hasTaskPermission: userPermissions?.move_client_through_workflow,
          };
        case "INSURANCE_APPROVED":
          return {
            attentionText: "A TASK IS WAITING FOR YOU",
            attentionTextColor: "#d97706",
            title: "Schedule the Client's First Session!",
            subtitle:
              "The client's insurance has approved the treatment plan and we're ready to begin therapy.",
            buttons: [
              userPermissions?.schedule_call && (
                <ScheduleCallButton
                  key={16}
                  onToggleScheduleClientVideo={onToggleScheduleClientVideo}
                  classes={classes}
                />
              ),
            ],
            hasTaskPermission: userPermissions?.schedule_call,
          };
        case "FULL_CUSTOMER":
          return upcomingVideoCalls?.length > 0 &&
            upcomingVideoCalls[0].scheduled_date &&
            !videoCallsLoading
            ? {
                hasTaskPermission: true,
              }
            : videoCallsLoading
              ? { hasTaskPermission: true }
              : {
                  attentionText: "Schedule a call with your client",
                  attentionTextColor: "#d97706",
                  needsScheduling: true,
                  title: "Oops, the calendar is empty.",
                  subtitle:
                    "It looks like you don't have a call scheduled. It's ok if it changes later but let's put one on the calendar for now.",
                  buttons: [
                    userPermissions?.schedule_call && (
                      <ScheduleCallButton
                        key={18}
                        onToggleScheduleClientVideo={onToggleScheduleClientVideo}
                        classes={classes}
                      />
                    ),
                  ],
                  hasTaskPermission: true,
                };
      }
    }
  };

  const displayData = mapStatusToDisplay();
  return (
    <DetailSection noPadding columnDirection minHeight={40}>
      {displayData && (
        <>
          <Typography className={classes.attentionText}>
            {displayData?.hasTaskPermission &&
              (displayData.attentionText ? (
                <span style={{ color: displayData.attentionTextColor }}>
                  {displayData.attentionText}
                </span>
              ) : (
                <span style={{ color: "#047857" }}>You are all caught up!</span>
              ))}
          </Typography>
          {displayData.title ? (
            <Typography component="h1" className={classes.title}>
              {displayData.title}
            </Typography>
          ) : (
            <Typography component="h1" className={classes.title}>
              Watch for tasks in the future.
            </Typography>
          )}
          {displayData.subtitle ? (
            <Typography component="p" className={classes.subtitle}>
              {displayData.subtitle}
            </Typography>
          ) : (
            <Typography component="p" className={classes.subtitle}>
              Tasks that you need to complete will appear in this space so be sure to keep a look
              out.
            </Typography>
          )}
          {isActive === false && details.deactivation && (
            <>
              <Typography className={classes.subtitle}>
                Deactivation Date: {displayData.date}
              </Typography>
              <Typography className={classes.subtitle}>Reason: {displayData.reason}</Typography>
              {/* <Typography className={classes.subtitle}>
                                Note: {displayData.note}
                            </Typography> */}
            </>
          )}
          {(displayData.buttons ||
            (!verified && isActive && userPermissions?.send_reset_password)) && (
            <div className={classes.buttonContainer}>
              {displayData.buttons?.map((button) => button)}
              {!verified && isActive && userPermissions?.send_reset_password && (
                <ResendLoginButton
                  onResendLoginEmail={onResendLoginEmail}
                  classes={classes}
                  resendLoginSuccess={resendLoginSuccess}
                />
              )}
            </div>
          )}
        </>
      )}
    </DetailSection>
  );
};

const styles = (theme) => ({
  attentionText: {
    marginBottom: 25,
    fontSize: 20,
    textTransform: "none",
    fontWeight: 500,
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    marginBottom: 5,
    fontWeight: 500,
  },
  subtitle: {
    fontSize: 16,
    color: "#000",
    textAlign: "left",
    marginBottom: 10,
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    margin: "10px 0px",
    "& button": {
      padding: "8px 0",
    },
  },
  chatAndSessionButtonContainer: {
    position: "absolute",
    margin: "0 10px",
    top: 0,
    right: 50,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
});

export default withStyles(styles)(StatusControl);

var ResendLoginButton = (props) => {
  const { onResendLoginEmail, classes, resendLoginSuccess } = props;
  return (
    <>
      {resendLoginSuccess ? (
        <Typography
          component="p"
          style={{
            fontSize: 15,
            color: "#059669",
            marginLeft: 50,
            fontWeight: 500,
          }}
        >
          Sent Successfully
        </Typography>
      ) : (
        <Button
          color="secondary"
          onClick={onResendLoginEmail}
          size="large"
          startIcon={resendLoginSuccess ? <CheckIcon /> : null}
          style={{ marginLeft: 50, padding: 10 }}
        >
          Resend Login Email
        </Button>
      )}
    </>
  );
};

var MarkEligibileButton = (props) => {
  const { submitEligibility, classes, markingEligible } = props;
  return (
    <Button
      onClick={submitEligibility}
      size="large"
      disabled={markingEligible}
      style={{ width: 200 }}
    >
      Assessment Approved
    </Button>
  );
};

var AssignClinicianButton = (props) => {
  const { onToggleAssignClinician } = props;
  return (
    <Button variant="text" onClick={onToggleAssignClinician} size="large">
      Assign
    </Button>
  );
};

const ScheduleCallButton = (props) => {
  const { onToggleScheduleClientVideo } = props;
  const history = useHistory();
  const userId = useSelector(selectors.getUserId);
  const { data: schedulePageFeatureFlag } = useGetFeatureFlag(userId, "scheduling_page");
  return (
    <Button
      variant="text"
      onClick={() => {
        if (schedulePageFeatureFlag) history.push("/clinician-schedule/create-event");
        else onToggleScheduleClientVideo(false);
      }}
      size="large"
    >
      Schedule Call
    </Button>
  );
};

var UploadTreatmentPlanButton = (props) => {
  const { onToggleTreatmentPlanDialog, loading } = props;
  return (
    <LoadingButton
      variant="text"
      onClick={onToggleTreatmentPlanDialog}
      size="large"
      loading={loading}
    >
      Upload
    </LoadingButton>
  );
};

var UpdateTreatmentPlanButton = (props) => {
  const { customerDetails, onOpenUpdateTreatmentPlan, classes, loading } = props;
  return (
    <LoadingButton
      onClick={() =>
        onOpenUpdateTreatmentPlan(customerDetails.treatmentPlans.find((plan) => !plan.approved))
      }
      variant="text"
      className={classes.button}
      size="large"
      loading={loading}
    >
      Update Plan
    </LoadingButton>
  );
};

var MarkTreatmentPlanApprovedButton = (props) => {
  const { submitApproval, classes } = props;
  return (
    <Button className={classes.button} variant="text" onClick={submitApproval} size="large">
      Treatment Plan Approved
    </Button>
  );
};

var MarkAssessmentCompleteButton = (props) => {
  const { onToggleAssessmentComplete } = props;
  return (
    <Button
      variant="text"
      onClick={onToggleAssessmentComplete}
      size="large"
      style={{ marginLeft: 50 }}
    >
      Mark Assessment Complete
    </Button>
  );
};

var CompleteClientIntake = (props) => {
  const { history, userId } = props;
  return (
    <Button
      onClick={() => history.push(`/client-onboarding/${userId}`)}
      size="large"
      style={{ minWidth: 160 }}
    >
      Complete Intake
    </Button>
  );
};
