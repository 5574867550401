import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withStyles from "@mui/styles/withStyles";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { find } from "lodash";
import styles from "./styles";
import actions from "actions";
import {
  getSpecificChat,
  getChatMessages,
  getChatIsLoaded,
  getAllChatTags,
  getMessageTags,
  getMessageTagsLoading,
  isCaregiver,
  isAdmin,
  isSuperAdmin,
} from "selectors";
import { format, isSameDay } from "date-fns";
import { dateWithoutTimezone } from "../../utils/dateWithoutTimezone";
import PageContainer from "elements/PageContainer";
import SectionContainer from "elements/SectionContainer";
import { CustomSelect } from "elements/DEPRECATED_elements";
import MenuItem from "@mui/material/MenuItem";
import { convertTokens } from "@date-fns/upgrade/v2";
import TextField from "@mui/material/TextField";

const mapStateToProps = (state) => ({
  chat: getSpecificChat(state),
  messages: getChatMessages(state),
  loaded: getChatIsLoaded(state),
  tags: getAllChatTags(state),
  messageTags: getMessageTags(state),
  tagsLoading: getMessageTagsLoading(state),
  isCaregiver: isCaregiver(state),
  isAdmin: isAdmin(state),
  isSuperAdmin: isSuperAdmin(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadChatDetails: actions.loadChatDetails,
      setPageDetails: actions.setPageDetails,
      getAllChatTags: actions.getAllChatTags,
      getChatMessageTags: actions.getChatMessageTags,
      tagChatMessage: actions.tagChatMessage,
      deleteMessageTag: actions.deleteMessageTag,
    },
    dispatch,
  );

function setAction(actions) {
  window._popoverActions = actions;
}

function updatePosition() {
  window._popoverActions.updatePosition();
}

class ChatContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tagOpen: false,
      achorEl: null,
      tagSelect: null,
      messageId: null,
    };
    this.messageRefs = {};
  }
  componentDidMount() {
    this.props.setPageDetails({
      pageName: "Chats",
      currentPage: "chats",
      menu: "entities",
    });
    this.props.loadChatDetails(this.props.match.params.id);
    this.props.getAllChatTags();
    const params = new URLSearchParams(this.props.location.search);
    if (params.get("date") && this.props.messages?.length > 0) {
      setTimeout(() => {
        this.autoScroll(params.get("date"));
      }, 500);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.messageTags !== this.props.messageTags && this.state.tagOpen) {
      this.setState({ tagOpen: false }, () => {
        this.setState({ tagOpen: true });
      });
    }
    if (prevProps.messages?.length === 0 && this.props.messages?.length > 0) {
      const params = new URLSearchParams(this.props.location.search);
      if (params.get("date")) {
        setTimeout(() => {
          this.autoScroll(params.get("date"));
        }, 500);
      }
    }
  }

  navToSender = (message) => {
    if (message.sent_by_user_role === "ROLE_CLINICIAN") {
      this.props.history.push(`/clinicians/${message.sent_by_user_id}`);
    } else {
      this.props.history.push(`/clients/${message.client_id}/dashboard`);
    }
  };

  openTagMessage = (event, messageId) => {
    this.setState({
      anchorEl: event.currentTarget,
      tagOpen: true,
      messageId: messageId,
    });
    this.props.getChatMessageTags(messageId);
  };

  closeTagMessage = () => {
    this.setState({ anchorEl: null, tagOpen: false, messageId: null });
  };

  onChangeTag = (e) => {
    this.setState({ tagSelect: e.target.value });
  };

  submitTagSelection = () => {
    this.props.tagChatMessage(this.state.messageId, this.state.tagSelect);
    this.setState({ tagSelect: null });
  };

  deleteMessageTag = (id) => {
    this.props.deleteMessageTag(id, this.state.messageId);
  };

  goBack = () => {
    this.props.history.goBack();
  };

  autoScroll = (date) => {
    let scrollDate = Object.keys(this.messageRefs)?.find((ref) =>
      isSameDay(new Date(date), new Date(ref)),
    );

    this.messageRefs[scrollDate]?.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  };

  render() {
    const {
      classes,
      loaded,
      chat,
      messages,
      tags,
      messageTags,
      tagsLoading,
      isCaregiver,
      isAdmin,
      isSuperAdmin,
    } = this.props;
    const { tagOpen, anchorEl } = this.state;
    const tagsToAdd = tags.filter((tag) => {
      return !find(messageTags, { tag_id: `${tag.id}` });
    });
    const sendBirdUrl = `https://dashboard.sendbird.com/${process.env.SENDBIRD_APP_ID}/group_channels/${chat.conversation_url}`;
    return (
      <PageContainer loading={!loaded}>
        <SectionContainer columnDirection>
          <Button
            variant="text"
            onClick={this.goBack}
            className={classes.goBackButton}
            startIcon={
              <div className={classes.goBackIconContainer}>
                <ArrowBackOutlinedIcon style={{ fill: "#fff", fontSize: 15 }} />
              </div>
            }
          >
            Go Back
          </Button>
          {!isCaregiver && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography component="h1" variant="h5">
                  Chat ID: {chat.id}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid container justifyContent="center" spacing={1}>
            <Grid item xs={2}>
              <Typography component="div">
                <strong>Chat Participants</strong>
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography component="div">{chat.member ? chat.member.join(", ") : ""}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography component="div">
                <strong>Started On</strong>
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography component="div">
                {format(dateWithoutTimezone(chat.started_on), "PP", {
                  awareOfUnicodeTokens: true,
                })}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography component="div">
                <strong>Total # of Messages</strong>
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography component="div">{messages.length}</Typography>
            </Grid>
            {(isAdmin || isSuperAdmin) && chat.conversation_url && (
              <Grid item xs={12}>
                <Typography component="div">
                  <a href={sendBirdUrl} target="_blank">
                    View messages on SendBird
                  </a>
                </Typography>
              </Grid>
            )}
          </Grid>
          <div className={classes.messagesContainer}>
            {messages.map((message) => (
              <div
                key={message.id}
                className={
                  message.sent_by_user_role === "ROLE_PARENT"
                    ? classes.parentMessage
                    : classes.clinicianMessage
                }
                ref={(ref) => (this.messageRefs[message.sent_on] = ref)}
              >
                <div className={classes.messageBlock}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div onClick={() => this.navToSender(message)}>
                      <Typography component="div" className={classes.messageSender}>
                        {message.first_name}
                      </Typography>
                    </div>
                    {!isCaregiver && (
                      <div onClick={(e) => this.openTagMessage(e, message.id)}>
                        <Typography component="div" className={classes.messageSender}>
                          Tag Message
                        </Typography>
                      </div>
                    )}
                    <Popover
                      className={classes.tagContainer}
                      id="tag-message-popover"
                      open={tagOpen}
                      anchorEl={anchorEl}
                      action={setAction}
                      onClose={this.closeTagMessage}
                      elevation={1}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <div>
                        {tagsLoading ? (
                          <DialogContent className={classes.tagsLoading}>
                            <CircularProgress />
                          </DialogContent>
                        ) : (
                          <>
                            <DialogContent className={classes.dialogContent} key="dialog-content">
                              {messageTags.length > 0 ? (
                                messageTags.map((tag) => {
                                  return (
                                    <div className={classes.messageTag} key={`div-${tag.id}`}>
                                      <Chip
                                        key={tag.id}
                                        label={tag.tag_name}
                                        onDelete={() => this.deleteMessageTag(tag.id, message.id)}
                                        className={classes.chip}
                                      />
                                    </div>
                                  );
                                })
                              ) : (
                                <DialogContentText>Message has no tags</DialogContentText>
                              )}
                              {tagsToAdd.length > 0 && (
                                <TextField
                                  select
                                  id="tag-select"
                                  label="Tag Options"
                                  name="tagSelect"
                                  onChange={this.onChangeTag}
                                  required
                                  size="small"
                                >
                                  <MenuItem disabled value={""} />
                                  {tagsToAdd.map((tag) => {
                                    return (
                                      <MenuItem key={tag.id} value={tag.id}>
                                        {tag.tag_name}
                                      </MenuItem>
                                    );
                                  })}
                                </TextField>
                              )}
                            </DialogContent>
                            <DialogActions key="dialog-actions">
                              <Button onClick={this.closeTagMessage} color="primary">
                                Cancel
                              </Button>
                              <Button
                                onClick={() => this.submitTagSelection(message.id)}
                                color="primary"
                              >
                                Save
                              </Button>
                            </DialogActions>
                          </>
                        )}
                      </div>
                    </Popover>
                  </div>
                  <Typography
                    component="div"
                    className={
                      message.sent_by_user_role === "ROLE_PARENT"
                        ? classes.messageBlue
                        : classes.messageGray
                    }
                  >
                    {message.message}
                  </Typography>
                  <Typography component="div" className={classes.messageTime}>
                    {format(new Date(message.sent_on), convertTokens("MM/DD/YYYY h:mm a"))}
                  </Typography>
                </div>
              </div>
            ))}
          </div>
        </SectionContainer>
      </PageContainer>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ChatContainer));
