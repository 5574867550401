import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

const SessionFullDialog = (props) => {
  const { open, onToggleSessionFullDialog, onJoinFullSession } = props;
  return (
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      style={{ zIndex: 2010 }}
      PaperProps={{
        style: {
          maxWidth: "90%",
          width: 444,
        },
      }}
    >
      <DialogTitle
        style={{
          fontSize: 20,
          fontWeight: "500",
          color: "#212121",
          letterSpacing: "0.15px",
        }}
      >
        Your session is full
      </DialogTitle>
      <DialogContent style={{ padding: "0 24px" }}>
        <DialogContentText style={{ fontSize: 16, fontWeight: "400", color: "#212121" }}>
          Your session has reached the limit on the number of people attending. Joining the call
          will log out the other participant.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onToggleSessionFullDialog}
          variant="text"
          color="primary"
          style={{ height: 36, fontWeight: "500" }}
        >
          Cancel
        </Button>
        <Button
          onClick={onJoinFullSession}
          style={{ width: 140, height: 36, fontWeight: "500" }}
          color="primary"
        >
          Join Video Call
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SessionFullDialog;
