import startCase from "lodash/startCase";

/**
 * Converts a camelCase string to Title Case.
 *
 * @param {string} camelCase - The camelCase string to convert.
 * @returns {string} The converted string in Title Case.
 *
 * @example
 * // returns 'Camel Case String'
 * camelCaseToTitleCase('camelCaseString');
 */
const camelCaseToTitleCase = (camelCase) => startCase(camelCase);

export default camelCaseToTitleCase;
