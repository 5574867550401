import React from "react";
import { withStyles } from "@mui/styles";
import { TextField } from "@mui/material";

export const CustomInput = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#15171D",
    },
    "& fieldset": {
      border: "2px solid #c1c1c1",
      borderRadius: 4,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {},
      "&:hover fieldset": {
        border: "2px solid #4876A0",
      },
      "&.Mui-focused fieldset": {
        border: "2px solid #4876A0",
      },
    },
    "& .MuiFormHelperText-root": {
      margin: "0 5px",
      color: "red",
    },
  },
})((props) => (
  <TextField {...props} variant={props.variant || "outlined"} size={props.size || "small"} />
));
