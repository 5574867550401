import React from "react";
import ANDatePicker from "elements/ANDatePicker";
import { Controller, useFormContext } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import { FormHelperText } from "@mui/material";

const DEFAULT_FORMAT = "PP"; //"MMM d, yyyy"

export const GenericCalendarInput = ({
  name,
  label,
  format,
  required,
  helperText,
  disableFuture,
  disabled,
}) => {
  const { control, trigger, formState } = useFormContext();
  const { errors } = formState;

  const handleBlur = () => trigger(name);

  const handleFocus = () => {
    if (errors[name]) {
      trigger(name, { shouldFocus: false });
    }
  };

  const isDateValid = (dateString) => {
    return !required || !isNaN(new Date(dateString).getTime());
  };

  return (
    <FormControl fullWidth onBlur={handleBlur} onFocus={handleFocus}>
      <Controller
        control={control}
        name={name}
        rules={{ required, validate: isDateValid }}
        render={({ field: { onChange, value } }) => {
          return (
            <>
              <ANDatePicker
                value={value ?? null}
                label={label}
                onChange={onChange}
                format={format ?? DEFAULT_FORMAT}
                disableFuture={disableFuture}
                disabled={disabled}
                helperText={errors[name] ? helperText : null}
                slotProps={{
                  textField: {
                    error: !!errors[name],
                    sx: {
                      width: "100%",
                      svg: {
                        fontSize: "24px !important",
                        marginBottom: "0 !important",
                      },
                    },
                  },
                }}
              />
              {errors[name] && <FormHelperText error>{helperText}</FormHelperText>}
            </>
          );
        }}
      />
    </FormControl>
  );
};
