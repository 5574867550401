import React from "react";
import withStyles from "@mui/styles/withStyles";
import Person from "@mui/icons-material/Person";

const Avatar = (props) => {
  const { classes, imageUrl } = props;
  return (
    <div className={classes.container}>
      {imageUrl ? (
        <img src={imageUrl} className={classes.image} />
      ) : (
        <Person classes={{ root: classes.icon }} />
      )}
    </div>
  );
};

const styles = (theme) => ({
  container: {
    height: 40,
    width: 40,
    borderRadius: "100px",
    overflow: "hidden",
    border: "1px solid #979797",
    backgroundColor: "#e2e2e2",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },
  icon: {
    fontSize: "40px",
    fill: "#fff",
  },
});

export default withStyles(styles)(Avatar);
