import { getClients } from "api/customers";
import { GET_CLIENTS } from "constants/reactQueryKeys";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { queryParamsObjIsValid } from "utils/queryParamsObjIsValid";
import * as selectors from "selectors";
import { useHistory } from "react-router-dom";

export const useGetClients = (query) => {
  const isCaregiver = useSelector(selectors.isCaregiver);
  const history = useHistory();
  const { data, isLoading, ...rest } = useQuery([GET_CLIENTS, { query }], () => getClients(query), {
    enabled: queryParamsObjIsValid(query),
    onSuccess: ({ data: clients }) => {
      // If there are no clients for a caregiver, always redirect them to "No Active Clients" screen
      if (clients?.length === 0 && isCaregiver) history.push("/account-selection");
    },
  });
  return {
    clients: data?.data || null,
    clientsLoading: isLoading,
    ...rest,
  };
};
