import { useMutation } from "react-query";
import {
  cancelNonVideoEvent,
  rescheduleNonVideoEvent,
  scheduleNonVideoEvent,
} from "../../api/nonVideoEvent";

export const useNonVideoEventMutation = () => {
  const scheduleNonVideoEventMutation = useMutation({
    mutationFn: scheduleNonVideoEvent,
  });
  const rescheduleNonVideoEventMutation = useMutation({
    mutationFn: rescheduleNonVideoEvent,
  });
  const cancelNonVideoEventMutation = useMutation({
    mutationFn: cancelNonVideoEvent,
  });

  return {
    scheduleNonVideoEvent: scheduleNonVideoEventMutation,
    rescheduleNonVideoEvent: rescheduleNonVideoEventMutation,
    cancelNonVideoEvent: cancelNonVideoEventMutation,
  };
};
