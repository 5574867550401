import React from "react";
import { Box, Stack, Switch, Typography } from "@mui/material";

/**
 * The `PrivacyItem` function is a React component that displays a privacy item with a title,
 * description, and a switch for toggling its state.
 * @component
 * @param {Object} props - The props of the component `PrivacyItem` include:
 * @param {string} props.title - The title of the privacy item.
 * @param {string} props.description - The description of the privacy item.
 * @param {boolean} props.disabled - The disabled state of the switch.
 * @param {boolean} props.checked - The checked state of the switch.
 * @param {function} props.onChange - The function to handle the change of the switch.
 * @returns {JSX.Element} The `PrivacyItem` component is being returned.
 */
function PrivacyItem({ title, description, disabled, checked, onChange }) {
  return (
    <Stack direction="row" marginBottom={7} justifyContent="space-between" width="100%">
      <Box>
        <Typography component="h3" variant="body2">
          {title}
        </Typography>
        <Typography component="h4" variant="body2" color="text.secondary">
          {description}
        </Typography>
      </Box>
      <Box marginRight={-2}>
        <Switch
          checked={checked}
          onChange={onChange}
          inputProps={{ "aria-label": "controlled" }}
          disabled={disabled}
        />
      </Box>
    </Stack>
  );
}

export default PrivacyItem;
