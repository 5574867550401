import { Typography } from "@mui/material";
import React from "react";
import TextField from "@mui/material/TextField";
import Modal from "elements/Modal";

const RejectApplicantDialog = (props) => {
  const {
    rejectApplicantOpen,
    onSubmitRejectApplicant,
    onChange,
    rejectApplicantReason,
    handleToggleRejectApplicantDialog,
    rejectApplicantWithoutEmail,
  } = props;
  return (
    <Modal
      title={rejectApplicantWithoutEmail ? "Deactivate Applicant" : "Reject Applicant"}
      open={rejectApplicantOpen}
      onClose={handleToggleRejectApplicantDialog}
      content={
        <div style={{ width: 600 }}>
          <Typography component="p" style={{ marginBottom: 10 }}>
            Please select the reason for{" "}
            {rejectApplicantWithoutEmail ? "deactivating" : "rejecting"} this applicant from the
            options below.
          </Typography>
          <TextField
            fullWidth
            value={rejectApplicantReason}
            label={rejectApplicantWithoutEmail ? "Reason for Deactivation" : "Reason for Rejection"}
            onChange={onChange("rejectApplicantReason")}
          />
        </div>
      }
      primaryActionText="Submit"
      primaryActionOnClick={onSubmitRejectApplicant}
      secondaryActionText="Cancel"
      secondaryActionOnClick={handleToggleRejectApplicantDialog}
    />
  );
};

export default RejectApplicantDialog;
