import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import { Typography } from "@mui/material";

const Roadmap = (props) => {
  const { classes, insuranceRoadmap } = props;
  const { steps, currentStepIndex, numSteps } = insuranceRoadmap;

  return (
    <div className={classes.roadmapContainer}>
      <Typography component="p" className={classes.roadmapTitle}>
        Progress to Full Client
      </Typography>
      <Typography component="p" className={classes.roadmapStepCount}>
        Step {currentStepIndex + 1}/{numSteps}
      </Typography>
      {steps.map((step, index) => {
        if (step.completed) {
          return (
            <React.Fragment key={index}>
              <div className={classes.completedRoadmapStep}>
                <div className={classes.roadmapIconContainer}>
                  <CheckIcon />
                </div>
                <div className={classes.roadmapStepTextContainer}>
                  <Typography component="h3">{step.stepTitle}</Typography>
                  <Typography component="p">COMPLETED</Typography>
                </div>
              </div>
              <div
                className={classes.roadmapDividerCompleted}
                style={index + 1 === currentStepIndex ? { height: 40 } : null}
              />
            </React.Fragment>
          );
        } else if (index == currentStepIndex) {
          return (
            <React.Fragment key={index}>
              <div className={classes.currentRoadmapStep}>
                <Typography component="p" className={classes.roadmapStepNumber}>
                  {index + 1}
                </Typography>
                <div className={classes.roadmapStepTextContainer}>
                  <Typography component="h3">{step.stepTitle}</Typography>
                  <Typography component="h4">CURRENT STEP</Typography>
                  <Typography component="h6">{step.stepParagraphs[0]}</Typography>
                </div>
              </div>
              <div className={classes.roadmapDivider} style={{ height: 40 }} />
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment key={index}>
              <div className={classes.incompleteRoadmapStep}>
                <Typography component="p" className={classes.roadmapStepNumber}>
                  {index + 1}
                </Typography>
                <div className={classes.roadmapStepTextContainer}>
                  <Typography component="h3">{step.stepTitle}</Typography>
                </div>
              </div>
              {index !== steps.length - 1 && <div className={classes.roadmapDivider} />}
            </React.Fragment>
          );
        }
      })}
    </div>
  );
};

export default Roadmap;
