import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import MinimizeIcon from "@mui/icons-material/Minimize";
import IconButton from "@mui/material/IconButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import AddIcon from "@mui/icons-material/Add";
import NotesIcon from "@mui/icons-material/InsertComment";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import styles from "./styles";
import getChannelUsers from "./utils/getChannelUsers";
import getChannelName from "./utils/getChannelName";
import getChannelParentName from "./utils/getChannelParentName";
import StatusIndicator from "./StatusIndicator";

const ChatHeader = (props) => {
  const {
    classes,
    theme,
    handleDrawerToggle,
    currentChannel,
    connection,
    displayLeftDrawer,
    displayNotes,
    displayLogout,
    onNotesClick,
    onLogoutClick,
    displayBack,
    displayHeaderBack,
    onHeaderBackClick,
    onHeadingClick,
    miniDisplay,
    handleToggleDescription,
    onMinimizeClick,
    minimized,
    isSupport,
    isSupervisor,
    isDirector,
    primaryClinicianUserId,
  } = props;
  const isSupportClinicianChat = (channel) => {
    if (!channel || !channel.data) {
      return false;
    } else {
      const data = JSON.parse(channel.data);
      if (data.bcbaName) {
        return true;
      } else {
        return false;
      }
    }
  };
  const isBCBAChatChannel = (channel) => {
    if (channel && channel.name == "BCBA Chat") {
      return true;
    }
    return false;
  };
  const getChannelDescription = (channel) => {
    if (!channel.data) {
      return null;
    }
    const data = JSON.parse(channel.data);
    return data.description || null;
  };
  const currentUser = connection && connection.currentUser ? connection.currentUser : null;
  const users =
    connection && connection.currentUser && currentChannel
      ? getChannelUsers(currentChannel, currentUser.userId)
      : [];
  const bcbaList = users ? users.filter((user) => user.metaData.isBCBA) : [];
  let bcba = bcbaList.length > 0 ? bcbaList.reverse()[0] : null;
  if (primaryClinicianUserId) {
    bcba = bcbaList.find((user) => user.userId == primaryClinicianUserId);
  }
  const description = currentChannel ? getChannelDescription(currentChannel) : null;
  let parentName =
    isSupport && currentChannel
      ? getChannelParentName(currentChannel.data, currentChannel.isPublic)
      : null;

  return (
    <AppBar
      position="absolute"
      className={classNames(
        classes.appBar,
        miniDisplay && classes.appBarMiniDisplay,
        !displayLeftDrawer && classes.appBarFull,
      )}
    >
      <Toolbar disableGutters={miniDisplay} style={{ color: "#555555" }}>
        {displayLeftDrawer && (
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
            size="large"
          >
            <MenuIcon />
          </IconButton>
        )}
        <Typography className={classes.title} variant="h6" color="inherit" noWrap>
          {currentChannel ? (
            <React.Fragment>
              {users.length > 1 && currentUser.metaData.isBCBA ? (
                <span className={classes.channelNameHeading}>
                  {currentChannel.isPublic ? (
                    <React.Fragment>
                      <span>
                        <span className={classes.multinameHeading}>{currentChannel.name}</span>
                        {description && (
                          <IconButton
                            color="inherit"
                            aria-label="Open channel description"
                            // className={classes.notesButton}
                            onClick={handleToggleDescription}
                            size="large"
                          >
                            <InfoOutlinedIcon fontSize={"inherit"} />
                          </IconButton>
                        )}
                      </span>
                      {!miniDisplay && !displayNotes && (
                        <Avatar
                          alt={currentChannel.name}
                          src={currentChannel.coverUrl}
                          className={classes.headerAvatar}
                        />
                      )}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {currentUser.metaData.isBCBA &&
                      !(
                        isSupportClinicianChat(currentChannel) || isBCBAChatChannel(currentChannel)
                      ) ? (
                        <span className={classes.multinameHeading}>{currentChannel.name}</span>
                      ) : (
                        <React.Fragment>
                          <span className={classes.headingFlex}>
                            {bcba ? (
                              <>
                                {!isBCBAChatChannel && (
                                  <StatusIndicator
                                    onlineStatus={bcba.connectionStatus === "online"}
                                  />
                                )}
                                <span className={classes.multinameHeading}>
                                  {isBCBAChatChannel ||
                                  currentUser.metaData.isBCBA ||
                                  isSupervisor ||
                                  isDirector
                                    ? currentChannel.name
                                    : `Chat with ${bcba.nickname}`}
                                </span>
                              </>
                            ) : (
                              <span className={classes.multinameHeading}>
                                {isSupport && parentName && !isSupportClinicianChat(currentChannel)
                                  ? `Support for ${parentName}`
                                  : currentChannel.name}
                              </span>
                            )}
                          </span>
                          {!miniDisplay && !displayNotes && !displayHeaderBack && bcba && (
                            <div onClick={onHeadingClick}>
                              <Avatar
                                alt={bcba.nickname}
                                src={bcba.profileUrl}
                                className={classes.headerAvatar}
                              />
                            </div>
                          )}
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}
                </span>
              ) : bcba ? (
                <React.Fragment>
                  <div className={classes.headingFlex}>
                    <span className={classes.channelNameHeading}>
                      {currentUser.metaData.isBCBA || isSupervisor || isDirector
                        ? currentChannel.name
                        : `Chat with ${bcba.nickname}`}
                    </span>
                    <StatusIndicator onlineStatus={users[0].connectionStatus === "online"} />
                  </div>
                  {!miniDisplay && !displayNotes && !displayHeaderBack && (
                    <div onClick={onHeadingClick}>
                      <Avatar
                        alt={bcba.nickname}
                        src={bcba.profileUrl}
                        className={classes.headerAvatar}
                      />
                    </div>
                  )}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className={classes.headingFlex}>
                    <span className={classes.channelNameHeading}>{currentChannel.name}</span>
                    {users[0] && (
                      <StatusIndicator onlineStatus={users[0].connectionStatus === "online"} />
                    )}
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          ) : null}
        </Typography>
        {displayNotes &&
          !isSupportClinicianChat(currentChannel) &&
          !isBCBAChatChannel(currentChannel) && (
            <IconButton
              color="inherit"
              aria-label="Open notes"
              className={classes.notesButton}
              onClick={onNotesClick}
              size="large"
            >
              <NotesIcon fontSize={"small"} />
            </IconButton>
          )}
        {displayLogout && <Button onClick={onLogoutClick}>Log Out</Button>}
        {displayHeaderBack && (
          <Button onClick={onHeaderBackClick} className={classes.navButton}>
            Back
          </Button>
        )}
        {miniDisplay && !minimized && (
          <div aria-label="Minimize" className={classes.minimizeButton} onClick={onMinimizeClick}>
            <div className={classes.minimize} />
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default withStyles(styles, { withTheme: true })(ChatHeader);
