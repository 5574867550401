export default (theme) => ({
  pageContainer: {
    width: "100%",
    marginTop: 72,
    height: "calc(100vh - 75px)",
    display: "flex",
    [theme.breakpoints.up("lg")]: {
      marginTop: 0,
    },
  },
  loadingContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  leftBlock: {
    position: "sticky",
    width: "18%",
    top: 0,
    zIndex: 1,
    height: "calc(100vh - 75px)",
    paddingTop: 90,
    backgroundColor: "#fafbff",
    [theme.breakpoints.down("xl")]: {
      width: "20%",
    },
  },
  clinicianContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    paddingTop: 20,
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: 10,
    padding: "0px 20px",
    color: "#2a2d39",
    letterSpacing: "-0.02em",
    "& p": {
      fontWeight: 500,
      fontSize: 22,
    },
    "& h4": {
      fontWeight: 500,
    },
  },
  searchContainer: {
    height: "10%",
    display: "flex",
    alignItems: "center",
    padding: "0px 20px",
  },
  searchBar: {
    "& .MuiOutlinedInput-root": {
      height: 30,
      fontSize: 13,
      lineHeight: "18px",
      color: "#545a72",
      backgroundColor: "#fff",
      borderRadius: 11,
      fontWeight: 500,
      "& fieldset": {
        borderRadius: 11,
        border: "1px solid #c3c6d2",
        boxShadow: "inset 0px 0px 3px rgba(0, 0, 0, 0.25)",
      },
      "& .MuiInputAdornment-root": {
        color: "#878da5",
      },
    },
  },
  clinicianList: {
    height: "100%",
    width: "100%",
    overflow: "auto",
    "& .MuiTypography-root": {
      padding: "8px",
    },
    "& p": {
      lineHeight: "25px",
      fontSize: 17,
      letterSpacing: "-2%",
      cursor: "pointer",
      padding: "15px 50px",
    },
  },
  selectedClinician: {
    backgroundColor: "#8e42ca",
    boxShadow: "inset 0px 0px 5px rgba(87,18,141, 0.55)",
    color: "#fff",
    fontWeight: 500,
  },
  notSelectedClician: {
    color: "#3f4456",
    fontWeight: 400,
  },
  title: {
    fontWeight: 500,
    color: "#2A2D39",
  },
  subtitle: {
    fontWeight: 500,
    color: "#2A2D39",
    fontSize: 20,
    marginTop: 10,
  },
  subtext: {
    fontSize: 14,
    color: "#DC2626",
    marginTop: "-25px",
    marginBottom: 10,
  },
  tableLeftCell: {
    borderBottom: "none",
    "& p": {
      fontWeight: 500,
      color: "#3F4456",
    },
    paddingLeft: 0,
  },
  tableCell: {
    borderBottom: "none",
    "& p": {
      fontWeight: 500,
      color: "#2A2D39",
    },
    paddingLeft: 0,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "20px",
    width: "100%",
  },
  videoInfoContainer: {
    width: "100%",
    paddingLeft: 200,
    paddingRight: "55%",
    boxSizing: "border-box",
    [theme.breakpoints.down("xl")]: {
      padding: "0px 50px",
    },
  },
  sessionNotesContainer: {
    width: "100%",
    padding: "0px 200px",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    marginTop: 20,
    [theme.breakpoints.down("xl")]: {
      padding: "0px 50px",
    },
  },
  missingNotesContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "10px 0px 20px",
    "& p": {
      fontWeight: 500,
      color: "#3F4456",
    },
  },
  missingText: {
    color: "#B91C1C",
    fontWeight: 500,
    marginRight: 50,
    fontSize: 20,
  },
  enterNotesButton: {
    margin: "10px auto",
    width: 150,
    height: 50,
  },
  videoBreakdownTable: {},
  tableColumnTitle: {
    color: "#8c8c8c",
    fontWeight: 500,
    fontSize: 16,
    lineHeight: "24px",
  },
  tableControlButton: {
    fontWeight: 500,
    fontSize: 14,
    "&:hover": {
      cursor: "pointer",
    },
  },
  downloadAllButton: {
    margin: "30px 0px",
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  incompleteNotesRow: {
    backgroundColor: "#FECACA",
  },
  sessionNoteDetails: {
    width: "90%",
    marginTop: 15,
    margin: "0px auto",
    backgroundColor: "#f5f5f5",
    boxShadow: "0 0 5px 0 rgba(0,0,0,.4)",
    borderRadius: 6,
    display: "flex",
    justifyContent: "space-between",
    padding: "40px 100px",
    boxSizing: "border-box",
  },
  titleContainer: {
    margin: "20px 0px 20px 10%",
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  title: {
    color: "#2A2D39",
    fontSize: 30,
    fontWeight: 500,
  },
  subtitle: {
    color: "#2A2D39",
    fontSize: 20,
    fontWeight: 500,
    marginBottom: 30,
  },
  anLogo: {
    width: 45,
    height: 45,
    marginRight: 50,
    objectFit: "contain",
  },
  leftColumn: {
    fontWeight: 500,
    color: "#3F4456",
  },
  rightColumn: {
    fontWeight: 500,
    color: "#2A2D39",
  },
  rightColumnText: {
    fontWeight: 500,
    color: "#2A2D39",
    fontSize: 18,
  },
  textRightColumnText: {
    fontWeight: 500,
    color: "#2A2D39",
    fontSize: 18,
    marginBottom: 20,
  },
  sessionDetailButtonsContainer: {
    marginTop: 20,
    marginRight: 100,
    display: "flex",
    justifyContent: "flex-end",
  },
  dialogSessionNoteContainer: {
    width: 700,
    justifyContent: "space-between",
    padding: "50px 20px 0px",
    boxSizing: "border-box",
  },
  dialogActionsContainer: {
    backgroundColor: "#F0F1F4",
    padding: "12px 16px",
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
  },
  signatureImage: {
    width: 450,
    height: 120,
    marginLeft: 220,
  },
  showCanceledCallsLabel: {
    fontSize: 13,
    color: "#2a2d39",
    fontWeight: "500",
  },
  showCanceledCallsRoot: {
    marginLeft: 0,
    marginBottom: 10,
  },
});
