import React from "react";
import {
  Dialog,
  Typography,
  DialogContent,
  DialogActions,
  CircularProgress,
  Button,
} from "@mui/material";
import { Warning } from "@phosphor-icons/react";

const ConfirmSubmitDialog = (props) => {
  const {
    classes,
    open,
    successToastOpen,
    finalToastOpen,
    notesCompleted,
    callBreakdownLoading,
    onConfirmSubmit,
    onClose,
    setAssociatedTimesLoading,
  } = props;

  return (
    <Dialog
      open={open && !successToastOpen && !finalToastOpen && !notesCompleted}
      disableEscapeKeyDown={true}
      PaperProps={{
        style: {
          maxWidth: "95%",
          width: 444,
        },
      }}
    >
      <DialogContent>
        <div className={classes.confirmSubmitDialogContentContainer}>
          <div className={classes.confirmSubmitDialogIconContainer}>
            <Warning size={22} color="rgba(237, 108, 2, 1)" />
          </div>
          <div className={classes.confirmSubmitDialogTitleContainer}>
            <Typography
              component="p"
              variant="subtitle1"
              className={classes.confirmSubmitDialogTitle}
            >
              Submit Session Note?
            </Typography>
            <Typography
              component="p"
              variant="body2"
              className={classes.confirmSubmitDialogContent}
            >
              Session notes can not be updated after they are submitted.
            </Typography>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="secondary"
          disabled={callBreakdownLoading || setAssociatedTimesLoading}
        >
          Cancel
        </Button>
        <Button
          onClick={onConfirmSubmit}
          disabled={callBreakdownLoading || setAssociatedTimesLoading}
        >
          {(callBreakdownLoading || setAssociatedTimesLoading) && (
            <CircularProgress style={{ color: "#C0C8D0", marginRight: 8 }} size={16} />
          )}
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmSubmitDialog;
