import React from "react";
import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";
import { Overview, Filter, Table, InsuranceFormDialog } from "components/ClinicianCredentials";

export const TabContents = (props) => {
  const { overView, filter, table, insuranceFormDialog } = props;
  const theme = useTheme();
  return (
    <Box>
      <Overview {...overView} theme={theme} />
      <Filter {...filter} theme={theme} />
      <Table {...table} theme={theme} />
      <InsuranceFormDialog {...insuranceFormDialog} theme={theme} />
    </Box>
  );
};
