import React from "react";
import withStyles from "@mui/styles/withStyles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

const EditSelectionDialog = (props) => {
  const {
    classes,
    open,
    closeDialog,
    editAllInstances,
    onChangeEditSelection,
    onContinueEditSelection,
  } = props;
  return (
    <Dialog
      PaperProps={{
        style: {
          minWidth: "20%",
        },
      }}
      open={open}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography component="h2">Edit Repeating Session</Typography>
        <Typography component="p">Choose how you would like to edit:</Typography>
      </DialogTitle>
      <DialogContent>
        <RadioGroup
          aria-label="plan"
          name="plan"
          value={editAllInstances}
          onChange={onChangeEditSelection}
        >
          <FormControlLabel
            value={false}
            control={<Radio color="default" size="small" />}
            label={
              <Typography className={classes.radioButtonLabel}>Change only this session</Typography>
            }
          />
          <FormControlLabel
            value={true}
            control={<Radio color="default" size="small" />}
            label={
              <Typography className={classes.radioButtonLabel}>
                Change this and all future sessions
              </Typography>
            }
          />
        </RadioGroup>
      </DialogContent>
      <DialogActions className={classes.dialogButtons}>
        <Button style={{ color: "#3f4456" }} variant="text" onClick={closeDialog}>
          Cancel
        </Button>
        <Button onClick={onContinueEditSelection} variant="text">
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};
const styles = (theme) => ({
  dialogTitle: {
    marginBottom: 5,
    backgroundColor: "#3F4456",
    display: "flex",
    flexDirection: "column",
    color: "#f0f1f4",
    fontWeight: "400",
    "& h2": {
      fontSize: 15,
      lineHeight: "20px",
    },
    "& p": {
      fontSize: 13,
      lineHeight: "18px",
    },
  },
  radioButtonLabel: {
    fontSize: 13,
    color: "#3f4456",
    fontWeight: 500,
  },
  dialogButtons: {
    width: "90%",
    margin: "0px auto",
    boxSizing: "border-box",
    "& button": {
      fontSize: 12,
    },
  },
});

export default withStyles(styles)(EditSelectionDialog);
