/**
 * Checks if a query parameters object is valid, i.e. it contains at least one non-null key-value pair.
 *
 * @param {Object} query - The query parameters object to validate.
 * @returns {boolean} - Returns true if the query parameters object is valid, otherwise returns false.
 */
export const queryParamsObjIsValid = (query) => {
  if (!query || Object.keys(query).length === 0) return false;
  // removes any key-value pairs with a value of undefined or null
  const filteredQuery = Object.fromEntries(Object.entries(query).filter(([_, v]) => !!v));
  return Object.keys(filteredQuery).length > 0;
};
