import React from "react";
import withStyles from "@mui/styles/withStyles";

import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

import styles from "./styles";

const Specialties = (props) => {
  const { clinician, specialtiesLoading, specialties, classes } = props;

  if (specialtiesLoading) {
    return (
      <div className={classes.loading}>
        <CircularProgress />
      </div>
    );
  } else {
    return (
      <Typography component="div" className={classes.specialtiesList}>
        <Typography component="p" align="center">
          {clinician.first_name} specializes in:
        </Typography>
        <List dense={true}>
          {specialties.map((specialty) => (
            <ListItem key={specialty.id} className={classes.compressedList}>
              <ListItemText primary={specialty.name} className={classes.listText} />
            </ListItem>
          ))}
        </List>
      </Typography>
    );
  }
};

export default withStyles(styles)(Specialties);
