import { call, put, select, takeEvery } from "redux-saga/effects";
import actions from "../actions";
import { checkCognitoAuth, getToken } from "../api/auth";
import cookie from "react-cookies";

function* authorizeUser() {
  try {
    let user = yield call(checkCognitoAuth);
    const fromStaticPageEmail = cookie.load("fromStaticVideoPage", {
      path: "/",
    });
    if (fromStaticPageEmail) {
      user.email = fromStaticPageEmail;
    }
    yield put(actions.setBasicDetails(user));
    yield put(actions.getUser());
    yield put(actions.setSignedinState(true));
  } catch (e) {
    console.log(e);
    // log them out
    yield put(actions.setSignedinState(false));
  }
}

export default function* authSaga() {
  yield takeEvery(actions.CHECK_AUTH_STATE, authorizeUser);
}
