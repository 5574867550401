import * as IntercomAi from "components/IntercomAi";
const APP_ID = process.env.INTERCOM_APP_ID;
/**
 * Initializes IntercomAi and displays icon
 *
 * @param {{
 *  name: String,
 *  email: String,
 *  userId: String,
 *  isClient: Boolean,
 *  isClinician: Boolean,
 *  user_hash: String
 * }} userInfo
 *
 * @param {{
 *  alignment: 'left' | 'right',
 *  verticalPadding: Number,
 *  horizontalPadding: Number
 * }} location
 * @returns IntercomAi functions (ie openIntercomAi, closeIntercomAi, showChatPanel, etc)
 */
export const IntercomAiProvider = (userInfo = {}, location = {}) => {
  window.intercomSettings = IntercomAi.getIntercomAiConfiguration(userInfo, location);

  /**********************************************************************
   * Copied from intercom: below
   **********************************************************************/
  var w = window;
  var ic = w?.Intercom;
  if (typeof ic === "function") {
    ic("reattach_activator");
    ic("update", w.intercomSettings);
  } else {
    var d = document;
    var i = function () {
      i.c(arguments);
    };
    i.q = [];
    i.c = function (args) {
      i.q.push(args);
    };
    w.Intercom = i;
    var l = function () {
      var s = d.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src = "https://widget.intercom.io/widget/" + APP_ID;
      var x = d.getElementsByTagName("script")[0];
      x.parentNode.insertBefore(s, x);
    };
    if (document.readyState === "complete") {
      l();
    } else if (w.attachEvent) {
      w.attachEvent("onload", l);
    } else {
      w.addEventListener("load", l, false);
    }
  }
  /**********************************************************************
   * Copied from intercom: above
   **********************************************************************/

  return { ...IntercomAi };
};

export default IntercomAiProvider;
