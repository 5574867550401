import React, { useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import { MuiOtpInput } from "mui-one-time-password-input";
import { ContactMethods } from "./types";
import { obscureEmail, obscurePhone } from "./util";
import { OtpProperties } from "./types";
import Snackbar from "@mui/material/Snackbar";

const WithCode = (props) => {
  const {
    classes,
    contactMethod,
    username,
    otp,
    otpError,
    handleLoginWithPassword,
    handleOtpChange,
    handleOtpComplete,
    handleReSendCode,
    renderErrorMessage,
    showCodeResentMessage,
    setShowCodeResentMessage,
    onSubmit,
  } = props;

  useEffect(() => {
    if (otp.length === OtpProperties.LENGTH) {
      handleOtpComplete(otp);
    }
  });

  const obscuredUsername =
    contactMethod == ContactMethods.PHONE ? obscurePhone(username) : obscureEmail(username);

  return (
    <form className={classes.form} onSubmit={onSubmit}>
      <div
        style={{
          fontSize: 16,
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "150%",
          letterSpacing: "0.15px",
          marginBottom: 24,
        }}
      >
        Enter the {OtpProperties.LENGTH} digit code sent to you at {obscuredUsername}
      </div>
      <div style={{ height: 60 }}>
        <MuiOtpInput
          TextFieldsProps={{
            error: !!otpError,
            size: "medium",
            sx: {
              "& label.Mui-focused": {
                color: "#9c00d5",
              },
              "& fieldset": {
                border: "1px solid #A5AABC",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset legend": {
                  color: "#69718F",
                },
                "&:hover fieldset": {
                  border: "2px solid #9c00d5",
                },
                "&.Mui-focused fieldset": {
                  border: "2px solid #9c00d5",
                },
              },
            },
          }}
          value={otp}
          onChange={handleOtpChange}
          onComplete={handleOtpComplete}
          length={OtpProperties.LENGTH}
          type="numeric"
          gap={"8px"}
        />
      </div>
      <FormControl margin="none" fullWidth>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: 24,
          }}
        >
          <div
            style={{
              fontSize: 14,
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "143%",
              letterSpacing: "0.17px",
            }}
          >
            Didn't receive the code?
          </div>
          <Button
            fullWidth
            onClick={handleReSendCode}
            variant="text"
            size="large"
            color="primary"
            disabled={!username}
            style={{ height: 42 }}
          >
            <b
              style={{
                fontSize: 15,
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "26px",
                letterSpacing: "0.46px",
              }}
            >
              Re-Send Code
            </b>
          </Button>
        </div>
        <Button
          onClick={handleLoginWithPassword}
          color="secondary"
          style={{ height: 42, marginTop: 16 }}
        >
          Use A Password To Log In
        </Button>
        {otpError && <div style={{ marginTop: 15 }}>{renderErrorMessage("otp")}</div>}
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={showCodeResentMessage}
          onClose={() => setShowCodeResentMessage(false)}
          message="Code resent!"
          autoHideDuration={6000}
        />
      </FormControl>
    </form>
  );
};

export default WithCode;
