import { useState } from "react";

/**
 * Custom hook to manage the state of a video player modal.
 *
 * @returns {Object} An object containing the state and control functions for the modal.
 */
export const useVideoPlayer = () => {
  const [isOpen, setIsOpen] = useState(false);

  /**
   * Function to open the modal.
   */
  const openModal = () => setIsOpen(true);

  /**
   * Function to close the modal.
   */
  const closeModal = () => setIsOpen(false);

  return {
    isOpen,
    openModal,
    closeModal,
  };
};
