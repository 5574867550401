import { getUserPermissionsAsync } from "api/user";
import { USER_PERMISSIONS } from "constants/reactQueryKeys";
import { useQuery } from "react-query";

export const useGetUserPermissions = (userId) => {
  const { data, isLoading, ...rest } = useQuery(
    [USER_PERMISSIONS],
    () => getUserPermissionsAsync(userId),
    {
      enabled: !!userId,
      refetchOnWindowFocus: false,
    }
  );
  return {
    userPermissions: data?.data || null,
    permissionsLoading: isLoading,
    ...rest,
  };
};
