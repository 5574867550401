import React from "react";
import { FormControl, Box, Grid, Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import ANDatePicker from "elements/ANDatePicker";

export const CredentialForm = (props) => {
  const {
    // handlers
    onCompanyChange,
    onPlanChange,
    setInitialCredentialDate,
    setSubmittedDate,
    setApprovalDate,
    setRecredentialDate,
    setTermDate,
    credentialModifiedListener,
    // values
    insuranceCompanyNameId,
    insurancePlanId,
    insuranceCompanyOptions,
    insurancePlanOptions,
    initialCredentialDate,
    submittedDate,
    approvalDate,
    recredentialDate,
    termDate,
  } = props;

  const fields = [
    {
      label: "Submitted Date",
      value: submittedDate,
      handler: setSubmittedDate,
      enabled: true,
    },
    {
      label: "Initial Credential Date",
      value: initialCredentialDate,
      handler: setInitialCredentialDate,
      enabled: true,
    },
    {
      label: "Approval Date",
      value: approvalDate,
      handler: setApprovalDate,
      enabled: true,
    },
    {
      label: "Recredential Date",
      value: recredentialDate,
      handler: setRecredentialDate,
      enabled: true,
    },
    {
      label: "Term Date",
      value: termDate,
      handler: setTermDate,
      enabled: true,
      fullWidth: true,
    },
  ];

  const getDatePickers = ({ value, handler, enabled, label, fullWidth }, i) => (
    <Grid item key={i} sm={fullWidth ? 4 : 2} md={fullWidth ? 8 : 4} marginTop={"24px"}>
      <Box display={"flex"} width={"100%"} flexDirection={"column"}>
        <Box
          paddingBottom={"8px"}
          fontSize={"12px"}
          fontStyle={"normal"}
          fontWeight={"500"}
          lineHeight={"12px"}
          letterSpacing={"0.3px"}
          color={"#5B6172"}
        >
          {label}
        </Box>
        <ANDatePicker
          placeholder={value ? new Date(value) : new Date()}
          views={["year", "month", "day"]}
          onChange={credentialModifiedListener(handler)}
          timeIntervals={1}
          format="MM/dd/yy"
          disabled={!enabled}
          value={value ? new Date(value) : null}
          style={{ fontSize: 14 }}
        />
      </Box>
    </Grid>
  );

  return (
    <Box sx={{ width: "100%", padding: "0 0 24px 0" }}>
      <FormControl fullWidth>
        <Box sx={{ width: "100%" }}>
          <Box
            paddingBottom={"8px"}
            fontSize={"12px"}
            fontStyle={"normal"}
            fontWeight={"500"}
            lineHeight={"12px"}
            letterSpacing={"0.3px"}
            color={"#5B6172"}
          >
            Insurance Company*
          </Box>
          <Select
            sx={{ width: "100%" }}
            variant="outlined"
            onChange={credentialModifiedListener(onCompanyChange)}
            value={`${insuranceCompanyNameId}`}
          >
            {insuranceCompanyOptions?.map(({ name, value }, index) => (
              <MenuItem key={`${index}`} name={name} value={value}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </FormControl>
      <FormControl fullWidth>
        <Box sx={{ width: "100%", paddingTop: "24px" }}>
          <Box
            paddingBottom={"8px"}
            fontSize={"12px"}
            fontStyle={"normal"}
            fontWeight={"500"}
            lineHeight={"12px"}
            letterSpacing={"0.3px"}
            color={"#5B6172"}
          >
            Insurance Plan
          </Box>
          <Select
            sx={{ width: "100%" }}
            variant="outlined"
            fullWidth
            onChange={credentialModifiedListener(onPlanChange)}
            value={`${insurancePlanId}`}
          >
            {insurancePlanOptions?.map(({ name, value }, index) => (
              <MenuItem key={`${index}`} name={name} value={value}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </FormControl>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container columnSpacing={{ sm: 1, md: 2 }} columns={{ sm: 4, lg: 8 }}>
          {fields.map(getDatePickers)}
        </Grid>
      </Box>
    </Box>
  );
};
