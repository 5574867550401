import React, { Component } from "react";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { Lobby } from "../Lobby";
import Logo from "../../../assets/answers_now_logo_dark_purple.png";
import Button from "@mui/material/Button";
import HangTight from "./HangTight";
import RemovedFromSessionDialog from "../Dialogs/RemovedFromSessionDialog";
import ClientSignaturesDialog from "../Dialogs/ClientSignaturesDialog";

class ClientVideoMessage extends Component {
  renderMessage = () => {
    const {
      startDate,
      endDate,
      callEnded,
      legacy,
      hasJoinedRoom,
      userPermissions,
      secondaryClinicianConnected,
      fromStaticUrl,
      signOut,
    } = this.props;
    if (startDate) {
      if (endDate || callEnded) {
        if (legacy || !userPermissions?.sign_after_video_call) {
          return this.legacyCallEnded();
        } else if (fromStaticUrl) {
          return this.fromStaticUrlCallEnded();
        } else {
          return this.postVideoSignatures();
        }
      } else if (hasJoinedRoom && !secondaryClinicianConnected) {
        return this.waitingForClinician();
      } else {
        return this.preVideoLobbyWithInfo();
      }
    } else {
      return this.preVideoLobbyWithInfo();
    }
  };

  preVideoLobbyWithInfo = () => {
    const { hasJoinedRoom } = this.props;
    return hasJoinedRoom ? null : <Lobby {...this.props} />;
  };

  legacyCallEnded = () => {
    const { classes } = this.props;
    return (
      <>
        <img className={classes.logo} src={Logo} alt="AnswersNow" title="AnswersNow" />
        <Typography className={classes.waitingMessage} component="h6">
          Your call has been completed.
        </Typography>
        <Button onClick={this.leaveWithoutSign} style={{ zIndex: 3000, marginTop: 20 }}>
          Okay
        </Button>
      </>
    );
  };

  fromStaticUrlCallEnded = () => {
    const { classes } = this.props;
    return (
      <>
        <img className={classes.logo} src={Logo} alt="AnswersNow" title="AnswersNow" />
        <div style={{ margin: 10 }}>
          <CircularProgress />
        </div>
      </>
    );
  };

  postVideoSignatures = () => {
    const {
      classes,
      endDate,
      hasJoinedRoom,
      callEnded,
      primaryClinicianData,
      secondaryClinicianData,
      secondaryClinicianInControl,
      clientUserReplaced,
      history,
      trimSignatures,
    } = this.props;
    let placeholderName = secondaryClinicianInControl
      ? secondaryClinicianData?.name[0]
      : primaryClinicianData?.name[0];
    return endDate && !clientUserReplaced ? (
      <ClientSignaturesDialog onSubmitSignatures={trimSignatures} {...this.props} />
    ) : clientUserReplaced ? (
      <RemovedFromSessionDialog
        open={clientUserReplaced}
        navToDashboard={() => history.push("/dashboard")}
      />
    ) : callEnded && hasJoinedRoom ? (
      <div className={classes.mainMediaDisabledPlaceholder}>
        <div className={classes.mainMediaDisabledInitial}>
          <Typography component="p">{placeholderName}</Typography>
        </div>
      </div>
    ) : (
      <HangTight isClient classes={classes} />
    );
  };

  waitingForClinician = () => {
    const { classes } = this.props;
    return (
      <>
        <img className={classes.logo} src={Logo} alt="AnswersNow" title="AnswersNow" />
        <div style={{ margin: 10 }}>
          <CircularProgress />
        </div>
        <Typography className={classes.waitingMessage} component="h1" variant="h5">
          Waiting for clinician to join
        </Typography>
      </>
    );
  };

  leaveWithoutSign = () => {
    if (this.props.activeRoom) {
      this.props.activeRoom.disconnect();
    }
    this.props.history.goBack();
    return;
  };

  render() {
    return this.renderMessage();
  }
}

export default ClientVideoMessage;
