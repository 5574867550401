const ACTIONS = {
  GET_COMPUTER_PERFORMANCE: "GET_COMPUTER_PERFORMANCE",
  GET_COMPUTER_PERFORMANCE_SUCCESS: "GET_COMPUTER_PERFORMANCE_SUCCESS",
  CLEAR_COMPUTER_PERFORMANCE: "CLEAR_COMPUTER_PERFORMANCE",
};

const ACTION_CREATORS = {
  getComputerPerformance() {
    return {
      type: ACTIONS.GET_COMPUTER_PERFORMANCE,
    };
  },
  getComputerPerformanceSuccess(result) {
    return {
      type: ACTIONS.GET_COMPUTER_PERFORMANCE_SUCCESS,
      payload: result,
    };
  },
  clearComputerPerformance() {
    return { type: ACTIONS.CLEAR_COMPUTER_PERFORMANCE };
  },
};

export default { ...ACTIONS, ...ACTION_CREATORS };
