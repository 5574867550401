import { createReducer } from "redux-create-reducer";
import actions from "../actions";

const initialState = {
  appointmentChangeLogs: [],
};

const reducers = {
  [actions.GET_APPOINTMENT_CHANGE_LOGS](state) {
    return {
      ...state,
      appointmentChangeLogsLoading: true,
      appointmentChangeLogsSuccess: false,
    };
  },
  [actions.GET_APPOINTMENT_CHANGE_LOGS_SUCCESS](state, { payload }) {
    return {
      ...state,
      appointmentChangeLogs: payload,
      count: payload.length > 0 ? payload[0].count : 0,
      appointmentChangeLogsLoading: false,
      appointmentChangeLogsSuccess: true,
    };
  },
  [actions.GET_APPOINTMENT_CHANGE_LOGS_FAIL](state) {
    return {
      ...state,
      appointmentChangeLogsLoading: false,
    };
  },
};

export default createReducer(initialState, reducers);
