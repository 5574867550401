import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useTheme } from "@emotion/react";

import {
  saveClinicianDocumentAsync,
  replaceClinicianDocumentAsync,
  deleteClinicianDocumentAsync,
} from "api/clinician";
import { postUploadFiles } from "hooks/postUploadFiles";
import { openS3Document } from "utils/openS3Document";
import { CLINICIAN_LIABILITY_INSURANCE } from "constants/reactQueryKeys";
import SectionList from "./SectionList";
import SectionBox from "elements/SectionBox";
import { useGlobalToast } from "components/GlobalToastProvider";
import LiabilityInsuranceDialog from "./Dialogs/AddEditLiabilityDialog";
import DeleteAlertDialog from "./Dialogs/DeleteAlertDialog";
/** @typedef {import("api/typesDef").LiabilityInsurance} LiabilityInsurance */
/** @typedef {import("elements/ANUploader").AcceptedFile} LiabilityFile */

/**
 * @typedef {Object} FormValues - The Liability Insurance Fromik values.
 * @property {Date} expiryDate - The expiry date of the Liability Insurance.
 * @property {LiabilityFile[]} liabilityFile - The uploaded file of the Liability Insurance.
 */

const LIABILITY_INSURANCE = "LIABILITY_INSURANCE";

export default function LiabilityInsurance({ basicInfo, editMode, liabilityInsuranceList = [] }) {
  const queryClient = useQueryClient();
  const theme = useTheme();
  const { showToast } = useGlobalToast();

  // Retry State for mutations
  const [retry, setRetry] = useState(0);

  // Dialog States
  const [openAddEditDialog, setOpenAddEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [isNewLiability, setIsNewLiability] = useState(true);

  /** @type {ReturnType<typeof useState<(LiabilityInsurance)>>} */
  const [selectedLiability, setSelectedLiability] = useState(null);

  // Menu Options
  const list = liabilityInsuranceList.map((liability) => ({
    id: liability.id,
    title: liability.document_name || "Missing document name",
    expirationDate: liability.expiry_date,
    BasicInfo: basicInfo,
  }));

  const onSelectElement = (id) => {
    const currentLiability = liabilityInsuranceList.find(
      (liabilityInsurance) => liabilityInsurance.id === parseInt(id)
    );
    setSelectedLiability(currentLiability);
  };

  // Mutations
  const { mutate: saveLiabilityInsurance } = useMutation(
    isNewLiability ? saveClinicianDocumentAsync : replaceClinicianDocumentAsync,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(CLINICIAN_LIABILITY_INSURANCE);
        showToast({
          message: isNewLiability ? "Liability Insurance added!" : "Liability Insurance updated!",
        });
        setRetry(0);
      },
      onError: (error) => {
        console.error(error);
        showToast({
          message: "Whoops! Something went wrong",
          errorState: true,
          retryHandler: () => setRetry(1),
        });
      },
      onSettled: () => {
        handleCloseAddEditDialog();
      },
      retry,
    }
  );

  const { mutate: deleteLiabilityInsurance, isLoading: isDeletingLiabilityInsurance } = useMutation(
    deleteClinicianDocumentAsync,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(CLINICIAN_LIABILITY_INSURANCE);
        showToast({ message: "Liability Insurance removed!" });
        setRetry(0);
      },
      onError: (error) => {
        console.error(error);
        showToast({
          message: "Whoops! Something went wrong",
          errorState: true,
          retryHandler: () => setRetry(1),
        });
      },
      onSettled: () => {
        handleCloseDeleteDialog();
      },
      retry,
    }
  );

  // Dialog handler
  const handleOpenLiabilityDialog = (isNew) => {
    setIsNewLiability(isNew);
    setOpenAddEditDialog(true);
  };

  const handleCloseAddEditDialog = () => setOpenAddEditDialog(false);
  const handleCloseDeleteDialog = () => setOpenDeleteDialog(false);

  // Formik Submit Handler
  /** @param {FormValues} values */
  const handleSubmitLiability = (values) => {
    const liabilityFiles = values.liabilityFile[0]?.alreadyUploaded ? [] : values.liabilityFile;

    return postUploadFiles(basicInfo.cognito_id, liabilityFiles).then(
      async ({ errors, success: filename }) => {
        const file = liabilityFiles[0]?.file;
        if (errors) {
          console.error(errors);
          return;
        }

        let liabilityData = {
          clinicianId: basicInfo.id,
          documentName: filename ? file.name : selectedLiability.document_name,
          documentType: LIABILITY_INSURANCE,
          expiryDate: values.expiryDate,
          fileSize: filename ? file.size : selectedLiability.file_size,
        };

        if (isNewLiability) {
          liabilityData = {
            ...liabilityData,
            filename: filename.split("/")[1],
          };
        } else {
          liabilityData = {
            ...liabilityData,
            id: selectedLiability.id,
            filename: filename ? filename.split("/")[1] : null,
          };
        }

        await saveLiabilityInsurance(liabilityData);
      }
    );
  };

  // Delete Liability Insurance handler
  const handleDeleteLiabilityInsurance = () => {
    const payload = {
      clinicianId: selectedLiability.clinician_id,
      id: selectedLiability.id,
    };

    deleteLiabilityInsurance(payload);
  };

  // Download Liability Insurance handler
  const handleDownloadLiability = () => {
    openS3Document({ cognitoId: basicInfo.cognito_id, filename: selectedLiability.filename });
  };

  return (
    <SectionBox
      title="Liability Insurance"
      description={"Manage Liability coverage on file for the Clinician"}
      padding={theme.spacing(5, 0)}
    >
      <SectionList
        list={list}
        editMode={editMode}
        addHandler={() => handleOpenLiabilityDialog(true)}
        editHandler={() => handleOpenLiabilityDialog(false)}
        deleteHandler={() => setOpenDeleteDialog(true)}
        downloadHandler={handleDownloadLiability}
        onSelectElement={onSelectElement}
        noDataMessage="No Liability Insurance on the account"
        menuActionText="Liability Insurance"
      />

      <LiabilityInsuranceDialog
        open={openAddEditDialog}
        currentLiability={selectedLiability}
        isNew={isNewLiability}
        onClose={handleCloseAddEditDialog}
        onSubmit={handleSubmitLiability}
      />

      <DeleteAlertDialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        onDelete={handleDeleteLiabilityInsurance}
        isLoading={isDeletingLiabilityInsurance}
        title="Delete Liability Insurance?"
      />
    </SectionBox>
  );
}
