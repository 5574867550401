import { TextField } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
export const StyledInput = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#C3C6D2",
    },
    "& fieldset": {
      border: "1px solid #c1c1c1",
      borderRadius: 18,
      minHeight: 36,
      fontSize: 15,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 18,
      "& fieldset": {},
      "&:hover fieldset": {
        border: "1px solid #C3C6D2",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid #C3C6D2",
      },
      "& input": {
        padding: "5px 15px",
        fontSize: 14,
        height: "fit-content",
        backgroundColor: "#fafafa",
        borderRadius: 18,
      },
    },
    "& .MuiFormHelperText-root": {
      margin: "0 5px",
      color: "red",
    },
  },
})(TextField);
