import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import { Views } from "react-big-calendar";

import GoogleBrandmark from "assets/google_brandmark.svg";
/** @typedef {import("api/typesDef").CalendarEvent} Event */

/**
 * Returns the initials of the name.
 * @param {string} name - The name to get the initials from.
 * @returns {string} The initials of the name.
 * @example
 * getInitials("John Doe"); // Returns "JD"
 */
function getInitials(name) {
  if (!name) return "";

  return (
    name
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase())
      // Only get the first two initials
      .slice(0, 2)
      .join("")
  );
}

/**
 * The ANCalendarEvent renders a calendar event component with details like title, start and
 * end time, attendees, and participant name. The component is used in the ANCalendar component.
 * @param {Object} props - The props object.
 * @param {Event} props.event - The event object that contains the event details.
 * @param {string} props.view - The current view of the calendar: "day", "week", or "month".
 * @param {"small"| "medium"| "large"} props.fixedSize - Overrides the event block size based on the call duration.
 * @returns {JSX.Element} The ANCalendarEvent component is being returned.
 */
const ANCalendarEvent = ({ event, view, fixedSize }) => {
  const {
    call_duration,
    client_id,
    client_name,
    clinician_id,
    clinician_name,
    clinician_image_url,
    end,
    start,
    googleEvent,
    title,
    no_show,
    canceled,
    team_member_image_url,
    team_member_name,
  } = event;
  const callDuration = parseInt(call_duration);

  // Set the block size based on the call duration.
  let blockSize = "small";
  if (callDuration > 15 && callDuration < 60) blockSize = "medium";
  if (callDuration >= 60) blockSize = "large";

  // If the fixedSize prop is passed, override the block size.
  if (fixedSize === "large" || fixedSize === "medium" || fixedSize === "small") {
    blockSize = fixedSize;
  }

  const containerStyle =
    callDuration < 60 || view === Views.MONTH ? { gap: 1 } : { flexDirection: "column" };

  let eventTitle = title;
  if (no_show) eventTitle = "No-show";
  if (canceled) eventTitle = "Canceled";

  const eventAttendees = [
    { id: client_id, name: team_member_name, avatar: team_member_image_url },
    { id: clinician_id, name: clinician_name, avatar: clinician_image_url },
  ];

  const isAnowEvent = Boolean(client_id ?? clinician_id);

  // Format the start and end times of the event to display in the event block.
  const eventStartTime = new Date(start).toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  const eventEndTime = new Date(end).toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  return (
    <div
      style={{
        height: "100%",
        ...containerStyle,
      }}
    >
      {view === Views.MONTH ? (
        <Stack direction="row" sx={{ height: "100%" }}>
          <span className="event-color-bar" />
          <header className="event-participant">
            <Typography className="event-client-name" variant="inherit" noWrap>
              {client_name} {eventTitle}
            </Typography>
          </header>
        </Stack>
      ) : (
        <Stack direction="row" sx={{ height: "100%" }} className={blockSize}>
          <span className="event-color-bar" />

          <section className={`event-detail ${googleEvent ? "is-google-event" : ""}`}>
            <header className="event-participant">
              <Typography component={"h3"} className="event-client-name" variant="inherit" noWrap>
                {client_name}
              </Typography>
            </header>

            {isAnowEvent && (
              <div className="event-title">
                <Typography component={"p"} variant="caption" color={"text.secondary"} noWrap>
                  {eventTitle}
                </Typography>
              </div>
            )}

            {googleEvent || blockSize === "large" ? (
              <div className="event-start-end-time">
                <Typography component={"p"} variant="caption" color={"text.secondary"} noWrap>
                  {eventStartTime} - {eventEndTime}
                </Typography>
              </div>
            ) : null}

            {isAnowEvent && (
              <div className="event-attendees">
                <AvatarGroup max={3} sx={{ justifyContent: "flex-end", paddingTop: "5px" }}>
                  {eventAttendees.map((attendee) => (
                    <Avatar
                      key={attendee.id}
                      alt={attendee.name}
                      src={attendee.avatar}
                      sx={{ width: 24, height: 24, fontSize: "12px" }}
                    >
                      {getInitials(attendee.name)}
                    </Avatar>
                  ))}
                </AvatarGroup>
              </div>
            )}
          </section>

          {googleEvent && (
            <span className="event-google-icon">
              <img src={GoogleBrandmark} alt="Google logo" />
            </span>
          )}
        </Stack>
      )}
    </div>
  );
};

export default ANCalendarEvent;
