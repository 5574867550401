export const styles = (theme) => ({
  smsTextMessages: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    "& p": {
      color: "#2A2D39",
      fontSize: 17,
      fontWeight: 500,
    },
  },
  dialogContent: {
    padding: "0 !important",
  },
  consentWarningContainer: {
    width: "100%",
    height: "fit-content",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "12px 30px 12px 50px",
    backgroundColor: "#F0F1F4",
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
    paddingBottom: 0,
  },
  smsConsentWarningContainer: {
    width: "100%",
    height: "fit-content",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "16px 24px",
    backgroundColor: "#F0F1F4",
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
    paddingBottom: 0,
  },
  unsignedConsentWarning: {
    color: "#DC2626",
    fontSize: 14,
    fontWeight: 500,
    marginBottom:16
  },
  smsFacilityContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  header: {
    height: 65,
    width: "100%",
    backgroundColor: "none",
    paddingLeft: 24,
    paddingRight: 20,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
  },
  headerText: {
    color: "#3F4456",
    fontSize: 18,
    fontWeight: "800",
  },
  closeIcon: {
    fontSize: 30,
    color: "#15171d",
  },
  smsThread: {
    flex: 1,
    overflowY: "scroll",
    padding: "20px 24px",
  },
  newDayContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "5px 0",
    "& p": {
      color: "#69718F",
      fontSize: 12,
      fontWeight: "500",
    },
  },
  smsContainer: {
    display: "flex",
    flexDirection: "column",
    width: "fit-content",
    width: "100%",
    alignItems: "flex-end",
    justifyContent: "flex-start",
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: 5,
    marginBottom: 10,
    scrollMarginTop: "30px",
  },
  smsContainerFamily: {
    display: "flex",
    flexDirection: "column",
    width: "fit-content",
    width: "100%",
    justifyContent: "flex-start",
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: 5,
    marginBottom: 10,
    scrollMarginTop: "30px",
  },
  smsMessageContainer: {
    width: "fit-content",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    maxWidth: "75%",
    [theme.breakpoints.down("xl")]: {
      maxWidth: "90%",
    },
  },
  message: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: "0.00938em",
    whiteSpace: "pre-line",
    wordWrap: "break-word",
    [theme.breakpoints.down("xl")]: {
      fontSize: 12,
    },
  },
  smsMessage: {
    maxWidth: "100%",
    borderRadius: 9999,
    backgroundColor: "#E8D9F4",
    padding: "15px 25px",
    [theme.breakpoints.down("xl")]: {
      borderRadius: 20,
    },
  },
  smsMessageFamily: {
    maxWidth: "100%",
    borderRadius: 9999,
    backgroundColor: "#E1E3E9",
    padding: "15px 25px",
    [theme.breakpoints.down("xl")]: {
      borderRadius: 20,
    },
  },
  timeSender: {
    color: "#69718F",
    fontSize: 12,
    fontWeight: "500",
  },
  inputContainer: {
    width: "100%",
    minHeight: 50,
    height: "fit-content",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "12px 30px 12px 50px",
    backgroundColor: "#F0F1F4",
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
  },
  smsInputContainer: {
    width: "100%",
    minHeight: 50,
    height: "fit-content",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "16px 24px",
    backgroundColor: "#F0F1F4",
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
  },
  sendIcon: { fill: "#7235A2", fontSize: 20 },
  sendIconDisabled: { fill: "#E0E0E0", fontSize: 20 },
});
