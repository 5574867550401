import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { X } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import ANUploader from "elements/ANUploader";
import { useGetClinicianDocumentTypes } from "hooks/useGetClinicianDocumentTypes";
import { formatFilename } from "utils/snakeCasetoTitleCase";

export default function UploadDocumentDialog({
  open,
  handleClose,
  handleFileUpload,
  dropzoneFiles,
  handleFileDelete,
  submitDocumentUpload,
  isNewDocument,
  alreadyUploadedFiles,
  uploadDocType,
  setUploadDocType,
  handleLoadedFileDelete,
  filterOutDocumentTypes,
}) {
  const theme = useTheme();
  //use hook

  const { isFetching, data: documentTypes } = useGetClinicianDocumentTypes();
  const [showErrors, setShowErrors] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [disableSaveButton, setDisableSaveButton] = useState(false);

  useEffect(() => {
    // since the dialog does not unmount on close, we need to reset the state when it opens
    if (open) setDisableSaveButton(!isNewDocument);
  }, [open]);

  const handleSubmit = () => {
    setShowErrors(true);
    if (
      !uploadDocType ||
      (!dropzoneFiles.length && !alreadyUploadedFiles.length) ||
      dropzoneFiles?.[0]?.errors
    )
      return;
    // setUploading(true);
    submitDocumentUpload(uploadDocType)
      .then(() => {
        handleClose();
        setShowErrors(false);
      })
      .catch((e) => console.error(e))
      .finally(() => setUploading(false));
  };

  const internalHandleClose = () => {
    handleClose();
    setTimeout(() => setShowErrors(false), 250); // Timeout avoids flickering
  };

  return (
    <Dialog open={open} onClose={internalHandleClose} maxWidth="xs">
      <DialogTitle>
        {isNewDocument ? "Upload Document" : "Edit Document"}
        <IconButton aria-label="close" onClick={internalHandleClose} disabled={uploading}>
          <X />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Stack gap={theme.spacing(5)}>
          <TextField
            select
            label="Document Type"
            name="uploadDocType"
            value={uploadDocType}
            error={!uploadDocType && showErrors}
            helperText={!uploadDocType && showErrors && "Please select a document type"}
            onChange={(e) => {
              setUploadDocType(e.target.value);
              setDisableSaveButton(false);
            }}
            fullWidth
          >
            <MenuItem disabled value={""}>
              Please select
            </MenuItem>
            {documentTypes &&
              documentTypes.map((docType) => {
                if (!filterOutDocumentTypes.includes(docType.name)) {
                  return (
                    <MenuItem key={docType.id} value={docType.name}>
                      {formatFilename(docType.display_name)}
                    </MenuItem>
                  );
                }
              })}
          </TextField>
          <ANUploader
            handleFileUpload={handleFileUpload}
            dropzoneFiles={dropzoneFiles}
            useDropzoneOptions={{ multiple: false }}
            handleFileDelete={handleFileDelete}
            handleLoadedFileDelete={(file) => {
              handleLoadedFileDelete(file);
              setDisableSaveButton(false);
            }}
            error={!dropzoneFiles.length && !alreadyUploadedFiles.length && showErrors}
            errorText="Please upload a file"
            dropZoneStyle={{ margin: 0 }}
            filePreviewStyle={{ margin: 0 }}
            alreadyUploadedFiles={alreadyUploadedFiles}
            hideDropzone={dropzoneFiles.length || alreadyUploadedFiles.length}
            removeFileButtonProps={{ disabled: uploading }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={internalHandleClose} disabled={uploading}>
          Cancel
        </Button>
        <LoadingButton onClick={handleSubmit} loading={uploading} disabled={disableSaveButton}>
          {isNewDocument ? "Upload Document" : "Save Changes"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
