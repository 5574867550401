import { Box, Button, Divider, Link, Stack, Typography, useTheme } from "@mui/material";
import { Plus } from "@phosphor-icons/react";
import ANLoadingScreen from "elements/ANLoadingScreen";
import React, { useEffect } from "react";
import AddLogDialog from "./AddLogDialog";
import { useGetInfiniteClientLogs } from "hooks/useGetInfiniteClientLogs";
import { useGlobalToast } from "components/GlobalToastProvider";
import LogsFilter from "./LogsFilter";
import ClientSMSDialog from "components/ClientSMSDialog";
import { filtersMap, filtersMenuItems, getLogIcon, getLogText, getOpenConversation } from "./Utils";
import ANNoResultsOverlay from "../../../../elements/ANNoResultsOverlay";
import ANNoDataOverlay from "elements/ANNoDataOverlay";
import noLogsSvg from "../../../../assets/no_logs.svg";
import LogElement from "./LogElement";
import EventDetailsPanel from "./EventDetailsPanel";
import { useSelector } from "react-redux";
import * as selectors from "selectors";
import { useHistory } from "react-router-dom";
import { getObjectChanges } from "utils/getObjectChanges";

export default function Logs(props) {
  // Excluded classes from otherProps, and not used intentionally, to avoid ClientSMSDialog
  // showing warning `MUI: The key root provided to the classes prop is not implemented in ClientSMSDialog`
  // and many more similar warnings.
  const {
    clientId,
    childName,
    childLastName,
    userPermissions,
    clinicianList,
    setCliniciansPerPage,
    loadCliniciansList,
    ...otherProps
  } = props;
  const clientName = `${childName} ${childLastName}`;
  const canMessageAnytime = userPermissions?.can_send_sms_without_consent;
  const documentTypes = useSelector(selectors.getDocumentTypes);

  const theme = useTheme();
  const history = useHistory();
  const [addLogDialogOpen, setAddLogDialogOpen] = React.useState(false);
  // Tracks the changes in logs count to show toast
  const [originalLogsCount, setOriginalLogsCount] = React.useState(null);
  // Tracks the initial logs count to pass to the endpoint, to offset the logs accordingly
  // to the difference in logs count
  const [initialLogsCount, setInitialLogsCount] = React.useState(null);
  const [filterBy, setFilterBy] = React.useState([]);
  const [isEventDetailsPanelOpen, setIsEventDetailsPanelOpen] = React.useState(false);
  const [sidePanelLog, setSidePanelLog] = React.useState(null);
  const { showToast } = useGlobalToast();
  const {
    data: response,
    isLoading,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useGetInfiniteClientLogs(clientId, {
    size: 10,
    prevLogCount: initialLogsCount,
    filters: filterBy.flatMap((logType) => filtersMap[logType]).join(","),
  });
  const hasLogs = response?.pages?.[0]?.data?.logs?.length > 0;

  useEffect(() => {
    const logsCount = response?.pages?.[response.pages.length - 1].data?.logsCount;
    if (logsCount && originalLogsCount === null) {
      setOriginalLogsCount(logsCount);
      setInitialLogsCount(logsCount);
    } else if (logsCount && originalLogsCount !== logsCount) {
      showToast({
        message: "New updates are available.",
        errorState: true,
        retryHandler: () => {
          // Reset tracking logsCount values
          setOriginalLogsCount(null);
          setInitialLogsCount(null);
        },
        errorButtonText: "Refresh this page",
      });
      setOriginalLogsCount(logsCount);
    }
  }, [response]);

  // Update logs if initialLogsCount is reseted
  useEffect(() => {
    if (!initialLogsCount) refetch();
  }, [initialLogsCount]);

  useEffect(() => {
    setCliniciansPerPage(999);
    if (loadCliniciansList) loadCliniciansList();
  }, [loadCliniciansList]);

  if (isLoading) return <ANLoadingScreen />;
  return (
    <>
      <ClientSMSDialog {...otherProps} canMessageAnytime={canMessageAnytime} />
      <AddLogDialog
        open={addLogDialogOpen}
        handleClose={() => setAddLogDialogOpen(false)}
        clientId={clientId}
        updateLogs={() => {
          setOriginalLogsCount(null);
          setInitialLogsCount(null);
        }}
      />
      {/* 163px is the height of toolbar at the top*/}
      <Stack
        direction="row"
        sx={{
          // 163px and 171px are fixed values for toolbar height for different screen sizes
          height: "calc(100vh - 163px)",
          [theme.breakpoints.down("md")]: {
            height: "calc(100vh - 171px)",
          },
        }}
      >
        <Stack
          padding={theme.spacing(7)}
          alignItems="center"
          flexGrow={1}
          sx={{ overflowY: "auto" }}
        >
          <Stack maxWidth="900px" width="100%" gap={theme.spacing(7)}>
            <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
              <LogsFilter
                filtersMenuItems={filtersMenuItems}
                filterBy={filterBy}
                setFilterBy={(value) => {
                  setOriginalLogsCount(null);
                  setInitialLogsCount(null);
                  setFilterBy(value);
                }}
              />
              <Button
                color="secondary"
                size="small"
                startIcon={<Plus />}
                onClick={() => setAddLogDialogOpen(true)}
                sx={{ marginLeft: "8px", minWidth: "fit-content" }}
              >
                Add Log
              </Button>
            </Stack>
            <Stack>
              {!hasLogs && filterBy.length > 0 && (
                <Box py="64px">
                  <ANNoResultsOverlay
                    handleClearFilters={() => {
                      setOriginalLogsCount(null);
                      setInitialLogsCount(null);
                      setFilterBy([]);
                    }}
                  />
                </Box>
              )}
              {!hasLogs && filterBy.length === 0 && (
                <Box py="64px">
                  <ANNoDataOverlay
                    imgSrc={noLogsSvg}
                    title="No logs yet"
                    description="Logs will display here with new activity on the client’s account."
                  />
                </Box>
              )}
              {hasLogs &&
                response?.pages?.map((group, i) => {
                  return (
                    <React.Fragment key={i}>
                      {i > 0 && i === response.pages.length - 1 && (
                        <Stack
                          direction="row"
                          gap={theme.spacing(3)}
                          height="49px"
                          alignItems="center"
                        >
                          <Typography variant="body2" color="text.secondary">
                            More logs
                          </Typography>
                          <Divider sx={{ flexGrow: 1 }} />
                        </Stack>
                      )}
                      {group?.data?.logs?.map((log, index) => {
                        let changes = {};
                        let logWithProfileChanges = [log];
                        if (log.before && log.after) {
                          changes = getObjectChanges(log.after, log.before, [
                            "modified_by",
                            "created_by",
                            "login",
                            "data",
                          ]);
                          if (Object.keys(changes).length === 0) return null;
                          let existingLog = logWithProfileChanges.find((l) => l === log);

                          if (existingLog) {
                            // Merge new changes into the existing log entry
                            existingLog.data_changes = existingLog.data_changes || {};

                            Object.keys(changes).forEach((key) => {
                              existingLog.data_changes[key] = {
                                new_value: changes[key].new,
                                old: changes[key].old,
                              };
                            });
                          } else {
                            // If log is not already in the list, add it as a new entry
                            logWithProfileChanges.push({
                              ...log,
                              data_changes: Object.keys(changes).reduce((acc, key) => {
                                acc[key] = { new_value: changes[key].new, old: changes[key].old };
                                return acc;
                              }, {}),
                            });
                          }
                        }

                        return logWithProfileChanges.map((log, changeIndex) => {
                          // We don't want to show the divider if it's the last log of the last group
                          const showDivider = !(
                            i === response.pages.length - 1 &&
                            index === group.data.logs.length - 1 &&
                            changeIndex === logWithProfileChanges.length - 1
                          );
                          // We are checking if "before" has more than one element because if it has less
                          // than 2 that means that it is an old log without enough information to
                          // show details
                          const showEventDetailsLink =
                            ["rescheduled", "canceled", "scheduled"].includes(
                              log?.event_action?.toLowerCase()
                            ) &&
                            log.before &&
                            Object.keys(log.before).length > 1;

                          let author = "";

                          if (log.type !== "document_signatures") {
                            author = log.author;
                          }

                          return (
                            <LogElement
                              key={changeIndex}
                              logNote={log.note}
                              logAuthor={author}
                              showDivider={showDivider}
                              openDetailsPanel={() => setIsEventDetailsPanelOpen(true)}
                              logDate={new Date(log.activity_date || log.created)}
                              icon={getLogIcon(theme, log)}
                              logTitle={getLogText(clientName, documentTypes, log, clinicianList)}
                              showEventDetailsLink={
                                log?.event_action?.toLowerCase() === "rescheduled"
                              }
                              showLogWithNoTitle={log.type === "COMMENT"}
                              endTitleElement={
                                <>
                                  {getOpenConversation(history, log)}
                                  {showEventDetailsLink && (
                                    <>
                                      {" "}
                                      (
                                      <Link
                                        underline="hover"
                                        onClick={() => {
                                          setIsEventDetailsPanelOpen(true);
                                          setSidePanelLog(log);
                                        }}
                                      >
                                        event details
                                      </Link>
                                      )
                                    </>
                                  )}
                                </>
                              }
                            />
                          );
                        });
                      })}
                    </React.Fragment>
                  );
                })}
            </Stack>
            {hasNextPage && (
              <Link onClick={() => fetchNextPage()} underline="hover" sx={{ width: "fit-content" }}>
                View older logs
              </Link>
            )}
          </Stack>
        </Stack>
        <EventDetailsPanel
          open={isEventDetailsPanelOpen}
          setOpen={setIsEventDetailsPanelOpen}
          clientId={clientId}
          parentLog={sidePanelLog}
          clientName={clientName}
        />
      </Stack>
    </>
  );
}
