import React from "react";
import Lottie from "react-lottie";

const Animation = (props) => {
  const { classes, animation, isCaregiver } = props;
  const defaultLottieOptions = {
    loop: true,
    autoplay: true,
    path: animation.asset_url,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className={isCaregiver ? classes.animationContainer : classes.animationDisplayContainer}>
      <Lottie options={defaultLottieOptions} />
    </div>
  );
};

export default Animation;
