import React from "react";
import { Dialog, DialogTitle } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ViewSessionNote from "./ViewSessionNote";
import EditSessionNote from "./EditSessionNote";
import ExitConfirmationDialog from "./Dialogs/ExitConfirmationDialog";
import SuccessToast from "./Tools/SuccessToast";
import TimesheetNote from "./TimesheetNote";
import RequestChangesDialog from "./Dialogs/RequestChangesDialog";
import ExitAdhocConfirmationDialog from "./Dialogs/ExitAdhocConfirmationDialog";
import Actions from "./Actions";
import ConfirmDeleteDialog from "./Dialogs/ConfirmDeleteDialog";
import { X } from "@phosphor-icons/react";

const SessionNoteComponent = (props) => {
  const {
    viewOnly,
    onToggleExitDialog,
    classes,
    open,
    successToastOpen,
    serviceType,
    timesheetNote,
    serviceTypeOptions,
    requestChangesDialogOpen,
    createNew,
    onToggleExitAdhocDialog,
    noteCompleted,
    videoCallId,
    onToggleConfirmDeleteDialog,
    confirmDeleteDialogOpen,
    deleteNoteLoading,
    onConfirmDelete,
    onCloseSuccessToast,
    finalToastOpen,
    onCloseFinalToast,
    savedAsDraft,
    requestEditsSuccess,
    approveTimeSuccess,
    associatedTimesDraftSaving,
    deleteNoteSuccess,
  } = props;

  return finalToastOpen ? (
    <SuccessToast
      open={finalToastOpen}
      onClose={onCloseFinalToast}
      classes={classes}
      savedAsDraft={savedAsDraft}
      timesheetNote={timesheetNote}
      requestEditsSuccess={requestEditsSuccess}
      approveTimeSuccess={approveTimeSuccess}
      associatedTimesDraftSaving={associatedTimesDraftSaving}
      deleteNoteSuccess={deleteNoteSuccess}
    />
  ) : (
    <>
      {timesheetNote ? (
        <TimesheetNote
          {...props}
          visible={timesheetNote}
          options={serviceTypeOptions?.timesheet || []}
        />
      ) : (
        <>
          <Dialog
            open={open}
            PaperProps={{
              style: {
                width: 900,
                maxWidth: "95%",
              },
            }}
            sx={{
              "& .MuiPaper-root": {
                overflow: "hidden",
              },
            }}
          >
            <DialogTitle
              id="form-dialog-title"
              align="left"
              style={{ borderBottom: "1px solid #CDD3DA" }}
            >
              {videoCallId ? "Video Call" : serviceType?.description || ""}
              <IconButton
                onClick={
                  createNew || viewOnly || (!videoCallId && noteCompleted)
                    ? onToggleExitAdhocDialog
                    : onToggleExitDialog
                }
              >
                <X />
              </IconButton>
            </DialogTitle>
            {viewOnly ? <ViewSessionNote {...props} /> : <EditSessionNote {...props} />}
            <Actions {...props} />
            <SuccessToast
              open={successToastOpen}
              onClose={onCloseSuccessToast}
              classes={classes}
              savedAsDraft={savedAsDraft}
              timesheetNote={false}
              requestEditsSuccess={requestEditsSuccess}
              approveTimeSuccess={approveTimeSuccess}
              associatedTimesDraftSaving={associatedTimesDraftSaving}
              deleteNoteSuccess={deleteNoteSuccess}
            />
          </Dialog>
          <ExitConfirmationDialog {...props} />
          <ExitAdhocConfirmationDialog {...props} />
        </>
      )}
      <ConfirmDeleteDialog
        classes={classes}
        open={confirmDeleteDialogOpen}
        onClose={onToggleConfirmDeleteDialog}
        onConfirmDelete={onConfirmDelete}
        deleteNoteLoading={deleteNoteLoading}
      />
      {requestChangesDialogOpen && <RequestChangesDialog {...props} />}
    </>
  );
};

export default SessionNoteComponent;
