import React from "react";
import { withRouter } from "react-router";
import withStyles from "@mui/styles/withStyles";
import IconButton from "@mui/material/IconButton";
import styles from "./styles";
import { NAVIGATION_PERMISSIONS } from "../../constants/navigationMapping";
import NavItem from "./NavItem";
import SideNavDrawer from "./SideNavDrawer";
import List from "@mui/material/List";
import { ArrowLineLeft, ArrowLineRight } from "@phosphor-icons/react";
import { Tooltip } from "@mui/material";

const SideNav = (props) => {
  const { classes, drawerOpen, handleDrawerToggle, mobile, navigate } = props;

  const getFilteredNavMenuItems = () => {
    const { userPermissions } = props;
    if (!userPermissions) {
      return [];
    }
    let seenDashboard = false;
    return Object.values(NAVIGATION_PERMISSIONS).filter(({ url, permission }) => {
      if (url !== "/dashboard") {
        return userPermissions[permission];
      }
      if (!seenDashboard && userPermissions[permission]) {
        seenDashboard = true;
        return true;
      }
      return false;
    });
  };

  const pages = getFilteredNavMenuItems();
  const ArrowLine = drawerOpen ? ArrowLineLeft : ArrowLineRight;
  return (
    <SideNavDrawer open={drawerOpen} mobile={mobile} handleDrawerToggle={handleDrawerToggle}>
      <List disablePadding>
        {pages.map((page) => {
          return <NavItem {...props} navigate={navigate} page={page} key={page.title} />;
        })}
      </List>
      <div className={classes.toggleDrawerButtonContainer}>
        <Tooltip title={drawerOpen ? "Collapse" : "Expand"}>
          <IconButton
            color="inherit"
            aria-label={`${drawerOpen ? "collapse" : "expand"} drawer`}
            onClick={handleDrawerToggle}
          >
            <ArrowLine size={20} color={"#D2B3EA"} />
          </IconButton>
        </Tooltip>
      </div>
    </SideNavDrawer>
  );
};

export default withRouter(withStyles(styles)(SideNav));
