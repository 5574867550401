import React from "react";
import { Typography } from "@mui/material";
import { format, addMinutes } from "date-fns";
import { formatToTimeZone } from "date-fns-timezone";
import AvatarInitials from "components/Header/HeaderSections/AvatarInitials";
import { Calendar, Timer, Clock, Stethoscope, GlobeHemisphereWest } from "@phosphor-icons/react";

const VideoSessionDetails = (props) => {
  const {
    classes,
    startDate,
    endDate,
    duration,
    totalCallTime,
    serviceType,
    clinicianName,
    parentName,
    clientName,
    otherClinicianName,
    customerDetails,
    timezone,
  } = props;
  let participantsOptions = [clinicianName, clientName, parentName, "Other"];
  if (otherClinicianName) {
    participantsOptions.splice(3, 0, otherClinicianName);
  }
  const clientInitials = clientName
    ? `${clientName.split(" ")[0][0]?.toUpperCase()}${clientName.split(" ")[1][0]?.toUpperCase()}`
    : "";
  return (
    <div className={classes.callDetailRow}>
      <div className={classes.sessionDetailsTitle}>
        <AvatarInitials userInitials={clientInitials} size={32} />
        <Typography component="p" variant="subtitle1" style={{ marginLeft: 12 }}>
          {clientName}
        </Typography>
      </div>
      <div className={classes.callDetail}>
        <Calendar size={20} color="rgba(0, 0, 0, 0.56)" />
        <Typography component="p" variant="body2Secondary">
          {format(new Date(startDate), "PPP")}
        </Typography>
      </div>

      <div className={classes.callDetail}>
        <Clock size={20} color="rgba(0, 0, 0, 0.56)" />
        <Typography component="p" variant="body2Secondary">{`${totalCallTime} minutes`}</Typography>
      </div>

      <div className={classes.callDetail}>
        <Timer size={20} color="rgba(0, 0, 0, 0.56)" />
        <Typography component="p" variant="body2Secondary">
          {(timezone || customerDetails?.timezone) &&
            startDate &&
            endDate &&
            `${formatToTimeZone(startDate, "h:mm A", {
              timeZone: timezone || customerDetails?.timezone,
            })} - ${formatToTimeZone(endDate, "h:mm A z", {
              timeZone: timezone || customerDetails?.timezone,
            })}`}
        </Typography>
      </div>

      <div className={classes.callDetail}>
        <Stethoscope size={20} color="rgba(0, 0, 0, 0.56)" />
        <Typography component="p" variant="body2Secondary">
          {customerDetails?.diagnosis}
        </Typography>
      </div>

      <div className={classes.callDetail}>
        <GlobeHemisphereWest size={20} color="rgba(0, 0, 0, 0.56)" />
        <Typography component="p" variant="body2Secondary">
          {serviceType?.place_of_service || "Telehealth"}
        </Typography>
      </div>
    </div>
  );
};

export default VideoSessionDetails;
