import React from "react";
import {
  Accordion,
  AccordionSummary,
  Typography,
  Switch,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import { CaretDown } from "@phosphor-icons/react";
import { useTheme } from "@mui/styles";

export const OtherFiltering = ({
  showNoShows,
  setShowNoShows,
  showSupervisoryEvents,
  handleToggleShowSupervisoryEvents,
}) => {
  const theme = useTheme();
  const handleToggleNoShows = (e) => {
    setShowNoShows(!showNoShows);
  };

  return (
    <Accordion
      defaultExpanded
      square
      disableGutters
      sx={{
        "&::before": {
          display: "none",
        },
        "&.Mui-expanded": {
          margin: 0,
        },
        padding: 0,
        boxShadow: "none",
      }}
    >
      <AccordionSummary expandIcon={<CaretDown size={20} />} sx={{ padding: 0 }}>
        <Typography>Other</Typography>
      </AccordionSummary>
      <FormGroup
        sx={{
          "& .MuiFormControlLabel-root": { margin: 0 },
          gap: theme.spacing(3),
          marginBottom: theme.spacing(5),
        }}
      >
        <FormControlLabel
          control={<Switch size="small" checked={showNoShows} onChange={handleToggleNoShows} />}
          label="Show No-shows & Cancellations"
        />
        <FormControlLabel
          control={
            <Switch
              size="small"
              checked={showSupervisoryEvents}
              onChange={handleToggleShowSupervisoryEvents}
            />
          }
          label="Show Supervisory Events"
        />
      </FormGroup>
    </Accordion>
  );
};
