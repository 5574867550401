const ACTIONS = {
  GET_INSURANCE_CUSTOMER_LIST: "GET_INSURANCE_CUSTOMER_LIST",
  GET_INSURANCE_CUSTOMER_LIST_FAIL: "GET_INSURANCE_CUSTOMER_LIST_FAIL",
  GET_DEACTIVATED_CUSTOMER_LIST: "GET_DEACTIVATED_CUSTOMER_LIST",
  GET_DEACTIVATED_CUSTOMER_LIST_SUCCESS: "GET_DEACTIVATED_CUSTOMER_LIST_SUCCESS",
  GET_DEACTIVATED_CUSTOMER_LIST_FAIL: "GET_DEACTIVATED_CUSTOMER_LIST_FAIL",
  LOAD_CUSTOMER_LIST: "LOAD_CUSTOMER_LIST",
  SET_CUSTOMER_LIST: "SET_CUSTOMER_LIST",
  GET_ALL_ACTIVE_CLIENTS: "GET_ALL_ACTIVE_CLIENTS",
  GET_CUSTOMER_DETAILS: "GET_CUSTOMER_DETAILS",

  GET_CUSTOMER_INFO: "GET_CUSTOMER_INFO",
  GET_CUSTOMER_INFO_SUCCESS: "GET_CUSTOMER_INFO_SUCCESS",
  CLEAR_CUSTOMER_INFO: "CLEAR_CUSTOMER_INFO",
  GET_CUSTOMER_CLINICIANS: "GET_CUSTOMER_CLINICIANS",
  GET_CUSTOMER_CLINICIANS_SUCCESS: "GET_CUSTOMER_CLINICIANS_SUCCESS",
  GET_CUSTOMER_CLINICIANS_FAIL: "GET_CUSTOMER_CLINICIANS_FAIL",
  GET_CUSTOMER_TREATMENT_PLANS: "GET_CUSTOMER_TREATMENT_PLANS",
  GET_CUSTOMER_TREATMENT_PLANS_SUCCESS: "GET_CUSTOMER_TREATMENT_PLANS_SUCCESS",
  GET_CUSTOMER_TREATMENT_PLANS_FAIL: "GET_CUSTOMER_TREATMENT_PLANS_FAIL",
  GET_CUSTOMER_DOCUMENTS: "GET_CUSTOMER_DOCUMENTS",
  GET_CUSTOMER_DOCUMENTS_SUCCESS: "GET_CUSTOMER_DOCUMENTS_SUCCESS",
  GET_CUSTOMER_DOCUMENTS_FAIL: "GET_CUSTOMER_DOCUMENTS_FAIL",
  GET_CUSTOMER_APPROVED_UNITS: "GET_CUSTOMER_APPROVED_UNITS",
  GET_CUSTOMER_APPROVED_UNITS_SUCCESS: "GET_CUSTOMER_APPROVED_UNITS_SUCCESS",
  GET_CUSTOMER_APPROVED_UNITS_FAIL: "GET_CUSTOMER_APPROVED_UNITS_FAIL",

  GET_CUSTOMER_PHYSICIANS: "GET_CUSTOMER_PHYSICIANS",
  GET_CUSTOMER_PHYSICIANS_SUCCESS: "GET_CUSTOMER_PHYSICIANS_SUCCESS",
  GET_CUSTOMER_PHYSICIANS_FAIL: "GET_CUSTOMER_PHYSICIANS_FAIL",
  GET_CUSTOMER_PAST_CLINICIANS: "GET_CUSTOMER_PAST_CLINICIANS",
  GET_CUSTOMER_PAST_CLINICIANS_SUCCESS: "GET_CUSTOMER_PAST_CLINICIANS_SUCCESS",
  GET_CUSTOMER_PAST_CLINICIANS_FAIL: "GET_CUSTOMER_PAST_CLINICIANS_FAIL",

  LOAD_CUSTOMER_DETAILS: "LOAD_CUSTOMER_DETAILS",
  SET_CUSTOMER_DETAILS: "SET_CUSTOMER_DETAILS",

  SET_CUSTOMERS_PAGE: "SET_CUSTOMERS_PAGE",
  SET_CUSTOMERS_PER_PAGE: "SET_CUSTOMERS_PER_PAGE",
  GET_CUSTOMERS_PAGE: "GET_CUSTOMERS_PAGE",
  GET_CUSTOMERS_PER_PAGE: "GET_CUSTOMERS_PER_PAGE",
  GET_CUSTOMERS_COUNT: "GET_CUSTOMERS_COUNT",
  SET_CUSTOMERS_SORT: "SET_CUSTOMERS_SORT",
  SET_CUSTOMERS_SEARCH: "SET_CUSTOMERS_SEARCH",
  SET_INSURANCE_CUSTOMERS_FILTER: "SET_INSURANCE_CUSTOMERS_FILTER",
  SET_INSURANCE_CUSTOMERS_TYPE: "SET_INSURANCE_CUSTOMERS_TYPE",
  SET_INSURANCE_CUSTOMERS_STATE: "SET_INSURANCE_CUSTOMERS_STATE",

  SWITCH_CLINICIAN: "SWITCH_CLINICIAN",
  MARK_ELIGIBLE: "MARK_ELIGIBLE",
  MARK_ELIGIBLE_SUCCESS: "MARK_ELIGIBLE_SUCCESS",
  MARK_APPROVED: "MARK_APPROVED",
  MARK_APPROVED_SUCCESS: "MARK_APPROVED_SUCCESS",
  CLEAR_CUSTOMERS: "CLEAR_CUSTOMERS",

  UPLOAD_TREATMENT_PLAN: "UPLOAD_TREATMENT_PLAN",
  UPLOAD_TREATMENT_PLAN_SUCCESS: "UPLOAD_TREATMENT_PLAN_SUCCESS",

  UPDATE_TREATMENT_PLAN: "UPDATE_TREATMENT_PLAN",
  UPDATE_TREATMENT_PLAN_SUCCESS: "UPDATE_TREATMENT_PLAN_SUCCESS",

  UPLOAD_CUSTOMER_DOCUMENT: "UPLOAD_CUSTOMER_DOCUMENT",
  UPLOAD_CUSTOMER_DOCUMENT_SUCCESS: "UPLOAD_CUSTOMER_DOCUMENT_SUCCESS",
  UPLOAD_CUSTOMER_DOCUMENT_FAIL: "UPLOAD_CUSTOMER_DOCUMENT_FAIL",
  GET_DOCUMENT_TYPES: "GET_DOCUMENT_TYPES",
  GET_DOCUMENT_TYPES_SUCCESS: "GET_DOCUMENT_TYPES_SUCCESS",
  GET_DOCUMENT_MATRIX: "GET_DOCUMENT_MATRIX",
  GET_DOCUMENT_MATRIX_SUCCESS: "GET_DOCUMENT_MATRIX_SUCCESS",
  GET_DOCUMENT_MATRIX_FAIL: "GET_DOCUMENT_MATRIX_FAIL",
  APPROVE_CUSTOMER_DOCUMENT: "APPROVE_CUSTOMER_DOCUMENT",
  APPROVE_CUSTOMER_DOCUMENT_SUCCESS: "APPROVE_CUSTOMER_DOCUMENT_SUCCESS",
  APPROVE_CUSTOMER_DOCUMENT_FAIL: "APPROVE_CUSTOMER_DOCUMENT_FAIL",

  SET_CUSTOMER_DOCUMENT_STATUS: "SET_CUSTOMER_DOCUMENT_STATUS",

  UPDATE_USER_INSURANCE_STATUS: "UPDATE_USER_INSURANCE_STATUS",
  UPDATE_USER_INSURANCE_STATUS_SUCCESS: "UPDATE_USER_INSURANCE_STATUS_SUCCESS",
  UPDATE_USER_INSURANCE_STATUS_FAIL: "UPDATE_USER_INSURANCE_STATUS_FAIL",
  CLEAR_UPDATE_USER_INSURANCE_STATUS: "CLEAR_UPDATE_USER_INSURANCE_STATUS",

  RESEND_LOGIN_EMAIL: "RESEND_LOGIN_EMAIL",
  RESEND_LOGIN_EMAIL_SUCCESS: "RESEND_LOGIN_EMAIL_SUCCESS",

  ASSIGN_CLINICIAN: "ASSIGN_CLINICIAN",
  ASSIGN_SECONDARY_CLINICIAN: "ASSIGN_SECONDARY_CLINICIAN",
  REMOVE_SECONDARY_CLINICIAN: "REMOVE_SECONDARY_CLINICIAN",

  TOGGLE_CUSTOMER_ACTIVATION: "TOGGLE_CUSTOMER_ACTIVATION",
  TOGGLE_CUSTOMER_ACTIVATION_SUCCESS: "TOGGLE_CUSTOMER_ACTIVATION_SUCCESS",

  DEACTIVATE_CLIENT: "DEACTIVATE_CLIENT",
  DEACTIVATE_CLIENT_SUCCESS: "DEACTIVATE_CLIENT_SUCCESS",

  SET_SAVING: "SET_SAVING",
  CREATE_INSURANCE_CUSTOMER: "CREATE_INSURANCE_CUSTOMER",
  CREATE_INSURANCE_CUSTOMER_SUCCESS: "CREATE_INSURANCE_CUSTOMER_SUCCESS",
  CREATE_INSURANCE_CUSTOMER_FAIL: "CREATE_INSURANCE_CUSTOMER_FAIL",
  CLEAR_CREATE_CUSTOMER: "CLEAR_CREATE_CUSTOMER",
  SAVE_CUSTOMER_FOR_LATER: "SAVE_CUSTOMER_FOR_LATER",
  SAVE_CUSTOMER_FOR_LATER_SUCCESS: "SAVE_CUSTOMER_FOR_LATER_SUCCESS",
  SAVE_CUSTOMER_FOR_LATER_FAIL: "SAVE_CUSTOMER_FOR_LATER_FAIL",
  CLEAR_SAVE_FOR_LATER_INFO: "CLEAR_SAVE_FOR_LATER_INFO",

  SET_AUTH_CODES_LOADING: "SET_AUTH_CODES_LOADING",

  SET_CUSTOMER_BILLABLE_TIME: "SET_CUSTOMER_BILLABLE_TIME",
  SET_CUSTOMERS_BILLABLE_TIME_PERIOD: "SET_CUSTOMERS_BILLABLE_TIME_PERIOD",

  CREATE_UNSIGNED_DOCUMENT_REQUEST: "CREATE_UNSIGNED_DOCUMENT_REQUEST",
  CREATE_UNSIGNED_DOCUMENT_REQUEST_SUCCESS: "CREATE_UNSIGNED_DOCUMENT_REQUEST_SUCCESS",
  CREATE_UNSIGNED_DOCUMENT_REQUEST_FAIL: "CREATE_UNSIGNED_DOCUMENT_REQUEST_FAIL",
  CLEAR_DOCUMENT_REQUEST_SUCCESS: "CLEAR_DOCUMENT_REQUEST_SUCCESS",
  GET_ALL_DOCUMENT_REQUESTS: "GET_ALL_DOCUMENT_REQUESTS",
  GET_ALL_DOCUMENT_REQUESTS_SUCCESS: "GET_ALL_DOCUMENT_REQUESTS_SUCCESS",
  GET_ALL_DOCUMENT_REQUESTS_FAIL: "GET_ALL_DOCUMENT_REQUESTS_FAIL",

  GET_SINGLE_CUSTOMER_SEARCH: "GET_SINGLE_CUSTOMER_SEARCH",
  GET_SINGLE_CUSTOMER_SEARCH_SUCCESS: "GET_SINGLE_CUSTOMER_SEARCH_SUCCESS",
  GET_SINGLE_CUSTOMER_SEARCH_FAIL: "GET_SINGLE_CUSTOMER_SEARCH_FAIL",
  CLEAR_CUSTOMER_SEARCH: "CLEAR_CUSTOMER_SEARCH",

  GET_CUSTOMER_DEACTIVATION_REASONS: "GET_CUSTOMER_DEACTIVATION_REASONS",
  GET_CUSTOMER_DEACTIVATION_REASONS_SUCCESS: "GET_CUSTOMER_DEACTIVATION_REASONS_SUCCESS",

  GET_CUSTOMER_DEPARTURE_OPTIONS: "GET_CUSTOMER_DEPARTURE_OPTIONS",
  GET_CUSTOMER_DEPARTURE_OPTIONS_SUCCESS: "GET_CUSTOMER_DEPARTURE_OPTIONS_SUCCESS",

  GET_CUSTOMER_STATUS_UPDATES: "GET_CUSTOMER_STATUS_UPDATES",
  GET_CUSTOMER_STATUS_UPDATES_SUCCESS: "GET_CUSTOMER_STATUS_UPDATES_SUCCESS",
  GET_CUSTOMER_STATUS_UPDATES_FAIL: "GET_CUSTOMER_STATUS_UPDATES_FAIL",

  GET_SAVED_FOR_LATER_CUSTOMERS: "GET_SAVED_FOR_LATER_CUSTOMERS",
  GET_SAVED_FOR_LATER_CUSTOMERS_SUCCESS: "GET_SAVED_FOR_LATER_CUSTOMERS_SUCCESS",
  GET_SAVED_FOR_LATER_CUSTOMERS_FAIL: "GET_SAVED_FOR_LATER_CUSTOMERS_FAIL",

  GET_SAVED_CUSTOMER_DETAILS: "GET_SAVED_CUSTOMER_DETAILS",
  GET_SAVED_CUSTOMER_DETAILS_SUCCESS: "GET_SAVED_CUSTOMER_DETAILS_SUCCESS",
  GET_SAVED_CUSTOMER_DETAILS_FAIL: "GET_SAVED_CUSTOMER_DETAILS_FAIL",

  SAVE_LEAD: "SAVE_LEAD",
  SAVE_LEAD_SUCCESS: "SAVE_LEAD_SUCCESS",
  SAVE_LEAD_FAIL: "SAVE_LEAD_FAIL",

  UPDATE_PATIENT_INFO: "UPDATE_PATIENT_INFO",
  UPDATE_PATIENT_INFO_SUCCESS: "UPDATE_PATIENT_INFO_SUCCESS",
  UPDATE_PATIENT_INFO_FAIL: "UPDATE_PATIENT_INFO_FAIL",

  UPDATE_GUARDIAN_INFO: "UPDATE_GUARDIAN_INFO",
  UPDATE_GUARDIAN_INFO_SUCCESS: "UPDATE_GUARDIAN_INFO_SUCCESS",
  UPDATE_GUARDIAN_INFO_FAIL: "UPDATE_GUARDIAN_INFO_FAIL",

  SAVE_CUSTOMER_PHYSICIAN: "SAVE_CUSTOMER_PHYSICIAN",
  SAVE_CUSTOMER_PHYSICIAN_SUCCESS: "SAVE_CUSTOMER_PHYSICIAN_SUCCESS",
  SAVE_CUSTOMER_PHYSICIAN_FAIL: "SAVE_CUSTOMER_PHYSICIAN_FAIL",

  UPDATE_CUSTOMER_PHYSICIAN: "UPDATE_CUSTOMER_PHYSICIAN",
  UPDATE_CUSTOMER_PHYSICIAN_SUCCESS: "UPDATE_CUSTOMER_PHYSICIAN_SUCCESS",
  UPDATE_CUSTOMER_PHYSICIAN_FAIL: "UPDATE_CUSTOMER_PHYSICIAN_FAIL",

  UPDATE_INTAKE_HISTORY_INFO: "UPDATE_INTAKE_HISTORY_INFO",
  UPDATE_INTAKE_HISTORY_INFO_SUCCESS: "UPDATE_INTAKE_HISTORY_INFO_SUCCESS",
  UPDATE_INTAKE_HISTORY_INFO_FAIL: "UPDATE_INTAKE_HISTORY_INFO_FAIL",

  GET_CUSTOMER_BILLABLE_ITEMS: "GET_CUSTOMER_BILLABLE_ITEMS",
  GET_CUSTOMER_BILLABLE_ITEMS_SUCCESS: "GET_CUSTOMER_BILLABLE_ITEMS_SUCCESS",
  GET_CUSTOMER_BILLABLE_ITEMS_FAIL: "GET_CUSTOMER_BILLABLE_ITEMS_FAIL",
  SET_CUSTOMER_BILLABLE_ITEMS_PAGE: "SET_CUSTOMER_BILLABLE_ITEMS_PAGE",
  SET_CUSTOMER_BILLABLE_ITEMS_ROWS_PER_PAGE: "SET_CUSTOMER_BILLABLE_ITEMS_ROWS_PER_PAGE",

  SWITCH_PRIMARY_ACCOUNT_HOLDER: "SWITCH_PRIMARY_ACCOUNT_HOLDER",
  SWITCH_PRIMARY_ACCOUNT_HOLDER_SUCCESS: "SWITCH_PRIMARY_ACCOUNT_HOLDER_SUCCESS",
  SWITCH_PRIMARY_ACCOUNT_HOLDER_FAIL: "SWITCH_PRIMARY_ACCOUNT_HOLDER_FAIL",

  UPDATE_CUSTOMER_EMAIL: "UPDATE_CUSTOMER_EMAIL",
  UPDATE_CUSTOMER_EMAIL_SUCCESS: "UPDATE_CUSTOMER_EMAIL_SUCCESS",
  UPDATE_CUSTOMER_EMAIL_FAIL: "UPDATE_CUSTOMER_EMAIL_FAIL",

  UPDATE_CUSTOMER_TIMEZONE: "UPDATE_CUSTOMER_TIMEZONE",
  UPDATE_CUSTOMER_TIMEZONE_SUCCESS: "UPDATE_CUSTOMER_TIMEZONE_SUCCESS",
  UPDATE_CUSTOMER_TIMEZONE_FAIL: "UPDATE_CUSTOMER_TIMEZONE_FAIL",
  CLEAR_TIMEZONE_INFO: "CLEAR_TIMEZONE_INFO",

  GET_CUSTOMER_PAST_CALLS: "GET_CUSTOMER_PAST_CALLS",
  GET_CUSTOMER_PAST_CALLS_SUCCESS: "GET_CUSTOMER_PAST_CALLS_SUCCESS",
  GET_CUSTOMER_PAST_CALLS_FAIL: "GET_CUSTOMER_PAST_CALLS_FAIL",

  ENABLE_CLIENT_STATIC_PAGE: "ENABLE_CLIENT_STATIC_PAGE",
  ENABLE_CLIENT_STATIC_PAGE_SUCCESS: "ENABLE_CLIENT_STATIC_PAGE_SUCCESS",
  ENABLE_CLIENT_STATIC_PAGE_FAIL: "ENABLE_CLIENT_STATIC_PAGE_FAIL",
  DISABLE_CLIENT_STATIC_PAGE: "DISABLE_CLIENT_STATIC_PAGE",
  DISABLE_CLIENT_STATIC_PAGE_SUCCESS: "DISABLE_CLIENT_STATIC_PAGE_SUCCESS",
  DISABLE_CLIENT_STATIC_PAGE_FAIL: "DISABLE_CLIENT_STATIC_PAGE_FAIL",

  GET_CLIENT_TASK_LIST: "GET_CLIENT_TASK_LIST",
  GET_CLIENT_TASK_LIST_SUCCESS: "GET_CLIENT_TASK_LIST_SUCCESS",
  GET_CLIENT_TASK_LIST_FAIL: "GET_CLIENT_TASK_LIST_FAIL",

  MARK_CLIENT_TASK_COMPLETE: "MARK_CLIENT_TASK_COMPLETE",
  MARK_CLIENT_TASK_COMPLETE_SUCCESS: "MARK_CLIENT_TASK_COMPLETE_SUCCESS",
  MARK_CLIENT_TASK_COMPLETE_FAIL: "MARK_CLIENT_TASK_COMPLETE_FAIL",

  GET_CLIENT_VIDEO_SESSION_STATISTICS: "GET_CLIENT_VIDEO_SESSION_STATISTICS",
  GET_CLIENT_VIDEO_SESSION_STATISTICS_SUCCESS: "GET_CLIENT_VIDEO_SESSION_STATISTICS_SUCCESS",
  GET_CLIENT_VIDEO_SESSION_STATISTICS_FAIL: "GET_CLIENT_VIDEO_SESSION_STATISTICS_FAIL",

  GET_CLIENT_CRITICAL_NEEDS: "GET_CLIENT_CRITICAL_NEEDS",
  GET_CLIENT_CRITICAL_NEEDS_SUCCESS: "GET_CLIENT_CRITICAL_NEEDS_SUCCESS",
  GET_CLIENT_CRITICAL_NEEDS_FAIL: "GET_CLIENT_CRITICAL_NEEDS_FAIL",

  SNOOZE_CLIENT_TASK: "SNOOZE_CLIENT_TASK",
  SNOOZE_CLIENT_TASK_SUCCESS: "SNOOZE_CLIENT_TASK_SUCCESS",
  SNOOZE_CLIENT_TASK_FAIL: "SNOOZE_CLIENT_TASK_FAIL",

  UPDATE_LINK_PHYSICIAN: "UPDATE_LINK_PHYSICIAN",
  UPDATE_LINK_PHYSICIAN_SUCCESS: "UPDATE_LINK_PHYSICIAN_SUCCESS",
  UPDATE_LINK_PHYSICIAN_FAIL: "UPDATE_LINK_PHYSICIAN_FAIL",

  GET_ALL_PHYSICIANS: "GET_ALL_PHYSICIANS",
  GET_ALL_PHYSICIANS_SUCCESS: "GET_ALL_PHYSICIANS_SUCCESS",
  GET_ALL_PHYSICIANS_FAIL: "GET_ALL_PHYSICIANS_FAIL",
};

const ACTION_CREATORS = {
  getInsuranceCustomerList() {
    return {
      type: ACTIONS.GET_INSURANCE_CUSTOMER_LIST,
    };
  },
  getInsuranceCustomerListFail() {
    return {
      type: ACTIONS.GET_INSURANCE_CUSTOMER_LIST_FAIL,
    };
  },
  getDeactivatedCustomerList() {
    return {
      type: ACTIONS.GET_DEACTIVATED_CUSTOMER_LIST,
    };
  },
  getDeactivatedCustomerListSuccess(payload) {
    return {
      type: ACTIONS.GET_DEACTIVATED_CUSTOMER_LIST_SUCCESS,
      payload,
    };
  },
  getDeactivatedCustomerListFail() {
    return {
      type: ACTIONS.GET_DEACTIVATED_CUSTOMER_LIST_FAIL,
    };
  },
  loadCustomerList() {
    return {
      type: ACTIONS.LOAD_CUSTOMER_LIST,
    };
  },
  setCustomerList(customers, count) {
    return {
      type: ACTIONS.SET_CUSTOMER_LIST,
      payload: {
        customers,
        count,
      },
    };
  },
  getAllActiveClients(payload) {
    return {
      type: ACTIONS.GET_ALL_ACTIVE_CLIENTS,
      payload,
    };
  },
  getCustomerDetails() {
    return {
      type: ACTIONS.GET_CUSTOMER_DETAILS,
    };
  },
  getCustomerInfo(clientId) {
    return {
      type: ACTIONS.GET_CUSTOMER_INFO,
      payload: {
        clientId,
      },
    };
  },
  getCustomerInfoSuccess(details) {
    return {
      type: ACTIONS.GET_CUSTOMER_INFO_SUCCESS,
      payload: details,
    };
  },
  clearCustomerInfo(userId) {
    return {
      type: ACTIONS.CLEAR_CUSTOMER_INFO,
      payload: userId,
    };
  },
  loadCustomerDetails(clientId) {
    return {
      type: ACTIONS.LOAD_CUSTOMER_DETAILS,
      payload: {
        clientId,
      },
    };
  },
  getCustomerClinicians(clientId) {
    return {
      type: ACTIONS.GET_CUSTOMER_CLINICIANS,
      payload: clientId,
    };
  },
  getCustomerCliniciansSuccess(clinician) {
    return {
      type: ACTIONS.GET_CUSTOMER_CLINICIANS_SUCCESS,
      payload: clinician,
    };
  },
  getCustomerCliniciansFail() {
    return {
      type: ACTIONS.GET_CUSTOMER_CLINICIANS_FAIL,
    };
  },
  getCustomerTreatmentPlans(clientId) {
    return {
      type: ACTIONS.GET_CUSTOMER_TREATMENT_PLANS,
      payload: clientId,
    };
  },
  getCustomerTreatmentPlansSuccess(treatmentPlans) {
    return {
      type: ACTIONS.GET_CUSTOMER_TREATMENT_PLANS_SUCCESS,
      payload: treatmentPlans,
    };
  },
  getCustomerTreatmentPlansFail() {
    return {
      type: ACTIONS.GET_CUSTOMER_TREATMENT_PLANS_FAIL,
    };
  },
  getCustomerDocuments(clientId) {
    return {
      type: ACTIONS.GET_CUSTOMER_DOCUMENTS,
      payload: clientId,
    };
  },
  getCustomerDocumentsSuccess(documents) {
    return {
      type: ACTIONS.GET_CUSTOMER_DOCUMENTS_SUCCESS,
      payload: documents,
    };
  },
  getCustomerDocumentsFail() {
    return {
      type: ACTIONS.GET_CUSTOMER_DOCUMENTS_FAIL,
    };
  },
  getCustomerApprovedUnits(clientId) {
    return {
      type: ACTIONS.GET_CUSTOMER_APPROVED_UNITS,
      payload: clientId,
    };
  },
  getCustomerApprovedUnitsSuccess(approvedUnits) {
    return {
      type: ACTIONS.GET_CUSTOMER_APPROVED_UNITS_SUCCESS,
      payload: approvedUnits,
    };
  },
  getCustomerApprovedUnitsFail() {
    return {
      type: ACTIONS.GET_CUSTOMER_APPROVED_UNITS_FAIL,
    };
  },
  getCustomerPhysicians(clientId) {
    return {
      type: ACTIONS.GET_CUSTOMER_PHYSICIANS,
      payload: clientId,
    };
  },
  getCustomerPhysiciansSuccess(physicians) {
    return {
      type: ACTIONS.GET_CUSTOMER_PHYSICIANS_SUCCESS,
      payload: physicians,
    };
  },
  getCustomerPhysiciansFail() {
    return {
      type: ACTIONS.GET_CUSTOMER_PHYSICIANS_FAIL,
    };
  },
  getCustomerPastClinicians(clientId) {
    return {
      type: ACTIONS.GET_CUSTOMER_PAST_CLINICIANS,
      payload: clientId,
    };
  },
  getCustomerPastCliniciansSuccess(pastClinicians) {
    return {
      type: ACTIONS.GET_CUSTOMER_PAST_CLINICIANS_SUCCESS,
      payload: pastClinicians,
    };
  },
  getCustomerPastCliniciansFail() {
    return {
      type: ACTIONS.GET_CUSTOMER_PAST_CLINICIANS_FAIL,
    };
  },
  setAuthorizationCodesLoading(loading) {
    return {
      type: ACTIONS.SET_AUTH_CODES_LOADING,
      payload: loading,
    };
  },
  setCustomerDetails(payload) {
    return {
      type: ACTIONS.SET_CUSTOMER_DETAILS,
      payload,
    };
  },
  setCustomersPage(page) {
    return {
      type: ACTIONS.SET_CUSTOMERS_PAGE,
      payload: {
        page,
      },
    };
  },
  setCustomersPerPage(rowsPerPage) {
    return {
      type: ACTIONS.SET_CUSTOMERS_PER_PAGE,
      payload: {
        rowsPerPage,
      },
    };
  },
  getCustomersPage() {
    return {
      type: ACTIONS.GET_CUSTOMERS_PAGE,
    };
  },
  getCustomersPerPage() {
    return {
      type: ACTIONS.GET_CUSTOMERS_PER_PAGE,
    };
  },
  getCustomersCount() {
    return {
      type: ACTIONS.GET_CUSTOMERS_COUNT,
    };
  },
  setCustomersSort(payload) {
    return {
      type: ACTIONS.SET_CUSTOMERS_SORT,
      payload,
    };
  },
  setCustomersSearch(searchValue) {
    return {
      type: ACTIONS.SET_CUSTOMERS_SEARCH,
      payload: searchValue,
    };
  },
  setInsuranceCustomersFilter(payload) {
    return {
      type: ACTIONS.SET_INSURANCE_CUSTOMERS_FILTER,
      payload,
    };
  },
  setInsuranceCustomersType(payload) {
    return {
      type: ACTIONS.SET_INSURANCE_CUSTOMERS_TYPE,
      payload,
    };
  },
  setInsuranceCustomersState(payload) {
    return {
      type: ACTIONS.SET_INSURANCE_CUSTOMERS_STATE,
      payload,
    };
  },
  switchClinician(clinicianId) {
    return {
      type: ACTIONS.SWITCH_CLINICIAN,
      payload: {
        clinicianId,
      },
    };
  },
  assignClinician(clientId, clinicianUserId) {
    return {
      type: ACTIONS.ASSIGN_CLINICIAN,
      payload: {
        clientId,
        clinicianUserId,
      },
    };
  },
  assignSecondaryClinician(clientId, clinicianUserId) {
    return {
      type: ACTIONS.ASSIGN_SECONDARY_CLINICIAN,
      payload: {
        clientId,
        clinicianUserId,
      },
    };
  },
  removeSecondaryClinician(clientId) {
    return {
      type: ACTIONS.REMOVE_SECONDARY_CLINICIAN,
      payload: {
        clientId,
      },
    };
  },
  markEligible() {
    return {
      type: ACTIONS.MARK_ELIGIBLE,
    };
  },
  markEligibleSuccess() {
    return {
      type: ACTIONS.MARK_ELIGIBLE_SUCCESS,
    };
  },
  markApproved() {
    return {
      type: ACTIONS.MARK_APPROVED,
    };
  },
  markApprovedSuccess() {
    return {
      type: ACTIONS.MARK_APPROVED_SUCCESS,
    };
  },
  clearCustomers() {
    return { type: ACTIONS.CLEAR_CUSTOMERS };
  },
  uploadTreatmentPlan(plan) {
    return {
      type: ACTIONS.UPLOAD_TREATMENT_PLAN,
      payload: plan,
    };
  },
  uploadTreatmentPlanSuccess() {
    return {
      type: ACTIONS.UPLOAD_TREATMENT_PLAN_SUCCESS,
    };
  },
  updateTreatmentPlan(treatmentPlan) {
    return {
      type: ACTIONS.UPDATE_TREATMENT_PLAN,
      payload: treatmentPlan,
    };
  },
  updateTreatmentPlanSuccess() {
    return {
      type: ACTIONS.UPDATE_TREATMENT_PLAN_SUCCESS,
    };
  },
  uploadCustomerDocument(document) {
    return {
      type: ACTIONS.UPLOAD_CUSTOMER_DOCUMENT,
      payload: document,
    };
  },
  uploadCustomerDocumentSuccess() {
    return {
      type: ACTIONS.UPLOAD_CUSTOMER_DOCUMENT_SUCCESS,
    };
  },
  uploadCustomerDocumentFail() {
    return {
      type: ACTIONS.UPLOAD_CUSTOMER_DOCUMENT_FAIL,
    };
  },
  getDocumentTypes() {
    return {
      type: ACTIONS.GET_DOCUMENT_TYPES,
    };
  },
  getDocumentTypesSuccess(types) {
    return {
      type: ACTIONS.GET_DOCUMENT_TYPES_SUCCESS,
      payload: types,
    };
  },
  getDocumentMatrix(payload) {
    return {
      type: ACTIONS.GET_DOCUMENT_MATRIX,
      payload,
    };
  },
  getDocumentMatrixSuccess(payload) {
    return {
      type: ACTIONS.GET_DOCUMENT_MATRIX_SUCCESS,
      payload,
    };
  },
  getDocumentMatrixFail() {
    return {
      type: ACTIONS.GET_DOCUMENT_MATRIX_FAIL,
    };
  },
  approveCustomerDocument(payload) {
    return {
      type: ACTIONS.APPROVE_CUSTOMER_DOCUMENT,
      payload,
    };
  },
  approveCustomerDocumentSuccess() {
    return {
      type: ACTIONS.APPROVE_CUSTOMER_DOCUMENT_SUCCESS,
    };
  },
  approveCustomerDocumentFail() {
    return {
      type: ACTIONS.APPROVE_CUSTOMER_DOCUMENT_FAIL,
    };
  },
  setCustomerDocumentStatus(payload) {
    return {
      type: ACTIONS.SET_CUSTOMER_DOCUMENT_STATUS,
      payload,
    };
  },
  updateUserInsuranceStatus(status) {
    return {
      type: ACTIONS.UPDATE_USER_INSURANCE_STATUS,
      payload: status,
    };
  },
  updateUserInsuranceStatusSuccess() {
    return {
      type: ACTIONS.UPDATE_USER_INSURANCE_STATUS_SUCCESS,
    };
  },
  updateUserInsuranceStatusFail() {
    return {
      type: ACTIONS.UPDATE_USER_INSURANCE_STATUS_SUCCESS,
    };
  },
  clearUpdateUserInsuranceStatus() {
    return {
      type: ACTIONS.CLEAR_UPDATE_USER_INSURANCE_STATUS,
    };
  },
  resendLoginEmail(userId) {
    return {
      type: ACTIONS.RESEND_LOGIN_EMAIL,
      payload: userId,
    };
  },
  resendLoginEmailSuccess() {
    return {
      type: ACTIONS.RESEND_LOGIN_EMAIL_SUCCESS,
    };
  },
  toggleCustomerActivation(payload) {
    return {
      type: ACTIONS.TOGGLE_CUSTOMER_ACTIVATION,
      payload,
    };
  },
  toggleCustomerActivationSuccess() {
    return {
      type: ACTIONS.TOGGLE_CUSTOMER_ACTIVATION_SUCCESS,
    };
  },
  deactivateClient(payload) {
    return {
      type: ACTIONS.DEACTIVATE_CLIENT,
      payload,
    };
  },
  deactivateClientSuccess() {
    return {
      type: ACTIONS.DEACTIVATE_CLIENT_SUCCESS,
    };
  },
  setSaving() {
    return {
      type: ACTIONS.SET_SAVING,
    };
  },
  createInsuranceCustomer(payload) {
    return {
      type: ACTIONS.CREATE_INSURANCE_CUSTOMER,
      payload,
    };
  },
  createInsuranceCustomerSuccess(payload) {
    return {
      type: ACTIONS.CREATE_INSURANCE_CUSTOMER_SUCCESS,
      payload,
    };
  },
  createInsuranceCustomerFail(error) {
    return {
      type: ACTIONS.CREATE_INSURANCE_CUSTOMER_FAIL,
      payload: error,
    };
  },
  clearCreateCustomer() {
    return {
      type: ACTIONS.CLEAR_CREATE_CUSTOMER,
    };
  },
  saveCustomerForLater(payload) {
    return {
      type: ACTIONS.SAVE_CUSTOMER_FOR_LATER,
      payload,
    };
  },
  saveCustomerForLaterSuccess(payload) {
    return {
      type: ACTIONS.SAVE_CUSTOMER_FOR_LATER_SUCCESS,
      payload,
    };
  },
  saveCustomerForLaterFail(payload) {
    return {
      type: ACTIONS.SAVE_CUSTOMER_FOR_LATER_FAIL,
      payload,
    };
  },
  clearSaveForLaterInfo() {
    return {
      type: ACTIONS.CLEAR_SAVE_FOR_LATER_INFO,
    };
  },
  setCustomersBillableTimePeriod(payload) {
    return {
      type: ACTIONS.SET_CUSTOMERS_BILLABLE_TIME_PERIOD,
      payload,
    };
  },
  setCustomerBillableTime(payload) {
    return {
      type: ACTIONS.SET_CUSTOMER_BILLABLE_TIME,
      payload,
    };
  },
  createUnsignedDocumentRequest(payload) {
    return {
      type: ACTIONS.CREATE_UNSIGNED_DOCUMENT_REQUEST,
      payload,
    };
  },
  createUnsignedDocumentRequestSuccess() {
    return {
      type: ACTIONS.CREATE_UNSIGNED_DOCUMENT_REQUEST_SUCCESS,
    };
  },
  createUnsignedDocumentRequestFail() {
    return {
      type: ACTIONS.CREATE_UNSIGNED_DOCUMENT_REQUEST_FAIL,
    };
  },
  clearDocumentRequestSuccess() {
    return {
      type: ACTIONS.CLEAR_DOCUMENT_REQUEST_SUCCESS,
    };
  },
  getAllDocumentRequests(clientId) {
    return {
      type: ACTIONS.GET_ALL_DOCUMENT_REQUESTS,
      payload: clientId,
    };
  },
  getAllDocumentRequestsSuccess(payload) {
    return {
      type: ACTIONS.GET_ALL_DOCUMENT_REQUESTS_SUCCESS,
      payload,
    };
  },
  getAllDocumentRequestsFail() {
    return {
      type: ACTIONS.GET_ALL_DOCUMENT_REQUESTS_FAIL,
    };
  },
  getSingleCustomerSearch(payload) {
    return {
      type: ACTIONS.GET_SINGLE_CUSTOMER_SEARCH,
      payload,
    };
  },
  getSingleCustomerSearchSuccess(payload) {
    return {
      type: ACTIONS.GET_SINGLE_CUSTOMER_SEARCH_SUCCESS,
      payload,
    };
  },
  getSingleCustomerSearchFail() {
    return {
      type: ACTIONS.GET_SINGLE_CUSTOMER_SEARCH_FAIL,
    };
  },
  clearCustomerSearch() {
    return {
      type: ACTIONS.CLEAR_CUSTOMER_SEARCH,
    };
  },
  getCustomerDeactivationReasons() {
    return {
      type: ACTIONS.GET_CUSTOMER_DEACTIVATION_REASONS,
    };
  },
  getCustomerDeactivationReasonsSuccess(payload) {
    return {
      type: ACTIONS.GET_CUSTOMER_DEACTIVATION_REASONS_SUCCESS,
      payload,
    };
  },
  getCustomerDepartureOptions() {
    return {
      type: ACTIONS.GET_CUSTOMER_DEPARTURE_OPTIONS,
    };
  },
  getCustomerDepartureOptionsSuccess(payload) {
    return {
      type: ACTIONS.GET_CUSTOMER_DEPARTURE_OPTIONS_SUCCESS,
      payload,
    };
  },
  getCustomerStatusUpdates(payload) {
    return {
      type: ACTIONS.GET_CUSTOMER_STATUS_UPDATES,
      payload,
    };
  },
  getCustomerStatusUpdatesSuccess(payload) {
    return {
      type: ACTIONS.GET_CUSTOMER_STATUS_UPDATES_SUCCESS,
      payload,
    };
  },
  getCustomerStatusUpdatesFail() {
    return {
      type: ACTIONS.GET_CUSTOMER_STATUS_UPDATES_FAIL,
    };
  },
  getSavedForLaterCustomers() {
    return {
      type: ACTIONS.GET_SAVED_FOR_LATER_CUSTOMERS,
    };
  },
  getSavedForLaterCustomersSuccess(customers, count) {
    return {
      type: ACTIONS.GET_SAVED_FOR_LATER_CUSTOMERS_SUCCESS,
      payload: {
        customers,
        count,
      },
    };
  },
  getSavedForLaterCustomersFail() {
    return {
      type: ACTIONS.GET_SAVED_FOR_LATER_CUSTOMERS_FAIL,
    };
  },
  getSavedCustomerDetails(userId) {
    return {
      type: ACTIONS.GET_SAVED_CUSTOMER_DETAILS,
      payload: userId,
    };
  },
  getSavedCustomerDetailsSuccess(payload) {
    return {
      type: ACTIONS.GET_SAVED_CUSTOMER_DETAILS_SUCCESS,
      payload,
    };
  },
  getSavedCustomerDetailsFail() {
    return {
      type: ACTIONS.GET_SAVED_CUSTOMER_DETAILS_FAIL,
    };
  },
  saveLead(customer) {
    return {
      type: ACTIONS.SAVE_LEAD,
      payload: customer,
    };
  },
  saveLeadSuccess() {
    return {
      type: ACTIONS.SAVE_LEAD_SUCCESS,
    };
  },
  saveLeadFail() {
    return {
      type: ACTIONS.SAVE_LEAD_FAIL,
    };
  },
  updatePatientInfo(payload) {
    return {
      type: ACTIONS.UPDATE_PATIENT_INFO,
      payload,
    };
  },
  updatePatientInfoSuccess() {
    return {
      type: ACTIONS.UPDATE_PATIENT_INFO_SUCCESS,
    };
  },
  updatePatientInfoFail() {
    return {
      type: ACTIONS.UPDATE_PATIENT_INFO_FAIL,
    };
  },
  updateGuardianInfo(payload) {
    return {
      type: ACTIONS.UPDATE_GUARDIAN_INFO,
      payload,
    };
  },
  updateGuardianInfoSuccess() {
    return {
      type: ACTIONS.UPDATE_GUARDIAN_INFO_SUCCESS,
    };
  },
  updateGuardianInfoFail() {
    return {
      type: ACTIONS.UPDATE_GUARDIAN_INFO_FAIL,
    };
  },
  saveCustomerPhysician(payload) {
    return {
      type: ACTIONS.SAVE_CUSTOMER_PHYSICIAN,
      payload,
    };
  },
  saveCustomerPhysicianSuccess() {
    return {
      type: ACTIONS.SAVE_CUSTOMER_PHYSICIAN_SUCCESS,
    };
  },
  saveCustomerPhysicianFail() {
    return {
      type: ACTIONS.SAVE_CUSTOMER_PHYSICIAN_FAIL,
    };
  },
  updateCustomerPhysician(payload) {
    return {
      type: ACTIONS.UPDATE_CUSTOMER_PHYSICIAN,
      payload,
    };
  },
  updateCustomerPhysicianSuccess() {
    return {
      type: ACTIONS.UPDATE_CUSTOMER_PHYSICIAN_SUCCESS,
    };
  },
  updateCustomerPhysicianFail() {
    return {
      type: ACTIONS.UPDATE_CUSTOMER_PHYSICIAN_FAIL,
    };
  },
  getCustomerBillableItems(payload) {
    return {
      type: ACTIONS.GET_CUSTOMER_BILLABLE_ITEMS,
      payload,
    };
  },
  getCustomerBillableItemsSuccess(billableItems, count) {
    return {
      type: ACTIONS.GET_CUSTOMER_BILLABLE_ITEMS_SUCCESS,
      payload: { billableItems, count },
    };
  },
  getCustomerBillableItemsFail() {
    return {
      type: ACTIONS.GET_CUSTOMER_BILLABLE_ITEMS_FAIL,
    };
  },
  setCustomerBillableItemsPage(payload) {
    return {
      type: ACTIONS.SET_CUSTOMER_BILLABLE_ITEMS_PAGE,
      payload,
    };
  },
  setCustomerBillableItemsRowsPerPage(payload) {
    return {
      type: ACTIONS.SET_CUSTOMER_BILLABLE_ITEMS_ROWS_PER_PAGE,
      payload,
    };
  },
  switchPrimaryAccountHolder(payload) {
    return {
      type: ACTIONS.SWITCH_PRIMARY_ACCOUNT_HOLDER,
      payload,
    };
  },
  switchPrimaryAccountHolderSuccess() {
    return {
      type: ACTIONS.SWITCH_PRIMARY_ACCOUNT_HOLDER_SUCCESS,
    };
  },
  switchPrimaryAccountHolderFail() {
    return {
      type: ACTIONS.SWITCH_PRIMARY_ACCOUNT_HOLDER_FAIL,
    };
  },
  updateCustomerEmail(payload) {
    return {
      type: ACTIONS.UPDATE_CUSTOMER_EMAIL,
      payload,
    };
  },
  updateCustomerEmailSuccess(payload) {
    return {
      type: ACTIONS.UPDATE_CUSTOMER_EMAIL_SUCCESS,
      payload,
    };
  },
  updateCustomerEmailFail(payload) {
    return {
      type: ACTIONS.UPDATE_CUSTOMER_EMAIL_FAIL,
      payload,
    };
  },
  updateCustomerTimezone(payload) {
    return {
      type: ACTIONS.UPDATE_CUSTOMER_TIMEZONE,
      payload,
    };
  },
  updateCustomerTimezoneSuccess(payload) {
    return {
      type: ACTIONS.UPDATE_CUSTOMER_TIMEZONE_SUCCESS,
      payload,
    };
  },
  updateCustomerTimezoneFail(payload) {
    return {
      type: ACTIONS.UPDATE_CUSTOMER_TIMEZONE_FAIL,
      payload,
    };
  },
  clearTimezoneInfo() {
    return {
      type: ACTIONS.CLEAR_TIMEZONE_INFO,
    };
  },
  getCustomerPastCalls(userInfo) {
    return {
      type: ACTIONS.GET_CUSTOMER_PAST_CALLS,
      payload: userInfo,
    };
  },
  getCustomerPastCallsSuccess(pastCalls) {
    return {
      type: ACTIONS.GET_CUSTOMER_PAST_CALLS_SUCCESS,
      payload: pastCalls,
    };
  },
  getCustomerPastCallsFail() {
    return {
      type: ACTIONS.GET_CUSTOMER_PAST_CALLS_FAIL,
    };
  },
  enableClientStaticPage(payload) {
    return {
      type: ACTIONS.ENABLE_CLIENT_STATIC_PAGE,
      payload,
    };
  },
  enableClientStaticPageSuccess() {
    return {
      type: ACTIONS.ENABLE_CLIENT_STATIC_PAGE_SUCCESS,
    };
  },
  enableClientStaticPageFail() {
    return {
      type: ACTIONS.ENABLE_CLIENT_STATIC_PAGE_FAIL,
    };
  },
  disableClientStaticPage(payload) {
    return {
      type: ACTIONS.DISABLE_CLIENT_STATIC_PAGE,
      payload,
    };
  },
  disableClientStaticPageSuccess() {
    return {
      type: ACTIONS.DISABLE_CLIENT_STATIC_PAGE_SUCCESS,
    };
  },
  disableClientStaticPageFail() {
    return {
      type: ACTIONS.DISABLE_CLIENT_STATIC_PAGE_FAIL,
    };
  },
  getClientTaskList(clientId) {
    return {
      type: ACTIONS.GET_CLIENT_TASK_LIST,
      payload: clientId,
    };
  },
  getClientTaskListSuccess(payload) {
    return {
      type: ACTIONS.GET_CLIENT_TASK_LIST_SUCCESS,
      payload,
    };
  },
  getClientTaskListFail() {
    return {
      type: ACTIONS.GET_CLIENT_TASK_LIST_FAIL,
    };
  },
  markClientTaskComplete(payload) {
    return {
      type: ACTIONS.MARK_CLIENT_TASK_COMPLETE,
      payload,
    };
  },
  markClientTaskCompleteSuccess() {
    return {
      type: ACTIONS.MARK_CLIENT_TASK_COMPLETE_SUCCESS,
    };
  },
  markClientTaskCompleteFail() {
    return {
      type: ACTIONS.MARK_CLIENT_TASK_COMPLETE_FAIL,
    };
  },
  getClientVideoSessionStatistics(payload) {
    return {
      type: ACTIONS.GET_CLIENT_VIDEO_SESSION_STATISTICS,
      payload,
    };
  },
  getClientVideoSessionStatisticsSuccess(payload) {
    return {
      type: ACTIONS.GET_CLIENT_VIDEO_SESSION_STATISTICS_SUCCESS,
      payload,
    };
  },
  getClientVideoSessionStatisticsFail() {
    return {
      type: ACTIONS.GET_CLIENT_VIDEO_SESSION_STATISTICS_FAIL,
    };
  },
  getClientCriticalNeeds() {
    return {
      type: ACTIONS.GET_CLIENT_CRITICAL_NEEDS,
    };
  },
  getClientCriticalNeedsSuccess(payload) {
    return {
      type: ACTIONS.GET_CLIENT_CRITICAL_NEEDS_SUCCESS,
      payload,
    };
  },
  getClientCriticalNeedsFail() {
    return {
      type: ACTIONS.GET_CLIENT_CRITICAL_NEEDS_FAIL,
    };
  },
  snoozeClientTask(payload) {
    return {
      type: ACTIONS.SNOOZE_CLIENT_TASK,
      payload,
    };
  },
  snoozeClientTaskSuccess() {
    return {
      type: ACTIONS.SNOOZE_CLIENT_TASK_SUCCESS,
    };
  },
  snoozeClientTaskFail() {
    return {
      type: ACTIONS.SNOOZE_CLIENT_TASK_FAIL,
    };
  },
  updateLinkPhysician(payload) {
    return {
      type: ACTIONS.UPDATE_LINK_PHYSICIAN,
      payload,
    };
  },
  updateLinkPhysicianSuccess(payload) {
    return {
      type: ACTIONS.UPDATE_LINK_PHYSICIAN_SUCCESS,
      payload,
    };
  },
  updateLinkPhysicianFail(payload) {
    return {
      type: ACTIONS.UPDATE_LINK_PHYSICIAN_FAIL,
      payload,
    };
  },
  getAllPhysicians() {
    return {
      type: ACTIONS.GET_ALL_PHYSICIANS,
    };
  },
  getAllPhysiciansSuccess(payload) {
    return {
      type: ACTIONS.GET_ALL_PHYSICIANS_SUCCESS,
      payload,
    };
  },
  getAllPhysiciansFail() {
    return {
      type: ACTIONS.GET_ALL_PHYSICIANS_FAIL,
    };
  },
};

export default { ...ACTIONS, ...ACTION_CREATORS };
