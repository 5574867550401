export default (theme) => ({
  pageContainer: {
    width: "100%",
    minWidth: "100vh-64px",
    position: "relative",
    background: "linear-gradient(#7235a2, #a288d3)",
  },
  loadingContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  sessionsContainer: {
    backgroundColor: "#fff",
    width: "92%",
    margin: "auto",
    padding: "60px 0px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("xl")]: {
      width: "96%",
      padding: "40px 0px",
    },
    [theme.breakpoints.down("xl")]: {
      width: "98%",
      padding: "30px 0px",
    },
  },
  leftBlock: {
    position: "absolute",
    left: 0,
    top: 152,
    width: "4%",
    height: 50,
    backgroundColor: "rgba(225,227,233,0.35)",
    [theme.breakpoints.down("xl")]: {
      top: 92,
      width: "1%",
    },
  },
  rightBlock: {
    position: "absolute",
    right: 0,
    top: 152,
    width: "4%",
    height: 50,
    backgroundColor: "rgba(225,227,233,0.35)",
    [theme.breakpoints.down("xl")]: {
      top: 92,
      width: "1%",
    },
  },
  pageTitle: {
    fontSize: 28,
    fontWeight: 500,
    marginLeft: 60,
    [theme.breakpoints.down("xl")]: {
      marginLeft: 40,
    },
    [theme.breakpoints.down("xl")]: {
      marginLeft: 20,
    },
  },
  pageSubTitle: {
    fontWeight: 500,
    marginLeft: 60,
    [theme.breakpoints.down("xl")]: {
      marginLeft: 40,
    },
    [theme.breakpoints.down("xl")]: {
      marginLeft: 20,
    },
  },
  checkboxContainer: {
    width: "100%",
    height: 50,
    backgroundColor: "rgba(229,230,233,0.8)",
    display: "flex",
    alignItems: "center",
    margin: "50px 0",
    paddingLeft: 60,
    "& p": {
      fontSize: 16,
      fontWeight: 500,
      color: "#000",
      marginRight: 30,
    },
    [theme.breakpoints.down("xl")]: {
      margin: "40px 0",
      paddingLeft: 40,
    },
    [theme.breakpoints.down("xl")]: {
      margin: "20px 0 30px",
      padding: 25,
    },
  },
  checkboxLabel: {
    fontSize: 15,
    fontWeight: 500,
    color: "#15171d",
  },
  pastCallsContainer: {
    width: "88%",
    margin: "20px auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    [theme.breakpoints.down("xl")]: {
      width: "100%",
      padding: "0 20px 0 70px",
    },
  },
  tabContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    "& h5": {
      width: 260,
      fontSize: 17,
      fontWeight: 500,
    },
    [theme.breakpoints.down("xl")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      marginBottom: 10,
      "& h5": {
        marginBottom: 5,
      },
    },
  },
  monthsTab: {
    width: "70%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 40,
    [theme.breakpoints.down("xl")]: {
      flexWrap: "wrap",
      gap: 20,
      width: "100%",
      justifyContent: "center",
      marginTop: 10,
    },
  },
  month: {
    width: "fit-content",
    minWidth: 85,
    height: 37,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0 2px 5px rgba(51,51,51,.25)",
    border: "0.25px solid #f0f1f4",
    backgroundColor: "#fafafa",
    borderRadius: 5,
    cursor: "pointer",
    "& p": {
      fontSize: 14,
      fontWeight: 500,
      textTransform: "uppercase",
      whiteSpace: "nowrap",
    },
  },
  selectedMonth: {
    width: "fit-content",
    minWidth: 85,
    height: 37,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0 2px 5px rgba(51,51,51,.25)",
    borderRadius: 5,
    backgroundColor: "#e1e3e9",
    border: "0.5px solid #a5aabc",
    cursor: "pointer",
    "& p": {
      color: "#15171d",
      fontSize: 14,
      fontWeight: 500,
      textTransform: "uppercase",
      whiteSpace: "nowrap",
    },
  },
  timelineContainer: {},
  timelineRoot: {
    width: "100%",
    padding: 0,
    marginTop: 30,
  },
  timelineItem: {
    "& .MuiTimelineContent-root": {
      flex: "none",
      width: 170,
      textAlign: "left",
      paddingLeft: 0,
      paddingTop: 0,
      fontSize: 18,
      fontWeight: 500,
      letterSpacing: "-0.03em",
      marginTop: -9,
    },
    "& .MuiTimelineOppositeContent-root": {
      marginLeft: 70,
      marginBottom: 50,
    },
    "& 	.MuiTimelineSeparator-root": {
      "& span": {
        borderWidth: 1,
        width: 1,
      },
    },
    "& .MuiTimelineDot-root": {
      marginTop: "-1px",
      marginBottom: "-1px",
    },
    [theme.breakpoints.down("xl")]: {
      "& .MuiTimelineContent-root": {
        width: 150,
        fontSize: 17,
      },
      "& .MuiTimelineOppositeContent-root": {
        marginLeft: 50,
        marginBottom: 30,
      },
    },
    [theme.breakpoints.down("xl")]: {
      "& .MuiTimelineContent-root": {
        width: 100,
        fontSize: 15,
        paddingRight: 5,
      },
      "& .MuiTimelineOppositeContent-root": {
        marginLeft: 10,
      },
    },
    [theme.breakpoints.down("lg")]: {
      "& .MuiTimelineContent-root": {
        fontSize: 17,
        width: "100%",
      },
      "& .MuiTimelineOppositeContent-root": {
        marginLeft: 0,
      },
      // "& 	.MuiTimelineSeparator-root": {
      //     display: "none",
      // },
      // "& .MuiTimelineDot-root": {
      //     display: "none",
      // },
    },
  },
  timelineContentDesktop: {
    display: "block",
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },
  timelineContentMobile: {
    display: "block",
    marginTop: "-14px !important",
    marginBottom: 5,
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  callInfoContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
    rowGap: 35,
    columnGap: 30,
    [theme.breakpoints.down("xl")]: {
      rowGap: 25,
      columnGap: 20,
    },
    [theme.breakpoints.down("xl")]: {
      rowGap: 15,
      columnGap: 15,
      "& button": {
        display: "none",
      },
    },
  },
  callBlock: {
    width: 330,
    height: 140,
    display: "flex",
    backgroundColor: "#f0f1f4",
    borderRadius: 3,
    boxShadow: "0px 4px 4px rgba(0,0,0,0.25)",
    [theme.breakpoints.down("xl")]: {
      width: 300,
      height: 120,
    },
    [theme.breakpoints.down("xl")]: {
      width: 260,
      height: "fit-content",
      minHeight: 120,
    },
    [theme.breakpoints.down("lg")]: {
      width: "100%",
      height: "fit-content",
      minHeight: 120,
      flexDirection: "column",
    },
  },
  missedCallBlock: {
    width: 330,
    height: 140,
    display: "flex",
    borderRadius: 3,
    boxShadow: "0px 4px 4px rgba(0,0,0,0.25)",
    background:
      "repeating-linear-gradient(-55deg, rgba(105,113,143,0.25) 0 12px, rgba(105,113,143,0.45)  4px 16px)",
    [theme.breakpoints.down("xl")]: {
      width: 300,
      height: 120,
    },
    [theme.breakpoints.down("xl")]: {
      width: 260,
      height: "fit-content",
      minHeight: 120,
    },
    [theme.breakpoints.down("lg")]: {
      width: "100%",
      height: "fit-content",
      minHeight: 120,
      flexDirection: "column",
    },
  },
  callDate: {
    width: "25%",
    display: "flex",
    padding: "25px 5px 35px",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    "& p": {
      fontSize: 22,
      fontWeight: 500,
      textTransform: "uppercase",
    },
    [theme.breakpoints.down("xl")]: {
      padding: "20px 5px 25px",
      width: "fit-content",
      "& p": {
        fontSize: 18,
      },
    },
    [theme.breakpoints.down("lg")]: {
      width: "100%",
      flexDirection: "row",
      justifyContent: "center",
      padding: "10px 0 0 0",
      "& h3": {
        margin: "0 10px",
      },
    },
  },
  callInfo: {
    // width: "75%",
    padding: "25px 10px 15px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    [theme.breakpoints.down("xl")]: {
      padding: "20px 10px 10px",
      flex: 1,
    },
    [theme.breakpoints.down("lg")]: {
      padding: "10px",
      flex: 1,
    },
  },
  billingType: {
    fontSize: 18,
    lineHeight: 1.2,
    color: "#1517d",
    fontWeight: 500,
    letterSpacing: "-0.03em",
  },
  clinicianName: {
    fontSize: 15,
    lineHeight: 1.2,
    color: "#2a2d39",
    fontWeight: 500,
    margin: "10px 0",
  },
  infoContainer: {
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "& button": {
      marginBottom: 30,
      fontSize: 16,
    },
    "& p": {
      color: "#3f4456",
      fontSize: 16,
      fontWeight: 500,
    },
    [theme.breakpoints.down("xl")]: {
      "& button": {
        display: "none",
      },
    },
  },
  pastSessionsContainer: {
    position: "relative",
    backgroundColor: "#fafafa",
    boxShadow: "0px 4px 6px -1px rgba(55,55,55,0.15)",
    borderRadius: 5,
    width: "90%",
    margin: "auto",
    padding: "60px 0",
    display: "flex",
    flexDirection: "column",
    marginBottom: 50,
  },
  goBackButton: {
    position: "absolute",
    top: 0,
    left: 20,
    "& span": {
      textTransform: "none",
      fontSize: 15,
      fontWeight: 500,
    },
  },
  tableContainer: {
    padding: "0px 150px",
  },
  tableRow: {
    "& td,th": {
      paddingLeft: 90,
    },
  },
  tableHead: {
    "& th": {
      fontSize: 17,
    },
  },
});
