const ACTIONS = {
  SET_CHAT_DETAILS: "SET_CHAT_DETAILS",
  GET_CHAT_CONNECTION: "GET_CHAT_CONNECTION",
  GET_CHAT_CONNECTION_SUCCESS: "GET_CHAT_CONNECTION_SUCCESS",
  DISCONNECT_CHAT_CONNECTION: "DISCONNECT_CHAT_CONNECTION",
  DISCONNECT_CHAT_CONNECTION_SUCCESS: "DISCONNECT_CHAT_CONNECTION_SUCCESS",
  SEND_MESSAGE: "SEND_MESSAGE",
  LOG_GROUP_MESSAGE: "LOG_GROUP_MESSAGE",
  SET_CURRENT_CHANNEL_URL: "SET_CURRENT_CHANNEL_URL",
};

const ACTION_CREATORS = {
  setChatDetails(payload) {
    return {
      type: ACTIONS.SET_CHAT_DETAILS,
      payload,
    };
  },
  getChatConnection() {
    return { type: ACTIONS.GET_CHAT_CONNECTION };
  },
  getChatConnectionSuccess(connection) {
    return {
      type: ACTIONS.GET_CHAT_CONNECTION_SUCCESS,
      payload: {
        connection,
      },
    };
  },
  disconnectChatConnection() {
    return { type: ACTIONS.DISCONNECT_CHAT_CONNECTION };
  },
  disconnectChatConnectionSuccess() {
    return { type: ACTIONS.DISCONNECT_CHAT_CONNECTION_SUCCESS };
  },
  sendMessage(userId, params) {
    return {
      type: ACTIONS.SEND_MESSAGE,
      payload: {
        userId,
        params,
      },
    };
  },
  logGroupMessage(params) {
    return {
      type: ACTIONS.LOG_GROUP_MESSAGE,
      payload: {
        params,
      },
    };
  },
  setCurrentChannelUrl(channelUrl) {
    return {
      type: ACTIONS.SET_CURRENT_CHANNEL_URL,
      payload: channelUrl,
    };
  },
};

export default { ...ACTIONS, ...ACTION_CREATORS };
