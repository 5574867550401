import ClientSignatureBG from "../../../assets/client_signature_bg.png";

export default (theme) => ({
  container: {
    width: "100%",
    height: "100vh",
    padding: "20px 100px",
    zIndex: 3000,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#fff",
    backgroundImage: `url(${ClientSignatureBG})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    position: "fixed",
    overflowY: "auto",
    top: 0,
    left: 0,
    [theme.breakpoints.down("xl")]: {
      padding: "50px 15px 10px",
    },
  },
  innerContainer: {
    width: "100%",
    height: "fit-content",
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    width: 180,
    height: "auto",
    marginBottom: 20,
    alignSelf: "center",
  },
  termsMainTitle: {
    width: "100%",
    fontSize: 16,
    fontWeight: 500,
    textAlign: "center",
    color: "#fff",
    padding: 20,
    [theme.breakpoints.down("xl")]: {
      fontSize: 14,
    },
  },
  signaturesMessage: {
    maxWidth: 700,
    color: "#fff",
    textAlign: "center",
    [theme.breakpoints.down("xl")]: {
      fontSize: 15,
      maxWidth: "95%",
    },
  },
  signaturesContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: 24,
    borderRadius: 4,
    backgroundColor: "#F0F1F4",
    "& h5": {
      fontSize: 17,
      fontWeight: 500,
      color: "#15171D",
      marginBottom: 10,
    },
    [theme.breakpoints.down("xl")]: {
      flexDirection: "column",
      padding: 16,
      "& h5": {
        fontSize: 15,
        fontWeight: 500,
        color: "#15171D",
        marginBottom: 10,
      },
    },
  },
  signatureCanvasContainer: {
    margin: "8px 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    "& p": {
      color: "#fff",
      fontWeight: 500,
    },
  },
  signatureCanvas: {
    width: "100%",
    backgroundColor: "#fff",
    borderRadius: 4,
    border: "1px solid #A5AABC",
    "& canvas": {
      width: "100%",
      maxWidth: "100%",
      height: 120,
    },
  },
  sigError: {
    fontSize: 15,
    fontWeight: "500",
    color: "#B91C1C",
    marginTop: 5,
  },
  signatureStartEndTimesContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    [theme.breakpoints.down("xl")]: {
      flexDirection: "column",
    },
  },
  signatureStartEndTimes: {
    width: "50%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    "& p": {
      fontSize: 15,
      fontWeight: "500",
      marginRight: 24,
      color: "#69718F",
    },
    "& h6": {
      fontSize: 17,
      fontWeight: "400",
      color: "#15171D",
    },
    [theme.breakpoints.down("xl")]: {
      width: "100%",
      "& p": {
        fontSize: 13,
        fontWeight: "500",
        marginRight: 24,
        color: "#69718F",
      },
      "& h6": {
        fontSize: 15,
        fontWeight: "400",
        color: "#15171D",
      },
    },
  },
  clientSignatureTitle: {
    width: "100%",
    fontSize: "24px",
    fontWeight: 500,
    color: "#15171D",
    padding: "36px 24px 16px 24px",
    [theme.breakpoints.down("lg")]: {
      fontSize: 20,
      padding: "26px 16px 10px",
    },
  },
  clientSignatureContent: {
    padding: "0 24px",
    [theme.breakpoints.down("lg")]: {
      padding: "0 16px",
    },
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
});
