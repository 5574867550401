import React from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import Modal from "elements/Modal";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

const UploadDocumentDialog = (props) => {
  const {
    classes,
    uploadDocumentOpen,
    onToggleUploadDocument,
    uploadDoc,
    uploadDocType,
    clearDocument,
    openDocumentDialog,
    documentTypes,
    onChangeUploadType,
    uploadDocInternal,
    onClickCheckbox,
    uploadDocBilling,
    uploadError,
    uploading,
    submitDocumentUpload,
  } = props;

  return (
    <Modal
      open={uploadDocumentOpen}
      onClose={onToggleUploadDocument}
      title="Upload Document"
      titleCentered
      content={
        <div style={{ width: 600 }}>
          {uploadDoc ? (
            <React.Fragment>
              <Button onClick={() => clearDocument()} className={classes.smallButton}>
                Clear Document
              </Button>
            </React.Fragment>
          ) : (
            <Button onClick={() => openDocumentDialog()} className={classes.smallButton}>
              Upload Document
            </Button>
          )}

          {documentTypes && (
            <TextField
              select
              onChange={(e) => onChangeUploadType(e)}
              label="Document Type"
              fullWidth
              value={uploadDocType}
              style={{ margin: "10px 0px" }}
            >
              <MenuItem disabled value={""} />
              {documentTypes.map((docType) => {
                return docType.id === 1 ? null : (
                  <MenuItem key={docType.id} value={docType.name}>
                    {docType.display_name}
                  </MenuItem>
                );
              })}
            </TextField>
          )}
          <FormControlLabel
            control={
              <Checkbox
                checked={uploadDocInternal}
                onClick={() => onClickCheckbox("uploadDocInternal")}
              />
            }
            label={"Internal Use Only"}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={uploadDocBilling}
                onClick={() => onClickCheckbox("uploadDocBilling")}
              />
            }
            label={"Billing Related"}
          />
          {uploadError && (
            <Typography component="p" color="error">
              There was an error uploading this document. Please try again.
            </Typography>
          )}
        </div>
      }
      loading={uploading}
      primaryActionText="Save"
      primaryActionOnClick={submitDocumentUpload}
      primaryActionDisabled={!uploadDoc || !uploadDocType || uploading}
      secondaryActionText="Cancel"
      secondaryActionOnClick={onToggleUploadDocument}
      secondaryActionDisabled={uploading}
    />
  );
};

export default UploadDocumentDialog;
