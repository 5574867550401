import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import ExitAdhocConfirmationDialog from "../Dialogs/ExitAdhocConfirmationDialog";
import EditTimesheetNote from "./EditTimesheetNote";
import ViewTimesheetNote from "./ViewTimesheetNote";
import { DialogContent, Typography } from "@mui/material";
import TimesheetActions from "./TimesheetActions";
import { differenceInDays } from "date-fns";
import { X } from "@phosphor-icons/react";
import SuccessToast from "../Tools/SuccessToast";

export default function TimesheetNote(props) {
  const [serviceTypeError, setServiceTypeError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [timeError, setTimeError] = useState(false);
  const [totalMinutesError, setTotalMinutesError] = useState(false);
  const [noteError, setNoteError] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const {
    classes,
    visible,
    onToggleExitAdhocDialog,
    viewOnly,
    serviceType,
    startDate,
    endTime,
    duration,
    serviceTime,
    note,
    onSubmitTimesheet,
    successToastOpen,
    onCloseSuccessToast,
    savedAsDraft,
    requestEditsSuccess,
    approveTimeSuccess,
    associatedTimesDraftSaving,
    deleteNoteSuccess,
  } = props;

  useEffect(() => {
    if (submitting) {
      if (!serviceTypeError && !dateError && !timeError && !totalMinutesError && !noteError) {
        onSubmitTimesheet();
      }
      setSubmitting(false);
    }
  }, [submitting]);

  const onSubmit = () => {
    setServiceTypeError(false);
    setDateError(false);
    setTimeError(false);
    setTotalMinutesError(false);
    setNoteError(false);
    if (!serviceType || !serviceType?.id) {
      setServiceTypeError(true);
    }
    if (!startDate || differenceInDays(new Date(), new Date(startDate)) > 7) {
      setDateError(true);
    }
    if (!serviceTime) {
      setTimeError(true);
    }
    if (!duration || !endTime) {
      setTotalMinutesError(true);
    }
    if (!note) {
      setNoteError(true);
    }
    setSubmitting(true);
  };
  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={visible}
        PaperProps={{
          style: {
            width: 852,
            maxWidth: "95%",
          },
        }}
        sx={{
          "& .MuiPaper-root": {
            overflowY: "auto",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          },
        }}
      >
        <DialogTitle
          id="form-dialog-title"
          align="left"
          style={{ borderBottom: "1px solid #CDD3DA" }}
        >
          <Typography className={classes.title}>{serviceType.description}</Typography>

          <IconButton onClick={onToggleExitAdhocDialog}>
            <X size={24} color="rgba(49, 51, 58, 1)" />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ overflowY: "visible", padding: "24px" }}>
          {viewOnly ? (
            <ViewTimesheetNote {...props} />
          ) : (
            <EditTimesheetNote
              {...props}
              handleSubmit={onSubmit}
              serviceTypeError={serviceTypeError}
              setServiceTypeError={setServiceTypeError}
              dateError={dateError}
              setDateError={setDateError}
              timeError={timeError}
              setTimeError={setTimeError}
              totalMinutesError={totalMinutesError}
              setTotalMinutesError={setTotalMinutesError}
              noteError={noteError}
              setNoteError={setNoteError}
              submitting={submitting}
            />
          )}
        </DialogContent>
        <TimesheetActions {...props} handleSubmit={onSubmit} submitting={submitting} />
      </Dialog>
      <ExitAdhocConfirmationDialog {...props} />
      <SuccessToast
        open={successToastOpen}
        onClose={onCloseSuccessToast}
        classes={classes}
        savedAsDraft={savedAsDraft}
        timesheetNote={true}
        requestEditsSuccess={requestEditsSuccess}
        approveTimeSuccess={approveTimeSuccess}
        associatedTimesDraftSaving={associatedTimesDraftSaving}
        deleteNoteSuccess={deleteNoteSuccess}
      />
    </>
  );
}
