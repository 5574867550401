import { formatFilename } from "utils/snakeCasetoTitleCase";
import { formatLogText } from "./Utils";
import { format } from "date-fns";

function getVOBText(text) {
  text = String(text);

  const wordMap = {
    deductible_out_of_pocket_max: "deductible apply to OoP max",
    co_insurance_amount_insurance: "co-insurance amount (insurance)",
    co_insurance_amount_family: "co-insurance amount (family)",
    co_pay_amount_insurance: "co-pay amount",
    individual_out_of_pocket_max: "individual OOP max",
    individual_out_of_pocket_remaining: "individual OOP remaining",
    family_out_of_pocket_max: "family OOP max",
    family_out_of_pocket_remaining: "family OOP remaining",
    aba_covered: "ABA covered",
    is_same_benefit: "benefit same field",
    auth_required_for_assessment: "authorization required",
    auth_required_for_treatment: "authorization required for treatment",
    aba_managing_entity: "ABA managing entity",
    phone: "phone number",
    fax: "fax number",
    address1: "address",
    address2: "address",
    payer_id: "payer ID number",
    representative_id: "representative ID number",
  };
  for (const [key, value] of Object.entries(wordMap)) {
    text = text.replace(new RegExp(`\\b${key}\\b`, "g"), value);
  }
  return text;
}

export const getFormattedVOBLogs = (obj) => {
  const { data_changes, event_action, after, before } = obj;

  if (!data_changes) {
    if (event_action === "INSERT") {
      return formatLogText(
        "added a new Verification of Benefits for the",
        after.insurance_plan_name || after.insurance_plan_id,
        "plan"
      );
    }
  } else {
    let dataChangeKey = Object.keys(data_changes)?.[0];
    switch (dataChangeKey) {
      case "insurance_plan_name":
        return formatLogText(
          "changed the VOB insurance plan to",
          after.insurance_plan_name ?? "none",
          "from",
          before.insurance_plan_name ?? "none"
        );
      case "visit_limit":
      case "age_limit":
      case "annual_dollar_maximum":
      case "lifetime_dollar_maximum":
        if (data_changes[dataChangeKey].old) {
          return formatLogText(
            "changed the",
            formatFilename(getVOBText(dataChangeKey)),
            "from",
            data_changes[dataChangeKey].old !== "" ? data_changes[dataChangeKey].old : "none",
            "to",
            data_changes[dataChangeKey].new_value !== ""
              ? data_changes[dataChangeKey].new_value
              : "none",
            "for the",
            format(new Date(after.created_at), "MMMM do p"),
            "VOB"
          );
        } else {
          return formatLogText(
            "added an",
            formatFilename(getVOBText(dataChangeKey)),
            "of",
            data_changes[dataChangeKey].new_value !== ""
              ? data_changes[dataChangeKey].new_value
              : "none",
            "for the",
            format(new Date(after.created_at), "MMMM do p"),
            "VOB"
          );
        }
      case "insurance_plan_name":
        return formatLogText(
          "changed the",
          format(new Date(after.created_at), "MMMM do p"),
          "VOB insurance plan to",
          after.insurance_plan_name ?? "none",
          "from",
          before.insurance_plan_name ?? "none"
        );
      default:
        return formatLogText(
          `Changed the`,
          `${formatFilename(getVOBText(dataChangeKey))}`,
          "from",
          `${data_changes[dataChangeKey].old !== "" ? data_changes[dataChangeKey].old : "none"}`,
          `to`,
          `${data_changes[dataChangeKey].new_value !== "" ? data_changes[dataChangeKey].new_value : "none"}`,
          "for the",
          format(new Date(after.created_at), "MMMM do p"),
          "VOB"
        );
    }
  }
};
