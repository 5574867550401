import React from "react";
import { Typography, Button } from "@mui/material";
import { PauseCircle, ArrowRight } from "@phosphor-icons/react";

const BulkOperationsHeader = (props) => {
  const { classes, selectedRows, onHoldBillingItems, onReleaseBillingItems } = props;

  const showHoldAction = () => {
    return selectedRows.some((row) => !row.enter_health_id && row.note_completed && !row.on_hold);
  };

  const showReleaseAction = () => {
    return selectedRows.some((row) => !row.enter_health_id && row.note_completed && !!row.on_hold);
  };

  return (
    <div className={classes.bulkHeader}>
      <Typography component="p" variant="subtitle1" className={classes.bulkHeaderCount}>
        {selectedRows.length} selected
      </Typography>
      <div className={classes.bulkHeaderButtons}>
        {showHoldAction() && (
          <Button
            color="error"
            variant="contained"
            startIcon={<PauseCircle color="#fff" size={20} />}
            onClick={onHoldBillingItems}
          >
            Hold
          </Button>
        )}
        {showReleaseAction() && (
          <Button
            color="primary"
            variant="contained"
            startIcon={<ArrowRight color="#fff" size={20} />}
            onClick={onReleaseBillingItems}
          >
            Release
          </Button>
        )}
      </div>
    </div>
  );
};

export default BulkOperationsHeader;
