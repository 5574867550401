export { IntercomAiProvider } from "./IntercomAiProvider";
export {
  getIntercomAiConfiguration,
  openIntercomAi,
  closeIntercomAi,
  showChatPanel,
  hideChatPanel,
  tour,
} from "./IntercomAi";

import { IntercomAiProvider } from "./IntercomAiProvider";
export default IntercomAiProvider;
