import React from "react";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";

const SubHeading = (props) => {
  const { classes, children, ...otherProps } = props;

  return (
    <Typography component="h3" className={classes.subHeading} {...otherProps}>
      {children}
    </Typography>
  );
};

const styles = (theme) => ({
  subHeading: {
    fontSize: 22,
    lineHeight: "32px",
    letterSpacing: "-0.02em",
  },
});

export default withStyles(styles)(SubHeading);
