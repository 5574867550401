import React, { useState } from "react";
import { Paper, Popper, MenuList } from "@mui/material";
import CaretRight from "@mui/icons-material/ChevronRight";
import MenuItem from "@mui/material/MenuItem";

// Separate Component for Option with Nested Popper
const NestedPopperOption = ({ label, subOptions, classes, onClick, parentOption }) => {
  const [nestedOpen, setNestedOpen] = useState(false);
  const [nestedAnchorEl, setNestedAnchorEl] = useState(null);

  const handleMouseEnter = (event) => {
    setNestedAnchorEl(event.currentTarget);
    setNestedOpen(true);
  };

  const handleMouseLeave = () => {
    setNestedOpen(false);
    setNestedAnchorEl(null);
  };

  return (
    <li
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <span>{label}</span>
      <CaretRight />
      {/* Nested Popper */}
      <Popper open={nestedOpen} anchorEl={nestedAnchorEl} placement="right-start">
        <Paper
          elevation={8}
          classes={{ root: classes.paper }}
          onMouseDown={(e) => e.preventDefault()}
        >
          <MenuList>
            {subOptions.map((subOption, index) => {
              return (
                <MenuItem
                  key={index}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    const clickHandler = {
                      event: e,
                      option: subOption,
                      isTeamMember: true,
                      teamMemberId: parentOption.id,
                    };

                    onClick(clickHandler.event, clickHandler.option, clickHandler.teamMemberId);
                  }}
                >
                  {subOption.teamName}
                </MenuItem>
              );
            })}
          </MenuList>
        </Paper>
      </Popper>
    </li>
  );
};

export default NestedPopperOption;
