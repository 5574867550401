import { useMutation } from "react-query";
import { useGlobalToast } from "components/GlobalToastProvider";
import { updateCustomerEmailAsync } from "api/customers";

/**
 * The `useUpdateUserEmail` hook provides both synchronous (mutate) and async (mutateAsync) mutation functions
 * for updating a user's email with error handling and toast notifications.
 * @category Hooks
 * @param {UseMutationOptions} options It accepts an `options` object as a parameter, which can include custom configurations for the mutation operation.
 * @return The `useUpdateUserEmail` hook returns an object with the following properties:
 * - `updateUserEmailMutation`: Synchronous mutation function.
 * - `updateUserEmailMutationAsync`: Async mutation function.
 * - Other properties returned by the `useMutation` hook, such as `isLoading`, `isError`, etc.
 */
export const useUpdateUserEmail = (options = {}) => {
  const { onError = () => {}, ...otherOptions } = options;
  const { showToast } = useGlobalToast();

  const { mutate, mutateAsync, ...rest } = useMutation(
    ({ userId, newEmail }) => updateCustomerEmailAsync({ userId, newEmail }),
    {
      onError: (_, variables, context) => {
        showToast({
          message: "Whoops! Something went wrong while updating the email",
          errorState: true,
          retryHandler: () => mutate(variables, context),
        });
        onError();
      },
      ...otherOptions,
    }
  );

  return {
    updateUserEmailMutation: mutate,
    updateUserEmailMutationAsync: mutateAsync,
    ...rest,
  };
};
