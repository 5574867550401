import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import SubHeading from "./SubHeading";
import Divider from "@mui/material/Divider";
import { Box } from "@mui/material";

/**
 * It is a functional component that renders a Box component with specific styling and structure
 * based on the props passed to it, includes a title, description, loading indicator, and children components.
 * @component
 * @param {Object} props - The `SectionBox` component accepts the following props:
 * @param {String} props.title - The title prop is used to display the title of the section.
 * @param {String|React.ReactNode} props.description - The description prop is used to display a description of the section.
 * @param {Boolean} props.loading - The loading prop is used to determine if the loading indicator should be displayed.
 * @param {Object} props.classes - The classes prop is used to style the component.
 * @param {String} props.columnDirection - The columnDirection prop is used to determine the direction of the children components.
 * @returns {JSX.Element} The `SectionBox` component is being returned.
 */
const SectionBox = (props) => {
  const { classes, loading, columnDirection, title, description, ...rest } = props;

  return (
    <Box display="flex" justifyContent="center">
      <Box width="100%" maxWidth="1200px" padding={5} {...rest}>
        <Box
          display={"flex"}
          sx={{
            flexDirection: "row",
            "@media (max-width: 1200px)": { flexDirection: "column" },
            marginBottom: 11,
          }}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            sx={{
              width: "75%",
              mb: 7,
              "@media (max-width: 1200px)": { flexDirection: "column", width: "100%" },
            }}
          >
            <Typography variant="h3">{title}</Typography>
            <Typography variant="body2" color="text.secondary">
              {description}
            </Typography>
          </Box>
          <Box
            display={"flex"}
            flexDirection={columnDirection ? "column" : "row"}
            width={"100%"}
            sx={{ "@media (min-width: 1200px)": { pl: "24px" } }}
          >
            {loading ? (
              <div className={classes.loading}>
                <CircularProgress size={30} color="primary" style={{ margin: 10 }} />
                <SubHeading>Loading</SubHeading>
              </div>
            ) : (
              <React.Fragment>{props.children}</React.Fragment>
            )}
          </Box>
        </Box>
        <Divider />
      </Box>
    </Box>
  );
};
export default SectionBox;
