import React, { useState } from "react";
import { Typography, Box, IconButton, Divider } from "@mui/material";
import { SlidersHorizontal, X } from "@phosphor-icons/react";
import { ServiceFiltering } from "./ServiceFiltering";
import { OtherFiltering } from "./OtherFiltering";
import { PeopleFiltering } from "./PeopleFiltering";

export const ScheduleFilterArea = ({
  toggleScheduleUtilityDrawer,
  userPermissions,
  showNoShows,
  setShowNoShows,
  colorIndexManager,
  userId,
  peopleFilters,
  setPeopleFilters,
  selectedServices,
  setSelectedServices,
  showSupervisoryEvents,
  handleToggleShowSupervisoryEvents,
  showTentativeEvents,
  handleToggleTentativeEvents,
}) => {
  const [inputValue, setInputValue] = useState("");
  const handleToggleService = (service) => {
    setSelectedServices(
      selectedServices.includes(service)
        ? selectedServices.filter((s) => s !== service)
        : [...selectedServices, service]
    );
  };

  const canSearchAll =
    userPermissions?.view_clinician_list || userPermissions?.view_client_list_by_workflow_state;

  return (
    <Box padding="16px 16px 0 16px" display="flex" flexDirection="column">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography variant="subtitle1" paddingRight="8px">
            Filters
          </Typography>
          <SlidersHorizontal />
        </Box>
        <IconButton fontSize="small" onClick={toggleScheduleUtilityDrawer}>
          <X />
        </IconButton>
      </Box>
      <PeopleFiltering
        userId={userId}
        canSearchAll={canSearchAll}
        inputValue={inputValue}
        setInputValue={setInputValue}
        filteredPeople={peopleFilters}
        setFilteredPeople={setPeopleFilters}
        colorIndexManager={colorIndexManager}
      />
      <Divider />
      <ServiceFiltering selectedServices={selectedServices} onToggleService={handleToggleService} />
      <Divider />
      <OtherFiltering
        showNoShows={showNoShows}
        setShowNoShows={setShowNoShows}
        showSupervisoryEvents={showSupervisoryEvents}
        handleToggleShowSupervisoryEvents={handleToggleShowSupervisoryEvents}
        showTentativeEvents={showTentativeEvents}
        handleToggleTentativeEvents={handleToggleTentativeEvents}
      />
      <Divider />
    </Box>
  );
};
