import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { format } from "date-fns";
import actions from "../../actions";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { CustomTable } from "elements/DEPRECATED_elements";
import { CustomHeaderCell } from "elements/DEPRECATED_elements";

import {
  getClinicianClientsSort,
  getClinicianClientsSortDirection,
  getClinicianClientsCount,
  userLoaded,
} from "../../selectors";
import { Link } from "@mui/material";

const mapStateToProps = (state) => ({
  sort: getClinicianClientsSort(state),
  direction: getClinicianClientsSortDirection(state),
  count: getClinicianClientsCount(state),
  userLoaded: userLoaded(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getClinicianClients: actions.getClinicianClients,
      setClinicianClientsSort: actions.setClinicianClientsSort,
    },
    dispatch,
  );

class ClientTable extends Component {
  componentDidUpdate(prevProps) {
    if (
      (!prevProps.userLoaded && this.props.userLoaded) ||
      prevProps.sort !== this.props.sort ||
      prevProps.direction !== this.props.direction ||
      prevProps.clinicianId !== this.props.clinicianId
    ) {
      this.props.getClinicianClients(this.props.clinicianId);
    }
  }

  handleSortClick = (name) => {
    this.props.setClinicianClientsSort({
      sort: name,
    });
  };

  mapStatusToDisplay = (status) => {
    switch (status) {
      case "INSURANCE_APPROVED":
        return "Therapy";
      case "FULL_CUSTOMER":
        return "Therapy";
      default:
        return "Assessment";
    }
  };

  render() {
    const { classes, clients, handleDetailsClick, sort, direction, userPermissions, userId } =
      this.props;
    return (
      <CustomTable
        noResultsMessage="No Clients Found"
        headerCells={[
          <CustomHeaderCell
            label="Client Name"
            sortable={true}
            sortDirection={sort === "name" || !sort ? direction : ""}
            active={sort === "name" || !sort}
            onClick={() => this.handleSortClick("name")}
          />,
          <CustomHeaderCell
            label="Guardian Name"
            sortable={true}
            active={sort === "first_name"}
            sortDirection={sort === "first_name" ? direction : ""}
            onClick={() => this.handleSortClick("first_name")}
          />,
          <CustomHeaderCell
            label="Insurance"
            sortable={true}
            sortDirection={sort === "insurance" ? direction : ""}
            active={sort === "insurance"}
            onClick={() => this.handleSortClick("insurance")}
          />,
          <CustomHeaderCell
            label="Client Since"
            sortable={true}
            sortDirection={sort === "started_on" ? direction : ""}
            active={sort === "started_on"}
            onClick={() => this.handleSortClick("started_on")}
          />,
          <CustomHeaderCell label="Status" />,
          userPermissions?.assignable_as_secondary_clinician && (
            <CustomHeaderCell label=" My Role" />
          ),
          <CustomHeaderCell label="See More" align="center" />,
        ]}
      >
        {clients.map((row, index) => {
          return (
            <TableRow key={index}>
              <TableCell align="left" style={{ fontWeight: "500", color: "#4e4e4e" }}>
                {row.name}
              </TableCell>
              <TableCell align="left">
                {row.first_name} {row.last_name}
              </TableCell>
              <TableCell align="left">
                {row.private_pay ? "Private Pay" : row.insurance || "N/A"}
              </TableCell>
              <TableCell align="left">
                {format(new Date(row.started_on), "PP", {
                  awareOfUnicodeTokens: true,
                })}
              </TableCell>
              <TableCell align="left">{this.mapStatusToDisplay(row.workflow_status)}</TableCell>
              {userPermissions?.assignable_as_secondary_clinician && (
                <TableCell align="left">
                  {row.clinician_user_id == userId ? "Primary Clinician" : "Supervising Clinician"}
                </TableCell>
              )}
              <TableCell align="center">
                {userPermissions?.view_my_clients ? (
                  <Link onClick={handleDetailsClick(row.client_id)}>Details</Link>
                ) : (
                  <Typography component="p" style={{ fontWeight: "500", color: "#4e4e4e" }}>
                    N/A
                  </Typography>
                )}
              </TableCell>
            </TableRow>
          );
        })}
      </CustomTable>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClientTable));
