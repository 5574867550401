import moment from "moment";

export const findDifferenceInMinutes = (endDate, startDate) => {
  let startTime = new Date(startDate);
  startTime.setSeconds(0);
  startTime.setMilliseconds(0);
  let endTime = new Date(endDate);
  endTime.setSeconds(0);
  endTime.setMilliseconds(0);
  let duration = endTime - startTime;
  let minutes = duration / 1000 / 60;
  return minutes;
};
