import React, { useEffect } from "react";
import { Typography, Box, CircularProgress } from "@mui/material";
import { AvailableHoursSelector } from "./AvailableHoursSelector";
import { PreferredAvailabilityCard } from "./PreferredAvailabilityCard";
import { useClientAvailability } from "./hooks";
import camelCaseToTitleCase from "utils/camelCaseToTitleCase";
import { formatTimeZoneToAbbreviation } from "utils/formatTimeZoneToAbbreviation";
import SectionBox from "../../../../elements/SectionBox";

const daysOrder = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
const serviceTypesOrder = ["directTherapy", "caregiverTraining"];

const initializeDefaultHours = () => {
  return daysOrder.reduce((acc, day) => {
    acc[day] = {
      isActive: false,
      ranges: [],
    };
    return acc;
  }, {});
};

const defaultServiceTypes = {
  services: serviceTypesOrder.map((type) => ({
    serviceType: type,
    availability: initializeDefaultHours(),
  })),
};

export const ClientAvailability = ({
  editMode,
  basicInfo,
  setNewAvailableHours,
  newAvailableHours,
  onChangeAvailability,
  classes,
}) => {
  const {
    data: currentAvailability,
    isLoading,
    refetch,
  } = useClientAvailability({
    clientId: basicInfo?.details?.id,
    onSuccess: ({ data }) => {
      if (!editMode) {
        setNewAvailableHours(data.services.length > 0 ? data : defaultServiceTypes);
      }
    },
  });

  useEffect(() => {
    setNewAvailableHours(currentAvailability?.data?.services ?? defaultServiceTypes);
  }, []);

  useEffect(() => {
    refetch();
  }, [editMode, newAvailableHours]);

  const updateDayData = ({ serviceType, day, isActive, timeRanges }) => {
    const timeRangesArray = Array.isArray(timeRanges) ? timeRanges : [];
    const updatedServices = newAvailableHours.services.map((service) => {
      if (service.serviceType === serviceType) {
        return {
          ...service,
          availability: {
            ...service.availability,
            [day]: { isActive, ranges: timeRangesArray },
          },
        };
      }
      return service;
    });
    setNewAvailableHours({ services: updatedServices });
    onChangeAvailability();
  };

  const copyToDays = ({ serviceType, day, selectedDays }) => {
    const service = newAvailableHours.services.find((s) => s.serviceType === serviceType);
    const copiedDayData = service.availability[day];
    const updatedAvailability = selectedDays.reduce((acc, day) => {
      acc[day] = copiedDayData;
      return acc;
    }, service.availability);

    const updatedServices = newAvailableHours.services.map((s) => {
      if (s.serviceType === serviceType) {
        return { ...s, availability: updatedAvailability };
      }
      return s;
    });

    setNewAvailableHours({ services: updatedServices });
  };

  return (
    <div className={classes.accordionContainer}>
      <SectionBox
        title="Weekly Availability"
        description="To set the client’s weekly availability for therapy sessions, please indicate the days
              and times they are available. Times can also be identified as preferred therapy hours
              for best fit scheduling efforts."
        columnDirection
      >
        {newAvailableHours?.services && (
          <PreferredAvailabilityCard
            serviceTypesOrder={serviceTypesOrder}
            availableHours={newAvailableHours}
            basicInfo={basicInfo}
            clientTimeZone={basicInfo.details.timezone_jhi}
          />
        )}
        {serviceTypesOrder.map((serviceType, index) => (
          <Box key={index} marginTop={"32px"}>
            <Typography
              variant="inputLabel"
              color={(theme) => theme.palette.text.secondary}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "8px",
              }}
            >
              {camelCaseToTitleCase(serviceType)}
            </Typography>
            {!isLoading && newAvailableHours?.services ? (
              <>
                {daysOrder.map((day) => {
                  return (
                    <AvailableHoursSelector
                      key={`${serviceType}-${day}`}
                      day={day}
                      isActive={
                        newAvailableHours.services.find((s) => s.serviceType === serviceType)
                          ?.availability[day]?.isActive
                      }
                      clientTimeZone={basicInfo.details.timezone_jhi}
                      timeRanges={
                        newAvailableHours.services.find((s) => s.serviceType === serviceType)
                          ?.availability[day]?.ranges
                      }
                      newAvailableHours={newAvailableHours}
                      updateDayData={({ isActive, timeRanges }) =>
                        updateDayData({
                          serviceType,
                          day,
                          isActive,
                          timeRanges,
                        })
                      }
                      editMode={editMode}
                      copyToDays={({ selectedDays }) =>
                        copyToDays({ serviceType, day, selectedDays })
                      }
                    />
                  );
                })}
                {editMode && (
                  <Typography
                    variant="caption"
                    sx={{ fontStyle: "italic", marginTop: "16px" }}
                  >{`All times are in the client’s time zone (${formatTimeZoneToAbbreviation(
                    basicInfo.details.timezone_jhi
                  )})`}</Typography>
                )}
              </>
            ) : (
              <CircularProgress size={30} color="primary" style={{ marginBottom: 10 }} />
            )}
          </Box>
        ))}
      </SectionBox>
    </div>
  );
};

export default ClientAvailability;
