export const getInitialState = (props) => {
  return {
    username: null,
    roomNameErr: false,
    previewTracks: null,
    localMediaAvailable: false,
    hasJoinedRoom: false,
    activeRoom: null,
    localAudioTrack: null,
    localVideoTrack: null,
    screenTrack: null,
    screenTrackAudio: null,
    muted: false,
    cameraDisabled: false,
    showControls: false,
    clinicianJoined: false,
    remoteVideoDisabled: false,
    callEnded: false,
    chatOpen: false,
    chatTabOpen: false,
    roomLayout: "standard",
    animation: {},
    activity: {},
    card: null,
    cardResponse: null,
    shakeAnimation: false,
    videoStartTime: 0,
    videoPlaying: false,
    startTimeURL: null,
    endTimeURL: null,
    sigError: false,
    assessmentVideo: false,
    promptActivityData: null,
    promptSelectionCorrect: null,
    promptShakeAnimation: null,
    firstPromptAttemptCorrect: null,
    showPrompt: false,
    joinRoomReady: false,
    hideChat: false,
    confirmedGoBack: false,
    backPressDialogOpen: false,
    rejoinedSocket: false,
    performanceDialogOpen: false,
    speedTestDialogOpen: false,
    settingDialogOpen: false,
    audioInputs: [],
    cameraInputs: [],
    audioOutputs: [],
    mobileCameraInputs: [],
    currentAudioInput: "",
    currentAudioOutput: "",
    currentCameraInput: "",
    screenShareOn: false,
    parentScreenSharing: false,
    screenshareUserId: null,
    inControlUserId: null,
    secondaryClinicianInControl: false,
    secondaryClinicianData: null,
    primaryClinicianData: null,
    secondaryVideoDisabled: false,
    hasStartedCall: false,
    notesOpen: false,
    endCallDialogOpen: false,
    noShowEndCallDialogOpen: false,
    clientDeviceType: null,
    cardIndex: 0,
    currentCard: {},
    cardsCorrect: 0,
    cardsIncorrect: 0,
    cardActivityStarted: false,
    promptNextCard: false,
    videoActivityOpen: false,
    videoURL: "",
    showSignatures: false,
    assessmentComplete: false,
    scheduleCallOpen: false,
    promptDrawerOpen: false,
    promptControlsOpen: false,
    fieldSize: 4,
    promptLevel: "none",
    promptActivityOpen: false,
    clientPromptResponse: null,
    correctPromptCard: null,
    initialPromptResponse: false,
    notes: "",
    noNotesRequired: false,
    skipNotes: false,
    participants: "",
    cptCodeDialogOpen: false,
    sessionNotesDialogOpen: false,
    showClientChat: true,
    assessmentCall: false,
    inVideoRoom: props.match?.params?.videoKey && !props.isCaregiver,
    clientJoined: false,
    clientJoinedData: {},
    isDemo: false,
    showClientScreenShare: false,
    clientLastLoginDate: null,
    clinicianPassedTime: 0,
    timerId: null,
    clinicianScreenSharing: false,
    currentParticipant: {},
    isPrimaryClinician: false,
    secondaryClinicianVideoJoinedTime: null,
    secondaryClinicianTimerId: null,
    secondaryClinicianPassedTime: 0,
    primaryClinicianConnected: false,
    secondaryClinicianConnected: false,
    secondaryClinicianHasIncompleteBilling: false,
    remoteClinicianVideoDisabled: false,
    secondaryLeaveCallDialogOpen: false,
    signatureId: null,
    isSecondaryJoinRoomReady: false,
    remoteClinicianData: null,
    clinicianInControl: false,
    passControlsConfirmOpen: false,
    passControlsRequestedDialogOpen: false,
    controlsReceived: false,
    videoParticipants: [],
    controlsPassingClinician: null,
    passControlsRejected: false,
    passControlsFailed: false,
    controlsPassed: false,
    videoControlsAcceptTimerId: null,
    countdown: 25,
    controlsRequestTimedOutDialogOpen: false,
    requestSentTextOpen: false,
    controlsRequestingClinician: null,
    passToUserId: null,
    controlsRequestedDialogOpen: false,
    takeControlsRejected: false,
    giveControlsRequestTimedOutDialogOpen: false,
    takeControlsFailed: false,
    socketConnected: false,
    multipleServiceTypes: null,
    localParticipantNetworkLevelReceived: false,
    localParticipantNetworkLevel: null,
    remoteBadInternetDialogOpen: false,
    localBadInternetDialogOpen: false,
    badInternetParticipant: null,
    localBadInternedDetected: false,
    connectionFailedDialogOpen: false,
    videoDisabledOpen: false,
    clientConnected: false,
    unreadMessageCount: 0,
    inputAccessGranted: false,
    showUnsupportedPlatformDialog: false,
    hideUnsupportedPlatformMessage: false,
    showUnsupportedPlatformCheckbox: false,
    clientOnMobile: false,
    screenSharingNotSupported: false,
    speakerTestOn: false,
    microphoneTestOn: false,
    joinRoomTriggered: false,
    remoteConnectionFailedDialogOpen: false,
    connectionFailedParticipant: null,
    fromStaticUrl: false,
    clientCanScreenshare: false,
    sessionNoteId: null,
    billableTimeId: null,
    serviceType: "",
    callDuration: 0,
    primaryClinicianFirstName: "",
    readyToJoinCall: false,
    activityDrawerOpen: false,
    hostControlsDrawerOpen: false,
    nextClientCall: null,
    mergeNextSessionDialogOpen: false,
    mergeSessionError: false,
    mergeSessionLoading: false,
    allowedToJoinCall: true,
    sessionFullDialogOpen: false,
    replacingClientUser: false,
    clientUserReplaced: false,
    eCommConsentFormSigned: false,
    smsFacilityDialogOpen: false,
    endCallTriggered: false,
    callEndedMarkedAsNoShow: false,
  };
};

export const getInitialSpeedTestState = () => {
  return {
    fileList: [
      "temp_3.1MB_file",
      "temp_4.2MB_file",
      "temp_5.2MB_file",
      "temp_6.3MB_file",
      "temp_7.3MB_file",
      "temp_8.4MB_file",
      "temp_9.4MB_file",
      "temp_10.5MB_file",
      "temp_11.5MB_file",
      "temp_12.6MB_file",
      "temp_13.6MB_file",
      "temp_14.7MB_file",
      "temp_15.7MB_file",
    ],
    uploadFileList: ["temp_2MB_file", "temp_3MB_file", "temp_4MB_file", "temp_5MB_file"],
    uploadDocument: null,
    uploadSpeedLog: [],
    downloadSpeedLog: [],
    averageDownloadMbps: null,
    averageUploadMbps: null,
    downloadRound: 0,
    uploadRound: 0,
    speedTestCancelled: false,
    downloadTestEnded: false,
    uploadTestEnded: false,
  };
};

export const getInitialTestState = (props) => {
  return {
    deviceInformationError: false,
    browserPermissionError: false,
    microphoneError: false,
    speakerError: false,
    cameraError: false,
    errorDialogOpen: false,
  };
};
