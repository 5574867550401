import { TextField } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

export const StyledInput = withStyles({
  root: {
    backgroundColor: "#ffffff",
    "& label.Mui-focused": {
      color: "#545A72",
    },
    "& fieldset": {
      borderRadius: 4,
      color: "#3F4456",
    },
    "& .MuiOutlinedInput-root": {
      fontSize: 14,
      "&:hover fieldset": {
        border: "1px solid #BCBCBC",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid #BCBCBC",
      },
    },
    "& .MuiInputLabel-root": {
      fontSize: 15,
    },
    "& .MuiFormHelperText-root": {
      backgroundColor: "#fafafa",
      margin: 0,
      padding: "0 5px",
      color: "red",
    },
  },
})(TextField);
