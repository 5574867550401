import {
  getCliniciansPage,
  getCliniciansPerPage,
  getClinicianId,
  getCliniciansSort,
  getCliniciansSortDirection,
  getCliniciansStatus,
} from "../selectors";
import { select } from "redux-saga/effects";
import SecureRequest from "../utils/securerequest";

export function* getCliniciansList() {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const page = yield select(getCliniciansPage);
  const size = yield select(getCliniciansPerPage);
  const sort = yield select(getCliniciansSort);
  const direction = yield select(getCliniciansSortDirection);
  const status = yield select(getCliniciansStatus);
  const params = {
    page,
    size,
    sort,
    direction,
    status,
  };
  return yield Request.get(`/clinicians`, { params });
}

export function* getDeactivatedClinicians() {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const page = yield select(getCliniciansPage);
  const size = yield select(getCliniciansPerPage);
  const sort = yield select(getCliniciansSort);
  const direction = yield select(getCliniciansSortDirection);
  const params = {
    page,
    size,
    sort,
    direction,
  };
  return yield Request.get(`/clinicians/deactivated-clinicians`, { params });
}

export const getClinicalLeads = async () => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.get(`/v2/clinician/clinical-leads`);
};

export function* getClinician(id) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return yield Request.get(`/clinicians/${id}`);
}

export const getClinicianDocumentTypes = async () => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const test = await Request.get(`/documents/clinician/types`);
  return test.data;
};

export const getClinicianDocuments = async (clinicianId, params) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.get(`/v2/clinician/${clinicianId}/documents`, { params });
};

export function* getClinicianCertificates(id) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return yield Request.get(`/clinicians/${id}/certificates`);
}

export function* getClinicianLicenses(id) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return yield Request.get(`/clinicians/${id}/licenses`);
}

export function* getClinicianDegrees(id) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return yield Request.get(`/clinicians/${id}/degrees`);
}

export function* getClinicianPhoneNumbers(id) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/clinicians/${id}/phone-numbers`);
}

export function* getClinicianAddress(id) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/clinicians/${id}/address`);
}

export function* updateClinicianUserDetails(id, firstName, lastName, image) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const params = {
    id,
    firstName,
    lastName,
    image,
  };

  return yield Request.post("/clinicians/userDetails", params);
}

export function* updateClinicianDetails(id, bio, video, gender, active, calendar) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const params = {
    bio,
    video,
    gender,
    active,
    calendar,
    id,
  };

  return yield Request.post("/clinicians/clinicianDetails", params);
}

export function* updateClinicianBehaviors(behaviors, clinicianId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const params = {
    behaviors,
    clinicianId,
  };

  return yield Request.post("/clinicians/clinicianBehaviors", params);
}

export function* updateClinicianDiagnoses(diagnoses, clinicianId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const params = {
    diagnoses,
    clinicianId,
  };

  return yield Request.post("/clinicians/clinicianDiagnoses", params);
}

export function* updateClinicianSpecialties(specialties, clinicianId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const params = {
    specialties,
    clinicianId,
  };

  return yield Request.post("/clinicians/clinicianSpecialties", params);
}

export const updateEmploymentTypeApi = async ({ clinicianId, employmentType }) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const response = await Request.put(`/v2/clinician/${clinicianId}/employment-status`, {
    clinicianId,
    employmentType,
  });
  return response.data;
};

export const updateSupervisorApi = async ({ clinicianId, supervisorUserId }) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const response = await Request.put(`/v2/clinician/${clinicianId}/supervisor`, {
    supervisorUserId,
  });
  return response.data;
};

export function* updateClinicianBasicDetails(params) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.post("/clinicians/basic-details", params);
}

export function* updateClinicianImage(params) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.post("/clinicians/image", params);
}

export function* updateClinicianAddress(params) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const clinicianId = yield select(getClinicianId);
  return yield Request.post(`/clinicians/${clinicianId}/address`, params);
}

export function* addClinicianPhone(params) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const clinicianId = yield select(getClinicianId);
  return yield Request.post(`/clinicians/${clinicianId}/phone`, params);
}

export function* updateClinicianNPI(params) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const clinicianId = yield select(getClinicianId);
  return yield Request.post(`/clinicians/${clinicianId}/npi`, params);
}

export function* addClinicianLicense(params) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const clinicianId = yield select(getClinicianId);
  return yield Request.post(`/clinicians/${clinicianId}/license`, params);
}

export function* addClinicianCertificate(params) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const clinicianId = yield select(getClinicianId);
  return yield Request.post(`/clinicians/${clinicianId}/certificate`, params);
}

export function* createClinician(params) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.post("/clinicians", params);
}

export function* createSupportChat(clinician_user_id) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.post(`/clinicians/${clinician_user_id}/create-support`, {});
}

export function* getClinicianDeactivationReasons() {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get("clinicians/deactivation/reasons");
}

export function* getAssignableClinicians(secondary) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  let secondaryQuery = "";
  if (secondary === true) {
    secondaryQuery = "?secondary=true";
  }
  return yield Request.get(`v2/clinicians/assignable${secondaryQuery}`);
}

export async function getAllClinicians() {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.get(`/clinicians/all`);
}
