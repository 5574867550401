import SecureRequest from "../utils/securerequest";

export function* getUserEmailLog(email) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/communication/${email}/emails-sent`);
}

export function* sendSMSToCaregiver(messageData) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.post(`/communication/${messageData.clientId}/send-sms`, messageData);
}

export function* getSMSThreadByClient(clientId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/communication/${clientId}/sms`);
}
