import React from "react";
import GenericSelect from "../GenericFormFields/GenericSelect";
import { useSelector } from "react-redux";
import { getAllPhysicians } from "../../../../selectors";
import { useWatch } from "react-hook-form";
import { Box } from "@mui/material";
import { providerTypes } from "./providerTypes";

const ProvidersFormDialogProvidersForm = ({ submitAttempted }) => {
  const allPhysicians = useSelector(getAllPhysicians);
  const isEdit = useWatch({ name: "isEdit" });

  return (
    <>
      <Box style={{ marginBottom: "24px" }}>
        <GenericSelect
          name="provider.type"
          label="Provider Type"
          required
          options={providerTypes}
          helperText={"Provider Type required"}
          submitAttempted={submitAttempted}
        />
      </Box>
      <GenericSelect
        name="provider.id"
        label="Provider"
        required
        disabled={isEdit}
        options={allPhysicians.map(({ id, first_name, last_name }) => ({
          label: `${first_name} ${last_name}`,
          value: id,
        }))}
        helperText={"Provider required"}
        submitAttempted={submitAttempted}
      />
    </>
  );
};

export default ProvidersFormDialogProvidersForm;
