import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import SubHeading from "./SubHeading";
import { makeStyles } from "@mui/styles";

const PageContainer = (props) => {
  const { loading, horizontalPadding, fixHeight } = props;
  const useStyles = makeStyles((theme) => ({
    container: {
      width: "100%",
      padding: 25,
      minHeight: fixHeight ? 0 : "calc(100vh - 72px)",
      height: fixHeight && "calc(100vh - 72px)",
      [theme.breakpoints.down("xl")]: {
        padding: 10,
      },
      [theme.breakpoints.up("lg")]: {
        marginTop: 0,
        height: fixHeight && "100%",
      },
    },
    loadingContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "calc(100vh - 50px - 64px)",
    },
  }));
  const classes = useStyles();

  const children = Array.isArray(props.children) ? props.children : [props.children];
  return (
    <div
      className={classes.container}
      style={horizontalPadding === false ? { padding: "25px 0" } : null}
    >
      {loading ? (
        <div className={classes.loadingContainer}>
          <CircularProgress size={30} color="primary" style={{ marginBottom: 10 }} />
          <SubHeading>Loading</SubHeading>
        </div>
      ) : (
        children.map((e, key) => (!!e ? { ...e, key } : e))
      )}
    </div>
  );
};

export default PageContainer;
