import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import { format } from "date-fns";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { CustomTable } from "elements/DEPRECATED_elements";
import { CustomHeaderCell } from "elements/DEPRECATED_elements";
import Button from "@mui/material/Button";

import actions from "actions";
import {
  getClinicianPastClientsSort,
  getClinicianPastClientsSortDirection,
  getClinicianPastClientsCount,
  userLoaded,
} from "selectors";
import styles from "./styles";

const mapStateToProps = (state) => ({
  userLoaded: userLoaded(state),
  sort: getClinicianPastClientsSort(state),
  direction: getClinicianPastClientsSortDirection(state),
  count: getClinicianPastClientsCount(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getClinicianPastClients: actions.getClinicianPastClients,
      setClinicianPastClientsSort: actions.setClinicianPastClientsSort,
    },
    dispatch,
  );

class PastClientsTable extends Component {
  componentDidUpdate(prevProps) {
    if (
      (!prevProps.userLoaded && this.props.userLoaded) ||
      prevProps.sort !== this.props.sort ||
      prevProps.direction !== this.props.direction ||
      prevProps.clinicianId !== this.props.clinicianId
    ) {
      this.props.getClinicianPastClients();
    }
  }

  handleSortClick = (name) => {
    this.props.setClinicianPastClientsSort({
      sort: name,
    });
  };

  mapStatusToDisplay = (status) => {
    switch (status) {
      case "INSURANCE_APPROVED":
        return "Therapy";
      case "FULL_CUSTOMER":
        return "Therapy";
      default:
        return "Assessment";
    }
  };

  render() {
    const { classes, clients, sort, direction, handleDetailsClick, userPermissions } = this.props;
    return (
      <CustomTable
        headerCells={[
          <CustomHeaderCell
            label="Client Name"
            sortDirection={sort === "name" || !sort ? direction : false}
            active={sort === "name" || !sort}
            onClick={() => this.handleSortClick("name")}
          />,
          <CustomHeaderCell
            label="Guardian Name"
            active={sort === "first_name"}
            sortDirection={sort === "first_name" ? direction : false}
            onClick={() => this.handleSortClick("first_name")}
          />,
          <CustomHeaderCell
            label="Insurance"
            sortDirection={sort === "insurance" ? direction : false}
            active={sort === "insurance"}
            onClick={() => this.handleSortClick("insurance")}
          />,
          <CustomHeaderCell
            label="Client Since"
            sortDirection={sort === "started_on" ? direction : false}
            active={sort === "started_on"}
            onClick={() => this.handleSortClick("started_on")}
          />,
          <CustomHeaderCell label="Status" />,

          <CustomHeaderCell label="See More" align="center" />,
        ]}
      >
        {clients.map((row, index) => {
          return (
            <TableRow key={index}>
              <TableCell align="left" style={{ fontWeight: 500, color: "#4e4e4e" }}>
                {row.name}
              </TableCell>
              <TableCell align="left">
                {row.first_name} {row.last_name}
              </TableCell>
              <TableCell align="left">
                {row.private_pay ? "Private Pay" : row.insurance || "N/A"}
              </TableCell>
              <TableCell align="left">
                {format(new Date(row.started_on), "PP", {
                  awareOfUnicodeTokens: true,
                })}
              </TableCell>
              <TableCell align="left">{this.mapStatusToDisplay(row.workflow_status)}</TableCell>
              <TableCell align="center">
                {userPermissions?.view_my_clients ? (
                  <Button
                    variant="text"
                    onClick={handleDetailsClick(row.client_id)}
                    disabled={row.is_active ? true : false}
                    style={
                      row.is_active ? { color: "#8c8c8c" } : { fontWeight: 500, color: "#4e4e4e" }
                    }
                  >
                    Details
                  </Button>
                ) : (
                  <Typography component="p" style={{ fontWeight: 500, color: "#4e4e4e" }}>
                    N/A
                  </Typography>
                )}
              </TableCell>
            </TableRow>
          );
        })}
      </CustomTable>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PastClientsTable)),
);
