import { makeStyles } from "@mui/styles";
import { COLOR_CHIPS } from "constants/colors";

// Maps the color chips to the event colors
const colorEventMap = Object.keys(COLOR_CHIPS).map((colorIndex, index) => ({
  [`& .event-color-${index}`]: {
    color: COLOR_CHIPS[colorIndex].color,
    backgroundColor: COLOR_CHIPS[colorIndex].backgroundColor,
    cursor: "default",
    "&:focus": { outline: "none" },
  },
}));

/**
 * Generates the styles used in the component.
 *
 * @param {Object} theme - The theme object provided by the ThemeProvider.
 * @returns {Object} - The styles object.
 */
const useStyles = makeStyles((theme) => ({
  // Styles for days of the week buttons
  ...theme.bigCalendarCustomStyle,
  daysOfWeek: {
    "& button": {
      minWidth: "45px",
    },
  },
  // Custom styles for the calendar component
  calendarTheme: {
    ...theme.bigCalendarCustomStyle,
    // Removes the hover and focus styles and overrides color by number
    ...colorEventMap.reduce((acc, curr) => ({ ...acc, ...curr }), {}),

    "&& .expired": {
      opacity: 0.5,
    },

    "&& .private": {
      color: theme.palette.text.secondary,
      backgroundColor: "#EAECEF",
      cursor: "default",
      "&.expired": {
        color: theme.palette.text.disabled,
        backgroundColor: "#F6F7F8",
      },
      "&:focus": { outline: "none" },
    },
  },
  // Styles for the selected date display
  selectedDate: {
    borderRadius: "100px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrast,
    padding: theme.spacing(0, 1),
    minWidth: "22px",
    display: "flex",
    justifyContent: "center",
  },
}));

export default useStyles;
