import {
  Button,
  Checkbox,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Radio,
  TableCell,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { DoorOpen, DotsThree, PaperPlaneTilt, Password, UserCircle } from "@phosphor-icons/react";
import ANTooltip from "elements/ANTooltip";
import { useFormikContext } from "formik";
import React, { useState } from "react";
import { useResetUserPassword } from "hooks/api/useResetUserPassword";
import { useGlobalToast } from "components/GlobalToastProvider";

export default function EditTable({
  row,
  teamRoles,
  rowIndex,
  onRemoveMember,
  onEditProfile,
  onResendInvite,
}) {
  const theme = useTheme();
  const { showToast } = useGlobalToast();
  const [anchorRelationshipEl, setAnchorRelationshipEl] = useState(null);
  const openRelationship = Boolean(anchorRelationshipEl);
  const handleRelationshipClose = () => setAnchorRelationshipEl(null);
  const [anchorOptionsEl, setAnchorOptionsEl] = useState(null);
  const openOptions = Boolean(anchorOptionsEl);
  const handleOptionsClose = () => setAnchorOptionsEl(null);
  const { resetUserPasswordMutation, isLoading } = useResetUserPassword();

  const { values, handleChange, handleBlur, setFieldValue } = useFormikContext();
  const memberFieldName = `teamSection.teamMembers[${rowIndex}]`;

  const iconProps = { color: theme.palette.action.active, weight: "duotone" };
  const optionsMenuItems = [
    {
      label: "Edit Profile",
      value: "edit_profile",
      icon: <UserCircle {...iconProps} />,
      action: onEditProfile,
    },
    {
      label: "Send Password Reset Link",
      value: "send_password_reset_link",
      icon: <Password {...iconProps} />,
      disabled: isLoading,
      action: () =>
        resetUserPasswordMutation(row.userId, {
          onSuccess: () => showToast({ message: "The link has been successfully sent!" }),
        }),
    },
    {
      label: "Remove from Team",
      value: "remove_from_team",
      icon: <DoorOpen {...iconProps} />,
      disabled: row.isDesignatedCaregiver,
      action: onRemoveMember,
    },
    {
      label: "Resend Invite",
      value: "resend_invite",
      icon: <PaperPlaneTilt {...iconProps} />,
      action: onResendInvite,
    },
  ];

  return (
    <>
      <Menu
        id="relationship-menu"
        anchorEl={anchorRelationshipEl}
        open={openRelationship}
        onClose={handleRelationshipClose}
        MenuListProps={{ "aria-labelledby": "open-relationship" }}
      >
        {teamRoles.map((el) => (
          <MenuItem
            key={el.id}
            value={el.id}
            onClick={() => {
              setFieldValue(`${memberFieldName}.relationshipType`, el.id);
              handleRelationshipClose();
            }}
          >
            {el.name}
          </MenuItem>
        ))}
      </Menu>
      <Menu
        id="options-menu"
        anchorEl={anchorOptionsEl}
        open={openOptions}
        onClose={handleOptionsClose}
        MenuListProps={{ "aria-labelledby": "open-options" }}
      >
        {optionsMenuItems.map((item) => {
          if (
            !row.hasAcceptedInvite &&
            ["edit_profile", "send_password_reset_link"].includes(item.value)
          )
            return null;
          if (row.hasAcceptedInvite && item.value === "resend_invite") return null;
          return (
            <MenuItem
              key={`${item.value}`}
              value={item.value}
              onClick={() => {
                item.action();
                handleOptionsClose();
              }}
              disabled={item.disabled}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              {item.label}
            </MenuItem>
          );
        })}
      </Menu>
      <TableCell>
        <ANTooltip
          component={Button}
          size="small"
          color="secondary"
          style={{ maxWidth: "100%", display: "block" }}
          onClick={(e) => setAnchorRelationshipEl(e.currentTarget)}
          disabled={!row.hasAcceptedInvite}
        >
          {teamRoles.find((el) => el.id === row.relationshipType)?.name}
        </ANTooltip>
      </TableCell>
      <TableCell align="center">
        <Checkbox
          name={`${memberFieldName}.isLegalGuardian`}
          checked={row.isLegalGuardian}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={!row.hasAcceptedInvite}
        />
      </TableCell>
      <TableCell align="center">
        <Radio
          name={`${memberFieldName}.isDesignatedCaregiver`}
          checked={row.isDesignatedCaregiver}
          value={row.id}
          onChange={() => {
            const updatedTeamMembers = values.teamSection.teamMembers.map((m) =>
              m.id === row.id
                ? { ...m, isDesignatedCaregiver: true }
                : { ...m, isDesignatedCaregiver: false }
            );
            setFieldValue("teamSection.teamMembers", updatedTeamMembers);
          }}
          onBlur={handleBlur}
          disabled={!row.hasAcceptedInvite}
        />
      </TableCell>
      <TableCell align="center">
        <IconButton onClick={(e) => setAnchorOptionsEl(e.currentTarget)}>
          <DotsThree />
        </IconButton>
      </TableCell>
    </>
  );
}
