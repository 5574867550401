import React, { useState } from "react";
import {
  DialogContent,
  Typography,
  Dialog,
  DialogTitle,
  Grid,
  FormLabel,
  IconButton,
  TextField,
  Button,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import { X } from "@phosphor-icons/react";

const RequestChangesDialog = (props) => {
  const [error, setError] = useState(false);

  const {
    requestChangesDialogOpen,
    onToggleRequestChangesDialog,
    requestedEdits,
    onChange,
    requestEditsLoading,
    submitRequestedChanges,
    classes,
  } = props;

  return (
    <>
      <Dialog
        maxWidth={"lg"}
        open={requestChangesDialogOpen}
        onClose={onToggleRequestChangesDialog}
        PaperProps={{
          style: {
            width: 600,
            maxWidth: "95%",
            overflowY: "auto",
          },
        }}
      >
        <DialogTitle className={classes.dialogTitle}>
          <Typography component="h3" style={{ fontSize: 20, fontWeight: 500 }}>
            Suggest Edits
          </Typography>
          <IconButton onClick={onToggleRequestChangesDialog} size="medium">
            <X size={24} color="rgba(49, 51, 58, 1)" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <FormLabel component="legend" className={classes.formLabel} style={{ marginBottom: 8 }}>
            Provide guidance on any revisions that should be made to the note for approval
          </FormLabel>
          <TextField
            fullWidth
            multiline={true}
            minRows={6}
            value={requestedEdits}
            onChange={onChange("requestedEdits")}
            size="medium"
            onFocus={() => setError(false)}
            onBlur={() => {
              requestedEdits.length === 0 || !requestedEdits ? setError(true) : setError(false);
            }}
            helperText={error ? "Please enter the requested edits before submitting" : null}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={onToggleRequestChangesDialog}
            disabled={requestEditsLoading}
          >
            Cancel
          </Button>
          <Button
            onClick={!requestedEdits ? null : submitRequestedChanges}
            disabled={requestEditsLoading}
          >
            {requestEditsLoading && (
              <CircularProgress style={{ color: "#C0C8D0", marginRight: 8 }} size={16} />
            )}
            Submit Suggestion
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RequestChangesDialog;
