import { useQuery } from "react-query";
import { getAllAvailableInsuranceCodes } from "api/insurance";

// Custom hook to fetch and filter insurance codes to non-video, client-related codes
const useGetNonVideoServiceTypes = () => {
  const response = useQuery("insuranceCodes", getAllAvailableInsuranceCodes, {
    select: (response) => response.data?.filter((code) => !code.video && !code.time_tracking),
  });

  return response?.data ?? [];
};

export default useGetNonVideoServiceTypes;
