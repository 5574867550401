import React from "react";
import Modal from "elements/Modal";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { Alert, AlertTitle } from "@mui/material";
import { WarningCircle } from "@phosphor-icons/react";

const ReassignClientsBeforeDeactivationDialog = (props) => {
  const { open, onClose } = props;  
  const [dialogOpen, setDialogOpen] = React.useState(open);

  const handleDialogOpen = (open) => {
    setDialogOpen(open);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          onClose();
        }}
        content={
          <>
            <Alert severity={"error"} icon={<WarningCircle />}>
              <AlertTitle>Reassign Clients before deactivating</AlertTitle>
              The account can't be deactivated until all clients are reassigned.
            </Alert>
          </>
        }
        secondaryActionText="close"
        secondaryActionOnClick={() => onClose()}
      />
    </>
  );
};

export default ReassignClientsBeforeDeactivationDialog;
