import { getClinicianStateLicenseAsync } from "api/clinician";
import { useQuery } from "react-query";

import { STALE_TIME_5_MINUTES } from "constants/reactQueryKeys";

export const useGetClinicianStateLicense = (clinicianId) => {
  return useQuery(["clinicianStateLicense"], () => getClinicianStateLicenseAsync(clinicianId), {
    enabled: !!clinicianId,
    staleTime: STALE_TIME_5_MINUTES,
  });
};
