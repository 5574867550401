import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Prompt, useParams } from "react-router-dom";

import {
  getCreatePlanLoading,
  getInsuranceListProviderId,
  getInsuranceListState,
  getCreateProviderLoading,
  getUpdatePlanLoading,
} from "../../selectors";
import { useGlobalToast } from "components/GlobalToastProvider";
import { ClientDetailsFormProvider } from "../ClientDetails/ClientDetailsFormContext";
import InsuranceUtility from "../InsuranceUtility";
import { PhysiciansPage } from "../PhysiciansPage";
import Assessments from "./Assessments";
import { useGetInsurancePlansQuery } from "../../hooks/useGetInsurancePlans";
import { BookOpenText, MapPin } from "@phosphor-icons/react";
import { COVERAGE_STATES } from "../../constants";
import { useTheme } from "@mui/styles";
import SaveBeforeNavDialog from "../ClientDetails/Dialogs/SaveBeforeNavDialog";

const AnLibrariesPageContents = (props) => {
  const {
    currentTab,
    editMode,
    visibleTabs,
    navigate,
    setEditMode,
    openWarning,
    setOpenWarning,
    setUnsavedChanges,
    unsavedChanges,
    nextTab,
    changeTab,
  } = props;

  const theme = useTheme();
  const { showToast } = useGlobalToast();
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 10,
    page: 0,
  });
  const [isPanelOpen, setIsPanelOpen] = React.useState(false);
  const [dataPanel, setDataPanel] = React.useState({});
  const [configPanel, setConfigPanel] = React.useState([]);
  const [titlePanel, setTitlePanel] = React.useState("");
  const [inputsForm, setInputsForms] = React.useState([]);
  const [isDirty, setIsDirty] = useState(true);
  const [saveButtonTitle, setSaveButtonTitle] = useState(true);
  const [activePanel, setActivePanel] = useState(null);
  const [topEdit, setTopEdit] = useState(true);
  const [nextLocation, setNextLocation] = useState(null);
  const [isCancelClicked, setIsCancelClicked] = useState(false);
  const panelRef = useRef(null);
  const [orderBy, setOrderBy] = useState("p.name");
  const updateDataPanel = (key, value) => {
    setDataPanel((prevDataPanel) => ({
      ...prevDataPanel,
      [key]: value,
    }));
  };
  const [uploadedDateFilter, setUploadedDateFilter] = useState([null, null]);
  const state = useSelector(getInsuranceListState);
  const providerId = useSelector(getInsuranceListProviderId);
  const createProviderLoading = useSelector(getCreateProviderLoading);
  const createPlanLoading = useSelector(getCreatePlanLoading);
  const updatePlanLoading = useSelector(getUpdatePlanLoading);
  const [coverageStateFilter, setCoverageStateFilter] = useState([]);
  const [planFilter, setPlanFilter] = useState([]);
  const [companyFilter, setCompanyFilter] = useState([]);

  const updateActivePanel = (title) => {
    setActivePanel(title);
  };

  const updateTitlePanel = (title) => {
    setTitlePanel(title);
  };

  const resetDataPanel = () => {
    setDataPanel({});
  };

  const copyToDataPanel = (newData) => {
    setDataPanel(newData);
  };

  const updateInputsForm = (newInputsForm) => {
    setInputsForms(newInputsForm);
  };

  const updateIsDirty = (value) => {
    setIsDirty(value);
  };

  const finishSubmit = (message, isError = false) => {
    showToast({
      message: message,
      errorState: isError,
    });
    resetDataPanel();
    setConfigPanel([]);
    setIsPanelOpen(false);
  };

  const clearData = () => {
    resetDataPanel();
    setIsDirty(true);
    setUnsavedChanges(false);
    setConfigPanel([]);
    setIsPanelOpen(false);
    setOpenWarning(false);
    if (nextTab) {
      changeTab(nextTab);
    }
  };

  useEffect(() => {
    if (!openWarning && nextLocation) {
      navigate(nextLocation);
    }
  }, [openWarning]);

  const orderByMap = {
    "i.name": { sort: "i.name", direction: "asc" },
    "p.name": { sort: "p.name", direction: "asc" },
    "p.coverage_state": { sort: "p.coverage_state", direction: "asc" },
  };

  // using isFetching instead of isLoading, since keepPreviousData (avoids showing `undefined` in chips)
  // is set to true in useGetAssessementsBatch and isLoading will be false when
  // the previous data is being used
  const {
    isFetchingData,
    data: response,
    refetch: updateTable,
  } = useGetInsurancePlansQuery(state, providerId, {
    sort: orderByMap[orderBy]?.sort,
    direction: orderByMap[orderBy]?.direction,
    page: paginationModel.page,
    size: paginationModel.pageSize,
    planList: planFilter.join(","),
    companyList: companyFilter.join(","),
    coverageList: coverageStateFilter.join(","),
  });

  const dataInsurance = response?.data?.data;
  const rowCount = response?.data.count || 0;
  const coverageStateList = response?.data?.coverageStateList || 0;
  const companyList = response?.data?.companyList || 0;
  const planList = response?.data?.planList || 0;
  const generateRows = () => {
    if (!dataInsurance) return [];
    return [...dataInsurance];
  };
  const insurancePlansData = generateRows();

  useEffect(() => {
    updateTable();
  }, [planFilter, companyFilter, coverageStateFilter, orderBy, paginationModel]);
  useEffect(() => {
    updateTable();
  }, [createProviderLoading, createPlanLoading, updatePlanLoading]);

  const filtersMenuItems = [
    {
      label: "Insurance Plan",
      value: "p.name",
      valueUncapitalize: false,
      icon: <BookOpenText weight="duotone" />,
      countObject: planList,
      state: planFilter,
      setState: setPlanFilter,
    },
    {
      label: "Insurance Company",
      value: "i.name",
      countLabel: "plan",
      valueUncapitalize: false,
      icon: <BookOpenText weight="duotone" />,
      countObject: companyList,
      state: companyFilter,
      setState: setCompanyFilter,
    },
    {
      label: "Coverage State",
      value: "i.coverage_state",
      countLabel: "plan",
      valueUncapitalize: false,
      icon: <MapPin weight="duotone" />,
      countObject:
        coverageStateList &&
        coverageStateList.map((item) => {
          return {
            id: item.id,
            display_name: COVERAGE_STATES[item.id] || item.display_name,
            count: item.count,
          };
        }),
      state: coverageStateFilter,
      setState: setCoverageStateFilter,
    },
  ];

  const orderingMenuItems = [
    {
      label: "Insurance Company",
      value: "i.name",
      icon: <BookOpenText weight="duotone" />,
    },
    {
      label: "Insurance Plan",
      value: "p.name",
      icon: <BookOpenText weight="duotone" />,
    },
    {
      label: "Coverage State",
      value: "p.coverage_state",
      icon: <MapPin weight="duotone" />,
    },
  ];
  const tabs = [
    {
      label: "Insurance",
      link: "insurance",
      component: (
        <InsuranceUtility
          {...props}
          editMode={editMode}
          dataPanel={dataPanel}
          updateDataPanel={updateDataPanel}
          resetDataPanel={resetDataPanel}
          copyToDataPanel={copyToDataPanel}
          configPanel={configPanel}
          setConfigPanel={setConfigPanel}
          isPanelOpen={isPanelOpen}
          setIsPanelOpen={setIsPanelOpen}
          titlePanel={titlePanel}
          updateTitlePanel={updateTitlePanel}
          inputsForm={inputsForm}
          updateInputsForm={updateInputsForm}
          isDirty={isDirty}
          updateIsDirty={updateIsDirty}
          panelRef={panelRef}
          setEditMode={setEditMode}
          setSaveButtonTitle={setSaveButtonTitle}
          saveButtonTitle={saveButtonTitle}
          activePanel={activePanel}
          updateActivePanel={updateActivePanel}
          finishSubmit={finishSubmit}
          topEdit={topEdit}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          orderByMap={orderByMap}
          filtersMenuItems={filtersMenuItems}
          orderingMenuItems={orderingMenuItems}
          isFetchingData={isFetchingData}
          insurancePlansData={insurancePlansData}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          setPlanFilter={setPlanFilter}
          setCompanyFilter={setCompanyFilter}
          setCoverageStateFilter={setCoverageStateFilter}
          uploadedDateFilter={uploadedDateFilter}
          setUploadedDateFilter={setUploadedDateFilter}
          rowCount={rowCount}
          theme={theme}
          openWarning={openWarning}
          setOpenWarning={setOpenWarning}
          setIsCancelClicked={setIsCancelClicked}
          setUnsavedChanges={setUnsavedChanges}
          unsavedChanges={unsavedChanges}
        />
      ),
    },
    {
      label: "Providers",
      link: "providers",
      component: <PhysiciansPage {...props} />,
    },
    {
      label: "Assessments",
      link: "assessments",
      component: <Assessments />,
    },
  ];

  const selectedTab = tabs.find(
    (tab) =>
      visibleTabs?.some((includedTab) => includedTab.link === tab.link) && tab.link === currentTab
  );

  return (
    <div style={{ width: "100%" }}>
      <Prompt
        when={unsavedChanges}
        message={(nextLocation, action) => {
          if (unsavedChanges) {
            setNextLocation(nextLocation.pathname);
            if (window.location.pathname !== nextLocation.pathname && action === "POP") {
              window.history.forward();
            }
            setOpenWarning(true);
            setIsCancelClicked(false);
            return false;
          }
        }}
      />
      <SaveBeforeNavDialog
        saveBeforeNavOpen={openWarning}
        onToggleSaveBeforeNav={() => {
          setOpenWarning(false);
          if (nextLocation) {
            setNextLocation(null);
          }
        }}
        onContinueWithoutSaving={clearData}
        severetyAlert={"warning"}
        primaryActionColorButton={"error"}
        secondaryText={"Stay on Page"}
        isCancelClicked={isCancelClicked}
      />
      <ClientDetailsFormProvider editMode={editMode} currentTab={currentTab}>
        {selectedTab?.component}
      </ClientDetailsFormProvider>
    </div>
  );
};

export default AnLibrariesPageContents;
