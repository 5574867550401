import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";

const MergeSessionsDialog = (props) => {
  const {
    open,
    onToggleMergeNextSessionDialog,
    onMergeNextSession,
    clientName,
    mergeSessionLoading,
    mergeSessionError,
  } = props;
  return (
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      style={{ zIndex: 2010 }}
      PaperProps={{
        style: {
          maxWidth: "90%",
          width: 444,
        },
      }}
    >
      <DialogTitle
        style={{
          fontSize: 20,
          fontWeight: "500",
          color: "#212121",
          letterSpacing: "0.15px",
        }}
      >
        Your next session is about to begin
      </DialogTitle>
      <DialogContent style={{ padding: "0 24px" }}>
        <DialogContentText style={{ fontSize: 16, fontWeight: "400", color: "#212121" }}>
          {`The next session with ${clientName} is about to begin. Merge the sessions to continue this call without ending and starting the second session`}
        </DialogContentText>
      </DialogContent>
      {mergeSessionError && (
        <Typography
          component="p"
          style={{
            fontSize: 14,
            fontWeight: "500",
            color: "#B91C1C",
            textAlign: "center",
            margin: "10px auto",
          }}
        >
          There was an error merging these calls, please try again.
        </Typography>
      )}
      <DialogActions>
        <Button
          onClick={onToggleMergeNextSessionDialog}
          variant="text"
          color="primary"
          style={{ height: 36, fontWeight: "500" }}
        >
          Keep Sessions Separate
        </Button>
        <LoadingButton
          loading={mergeSessionLoading}
          onClick={onMergeNextSession}
          style={{ width: 140, height: 36, fontWeight: "500" }}
          color="primary"
        >
          Merge Sessions
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default MergeSessionsDialog;
