import React, { useEffect, useState } from "react";
import withStyles from "@mui/styles/withStyles";
import InsuranceDetails from "./InsuranceDetails";
import styles from "../../styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import InsuranceDetailsEdit from "./InsuranceDetailsEdit";
import Divider from "@mui/material/Divider";
import SectionBox from "../../../../elements/SectionBox";
import { ClientDetailsFormStates, useClientDetailsForm } from "../../ClientDetailsFormContext";
import useInsuranceSubmit from "./useInsuranceSubmit";
import { useSelector } from "react-redux";
import * as selectors from "../../../../selectors";

const getInitialState = () => {
  return {
    isPrimary: false,
    id: 0,
    insuranceName: "",
    planName: "",
    memberId: "",
    groupNumber: "",
    effectiveDate: null,
    expirationDate: null,
    isMedicaid: false,
    preAuthRequired: null,
    insuranceHolderName: "",
  };
};

const Insurance = (props) => {
  const {
    userInsurance,
    getInsuranceProvidersList,
    getInsurancePlans,
    insuranceListProviderId,
    userPermissions,
    editDisabled,
    classes,
    editMode,
    updateUserInsurance,
    updateInsuranceIsPrimary,
    disableInsuranceOpen,
    onCloseUpdateInsurance,
    clientId,
    privatePayClient,
    insurancePlans,
    insuranceProvidersList,
    customerDetails,
    getUserInsurance,
    setInsuranceListProviderId,
    setUnsavedChanges,
    setInsurancesId,
  } = props;

  const [showInsuranceForm, setShowInsuranceForm] = useState(false);
  const [primaryInsuranceId, setPrimaryInsuranceId] = useState();
  const [formErrorsById, setFormErrorsById] = useState({});
  const [formValuesById, setFormValuesById] = useState({});
  const userId = useSelector(selectors.getUserId);
  const primaryInsurance = userInsurance.find(({ is_primary }) => is_primary);

  useEffect(() => {
    getInsuranceProvidersList();
  }, []);

  useEffect(() => {
    if (!editMode) {
      setShowInsuranceForm(false);

      // Reset primary insurance id every time we exit edit mode
      if (primaryInsurance) {
        setPrimaryInsuranceId(primaryInsurance.id);
      }
    }
  }, [editMode]);

  useEffect(() => {
    if (insuranceListProviderId) {
      getInsurancePlans();
    }
  }, [insuranceListProviderId]);

  useEffect(() => {
    if (primaryInsurance) {
      setPrimaryInsuranceId(primaryInsurance.id);
    }

    setInsurancesId(
      userInsurance.map((value) => ({
        name: value.insurance_plan_name,
        id: value.insurance_plan_id,
        medicaid: value.insurance_is_medicaid,
      }))
    );
  }, [userInsurance]);

  const insuranceCount = userInsurance.length;
  const showAddSecondaryInsuranceButton =
    insuranceCount === 1 && editMode && userPermissions?.add_client_insurance && !editDisabled;

  userInsurance.sort((a, b) => a.id - b.id);
  const isOnlyInsurance = userInsurance.length === 1 && !showInsuranceForm;

  const { formState, onSubmitError, onSubmitting } = useClientDetailsForm();
  const { submitForms } = useInsuranceSubmit({ userId, getUserInsurance, clientId });
  const storeFormValues = (values) => {
    setFormValuesById((prevState) => ({
      ...prevState,
      [values.id || "new"]: values,
    }));
  };

  const storeFormErrors = ({ id, value }) => {
    setFormErrorsById((prevState) => ({
      ...prevState,
      [id || "new"]: value,
    }));
  };

  const resetFormStates = () => {
    setFormErrorsById({});
    setFormValuesById({});
  };

  useEffect(() => {
    let allFormsValuesCollected = userInsurance.every(({ id }) => formValuesById[id]);

    if (allFormsValuesCollected && showInsuranceForm) {
      allFormsValuesCollected = !!formValuesById.new;
    }

    if (formState !== ClientDetailsFormStates.onsubmit || !allFormsValuesCollected) {
      return;
    }

    let noErrors = userInsurance.every(({ id }) => !formErrorsById[id]);

    if (noErrors && showInsuranceForm) {
      noErrors = !formErrorsById.new;
    }

    if (noErrors) {
      onSubmitting();
      submitForms(Object.values(formValuesById), clientId, userId).then(() => {
        resetFormStates();
      });
    } else {
      onSubmitError();
    }
  }, [formErrorsById, formValuesById, formState]);

  return (
    <SectionBox
      title="Insurance"
      description="Add and manage insurance coverage on the client account."
    >
      <div className={classes.insuranceContainer}>
        {userInsurance.map((insurance, idx) => (
          <React.Fragment key={insurance.id}>
            <InsuranceDetails
              isEditing={editMode && userPermissions?.add_client_insurance}
              isOnlyInsurance={isOnlyInsurance}
              insurance={insurance}
              privatePayClient={privatePayClient}
              customerDetails={customerDetails}
              insuranceListProviderId={insuranceListProviderId}
              getInsurancePlans={getInsurancePlans}
              insuranceProvidersList={insuranceProvidersList}
              insurancePlans={insurancePlans}
              classes={classes}
              userId={userId}
              clientId={clientId}
              primaryInsuranceId={primaryInsuranceId}
              setPrimaryInsuranceId={setPrimaryInsuranceId}
              getUserInsurance={getUserInsurance}
              setInsuranceListProviderId={setInsuranceListProviderId}
              onSave={storeFormValues}
              onError={storeFormErrors}
              setUnsavedChanges={setUnsavedChanges}
            />
            {idx !== userInsurance.length - 1 && <Divider style={{ margin: "24px 0" }} />}
          </React.Fragment>
        ))}

        {insuranceCount === 0 && (
          <>
            {!editMode ? (
              <Typography
                component="p"
                className={classes.noInfoText}
                style={{ marginTop: "24px" }}
              >
                This client has no insurance insurance information
              </Typography>
            ) : (
              <div className={classes.addInsuranceButtonContainer}>
                {userPermissions?.add_client_insurance && !editDisabled && (
                  <Button
                    color="secondary"
                    onClick={() => setShowInsuranceForm(true)}
                    startIcon={<AddIcon />}
                    style={{ marginTop: "24px" }}
                  >
                    Add Insurance
                  </Button>
                )}
              </div>
            )}
          </>
        )}

        {showAddSecondaryInsuranceButton && !showInsuranceForm && (
          <Button
            color="secondary"
            onClick={() => setShowInsuranceForm(true)}
            startIcon={<AddIcon />}
            style={{ marginTop: "24px" }}
          >
            Add Secondary Insurance
          </Button>
        )}

        {showInsuranceForm && userPermissions?.add_client_insurance && (
          <div style={{ marginTop: "24px" }}>
            <InsuranceDetailsEdit
              {...{
                getUserInsurance,
                insurance: getInitialState(),
                updateInsuranceIsPrimary,
                disableInsuranceOpen,
                insuranceListProviderId,
                getInsurancePlans,
                onCloseUpdateInsurance,
                primaryInsuranceId,
                setPrimaryInsuranceId,
                updateUserInsurance,
                userId,
                clientId,
                privatePayClient,
                insurancePlans,
                insuranceProvidersList,
                classes,
                onError: storeFormErrors,
                onSave: storeFormValues,
                setUnsavedChanges,
                onCancelCreateInsurance: () => setShowInsuranceForm(false),
              }}
            />
          </div>
        )}
      </div>
    </SectionBox>
  );
};

export default withStyles(styles)(Insurance);
