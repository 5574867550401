import React, { Component, Children } from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import CircularProgress from "@mui/material/CircularProgress";
import styles from "./styles";
import "./styles/calendar.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
// import "react-big-calendar/lib/sass/styles";
import { Calendar, momentLocalizer } from "react-big-calendar";
import Toolbar from "react-big-calendar/lib/Toolbar";
import moment from "moment";
import SectionContainer from "elements/SectionContainer";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Typography } from "@mui/material";
import { isSameDay } from "date-fns";

const localizer = momentLocalizer(moment);

class CustomToolbar extends Toolbar {
  render() {
    const { label } = this.props;
    return (
      <div className="calendar-toolbar">
        <ChevronLeftIcon onClick={() => this.navigate("PREV")} />
        <Typography component="p" className="toolbar-label-text">
          {this.props.label}
        </Typography>
        <ChevronRightIcon onClick={() => this.navigate("NEXT")} />
      </div>
    );
  }
}

const CustomEventBubble = (props) => {
  return <div style={{ width: 4, height: 4, backgroundColor: "blue", borderRadius: 2 }} />;
};

const SelectedDateCellStyle =
  ({ selectedDate }) =>
  ({ children, value }) => {
    const isSelectedDate = isSameDay(new Date(value), new Date(selectedDate));
    return React.cloneElement(Children.only(children), {
      style: {
        ...children.style,
        border: isSelectedDate ? "1px solid #A568D5" : "none",
        borderRadius: isSelectedDate ? 4 : "none",
      },
    });
  };

const SessionCalendar = (props) => {
  const {
    classes,
    dashboard,
    calendarCallsLoading,
    setCurrentMonth,
    onClickCall,
    onSelectCalendarDay,
    onCalendarNavigate,
    calendarCalls,
    calendarDisplayDate,
    disableInteraction,
    maxHeight,
  } = props;

  let callList = calendarCalls?.map((call) => {
    let eventColor;
    switch (call.billing_type) {
      case "CAREGIVER_TRAINING":
        eventColor = "C96F26";
        break;
      case "ORIENTATION":
        eventColor = "2653C9";
        break;
      case "DIRECT_THERAPY":
        eventColor = "2C88BC";
        break;
      case "ASSESSMENT":
        eventColor = "B226C9";
    }
    return {
      id: call.id,
      start: call.end_date ? call.start_date : call.scheduled_date,
      end: call.end_date ? call.end_date : call.scheduled_date,
      color: eventColor,
    };
  });

  const eventStyle = (e) => {
    return {
      style: {
        backgroundColor: "#" + e.color,
      },
    };
  };

  const formats = {
    weekdayFormat: (date, culture, localizer) => localizer.format(date, "dd", culture)[0],
    dateFormat: "D",
  };

  const components = {
    toolbar: CustomToolbar,
    dateCellWrapper: SelectedDateCellStyle({
      selectedDate: calendarDisplayDate,
    }),
  };

  return (
    <div id="ClientCalendar">
      <Calendar
        localizer={localizer}
        events={callList}
        views={["month"]}
        components={components}
        eventPropGetter={eventStyle}
        formats={formats}
        onNavigate={disableInteraction ? null : (e) => onCalendarNavigate(e)}
        onSelectEvent={disableInteraction ? null : (e) => onCalendarNavigate(e.start)}
        className={classes.calendar}
        style={maxHeight ? { maxHeight } : null}
      />
    </div>
  );
};

export default withRouter(withStyles(styles)(SessionCalendar));
