import React from "react";
import withStyles from "@mui/styles/withStyles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import { DialogTitle, IconButton, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const Modal = (props) => {
  const {
    classes,
    open,
    onClose,
    icon,
    title,
    titleCentered,
    description,
    content,
    primaryActionText,
    primaryActionOnClick,
    secondaryActionText,
    secondaryActionOnClick,
    loading,
    primaryActionDisabled,
    secondaryActionDisabled,
    success,
    successMessage,
    error,
    errorMessage,
    transitionDirection,
    showGoBack,
    onClickGoBack,
    goBackText,
    primaryActionOutlined,
    titleStyles,
    dialogContentPadding,
    width,
    sx,
    primaryButtonStyles,
    secondaryButtonStyles,
    showCloseOnTitle,
    showBordersOnContent,
  } = props;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          borderRadius: 4,
          maxWidth: "95%",
          overflow: "visible",
          width: width ? width : "fit-content",
        },
      }}
      style={{ padding: "5px 7px" }}
      TransitionProps={{ direction: transitionDirection || "down" }}
      sx={sx}
    >
      {title && (
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: 0,
            padding: "12px 15px",
          }}
        >
          <Typography
            component="h4"
            className={titleCentered ? classes.titleTextCentered : classes.titleText}
            style={titleStyles ? titleStyles : null}
          >
            {title}
          </Typography>
          {showCloseOnTitle && (
            <IconButton aria-label="close" style={{ borderRadius: "15%" }}>
              <CloseIcon onClick={() => onClose(true)} style={{ cursor: "pointer" }} />
            </IconButton>
          )}
        </DialogTitle>
      )}
      <DialogContent
        className={classes.dialogContent}
        style={dialogContentPadding ? { padding: dialogContentPadding } : { padding: "24px 10px" }}
        sx={{
          padding: 0,
          borderTop: showBordersOnContent ? "1pt solid #CDD3DA" : "",
          borderBottom: showBordersOnContent ? "1pt solid #CDD3DA" : "",
        }}
      >
        <div className={classes.infoContainer}>
          {icon ? <div className={classes.iconContainer}>{icon}</div> : null}
          <div
            className={icon ? classes.textContainerWithIcon : classes.textContainer}
            style={{ width: "100%" }}
          >
            {content ? (
              content
            ) : (
              <Typography component="p" className={classes.contentText}>
                {description}
              </Typography>
            )}
          </div>
        </div>
      </DialogContent>

      <DialogActions className={classes.dialogActionsContainer} style={{ padding: "16px 24px" }}>
        <Stack direction={"row"} justifyContent={"space-between"} gap={2} width={"100%"}>
          {(showGoBack || success || error) && (
            <Stack direction={"row"} flex={1} justifyContent={"flex-start"}>
              {showGoBack && (
                <Button
                  variant="text"
                  onClick={onClickGoBack}
                  style={{ position: "absolute", left: 20, bottom: 9 }}
                  startIcon={
                    goBackText ? null : (
                      <div>
                        <ArrowBackOutlinedIcon style={{ fontSize: 15 }} />
                      </div>
                    )
                  }
                >
                  {goBackText ? goBackText : "Go Back"}
                </Button>
              )}
              {success && (
                <Typography component="p" className={classes.successText}>
                  {successMessage ? successMessage : "Success"}
                </Typography>
              )}

              {error && (
                <Typography component="p" className={classes.errorText}>
                  {errorMessage ? errorMessage : "An error has occurred. Please try again."}
                </Typography>
              )}
            </Stack>
          )}
          <Stack direction={"row"} gap={2} flex={1} width={"100%"} justifyContent={"flex-end"}>
            {primaryActionText && primaryActionOnClick && (
              <LoadingButton
                onClick={primaryActionOnClick}
                className={classes.action}
                disabled={primaryActionDisabled}
                loading={loading}
                style={
                  !primaryActionDisabled && primaryButtonStyles ? primaryButtonStyles : undefined
                }
                variant={primaryActionOutlined ? "outlined" : "contained"}
              >
                {primaryActionText}
              </LoadingButton>
            )}
            {secondaryActionText && secondaryActionOnClick && (
              <Button
                onClick={secondaryActionOnClick}
                className={classes.action}
                variant={primaryActionOutlined ? "text" : "outlined"}
                color="default"
                disabled={secondaryActionDisabled}
                style={
                  !secondaryActionDisabled && secondaryButtonStyles
                    ? secondaryButtonStyles
                    : undefined
                }
              >
                {secondaryActionText}
              </Button>
            )}
            {primaryActionText && primaryActionOnClick && (
              <LoadingButton
                onClick={primaryActionOnClick}
                className={classes.actionMobile}
                fullWidth
                style={{ marginBottom: 10 }}
                disabled={primaryActionDisabled}
                loading={loading}
              >
                {primaryActionText}
              </LoadingButton>
            )}
            {secondaryActionText && secondaryActionOnClick && (
              <Button
                onClick={secondaryActionOnClick}
                className={classes.actionMobile}
                color="secondary"
                fullWidth
                disabled={secondaryActionDisabled}
              >
                {secondaryActionText}
              </Button>
            )}
          </Stack>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
const styles = (theme) => ({
  dialogContent: {
    height: "fit-content",
    maxHeight: "95%",
    maxWidth: "100%",
    color: "#404040",
    padding: "20px 20px 16px",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    [theme.breakpoints.down("xl")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  textContainerWithIcon: {
    maxWidth: "calc(100% - 60px)",
    [theme.breakpoints.down("xl")]: {
      maxWidth: "100%",
    },
  },
  textContainer: {
    maxWidth: "100%",
  },
  iconContainer: {
    height: 48,
    width: 48,
    borderRadius: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 12,
    backgroundColor: "#F4ECFA",
    "& svg": {
      fill: "#8E42CA",
      height: 25,
      width: 25,
    },
    [theme.breakpoints.down("xl")]: {
      marginRight: 0,
    },
  },
  titleText: {
    fontSize: 24,
    fontWeight: 500,
    lineHeight: "26px",
    marginBottom: 8,
    [theme.breakpoints.down("xl")]: {
      marginTop: 10,
      textAlign: "center",
    },
  },
  titleTextCentered: {
    fontSize: 24,
    fontWeight: 500,
    lineHeight: "26px",
    textAlign: "center",
    marginBottom: 8,
    [theme.breakpoints.down("xl")]: {
      marginTop: 10,
      textAlign: "center",
    },
  },
  contentText: {
    fontSize: 15,
    fontWeight: 500,
    lineHeight: "20px",
    color: "#69718F",
  },
  dialogActionsContainer: {
    padding: "12px 20px",
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
    position: "relative",
    [theme.breakpoints.down("xl")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  action: {
    [theme.breakpoints.down("xl")]: {
      display: "none",
    },
    "&:hover": {
      filter: "brightness(85%)",
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  actionMobile: {
    [theme.breakpoints.up("xl")]: {
      display: "none",
    },
  },
  spinner: {
    position: "absolute",
    bottom: 10,
    left: "calc(50% - 20px)",
  },
  successText: {
    width: "100%",
    textAlign: "center",
    fontWeight: 500,
    color: "#059669",
  },
  errorText: {
    width: "100%",
    textAlign: "center",
    fontWeight: 500,
    color: "#DC2626",
  },
});
export default withStyles(styles)(Modal);
