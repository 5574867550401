import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";

import styles from "./styles";
import Dashboard from "./Sections/Dashboard";
import ProvidersAndInsurance from "./Sections/ProvidersAndInsurance";
import ClientInfo from "./Sections/ClientInfo";
import Documents from "./Sections/Documents";
import Communication from "./Sections/Communication";
import Actions from "./Sections/Actions";
import Assessments from "./Sections/Assessments";
import BasicInformation from "./BasicInformation";
import { getUser, isClinician as getIsClinician } from "../../selectors";
import { ClientDetailsFormProvider } from "./ClientDetailsFormContext";
import ClientDetailsPageContentsButtons from "./ClientDetailsPageContentsButtons";
import { ClientAvailability } from "./Sections/ClientAvailability";
import { useGlobalToast } from "components/GlobalToastProvider";
import { useUpdateClientAvailability } from "./Sections/ClientAvailability/hooks";
import Typography from "@mui/material/Typography";
import Logs from "./Sections/Logs";
import * as selectors from "../../selectors";
import actions from "../../actions";

const ClientDetailsPageContents = (props) => {
  const {
    classes,
    isActive,
    customerDetails,
    currentUser,
    verified,
    isCustomerCare,
    onActiveTabChange,
    currentTab,
    updatingClientInfo,
    onCloseEditMode,
    userPermissions,
    resetTab,
    submitButtonDisabled,
    editMode,
    visibleTabs,
    onSubmitUpdateUserStatus,
    onChangeAvailability,
    navigate,
  } = props;

  const dispatch = useDispatch();
  const { section } = useParams();
  const user = useSelector(getUser);
  const isClinician = useSelector(getIsClinician);
  const { showToast } = useGlobalToast();
  const [isPanelOpen, setIsPanelOpen] = React.useState(false);
  const [dataPanel, setDataPanel] = React.useState({});
  const [configPanel, setConfigPanel] = React.useState([]);
  const [titlePanel, setTitlePanel] = React.useState("");
  const [inputsForm, setInputsForms] = React.useState([]);
  const [isDirty, setIsDirty] = useState(true);
  const updatingClientInfoSuccess = useSelector(selectors.getUpdatingClientInfoSuccess);
  const updateUserInsuranceStatusSuccess = useSelector(
    selectors.getUpdateUserInsuranceStatusSuccess
  );
  const updatingClientInfoFail = useSelector(selectors.getUpdatingClientInfoFail);
  const updateCustomerEmailErrorMessage = useSelector(selectors.getUpdateCustomerEmailErrorMessage);

  useEffect(() => {
    onActiveTabChange(section);
  }, [section]);

  const updateDataPanel = (key, value) => {
    setDataPanel((prevDataPanel) => ({
      ...prevDataPanel,
      [key]: value,
    }));
  };

  const updateTitlePanel = (title) => {
    setTitlePanel(title);
  };

  const resetDataPanel = () => {
    setDataPanel({});
  };

  const copyToDataPanel = (newData) => {
    setDataPanel(newData);
  };

  const updateInputsForm = (newInputsForm) => {
    setInputsForms(newInputsForm);
  };

  const updateIsDirty = (value) => {
    setIsDirty(value);
  };

  const editDashboardDisabled = isActive === false;

  const isAssignedClinician = (currentUser, customerDetails) => {
    return (
      currentUser?.id === customerDetails?.clinician?.clinician_user_id ||
      currentUser?.id === customerDetails?.secondaryClinician?.clinician_user_id
    );
  };

  const { mutate: saveAvailableHours } = useUpdateClientAvailability({
    onSuccess: () => {
      finishSubmit("Success!");
    },
    onError: () => {
      finishSubmit("Whoops! Something went wrong", true);
    },
  });

  const [newAvailableHours, setNewAvailableHours] = useState(null);

  /**
   * Add here all the updates need to do on the save
   *
   * The method called will need to have a handle change
   * data in fields to not execute extra updates
   */
  const onSubmitGlobalEdit = () => {
    if (currentTab === "client-availability") {
      saveAvailableHours({ newAvailableHours, clientInfo: customerDetails });
    } else if (currentTab === "account-settings") {
      onSubmitUpdateUserStatus();
    }
  };

  useEffect(() => {
    if (updatingClientInfoSuccess || updateUserInsuranceStatusSuccess) {
      finishSubmit("Success!");
    }

    if (updatingClientInfoFail) {
      finishSubmit("Whoops! Something went wrong", true);
    }

    if (updateCustomerEmailErrorMessage) {
      finishSubmit(`Error: ${updateCustomerEmailErrorMessage}`, true);
    }
  }, [updatingClientInfoSuccess, updateUserInsuranceStatusSuccess, updatingClientInfoFail]);

  const finishSubmit = (message, isError = false) => {
    showToast({
      message: message,
      errorState: isError,
    });
    resetTab(currentTab);
    dispatch(actions.resetClientInfoState());
    dispatch(actions.clearUpdateUserInsuranceStatus());
  };

  const determineChatUrl = (
    isCustomerCare,
    verified,
    isClinician,
    customerDetails,
    currentUser
  ) => {
    return isCustomerCare
      ? customerDetails?.details?.support_conversation_url
      : verified && isClinician && isAssignedClinician(currentUser, customerDetails)
        ? customerDetails?.details?.active_conversation_url
        : null;
  };

  const tabs = [
    {
      label: "Dashboard",
      link: "dashboard",
      component: (
        <Dashboard
          editDisabled={editDashboardDisabled}
          isAssignedClinician={isAssignedClinician(currentUser, customerDetails)}
          {...props}
        />
      ),
    },
    {
      label: "Profile",
      link: "profile",
      component: <ClientInfo editDisabled={editDashboardDisabled} {...props} />,
    },
    {
      label: "Availability",
      link: "client-availability",
      component: (
        <ClientAvailability
          editMode={editMode}
          basicInfo={customerDetails}
          editAvailabilityHoursPerm={userPermissions?.edit_client_availability}
          viewAvailabilityHoursPerm={userPermissions?.view_client_availability}
          newAvailableHours={newAvailableHours}
          setNewAvailableHours={setNewAvailableHours}
          onChangeAvailability={onChangeAvailability}
          classes={classes}
        />
      ),
    },
    {
      label: "Providers & Insurance",
      link: "insurance",
      component: (
        <ProvidersAndInsurance
          dataPanel={dataPanel}
          updateDataPanel={updateDataPanel}
          resetDataPanel={resetDataPanel}
          copyToDataPanel={copyToDataPanel}
          configPanel={configPanel}
          setConfigPanel={setConfigPanel}
          updateTitlePanel={updateTitlePanel}
          setIsPanelOpen={setIsPanelOpen}
          isPanelOpen={isPanelOpen}
          updateInputsForm={updateInputsForm}
          inputsForm={inputsForm}
          titlePanel={titlePanel}
          editDisabled={editDashboardDisabled}
          isDirty={isDirty}
          updateIsDirty={updateIsDirty}
          {...props}
        />
      ),
    },
    {
      label: "Assessments",
      link: "assessments",
      component: <Assessments basicInfo={customerDetails} />,
    },
    {
      label: "Documents",
      link: "documents",
      component: <Documents editDisabled={editDashboardDisabled} {...props} />,
    },
    {
      label: "Communication",
      link: "communication",
      component: <Communication {...props} />,
    },
    {
      label: "Logs",
      link: "logs",
      component: <Logs {...props} />,
    },
    {
      label: "Account Settings",
      link: "account-settings",
      component: <Actions {...props} editDisabled={editDashboardDisabled} />,
    },
  ];

  const selectedTab =
    tabs.find(
      (tab) =>
        visibleTabs?.some((includedTab) => includedTab.link === tab.link) && tab.link === section
    ) || "dashboard";

  const chatUrl = determineChatUrl(
    isCustomerCare,
    verified,
    isClinician,
    customerDetails,
    currentUser
  );

  return (
    <div style={{ width: section === "dashboard" ? "75%" : "100%" }}>
      <ClientDetailsFormProvider editMode={editMode} currentTab={currentTab}>
        {section === "dashboard" && (
          <>
            <div className={classes.sectionTitleContainer}>
              <Typography>
                Client Details <span> | {selectedTab?.label} </span>
              </Typography>
              {userPermissions?.chat && chatUrl && (
                <Button variant="text" onClick={() => navigate(`/chat/${chatUrl}`)} size="large">
                  Chat with Client
                </Button>
              )}
            </div>
            <BasicInformation {...props} user={user} customerDetails={customerDetails} />
          </>
        )}
        {selectedTab?.component}
        {editMode ? (
          <ClientDetailsPageContentsButtons
            updatingClientInfo={updatingClientInfo}
            onCloseEditMode={onCloseEditMode}
            submitButtonDisabled={submitButtonDisabled}
            onSubmitGlobalEdit={onSubmitGlobalEdit}
            currentTab={currentTab}
            resetTab={() => resetTab(currentTab)}
          />
        ) : (
          ""
        )}
      </ClientDetailsFormProvider>
    </div>
  );
};

export default withStyles(styles)(ClientDetailsPageContents);
