export default (theme) => ({
  dialogTitle: {
    padding: "40px 50px",
    fontSize: 24,
    fontWeight: 500,
  },
  dialogContent: {
    padding: "0 50px 30px",
    width: "100%",
    overflowY: "unset",
    "& p": {
      fontSize: 14,
      color: "#3f4456",
      fontWeight: 500,
      lineHeight: "20px",
    },
  },
  dialogButtons: {
    width: "100%",
    margin: "10px auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    boxSizing: "border-box",
    "& button": {
      fontSize: 12,
    },
  },
  reasonTextfield: {
    margin: "10px 0px -5px",
    "& fieldset": {
      borderRadius: 7,
      border: "0.5px solid #C3C6D2",
    },
    "& .MuiOutlinedInput-root": {
      fontSize: 14,
      color: "#545a72",
      fontWeight: 500,
      letterSpacing: "-2%",
      "&:hover fieldset": {
        border: "1px solid #C3C6D2",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid #C3C6D2",
      },
    },
  },
});
