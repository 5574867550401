import React, { useEffect, useState } from "react";
import styles from "../styles/formDialogStyles";
import Typography from "@mui/material/Typography";
import Logo from "../../../assets/answers_now_logo_dark_purple.png";
import LogoMobile from "../../../assets/an_purple_butterfly_logo.png";
import Button from "@mui/material/Button";
import withStyles from "@mui/styles/withStyles";
import PandaDocContainer from "components/PandaDocContainer";
import formSummary from "./formSummary";
import formSummaryVector from "../../../assets/form_summary_vector.svg";
import { ArrowArcRight } from "@phosphor-icons/react";

const Form = ({
  classes,
  formData,
  index,
  handleNext,
  handleBack,
  setFormComplete,
  completed,
  handleSkip,
  isLoading,
  skipIsLoading,
}) => {
  const formSummaryData = formSummary[formData?.type] || {};
  return (
    <div className={classes.formContainer}>
      <div className={classes.innerFormContainer}>
        <div className={classes.form}>
          <div className={classes.logoContainer}>
            <img className={classes.logo} src={Logo} alt="AnswersNow" title="AnswersNow" />
            <img
              className={classes.logoMobile}
              src={LogoMobile}
              alt="AnswersNow"
              title="AnswersNow"
            />
          </div>
          {formSummaryData && formSummaryData.title && (
            <div className={classes.formSummaryContainer}>
              <Typography variant="h3" color="primary">
                {formSummaryData.title}
              </Typography>
              {formSummaryData.summaries.length > 0 &&
                formSummaryData.summaries.map((summary, index) => {
                  return (
                    <div key={`summary-${index}`} className={classes.formSummary}>
                      <div className={classes.formSummaryIconContainer}>
                        <div className={classes.formSummaryVector}>
                          <img src={formSummaryVector} alt="Form Summary" />
                        </div>
                        {summary.icon}
                      </div>
                      <Typography variant="h6" color={(theme) => theme.palette.text.secondary}>
                        {summary.text}
                      </Typography>
                    </div>
                  );
                })}
            </div>
          )}
          <div className={classes.pandaDocContainer}>
            <PandaDocContainer
              pandaDocId={formData?.panda_doc_session_id}
              onDocumentCompleted={setFormComplete}
            />
          </div>
          <div className={classes.buttonContainer}>
            <div className={classes.divider} />
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              disabled={!completed}
              fullWidth
              size="large"
            >
              Submit
            </Button>
            {formData?.allow_skip && (
              <Button
                variant="outlined"
                color="default"
                onClick={() => handleSkip(formData.pkey)}
                disabled={skipIsLoading || isLoading || completed}
                fullWidth
                endIcon={<ArrowArcRight />}
              >
                Skip
              </Button>
            )}
            {index !== 0 && (
              <Button variant="text" color="primary" onClick={handleBack} size="large">
                Back
              </Button>
            )}
          </div>
          <Typography component="p" variant="body2" textAlign="center">
            Have questions? Reach us directly at{" "}
            <span style={{ color: "#8E42CA" }}>804-215-5600</span>
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(Form);
