import React from "react";
import SectionBox from "../../../../elements/SectionBox";
import {
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import EditTable from "./EditTable";
import InviteMembers from "./InviteMembers";
import { useGlobalToast } from "components/GlobalToastProvider";
import { useRemoveTeamMember } from "hooks/api/useRemoveTeamMember";
import ANTooltip from "../../../../elements/ANTooltip";
import { useFormikContext } from "formik";
import { useInviteMember } from "hooks/api/useInviteMember";
import { useDeleteTeamMemberInvitation } from "hooks/api/useDeleteTeamMemberInvitation";
import { useSelector } from "react-redux";
import * as selectors from "selectors";

const TeamSection = ({
  customerInfo,
  editMode,
  onOpenMemberPanel,
  refetchTeamMembers,
  teamData,
  teamRoles,
  clientId,
  setOpenGuardianDialog,
  hasLegalGuardian,
}) => {
  const theme = useTheme();
  const { showToast } = useGlobalToast();
  const { id: teamId } = teamData;
  const teamName = teamData?.name || "Team";
  const isCaregiver = useSelector(selectors.isCaregiver);
  const userId = useSelector(selectors.getUserId);

  const { removeTeamMemberMutation } = useRemoveTeamMember();
  const { inviteMemberMutation } = useInviteMember();
  const { deleteTeamMemberInvitationMutation } = useDeleteTeamMemberInvitation();
  const { values, initialValues } = useFormikContext();

  const handleRemoveMember = ({ userId, name, hasAcceptedInvite, email, invitationGuid }) => {
    if (!hasLegalGuardian(values, email)) {
      setOpenGuardianDialog(true);
      return;
    }

    const initialDesignatedCaregiver = initialValues.teamSection.teamMembers.find(
      (member) => member.isDesignatedCaregiver
    );

    // Prevent removing the designated caregiver without saving changes (BE will see
    // this as trying to remove the only designated caregiver, and throw an error), and
    // show an informative message to the user
    if (initialDesignatedCaregiver?.userId === userId) {
      showToast({ message: `Please save your changes before removing the team member` });
      return;
    }

    const onSuccess = () => {
      refetchTeamMembers();
      showToast({
        message: `${hasAcceptedInvite ? name : email} was successfully removed from the ${teamName}`,
      });
    };

    if (hasAcceptedInvite) {
      removeTeamMemberMutation({ teamId: teamId, userId }, { onSuccess });
    } else {
      deleteTeamMemberInvitationMutation({ invitationGuid }, { onSuccess });
    }
  };

  const handleResendInvite = ({ email }) => {
    inviteMemberMutation(
      { teamId, email, clientId },
      {
        onSuccess: () => {
          refetchTeamMembers();
          showToast({
            message: `Invitation successfully sent`,
          });
        },
      }
    );
  };

  const canViewMemberDetail = (currentMember) => {
    if (!currentMember?.hasAcceptedInvite) return false;
    const isOwnDetails = isCaregiver && currentMember.userId === userId;
    if (isOwnDetails || !isCaregiver) return true;
    return false;
  };

  return (
    <SectionBox
      title={teamName}
      description={`${customerInfo?.name}'s external support team, including family members, caregivers, and other professionals, involved in their care.`}
      columnDirection
      padding={theme.spacing(5, 0)}
    >
      <Stack gap={7}>
        <TableContainer
          component={"div"}
          sx={{ borderRadius: theme.radius.large, border: `1px solid ${theme.palette.divider}` }}
        >
          <Table sx={{ tableLayout: "fixed" }}>
            <TableHead>
              <TableRow>
                <ANTooltip component={TableCell}>Member</ANTooltip>
                <ANTooltip component={TableCell}>Relationship</ANTooltip>
                <ANTooltip component={TableCell}>Legal Guardian</ANTooltip>
                <ANTooltip component={TableCell}>Designated Caregiver</ANTooltip>
                {editMode && <TableCell sx={{ width: "68px" }}></TableCell>}
              </TableRow>
            </TableHead>

            <TableBody>
              {values.teamSection?.teamMembers?.map((row, index) => {
                const isInviteExpired =
                  row?.expirationDate && new Date(row.expirationDate) < new Date();
                const inviteStatus = isInviteExpired ? "Expired Invite" : "Pending Invite";
                return (
                  <TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell>
                      <Stack>
                        {canViewMemberDetail(row) ? (
                          <ANTooltip
                            component={Link}
                            onClick={() => onOpenMemberPanel(row.id)}
                            underline="hover"
                          >
                            {row.name}
                          </ANTooltip>
                        ) : (
                          <Typography
                            component="p"
                            variant="body2"
                            noWrap={true}
                            title={row.hasAcceptedInvite ? row.name : row.email}
                          >
                            {row.hasAcceptedInvite ? row.name : row.email}
                          </Typography>
                        )}

                        <ANTooltip component={Typography} variant="body2" color="text.secondary">
                          {row.hasAcceptedInvite ? row.email : inviteStatus}
                        </ANTooltip>
                      </Stack>
                    </TableCell>
                    {editMode ? (
                      <EditTable
                        row={row}
                        rowIndex={index}
                        teamRoles={teamRoles}
                        onRemoveMember={() => handleRemoveMember(row)}
                        onEditProfile={() => onOpenMemberPanel(row.id, "edit")}
                        onResendInvite={() => handleResendInvite(row)}
                      />
                    ) : (
                      <>
                        <ANTooltip component={TableCell}>
                          {teamRoles.find((el) => el.id === row.relationshipType)?.name}
                        </ANTooltip>
                        <TableCell>{row.isLegalGuardian ? "Yes" : "No"}</TableCell>
                        <TableCell>{row.isDesignatedCaregiver ? "Yes" : "No"}</TableCell>
                      </>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        {editMode && (
          <InviteMembers
            title={`Invite Members to ${teamName}`}
            teamId={teamId}
            refetchTeamMembers={refetchTeamMembers}
            clientId={clientId}
          />
        )}
      </Stack>
    </SectionBox>
  );
};
export default TeamSection;
