import React from "react";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import CalendarSyncImageUnknown from "../../assets/cal_sync_2.png";
import GoogleGIcon from "../../assets/google_G_icon.png";
import MicrosoftIcon from "../../assets/microsoft_icon.png";
import { Envelope } from "@phosphor-icons/react";
import { LoadingButton } from "@mui/lab";
import TextField from "@mui/material/TextField";

const OptionalSync = (props) => {
  const {
    classes,
    handleSyncButtonClick,
    isLoading,
    associatedEmail,
    setAssociatedEmail,
    onClose,
    isGoogle,
  } = props;

  return (
    <>
      <div className={classes.dialogContentText}>
        <Typography variant="h5" align="center">
          Integrate your AnswersNow and External Calendars
        </Typography>
        <Typography variant="body2" className={classes.body2} align="center">
          Sync with your Google or Microsoft calendar to automatically add AnswersNow events to your
          external calendar and vice versa
        </Typography>
      </div>
      <img src={CalendarSyncImageUnknown} />
      <div className={classes.dialogContentOptionalSync}>
        <div className={classes.emailContainer}>
          <TextField
            id="associated-email"
            label="Email Address"
            value={associatedEmail}
            onChange={(e) => setAssociatedEmail(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Envelope />
                </InputAdornment>
              ),
            }}
            helperText="Confirm the email address associated with your Google or Microsoft calendar"
          />
        </div>
        <div className={classes.buttonContainer}>
          <LoadingButton
            onClick={() => handleSyncButtonClick(true)}
            color="secondary"
            loading={isGoogle && isLoading}
            disabled={isLoading}
            startIcon={<img src={GoogleGIcon} style={{ height: 20, width: 20 }} />}
            fullWidth
          >
            Sign in with Google
          </LoadingButton>
          <LoadingButton
            onClick={() => handleSyncButtonClick(false)}
            color="secondary"
            loading={!isGoogle && isLoading}
            disabled={isLoading}
            startIcon={<img src={MicrosoftIcon} style={{ height: 20, width: 20 }} />}
            fullWidth
          >
            Sign in with Microsoft
          </LoadingButton>
        </div>
        <Button onClick={onClose} variant="text" color="primary">
          Dismiss
        </Button>
      </div>
    </>
  );
};

export default OptionalSync;
