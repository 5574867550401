import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { X } from "@phosphor-icons/react";
import React, { useState } from "react";

export default function NoShowEndCallDialog({
  open,
  onCloseNoShowEndCallDialog,
  markClientNoShow,
  handleEndCall,
}) {
  const theme = useTheme();
  const [markAsNoShow, setmarkAsNoShow] = useState(false);
  return (
    <Dialog open={open} onClose={onCloseNoShowEndCallDialog} maxWidth="xs">
      <DialogTitle>
        End Call?
        <IconButton aria-label="close" onClick={onCloseNoShowEndCallDialog}>
          <X />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Alert severity="warning">
          Are you sure you want to end the call? Ending the call will end for all participants.
        </Alert>
        <Stack
          gap="8px"
          sx={{
            padding: theme.spacing(5),
            backgroundColor: theme.palette.action.disabledBackground,
            borderRadius: theme.radius["large"],
            marginTop: theme.spacing(5),
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={markAsNoShow}
                onChange={(e) => setmarkAsNoShow(e.target.checked)}
              />
            }
            label="Mark client as no-show"
          />
          <Typography variant="body2">
            Mark whether or not the client attended the scheduled event
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseNoShowEndCallDialog} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            if (markAsNoShow) markClientNoShow();
            handleEndCall();
          }}
          color="error"
        >
          End Call
        </Button>
      </DialogActions>
    </Dialog>
  );
}
