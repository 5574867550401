import React, { useEffect, useRef } from "react";

import "./index.css";

/** @typedef {{label:string,value:string,unit?:string,fallback?:string}} DebugLineConfig */
/** @typedef {{key:string,"altKey"?:boolean,"ctrlKey"?:boolean,"metaKey"?:boolean,"shiftKey"?:boolean}} KeySequence */

/**
 * @type {import('react').FunctionComponent<{
 *  enabled:boolean,
 *  devOnly:boolean,
 *  linesJson: string,
 *  keySequence: KeySequence,
 *  onClose:()=>{}
 * }>}
 */
const DebugOverlay = function DebugOverlay({
  children = <></>,
  devOnly,
  keySequence,
  linesJson,
  onClose = () => {},
}) {
  if (devOnly && process.env.NODE_ENV !== "development") return null;

  // use keySequence to toggle on/off
  const enabledRef = useRef(false);
  function debugOverlayKeyDownHandler(/** @type {KeyboardEvent} */ ev) {
    const { key, ...modifiers } = keySequence;
    if (ev.key === key) {
      // no modifier required
      if (!Object.keys(modifiers).length) {
        return (enabledRef.current = !enabledRef.current);
      }

      // ensure all modifiers are met
      for (const modifierKey in modifiers) {
        if (ev[modifierKey] != modifiers[modifierKey]) {
          return;
        }

        ev.preventDefault();

        // all modifiers match, toggle the hook
        enabledRef.current = !enabledRef.current;

        console.log("toggling debug overlay");
      }
    }
  }
  useEffect(() => {
    document.addEventListener("keydown", debugOverlayKeyDownHandler);
    return () => {
      document.removeEventListener("keydown", debugOverlayKeyDownHandler);
    };
  }, [keySequence]);

  /** @type {DebugLineConfig[]} */
  const initialLines = [];
  const linesRef = useRef(initialLines);
  useEffect(() => {
    try {
      linesRef.current = JSON.parse(linesJson || "[]");
    } catch (e) {
      console.error(e);
      linesRef.current = [];
    }
  }, [linesJson]);

  function onClickClose() {
    enabledRef.current = false;
    onClose();
  }

  if (!enabledRef.current) return null;

  return (
    <div className="debugOverlayContainer">
      <code onClick={onClickClose}>X</code>
      {linesRef.current.map((line) => (
        <div key={`debugOverlayLine_${line.label}`}>
          <span>{line.label}</span>
          <span>
            {line.value || line.fallback || ""}
            {line.unit || ""}
          </span>
        </div>
      ))}
      {/* {children} */}
    </div>
  );
};

export default DebugOverlay;
