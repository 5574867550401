import React from "react";
import withStyles from "@mui/styles/withStyles";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import SubHeading from "./SubHeading";

const DetailSection = (props) => {
  const { classes, loading, title, halfWidth, minHeight, maxHeight, noPadding, columnDirection } =
    props;
  let style = {};
  style.minHeight = minHeight ? minHeight : 150;
  style.maxHeight = maxHeight ? maxHeight : null;
  style.padding = noPadding ? 0 : null;

  return (
    <div
      className={halfWidth ? classes.halfWidthContainer : classes.fullWidthContainer}
      style={style}
    >
      <div className={columnDirection ? classes.columnContentContainer : classes.contentContainer}>
        {loading ? (
          <div className={classes.loading} style={minHeight ? { minHeight } : null}>
            <CircularProgress size={25} color="primary" style={{ margin: 10 }} />
            <Typography component="p">Loading</Typography>
          </div>
        ) : (
          <>
            <Typography component="h1" className={classes.title}>
              {title}
            </Typography>
            {props.children}
          </>
        )}
      </div>
    </div>
  );
};

const styles = (theme) => ({
  fullWidthContainer: {
    width: "100%",
    height: "fit-content",
    marginBottom: 40,
    boxShadow: "0px 4px 5px -1px rgba(210,179,234,0.77)",
    webkitBoxShadow: " 0px 4px 5px -1px rgba(210,179,234,0.77)",
    mozBoxShadow: " 0px 4px 5px -1px rgba(210,179,234,0.77)",
    borderTop: "none",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    overflow: "hidden",
    "& p": {
      letterSpacing: ".8px",
    },
  },
  halfWidthContainer: {
    width: "50%",
    height: "fit-content",
    margin: "20px 0",
    boxShadow: "0px 4px 5px -1px rgba(210,179,234,0.77)",
    webkitBoxShadow: " 0px 4px 5px -1px rgba(210,179,234,0.77)",
    mozBoxShadow: " 0px 4px 5px -1px rgba(210,179,234,0.77)",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    overflow: "hidden",
    "& p": {
      letterSpacing: ".8px",
    },
  },
  contentContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "25px 40px",
  },
  columnContentContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    padding: "25px 40px",
  },
  loading: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& p": {
      fontSize: 18,
    },
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    marginBottom: 5,
    fontWeight: 500,
  },
});

export default withStyles(styles)(DetailSection);
