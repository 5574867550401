import { Alert, AlertTitle } from "@mui/material";
import React from "react";
import { Warning } from "@phosphor-icons/react";

const RequestedEdits = (props) => {
  const { requestedEdits } = props;

  return (
    <Alert
      sx={{ width: "100%" }}
      severity="warning"
      icon={<Warning size={22} color="rgba(237, 108, 2, 1)" />}
    >
      <AlertTitle sx={{ fontSize: 16 }}>Requested changes from your clinical supervisor</AlertTitle>
      {requestedEdits}
    </Alert>
  );
};

export default RequestedEdits;
