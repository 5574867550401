import React from "react";
import Typography from "@mui/material/Typography";

export default ({ children, dir }) => {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
};
