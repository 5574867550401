import { createReducer } from "redux-create-reducer";
import actions from "../actions";

const initialState = {
  chats: [],
  page: 0,
  rowsPerPage: 25,
  count: 0,
  sort: "",
  sortdirection: "asc",
  filter: true,
  chatLoaded: false,
  chatsLoading: false,
  chat: {},
  messages: [],
  tags: [],
  messageTags: [],
  tagsLoading: false,
};

const reducers = {
  [actions.LOAD_CHAT_LIST](state) {
    return {
      ...state,
      chatsLoading: true,
    };
  },
  [actions.SET_CHAT_LIST](state, { payload }) {
    return {
      ...state,
      chats: payload.chats,
      count: payload.count,
      chatsLoading: false,
      saving: false,
    };
  },
  [actions.SET_CHATS_PAGE](state, { payload }) {
    return {
      ...state,
      page: payload.page,
    };
  },
  [actions.SET_CHATS_PER_PAGE](state, { payload }) {
    return {
      ...state,
      rowsPerPage: payload.rowsPerPage,
    };
  },
  [actions.SET_CHATS_SORT](state, { payload }) {
    let sortdirection = state.sortdirection;
    if (state.sort && state.sort !== payload.sort) {
      sortdirection = "asc";
    } else if (state.sort) {
      sortdirection = sortdirection === "asc" ? "desc" : "asc";
    }
    return {
      ...state,
      sort: payload.sort,
      sortdirection,
    };
  },
  [actions.SET_CHATS_FILTER](state, { payload }) {
    return {
      ...state,
      filter: payload.filter,
    };
  },
  [actions.GET_CHAT_DETAILS](state) {
    return {
      ...state,
      chatLoaded: false,
    };
  },
  [actions.SET_CHAT_DETAILS](state, { payload }) {
    return {
      ...state,
      chat: payload.chat,
    };
  },
  [actions.LOAD_CHAT_MESSAGES](state, { payload }) {
    return {
      ...state,
      chatLoaded: true,
      messages: payload.messages,
    };
  },
  [actions.SET_CHAT_TAGS](state, { payload }) {
    return {
      ...state,
      tags: payload,
    };
  },
  [actions.GET_CHAT_MESSAGE_TAGS](state) {
    return {
      ...state,
      tagsLoading: true,
    };
  },
  [actions.SET_CHAT_MESSAGE_TAGS](state, { payload }) {
    return {
      ...state,
      messageTags: payload,
      tagsLoading: false,
    };
  },
};

export default createReducer(initialState, reducers);
