import React, { Component } from "react";
import Button from "@mui/material/Button";
import { format, addYears } from "date-fns";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import SignatureCanvas from "react-signature-canvas";
import Logo from "../../../assets/an_purple_gray.png";
import { convertTokens } from "@date-fns/upgrade/v2";
import LoadingButton from "@mui/lab/LoadingButton";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const PhiReleaseForm = (props) => {
  const {
    classes,
    firstName,
    lastName,
    phone,
    clientName,
    clientLastName,
    clientAddress1,
    clientAddress2,
    clientCity,
    clientState,
    clientZip,
    clientDOB,
    signatureStarted,
    sendingDocumentRequestSignatureLoading,
    savePhiReleaseSignatureSuccess,
    savePhiReleaseSignatureError,
    phiFormDialogOpen,
    savePhiReleaseSignature,
    phiReleaseFormSignatureRef,
    startSignature,
    physicians,
    formatPhoneNumber,
    phiReleaseSaving,
  } = props;
  return (
    <Dialog
      open={phiFormDialogOpen}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="dialog-diagnosis-form"
      aria-describedby="dialog-diagnosis-form-description"
      PaperProps={{
        style: {
          maxWidth: "100%",
          width: 800,
          margin: 0,
        },
      }}
    >
      <DialogTitle
        id="alert-dialog-slide-title"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          className={classes.formLogo}
          style={{ marginBottom: "0 !important" }}
          src={Logo}
          alt="AnswersNow"
          title="AnswersNow"
        />
        <Typography component="p" style={{ fontSize: 10, textAlign: "center", marginBottom: 10 }}>
          1717 E Cary Street, Richmond Virginia, 23223 | Telephone: 804 215 5600 Fax: 804 800 9329
        </Typography>
        <Typography component="p" className={classes.phiTitle}>
          HIPAA AUTHORIZATION TO RELEASE PATIENT INFORMATION
        </Typography>
      </DialogTitle>
      <DialogContent>
        <div className={classes.phiListItemDetailContainer}>
          <Typography
            component="p"
            className={classes.phiListItemDetailLabel}
            style={{ fontWeight: 500 }}
          >
            Patient's Full Name:{"    "}
          </Typography>
          <Typography
            component="p"
            className={classes.phiListItemDetailValue}
            style={{ fontWeight: 500 }}
          >
            {clientName + " " + clientLastName}
          </Typography>
        </div>
        <div className={classes.phiListItemDetailContainer}>
          <Typography component="p" className={classes.phiListItemDetailLabel}>
            Patient's Date of Birth:{"    "}
          </Typography>
          <Typography component="p" className={classes.phiListItemDetailValue}>
            {format(new Date(clientDOB), convertTokens("DD-MM-YYYY"))}
          </Typography>
        </div>
        <div className={classes.phiListItemDetailContainer}>
          <Typography component="p" className={classes.phiListItemDetailLabel}>
            Patient's Address:
          </Typography>
          <Typography component="p" className={classes.phiListItemDetailValue}>
            {`${clientAddress1?.trim()}${clientAddress2 ? " " + clientAddress2?.trim() : ""}`}{" "}
            {clientCity?.trim()}, {clientState} {clientZip}
          </Typography>
        </div>
        <div className={classes.phiListItemDetailContainer}>
          <Typography component="p" className={classes.phiListItemDetailLabel}>
            Patient's Legal Guardian/Parent:
          </Typography>
          <Typography component="p" className={classes.phiListItemDetailValue}>
            {firstName} {lastName}
          </Typography>
        </div>
        <div className={classes.phiListItemDetailContainer}>
          <Typography component="p" className={classes.phiListItemDetailLabel}>
            Telephone Number:
          </Typography>
          <Typography component="p" className={classes.phiListItemDetailValue}>
            {phone ? formatPhoneNumber(phone) : ""}
          </Typography>
        </div>
        <div className={classes.divider} />
        <Typography component="p" className={classes.phiSubtitle}>
          I hereby request that AnswersNow use / disclose my protected health information (PHI) as
          directed below. Specifically, I request that my PHI:
        </Typography>
        <div className={classes.phiFormList}>
          <Typography component="p" className={classes.phiListItem}>
            1. From the Following Care Center locations and/or providers:
          </Typography>
          {physicians &&
            physicians.map((physician) => {
              return (
                <React.Fragment key={physician?.id}>
                  <div className={classes.phiListItemDetailContainer}>
                    <Typography component="p" className={classes.phiListItemDetailLabel}>
                      Physician Name:
                    </Typography>
                    <Typography component="p" className={classes.phiListItemDetailValue}>
                      {`${physician?.first_name} ${physician?.last_name}`}
                    </Typography>
                  </div>
                  <div className={classes.phiListItemDetailContainer}>
                    <Typography component="p" className={classes.phiListItemDetailLabel}>
                      Physician Address:
                    </Typography>
                    <Typography component="p" className={classes.phiListItemDetailValue}>
                      {`${
                        !!physician?.physical_address ? physician?.physical_address.trim() : "--"
                      }${!!physician?.npi_number ? ` ${physician?.npi_number?.trim()}` : ""}`}{" "}
                      {physician?.city ? physician.city.trim() : "--"}{" "}
                      {physician?.state ? ", " + physician.state : ""}
                      {physician?.zip_code ? physician.zip_code : ""}
                    </Typography>
                  </div>
                </React.Fragment>
              );
            })}

          <Typography component="p" className={classes.phiListItem}>
            2. Be faxed to the following person:
          </Typography>
          <Typography component="p" className={classes.phiListItemDetail}>
            AnswersNow Care Coordination team
          </Typography>
          <Typography component="p" className={classes.phiListItemDetail}>
            Fax Number: 804 800 9329
          </Typography>
          <Typography component="p" className={classes.phiListItem}>
            3. I authorize disclosure of the following specific information:
          </Typography>
          <Typography component="p" className={classes.phiListItemDetail}>
            Diagnostic evaluation/report completed by a doctorate level clinician
          </Typography>
          <Typography component="p" className={classes.phiListItemDetail}>
            MD prescription or signed coordination of care letter
          </Typography>
          <Typography component="p" className={classes.phiListItem}>
            4. I understand that I have the right to receive a copy of my PHI in the form and format
            and manner I request, if readily producible that way.
          </Typography>
          <Typography component="p" className={classes.phiListItem}>
            5. I understand that the information used or disclosed may be subject to re-disclosure
            by the person or class of persons or entity receiving it and would then no longer be
            protected by federal privacy regulations.
          </Typography>
          <Typography component="p" className={classes.phiListItem}>
            6. I understand I may remove this authorization by notifying AnswersNow in writing of my
            desire to revoke it.
          </Typography>
          <Typography component="p" className={classes.phiListItem}>
            7. This authorization expires on {format(new Date(addYears(new Date(), 1)), "PP")}
          </Typography>
          <div className={classes.divider} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Typography component="p" className={classes.phiListItem}>
              Client's Legal Guardian:
            </Typography>
            <Typography
              component="p"
              className={classes.phiListItem}
              style={{ fontWeight: 500, marginLeft: 10 }}
            >
              {firstName} {lastName}
            </Typography>
          </div>
          <Typography component="p" className={classes.phiListItem}>
            Signature of Client's Legal Guardian:
          </Typography>
          <div className={classes.signatureCanvas}>
            <SignatureCanvas
              ref={phiReleaseFormSignatureRef}
              onBegin={startSignature}
              penColor="#000"
              canvasProps={{
                width: window.innerWidth < 961 ? window.innerWidth * 0.9 : 700,
                height: 120,
              }}
            />
          </div>
        </div>
      </DialogContent>
      {savePhiReleaseSignatureError && (
        <Typography component="p" className={classes.errorMessage}>
          There was an error taking your signatures. Please try again.
        </Typography>
      )}
      <DialogActions>
        <LoadingButton
          disabled={!signatureStarted || savePhiReleaseSignatureSuccess}
          onClick={savePhiReleaseSignature}
          align="center"
          loading={phiReleaseSaving}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const styles = (theme) => ({
  formLogo: {
    width: 170,
    height: "auto",
    margin: "5px 0 20px 0",
    alignSelf: "center",
    [theme.breakpoints.down("xl")]: {
      margin: 0,
      width: 120,
    },
  },
  phiTitle: {
    fontSize: 18,
    fontWeight: 500,
    textAlign: "center",
    [theme.breakpoints.down("xl")]: {
      fontSize: 15,
    },
  },
  phiListItemDetailContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    margin: "0 30px",
    [theme.breakpoints.down("xl")]: {
      margin: "0 10px",
    },
  },
  phiListItemDetailLabel: {
    fontSize: 14,
    width: 240,
    [theme.breakpoints.down("xl")]: {
      width: "fit-content",
      fontSize: 12,
    },
  },
  phiListItemDetailValue: {
    fontSize: 14,
    margin: "5px 20px",
    [theme.breakpoints.down("xl")]: {
      fontSize: 12,
      margin: "5px",
    },
  },
  phiListItemDetail: {
    fontWeight: 500,
    marginLeft: 30,
    fontSize: 14,
    [theme.breakpoints.down("xl")]: {
      fontSize: 12,
      margin: "0 10px",
    },
  },
  divider: {
    width: "100%",
    height: 0,
    border: "1px solid #000",
    margin: "20px 0",
  },
  phiSubtitle: {
    fontSize: 14,
    [theme.breakpoints.down("xl")]: {
      fontSize: 12,
    },
  },
  phiFormList: {
    width: "100%",
    padding: 20,
    [theme.breakpoints.down("xl")]: {
      padding: 0,
    },
  },
  phiListItem: {
    fontSize: 14,
    margin: "15px 0 5px 0",
    [theme.breakpoints.down("xl")]: {
      fontSize: 12,
    },
  },
  signatureCanvas: {
    backgroundColor: "#fff",
    borderRadius: 8,
    border: "2px dashed #c4c4c4",
    overflow: "hidden",
    margin: "10px auto",
  },
  errorMessage: {
    fontSize: 14,
    color: "red",
    textAlign: "center",
    margin: "10px auto",
  },
});

export default withStyles(styles)(PhiReleaseForm);
