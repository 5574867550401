import React from "react";

const withHooks = (WrappedComponent, hooks) => {
  return function WithHooks(props) {
    const hookValues = hooks.reduce((acc, hook) => {
      return { ...acc, ...hook() };
    }, {});

    return <WrappedComponent {...props} {...hookValues} />;
  };
};

export default withHooks;
