import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import actions from "../../actions";
import styles from "./styles";
import CaregiverSessions from "./CaregiverSessions";
import moment from "moment";
import { CircularProgress } from "@mui/material";

import {
  getUserId,
  getUserPermissionsList,
  getCustomerPastCallsLoading,
  getCustomerPastCalls,
  getClientId,
} from "selectors";

const mapStateToProps = (state) => ({
  userId: getUserId(state),
  clientId: getClientId(state),
  userPermissions: getUserPermissionsList(state),
  pastCallsLoading: getCustomerPastCallsLoading(state),
  pastCalls: getCustomerPastCalls(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setPageDetails: actions.setPageDetails,
      getCustomerPastCalls: actions.getCustomerPastCalls,
    },
    dispatch,
  );

class CaregiverSessionsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMissedCalls: false,
      groupedCalls: [],
      monthsArray: [],
      selectedMonth: "",
    };
    this.callTimelineRefs = {};
  }

  componentDidMount() {
    this.props.setPageDetails({
      pageName: "Sessions",
      currentPage: "sessions",
      menu: "caregiverSessions",
    });
    if (this.props.clientId) {
      this.props.getCustomerPastCalls({
        clientId: this.props.clientId,
        includeMissedCalls: this.state.showMissedCalls,
        monthLimit: true,
      });
    }
    this.getPastMonths();
  }
  componentDidUpdate(prevProps) {
    if (!prevProps.clientId && this.props.clientId) {
      this.props.getCustomerPastCalls({
        clientId: this.props.clientId,
        includeMissedCalls: this.state.showMissedCalls,
        monthLimit: true,
      });
    }
    if (prevProps.pastCallsLoading && !this.props.pastCallsLoading && this.props.pastCalls) {
      this.groupCalls(this.props.pastCalls);
      window.scrollTo(0, 0);
    }
  }
  toggleShowMissedCalls = () => {
    this.setState({ showMissedCalls: !this.state.showMissedCalls }, () => {
      this.props.getCustomerPastCalls({
        clientId: this.props.clientId,
        includeMissedCalls: this.state.showMissedCalls,
        monthLimit: true,
      });
      this.setState({ selectedMonth: this.state.monthsArray[0] });
    });
  };

  groupCalls = (calls) => {
    let groupedCalls = calls.reduce((result, call) => {
      const key = call["month_and_year"];
      if (!result[key]) {
        result[key] = [];
      }
      result[key].push(call);
      return result;
    }, {});
    this.setState({ groupedCalls });
  };

  getPastMonths = () => {
    let monthsArray = [];
    for (let i = 0; i <= 5; i++) {
      let month = moment().subtract(i, "months").format("MMM YYYY");
      monthsArray.push(month);
    }
    this.setState({ monthsArray, selectedMonth: monthsArray[0] });
  };

  onSelectMonth = (month) => {
    this.autoScroll(month);
    this.setState({ selectedMonth: month });
  };

  autoScroll = (month) => {
    this.callTimelineRefs[month].scrollIntoView(true);
    window.scrollBy({ left: 0, top: "-75", behavior: "smooth" });
  };

  navigate = (url) => {
    this.props.history.push(url);
  };

  render() {
    const { classes, pastCallsLoading } = this.props;
    return pastCallsLoading ? (
      <div className={classes.loadingContainer}>
        <CircularProgress size={30} color="primary" style={{ marginBottom: 10 }} />
        <Typography variant="h3">Loading</Typography>
      </div>
    ) : (
      <div className={classes.pageContainer}>
        <CaregiverSessions
          {...this.props}
          {...this.state}
          toggleShowMissedCalls={this.toggleShowMissedCalls}
          callTimelineRefs={this.callTimelineRefs}
          onSelectMonth={this.onSelectMonth}
          navigate={this.navigate}
        />
      </div>
    );
  }
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CaregiverSessionsContainer)),
);
