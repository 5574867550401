import React from "react";
import Button from "@mui/material/Button";
import { openS3Document } from "../../../utils/openS3Document";
import { format } from "date-fns";
import Typography from "@mui/material/Typography";
import Modal from "elements/Modal";

import { convertTokens } from "@date-fns/upgrade/v2";

const DocumentVersionsDialog = (props) => {
  const {
    classes,
    documentVersionsOpen,
    onToggleDocumentVersions,
    documentVersions,
    customerDetails,
  } = props;
  return (
    <Modal
      open={documentVersionsOpen}
      onClose={onToggleDocumentVersions}
      title="Document Versions"
      content={
        <div>
          {documentVersions?.map((version) => {
            return (
              <div key={version.VersionId} className={classes.documentVersionContainer}>
                <Typography
                  component="p"
                  className={classes.documentVersionText}
                  style={version.IsLatest ? { fontWeight: 500 } : null}
                >
                  Uploaded on:{" "}
                  {format(new Date(version.LastModified), convertTokens("MM/DD/YYYY h:mm a"))}
                </Typography>
                <Button
                  size="small"
                  onClick={async () =>
                    await openS3Document(
                      {
                        cognitoId: customerDetails.details.cognito_id,
                        filename: version.Key,
                        versionId: version.VersionId,
                      },
                      true
                    )
                  }
                >
                  View
                </Button>
              </div>
            );
          })}
        </div>
      }
      secondaryActionText="Close"
      secondaryActionOnClick={onToggleDocumentVersions}
    />
  );
};

export default DocumentVersionsDialog;
