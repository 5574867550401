import { select } from "redux-saga/effects";
import SecureRequest, { apiGet, apiPost } from "../utils/securerequest";
import {
  getVideoCallsPage,
  getVideoCallsPerPage,
  getVideoCallsSort,
  getVideoCallsSortDirection,
  getVideoCallsType,
  getUserId,
  getUserVideoCallsPage,
  getUserVideoCallsPerPage,
  getUserVideoCallsSort,
  getUserVideoCallsSortDirection,
  getUserVideoCallsType,
  getClinicianPastCallsSort,
  getClinicianPastCallsSortDirection,
  getClinicianUpcomingCallsSort,
  getClinicianUpcomingCallsSortDirection,
  getUserVideoFilterStartDate,
  getUserVideoFilterEndDate,
  getVideoFilterStartDate,
  getVideoFilterEndDate,
  getWeekStartDate,
  getWeekEndDate,
  getShowWeekCanceledCalls,
} from "../selectors";
import publicIp from "public-ip";
import { buildUrl } from "utils/buildUrl";

function* getNearestAwsMediaRegion() {
  const ipAddress = yield publicIp.v4();

  // we only want to fetch the region if it's not cached OR the IP addr changes
  const k = `media_region_${ipAddress.replace(".", "-")}`;

  // check cache (LS)
  let region = localStorage.getItem(k);
  if (region) {
    return yield region;
  }

  // try to get region - silently times out after 5.555s
  return yield Promise.race([
    new Promise((res) => setTimeout(res, 5555)),
    fetch("https://nearest-media-region.l.chime.aws/")
      .catch(console.error)
      .then(async (res) => {
        region = (await res.json()).region;
        region && localStorage.setItem(k, region);
        return region;
      }),
  ]);
}

export function* fetchMeetingConfig(videoId) {
  // get the nearest Media Region before making the call (cached by ip address)
  let MediaRegion = "us-east-1";
  try {
    MediaRegion = yield getNearestAwsMediaRegion();
  } catch (e) {
    conosle.error(e);
  }

  return yield apiGet(`/v2/video/${videoId}/meeting?MediaRegion=${MediaRegion}`);
}

export function* fetchMessagingConfig(videoId) {
  return yield apiGet(`/v2/video/${videoId}/channel`);
}

export function endMeeting(videoId) {
  return apiPost(`/v2/video/${videoId}/end`);
}

export function* startVideoCall(videoDetails) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.post(`/video/start`, videoDetails);
}

export function* endVideoCall(videoId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.put(`/video/${videoId}/end`, {});
}

export function* getNextClinicianCall(clinicianUserId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/video/${clinicianUserId}/next-call`);
}

export function* getClinicianCallsWeek(clinicianUserId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/video/${clinicianUserId}/upcoming-week`);
}

export function* getUpcomingVideo(clinicianUserId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/video/${clinicianUserId}/upcoming`);
}

export function* getRoomInfo(id) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/video/${id}/info`);
}

export function* getVideoIsRunning(roomId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/video/${roomId}/is-running`);
}

export function* updateRoomLayout(updatedState) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.put(`/video/${updatedState.roomId}/change-state`, updatedState);
}

export function* getAnimations() {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/video/animations`);
}

export function* getActivities() {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/video/activities`);
}

export function* getCards() {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/video/cards`);
}

export function* sendVideoSignatures(signatures) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.put(`/video/${signatures.videoId}/bcba-sign`, signatures);
}

export function* getVideoCallInfo(videoId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/video/${videoId}/details`);
}

export function* scheduleVideoCall(videoDetails) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.post(`/v3/appointments/${videoDetails.clientId}/schedule`, videoDetails);
}

/**
 * @deprecated
 * This function has been deprecated, in favor of rescheduleVideoCallAsync
 * to be used with react-query.
 */
export function* rescheduleVideoCall(videoDetails) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.put(`/v3/appointments/${videoDetails.videoId}/reschedule`, videoDetails);
}

export async function rescheduleVideoCallAsync(videoDetails) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.put(`/v3/appointments/${videoDetails.videoId}/reschedule`, videoDetails);
}

/**
 * @deprecated
 * This function has been deprecated, in favor of cancelVideoCallAsync
 * to be used with react-query.
 */
export function* cancelVideoCall(videoCall) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.put(`/v3/appointments/${videoCall.id}/cancel`, videoCall);
}

export async function cancelVideoCallAsync(videoCall) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.put(`/v3/appointments/${videoCall.id}/cancel`, videoCall);
}

export function* getVideoCallsList() {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const page = yield select(getVideoCallsPage);
  const size = yield select(getVideoCallsPerPage);
  const sort = yield select(getVideoCallsSort);
  const direction = yield select(getVideoCallsSortDirection);
  const type = yield select(getVideoCallsType);

  return yield Request.get(
    `/video/all?type=${type}&page=${page}&size=${size}&sort=${sort}&direction=${direction}`
  );
}

export function* getVideoCallsFuture() {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const page = yield select(getVideoCallsPage);
  const size = yield select(getVideoCallsPerPage);
  const sort = yield select(getVideoCallsSort);
  const direction = yield select(getVideoCallsSortDirection);

  return yield Request.get(
    `/video/future-calls?page=${page}&size=${size}&sort=${sort}&direction=${direction}`
  );
}

export function* getVideoCallsPast() {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const page = yield select(getVideoCallsPage);
  const size = yield select(getVideoCallsPerPage);
  const sort = yield select(getVideoCallsSort);
  const direction = yield select(getVideoCallsSortDirection);
  const filterStartDate = yield select(getVideoFilterStartDate);
  const filterEndDate = yield select(getVideoFilterEndDate);
  let dateFilter = "";
  if (filterStartDate && filterEndDate) {
    dateFilter = `&startdate=${new Date(filterStartDate).toISOString()}&enddate=${new Date(
      filterEndDate
    ).toISOString()}`;
  }

  return yield Request.get(
    `/video/past-calls?page=${page}&size=${size}&sort=${sort}&direction=${direction}${dateFilter}`
  );
}

export function* getUserVideoCalls(clientId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const page = yield select(getUserVideoCallsPage);
  const size = yield select(getUserVideoCallsPerPage);
  const sort = yield select(getUserVideoCallsSort);
  const direction = yield select(getUserVideoCallsSortDirection);
  const type = yield select(getUserVideoCallsType);
  const filterStartDate = yield select(getUserVideoFilterStartDate);
  const filterEndDate = yield select(getUserVideoFilterEndDate);
  let dateFilter = "";
  if (filterStartDate && filterEndDate) {
    dateFilter = `&startdate=${new Date(filterStartDate).toISOString()}&enddate=${new Date(
      filterEndDate
    ).toISOString()}`;
  }

  return yield Request.get(
    `/video/${clientId}/all-by-user?type=${type}&page=${page}&size=${size}&sort=${sort}&direction=${direction}${dateFilter}`
  );
}

export function* getVideoChatInfo({ id, isOtherServices }) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/v3/appointments/${id}?isNonVideo=${isOtherServices}`);
}

export function* getVideoCallBreakdown(videoId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/video/${videoId}/call-breakdown`);
}

export function* sendBillableTimeForVideo(billableTime) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.post(`/v2/billable-time`, billableTime);
}

export function* sendSessionNoteForVideo(sessionNote) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.post(`/v2/billable-time/session-note`, sessionNote);
}

export function* markClientNoShow(id) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.put(`/video/${id}/no-show`, {});
}

export function* getClinicianCalendarCalls(query) {
  const { month } = query;
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  if (month) {
    return yield Request.get(`/v2/clinician/calendar-calls?month=${month}`);
  }
  return yield Request.get(`/v2/clinician/calendar-calls`);
}

export function* getClinicianPastCalls() {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const sort = yield select(getClinicianPastCallsSort);
  const direction = yield select(getClinicianPastCallsSortDirection);
  const type = yield select(getVideoCallsType);
  return yield Request.get(
    `/v2/clinician/all-calls?type=${type}&sort=${sort}&direction=${direction}`
  );
}

export function* getClinicianUpcomingCalls() {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const sort = yield select(getClinicianUpcomingCallsSort);
  const direction = yield select(getClinicianUpcomingCallsSortDirection);
  const type = yield select(getVideoCallsType);
  return yield Request.get(
    `/v2/clinician/all-calls?type=${type}&sort=${sort}&direction=${direction}`
  );
}

export function* secondaryClinicianJoinCall(videoDetails) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.post(`/video/${videoDetails.clinicianid}/secondary-join-call`, videoDetails);
}

export function* secondaryClinicianLeaveCall(videoDetails) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.put(`/video/${videoDetails.clinicianid}/secondary-leave-call`, videoDetails);
}

export function* getVideoRoomState(videoRoomId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/video/${videoRoomId}/state`);
}

export function* sendParentVideoSignatures(signatures) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.put(`/video/${signatures.videoId}/customer-sign`, signatures);
}

export function* getCustomerUpcomingCalls(clientId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/video/${clientId}/upcoming-calls`);
}

export function* checkVideoCallAccess(videoId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const userId = yield select(getUserId);
  return yield Request.get(`/video/${videoId}/access/${userId}`);
}

export function* getVideoCallBillingIssues(clinicianUserId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  let clinicianQuery = "";
  if (clinicianUserId) {
    clinicianQuery = `?clinician=${clinicianUserId}`;
  }
  return yield Request.get(`/v2/billable-time/missing${clinicianQuery}`);
}

export function* setDemoCall(videoDetails) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.post(`/video/demo-call`, videoDetails);
}

export function* getUserCalendarSessions(details) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const weekStartDate = yield select(getWeekStartDate);
  const weekEndDate = yield select(getWeekEndDate);
  const showCanceledCalls = yield select(getShowWeekCanceledCalls);
  const showSupervisoryEvents = details.showSupervisoryEvents;
  const showTentativeEvents = details.showTentativeEvents;
  const { peopleFilters, selectedServices, currentUserId } = details;
  // This query variable ideally should be removed to favor the use of buildUrl,
  // but leaving here for now, for the array filters
  let query = "";
  const { clinicianUserIdList, clientIdList } = peopleFilters.reduce(
    (acc, user) => {
      if (user.person_type === "client") {
        acc.clientIdList.push(user.client_id);
      } else {
        acc.clinicianUserIdList.push(user.id);
      }
      return acc;
    },
    { clinicianUserIdList: [], clientIdList: [] }
  );

  // Default the clinicianUserIdList to include the currentUserId if both idLists are empty
  if (clinicianUserIdList.length === 0 && clientIdList.length === 0) {
    clinicianUserIdList.push(currentUserId);
  }
  let serviceTypeFilterList = selectedServices;

  if (clinicianUserIdList) {
    query += `&userIdFilterList=${encodeURIComponent(clinicianUserIdList.join(","))}`;
  }
  if (serviceTypeFilterList) {
    query += `&serviceTypeFilterList=${encodeURIComponent(serviceTypeFilterList.join(","))}`;
  }
  if (clientIdList) {
    query += `&clientIdFilterList=${encodeURIComponent(clientIdList.join(","))}`;
  }
  return yield Request.get(
    buildUrl("/video/calendar-sessions", {
      startDate: weekStartDate,
      endDate: weekEndDate,
      showCanceled: showCanceledCalls,
      showSupervisory: showSupervisoryEvents,
      showTentative: showTentativeEvents,
    }) + query
  );
}

export function* getCalendarSessions(details) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const weekStartDate = yield select(getWeekStartDate);
  const weekEndDate = yield select(getWeekEndDate);
  const showCanceledCalls = yield select(getShowWeekCanceledCalls);
  const { clinicianUserIdList, firstDayOfWeek, lastDayOfWeek } = details;
  let query = `?startDate=${weekStartDate?.replace("+", "%2b")}&endDate=${weekEndDate?.replace(
    "+",
    "%2b"
  )}&showCanceled=${showCanceledCalls}`;
  if (clinicianUserIdList) {
    query += `&clinicianUserIdList=${encodeURIComponent(clinicianUserIdList.join(","))}`;
  }
  return yield Request.get(`/video/calendar-sessions${query}`);
}

export function* getClinicianIncompleteOffPlatformCalls(userId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/video/${userId}/incomplete-off-platform`);
}

export function* completeOffPlatformCall(details) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.put(`/video/${details.id}/complete-off-platform`, details);
}

export async function getCalendarSessionsByDates(details) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const { startDate, endDate, userIdList } = details;
  let query = `?startDate=${startDate?.replace("+", "%2b")}&endDate=${endDate?.replace(
    "+",
    "%2b"
  )}&showCanceled=false&userIdFilterList=${encodeURIComponent(userIdList.join(","))}`;
  return await Request.get(`/video/calendar-sessions${query}`);
}
