import { select } from "redux-saga/effects";
import { getLogin, getUserId, getClientId } from "../selectors";
import SecureRequest from "../utils/securerequest";

export function* getParentInfo() {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  const username = yield select(getLogin);
  return yield Request.get(`/customers/${username}/chat`);
}

export function* getInsuranceRoadmap() {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const userId = yield select(getUserId);
  return yield Request.get(`/dashboard/${userId}/roadmap`);
}

export function* getClinicianBio(clinicianUserId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/clinicians/${clinicianUserId}`);
}

export function* getClinicianSpecialties(clinicianId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/clinicians/${clinicianId}/specialties`);
}

export function* cancelSubscription(reason) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const userId = yield select(getUserId);
  return yield Request.post(`/customers/${userId}/cancel`, reason);
}

export function* getCancelReasons() {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const userId = yield select(getUserId);
  return yield Request.get(`/customers/${userId}/cancel-reasons`);
}

export function* agreeToPolicies(info) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.put(`/customers/${info.userId}/policies`, info);
}

export function* getActiveTreatmentPlan() {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const clientId = yield select(getClientId);
  return yield Request.get(`/treatment-plans/${clientId}/active`);
}

export function* approveTreatmentPlan(planId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.put(`/treatment-plans/${planId}/approve`, {});
}

export function* sendDocumentRequestSignature(signatureObject) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.post(
    `/document-requests/${signatureObject.clientId}/create`,
    signatureObject
  );
}

export function* sendEligibilityRejectionInfo(info) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.post(`/leads/`, info);
}

export function* logAWSUploadError(errorObj) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.post(`/documents/upload-error`, errorObj);
}

export function* getEligibilityFields() {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const clientId = yield select(getClientId);
  return yield Request.get(`/roadmap/${clientId}/eligibility`);
}

export function* getUnsignedRequests() {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const clientId = yield select(getClientId);
  return yield Request.get(`/document-requests/${clientId}/unsigned`);
}

export function* signDocumentRequest(requestObj) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.put(`/document-requests/${requestObj.requestId}/sign`, requestObj);
}

export function* checkRecaptchaVerification(token) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.post("/customers/recaptcha", token);
}

/**
 * @deprecated
 * This function has been deprecated, in favor of getCustomerSignedFormAsync
 * to be used in a useQuery.
 */
export function* getCustomerSignedForm(formData) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/v2/customers/${formData.clientId}/form?type=${formData.type}`);
}

export async function getCustomerSignedFormAsync(formData) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.get(`/v2/customers/${formData.clientId}/form?type=${formData.type}`);
}

export function* saveSignedCustomerForm(formData) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.post(`/v2/customers/${formData.clientId}/form`, formData);
}

export function* updateClientInfo(payload) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.put(`/v2/customers/${payload.clientId}/client-info`, payload);
}

export function* updateClientTimeZone(payload) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.put(`/v2/customers/${payload.clientId}/timezone`, payload);
}

export function* updateCaregiverInfo(payload) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.put(`/v2/customers/${payload.userId}/caregiver-info`, payload);
}

export function* getCustomerCallsByMonth(data) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  let monthQuery = "";
  if (data.month) {
    monthQuery = `?month=${data.month}`;
  }
  return yield Request.get(`/v2/customers/${data.clientId}/calendar-calls${monthQuery}`);
}

export function* getCustomerDashboardNotifications() {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const userId = yield select(getUserId);
  return yield Request.get(`/v2/customers/${userId}/dashboard-notifications`);
}

export function* markNotificationAsRead(id) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.put(`/v2/customers/${id}/dashboard-notification`, {});
}

export function* requestTreatmentPlanReview() {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const clientId = yield select(getClientId);
  return yield Request.post(`/v2/customers/${clientId}/request-treatment-plan-review`, {});
}

export function* requestVideoCallReschedule(videoId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.post(`/v2/customers/${videoId}/request-session-reschedule`, {});
}

export function* getClientStaticPage(clientId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/v2/client/${clientId}/static-page`);
}

export function* getPrimaryAccountEmail(clientId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/v2/client/${clientId}/primary-email`);
}
