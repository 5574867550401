const ACTIONS = {
  SYSTEM_LOADED: "SYSTEM_LOADED",
  SET_PAGE_DETAILS: "SET_PAGE_DETAILS",
  OPEN_CONFIRMATION: "OPEN_CONFIRMATION",
  CANCEL_CONFIRMATION: "CANCEL_CONFIRMATION",
  ACCEPT_CONFIRMATION: "ACCEPT_CONFIRMATION",
  CLEAR_CONFIRMATION: "CLEAR_CONFIRMATION",
  GET_BUILD_VERSION: "GET_BUILD_VERSION",
  GET_BUILD_VERSION_SUCCESS: "GET_BUILD_VERSION_SUCCESS",
  GET_BUILD_VERSION_FAIL: "GET_BUILD_VERSION_FAIL",
  LOG_ERROR: "LOG_ERROR",
};

const ACTION_CREATORS = {
  systemLoaded() {
    return { type: ACTIONS.SYSTEM_LOADED };
  },
  setPageDetails(payload) {
    return {
      type: ACTIONS.SET_PAGE_DETAILS,
      payload,
    };
  },
  openConfirmation(payload) {
    return {
      type: ACTIONS.OPEN_CONFIRMATION,
      payload,
    };
  },
  cancelConfirmation() {
    return { type: ACTIONS.CANCEL_CONFIRMATION };
  },
  acceptConfirmation() {
    return { type: ACTIONS.ACCEPT_CONFIRMATION };
  },
  clearConfirmation() {
    return { type: ACTIONS.CLEAR_CONFIRMATION };
  },
  getBuildVersion() {
    return {
      type: ACTIONS.GET_BUILD_VERSION,
    };
  },
  getBuildVersionSuccess(payload) {
    return {
      type: ACTIONS.GET_BUILD_VERSION_SUCCESS,
      payload,
    };
  },
  getBuildVersionFail() {
    return {
      type: ACTIONS.GET_BUILD_VERSION_FAIL,
    };
  },
  logError(errorObject) {
    return {
      type: ACTIONS.LOG_ERROR,
      payload: errorObject,
    };
  },
};

export default { ...ACTIONS, ...ACTION_CREATORS };
