import React from "react";
import withStyles from "@mui/styles/withStyles";
import TextField from "@mui/material/TextField";

const StyledInput = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#9c00d5",
    },
    "& fieldset": {
      border: "1px solid #c1c1c1",
      borderRadius: 5,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {},
      "&:hover:not(.Mui-disabled) fieldset": {
        border: "1px solid #9c00d5",
      },
      "&.Mui-focused fieldset": {
        border: "2px solid #9c00d5",
      },
      "& .MuiNativeSelect-select": {
        padding: "8.5px 14px",
      },
    },
    "& .MuiFormHelperText-root": {
      margin: "0 5px",
      color: "red",
    },
    "& .MuiOutlinedInput-input": {
      color: "#2A2D39",
      fontSize: 17,
    },
    "& .MuiSelect-root": {
      color: "#2A2D39",
      // The select element is set to required
      // as long as the selected options value
      // is empty the element is not valid.
      "& option:invalid": {
        color: "#A4A3AD",
      },

      // Styling for browsers which do support
      // styling select option elements directly
      "& option:disabled": {
        color: "#A4A3AD",
      },
    },
    "& .MuiInputBase-input": {
      backgroundColor: "#fff",
    },
  },
})(TextField);

export const CustomInput = React.forwardRef((props, ref) => {
  return (
    <StyledInput {...props} variant={props.variant || "outlined"} size={props.size || "medium"} />
  );
});

export default CustomInput;
