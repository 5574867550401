import React, { Component } from "react";
import IconButton from "@mui/material/IconButton";
import FlipCameraIosIcon from "@mui/icons-material/FlipCameraIosOutlined";
import Tooltip from "@mui/material/Tooltip";
import SettingsDialog from "../Dialogs/SettingsDialog";
import { MenuList, MenuItem, ListItemIcon, ListItemText, Badge } from "@mui/material";
import VideoSettingsIcon from "@mui/icons-material/VideoSettings";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  PhoneDisconnect,
  Microphone,
  MicrophoneSlash,
  VideoCamera,
  VideoCameraSlash,
  DotsThreeVertical,
  ChatCenteredText,
  Folder,
  PuzzlePiece,
  Sidebar,
  Screencast,
} from "@phosphor-icons/react";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { addMinutes } from "date-fns";

var CustomTooltip = React.forwardRef((props, ref) => {
  return (
    <Tooltip
      {...props}
      placement="top"
      PopperProps={{
        style: { zIndex: 3005 },
        disablePortal: true,
      }}
    />
  );
});

class ControlsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      settingMenuOpen: false,
      settingsTooltipOpen: false,
      quickSelectedTab: null,
    };
    this.anchorRef = React.createRef();
  }

  toggleSettingsTooltip = () => {
    this.setState({ settingsTooltipOpen: !this.state.settingsTooltipOpen });
  };

  handleSettingsTooltipClose = () => {
    this.setState({ settingsTooltipOpen: false });
  };

  toggleSettingsDialog = () => {
    this.setState({ settingMenuOpen: !this.state.settingMenuOpen });
  };

  render() {
    const {
      muted,
      cameraDisabled,
      hasJoinedRoom,
      clientLeaveRoom,
      toggleAudio,
      toggleVideo,
      toggleAudioView,
      toggleVideoView,
      canChangeVideoInput,
      flipCamerasOnMobile,
      classes,
      screenShareOn,
      clinicianInControl,
      // ADMIN
      onOpenEndCallDialog,
      onOpenNoShowEndCallDialog,
      clinicianScreenSharing,
      userPermissions,
      screenTrack,
      endScreenCapture,
      captureScreen,
      showClientScreenShare,
      canScreenShare,
      isPrimaryClinician,
      onOpenSecondaryLeaveCallDialog,
      isCaregiver,
      toggleNotes,
      hideChat,
      toggleChatTab,
      unreadMessageCount,
      clientOnMobile,
      fromStaticUrl,
      readyToJoinCall,
      toggleActivityDrawer,
      toggleHostControlsDrawer,
      showControls,
      isMobileControls,
      oneTimeVideoInfo,
    } = this.props;
    const screenshareDisabled =
      (!clinicianScreenSharing && screenShareOn && !isCaregiver) ||
      (!isCaregiver && !clinicianInControl) ||
      (isCaregiver && !showClientScreenShare);
    return (
      <>
        <div
          className={
            isMobileControls
              ? showControls
                ? classes.controlsContainerMobile
                : classes.controlsContainerHidden
              : classes.controlsContainer
          }
        >
          <div className={classes.controls}>
            <div
              className={classes.controlButtons}
              style={
                readyToJoinCall || hasJoinedRoom
                  ? { backgroundColor: "rgba(255, 255, 255, 0.50)" }
                  : null
              }
            >
              <CustomTooltip title={`${muted ? "Enable" : "Disable"} mic`}>
                <span>
                  <IconButton
                    className={muted ? classes.controlInUse : classes.control}
                    aria-label="Toggle-audio"
                    onClick={hasJoinedRoom ? toggleAudio : toggleAudioView}
                    color={muted ? "error" : "secondary"}
                  >
                    {muted ? <MicrophoneSlash /> : <Microphone />}
                  </IconButton>
                </span>
              </CustomTooltip>
              {clientOnMobile && canChangeVideoInput() && !cameraDisabled && (
                <CustomTooltip title="Flip Camera">
                  <span>
                    <IconButton
                      className={classes.control}
                      aria-label="Flip-camera"
                      onClick={flipCamerasOnMobile}
                    >
                      <FlipCameraIosIcon color="inherit" />
                    </IconButton>
                  </span>
                </CustomTooltip>
              )}
              <CustomTooltip title={`${cameraDisabled ? "Enable" : "Disable"} camera`}>
                <span>
                  <IconButton
                    className={cameraDisabled ? classes.controlInUse : classes.control}
                    aria-label="Toggle-video"
                    onClick={hasJoinedRoom ? toggleVideo : toggleVideoView}
                    color={cameraDisabled ? "error" : "secondary"}
                  >
                    {cameraDisabled ? <VideoCameraSlash /> : <VideoCamera />}
                  </IconButton>
                </span>
              </CustomTooltip>
              <CustomTooltip title={isCaregiver && hideChat ? "Chat Disabled" : "Chat"}>
                <IconButton
                  className={classes.control}
                  aria-label="Toggle-chat"
                  onClick={toggleChatTab}
                  disabled={isCaregiver && hideChat}
                  disabledDarkColor={readyToJoinCall || hasJoinedRoom}
                >
                  <Badge invisible={unreadMessageCount <= 0} color="error" variant="dot">
                    <ChatCenteredText color="inherit" />
                  </Badge>
                </IconButton>
              </CustomTooltip>
              {canScreenShare() && userPermissions?.screenshare && hasJoinedRoom && (
                <CustomTooltip
                  title={
                    screenshareDisabled
                      ? "Screenshare Disabled"
                      : screenTrack
                        ? "Stop sharing screen"
                        : "Share screen"
                  }
                >
                  <span>
                    <IconButton
                      className={screenTrack ? classes.controlInUse : classes.control}
                      aria-label="screenshare"
                      onClick={screenTrack ? endScreenCapture : captureScreen}
                      disabled={screenshareDisabled}
                      disabledDarkColor={readyToJoinCall || hasJoinedRoom}
                      color={screenTrack ? "error" : "secondary"}
                    >
                      <Screencast color={screenTrack ? "green" : "inherit"} />
                    </IconButton>
                  </span>
                </CustomTooltip>
              )}
              <ClickAwayListener onClickAway={this.handleSettingsTooltipClose}>
                <div className="settingsTip">
                  <CustomTooltip
                    leaveDelay={1200}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    open={this.state.settingsTooltipOpen}
                    onClose={this.handleSettingsTooltipClose}
                    title={
                      <div style={{ zIndex: 4000, pointerEvents: "all" }}>
                        <MenuList
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: 259,
                            height: 88,
                            border: "none",
                            backgroundColor: "#fff",
                            color: "#000",
                            borderRadius: 4,
                          }}
                        >
                          <MenuItem
                            onClick={() => {
                              this.setState(
                                { quickSelectedTab: "Audio & Video" },
                                this.toggleSettingsDialog()
                              );
                            }}
                          >
                            <ListItemIcon>
                              <VideoSettingsIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>
                              <div
                                style={{
                                  fontSize: 14,
                                }}
                              >
                                Audio & Video
                              </div>
                            </ListItemText>
                          </MenuItem>

                          <MenuItem
                            onClick={() => {
                              this.setState(
                                { quickSelectedTab: "Troubleshoot Issues" },
                                this.toggleSettingsDialog()
                              );
                            }}
                          >
                            <ListItemIcon>
                              <HelpOutlineIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>
                              <div
                                style={{
                                  fontSize: 14,
                                }}
                              >
                                Troubleshoot Issues
                              </div>
                            </ListItemText>
                          </MenuItem>
                        </MenuList>
                      </div>
                    }
                  >
                    <span>
                      <IconButton
                        className={classes.control}
                        aria-label="setting"
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={this.toggleSettingsTooltip}
                        ref={this.anchorRef}
                        size="large"
                      >
                        <DotsThreeVertical />
                      </IconButton>
                    </span>
                  </CustomTooltip>
                </div>
              </ClickAwayListener>
              {(hasJoinedRoom || readyToJoinCall) && (
                <>
                  {isCaregiver ? (
                    fromStaticUrl ? (
                      <></>
                    ) : (
                      <CustomTooltip title="End Call">
                        <span>
                          <IconButton
                            className={classes.endCallControl}
                            aria-label="Exit"
                            onClick={
                              hasJoinedRoom
                                ? clientLeaveRoom
                                : () => this.props.history.push("/dashboard")
                            }
                            size="large"
                            disableRipple={false}
                          >
                            <PhoneDisconnect style={{ color: "#fff" }} />
                          </IconButton>
                        </span>
                      </CustomTooltip>
                    )
                  ) : (
                    userPermissions?.end_video_call && (
                      <CustomTooltip title="End Call">
                        <span>
                          <IconButton
                            className={classes.endCallControl}
                            aria-label="End-Call"
                            onClick={() => {
                              if (hasJoinedRoom) {
                                return isPrimaryClinician
                                  ? onOpenEndCallDialog()
                                  : onOpenSecondaryLeaveCallDialog();
                              } else {
                                const deadline = addMinutes(
                                  new Date(oneTimeVideoInfo.scheduled_date),
                                  10
                                );
                                const currentDate = new Date();
                                const showNoShowDialog =
                                  isPrimaryClinician &&
                                  currentDate > deadline &&
                                  !oneTimeVideoInfo.canceled;
                                return showNoShowDialog
                                  ? onOpenNoShowEndCallDialog()
                                  : this.props.history.push("/dashboard");
                              }
                            }}
                            size="large"
                            disableRipple={false}
                          >
                            <PhoneDisconnect style={{ color: "#fff" }} />
                          </IconButton>
                        </span>
                      </CustomTooltip>
                    )
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        {!isCaregiver && (
          <div className={classes.clinicianOnlyControlsContainer}>
            <div
              className={classes.controlButtons}
              style={
                readyToJoinCall || hasJoinedRoom
                  ? { backgroundColor: "rgba(255, 255, 255, 0.50)" }
                  : null
              }
            >
              <CustomTooltip title="Session Notes">
                <IconButton
                  className={classes.control}
                  aria-label="Toggle-notes-drawer"
                  onClick={toggleNotes}
                  size="large"
                >
                  <Folder color="inherit" />
                </IconButton>
              </CustomTooltip>
              <CustomTooltip title={hasJoinedRoom ? "Activites" : "Activities Disabled"}>
                <span>
                  <IconButton
                    className={classes.control}
                    aria-label="Toggle-activity-drawer"
                    onClick={toggleActivityDrawer}
                    disabled={!hasJoinedRoom}
                    disabledDarkColor={readyToJoinCall || hasJoinedRoom}
                    size="large"
                  >
                    <PuzzlePiece color="inherit" />
                  </IconButton>
                </span>
              </CustomTooltip>

              <CustomTooltip title={"Host Controls"}>
                <span>
                  <IconButton
                    className={classes.control}
                    aria-label="Toggle-host-controls"
                    onClick={toggleHostControlsDrawer}
                    size="large"
                  >
                    <Sidebar color="inherit" />
                  </IconButton>
                </span>
              </CustomTooltip>
            </div>
          </div>
        )}

        {this.state.settingMenuOpen && (
          <SettingsDialog
            classes={this.props.classes}
            openedWith={this.state.quickSelectedTab}
            toggleSettingsDialog={this.toggleSettingsDialog}
            open={this.state.settingMenuOpen}
            microphones={this.props.microphones}
            speakers={this.props.speakers}
            cameras={this.props.cameras}
            runSpeedTest={this.props.runSpeedTest}
            runPerformanceTest={this.props.runPerformanceTest}
            runAudioTest={this.props.runAudioTest}
            currentMicrophone={this.props.currentMicrophone}
            currentSpeaker={this.props.currentSpeaker}
            currentCamera={this.props.currentCamera}
            handleUserCameraChange={this.props.handleUserCameraChange}
            handleUserMicrophoneChange={this.props.handleUserMicrophoneChange}
            handleUserSpeakerChange={this.props.handleUserSpeakerChange}
            canChangeSpeaker={this.props.canChangeSpeaker}
          />
        )}
      </>
    );
  }
}

export default ControlsContainer;
