import { random } from "lodash";

/**
 * @example await Promise.delay(250); // wait for 250ms before proceeding
 * @param {number} delayTimeMs
 * @returns {Promise<void>}
 */
export async function delay(delayTimeMs = 0) {
  return new Promise((res) => {
    setTimeout(res, delayTimeMs);
  });
}

/**
 * @example
 *  await Promise.jitter(0, 250); // wait for a random amount of time (between 0ms and 250ms) before proceeding
 *  await Promise.jitter(0, 250, 1); // wait for a random amount of time (between 0ms and 500ms) before proceeding
 *  await Promise.jitter(0, 250, 2); // wait for a random amount of time (between 0ms and 1000ms) before proceeding
 *  await Promise.jitter(0, 250, 3); // wait for a random amount of time (between 0ms and 2000ms) before proceeding
 *  await Promise.jitter(0, 250, 4); // wait for a random amount of time (between 0ms and 4000ms) before proceeding
 * @param {number} min
 * @param {number} max
 * @param {number} scale
 */
export async function jitter(min = 0, max = Infinity, scale = 0) {
  const delayTimeMs = (2 ** scale || 0) * random(min, max);
  await delay(delayTimeMs);
  return delayTimeMs;
}
