import React from "react";
import { Typography } from "@mui/material";
import { format } from "date-fns";
import CptHeader from "../NoteDetails/CptHeader";
import { Calendar, Timer, Clock, ShieldCheck } from "@phosphor-icons/react";
import RequestedEdits from "../Tools/RequestedEdits";

export default function ViewTimesheetNote(props) {
  const {
    classes,
    noteRef,
    note,
    startDate,
    endDate,
    serviceType,
    duration,
    needsEdits,
    requestedEdits,
  } = props;
  return (
    <div className={classes.billableTimeContent}>
      <div className={classes.sessionNoteDetails}>
        <Typography component="p" variant="body2">
          Details
        </Typography>
        <div className={classes.callDetailRow}>
          <div className={classes.callDetail}>
            <Calendar size={20} color="rgba(0, 0, 0, 0.56)" />
            <Typography component="p" variant="body2Secondary">
              {format(new Date(startDate), "PPP")}
            </Typography>
          </div>

          <div className={classes.callDetail}>
            <Clock size={20} color="rgba(0, 0, 0, 0.56)" />
            <Typography component="p" variant="body2Secondary">{`${duration} minutes`}</Typography>
          </div>

          <div className={classes.callDetail}>
            <Timer size={20} color="rgba(0, 0, 0, 0.56)" />
            <Typography component="p" variant="body2Secondary">
              {`${format(new Date(startDate), "h:mm a")} - ${format(new Date(endDate), "h:mm a")}`}
            </Typography>
          </div>

          <div className={classes.callDetail}>
            <ShieldCheck size={20} color="rgba(0, 0, 0, 0.56)" />
            <Typography component="p" variant="body2Secondary">
              {serviceType?.description}
            </Typography>
          </div>
        </div>
      </div>
      {needsEdits && <RequestedEdits requestedEdits={requestedEdits} />}
      <CptHeader {...props} />
      <div className={classes.noteDetail} ref={noteRef}>
        <Typography component="p" variant="body2">
          {note}
        </Typography>
      </div>
    </div>
  );
}
