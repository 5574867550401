const toCamelCase = (str) => {
  return str.replace(/_([a-z])/g, (g) => g[1].toUpperCase());
};

const toSnakeCase = (str) => {
  return str.replace(/([A-Z])/g, "_$1").toLowerCase();
};

export const transformKeysToSnake = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map((item) => transformKeysToSnake(item));
  } else if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce((acc, key) => {
      acc[toSnakeCase(key)] = transformKeysToSnake(obj[key]);
      return acc;
    }, {});
  }
  return obj;
};

export const transformKeysToCamel = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map((item) => transformKeysToCamel(item));
  } else if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce((acc, key) => {
      acc[toCamelCase(key)] = transformKeysToCamel(obj[key]);
      return acc;
    }, {});
  }
  return obj;
};
