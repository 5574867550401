import { Button, Link, Stack, Typography } from "@mui/material";
import ANLogo from "../../assets/answers_now_logo_purple.png";
import UserIcon from "../../assets/user_icon_shaped_bg.png";
import React from "react";
import { useDispatch } from "react-redux";
import actions from "actions";

export default function NoClientsScreen() {
  const dispatch = useDispatch();
  return (
    <Stack height="100vh" alignItems="center" justifyContent="center">
      <Stack maxWidth="352px" alignItems="center" gap={11}>
        <img src={ANLogo} alt="AnswersNow" title="AnswersNow" height="29px" />
        <Stack alignItems="center" gap={4}>
          <img src={UserIcon} alt="AnswersNow" title="AnswersNow" height="36px" width="38px" />
          <Stack alignItems="center">
            <Typography variant="subtitle1">No Active Clients</Typography>
            <Typography variant="body2" textAlign="center" color="text.secondary">
              It looks like you don't have any active clients right now. Questions or Issues?
              Contact us at{" "}
              <Link href="tel:+18004869060" sx={{ textWrap: "nowrap" }}>
                (800) 486-9060
              </Link>
              .
            </Typography>
          </Stack>
          <Button onClick={() => dispatch(actions.signOut(true))}>Back to Log in</Button>
        </Stack>
      </Stack>
    </Stack>
  );
}
