import { createStyles } from "@mui/styles";

const baseAppBar = {
  display: "flex",
  width: "100%",
  alignItems: "flex-end",
  backgroundColor: "#4C505C",
  zIndex: 1000,
  height: "70px",
};

const styles = (theme) =>
  createStyles({
    content: {
      position: "relative",
      top: "70px",
      height: `calc(100vh - 70px)`,
      overflowY: "scroll",
      zIndex: 1,
      backgroundColor: theme.palette.text.primary,

      "&::-webkit-scrollbar": {
        width: "8px",
        marginRight: "-20px",
      },
      "&::-webkit-scrollbar-track": {
        background: "transparent",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#CDD3DA",
        borderRadius: "4px",
        border: "2px solid transparent",
      },
      "& #react-doc-viewer": {
        fontFamily: "BasierCircle",
        color: theme.palette.primary.contrast,
        overflow: "visible",

        "& #proxy-renderer": {
          backgroundColor: theme.palette.text.primary,
          paddingTop: "48px",
          maxHeight: "100%",
          "& #react-doc-viewer": {
            paddingTop: "0px",
          },
          "& #image-renderer": {
            background: "transparent",
          },
          "& #pdf-page-wrapper": {
            marginTop: "0px",
          },
          "& #html-body": {
            padding: "0px",
            marginTop: "0px",
            backgroundColor: "white",
          },
        },

        "& #proxy-renderer, & #pdf-controls, & #pdf-renderer": {
          width: "100%",
          overflow: "visible",
        },

        "& #header-bar": {
          padding: "11px var(--5, 16px)",
          marginBottom: "48px",
          height: "64px",

          "& #file-name": {
            color: theme.palette.primary.contrast,
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "143%",
            letterSpacing: "0.17px",
            overflow: "visible",
          },
        },

        "& #pdf-controls": {
          display: "flex",
          alignItems: "flex-end",
          padding: theme.spacing(5),
          backgroundColor: "transparent",
          position: "fixed",
          boxShadow: "none",
          zIndex: 1001,

          "& #pdf-pagination": {
            position: "absolute",
            transform: "translateX(-58%)",
            left: "50%",
            top: "18px",
          },

          "& #pdf-toggle-pagination, & #pdf-download, & #pdf-zoom-out, & #pdf-zoom-in, & #pdf-zoom-reset":
            {
              display: "none",
            },

          "& #pdf-pagination-prev, & #pdf-pagination-next, & #pdf-pagination": {
            display: "flex",
            padding: theme.spacing(1),
            justifyContent: "center",
            alignItems: "center",
            gap: theme.spacing(1),
            borderRadius: theme.radius.large,
            background: theme.palette.divider,
            color: theme.palette.text.secondary,
            boxShadow: "none",
            "& svg path": {
              fill: theme.palette.text.secondary,
            },
            margin: "0px",
          },

          "& #pdf-pagination-info": {
            color: theme.palette.primary.primary,
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "143%",
            letterSpacing: "0.17px",
          },
        },
      },
    },
    contentError: {
      height: "100vh",
      width: "100vw",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: theme.palette.text.primary,
    },
    appBar: {
      ...baseAppBar,
      gap: theme.spacing(5),
    },
    appBarFixed: {
      ...baseAppBar,
      gap: theme.spacing(5),
      position: "fixed",
    },
    toolbar: {
      width: "100%",
      display: "flex",
      padding: theme.spacing(5),
      justifyContent: "center",
      alignItems: "center",
      gap: theme.spacing(3),
      flex: 1,
    },
    fileName: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      width: "100%",
    },
    pageCounter: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      flex: 1,
    },
    controls: {
      display: "flex",
      padding: theme.spacing(1),
      justifyContent: "center",
      alignItems: "center",
      gap: theme.spacing(1),
      borderRadius: theme.radius.large,
      background: theme.palette.divider,
    },
  });

export default styles;
