const ACTIONS = {
  GET_SENDBIRD_CONNECTION: "GET_SENDBIRD_CONNECTION",
  GET_SENDBIRD_CONNECTION_SUCCESS: "GET_SENDBIRD_CONNECTION_SUCCESS",
  GET_SENDBIRD_CONNECTION_FAIL: "GET_SENDBIRD_CONNECTION_FAIL",
  GET_UNREAD_CHANNEL_COUNT: "GET_UNREAD_CHANNEL_COUNT",
  GET_UNREAD_CHANNEL_COUNT_SUCCESS: "GET_UNREAD_CHANNEL_COUNT_SUCCESS",
  GET_UNREAD_CHANNEL_COUNT_FAIL: "GET_UNREAD_CHANNEL_COUNT_FAIL",
  DISCONNECT_SENDBIRD: "DISCONNECT_SENDBIRD",
  DISCONNECT_SENDBIRD_SUCCESS: "DISCONNECT_SENDBIRD_SUCCESS",
};

const ACTION_CREATORS = {
  getSendbirdConnection(userId, name, appId) {
    return {
      type: ACTIONS.GET_SENDBIRD_CONNECTION,
      payload: {
        userId,
        name,
        appId,
      },
    };
  },
  getSendbirdConnectionSuccess(connection) {
    return {
      type: ACTIONS.GET_SENDBIRD_CONNECTION_SUCCESS,
      payload: connection,
    };
  },
  getSendbirdConnectionFail() {
    return {
      type: ACTIONS.GET_SENDBIRD_CONNECTION_FAIL,
    };
  },
  getUnreadChannelCount(connection) {
    return {
      type: ACTIONS.GET_UNREAD_CHANNEL_COUNT,
      payload: connection,
    };
  },
  getUnreadChannelCountSuccess(count) {
    return {
      type: ACTIONS.GET_UNREAD_CHANNEL_COUNT_SUCCESS,
      payload: count,
    };
  },
  getUnreadChannelCountFail() {
    return {
      type: ACTIONS.GET_UNREAD_CHANNEL_COUNT_FAIL,
    };
  },
  disconnectSendbird(connection) {
    return {
      type: ACTIONS.DISCONNECT_SENDBIRD,
      payload: connection,
    };
  },
  disconnectSendbirdSuccess() {
    return {
      type: ACTIONS.DISCONNECT_SENDBIRD_SUCCESS,
    };
  },
};

export default { ...ACTIONS, ...ACTION_CREATORS };
