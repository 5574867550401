export const APP_ID = "01D282D5-42C8-42DF-BC29-D32F041B49CD";

export const POOLDATA = {
  UserPoolId: "us-east-1_Q5VfMqj7f",
  ClientId: "ehkvli98asvfsgg98pqorl49u",
};

export const STATES = {
  AL: "Alabama",
  AK: "Alaska",
  AS: "American Samoa",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District Of Columbia",
  FM: "Federated States Of Micronesia",
  FL: "Florida",
  GA: "Georgia",
  GU: "Guam",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MH: "Marshall Islands",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  MP: "Northern Mariana Islands",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PW: "Palau",
  PA: "Pennsylvania",
  PR: "Puerto Rico",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VI: "Virgin Islands",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};

export const GLOBAL_STATES = {
  Out: "Outside of the US",
  NATIONAL: "National",
  ...STATES,
};
export const COVERAGE_STATES = {
  "-999": "National",
  ...STATES,
};

export const ETHNICITIES = [
  "White",
  "Black or African American",
  "Hispanic / Latinx",
  "Asian",
  "American Indian or Alaska Native",
  "Middle Eastern or North African",
  "Native Hawain or Pacific Islander",
];

export const LANGUAGES = ["English", "Spanish", "Mandarin", "Tagalog", "Hindi", "Farsi", "Other"];

export const FOCUS_TYPES = [
  "Face-to-Face Parent Interview and/or Skill Assessment",
  "Face-to-Face services to modify/add protocols based on client programming needs",
  "1:1 direct therapy to address skills deficits",
  "Supervision of direct therapist with protocol modification to address skills deficits/behavior excesses related to ASD diagnosis",
  "Parent/Caregiver training on treatment plan protocols related to ASD diagnosis",
  "Coordination of care with client's related providers",
  "Protocol modification including data analysis and review",
  "Non-billable introductory video call with family to discuss therapy models and tour our telehealth platform",
  "Non-billable data analysis and/or treatment plan updates",
  "Non-billable coordination of care with client's related providers",
  "Therapeutic consultation caregiver meeting",
  "review of client documentation to inform the BIP and FBA",
  "Therapeutic Consult- Assessment and treatment related to clients FBA and BIP",
  "Meeting with clinical supervisor - case discussion",
  "Indirect assessment to include treatment plan writing",
  "Care Coordination/Protocol modification including data analysis and review",
  "Meeting with clinical supervisor for case review",
  "Client Observation for Training or Case Transfer",
  "Multiple-family group adaptive behavior treatment guidance",
  "Group adaptive behavior treatment by protocol",
  "Non-billable services",
];

export const TECH_ISSUES = [
  "Client Internet",
  "Client Video",
  "Client Audio",
  "Client tried and couldn't get on call",
  "Clinician Internet",
  "Clinician Video",
  "Clinician Audio",
  "Clinician tried and couldn't get on call",
];

export const DIAGNOSTIC_CODES = [
  "F84 Autism Spectrum Disorder",
  "Q90.1 Down Syndrome",
  "F90.8 Attention-deficit hyperactivity disorder, other type",
  "F90.9 Attention-deficit hyperactivity disorder, unspecified type",
  "F90.2 ADHD combined time",
  "F34.8 Other persistent mood [affective] disorders",
  "F88 Global Developmental Delay",
  "F91.3 Oppositional Defiant Disorder",
  "F41.1 Generalized Anxiety Disorder",
  "F40.10 Social Phobia (Social Anxiety)",
  "F40.2 Specific Isolated Phobia",
  "F80.2 Mixed receptive-expressive language disorder",
  "R48.2 Apraxia",
  "F89 Unspecified Neurodevelopmental Disorder",
  "F84.9 Pervasive Developmental Disorder",
  "F80.9 Speech Delay",
];

export const TIMEZONES = {
  "America/Los_Angeles": "Pacific Time",
  "America/Tijuana": "Pacific Time - Tijuana",
  "America/Denver": "Mountain Time",
  "America/Phoenix": "Mountain Time - Arizona",
  "America/Mazatlan": "Mountain Time - Chihuahua, Mazatlan",
  "America/Chicago": "Central Time",
  "America/Mexico_City": "Central Time - Mexico City",
  "America/Regina": "Central Time - Regina",
  "America/New_York": "Eastern Time",
  "America/Anchorage": "Alaska Time - Anchorage",
  "America/Juneau": "Alaska Time - Juneau",
  "America/Guatemala": "Guatemala",
  "America/Halifax": "Atlantic Time - Halifax",
  "America/Bogota": "Bogota",
  "America/Lima": "Lima",
  "America/Caracas": "Caracas",
  "America/Guyana": "Guyana",
  "America/La_Paz": "La Paz",
  "America/Argentina/Buenos_Aires": "Buenos Aires",
  "America/Godthab": "Godthab",
  "America/Montevideo": "Montevideo",
  "America/St_Johns": "Newfoundland Time - St. Johns",
  "America/Santiago": "Santiago",
  "America/Sao_Paulo": "Sao Paulo",
  "Africa/Algiers": "Algiers",
  "Asia/Almaty": "Almaty",
  "Europe/Amsterdam": "Amsterdam",
  "Pacific/Apia": "Apia",
  "Europe/Athens": "Athens",
  "Pacific/Auckland": "Auckland",
  "Atlantic/Azores": "Azores",
  "Asia/Baghdad": "Baghdad",
  "Asia/Baku": "Baku",
  "Asia/Bangkok": "Bangkok",
  "Europe/Berlin": "Berlin",
  "Europe/Brussels": "Brussels",
  "Europe/Bucharest": "Bucharest",
  "Europe/Budapest": "Budapest",
  "Africa/Cairo": "Cairo",
  "Atlantic/Cape_Verde": "Cape Verde",
  "Africa/Casablanca": "Casablanca",
  "Europe/Belgrade": "Central European Time - Belgrade",
  "Europe/Prague": "Central European Time - Prague",
  "Australia/Adelaide": "Central Time - Adelaide",
  "Australia/Darwin": "Central Time - Darwin",
  "Asia/Shanghai": "China Time - Beijing",
  "Asia/Colombo": "Colombo",
  "Europe/Copenhagen": "Copenhagen",
  "Asia/Dhaka": "Dhaka",
  "Europe/Dublin": "Dublin",
  "Australia/Brisbane": "Eastern Time - Brisbane",
  "Australia/Hobart": "Eastern Time - Hobart",
  "Australia/Sydney": "Eastern Time - Melbourne, Sydney",
  "Pacific/Fakaofo": "Fakaofo",
  "Pacific/Fiji": "Fiji",
  "Pacific/Guadalcanal": "Guadalcanal",
  "Pacific/Guam": "Guam",
  "Pacific/Honolulu": "Hawaii Time",
  "Europe/Helsinki": "Helsinki",
  "Asia/Hong_Kong": "Hong Kong",
  "Europe/Istanbul": "Istanbul",
  "Asia/Jakarta": "Jakarta",
  "Asia/Jerusalem": "Jerusalem",
  "Africa/Johannesburg": "Johannesburg",
  "Asia/Kabul": "Kabul",
  "Asia/Karachi": "Karachi",
  "Europe/Kiev": "Kiev",
  "Asia/Kuala_Lumpur": "Kuala Lumpur",
  "Europe/Lisbon": "Lisbon",
  "Europe/London": "London",
  "Europe/Madrid": "Madrid",
  "Pacific/Majuro": "Majuro",
  "Europe/Minsk": "Minsk",
  "Africa/Monrovia": "Monrovia",
  "Europe/Moscow": "Moscow+00 - Moscow",
  "Europe/Kaliningrad": "Moscow-01 - Kaliningrad",
  "Europe/Samara": "Moscow+01 - Samara",
  "Asia/Yekaterinburg": "Moscow+02 - Yekaterinburg",
  "Asia/Krasnoyarsk": "Moscow+04 - Krasnoyarsk",
  "Asia/Irkutsk": "Moscow+05 - Irkutsk",
  "Asia/Yakutsk": "Moscow+06 - Yakutsk",
  "Asia/Magadan": "Moscow+07 - Magadan",
  "Asia/Vladivostok": "Moscow+07 - Yuzhno-Sakhalinsk",
  "Asia/Kamchatka": "Moscow+09 - Petropavlovsk-Kamchatskiy",
  "Africa/Nairobi": "Nairobi",
  "Pacific/Noumea": "Noumea",
  "Europe/Paris": "Paris",
  "Pacific/Pago_Pago": "Pago Pago",
  "Pacific/Port_Moresby": "Port Moresby",
  "Asia/Rangoon": "Rangoon",
  "Europe/Riga": "Riga",
  "Asia/Riyadh": "Riyadh",
  "Europe/Rome": "Rome",
  "Asia/Seoul": "Seoul",
  "Asia/Singapore": "Singapore",
  "Europe/Sofia": "Sofia",
  "Atlantic/South_Georgia": "South Georgia",
  "Europe/Stockholm": "Stockholm",
  "Asia/Taipei": "Taipei",
  "Europe/Tallinn": "Tallinn",
  "Asia/Tashkent": "Tashkent",
  "Asia/Tbilisi": "Tbilisi",
  "Asia/Tehran": "Tehran",
  "Asia/Tokyo": "Tokyo",
  "Pacific/Tongatapu": "Tongatapu",
  "Asia/Ulaanbaatar": "Ulaanbaatar",
  "Europe/Vienna": "Vienna",
  "Europe/Vilnius": "Vilnius",
  "Europe/Warsaw": "Warsaw",
  "Australia/Perth": "Western Time - Perth",
  "Asia/Yerevan": "Yerevan",
};

export const FLIPPED_TIMEZONES = {
  "Pacific Time": "America/Los_Angeles",
  "Pacific Time - Tijuana": "America/Tijuana",
  "Mountain Time": "America/Denver",
  "Mountain Time - Arizona": "America/Phoenix",
  "Mountain Time - Chihuahua, Mazatlan": "America/Mazatlan",
  "Central Time": "America/Chicago",
  "Central Time - Mexico City": "America/Mexico_City",
  "Central Time - Regina": "America/Regina",
  "Eastern Time": "America/New_York",
  "Alaska Time - Anchorage": "America/Anchorage",
  "Alaska Time - Juneau": "America/Juneau",
  Guatemala: "America/Guatemala",
  "Atlantic Time - Halifax": "America/Halifax",
  Bogota: "America/Bogota",
  Lima: "America/Lima",
  Caracas: "America/Caracas",
  Guyana: "America/Guyana",
  "La Paz": "America/La_Paz",
  "Buenos Aires": "America/Argentina/Buenos_Aires",
  Godthab: "America/Godthab",
  Montevideo: "America/Montevideo",
  "Newfoundland Time - St. Johns": "America/St_Johns",
  Santiago: "America/Santiago",
  "Sao Paulo": "America/Sao_Paulo",
  Algiers: "Africa/Algiers",
  Almaty: "Asia/Almaty",
  Amsterdam: "Europe/Amsterdam",
  Apia: "Pacific/Apia",
  Athens: "Europe/Athens",
  Auckland: "Pacific/Auckland",
  Azores: "Atlantic/Azores",
  Baghdad: "Asia/Baghdad",
  Baku: "Asia/Baku",
  Bangkok: "Asia/Bangkok",
  Berlin: "Europe/Berlin",
  Brussels: "Europe/Brussels",
  Bucharest: "Europe/Bucharest",
  Budapest: "Europe/Budapest",
  Cairo: "Africa/Cairo",
  "Cape Verde": "Atlantic/Cape_Verde",
  Casablanca: "Africa/Casablanca",
  "Central European Time - Belgrade": "Europe/Belgrade",
  "Central European Time - Prague": "Europe/Prague",
  "Central Time - Adelaide": "Australia/Adelaide",
  "Central Time - Darwin": "Australia/Darwin",
  "China Time - Beijing": "Asia/Shanghai",
  Colombo: "Asia/Colombo",
  Copenhagen: "Europe/Copenhagen",
  Dhaka: "Asia/Dhaka",
  Dublin: "Europe/Dublin",
  "Eastern Time - Brisbane": "Australia/Brisbane",
  "Eastern Time - Hobart": "Australia/Hobart",
  "Eastern Time - Melbourne, Sydney": "Australia/Sydney",
  Honolulu: "Pacific/Honolulu",
  Fakaofo: "Pacific/Fakaofo",
  Fiji: "Pacific/Fiji",
  Guadalcanal: "Pacific/Guadalcanal",
  Guam: "Pacific/Guam",
  "Hawaii Time": "Pacific/Honolulu",
  Helsinki: "Europe/Helsinki",
  "Hong Kong": "Asia/Hong_Kong",
  Istanbul: "Europe/Istanbul",
  Jakarta: "Asia/Jakarta",
  Jerusalem: "Asia/Jerusalem",
  Johannesburg: "Africa/Johannesburg",
  Kabul: "Asia/Kabul",
  Karachi: "Asia/Karachi",
  Kiev: "Europe/Kiev",
  "Kuala Lumpur": "Asia/Kuala_Lumpur",
  Lisbon: "Europe/Lisbon",
  London: "Europe/London",
  Madrid: "Europe/Madrid",
  Majuro: "Pacific/Majuro",
  Minsk: "Europe/Minsk",
  Monrovia: "Africa/Monrovia",
  "Moscow+00 - Moscow": "Europe/Moscow",
  "Moscow-01 - Kaliningrad": "Europe/Kaliningrad",
  "Moscow+01 - Samara": "Europe/Samara",
  "Moscow+02 - Yekaterinburg": "Asia/Yekaterinburg",
  "Moscow+04 - Krasnoyarsk": "Asia/Krasnoyarsk",
  "Moscow+05 - Irkutsk": "Asia/Irkutsk",
  "Moscow+06 - Yakutsk": "Asia/Yakutsk",
  "Moscow+07 - Magadan": "Asia/Magadan",
  "Moscow+07 - Yuzhno-Sakhalinsk": "Asia/Vladivostok",
  "Moscow+09 - Petropavlovsk-Kamchatskiy": "Asia/Kamchatka",
  Nairobi: "Africa/Nairobi",
  Noumea: "Pacific/Noumea",
  Paris: "Europe/Paris",
  "Pago Pago": "Pacific/Pago_Pago",
  "Port Moresby": "Pacific/Port_Moresby",
  Rangoon: "Asia/Rangoon",
  Riga: "Europe/Riga",
  Riyadh: "Asia/Riyadh",
  Rome: "Europe/Rome",
  Seoul: "Asia/Seoul",
  Singapore: "Asia/Singapore",
  Sofia: "Europe/Sofia",
  "South Georgia": "Atlantic/South_Georgia",
  Stockholm: "Europe/Stockholm",
  Taipei: "Asia/Taipei",
  Tallinn: "Europe/Tallinn",
  Tashkent: "Asia/Tashkent",
  Tbilisi: "Asia/Tbilisi",
  Tehran: "Asia/Tehran",
  Tokyo: "Asia/Tokyo",
  Tongatapu: "Pacific/Tongatapu",
  Ulaanbaatar: "Asia/Ulaanbaatar",
  Vienna: "Europe/Vienna",
  Vilnius: "Europe/Vilnius",
  Warsaw: "Europe/Warsaw",
  "Western Time - Perth": "Australia/Perth",
  Yerevan: "Asia/Yerevan",
};

export const TIMEZONE_ABBREVIATIONS = {
  "America/Los_Angeles": "PST",
  "America/Tijuana": "PST",
  "America/Denver": "MST",
  "America/Phoenix": "MST",
  "America/Mazatlan": "MST",
  "America/Chicago": "CST",
  "America/Mexico_City": "CST",
  "America/Regina": "CST",
  "America/New_York": "EST",
  "America/Anchorage": "AKT",
  "America/Juneau": "AKT",
  "America/Guatemala": "CST",
  "America/Halifax": "AST",
  "America/Bogota": "COT",
  "America/Lima": "PET",
  "America/Caracas": "VET",
  "America/Guyana": "GYT",
  "America/La_Paz": "BOT",
  "America/Argentina/Buenos_Aires": "ART",
  "America/Godthab": "WGT",
  "America/Montevideo": "UYT",
  "America/St_Johns": "NST",
  "America/Santiago": "CLT",
  "America/Sao_Paulo": "BRT",
  "Africa/Algiers": "CET",
  "Asia/Almaty": "ALMT",
  "Europe/Amsterdam": "CET",
  "Pacific/Apia": "WST",
  "Europe/Athens": "EET",
  "Pacific/Auckland": "NZST",
  "Atlantic/Azores": "AZOT",
  "Asia/Baghdad": "AST",
  "Asia/Baku": "AZT",
  "Asia/Bangkok": "ICT",
  "Europe/Berlin": "CET",
  "Europe/Brussels": "CET",
  "Europe/Bucharest": "EET",
  "Europe/Budapest": "CET",
  "Africa/Cairo": "EET",
  "Atlantic/Cape_Verde": "CVT",
  "Africa/Casablanca": "WET",
  "Europe/Belgrade": "CET",
  "Europe/Prague": "CET",
  "Australia/Adelaide": "ACST",
  "Australia/Darwin": "ACST",
  "Asia/Shanghai": "CST",
  "Asia/Colombo": "IST",
  "Europe/Copenhagen": "CET",
  "Asia/Dhaka": "BDT",
  "Europe/Dublin": "IST",
  "Australia/Brisbane": "AEST",
  "Australia/Hobart": "AEDT",
  "Australia/Sydney": "AEDT",
  "Pacific/Honolulu": "HST",
  "Pacific/Fakaofo": "TKT",
  "Pacific/Fiji": "FJT",
  "Pacific/Guadalcanal": "SBT",
  "Pacific/Guam": "ChST",
  "Europe/Helsinki": "EET",
  "Asia/Hong_Kong": "HKT",
  "Europe/Istanbul": "TRT",
  "Asia/Jakarta": "WIB",
  "Asia/Jerusalem": "IST",
  "Africa/Johannesburg": "SAST",
  "Asia/Kabul": "AFT",
  "Asia/Karachi": "PKT",
  "Europe/Kiev": "EET",
  "Asia/Kuala_Lumpur": "MYT",
  "Europe/Lisbon": "WET",
  "Europe/London": "GMT",
  "Europe/Madrid": "CET",
  "Pacific/Majuro": "MHT",
  "Europe/Minsk": "FET",
  "Africa/Monrovia": "GMT",
  "Europe/Moscow": "MSK",
  "Europe/Kaliningrad": "EET",
  "Europe/Samara": "SAMT",
  "Asia/Yekaterinburg": "YEKT",
  "Asia/Krasnoyarsk": "KRAT",
  "Asia/Irkutsk": "IRKT",
  "Asia/Yakutsk": "YAKT",
  "Asia/Magadan": "MAGT",
  "Asia/Vladivostok": "VLAT",
  "Asia/Kamchatka": "PETT",
  "Africa/Nairobi": "EAT",
  "Pacific/Noumea": "NCT",
  "Europe/Paris": "CET",
  "Pacific/Pago_Pago": "SST",
  "Pacific/Port_Moresby": "PGT",
  "Asia/Rangoon": "MMT",
  "Europe/Riga": "EET",
  "Asia/Riyadh": "AST",
  "Europe/Rome": "CET",
  "Asia/Seoul": "KST",
  "Asia/Singapore": "SGT",
  "Europe/Sofia": "EET",
  "Atlantic/South_Georgia": "GST",
  "Europe/Stockholm": "CET",
  "Asia/Taipei": "CST",
  "Europe/Tallinn": "EET",
  "Asia/Tashkent": "UZT",
  "Asia/Tbilisi": "GET",
  "Asia/Tehran": "IRST",
  "Asia/Tokyo": "JST",
  "Pacific/Tongatapu": "TOT",
  "Asia/Ulaanbaatar": "ULAT",
  "Europe/Vienna": "CET",
  "Europe/Vilnius": "EET",
  "Europe/Warsaw": "CET",
  "Australia/Perth": "AWST",
  "Asia/Yerevan": "AMT",
};

export const CLIENT_STATUS = {
  SELF_SERVICE_INTAKE: "Self Service Intake",
  AWAITING_ONBOARDING: "Awaiting Orientation Call",
  AWAITING_ELIGIBILITY: "Awaiting Insurance Assessment Approval",
  ELIGIBLE: "Ready For Assessment",
  ELIGIBLE_NOW: "Ready For Assessment",
  ASSESSMENT_SCHEDULED_NOW: "Assessment Scheduled",
  ASSESSMENT_COMPLETED: "Assessment Completed (Awaiting Treatment Plan)",
  APPROVE_PLAN: "Awaiting Client Approval",
  AWAITING_INSURANCE_APPROVAL: "Awaiting Insurance Approval",
  INSURANCE_APPROVED: "Ready For Therapy",
  FULL_CUSTOMER: "In Therapy",
};

export const CLINICIAN_STATUS = {
  CREDENTIALED: "Credentialed",
  SUBMITTED: "Submitted",
  ASSIGNABLE_PRIVATE_PAY: "Assignable Private Pay",
  CAQH: "CAQH",
  APPLIED: "Applied",
  REQUEST_CHANGES: "Request Changes",
  INVITED: "Invited",
  REJECTED: "Rejected",
  NEEDS_CALENDAR: "Needs Calendar",
  CREDENTIALED_NO_CALENDAR: "Credentialed No Calendar",
  APPLICATION_IN_PROGRESS: "Application In Progress",
  INTERVIEWING: "Interviewing",
  CREDENTIALING: "Credentialing",
};

export const DOCUMENTS_VIEW_UNSUPPORTED_FILES = [
  "application/zip",
  "application/json",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/msword",
  "image/tiff",
  "image/webp",
  "image/svg+xml",
  "text/xml",
  "image/vnd.microsoft.icon",
];

export const DOCUMENTS_VIEW_SUPPORTED_FILES = [
  "text/html",
  "application/pdf",
  "image/jpeg",
  "image/gif",
  "image/png",
];

export const BILLING_TYPES = {
  CAREGIVER_TRAINING: "Caregiver Training",
  ASSESSMENT: "Assessment",
  DIRECT_THERAPY: "Direct Therapy",
  ORIENTATION: "Orientation",
};

export const RELATIONSHIPS = {
  caregiver: "Caregiver",
  child: "Child",
  dependent: "Dependent",
  family_member: "Family Member",
  legal_guardian: "Legal Guardian",
  is_parent: "Parent",
  self: "Self",
  spouse: "Spouse",
};

export const COUNTRIES = {
  US: "United States of America",
  CA: "Canada",
  MX: "Mexico",
  AF: "Afghanistan",
  AX: "Åland Islands",
  AL: "Albania",
  DZ: "Algeria",
  AS: "American Samoa",
  AD: "Andorra",
  AO: "Angola",
  AI: "Anguilla",
  AQ: "Antarctica",
  AG: "Antigua and Barbuda",
  AR: "Argentina",
  AM: "Armenia",
  AW: "Aruba",
  AU: "Australia",
  AT: "Austria",
  AZ: "Azerbaijan",
  BS: "Bahamas",
  BH: "Bahrain",
  BD: "Bangladesh",
  BE: "Belgium",
  BZ: "Belize",
  BJ: "Benin",
  BM: "Bermuda",
  BT: "Bhutan",
  BO: "Bolivia",
  BA: "Bosnia and Herzegovina",
  BW: "Botswana",
  BV: "Bouvet Island",
  BR: "Brazil",
  IO: "British Indian Ocean Territory",
  BN: "Brunei Darussalam",
  BG: "Bulgaria",
  BF: "Burkina Faso",
  BI: "Burundi",
  KH: "Cambodia",
  CM: "Cameroon",
  CV: "Cape Verde",
  KY: "Cayman Islands",
  CF: "Central African Republic",
  TD: "Chad",
  CL: "Chile",
  CN: "China",
  CX: "Christmas Island",
  CC: "Cocos (Keeling) Islands",
  CO: "Colombia",
  KM: "Comoros",
  CG: "Congo",
  CD: "Congo, The Democratic Republic of the",
  CK: "Cook Islands",
  CR: "Costa Rica",
  CI: "Cote D Ivoire",
  HR: "Croatia",
  CU: "Cuba",
  CY: "Cyprus",
  CZ: "Czech Republic",
  DK: "Denmark",
  DJ: "Djibouti",
  DM: "Dominica",
  DO: "Dominican Republic",
  EC: "Ecuador",
  EG: "Egypt",
  SV: "El Salvador",
  GQ: "Equatorial Guinea",
  ER: "Eritrea",
  EE: "Estonia",
  ET: "Ethiopia",
  FK: "Falkland Islands (Malvinas)",
  FO: "Faroe Islands",
  FJ: "Fiji",
  FI: "Finland",
  FR: "France",
  GF: "French Guiana",
  PF: "French Polynesia",
  TF: "French Southern Territories",
  GA: "Gabon",
  GM: "Gambia",
  GE: "Georgia",
  DE: "Germany",
  GH: "Ghana",
  GI: "Gibraltar",
  GR: "Greece",
  GL: "Greenland",
  GD: "Grenada",
  GP: "Guadeloupe",
  GU: "Guam",
  GT: "Guatemala",
  GG: "Guernsey",
  GN: "Guinea",
  GW: "Guinea-Bissau",
  GY: "Guyana",
  HT: "Haiti",
  HM: "Heard Island and Mcdonald Islands",
  VA: "Holy See (Vatican City State)",
  HN: "Honduras",
  HK: "Hong Kong",
  HU: "Hungary",
  IS: "Iceland",
  IN: "India",
  ID: "Indonesia",
  IR: "Iran, Islamic Republic Of",
  IQ: "Iraq",
  IE: "Ireland",
  IM: "Isle of Man",
  IL: "Israel",
  IT: "Italy",
  JM: "Jamaica",
  JP: "Japan",
  JE: "Jersey",
  JO: "Jordan",
  KZ: "Kazakhstan",
  KE: "Kenya",
  KI: "Kiribati",
  KP: "Korea, Democratic People Republic of",
  KR: "Korea, Republic of",
  KW: "Kuwait",
  KG: "Kyrgyzstan",
  LA: "Lao People Democratic Republic",
  LV: "Latvia",
  LB: "Lebanon",
  LS: "Lesotho",
  LR: "Liberia",
  LY: "Libyan Arab Jamahiriya",
  LI: "Liechtenstein",
  LT: "Lithuania",
  LU: "Luxembourg",
  MO: "Macao",
  MK: "North Macedonia",
  MG: "Madagascar",
  MW: "Malawi",
  MY: "Malaysia",
  MV: "Maldives",
  ML: "Mali",
  MT: "Malta",
  MH: "Marshall Islands",
  MQ: "Martinique",
  MR: "Mauritania",
  MU: "Mauritius",
  YT: "Mayotte",
  FM: "Micronesia, Federated States of",
  MD: "Moldova, Republic of",
  MC: "Monaco",
  MN: "Mongolia",
  MS: "Montserrat",
  MA: "Morocco",
  MZ: "Mozambique",
  MM: "Myanmar",
  NA: "Namibia",
  NR: "Nauru",
  NP: "Nepal",
  NL: "Netherlands",
  AN: "Netherlands Antilles",
  NC: "New Caledonia",
  NZ: "New Zealand",
  NI: "Nicaragua",
  NE: "Niger",
  NG: "Nigeria",
  NU: "Niue",
  NF: "Norfolk Island",
  MP: "Northern Mariana Islands",
  NO: "Norway",
  OM: "Oman",
  PK: "Pakistan",
  PW: "Palau",
  PS: "Palestinian Territory, Occupied",
  PA: "Panama",
  PG: "Papua New Guinea",
  PY: "Paraguay",
  PE: "Peru",
  PH: "Philippines",
  PN: "Pitcairn",
  PL: "Poland",
  PT: "Portugal",
  PR: "Puerto Rico",
  QA: "Qatar",
  RE: "Reunion",
  RO: "Romania",
  RU: "Russian Federation",
  RW: "RWANDA",
  SH: "Saint Helena",
  KN: "Saint Kitts and Nevis",
  LC: "Saint Lucia",
  PM: "Saint Pierre and Miquelon",
  VC: "Saint Vincent and the Grenadines",
  WS: "Samoa",
  SM: "San Marino",
  ST: "Sao Tome and Principe",
  SA: "Saudi Arabia",
  SN: "Senegal",
  CS: "Serbia and Montenegro",
  SC: "Seychelles",
  SL: "Sierra Leone",
  SG: "Singapore",
  SK: "Slovakia",
  SI: "Slovenia",
  SB: "Solomon Islands",
  SO: "Somalia",
  ZA: "South Africa",
  GS: "South Georgia and the South Sandwich Islands",
  ES: "Spain",
  LK: "Sri Lanka",
  SD: "Sudan",
  SR: "Suriname",
  SJ: "Svalbard and Jan Mayen",
  SZ: "Swaziland",
  SE: "Sweden",
  CH: "Switzerland",
  SY: "Syrian Arab Republic",
  TW: "Taiwan, Province of China",
  TJ: "Tajikistan",
  TZ: "Tanzania, United Republic of",
  TH: "Thailand",
  TL: "Timor-Leste",
  TG: "Togo",
  TK: "Tokelau",
  TO: "Tonga",
  TT: "Trinidad and Tobago",
  TN: "Tunisia",
  TR: "Turkey",
  TM: "Turkmenistan",
  TC: "Turks and Caicos Islands",
  TV: "Tuvalu",
  UG: "Uganda",
  UA: "Ukraine",
  AE: "United Arab Emirates",
  GB: "United Kingdom",
  UM: "United States Minor Outlying Islands",
  UY: "Uruguay",
  UZ: "Uzbekistan",
  VU: "Vanuatu",
  VE: "Venezuela",
  VN: "Viet Nam",
  VG: "Virgin Islands, British",
  VI: "Virgin Islands, U.S.",
  WF: "Wallis and Futuna",
  EH: "Western Sahara",
  YE: "Yemen",
  ZM: "Zambia",
  ZW: "Zimbabwe",
};
