import { call, put, takeEvery } from "redux-saga/effects";
import actions from "../actions";
import { getUserEmailLog, sendSMSToCaregiver, getSMSThreadByClient } from "../api/communication";

function* getUserEmailLogAsync({ payload }) {
  try {
    const sentEmails = yield call(getUserEmailLog, payload);
    yield put(actions.getUserEmailLogSuccess(sentEmails.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* sendSMSToCaregiverAsync({ payload }) {
  try {
    yield call(sendSMSToCaregiver, payload);
    yield put(actions.sendSMSToCaregiverSuccess());
    yield put(actions.getSMSThreadByClient(payload.clientId));
  } catch (e) {
    yield put(actions.sendSMSToCaregiverFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getSMSThreadByClientAsync({ payload }) {
  try {
    const smsThread = yield call(getSMSThreadByClient, payload);
    yield put(actions.getSMSThreadByClientSuccess(smsThread.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

export default function* communicationSaga() {
  yield takeEvery(actions.GET_USER_EMAIL_LOG, getUserEmailLogAsync);
  yield takeEvery(actions.SEND_SMS_TO_CAREGIVER, sendSMSToCaregiverAsync);
  yield takeEvery(actions.GET_SMS_THREAD_BY_CLIENT, getSMSThreadByClientAsync);
}
