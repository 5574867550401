import { find, filter } from "lodash";

//CHAT
export const getConnection = (state) => state.chat.connection;
export const getCurrentChannelUrl = (state) => state.chat.currentChatUrl;

//CHATS
export const getChatsPage = (state) => state.chats.page;
export const getChatsPerPage = (state) => state.chats.rowsPerPage;
export const getChatsSort = (state) => state.chats.sort;
export const getChatsSortDirection = (state) => state.chats.sortdirection;
export const getChatsList = (state) => state.chats.chats;
export const getChatsLoading = (state) => state.chats.chatsLoading;
export const getSpecificChat = (state) => state.chats.chat;
export const getChatsCount = (state) => state.chats.count;
export const getChatMessages = (state) => state.chats.messages;
export const getChatIsLoaded = (state) => state.chats.chatLoaded;
export const getChatsFilter = (state) => state.chats.filter;

export const getAllChatTags = (state) => state.chats.tags;
export const getMessageTags = (state) => state.chats.messageTags;
export const getMessageTagsLoading = (state) => state.chats.tagsLoading;

//CLINICIAN
export const getClinicianCredentialData = (state) => state.clinician.clinicianCredentialData;
export const getClinicianCredentialDataLoading = (state) =>
  state.clinician.clinicianCredentialDataLoading;
export const getClinicianCredentialDataError = (state) =>
  state.clinician.clinicianCredentialDataError;
export const getClinicianWorkflowStatus = (state) => state.clinician.workflowStatus;
export const getClinicianApplicationRejectionNote = (state) => state.clinician.rejectionNote;
export const getCurrentClinicianId = (state) => state.clinician.clinicianId;
export const getClinicianBasicInfo = (state) => {
  if (!state.clinician.basicInfoSuccess) {
    return null;
  }
  return state.clinician.basicInfo;
};
export const getClinicianBasicInfoLoading = (state) => state.clinician.basicInfoLoading;
export const getClinicianBasicInfoSaving = (state) => state.clinician.basicInfoSaving;
export const getClinicianBasicInfoSuccess = (state) => state.clinician.basicInfoSuccess;
export const getClinicianDriversLicenseLoading = (state) => state.clinician.driversLicenseLoading;
export const getClinicianDriversLicenseSaving = (state) => state.clinician.driversLicenseSaving;
export const getClinicianDriversLicenseSuccess = (state) => state.clinician.driversLicenseSuccess;
export const getClinicianDriversLicenseError = (state) => state.clinician.driversLicenseError;
export const getClinicianCredentials = (state) => {
  if (!state.clinician.credentialsSuccess) {
    return null;
  }
  return state.clinician.credentials;
};
export const getClinicianCredentialsLoading = (state) => state.clinician.credentialsLoading;
export const getClinicianCredentialsSaving = (state) => state.clinician.credentialsSaving;
export const getClinicianCredentialsSuccess = (state) => state.clinician.credentialsSuccess;
export const getClinicianStateLicenses = (state) => state.clinician.licenses;
export const getClinicianStateLicense = (state) => state.clinician.license;
export const getClinicianLicensesLoading = (state) => state.clinician.licensesLoading;
export const getClinicianLicensesSaving = (state) => state.clinician.licensesSaving;
export const getClinicianLicensesSuccess = (state) => state.clinician.licensesSuccess;
export const getClinicianCertificationLoading = (state) => state.clinician.certificationLoading;
export const getClinicianCertificationSaving = (state) => state.clinician.certificationSaving;
export const getClinicianCertificationSuccess = (state) => state.clinician.certificationSuccess;
export const getClinicianAvailability = (state) => {
  if (!state.clinician.availabilitySuccess) {
    return null;
  }
  return state.clinician.availability;
};
export const getClinicianAvailabilityLoading = (state) => state.clinician.availabilityLoading;
export const getClinicianAvailabilitySaving = (state) => state.clinician.availabilitySaving;
export const getClinicianAvailabilitySuccess = (state) => state.clinician.availabilitySuccess;
export const getClinicianDocuments = (state) => {
  if (!state.clinician.documentsSuccess) {
    return null;
  }
  return state.clinician.documents;
};
export const getClinicianDocumentsLoading = (state) => state.clinician.documentsLoading;
export const getClinicianDocumentsSaving = (state) => state.clinician.documentsSaving;
export const getClinicianDocumentsSuccess = (state) => state.clinician.documentsSuccess;
export const getClinicianDocumentsError = (state) => state.clinician.documentsError;
export const getClinicianApplicationLoading = (state) => state.clinician.applicationLoading;
export const getClinicianApplicationSuccess = (state) => state.clinician.applicationSuccess;
export const getClinicianCalendarSynced = (state) => state.clinician.calendarSynced;
export const getClinicianCalendarSyncedDate = (state) => state.clinician.calendarSyncedDate;
export const getClinicianCalendarSyncedEmail = (state) => state.clinician.calendarSyncedEmail;
export const getClinicianCalendarSyncLoading = (state) => state.clinician.calendarSyncLoading;
export const getClinicianCalendarSyncSuccess = (state) => state.clinician.calendarSyncSuccess;
export const getClinicianApplicationError = (state) => state.clinician.applicationError;
export const getClinicianApplicationErrorMessage = (state) =>
  state.clinician.applicationErrorMessage;
export const getClinicianApplicantCognitoId = (state) => state.clinician.applicantCognitoId;
export const getClinicianApplicantClinicianId = (state) => state.clinician.applicantClinicianId;

export const getClinicianInsurances = (state) => state.clinician.insurances;
export const getClinicianInsuranceLoading = (state) => state.clinician.insuranceLoading;
export const getClinicianInsuranceSaving = (state) => state.clinician.insuranceSaving;
export const getClinicianInsuranceSuccess = (state) => state.clinician.insuranceSuccess;
export const getClinicianInsuranceSaveFail = (state) => state.clinician.insuranceSaveFail;
export const getClinicianBioSaving = (state) => state.clinician.bioSaving;
export const getClinicianBioSuccess = (state) => state.clinician.bioSuccess;
export const getClinicianBioFail = (state) => state.clinician.bioFail;
export const getClinicianBioPictureSaving = (state) => state.clinician.bioPictureSaving;
export const getClinicianBioPictureSuccess = (state) => state.clinician.bioPictureSuccess;
export const getClinicianBioPictureFail = (state) => state.clinician.bioPictureFail;
export const getClinicianDashboard = (state) => {
  if (!state.clinician.dashboardSuccess) {
    return null;
  }
  return state.clinician.dashboard;
};
export const getClinicianDashboardLoading = (state) => state.clinician.dashboardLoading;
export const getClinicianDashboardSuccess = (state) => state.clinician.dashboardSuccess;
export const getClinicianClientsList = (state) => state.clinician.clients;
export const getClinicianClientsLoading = (state) => state.clinician.clientsLoading;
export const getClinicianClientsPage = (state) => state.clinician.page;
export const getClinicianClientsPerPage = (state) => state.clinician.rowsPerPage;
export const getClinicianClientsSort = (state) => state.clinician.sort;
export const getClinicianClientsSortDirection = (state) => state.clinician.sortdirection;
export const getClinicianClientsCount = (state) => state.clinician.count;

export const getClinicianPastClientsList = (state) => state.clinician.pastClients;
export const getClinicianPastClientsPage = (state) => state.clinician.pastClientsPage;
export const getClinicianPastClientsSort = (state) => state.clinician.pastClientsSort;

export const getAppointmentChangeLogs = (state) => state.appointmentChangeLog.appointmentChangeLogs;

export const getClinicianPastClientsSortDirection = (state) =>
  state.clinician.pastClientsSortDirection;
export const getClinicianPastClientsCount = (state) => state.clinician.pastClientsCount;

export const getClinicianPayStructure = (state) => state.clinician.payStructure;
export const getClinicianPayStructureLoading = (state) => state.clinician.payStructureLoading;
export const getClinicianPayStructureSaving = (state) => state.clinician.payStructureSaving;
export const getClinicianPayStructureSuccess = (state) => state.clinician.payStructureSuccess;

export const getClinicianLiabilityExpirationLoading = (state) =>
  state.clinician.liabilityExpirationLoading;
export const getClinicianLiabilityExpirationSuccess = (state) =>
  state.clinician.liabilityExpirationSuccess;
export const getClinicianLiabilityExpirationError = (state) =>
  state.clinician.liabilityExpirationError;

export const getUpdateClinicianEmailLoading = (state) =>
  state.clinician.updateClinicianEmailLoading;
export const getUpdateClinicianEmailSuccess = (state) =>
  state.clinician.updateClinicianEmailSuccess;
export const getUpdateClinicianEmailError = (state) => state.clinician.updateClinicianEmailError;
export const getUpdateClinicianEmailErrorMessage = (state) =>
  state.clinician.updateClinicianEmailErrorMessage;

export const getClinicianClientStatusInfo = (state) => state.clinician.clientStatusInfo;
export const getClinicianClientStatusInfoLoading = (state) =>
  state.clinician.clientStatusInfoLoading;

export const getClinicianNotes = (state) => state.clinician.notes;
export const getClinicianNoteSaving = (state) => state.clinician.noteSaving;
export const getClinicianNoteSuccess = (state) => state.clinician.noteSuccess;

export const getClinicianNotifications = (state) => state.clinician.notifications;
export const getClinicianNotificationsLoading = (state) => state.clinician.notificationsLoading;
export const getClinicianNotificationsSuccess = (state) => state.clinician.notificationsSuccess;

export const getClinicianCallsNeverStarted = (state) => state.clinician.callsNeverStarted;

//  CLINICIANS
export const getInviteClinicianLoading = (state) => state.clinicians.inviteClinicianLoading;
export const getInviteClinicianSuccess = (state) => state.clinicians.inviteClinicianSuccess;
export const getInviteClinicianError = (state) => state.clinicians.inviteClinicianError;
export const getCliniciansList = (state) => state.clinicians.clinicians;
export const getClinicianDeactivationReasons = (state) => state.clinicians.deactivationReasons;
export const getDeactivatedClinicians = (state) => state.clinicians.deactivatedClinicians;
export const getCliniciansCount = (state) => state.clinicians.count;
export const getCliniciansSort = (state) => state.clinicians.sort;
export const getCliniciansStatus = (state) => state.clinicians.status;
export const getCliniciansSortDirection = (state) => state.clinicians.sortdirection;
export const getClinicianDetails = (state) => state.clinicians.clinician;
export const getClinicianLoaded = (state) => state.clinicians.clinicianLoaded;
export const getClincicianBasicDetails = (state) => state.clinicians.clinician.details;
export const getClinicianAddress = (state) => state.clinicians.clinician.address;
export const getClinicianPhoneNumbers = (state) => state.clinicians.clinician.phoneNumbers;
export const getClinicianSaving = (state) => state.clinicians.saving;
export const getClinicianSaveError = (state) => state.clinicians.saveError;
export const getClinicianId = (state) => {
  if (!state.clinicians.clinicianLoaded) {
    return null;
  }
  return state.clinicians.clinician.details.id;
};
export const getAssignableClinicians = (state) => state.clinicians.assignableClinicians;
export const getAssignableCliniciansLoading = (state) =>
  state.clinicians.assignableCliniciansLoading;
export const getClinicianContactInfoId = (state) =>
  state.clinicians.clinician.details.clinician_contact_info_id;
export const getClinicianNPI = (state) => state.clinicians.clinician.details.npi;
export const getClinicianCertificates = (state) => state.clinicians.clinician.certificates;
export const getClinicianLicenses = (state) => state.clinicians.clinician.licenses;

export const getCliniciansPage = (state) => state.clinicians.page;
export const getCliniciansPerPage = (state) => state.clinicians.rowsPerPage;
export const getCliniciansLoading = (state) => state.clinicians.cliniciansLoading;

//COMMUNICATION
export const getSentEmails = (state) => state.communication.emailList;
export const getSentEmailsLoading = (state) => state.communication.sentEmailsLoading;
export const getSentEmailsSuccess = (state) => state.communication.sentEmailsSuccess;

//CUSTOMERS
export const getCustomerList = (state) => state.customers.customers;
export const getCustomersLoading = (state) => state.customers.customersLoading;
export const getDeactivatedCustomerList = (state) => state.customers.deactivatedCustomers;
export const getCustomerDeactivationReasons = (state, type = "main") => {
  return state.customers.deactivationReasons?.filter((reason) => reason.type === type);
};
export const getCustomerDepartureOptions = (state, type = "main") => {
  return state.customers.customerDepartureOptions?.filter((option) => option.type === type);
};

export const getCustomersCount = (state) => state.customers.count;
export const getCustomerDetails = (state) => state.customers.customer;
export const getCustomerDocuments = (state) => {
  return state.customers.customer?.documents || [];
};
export const getCustomerDocumentsLoading = (state) => state.customers.documentsLoading;
export const getCustomerTreatmentPlansLoading = (state) => state.customers.treatmentPlansLoading;
export const getCustomerCliniciansLoading = (state) => state.customers.cliniciansLoading;
export const getCustomerVideoCalls = (state) => state.customers.customer.upcomingVideoCalls;
export const getCustomerVideoCallsLoading = (state) => state.customers.videoCallsLoading;
export const getCustomerApprovedUnitsLoading = (state) => state.customers.approvedUnitsLoading;
export const getCustomerPhysiciansLoading = (state) => state.customers.physiciansLoading;
export const getCustomerPastCliniciansLoading = (state) => state.customers.pastCliniciansLoading;
export const getCustomerAuthorizationCodesLoading = (state) =>
  state.customers.authorizationCodesLoading;
export const getuploadDocumentLoading = (state) => state.customers.uploadDocumentLoading;

export const getDocumentTypes = (state) => state.customers.documentTypes;
export const getDocumentMatrix = (state) => state.customers.documentMatrix;
export const getDocumentMatrixLoading = (state) => state.customers.documentMatrixLoading;

export const getCustomerDocumentRequests = (state) => state.customers.documentRequests;
export const getDocumentRequestLoading = (state) => state.customers.documentRequestLoading;
export const getDocumentRequestSuccess = (state) => state.customers.documentRequestSuccess;
export const getDocumentRequests = (state) => state.customers.documentRequests;

export const getStatusUpdatesLoading = (state) => state.customers.statusUpdatesLoading;
export const getStatusUpdatesSuccess = (state) => state.customers.statusUpdatesSuccess;

export const getCustomersBillableTimePeriod = (state) => state.customers.billableTimePeriod;

export const getCustomersPage = (state) => state.customers.page;
export const getCustomersPerPage = (state) => state.customers.rowsPerPage;
export const getCustomersSort = (state) => state.customers.sort;
export const getCustomersSearch = (state) => state.customers.search;
export const getCustomersSortDirection = (state) => state.customers.sortdirection;
export const getInsuranceCustomersFilter = (state) => state.customers.insuranceCustomersFilter;
export const getInsuranceCustomersType = (state) => state.customers.insuranceCustomersType;
export const getInsuranceCustomersState = (state) => state.customers.insuranceCustomersState;
export const getCustomerLoaded = (state) => state.customers.customerLoaded;
export const getCustomerUserId = (state) =>
  state.customers.customer.details === undefined ? null : state.customers.customer.details.userid;
export const getCustomerClientId = (state) =>
  state.customers.customer.children === undefined
    ? null
    : state.customers.customer.details.client_id;
export const getCustomerLegacy = (state) =>
  state.customers.customer.details === undefined ? false : state.customers.customer.details.legacy;
export const getCustomerWorkflowStatus = (state) =>
  state.customers.customer.details === undefined
    ? ""
    : state.customers.customer.details.workflow_status;
export const getCustomerVerified = (state) =>
  state.customers.customer.details === undefined
    ? false
    : state.customers.customer.details.verified;
export const getCustomersExpired = (state) => {
  if (!state.customers.customers) {
    return 0;
  }

  if (state.customers.customers.length === 0) {
    return 0;
  }

  const expired = filter(state.customers.customers, { expired: true });
  return expired.length;
};
export const getCustomerAdminNotes = (state) => state.customers.customer?.notes || [];
export const getCustomerSMSThread = (state) => state.customers.customer?.smsThread || [];
export const getUserIsAssignedClinician = (state) => {
  const userId = state.user?.id;
  const primaryClinician = state.customers.customer?.clinician;
  const secondaryClinician = state.customers.customer?.secondaryClinician;
  return (
    primaryClinician?.clinician_user_id == userId || secondaryClinician?.clinician_user_id == userId
  );
};

export const getMarkingEligible = (state) => state.customers.markingEligible;

export const getMarkingApproved = (state) => state.customers.markingApproved;

export const getResendingLoginEmail = (state) => state.customers.resendingLoginEmail;

export const getUploadingTreatmentPlan = (state) => state.customers.uploadingTreatmentPlan;

export const getCreateCustomerSaving = (state) => state.customers.saving;
export const getCreateCustomerSuccess = (state) => state.customers.savingSuccess;
export const getCreateCustomerError = (state) => state.customers.createCustomerError;
export const getCreateUserCognitoId = (state) => state.customers.createUserCognitoId;
export const getCreateUserId = (state) => state.customers.createUserId;
export const getInvitationGUID = (state) => state.customers.invitationGUID;

export const getCustomerSearchResults = (state) => state.customers.searchResults;
export const getCustomerSearchLoading = (state) => state.customers.searchLoading;

export const getCustomerSavingForLater = (state) => state.customers.savingForLater;
export const getCustomerSavingForLaterSuccess = (state) => state.customers.savingForLaterSuccess;
export const getCustomerSavingForLaterError = (state) => state.customers.savingForLaterError;
export const getCustomerSavedUserId = (state) => state.customers.savedUserId;
export const getSavedForLaterCustomers = (state) => state.customers.savedCustomers;
export const getSavedCustomersLoading = (state) => state.customers.savedCustomersLoading;
export const getSavedCustomersError = (state) => state.customers.savedCustomersError;
export const getSavedCustomerDetails = (state) => state.customers.savedCustomerDetails;
export const getSavingLead = (state) => state.customers.savingLead;
export const getSaveLeadSuccess = (state) => state.customers.saveLeadSuccess;

export const getUpdatingCustomerInfo = (state) => state.customers.updatingCustomerInfo;
export const getUpdatingCustomerInfoSuccess = (state) =>
  state.customers.updatingCustomerInfoSuccess;
export const getSavingCustomerPhysician = (state) => state.customers.savingCustomerPhysician;
export const getSavingCustomerPhysicianSuccess = (state) =>
  state.customers.savingCustomerPhysicianSuccess;
export const getSavingCustomerPhysicianError = (state) =>
  state.customers.savingCustomerPhysicianError;
export const getTreatmentPlanLoading = (state) => state.customers.treatmentPlanLoading;
export const getTreatmentPlanSuccess = (state) => state.customers.treatmentPlanSuccess;
export const getCustomerBillableItems = (state) => state.customers.billableItems;
export const getCustomerBillableItemsLoading = (state) => state.customers.billableItemsLoading;
export const getCustomerBillableItemsPage = (state) => state.customers.billableItemsPage;
export const getCustomerBillableItemsRowsPerPage = (state) =>
  state.customers.billableItemsRowsPerPage;
export const getCustomerBillableItemsCount = (state) => state.customers.billableItemsCount;
export const getImportClientLoading = (state) => state.customers.importClientLoading;
export const getImportClientError = (state) => state.customers.importClientError;
export const getImportClientErrorMessage = (state) => state.customers.importClientErrorMessage;
export const getImportClientUserId = (state) => state.customers.importClientUserId;
export const getImportClientClientId = (state) => state.customers.importClientClientId;
export const getImportClientMissingFields = (state) => state.customers.importClientMissingFields;
export const getImportClientMissingFieldsLoading = (state) =>
  state.customers.importClientMissingFieldsLoading;
export const getImportClientMissingFieldsSuccess = (state) =>
  state.customers.importClientMissingFieldsSuccess;
export const getPrimaryAccountHolderSwitchLoading = (state) =>
  state.customers.primaryAccountHolderSwitchLoading;
export const getPrimaryAccountHolderSwitchSuccess = (state) =>
  state.customers.primaryAccountHolderSwitchSuccess;

export const getUpdateCustomerEmailLoading = (state) => state.customers.updateCustomerEmailLoading;
export const getUpdateCustomerEmailSuccess = (state) => state.customers.updateCustomerEmailSuccess;
export const getUpdateCustomerEmailError = (state) => state.customers.updateCustomerEmailError;
export const getUpdateCustomerEmailErrorMessage = (state) =>
  state.customers.updateCustomerEmailErrorMessage;

export const getUpdateCustomerTimezoneLoading = (state) =>
  state.customers.updateCustomerTimezoneLoading;
export const getUpdateCustomerTimezoneSuccess = (state) =>
  state.customers.updateCustomerTimezoneSuccess;
export const getUpdateCustomerTimezoneError = (state) =>
  state.customers.updateCustomerTimezoneError;

export const getUpdateUserInsuranceStatusLoading = (state) =>
  state.customers.updateUserInsuranceStatusLoading;
export const getUpdateUserInsuranceStatusSuccess = (state) =>
  state.customers.updateUserInsuranceStatusSuccess;
export const getUpdateUserInsuranceStatusError = (state) =>
  state.customers.updateUserInsuranceStatusError;

export const getCustomerPastCalls = (state) => state.customers.pastCalls;
export const getCustomerPastCallsLoading = (state) => state.customers.pastCallsLoading;

export const getEnableClientStaticPageLoading = (state) => state.customers.enableStaticPageLoading;
export const getEnableClientStaticPageSuccess = (state) => state.customers.enableStaticPageSuccess;
export const getEnableClientStaticPageError = (state) => state.customers.enableStaticPageError;

export const getClientTaskList = (state) => state.customers.clientTaskList;
export const getClientTaskListLoading = (state) => state.customers.clientTaskListLoading;
export const getMarkTaskCompleteLoading = (state) => state.customers.markTaskCompleteLoading;
export const getMarkTaskCompleteSuccess = (state) => state.customers.markTaskCompleteSuccess;
export const getMarkTaskCompleteError = (state) => state.customers.markTaskCompleteError;
export const getClientSessionStatistics = (state) => state.customers.clientSessionStatistics;
export const getClientStatisticsLoading = (state) => state.customers.statisticsLoading;

export const getClientCriticalNeeds = (state) => state.customers.criticalNeeds;
export const getSnoozeTaskLoading = (state) => state.customers.snoozeTaskLoading;
export const getSnoozeTaskError = (state) => state.customers.snoozeTaskError;

export const getUpdatingLinkPhysician = (state) => state.customers.updatingLinkPhysician;
export const getUpdatingLinkPhysicianSuccess = (state) =>
  state.customers.updatingLinkPhysicianSuccess;

export const getAllPhysicians = (state) => state.customers.allPhysicians;
export const getAllPhysiciansLoading = (state) => state.customers.allPhysiciansLoading;
export const getAllPhysiciansSuccess = (state) => state.customers.allPhysiciansSuccess;

//INSURANCE
export const getInsuranceProviders = (state) => state.insurance.providers;
export const getInsuranceProvidersLoading = (state) => state.insurance.providersLoading;
export const getInsuranceProvidersNotAccepted = (state) => state.insurance.providersNotAccepted;
export const getInsuranceCodes = (state) => state.insurance.cptCodes;
export const getAvailableInsuranceCodes = (state) => state.insurance.availableCptCodes;
export const getAuthorizationCodes = (state) => state.insurance.authorizationCodes;
export const getInsuranceCodesByUser = (state) => state.insurance.userCodes;
export const getInsuranceCodesByUserLoading = (state) => state.insurance.userCodesLoading;
export const getUserInsurance = (state) => state.insurance.userInsurance;
export const getUserInsuranceLoading = (state) => state.insurance.userInsuranceLoading;
export const getUserBillableTime = (state) => state.insurance.billableTime;
export const getBillableTimeLoading = (state) => state.insurance.billableTimeLoading;
export const getOffPlatformLoading = (state) => state.insurance.offPlatformLoading;
export const getOffPlatformSuccess = (state) => state.insurance.offPlatformSuccess;
export const getOffPlatformWork = (state) => state.insurance.offPlatformWork;
export const getUpdateBillableTimeInsuranceLoding = (state) =>
  state.insurance.updateBillableTimeInsuranceCodeLoading;
export const getUpdateBillableTimeInsuranceSuccess = (state) =>
  state.insurance.updateBillableTimeInsuranceCodeSuccess;
export const getUpdateBillableTimeInsuranceError = (state) =>
  state.insurance.updateBillableTimeInsuranceCodeError;
export const getBillableTimeSubmitting = (state) => state.insurance.billableTimeSubmitting;
export const getSuperBills = (state) => state.insurance.superBills;
export const getSuperBillsLoading = (state) => state.insurance.superBillsLoading;
export const getSuperBillsPage = (state) => state.insurance.superBillsPage;
export const getSuperBillsCount = (state) => state.insurance.superBillsCount;
export const getSuperBillsOffset = (state) => state.insurance.superBillsOffset;
export const getReferringPhysicians = (state) => state.insurance.referringPhysicians;
export const getCreatePhysicianLoading = (state) => state.insurance.createPhysicianLoading;
export const getCreatePhysicianId = (state) => state.insurance.createPhysicianId;

export const getInsurancePlans = (state) => state.insurance.plans;
export const getInsuranceLoading = (state) => state.insurance.insuranceLoading;
export const getInsuranceSuccess = (state) => state.insurance.insuranceSuccess;
export const getInsuranceListState = (state) => state.insurance.insuranceState;
export const getInsuranceListProviderId = (state) => state.insurance.insuranceProviderId;
export const getInsurancePlansPage = (state) => state.insurance.plansPage;
export const getInsurancePlansRowsPerPage = (state) => state.insurance.plansRowsPerPage;
export const getInsurancePlansCount = (state) => state.insurance.plansCount;
export const getInsurancePlansSort = (state) => state.insurance.plansSort;
export const getInsurancePlansSortDirection = (state) => state.insurance.plansSortDirection;
export const getInsuranceProvidersList = (state) => state.insurance.providersList;
export const getInsurancePlan = (state) => state.insurance.insurancePlan;
export const getInsuranceProvider = (state) => state.insurance.insuranceProvider;
export const getCreatePlanLoading = (state) => state.insurance.createPlanLoading;
export const getCreatePlanSuccess = (state) => state.insurance.createPlanSuccess;
export const getCreatePlanError = (state) => state.insurance.createPlanError;
export const getCreateProviderLoading = (state) => state.insurance.createProviderLoading;
export const getCreateProviderSuccess = (state) => state.insurance.createProviderSuccess;
export const getCreateProviderError = (state) => state.insurance.get;
export const getUpdatePlanLoading = (state) => state.insurance.updatePlanLoading;
export const getUpdatePlanSuccess = (state) => state.insurance.updatePlanSuccess;
export const getUpdatePlanError = (state) => state.insurance.updatePlanError;

//NOTES
export const getNotesOpen = (state) => state.notes.notesOpen;
export const getNotesSaving = (state) => state.notes.saving;
export const getNotesSavingError = (state) => state.notes.savingError;
export const getNotesSaved = (state) => state.notes.saved;
export const getNotesLoading = (state) => state.notes.notesLoading;
export const getNotes = (state) => state.notes.notes;
export const getNotesFields = (state) => state.notes;
export const getQuestionnaireId = (state) => state.notes.completed_questionnaire_id;
export const getNotesClientId = (state) => state.notes.client_id;
export const getSessionNote = (state) => state.notes.sessionNote;
export const getSessionNoteLoading = (state) => state.notes.sessionNoteLoading;
export const getSessionNotes = (state) => state.notes.sessionNotes;
export const getSessionNotesByUser = (state) => state.notes.sessionNotesByUser;
export const getOldSessionNotesByUser = (state) => state.notes.oldSessionNotesByUser;

//PARENT
export const isLoaded = (state) => state.parent.loaded; //userLoaded
export const getParentInfo = (state) => state.parent;
export const getClientId = (state) => state.parent.client_id;
export const getDialogOpen = (state) => state.parent.dialogOpen;
export const getABAInterestDialogOpen = (state) => state.parent.abaInterestOpen;
export const isAlpha = (state) => state.parent.alpha;
export const isBeta = (state) => state.parent.beta;
export const isSubscribed = (state) => state.parent.subscribed;
export const isExpired = (state) => state.parent.expired;
export const canMessage = (state) => state.parent.can_message;
export const getHoursRemaining = (state) => state.parent.hours_remaining;
export const getPaymentDialogOpen = (state) => state.parent.paymentDialogOpen;
export const isAccountHolder = (state) => state.parent.isAccountHolder;
export const completedQuestionnaire = (state) => state.parent.completed_questionnaire;
export const questionnaireAnswers = (state) => state.parent.answers;
export const profileSaving = (state) => state.parent.saving;
export const getChildName = (state) => state.parent.child_name;
export const userClinician = (state) => state.parent.clinician;
export const clinicianSpecialties = (state) => state.parent.specialties;
export const clinicianLoading = (state) => state.parent.clinicianLoading;
export const specialtiesLoading = (state) => state.parent.specialtiesLoading;
export const clinicianSelected = (state) => state.parent.clinicianSelected;
export const clinicianReferred = (state) => state.parent.clinicianReferred;
export const displayClinician = (state) => state.parent.displayClinician;
export const displaySpecialties = (state) => state.parent.displaySpecialties;
export const referralCode = (state) => state.parent.referral_code;
export const referralBannerOpen = (state) => state.parent.referralBannerOpen;
export const joinedCommunities = (state) => {
  if (!state.parent.settings) {
    return false;
  }

  const joinedCommunities = find(state.parent.settings, {
    joinedCommunities: true,
  });
  return !!joinedCommunities;
};
export const assessmentCompleted = (state) => state.parent.completed;
export const userAssessmentId = (state) => state.parent.assessment_id;
export const userQuestionId = (state) => state.parent.current_question_id;
export const dashboard = (state) => state.parent.dashboard;
export const dashboardLoading = (state) => state.parent.dashboardLoading;
export const cancelLoading = (state) => state.parent.cancelLoading;
export const cancelSuccess = (state) => state.parent.cancelSuccess;
export const cancelReasons = (state) => state.parent.cancelReasons;
export const isLegacyUser = (state) => state.parent.legacy;
export const policiesReadAndAccepted = (state) => state.parent.policies_read_and_accepted;
export const phiReleaseReadAndAccepted = (state) => state.parent.phi_release_accepted;
export const getSendingDocumentRequestSignatureLoading = (state) =>
  state.parent.sendingDocumentRequestSignatureLoading;
export const getSendingDocumentRequestSignatureSuccess = (state) =>
  state.parent.sendingDocumentRequestSignatureSuccess;
export const getSendingDocumentRequestSignatureError = (state) =>
  state.parent.sendingDocumentRequestSignatureError;
export const getParentDocumentRequests = (state) => state.parent.documentRequests;
export const workflowStatus = (state) => state.parent.workflow_status;
export const treatmentPlan = (state) => state.parent.treatmentPlan;
export const insuranceDashboard = (state) => state.parent.insuranceDashboard;
export const updatingFullCustomer = (state) => state.parent.updatingFullCustomer;
export const fullCustomerUpdated = (state) => state.parent.fullCustomerUpdated;
export const eligibilityIncomplete = (state) => state.parent.eligibility_incomplete;
export const insuranceRoadmap = (state) => state.parent.roadmap;
export const roadmapStepButtons = (state) => state.parent.roadmapStepButtons;
export const getParentPendingSignatures = (state) => {
  if (!state.parent.pendingSignature) {
    return null;
  }
  let signatures = state.parent.pendingSignature.filter((sig) => sig.no_show === false);
  return signatures;
};
export const getEligibilityFields = (state) => state.parent.eligibilityFields;
export const getProfileComplete = (state) => state.parent.profile_complete;
export const getUserPhysicians = (state) => state.parent.physicians;
export const getUserDetails = (state) => state.parent.userDetails;
export const getChildDetails = (state) => state.parent.childDetails;
export const getIsHuman = (state) => state.parent.isHuman;
export const getRecaptchaLoading = (state) => state.parent.recaptchaLoading;
export const getAgreeToPolicyLoading = (state) => state.parent.agreeToPolicyLoading;
export const getForm = (state) => state.parent.form;
export const getFormSaving = (state) => state.parent.formSaving;
export const getFormSuccess = (state) => state.parent.formSuccess;
export const getCustomerCalendarCalls = (state) => state.parent.calendarCalls;
export const getCustomerCalendarCallsLoading = (state) => state.parent.calendarCallsLoading;
export const getCustomerDashboardNotifications = (state) => state.parent.dashboardNotifications;
export const getCustomerDashboardNotificationsLoading = (state) =>
  state.parent.dashboardNotificationsLoading;

export const getUpdatingClientInfo = (state) => state.parent.updatingClientInfo;
export const getUpdatingClientInfoSuccess = (state) => state.parent.updatingClientInfoSuccess;
export const getUpdatingClientInfoFail = (state) => state.parent.updatingClientInfoFail;
export const createInsuranceProviderSuccess = (state) =>
  state.parent.createInsuranceProviderSuccess;
export const createInsuranceProviderFail = (state) => state.parent.createInsuranceProviderFail;

export const getStaticPageDataLoaded = (state) => state.parent.staticPageDataLoaded;
export const getStaticPageDataError = (state) => state.parent.staticPageDataError;
export const getStaticPageDataErrorMessage = (state) => state.parent.staticPageDataErrorMessage;
export const getStaticPageVideoKey = (state) => state.parent.staticPageVideoKey;
export const getStaticPageVideoKeyLoaded = (state) => state.parent.staticPageVideoKeyLoaded;

//REPORTS
export const getReportsLoading = (state) => state.reports.reportsLoading;
export const getMessageTagSummary = (state) => state.reports.messageTagSummary;
export const getMessagesForTag = (state) => state.reports.messagesForTag;
export const getTagRowsPerPage = (state) => state.reports.tagRowsPerPage;
export const getTagMessagesPage = (state) => state.reports.tagPage;
export const getTagMessageCount = (state) => state.reports.tagCount;
export const getVideoCallsReport = (state) => state.reports.videoCalls;
export const getBillingSummary = (state) => state.reports.billingSummary;

//SIGNIN
export const getSignedinState = (state) => state.signin.signedInState;
export const getSigninUsername = (state) => state.signin.username;
export const getSigninPassword = (state) => state.signin.password;
export const getSignInFailure = (state) => state.signin.signinFailure;
export const getSignInFailureMessage = (state) => state.signin.signinFailureMessage;
export const getNotConfirm = (state) => state.signin.notConfirm;
export const getPasswordChange = (state) => state.signin.passwordChangeRequired;
export const getUserAttributes = (state) => state.signin.userAttributes;
export const getNeedsReset = (state) => state.signin.needsReset;
export const getRedirect = (state) => state.signin.redirectUrl;
export const getForgotPasswordSent = (state) => state.signin.forgotPasswordSent;
export const getResetPasswordSuccess = (state) => state.signin.resetPasswordSuccess;
export const getResetPasswordError = (state) => state.signin.resetPasswordError;
export const getResetPasswordErrorMessage = (state) => state.signin.resetPasswordErrorMessage;
export const getVerified = (state) => state.signin.verified;
export const getVerificationError = (state) => state.signin.verificationError;
export const getSigninClear = (state) => state.signin.clear;
export const getRequiredAttributes = (state) => state.signin.requiredAttributes;
export const getLoginInvitation = (state) => state.signin.invitation;
export const getInvitationError = (state) => state.signin.invitationError;

//SYSTEM
export const getCurrentPage = (state) => state.system.currentPage;
export const getMenu = (state) => state.system.menu;
export const getConfirmOpen = (state) => state.system.open;
export const getConfirmType = (state) => state.system.type;
export const getConfirmAccepted = (state) => state.system.accepted;
export const getBuildVersionCode = (state) => state.system.versionCode;

//TESTS
export const getPerformanceTestResult = (state) => state.tests.performance;
export const getPerformanceTestLoading = (state) => state.tests.testLoading;
export const getPerformanceTestSuccess = (state) => state.tests.isSuccess;

//USER
export const getLogin = (state) => state.user.username;
export const getGroups = (state) => state.user.groups;
export const getUserId = (state) => state.user.id;
export const getUser = (state) => state.user;
export const userLoaded = (state) => state.user.loaded;
export const getUserClinicianId = (state) => state.user.clinician_id; //not have clinician_id field?
export const getUserCognitoId = (state) => state.user.cognito_id;
export const getUserDefaultVideoPlatform = (state) => state.user.default_video_platform;
export const isCustomerCare = (state) => state.user.groups.includes("CustomerCare");
export const isClinician = (state) => state.user.groups.includes("Clinicians");
export const isSupervisor = (state) => state.user.groups.includes("ClinicalSupervisor");
export const isCaregiver = (state) => state.user.groups.includes("Caregivers");
export const isAdmin = (state) => state.user.groups.includes("Admins");
export const isSuperAdmin = (state) => state.user.groups.includes("Superadmins");
export const isDirector = (state) => state.user.groups.includes("ClinicalDirector");
export const getHomeUrl = (state) => {
  if (!state.user.groups) {
    return "/";
  }
  return "/dashboard";
};
export const getPendingSignatures = (state) => {
  if (!state.user.pendingSignature) {
    return null;
  }
  let signatures = state.user.pendingSignature.filter((sig) => sig.no_show === false);
  return signatures;
};
export const getUserPermissionsList = (state) => {
  if (Object.keys(state.user.permissions).length === 0) {
    return null;
  }
  return state.user.permissions;
};
export const getUpdateUserRolesLoading = (state) => state.user.updateUserRolesLoading;
export const getUpdateUserRolesSuccess = (state) => state.user.updateUserRolesSuccess;
export const getUpdateUserRolesFail = (state) => state.user.updateUserRolesFail;

export const getSendingResetPassword = (state) => state.user.sendingResetPassword;
export const getResetPasswordSent = (state) => state.user.resetPasswordSent;
export const getSearchUserDetails = (state) => state.user.searchUserDetails;
export const getSearchUserDetailsLoading = (state) => state.user.searchUserDetailsLoading;
export const getSearchUserDetailsSuccess = (state) => state.user.searchUserDetailsSuccess;
export const getToggleUserActivationLoading = (state) => state.user.toggleUserActivationLoading;
export const getToggleUserActivationSuccess = (state) => state.user.toggleUserActivationSuccess;

//USERS
export const getUsersPage = (state) => state.users.page;
export const getUsersPerPage = (state) => state.users.rowsPerPage;
export const getUsersSort = (state) => state.users.sort;
export const getUsersSortDirection = (state) => state.users.sortdirection;
export const getUserIsLoaded = (state) => state.users.userLoaded;
export const getUsersList = (state) => state.users.users;
export const getUsersLoading = (state) => state.users.usersLoading;
export const getSpecificUser = (state) => state.users.user;
export const getUsersCount = (state) => state.users.count;
export const getUsersSaving = (state) => state.users.saving;
export const getCreateUserLoading = (state) => state.users.createUserLoading;
export const getCreateUserSuccess = (state) => state.users.createUserSuccess;
export const getCreateUserError = (state) => state.users.createUserError;
export const getCreateUserErrorMessage = (state) => state.users.createUserErrorMessage;
export const getUserDeactivationReasons = (state) => state.users.deactivationReasons;

//VIDEO
export const videoRoomId = (state) => state.video.roomId;
export const videoKey = (state) => state.video.videoKey;
export const videoId = (state) => state.video.videoId;
export const getNextClinicianCall = (state) => state.video.nextClinicianCall;
export const getNextClinicianCallLoading = (state) => state.video.nextClinicianCallLoading;
export const getNextClinicianCallSuccess = (state) => state.video.nextClinicianCallSuccess;
export const getClinicianCallsForWeek = (state) => state.video.clinicianCallsForWeek;
export const getClinicianCallsForWeekSuccess = (state) => state.video.clinicianCallsForWeekSuccess;
export const getClinicianCallsForWeekLoading = (state) => state.video.clinicianCallsForWeekLoading;
export const getClinicianCalendarCalls = (state) => state.video.calendarCalls;
export const getClinicianCalendarCallsLoading = (state) => state.video.calendarCallsLoading;
export const getClinicianNonWorkingHours = (state) => state.video.nonWorkingHours;
export const getClinicianCalendarPrivateBlocks = (state) => state.video.calendarPrivateBlocks;
export const getClinicianPastCalls = (state) => state.video.pastCalls;
export const getClinicianPastCallsLoading = (state) => state.video.pastCallsLoading;
export const getClinicianPastCallsSort = (state) => state.video.pastCallsSort;
export const getClinicianPastCallsSortDirection = (state) => state.video.pastCallsSortDirection;
export const getClinicianUpcomingCalls = (state) => state.video.upcomingCalls;
export const getClinicianUpcomingCallsLoading = (state) => state.video.upcomingCallsLoading;
export const getClinicianUpcomingCallsSort = (state) => state.video.upcomingCallsSort;
export const getClinicianUpcomingCallsSortDirection = (state) =>
  state.video.upcomingCallsSortDirection;

export const upcomingVideoCalls = (state) => state.video.upcomingVideoCalls;
export const oneTimeVideo = (state) => state.video.oneTimeVideo;
export const oneTimeVideoInfo = (state) => state.video.oneTimeVideoInfo;
export const getVideoClientId = (state) =>
  state.video.oneTimeVideoInfo ? state.video.oneTimeVideoInfo.client_id : null;
export const upcomingVideoLoading = (state) => state.video.upcomingVideoLoading;
export const videoIsRunning = (state) => state.video.isRunning;
export const sendbirdChatUrl = (state) => state.video.chatUrl;
export const updatingLayout = (state) => state.video.updatingLayout;
export const layoutChangeSuccess = (state) => state.video.layoutChangeSuccess;
export const videoAnimations = (state) => state.video.animations;
export const videoActivities = (state) => state.video.activities;
export const videoCards = (state) => state.video.cards;
export const videoPromptCards = (state) => state.video.promptCards;
export const videoStartDate = (state) => state.video.startDate;
export const videoEndDate = (state) => state.video.endDate;
export const sendingVideoSignatures = (state) => state.video.sendingVideoSignatures;
export const sendVideoSignaturesSuccess = (state) => state.video.sendVideoSignaturesSuccess;
export const videoCallEnded = (state) => state.video.callEnded;
export const videoAvailability = (state) => state.video.availability;
export const loadingAvailability = (state) => state.video.loadingAvailability;
export const scheduleCallLoading = (state) => state.video.scheduleCallLoading;
export const scheduleCallSuccess = (state) => state.video.scheduleCallSuccess;
export const setDemoCallLoading = (state) => state.video.setDemoCallLoading;
export const setDemoCallSuccess = (state) => state.video.setDemoCallSuccess;
export const getVideoChatChannelUrl = (state) => state.video.videoChatUrl;

export const getVideoCallsPage = (state) => state.video.page;
export const getVideoCallsPerPage = (state) => state.video.rowsPerPage;
export const getVideoCallsSort = (state) => state.video.sort;
export const getVideoCallsCount = (state) => state.video.count;
export const getVideoCallsSortDirection = (state) => state.video.sortdirection;
export const getVideoCallsType = (state) => state.video.type;
export const getVideoFilterStartDate = (state) => state.video.filterStartDate;
export const getVideoFilterEndDate = (state) => state.video.filterEndDate;
export const getVideoCallsList = (state) => state.video.videoCallsList;
export const getWeekStartDate = (state) => state.video.weekStartDate;
export const getWeekEndDate = (state) => state.video.weekEndDate;
export const getShowWeekCanceledCalls = (state) => state.video.showWeekCanceledCalls;

export const getUserVideoCallsPage = (state) => state.video.userPage;
export const getUserVideoCallsPerPage = (state) => state.video.userRowsPerPage;
export const getUserVideoCallsSort = (state) => state.video.userSort;
export const getUserVideoCallsCount = (state) => state.video.userCount;
export const getUserVideoCallsSortDirection = (state) => state.video.userSortdirection;
export const getUserVideoCallsType = (state) => state.video.userType;
export const getUserVideoFilterStartDate = (state) => state.video.userFilterStartDate;
export const getUserVideoFilterEndDate = (state) => state.video.userFilterEndDate;
export const getUserVideoCalls = (state) => state.video.userVideoCalls;
export const getUserVideoCallsLoading = (state) => state.video.userVideoCallsLoading;

export const videoChatInfo = (state) => state.video.videoChatInfo;
export const videoChatInfoLoading = (state) => state.video.videoChatInfoLoading;
export const getVideoBreakdown = (state) => state.video.videoBreakdown;
export const getVideoBreakdownLoading = (state) => state.video.videoBreakdownLoading;

export const getPrimaryClinicianVideoSignatureId = (state) => state.video.primarySignatureId;
export const getSecondaryClinicianVideoSignatureId = (state) =>
  state.video.secondaryClinicianVideoSignatureId;
export const getSecondaryClinicianVideoJoinTime = (state) => state.video.secondaryClinicianJoinTime;
export const getSecondaryClinicianVideoLeaveTime = (state) =>
  state.video.secondaryClinicianLeaveTime;
export const getSecondaryClinicianSessionNoteId = (state) =>
  state.video.secondaryClinicianSessionNoteId;
export const getSecondaryClinicianBillableTimeId = (state) =>
  state.video.secondaryClinicianBillableTimeId;
export const getSecondaryClinicianLeaveCallSuccess = (state) =>
  state.video.secondaryClinicianLeaveCallSuccess;
export const callType = (state) => state.video.callType;
export const userGuid = (state) => state.video.userGuid;
export const videoRunning = (state) =>
  state.video.startDate && !state.video.endDate && state.video.roomId;
export const rescheduleVideoDetails = (state) => state.video.rescheduleDetails;
export const getCallInfoLoading = (state) => state.video.getCallInfoLoading;
export const getVideoCallType = (state) => state.video.rescheduleDetails.call_type;
export const videoRoomState = (state) => state.video.videoRoomState;
export const getCheckingVideoAccess = (state) => state.video.checkingAccess;
export const getAccessToVideo = (state) => state.video.accessGranted;
export const getPrimaryClinicianUserId = (state) => state.video.primaryUserId;
export const getSecondaryClinicianUserId = (state) => state.video.secondaryUserId;
export const getVideoCallParticipants = (state) => state.video.participants;
export const getDefaultVideoPlatform = (state) => state.video.defaultVideoPlatform;
export const getClientUserId = (state) => {
  if (!state.video.participants) {
    return null;
  }
  let client = state.video.participants.filter((p) => p.role == "CLIENT")[0]?.user_id;
  return client;
};
export const getClientClientId = (state) => state.video.clientId;
export const getLoadingVideoCalls = (state) => state.video.loadingVideoCalls;
export const getCallBreakdownLoading = (state) => state.video.callBreakdownLoading;
export const getCallBreakdownSuccess = (state) => state.video.callBreakdownSuccess;
export const getCallBreakdownError = (state) => state.video.callBreakdownError;
export const getCallBreakdownErrorMessage = (state) => state.video.callBreakdownErrorMessage;
export const getLoadingVideoBillingIssues = (state) => state.video.loadingVideoBillingIssues;
export const getVideoBillingIssues = (state) => state.video.videoBillingIssues;
export const getBillableTimeIds = (state) => state.video.billableTimeIds;
export const getNextSessionNoteData = (state) => state.video.nextSessionNoteData;

export const getCancelVideoCallSuccess = (state) => state.video.cancelVideoCallSuccess;
export const getCancelVideoCallLoading = (state) => state.video.cancelVideoCallLoading;
export const getCancelVideoCallFail = (state) => state.video.cancelVideoCallFail;

export const getClinicianIncompleteOffPlatform = (state) => state.video.incompleteOffPlatform;
export const getCompletingOffPlatform = (state) => state.video.completingOffPlatform;
export const getCompleteOffPlatformSuccess = (state) => state.video.completeOffPlatformSuccess;
export const getOffPlatformVideoSessionNoteId = (state) =>
  state.video.offPlatformVideoSessionNoteId;
export const getOffPlatformVideoBillableTimeId = (state) =>
  state.video.offPlatformVideoBillableTimeId;

//SENDBIRD
export const getSendbirdConnection = (state) => state.sendbird.connection;
export const getUnreadMessageCount = (state) => state.sendbird.unreadCount;

//BILLING ITEMS
export const getBillingItems = (state) => state.billing.billingItems;
export const getBillingItemsLoading = (state) => state.billing.billingItemsLoading;
export const getBillingItemsSuccess = (state) => state.billing.billingItemsSuccess;
export const getBillingItemsPage = (state) => state.billing.page;
export const getBillingItemsRowsPerPage = (state) => state.billing.rowsPerPage;
export const getBillingItemsCount = (state) => state.billing.count;
export const getEditAssociatedTimesLoading = (state) => state.billing.editAssociatedTimesLoading;
export const getEditAssociatedTimesSuccess = (state) => state.billing.editAssociatedTimesSuccess;
export const getHoldReleaseBillableTimeLoading = (state) => state.billing.holdReleaseLoading;
export const getHoldReleaseBillableTimeSuccess = (state) => state.billing.holdReleaseSuccess;
export const getApproveTimeLoading = (state) => state.billing.approveTimeLoading;
export const getApproveTimeSuccess = (state) => state.billing.approveTimeSuccess;
export const getRequestBillableTimeEditsLoading = (state) => state.billing.requestEditsLoading;
export const getRequestBillableTimeEditsSuccess = (state) => state.billing.requestEditsSuccess;
export const getTimesheetLoading = (state) => state.billing.timesheetLoading;
export const getTimesheetSuccess = (state) => state.billing.timesheetSuccess;
export const getTimesheetError = (state) => state.billing.timesheetError;
export const getDeleteNoteLoading = (state) => state.billing.deleteNoteLoading;
export const getDeleteNoteSuccess = (state) => state.billing.deleteNoteSuccess;
export const getDeleteNoteError = (state) => state.billing.deleteNoteError;
export const getDeleteNoteCreatedBillableId = (state) => state.billing.deleteNoteCreatedBillableId;
export const getBillableItem = (state) => state.billing.billableItem;
export const getBillableItemSuccess = (state) => state.billing.billableItemSuccess;
export const getRevertToInProgressLoading = (state) => state.billing.revertToInProgressLoading;
export const getRevertToInProgressSuccess = (state) => state.billing.revertToInProgressSuccess;
