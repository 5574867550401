import { getUserNotificationPreferences } from "api/user";
import { GET_USER_NOTIFICATION_PREFERENCES } from "constants/reactQueryKeys";
import { useQuery } from "react-query";

export const useGetUserNotificationPreferences = (userId) => {
  return useQuery(
    [GET_USER_NOTIFICATION_PREFERENCES, userId],
    () => getUserNotificationPreferences(userId),
    {
      enabled: !!userId,
    }
  );
};
