import React, { useState, useRef } from "react";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const Physician = (props) => {
  const physicianAnchorRef = useRef(null);
  const [physicianMenuOpen, setPhysicianMenuOpen] = useState(false);

  const onToggleMenu = () => {
    if (physicianMenuOpen) {
      props.onTogglePhysicianMenu();
      setPhysicianMenuOpen(false);
    } else {
      props.onTogglePhysicianMenu(props.physician);
      setPhysicianMenuOpen(true);
    }
  };

  const editPhysician = () => {
    props.onSelectEditPhysician();
    setPhysicianMenuOpen(false);
  };

  const removePhysician = () => {
    setPhysicianMenuOpen(false);
    props.onSelectRemovePhysician();
  };

  const { classes, physician } = props;

  return (
    <>
      <div key={physician.id} className={classes.physicianContainer}>
        <div className={classes.physicianTextContainer}>
          <Typography component="h6">{`${physician.first_name} ${physician.last_name}`}</Typography>
          <Typography component="p">
            Physician Type:{" "}
            {physician?.type?.toLowerCase() == "primary care" ? "Primary Care" : "Diagnosing"}
          </Typography>
        </div>
        <IconButton
          color="inherit"
          aria-label="Open family member drawer"
          ref={physicianAnchorRef}
          onClick={onToggleMenu}
          size="large"
        >
          <MoreHorizIcon
            style={{
              fontSize: 26,
              marginBottom: 0,
              fill: "#552879",
            }}
          />
        </IconButton>
      </div>
      <Menu
        id="simple-menu"
        anchorEl={physicianAnchorRef.current}
        open={physicianMenuOpen}
        style={{ zIndex: "3000", top: "-15px" }}
        onClose={() => setPhysicianMenuOpen(false)}
        getcontentanchorel={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuItem className={classes.settingsMenuItem} onClick={editPhysician}>
          <Typography component="p">Edit</Typography>
        </MenuItem>
        <MenuItem className={classes.settingsMenuItem} onClick={removePhysician}>
          <Typography component="p" color="secondary">
            Remove
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default Physician;
