import { toggleFormSkippedStatus } from "api/customers";
import { GET_CUSTOMER_SIGNED_FORM } from "constants/reactQueryKeys";
import { useMutation, useQueryClient } from "react-query";

const useToggleFormSkippedStatus = (clientId) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(({ formId, skipped }) => toggleFormSkippedStatus(formId, skipped), {
    onSuccess: () => {
      queryClient.invalidateQueries(["unsignedForms", clientId]);
      queryClient.invalidateQueries([GET_CUSTOMER_SIGNED_FORM, clientId]);
    },
  });
  return mutation;
};

export default useToggleFormSkippedStatus;
