import React, { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Controller, useFormContext } from "react-hook-form";
import { FormHelperText } from "@mui/material";

const GenericSelect = ({
  name,
  label,
  valueKey,
  options,
  required,
  disabled,
  helperText,
  submitAttempted,
}) => {
  const { control, trigger, formState } = useFormContext();
  const { errors, isSubmitted } = formState;
  const [showError, setShowError] = useState(false);

  const handleBlur = () => {
    if (submitAttempted) {
      setShowError(true);
      trigger(name);
    }
  };

  const handleFocus = () => {
    if (errors[name]) {
      trigger(name, { shouldFocus: false });
    }
  };

  const getNestedErrorMessage = (errors, path) => {
    return path.split(".").reduce((acc, part) => acc && acc[part], errors)?.message;
  };

  return (
    <FormControl
      fullWidth
      onBlur={handleBlur}
      onFocus={handleFocus}
      error={!!getNestedErrorMessage(errors, name)}
    >
      <Controller
        control={control}
        name={name}
        rules={{ required: "This field is required" }}
        render={({ field: { onChange, value } }) => {
          return (
            <>
              <InputLabel>{label}</InputLabel>
              <Select label={label} value={value || ""} onChange={onChange} disabled={disabled}>
                <MenuItem value="">{`Select ${label}`}</MenuItem>
                {options?.map(({ label, value }) => {
                  return (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  );
                })}
              </Select>
              {submitAttempted && !!getNestedErrorMessage(errors, name) && (
                <FormHelperText>{helperText || getNestedErrorMessage(errors, name)}</FormHelperText>
              )}
            </>
          );
        }}
      />
    </FormControl>
  );
};

export default GenericSelect;
