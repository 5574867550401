import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Box,
  Alert,
  AlertTitle,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

/**
 * @component The DeleteAlertDialog component displays a dialog box with a warning message about permanent deletion.
 * @param {Object} props - The props of the component.
 * @param {boolean} props.open - The open state of the dialog.
 * @param {Function} props.onClose - The function to close the dialog.
 * @param {Function} props.onDelete - The function to delete the item.
 * @param {boolean} props.isLoading - The loading state of delete action.
 * @param {string} props.title - The title of the dialog.
 * @return {JSX.Element} The `DeleteAlertDialog` component.
 */
export default function DeleteAlertDialog({ open, onClose, onDelete, isLoading, title }) {
  const handleClose = () => {
    if (isLoading) return;
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs">
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box>
              <Alert severity="warning">
                <AlertTitle>{title}</AlertTitle>
                Deleting is permanent and cannot be reversed
              </Alert>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleClose} disabled={isLoading}>
          Cancel
        </Button>
        <LoadingButton onClick={onDelete} loading={isLoading} variant="contained" color="error">
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
