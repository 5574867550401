export default {
  control: {
    backgroundColor: "#f6f6f6",
    border: "none",
    fontSize: 16,
    fontWeight: "normal",
    fontFamily: ["Karla", "sans-serif"].join(","),
  },

  highlighter: {
    overflow: "hidden",
  },

  input: {
    margin: 0,
  },

  "&singleLine": {
    control: {
      display: "inline-block",

      width: 130,
    },

    highlighter: {
      padding: 1,
      border: "2px inset transparent",
    },

    input: {
      padding: 1,

      border: "2px inset",
    },
  },

  "&multiLine": {
    control: {
      border: "none",
    },

    highlighter: {
      padding: 9,
    },

    input: {
      padding: 9,
      minHeight: 37,
      outline: 0,
      border: 0,
      width: "100%",
      fontFamily: ["Karla", "sans-serif"].join(","),
      maxHeight: "75px",
      maxWidth: "100%",
      overflowY: "auto",
    },
  },

  suggestions: {
    list: {
      height: 300,
      width: 500,
      overflow: "hidden",
      overflowY: "auto",
      backgroundColor: "white",
      border: "1px solid rgba(0,0,0,0.15)",
      borderRadius: 10,
      // marginBottom: 25,
      fontSize: 14,
      fontFamily: ["Karla", "sans-serif"].join(","),
      boxShadow: "0px 0px 13px 1px #555555",
    },

    item: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      padding: "3px 15px",
      fontFamily: "inherit",
      // borderBottom: "1px solid rgba(0,0,0,0.15)",

      "&focused": {
        backgroundColor: "#cee4e5",
      },
    },
  },
};
