import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withStyles from "@mui/styles/withStyles";
import AppBar from "@mui/material/AppBar";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import {
  getBillableTimeLoading,
  getUserBillableTime,
  getNextClinicianCall,
  getUserId,
} from "../selectors";
import actions from "../actions";

const mapStateToProps = (state) => ({
  clinicianUserId: getUserId(state),
  billableTime: getUserBillableTime(state),
  billableTimeLoading: getBillableTimeLoading(state),
  nextCall: getNextClinicianCall(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getBillableTime: actions.getBillableTime,
    },
    dispatch,
  );

class PatientTherapyProgress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 0,
      billableTimePeriod: "week",
    };
  }

  componentDidMount() {
    this.props.getBillableTime({
      clientId: this.props.nextCall.client_id,
      period: this.state.billableTimePeriod,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.billableTimePeriod !== this.state.billableTimePeriod) {
      this.props.getBillableTime({
        clientId: this.props.nextCall.client_id,
        period: this.state.billableTimePeriod,
      });
    }
  }

  setBillableTimePeriod = (period) => {
    this.setState({ billableTimePeriod: period });
  };

  handleTabChange = (event, newValue) => {
    let period;
    if (newValue === 0) {
      period = "week";
    } else if (newValue === 1) {
      period = "month";
    } else {
      period = "authperiod";
    }
    this.setState({ currentTab: newValue });
    this.setBillableTimePeriod(period);
  };

  render() {
    const { currentTab } = this.state;
    const { classes, billableTimeLoading, nextCall, billableTime } = this.props;

    if (this.props.billableTimeLoading) {
      return (
        <div className={classes.container}>
          <div className={classes.loading}>
            <CircularProgress style={{ color: "#885FD5" }} size={30} />
          </div>
        </div>
      );
    }
    return this.props.billableTime.length > 0 ? (
      <div className={classes.container}>
        <AppBar position="static" color="default" elevation={0}>
          <Tabs
            value={currentTab}
            onChange={this.handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label="This Week" style={{ textTransform: "none" }} />
            <Tab label="This Month" style={{ textTransform: "none" }} />
            <Tab label="Auth Period" style={{ textTransform: "none" }} />
          </Tabs>
        </AppBar>
        <div className={classes.progressContainer}>
          {billableTime.map((unit, index) => {
            return (
              <div key={index} className={classes.billableTimeContainer}>
                <div className={classes.circularProgressContainer}>
                  <CircularProgressbarWithChildren
                    value={unit.used_units}
                    maxValue={unit.approved_units}
                    styles={buildStyles({
                      pathColor: "#40B89F",
                      trailColor: "#C0F3D9",
                      strokeLinecap: "butt",
                    })}
                  >
                    <Typography component="p" className={classes.progressText}>
                      {unit.used_units / 4} of {unit.approved_units / 4} hours completed
                    </Typography>
                  </CircularProgressbarWithChildren>
                </div>
                <div className={classes.textContainer}>
                  <Typography component="p" className={classes.unitTitle}>
                    {unit.cpt_code}
                  </Typography>
                  <Typography component="p" className={classes.hoursRemaining}>
                    {(unit.approved_units - unit.used_units) / 4 < 0
                      ? 0
                      : (unit.approved_units - unit.used_units) / 4}{" "}
                    hours remaining
                  </Typography>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    ) : (
      <div className={classes.loading}>
        <div className={classes.container}>
          {nextCall.private_pay ? (
            <Typography component="p" className={classes.noInsuranceTitle}>
              Private Pay Client
            </Typography>
          ) : (
            <Typography component="p" className={classes.noInsuranceTitle}>
              No Insurance Authorization for client
            </Typography>
          )}
        </div>
      </div>
    );
  }
}

const styles = (theme) => ({
  container: {
    height: "100%",
    width: "100%",
    dispay: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  progressContainer: {
    width: "100%",
    height: "auto",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    minHeight: 175,
  },
  billableTimeContainer: {
    width: "25%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 20,
  },
  circularProgressContainer: {
    width: "80%",
  },
  progressText: {
    fontSize: 10,
    textAlign: "center",
    maxWidth: "75%",
  },
  textContainer: {
    marginTop: 15,
  },
  unitTitle: {
    fontSize: 11,
    fontWeight: 500,
    textAlign: "center",
    marginBottom: 3,
  },
  hoursRemaining: {
    fontSize: 11,
    textAlign: "center",
  },
  noInsuranceTitle: {
    fontSize: 19,
    color: "#595959",
    textAlign: "center",
    margin: "auto",
  },
  loading: {
    display: "flex",
    width: "100%",
    height: "100%",
    minHeight: 205,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(PatientTherapyProgress));
