import { call, put, takeEvery } from "redux-saga/effects";
import {
  getMessageTagSummary,
  getMessagesForTag,
  getVideoCallSummary,
  getBillingSummary,
} from "../api/reports";
import actions from "../actions";

function* getMessageTagSummaryAsync() {
  try {
    const result = yield call(getMessageTagSummary);
    yield put(actions.getMessageTagSummarySuccess(result.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    console.error(e);
  }
}

function* getMessagesForTagAsync({ payload }) {
  try {
    const messages = yield call(getMessagesForTag, payload);
    yield put(actions.getMessagesForTagSuccess(messages.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    console.error(e);
  }
}

function* getVideoCallSummaryAsync({ payload }) {
  try {
    const videoCalls = yield call(getVideoCallSummary, payload);
    yield put(actions.getVideoCallSummarySuccess(videoCalls.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    console.error(e);
  }
}
function* getBillingSummaryAsync({ payload }) {
  try {
    const billingSummary = yield call(getBillingSummary, payload);
    yield put(actions.getBillingSummarySuccess(billingSummary.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    console.error(e);
  }
}

export default function* startSaga() {
  yield takeEvery(actions.GET_MESSAGE_TAG_SUMMARY, getMessageTagSummaryAsync);
  yield takeEvery(actions.GET_MESSAGES_FOR_TAG, getMessagesForTagAsync);
  yield takeEvery(actions.GET_VIDEO_CALL_SUMMARY, getVideoCallSummaryAsync);
  yield takeEvery(actions.GET_BILLING_SUMMARY, getBillingSummaryAsync);
}
