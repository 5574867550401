import {
  MessagingSessionConfiguration,
  DefaultMessagingSession,
  LogLevel,
  PrefetchSortBy,
  PrefetchOn,
} from "amazon-chime-sdk-js";
import { Auth } from "@aws-amplify/auth";
import {
  ChimeSDKMessagingClient,
  ListChannelMessagesCommand,
  SendChannelMessageCommand,
  UpdateChannelCommand,
} from "@aws-sdk/client-chime-sdk-messaging";

import {
  UpdateAppInstanceUserCommand,
  ChimeSDKIdentityClient,
} from "@aws-sdk/client-chime-sdk-identity";
import { getCredentialsFromIdentityPool } from "utils/aws";
import { debounce, random } from "lodash";
import { jitter } from "utils/promise";
import { createMultiLogger, log } from "video/debug";

/**
 * @param {string} Arn
 */
export function extractUserIdFromArn(Arn) {
  const userId = Arn?.split("/").pop();

  // userId is numeric
  if (!/\D/.test(userId)) {
    return userId;
  }
}

/**
 * @typedef {"HB"|"PI"|"PO"} ControlMessageType
 * HB - User Presence Update
 * PI - PING
 * PO - PONG
 */

export default class MessagingSession {
  /**
   * @type {{
   * Channel: import('@aws-sdk/client-chime-sdk-messaging').ChannelSummary,
   * ChannelMessages: import('@aws-sdk/client-chime-sdk-messaging').ChannelMessageSummary[]  ,
   * ChannelMemberships: import('@aws-sdk/client-chime-sdk-messaging').ChannelMembershipSummary[],
   * ReadMarkerTimestamp: Date,
   * ChannelMessagesHasMore: boolean
   * }}
   */
  channelDetails;

  get AppInstanceArn() {
    return this.__config.AppInstanceArn;
  }

  get ChimeBearer() {
    return this.__config.MemberArn;
  }

  get ChannelArn() {
    return this.__config.ChannelArn;
  }

  get MemberArn() {
    return this.__config.MemberArn;
  }

  get userId() {
    return this.MemberArn.split("/").pop();
  }

  get videoCallId() {
    return this.ChannelArn.split("/").pop();
  }

  /** @returns {string[]} */
  get otherUserIds() {
    return (
      this.channelDetails?.ChannelMemberships.reduce((mem, cur) => {
        const userId = extractUserIdFromArn(cur.Member.Arn);
        if (userId && userId !== this.userId) {
          mem.push(userId);
        }
        return mem;
      }, []) || []
    );
  }

  /** @private */
  __connected = false;
  get connected() {
    return this.__connected;
  }

  /**
   * @type {{
   *   AppInstanceArn: string,
   *   ChannelArn: string,
   *   MemberArn: string,
   * }}
   */
  __config;

  constructor(config) {
    this.__config = config;
    this.__created = Date.now();
  }

  destroy() {
    if (this.__connected) this.disconnect();
    this.__onInitialMessagesFetchedCbs = [];
    this._messageListeners = [];
    this._onStart = [];
    this.__connect = null;
  }

  disconnect() {
    this.__connected = false;
    log("disconnect;ChannelArn=", this.__config?.ChannelArn);
    this.messagingSession?.removeObserver(this);
    this.messagingSession?.stop();
  }

  async reconnect() {
    throw new Error("bubble");
    log("attempting to reconnect to messaging session");
    this.messagingSession.stop();
    return this.messagingSession.start().then(console.log);
  }

  /** @returns {Promise<this>} */
  async connect() {
    log("connect;ChannelArn=", this.__config.ChannelArn);

    if (this._connect) return this._connect;
    this._connect = this.__connect().catch((err) => {
      console.error("failed to connect to messaging session");
      console.error(err);
      throw err;
    });
    return this;
  }
  _connect;
  async __connect() {
    const sess = await Auth.currentSession();

    const credentials = await getCredentialsFromIdentityPool(sess.getIdToken().getJwtToken());

    this.chimeMessagingSdkClient = new ChimeSDKMessagingClient({
      credentials,
      region: "us-east-1",
    });

    this.chimeIdentitySdkClient = new ChimeSDKIdentityClient({ credentials, region: "us-east-1" });

    this.messagingSessionConfig = new MessagingSessionConfiguration(
      this.__config.MemberArn,
      null, // sessionId - TODO maintain a sessionId per site session?
      undefined,
      this.chimeMessagingSdkClient
    );
    this.messagingSessionConfig.prefetchOn = PrefetchOn.Connect;
    this.messagingSessionConfig.prefetchSortBy = PrefetchSortBy.LastMessageTimestamp;

    this.logger = await createMultiLogger("MessagingSession", this.videoCallId, {
      logLevel: LogLevel.WARN,
      metadata: {
        ChannelArn: this.ChannelArn,
        MemberArn: this.MemberArn,
      },
    });
    this.messagingSession = new DefaultMessagingSession(this.messagingSessionConfig, this.logger);
    this.messagingSession.addObserver(this);
    this.messagingSession.start().catch(async (err) => {
      console.error("failed to connect to messaging session");
      console.error(err);

      delete this._connect;

      if (err?.reason === "Unauthorized") {
        // TODO figure out how to reproduce and handle this (cognito token exprires? or credentials are cached and not refreshing?)
      }

      throw err;
    });

    return this;
  }

  messageCt = 0;

  /** @type {[cmd:SendChannelMessageCommand, onSentResolver:()=>import('@aws-sdk/client-chime-sdk-messaging').SendChannelMessageResponse][]} */
  __sendMessageQueue = [];

  /**
   * @param {string} Content
   * @param {ControlMessageType} ContentType
   * @param {import('@aws-sdk/client-chime-sdk-messaging').ChannelMessageType} Type
   * @param {import('@aws-sdk/client-chime-sdk-messaging').ChannelMessagePersistenceType} Persistence
   * @param {string[]} userIds target this message to the given users
   */
  async sendMessage(
    Content = "",
    ContentType = "HB",
    Type = "CONTROL",
    Persistence = "NON_PERSISTENT",
    userIds = []
  ) {
    // STANDARD = 4KB of data and 1KB of metadata
    // CONTROL  = 30 bytes of data and no metadata

    let Target;
    if (userIds?.length) {
      // Target may only contain a single item
      if (userIds.length > 1) {
        return Promise.all(
          userIds.map((userId) => {
            this.sendNonPersistentControlMessage(Content, ContentType, [userId]);
          })
        );
      }
      Target = userIds.map((userId) => ({ MemberArn: `${this.AppInstanceArn}/user/${userId}` }));
    }

    const cmd = new SendChannelMessageCommand({
      ChannelArn: this.ChannelArn,
      ChimeBearer: this.ChimeBearer,
      Type,
      Persistence,
      ContentType,
      Content,
      Target,
    });

    if (!this.connected) {
      log("messaging client not connected, queueing message:", Content);
      let res;
      const onSent = new Promise((resolve) => {
        res = resolve;
      }).then((res) => {
        this.messageCt += 1;
        return res;
      });
      this.__sendMessageQueue.push([cmd, res]);
      return onSent;
    } else {
      return this.chimeMessagingSdkClient.send(cmd).then((res) => {
        this.messageCt += 1;
        return res;
      });
    }
  }

  /**
   * @param {string} Content
   * @param {ControlMessageType} ContentType
   * @param {string[]} userIds target this message to the given users
   */
  async sendNonPersistentControlMessage(Content = "", ContentType = "HB", userIds = []) {
    return this.sendMessage(Content, ContentType, "CONTROL", "NON_PERSISTENT", userIds);
  }

  /**
   * @param {string} Content
   * @param {ControlMessageType} ContentType
   * @param {string[]} userIds target this message to the given users
   */
  async sendPersistentControlMessage(Content = "", ContentType = "HB", userIds = []) {
    return this.sendMessage(Content, ContentType, "CONTROL", "PERSISTENT", userIds);
  }

  /**
   * @param {string} Content
   * @param {ControlMessageType} ContentType
   * @param {string[]} userIds target this message to the given users
   */
  async sendNonPersistentStandardMessage(Content = "", ContentType = "HB", userIds = []) {
    return this.sendMessage(Content, ContentType, "STANDARD", "NON_PERSISTENT", userIds);
  }

  /**
   * @param {string} Content
   * @param {ControlMessageType} ContentType
   * @param {string[]} userIds target this message to the given users
   */
  async sendPersistentStandardMessage(Content = "", ContentType = "HB", userIds = []) {
    return this.sendMessage(Content, ContentType, "STANDARD", "PERSISTENT", userIds);
  }

  async runSendMessageQueues() {
    let cmd;
    let cb;
    let msg;
    while ((msg = this.__sendMessageQueue.shift())) {
      [cmd, cb] = msg;
      cb(await this.chimeMessagingSdkClient.send(cmd));
    }
  }

  /**
   * @param {(message:import('@aws-sdk/client-chime-sdk-messaging').ChannelMessageSummary)=>void} cb
   * @param {ControlMessageType} type
   */
  addMessageListener(cb, type) {
    this._messageListeners[type] = this._messageListeners[type] || [];
    this._messageListeners[type].push(cb);
  }
  /**
   * @param {(message:import('@aws-sdk/client-chime-sdk-messaging').ChannelMessageSummary)=>void} cb
   * @param {ControlMessageType} type
   */
  removeMessageListener(cb, type) {
    if (this._messageListeners[type]) {
      const i = this._messageListeners[type].findIndex((v) => v === cb);
      if (i >= 0) this._messageListeners[type].splice(i, 1);
    }
  }
  /** @type {{[messageType:string]:((message:import('@aws-sdk/client-chime-sdk-messaging').ChannelMessageSummary)=>void)[]}} */
  _messageListeners = { "*": [] };
  /**
   * @param {import('@aws-sdk/client-chime-sdk-messaging').ChannelMessageSummary} message
   */
  __onMessage(message) {
    this._messageListeners["*"].forEach((cb) => cb(message));
    this._messageListeners[message.ContentType || ""]?.forEach((cb) => cb(message));
  }

  messages = [];
  lastMessageTs = new Date();

  async fetchMessages(NextToken) {
    if (!NextToken) {
      // intial call - reset state
      this.messages = [];
      this.lastMessageTs = new Date();
    }
    const res = await this.chimeMessagingSdkClient.send(
      new ListChannelMessagesCommand({
        ChannelArn: this.ChannelArn,
        ChimeBearer: this.ChimeBearer,
        MaxResults: 50, // max of 50
        NextToken,
        SortOrder: "DESCENDING",
        NotAfter: this.lastMessageTs,
      })
    );
    this.messages.push(...res.ChannelMessages);
    if (res.NextToken) {
      await jitter(150, 750);
      await this.fetchMessages(res.NextToken);
    }
    this.lastMessageTs =
      res.ChannelMessages[res.ChannelMessages.length - 1]?.CreatedTimestamp || new Date();
  }

  __onInitialMessagesFetchedCbs = [];
  _onInitialMessagesFetched() {
    this.__onInitialMessagesFetchedCbs.forEach((cb) => cb(this.__prefetchedHbMessages));
  }
  /**
   * @param {(messages:import('@aws-sdk/client-chime-sdk-messaging').ChannelMessage[])=>void} cb
   */
  onInitialMessagesFetched(cb) {
    this.__onInitialMessagesFetchedCbs.push(cb);
  }

  // <Observer interface>
  _onStart = [];
  __onStart() {
    this._onStart.forEach((cb) => cb());
  }
  onStart(cb) {
    this._onStart.push(cb);
  }

  messagingSessionDidStart() {
    log("messagingSessionDidStart");
    this.__connected = true;
    this.__reconnecting = false;
    this.runSendMessageQueues();
    this.__onStart();
  }

  messagingSessionDidStartConnecting(reconnecting) {
    log("messagingSessionDidStartConnecting", reconnecting);
    this.__connected = !reconnecting;
    this.__reconnecting = reconnecting;
  }

  messagingSessionDidStop(event) {
    log("messagingSessionDidStop", event);
    this.__connected = false;
  }

  __onChannelUpdated = [];
  _onChannelUpdated(initial = false) {
    this.__onChannelUpdated.forEach((cb) => cb(this.channelDetails, initial));
  }
  /**
   * @param {(channelDetails: typeof this.channelDetails, initial:boolean)=>void} cb
   */
  onChannelUpdated(cb) {
    this.__onChannelUpdated.push(cb);
  }

  /**
   * @param {import('amazon-chime-sdk-js').Message} message
   */
  messagingSessionDidReceiveMessage(message) {
    /** @type {import('@aws-sdk/client-chime-sdk-messaging').ChannelMessage | typeof this.channelDetails} */
    const payload = JSON.parse(message.payload);

    const ChannelArn = payload?.ChannelArn || payload?.Channel?.ChannelArn;
    if (ChannelArn !== this.ChannelArn) {
      return;
    }

    // dbg("messagingSessionDidReceiveMessage", message, payload);

    switch (message.type) {
      case "CREATE_CHANNEL_MESSAGE":
        if (payload) {
          /** @type {import('@aws-sdk/client-chime-sdk-messaging').ChannelMessage} */
          const channelMessage = payload;
          this.__onMessage(channelMessage);
        }
        break;
      case "CHANNEL_DETAILS":
      case "UPDATE_CHANNEL":
        log("channel updated", payload);
        const initial = !this.channelDetails;
        this.channelDetails = payload;
        this._onChannelUpdated(initial);
        break;
      default:
        break;
    }
  }

  async updateUserMetadata(metadata = {}) {
    return this.chimeMessagingSdkClient.send(
      new UpdateAppInstanceUserCommand({
        ChannelArn: this.ChannelArn,
        ChimeBearer: this.ChimeBearer,
        Metadata: JSON.stringify(metadata),
      })
    );
  }

  async updateChannelMetadata(metadata = {}) {
    return this.chimeMessagingSdkClient.send(
      new UpdateChannelCommand({
        ChannelArn: this.ChannelArn,
        ChimeBearer: this.ChimeBearer,
        Metadata: JSON.stringify(metadata),
      })
    );
  }
}
