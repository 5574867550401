import { Auth } from "@aws-amplify/auth";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";

export async function getCredentialsFromIdentityPool(jwt, userIdentifier, region = "us-east-1") {
  if (!jwt && !userIdentifier) {
    return fromCognitoIdentityPool({
      identityPoolId: process.env.COGNITO_IDENTITY_POOL_ID,
      clientConfig: { region },
    });
  }

  return fromCognitoIdentityPool({
    // Required. The unique identifier for the identity pool from which an identity should be
    // retrieved or generated.
    identityPoolId: process.env.COGNITO_IDENTITY_POOL_ID,

    // Optional. A standard AWS account ID (9+ digits)
    // accountId: "123456789",

    // Optional. A cache in which to store resolved Cognito IdentityIds.
    // cache: custom_storage,

    // Optional. A unique identifier for the user used to cache Cognito IdentityIds on a per-user
    // basis.
    userIdentifier, // : "user_0",

    // Optional. The ARN of the role to be assumed when multiple roles were received in the token
    // from the identity provider.
    // customRoleArn: "arn:aws:iam::1234567890:role/MYAPP-CognitoIdentity",

    // Optional. A set of name-value pairs that map provider names to provider tokens.
    // Required when using identities associated with external identity providers such as Facebook.
    logins: {
      [`cognito-idp.${region}.amazonaws.com/${process.env.COGNITO_POOL_ID}`]: jwt,
      //   "graph.facebook.com": "FBTOKEN",
      //   "www.amazon.com": "AMAZONTOKEN",
      //   "accounts.google.com": "GOOGLETOKEN",
      //   "api.twitter.com": "TWITTERTOKEN",
      //   "www.digits.com": "DIGITSTOKEN",
    },

    // Optional. Custom client config if you need overwrite default Cognito Identity client
    // configuration.
    clientConfig: { region },
  });
}

export const getAWSCredentialsForCurrentUserSession = async () => {
  const currentSession = await Auth.currentSession();
  const idToken = currentSession.getIdToken().getJwtToken();
  const credentials = await getCredentialsFromIdentityPool(idToken);
  return await credentials();
};
