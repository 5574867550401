export default class AudioOutputController {
  __started = false;
  __device = null; // null sets the default device
  __container = null; // parent of the <audio/> being bound
  /** @type {HTMLAudioElement} */
  __audioEl;

  /**
   * @param {import('./MeetingSession').default} meetingSession
   * @param {string} userId
   */
  constructor(meetingSession, userId) {
    this.meetingSession = meetingSession;
    this.userId = userId;
  }

  destroy() {
    this.__audioEl = null;
    this.__container = null;
    this.__device = null;
    this.__started = null;
  }

  /**
   * @param {string} device
   * @param {HTMLElement} container
   */
  setDevice(device, container) {
    if (container !== this.__container) {
      this.setContainer(container);
    }

    if (device !== this.__device) {
      this.__device = device;
    }
  }

  /**
   * @param {HTMLElement} container
   */
  setContainer(container) {
    if ((this.__container = container)) {
      /** @type {HTMLAudioElement} */
      let audioEl = this.__container.querySelector("audio");

      if (!audioEl) {
        audioEl = document.createElement("audio");
        audioEl.style = "display:none";
        this.__container?.appendChild(audioEl);
      }

      if (audioEl !== this.__audioEl) {
        this.__audioEl = audioEl;
      }
    }
  }

  async changeDevice(device, container) {
    this.setDevice(device, container);
    return this.restart();
  }

  async start() {
    if (this.__started || !this.__container) return;
    await this.meetingSession.audioVideo.bindAudioElement(this.__audioEl);
    await this.meetingSession.audioVideo.chooseAudioOutput(this.__device);
  }

  async restart() {
    if (this.__started) await this.stop();
    return this.start();
  }

  stop() {
    this.meetingSession.audioVideo.unbindAudioElement();
    this.__started = false;
  }
}
