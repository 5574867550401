import React, { Component } from "react";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import styles from "../styles";
import { bindActionCreators } from "redux";
import { format, subMonths } from "date-fns";
import { Table, TableBody, TableHead, TableCell, TableRow } from "@mui/material";
import actions from "actions";
import {
  isClinician,
  getUserVideoCallsLoading,
  getUserVideoCalls,
  getSignedinState,
  isCustomerCare,
  getUserPermissionsList,
  getVideoBreakdown,
  getVideoBreakdownLoading,
} from "../../../selectors";
import Modal from "elements/Modal";
import Button from "@mui/material/Button";
import Auth from "@aws-amplify/auth";
import { capitalizeBillingTypeFirstLetter } from "../../../utils/capitalizeBillingTypeFirstLetter";
import CircularProgress from "@mui/material/CircularProgress";
import { findDifferenceInMinutes } from "../../../utils/findDifferenceInMinutes";

import { convertTokens } from "@date-fns/upgrade/v2";

const mapStateToProps = (state) => ({
  signedInState: getSignedinState(state),
  userVideoCalls: getUserVideoCalls(state),
  userVideoCallsLoading: getUserVideoCallsLoading(state),
  isClinician: isClinician(state),
  isCustomerCare: isCustomerCare(state),
  userPermissions: getUserPermissionsList(state),
  videoCallBreakdown: getVideoBreakdown(state),
  videoCallBreakdownLoading: getVideoBreakdownLoading(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUserVideoCalls: actions.getUserVideoCalls,
      setUserVideoFilterStartDate: actions.setUserVideoFilterStartDate,
      setUserVideoFilterEndDate: actions.setUserVideoFilterEndDate,
      getVideoCallBreakdown: actions.getVideoCallBreakdown,
    },
    dispatch,
  );

class ClientPastCalls extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scheduleCallOpen: false,
      rescheduleCall: false,
      rescheduleDetails: null,
      noSessionNotesDialogOpen: false,
      downloadVideoCallId: null,
    };
  }

  componentDidMount() {
    if (this.props.userId) {
      this.props.setUserVideoFilterStartDate(subMonths(new Date(), 1));
      this.props.setUserVideoFilterEndDate(new Date());
    }
    if (this.props.clientId) {
      this.props.getUserVideoCalls(this.props.clientId);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.videoCallBreakdownLoading &&
      !this.props.videoCallBreakdownLoading &&
      this.state.downloadVideoCallId
    ) {
      if (
        this.props.videoCallBreakdown.length > 0 &&
        this.props.videoCallBreakdown.some((call) => !!call.note_id)
      ) {
        this.downloadAll(this.state.downloadVideoCallId);
        this.setState({ downloadVideoCallId: null });
      } else {
        this.setState({ noSessionNotesDialogOpen: true });
      }
    }
  }

  downloadAll = async (videoCallId) => {
    const baseUrl = process.env.BASE_URL;
    const userSession = await Auth.currentSession();
    location.href = `${baseUrl}/download/${userSession.accessToken.jwtToken}/all-session-notes/${videoCallId}`;
    this.setState({ downloadVideoCallId: null });
  };

  onClickDownloadSessionNotes = (videoCallId) => {
    this.setState({ downloadVideoCallId: videoCallId });
    this.props.getVideoCallBreakdown(videoCallId);
  };

  onCloseNoSessionNotesDialog = () => {
    this.setState({ noSessionNotesDialogOpen: false });
  };

  render() {
    const {
      classes,
      userVideoCalls,
      userPermissions,
      userVideoCallsLoading,
      userId,
      navigate,
      clientId,
    } = this.props;
    let rows = userVideoCalls.slice(0, 10);
    return (
      <div>
        {rows.length > 0 && !userVideoCallsLoading ? (
          <Table className={classes.clientDetailsTable}>
            <TableHead>
              <TableRow>
                <TableCell>Begin</TableCell>
                <TableCell>End</TableCell>
                <TableCell>Call Duration</TableCell>
                <TableCell>Call Type</TableCell>
                <TableCell>Call Details</TableCell>
                {userPermissions?.download_session_notes && (
                  <TableCell align="center">Notes</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, i) => (
                <TableRow key={row.id} style={i % 2 === 0 ? { backgroundColor: "#ECFAFA" } : null}>
                  <TableCell align="left">
                    {row.start_date
                      ? format(new Date(row.start_date), convertTokens("MMM D, YYYY h:mm a"), {
                          awareOfUnicodeTokens: true,
                        })
                      : ""}
                  </TableCell>
                  <TableCell align="left">
                    {row.end_date
                      ? format(new Date(row.end_date), convertTokens("MMM D, YYYY h:mm a"), {
                          awareOfUnicodeTokens: true,
                        })
                      : ""}
                  </TableCell>
                  <TableCell align="left">
                    {row.end_date
                      ? `${findDifferenceInMinutes(row.end_date, row.start_date)} mins`
                      : ""}
                  </TableCell>
                  <TableCell align="left">
                    {row.billing_type ? capitalizeBillingTypeFirstLetter(row.billing_type) : null}
                  </TableCell>

                  <TableCell align="left">
                    <Button
                      onClick={() => navigate(`/video-calls/${row.id}`)}
                      variant="text"
                      disabled={!userPermissions?.view_video_call_details}
                    >
                      View
                    </Button>
                  </TableCell>
                  {userPermissions?.download_session_notes && (
                    <TableCell align="center">
                      <Button
                        variant="text"
                        onClick={() => this.onClickDownloadSessionNotes(row.id)}
                      >
                        Download
                      </Button>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : userVideoCallsLoading ? (
          <div className={classes.clientDetailsLoadingContainer}>
            <CircularProgress size={25} color="primary" style={{ marginBottom: 10 }} />
            <Typography>Loading</Typography>
          </div>
        ) : rows.length == 0 ? (
          <div className={classes.clientDetailsLoadingContainer}>
            <Typography>No Past Calls found</Typography>
          </div>
        ) : null}
        <div className={classes.clientCallsMoreItemsContainer}>
          <Typography>Need to see more past calls?</Typography>
          <Button color="secondary" onClick={() => navigate(`/video-calls/user/${clientId}`)}>
            Past Calls List
          </Button>
        </div>
        <Modal
          open={this.state.noSessionNotesDialogOpen}
          title="No Session Notes Exist"
          description={
            <>
              This session does not have any session notes associated with it.
              <br /> Please contact the clinical department for more information.
            </>
          }
          primaryActionText="Close"
          primaryActionOnClick={this.onCloseNoSessionNotesDialog}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ClientPastCalls));
