import { useQuery } from "react-query";
import { fetchDocumentSignatures } from "api/document";



export const useDocumentSignatures = (documentId) => {
  return useQuery({
    queryKey: ["documentSignatures", documentId],
    queryFn: () => fetchDocumentSignatures(documentId),
    enabled: !!documentId, // Only run if documentId is truthy
  });
};
