import { useQuery } from "react-query";

import { getTeamMemberInvitation } from "api/team";
import { GET_TEAM_MEMBER_INVITATION, STALE_TIME_5_MINUTES } from "constants/reactQueryKeys";
import { queryParamsObjIsValid } from "utils/queryParamsObjIsValid";
/** @typedef {import("api/typesDef").TeamMemberInvitation} TeamMemberInvitation */

const useGetTeamMemberInvitation = (query) => {
  const { data, isLoading, ...rest } = useQuery(
    [GET_TEAM_MEMBER_INVITATION, query],
    () => getTeamMemberInvitation(query),
    {
      enabled: queryParamsObjIsValid(query),
      staleTime: STALE_TIME_5_MINUTES,
      retry: 3,
    }
  );
  return {
    /** @type {TeamMemberInvitation} */
    invitation: data?.data || null,
    invitationLoading: isLoading,
    ...rest,
  };
};

export default useGetTeamMemberInvitation;
