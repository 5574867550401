import React from "react";
import SignalWifi0BarIcon from "@mui/icons-material/SignalWifi0Bar";
import SignalWifi1BarIcon from "@mui/icons-material/SignalWifi1Bar";
import SignalWifi2BarIcon from "@mui/icons-material/SignalWifi2Bar";
import SignalWifi3BarIcon from "@mui/icons-material/SignalWifi3Bar";
import SignalWifi4BarIcon from "@mui/icons-material/SignalWifi4Bar";
import SignalWifiOffIcon from "@mui/icons-material/SignalWifiOff";

const NetworkQualityLevel = (props) => {
  const { networkLevel, classes } = props;
  const renderIcon = () => {
    if (networkLevel == 0) {
      return <SignalWifiOffIcon className={classes.signalIcon} />;
    } else if (networkLevel == 0.5) {
      return <SignalWifi0BarIcon className={classes.signalIcon} />;
    } else if (networkLevel == 1) {
      return <SignalWifi1BarIcon className={classes.signalIcon} />;
    } else if (networkLevel == 2) {
      return <SignalWifi2BarIcon className={classes.signalIcon} />;
    } else if (networkLevel == 3 || networkLevel == 4) {
      return <SignalWifi3BarIcon className={classes.signalIcon} />;
    } else {
      return <SignalWifi4BarIcon className={classes.signalIcon} />;
    }
  };

  return <>{renderIcon()}</>;
};

export default NetworkQualityLevel;
