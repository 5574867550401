import moment from "moment";
import { convertNumbersToDays } from "utils/convertNumbersToDays";

/**
 * Creates a list of private events with additional properties.
 *
 * @param {Array} privateEvents - The array of private events the one on the response of the endpoint /video/calendar-sessions.
 * @returns {Array} - The list of private events with additional properties.
 */
export const createPrivateEventList = (privateEvents = []) =>
  privateEvents?.map((event) => {
    return {
      client_name: event.reason,
      start: new Date(event.startDateTime),
      end: new Date(event.endDateTime),
      allDay: false,
      call_duration: moment(event.endDateTime).diff(event.startDateTime, "minutes"),
      ...event,
    };
  });

const billingTypeLabelMap = {
  CAREGIVER_TRAINING: "Caregiver Training",
  "CAREGIVER TRAINING": "Caregiver Training",
  ORIENTATION: "Orientation",
  DIRECT_THERAPY: "Direct Therapy",
  "DIRECT THERAPY": "Direct Therapy",
  ASSESSMENT: "Assessment",
  OTHER_SERVICES: "Other Services",
};

export const getFormattedRepeatType = ({ repeatDays, timeUnit = "weeks", timeInterval }) => {
  if (timeUnit === "weeks" && repeatDays) {
    let repeatType;
    switch (repeatDays) {
      case "1,2,3,4,5,6,7":
        repeatType = "Daily";
        break;
      case "1,2,3,4,5":
        repeatType = "Weekdays";
        break;
      case "6,7":
        repeatType = "Weekends";
        break;
      default:
        repeatType = `Weekly on ${convertNumbersToDays(repeatDays)}`;
        break;
    }
    return repeatType;
  } else if (timeUnit === "days" && timeInterval) {
    return `Every ${timeInterval} day${timeInterval > 1 ? "s" : ""}`;
  }
  return null;
};

/**
 * Creates calendar call events based on the provided calls data.
 * @param {Array} calls - The array of calls data, the one on the response of the endpoint /video/calendar-sessions.
 * @returns {Array} - The array of calendar call events.
 */
export const createCalendarCallEvents = (calls = [], serviceTypeLookup) =>
  calls?.map((call) => {
    let billingType = call.billing_type.toUpperCase();
    const callType = call.is_video_call
      ? billingTypeLabelMap[billingType]
      : serviceTypeLookup?.[call.billing_type] ?? call.billing_type;

    let noShow = call.no_show;
    let canceled = call.canceled;
    let offPlatform = call.off_platform;
    let notStarted = call.not_started;

    const scheduledStartDate = new Date(call.scheduled_date);
    const scheduledEndDate = new Date(
      moment(call.scheduled_date).add(call.call_duration, "minutes")
    );

    const pastCall = new Date() > new Date(scheduledEndDate);
    let didNotOccur = false;
    if (canceled) didNotOccur = true;
    if (noShow || notStarted || (pastCall && !call.start_date && !canceled && !offPlatform)) {
      didNotOccur = true;
    }

    return {
      title: callType,
      start: scheduledStartDate,
      end: scheduledEndDate,
      allDay: false,
      repeatType: getFormattedRepeatType({
        repeatDays: call.repeat_days,
        timeUnit: call.time_unit,
        timeInterval: call.time_interval,
      }),
      pastCall,
      didNotOccur,
      ...call,
    };
  });
