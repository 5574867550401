import React, { useState } from "react";
import withStyles from "@mui/styles/withStyles";
import ProvidersDetails from "./ProvidersDetails";
import styles from "../../styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { UserCirclePlus } from "@phosphor-icons/react";
import ProvidersFormDialog, { ProviderFormDialogType } from "./ProvidersFormDialog";
import SectionBox from "../../../../elements/SectionBox";
import { useGlobalToast } from "../../../GlobalToastProvider";
import { checkboxes, providerTypes } from "./providerTypes";

const Providers = (props) => {
  const [providerFormType, setProviderFormType] = useState();
  const [providerFormValues, setProviderFormValues] = useState();

  const {
    providers,
    onCreatePHIRelease,
    editPhysicianInfoOpen,
    documentRequests,
    editMode,
    clientId,
    getCustomerPhysicians,
    userPermissions,
    currentUser,
  } = props;

  const hasProviders = providers.length > 0;

  const { showToast } = useGlobalToast();

  const handleProviderUpdate = ({ id, physician_type }) => {
    setProviderFormType(ProviderFormDialogType.updateProvider);
    setProviderFormValues({ id, type: physician_type });
  };
  const handleFormClose = () => {
    setProviderFormType(null);
    setProviderFormValues(null);
  };
  const onCreateROIForm = ({ id, physician_type }) => {
    setProviderFormValues({ id, type: physician_type });
    setProviderFormType(ProviderFormDialogType.roi);
  };
  const onProviderChanges = (ROICreated) => {
    const isEdit = providerFormType === "updateProvider";
    const isNew = providerFormType === "newProvider";
    let message = `Provider successfully ${isNew ? "added" : isEdit ? "updated" : "removed"}`;
    
    if (ROICreated) {
      message = "Release of Information successfully created";
    }
    getCustomerPhysicians(props.match.params.id);
    showToast({ message });
    setProviderFormType(null);
    setProviderFormValues(null);
  };

  const setROIDefaultValues = () => {
    let defaultDisclosures = {};
    const defaults = providerTypes.find(
      (p) => p.value === providerFormValues?.type
    )?.defaultDisclosureInfo;
    defaults.forEach((field) => {
      defaultDisclosures[field] = true;
    });
    const nonDefaults = checkboxes.filter((c) => !defaults.includes(c));
    nonDefaults.forEach((field) => {
      defaultDisclosures[field] = false;
    });
    defaultDisclosures["otherDescription"] = "";
    return defaultDisclosures;
  };

  return (
    <SectionBox
      title="Providers"
      description="External physician providers linked with the client’s account."
      columnDirection
    >
      {providers.map((provider) => (
        <ProvidersDetails
          key={provider.id}
          editMode={editMode}
          provider={provider}
          onProviderChanges={onProviderChanges}
          onEditProviderType={() => handleProviderUpdate(provider)}
          onCreateROIForm={() => onCreateROIForm(provider)}
          documentRequests={documentRequests}
          onCreatePHIRelease={onCreatePHIRelease}
          userPermissions={userPermissions}
          currentUserId={currentUser?.id}
        />
      ))}
      {!hasProviders && !editPhysicianInfoOpen && !editMode && (
        <Typography
          component="p"
          variant="body2"
          color={"textSecondary"}
          sx={{ width: "100%", lineHeight: 6 }}
        >
          There is no physician information
        </Typography>
      )}
      {editMode && (
        <Button
          color="secondary"
          onClick={() => setProviderFormType(ProviderFormDialogType.newProvider)}
          style={{ marginBottom: "24px", width: "148px", padding: "6px 16px" }}
          startIcon={<UserCirclePlus size={24} />}
        >
          Add Provider
        </Button>
      )}
      {providerFormType && (
        <ProvidersFormDialog
          {...props}
          onProviderChanges={onProviderChanges}
          open
          closeDialog={handleFormClose}
          clientId={clientId}
          defaultValues={
            providerFormType === ProviderFormDialogType.updateProvider
              ? {
                  provider: providerFormValues,
                  isEdit: true,
                }
              : providerFormType === ProviderFormDialogType.roi
                ? setROIDefaultValues()
                : null
          }
          formDialogType={providerFormType}
          providerFormValues={providerFormValues}
        />
      )}
    </SectionBox>
  );
};

export default withStyles(styles)(Providers);
