import React from "react";
import SMSNotification from "./SMSNotification";
import CallNotEndedDialog from "./CallNotEndedDialog";

const ClinicianNotifications = (props) => {
  const { notifications, callNotEndedDialogOpen } = props;
  return (
    notifications &&
    notifications.map((notification) => {
      switch (notification.type) {
        case "SMS":
          return <SMSNotification notification={notification} {...props} />;
        case "CALL_NOT_ENDED":
          return (
            <CallNotEndedDialog
              open={callNotEndedDialogOpen}
              notification={notification}
              {...props}
            />
          );
        default:
          return null;
      }
    })
  );
};

export default ClinicianNotifications;
