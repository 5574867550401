import React from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { STATES } from "../../../constants";
import Modal from "elements/Modal";
import { CustomSelect } from "elements/DEPRECATED_elements";
import MenuItem from "@mui/material/MenuItem";

const DriversLicenseDialog = (props) => {
  const {
    classes,
    licenseNumber,
    licenseState,
    filename,
    onToggleDialog,
    dialogOpen,
    driversLicenseSaving,
    driversLicenseSuccess,
    driversLicenseError,
    onSaveDriversLicense,
    onChange,
    driversLicenseImage,
    uploadError,
    openFileDialog,
    uploadSuccess,
    isDirty,
  } = props;

  const buttonDisabled = () => {
    if (
      licenseNumber &&
      licenseState &&
      (driversLicenseImage.length > 0 || filename) &&
      !driversLicenseSaving &&
      !driversLicenseSuccess &&
      isDirty
    ) {
      return false;
    }
    return true;
  };

  return (
    <Modal
      open={dialogOpen}
      // onClose={onToggleDialog}
      title="Driver's License Information"
      titleCentered
      content={
        <div style={{ width: 600 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button onClick={openFileDialog} className={classes.uploadImageButton}>
                {driversLicenseImage.length > 0 || filename
                  ? "Image uploaded - Click to Replace Image"
                  : " Upload Image"}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <TextField
                select
                id="licenseState"
                name="licenseState"
                autoComplete="off"
                value={licenseState}
                onChange={onChange("licenseState")}
                InputLabelProps={{ shrink: true }}
                label="Driver's License State*"
                fullWidth
              >
                <MenuItem disabled value={""} />
                {Object.keys(STATES).map((key) => {
                  if (key !== "Out") {
                    return (
                      <MenuItem key={key} value={key}>
                        {STATES[key]}
                      </MenuItem>
                    );
                  }
                })}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="licenseNumber"
                label="Driver's License Number*"
                value={licenseNumber}
                onChange={onChange("licenseNumber")}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
        </div>
      }
      error={uploadError || driversLicenseError}
      errorMessage="There was an error uploading this information. Please try again."
      success={driversLicenseSuccess}
      successMessage="Driver's License information saved successfully."
      loading={driversLicenseSaving}
      primaryActionText="Save"
      primaryActionOnClick={onSaveDriversLicense}
      primaryActionDisabled={buttonDisabled()}
      secondaryActionText={uploadSuccess ? "Close" : "Cancel"}
      secondaryActionOnClick={onToggleDialog}
      secondaryActionDisabled={driversLicenseSaving}
    />
  );
};

export default DriversLicenseDialog;
