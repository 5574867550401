import React from "react";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import TableRow from "@mui/material/TableRow";
import { CaretRight, CaretLeft, CaretDoubleRight, CaretDoubleLeft } from "@phosphor-icons/react";
import { useTheme } from "@emotion/react";
import { Tooltip } from "@mui/material";

const PageActions = (props) => {
  const theme = useTheme();
  const handleFirstPageButtonClick = (event) => {
    props.onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    props.onPageChange(event, props.page - 1);
  };

  const handleNextButtonClick = (event) => {
    props.onPageChange(event, props.page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    props.onPageChange(event, Math.max(0, Math.ceil(props.count / props.rowsPerPage) - 1));
  };

  const { className, count, page, rowsPerPage } = props;

  return (
    <div className={className}>
      <Tooltip title="First page">
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
          size="large"
        >
          {theme.direction === "rtl" ? <CaretDoubleRight /> : <CaretDoubleLeft />}
        </IconButton>
      </Tooltip>
      <Tooltip title="Previous Page">
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
          size="large"
        >
          {theme.direction === "rtl" ? <CaretRight /> : <CaretLeft />}
        </IconButton>
      </Tooltip>
      <Tooltip title="Next Page">
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
          size="large"
        >
          {theme.direction === "rtl" ? <CaretLeft /> : <CaretRight />}
        </IconButton>
      </Tooltip>
      <Tooltip title="Last Page">
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
          size="large"
        >
          {theme.direction === "rtl" ? <CaretDoubleLeft /> : <CaretDoubleRight />}
        </IconButton>
      </Tooltip>
    </div>
  );
};

const PaginatedFooter = (props) => {
  const { lockRowsPerPage, count, rowsPerPage, page, handleChangePage, handleChangeRowsPerPage } =
    props;
  return (
    <TableFooter>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={lockRowsPerPage ? [lockRowsPerPage] : [5, 10, 25]}
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={PageActions}
          slotProps={{
            select: {
              variant: "outlined",
              size: "small",
            },
          }}
        />
      </TableRow>
    </TableFooter>
  );
};

export default PaginatedFooter;
