import React, { Component } from "react";
import { Dialog, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import ChromeCamera from "../../assets/blocked-camera-chrome.jpg";

class WelcomeDialog extends Component {
  render() {
    const { classes, welcomeDialogOpen, permissionError, permissionErrorMessage, closeDialog } =
      this.props;
    return (
      <>
        <Dialog
          open={welcomeDialogOpen}
          disableEscapeKeyDown={true}
          PaperProps={{
            style: {
              minHeight: 250,
              maxWidth: "70%",
              padding: 20,
              overflow: "auto",
            },
          }}
        >
          <div className={classes.dialogContainer}>
            <Typography component="h3" variant="h3" className={classes.dialogTitle}>
              Welcome!
            </Typography>
            <Typography component="h4" variant="h4">
              Let's check your system to make sure everything is working correctly! First, we need
              to have permission to run a quick test.
            </Typography>
            <Typography component="p" variant="body1" style={{ width: "80%", marginTop: 15 }}>
              This test will check your camera, microphone, speakers, and connection to you internet
              - all of which must be working in order to use our virtual services. This test will
              not be running during any other time.
            </Typography>
            {!permissionError ? (
              <Typography component="p" variant="body1" style={{ margin: "10px 0px" }}>
                Please click "allow" in the upper area of your browser.
              </Typography>
            ) : (
              <div className={classes.dialogErrorTextContainer}>
                <Typography component="p" variant="body1" style={{ marginTop: 20 }}>
                  Failed to access your computer's camera and/or microphone.{" "}
                  <i>
                    ({permissionErrorMessage.name}:{permissionErrorMessage.message})
                  </i>
                </Typography>
                <Typography component="p" variant="body1" style={{ marginTop: 10 }}>
                  We require access to your camera and microphone. Click the camera blocked icon{" "}
                  <img src={ChromeCamera} className={classes.blockedCamera} /> in your browser's
                  address bar, allow access and then refresh the page.
                </Typography>
                <Button color="secondary" className={classes.dialogButton} onClick={closeDialog}>
                  Continue without audio and/or video
                </Button>
              </div>
            )}
          </div>
        </Dialog>
      </>
    );
  }
}
export default WelcomeDialog;
