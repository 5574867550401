export default (theme) => ({
  clinicianContainer: {
    margin: "80px auto",
    padding: "0 24px",
    maxWidth: 780,
  },
  cardAvatar: {
    width: 75,
    height: 75,
    border: "1px solid #7f7e81",
    margin: "0 auto 1rem",
  },
  planTabHeader: {
    textTransform: "none",
    color: "black",
  },
  selectionBar: {
    boxShadow: "none",
    backgroundColor: "#987ece",
    borderRadius: 5,
    marginTop: 15,
  },
  tabBar: {
    backgroundColor: "#987ece",
    borderLeft: "3px solid #987ece",
    borderRight: "3px solid #987ece",
    borderRadius: 5,
    "& .MuiButtonBase-root": {
      fontSize: 16,
    },
  },
  tabButton: {
    backgroundColor: "transparent",
    borderTop: "3px solid #987ece",
    borderBottom: "3px solid #987ece",
    opacity: 1,
  },
  activeTab: {
    backgroundColor: "#fff",
    borderRadius: 5,
  },
  indicatorColor: {
    backgroundColor: "#987ece",
  },
  cardBio: {
    maxWidth: 500,
    margin: "0 auto",
  },
  specialtiesList: {
    maxWidth: 300,
    margin: "0 auto",
  },
  signupButton: {
    width: "100%",
    height: 50,
    borderRadius: 5,
    backgroundColor: "#987ece",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    "& p": {
      fontSize: 20,
    },
  },
  loading: {
    height: 250,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});
