import { useJamMetadata } from "hooks/useJamMetadata";
import { log } from "video/debug";

const [jamMetadata, addJamMetadata] = useJamMetadata();
export default class AwsChimeMeetingEventObserver {
  /**
   * @param {import('./MeetingSession').default} meetingSession
   */
  constructor(meetingSession) {
    this.meetingSession = meetingSession;
  }

  destroy() {
    this.recentHistory = null;
    this._onEventCbs = null;
    this._onFatalErrorCbs = null;
  }

  /** @type {{name: import('amazon-chime-sdk-js').EventName,timestampMs:number}[]}*/
  recentHistory = [];

  _onFatalErrorCbs = [];
  __onFatalError(errorMsg = "", eventName = "") {
    this._onFatalErrorCbs.forEach((cb) => cb(errorMsg, eventName));
  }
  onFatalError(cb) {
    this._onFatalErrorCbs.push(cb);
  }

  /** @type {{[eventName:string]:(eventAttributes:import('amazon-chime-sdk-js').EventAttributes)=>void}} */
  _onEventCbs = {};
  /**
   * @param {import('amazon-chime-sdk-js').EventName} eventName
   * @param {import('amazon-chime-sdk-js').EventAttributes} eventAttributes
   */
  __onEvent(eventName, eventAttributes) {
    this._onEventCbs[eventName]?.forEach((cb) => cb(eventAttributes));
  }
  /**
   * @param {import('amazon-chime-sdk-js').EventName} eventName
   * @param {(eventAttributes:import('amazon-chime-sdk-js').EventAttributes)=>void} cb
   */
  onEvent(eventName, cb) {
    this._onEventCbs[eventName] = this._onEventCbs[eventName] || [];
    this._onEventCbs[eventName].push(cb);
  }

  /**
   * @see https://aws.github.io/amazon-chime-sdk-js/modules/meetingevents.html
   * @param {import('amazon-chime-sdk-js').EventName} name
   * @param {import('amazon-chime-sdk-js').EventAttributes} attributes
   */
  eventDidReceive(name, attributes) {
    log("eventDidReceive: %s", name, attributes);

    this.__onEvent(name, attributes);

    const { meetingHistory, ...otherAttributes } = attributes;

    // last 5min of history
    this.recentHistory = meetingHistory.filter(
      ({ timestampMs }) => Date.now() - timestampMs < 300000
    );

    addJamMetadata({ recentMeetingHistory: this.recentHistory });

    // TODO ensure this is not AFTER a successful reconnect ?
    const cxErr = meetingHistory.find((ev) => ev.name === "signalingDropped");

    const meetingEnded =
      otherAttributes.meetingStatus === "MeetingEnded" ||
      meetingHistory.find((ev) => ev.name === "meetingEnded");

    switch (name) {
      // this one is called along with, e.g., meetingEnded or meetingStartFailed - so we should ignore it? (or ingore the others?)
      case "signalingDropped":
        break;

      case "meetingStartFailed":
        switch (otherAttributes.meetingStatus) {
          case "MeetingEnded":
          case "AudioCallAtCapacity":
          case "SignalingBadRequest":
          case "TaskFailed":
            this.__onFatalError("CHM_ERR_MEETING_ENDED", name);
            break;
          default:
            break;
        }
        break;

      case "meetingEnded":
        cxErr && this.__onFatalError("CHM_ERR_MEETING_ENDED", name);
        break;

      case "meetingFailed":
        this.__onFatalError("CHM_ERR_MEETING_ENDED", name);
        break;

      case "audioInputFailed":
      case "videoInputFailed":
        break;

      default:
        break;
    }
  }
}

/*
  meetingStartRequested	The meeting will start.

  meetingStartSucceeded	The meeting started.

  meetingReconnected	The meeting reconnected.

  meetingStartFailed	The meeting failed to start due to the following failure MeetingSessionStatusCode:
    AudioCallAtCapacity
    MeetingEnded
    SignalingBadRequest
    TaskFailed
    For more information, see the "Meeting error messages" section.

  meetingEnded	The meeting ended.

  meetingFailed	The meeting ended with one of the following failure MeetingSessionStatusCode:
    AudioAttendeeRemoved
    AudioJoinedFromAnotherDevice
    RealtimeApiFailed
    TaskFailed
    For more information, see the "Meeting error messages" section.

  attendeePresenceReceived	The attendee joined the meeting with the microphone.

  deviceLabelTriggerFailed	The device label trigger failed. By default, the Chime SDK for JavaScript requests access to the microphone and camera in order to retrieve device labels. The SDK sends this event when either the microphone, camera, or both requests fail.
    If you have configured a custom function with meetingSession.audioVideo.setDeviceLabelTrigger, the SDK sends this event when your function fails.

  audioInputSelected	The microphone was selected.

  audioInputUnselected	The microphone was removed. You called meetingSession.audioVideo.stopAudioInput.

  audioInputFailed	The microphone selection failed.

  videoInputSelected	The camera was selected.

  videoInputUnselected	The camera was removed. You called meetingSession.audioVideo.stopVideoInput.

  videoInputFailed	The camera selection failed.

  signalingDropped	The WebSocket failed or closed with an error.

  receivingAudioDropped	A significant number of receive-audio packets dropped.

  sendingAudioFailed	Sending audio packets failed for N samples consecutively after an initial wait time of T. You can configure these values in ConnectionHealthPolicyConfiguration where N = sendingAudioFailureSamplesToConsider and T = sendingAudioFailureInitialWaitTimeMs.

  sendingAudioRecovered	Sending audio packets successful after failure.
*/
