import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import { Alert, AlertTitle } from "@mui/material";

const ClientNoShowDialog = (props) => {
  const { open, clientName, markNoShow, onCloseClientNoShow } = props;
  return (
    <Dialog
      open={open}
      hideBackdrop={true}
      aria-labelledby="form-dialog-title"
      PaperProps={{ style: { width: 464 } }}
    >
      <DialogContent>
        <Alert severity="warning">
          <AlertTitle>Did {clientName} miss the call?</AlertTitle>
          The client has yet to join and you're the only one here. Do you want to stay in the call
          or end the call and mark the client as a no-show?
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseClientNoShow} color="secondary">
          Stay in the call
        </Button>
        <Button onClick={markNoShow} color="error">
          Mark as no-show and end call
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ClientNoShowDialog;
