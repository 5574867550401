import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

const PromptControls = (props) => {
  const {
    promptCards,
    cardIndex,
    skipPromptCard,
    endActivity,
    cardsCorrect,
    cardsIncorrect,
    classes,
  } = props;
  return (
    <div className={classes.cardActivityControls}>
      <>
        <div className={classes.cardUpcomingContainer}>
          {promptCards[cardIndex] ? (
            <>
              <Typography component="p">
                <strong>"{promptCards[cardIndex].answer}"</strong>
              </Typography>
              <img src={promptCards[cardIndex].url} className={classes.cardUpcomingImage} />
            </>
          ) : (
            <Typography component="p">No More Cards</Typography>
          )}
        </div>
        <Button variant="text" onClick={skipPromptCard} className={classes.skipCardButton}>
          <Typography component="p" style={{ color: "#8E42CA" }}>
            Skip
          </Typography>
        </Button>
        <div className={classes.cardResults}>
          <Typography component="p" style={{ marginBottom: 5 }}>
            <strong>Results:</strong>
          </Typography>
          <div>
            <Typography component="p">
              Correct: <strong>{cardsCorrect}</strong>
            </Typography>
            <Typography component="p">
              Incorrect: <strong>{cardsIncorrect}</strong>
            </Typography>
          </div>
        </div>
      </>

      <Button onClick={endActivity} variant="contained" className={classes.endCardActivityButton}>
        <Typography component="p">End Activity</Typography>
      </Button>
    </div>
  );
};

export default PromptControls;
