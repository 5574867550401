import React, { useEffect } from "react";
import { Divider, Typography, Box, CircularProgress } from "@mui/material";
import { WorkingHoursSelector } from "components/ClinicianWorkingHours";
import { useQuery } from "react-query";
import { getClinicianWorkingHours } from "api/clinician";
import { formatTimeZoneToAbbreviation } from "utils/formatTimeZoneToAbbreviation";

const daysOrder = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

export const ClinicianWorkingHours = ({
  editMode,
  basicInfo,
  newWorkingHours,
  setNewWorkingHours,
  editWorkingHoursPerm,
  viewWorkingHoursPerm,
  onChangeWorkingHours,
}) => {
  const {
    data: body,
    isLoading,
    refetch,
  } = useQuery("body", () => getClinicianWorkingHours(basicInfo.id), {
    onSuccess: (body) => {
      if (!editMode) {
        setNewWorkingHours(body.data);
      }
    },
    onError: (error) => {
      console.error("Error updating clinician working hours:", error);
    },
  });

  useEffect(() => {
    if (!editMode) {
      refetch();
    }
  }, [editMode, refetch]);

  useEffect(() => {
    setNewWorkingHours(body);
  }, []);

  const updateDayData = (day, isActive, newRanges) => {
    setNewWorkingHours({
      ...newWorkingHours,
      [day]: { isActive, ranges: newRanges },
    });
    onChangeWorkingHours();
  };

  const copyToDays = (chosenDay, selectedDays) => {
    setNewWorkingHours({
      ...selectedDays.reduce(
        (acc, day) => {
          acc[day] = newWorkingHours[chosenDay];
          return acc;
        },
        {
          ...newWorkingHours,
        }
      ),
    });
  };

  return (
    <Box display="flex" justifyContent="center">
      <Box width="80%" maxWidth="1200px" padding={"16px 0px"}>
        <Box display={"flex"} flexDirection={"row"}>
          <Box display={"flex"} flexDirection={"column"} width={"500px"}>
            <Typography variant="h3">Working Hours</Typography>
            <Typography
              color={(theme) => theme.palette.text.secondary}
              variant="body2"
              width={"400px"}
            >
              {!editWorkingHoursPerm && viewWorkingHoursPerm
                ? `Contact your clinical supervisor to change your working hours.`
                : `To set the Clinician’s working hours for therapy sessions, please indicate the days
                and times they are available.`}
            </Typography>
          </Box>
          <Box display={"flex"} flexDirection={"column"} width={"100%"} paddingLeft={"24px"}>
            <Typography
              variant="inputLabel"
              color={(theme) => theme.palette.text.secondary}
              style={{
                height: "40px",
                display: "flex",
                alignItems: "center",
              }}
            >
              Working Hours
            </Typography>
            {!!newWorkingHours && !isLoading ? (
              <>
                {daysOrder.map((day) => {
                  const dayData = newWorkingHours[day];
                  if (dayData) {
                    return (
                      <WorkingHoursSelector
                        key={day}
                        day={day}
                        isActive={dayData.isActive}
                        clinicianTimeZone={basicInfo.timezone}
                        timeRanges={dayData.ranges}
                        updateDayData={updateDayData}
                        editMode={editMode}
                        copyToDays={copyToDays}
                      />
                    );
                  }
                  return null; // Return null if there's no data for the day
                })}
                {editMode && (
                  <Typography
                    variant="caption"
                    sx={{ fontStyle: "italic", marginTop: "16px" }}
                  >{`All times are in the clinician's time zone (${
                    formatTimeZoneToAbbreviation(basicInfo.timezone) || "UTC"
                  })`}</Typography>
                )}
              </>
            ) : (
              <CircularProgress size={30} color="primary" style={{ marginBottom: 10 }} />
            )}
          </Box>
        </Box>
        <Divider style={{ paddingTop: "16px" }} />
      </Box>
    </Box>
  );
};

export default ClinicianWorkingHours;
