import React from "react";
import { Box, Grid, Typography } from "@mui/material";

import { averageStatistics } from "./styled";
import { DeltaItem } from "./StatItem";
/** @typedef {import("api/typesDef").AssessmentsClient} AssessmentClient */

/**
 * The function `AverageScore` renders a section displaying assessment score averages for different
 * categories.
 * @component
 * @param {Object} props - The props object.
 * @param {AssessmentClient[]} props.dataAssessments - The assessment data for the client
 * @return {JSX.Element} - The `AverageScore` component to be rendered
 */
export default function AverageScore({ dataAssessments }) {
  const [assessment, prevAssessment] = dataAssessments;

  const abs = assessment.data.adaptive_behavior_composite_standard_score;
  const cs = assessment.data.communication_score;
  const dls = assessment.data.daily_living_score;
  const sc = assessment.data.socialization_score;
  const ms = assessment.data.motor_score;

  const absPrev = prevAssessment?.data.adaptive_behavior_composite_standard_score;
  const csPrev = prevAssessment?.data.communication_score;
  const dlsPrev = prevAssessment?.data.daily_living_score;
  const scPrev = prevAssessment?.data.socialization_score;
  const msPrev = prevAssessment?.data.motor_score;

  return (
    <Box component="section" sx={averageStatistics}>
      <Grid container gap={2}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Assessment Score Averages</Typography>
        </Grid>

        <Grid item xs={2}>
          <DeltaItem
            isUp
            label="Adaptive Behavior Composite"
            currentValue={abs}
            previousValue={absPrev}
          />
        </Grid>

        <Grid item xs={2}>
          <DeltaItem isUp label="Communication Score" currentValue={cs} previousValue={csPrev} />
        </Grid>

        <Grid item xs={2}>
          <DeltaItem isUp label="Daily Living Score" currentValue={dls} previousValue={dlsPrev} />
        </Grid>

        <Grid item xs={2}>
          <DeltaItem label="Socialization Score" currentValue={sc} previousValue={scPrev} />
        </Grid>

        <Grid item xs={2}>
          <DeltaItem label="Motor Skills" currentValue={ms} previousValue={msPrev} />
        </Grid>
      </Grid>
    </Box>
  );
}
