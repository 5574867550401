import { createReducer } from "redux-create-reducer";
import actions from "../actions";

const initialState = {
  performance: 0,
  testLoading: false,
  isSuccess: false,
};

const reducers = {
  [actions.GET_COMPUTER_PERFORMANCE](state) {
    return {
      ...state,
      testLoading: true,
    };
  },
  [actions.GET_COMPUTER_PERFORMANCE_SUCCESS](state, { payload }) {
    return {
      ...state,
      testLoading: false,
      isSuccess: true,
      performance: payload.result,
    };
  },
  [actions.CLEAR_COMPUTER_PERFORMANCE](state) {
    return initialState;
  },
};

export default createReducer(initialState, reducers);
