import { createReducer } from "redux-create-reducer";
import actions from "../actions";

const initialState = {
  customers: [],
  customersLoading: false,
  page: 0,
  rowsPerPage: 25,
  count: 0,
  sort: "",
  sortdirection: "asc",
  insuranceCustomersType: "AWAITING",
  insuranceCustomersFilter: "",
  insuranceCustomersState: "",
  customerLoaded: false,
  search: "",
  customer: {
    details: {
      name: "",
      userId: 0,
      email: "",
      dateCreated: "",
      organization: "",
    },
    children: {
      childId: 0,
      name: "",
      age: 0,
      notes: "",
    },
    clinician: {
      userId: 0,
      firstName: "",
      lastName: "",
    },
    secondaryClinician: {},
    documents: [],
    questionnaireResponse: [],
    assessmentResponse: [],
    notes: [],
    treatmentPlans: [],
    approvedUnits: [],
    upcomingVideoCalls: [],
    physicians: [],
    statusUpdates: [],
    pastClinicians: [],
    smsThread: [],
  },
  assessment: [],
  markingEligible: false,
  markingApproved: false,
  uploadingTreatmentPlan: false,
  documentTypes: [],
  documentMatrix: [],
  documentMatrixLoading: false,
  saving: false,
  savingSuccess: false,
  createCustomerError: null,
  createUserCognitoId: null,
  createUserId: null,
  cliniciansLoading: false,
  treatmentPlansLoading: false,
  documentsLoading: false,
  approvedUnitsLoading: false,
  videoCallsLoading: false,
  physiciansLoading: false,
  pastCliniciansLoading: false,
  authorizationCodesLoading: false,
  billableTimePeriod: "week",
  documentRequests: [],
  documentRequestLoading: false,
  documentRequestSuccess: false,
  searchResults: [],
  searchLoading: false,
  deactivatedCustomers: [],
  deactivationReasons: [],
  customerDepartureOptions: [],
  statusUpdatesLoading: false,
  statusUpdatesSuccess: false,
  invitationGUID: null,
  savingForLater: false,
  savingForLaterSuccess: false,
  savingForLaterError: false,
  savedUserId: null,
  savedCustomers: [],
  savedCustomersLoading: false,
  savedCustomersError: false,
  savedCustomerDetails: {},
  savingLead: false,
  saveLeadSuccess: false,
  updatingCustomerInfo: false,
  updatingCustomerInfoSuccess: false,
  savingCustomerPhysician: false,
  savingCustomerPhysicianSuccess: false,
  savingCustomerPhysicianError: false,
  treatmentPlanLoading: false,
  treatmentPlanSuccess: false,
  billableItems: [],
  billableItemsLoading: false,
  billableItemsPage: 0,
  billableItemsRowsPerPage: 25,
  billableItemsCount: 0,
  importClientLoading: false,
  importClientError: false,
  importClientErrorMessage: "",
  importClientUserId: null,
  importClientClientId: null,
  importClientMissingFields: [],
  importClientMissingFieldsLoading: false,
  importClientMissingFieldsSuccess: false,
  primaryAccountHolderSwitchLoading: false,
  primaryAccountHolderSwitchSuccess: false,
  updateCustomerEmailLoading: false,
  updateCustomerEmailSuccess: false,
  updateCustomerEmailError: false,
  updateCustomerEmailErrorMessage: null,
  updateCustomerTimezoneLoading: false,
  updateCustomerTimezoneSuccess: false,
  updateCustomerTimezoneError: false,
  updateUserInsuranceStatusLoading: false,
  updateUserInsuranceStatusSuccess: false,
  updateUserInsuranceStatusError: false,
  pastCallsLoading: false,
  pastCalls: [],
  enableStaticPageLoading: false,
  enableStaticPageSuccess: false,
  enableStaticPageError: false,
  clientTaskList: [],
  clientTaskListLoading: false,
  markTaskCompleteLoading: false,
  markTaskCompleteSuccess: false,
  markTaskCompleteError: false,
  clientSessionStatistics: null,
  statisticsLoading: false,
  criticalNeeds: { critical: [], warning: [] },
  snoozeTaskLoading: false,
  snoozeTaskError: false,
  updatingLinkPhysician: false,
  updatingLinkPhysicianSuccess: false,
  allPhysiciansLoading: false,
  allPhysicians: [],
  allPhysiciansError: false,
  uploadDocumentLoading: false,
  uploadDocumentSuccess: false,
  uploadDocumentError: false,
};

const reducers = {
  [actions.LOAD_CUSTOMER_LIST](state) {
    return {
      ...state,
      customersLoading: true,
    };
  },
  [actions.SET_CUSTOMER_LIST](state, { payload }) {
    return {
      ...state,
      customers: payload.customers,
      count: payload.count,
      customersLoading: false,
    };
  },
  [actions.GET_INSURANCE_CUSTOMER_LIST](state) {
    return {
      ...state,
      customersLoading: true,
    };
  },
  [actions.GET_INSURANCE_CUSTOMER_LIST_FAIL](state) {
    return {
      ...state,
      customersLoading: false,
    };
  },
  [actions.GET_DEACTIVATED_CUSTOMER_LIST](state, { payload }) {
    return {
      ...state,
      customersLoading: true,
    };
  },
  [actions.GET_DEACTIVATED_CUSTOMER_LIST_SUCCESS](state, { payload }) {
    return {
      ...state,
      deactivatedCustomers: payload,
      count: payload[0]?.count ? payload[0].count : 0,
      customersLoading: false,
    };
  },
  [actions.GET_DEACTIVATED_CUSTOMER_LIST_FAIL](state) {
    return {
      ...state,
      customersLoading: false,
    };
  },
  [actions.SET_CUSTOMERS_PAGE](state, { payload }) {
    return {
      ...state,
      page: payload.page,
    };
  },
  [actions.SET_CUSTOMERS_PER_PAGE](state, { payload }) {
    return {
      ...state,
      rowsPerPage: payload.rowsPerPage,
    };
  },
  [actions.SET_CUSTOMERS_SORT](state, { payload }) {
    let sortdirection = state.sortdirection;
    if (state.sort && state.sort !== payload.sort) {
      sortdirection = "asc";
    } else if (state.sort) {
      sortdirection = sortdirection === "asc" ? "desc" : "asc";
    }
    return {
      ...state,
      sort: payload.sort,
      sortdirection,
    };
  },
  [actions.SET_INSURANCE_CUSTOMERS_FILTER](state, { payload }) {
    return {
      ...state,
      insuranceCustomersFilter: payload,
    };
  },
  [actions.SET_INSURANCE_CUSTOMERS_TYPE](state, { payload }) {
    return {
      ...state,
      insuranceCustomersType: payload,
    };
  },
  [actions.SET_INSURANCE_CUSTOMERS_STATE](state, { payload }) {
    return {
      ...state,
      insuranceCustomersState: payload,
    };
  },
  [actions.SET_CUSTOMERS_SEARCH](state, { payload }) {
    return {
      ...state,
      search: payload,
    };
  },
  [actions.GET_CUSTOMER_DETAILS](state) {
    return {
      ...state,
      customerLoaded: false,
    };
  },
  [actions.GET_CUSTOMER_INFO_SUCCESS](state, { payload }) {
    return {
      ...state,
      customer: {
        ...state.customer,
        details: payload.details,
        children: payload.children?.length > 0 ? payload.children[0] : state.customer.children,
      },
    };
  },
  [actions.CLEAR_CUSTOMER_INFO](state, { payload }) {
    return {
      ...state,
      customer: {
        details: {
          name: "",
          userId: 0,
          email: "",
          dateCreated: "",
          organization: "",
        },
        children: {
          childId: 0,
          name: "",
          age: 0,
          notes: "",
        },
        clinician: {
          userId: 0,
          firstName: "",
          lastName: "",
        },
      },
    };
  },
  [actions.LOAD_CUSTOMER_DETAILS](state) {
    return {
      ...state,
      customerLoaded: false,
      documentMatrix: [],
      customer: {
        details: {
          name: "",
          userId: 0,
          email: "",
          dateCreated: "",
          organization: "",
        },
        children: {
          childId: 0,
          name: "",
          age: 0,
          notes: "",
        },
        clinician: {
          userId: 0,
          firstName: "",
          lastName: "",
        },
        secondaryClinician: {
          userId: 0,
          firstName: "",
          lastName: "",
        },
        documents: [],
        questionnaireResponse: [],
        assessmentResponse: [],
        notes: [],
        treatmentPlans: [],
        approvedUnits: [],
        upcomingVideoCalls: [],
        physicians: [],
        statusUpdates: [],
        pastClinicians: [],
      },
      updateCustomerEmailLoading: false,
      updateCustomerEmailSuccess: false,
      updateCustomerEmailError: false,
      updateCustomerEmailErrorMessage: null,
      enableStaticPageLoading: false,
      enableStaticPageSuccess: false,
      enableStaticPageError: false,
    };
  },
  [actions.SET_CUSTOMER_DETAILS](state, { payload }) {
    return {
      ...state,
      customerLoaded: true,
      customer: {
        ...state.customer,
        details: payload[0],
        children: payload[1][0],
        notes: payload[2],
      },
    };
  },
  [actions.CLEAR_CUSTOMERS](state) {
    return {
      ...state,
      customers: [],
    };
  },
  [actions.MARK_ELIGIBLE](state) {
    return {
      ...state,
      markingEligible: true,
    };
  },
  [actions.MARK_ELIGIBLE_SUCCESS](state) {
    return {
      ...state,
      markingEligible: false,
    };
  },
  [actions.MARK_APPROVED](state) {
    return {
      ...state,
      markingApproved: true,
    };
  },
  [actions.MARK_APPROVED_SUCCESS](state) {
    return {
      ...state,
      markingApproved: false,
    };
  },
  [actions.UPLOAD_TREATMENT_PLAN](state) {
    return {
      ...state,
      treatmentPlanLoading: true,
      treatmentPlanSuccess: false,
    };
  },
  [actions.UPLOAD_TREATMENT_PLAN_SUCCESS](state) {
    return {
      ...state,
      treatmentPlanLoading: false,
      treatmentPlanSuccess: true,
    };
  },
  [actions.UPDATE_TREATMENT_PLAN](state) {
    return {
      ...state,
      treatmentPlanLoading: true,
      treatmentPlanSuccess: false,
    };
  },
  [actions.UPDATE_TREATMENT_PLAN_SUCCESS](state) {
    return {
      ...state,
      treatmentPlanLoading: false,
      treatmentPlanSuccess: true,
    };
  },
  [actions.GET_DOCUMENT_TYPES_SUCCESS](state, { payload }) {
    return {
      ...state,
      documentTypes: payload,
    };
  },
  [actions.GET_DOCUMENT_MATRIX](state) {
    return {
      ...state,
      documentMatrixLoading: true,
    };
  },
  [actions.GET_DOCUMENT_MATRIX_SUCCESS](state, { payload }) {
    return {
      ...state,
      documentMatrix: payload,
      documentMatrixLoading: false,
    };
  },
  [actions.GET_DOCUMENT_MATRIX_FAIL](state) {
    return {
      ...state,
      documentMatrixLoading: false,
    };
  },
  [actions.APPROVE_CUSTOMER_DOCUMENT](state) {
    return {
      ...state,
      documentsLoading: true,
    };
  },
  [actions.APPROVE_CUSTOMER_DOCUMENT_SUCCESS](state) {
    return {
      ...state,
      documentsLoading: false,
    };
  },
  [actions.APPROVE_CUSTOMER_DOCUMENT_FAIL](state) {
    return {
      ...state,
      documentsLoading: false,
    };
  },
  [actions.SET_CUSTOMER_DOCUMENT_STATUS](state, { payload }) {
    let customersList = [...state.customers];
    let customerIndex = customersList.findIndex(
      (cust) => cust.client_id == payload.clientId && cust.documentStatus?.loading
    );
    if (customersList[customerIndex]) {
      customersList[customerIndex].documentStatus = payload;
    }
    return {
      ...state,
      customers: customersList,
    };
  },
  [actions.UPDATE_USER_INSURANCE_STATUS](state) {
    return {
      ...state,
      customerLoaded: false,
      updateUserInsuranceStatusLoading: true,
    };
  },
  [actions.UPDATE_USER_INSURANCE_STATUS_SUCCESS](state) {
    return {
      ...state,
      updateUserInsuranceStatusLoading: false,
      updateUserInsuranceStatusSuccess: true,
    };
  },
  [actions.UPDATE_USER_INSURANCE_STATUS_FAIL](state) {
    return {
      ...state,
      updateUserInsuranceStatusLoading: false,
      updateUserInsuranceStatusError: true,
    };
  },
  [actions.CLEAR_UPDATE_USER_INSURANCE_STATUS](state) {
    return {
      ...state,
      updateUserInsuranceStatusLoading: false,
      updateUserInsuranceStatusSuccess: false,
      updateUserInsuranceStatusError: false,
    };
  },
  [actions.RESEND_LOGIN_EMAIL](state) {
    return {
      ...state,
      resendingLoginEmail: true,
    };
  },
  [actions.RESEND_LOGIN_EMAIL_SUCCESS](state) {
    return {
      ...state,
      resendingLoginEmail: false,
    };
  },
  [actions.GET_APPROVED_UNITS_SUCCESS](state, { payload }) {
    return {
      ...state,
      customer: {
        ...state.customer,
        approvedUnits: payload,
      },
    };
  },
  [actions.GET_NOTES_LIST_SUCCESS](state, { payload }) {
    return {
      ...state,
      customer: {
        ...state.customer,
        details: payload.details,
        notes: payload.notes,
      },
    };
  },
  [actions.SET_SAVING](state) {
    return {
      ...state,
      saving: true,
      createCustomerError: null,
    };
  },
  [actions.CREATE_INSURANCE_CUSTOMER](state) {
    return {
      ...state,
      saving: true,
      createCustomerError: null,
      savingSuccess: false,
      createUserCognitoID: null,
      createUserId: null,
    };
  },
  [actions.CREATE_INSURANCE_CUSTOMER_SUCCESS](state, { payload }) {
    return {
      ...state,
      saving: false,
      savingSuccess: true,
      createUserCognitoId: payload.cognito_id,
      createUserId: payload.user_id,
      invitationGUID: payload.guid, //added for parent
    };
  },
  [actions.CREATE_INSURANCE_CUSTOMER_FAIL](state, { payload }) {
    return {
      ...state,
      saving: false,
      savingSuccess: false,
      createCustomerError: payload,
    };
  },
  [actions.CLEAR_CREATE_CUSTOMER](state) {
    return {
      ...state,
      saving: false,
      savingSuccess: false,
      createCustomerError: null,
      createUserCognitoId: null,
      createUserId: null,
      savingLead: false,
      saveLeadSuccess: false,
    };
  },
  [actions.SAVE_CUSTOMER_FOR_LATER](state, { payload }) {
    return {
      ...state,
      savingForLater: true,
      savingForLaterError: false,
      savingForLaterSuccess: false,
      createUserCognitoId: null,
      createUserId: null,
      createCustomerError: null,
    };
  },
  [actions.SAVE_CUSTOMER_FOR_LATER_SUCCESS](state, { payload }) {
    return {
      ...state,
      savingForLater: false,
      savingForLaterSuccess: true,
      createUserCognitoId: payload.cognito_id,
      createUserId: payload.user_id,
    };
  },
  [actions.SAVE_CUSTOMER_FOR_LATER_FAIL](state, { payload }) {
    return {
      ...state,
      savingForLater: false,
      savingForLaterError: true,
      createCustomerError: payload,
    };
  },
  [actions.CLEAR_SAVE_FOR_LATER_INFO](state) {
    return {
      ...state,
      savingForLater: false,
      savingForLaterSuccess: false,
      createUserCognitoID: null,
      savedUserId: null,
      savedCustomerDetails: {},
    };
  },
  [actions.GET_CUSTOMER_CLINICIANS](state, { payload }) {
    return {
      ...state,
      cliniciansLoading: true,
    };
  },
  [actions.GET_CUSTOMER_CLINICIANS_SUCCESS](state, { payload }) {
    return {
      ...state,
      cliniciansLoading: false,
      customer: {
        ...state.customer,
        clinician: payload.clinician,
        secondaryClinician: payload.secondary_clinician,
      },
    };
  },
  [actions.GET_CUSTOMER_CLINICIANS_FAIL](state, { payload }) {
    return {
      ...state,
      cliniciansLoading: false,
    };
  },
  [actions.GET_CUSTOMER_DOCUMENTS](state, { payload }) {
    return {
      ...state,
      documentsLoading: true,
    };
  },
  [actions.GET_CUSTOMER_DOCUMENTS_SUCCESS](state, { payload }) {
    return {
      ...state,
      documentsLoading: false,
      customer: {
        ...state.customer,
        documents: payload,
      },
    };
  },
  [actions.GET_CUSTOMER_DOCUMENTS_FAIL](state, { payload }) {
    return {
      ...state,
      documentsLoading: false,
    };
  },
  [actions.GET_CUSTOMER_TREATMENT_PLANS](state, { payload }) {
    return {
      ...state,
      treatmentPlansLoading: true,
    };
  },
  [actions.GET_CUSTOMER_TREATMENT_PLANS_SUCCESS](state, { payload }) {
    return {
      ...state,
      treatmentPlansLoading: false,
      customer: {
        ...state.customer,
        treatmentPlans: payload,
      },
    };
  },
  [actions.GET_CUSTOMER_TREATMENT_PLANS_FAIL](state, { payload }) {
    return {
      ...state,
      treatmentPlansLoading: false,
    };
  },
  [actions.SET_AUTH_CODES_LOADING](state, { payload }) {
    return {
      ...state,
      authorizationCodesLoading: payload,
    };
  },
  [actions.GET_AUTHORIZATION_CODES](state) {
    return {
      ...state,
      authorizationCodesLoading: true,
    };
  },
  [actions.GET_AUTHORIZATION_CODES_SUCCESS](state) {
    return {
      ...state,
      authorizationCodesLoading: false,
    };
  },
  [actions.GET_CUSTOMER_APPROVED_UNITS](state, { payload }) {
    return {
      ...state,
      approvedUnitsLoading: true,
    };
  },
  [actions.GET_CUSTOMER_APPROVED_UNITS_SUCCESS](state, { payload }) {
    return {
      ...state,
      approvedUnitsLoading: false,
      customer: {
        ...state.customer,
        approvedUnits: payload,
      },
    };
  },
  [actions.GET_CUSTOMER_APPROVED_UNITS_FAIL](state, { payload }) {
    return {
      ...state,
      approvedUnitsLoading: false,
    };
  },
  [actions.GET_CUSTOMER_UPCOMING_CALLS](state, { payload }) {
    return {
      ...state,
      videoCallsLoading: true,
    };
  },
  [actions.GET_CUSTOMER_UPCOMING_CALLS_SUCCESS](state, { payload }) {
    let sortedVideoCalls = payload.sort((a, b) => {
      var dateA = new Date(a.scheduled_date);
      var dateB = new Date(b.scheduled_date);
      return dateA - dateB;
    });
    return {
      ...state,
      videoCallsLoading: false,
      customer: {
        ...state.customer,
        upcomingVideoCalls: sortedVideoCalls,
      },
    };
  },
  [actions.GET_CUSTOMER_PHYSICIANS](state, { payload }) {
    return {
      ...state,
      physiciansLoading: true,
    };
  },
  [actions.GET_CUSTOMER_PHYSICIANS_SUCCESS](state, { payload }) {
    return {
      ...state,
      physiciansLoading: false,
      customer: {
        ...state.customer,
        physicians: payload,
      },
    };
  },
  [actions.GET_CUSTOMER_PHYSICIANS_FAIL](state, { payload }) {
    return {
      ...state,
      physiciansLoading: false,
    };
  },
  [actions.GET_CUSTOMER_PAST_CLINICIANS](state, { payload }) {
    return {
      ...state,
      pastCliniciansLoading: true,
    };
  },
  [actions.GET_CUSTOMER_PAST_CLINICIANS_SUCCESS](state, { payload }) {
    return {
      ...state,
      pastCliniciansLoading: false,
      customer: {
        ...state.customer,
        pastClinicians: payload,
      },
    };
  },
  [actions.GET_CUSTOMER_PAST_CLINICIANS_FAIL](state, { payload }) {
    return {
      ...state,
      pastCliniciansLoading: false,
    };
  },
  [actions.GET_SMS_THREAD_BY_CLIENT_SUCCESS](state, { payload }) {
    return {
      ...state,
      customer: {
        ...state.customer,
        smsThread: payload,
      },
    };
  },
  [actions.CLEAR_SMS_THREAD](state) {
    return {
      ...state,
      customer: {
        ...state.customer,
        smsThread: [],
      },
    };
  },
  [actions.SET_CUSTOMERS_BILLABLE_TIME_PERIOD](state, { payload }) {
    return {
      ...state,
      billableTimePeriod: payload,
    };
  },
  [actions.SET_CUSTOMER_BILLABLE_TIME](state, { payload }) {
    let customersList = [...state.customers];
    let customerIndex = customersList.findIndex(
      (cust) =>
        (cust.user_id ? cust.user_id == payload.userId : cust.id == payload.userId) &&
        cust.billableTime.loading
    );
    if (customersList[customerIndex]) {
      customersList[customerIndex].billableTime = payload;
    }
    return {
      ...state,
      customers: customersList,
    };
  },
  [actions.CREATE_UNSIGNED_DOCUMENT_REQUEST](state) {
    return {
      ...state,
      documentRequestLoading: true,
      documentRequestSuccess: false,
    };
  },
  [actions.CREATE_UNSIGNED_DOCUMENT_REQUEST_SUCCESS](state) {
    return {
      ...state,
      documentRequestLoading: false,
      documentRequestSuccess: true,
    };
  },
  [actions.CREATE_UNSIGNED_DOCUMENT_REQUEST_FAIL](state) {
    return {
      ...state,
      documentRequestLoading: false,
    };
  },
  [actions.CLEAR_DOCUMENT_REQUEST_SUCCESS](state) {
    return {
      ...state,
      documentRequestSuccess: false,
    };
  },
  [actions.GET_ALL_DOCUMENT_REQUESTS_SUCCESS](state, { payload }) {
    return {
      ...state,
      documentRequests: payload,
    };
  },
  [actions.GET_SINGLE_CUSTOMER_SEARCH](state) {
    return {
      ...state,
      searchLoading: true,
    };
  },
  [actions.GET_SINGLE_CUSTOMER_SEARCH_SUCCESS](state, { payload }) {
    return {
      ...state,
      searchLoading: false,
      searchResults: payload,
    };
  },
  [actions.GET_SINGLE_CUSTOMER_SEARCH_FAIL](state) {
    return {
      ...state,
      searchLoading: false,
    };
  },
  [actions.CLEAR_CUSTOMER_SEARCH](state) {
    return {
      ...state,
      searchResults: [],
    };
  },
  [actions.GET_CUSTOMER_DEACTIVATION_REASONS_SUCCESS](state, { payload }) {
    return {
      ...state,
      deactivationReasons: payload,
    };
  },
  [actions.GET_CUSTOMER_DEPARTURE_OPTIONS_SUCCESS](state, { payload }) {
    return {
      ...state,
      customerDepartureOptions: payload,
    };
  },
  [actions.GET_CUSTOMER_STATUS_UPDATES](state) {
    return {
      ...state,
      statusUpdatesLoading: true,
      statusUpdatesSuccess: false,
    };
  },
  [actions.GET_CUSTOMER_STATUS_UPDATES_SUCCESS](state, { payload }) {
    return {
      ...state,
      statusUpdatesLoading: false,
      statusUpdatesSuccess: true,
      customer: {
        ...state.customer,
        statusUpdates: payload,
      },
    };
  },
  [actions.GET_CUSTOMER_STATUS_UPDATES_FAIL](state) {
    return {
      ...state,
      statusUpdatesLoading: false,
      statusUpdatesSuccess: false,
    };
  },
  [actions.GET_SAVED_FOR_LATER_CUSTOMERS](state) {
    return {
      ...state,
      savedCustomersLoading: true,
      savedCustomersError: false,
    };
  },
  [actions.GET_SAVED_FOR_LATER_CUSTOMERS_SUCCESS](state, { payload }) {
    return {
      ...state,
      savedCustomersLoading: false,
      savedCustomers: payload.customers,
      count: payload.count,
    };
  },
  [actions.GET_SAVED_FOR_LATER_CUSTOMERS_FAIL](state) {
    return {
      ...state,
      savedCustomersLoading: false,
    };
  },
  [actions.GET_SAVED_CUSTOMER_DETAILS](state) {
    return {
      ...state,
      savedCustomersLoading: true,
      savedCustomersError: false,
      savingForLaterSuccess: false,
      savingForLaterError: false,
    };
  },
  [actions.GET_SAVED_CUSTOMER_DETAILS_SUCCESS](state, { payload }) {
    return {
      ...state,
      savedCustomersLoading: false,
      savedCustomerDetails: payload,
    };
  },
  [actions.GET_SAVED_CUSTOMER_DETAILS_FAIL](state) {
    return {
      ...state,
      savedCustomersLoading: false,
      savedCustomersError: true,
    };
  },
  [actions.SAVE_LEAD](state) {
    return {
      ...state,
      savingLead: true,
      saveLeadSuccess: false,
    };
  },
  [actions.SAVE_LEAD_SUCCESS](state) {
    return {
      ...state,
      savingLead: false,
      saveLeadSuccess: true,
    };
  },
  [actions.SAVE_LEAD_FAIL](state) {
    return {
      ...state,
      savingLead: false,
    };
  },
  [actions.UPDATE_PATIENT_INFO](state) {
    return {
      ...state,
      updatingCustomerInfo: true,
    };
  },
  [actions.UPDATE_PATIENT_INFO_SUCCESS](state) {
    return {
      ...state,
      updatingCustomerInfo: false,
      updatingCustomerInfoSuccess: true,
    };
  },
  [actions.UPDATE_PATIENT_INFO_FAIL](state) {
    return {
      ...state,
      updatingCustomerInfo: false,
      updatingCustomerInfoSuccess: false,
    };
  },
  [actions.UPDATE_GUARDIAN_INFO](state) {
    return {
      ...state,
      updatingCustomerInfo: true,
    };
  },
  [actions.UPDATE_GUARDIAN_INFO_SUCCESS](state) {
    return {
      ...state,
      updatingCustomerInfo: false,
      updatingCustomerInfoSuccess: true,
    };
  },
  [actions.UPDATE_GUARDIAN_INFO_FAIL](state) {
    return {
      ...state,
      updatingCustomerInfo: false,
      updatingCustomerInfoSuccess: false,
    };
  },
  [actions.SAVE_CUSTOMER_PHYSICIAN](state) {
    return {
      ...state,
      savingCustomerPhysician: true,
    };
  },
  [actions.SAVE_CUSTOMER_PHYSICIAN_SUCCESS](state) {
    return {
      ...state,
      savingCustomerPhysician: false,
      savingCustomerPhysicianSuccess: true,
    };
  },
  [actions.SAVE_CUSTOMER_PHYSICIAN_FAIL](state) {
    return {
      ...state,
      savingCustomerPhysician: false,
      savingCustomerPhysicianError: true,
    };
  },
  [actions.UPDATE_CUSTOMER_PHYSICIAN](state) {
    return {
      ...state,
      updatingCustomerInfo: true,
    };
  },
  [actions.UPDATE_CUSTOMER_PHYSICIAN_SUCCESS](state) {
    return {
      ...state,
      updatingCustomerInfo: false,
      updatingCustomerInfoSuccess: true,
    };
  },
  [actions.UPDATE_CUSTOMER_PHYSICIAN_FAIL](state) {
    return {
      ...state,
      updatingCustomerInfo: false,
      updatingCustomerInfoSuccess: false,
    };
  },
  [actions.UPDATE_INTAKE_HISTORY_INFO](state) {
    return {
      ...state,
      updatingCustomerInfo: true,
    };
  },
  [actions.UPDATE_INTAKE_HISTORY_INFO_SUCCESS](state) {
    return {
      ...state,
      updatingCustomerInfo: false,
      updatingCustomerInfoSuccess: true,
    };
  },
  [actions.UPDATE_INTAKE_HISTORY_INFO_FAIL](state) {
    return {
      ...state,
      updatingCustomerInfo: false,
      updatingCustomerInfoSuccess: false,
    };
  },
  [actions.GET_CUSTOMER_BILLABLE_ITEMS](state) {
    return {
      ...state,
      billableItems: [],
      billableItemsLoading: true,
      billableItemsCount: 0,
    };
  },
  [actions.GET_CUSTOMER_BILLABLE_ITEMS_SUCCESS](state, { payload }) {
    return {
      ...state,
      billableItems: payload.billableItems,
      billableItemsCount: payload.count,
      billableItemsLoading: false,
    };
  },
  [actions.GET_CUSTOMER_BILLABLE_ITEMS_FAIL](state) {
    return {
      ...state,
      billableItemsLoading: false,
    };
  },
  [actions.SET_CUSTOMER_BILLABLE_ITEMS_PAGE](state, { payload }) {
    return {
      ...state,
      billableItemsPage: payload,
    };
  },
  [actions.SET_CUSTOMER_BILLABLE_ITEMS_ROWS_PER_PAGE](state, { payload }) {
    return {
      ...state,
      billableItemsRowsPerPage: payload,
    };
  },
  [actions.SWITCH_PRIMARY_ACCOUNT_HOLDER](state) {
    return {
      ...state,
      primaryAccountHolderSwitchLoading: true,
      primaryAccountHolderSwitchSuccess: false,
    };
  },
  [actions.SWITCH_PRIMARY_ACCOUNT_HOLDER_SUCCESS](state) {
    return {
      ...state,
      primaryAccountHolderSwitchLoading: false,
      primaryAccountHolderSwitchSuccess: true,
    };
  },
  [actions.SWITCH_PRIMARY_ACCOUNT_HOLDER_FAIL](state) {
    return {
      ...state,
      primaryAccountHolderSwitchLoading: false,
    };
  },
  [actions.UPDATE_CUSTOMER_EMAIL](state) {
    return {
      ...state,
      updateCustomerEmailLoading: true,
      updateCustomerEmailSuccess: false,
      updateCustomerEmailError: false,
      updateCustomerEmailErrorMessage: null,
    };
  },
  [actions.UPDATE_CUSTOMER_EMAIL_SUCCESS](state, { payload }) {
    return {
      ...state,
      updateCustomerEmailLoading: false,
      updateCustomerEmailSuccess: true,
      customer: {
        ...state.customer,
        details: payload,
      },
    };
  },
  [actions.UPDATE_CUSTOMER_EMAIL_FAIL](state, { payload }) {
    return {
      ...state,
      updateCustomerEmailLoading: false,
      updateCustomerEmailError: true,
      updateCustomerEmailErrorMessage: payload,
    };
  },
  [actions.UPDATE_CUSTOMER_TIMEZONE](state) {
    return {
      ...state,
      updateCustomerTimezoneLoading: true,
      updateCustomerTimezoneSuccess: false,
      updateCustomerTimezoneError: false,
    };
  },
  [actions.UPDATE_CUSTOMER_TIMEZONE_SUCCESS](state, { payload }) {
    return {
      ...state,
      updateCustomerTimezoneLoading: false,
      updateCustomerTimezoneSuccess: true,
      customer: {
        ...state.customer,
        details: payload,
      },
    };
  },
  [actions.UPDATE_CUSTOMER_TIMEZONE_FAIL](state, { payload }) {
    return {
      ...state,
      updateCustomerTimezoneLoading: false,
      updateCustomerTimezoneError: true,
    };
  },
  [actions.CLEAR_TIMEZONE_INFO](state) {
    return {
      ...state,
      updateCustomerTimezoneLoading: false,
      updateCustomerTimezoneSuccess: false,
      updateCustomerTimezoneError: false,
    };
  },
  [actions.GET_CUSTOMER_PAST_CALLS](state, { payload }) {
    return {
      ...state,
      pastCallsLoading: true,
    };
  },
  [actions.GET_CUSTOMER_PAST_CALLS_SUCCESS](state, { payload }) {
    return {
      ...state,
      pastCallsLoading: false,
      pastCalls: payload,
    };
  },
  [actions.GET_CUSTOMER_PAST_CALLS_FAIL](state, { payload }) {
    return {
      ...state,
      pastCallsLoading: false,
    };
  },
  [actions.ENABLE_CLIENT_STATIC_PAGE](state) {
    return {
      ...state,
      enableStaticPageLoading: true,
      enableStaticPageSuccess: false,
      enableStaticPageError: false,
    };
  },
  [actions.ENABLE_CLIENT_STATIC_PAGE_SUCCESS](state) {
    return {
      ...state,
      enableStaticPageLoading: false,
      enableStaticPageSuccess: true,
      customer: {
        ...state.customer,
        details: {
          ...state.customer.details,
          allow_static_page: true,
        },
      },
    };
  },
  [actions.ENABLE_CLIENT_STATIC_PAGE_FAIL](state) {
    return {
      ...state,
      enableStaticPageLoading: false,
      enableStaticPageSuccess: false,
      enableStaticPageError: true,
    };
  },
  [actions.DISABLE_CLIENT_STATIC_PAGE](state) {
    return {
      ...state,
      enableStaticPageLoading: true,
      enableStaticPageSuccess: false,
      enableStaticPageError: false,
    };
  },
  [actions.DISABLE_CLIENT_STATIC_PAGE_SUCCESS](state) {
    return {
      ...state,
      enableStaticPageLoading: false,
      enableStaticPageSuccess: true,
      customer: {
        ...state.customer,
        details: {
          ...state.customer.details,
          allow_static_page: false,
        },
      },
    };
  },
  [actions.DISABLE_CLIENT_STATIC_PAGE_FAIL](state) {
    return {
      ...state,
      enableStaticPageLoading: false,
      enableStaticPageSuccess: false,
      enableStaticPageError: true,
    };
  },
  [actions.GET_CLIENT_TASK_LIST](state) {
    return {
      ...state,
      clientTaskList: [],
      clientTaskListLoading: true,
    };
  },
  [actions.GET_CLIENT_TASK_LIST_SUCCESS](state, { payload }) {
    return {
      ...state,
      clientTaskList: payload,
      clientTaskListLoading: false,
    };
  },
  [actions.GET_CLIENT_TASK_LIST_FAIL](state) {
    return {
      ...state,
      clientTaskListLoading: false,
    };
  },
  [actions.MARK_CLIENT_TASK_COMPLETE](state) {
    return {
      ...state,
      markTaskCompleteLoading: true,
      markTaskCompleteSuccess: false,
      markTaskCompleteError: false,
    };
  },
  [actions.MARK_CLIENT_TASK_COMPLETE_SUCCESS](state) {
    return {
      ...state,
      markTaskCompleteLoading: false,
      markTaskCompleteSuccess: true,
    };
  },
  [actions.MARK_CLIENT_TASK_COMPLETE_FAIL](state) {
    return {
      ...state,
      markTaskCompleteLoading: false,
      markTaskCompleteError: true,
    };
  },
  [actions.GET_CLIENT_VIDEO_SESSION_STATISTICS](state) {
    return {
      ...state,
      statisticsLoading: true,
    };
  },
  [actions.GET_CLIENT_VIDEO_SESSION_STATISTICS_SUCCESS](state, { payload }) {
    return {
      ...state,
      statisticsLoading: false,
      clientSessionStatistics: payload,
    };
  },
  [actions.GET_CLIENT_VIDEO_SESSION_STATISTICS_FAIL](state) {
    return {
      ...state,
      statisticsLoading: false,
    };
  },
  [actions.GET_CLIENT_CRITICAL_NEEDS_SUCCESS](state, { payload }) {
    return {
      ...state,
      criticalNeeds: payload,
    };
  },
  [actions.SNOOZE_CLIENT_TASK](state) {
    return {
      ...state,
      snoozeTaskLoading: true,
      snoozeTaskError: false,
    };
  },
  [actions.SNOOZE_CLIENT_TASK_SUCCESS](state) {
    return {
      ...state,
      snoozeTaskLoading: false,
    };
  },
  [actions.SNOOZE_CLIENT_TASK_FAIL](state) {
    return {
      ...state,
      snoozeTaskLoading: false,
      snoozeTaskError: true,
    };
  },
  [actions.UPDATE_LINK_PHYSICIAN](state) {
    return {
      ...state,
      updatingLinkPhysician: true,
    };
  },
  [actions.UPDATE_LINK_PHYSICIAN_SUCCESS](state) {
    return {
      ...state,
      updatingLinkPhysician: false,
      updatingLinkPhysicianSuccess: true,
    };
  },
  [actions.UPDATE_LINK_PHYSICIAN_FAIL](state) {
    return {
      ...state,
      updatingLinkPhysician: false,
      updatingLinkPhysicianSuccess: false,
    };
  },
  [actions.GET_ALL_PHYSICIANS](state) {
    return {
      ...state,
      allPhysiciansLoading: true,
    };
  },
  [actions.GET_ALL_PHYSICIANS_SUCCESS](state, { payload }) {
    return {
      ...state,
      allPhysiciansLoading: false,
      allPhysicians: payload,
    };
  },
  [actions.GET_ALL_PHYSICIANS_FAIL](state) {
    return {
      ...state,
      allPhysiciansLoading: false,
    };
  },
  [actions.UPLOAD_CUSTOMER_DOCUMENT](state) {
    return {
      ...state,
      uploadDocumentLoading: true,
      uploadDocumentSuccess: false,
    };
  },
  [actions.UPLOAD_CUSTOMER_DOCUMENT_SUCCESS](state) {
    return {
      ...state,
      uploadDocumentSuccess: true,
      uploadDocumentLoading: false,
    };
  },
  [actions.UPLOAD_CUSTOMER_DOCUMENT_FAIL](state) {
    return {
      ...state,
      uploadDocumentLoading: false,
    };
  },
};

export default createReducer(initialState, reducers);
