import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import Logo from "../../assets/answers_now_butterfly_logo_no_letters.png";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const WelcomeMessage = ({ classes, welcomeDialogOpen, closeDialog }) => {
  return (
    <Dialog
      open={welcomeDialogOpen}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="welcome-message-form"
      PaperProps={{
        style: {
          width: 400,
          maxWidth: "95%",
        },
      }}
    >
      <DialogTitle className={classes.welcomeTitleContainer}>
        <img className={classes.welcomeLogo} src={Logo} alt="AnswersNow" title="AnswersNow" />
        <Typography component="h1" className={classes.welcomeTitle}>
          Welcome to AnswersNow!
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.welcomeTextContainer}>
        <Typography component="p">
          This is your Dashboard. Here you will see upcoming therapy sessions, notifications
          regarding your account, and where you are in the insurance approval process.
        </Typography>
      </DialogContent>
      <DialogActions className={classes.welcomeDialogActionsContainer}>
        <Button onClick={closeDialog} style={{ width: "100%" }}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default WelcomeMessage;
