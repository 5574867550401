import { useTheme } from "@emotion/react";
import { Stack, Typography } from "@mui/material";
import React from "react";

export default function ANItemBox({ title, subtitle, rightSlot, subtitleProps }) {
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      padding={theme.spacing(5, 7)}
      border={`1px solid ${theme.palette.divider}`}
      borderRadius="6px"
      width="100%"
      justifyContent="space-between"
      alignItems="center"
    >
      <Stack>
        <Typography sx={{ fontWeight: 500 }}>{title}</Typography>
        <Typography variant="body2" color="text.secondary" {...subtitleProps}>
          {subtitle}
        </Typography>
      </Stack>
      {rightSlot}
    </Stack>
  );
}
