import React from "react";
import { Box, Typography } from "@mui/material";
import { PencilSimpleLine } from "@phosphor-icons/react";
import Button from "@mui/material/Button";

export const PageTitle = ({
  pageTitle,
  contextButtons,
  hideDivider,
  onEditButton,
  editButtonAction,
}) => {
  return pageTitle ? (
    <Box
      width={"100%"}
      display={"flex"}
      flexDirection={"row"}
      justifyContent={"flex-start"}
      marginBottom={hideDivider ? "0px" : "16px"}
    >
      <Box
        width={"100%"}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography
          sx={{
            color: "var(--text-primary, #31333A)",
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontSize: "34px",
            fontStyle: "normal",
            fontWeight: 600, // numeric values are acceptable for font-weight
            lineHeight: "123.5%", // equivalent to 123.5% which computes to 41.99px
            letterSpacing: "0.25px",
          }}
        >
          {pageTitle}
        </Typography>
        {contextButtons}
        {onEditButton && (
          <Button
            color="secondary"
            size="medium"
            variant="contained"
            startIcon={<PencilSimpleLine />}
            onClick={editButtonAction}
          >
            Edit
          </Button>
        )}
      </Box>
    </Box>
  ) : null;
};
