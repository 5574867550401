import React from "react";
import { withRouter } from "react-router";
import { withStyles } from "@mui/styles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import styles from "../../theme/globalStyles";
import { format } from "date-fns";
import { daysBetween } from "../../utils/daysBetween";
import { CustomTable } from "elements/DEPRECATED_elements";
import { CustomHeaderCell } from "elements/DEPRECATED_elements";

const Chats = (props) => {
  const {
    classes,
    handleChangePage,
    handleChangeRowsPerPage,
    handleSortClick,
    viewChatDetails,
    page,
    count,
    rowsPerPage,
    sort,
    direction,
  } = props;

  let rows = props.chats;

  const formatParticipants = (participants) => {
    return participants.map((participant, index) => (
      <div key={`participant-${index}`}>{participant}</div>
    ));
  };

  return (
    <CustomTable
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      noResultsMessage="No Chats Found"
      headerCells={[
        <CustomHeaderCell
          label="ID"
          sortable={true}
          sortDirection={sort === "id" || !sort ? direction : false}
          active={sort === "id" || !sort}
          onClick={() => handleSortClick("id")}
        />,
        <CustomHeaderCell label="Participants" />,
        <CustomHeaderCell
          label="Last Clinician Message"
          sortable={true}
          sortDirection={sort === "last_clinician_message" ? direction : false}
          active={sort === "last_clinician_message"}
          onClick={() => handleSortClick("last_clinician_message")}
        />,
        <CustomHeaderCell
          label="Last Customer Message"
          sortable={true}
          sortDirection={sort === "last_customer_message" ? direction : false}
          active={sort === "last_customer_message"}
          onClick={() => handleSortClick("last_customer_message")}
        />,
        <CustomHeaderCell
          label="Chat Started"
          sortable={true}
          sortDirection={sort === "started_on" ? direction : false}
          active={sort === "started_on"}
          onClick={() => handleSortClick("started_on")}
        />,
        <CustomHeaderCell label="Active?" />,
      ]}
    >
      {rows.map((row) => (
        <TableRow
          key={row.id}
          className={classes.clickableRow}
          onClick={() => viewChatDetails(row.id)}
          hover
        >
          <TableCell component="th" scope="row">
            {row.id}
          </TableCell>
          <TableCell align="left">{formatParticipants(row.member)}</TableCell>
          <TableCell align="left">
            {row.last_clinician_message ? `${daysBetween(row.last_clinician_message)} days` : ""}
          </TableCell>
          <TableCell align="left">
            {row.last_customer_message ? `${daysBetween(row.last_customer_message)} days` : ""}
          </TableCell>
          <TableCell align="left">
            {format(new Date(row.started_on), "PP", {
              awareOfUnicodeTokens: true,
            })}
          </TableCell>
          <TableCell align="left">{row.is_active ? "Yes" : "No"}</TableCell>
        </TableRow>
      ))}
    </CustomTable>
  );
};

export default withRouter(withStyles(styles)(Chats));
