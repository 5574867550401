import { useEffect } from "react";

/**
 * Custom hook to handle mouse events for video player controls.
 *
 * @param {React.RefObject} videoContainerRef - Reference to the video container element.
 * @param {Function} handleControlsVisibility - Function to show or hide the video controls.
 * @param {Function} toggleFullscreen - Function to toggle fullscreen mode.
 * @param {Function} handlePlayPause - Function to handle play/pause functionality.
 * @returns {Object} - Contains the handleSingleClick function.
 */
const useMouseEvents = (
  videoContainerRef,
  handleControlsVisibility,
  toggleFullscreen,
  handlePlayPause
) => {
  useEffect(() => {
    /**
     * Show the controls when the mouse is moved over the video container.
     */
    const handleMouseMove = () => {
      handleControlsVisibility(true);
    };

    if (videoContainerRef.current) {
      videoContainerRef.current.addEventListener("mousemove", handleMouseMove);
    }

    return () => {
      if (videoContainerRef.current) {
        videoContainerRef.current.removeEventListener("mousemove", handleMouseMove);
      }
    };
  }, [handleControlsVisibility, videoContainerRef]);

  useEffect(() => {
    /**
     * Toggle fullscreen mode when the video container is double-clicked.
     */
    const handleDoubleClick = () => {
      toggleFullscreen();
    };

    if (videoContainerRef.current) {
      videoContainerRef.current.addEventListener("dblclick", handleDoubleClick);
    }

    return () => {
      if (videoContainerRef.current) {
        videoContainerRef.current.removeEventListener("dblclick", handleDoubleClick);
      }
    };
  }, [toggleFullscreen, videoContainerRef]);

  /**
   * Handle single click to play or pause the video.
   *
   * @param {React.MouseEvent} event - The mouse event.
   */
  const handleSingleClick = (event) => {
    // Prevent interaction with ReactPlayer itself
    event.stopPropagation();
    handlePlayPause();
  };

  return {
    handleSingleClick,
  };
};

export default useMouseEvents;
