import { buildUrl } from "utils/buildUrl";
import SecureRequest from "utils/securerequest";

export const getTeams = async (query) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.get(buildUrl(`/v3/teams`, query), {});
};

export const getTeamMembers = async (query) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.get(buildUrl(`/v3/team-members`, query), {});
};

export const getTeamRoles = async (query) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.get(buildUrl(`/v3/team-roles`, query), {});
};

export const removeTeamMember = async (teamId, userId) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.delete(`/v3/team-members/${teamId}/members/${userId}`, {});
};

export const inviteMember = async (teamId, params) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.post(`/v3/team-members/${teamId}/invite-member`, params);
};

/**
 * The function `getTeamInvitation` retrieves a team invitation using the provided invitation ID and
 * guid.
 * @param {string|number} invitationId - The `invitationId` parameter is the unique identifier of the team invitation
 * that you want to retrieve.
 * @param {Object} query - The `query` object likely contains additional parameters needed to retrieve the team invitation.
 * @param {string} query.guid - The `guid` parameter is used to identify the team invitation.
 * @returns {Promise} The result of making a GET request to the endpoint `/v3/user-invitations/[invitationId]`
 */
export const getTeamMemberInvitation = async (query) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const { invitationId } = query;
  return await Request.get(buildUrl(`/v3/team-invitations/${invitationId}`, query));
};

export const deleteTeamMemberInvitation = async (invitationGuid) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.delete(`/v3/user-invitations/${invitationGuid}`);
};

/**
 * The function `saveAcceptInvitationMember` sends a PUT request to accept a user invitation using the
 * provided parameters.
 * @param {Object} payload - The `payload` object likely contains information needed to accept a user invitation.
 * @param {string|number} payload.id - The `id` parameter is used to identify the user invitation.
 * @param {string} payload.guid - The `guid` parameter is used to identify the user invitation.
 * @returns {Promise} The result of making a PUT request to the endpoint `/v3/user-invitations/[id]/accept`
 */
export const saveAcceptInvitationMember = async (payload) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const { id } = payload;

  return await Request.put(`/v3/team-invitations/${id}/accept`, payload);
};

/**
 * The function `saveNewTeamMember` sends a POST request to add a new team member to a specified team.
 * @param {Object} params - The `params` object likely contains information needed to add a new
 * team member to a specific team.
 * @param {string|number} params.teamId - The `teamId` is used to identify the team
 * @returns {Promise} The result of making a POST request to the
 * endpoint `/v3/team-members/[teamId]/add` with the provided `params`.
 */
export const saveNewTeamMember = async (params) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const { teamId } = params;
  // TODO: Define the final Endpoint
  return await Request.post(`/v3/team-members/${teamId}/add`, params);
};

/**
 * The function `updateTeamUser` sends a PUT request to update a team member's information.
 * @param {Object} params - The `params` object likely contains information needed to update a team member.
 * @param {string|number} params.teamId - The `teamId` parameter is used to identify the team.
 * @param {string|number} params.userId - The `userId` parameter is used to identify the user.
 * @param {Object} params.data - The `data` parameter contains the updated information for the team member.
 * @returns The `updateTeamUser` function is returning the result of a PUT request made to the
 * endpoint `/v3/users/[userId]/update` with the provided `data`.
 */
export const updateTeamUser = async (params) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const { userId, data } = params;

  if (!userId) {
    console.error("userId is required");
    return;
  }

  return await Request.put(`/v3/users/${userId}/update`, data);
};

export const updateTeamMembers = async (teamId, params) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.patch(`/v3/team-members/${teamId}/bulk`, params);
};
