import React, { useState } from "react";
import withStyles from "@mui/styles/withStyles";
import styles from "./styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import { Tooltip } from "@mui/material";

const NavItem = (props) => {
  const [open, setOpen] = useState(false);

  const handleClickCollapse = () => {
    setOpen(!open);
  };

  const { classes, navigate, page, drawerOpen, history } = props;

  const url = history?.location?.pathname;
  let isCurrentPage = url?.includes(page.url);
  if (
    (page.url === "/dashboard" || (page.url === "/insurance" && !url.includes("/insurance/"))) &&
    url !== page.url
  ) {
    isCurrentPage = false;
  }
  return (
    <ListItem
      key={page.title}
      disablePadding
      onClick={(e) => (page.url ? navigate(page.url) : handleClickCollapse())}
      sx={{ display: "block", padding: drawerOpen ? "0 16px" : "0" }}
    >
      <Tooltip title={drawerOpen ? "" : page.title} placement="right">
        <ListItemButton
          sx={{
            minHeight: 36,
            justifyContent: drawerOpen ? "initial" : "center",
            padding: drawerOpen ? "0 16px" : "0 8px",
            margin: drawerOpen ? "0" : "0 8px",
            borderRadius: 2,
            backgroundColor: isCurrentPage ? "#391A51" : "#552879",
            color: isCurrentPage ? "#FFFFFF" : "#D2B3EA",
            "&:hover": {
              color: "#FFFFFF",
              backgroundColor: "#391A51",
            },
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: drawerOpen ? 1 : "auto",
              ml: drawerOpen ? 0 : "6px",
              "&:hover": {
                color: "#FFFFFF",
              },
            }}
          >
            {page.title === "Chat" && props.unreadMessageCount > 0 && (
              <div
                className={classes.unreadMessageCount}
                style={{
                  marginLeft: drawerOpen ? 0 : 6,
                }}
              />
            )}
            {isCurrentPage ? page.iconSelected : page.icon}
          </ListItemIcon>
          <ListItemText
            primary={page.title}
            primaryTypographyProps={{ variant: "body2" }}
            sx={{ display: drawerOpen ? "flex" : "none" }}
          />
          {page.pages?.length > 0 &&
            drawerOpen &&
            (open ? (
              <ExpandLess style={{ fill: "#D2B3EA" }} />
            ) : (
              <ExpandMore style={{ fill: "#D2B3EA" }} />
            ))}
        </ListItemButton>
      </Tooltip>
      {page.pages?.length > 0 && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List>
            {page.pages.map((p, i) => {
              return (
                <Tooltip title={drawerOpen ? "" : p.title} key={i} placement="right">
                  <ListItemButton
                    onClick={() => navigate(p.url)}
                    sx={{
                      minHeight: 36,
                      justifyContent: "initial",
                      padding: "0 16px 0 20px",
                      borderRadius: 2,
                      backgroundColor: "#552879",
                      color: "#D2B3EA",
                      "&:hover": {
                        color: "#FFFFFF",
                        backgroundColor: "#391A51",
                      },
                    }}
                  >
                    <ListItemIcon>{p.icon}</ListItemIcon>
                    <ListItemText primary={p.title} primaryTypographyProps={{ variant: "body2" }} />
                  </ListItemButton>
                </Tooltip>
              );
            })}
          </List>
        </Collapse>
      )}
    </ListItem>
  );
};

export default withStyles(styles)(NavItem);
