const ACTIONS = {
  CHECK_AUTH_STATE: "CHECK_AUTH_STATE",
  GET_AUTH_STATE: "GET_AUTH_STATE",
  SET_AUTH_STATE: "SET_AUTH_STATE",
};

const ACTION_CREATORS = {
  checkAuthState() {
    return {
      type: ACTIONS.CHECK_AUTH_STATE,
    };
  },
  getAuthState() {
    return {
      type: ACTIONS.GET_AUTH_STATE,
    };
  },
  setAuthState() {
    return {
      type: ACTIONS.SET_AUTH_STATE,
    };
  },
};

export default { ...ACTIONS, ...ACTION_CREATORS };
