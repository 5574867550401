import { call, put, all, select, takeEvery } from "redux-saga/effects";
import actions from "../actions";
import { disconnectSendBird, getSendBirdConnection, getUnreadChannelCount } from "../api/sendbird";

function* getSendbirdConnectionAsync({ payload }) {
  try {
    const connection = yield call(getSendBirdConnection, payload);
    yield put(actions.getSendbirdConnectionSuccess(connection));
    yield put(actions.getUnreadChannelCount(connection));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "SENDBIRD" }));
    yield put(actions.getSendbirdConnectionFail());
    console.error(e);
  }
}

function* disconnectSendbirdAsync({ payload }) {
  try {
    if (payload) {
      yield call(disconnectSendBird, payload);
    }
    yield put(actions.disconnectSendbirdSuccess());
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "SENDBIRD" }));
    console.error(e);
  }
}

function* getUnreadChannelCountAsync({ payload }) {
  try {
    const count = yield call(getUnreadChannelCount, payload);
    yield put(actions.getUnreadChannelCountSuccess(count));
  } catch (e) {
    yield put(actions.getUnreadChannelCountFail());
    yield put(actions.logError({ errorMessage: e, errorType: "SENDBIRD" }));
    console.error(e);
  }
}

export default function* startSaga() {
  yield takeEvery(actions.GET_SENDBIRD_CONNECTION, getSendbirdConnectionAsync);
  yield takeEvery(actions.DISCONNECT_SENDBIRD, disconnectSendbirdAsync);
  yield takeEvery(actions.GET_UNREAD_CHANNEL_COUNT, getUnreadChannelCountAsync);
}
