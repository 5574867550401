import React from "react";
import {
  Dialog,
  Typography,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/NotificationsNone";
import { Warning } from "@phosphor-icons/react";

const ExitConfirmationDialog = (props) => {
  const {
    classes,
    confirmExitDialogOpen,
    successToastOpen,
    notesCompleted,
    callBreakdownLoading,
    onToggleExitDialog,
    onSaveAsDraft,
    setAssociatedTimesLoading,
  } = props;

  return (
    <Dialog
      open={confirmExitDialogOpen && !successToastOpen && !notesCompleted}
      disableEscapeKeyDown={true}
      PaperProps={{
        style: {
          maxWidth: "95%",
          width: 500,
        },
      }}
    >
      <DialogContent>
        <div className={classes.confirmSubmitDialogContentContainer}>
          <div className={classes.confirmSubmitDialogIconContainer}>
            <Warning size={22} color="rgba(237, 108, 2, 1)" />
          </div>
          <div className={classes.confirmSubmitDialogTitleContainer}>
            <Typography
              component="p"
              variant="subtitle1"
              className={classes.confirmSubmitDialogTitle}
            >
              Are you sure you want to exit?
            </Typography>
            <Typography
              component="p"
              variant="body2"
              className={classes.confirmSubmitDialogContent}
            >
              Your session note progress will be saved.
            </Typography>
          </div>
        </div>
      </DialogContent>
      <DialogActions style={{ margin: "0px 15px 15px 0" }}>
        <Button
          onClick={onToggleExitDialog}
          color="secondary"
          disabled={callBreakdownLoading || setAssociatedTimesLoading}
        >
          Cancel
        </Button>
        <Button
          onClick={onSaveAsDraft}
          disabled={callBreakdownLoading || setAssociatedTimesLoading}
        >
          {(callBreakdownLoading || setAssociatedTimesLoading) && (
            <CircularProgress style={{ color: "#C0C8D0", marginRight: 8 }} size={16} />
          )}
          Save Progress And Exit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExitConfirmationDialog;
