export const mapRelationshipToDisplay = (relationship) => {
  switch (relationship) {
    case "caregiver":
      return "Caregiver";
    case "child":
      return "Child";
    case "dependent":
      return "Dependent";
    case "family_member":
      return "Family Member";
    case "legal_guardian":
      return "Legal Guardian";
    case "is_parent":
      return "Parent";
    case "spouse":
      return "Spouse";
    default:
      return "Unknown";
  }
};

export const formatRelationship = (relationship) => {
  return mapRelationshipToDisplay(relationship);
};
