import { select } from "redux-saga/effects";
import { getNotesFields, getQuestionnaireId } from "../selectors";
import SecureRequest from "../utils/securerequest";

export function* getNotesList(clientId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/customers/${clientId}/note-list`);
}

export function* getCustomerAnswers() {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const questionnaireId = yield select(getQuestionnaireId);
  return yield Request.get(`customers/${questionnaireId}/answers`);
}

export function* saveNotes(noteParams) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.post(`/customers/${noteParams.clientId}/log-notes`, noteParams);
}

export function* getSessionNote(billableTimeId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/v2/notes/${billableTimeId}/video-session-note`);
}

export function* getSessionNotes(videoCallId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/v2/notes/${videoCallId}/session-notes`);
}

export function* getSessionNotesByUser(clientId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/v2/notes/${clientId}/user-session-notes`);
}

export function* getOldSessionNotesByUser(clientId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const fields = yield select(getNotesFields);
  const client_id = clientId ? clientId : fields.client_id;
  return yield Request.get(`/customers/${client_id}/old-note-list`);
}

export function* getSessionNotesByClinician(signatureId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/v2/notes/${signatureId}/clinician-session-notes`);
}

export function* saveSessionNote(noteObject) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.post(`/v2/notes/${noteObject.billableTimeId}/session-note`, noteObject);
}

export function* updateSessionNote(noteObject) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.put(`/v2/notes/${noteObject.noteId}/session-note`, noteObject);
}
