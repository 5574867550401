import React from "react";
import Modal from "elements/Modal";
import UpdateIcon from "@mui/icons-material/Update";

const RefreshPageDialog = (props) => {
  const { open, onClickRefreshPage } = props;
  return (
    <Modal
      open={open}
      title="AnswersNow has been updated!"
      description="Please click below to refresh the page and ensure you are seeing the newest version of AnswersNow."
      icon={<UpdateIcon />}
      primaryActionText="Refresh"
      primaryActionOnClick={onClickRefreshPage}
    />
  );
};

export default RefreshPageDialog;
