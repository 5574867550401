import React, { Component } from "react";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import Typography from "@mui/material/Typography";
import _ from "lodash";
import Modal from "elements/Modal";

class TreatmentPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      numPages: null,
      pageArray: null,
      PDFWidth: null,
      confirmOpen: false,
    };
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages, pageArray: new Array(numPages) });
  };

  onPrevPageClick = () => {
    this.setState({ page: this.state.page - 1 }, () => {
      this.dialogContentRef.scrollTop = 0;
    });
  };

  onNextPageClick = () => {
    this.setState({ page: this.state.page + 1 }, () => {
      this.dialogContentRef.scrollTop = 0;
    });
  };

  onToggleConfirmTreatmentPlan = () => {
    this.setState({ confirmOpen: !this.state.confirmOpen });
  };

  render() {
    const {
      open,
      onToggleTreatmentPlan,
      classes,
      treatmentPlanUrl,
      messageClinicianAboutTreatmentPlan,
      onSubmitTreatmentPlanApproval,
      onToggleConfirmTreatmentPlanApproval,
      confirmTreatmentPlanOpen,
    } = this.props;
    return (
      <>
        {treatmentPlanUrl && (
          <Modal
            open={open}
            onClose={onToggleTreatmentPlan}
            maxWidth={false}
            title="Review Your Treatment Plan"
            content={
              <div
                ref={(ref) => (this.dialogContentRef = ref)}
                style={{
                  minWidth: 400,
                  minHeight: 300,
                  width: "95%",
                  height: "95%",
                }}
              >
                <Document file={treatmentPlanUrl} onLoadSuccess={this.onDocumentLoadSuccess}>
                  {this.state.numPages &&
                    _.range(this.state.numPages).map((page) => {
                      return (
                        <Page key={page} width={window.innerWidth * 0.9} pageNumber={page + 1} />
                      );
                    })}
                </Document>
              </div>
            }
            primaryActionText="APPROVE"
            primaryActionOnClick={onToggleConfirmTreatmentPlanApproval}
            secondaryActionText="Speak with clinician"
            secondaryActionOnClick={messageClinicianAboutTreatmentPlan}
            showGoBack={!!this.state.numPages}
            goBackText="Close"
            onClickGoBack={onToggleTreatmentPlan}
          />
        )}
        <Modal
          open={confirmTreatmentPlanOpen}
          onClose={onToggleConfirmTreatmentPlanApproval}
          title="Confirm and Submit"
          content={
            <div style={{ width: 450, height: "auto" }}>
              <Typography component="p">
                Please Confirm that you approve of your child's Treatment Plan. Once approved, we
                will begin the process of submitting this plan to your insurance company.
              </Typography>
            </div>
          }
          primaryActionText="Submit"
          primaryActionOnClick={onSubmitTreatmentPlanApproval}
          secondaryActionText="Cancel"
          secondaryActionOnClick={onToggleConfirmTreatmentPlanApproval}
        />
      </>
    );
  }
}

export default TreatmentPlan;
