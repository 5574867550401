import { call, put, takeEvery } from "redux-saga/effects";
import actions from "../actions";
import {
  signinCognitoUser,
  signOutCognito,
  forgotPassword,
  resetPassword,
  verify,
  migrate,
  changePassword,
  changeUserPassword,
  getInvitation,
  markInvitationUsed,
  signinCognitoUserFromOTP,
} from "../api/signin";
import cookie from "react-cookies";

function* signinUser({ payload }) {
  try {
    let currentTry = 1;
    let result;
    if (payload.otpVerified) {
      result = yield call(signinCognitoUserFromOTP, payload);
    } else {
      result = yield call(signinCognitoUser, payload, currentTry);
    }
    if (result.needMigrate) {
      const migrateResult = yield call(migrate, {
        email: payload.username,
        password: payload.password,
      });
      if (migrateResult.data.success) {
        currentTry++;
        result = yield call(signinCognitoUser, payload, currentTry);
      } else {
        if (migrateResult.data.forceForgot) {
          throw "Needs reset";
        } else {
          throw "Incorrect username or password.";
        }
      }
    }
    if (!result.email) {
      //setting email in user attributes for force pw change
      //  yield put(actions.setUserAttributes({ email: result.username }));
      yield put(actions.forcePasswordChange(result));
    } else {
      yield put(actions.setBasicDetails(result));
    }
    if (!payload.skipGetUser) {
      yield put(actions.getUser());
    }

    yield put(actions.setSignedinState(true));

    const expires = new Date();
    expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 14);
    cookie.save("username", payload.username, { path: "/", expires });
    //
    // const tracking = new EventTracking();
    // tracking.trackCustom("loginSuccess");
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
    // if (e === "Needs reset") {
    //     yield put(actions.setNeedsReset());
    //     return;
    // } else if (e.code && e.code === "UserNotConfirmedException") {
    //     yield put(actions.setNotConfirm());
    //     return;
    // }
    yield put(actions.setSigninFailure(e));
  }
}

function* signoutUser() {
  try {
    yield call(signOutCognito);
    yield put(actions.setSignedinState(false));
    yield put(actions.clearBasicDetails());
  } catch (e) {
    yield put(actions.setSignedinState(false));
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
  }
}

function* forgotPasswordAsync({ payload }) {
  try {
    yield call(forgotPassword, payload);
    yield put(actions.forgotPasswordSuccess());
  } catch (e) {
    // always show them success so they don't know if
    // email address is valid or not.
    console.log(e);
    yield put(actions.forgotPasswordSuccess());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
  }
}

function* resetPasswordAsync({ payload }) {
  try {
    yield call(resetPassword, payload);
    yield put(actions.resetPasswordSuccess());
  } catch (e) {
    yield put(actions.resetPasswordError(e));
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
  }
}

function* verifyAsync({ payload }) {
  try {
    const { email, code, username } = payload;

    const params = {
      code,
      username,
    };
    yield call(verify, email, params);
    yield put(actions.setVerified());
  } catch (e) {
    yield put(actions.setVerificationError());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
  }
}

function* changePasswordAsync({ payload }) {
  try {
    yield call(changePassword, payload);
    yield put(actions.clearPasswordChange());
    yield put(actions.resetPasswordSuccess());
  } catch (e) {
    yield put(actions.resetPasswordError());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* changeUserPasswordAsync({ payload }) {
  try {
    yield call(changeUserPassword, payload);
    yield put(actions.clearPasswordChange());
    yield put(actions.resetPasswordSuccess());
  } catch (e) {
    yield put(actions.resetPasswordError(e));
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* getInvitationAsync({ payload }) {
  try {
    const invitation = yield call(getInvitation, payload);
    yield put(actions.getLoginInvitationSuccess(invitation.data));
  } catch (e) {
    if (e.response && e.response.data) {
      yield put(actions.getLoginInvitationFail(e.response.data.error));
    }
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* markInviationUsedAsync({ payload }) {
  try {
    yield call(markInvitationUsed, payload);
    yield put(actions.markInvitationUsedSuccess());
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

export default function* signinSaga() {
  yield takeEvery(actions.START_SIGNIN, signinUser);
  yield takeEvery(actions.SIGN_OUT, signoutUser);
  yield takeEvery(actions.FORGOT_PASSWORD, forgotPasswordAsync);
  yield takeEvery(actions.RESET_PASSWORD, resetPasswordAsync);
  yield takeEvery(actions.VERIFY, verifyAsync);
  yield takeEvery(actions.CHANGE_PASSWORD, changePasswordAsync);
  yield takeEvery(actions.CHANGE_USER_PASSWORD, changeUserPasswordAsync);
  yield takeEvery(actions.GET_INVITATION, getInvitationAsync);
  yield takeEvery(actions.MARK_INVITATION_USED, markInviationUsedAsync);
}
