import SendBird from "sendbird";
import { select } from "redux-saga/effects";

export function getSendBirdConnection({ userId, name, appId }) {
  const sb = new SendBird({
    appId,
  });

  return new Promise((resolve, reject) => {
    sb.connect(userId, (user, error) => {
      if (error) {
        reject(error);
      }
      sb.updateCurrentUserInfo(decodeURIComponent(name), null, (user, error) => {
        error ? reject(error) : resolve(sb);
      });
    });
  });
}

export function disconnectSendBird(connection) {
  return new Promise((resolve, reject) => {
    connection.disconnect((response, error) => {
      if (error) {
        reject(error);
      }
      resolve();
    });
  });
}

export function getChannelList(connection, chatType) {
  return new Promise((resolve, reject) => {
    const channelListQuery = connection.GroupChannel.createMyGroupChannelListQuery();
    channelListQuery.includeEmpty = true;
    channelListQuery.limit = 100;
    channelListQuery.order = "latest_last_message";
    channelListQuery.publicChannelFilter = chatType; // 'public', 'private', 'all'
    channelListQuery.next((channelList, error) => {
      error ? reject(error) : resolve(channelList);
    });
  });
}

export function getPublicChannelList(connection) {
  return new Promise((resolve, reject) => {
    const channelListQuery = connection.GroupChannel.createPublicGroupChannelListQuery();
    channelListQuery.membershipFilter = "all";
    channelListQuery.includeEmpty = true;
    channelListQuery.limit = 100;
    channelListQuery.order = "channel_name_alphabetical";
    channelListQuery.next((channelList, error) => {
      error ? reject(error) : resolve(channelList);
    });
  });
}

export function getChannel(url, connection) {
  return new Promise((resolve, reject) => {
    connection.GroupChannel.getChannel(url, (channel, error) => {
      error ? reject(error) : resolve(channel);
    });
  });
}

export function sendMessage(message, mentions, channel, connection) {
  return new Promise((resolve, reject) => {
    const params = new connection.UserMessageParams();
    params.message = message;
    params.mentionedUserIds = mentions;
    channel.sendUserMessage(params, (message, error) => {
      error ? reject(error) : resolve(message);
    });
  });
}

export function sendFileMessage(file, channel, connection) {
  return new Promise((resolve, reject) => {
    const params = new connection.FileMessageParams();
    params.file = file;
    params.fileName = file.name;
    params.fileSize = file.size;
    params.thumbnailSizes = [
      { maxWidth: 200, maxHeight: 200 },
      { maxWidth: 400, maxHeight: 400 },
    ];
    params.mimeType = file.type;
    channel.sendFileMessage(params, (message, error) => {
      error ? reject(error) : resolve(message);
    });
  });
}

export function getMessagesForChannel(channel, prevMessageListQuery) {
  return new Promise((resolve, reject) => {
    prevMessageListQuery.load((messages, error) => {
      error ? reject(error) : resolve(messages);
    });
  });
}

export function joinChannel(channel) {
  return new Promise((resolve, reject) => {
    channel.join((response, error) => {
      error ? reject(error) : resolve({});
    });
  });
}

export function leaveChannel(channel) {
  return new Promise((resolve, reject) => {
    channel.leave((response, error) => {
      error ? reject(error) : resolve({});
    });
  });
}

export function getUnreadChannelCount(connection) {
  return new Promise((resolve, reject) => {
    connection.getTotalUnreadChannelCount((count, error) => {
      error ? reject(error) : resolve(count);
    });
  });
}
