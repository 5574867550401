import React from "react";
import { ListItem, ListItemText } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { ChartBar } from "@phosphor-icons/react";

import { innerCard } from "./styled";

const primaryText = "Vineland-3™ Adaptive Behavior Assessment";
const secondaryText = `
  Vineland-3™ assessments aid in diagnosing and classifying intellectual and developmental 
  disabilities and other disorders, such as autism, Asperger Syndrome, and developmental delays.
`;

export default function VinelandBanner({ onClickChartButton, noAssessments }) {
  return (
    <ListItem component="aside" sx={innerCard}>
      <ListItemText
        primaryTypographyProps={{ fontWeight: "500" }}
        primary={primaryText}
        secondary={secondaryText}
      />

      {!noAssessments && (
        <IconButton size="large" onClick={onClickChartButton}>
          <ChartBar weight="duotone" />
        </IconButton>
      )}
    </ListItem>
  );
}
