import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

const HangTight = (props) => {
  const { classes, isClient, startCall } = props;
  let message;
  if (isClient) {
    message = `We're waiting for your clinician to ${startCall ? "start" : "end"} the call.`;
  } else {
    message = `We're waiting for your client to join the call.`;
  }
  return (
    <div className={startCall ? classes.hangTightStartCall : classes.hangTightEndCall}>
      <CustomCircularProgress />
      <Typography component="h5">Hang tight!</Typography>
      <Typography component="p">{message}</Typography>
    </div>
  );
};

const useStylesLoading = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  bottom: {
    color: "#E0E0E0",
  },
  top: {
    color: "#30364D",
    animationDuration: "1000ms",
    position: "absolute",
    left: 0,
  },
  circle: {
    strokeLinecap: "round",
  },
}));

function CustomCircularProgress(props) {
  const customClasses = useStylesLoading();
  return (
    <div className={customClasses.root}>
      <CircularProgress
        variant="determinate"
        className={customClasses.bottom}
        size={40}
        thickness={5}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={customClasses.top}
        classes={{
          circle: customClasses.circle,
        }}
        size={40}
        thickness={5}
        {...props}
      />
    </div>
  );
}

export default HangTight;
