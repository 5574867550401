import React from "react";
import withStyles from "@mui/styles/withStyles";
import styles from "../../styles";
import SectionBox from "../../../../elements/SectionBox";
import { Box, Button, FormHelperText, Select, TextField, Typography } from "@mui/material";
import { SealCheck } from "@phosphor-icons/react";
import VerificationsOfBenefitsList from "./VerificationOFBenefitsList";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import MenuItem from "@mui/material/MenuItem";
import { STATES } from "../../../../constants";
import {
  VerificationOfBenefitsConfig,
  VerificationOfBenefitsMedicaidConfig,
} from "./VerificationOfBenefitsConfig";
import Checkbox from "@mui/material/Checkbox";
import { VerificationOfBenefitsForm } from "./VerificationOfBenefitsForm";
import { VerificationOfBenefitsFormMedicaid } from "./VerificationOfBenefitsFormMedicaid";

const VerificationOfBenefits = (props) => {
  const {
    classes,
    clientId,
    userPermissions,
    editMode,
    dataPanel,
    updateDataPanel,
    copyToDataPanel,
    configPanel,
    setConfigPanel,
    isPanelOpen,
    setIsPanelOpen,
    updateTitlePanel,
    updateInputsForm,
    insurancesId,
    updateHasChenages,
    updateIsDirty,
    isDirty,
    dataVOB,
    isLoadingVOB,
    moveToTop,
  } = props;
  let medicaid = false;

  const setError = (name, error) => {
    updateDataPanel(name + "_error", error);
  };

  const onChange = (name, onChangeType, data) => (e) => {
    const { id, checked, type, value } = e.target;
    const insurance = insurancesId.find((insurance) => insurance.id === value);
    if (!onChangeType) {
      let val;
      switch (value) {
        case "Yes":
          val = true;
          break;
        case "No":
          val = false;
          break;
        default:
          val = type === "checkbox" ? checked : value;
      }
      data[name] = val;
      updateDataPanel(name, val);
    } else if (onChangeType === "radioButtonChange") {
      let parsedValue;
      if (typeof value === "boolean") {
        parsedValue = value;
      } else {
        parsedValue = value === "true";
      }
      data[name] = parsedValue;
      updateDataPanel(name, parsedValue);
    } else if (onChangeType === "phoneNumberchange") {
      let numberValue = value.replace(/[^0-9]/g, "");
      let phone = numberValue.replace(
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/g,
        "($1)-$2-$3"
      );
      data[name] = phone;
      updateDataPanel(name, phone);
    } else if (onChangeType === "numberChange") {
      data[name] = value.replace(/[^0-9]/g, "");
      updateDataPanel(name, value.replace(/[^0-9]/g, ""));
    } else if (onChangeType === "zipCode") {
      data[name] = value;
      updateDataPanel(name, value);
    }
    if (data && data["has_deductible"] === false) {
      updateDataPanel("individual_deductible_amount", null);
      data["individual_deductible_amount"] = null;
      updateDataPanel("remaining_deductible_amount", null);
      data["remaining_deductible_amount"] = null;
      updateDataPanel("family_deductible_amount", null);
      data["family_deductible_amount"] = null;
      updateDataPanel("remaining_family_deductible_amount", null);
      data["remaining_family_deductible_amount"] = null;
      updateDataPanel("deductible_out_of_pocket_max", null);
      data["deductible_out_of_pocket_max"] = "";
    }
    if (name === "insurance_plan_id") {
      medicaid = insurance.medicaid;
    }
    updateInputsForm(
      inputForms(data, insurancesId, validateRequired(data, onChangeType), medicaid)
    );
  };

  const validateRequired = (data, type) => {
    const errors = {};
    updateIsDirty(false);
    const requiredIds = medicaid
      ? VerificationOfBenefitsMedicaidConfig.filter((item) => item.required).map((item) => item.id)
      : VerificationOfBenefitsConfig.filter((item) => item.required).map((item) => item.id);
    requiredIds.forEach((id) => {
      if (type === "zipCode" && id.includes("zip")) {
        if (data[id]?.length < 5) {
          updateIsDirty(true);
          errors[id + "_format_error"] = true;
        } else if (data[id] === undefined || data[id] === null || data[id].length === 0) {
          updateIsDirty(true);
          errors[id] = true;
        } else {
          updateIsDirty(false);
          errors[id + "_format_error"] = false;
          errors[id] = false;
        }
      } else if (data[id] === undefined || data[id] === null || data[id].length === 0) {
        updateIsDirty(true);
        errors[id] = true;
      } else {
        errors[id] = false;
      }
    });
    return errors;
  };

  const inputForms = (data, insurances, errors, isMedicaidForm) => {
    medicaid = isMedicaidForm;
    if (insurances?.length === 1 && !data.insurance_plan_id) {
      const insurance = insurancesId.find((insurance) => insurance.id === insurances[0].id);
      data.insurance_plan_id = insurance.id;
      updateDataPanel("insurance_plan_id", insurance.id);
      medicaid = insurance.medicaid;
    }
    return medicaid
      ? VerificationOfBenefitsFormMedicaid(data, insurances, errors, isMedicaidForm, onChange)
      : VerificationOfBenefitsForm(data, insurances, errors, isMedicaidForm, onChange);
  };

  return (
    <SectionBox
      title="Verification of Benefits"
      description="Current and historical verifications of client insurance benefits."
    >
      <>
        <Box width={"100%"} flexDirection={"column"}>
          {!isLoadingVOB && (
            <VerificationsOfBenefitsList
              userPermissions={userPermissions}
              classes={classes}
              editMode={editMode}
              vobList={dataVOB?.data}
              dataPanel={dataPanel}
              updateDataPanel={updateDataPanel}
              copyToDataPanel={copyToDataPanel}
              configPanel={configPanel}
              setConfigPanel={setConfigPanel}
              updateTitlePanel={updateTitlePanel}
              setIsPanelOpen={setIsPanelOpen}
              isPanelOpen={isPanelOpen}
              updateInputsForm={updateInputsForm}
              inputForms={inputForms}
              onChange={onChange}
              insurancesId={insurancesId}
              moveToTop={moveToTop}
            ></VerificationsOfBenefitsList>
          )}
          {userPermissions?.edit_verification_of_benefits && editMode && !dataPanel?.id && (
            <Box marginTop="24px">
              <Button
                color="secondary"
                variant="contained"
                onClick={() => {
                  moveToTop();
                  copyToDataPanel({});
                  setIsPanelOpen(true);
                  updateTitlePanel("New Verification of Benefits");
                  updateInputsForm(inputForms({}, insurancesId, updateDataPanel, false, onChange));
                }}
                startIcon={<SealCheck />}
              >
                New Verification of Benefits
              </Button>
            </Box>
          )}
        </Box>
      </>
    </SectionBox>
  );
};

export default withStyles(styles)(VerificationOfBenefits);
