const ACTIONS = {
  GET_MESSAGE_TAG_SUMMARY: "GET_MESSAGE_TAG_SUMMARY",
  GET_MESSAGE_TAG_SUMMARY_SUCCESS: "GET_MESSAGE_TAG_SUMARRY_SUCCESS",
  GET_MESSAGES_FOR_TAG: "GET_MESSAGES_FOR_TAG",
  GET_MESSAGES_FOR_TAG_SUCCESS: "GET_MESSAGES_FOR_TAG_SUCCESS",
  SET_TAG_MESSAGES_PAGE: "SET_TAG_MESSAGES_PAGE",
  SET_TAG_MESSAGES_PER_PAGE: "SET_TAG_MESSAGES_PER_PAGE",
  GET_VIDEO_CALL_SUMMARY: "GET_VIDEO_CALL_SUMMARY",
  GET_VIDEO_CALL_SUMMARY_SUCCESS: "GET_VIDEO_CALL_SUMMARY_SUCCESS",
  GET_BILLING_SUMMARY: "GET_BILLING_SUMMARY",
  GET_BILLING_SUMMARY_SUCCESS: "GET_BILLING_SUMMARY_SUCCESS",
};

const ACTION_CREATORS = {
  getMessageTagSummary() {
    return {
      type: ACTIONS.GET_MESSAGE_TAG_SUMMARY,
    };
  },
  getMessageTagSummarySuccess(report) {
    return {
      type: ACTIONS.GET_MESSAGE_TAG_SUMMARY_SUCCESS,
      payload: {
        report,
      },
    };
  },
  getMessagesForTag(tagId) {
    return {
      type: ACTIONS.GET_MESSAGES_FOR_TAG,
      payload: tagId,
    };
  },
  getMessagesForTagSuccess(messages) {
    return {
      type: ACTIONS.GET_MESSAGES_FOR_TAG_SUCCESS,
      payload: messages,
    };
  },
  setTagMessagesPage(page) {
    return {
      type: ACTIONS.SET_TAG_MESSAGES_PAGE,
      payload: page,
    };
  },
  setTagMessagesPerPage(size) {
    return {
      type: ACTIONS.SET_TAG_MESSAGES_PER_PAGE,
      payload: size,
    };
  },
  getVideoCallSummary(query) {
    return {
      type: ACTIONS.GET_VIDEO_CALL_SUMMARY,
      payload: query,
    };
  },
  getVideoCallSummarySuccess(report) {
    return {
      type: ACTIONS.GET_VIDEO_CALL_SUMMARY_SUCCESS,
      payload: report,
    };
  },
  getBillingSummary(payload) {
    return {
      type: ACTIONS.GET_BILLING_SUMMARY,
      payload,
    };
  },
  getBillingSummarySuccess(report) {
    return {
      type: ACTIONS.GET_BILLING_SUMMARY_SUCCESS,
      payload: { report },
    };
  },
};

export default { ...ACTIONS, ...ACTION_CREATORS };
