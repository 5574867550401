import cookie from "react-cookies";
import { addDays } from "date-fns";

/**
 * The `ClientCookie` is a class that manages client and user IDs in cookies.
 * @returns {Object} The `ClientCookie` class instance with `set`, `get`, and `save` methods.
 * @example
 * const cookieClientId = new ClientCookie();
 * cookieClientId.set({ clientId: "123", userId: "456" });
 */
export function ClientCookie() {
  this.clientId = null;
  this.userId = null;

  /**
   * The `this.set` function in the `ClientCookie` class is a method that takes an object with
   * `clientId` and `userId` properties as its parameter.
   * @param {Object} param - An object with `clientId` and `userId` properties.
   * @param {string} param.clientId - The client ID.
   * @param {string} param.userId - The user ID.
   * @returns {void}
   */
  this.set = ({ clientId, userId }) => {
    if (!this.clientId || !this.userId) {
      console.error("ClientCookie: missing clientId or userId");
    }

    this.clientId = clientId;
    this.userId = userId;
  };

  /**
   * The `this.get` method in the `ClientCookie` class is a function that retrieves the `clientId`
   * @returns {string | undefined} The `clientId` value stored in the cookie.
   */
  this.get = () => {
    return cookie.load(`${this.userId}-clientId`);
  };

  /**
   * The `this.save` method in the `ClientCookie` class is responsible for saving the `clientId` value
   * to a cookie.
   * @returns {void}
   */
  this.save = () => {
    if (!this.clientId || !this.userId) {
      console.error("ClientCookie: clientId or userId not set");
      return;
    }

    cookie.save(`${this.userId}-clientId`, this.clientId, {
      path: "/",
      expires: addDays(new Date(), 30),
    });
  };

  return {
    set: this.set,
    get: this.get,
    save: this.save,
  };
}
