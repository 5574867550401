import * as AWS from "aws-sdk";
import { getAWSCredentialsForCurrentUserSession } from "./aws";

export const openS3Document = async (documentData, isVersioned) => {
  // expects object with two keys - {cognitoId, filename}
  const { cognitoId, filename, versionId } = documentData;
  const credentials = await getAWSCredentialsForCurrentUserSession();
  var s3 = new AWS.S3({ credentials });
  const params = {
    Bucket: process.env.AWS_USER_DOC_BUCKET,
    Key: isVersioned ? filename : `${cognitoId}/${filename}`,
  };
  if (versionId) {
    params.VersionId = versionId;
  }
  var url = s3.getSignedUrl("getObject", params);
  window.open(url, "_blank");
};
