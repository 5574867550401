import React from "react";
import {
  Typography,
  Checkbox,
  FormControl,
  TextField,
  FormGroup,
  FormHelperText,
  FormControlLabel,
} from "@mui/material";
import { TECH_ISSUES } from "../../../constants";

const TechIssues = (props) => {
  const {
    classes,
    techIssueReasonError,
    techIssueReasons,
    onChangeOtherInputTechIssue,
    onChangeTechIssueReasons,
    viewOnly,
    otherTechIssue,
    techIssuesRef,
  } = props;

  return (
    <div className={classes.techIssuesContainer} ref={techIssuesRef}>
      <div className={classes.techIssueReasons}>
        <FormControl component="fieldset">
          <Typography component="p" variant="body2">
            Select the tech issue(s) you experienced on the call
          </Typography>
          <FormGroup>
            {TECH_ISSUES.map((issue, i) => {
              return (
                <FormControlLabel
                  key={i}
                  control={
                    <Checkbox
                      color="primary"
                      checked={techIssueReasons.includes(issue)}
                      onChange={onChangeTechIssueReasons}
                      disabled={viewOnly}
                      name={issue}
                    />
                  }
                  label={
                    <Typography
                      component="p"
                      variant="body2"
                      style={viewOnly ? { color: "rgba(0, 0, 0, 0.38)" } : null}
                    >
                      {issue}
                    </Typography>
                  }
                  sx={{ margin: 0 }}
                />
              );
            })}
          </FormGroup>
        </FormControl>
      </div>
      <TextField
        placeholder="Other"
        onChange={onChangeOtherInputTechIssue}
        value={otherTechIssue}
        variant="outlined"
        disabled={viewOnly}
        sx={{ width: 256 }}
      />
      {techIssueReasonError && (
        <FormHelperText className={classes.helperText}>
          Select issue(s) experienced during session
        </FormHelperText>
      )}
    </div>
  );
};

export default TechIssues;
