import React from "react";
import { Box, Divider, Typography } from "@mui/material";

export const Overview = ({ numCredentialedPayors, numStatesCovered, theme }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignSelf: "stretch",
        backgroundColor: theme.palette.grey[50],
        padding: "16px 0",
        margin: "16px 24px 0 24px",
        borderRadius: "6px",
        "& .metricContainer": {
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          width: "50%",
          padding: "0 24px",
        },
        "&. metric": {},
      }}
    >
      <div className="metricContainer">
        <div className="metric">
          <Typography variant="subtitle2">Credentialed Payor</Typography>
          <Typography variant="h4" color={"primary"}>
            {numCredentialedPayors}
          </Typography>
        </div>
      </div>
      <div className="divider">
        <Divider orientation="vertical" />
      </div>
      <div className="metricContainer">
        <div className="metric">
          <Typography variant="subtitle2">State Coverage</Typography>
          <Typography variant="h4" color={"primary"}>
            {numStatesCovered}
          </Typography>
        </div>
      </div>
    </Box>
  );
};
