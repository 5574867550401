import React from "react";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import { FormControl } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Switch from "@mui/material/Switch";
import { UserCircle, UsersThree, IdentificationBadge } from "@phosphor-icons/react";
import CustomTypography from "./CustomTypography";

export const SearchOptionsList = (props) => {
  const {
    classes,
    parents,
    clients,
    clinicians,
    deactivated,
    allUsers,
    onChangeFilter,
    children,
    isSuperAdmin,
    userPermissions,
  } = props;
  return (
    <div className={classes.searchOptionsContainer}>
      <div className={classes.searchOptions}>
        {userPermissions?.view_client_list_by_workflow_state && (
          <div className={classes.filters}>
            <Button
              variant="contained"
              color={clients ? "primary" : "secondary"}
              size="small"
              startIcon={<UserCircle weight="duotone" />}
              onClick={() => onChangeFilter("clients", !clients)}
              disabled={!!allUsers}
            >
              Clients
            </Button>

            {userPermissions?.view_clinician_list && (
              <Button
                variant="contained"
                color={clinicians ? "primary" : "secondary"}
                size="small"
                startIcon={<IdentificationBadge weight="duotone" />}
                onClick={() => onChangeFilter("clinicians", !clinicians)}
                disabled={!!allUsers}
              >
                Clinicians
              </Button>
            )}
          </div>
        )}

        {userPermissions?.view_client_list_by_workflow_state && (
          <div className={classes.filters}>
            <Button
              variant="contained"
              color={parents ? "primary" : "secondary"}
              size="small"
              startIcon={<UsersThree weight="duotone" />}
              onClick={() => onChangeFilter("parents", !parents)}
              disabled={!!allUsers}
            >
              Team Members
            </Button>
          </div>
        )}

        <div className={classes.filters}>
          <FormControl style={{ padding: 5 }}>
            <FormControlLabel
              classes={{ label: classes.checkBoxLabel }}
              onChange={(e) => {
                onChangeFilter("deactivated", !deactivated);
              }}
              checked={!!deactivated}
              control={
                <Checkbox
                  value={!!deactivated}
                  inputProps={{
                    "aria-label": "primary checkbox",
                  }}
                />
              }
              label={
                <CustomTypography
                  text="Show deactivated users"
                  onClick={() => onChangeFilter("deactivated", !deactivated)}
                />
              }
            />
          </FormControl>
        </div>

        {isSuperAdmin && (
          <div className={classes.filters}>
            <FormControl>
              <FormControlLabel
                checked={!!allUsers}
                onChange={() => {
                  onChangeFilter("allUsers", !allUsers);
                }}
                control={<Switch size="small" />}
                label={
                  <CustomTypography
                    text="User Admin"
                    onClick={() => onChangeFilter("allUsers", !allUsers)}
                  />
                }
              />
            </FormControl>
          </div>
        )}
      </div>
      <section className={classes.listOptions}>{children}</section>
    </div>
  );
};
