export default (theme) => ({
  dashboardContainer: {
    width: "100%",
    minHeight: "calc(100vh - 64px)",
    display: "flex",
    padding: "20px 20px 40px 20px",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: "#e5e5e5",
    // position: "relative",
    [theme.breakpoints.up("lg")]: {
      padding: "20px 40px 40px 100px",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-start",
    },
  },
  loadingContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "calc(100vh - 50px - 64px)",
  },
  greeting: {
    fontSize: 19,
    fontWeight: 500,
    [theme.breakpoints.down("xl")]: {
      fontSize: 16,
    },
  },
  dashboardSections: {
    display: "flex",
    flexDirection: "column",
    width: "70%",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    [theme.breakpoints.down("xl")]: {
      width: "100%",
    },
  },
  sectionTitle: {
    fontSize: 20,
    margin: "15px 0 10px",
    fontWeight: 500,
    [theme.breakpoints.down("xl")]: {
      fontSize: 17,
      margin: "10px 0",
    },
  },
  upcomingSession: {
    width: "100%",
    height: 185,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    borderRadius: 5,
    backgroundColor: "#fafafa",
    boxShadow: "0px 4px 6px -1px rgba(55, 55, 55, 0.15)",
    marginBottom: 20,
    [theme.breakpoints.down("xl")]: {
      flexDirection: "column",
      height: "fit-content",
    },
  },
  countdownContainer: {
    height: "100%",
    width: "20%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "20px 0",
    "& p": {
      fontSize: 20,
      fontWeight: 500,
      textAlign: "center",
      color: "#2a2d39",
    },
    "& h1": {
      fontSize: 48,
    },
    borderRight: "0.7px solid #c3c6d2",
    [theme.breakpoints.down("xl")]: {
      //display: "none", //THIS IS ONE OPTION
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      padding: "10px",
      width: "100%",
      height: "fit-content",
      borderBottom: "0.7px solid #c3c6d2",
      borderRight: "none",
      "& p": {
        fontSize: 16,
      },
      "& h1": {
        fontSize: 24,
        fontWeight: 500,
        margin: "0 5px",
      },
    },
  },
  upcomingSessionDetails: {
    padding: "20px 10px 20px 40px",
    width: "80%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    [theme.breakpoints.down("xl")]: {
      padding: "15px",
      width: "100%",
    },
  },
  callType: {
    fontSize: 22,
    fontWeight: 500,
    color: "#2a2d39",
    [theme.breakpoints.down("xl")]: {
      fontSize: 16,
      marginBottom: 10,
    },
  },
  dateAndTime: {
    fontSize: 16,
    color: "#3f4456",
    fontWeight: 500,
    [theme.breakpoints.down("xl")]: {
      fontSize: 13,
      fontWeight: 500,
    },
  },
  clinicianName: {
    fontSize: 16,
    color: "#3f4456",
    fontWeight: 500,
    [theme.breakpoints.down("xl")]: {
      fontSize: 13,
      fontWeight: 500,
    },
  },
  upcomingSessionButtonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    "& button": {
      marginRight: 50,
    },
    [theme.breakpoints.down("xl")]: {
      width: "100%",
      marginTop: 10,
      "& button": {
        marginRight: 0,
        padding: "4px 10px !important",
      },
    },
  },
  calendarContainer: {
    width: "23%",
    minWidth: 260,
    height: "fit-content",
    marginTop: 90,
    borderRadius: 5,
    backgroundColor: "#fafafa",
    [theme.breakpoints.down("xl")]: {
      width: "100%",
      minWidth: 0,
      marginTop: 30,
    },
  },
  calendar: {
    width: "100%",
    height: 350,
    maxHeight: "100%",
    [theme.breakpoints.down("xl")]: {
      height: "80vw",
    },
  },
  calendarEventsContainer: {
    width: "100%",
    padding: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  calendarEventsDateContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    paddingLeft: 15,
    justifyContent: "space-between",
    alignItems: "center",
    margin: 10,
    "& p": {
      color: "#3f4456",
      fontSize: 16,
      fontWeight: 500,
    },
  },
  calendarEvent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "100%",
    borderRadius: 5,
    margin: "5px 0",
    padding: "15px 10px",
  },
  noCalendarEvents: {
    width: "100%",
    height: 100,
    borderRadius: 5,
    padding: 15,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: "rgba(214, 214, 214, 0.6)",
    "& p": {
      color: "#717D84",
      fontSize: 17,
      fontWeight: 500,
    },
  },
  calendarEventIconContainer: {
    marginRight: 10,
  },
  calendarEventInfo: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    cursor: "pointer",
    "& p": {
      color: "#3F4456",
      fontSize: 15,
    },
    [theme.breakpoints.down("xl")]: {
      "& p": {
        fontSize: 14,
      },
    },
  },
  eventTypeTitle: {
    fontSize: 18,
    fontWeight: 500,
    marginBottom: 5,
  },
  roadmapContainer: {
    width: "23%",
    minWidth: 260,
    height: "fit-content",
    marginTop: 90,
    borderRadius: 5,
    backgroundColor: "#fafafa",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "25px 0",
    [theme.breakpoints.down("xl")]: {
      width: "100%",
      minWidth: 0,
      marginTop: 30,
    },
  },
  roadmapTitle: {
    color: "#2A2D39",
    fontSize: 20,
    fontWeight: 500,
    marginBottom: 5,
  },
  roadmapStepCount: {
    color: "#2A2D39",
    fontSize: 18,
    fontWeight: 400,
    marginBottom: 15,
  },
  completedRoadmapStep: {
    width: "80%",
    borderRadius: 5,
    backgroundColor: "#ecfdf5",
    border: "1.5px solid #a7f3d0",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: 16,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    "& div": {
      display: "block",
      width: "90%",
    },
    "& h3": {
      color: "#047857",
      fontSize: 16,
      marginBottom: 5,
      fontWeight: 500,
      width: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    "& p": {
      color: "#064E3B",
      fontSize: 14,
      fontWeight: 500,
    },
  },
  currentRoadmapStep: {
    width: "90%",
    borderRadius: 5,
    backgroundColor: "#D9F4F4",
    border: "2.5px solid #68D5D5",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: "16px calc(5% + 16px)",
    paddingRight: 5,
    "& div": {
      display: "block",
      width: "90%",
    },
    "& h3": {
      width: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      color: "#1a5151",
      fontSize: 18,
      marginBottom: 5,
      fontWeight: 500,
    },
    "& h4": {
      color: "#0D2828",
      fontSize: 16,
      marginBottom: 5,
      fontWeight: 400,
    },
    "& h6": {
      color: "#3F4456",
      width: "100%",
      fontSize: 16,
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      fontWeight: 500,
    },
  },
  incompleteRoadmapStep: {
    width: "80%",
    borderRadius: 5,
    backgroundColor: "#F0F1F4",
    border: "0.5px solid #C3C6D2",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: 16,
    "& div": {
      display: "block",
      width: "90%",
    },
    "& h3": {
      width: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      color: "#69718F",
      fontSize: 16,
      fontWeight: 500,
    },
  },
  roadmapIconContainer: {
    height: 26,
    maxWidth: 22,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& svg": {
      fill: "#047857",
      fontSize: 16,
    },
  },
  roadmapStepNumber: {
    color: "#15171d",
    fontSize: 16,
    fontWeight: 500,
    marginRight: 12,
  },
  roadmapStepTextContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  roadmapDividerCompleted: {
    height: 30,
    width: 12,
    backgroundColor: "#A7F3D0",
  },
  roadmapDivider: {
    height: 30,
    width: 12,
    backgroundColor: "#C3C6D2",
  },
  stepLabelActive: {
    fontSize: 19,
    fontWeight: 500,
    [theme.breakpoints.down("xl")]: {
      fontSize: 16,
    },
  },
  stepLabel: {
    fontSize: 19,
    fontWeight: 500,
    color: "rgba(0, 0, 0, 0.44)",
    [theme.breakpoints.down("xl")]: {
      fontSize: 16,
    },
  },
  stepContent: {
    fontSize: 16,
    // fontWeight: 500,
    [theme.breakpoints.down("xl")]: {
      fontSize: 14,
    },
  },
  stepContentIncomplete: {
    fontSize: 15,
    color: "rgba(0, 0, 0, 0.44)",
    [theme.breakpoints.down("xl")]: {
      fontSize: 13,
    },
  },
  notificationContainer: {
    width: "100%",
    minHeight: 135,
    minHeight: "fit-content",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    borderRadius: 5,
    backgroundColor: "#fafafa",
    boxShadow: "0px 4px 6px -1px rgba(55, 55, 55, 0.15)",
    marginBottom: 25,
    flex: 1,
  },
  notificationHighlight: {
    position: "absolute",
    flex: 1,
    height: "100%",
    top: 0,
    left: 0,
    width: 5,
    borderRadius: 5,
  },
  notificationDetails: {
    padding: "15px 0 15px 45px",
    width: "95%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    [theme.breakpoints.down("xl")]: {
      padding: "10px 10px 10px 20px",
    },
  },
  notificationTitle: {
    fontSize: 18,
    fontWeight: 500,
    color: "#2a2d39",
    [theme.breakpoints.down("xl")]: {
      fontSize: 16,
    },
  },
  notificationDescription: {
    fontSize: 15,
    color: "#3f4456",
    fontWeight: 500,
    [theme.breakpoints.down("xl")]: {
      fontSize: 14,
    },
  },
  notificationButtonContainer: {
    width: "50%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    // marginTop: 10,
    "& button": {
      padding: "4px !important",
    },
    [theme.breakpoints.down("xl")]: {
      width: "100%",
    },
  },
  welcomeLogo: {
    width: 60,
    height: 60,
    borderRadius: "50%",
  },
  welcomeTitleContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  welcomeTitle: {
    fontWeight: 500,
    marginTop: 10,
    fontSize: 18,
    color: "#15171D",
  },
  welcomeTextContainer: {
    lineHeight: 20,
    marginTop: -10,
    color: "##69718F",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    "& p": {
      fontSize: 15,
    },
    [theme.breakpoints.down("xl")]: {
      "& p": {
        fontSize: 15,
      },
    },
  },
  welcomeDialogActionsContainer: {
    backgroundColor: "#F0F1F4",
    padding: 15,
    marginTop: 10,
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
  },
  consentToECommContainer: {
    // width: "80vh",
    // height: "80vh",
    zIndex: 3000,
    // position: "absolute",
    // top: 0,
    // left: 0,
    padding: "20px 40px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    backgroundColor: "#fff",
    "& ul": {
      marginTop: 0,
    },
    [theme.breakpoints.down("xl")]: {
      padding: "15px",
    },
    [theme.breakpoints.down("xl")]: {
      padding: "5px",
    },
  },
  consentPageTitle: {
    width: "100%",
    fontSize: 16,
    fontWeight: 500,
    textAlign: "center",
    padding: 20,
    [theme.breakpoints.down("xl")]: {
      fontSize: 13,
    },
  },
  consentTitle: {
    width: "100%",
    fontSize: 16,
    fontWeight: 500,
    textAlign: "center",
    padding: 20,
    [theme.breakpoints.down("xl")]: {
      fontSize: 12,
    },
  },
  consentText: {
    fontSize: 14,
    textAlign: "left",
    marginBottom: 15,
    [theme.breakpoints.down("xl")]: {
      fontSize: 12,
    },
  },
  consentUnorderedList: {
    listStyleType: "none",
    "& li": {
      display: "flex",
      flexDirection: "row",
      "&:before": {
        marginRight: 10,
        content: '"\\2714\\0020"',
      },
    },
  },
  consentBulletText: {
    fontSize: 14,
    textAlign: "left",
    [theme.breakpoints.down("xl")]: {
      fontSize: 12,
    },
  },
  consentToECommText: {
    width: "100%",
    fontSize: 16,
    textAlign: "center",
    marginTop: 10,
    fontWeight: 500,
    [theme.breakpoints.down("xl")]: {
      fontSize: 15,
    },
  },
  consentToECommButton: {
    width: "100%",
    height: 45,
    borderRadius: 8,
    alignSelf: "center",
    marginTop: 5,
    "& p": {
      color: "#fff",
      textTransform: "none",
      fontWeight: 500,
    },
  },
  consentLogo: {
    width: 200,
    height: "auto",
    marginBottom: 15,
    alignSelf: "center",
    [theme.breakpoints.down("xl")]: {
      width: 200,
    },
  },
  signatureCanvas: {
    backgroundColor: "#fff",
    borderRadius: 8,
    border: "2px dashed #c4c4c4",
    overflow: "hidden",
    margin: "10px auto",
    width: "fit-content",
  },
  stepperRoot: {
    [theme.breakpoints.down("xl")]: {
      padding: 10,
    },
  },
});
