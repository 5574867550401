import { select } from "redux-saga/effects";
import { getLogin, getUserId } from "../selectors";
import SecureRequest from "../utils/securerequest";
// import Auth from "@aws-amplify/auth";

export function* getUser() {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const username = yield select(getLogin);
  return yield Request.get(`/users/${username}`);
}

// export function* changeCognitoPassword(payload) {
//   const { oldPassword, newPassword } = payload;
//   return yield new Promise((resolve, reject) => {
//     Auth.currentAuthenticatedUser()
//       .then((user) => {
//         return Auth.changePassword(user, oldPassword, newPassword);
//       })
//       .then(resolve())
//       .catch((err) => reject(err));
//   });
// }

export function* sendReferralEmail(recipientEmail) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const userId = yield select(getUserId);
  return yield Request.get(`/referrals/${userId}/send-email?email=${recipientEmail}`);
}

export function* getUserPermissions(userId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/users/${userId}/permissions`);
}

export async function getUserPermissionsAsync(userId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.get(`/users/${userId}/permissions`);
}

export function* updateUserRoles(userRoles) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.put(`/users/${userRoles.userId}/update-roles`, userRoles);
}

/**
 * @deprecated
 * This function has been deprecated, in favor of resetUserPasswordAsync
 * to be used in with react-query.
 */
export function* resetUserPassword(userId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.put(`/users/${userId}/reset-password`, {});
}

export async function resetUserPasswordAsync(userId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.put(`/users/${userId}/reset-password`, {});
}

export function* getUserDetails(userId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/users/${userId}/details`);
}

export const getHasClientAccountAccess = async ({ userId, ...params }) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.get(`/v3/users/${userId}/client-account-access`, { params });
};

export const getUserNotificationPreferences = async (userId) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.get(`/v3/notifications/preferences/${userId}`);
};

export const setUserNotificationPreferences = async ({ userId, data }) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.patch(`/v3/notifications/preferences/${userId}`, data);
};
