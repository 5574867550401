import React, { Component } from "react";
import VideoCamOffIcon from "@mui/icons-material/VideocamOff";
import Typography from "@mui/material/Typography";
import { differenceInHours } from "date-fns";
import { Lobby } from "./Lobby";
import ParentStockImage from "assets/demo-call-parent.jpg";
import ClientVideoMessage from "./Elements/ClientVideoMessage";
import NetworkQualityLevel from "./Elements/NetworkQualityLevel";
import NotesDrawer from "./Drawers/NotesDrawer";
import ControlsContainer from "./Elements/ControlsContainer";
import { getUser } from "selectors";
import IntercomAiProvider, { closeIntercomAi, openIntercomAi } from "components/IntercomAi";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
  user: getUser(state),
});

class VideoComponent extends Component {
  /** @type {HTMLElement} */
  __sigPadStart;
  setSigPadStart = (el) => {
    this.sigPadStart = el;
  };
  set sigPadStart(el) {
    this.__sigPadStart = el;
  }
  get sigPadStart() {
    return this.__sigPadStart;
  }

  constructor(props) {
    super(props);
    this.sigPadStart = React.createRef();
  }

  getIntercomSettings = () => {
    const { user } = this.props;
    const isClinician = this.props?.isClinician;
    const { first_name, last_name, email, id } = user;

    return {
      userInfo: {
        name: `${first_name} ${last_name}`,
        email,
        userId: id,
        isClient: !isClinician,
        isClinician,
      },
      location: {
        verticalPadding: isClinician ? 130 : 0,
        horizontalPadding: isClinician ? 25 : 0,
      },
    };
  };

  componentDidUpdate = (prevProps) => {
    if (!prevProps.sigError && this.props.sigError) {
      this.sigPadStart.clear();
    }

    if (
      prevProps.hideIntercomAi != this.props.hideIntercomAi ||
      prevProps.isClinician != this.props.isClinician ||
      prevProps.isCaregiver != this.props.isCaregiver
    ) {
      if (window?.Intercom) {
        closeIntercomAi();
      }
      if (!this.props.hideIntercomAi && this.props.isClinician) {
        const { userInfo, location } = this.getIntercomSettings();
        if (window?.Intercom) {
          openIntercomAi(userInfo, location);
        } else {
          IntercomAiProvider(userInfo, location);
        }
      }
    }
  };

  trimSignatures = () => {
    const startSigURL = this.sigPadStart.getTrimmedCanvas().toDataURL("image/png");
    this.props.setSigURLSFromCanvas(startSigURL);
  };

  setPrimaryClinicianStyling = () => {
    const { remoteVideoDisabled, screenShareOn, secondaryClinicianInControl, classes } = this.props;
    if (screenShareOn && !remoteVideoDisabled) {
      return classes.headShotMedia;
    } else if (screenShareOn) {
      return classes.headShotMediaDisabled;
    } else if (secondaryClinicianInControl) {
      return remoteVideoDisabled ? classes.headShotMediaDisabled : classes.headShotMedia;
    } else {
      return remoteVideoDisabled ? classes.mainMediaDisabled : classes.mainMedia;
    }
  };

  setSecondaryClinicianStyling = () => {
    const { secondaryClinicianInControl, screenShareOn, secondaryVideoDisabled, classes } =
      this.props;
    if (screenShareOn && !secondaryVideoDisabled) {
      return classes.headShotMedia;
    } else if (screenShareOn) {
      return classes.headShotMediaDisabled;
    } else if (!secondaryVideoDisabled) {
      return secondaryClinicianInControl ? classes.mainMedia : classes.headShotMedia;
    } else {
      return secondaryClinicianInControl
        ? classes.mainMediaDisabled
        : classes.headShotMediaDisabled;
    }
  };

  render() {
    const {
      muted,
      cameraDisabled,
      hasJoinedRoom,
      localMediaAvailable,
      classes,
      showControls,
      clinicianJoined,
      remoteVideoDisabled,
      startDate,
      endDate,
      callEnded,
      roomLayout,
      sendVideoSignaturesSuccess,
      parentScreenSharing,
      screenShareOn,
      clinicianRemoteMedia,
      secondaryClinicianConnected,
      secondaryClinicianData,
      primaryClinicianData,
      secondaryVideoDisabled,
      secondaryClinicianInControl,
      // ADMIN
      hasStartedCall,
      oneTimeVideoInfo,
      isDemo,
      clinicianScreenSharing,
      userId,
      currentParticipant,
      isPrimaryClinician,
      primaryClinicianConnected,
      remoteClinicianVideoDisabled,
      screenshareUserId,
      clientJoinedData,
      remoteClinicianData,
      accessGranted,
      clientUserId,
      isCaregiver,
      localParticipantNetworkLevel,
      toggleNotes,
      user,
      clientConnected,
      clientOnMobile,
      localParticipantNetworkLevelReceived,
      secondaryClinicianHasIncompleteBilling,
      secondaryClinicianLeftCall,
      primaryClinicianFirstName,
    } = this.props;
    let timeDifference = differenceInHours(new Date(startDate), new Date());
    let screenSharerName = isCaregiver
      ? secondaryClinicianInControl
        ? secondaryClinicianData?.name
        : primaryClinicianData?.name
      : screenshareUserId === clientUserId
        ? clientJoinedData?.name
        : remoteClinicianData?.name;
    return (
      <div
        className={
          roomLayout === "standard" || (!clinicianJoined && isCaregiver)
            ? classes.videoContainer
            : roomLayout === "activity_split"
              ? classes.videoActivitySplit
              : classes.videoActivityFocus
        }
        style={isCaregiver && roomLayout == "activity_focus" ? { height: 0, marginTop: 0 } : null}
      >
        {(parentScreenSharing || clinicianScreenSharing) && hasJoinedRoom && (
          <div className={classes.screenshareMessage}>
            <Typography component="p">YOU ARE SCREEN SHARING</Typography>
          </div>
        )}

        {((!clinicianScreenSharing && !isCaregiver) || (!parentScreenSharing && isCaregiver)) &&
          screenShareOn &&
          hasJoinedRoom && (
            <div className={classes.screenshareMessage}>
              <Typography component="p">{screenSharerName} IS SCREEN SHARING</Typography>
            </div>
          )}

        {!hasStartedCall && !isCaregiver && (!endDate || !accessGranted) && (
          <div className={classes.mainMediaContainer}>
            <div className={classes.waitingMessageContainer}>
              <Lobby {...this.props} />
            </div>
          </div>
        )}

        {!clinicianJoined && !sendVideoSignaturesSuccess && !parentScreenSharing && isCaregiver && (
          <div className={classes.mainMediaContainer}>
            <div className={classes.waitingMessageContainer}>
              <ClientVideoMessage
                timeDifference={timeDifference}
                trimSignatures={this.trimSignatures}
                sigPadStart={this.setSigPadStart}
                {...this.props}
              />
            </div>
          </div>
        )}
        {hasJoinedRoom && !endDate && !secondaryClinicianLeftCall && (
          <div
            className={
              clientOnMobile
                ? showControls
                  ? classes.mobileLocalMediaContainerShowControls
                  : classes.mobileLocalMediaContainer
                : classes.localMediaContainer
            }
            style={
              isCaregiver && cameraDisabled
                ? { display: "none" }
                : screenShareOn ||
                    (isCaregiver && primaryClinicianConnected && secondaryClinicianConnected) ||
                    (isPrimaryClinician && secondaryClinicianConnected) ||
                    (!isCaregiver && !isPrimaryClinician && primaryClinicianConnected)
                  ? {
                      borderTopRightRadius: 0,
                      borderTopLeftRadius: 0,
                    }
                  : null
            }
          >
            {localMediaAvailable && !endDate ? (
              <div
                ref={this.props.localMedia}
                className={classes.localMedia}
                style={isCaregiver && cameraDisabled ? { display: "none" } : null}
                id="localMedia"
              >
                {localParticipantNetworkLevelReceived && (
                  <div className={classes.localMediaNetworkLevelContainer}>
                    <NetworkQualityLevel
                      networkLevel={localParticipantNetworkLevel}
                      {...this.props}
                    />
                  </div>
                )}
                {cameraDisabled && (
                  <>
                    <div className={classes.cameraDisabledPlaceholder}>
                      <div className={classes.cameraDisabledInitial}>
                        <VideoCamOffIcon
                          style={{
                            fontSize: 22,
                            fill: "#fff",
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            ) : null}
            {!isCaregiver && (
              <div className={classes.roleLabelContainer} style={{ bottom: 16 }}>
                <Typography component="p" className={classes.roleLabel}>
                  {`${currentParticipant?.name || ""} - ${
                    isPrimaryClinician ? "Primary" : "Supervisory"
                  } Clinician`}
                </Typography>
              </div>
            )}
          </div>
        )}

        {!isCaregiver && hasJoinedRoom && (
          <>
            <div
              className={
                screenShareOn ? classes.middleHeadShotMediaContainer : classes.mainMediaContainer
              }
              style={
                screenShareOn && (primaryClinicianConnected || secondaryClinicianConnected)
                  ? {
                      borderTopRightRadius: 0,
                      borderTopLeftRadius: 0,
                    }
                  : null
              }
            >
              {isDemo ? (
                <img
                  className={screenShareOn ? classes.headShotMedia : classes.mainMedia}
                  src={ParentStockImage}
                />
              ) : (
                <>
                  {hasJoinedRoom && screenShareOn && (
                    <div className={classes.roleLabelContainer}>
                      <Typography className={classes.roleLabel}>
                        Client - {clientJoinedData.name}
                      </Typography>
                    </div>
                  )}
                  <div
                    className={
                      !remoteVideoDisabled && screenShareOn
                        ? classes.headShotMedia
                        : remoteVideoDisabled && screenShareOn
                          ? classes.headShotMediaDisabled
                          : remoteVideoDisabled
                            ? classes.mainMediaDisabled
                            : clientOnMobile
                              ? classes.mainMediaClientOnMobile
                              : classes.mainMedia
                    }
                    ref={this.props.remoteMedia}
                  />
                  {!clientConnected && (
                    <div
                      className={classes.mainMediaDisabledPlaceholder}
                      style={{
                        borderRadius: !remoteVideoDisabled && screenShareOn ? "6px" : null,
                      }}
                    >
                      <div
                        className={
                          !remoteVideoDisabled && screenShareOn
                            ? classes.mainMediaHeadShotDisabledInitial
                            : classes.mainMediaDisabledInitial
                        }
                      >
                        <Typography component="p">{oneTimeVideoInfo?.child_name[0]}</Typography>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>

            {(secondaryClinicianConnected || primaryClinicianConnected) && (
              <div
                className={
                  screenShareOn
                    ? classes.topHeadShotMediaContainer
                    : classes.middleHeadShotMediaContainer
                }
              >
                <div className={classes.roleLabelContainer}>
                  <Typography className={classes.roleLabel}>
                    {remoteClinicianData?.name} -{" "}
                    {remoteClinicianData?.role === "PRIMARY"
                      ? "Primary Clinician"
                      : "Supervisory Clinician"}
                  </Typography>
                </div>
                <div
                  className={
                    !remoteClinicianVideoDisabled
                      ? classes.headShotMedia
                      : classes.headShotMediaDisabled
                  }
                  ref={clinicianRemoteMedia}
                />
                {remoteClinicianVideoDisabled && (
                  <div className={classes.cameraDisabledPlaceholder}>
                    <div className={classes.cameraDisabledInitial}>
                      <Typography component="p">{remoteClinicianData?.name[0]}</Typography>
                    </div>
                  </div>
                )}
              </div>
            )}
            <div
              className={classes.screenShareMediaContainer}
              id="clinicianScreenShare"
              style={{ display: !clinicianScreenSharing ? "none" : "block" }}
            >
              <div ref={this.props.screenShareMedia} className={classes.screenShareMedia}></div>
            </div>

            <div
              className={classes.screenShareMediaContainer}
              id="remoteParticipantScreenShare"
              style={{
                display: screenshareUserId === userId ? "none" : "block",
              }}
            >
              <div
                ref={this.props.remoteScreenShareMedia}
                className={classes.screenShareMedia}
              ></div>
            </div>
          </>
        )}
        {isCaregiver && hasJoinedRoom && (
          <>
            {primaryClinicianConnected && (
              <div
                className={
                  clientOnMobile
                    ? secondaryClinicianInControl
                      ? screenShareOn
                        ? classes.mobileTopHeadShotMediaContainer
                        : classes.mobileMiddleHeadShotMediaContainer
                      : screenShareOn
                        ? classes.mobileMiddleHeadShotMediaContainer
                        : classes.mainMediaContainer
                    : secondaryClinicianInControl
                      ? screenShareOn
                        ? classes.topHeadShotMediaContainer
                        : classes.middleHeadShotMediaContainer
                      : screenShareOn
                        ? classes.middleHeadShotMediaContainer
                        : classes.mainMediaContainer
                }
                style={
                  !clientOnMobile &&
                  !secondaryClinicianInControl &&
                  screenShareOn &&
                  secondaryClinicianConnected &&
                  primaryClinicianConnected
                    ? {
                        borderTopRightRadius: 0,
                        borderTopLeftRadius: 0,
                      }
                    : null
                }
              >
                <div
                  id="clientRemoteMedia"
                  className={this.setPrimaryClinicianStyling()}
                  ref={this.props.remoteMedia}
                >
                  {(parentScreenSharing || screenShareOn || secondaryClinicianInControl) &&
                    primaryClinicianData &&
                    clinicianJoined &&
                    !endDate &&
                    hasJoinedRoom && (
                      <>
                        {(remoteVideoDisabled || !clinicianJoined) &&
                          (screenShareOn || secondaryClinicianInControl) && (
                            <div className={classes.cameraDisabledPlaceholder}>
                              <div className={classes.cameraDisabledInitial}>
                                <Typography component="p">
                                  {primaryClinicianData?.name[0]}
                                </Typography>
                              </div>
                            </div>
                          )}
                        <div className={classes.roleLabelContainer}>
                          <Typography component="p" className={classes.roleLabel}>
                            {`${primaryClinicianData.name} - Primary Clinician`}
                          </Typography>
                        </div>
                      </>
                    )}
                </div>
                {(remoteVideoDisabled || !clinicianJoined) &&
                  !callEnded &&
                  !parentScreenSharing &&
                  !screenShareOn &&
                  !secondaryClinicianInControl && (
                    <div className={classes.mainMediaDisabledPlaceholder}>
                      <div className={classes.mainMediaDisabledInitial}>
                        <Typography component="p">{primaryClinicianData?.name[0]}</Typography>
                      </div>
                    </div>
                  )}
              </div>
            )}
            {!endDate && // TODO this should also account for caregiver when Secondary is connected and in control but not screen sharing
              ((!secondaryClinicianInControl && secondaryClinicianConnected) ||
                (secondaryClinicianInControl && primaryClinicianConnected)) && (
                <div
                  className={
                    clientOnMobile
                      ? secondaryClinicianInControl
                        ? screenShareOn
                          ? classes.mobileMiddleHeadShotMediaContainer
                          : classes.mainMediaContainer
                        : screenShareOn
                          ? classes.mobileTopHeadShotMediaContainer
                          : classes.mobileMiddleHeadShotMediaContainer
                      : secondaryClinicianInControl
                        ? screenShareOn
                          ? classes.middleHeadShotMediaContainer
                          : classes.mainMediaContainer
                        : screenShareOn
                          ? classes.topHeadShotMediaContainer
                          : classes.middleHeadShotMediaContainer
                  }
                  style={
                    screenShareOn && secondaryClinicianInControl
                      ? {
                          borderTopRightRadius: 0,
                          borderTopLeftRadius: 0,
                        }
                      : null
                  }
                >
                  <div
                    className={this.setSecondaryClinicianStyling()}
                    ref={this.props.clinicianRemoteMedia}
                    id="clientClinicianRemoteMedia"
                  >
                    {secondaryClinicianConnected && secondaryVideoDisabled && (
                      <div
                        className={
                          secondaryClinicianInControl && !screenShareOn
                            ? classes.mainMediaDisabledPlaceholder
                            : classes.cameraDisabledPlaceholder
                        }
                      >
                        <div
                          className={
                            secondaryClinicianInControl && !screenShareOn
                              ? classes.mainMediaDisabledInitial
                              : classes.cameraDisabledInitial
                          }
                        >
                          <Typography component="p">{secondaryClinicianData?.name[0]}</Typography>
                        </div>
                      </div>
                    )}

                    {((screenShareOn && secondaryClinicianInControl) ||
                      !secondaryClinicianInControl) &&
                      secondaryClinicianConnected &&
                      secondaryClinicianData &&
                      hasJoinedRoom && (
                        <div className={classes.roleLabelContainer}>
                          <Typography component="p" className={classes.roleLabel}>
                            {`${secondaryClinicianData.name} - Supervisory Clinician`}
                          </Typography>
                        </div>
                      )}
                  </div>
                  {(secondaryVideoDisabled || !secondaryClinicianConnected) &&
                    secondaryClinicianInControl &&
                    !callEnded &&
                    !parentScreenSharing &&
                    !screenShareOn && (
                      <div className={classes.mainMediaDisabledPlaceholder}>
                        <div className={classes.mainMediaDisabledInitial}>
                          <Typography component="p">{secondaryClinicianData?.name[0]}</Typography>
                        </div>
                      </div>
                    )}
                </div>
              )}
            <div
              className={
                clientOnMobile
                  ? classes.mobileClientScreenShareMediaContainer
                  : classes.clientScreenShareMediaContainer
              }
              style={{ display: !parentScreenSharing ? "none" : "block" }}
              id="parentScreenShare"
            >
              <div
                ref={this.props.screenShareMedia}
                className={classes.screenShareMedia}
                id="screenShareMedia"
              ></div>
            </div>
            <div
              className={
                clientOnMobile
                  ? classes.mobileClientScreenShareMediaContainer
                  : classes.clientScreenShareMediaContainer
              }
              style={{
                display: !screenShareOn && parentScreenSharing ? "none" : "block",
              }}
            >
              <div
                id="remoteScreenShareMedia"
                ref={this.props.remoteScreenShareMedia}
                className={classes.screenShareMedia}
              ></div>
            </div>
          </>
        )}
        {!endDate && (!clientOnMobile || !hasJoinedRoom) && !secondaryClinicianLeftCall && (
          <ControlsContainer {...this.props} />
        )}
        {!endDate && hasJoinedRoom && clientOnMobile && (
          <ControlsContainer isMobileControls={true} {...this.props} />
        )}
        <NotesDrawer toggleNotes={toggleNotes} {...this.props} />
      </div>
    );
  }
}

export default connect(mapStateToProps, {})(VideoComponent);
