import { setDay, format } from "date-fns";

/**
 * Converts a comma-separated string of numbers representing
 * days into a string of corresponding day names.
 *
 * @param {string} input - The comma-separated string of numbers representing days.
 * @param {string} joiner - The string to join the day names with.
 * @returns {string} - The string of corresponding day names, separated by ', '.
 */
export function convertNumbersToDays(input, joiner = ", ") {
  if (!input) return "";
  const cleanInput = input.split(",").filter((day) => +day >= 1 && +day <= 7);
  return cleanInput.map((day) => format(setDay(new Date(), +day), "eeee")).join(joiner);
}
