export const daysBetween = (date) => {
  const day = date ? date.toString().substr(0, 10) : "";
  if (!day) {
    return "";
  }

  const start = new Date(day);
  const now = new Date();

  const seconds = Math.abs(now - start) / 1000;
  return Math.floor(seconds / 86400); // get number of days out of seconds
};
