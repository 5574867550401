export function getInitialState(props) {
  return {
    id: 0,
    error: false,
    clinicianId: "",
    clientTimezone: "",
    currentTab: "insurance",
    unsavedChanges: false,
    openWarning: false,
    nextLocation: null,
    editButton: false,
    isCancelClicked: false,
    company_name: false,
    company_contact_type: false,
    nextTab: null,
  };
}
