import React, { useEffect, useRef } from "react";

const PandaDocContainer = ({ pandaDocId, onDocumentCompleted }) => {
  const iframeRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const iframe = document.createElement("iframe");
    iframe.src = `https://app.pandadoc.com/s/${pandaDocId}/`;
    iframe.style.height = "100%";
    iframe.style.width = "100%";
    iframe.style.border = "none";
    iframeRef.current = iframe;
    containerRef.current.appendChild(iframe);

    const handleMessage = (event) => {
      if (
        event &&
        event.data &&
        iframeRef.current &&
        event.source === iframeRef.current.contentWindow
      ) {
        let message = event.data;
        try {
          if (typeof message === "string") {
            try {
              message = JSON.parse(event.data);
            } catch (error) {
              console.error("Error parsing JSON data:", error);
              return;
            }
          }
          if (message && message.event) {
            const event = message.event.replace("embed.form.", "");
            // Handle events here
            if (event === "session_view.document.completed") {
              onDocumentCompleted();
            }
          }
        } catch (error) {
          console.error("Error parsing message:", error);
        }
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
      if (containerRef.current && iframeRef.current) {
        containerRef.current.removeChild(iframeRef.current);
      }
      iframeRef.current = null;
    };
  }, [pandaDocId]);

  return <div ref={containerRef} style={{ width: "100%", height: "100%" }}></div>;
};

export default PandaDocContainer;
