import React from "react";
import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import styles from "../styles";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "elements/Modal";
const result = [
  {
    status: "EXCELLENT",
    text: "Your computer's performance is optimal for a video call!",
    color: "#1f640a",
  },
  {
    status: "GOOD",
    text: "Your computer is operating at an acceptable level for a video call.",
    color: "#FFCB18",
  },
  {
    status: "SLOW",
    text: "Your computer is running slow. If your computer has not been rebooted recently, please reboot it. Check programs running in the background and close any browser tabs you aren't using.",
    color: "#c00000",
  },
];

const PerformanceTest = (props) => {
  const {
    classes,
    getComputerPerformance,
    performance,
    isLoading,
    isSuccess,
    handlePerformanceDialogClose,
    performanceDialogOpen,
  } = props;
  const performanceStatus =
    performance > 0 && performance <= 10 ? "EXCELLENT" : performance <= 50 ? "GOOD" : "SLOW";
  return (
    <Modal
      open={performanceDialogOpen}
      onClose={handlePerformanceDialogClose}
      title="Computer Performance Test"
      titleCentered
      content={
        <div style={{ maxWidth: 500 }}>
          <Typography>Please check your computer performance before the session.</Typography>
          <div className={classes.buttonContainer}>
            {isLoading ? (
              <CircularProgress size={65} />
            ) : (
              <Button
                onClick={getComputerPerformance}
                color="primary"
                variant="contained"
                className={classes.checkButton}
              >
                Check
              </Button>
            )}
          </div>

          {isSuccess &&
            result.map((item, i) => {
              if (item.status == performanceStatus) {
                return (
                  <div className={classes.performanceContainer} key={i}>
                    <Typography
                      component="h1"
                      style={{
                        fontSize: 22,
                        fontWeight: 500,
                        color: item.color,
                      }}
                    >
                      {item.status}
                    </Typography>
                    <Typography component="h2">{item.text}</Typography>
                  </div>
                );
              }
            })}
        </div>
      }
      secondaryActionText="Close"
      secondaryActionOnClick={handlePerformanceDialogClose}
    />
  );
};

export default withStyles(styles)(PerformanceTest);
