import React, { Component } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import { addMinutes } from "date-fns";
import styles from "./styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../../actions";
import {
  getCancelVideoCallSuccess,
  getCompleteOffPlatformSuccess,
  getCompletingOffPlatform,
  getOffPlatformVideoBillableTimeId,
  getOffPlatformVideoSessionNoteId,
} from "../../selectors";
import CancelSessionDialog from "../WeeklyCalendarSessions/CancelSessionDialog";
import LoadingButton from "@mui/lab/LoadingButton";
import ANTimePicker from "elements/ANTimePicker";
import ANDatePicker from "elements/ANDatePicker";
import { getCancellationReasonLabel, getKeyCancellationReason } from "../../constants/cancelReasons";

const mapStateToProps = (state) => ({
  cancelVideoCallSuccess: getCancelVideoCallSuccess(state),
  completingOffPlatform: getCompletingOffPlatform(state),
  completeOffPlatformSuccess: getCompleteOffPlatformSuccess(state),
  offPlatformVideoSessionNoteId: getOffPlatformVideoSessionNoteId(state),
  offPlatformVideoBillableTimeId: getOffPlatformVideoBillableTimeId(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      markClientNoShow: actions.markClientNoShow,
      cancelVideoCall: actions.cancelVideoCall,
      completeOffPlatformCall: actions.completeOffPlatformCall,
      getIncompleteOffPlatform: actions.getClinicianIncompleteOffPlatformCalls,
    },
    dispatch,
  );

const StyledInput = withStyles({
  root: {
    // "& label.Mui-focused": {
    //     color: "#545A72",
    // },
    "& .MuiOutlinedInput-root": {
      minHeight: 41,
      "& fieldset": {
        borderRadius: 3,
        color: "#15171D",
        border: "1px solid #E1E3E9",
      },
      backgroundColor: "#F0F1F4",
      fontSize: 14,
      fontWeight: 500,
      "&:hover fieldset": {
        border: "1px solid #C8CAD2",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid #C8CAD2",
      },
    },
    "& .MuiInput-root": {
      minHeight: 41,
      "& input": {
        color: "#1DA0D8",
        paddingBottom: 3,
      },
      backgroundColor: "#ffffff",
      fontSize: 17,
      fontWeight: 500,
      "&:hover fieldset": {
        border: "1px solid #fefefe",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid #fefefe",
      },
      "&:hover": {
        backgroundColor: "#fefefe",
        "&:before": {
          borderBottom: "none",
        },
        "&:after": {
          borderBottom: "none",
        },
      },
      "&:before": {
        borderBottom: "none",
      },
      "&:after": {
        borderBottom: "none",
      },
    },
    "& .MuiInputLabel-root": {
      fontSize: 15,
    },
    "& .MuiFormHelperText-root": {
      backgroundColor: "#fafafa",
      margin: 0,
      padding: "0 5px",
      color: "red",
    },
  },
})(TextField);

export const CustomInput = React.forwardRef((props, ref) => {
  const { variant } = props;
  return <StyledInput variant={variant ? variant : "outlined"} size="small" {...props} />;
});

class CompleteOffPlatformDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      callDetails: {},
      offPlatformResult: true,
      startDate: null,
      endDate: null,
      offPlatformDetailsOpen: true,
      cancelSessionOpen: false,
      cancelSessionDetails: {},
      cancelReasonText: "",
      responsibleForCancellation: "",
      loading: false,
      error: false,
    };
  }

  componentDidMount() {
    this.setState({
      callDetails: this.props.callDetails,
      startDate: this.props.callDetails?.scheduled_date,
      endDate: addMinutes(
        new Date(this.props.callDetails?.scheduled_date),
        this.props.callDetails?.call_duration,
      ),
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.completingOffPlatform && !this.props.completingOffPlatform) {
      this.setState({ loading: false });
      if (this.props.completeOffPlatformSuccess) {
        this.props.onToggleCompleteOffPlatformDialog(null);
        if (this.state.offPlatformResult === true) {
          this.props.openCptCodeDialog(
            {
              ...this.state.callDetails,
              start_date: this.state.startDate,
              end_date: this.state.endDate,
              sessionNoteId: this.props.offPlatformVideoSessionNoteId,
              billableTimeId: this.props.offPlatformVideoBillableTimeId,
            },
            true,
          );
        }
      } else {
        this.setState({ error: true });
      }
    }
  }

  onChange = (name) => (e) => {
    const { value } = e.target;
    this.setState({ [name]: value });
  };

  handleDateChange = (val, name) => {
    let timeError = false;
    if (name === "startDate") {
      if (new Date(this.state.endDate) <= new Date(val)) {
        timeError = true;
      }
      this.setState({ [name]: val, timeError });
    } else {
      if (new Date(val) <= new Date(this.state.startDate)) {
        timeError = true;
      }
      this.setState({ [name]: val, timeError });
    }
  };

  onRadioButtonChange = (name) => (e) => {
    const { value } = e.target;
    this.setState({ [name]: this.stringToBoolean(value) });
  };

  stringToBoolean = (value) => {
    if (value && typeof value === "string") {
      if (value.toLowerCase() === "true") return true;
    }
    return value;
  };

  onChangeCancelReason = (e) => {
    this.setState({ cancelReasonText: e.target.value });
  };

  onChangeResponsibleForCancellation = (e) => {
    let cancelReasonText = getCancellationReasonLabel(e.target.value);
    this.setState({
      responsibleForCancellation: e.target.value,
      cancelReasonText,
    });
  };

  onSubmit = () => {
    const { offPlatformResult, cancelReasonText, startDate, endDate, callDetails } = this.state;
    this.setState({ loading: true, error: false });
    if (offPlatformResult === true) {
      this.props.completeOffPlatformCall({
        startDate,
        endDate,
        id: callDetails.id,
      });
    } else if (offPlatformResult === "no-show") {
      this.props.markClientNoShow(callDetails.id);
    } else {
      this.setState({
        cancelSessionDetails: {
          ...callDetails,
          client_name: `${callDetails?.child_first_name} ${callDetails?.child_last_name}`,
        },
        cancelSessionOpen: true,
        cancelReasonText: "",
        responsibleForCancellation: "",
        offPlatformDetailsOpen: false,
      });
    }
  };

  submitCancelSession = () => {
    const { cancelSessionDetails, cancelReasonText, responsibleForCancellation } = this.state;
    let params = {
      message: cancelReasonText,
      responsibleForCancellation: getKeyCancellationReason(responsibleForCancellation),
      id: cancelSessionDetails.id,
      clinicianUserId: cancelSessionDetails.clinician_user_id,
      clientId: cancelSessionDetails.client_id,
      cancelAllInstances: false,
    };
    this.props.cancelVideoCall(params);
    this.onToggleCancelSession();
  };

  onToggleCancelSession = () => {
    this.setState(
      {
        cancelSessionOpen: !this.state.cancelSessionOpen,
      },
      () => {
        if (!this.state.cancelSessionOpen) {
          this.setState({
            cancelReasonText: "",
            cancelAllInstances: false,
            responsibleForCancellation: "",
            cancelSessionDetails: {},
          });
        }
      },
    );
  };

  buttonDisabled = () => {
    const { cancelReasonText, offPlatformResult } = this.state;
    if (offPlatformResult === "cancelled" && !cancelReasonText) {
      return true;
    }
    return false;
  };

  render() {
    const {
      callDetails,
      offPlatformResult,
      startDate,
      endDate,
      offPlatformDetailsOpen,
      cancelReasonText,
      timeError,
      loading,
      error,
    } = this.state;
    const { classes, onToggleCompleteOffPlatformDialog } = this.props;

    return (
      <>
        <Dialog
          open={offPlatformDetailsOpen}
          onClose={() => onToggleCompleteOffPlatformDialog(null)}
          PaperProps={{
            style: {
              maxWidth: "95%",
              width: 700,
              height: "fit-content",
              overflow: "visible",
            },
          }}
        >
          <DialogTitle className={classes.dialogTitle}>
            Finish Notation for Off-Platform Session
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <Grid container spacing={3}>
              <Grid item sm={12}>
                <FormLabel
                  component="legend"
                  style={{
                    marginBottom: 5,
                    fontSize: 14,
                    fontWeight: 500,
                    color: "#545A72",
                    lineHeight: 1.4,
                  }}
                >
                  Did the video call take place?
                </FormLabel>

                <RadioGroup
                  aria-label="offPlatformResult"
                  name="offPlatformResult"
                  value={offPlatformResult}
                  onChange={this.onRadioButtonChange("offPlatformResult")}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio color="primary" size="small" />}
                    label={
                      <Typography
                        component="p"
                        style={{
                          fontSize: 15,
                          color: "#2A2D39",
                        }}
                      >
                        Yes, it happened
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value={"no-show"}
                    control={<Radio color="primary" size="small" />}
                    label={
                      <Typography
                        component="p"
                        style={{
                          fontSize: 15,
                          color: "#2A2D39",
                        }}
                      >
                        No, the client was a no show
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value={"cancelled"}
                    control={<Radio color="primary" size="small" />}
                    label={
                      <Typography
                        component="p"
                        style={{
                          fontSize: 15,
                          color: "#2A2D39",
                        }}
                      >
                        No, it was canceled
                      </Typography>
                    }
                  />
                </RadioGroup>
              </Grid>
              {offPlatformResult === true && (
                <>
                  <Grid item xs={12} sm={4}>
                    <FormControl margin="none" fullWidth>
                      <FormLabel
                        component="legend"
                        style={{
                          marginBottom: 15,
                          fontSize: 14,
                          fontWeight: 500,
                          color: "#545A72",
                          lineHeight: 1.4,
                        }}
                      >
                        Date
                      </FormLabel>

                      <ANDatePicker
                        id="offplatformDatePicker"
                        value={new Date(startDate) || null}
                        onChange={(date) => this.handleDateChange(date, "startDate")}
                        disableFuture
                        format="P" //"MM/dd/yyyy"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl margin="none" fullWidth>
                      <FormLabel
                        component="legend"
                        style={{
                          marginBottom: 15,
                          fontSize: 14,
                          fontWeight: 500,
                          color: "#545A72",
                          lineHeight: 1.4,
                        }}
                      >
                        Start Time
                      </FormLabel>

                      <ANTimePicker
                        id="start-time"
                        value={new Date(startDate) || null}
                        onChange={(date) => this.handleDateChange(date, "startDate")}
                        minDate={new Date(startDate)}
                        style={{ width: 140 }}
                        variant="outlined"
                        color="primary"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl margin="none" fullWidth>
                      <FormLabel
                        component="legend"
                        style={{
                          marginBottom: 15,
                          fontSize: 14,
                          fontWeight: 500,
                          color: "#545A72",
                          lineHeight: 1.4,
                        }}
                      >
                        End Time
                      </FormLabel>
                      <ANTimePicker
                        id="end-time"
                        value={new Date(endDate) || null}
                        onChange={(date) => this.handleDateChange(date, "endDate")}
                        minDate={new Date(startDate)}
                        style={{ width: 140 }}
                        variant="outlined"
                        color="primary"
                      />
                    </FormControl>
                  </Grid>
                </>
              )}

              {timeError && (
                <Grid item xs={12}>
                  <Typography component="p" style={{ fontSize: 15, color: "#DC2626" }}>
                    End Time cannot be before Start Time
                  </Typography>
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions className={classes.dialogButtons}>
            <LoadingButton variant="outlined" onClick={this.onSubmit} loading={loading}>
              {offPlatformResult === true
                ? "Finish and Add Notes"
                : offPlatformResult == "cancelled"
                  ? "Next"
                  : "Finish"}
            </LoadingButton>
            {error && (
              <Typography component="p" style={{ fontSize: 15, color: "#DC2626", marginTop: 10 }}>
                An error has occurred. Please try again
              </Typography>
            )}
          </DialogActions>
        </Dialog>
        <CancelSessionDialog
          sessionDetails={this.state.cancelSessionDetails}
          open={this.state.cancelSessionOpen}
          cancelReasonText={this.state.cancelReasonText}
          responsibleForCancellation={this.state.responsibleForCancellation}
          closeDialog={this.onToggleCancelSession}
          onChangeCancelReason={this.onChangeCancelReason}
          onChangeResponsibleForCancellation={this.onChangeResponsibleForCancellation}
          submitCancelSession={this.submitCancelSession}
        />
      </>
    );
  }
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(CompleteOffPlatformDialog),
);
