import React from "react";
import { Breadcrumbs, Box } from "@mui/material";
import { CaretRight } from "@phosphor-icons/react";

export const BreadBox = ({ breadcrumbs }) => {
  return (
    <Box
      visibility={!!breadcrumbs ? "visible" : "hidden"}
      width={"100%"}
      display={"flex"}
      flexDirection={"row"}
      justifyContent={"flex-start"}
    >
      <Breadcrumbs separator={<CaretRight />} aria-label="breadcrumb">
        {breadcrumbs}
      </Breadcrumbs>
    </Box>
  );
};
