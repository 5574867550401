export default (theme) => ({
  callInstructionsContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    padding: "100px 60px",
    paddingRight: "40px",
    boxShadow: "0px 0px 3px 1px rgba(55, 55, 55, 0.15)",
    borderRadius: 5,
    width: "90%",
    minHeight: "calc(100vh - 164px)",
    margin: "25px 5%",
    color: "#2A2D39",
    backgroundColor: "#ffffff",
    [theme.breakpoints.down("xl")]: {
      padding: "60px 30px",
      width: "100%",
      margin: "25px 0",
    },
    [theme.breakpoints.down("xl")]: {
      flexDirection: "column",
      padding: "40px 20px",
    },
  },
  goBackButton: {
    position: "absolute",
    left: 60,
    top: 30,
    "& span": {
      color: "#000",
    },
    [theme.breakpoints.down("xl")]: {
      top: 20,
      left: 20,
    },
    [theme.breakpoints.down("xl")]: {
      top: 20,
      left: 20,
    },
  },
  callDetailsContainer: {
    width: "30%",
    minHeight: "calc(100vh - 364px)",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    paddingRight: 60,
    borderRight: "0.5px solid #545a72",
    [theme.breakpoints.down("xl")]: {
      paddingRight: 30,
    },
    [theme.breakpoints.down("xl")]: {
      flexDirection: "row",
      width: "100%",
      alignItems: "center",
      borderRight: "none",
      borderBottom: "0.5px solid #545a72",
      marginBottom: 30,
      paddingRight: 0,
      minHeight: 300,
      height: "fit-content",
      paddingBottom: 20,
    },
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      margin: "20px 0",
    },
  },
  callDetails: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  callTypeContainer: {
    paddingLeft: 30,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    marginBottom: 30,
    [theme.breakpoints.down("lg")]: {
      margin: "20px 0",
    },
  },
  callType: {
    color: "#2A2D39",
    fontSize: 20,
    fontWeight: "500",
    marginBottom: 5,
  },
  callWith: {
    color: "#2A2D39",
    fontSize: 16,
  },
  callTimeDetails: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    color: "#2A2D39",
  },
  callTimeContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: 5,
    "& p": {
      fontSize: 16,
      fontWeight: "500",
    },
  },
  callTimeIcon: {
    "& svg": {
      fill: "#2A2D39",
      fontSize: 20,
    },
    width: 30,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  calendarContainer: {
    width: "100%",
    height: "55%",
    minHeight: 200,
    minWidth: 200,
    [theme.breakpoints.down("xl")]: {
      width: "50%",
      maxHeight: 300,
    },
    [theme.breakpoints.down("lg")]: {
      width: "100%",
      marginTop: 20,
    },
  },
  instructionsContainer: {
    width: "70%",
    paddingLeft: "60px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    "& h1": {
      fontSize: 24,
      fontWeight: 500,
      marginBottom: 15,
    },
    "& h3": {
      fontSize: 20,
      fontWeight: 500,
      marginBottom: 10,
    },
    "& p": {
      fontSize: 16,
      fontWeight: 500,
      marginBottom: 10,
    },
    "& ul": {
      margin: 0,
      "& li": {
        fontSize: 18,
        fontWeight: 500,
        marginBottom: 10,
      },
    },
    "& button": {
      marginLeft: 30,
    },
    [theme.breakpoints.down("xl")]: {
      width: "100%",
      paddingLeft: 0,
    },
    [theme.breakpoints.down("lg")]: {
      "& h1": {
        fontSize: 18,
        marginBottom: 5,
        marginTop: 10,
      },
      "& h3": {
        fontSize: 16,
        marginBottom: 5,
      },
      "& h4": {
        fontSize: 16,
      },
      "& ul": {
        margin: 0,
        paddingLeft: 20,
      },
      "& li": {
        fontSize: "14px !important",
        marginBottom: 0,
      },
      "& button": {
        marginLeft: 0,
      },
    },
  },
  callSpecificInstructions: {
    width: "100%",
    marginBottom: 20,
    borderBottom: "0.5px solid #545a72",
    "& h1": {
      marginBottom: 5,
    },
    "& h3": {
      marginBottom: 5,
    },
    "& p": {
      marginBottom: 10,
    },
  },
  sessionNotesTitleContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginBottom: 20,
  },
  sessionNotesArrowsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 15,
    "& h2": {
      fontSize: 22,
      fontWeight: 500,
      marginRight: 40,
    },
    "& button": {
      marginLeft: 0,
      marginRight: 3,
      padding: 5,
    },
    [theme.breakpoints.down("lg")]: {
      "& h2": {
        fontSize: 17,
        marginRight: 10,
      },
      "& button": {
        marginLeft: 0,
        marginRight: 0,
        padding: 3,
      },
    },
  },
  sessionNotesArrows: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  sessionNoteLoading: {
    flex: 1,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  downloadButtonContainer: {
    width: "70%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
  },
});
