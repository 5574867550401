import React, { useState, useEffect, useRef } from "react";
import useGetClientFormsForSignature from "../hooks/useGetClientFormsForSignature";
import useMarkClientFormAsSkipped from "../hooks/useMarkClientFormAsSkipped";
import Form from "./Form";

const FormsComponent = ({ clientId, markAllFormsCompleted }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [completed, setCompleted] = useState(false);
  const [completedForms, setCompletedForms] = useState({});
  const [fetchEnabled, setFetchEnabled] = useState(true);
  const { forms, isLoading, refetch } = useGetClientFormsForSignature(clientId, fetchEnabled);
  const { mutate, isLoading: skipIsLoading } = useMarkClientFormAsSkipped(clientId);

  const skipTriggeredRef = useRef(false);

  useEffect(() => {
    if (skipTriggeredRef.current) {
      skipTriggeredRef.current = false;
      return;
    }
    if (forms[currentIndex]) {
      const completedId = forms[currentIndex]?.pkey;
      setCompleted(!!completedForms[completedId]);
    }
  }, [currentIndex]);

  useEffect(() => {
    if (!isLoading) {
      setFetchEnabled(false);
    }
  }, [isLoading]);

  const handleSkip = (formId) => {
    skipTriggeredRef.current = true;
    setCurrentIndex(0);
    setFetchEnabled(true);
    mutate(formId);
  };

  const handleNext = () => {
    if (currentIndex < forms.length - 1) {
      setCurrentIndex(currentIndex + 1);
    } else {
      setFetchEnabled(true);
      refetch().then(() => {
        setFetchEnabled(false);
      });
      markAllFormsCompleted();
    }
  };

  const handleBack = () => {
    setCurrentIndex(currentIndex - 1);
  };

  const setFormComplete = () => {
    const completedId = forms[currentIndex].pkey;
    setCompletedForms((prev) => ({ ...prev, [completedId]: true }));
    setCompleted(true);
  };

  return forms?.length > 0 && !isLoading ? (
    <Form
      formData={forms[currentIndex]}
      handleNext={handleNext}
      handleBack={handleBack}
      index={currentIndex}
      completed={completed}
      setFormComplete={setFormComplete}
      handleSkip={handleSkip}
      isLoading={isLoading}
      skipIsLoading={skipIsLoading}
    />
  ) : null;
};

export default FormsComponent;
