import React from "react";
import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { format } from "date-fns";
import { styles } from "./styles";
import { convertToISO } from "utils/convertToISO";
import RequestNewClinician from "../RequestNewClinician";
import StyledDetailSection from "./Header";
import { Box, Avatar, Stack } from "@mui/material";

import {
  ArrowsLeftRight,
  ChatCircleDots,
  IdentificationBadge,
  LinkBreak,
  Warning,
} from "@phosphor-icons/react";
import { useTheme } from "@mui/styles";

const AssignedClinicians = (props) => {
  const {
    classes,
    clinician,
    loading,
    onClickChatWithClinician,
    editDisabled,
    chatId,
    history,
    hasScheduledCall,
    userPermissions,
    assignClinicianOpen,
    onOpenAssignClinician,
    onCloseAssignClinician,
    onOpenAssignClinicianConfirm,
    onOpenRemoveClinicianConfirm,
    assignedRole,
    assignableClinicians,
    assignableCliniciansLoading,
    secondaryClinician,
    assignBlockRef,
  } = props;

  const theme = useTheme();

  const mapTimeSlotsToDisplay = (timeSlots) => {
    return (
      <div className={classes.availabilitySlotContainer}>
        {timeSlots.map((slot, i) => {
          return (
            <Typography key={i} component="p" style={{ fontSize: 14 }}>
              {`${format(convertToISO(slot.range_start), "p")} - 
                            ${format(convertToISO(slot.range_end), "p")}`}
            </Typography>
          );
        })}
      </div>
    );
  };

  const filteredAssignableClinicians = assignableClinicians?.filter((c) => {
    if (!!clinician || !!secondaryClinician) {
      return (
        c.clinician_id !== clinician?.clinician_id &&
        c.clinician_id !== secondaryClinician?.clinician_id
      );
    } else {
      return c;
    }
  });

  const initials = `${clinician?.first_name?.[0]?.toUpperCase() || ""}${clinician?.last_name?.[0]?.toUpperCase() || ""}`;
  const secondaryInitials = `${secondaryClinician?.first_name?.[0]?.toUpperCase() || ""}${secondaryClinician?.last_name?.[0]?.toUpperCase() || ""}`;
  return (
    <StyledDetailSection
      title="Assigned Clinicians"
      subHeader={"Set the primary and supervisory clinicians for the client"}
    >
      {!assignClinicianOpen ? (
        <Box className={classes.DetailSection}>
          <Box className={classes.clinicianBox}>
            {!!clinician ? (
              <Stack direction={"row"} justifyContent={"space-between"} width={"100%"}>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Avatar src={clinician.image_url} height="40px">
                    {initials}
                  </Avatar>
                  <div className={classes.clinicianNameInfo}>
                    <Typography variant="body1">{`${clinician.first_name} ${clinician.last_name}`}</Typography>
                    <Typography variant="body2" color="text.secondary">
                      {clinician?.is_director
                        ? "Clinical Director"
                        : secondaryClinician?.is_supervisor
                          ? "Clinical Supervisor"
                          : "Primary Clinician"}
                    </Typography>
                  </div>
                </Stack>
                <Stack direction={"row"} justifyContent={"space-between"} spacing={"3px"}>
                  {userPermissions?.create_support_chat_with_clinician && (
                    <Box justifyContent={"center"} alignItems={"center"} display={"flex"}>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={onClickChatWithClinician}
                        disabled={editDisabled}
                        startIcon={<ChatCircleDots />}
                      >
                        Chat
                      </Button>
                    </Box>
                  )}
                  {userPermissions?.view_chat_log_with_clinician && (
                    <Box justifyContent={"center"} alignItems={"center"} display={"flex"}>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => history.push(`/chats/${chatId}`)}
                        startIcon={<ChatCircleDots weight="duotone" />}
                      >
                        View Chat
                      </Button>
                    </Box>
                  )}
                  {userPermissions?.assign_clinician && (
                    <Box justifyContent={"center"} alignItems={"center"} display={"flex"}>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => onOpenAssignClinician("primary")}
                        // PATCH::  Always allows primary clinician change
                        // disabled={editDisabled || hasScheduledCall}
                        disabled={editDisabled}
                        startIcon={<ArrowsLeftRight weight="duotone" />}
                      >
                        Reassign Clinician
                      </Button>
                    </Box>
                  )}
                </Stack>
              </Stack>
            ) : (
              <Stack
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                width={"100%"}
              >
                <Box display={"flex"} gap={"8px"}>
                  <Warning weight="duotone" fill={theme.palette.warning.main} size={24} />
                  <Box justifyContent={"center"} alignItems={"center"} display={"flex"}>
                    <Typography variant="body1" color={"text.secondary"}>
                      No Clinician assigned
                    </Typography>
                  </Box>
                </Box>
                {userPermissions?.assign_clinician && (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => onOpenAssignClinician("primary")}
                    disabled={editDisabled}
                    startIcon={<IdentificationBadge weight="duotone" />}
                  >
                    Assign Clinician
                  </Button>
                )}
              </Stack>
            )}
          </Box>
          <Box className={classes.clinicianDivider} />
          <Box className={classes.clinicianBox}>
            {!!secondaryClinician ? (
              <Stack direction={"row"} justifyContent={"space-between"} width={"100%"}>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Avatar src={secondaryClinician.image_url}>{secondaryInitials}</Avatar>
                  <div className={classes.clinicianNameInfo}>
                    <Typography variant="body1">{`${secondaryClinician.first_name} ${secondaryClinician.last_name}`}</Typography>
                    <Typography variant="body2" color={"text.secondary"}>
                      {secondaryClinician?.is_director
                        ? "Clinical Director"
                        : secondaryClinician?.is_supervisor
                          ? "Clinical Supervisor"
                          : "Supervisory Clinician"}
                    </Typography>
                  </div>
                </Stack>
                <div className={classes.info}>
                  {userPermissions?.assign_secondary_clinician && (
                    <Button
                      aria-label="delete"
                      variant="contained"
                      color="secondary"
                      disabled={editDisabled}
                      onClick={() => onOpenRemoveClinicianConfirm(secondaryClinician)}
                      startIcon={<LinkBreak />}
                    >
                      Remove as Supervisor
                    </Button>
                  )}
                  {userPermissions?.assign_secondary_clinician && (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => onOpenAssignClinician("secondary")}
                      disabled={editDisabled}
                      startIcon={<ArrowsLeftRight weight="duotone" />}
                    >
                      Reassign Supervisor
                    </Button>
                  )}
                </div>
              </Stack>
            ) : (
              <Stack
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                width={"100%"}
              >
                <Box display={"flex"} gap={"8px"} justifyContent={"center"} alignItems={"center"}>
                  <Warning weight="duotone" fill="#ED6C02" size={24} />
                  <Box
                    justifyContent={"center"}
                    alignItems={"center"}
                    display={"flex"}
                    height={"100%"}
                  >
                    <Typography variant="body1" color={"text.secondary"}>
                      No Supervisor assigned
                    </Typography>
                  </Box>
                </Box>
                {userPermissions?.assign_clinician && (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => onOpenAssignClinician("secondary")}
                    disabled={editDisabled || !clinician}
                    startIcon={<IdentificationBadge weight="duotone" />}
                  >
                    Assign Supervisor
                  </Button>
                )}
              </Stack>
            )}
          </Box>
          {/* PATCH::  Always allows primary clinician change */}
          {/* {!!clinician && hasScheduledCall && userPermissions?.assign_clinician && (
            <Alert severity="error" className={classes.alertContainer}>
              The client has scheduled call/s. Please, cancel all calls before assigning a new
              primary clinician.
            </Alert>
          )} */}
        </Box>
      ) : (
        <div className={classes.assignContainer} ref={assignBlockRef}>
          {filteredAssignableClinicians.length == 0 ? (
            <Box margin="24px 0 24px 0">
              <div className={classes.clinicianInfo}>
                <Typography variant="body1">NO ASSIGNABLE CLINICIANS</Typography>
                <Button color="secondary" variant="contained" onClick={onCloseAssignClinician}>
                  Go Back
                </Button>
              </div>
            </Box>
          ) : (
            <>
              <div className={classes.info}>
                <Typography component="p" className={classes.subtitle} style={{ marginBottom: 5 }}>
                  {assignedRole === "primary"
                    ? "Select Primary Clinician"
                    : "Select Supervisory Clinician"}
                </Typography>
                <Button
                  onClick={onCloseAssignClinician}
                  color="secondary"
                  variant="contained"
                  style={{ fontWeight: 500 }}
                >
                  Go Back
                </Button>
              </div>
              {filteredAssignableClinicians.map((c) => (
                <div className={classes.clinicianAvailabilityInfoContainer} key={c.clinician_id}>
                  <div className={classes.clinicianAvailabilityInfo}>
                    <Avatar imageUrl={c.image_url} />
                    <div className={classes.clinicianAvailabilityBasicInfo}>
                      <Typography component="p" className={classes.clinicianName}>
                        {c.name}
                        <span className={classes.availabilityRole}>
                          {"  -  "}
                          {c.is_director
                            ? "Clinical Director"
                            : c?.is_supervisor
                              ? "Clinical Supervisor"
                              : "Clinician"}
                        </span>
                      </Typography>
                      <div className={classes.clinicianInfo}>
                        <Button
                          onClick={() => onOpenAssignClinicianConfirm(c)}
                          variant="contained"
                          color="secondary"
                          style={{ fontWeight: 500 }}
                        >
                          Select
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      )}

      <RequestNewClinician />
    </StyledDetailSection>
  );
};

export default withStyles(styles)(AssignedClinicians);
