import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import SignatureCanvas from "react-signature-canvas";
import { formatToTimeZone } from "date-fns-timezone";
import { format } from "date-fns";

import { convertTokens } from "@date-fns/upgrade/v2";

const ClientSignaturesDialog = (props) => {
  const [signatureComplete, setSignatureComplete] = useState(false);
  const [showIncomplete, setShowIncomplete] = useState(false);
  const {
    classes,
    open,
    serviceType,
    primaryClinicianFirstName,
    onSubmitSignatures,
    sendingVideoSignatures,
    sigPadStart,
    sigError,
    oneTimeVideoInfo,
    startDate,
    endDate,
  } = props;

  useEffect(() => {
    if (sigPadStart?.current) {
      const canvas = sigPadStart.current;
      canvas.style.width = "100%";
      canvas.style.height = "100%";
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;
    }
  }, []);

  const onSubmit = () => {
    if (!signatureComplete) {
      setShowIncomplete(true);
      return;
    }
    onSubmitSignatures();
  };

  return (
    <Dialog
      open={true}
      hideBackdrop={true}
      aria-labelledby="form-dialog-title"
      style={{ zIndex: 3001 }}
      PaperProps={{
        style: {
          maxWidth: "95%",
          width: 650,
          margin: 0,
        },
      }}
    >
      <Typography component="h1" className={classes.clientSignatureTitle}>
        Your call has been completed
      </Typography>
      <DialogContent className={classes.clientSignatureContent}>
        <DialogContentText
          style={{
            fontSize: 14,
            fontWeight: "500",
            color: "#15171D",
            marginTop: 0,
            lineHeight: "20px",
          }}
        >
          Please sign below to confirm the start and end times of your session.
        </DialogContentText>
        <div className={classes.signaturesContainer}>
          <Typography component="h5">
            {serviceType} Call with {primaryClinicianFirstName} on{" "}
            {format(new Date(startDate), convertTokens("dddd, MMMM Do"))}
          </Typography>
          <div className={classes.signatureStartEndTimesContainer}>
            <div className={classes.signatureStartEndTimes}>
              <Typography component="p">Start Time</Typography>
              <Typography component="h6">
                {oneTimeVideoInfo?.call_timezone
                  ? formatToTimeZone(startDate, "h:mm a (z)", {
                      timeZone: oneTimeVideoInfo.call_timezone,
                    })
                  : format(new Date(startDate), convertTokens("h:mm A"))}
              </Typography>
            </div>
            <div className={classes.signatureStartEndTimes}>
              <Typography component="p">End Time</Typography>
              <Typography component="h6">
                {oneTimeVideoInfo?.call_timezone
                  ? formatToTimeZone(endDate, "h:mm a (z)", {
                      timeZone: oneTimeVideoInfo.call_timezone,
                    })
                  : format(new Date(endDate), convertTokens("h:mm A"))}
              </Typography>
            </div>
          </div>
          <div className={classes.signatureCanvasContainer}>
            <div className={classes.signatureCanvas}>
              <SignatureCanvas
                ref={sigPadStart}
                penColor="#4e4e4e"
                onBegin={() => {
                  setSignatureComplete(true);
                  setShowIncomplete(false);
                }}
              />
            </div>
          </div>
          {showIncomplete && (
            <Typography className={classes.sigError} component="p">
              Please sign above to confirm the start and end times of the session
            </Typography>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        {sigError && (
          <Typography className={classes.sigError} style={{ marginLeft: 16 }} component="p">
            An error occurred while processing your signatures. Please try again.
          </Typography>
        )}
        <LoadingButton
          onClick={onSubmit}
          loading={sendingVideoSignatures}
          disabled={showIncomplete}
          style={{
            width: 150,
            height: 36,
            fontWeight: "500",
            margin: "6px 16px 16px 0",
          }}
          color="primary"
        >
          Finish and close
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ClientSignaturesDialog;
