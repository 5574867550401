import React from "react";
import { withRouter } from "react-router";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Logo from "../../assets/answers_now_logo_dark_purple.png";
import Link from "@mui/material/Link";

const RecoveryEmailConfirmation = (props) => {
  const { classes, onSubmit } = props;
  const year = new Date().getFullYear();
  return (
    <div className={classes.container}>
      <div className={classes.forgotPasswordFormContainer}>
        <CssBaseline />
        <img src={Logo} className={classes.signinLogo} />
        <div className={classes.forgotPasswordForm}>
          <form className={classes.form} onSubmit={onSubmit}>
            <Typography component="h1" variant="h5" className={classes.forgotPasswordTitle}>
              Information Submitted
            </Typography>
            <div className={classes.forgotPasswordFormBody}>
              <Typography component="p" className={classes.forgotPasswordText}>
                We've received your form and will be reaching out to you soon to get more
                information and finish setting up your account. If you have questions, you can
                contact us at (804) 215-5600.
              </Typography>
              <Typography component="p" className={classes.forgotPasswordText}>
                If you're interested in this process or what this will look like for you, check out
                our blog on teleABA{" "}
                <Link href="https://www.getanswersnow.com/blog/myth-busting-aba-tele-health-delivery">
                  HERE.
                </Link>
              </Typography>
            </div>
          </form>
        </div>
      </div>
      <div className={classes.footerContainer}>
        <Typography component="p" className={classes.footerText}>
          Version: {APP_VERSION} Copyright &copy; {year} AnswersNow &#x2022; All rights reserved
        </Typography>
      </div>
    </div>
  );
};

export default withRouter(RecoveryEmailConfirmation);
