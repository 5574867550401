import React from "react";
import Typography from "@mui/material/Typography";
import CalendarSyncImageGoogle from "../../assets/cal_sync.png";
import { ArrowsClockwise } from "@phosphor-icons/react";
import Button from "@mui/material/Button";
import { LoadingButton } from "@mui/lab";

const GoogleSync = (props) => {
  const { classes, handleSyncButtonClick, isLoading, onClose } = props;

  return (
    <>
      <div className={classes.dialogContentText}>
        <Typography variant="h5" align="center">
          Integrate your AnswersNow and Google Calendars
        </Typography>
        <Typography variant="body2" className={classes.body2} align="center">
          AnswersNow connects with Google, automating the scheduling process from start to finish.
          Scheduled events are added directly to your calendar, removing any unnecessary steps in
          between.
        </Typography>
      </div>
      <img src={CalendarSyncImageGoogle} />
      <div className={classes.dialogContentSync}>
        <LoadingButton
          onClick={() => handleSyncButtonClick(true)}
          className={classes.button}
          color="primary"
          startIcon={<ArrowsClockwise />}
          loading={isLoading}
        >
          Sync Calendars
        </LoadingButton>
        <Button onClick={onClose} variant="text" color="primary">
          Dismiss
        </Button>
      </div>
    </>
  );
};

export default GoogleSync;
