import { debounce } from "lodash";
import { log } from "video/debug";

/** @typedef {"144p"|"222p"|"360p"|"540p"|"720p"|"1080p"|"2160p"} VideoInputQuality */

const KiB = 2 ** 10;
const MiB = 2 ** 20;

export default class VideoInputController {
  /** @type {"AUTO" | VideoInputQuality} */
  desiredVideoInputQuality = "AUTO";

  /** @type {(vts:import('amazon-chime-sdk-js').VideoTileState)=>HTMLElement} */
  userVideoContainerLogic;

  videoTileStates = {};

  videoQualitySettings = { width: 960, height: 540, frameRate: 15 };
  maxBitrateKbps = 1000;
  /** @type {VideoInputQuality} */
  videoInputQuality = "360p";

  /** @type {import('video/meeting/MeetingSession').default} */
  meetingSession;

  __device = null;
  __started = false;

  /**
   * number of active Video Tiles
   */
  get length() {
    return this.meetingSession?.audioVideo?.getAllVideoTiles().length || 0;
  }

  /**
   * @param {import('./MeetingSession').default} meetingSession
   * @param {typeof this.userVideoContainerLogic} userVideoContainerLogic
   * @param {"AUTO"|VideoInputQuality} desiredVideoQuality
   */
  constructor(meetingSession, userVideoContainerLogic, desiredVideoQuality = "AUTO") {
    this.meetingSession = meetingSession;
    this.desiredVideoInputQuality = desiredVideoQuality;
    this.userVideoContainerLogic = userVideoContainerLogic;

    // start at 360p and ramp-up as available bitrate increases
    this.setVideoInputQuality(
      desiredVideoQuality === "AUTO" ? "360p" : desiredVideoQuality || "540p"
    );
  }
  destroy() {
    this.__device = null;
    this.__started = null;
    this._onStart = [];
  }

  setDevice(device) {
    if (device !== this.__device) {
      log("setting video input device:", device);
      this.__device = device;
    }
  }

  async changeDevice(device) {
    if (device !== this.__device) {
      this.setDevice(device);
    }
    return this.restart();
  }

  /**
   * @param {import('amazon-chime-sdk-js').VideoInputDevice} device
   */
  async start() {
    // if (this.__started) return;
    this.__started = true;

    await this.meetingSession?.audioVideo?.startVideoInput(this.__device || null);

    this.onLocalVideoAvailable(() => {
      if (!this.meetingSession?.audioVideo?.hasStartedLocalVideoTile()) {
        // startVideoInput must be finished before this is called
        log("starting local video tile", this.__device);
        this.localVideoTileId = this.meetingSession?.audioVideo?.startLocalVideoTile();
        this.__onStart(this.localVideoTileId);
      }
    });
  }

  onStart(cb) {
    this._onStart.push(cb);
  }
  _onStart = [];
  __onStart() {
    this._onStart.forEach((cb) => cb());
  }

  async restart() {
    // if (this.__started) await this.stop();
    return this.start();
  }

  async stop() {
    log("stopping video input");
    this.localVideoTileId = null;
    this.meetingSession.audioVideo?.stopLocalVideoTile();
    await this.meetingSession.audioVideo?.stopVideoInput();
    this.__started = false;
  }

  getUserVideoContentTileState(userId = "") {
    return this.meetingSession?.audioVideo
      ?.getAllVideoTiles()
      .find(
        (videoTile) =>
          videoTile.stateRef().boundExternalUserId == userId && videoTile.stateRef().isContent
      )
      ?.state();
  }

  getUserVideoTileState(userId = "") {
    return this.meetingSession?.audioVideo
      ?.getAllVideoTiles()
      .find(
        (videoTile) =>
          videoTile.stateRef().boundExternalUserId == userId && !videoTile.stateRef().isContent
      )
      ?.state();
  }

  /**
   * Ensure each `active` Video Tile is bound to a `<video />`
   */
  checkVideoTileBindings = () => {
    this.meetingSession?.audioVideo?.getAllVideoTiles().forEach(this.checkVideoTileBinding);
  };

  checkVideoTileBinding = /** @param {import('amazon-chime-sdk-js').VideoTile} videoTile */ (
    videoTile
  ) => {
    // indeterminate without our user id
    if (!videoTile?.stateRef().boundExternalUserId) {
      videoTile?.bindVideoElement(null);
      return;
    }

    // determine which Video Container this tile should be in
    const videoContainer = this.userVideoContainerLogic(videoTile.stateRef());
    if (!videoContainer) {
      console.warn("unable to determine video container for tile", videoTile.state());
      return;
    }

    // get/create the <video/> el within the container
    const videoEl = this.createVideoElement(videoContainer, videoTile.stateRef());
    if (!videoEl) {
      console.warn("unable to get video element for tile", videoContainer, videoTile.state());
      return;
    }

    if (videoEl !== videoTile.stateRef().boundVideoElement) {
      log("binding video element for tile:", videoTile.id);
    }
    // we're assuming the function will check if it actually changed
    videoTile.bindVideoElement(videoEl);
  };

  /**
   * @param {HTMLElement} videoContainer
   * @param {import('amazon-chime-sdk-js').VideoTileState} videoTileState
   */
  createVideoElement(videoContainer, videoTileState) {
    if (!videoContainer || !videoTileState) return;

    let videoEl = videoContainer.querySelector("video");
    if (!videoEl) {
      log("creating video element for stream %s", videoTileState.tileId);
      videoEl = document.createElement("video");
      videoEl.id = "video-tile-" + videoTileState.tileId;
      videoContainer.appendChild(videoEl);
    }
    return videoEl;
  }

  /**
   * @param {VideoInputQuality} videoInputQuality
   */
  setVideoInputQuality(videoInputQuality = "540p") {
    if (videoInputQuality === this.videoInputQuality) {
      return;
    }
    this.videoInputQuality = videoInputQuality;
    log("setVideoQuality", videoInputQuality);
    switch (videoInputQuality) {
      case "144p":
        this.videoQualitySettings = { width: 192, height: 144, frameRate: 10 };
        this.maxBitrateKbps = 250;
        break;
      case "222p":
        this.videoQualitySettings = { width: 400, height: 222, frameRate: 10 };
        this.maxBitrateKbps = 500;
        break;
      case "360p":
        this.videoQualitySettings = { width: 640, height: 360, frameRate: 15 };
        this.maxBitrateKbps = 750;
        break;
      case "540p":
        this.videoQualitySettings = { width: 960, height: 540, frameRate: 15 };
        this.maxBitrateKbps = 1000;
        break;
      case "720p":
        this.videoQualitySettings = { width: 1280, height: 720, frameRate: 15 };
        this.maxBitrateKbps = 1250;
        break;

      // 1080p/2160p not currently used
      case "1080p":
        this.maxBitrateKbps = 2500;
        this.videoQualitySettings = {
          width: 1920,
          height: 1080,
          frameRate: 15,
        };
        break;
      case "2160p":
        this.maxBitrateKbps = 2500;
        this.videoQualitySettings = {
          width: 3840,
          height: 2160,
          frameRate: 15,
        };
        break;
      default:
        return this.setVideoInputQuality();
    }
    this.maxBitrateKbps = Math.min(2500, this.maxBitrateKbps); // max of 2500kbps
    this.meetingSession.audioVideo.setVideoMaxBandwidthKbps(this.maxBitrateKbps);
    this.meetingSession.audioVideo.chooseVideoInputQuality(
      this.videoQualitySettings.width,
      this.videoQualitySettings.height,
      this.videoQualitySettings.frameRate
    );
  }

  /** @param {import('amazon-chime-sdk-js').VideoTileState} videoTileState */
  videoTileDidUpdate(videoTileState) {
    const videoTile = this.meetingSession?.audioVideo?.getVideoTile(videoTileState.tileId);
    if (videoTile) this.checkVideoTileBinding(videoTile);
  }

  /**
   * Called whenever a tile has been removed.
   * @param {number} tileId
   */
  videoTileWasRemoved = (tileId) => {
    const tile = this.meetingSession?.audioVideo?.getVideoTile(tileId);
    const el =
      tile?.stateRef()?.boundVideoElement || document.getElementById(`video-tile-${tileId}`);
    log("removing <video/> element:", tileId);

    // we're assuming no other <video/> element with the same tileId was created by now...
    el?.remove();
  };

  /**
   * @param {{canStartLocalVideo:boolean,remoteVideoAvailable:true}} availability
   */
  videoAvailabilityDidChange = (availability) => {
    this.canStartLocalVideo = availability.canStartLocalVideo;
    if (this.canStartLocalVideo) this._onLocalVideoAvailable();
  };

  /**
   * @param {"AUTO"|VideoInputQuality} videoInputQuality
   */
  setDesiredVideoInputQuality(videoInputQuality) {
    this.desiredVideoInputQuality = videoInputQuality;
    if (this.desiredVideoInputQuality === "AUTO") {
      this.__autoSetVideoInputQuality();
    } else {
      this.setVideoInputQuality(this.desiredVideoInputQuality);
    }
  }

  /**
   * @param {import('./AudioVideoController').default} audioVideoController
   */
  __autoSetVideoInputQuality(
    { availableOutgoingBitrate, availableIncomingBitrate } = this.meetingSession
      ?.audioVideoController || {}
  ) {
    if (this.desiredVideoInputQuality === "AUTO") {
      const videoInputQuality = `${this.videoInputQuality}`;
      const checkBitrate = (availableOutgoingBitrate + availableIncomingBitrate) / 2;
      if (checkBitrate && checkBitrate < 100 * KiB) {
        // < 200KiB/s
        this.setVideoInputQuality("144p");
      } else if (checkBitrate && checkBitrate < 250 * KiB) {
        // 200KiB/s - 500KiB/s
        this.setVideoInputQuality("222p");
      } else if (checkBitrate && checkBitrate < 0.5 * MiB) {
        // 500KiB/s - 1MiB/s
        this.setVideoInputQuality("360p");
      } else if (checkBitrate && checkBitrate < 1 * MiB) {
        // 1MiB/s - 1.5MiB/s
        this.setVideoInputQuality("540p");
      } else if (checkBitrate) {
        // > 1.5MiB/s
        this.setVideoInputQuality("720p");
      } else {
        // ??KiB/s - use the default of 360p -
        this.setVideoInputQuality("360p");
      }
      if (videoInputQuality !== this.videoInputQuality) {
        log("videoQualityAutoChange", videoInputQuality);
        this.__onVideoQualityAutoChange();
      }
    }
  }

  // event listeners

  /**
   * @param {(
   *  videoInputQuality:typeof this.videoInputQuality,
   *  videoQualitySettings: typeof this.videoQualitySettings,
   *  maxBitrateKbps: typeof this.maxBitrateKbps
   * )=>void} cb
   */
  onVideoQualityAutoChange(cb) {
    this._onVideoQualityAutoChangeCbs.push(cb);
  }
  _onVideoQualityAutoChangeCbs = [];
  __onVideoQualityAutoChange() {
    this._onVideoQualityAutoChangeCbs.forEach((cb) =>
      cb(this.videoInputQuality, this.videoQualitySettings, this.maxBitrateKbps)
    );
  }

  __localVideoAvailableCbs = [];
  _onLocalVideoAvailable() {
    this.__localVideoAvailableCbs.forEach((cb) => cb());
  }
  onLocalVideoAvailable(cb) {
    if (!this.canStartLocalVideo) this.__localVideoAvailableCbs.push(cb);
    else cb();
  }
}
