const APP_ID = process.env.INTERCOM_APP_ID;

/**
 * return IntercomAi configuration (ie window.intercomSettings = getIntercomAiConfiguration(userInfo...))
 */
export const getIntercomAiConfiguration = (userInfo = {}, location = {}) => {
  const {
    name, // full name
    email, // email address
    userId, // user id
    isClient, // boolean
    isClinician, //boolean
    isSupervisor, // boolean
    isDirector, // boolean
    user_hash, // HMAC code
  } = userInfo;
  const { alignment, horizontalPadding, verticalPadding } = location;

  let config = {
    app_id: APP_ID,
  };

  config = {
    ...config,
    alignment: alignment ?? "right",
    vertical_padding: verticalPadding ?? 0,
    horizontal_padding: horizontalPadding ?? 0,
  };

  if (name && email && userId) {
    config = {
      ...config,
      name,
      email,
      user_id: userId,
      isClient: !!isClient,
      isClinician: !!isClinician,
      isSupervisor: !!isSupervisor,
      isDirector: !!isDirector,
      user_hash,
    };
  }

  return { ...config };
};

/**
 * Displays IntercomAi icon
 */
export const openIntercomAi = (userInfo, location) => {
  window?.Intercom("boot", getIntercomAiConfiguration(userInfo, location));
};

/**
 * Hides IntercomAi icon
 */
export const closeIntercomAi = () => {
  window?.Intercom("shutdown");
};

/**
 * Hides chat panel
 */
export const hideChatPanel = () => {
  window?.Intercom("hide");
};

/**
 * Shows chat panel
 */
export const showChatPanel = () => {
  window?.Intercom("show");
};

/**
 * More information: https://www.intercom.com/help/en/articles/2900893-automatically-show-your-product-tour-to-the-right-customers
 * For triggering walkthrought of products or features
 */
export const tour = () => {
  throw new Error("Implementation Error: No tours created in Intercom, 'tour' Not Implemented");
  // window?.Intercom("startTour", 123);
};
