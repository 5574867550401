import React from "react";
import { useFormikContext } from "formik";
import withStyles from "@mui/styles/withStyles";
import { Box, TextField, Stack, MenuItem, CircularProgress } from "@mui/material";

import styles from "../styles";
import { useGetClinicalLeads } from "hooks/useGetClinicalLeads";
import ANFormView from "elements/Forms/ANFormView";
import SectionBox from "elements/SectionBox";
import ANDatePicker from "elements/ANDatePicker";
import { dateWithoutTimezone } from "utils/dateWithoutTimezone";

function getEmploymentOptions() {
  return [
    { value: "W2_1_FTE", label: "BCBA - W2 1 FTE", maxBH: 36 },
    { value: "W2_75_FTE", label: "BCBA - W2 .75 FTE", maxBH: 27 },
    { value: "W2_5_FTE", label: "BCBA - W2 .5 FTE", maxBH: 19 },
    { value: "Contractor", label: "BCBA - Contractor" },
    { value: "Clinical_Assessor", label: "Clinical Assessor" },
    { value: "Clinical_Lead", label: "Clinical Lead" },
  ];
}

const EmploymentDetails = (props) => {
  const { editMode, userPermissions, basicInfo } = props;
  const { leads, leadsLoading } = useGetClinicalLeads();
  const { values, handleChange, handleBlur, setFieldValue } = useFormikContext();

  if (leadsLoading) return <CircularProgress />;

  const findLeadById = (id) => {
    const supervisor = leads.find((lead) => lead.user_id === id);
    return supervisor ? supervisor.first_name + " " + supervisor.last_name : "";
  };

  const employmentOptions = getEmploymentOptions();

  const handleEmploymentTypeChange = (event) => {
    const { value } = event.target;
    // Find the selected employmentType and set the max billable hours
    const selectedOption = employmentOptions.find((option) => option.value === value);

    if (selectedOption?.maxBH) {
      setFieldValue("maxBillableHour", selectedOption.maxBH, true);
    }

    setFieldValue("employmentType", value, true);
  };

  return (
    <SectionBox
      title="Employment Details"
      description={"Manage employee and supervisor settings"}
      columnDirection
    >
      {editMode ? (
        <>
          {userPermissions?.approve_clinician_application && (
            <Stack gap={7}>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={5}
                justifyContent="space-between"
              >
                <Box flex={1}>
                  <TextField
                    select
                    name="employmentType"
                    label="Employment Type"
                    value={values.employmentType}
                    onChange={handleEmploymentTypeChange}
                    onBlur={handleBlur}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  >
                    <MenuItem disabled value={""}>
                      Select Employment Type
                    </MenuItem>
                    {employmentOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>

                <Box flex={1}>
                  <TextField
                    name="maxBillableHour"
                    label="Max Billable Hours"
                    value={values.maxBillableHour}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                  />
                </Box>
              </Stack>

              <TextField
                select
                name="supervisor"
                label="Supervisor"
                value={values.supervisor}
                onChange={handleChange}
                onBlur={handleBlur}
                InputLabelProps={{ shrink: true }}
                fullWidth
              >
                <MenuItem disabled value={""}>
                  Select Supervisor
                </MenuItem>
                {leads.map((lead) => (
                  <MenuItem key={lead.user_id} value={lead.user_id}>
                    {lead.first_name} {lead.last_name}
                  </MenuItem>
                ))}
              </TextField>

              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={5}
                justifyContent="space-between"
              >
                <Box flex={1}>
                  <ANDatePicker
                    value={values.employmentStartDate}
                    onChange={(value) => setFieldValue("employmentStartDate", value, true)}
                    label="Employment Start Date"
                    format="PP" //"MMM d, yyyy"
                    sx={{ width: "100%" }}
                    disableFuture
                  />
                </Box>

                <Box flex={1}>
                  <ANDatePicker
                    value={values.employmentLastDate}
                    onChange={(value) => setFieldValue("employmentLastDate", value, true)}
                    label="Employment Last Day"
                    format="PP" //"MMM d, yyyy"
                    sx={{ width: "100%" }}
                    disableFuture
                  />
                </Box>
              </Stack>
            </Stack>
          )}
        </>
      ) : (
        <ANFormView
          data={{
            employmentType:
              employmentOptions.find((option) => option.value === basicInfo?.employment_type)
                ?.label || "",
            maxBillableHour: basicInfo?.max_billable_hour || "",
            supervisor: findLeadById(basicInfo?.supervisor_user_id) || "",
            employmentStartDate: basicInfo?.start_date
              ? dateWithoutTimezone(basicInfo?.start_date)
              : null,
            employmentLastDate: basicInfo?.end_date
              ? dateWithoutTimezone(basicInfo?.end_date)
              : null,
          }}
          config={[
            { label: "Employment Type", dataKey: "employmentType", cols: 6, type: "select" },
            { label: "Max Billable Hours", dataKey: "maxBillableHour", cols: 6 },
            {
              label: "Supervisor",
              dataKey: "supervisor",
              type: "select",
              cols: 12,
            },
            {
              label: "Employment Start Date",
              dataKey: "employmentStartDate",
              format: "jsonDate",
              cols: 6,
            },
            {
              label: "Employment Last Day",
              dataKey: "employmentLastDate",
              format: "jsonDate",
              cols: 6,
            },
          ]}
        ></ANFormView>
      )}
    </SectionBox>
  );
};

export default withStyles(styles)(EmploymentDetails);
