import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import actions from "../../actions";
import { getSignedinState, getForgotPasswordSent, getGroups } from "../../selectors";
import ForgotPassword from "./ForgotPassword";
import withStyles from "@mui/styles/withStyles";
import styles from "../Signin/styles";

const mapStateToProps = (state) => ({
  signedInState: getSignedinState(state),
  forgotPasswordSent: getForgotPasswordSent(state),
  groups: getGroups(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      forgotPassword: actions.forgotPassword,
      clearForgotPassword: actions.clearForgotPassword,
      checkAuthState: actions.checkAuthState,
      setPageDetails: actions.setPageDetails,
    },
    dispatch,
  );

function getInitialState() {
  return {
    username: "",
    emailError: false,
  };
}

class ForgotPasswordContainer extends Component {
  state = {
    ...getInitialState(),
  };

  componentDidMount() {
    this.props.setPageDetails({
      pageName: "Forgot your password?",
    });
    this.props.checkAuthState();
  }

  componentDidUpdate(prevProps) {
    if (
      (!prevProps.signedInState || !prevProps.groups) &&
      this.props.signedInState &&
      this.props.groups
    ) {
      this.goToDashboard();
    }
  }

  componentWillUnmount() {
    this.props.clearForgotPassword();
  }

  goToDashboard() {
    this.props.history.push("/dashboard");
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.props.forgotPassword({
      ...this.state,
    });
  };

  handleEmailChange = (name) => (e) => {
    const { value } = e.target;
    this.setState({ username: value.replace(" ", "") });
  };

  validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  setEmailErrorFalse = () => {
    this.setState({ emailError: false });
  };

  setEmailErrorTrue = () => {
    this.setState({ emailError: true });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <ForgotPassword
          {...this.props}
          {...this.state}
          onSubmit={this.onSubmit}
          handleEmailChange={this.handleEmailChange}
          validateEmail={this.validateEmail}
          setEmailErrorFalse={this.setEmailErrorFalse}
          setEmailErrorTrue={this.setEmailErrorTrue}
        />
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ForgotPasswordContainer)),
);
