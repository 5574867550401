import React from "react";
import withStyles from "@mui/styles/withStyles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import WarningIcon from "@mui/icons-material/Warning";

const VideoDisabledDialog = (props) => {
  const {
    classes,
    videoDisabledOpen,
    onCloseVideoDisabledDialog,
    toggleVideo,
    toggleVideoView,
    hasJoinedRoom,
    isCaregiver,
  } = props;
  return (
    <Dialog
      open={videoDisabledOpen}
      onClose={onCloseVideoDisabledDialog}
      PaperProps={{
        style: {
          borderRadius: 8,
          width: 420,
          maxWidth: "95%",
        },
      }}
      TransitionProps={{ direction: "down" }}
      style={{ zIndex: 2050 }}
    >
      <DialogContent className={classes.dialogContent}>
        <div className={classes.infoContainer}>
          <div className={classes.iconContainer}>
            <WarningIcon />
          </div>
          <div className={classes.textContainerWithIcon}>
            <Typography component="p" className={classes.titleTextCentered}>
              {isCaregiver ? "Caution: Video Preview Disabled" : "Your video is disabled"}
            </Typography>

            <Typography component="p" className={classes.contentText}>
              {isCaregiver
                ? "Selecting this will make it so that you cannot see yourself but your clinician can still see you."
                : "Turn on and enable your video camera so that this session can be properly billed."}
            </Typography>
          </div>
        </div>
      </DialogContent>
      <DialogActions className={classes.dialogActionsContainer}>
        <Button
          onClick={
            isCaregiver ? onCloseVideoDisabledDialog : hasJoinedRoom ? toggleVideo : toggleVideoView
          }
          fullWidth
          color="primary"
        >
          {isCaregiver ? "Ok" : "Turn video on"}
        </Button>
        {!isCaregiver && (
          <Button onClick={onCloseVideoDisabledDialog} color="primary" variant="text" fullWidth>
            Leave video off
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
const styles = (theme) => ({
  dialogContent: {
    height: "fit-content",
    maxHeight: "95%",
    maxWidth: "100%",
    color: "#404040",
    padding: "20px 0 !important",
    marginBottom: 25,
  },
  infoContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  textContainerWithIcon: {
    maxWidth: "100%",
  },
  textContainer: {
    maxWidth: "100%",
  },
  iconContainer: {
    height: 48,
    width: 48,
    borderRadius: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FFF4D5",
    "& svg": {
      fill: "#99771A",
      height: 25,
      width: 25,
    },
  },
  titleTextCentered: {
    fontSize: 22,
    fontWeight: 500,
    lineHeight: "26px",
    margin: 20,
    textAlign: "center",
  },
  contentText: {
    fontSize: 15,
    fontWeight: 500,
    lineHeight: "20px",
    color: "#69718F",
    width: "100%",
    textAlign: "left",
    padding: "0 20px",
  },
  dialogActionsContainer: {
    backgroundColor: "#F0F1F4",
    padding: "12px 16px",
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
    display: "flex",
    flexDirection: "column",
  },
  action: {
    backgroundColor: "#D97706",
    height: 44,
    borderRadius: 5,
    "& span": {
      color: "#f0f1f4",
      fontSize: 15,
    },
    "&:hover": {
      backgroundColor: "#B66405 !important",
    },
  },
  secondaryAction: {
    height: 44,
    marginLeft: "0 !important",
    marginTop: 5,
    borderRadius: 5,
    "& span": {
      color: "#D97706",
      fontSize: 15,
    },
  },
});
export default withStyles(styles)(VideoDisabledDialog);
