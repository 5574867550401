import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Modal from "elements/Modal";
import { useGetFeatureFlag } from "hooks/useGetFeatureFlag";
import * as selectors from "selectors";
import { useSelector } from "react-redux";

const ManageCallDialog = (props) => {
  const {
    manageCallOpen,
    onCloseManageCallDialog,
    onClickRescheduleSession,
    videoCall,
    userPermissions,
    onClickCancelSession,
    history,
  } = props;
  const userId = useSelector(selectors.getUserId);
  const { data: schedulePageFeatureFlag } = useGetFeatureFlag(userId, "scheduling_page");

  return (
    <Modal
      open={manageCallOpen}
      onClose={onCloseManageCallDialog}
      title={`Manage session with ${videoCall.child_name}`}
      titleCentered
      content={
        <div style={{ width: 550, display: "flex", flexDirection: "column" }}>
          <Typography component="p">
            <span style={{ fontWeight: 500 }}>Reschedule Call</span> - please message your client
            prior to rescheduling
          </Typography>
          {userPermissions?.reschedule_call && (
            <Button
              onClick={() => {
                if (schedulePageFeatureFlag)
                  history.push("/clinician-schedule/create-event?id=" + videoCall.id);
                else onClickRescheduleSession(videoCall.is_recurring);
              }}
              style={{ width: 100, marginTop: 10 }}
            >
              Reschedule
            </Button>
          )}
          <Divider style={{ margin: "15px 0" }} />
          <Typography component="p">
            <span style={{ fontWeight: 500 }}>Cancel Call</span> - rescheduling the call is
            preferred if you or the client can no longer make this session time
          </Typography>
          {userPermissions?.cancel_call && (
            <Button
              onClick={() => onClickCancelSession(videoCall.is_recurring)}
              color="secondary"
              style={{ width: 100, marginTop: 10 }}
            >
              Cancel
            </Button>
          )}
          <Divider style={{ margin: "15px 0" }} />
        </div>
      }
      secondaryActionText="Close"
      secondaryActionOnClick={onCloseManageCallDialog}
    />
  );
};

export default ManageCallDialog;
