import React from "react";
import { MenuItem, ListItemText, IconButton, Select, TextField } from "@mui/material";
import { Trash, Check } from "@phosphor-icons/react";

const Service = (props) => {
  const {
    classes,
    serviceType,
    duration,
    onChangeServiceType,
    onChangeMinutes,
    setError,
    clientInsuranceCodes,
    insuranceCodes,
    maxDuration,
    onClickDelete,
  } = props;
  const techIssuesCode = insuranceCodes.find((ic) => ic.description.includes("Tech"));
  const nonBillableCode = insuranceCodes.find((ic) => ic.description.includes("Nonbillable Time"));
  return (
    <div className={classes.editService}>
      <Select
        value={serviceType?.description || ""}
        onChange={onChangeServiceType}
        variant="outlined"
        color="primary"
        displayEmpty
        sx={{ width: 315, height: "40px" }}
        disabled={serviceType?.locked}
        MenuProps={{
          style: { zIndex: 3007 },
        }}
        onFocus={() => setError("serviceTypeError", false)}
        size="small"
      >
        <MenuItem
          value=""
          disableGutters
          disabled
          style={{
            padding: "0 5px",
          }}
        >
          <ListItemText primary={"Select service type"} />
        </MenuItem>
        {clientInsuranceCodes.map((code) => (
          <MenuItem
            key={code.id}
            value={code.description}
            disableGutters
            style={{
              padding: "0 5px",
            }}
          >
            <ListItemText primary={`${code.description} - ${code.code}`} />
          </MenuItem>
        ))}
        <MenuItem
          key={nonBillableCode?.id}
          value={nonBillableCode?.description}
          disableGutters
          style={{
            borderTop: "1px solid #C3C6D2",
            marginTop: 5,
            padding: "5px 5px 0",
          }}
        >
          <ListItemText primary={"Nonbillable Time"} />
        </MenuItem>
        <MenuItem
          key={techIssuesCode?.id}
          value={techIssuesCode?.description}
          disableGutters
          style={{
            padding: "5px 5px 0",
          }}
        >
          <ListItemText primary={"Tech Issues"} />
        </MenuItem>
      </Select>
      <TextField
        autoComplete="off"
        value={duration}
        onChange={onChangeMinutes}
        type="number"
        disabled={serviceType?.locked}
        style={{ width: 68 }}
        inputProps={{
          min: 0,
          max: serviceType?.time_limit ? parseInt(serviceType.time_limit) : maxDuration,
        }}
      />
      {serviceType?.locked ? (
        <div className={classes.iconCheckContainer}>
          <Check size={24} color="rgba(46, 125, 50, 1)" />
        </div>
      ) : (
        <IconButton aria-label="Edit" onClick={onClickDelete} size="medium" sx={{ padding: "6px" }}>
          <Trash size={24} color="rgba(211, 47, 47, 1)" />
        </IconButton>
      )}
    </div>
  );
};

export default Service;
