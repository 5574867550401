import Request from "../utils/request";

/**
 *
 * @param {String} login email or phone number
 * @returns {VoidFunction}
 *
 */
export const requestOtp = async (login) => {
  return await Request.get(`/users/${login}/request-otp`);
};

/**
 *
 * @param {String} login email or phone number
 * @param {Object} params {login: String, code: String}
 * @returns {Object} {accessToken: String, idToken: String, refreshToken: String}
 *
 */
export const verifyOtp = async (params) => {
  return await Request.post(`/users/verify-otp`, params);
};
