const ACTIONS = {
  TOGGLE_NOTES: "TOGGLE_NOTES",
  GET_ANSWERS: "GET_ANSWERS",
  GET_ANSWERS_SUCCESS: "GET_ANSWERS_SUCCESS",
  SAVE_NOTES: "SAVE_NOTES",
  SAVE_NOTES_SUCCESS: "SAVE_NOTES_SUCCESS",
  SAVE_NOTES_FAIL: "SAVE_NOTES_FAIL",
  CLEAR_NOTES_SUCCESS: "CLEAR_NOTES_SUCCESS",
  GET_NOTES_LIST: "GET_NOTES_LIST",
  GET_NOTES_LIST_SUCCESS: "GET_NOTES_LIST_SUCCESS",
  GET_SESSION_NOTE: "GET_SESSION_NOTE",
  GET_SESSION_NOTE_SUCCESS: "GET_SESSION_NOTE_SUCCESS",
  GET_SESSION_NOTES: "GET_SESSION_NOTES",
  GET_SESSION_NOTES_SUCCESS: "GET_SESSION_NOTES_SUCCESS",
  GET_SESSION_NOTES_FAIL: "GET_SESSION_NOTES_FAIL",
  GET_SESSION_NOTES_BY_USER: "GET_SESSION_NOTES_BY_USER",
  GET_SESSION_NOTES_BY_USER_SUCCESS: "GET_SESSION_NOTES_BY_USER_SUCCESS",
  GET_SESSION_NOTES_BY_USER_FAIL: "GET_SESSION_NOTES_BY_USER_FAIL",
  GET_OLD_SESSION_NOTES_BY_USER: "GET_OLD_SESSION_NOTES_BY_USER",
  GET_OLD_SESSION_NOTES_BY_USER_SUCCESS: "GET_OLD_SESSION_NOTES_BY_USER_SUCCESS",
  GET_SESSION_NOTES_BY_CLINICIAN: "GET_SESSION_NOTES_BY_CLINICIAN",
  GET_SESSION_NOTES_BY_CLINICIAN_SUCCESS: "GET_SESSION_NOTES_BY_CLINICIAN_SUCCESS",
  GET_SESSION_NOTES_BY_CLINICIAN_FAIL: "GET_SESSION_NOTES_BY_CLINICIAN_FAIL",
  SAVE_SESSION_NOTE: "SAVE_SESSION_NOTE",
  SAVE_SESSION_NOTE_SUCCESS: "SAVE_SESSION_NOTE_SUCCESS",
  SAVE_SESSION_NOTE_FAIL: "SAVE_SESSION_NOTE_FAIL",
  CLEAR_SESSION_NOTE_SUCCESS: "CLEAR_SESSION_NOTE_SUCCESS",
  UPDATE_SESSION_NOTE: "UPDATE_SESSION_NOTE",
  UPDATE_SESSION_NOTE_SUCCESS: "UPDATE_SESSION_NOTE_SUCCESS",
  UPDATE_SESSION_NOTE_FAIL: "UPDATE_SESSION_NOTE_FAIL",
};

const ACTION_CREATORS = {
  toggleNotes() {
    return {
      type: ACTIONS.TOGGLE_NOTES,
    };
  },
  getNotesList(clientId) {
    return {
      type: ACTIONS.GET_NOTES_LIST,
      payload: clientId,
    };
  },
  getNotesListSuccess(notes) {
    return {
      type: ACTIONS.GET_NOTES_LIST_SUCCESS,
      payload: notes,
    };
  },
  getAnswers() {
    return {
      type: ACTIONS.GET_ANSWERS,
    };
  },
  saveNotes(payload) {
    return {
      type: ACTIONS.SAVE_NOTES,
      payload,
    };
  },
  saveNotesSuccess(notes) {
    return {
      type: ACTIONS.SAVE_NOTES_SUCCESS,
      payload: { notes },
    };
  },
  saveNotesFail() {
    return {
      type: ACTIONS.SAVE_NOTES_FAIL,
    };
  },
  clearNotesSuccess() {
    return {
      type: ACTIONS.CLEAR_NOTES_SUCCESS,
    };
  },
  getSessionNote(billableTimeId) {
    return {
      type: ACTIONS.GET_SESSION_NOTE,
      payload: billableTimeId,
    };
  },
  getSessionNoteSuccess(note) {
    return {
      type: ACTIONS.GET_SESSION_NOTE_SUCCESS,
      payload: note,
    };
  },
  getSessionNotes(videoId) {
    return {
      type: ACTIONS.GET_SESSION_NOTES,
      payload: videoId,
    };
  },
  getSessionNotesSuccess(notes) {
    return {
      type: ACTIONS.GET_SESSION_NOTES_SUCCESS,
      payload: notes,
    };
  },
  getSessionNotesByUser(clientId) {
    return {
      type: ACTIONS.GET_SESSION_NOTES_BY_USER,
      payload: clientId,
    };
  },
  getSessionNotesByUserSuccess(payload) {
    return {
      type: ACTIONS.GET_SESSION_NOTES_BY_USER_SUCCESS,
      payload,
    };
  },
  getOldSessionNotesByUser(clientId) {
    return {
      type: ACTIONS.GET_OLD_SESSION_NOTES_BY_USER,
      payload: clientId,
    };
  },
  getOldSessionNotesByUserSuccess(payload) {
    return {
      type: ACTIONS.GET_OLD_SESSION_NOTES_BY_USER_SUCCESS,
      payload,
    };
  },
  getSessionNotesByClinician(payload) {
    return {
      type: ACTIONS.GET_SESSION_NOTES_BY_CLINICIAN,
      payload,
    };
  },
  getSessionNotesByClinicianSuccess(payload) {
    return {
      type: ACTIONS.GET_SESSION_NOTES_BY_CLINICIAN_SUCCESS,
      payload,
    };
  },
  getSessionNotesByClinicianFail() {
    return {
      type: ACTIONS.GET_SESSION_NOTES_BY_CLINICIAN_FAIL,
    };
  },
  saveSessionNote(payload) {
    return {
      type: ACTIONS.SAVE_SESSION_NOTE,
      payload,
    };
  },
  saveSessionNoteSuccess() {
    return {
      type: ACTIONS.SAVE_SESSION_NOTE_SUCCESS,
    };
  },
  saveSessionNoteFail() {
    return {
      type: ACTIONS.SAVE_SESSION_NOTE_FAIL,
    };
  },
  clearSessionNoteSuccess() {
    return {
      type: ACTIONS.CLEAR_SESSION_NOTE_SUCCESS,
    };
  },
  updateSessionNote(note) {
    return {
      type: ACTIONS.UPDATE_SESSION_NOTE,
      payload: note,
    };
  },
  updateSessionNoteSuccess() {
    return {
      type: ACTIONS.UPDATE_SESSION_NOTE_SUCCESS,
    };
  },
  updateSessionNoteFail() {
    return {
      type: ACTIONS.UPDATE_SESSION_NOTE_FAIL,
    };
  },
};

export default { ...ACTIONS, ...ACTION_CREATORS };
