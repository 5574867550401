import React from "react";
import { withRouter } from "react-router";
import withStyles from "@mui/styles/withStyles";

import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import styles from "../../theme/globalStyles";
import { format } from "date-fns";
import { CustomTable } from "elements/DEPRECATED_elements";
import { CustomHeaderCell } from "elements/DEPRECATED_elements";

const Users = (props) => {
  const {
    classes,
    handleChangePage,
    handleChangeRowsPerPage,
    handleSortClick,
    viewUserDetails,
    page,
    count,
    rowsPerPage,
    sort,
    direction,
  } = props;

  let rows = props.users;

  const formatRoles = (roles, id) => {
    const roleLabels = {
      ROLE_PARENT: "Client",
      ROLE_CLINICIAN: "Clinician",
      ROLE_ADMIN: "Admin",
      ROLE_SUPER_ADMIN: "Super Admin",
      ROLE_FAMILY: "Family Member",
      ROLE_CUSTOMER_CARE: "Care Manager",
      ROLE_CLINICAL_SUPERVISOR: "Clinical Supervisor",
      ROLE_CLINICAL_DIRECTOR: "Clinical Director",
      ROLE_CLINICIAN_VIEW_ONLY: "Clinician View Only",
    };

    return roles.map((role) => <div key={`${role}-${id}`}>{roleLabels[role]}</div>);
  };

  return (
    <CustomTable
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      noResultsMessage="No Users Found"
      headerCells={[
        <CustomHeaderCell
          label="ID"
          sortable={true}
          sortDirection={sort === "id" ? direction : false}
          active={sort === "id"}
          onClick={() => handleSortClick("id")}
        />,
        <CustomHeaderCell
          label="Name"
          sortable={true}
          sortDirection={sort === "first_name" ? direction : false}
          active={sort === "first_name"}
          onClick={() => handleSortClick("first_name")}
        />,
        <CustomHeaderCell
          label="Email"
          sortable={true}
          sortDirection={sort === "email" ? direction : false}
          active={sort === "email"}
          onClick={() => handleSortClick("email")}
        />,
        <CustomHeaderCell label="Active?" />,
        <CustomHeaderCell label="Migrated?" />,
        <CustomHeaderCell label="Verified?" />,
        <CustomHeaderCell
          label="Created"
          sortable={true}
          sortDirection={sort === "created_date" ? direction : false}
          active={sort === "created_date"}
          onClick={() => handleSortClick("created_date")}
        />,
        <CustomHeaderCell label="Role" />,
      ]}
    >
      {rows.map((row) => (
        <TableRow
          key={row.id}
          className={classes.clickableRow}
          onClick={() => viewUserDetails(row.id)}
          hover
        >
          <TableCell component="th" scope="row">
            {row.id}
          </TableCell>
          <TableCell align="left">
            {row.first_name} {row.last_name}
          </TableCell>
          <TableCell align="left">{row.email}</TableCell>
          <TableCell align="left">{row.can_login ? "Yes" : "No"}</TableCell>
          <TableCell align="left">{row.migrated ? "Yes" : "No"}</TableCell>
          <TableCell align="left">{row.verified ? "Yes" : "No"}</TableCell>
          <TableCell align="left">
            {format(new Date(row.created_date), "PP", {
              awareOfUnicodeTokens: true,
            })}
          </TableCell>
          <TableCell align="left">{formatRoles(row.authority, row.id)}</TableCell>
        </TableRow>
      ))}
    </CustomTable>
  );
};

export default withRouter(withStyles(styles)(Users));
