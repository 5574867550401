import React from "react";
import withStyles from "@mui/styles/withStyles";
import { Grid, TextField, Stack, MenuItem, CircularProgress } from "@mui/material";

import Button from "@mui/material/Button";
import { DropzoneDialogBase } from "mui-file-dropzone";
import SectionBox from "../../../elements/SectionBox";

import { UploadSimple } from "@phosphor-icons/react";
import { useTheme } from "@mui/styles";
import styles from "../styles";
import { useGetClinicalLeads } from "hooks/useGetClinicalLeads";
import ANFormView from "elements/Forms/ANFormView";

const EmploymentDetails = (props) => {
  const {
    photoUrl,
    editMode,
    userPermissions,
    basicInfo,
    appBio,
    onChange,
    fileObjects,
    uploadProfilePhotoOpen,
    openFileDialog,
    closeFileDialog,
    addFileObjects,
    persistFileObjects,
  } = props;
  const { leads, leadsError, leadsLoading } = useGetClinicalLeads();

  const [employmentType, setEmploymentType] = React.useState(basicInfo?.employment_type || "");
  const [supervisor, setSupervisor] = React.useState(basicInfo?.supervisor_user_id || "");

  if (leadsLoading) return <CircularProgress />;

  const findLeadById = (id) => {
    const supervisor = leads.find((lead) => lead.user_id === id);
    return supervisor ? supervisor.first_name + " " + supervisor.last_name : "";
  };

  function getEmploymentOptions() {
    return [
      { value: "W2_1_FTE", label: "BCBA - W2 1 FTE" },
      { value: "W2_75_FTE", label: "BCBA - W2 .75 FTE" },
      { value: "W2_5_FTE", label: "BCBA - W2 .5 FTE" },
      { value: "Contractor", label: "BCBA - Contractor" },
      { value: "Clinical_Assessor", label: "Clinical Assessor" },
      { value: "Clinical_Lead", label: "Clinical Lead" },
    ];
  }

  const employmentOptions = getEmploymentOptions();
  return (
    <SectionBox
      title="Employment Details"
      description={"Manage employee and supervisor settings"}
      columnDirection
    >
      {editMode ? (
        <>
          {userPermissions?.approve_clinician_application && (
            <Stack gap={7}>
              <Grid item xs={12}>
                <TextField
                  select
                  id="employmentType"
                  label="Employment Type"
                  value={employmentType}
                  onChange={(e) => {
                    onChange("employmentType")(e);
                    setEmploymentType(e.target.value);
                  }}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                >
                  <MenuItem disabled value={""}>
                    Select Employment Type
                  </MenuItem>
                  {employmentOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  select
                  id="supervisor"
                  label="Supervisor"
                  value={supervisor}
                  onChange={(e) => {
                    onChange("supervisor")(e);
                    setSupervisor(e.target.value);
                  }}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                >
                  <MenuItem disabled value={""}>
                    Select Supervisor
                  </MenuItem>
                  {leads.map((lead) => (
                    <MenuItem key={lead.user_id} value={lead.user_id}>
                      {lead.first_name} {lead.last_name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Stack>
          )}
        </>
      ) : (
        <ANFormView
          data={{
            employmentType:
              employmentOptions.find((option) => option.value === basicInfo?.employment_type)
                ?.label || "",
            supervisor: findLeadById(basicInfo?.supervisor_user_id) || "",
          }}
          config={[
            { label: "Employment Type", dataKey: "employmentType", cols: 12, type: "select" },
            {
              label: "Supervisor",
              dataKey: "supervisor",
              type: "select",
              cols: 12,
            },
          ]}
        ></ANFormView>
      )}
    </SectionBox>
  );
};

export default withStyles(styles)(EmploymentDetails);
