import React, { useEffect, useMemo, useState } from "react";
import { Stack } from "@mui/material";
import { useTheme } from "@mui/styles";
import actions from "actions";

import PersonalInformation from "../Widgets/Profile/PersonalInformation";
import TeamSection from "../Widgets/TeamSection";
import CollapsibleMemberView from "components/ClientDetails/Sections/Profile/Member/CollapsibleMemberView";
import { useLocation } from "react-router-dom";
import SaveBeforeNavDialog from "../Dialogs/SaveBeforeNavDialog";
import EditActionButtons from "components/ClinicianDetail/EditActionButtons";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { dateWithoutTimezone } from "utils/dateWithoutTimezone";
import { useGetTeams } from "hooks/api/useGetTeams";
import { useGetTeamMembers } from "hooks/api/useGetTeamMembers";
import { useGetTeamRoles } from "hooks/api/useGetTeamRoles";
import ANLoadingScreen from "elements/ANLoadingScreen";
import * as yup from "yup";
import * as selectors from "selectors";
import GuardianRequiredDialog from "../Widgets/TeamSection/GuardianRequiredDialog";

import DataPrivacy from "./Profile/DataPrivacy";
import { useUpdateTeamMembers } from "hooks/api/useUpdateTeamMembers";

const ClientInfo = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  // Get the teamMember query parameter from the URL
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const teamMemberParam = searchParams.get("teamMember");

  const {
    isCaregiver,
    userPermissions,
    setUnsavedChanges,
    unsavedChanges,
    clientId,
    customerDetails,
    currentUser,
  } = props;
  const customerInfo = { ...customerDetails.details };

  // States for opening the team member side-panel
  const [openPanel, setOpenPanel] = useState(false);
  const [modePanel, setModePanel] = useState("view");

  const [openWarning, setOpenWarning] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const [openGuardianDialog, setOpenGuardianDialog] = useState(false);
  const [currentMember, setCurrentMember] = useState(null);

  const updatingPersonalInfo = useSelector(selectors.getUpdatingClientInfo);
  const { updateTeamMembresMutation } = useUpdateTeamMembers();

  const { teams, teamsLoading } = useGetTeams({ clientIds: clientId });
  const teamId = teams?.[0]?.id;
  const {
    teamMembers,
    teamMembersLoading,
    refetch: refetchTeamMembers,
  } = useGetTeamMembers({ teamIds: teamId });
  const { teamRoles, teamRolesLoading } = useGetTeamRoles({ teamIds: teamId });
  const isDesignatedCaregiver = useMemo(() => {
    const documentSigner = teamMembers?.find((member) => member.documentSigner === true);
    return documentSigner ? documentSigner.userId === currentUser?.id : false;
  }, [teamMembers, currentUser]);

  const showEditButton = (action) => dispatch(actions.setPageDetails({ onEditButton: action }));

  useEffect(() => {
    dispatch(
      actions.setPageDetails({
        onEditButton: !isCaregiver && userPermissions?.edit_client_info,
        editButtonAction: () => {
          setEditMode(true);
          showEditButton(false);
        },
      })
    );
    return () => showEditButton(false);
  }, []);

  const handleOpenMemberPanel = (memberId, mode = "view") => {
    const member = teamMembers.find((member) => {
      return member?.id === memberId || member?.userId === memberId;
    });
    setCurrentMember(member);
    setOpenPanel(true);
    setModePanel(mode);
  };

  useEffect(() => {
    if (teamMemberParam && teamMembers) {
      handleOpenMemberPanel(teamMemberParam);
    }
  }, [teamMemberParam, teamMembers]);

  if (teamsLoading || teamMembersLoading || teamRolesLoading) return <ANLoadingScreen />;

  /**
   * Formats an array to use in the teamSection table.
   *
   * @param {Array} teamMembers - The result of the useGetTeamMembers hook.
   * @returns {Array} The formatted array of team members.
   */
  const teamMembersFormatted = (teamMembers) => {
    return teamMembers?.map((member) => {
      return {
        id: member.id, // group_member.id (if TeamMembers) OR group_member_invite.id (if TeamInvitee)
        name: member.name, // NULL if e.g. Invitee is not a User and has not accepted the invite
        email: member.email,
        relationshipType: member.roleId,
        isLegalGuardian: member.legalGuardian,
        isDesignatedCaregiver: member.documentSigner,
        hasAcceptedInvite: member.hasAcceptedInvite,
        userId: member.userId, // NULL if e.g. Invitee is not a User and has not accepted the invite
        invitationGuid: member.invitationGuid,
        expirationDate: member.expirationDate, // Only for Invites
      };
    });
  };

  const initialValues = {
    personalInformation: {
      firstName: customerInfo?.name || "",
      lastName: customerInfo?.child_last_name || "",
      dob: customerInfo?.dob ? dateWithoutTimezone(customerInfo?.dob) : null,
      gender: customerInfo?.gender || "",
      pronouns: customerInfo?.pronouns || "",
      clientTimezone: customerInfo?.timezone_jhi || "",
      address1: customerInfo?.line1 || "",
      address2: customerInfo?.line2 || "",
      city: customerInfo?.city || "",
      state: customerInfo?.state || "",
      zipCode: customerInfo?.zip || "",
      clientDiagnosis: customerInfo?.diagnosis || "",
      therapeuticConsultation: customerInfo?.therapeutic_consultation,
      preAuthRequired: customerInfo?.pre_auth_required,
      ethnicity: customerInfo?.ethnicity,
      primaryLanguage: customerInfo?.primary_language,
      preferredFirstName: customerInfo?.preferred_first_name,
      transgender: customerInfo?.transgender,
      identifiedGender: customerInfo?.identified_gender || "",
      needsInterpreter: customerInfo?.needs_interpreter,
      medicalConditions: customerInfo?.medical_conditions || "",
      livesWith: customerInfo?.lives_with || "",
      requiresTablet: customerInfo.requires_tablet,
    },
    teamSection: {
      teamId,
      teamMembers: teamMembersFormatted(teamMembers),
    },
  };

  const validationSchema = yup.object({
    zipCode: yup.string().matches(/^\d{5}$/, "Invalid ZIP Code"),
  });

  /**
   * Checks if there is a legal guardian in the team members.
   *
   * @param {Object} values - The Formik values object for this component.
   * @param {string} emailToExclude - The email of the team member to exclude from the check.
   * @returns {boolean} - Returns true if there is at least one legal guardian.
   */
  const hasLegalGuardian = (values, emailToExclude) => {
    return values.teamSection?.teamMembers
      ?.filter((row) => row.email !== emailToExclude)
      .some((row) => row.isLegalGuardian);
  };

  const handleSave = (values) => {
    if (!hasLegalGuardian(values)) {
      setOpenGuardianDialog(true);
      return;
    }

    const personalInformation = values.personalInformation;

    dispatch(
      actions.updateClientInfo({
        clientId: clientId,
        ...personalInformation,
        location: {
          id: customerInfo.location?.id,
          state: personalInformation.state,
          zip: personalInformation.zipCode,
          line1: personalInformation.address1,
          line2: personalInformation.address2,
          city: personalInformation.city,
        },
        isAdmin: true,
      })
    );

    const teamMembers = values.teamSection.teamMembers
      .filter((member) => member.hasAcceptedInvite)
      .map((member) => {
        return {
          id: member.id,
          roleId: member.relationshipType,
          legalGuardian: member.isLegalGuardian,
          documentSigner: member.isDesignatedCaregiver,
        };
      });

    updateTeamMembresMutation({ teamId, teamMembers }, { onSuccess: () => refetchTeamMembers() });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSave}
      enableReinitialize
    >
      {({ handleSubmit, dirty, resetForm, values }) => {
        useEffect(() => {
          if (!isCaregiver)
            setOpenGuardianDialog(
              !values.teamSection?.teamMembers?.some((row) => row.isLegalGuardian)
            );
        }, [values.teamSection.teamMembers]);

        const resetFormAndExitEditMode = () => {
          setEditMode(false);
          showEditButton(true);
          resetForm();
        };

        const onCancel = () => {
          if (dirty) {
            setOpenWarning(true);
            return;
          }
          if (!unsavedChanges) resetFormAndExitEditMode();
        };

        useEffect(() => {
          setUnsavedChanges(dirty);
        }, [dirty]);

        return (
          <Stack
            direction="row"
            sx={{
              // 156px and 164px are fixed values for toolbar height for different screen sizes
              height: "calc(100vh - 156px)",
              [theme.breakpoints.down("md")]: { height: "calc(100vh - 164px)" },
            }}
          >
            <Stack gap={5} padding={5} overflow="hidden auto" alignItems="center" flex={1}>
              <Stack maxWidth="1200px">
                <PersonalInformation {...props} editMode={editMode} />

                <TeamSection
                  customerInfo={customerInfo}
                  clientId={clientId}
                  onOpenMemberPanel={handleOpenMemberPanel}
                  editMode={editMode}
                  refetchTeamMembers={refetchTeamMembers}
                  teamRoles={teamRoles}
                  setOpenGuardianDialog={setOpenGuardianDialog}
                  hasLegalGuardian={hasLegalGuardian}
                  teamData={teams?.[0] || {}}
                />

                {isCaregiver && (
                  <DataPrivacy
                    isDesignatedCaregiver={isDesignatedCaregiver}
                    userId={customerInfo.userid}
                    clientId={clientId}
                  />
                )}

                {editMode && (
                  <Stack alignItems="center">
                    <EditActionButtons
                      onCancel={onCancel}
                      onSave={handleSubmit}
                      loading={updatingPersonalInfo}
                    />
                  </Stack>
                )}
                {/* Cancel action it is handled internally. Moving away action (going to a new page) it
                  is still handled in the parent component */}
                <SaveBeforeNavDialog
                  saveBeforeNavOpen={openWarning}
                  onToggleSaveBeforeNav={() => setOpenWarning(false)}
                  onContinueWithoutSaving={() => {
                    setOpenWarning(false);
                    resetFormAndExitEditMode();
                  }}
                  severetyAlert={"warning"}
                  primaryActionColorButton={"error"}
                  secondaryText={"Stay on Page"}
                  isCancelClicked
                />
              </Stack>
            </Stack>

            <GuardianRequiredDialog
              open={openGuardianDialog}
              onClose={() => setOpenGuardianDialog(false)}
            />

            {openPanel && currentMember?.userId && (
              <Stack overflow="auto" borderLeft={`1px solid ${theme.palette.divider}`}>
                <CollapsibleMemberView
                  currentMember={currentMember}
                  mode={modePanel}
                  open={openPanel}
                  setOpen={setOpenPanel}
                  userPermissions={userPermissions}
                  refetchTeamMembers={refetchTeamMembers}
                />
              </Stack>
            )}
          </Stack>
        );
      }}
    </Formik>
  );
};

export default ClientInfo;
