import React, { useEffect, useRef, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button, Link, Stack, Tooltip, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { Calendar, UploadSimple, UserCircle } from "@phosphor-icons/react";
import { format } from "date-fns";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker";
import { useSelector } from "react-redux";
import { useMutation } from "react-query";
import { updateAssessmentBatch, uploadAssessmentBatch } from "api/customers";
import NoDocumentsWithPermissionOverlay from "elements/NoDocumentsWithPermissionOverlay";
import ANNoResultsOverlay from "elements/ANNoResultsOverlay";
import * as selectors from "selectors";
import ANTableFilters from "elements/ANTableFilters";
import UploadAssessmentDialog from "./UploadAssessmentDialog";
import { useGetAssessementsBatch } from "hooks/api/useGetAssessementsBatch";
import { postUploadFiles } from "hooks/postUploadFiles";
import { useGlobalToast } from "components/GlobalToastProvider";

const Assessments = () => {
  const theme = useTheme();
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 10,
    page: 0,
  });
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [isNewAssessment, setIsNewAssessment] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null);
  const [orderBy, setOrderBy] = useState("uploaded_date-desc");
  const [uploadedByFilter, setUploadedByFilter] = useState([]);
  const [uploadedDateFilter, setUploadedDateFilter] = useState([null, null]);
  const [dropzoneFiles, setDropzoneFiles] = useState([]);
  const [alreadyUploadedFiles, setAlreadyUploadedFiles] = useState([]);
  const userId = useSelector(selectors.getUserId);
  const cognitoId = useSelector(selectors.getUserCognitoId);
  const { showToast } = useGlobalToast();
  const lastPayloadRef = useRef(); // Reference to the last payload to be used in the retry handler
  const ASSESMENTS_BUCKET = process.env.AWS_ASSESSMENT_BATCH_DOCS_BUCKET;

  const { mutate: updateAssessmentMutation, isLoading: isLoadingDocumentUpdate } = useMutation(
    (payload) => updateAssessmentBatch(payload),
    {
      onSuccess: () => {
        setUploadDialogOpen(false);
        updateTable();
        showToast({ message: "Success!" });
      },
      onError: () =>
        showToast({
          message: "Whoops! Something went wrong",
          errorState: true,
          retryHandler: () => updateAssessmentBatch(lastPayloadRef.current),
        }),
    }
  );
  const { mutate: uploadAssessmentMutation, isLoading: isLoadingUploadAssessment } = useMutation(
    (payload) => uploadAssessmentBatch(payload),
    {
      onSuccess: () => updateTable(),
      onError: () =>
        showToast({
          message: "Whoops! Something went wrong",
          errorState: true,
          retryHandler: () => uploadAssessmentBatch(lastPayloadRef.current),
        }),
    }
  );

  const orderByMap = {
    "uploaded_date-asc": { sort: "created", direction: "asc" },
    "uploaded_date-desc": { sort: "created", direction: "desc" },
    created_by: { sort: "created_by", direction: "asc" },
  };
  // using isFetching instead of isLoading, since keepPreviousData (avoids showing `undefined` in chips)
  // is set to true in useGetAssessementsBatch and isLoading will be false when
  // the previous data is being used
  const {
    isFetching,
    data: response,
    refetch: updateTable,
  } = useGetAssessementsBatch({
    sort: orderByMap[orderBy]?.sort,
    direction: orderByMap[orderBy]?.direction,
    page: paginationModel.page,
    size: paginationModel.pageSize,
    uploadedByList: uploadedByFilter.join(","),
    startDate: uploadedDateFilter?.[0],
    endDate: uploadedDateFilter?.[1],
  });

  const batchFiles = response?.data.files;
  const rowCount = response?.data.count || 0;
  const createdByCount = response?.data.created_by_count;

  useEffect(() => {
    const intervalId = setInterval(() => {
      const isProcessingCSV = batchFiles.some((e) => e.upload_status === "LOADING");
      if (!isProcessingCSV) clearInterval(intervalId);
      else updateTable();
    }, 30000); // 30 seconds interval

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [batchFiles]);

  const handleFileUpload = (file) => {
    setDropzoneFiles([
      { errors: !!file.fileRejections?.[0], file: file.acceptedFiles[0] || file.fileRejections[0] },
    ]);
    if (!uploadDialogOpen) setUploadDialogOpen(true);
  };

  const handleFileDelete = (file) => {
    setDropzoneFiles(dropzoneFiles.filter((dropzoneFile) => dropzoneFile !== file));
  };

  const handleLoadedFileDelete = (file) => {
    setAlreadyUploadedFiles(alreadyUploadedFiles.filter((uploadedFile) => uploadedFile !== file));
  };

  const submitDocumentUpload = async () => {
    const { errors, success: filename } = await postUploadFiles(
      cognitoId,
      dropzoneFiles,
      ASSESMENTS_BUCKET
    );
    const file = dropzoneFiles[0]?.file;
    if (errors) {
      console.error(errors);
      return;
    }
    const payload = {
      filename: file ? filename.split("/")[1] : selectedRow.name,
      display_filename: filename ? file.name : selectedRow.name,
      file_size: filename ? file.size : selectedRow.file_size,
      cognito_id: cognitoId,
      type: "VINELAND", // hardcoded for now, since we only have one type
    };
    if (isNewAssessment) {
      lastPayloadRef.current = payload;
      uploadAssessmentMutation(payload);
    } else {
      const updatePayload = { ...payload, id: selectedRow.id };
      lastPayloadRef.current = updatePayload;
      updateAssessmentMutation(updatePayload);
    }
  };

  const statusMap = {
    LOADING: { label: "Loading" },
    SUCCESS: { label: "Success", color: theme.palette.success.main },
    ERROR: { label: "Upload Failed", color: theme.palette.error.main },
  };

  const generateRows = () => {
    if (!batchFiles) return [];
    return batchFiles.map((batchFile) => ({
      id: batchFile.id,
      name: batchFile.display_filename || batchFile.filename,
      uploaded_by: batchFile.uploaded_by,
      uploaded_date: batchFile.created,
      status: statusMap[batchFile.upload_status],
      edit: "Edit",
      file_size: batchFile.file_size,
    }));
  };
  const rows = generateRows();

  const getColumnDefinitions = (handleEdit, handleOpenFile) => {
    const DateValueGetter = ({ value }) => (value ? format(new Date(value), "P") : null);
    const defaultColAttrs = {
      sortable: false,
      flex: 1,
    };
    const columns = [
      {
        field: "name",
        headerName: "Assessments",
        ...defaultColAttrs,
        renderCell: (params) => {
          return (
            <Tooltip title={params.value}>
              <Link
                color="primary"
                variant="body2"
                underline="hover"
                onClick={() => handleOpenFile(params)}
                sx={{ "&:hover": { cursor: "pointer" } }}
              >
                {params.value}
              </Link>
            </Tooltip>
          );
        },
      },
      { field: "uploaded_by", headerName: "Uploaded By", ...defaultColAttrs },
      {
        field: "uploaded_date",
        headerName: "Uploaded Date",
        valueGetter: DateValueGetter,
        ...defaultColAttrs,
      },
      {
        field: "status",
        headerName: "Status",
        ...defaultColAttrs,
        renderCell: (params) => {
          return (
            <Typography variant="body2" color={params.value.color}>
              {params.value.label}
            </Typography>
          );
        },
      },
      {
        field: "edit",
        headerName: "Edit",
        ...defaultColAttrs,
        renderCell: (params) => {
          return (
            <Link
              color="primary"
              variant="body2"
              underline="hover"
              onClick={() => handleEdit(params)}
              sx={{ "&:hover": { cursor: "pointer" } }}
            >
              Edit
            </Link>
          );
        },
      },
    ];
    return columns;
  };

  const orderingMenuItems = [
    { label: "Uploaded By", value: "uploaded_by", icon: <UserCircle weight="duotone" /> },
    {
      label: "Uploaded Date",
      value: "uploaded_date-asc",
      icon: <Calendar weight="duotone" />,
      caption: "Ascending",
    },
    {
      label: "Uploaded Date",
      value: "uploaded_date-desc",
      icon: <Calendar weight="duotone" />,
      caption: "Descending",
    },
  ];
  const filtersMenuItems = [
    {
      label: "Uploaded Date",
      value: "uploaded_date",
      icon: <Calendar weight="duotone" />,
      customComponent: (
        <StaticDateRangePicker
          value={uploadedDateFilter}
          onChange={(date) => setUploadedDateFilter(date)}
          slotProps={{ actionBar: { actions: [] }, toolbar: { hidden: true } }}
          calendars={2}
        />
      ),
      filterChipProps: {
        label: `Uploaded Date is : ${format(new Date(uploadedDateFilter[0]), "MM/dd/yy")} 
          ${
            uploadedDateFilter[1] ? ` - ${format(new Date(uploadedDateFilter[1]), "MM/dd/yy")}` : ""
          }`,
        onDelete: () => setUploadedDateFilter([null, null]),
      },
      showChipConditionCustomComponent: !!uploadedDateFilter[0],
    },
    {
      label: "Uploaded By",
      value: "uploaded_by",
      icon: <UserCircle weight="duotone" />,
      countObject: createdByCount,
      state: uploadedByFilter,
      setState: setUploadedByFilter,
    },
  ];

  const handleClearFilters = () => {
    setUploadedByFilter([]);
    setUploadedDateFilter([null, null]);
  };

  const customNoRowsOverlay = {
    NoDocumentsWithPermission: {
      component: NoDocumentsWithPermissionOverlay,
      height: "296px",
      props: { handleFileUpload },
    },
    NoResults: {
      component: ANNoResultsOverlay,
      height: "236px",
      props: { handleClearFilters },
    },
  };
  const overlayName =
    !uploadedByFilter.length && !uploadedDateFilter[0] && !uploadedDateFilter[1]
      ? "NoDocumentsWithPermission"
      : "NoResults";

  return (
    <>
      <Stack
        direction="row"
        padding={theme.spacing(5)}
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <ANTableFilters
          orderingMenuItems={orderingMenuItems}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          filtersMenuItems={filtersMenuItems}
          filtersButtonProps={{ disabled: batchFiles?.length === 0 }}
        />
        <Button
          color="secondary"
          startIcon={<UploadSimple />}
          size="small"
          onClick={() => setUploadDialogOpen(true)}
          sx={{ minWidth: "fit-content" }}
        >
          Upload Assessment
        </Button>
      </Stack>
      <DataGrid
        rows={rows}
        loading={
          (isFetching && !batchFiles) || isLoadingDocumentUpdate || isLoadingUploadAssessment
        }
        pageSizeOptions={[5, 10, 20]}
        columns={getColumnDefinitions(
          ({ row }) => {
            setUploadDialogOpen(true);
            setIsNewAssessment(false);
            setAlreadyUploadedFiles([{ name: row.name, key: row.name, size: row.file_size ?? 0 }]);
            setSelectedRow(row);
          },
          ({ row }) => {
            const url = `/document-viewer/${userId}/${row.id}?documentType=assessment_batch`;
            window.open(url, "_blank", "noopener,noreferrer");
          }
        )}
        disableRowSelectionOnClick
        autoHeight
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        slots={{
          noRowsOverlay: customNoRowsOverlay[overlayName].component,
        }}
        slotProps={{ noRowsOverlay: { ...customNoRowsOverlay[overlayName].props } }}
        sx={{ "--DataGrid-overlayHeight": customNoRowsOverlay[overlayName].height }}
        hideFooter={!rowCount}
        paginationMode="server"
        rowCount={rowCount}
      />
      <UploadAssessmentDialog
        open={uploadDialogOpen}
        handleClose={() => {
          setUploadDialogOpen(false);
          setSelectedRow(null);
          setTimeout(() => {
            setIsNewAssessment(true);
            setAlreadyUploadedFiles([]);
            setDropzoneFiles([]);
          }, 250); // Timeout avoids flickering
        }}
        handleFileUpload={handleFileUpload}
        dropzoneFiles={dropzoneFiles}
        handleFileDelete={handleFileDelete}
        handleLoadedFileDelete={handleLoadedFileDelete}
        submitDocumentUpload={submitDocumentUpload}
        isNew={isNewAssessment}
        alreadyUploadedFiles={alreadyUploadedFiles}
      />
    </>
  );
};

export default Assessments;
