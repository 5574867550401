import { call, put, select, takeEvery } from "redux-saga/effects";
import { sendMessage, logGroupMessage, clinicianSendMessage } from "../api/chat";
import actions from "../actions";
import { getGroups } from "../selectors";

function* sendMessageAsync({ payload }) {
  try {
    let result;
    const groups = yield select(getGroups);
    if (groups && groups.includes("Caregivers")) {
      result = yield call(sendMessage, payload.userId, payload.params);
    } else {
      result = yield call(clinicianSendMessage, payload.userId, payload.params);
    }
  } catch (e) {
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    yield put(actions.logError({ errorMessage: e, errorType: "SENDBIRD" }));
    console.error(e);
  }
}

function* logGroupMessageAsync({ payload }) {
  try {
    const result = yield call(logGroupMessage, payload.params);
  } catch (e) {
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

export default function* chatSaga() {
  yield takeEvery(actions.SEND_MESSAGE, sendMessageAsync);
  yield takeEvery(actions.LOG_GROUP_MESSAGE, logGroupMessageAsync);
}
