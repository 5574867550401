import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import StepConnector from "@mui/material/StepConnector";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import Modal from "elements/Modal";

const Connector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  line: {
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#A568D5",
  },
  completed: {
    color: "#A568D5",
    zIndex: 1,
  },
  incomplete: {
    width: 20,
    height: 20,
    borderRadius: "50%",
    backgroundColor: "#A1A1A1",
    margin: "0 2px",
  },
});

function StepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;
  return completed ? (
    <CheckCircleIcon className={classes.completed} />
  ) : active ? (
    <PlayCircleFilledIcon className={classes.completed} />
  ) : (
    <div className={classes.incomplete} />
  );
}

const FullRoadmap = (props) => {
  const { steps, currentStepIndex, classes, open, onToggleRoadmapDialog, clinicianView } = props;
  return (
    <Modal
      open={open}
      onClose={onToggleRoadmapDialog}
      title={clinicianView ? "Client Progress" : "What's Next"}
      titleCentered
      content={
        <div style={{ maxWidth: 600 }}>
          <Stepper
            activeStep={currentStepIndex}
            orientation="vertical"
            connector={<Connector />}
            classes={{ root: classes.stepperRoot }}
          >
            {steps.map((step, index) => {
              return (
                <Step key={index}>
                  <StepLabel StepIconComponent={StepIcon}>
                    <Typography
                      component="p"
                      className={
                        index === currentStepIndex ? classes.stepLabelActive : classes.stepLabel
                      }
                    >
                      {step.stepTitle}
                    </Typography>
                  </StepLabel>
                  <StepContent TransitionProps={{ in: true }}>
                    <Typography
                      component="p"
                      className={
                        index === currentStepIndex
                          ? classes.stepContent
                          : classes.stepContentIncomplete
                      }
                    >
                      {step.stepParagraphs.map((paragraph, index) => {
                        return (
                          <React.Fragment key={index}>
                            {index > 0 && <br />}
                            {paragraph}
                            {index === 0 && <br />}
                          </React.Fragment>
                        );
                      })}
                    </Typography>
                    {step.note && (
                      <Typography
                        component="p"
                        className={
                          index === currentStepIndex ? classes.stepNote : classes.stepNoteIncomplete
                        }
                      >
                        {step.note}
                      </Typography>
                    )}
                  </StepContent>
                </Step>
              );
            })}
          </Stepper>
        </div>
      }
      primaryActionText="Close"
      primaryActionOnClick={onToggleRoadmapDialog}
    />
  );
};

export default FullRoadmap;
