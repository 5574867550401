import React from "react";
import { IconButton, Snackbar } from "@mui/material";
import { X } from "@phosphor-icons/react";

const SuccessToast = (props) => {
  const {
    classes,
    open,
    onClose,
    savedAsDraft,
    timesheetNote,
    requestEditsSuccess,
    approveTimeSuccess,
    associatedTimesDraftSaving,
    deleteNoteSuccess,
  } = props;
  const renderSuccessToastMessage = () => {
    if (requestEditsSuccess) {
      return "Requested edits have been sent";
    } else if (approveTimeSuccess) {
      return "Billable time approved";
    } else if (timesheetNote) {
      if (savedAsDraft) {
        return "Timesheet note has been saved as draft";
      } else if (deleteNoteSuccess) {
        return "Timesheet note has been deleted";
      } else {
        return "Timesheet note has been submitted";
      }
    } else {
      if (savedAsDraft || associatedTimesDraftSaving) {
        return "Session note has been saved as draft";
      } else if (deleteNoteSuccess) {
        return "Session note has been deleted";
      } else {
        return "Session note has been submitted";
      }
    }
  };
  return (
    <Snackbar
      sx={{ zIndex: 3007 }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      open={open}
      classes={{ anchorOriginBottomCenter: classes.snackbar }}
      message={renderSuccessToastMessage()}
      action={
        <IconButton color="inherit" sx={{ p: 0.5 }} onClick={onClose}>
          <X size={20} color="#fff" />
        </IconButton>
      }
    />
  );
};

export default SuccessToast;
