const customerInfoConfig = [
  { label: "First Name", dataKey: "name", cols: 4 },
  { label: "Preferred First Name", dataKey: "preferred_first_name", cols: 4 },
  { label: "Last Name", dataKey: "child_last_name", cols: 4 },
  { label: "Date of Birth", dataKey: "dob", cols: 4, format: "date" },
  { label: "Sex Assigned at Birth", dataKey: "gender", cols: 4 },
  { label: "Identified Gender", dataKey: "identified_gender", cols: 4 },
  { label: "Transgender", dataKey: "transgender", cols: 4, type: "yes_no" },
  { label: "Pronouns", dataKey: "pronouns", cols: 4 },
  { label: "Diagnosis", dataKey: "diagnosis", cols: 4 },
  { label: "Address", dataKey: "line1", cols: 12 }, // Full width
  { label: "Address 2", dataKey: "line2", cols: 12 }, // Full width
  { label: "State", dataKey: "state", cols: 6 },
  { label: "City", dataKey: "city", cols: 6 },
  { label: "ZIP Code", dataKey: "zip", cols: 6 },
  { label: "Time Zone", dataKey: "timezone_jhi", cols: 6, format: "timezone" },
  { label: "Country", dataKey: "country", cols: 6 },
  { label: "Client Lives With", dataKey: "lives_with", cols: 6 }, // Full width
  { label: "Race & Ethnicity", dataKey: "ethnicity", cols: 6 },
  { label: "Primary Language", dataKey: "primary_language", cols: 6 },
  { label: "Interpreter Required", dataKey: "needs_interpreter", cols: 6, type: "yes_no" },
  { label: "Medical Conditions", dataKey: "medical_conditions", cols: 6 },
  { label: "Pre-authorization Required", dataKey: "pre_auth_required", cols: 6, type: "yes_no" },
  { label: "Requires Tablet", dataKey: "requires_tablet", cols: 6, type: "yes_no" },
];

export default customerInfoConfig;
