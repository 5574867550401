import React, { useState } from "react";
import { Button, Chip, ListItemIcon, Menu, MenuItem, Stack } from "@mui/material";
import { CaretDown, FunnelSimple } from "@phosphor-icons/react";

export default function LogsFilter({ filtersMenuItems = [], filterBy, setFilterBy }) {
  const [anchorFiltersEl, setAnchorFiltersEl] = useState(null);
  const openFilters = Boolean(anchorFiltersEl);
  const handleFiltersOpen = (event) => setAnchorFiltersEl(event.currentTarget);
  const handleFiltersClose = () => setAnchorFiltersEl(null);

  const filtersMenuItemsLookup = filtersMenuItems.reduce((acc, item) => {
    acc[item.value] = item;
    return acc;
  }, {});
  return (
    <>
      <Menu
        id="filters-menu"
        anchorEl={anchorFiltersEl}
        open={openFilters}
        onClose={handleFiltersClose}
        MenuListProps={{ "aria-labelledby": "open-ordering" }}
      >
        {filtersMenuItems.map((item) => (
          <MenuItem
            key={`${item.value}`}
            value={item.value}
            onClick={() => {
              if (!filterBy.includes(item.value)) setFilterBy((prev) => [...prev, item.value]);
              handleFiltersClose();
            }}
          >
            {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
            {item.label}
          </MenuItem>
        ))}
      </Menu>
      <Stack direction="row" gap="8px" flexWrap="wrap" alignItems="center">
        <Button
          color="secondary"
          size="small"
          startIcon={<FunnelSimple />}
          endIcon={<CaretDown />}
          onClick={handleFiltersOpen}
        >
          Filters
        </Button>
        {filterBy.map((item) => {
          const { value, label, icon } = filtersMenuItemsLookup[item];
          return (
            <Chip
              key={value}
              label={label}
              size="small"
              variant="outlined"
              icon={icon}
              onDelete={() => setFilterBy((prev) => prev.filter((item) => item !== value))}
            />
          );
        })}
      </Stack>
    </>
  );
}
