import { useMutation } from "react-query";

import { initUserChat } from "api/chats";
import { useGlobalToast } from "components/GlobalToastProvider";

const useInitUserChat = (options = {}) => {
  const { onError = () => {}, ...otherOptions } = options;
  const { showToast } = useGlobalToast();

  const { mutate, isIdle, isLoading, ...rest } = useMutation((userId) => initUserChat(userId), {
    onError: () => {
      showToast({
        message: "Whoops! not able to initialize chat",
        errorState: true,
      });
      onError();
    },
    ...otherOptions,
  });

  return {
    initUserChatMutation: mutate,
    isMutationInitChatIdle: isIdle,
    isMutationInitChatLoading: isLoading,
    ...rest,
  };
};

export default useInitUserChat;
