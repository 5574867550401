const ACTIONS = {
  GET_BILLING_ITEMS: "GET_BILLING_ITEMS",
  GET_BILLING_ITEMS_SUCCESS: "GET_BILLING_ITEMS_SUCCESS",
  GET_BILLING_ITEMS_FAIL: "GET_BILLING_ITEMS_FAIL",

  SET_BILLING_ITEMS_PAGE: "SET_BILLING_ITEMS_PAGE",
  SET_BILLING_ITEMS_ROWS_PER_PAGE: "SET_BILLING_ITEMS_ROWS_PER_PAGE",

  SET_ASSOCIATED_BILLABLE_TIMES: "SET_ASSOCIATED_BILLABLE_TIMES",
  SET_ASSOCIATED_BILLABLE_TIMES_SUCCESS: "SET_ASSOCIATED_BILLABLE_TIMES_SUCCESS",
  SET_ASSOCIATED_BILLABLE_TIMES_FAIL: "SET_ASSOCIATED_BILLABLE_TIMES_FAIL",

  APPROVE_BILLABLE_TIME: "APPROVE_BILLABLE_TIME",
  APPROVE_BILLABLE_TIME_SUCCESS: "APPROVE_BILLABLE_TIME_SUCCESS",
  APPROVE_BILLABLE_TIME_FAIL: "APPROVE_BILLABLE_TIME_FAIL",

  HOLD_BILLABLE_TIME: "HOLD_BILLABLE_TIME",
  HOLD_BILLABLE_TIME_SUCCESS: "HOLD_BILLABLE_TIME_SUCCESS",
  HOLD_BILLABLE_TIME_FAIL: "HOLD_BILLABLE_TIME_FAIL",
  RELEASE_BILLABLE_TIME: "RELEASE_BILLABLE_TIME",
  RELEASE_BILLABLE_TIME_SUCCESS: "RELEASE_BILLABLE_TIME_SUCCESS",
  RELEASE_BILLABLE_TIME_FAIL: "RELEASE_BILLABLE_TIME_FAIL",

  REQUEST_BILLABLE_TIME_EDITS: "REQUEST_BILLABLE_TIME_EDITS",
  REQUEST_BILLABLE_TIME_EDITS_SUCCESS: "REQUEST_BILLABLE_TIME_EDITS_SUCCESS",
  REQUEST_BILLABLE_TIME_EDITS_FAIL: "REQUEST_BILLABLE_TIME_EDITS_FAIL",

  SEND_TIMESHEET_BILLABLE_TIME: "SEND_TIMESHEET_BILLABLE_TIME",
  SEND_TIMESHEET_BILLABLE_TIME_SUCCESS: "SEND_TIMESHEET_BILLABLE_TIME_SUCCESS",
  SEND_TIMESHEET_BILLABLE_TIME_FAIL: "SEND_TIMESHEET_BILLABLE_TIME_FAIL",
  SAVE_TIMESHEET_AS_DRAFT: "SAVE_TIMESHEET_AS_DRAFT",
  SAVE_TIMESHEET_AS_DRAFT_SUCCESS: "SAVE_TIMESHEET_AS_DRAFT_SUCCESS",
  SAVE_TIMESHEET_AS_DRAFT_FAIL: "SAVE_TIMESHEET_AS_DRAFT_FAIL",

  DELETE_BILLABLE_TIME: "DELETE_BILLABLE_TIME",
  DELETE_BILLABLE_TIME_SUCCESS: "DELETE_BILLABLE_TIME_SUCCESS",
  DELETE_BILLABLE_TIME_FAIL: "DELETE_BILLABLE_TIME_FAIL",

  GET_BILLABLE_ITEM_BY_ID: "GET_BILLABLE_ITEM_BY_ID",
  GET_BILLABLE_ITEM_BY_ID_SUCCESS: "GET_BILLABLE_ITEM_BY_ID_SUCCESS",

  REVERT_BILLABLE_ITEM_TO_IN_PROGRESS: "REVERT_BILLABLE_ITEM_TO_IN_PROGRESS",
  REVERT_BILLABLE_ITEM_TO_IN_PROGRESS_SUCCESS: "REVERT_BILLABLE_ITEM_TO_IN_PROGRESS_SUCCESS",
  REVERT_BILLABLE_ITEM_TO_IN_PROGRESS_FAIL: "REVERT_BILLABLE_ITEM_TO_IN_PROGRESS_FAIL",
};

const ACTION_CREATORS = {
  getBillingItems(payload) {
    return {
      type: ACTIONS.GET_BILLING_ITEMS,
      payload,
    };
  },
  getBillingItemsSuccess(payload) {
    return {
      type: ACTIONS.GET_BILLING_ITEMS_SUCCESS,
      payload,
    };
  },
  getBillingItemsFail(payload) {
    return {
      type: ACTIONS.GET_BILLING_ITEMS_FAIL,
      payload,
    };
  },
  setBillingItemsPage(payload) {
    return {
      type: ACTIONS.SET_BILLING_ITEMS_PAGE,
      payload,
    };
  },
  setBillingItemsRowsPerPage(payload) {
    return {
      type: ACTIONS.SET_BILLING_ITEMS_ROWS_PER_PAGE,
      payload,
    };
  },
  setAssociatedBillableTime(payload) {
    return {
      type: ACTIONS.SET_ASSOCIATED_BILLABLE_TIMES,
      payload,
    };
  },
  setAssociatedBillableTimeSuccess() {
    return {
      type: ACTIONS.SET_ASSOCIATED_BILLABLE_TIMES_SUCCESS,
    };
  },
  setAssociatedBillableTimeFail() {
    return {
      type: ACTIONS.SET_ASSOCIATED_BILLABLE_TIMES_FAIL,
    };
  },
  approveBillableTime(payload) {
    return {
      type: ACTIONS.APPROVE_BILLABLE_TIME,
      payload,
    };
  },
  approveBillableTimeSuccess() {
    return {
      type: ACTIONS.APPROVE_BILLABLE_TIME_SUCCESS,
    };
  },
  approveBillableTimeFail() {
    return {
      type: ACTIONS.APPROVE_BILLABLE_TIME_FAIL,
    };
  },
  holdBillableTime(payload) {
    return {
      type: ACTIONS.HOLD_BILLABLE_TIME,
      payload,
    };
  },
  holdBillableTimeSuccess() {
    return {
      type: ACTIONS.HOLD_BILLABLE_TIME_SUCCESS,
    };
  },
  holdBillableTimeFail() {
    return {
      type: ACTIONS.HOLD_BILLABLE_TIME_FAIL,
    };
  },
  releaseBillableTime(payload) {
    return {
      type: ACTIONS.RELEASE_BILLABLE_TIME,
      payload,
    };
  },
  releaseBillableTimeSuccess() {
    return {
      type: ACTIONS.RELEASE_BILLABLE_TIME_SUCCESS,
    };
  },
  releaseBillableTimeFail() {
    return {
      type: ACTIONS.RELEASE_BILLABLE_TIME_FAIL,
    };
  },
  requestBillableTimeEdits(payload) {
    return {
      type: ACTIONS.REQUEST_BILLABLE_TIME_EDITS,
      payload,
    };
  },
  requestBillableTimeEditsSuccess() {
    return {
      type: ACTIONS.REQUEST_BILLABLE_TIME_EDITS_SUCCESS,
    };
  },
  requestBillableTimeEditsFail() {
    return {
      type: ACTIONS.REQUEST_BILLABLE_TIME_EDITS_FAIL,
    };
  },
  sendTimesheetBillableTime(payload) {
    return {
      type: ACTIONS.SEND_TIMESHEET_BILLABLE_TIME,
      payload,
    };
  },
  sendTimesheetBillableTimeSuccess() {
    return {
      type: ACTIONS.SEND_TIMESHEET_BILLABLE_TIME_SUCCESS,
    };
  },
  sendTimesheetBillableTimeFail() {
    return {
      type: ACTIONS.SEND_TIMESHEET_BILLABLE_TIME_FAIL,
    };
  },
  saveTimesheetAsDraft(payload) {
    return {
      type: ACTIONS.SAVE_TIMESHEET_AS_DRAFT,
      payload,
    };
  },
  saveTimesheetAsDraftSuccess() {
    return {
      type: ACTIONS.SAVE_TIMESHEET_AS_DRAFT_SUCCESS,
    };
  },
  saveTimesheetAsDraftFail() {
    return {
      type: ACTIONS.SAVE_TIMESHEET_AS_DRAFT_FAIL,
    };
  },
  deleteBillableTime(payload) {
    return {
      type: ACTIONS.DELETE_BILLABLE_TIME,
      payload,
    };
  },
  deleteBillableTimeSuccess(payload) {
    return {
      type: ACTIONS.DELETE_BILLABLE_TIME_SUCCESS,
      payload,
    };
  },
  deleteBillableTimeFail() {
    return {
      type: ACTIONS.DELETE_BILLABLE_TIME_FAIL,
    };
  },
  getBillableItemById(payload) {
    return {
      type: ACTIONS.GET_BILLABLE_ITEM_BY_ID,
      payload,
    };
  },
  getBillableItemByIdSuccess(payload) {
    return {
      type: ACTIONS.GET_BILLABLE_ITEM_BY_ID_SUCCESS,
      payload,
    };
  },
  revertBillableItemToInProgress(payload) {
    return {
      type: ACTIONS.REVERT_BILLABLE_ITEM_TO_IN_PROGRESS,
      payload,
    };
  },
  revertBillableItemToInProgressSuccess() {
    return {
      type: ACTIONS.REVERT_BILLABLE_ITEM_TO_IN_PROGRESS_SUCCESS,
    };
  },
  revertBillableItemToInProgressFail() {
    return {
      type: ACTIONS.REVERT_BILLABLE_ITEM_TO_IN_PROGRESS_FAIL,
    };
  },
};

export default { ...ACTIONS, ...ACTION_CREATORS };
