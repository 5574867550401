import { getCustomerSignedFormAsync } from "api/parent";
import { GET_CUSTOMER_SIGNED_FORM } from "constants/reactQueryKeys";
import { useQuery } from "react-query";

export const useGetCustomerSignedForm = (formData) => {
  const { data, isLoading, ...rest } = useQuery(
    [GET_CUSTOMER_SIGNED_FORM, formData.clientId],
    () => getCustomerSignedFormAsync(formData),
    {
      enabled: !!formData.clientId && !!formData.type,
    }
  );
  return {
    customerSignedForm: data?.data,
    customerSignedFormLoading: isLoading,
    ...rest,
  };
};
