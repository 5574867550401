// Initial State
export const getInitialState = () => {
  return {
    emailError: false,
    phoneError: false,
    secondaryPhoneError: false,
    zipError: false,
    doctorPhoneError: false,
    diagnosingPhoneError: false,
    insurancePhoneError: false,
    secondaryInsurancePhoneError: false,
    familyPhoneError: false,
    familyEmailError: false,
    familyFirstNameError: false,
    familyLastNameError: false,
    signatureStarted: false,
    phiFormOpen: false,
    clientFirstName: "",
    clientLastName: "",
    clientDOB: "",
    clientAge: "",
    clientGender: "",
    clientHasASDDiagnosis: null,
    clientDiagnosis: "",
    clientFirstNameError: false,
    clientLastNameError: false,
    clientDOBError: false,
    clientGenderError: false,
    clientHasASDDiagnosisError: false,
    timezoneError: false,
    clientAddress1: "",
    clientAddress2: "",
    clientCity: "",
    clientZip: "",
    clientState: "",
    clientCountry: "United States",
    clientAddress1Error: false,
    clientCityError: false,
    clientStateError: false,
    clientZipError: false,
    guardianFirstName: "",
    guardianLastName: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    contactTime: "",
    contactMethod: "",
    insuranceId: "",
    insurancePlanId: "",
    insuranceMemberId: "",
    insuranceGroupNumber: "",
    insuranceHolderRelationship: "",
    insuranceHolderName: "",
    insuranceHolderDOB: "",
    insuranceType: "",
    insuranceExpirationDate: "",
    insuranceEffectiveDate: "",
    isPrivatePayError: false,
    preAuthRequiredError: false,
    insuranceIdError: false,
    insurancePlanIdError: false,
    insuranceMemberIdError: false,
    insuranceHolderDOBError: false,
    insuranceHolderNameError: false,
    insuranceHolderRelationshipError: false,
    secondaryInsuranceId: "",
    secondaryInsurancePlanId: "",
    secondaryInsuranceMemberId: "",
    secondaryInsuranceGroupNumber: "",
    secondaryInsuranceHolderDOB: "",
    secondaryInsuranceHolderName: "",
    secondaryInsuranceHolderRelationship: "",
    secondaryInsuranceIdError: false,
    secondaryInsurancePlanIdError: false,
    secondaryInsuranceMemberIdError: false,
    secondaryInsuranceHolderDOBError: false,
    secondaryInsuranceHolderNameError: false,
    secondaryInsuranceHolderRelationshipError: false,
    privatePay: null,
    savedUserId: null,
    successDialogOpen: false,
    timezone: "",
    clientPronouns: "",
    familyMembers: [],
    familyMembersError: false,
    familyDrawerOpen: false,
    editFamilyDrawerOpen: false,
    addFamilyMember: {},
    updateFamilyMember: {},
    hasSecondaryInsurance: false,
    insurancePhoneError: false,
    secondaryInsurancePhoneError: false,
    physicianDrawerOpen: false,
    physicians: [],
    physiciansError: false,
    additionalInfo: "",
    isVerbal: null,
    hasComputer: null,
    hasInternet: null,
    preAuthRequired: null,
    primaryInsurancePlans: [],
    secondaryInsurancePlans: [],
    personalInfoOpen: false,
    clientAddressOpen: false,
    familyMembersOpen: false,
    insuranceOpen: false,
    physiciansOpen: false,
  };
};

// Validation
export const validateEmail = (email) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

// String Manipulation
export const stringToBoolean = (value) => {
  if (value && typeof value === "string") {
    if (value.toLowerCase() === "true") return true;
    if (value.toLowerCase() === "false") return false;
  }
  return value;
};
