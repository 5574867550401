import React from "react";
import { Dialog, Typography, DialogContent, DialogActions, Button } from "@mui/material";
import { Warning } from "@phosphor-icons/react";
import CircularProgress from "@mui/material/CircularProgress";

const ConfirmDeleteDialog = (props) => {
  const { classes, onConfirmDelete, onClose, open, deleteNoteLoading } = props;

  return (
    <Dialog
      open={open}
      disableEscapeKeyDown={true}
      PaperProps={{
        style: {
          maxWidth: "95%",
          width: 444,
        },
      }}
    >
      <DialogContent>
        <div className={classes.confirmSubmitDialogContentContainer}>
          <div className={classes.confirmSubmitDialogIconContainer}>
            <Warning size={22} color="rgba(237, 108, 2, 1)" />
          </div>
          <div className={classes.confirmSubmitDialogTitleContainer}>
            <Typography
              component="p"
              variant="subtitle1"
              className={classes.confirmSubmitDialogTitle}
            >
              Delete Service?
            </Typography>
            <Typography
              component="p"
              variant="body2"
              className={classes.confirmSubmitDialogContent}
            >
              Deleting is permanent and any notes will be lost.
            </Typography>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="default" disabled={deleteNoteLoading}>
          Cancel
        </Button>
        <Button color="error" onClick={onConfirmDelete} disabled={deleteNoteLoading}>
          {deleteNoteLoading && (
            <CircularProgress style={{ color: "#C0C8D0", marginRight: 8 }} size={16} />
          )}
          Delete Service
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDeleteDialog;
