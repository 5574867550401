import React, { Component } from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../../actions";

import styles from "./styles";
import {
  getChannelList,
  getPublicChannelList,
  getChannel,
  getMessagesForChannel,
  sendFileMessage,
  joinChannel,
  leaveChannel,
} from "api/sendbird";

import ChatList from "./ChatList";
import ChatHeader from "./ChatHeader";
import ChatMessages from "./ChatMessages";
import ConfirmationDialog from "./ConfirmationDialog";
import GroupDescriptionDialog from "./GroupDescriptionDialog";

import uuidv4 from "uuid/v4";
import { uniqBy, filter, findIndex, partition } from "lodash";
import { DropzoneDialog } from "mui-file-dropzone";
import { getSendbirdConnection } from "selectors";

const mapStateToProps = (state) => ({
  connection: getSendbirdConnection(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getSendbirdConnection: actions.getSendbirdConnection,
    },
    dispatch,
  );

const NUM_TO_RETRIEVE = 30;

class AnswersNowChat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      showError: false,
      currentChannel: null,
      channelList: [],
      messages: [],
      handler: null,
      handlerKey: null,
      typingMembers: [],
      typingMessage: "",
      focused: true,
      messageQuery: null,
      loadingMessages: false,
      loadingChannels: false,
      open: false,
      openGroup: false,
      openConfirm: false,
      openDescription: false,
      privateChannels: [],
      publicChannelsJoined: [],
      publicChannelsUnjoined: [],
      confirmationType: "join",
      selectedChannel: null,
      allMessagesDisplay: false,
      dropzoneKey: 0,
      screenWidth: 0,
    };
  }

  componentDidMount() {
    if (!this.props.userId || !this.props.name) {
      this.setState({ showError: true });
      return;
    }
    if (this.props.connection) {
      this.setupChat();
    } else {
      this.props.getSendbirdConnection(
        this.props.userId,
        this.props.name,
        process.env.SENDBIRD_APP_ID,
      );
    }
    window.addEventListener("focus", this.onFocus);
    window.addEventListener("blur", this.onBlur);
    window.addEventListener("resize", this.handleResize);
    this.setState({ screenWidth: window.innerWidth });
  }

  componentWillUnmount() {
    if (!this?.props?.userId || !this?.props?.name) {
      return;
    }
    this.disconnectChannels();
    window.removeEventListener("focus", this.onFocus);
    window.removeEventListener("blur", this.onBlur);
    window.removeEventListener("resize", this.handleResize);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.focused && this.state.focused) {
      this.markMessagesRead();
    }
    if (!prevProps.connection && this.props.connection) {
      this.setupChat();
    }
  }

  handleResize = () => {
    this.setState({ screenWidth: window.innerWidth });
  };

  attemptToJoin = (channel, isPremium) => (e) => {
    if (isPremium && !this.props.canJoinPremiumCommunity) {
      this.props.onPremiumClick();
      return;
    }

    this.setState({
      openGroup: false,
      openConfirm: true,
      selectedChannel: channel,
      confirmationType: "join",
      mobileOpen: false,
    });
  };

  attemptToLeave = (channel) => (e) => {
    this.setState({
      openConfirm: true,
      selectedChannel: channel,
      confirmationType: "leave",
      mobileOpen: false,
    });
  };

  connectToChannel = async (url) => {
    const { connection } = this.props;
    const currentChannel = await getChannel(url, connection);
    const message = "";
    const mentions = [];
    const messageText = "";

    const messageQuery = currentChannel.createPreviousMessageListQuery();
    messageQuery.limit = NUM_TO_RETRIEVE;
    messageQuery.reverse = true;

    let messages = await getMessagesForChannel(currentChannel, messageQuery);
    messages = messages.reverse();
    const allMessagesDisplay = messages.length < NUM_TO_RETRIEVE;

    // now set up the Handlers
    const handlerKey = uuidv4();
    const handler = new connection.ChannelHandler();
    handler.onMessageReceived = (channel, message) => {
      if (channel.url !== this.state.currentChannel.url) {
        return;
      }
      let messages = this.mergeMessages([message]);
      this.setState({ messages });
      this.markMessagesRead();
      // this.props.setCurrentChannelUrl(message)
    };
    handler.onTypingStatusUpdated = (channel) => {
      if (channel.url !== this.state.currentChannel.url) {
        return;
      }
      const typingMembers = channel.getTypingMembers();
      const typingMessage = this.getTypingMessage(typingMembers);
      this.setState({ typingMembers, typingMessage });
    };
    connection.addChannelHandler(handlerKey, handler);

    this.setState(
      {
        currentChannel,
        message,
        mentions,
        messageText,
        messages,
        handler,
        handlerKey,
        messageQuery,
        allMessagesDisplay,
      },
      () => {
        this.timeoutKey = setInterval(this.syncChannels, 3000);
        this.markMessagesRead();
      },
    );
  };

  disconnectChannels = () => {
    if (this.state.handlerKey) {
      clearInterval(this.timeoutKey);
      this.timeoutKey = null;
      this.props.connection.removeChannelHandler(this.state.handlerKey);
    }
  };

  getChannel = (url) => {
    if (this.state.handlerKey) {
      clearInterval(this.timeoutKey);
      this.props.connection.removeChannelHandler(this.state.handlerKey);
      this.setState({ handler: null, handlerKey: null });
    }
    this.connectToChannel(url);
  };

  getMoreMessages = async () => {
    let { messageQuery, currentChannel, messages, loadingMessages } = this.state;

    const msgs = await getMessagesForChannel(currentChannel, messageQuery);
    messages = uniqBy([...msgs.reverse(), ...messages], "messageId");
    loadingMessages = false;
    const allMessagesDisplay = msgs.length < NUM_TO_RETRIEVE;
    this.setState({
      messages,
      loadingMessages,
      allMessagesDisplay,
    });
  };

  getTypingMessage = (typingMembers) => {
    const filteredList = filter(typingMembers, (user) => {
      return user.userId !== this.props.connection.userId;
    });
    if (filteredList.length === 0) {
      return null;
    }
    const verb = filteredList.length > 1 ? "are" : "is";
    const members = filteredList
      .map((member) => {
        return member.nickname;
      })
      .join(",");
    return `${members} ${verb} typing`;
  };

  handleClose = (e) => {
    this.setState({ open: false });
  };

  handleCloseConfirm = (e) => {
    this.setState({
      openConfirm: false,
      confirmationType: "join",
    });
  };

  handleCloseGroups = (e) => {
    this.setState({
      publicChannels: [],
      openGroup: false,
      loadingChannels: false,
    });
  };

  handleJoin = async (e) => {
    const { selectedChannel } = this.state;
    try {
      await joinChannel(selectedChannel);
      this.getChannel(selectedChannel.url);
      this.handleCloseConfirm();
      if (this.props.onJoin) {
        this.props.onJoin(selectedChannel.url);
      }
      // this.setupChat();
    } catch (err) {
      console.error(err);
    }
  };

  handleLeave = async (e) => {
    const { selectedChannel, channelList, publicChannelsJoined } = this.state;
    const length = publicChannelsJoined.length;
    try {
      await leaveChannel(selectedChannel);
      if (length === 1) {
        this.setState({ selectedChannel: null });
      } else {
        if (publicChannelsJoined[0].url === selectedChannel.url) {
          this.getChannel(publicChannelsJoined[1].url);
        } else {
          this.getChannel(publicChannelsJoined[0].url);
        }
      }
      this.handleCloseConfirm();
      this.updateCommunityChannels();
      if (this.props.onLeave) {
        this.props.onLeave(selectedChannel.url);
        this.setState({ selectedChannel: null });
      }
    } catch (err) {
      console.error(err);
    }
  };

  handleUpload = async (files) => {
    const { connection } = this.props;
    if (files.length === 0) {
      return;
    }
    const file = files[0];
    let { currentChannel, messages, dropzoneKey } = this.state;
    const canSendMessage = this.props.canSendMessage();
    let url = "";
    if (canSendMessage) {
      const m = await sendFileMessage(file, currentChannel, connection);
      url = m.url;
      messages = messages.concat(m);
    }

    // now call the callback
    const { userId } = this.props;
    const members = filter(currentChannel.members, (m) => {
      return m.userId !== userId;
    });

    const message = `A file was attached at:
        ${url}`;
    if (members.length === 1) {
      const online = members[0].connectionStatus === "online";
      this.props.onMessageSend(message, currentChannel, this.props.userId, online);
    } else {
      this.props.onMessageSend(message, currentChannel, this.props.userId, false);
    }

    this.setState(
      {
        messages,
        open: false,
        dropzoneKey: dropzoneKey + 1,
      },
      () => {
        currentChannel.endTyping();
      },
    );
  };

  handleDrawerToggle = () => {
    this.setState((state) => ({ mobileOpen: !state.mobileOpen }));
  };

  handleDrawerClose = () => {
    this.setState({ mobileOpen: false });
  };

  handleToggleDescription = () => {
    this.setState({ openDescription: !this.state.openDescription });
  };

  markMessagesRead = () => {
    const { currentChannel, focused } = this.state;

    if (currentChannel && focused) {
      currentChannel.markAsRead();
    }

    if (currentChannel && this.props.onRead) {
      this.props.onRead(currentChannel.url);
    }
  };

  mergeMessages = (msgs) => {
    let { messages } = this.state;

    messages = uniqBy(messages.concat(msgs), "messageId");
    return messages;
  };

  onBlur = (e) => {
    this.setState({ focused: false });
  };

  onFocus = (e) => {
    this.setState({ focused: true });
  };

  onScroll = () => {
    const { loadingMessages } = this.state;

    if (!loadingMessages) {
      this.setState({ loadingMessages: true }, () => {
        this.getMoreMessages();
      });
    }
  };

  openDialog = (e) => {
    this.setState({ open: true });
  };

  openGroupDialog = async (e) => {
    const { connection, userId } = this.props;

    this.setState({
      loadingChannels: true,
      openGroup: true,
      publicChannels: [],
    });

    try {
      const result = await getPublicChannelList(connection);

      // filter public channels to ones that they haven't joined
      const publicChannels = filter(result, (channel) => {
        return findIndex(channel.members, { userId }) < 0;
      });

      this.setState({
        publicChannels,
        loadingChannels: false,
      });
    } catch (err) {
      console.error(err);
    }
  };

  onHeadingClick = (clinicianUserId) => {
    if (this.props.onHeadingClick) {
      this.props.onHeadingClick(clinicianUserId);
    }
  };

  setupChat = async (connected) => {
    const { connection, userId, name, channelUrl, appId, chatType } = this.props;
    const { selectedChannel } = this.state;
    try {
      let channelList = await getChannelList(connection, chatType || "all");
      channelList = channelList.filter((channel) => {
        let data = channel.data ? JSON.parse(channel.data) : {};
        let unreadMessages = channel.unreadMessageCount || 0;
        if (data.is_video_chat && unreadMessages > 0) {
          channel.markAsRead();
        }
        return !data.is_video_chat;
      });
      let privateChannels = await getChannelList(connection, "private");
      privateChannels = privateChannels.filter((channel) => {
        let data = channel.data ? JSON.parse(channel.data) : {};
        let unreadMessages = channel.unreadMessageCount || 0;
        if (data.is_video_chat && unreadMessages > 0) {
          channel.markAsRead();
        }
        return !data.is_video_chat;
      });
      const groups = await getPublicChannelList(connection);
      const publicChannels = partition(groups, {
        members: [{ userId }],
      });

      if (privateChannels.length > 0 && (chatType === "all" || chatType === "private")) {
        const url = selectedChannel ? selectedChannel.url : channelUrl || privateChannels[0].url;

        this.setState(
          {
            channelList,
            privateChannels,
            publicChannelsJoined: publicChannels[0],
            publicChannelsUnjoined: publicChannels[1],
          },
          () => {
            this.connectToChannel(url);
          },
        );
      } else if (channelList.length > 0) {
        const url = selectedChannel ? selectedChannel.url : channelUrl || channelList[0].url;
        this.setState(
          {
            channelList,
            privateChannels,
            publicChannelsJoined: publicChannels[0],
            publicChannelsUnjoined: publicChannels[1],
          },
          () => {
            this.connectToChannel(url);
          },
        );
      } else if (channelUrl) {
        this.connectToChannel(channelUrl);
      }

      if (
        publicChannels[0].length === 0 &&
        privateChannels.length === 0 &&
        this.state.screenWidth < 600
      ) {
        this.handleDrawerToggle();
      }
      if (channelList.length === 0 && chatType === "public") {
        this.setState(
          {
            publicChannelsJoined: publicChannels[0],
            publicChannelsUnjoined: publicChannels[1],
          },
          this.openGroupDialog,
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  syncChannels = async () => {
    const { connection, chatType } = this.props;
    const channelList = await getChannelList(connection, chatType || "all");
    this.setState({ channelList });
  };

  updateCommunityChannels = async () => {
    let { connection } = this.props;
    const publicChannels = await getChannelList(connection, "public");

    this.setState({
      publicChannelsJoined: publicChannels,
    });
  };

  notesClick = () => {
    const { currentChannel } = this.state;
    const { userId } = this.props;
    let metaData = JSON.parse(currentChannel.data);
    if (metaData.parentUserId) {
      this.props.onNotesClick(metaData.parentUserId);
    } else {
      const members = filter(currentChannel.members, (m) => {
        return m.userId !== userId && !m.metaData.isBCBA;
      });
      if (members.length > 0) {
        this.props.onNotesClick(members[0].userId);
      }
    }
  };

  logoutClick = () => {
    this.props.onLogoutClick();
  };

  linkClick = (e) => {
    if (this.props.trackLink) {
      if (e.target.tagName === "A") {
        this.props.trackLink(e.target.href);
      }
    }
  };

  setNewMessageList = (messages) => {
    this.setState({ messages });
  };

  render() {
    const {
      classes,
      theme,
      displayLeftDrawer,
      displayNotes,
      displayLogout,
      displayBack,
      displayHeaderBack,
      onNotesClick,
      canJoinPremiumCommunity,
      miniDisplay,
    } = this.props;

    if (this.state.showError) {
      return <div>User Id and Name are required</div>;
    }
    return (
      <div
        className={classes.root}
        ref={(el) => {
          this.chatWindow = el;
        }}
      >
        {!miniDisplay && (
          <ChatHeader
            {...this.state}
            {...this.props}
            handleDrawerToggle={this.handleDrawerToggle}
            displayLeftDrawer={displayLeftDrawer}
            displayNotes={displayNotes}
            displayLogout={displayLogout}
            displayBack={displayBack}
            displayHeaderBack={displayHeaderBack}
            onNotesClick={this.notesClick}
            onLogoutClick={this.logoutClick}
            onHeadingClick={this.onHeadingClick}
            handleToggleDescription={this.handleToggleDescription}
          />
        )}
        {displayLeftDrawer && (
          <ChatList
            {...this.state}
            {...this.props}
            handleDrawerToggle={this.handleDrawerToggle}
            handleDrawerClose={this.handleDrawerClose}
            getChannel={this.getChannel}
            openGroupDialog={this.openGroupDialog}
            attemptToLeave={this.attemptToLeave}
            attemptToJoin={this.attemptToJoin}
          />
        )}
        <ChatMessages
          {...this.state}
          {...this.props}
          onScrollUp={this.onScroll}
          onUploadClick={this.openDialog}
          onLinkClick={this.linkClick}
          onAvatarClick={this.onHeadingClick}
          setNewMessageList={this.setNewMessageList}
        />

        <DropzoneDialog
          key={this.state.dropzoneKey}
          open={this.state.open}
          filesLimit={1}
          onSave={this.handleUpload}
          showPreviews={false}
          showPreviewsInDropzone={true}
          maxFileSize={5000000}
          onClose={this.handleClose}
          showAlerts={true}
          dropzoneText={"Drag and drop or select a file"}
          dialogProps={{
            classes: { root: classes.dropzoneDialogRoot },
          }}
        />

        <ConfirmationDialog
          {...this.state}
          handleJoin={this.handleJoin}
          handleLeave={this.handleLeave}
          handleCloseConfirm={this.handleCloseConfirm}
        />
        <GroupDescriptionDialog
          {...this.state}
          {...this.props}
          handleToggleDescription={this.handleToggleDescription}
        />
      </div>
    );
  }
}

AnswersNowChat.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(AnswersNowChat));
