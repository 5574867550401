import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { X } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import ANUploader from "elements/ANUploader";

export default function UploadAssessmentDialog({
  open,
  handleClose,
  handleFileUpload,
  dropzoneFiles,
  handleFileDelete,
  submitDocumentUpload,
  isNew,
  alreadyUploadedFiles,
  handleLoadedFileDelete,
}) {
  const [showErrors, setShowErrors] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [disableSaveButton, setDisableSaveButton] = useState(false);

  useEffect(() => {
    // since the dialog does not unmount on close, we need to reset the state when it opens
    if (open) setDisableSaveButton(!isNew);
  }, [open]);

  const handleSubmit = () => {
    setShowErrors(true);
    if ((!dropzoneFiles.length && !alreadyUploadedFiles.length) || dropzoneFiles?.[0]?.errors)
      return;
    setUploading(true);
    submitDocumentUpload()
      .then(() => {
        handleClose();
        setShowErrors(false);
      })
      .catch((e) => console.error(e))
      .finally(() => setUploading(false));
  };

  const internalHandleClose = () => {
    handleClose();
    setTimeout(() => setShowErrors(false), 250); // Timeout avoids flickering
  };

  return (
    <Dialog open={open} onClose={internalHandleClose} maxWidth="xs">
      <DialogTitle>
        {`${isNew ? "Upload" : "Edit"} Assessment`}
        <IconButton aria-label="close" onClick={internalHandleClose} disabled={uploading}>
          <X />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <ANUploader
          handleFileUpload={handleFileUpload}
          dropzoneFiles={dropzoneFiles}
          useDropzoneOptions={{ multiple: false, accept: ["text/csv", "application/vnd.ms-excel"] }}
          handleFileDelete={handleFileDelete}
          handleLoadedFileDelete={(file) => {
            handleLoadedFileDelete(file);
            setDisableSaveButton(false);
          }}
          error={!dropzoneFiles.length && !alreadyUploadedFiles.length && showErrors}
          errorText="Please upload a file"
          dropZoneStyle={{ margin: 0 }}
          filePreviewStyle={{ margin: 0 }}
          alreadyUploadedFiles={alreadyUploadedFiles}
          hideDropzone={dropzoneFiles.length || alreadyUploadedFiles.length}
          removeFileButtonProps={{ disabled: uploading }}
        />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={internalHandleClose} disabled={uploading}>
          Cancel
        </Button>
        <LoadingButton onClick={handleSubmit} loading={uploading} disabled={disableSaveButton}>
          {isNew ? "Upload Document" : "Save Changes"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
