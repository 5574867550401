import React from "react";
import {
  Accordion,
  AccordionSummary,
  Typography,
  Switch,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import { CaretDown } from "@phosphor-icons/react";
import { useTheme } from "@mui/styles";
import { useSelector } from "react-redux";
import * as selectors from "selectors";

export const OtherFiltering = ({
  showNoShows,
  setShowNoShows,
  showSupervisoryEvents,
  handleToggleShowSupervisoryEvents,
  showTentativeEvents,
  handleToggleTentativeEvents,
}) => {
  const theme = useTheme();
  const userPermissions = useSelector(selectors.getUserPermissionsList);

  return (
    <Accordion
      defaultExpanded
      square
      disableGutters
      sx={{
        "&::before": { display: "none" },
        "&.Mui-expanded": { margin: 0 },
        padding: 0,
        boxShadow: "none",
      }}
    >
      <AccordionSummary expandIcon={<CaretDown size={20} />} sx={{ padding: 0 }}>
        <Typography>Other</Typography>
      </AccordionSummary>
      <FormGroup
        sx={{
          "& .MuiFormControlLabel-root": { margin: 0 },
          gap: theme.spacing(3),
          marginBottom: theme.spacing(5),
        }}
      >
        <FormControlLabel
          control={
            <Switch
              size="small"
              checked={showNoShows}
              onChange={() => setShowNoShows(!showNoShows)}
            />
          }
          label="Show No-shows & Cancellations"
        />
        <FormControlLabel
          control={
            <Switch
              size="small"
              checked={showSupervisoryEvents}
              onChange={handleToggleShowSupervisoryEvents}
            />
          }
          label="Show Supervisory Events"
        />

        {userPermissions?.create_proposed_event && (
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={showTentativeEvents}
                onChange={handleToggleTentativeEvents}
              />
            }
            label="Show Proposed Events"
          />
        )}
      </FormGroup>
    </Accordion>
  );
};
