import { Alert, AlertTitle, Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import React from "react";

export default function GuardianRequiredDialog({ open, onClose }) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs">
      <DialogContent>
        <Alert severity="error">
          <AlertTitle>The client requires a legal guardian</AlertTitle>
          Please choose at least one team member as the legal guardian of the client
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
