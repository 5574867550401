import React from "react";
import withStyles from "@mui/styles/withStyles";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PaginatedFooter from "./PaginatedFooter";
import SubHeading from "elements/SubHeading";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";

const CustomTable = (props) => {
  const {
    classes,
    headerCells,
    maxHeight,
    count,
    rowsPerPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    noResultsMessage,
    lockRowsPerPage,
    clearFilters,
  } = props;

  return (
    <TableContainer style={maxHeight ? { maxHeight } : null}>
      <Table stickyHeader className={classes.table}>
        <TableHead>
          <TableRow>
            {headerCells.map((cell, index) => (
              <React.Fragment key={index}>{cell}</React.Fragment>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{props.children}</TableBody>
        {rowsPerPage && !!props.children && <PaginatedFooter {...props} />}
      </Table>
      {count == 0 && (
        <div className={classes.noResultsMessage}>
          <div
            style={{
              height: "236px",
              width: "562px",
              padding: "64px 0px 64px 0px",
              textAlign: "left",
            }}
          >
            <Typography variant="h3">{noResultsMessage || "No Results Found"}</Typography>
            <Typography variant="body1">
              Try adjusting your search or filter options to find what you are looking for.
            </Typography>
            {!!clearFilters && (
              <Button
                onClick={clearFilters}
                variant="text"
                color="info"
                style={{ marginTop: "15px" }}
              >
                Clear Filters
              </Button>
            )}
          </div>
        </div>
      )}
    </TableContainer>
  );
};

const styles = (theme) => ({
  table: {
    minWidth: 700,
  },
  noResultsMessage: {
    width: "100%",
    padding: 10,
    display: "flex",
    justifyContent: "center",
  },
});

export default withStyles(styles)(CustomTable);
export { default as CustomHeaderCell } from "./CustomHeaderCell";
export { default as TableFooter } from "./PaginatedFooter";
