import { createReducer } from "redux-create-reducer";
import actions from "../actions";

const initialState = {
  connection: null,
  unreadCount: 0,
};

const reducers = {
  [actions.GET_SENDBIRD_CONNECTION_SUCCESS](state, { payload }) {
    return {
      ...state,
      connection: payload,
    };
  },
  [actions.GET_UNREAD_CHANNEL_COUNT_SUCCESS](state, { payload }) {
    return {
      ...state,
      unreadCount: payload,
    };
  },
  [actions.DISCONNECT_SENDBIRD_SUCCESS](state) {
    return {
      ...state,
      connection: null,
      unreadCount: 0,
    };
  },
};

export default createReducer(initialState, reducers);
