import { createInsuranceVOB, getInsuranceVOB, updateInsuranceVOB } from "../api/customers";
import { useMutation, useQuery } from "react-query";
import { transformKeysToCamel } from "../utils/transformKeys";
import { GET_INSURANCE_VOB } from "constants/reactQueryKeys";

export const useGetInsuranceVOB = (clientId, params, options = {}) => {
  const { data, isLoading, error } = useQuery({
    queryKey: [GET_INSURANCE_VOB, clientId, params],
    queryFn: () => getInsuranceVOB(clientId, params),
    ...options,
  });
  return { data, isLoading, error };
};

export const useCreateInsuranceVOB = (options) => {
  const mutation = useMutation(async (payload) => {
    return await createInsuranceVOB(payload.clientId, transformKeysToCamel(payload));
  }, options);
  return mutation;
};

export const useUpdateInsuranceVOB = (options) => {
  const mutation = useMutation(async (payload) => {
    return await updateInsuranceVOB(payload.clientId, transformKeysToCamel(payload));
  }, options);
  return mutation;
};
