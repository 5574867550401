import React from "react";
import {
  Box,
  Badge,
  Avatar,
  Popover,
  IconButton,
  MenuList,
  MenuItem,
  ListItemIcon,
} from "@mui/material";
import { Bell, Check, Sidebar } from "@phosphor-icons/react";
import AvatarInitials from "./AvatarInitials";
import LogoIcon from "../../../assets/an_purple_white.png";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/styles";

export const Tools = ({
  handleBellClicked,
  unreadNotifications,
  userAvatarSrc,
  menuItems,
  userInitials,
  handleDrawerToggle,
}) => {
  const anchorEl = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const [openSubMenu, setOpenSubMenu] = React.useState(false);
  const [anchorElSubMenu, setAnchorElSubMenu] = React.useState(false);
  const [subMenuItems, setSubMenuItems] = React.useState([]);
  const theme = useTheme();

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClickSubMenu = () => {
    setOpenSubMenu(!openSubMenu);
  };

  const handleMenuItemClicked = (clickHandler) => {
    setOpen(false);
    clickHandler();
  };

  const handleEventMenuItemClicked = (event, items, clickHandler) => {
    setAnchorElSubMenu(event.currentTarget);
    setSubMenuItems(items);
    setOpenSubMenu(true);
    clickHandler();
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      width={"100%"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerToggle}>
        <Sidebar size={24} color={"#D2B3EA"} />
      </IconButton>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={LogoIcon} style={{ height: 29, width: "auto" }} />
      </div>
      <Box display={"flex"} flexDirection={"row"} alignItems={"center"} justifyContent={"flex-end"}>
        {handleBellClicked && (
          <IconButton sx={{ marginRight: 2 }} onClick={handleBellClicked}>
            <Badge badgeContent={unreadNotifications} color="primary" variant="dot">
              <Bell />
            </Badge>
          </IconButton>
        )}

        <IconButton onClick={handleClick} ref={anchorEl}>
          {userAvatarSrc || !userInitials ? (
            <Avatar src={userAvatarSrc} sx={{ width: 30, height: 30 }} />
          ) : (
            <AvatarInitials userInitials={userInitials} />
          )}
        </IconButton>
        <Popover
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={open}
          anchorEl={anchorEl.current}
          onClose={handleClick}
          PaperProps={{
            style: { width: "150px", minWidth: "fit-content" },
          }}
        >
          <MenuList>
            {menuItems.map(
              (
                {
                  value,
                  label,
                  disabled = undefined,
                  icon = "",
                  divider = false,
                  typography = false,
                  clickHandler,
                  endIcon = "",
                  subMenuItems = null,
                },
                i
              ) => (
                <MenuItem
                  key={i}
                  value={value}
                  onClick={(event) =>
                    !subMenuItems
                      ? handleMenuItemClicked(clickHandler)
                      : handleEventMenuItemClicked(event, subMenuItems, clickHandler)
                  }
                  disabled={disabled}
                  divider={divider}
                >
                  <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {icon !== "" ? (
                        <ListItemIcon>
                          {React.createElement(icon, {
                            weight: "duotone",
                            color: "text.secondary",
                          })}
                        </ListItemIcon>
                      ) : null}
                      {typography ? (
                        <Typography
                          variant="caption"
                          color="text.secondary"
                        >{`${label}`}</Typography>
                      ) : (
                        label
                      )}
                    </Box>
                    {endIcon !== "" ? (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {React.createElement(endIcon, {
                          color: "text.secondary",
                        })}
                      </Box>
                    ) : null}
                  </Box>
                </MenuItem>
              )
            )}
          </MenuList>
        </Popover>
        <Popover
          anchorEl={anchorElSubMenu}
          open={openSubMenu || false}
          onClose={handleClickSubMenu}
          anchorOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          PaperProps={{
            style: { minWidth: "264px" },
          }}
          style={{
            paddingTop: "2px",
            padding: "2px",
          }}
        >
          <MenuList>
            {subMenuItems &&
              subMenuItems.map(
                (
                  {
                    label,
                    avatar = false,
                    avatarUrl = undefined,
                    avatarInitials = undefined,
                    clickHandler,
                    checked,
                  },
                  i
                ) => (

                  <MenuItem
                    onClick={() => {
                      clickHandler();
                    }}
                    onClose={handleClickSubMenu}
                    key={i}
                  >
                    {avatar && (
                      <Avatar sx={{ mr: theme.spacing(3) }} size="24px">
                        {avatarInitials}
                      </Avatar>
                    )}
                    <Typography flexGrow={1} variant="body2">
                      {label}
                    </Typography>
                    {checked && (
                      <Check size={24} color={theme.palette.primary.main} weight="regular" />
                    )}
                  </MenuItem>
                )
              )}
          </MenuList>
        </Popover>
      </Box>
    </Box>
  );
};
