import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import withStyles from "@mui/styles/withStyles";
import actions from "actions";
// import EventTracking from "../../utils/eventtracking";
import CircularProgress from "@mui/material/CircularProgress";
// import ChatWrapper from "./ChatWrapper";
import Clinician from "../Clinician/Clinician";
import AnswersNowChat from "../AnswersNowChat";
import Modal from "elements/Modal";

import styles from "./styles";

import {
  getSignedinState,
  getParentInfo,
  isLoaded,
  getLogin,
  canMessage,
  userClinician,
  videoIsRunning,
  videoKey,
  userLoaded,
  getUser,
  getGroups,
  isCustomerCare,
  isSupervisor,
  isDirector,
  isCaregiver,
  getCurrentChannelUrl,
} from "../../selectors";

const mapStateToProps = (state) => ({
  signedInState: getSignedinState(state),
  login: getLogin(state),
  loaded: isLoaded(state),
  parent: getParentInfo(state),
  canMessage: canMessage(state),
  clinician: userClinician(state),
  isRunning: videoIsRunning(state),
  videoKey: videoKey(state),
  user: getUser(state),
  userLoaded: userLoaded(state),
  isCustomerCare: isCustomerCare(state),
  isSupervisor: isSupervisor(state),
  isDirector: isDirector(state),
  isCaregiver: isCaregiver(state),
  currentChannelUrl: getCurrentChannelUrl(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      startSignin: actions.startSignin,
      checkAuthState: actions.checkAuthState,
      getParent: actions.getParentInfo,
      getUser: actions.getUser,
      sendMessage: actions.sendMessage,
      signOut: actions.signOut,
      getClinicianBio: actions.getClinicianBio,
      clearDisplayClinician: actions.clearDisplayClinician,
      setVideoRoomId: actions.setVideoRoomId,
      getVideoIsRunning: actions.getVideoIsRunning,
      setPageDetails: actions.setPageDetails,
      toggleNotes: actions.toggleNotes,
      // getNotes: actions.getNotes,
      setVideoRoomId: actions.setVideoRoomId,
      setCurrentChannelUrl: actions.setCurrentChannelUrl,
    },
    dispatch
  );

function getInitialState() {
  return {
    username: "",
    password: "",
    open: false,
    videoExpiredOpen: false,
    url: "",
    stateLoaded: false,
  };
}

class ChatContainer extends Component {
  state = {
    ...getInitialState(),
  };

  componentDidMount() {
    this.props.setPageDetails({
      pageName: "Chat",
      currentPage: "chat",
      menu: "chat",
    });

    this.setState({ url: this.props.match.params.url, stateLoaded: true });

    // this.props.checkAuthState();
    // if (this.props.login) {
    //     if (this.props.isCaregiver) {
    //         this.props.getParent();
    //     } else {
    //         this.props.getUser();
    //     }
    // }
  }

  componentDidUpdate(prevProps) {
    // if (!prevProps.login && this.props.login) {
    //     if (this.props.isCaregiver) {
    //         this.props.getParent();
    //     } else {
    //         this.props.getUser();
    //     }
    // }

    if (!prevProps.isRunning && this.props.isRunning) {
      //  (!prevProps.isRunning && this.props.isRunning && this.props.videoKey) ADMIN CODE
      this.props.history.push(`/video/${this.props.videoKey}`);
    } else if (prevProps.isRunning === null && this.props.isRunning === false) {
      this.setState({ videoExpiredOpen: true });
    }
  }

  onMessageSend = (message, currentChannel, userId, online) => {
    this.props.sendMessage(userId, {
      message,
      conversationUrl: currentChannel.url,
      clinicianOnline: online,
      role: this.props.isCustomerCare
        ? "ROLE_CUSTOMER_CARE"
        : this.props.isCaregiver
          ? "ROLE_CAREGIVER"
          : "ROLE_CLINICIAN",
    });
  };

  canSendMessage = () => {
    return true;
  };

  onBackClick = () => {
    this.props.history.goBack();
  };

  viewClinicianBio = (clinicianUserId) => {
    if (typeof clinicianUserId === "string" || typeof clinicianUserId === "number") {
      this.props.getClinicianBio(clinicianUserId);
    } else {
      this.props.getClinicianBio(this.props.clinician.clinician_user_id);
    }
    this.openDialog();
  };

  openDialog = (e) => {
    this.setState({ open: true });
  };

  closeDialog = (e) => {
    this.setState({ open: false }, () => {
      this.props.clearDisplayClinician();
    });
  };

  signUserOut = () => {
    this.props.signOut(true);
  };

  onClientStartVideo = (messageData) => {
    this.props.setVideoRoomId(messageData);
    this.props.getVideoIsRunning(messageData.room_id);
  };

  closeVideoExpired = () => {
    this.setState({ videoExpiredOpen: false });
  };

  onNotesClick = (userId) => {
    this.props.toggleNotes();
    // this.props.getNotes(userId);
  };

  onMessagesRead = (conversationUrl) => {
    this.props.setCurrentChannelUrl(conversationUrl);
  };
  render() {
    const {
      user,
      loaded,
      classes,
      userLoaded,
      isCustomerCare,
      isSupervisor,
      isDirector,
      isCaregiver,
      parent,
    } = this.props;

    const appId = process.env.SENDBIRD_APP_ID;
    const supportChat = location && location.pathname === "/support" ? true : false;
    if (!userLoaded || !this.state.stateLoaded) {
      return (
        <React.Fragment>
          <CircularProgress />
        </React.Fragment>
      );
    } else {
      return (
        <div style={{ width: "100%", height: "100vh" }}>
          <AnswersNowChat
            appId={appId}
            userId={user.id}
            name={user.first_name}
            chatType={"private"}
            displayBack={true}
            onBackClick={this.onBackClick}
            canJoinPremiumCommunity={true}
            displayLeftDrawer={true}
            displayNotes={false}
            displayHeaderBack={false}
            onNotesClick={this.onNotesClick}
            onMessageSend={this.onMessageSend}
            onHeaderBackClick={this.onDisplayHeaderBack}
            canSendMessage={this.canSendMessage}
            channelUrl={
              isCaregiver
                ? supportChat
                  ? parent.support_conversation_url
                  : parent.conversation_url
                : this.state.url
            }
            onHeadingClick={this.viewClinicianBio}
            userViewing={true}
            onClientStartVideo={this.onClientStartVideo}
            primaryClinicianUserId={parent.clinician?.clinician_user_id || null}
            onRead={this.onMessagesRead}
            isSupport={isCustomerCare}
            isSupervisor={isSupervisor}
            isDirector={isDirector}
          />
          <Modal
            open={this.state.open}
            onClose={this.closeDialog}
            content={
              <div
                style={{
                  maxWidth: "100%",
                  width: 500,
                  height: 400,
                  maxHeight: "98%",
                }}
              >
                <Clinician {...this.state} />
              </div>
            }
            secondaryActionText="Close"
            secondaryActionOnClick={this.closeDialog}
          />
          <Modal
            open={this.state.videoExpiredOpen}
            onClose={this.closeVideoExpired}
            title="Video Link Has Expired"
            description="Please request a new link from your BCBA."
            secondaryActionText="Close"
            secondaryActionOnClick={this.closeVideoExpired}
          />
        </div>
      );
    }
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ChatContainer))
);
