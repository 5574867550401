import React from "react";
import { Box, Select, MenuItem, Typography, Alert, AlertTitle } from "@mui/material";
import { WarningCircle } from "@phosphor-icons/react";
import ClientActivation from "./ClientActivation";
import SectionBox from "../../../elements/SectionBox";
import { CLIENT_STATUS } from "constants";

const UpdateClientStatus = (props) => {
  const { workflowStatus, userPermissions, setSelectedStatus, selectedStatus, editMode, isActive } =
    props;

  // Function to get the display text for the current status
  const getStatusDisplayText = (statusKey) => CLIENT_STATUS[statusKey] || "";

  return (
    <SectionBox
      title="Client Status"
      description="Set the client account status to reflect the appropriate stage"
      columnDirection
    >
      <Box>
        <Typography margin={"8px 0"} variant="inputLabel">
          Account Status
        </Typography>
        {editMode ? (
          <>
            <Select
              disabled={!isActive}
              style={{ width: "100%" }}
              value={selectedStatus || workflowStatus}
              onChange={(e) => {
                setSelectedStatus(e);
              }}
            >
              <MenuItem disabled value="" />
              {Object.entries(CLIENT_STATUS).map(([value, text]) => (
                <MenuItem key={value} value={value}>
                  {text}
                </MenuItem>
              ))}
            </Select>
            {(userPermissions.disable_client || userPermissions.reactivate_client) && (
              <Alert
                severity={isActive ? "error" : "warning"}
                action={<ClientActivation {...props} userPermissions={userPermissions} />}
                style={{ margin: "20px 0" }}
                icon={<WarningCircle />}
              >
                <AlertTitle>{`${isActive ? "Deactivate" : "Activate"} Client Account`}</AlertTitle>
                {isActive
                  ? "Deactivation will suspend account access for the client"
                  : "Reactivating the client account will return the account status to the previous state before deactivation"}
              </Alert>
            )}
          </>
        ) : (
          <Typography variant="body2">
            {getStatusDisplayText(workflowStatus || selectedStatus)}
          </Typography>
        )}
      </Box>
    </SectionBox>
  );
};

export default UpdateClientStatus;
