import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, useLocation } from "react-router-dom";

import useGetTeamMemberInvitation from "hooks/api/invitation/useGetTeamMemberInvitation";
import useSaveAcceptInvitation from "hooks/api/invitation/useSaveAcceptInvitation";
import getCognitoAuth from "hooks/api/user/useGetCognitoSession";
import useSignInCognito from "hooks/api/auth/useSignInCognito";
import actions from "actions";
import ANLoadingScreen from "elements/ANLoadingScreen";
import UpdateMemberInfo from "./UpdateMemberInfo";
import ResetPassword from "../ResetPassword/ResetPassword";
import SplashScreen from "./SplashScreen";
import { ClientCookie } from "./utils";

const currentDate = new Date();
const cookieClientId = new ClientCookie();

export default function AcceptInvitation() {
  const [step, setStep] = useState("setPassword");
  const [password, setPassword] = useState("");
  const [userInfo, setUserInfo] = useState({});

  const dispatch = useDispatch();
  const { guid } = useParams();
  // Get the invitation id from the URL
  const searchParams = new URLSearchParams(useLocation().search);
  const invitationId = searchParams.get("invitationid") || null;

  // Dispatch actions
  const setPageDetails = (payload) => dispatch(actions.setPageDetails(payload));

  useEffect(() => {
    setPageDetails({
      pageName: "Accept Invitation",
    });

    if (!invitationId || !guid) {
      location.href = "/";
    }
  }, []);

  /* ----- Mutations ----- */
  let { signinCognitoMutation, isSigninSuccess } = useSignInCognito({
    // Redirect to updateInfo page after successful login
    onSuccess: (_, variables) => {
      setUserInfo(variables);
      setStep("updateInfo");
    },
  });

  let { acceptInvitationMutation, isMutationInvitationIdle, isMutationInvitationLoading } =
    useSaveAcceptInvitation({
      onSuccess: (userData) => {
        // after accepting the invitation, the existing user will be redirected to the home page
        const isMemberExists = invitation?.existingUserId;
        if (isMemberExists) {
          location.href = "/";
          return;
        }

        // after accepting the invitation, The new user will login to update his information
        signinCognitoMutation({
          username: userData.data.email,
          password,
          userId: userData.data.userId,
        });
      },
    });

  /* 1. ---- Fetch Invitation details ---- */
  let {
    invitation,
    invitationLoading,
    error: invitationErrorData,
    isSuccess: invitationSuccess,
  } = useGetTeamMemberInvitation({ guid, invitationId });
  let { session, sessionLoading, sessionSuccess } = getCognitoAuth(invitationSuccess);

  /* 2. ---- Display the splashScreen if the invitation has expired or already accepted ---- */
  const expirationDate = invitation?.userInvites?.expirationDate;
  const invitationHasExpired = expirationDate && currentDate > new Date(expirationDate);
  const invitationWasAccepted = invitation?.userInvites?.acceptedAt;

  if (invitationErrorData) {
    console.error(invitationErrorData);
    location.href = "/";
  }

  const showSplashScreen =
    (invitationHasExpired || invitationWasAccepted) && step === "setPassword";

  if (showSplashScreen) {
    return (
      <SplashScreen
        title="This link has expired"
        description={
          <>
            Questions or Issues? Contact us at <br />
            (800) 486-9060.
          </>
        }
        alertSeverity="error"
      />
    );
  }

  /* 3. ---- If the invited Member exists, go to login: ---- */
  const userExists = Boolean(invitation?.existingUserId);

  if (invitationSuccess && sessionSuccess && step === "setPassword") {
    if (!cookieClientId.get() && !session && userExists) {
      cookieClientId.set({
        clientId: invitation?.clientId,
        userId: invitation?.existingUserId,
      });
      cookieClientId.save();
    }

    if (!session && userExists) {
      location.href = `/?redirect=/accept-invitation/${guid}?invitationid=${invitationId}`;
    }

    // Fix avoid creating a new user if there is an logged in user
    if (session && !userExists) {
      location.href = "/";
    }

    /* 4. ---- If the existing Member is logged in, check credentials and accept invitation ---- */
    const isCredentialsMatch = session?.email === invitation?.userInvites?.email;

    if (userExists && session && isCredentialsMatch && isMutationInvitationIdle) {
      acceptInvitationMutation({
        id: invitationId,
        invitationGuid: guid,
      });
    }

    // Redirect to the home page if the credentials do not match
    if (userExists && session && !isCredentialsMatch) {
      console.error("The credentials do not match");
      location.href = "/";
    }
  }

  /* 5. If the invited Member is new, create a cognito account */
  const handleCreatePassword = (values) => {
    setPassword(values.password);

    acceptInvitationMutation({
      id: invitationId,
      invitationGuid: guid,
      password: values.password,
    });
  };

  // Display the loading screen while fetching or processing the data
  const loading =
    invitationLoading || sessionLoading || (isMutationInvitationLoading && userExists);

  if (loading) return <ANLoadingScreen />;

  return (
    <div>
      {step === "setPassword" ? (
        <ResetPassword
          change={true}
          invitationSignin={true}
          loginInvitation={{
            email: invitation?.userInvites?.email,
          }}
          onChange={() => {}}
          onSubmitInvitationLogin={handleCreatePassword}
          showVersion={false}
          username={invitation?.userInvites?.email}
          verificationCode="ignoreme"
        />
      ) : (
        isSigninSuccess && (
          <UpdateMemberInfo
            email={userInfo.username}
            userId={userInfo.userId}
            clientId={invitation?.clientId}
          />
        )
      )}
    </div>
  );
}
