import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Typography, TextField, Select, CircularProgress } from "@mui/material";
import {
  format,
  getSeconds,
  getMilliseconds,
  subSeconds,
  subMilliseconds,
  addMinutes,
  differenceInMinutes,
  differenceInDays,
  addDays,
} from "date-fns";
import withStyles from "@mui/styles/withStyles";
import styles from "./styles";
import {
  getCallBreakdownError,
  getCallBreakdownLoading,
  getCallBreakdownSuccess,
  getInsuranceCodesByUser,
  getUserId,
  getBillableTimeIds,
  getTreatmentPlanLoading,
  getTreatmentPlanSuccess,
  getCustomerDetails,
  getCustomerLoaded,
  getNextSessionNoteData,
  getCustomerCliniciansLoading,
  getEditAssociatedTimesLoading,
  getEditAssociatedTimesSuccess,
  getApproveTimeLoading,
  getApproveTimeSuccess,
  getRequestBillableTimeEditsLoading,
  getRequestBillableTimeEditsSuccess,
  getTimesheetLoading,
  getTimesheetSuccess,
  getTimesheetError,
  getCustomerList,
  getInsuranceCodesByUserLoading,
  getUser,
  getUserPermissionsList,
  getDeleteNoteLoading,
  getDeleteNoteSuccess,
  getDeleteNoteError,
  getDeleteNoteCreatedBillableId,
  getBillableItem,
  getBillableItemSuccess,
} from "../../selectors";
import actions from "../../actions";
import { withRouter } from "react-router";
import { findDifferenceInMinutes } from "../../utils/findDifferenceInMinutes";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import * as AWS from "aws-sdk";
import { mapServiceTypeToTemplateText } from "../../utils/mapServiceTypeToTemplateText";
import SessionNoteComponent from "./SessionNotesComponent";
import Auth from "@aws-amplify/auth";

import ConfirmSubmitDialog from "./Dialogs/ConfirmSubmitDialog";
import { getAWSCredentialsForCurrentUserSession } from "utils/aws";

const mapStateToProps = (state) => ({
  insuranceCodes: getInsuranceCodesByUser(state),
  insuranceCodesLoading: getInsuranceCodesByUserLoading(state),
  userId: getUserId(state),
  callBreakdownLoading: getCallBreakdownLoading(state),
  callBreakdownSuccess: getCallBreakdownSuccess(state),
  callBreakdownError: getCallBreakdownError(state),
  billableTimeIds: getBillableTimeIds(state),
  treatmentPlanLoading: getTreatmentPlanLoading(state),
  treatmentPlanSuccess: getTreatmentPlanSuccess(state),
  customerDetails: getCustomerDetails(state).details,
  customerClinician: getCustomerDetails(state).clinician,
  customerSecondaryClinician: getCustomerDetails(state).secondaryClinician,
  customerLoaded: getCustomerLoaded(state),
  nextSessionNote: getNextSessionNoteData(state),
  customerCliniciansLoading: getCustomerCliniciansLoading(state),
  setAssociatedTimesLoading: getEditAssociatedTimesLoading(state),
  setAssociatedTimesSuccess: getEditAssociatedTimesSuccess(state),
  approveTimeLoading: getApproveTimeLoading(state),
  approveTimeSuccess: getApproveTimeSuccess(state),
  requestEditsLoading: getRequestBillableTimeEditsLoading(state),
  requestEditsSuccess: getRequestBillableTimeEditsSuccess(state),
  timesheetLoading: getTimesheetLoading(state),
  timesheetSuccess: getTimesheetSuccess(state),
  timesheetError: getTimesheetError(state),
  clientList: getCustomerList(state),
  currentUser: getUser(state),
  userPermissions: getUserPermissionsList(state),
  deleteNoteLoading: getDeleteNoteLoading(state),
  deleteNoteSuccess: getDeleteNoteSuccess(state),
  deleteNoteError: getDeleteNoteError(state),
  deleteNoteCreatedBillableId: getDeleteNoteCreatedBillableId(state),
  billableItem: getBillableItem(state),
  billableItemSuccess: getBillableItemSuccess(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      sendBillableTime: actions.sendBillableTimeForVideo,
      getInsuranceCodesByUser: actions.getInsuranceCodesByUser,
      loadCustomerDetails: actions.loadCustomerDetails,
      clearSessionNote: actions.clearSessionNoteSuccess,
      logError: actions.logError,
      setAssociatedTimes: actions.setAssociatedBillableTime,
      approveBillableTime: actions.approveBillableTime,
      requestEdits: actions.requestBillableTimeEdits,
      sendTimesheetBillableTime: actions.sendTimesheetBillableTime,
      deleteBillableTime: actions.deleteBillableTime,
      getBillableItem: actions.getBillableItemById,
    },
    dispatch
  );

function getInitialState() {
  return {
    startDate: null,
    endDate: null,
    multipleServiceTypes: null,
    serviceTypeCount: "",
    serviceTypes: [],
    serviceType: { insurance_code: "" },
    duration: 0,
    totalCallTime: 0,
    treatmentPlanServiceTypes: [],
    treatmentPlanServiceUpdated: false,
    participants: [],
    otherParticipant: "",
    clientName: "",
    clinicianName: "",
    parentName: "",
    focus: "",
    note: "",
    responseNote: "",
    planModified: false,
    planModification: "",
    location: "",
    signatureStarted: false,
    isVerified: false,
    showLocationDropdown: false,
    cancelEditSessionNoteOpen: false,
    participantsError: false,
    otherParticipantError: false,
    serviceTypeError: false,
    responseError: false,
    interventionError: false,
    focusError: false,
    noteTipOpen: false,
    responseTipOpen: false,
    signatureError: false,
    verifyError: false,
    successToastOpen: false,
    finalToastOpen: false,
    notesCompleted: false,
    savedAsDraft: false,
    noteCount: 1,
    confirmExitDialogOpen: false,
    confirmExitAdhocDialogOpen: false,
    templateText: {},
    techIssues: false,
    techIssueReasons: [],
    otherTechIssue: "",
    techIssueReasonError: false,
    otherClinicianName: "",
    sessionNoteId: null,
    signatureUrl: null,
    clinicianUserId: null,
    associatedSessionNotes: [],
    inProgress: false,
    noteCompleted: false,
    approved: false,
    needsEdits: false,
    requestedEdits: "",
    previousDuration: 0,
    editAssociatedTimesOpen: false,
    editAssociatedTimesOpenError: false,
    billableTimeId: null,
    setAssociatedTimesError: false,
    timesheetNotes: [],
    requestChangesDialogOpen: false,
    selectedClientId: "",
    selectedClientError: false,
    selectedClientMissingCodeError: false,
    serviceTime: "",
    endTime: "",
    startDateError: false,
    serviceTimeError: false,
    serviceEndTimeError: false,
    locked: false,
    videoId: null,
    confirmSubmitDialogOpen: false,
    submitClicked: false,
    currentIndex: 0,
    confirmDeleteDialogOpen: false,
    deleteNoteIndex: null,
    associatedTimesDraftSaving: false,
    associatedTimesSubmitSaving: false,
    timezone: null,
  };
}

class SessionNotes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...getInitialState(),
    };
    this.signatureCanvasRef = null;
    this.signatureRef = null;
    this.callDetailsRef = null;
    this.adhocCallDetailsRef = null;
    this.serviceDetailsRef = null;
    this.noteRef = null;
    this.responseNoteRef = null;
    this.techIssuesRef = null;
  }

  componentDidMount() {
    const { startDate, endDate, billableTimeId, downloadViewOnly } = this.props;
    if (startDate && endDate) {
      let seconds = getSeconds(new Date(startDate));
      let milliseconds = getMilliseconds(new Date(startDate));
      let startTime = subSeconds(new Date(startDate), seconds);
      startTime = subMilliseconds(new Date(startTime), milliseconds);
      let totalTime = findDifferenceInMinutes(endDate, startTime);
      totalTime = totalTime == 0 ? 1 : totalTime;
      this.setState({
        totalCallTime: totalTime,
        duration: totalTime,
        startDate,
        endDate,
      });
    }
    if (this.props.billableTimeId) {
      this.props.getBillableItem({
        billableTimeId,
        viewAllByVideoCall: downloadViewOnly,
      });
    }
    if (!this.props.inVideoCall && this.props.clientId) {
      this.props.getInsuranceCodesByUser({
        clientId: this.props.clientId,
        video: !!this.props.videoCallId,
        videoCallId: this.props.videoCallId,
      });
    }
    if (this.props.isDemo) {
      this.props.getInsuranceCodesByUser({
        isDemo: true,
        video: true,
        videoCallId: this.props.videoCallId,
      });
    }
    if (this.props.clientId && startDate && endDate) {
      this.props.loadCustomerDetails(this.props.clientId);
    }
    if (this.props.currentClinician) {
      this.setState({ clinicianName: this.props.currentClinician });
    }
    if (this.props.clientData && this.props.clientData.child_name) {
      this.setState({
        clientName:
          this.props.clientData.child_name?.trim() +
          " " +
          this.props.clientData.child_last_name?.trim(),
      });
    }
    if (
      this.props.createNew &&
      this.props.serviceTypeSelection &&
      !this.props.timesheetNote &&
      this.props.serviceTypeOptions
    ) {
      let codes = this.props.serviceTypeOptions?.session;
      let billingCode = codes.find((code) => code.description == this.props.serviceTypeSelection);
      const templateText = mapServiceTypeToTemplateText(billingCode.description);
      const clinicianName = `${this.props.currentUser?.first_name} ${this.props.currentUser?.last_name}`;
      this.setState({
        templateText,
        serviceType: billingCode,
        focus: templateText?.focus || "",
        clinicianName,
      });
    }
    if (this.props.timesheetNote && this.props.createNew) {
      this.setTimesheetServiceType(this.props.serviceTypeSelection, 0);
    }
    if (this.props.videoCallId) {
      this.setState({ videoId: this.props.videoCallId });
    }
  }

  componentDidUpdate(prevProps) {
    const { startDate, endDate, billableTimeId, downloadViewOnly } = this.props;
    if (!prevProps.endDate && startDate && endDate) {
      let seconds = getSeconds(new Date(startDate));
      let milliseconds = getMilliseconds(new Date(startDate));
      let startTime = subSeconds(new Date(startDate), seconds);
      startTime = subMilliseconds(new Date(startTime), milliseconds);
      let totalTime = findDifferenceInMinutes(endDate, startTime);
      totalTime = totalTime == 0 ? 1 : totalTime;
      this.setState({
        totalCallTime: totalTime,
        duration: totalTime,
        startDate,
        endDate,
      });
    }
    if (
      (!prevProps.billableTimeId && this.props.billableTimeId) ||
      (prevProps.billableTimeId !== this.props.billableTimeId && this.props.billableTimeId)
    ) {
      this.props.getBillableItem({
        billableTimeId,
        viewAllByVideoCall: downloadViewOnly,
      });
    }

    if (
      ((!prevProps.callBreakdownSuccess && this.props.callBreakdownSuccess) ||
        (!prevProps.setAssociatedTimesSuccess && this.props.setAssociatedTimesSuccess)) &&
      (this.state.confirmExitDialogOpen || !this.state.videoId)
    ) {
      this.setState({ finalToastOpen: true }, () => {
        setTimeout(() => {
          this.onClose();
          this.setState({ finalToastOpen: false, noteCompleted: true });
        }, 3000);
      });
      // }
    }
    if (
      prevProps.deleteNoteLoading &&
      !this.props.deleteNoteLoading &&
      this.props.deleteNoteSuccess
    ) {
      if (!this.state.videoId) {
        this.setState({ finalToastOpen: true }, () => {
          setTimeout(() => {
            this.onClose();
            this.setState({ finalToastOpen: false, noteCompleted: true });
          }, 3000);
        });
      } else if (this.state.deleteNoteIndex == this.state.currentIndex) {
        this.setSessionNoteData();
      } else {
        this.mapBillableItemsToAssociatedNotes();
      }
      this.setState({ confirmDeleteDialogOpen: false });
    }
    if (!prevProps.callBreakdownError && this.props.callBreakdownError) {
      if (this.props.callBreakdownError === "Missing rate or code" && this.props.clientId) {
        this.props.getInsuranceCodesByUser({
          clientId: this.props.clientId,
          video: !!this.props.videoCallId,
          videoCallId: this.props.videoCallId,
        });
      }
    }
    if (
      !this.props.inVideoCall &&
      !prevProps.enterInsuranceCode &&
      this.props.enterInsuranceCode &&
      this.props.clientId
    ) {
      this.props.getInsuranceCodesByUser({
        clientId: this.props.clientId,
        video: !!this.props.videoCallId,
        videoCallId: this.props.videoCallId,
      });
    }
    if (
      (!prevProps.clientId || !prevProps.endDate) &&
      this.props.clientId &&
      this.props.startDate &&
      this.props.endDate
    ) {
      this.props.loadCustomerDetails(this.props.clientId);
    }
    if (
      (!prevProps.customerLoaded ||
        !prevProps.customerDetails?.name ||
        prevProps.customerCliniciansLoading ||
        !prevProps.billableItemSuccess) &&
      !this.props.timesheetNote &&
      this.props.customerLoaded &&
      this.props.customerDetails?.name &&
      !this.props.customerCliniciansLoading &&
      this.props.billableItemSuccess
    ) {
      this.setSessionNoteData();
    }
    if (
      !prevProps.billableItemSuccess &&
      this.props.billableItemSuccess &&
      this.props.timesheetNote &&
      !this.props.createNew
    ) {
      this.setTimesheetData();
    }

    if (prevProps.setAssociatedTimesLoading && !this.props.setAssociatedTimesLoading) {
      if (!this.props.setAssociatedTimesSuccess) {
        this.setState({
          setAssociatedTimesError: true,
          associatedTimesDraftSaving: false,
          associatedTimesSubmitSaving: false,
          submitClicked: false,
        });
      } else if (this.state.associatedTimesDraftSaving || this.state.associatedTimesSubmitSaving) {
        const timeCoded = this.props.billableItem?.associated_billable_times?.reduce((a, c) => {
          return c.actual_time ? a + parseInt(c.actual_time) : a;
        }, 0);
        const allNotesSubmitted = this.props.billableItem?.associated_billable_times?.every(
          (n) => n.locked
        );
        const associatedNotesCount = this.props.billableItem?.associated_billable_times?.length;
        if (
          (timeCoded === this.state.totalCallTime && allNotesSubmitted) ||
          associatedNotesCount === 1
        ) {
          this.setState({ finalToastOpen: true }, () => {
            setTimeout(() => {
              this.setState({ finalToastOpen: false, notesCompleted: true });
              this.onClose();
            }, 3000);
          });
        } else {
          this.setSessionNoteData();
          this.setState(
            {
              successToastOpen: true,
              associatedTimesDraftSaving: false,
              associatedTimesSubmitSaving: false,
              submitClicked: false,
            },
            () => {
              setTimeout(() => {
                this.setState({ successToastOpen: false });
              }, 3000);
            }
          );
        }
      } else {
        this.mapBillableItemsToAssociatedNotes();
        this.setState({
          editAssociatedTimesOpenError: false,
        });
      }
    }
    if (
      (prevProps.timesheetLoading ||
        prevProps.requestEditsLoading ||
        prevProps.approveTimeLoading) &&
      !this.props.timesheetLoading &&
      !this.props.requestEditsLoading &&
      !this.props.approveTimeLoading
    ) {
      if (
        this.props.timesheetSuccess ||
        this.props.requestEditsSuccess ||
        this.props.approveTimeSuccess
      ) {
        this.setState({ finalToastOpen: true }, () => {
          setTimeout(() => {
            this.setState({ finalToastOpen: false, notesCompleted: true });
            this.onClose();
          }, 3000);
        });
      }
    }
    if (
      prevProps.insuranceCodesLoading &&
      !this.props.insuranceCodesLoading &&
      this.state.serviceType?.description
    ) {
      let userCode = this.props.insuranceCodes.find(
        (code) => code.description == this.state.serviceType.description
      );
      if (!userCode) {
        this.setState({ selectedClientMissingCodeError: true });
      } else {
        this.setState({
          serviceType: { ...this.state.serviceType, ...userCode },
        });
      }
    }
    if (!prevProps.videoCallId && this.props.videoCallId) {
      this.setState({ videoId: this.props.videoCallId });
    }
    if (
      !this.props.inVideoCall &&
      prevProps.clientId !== this.props.clientId &&
      !!this.props.clientId
    ) {
      this.props.getInsuranceCodesByUser({
        clientId: this.props.clientId,
        video: !!this.props.videoCallId,
        videoCallId: this.props.videoCallId,
      });
    }
  }

  setTimesheetData = () => {
    const { billableItem, serviceTypeOptions } = this.props;
    const sessionNoteBreakdown = billableItem || {};

    let codes = serviceTypeOptions?.timesheet;
    let billingCode = codes?.find((code) => code.id == sessionNoteBreakdown.insurance_code_id);
    let created = new Date(sessionNoteBreakdown?.start_date);
    let createdHours = ("0" + created.getHours()).slice(-2);
    let createdMinutes = ("0" + created.getMinutes()).slice(-2);
    let createdTime = `${createdHours}:${createdMinutes}`;

    let endedDate = sessionNoteBreakdown?.end_date
      ? new Date(sessionNoteBreakdown?.end_date)
      : new Date();
    let endHours = ("0" + endedDate.getHours()).slice(-2);
    let endMinutes = ("0" + endedDate.getMinutes()).slice(-2);
    let endTime = `${endHours}:${endMinutes}`;

    this.setState({
      note: sessionNoteBreakdown?.note,
      startDate: sessionNoteBreakdown?.start_date,
      endDate: sessionNoteBreakdown?.end_date,
      duration: sessionNoteBreakdown?.actual_time,
      endTime,
      serviceTime: createdTime,
      serviceType: billingCode,
      clinicianUserId: sessionNoteBreakdown?.clinician_user_id || null,
      billableTimeId: sessionNoteBreakdown?.billable_time_id || null,
      needsEdits: sessionNoteBreakdown?.needs_edits || false,
      requestedEdits: sessionNoteBreakdown?.requested_edits || "",
      noteCompleted: sessionNoteBreakdown?.note_completed || false,
      inProgress: sessionNoteBreakdown?.in_progress || false,
      approved: sessionNoteBreakdown?.approved || false,
      locked: sessionNoteBreakdown?.locked || false,
    });
  };

  mapBillableItemsToAssociatedNotes = () => {
    const { billableItem } = this.props;
    const { associatedSessionNotes, currentIndex } = this.state;
    let idTracking = [];
    let associatedNotes = associatedSessionNotes.map((note, i) => {
      let current = i === currentIndex;
      if (note.billable_time_id) return note;
      let updatedNote = billableItem?.associated_billable_times?.find((newNote) => {
        return (
          new Date(newNote.start_date + "Z").getTime() === new Date(note.start_date).getTime() &&
          new Date(newNote.end_date + "Z").getTime() === new Date(note.end_date).getTime() &&
          !idTracking.includes(newNote.billable_time_id)
        );
      });
      if (updatedNote) idTracking.push(updatedNote.billable_time_id);
      if (current) {
        this.setState({ billableTimeId: updatedNote?.billable_time_id });
      }

      return {
        ...note,
        ...updatedNote,
        participants: note.participants || [],
        otherParticipant: note.otherParticipant || "",
        create_new: false,
      };
    });
    if (this.state.deleteNoteIndex) {
      if (this.state.deleteNoteIndex == currentIndex) {
        this.mapServiceTypeToTemplateText(note.description);
      }
      associatedNotes.splice(this.state.deleteNoteIndex, 1);
    }
    this.setState({
      associatedSessionNotes: associatedNotes,
      deleteNoteIndex: null,
    });
  };

  setSessionNoteData = () => {
    const { billableItem, currentClinician } = this.props;
    const sessionNoteBreakdown = billableItem || {};
    let clientName = `${this.props.customerDetails.name?.trim()} ${this.props.customerDetails.child_last_name?.trim()}`;
    let parentName;
    if (this.props.customerDetails.first_name && this.props.customerDetails.last_name) {
      parentName = `${this.props.customerDetails.first_name?.trim()} ${this.props.customerDetails.last_name?.trim()}`;
    } else {
      parentName = "Caregiver";
    }
    let clinicianName = sessionNoteBreakdown?.clinician_name
      ? sessionNoteBreakdown.clinician_name?.replace("  ", " ")
      : this.props.currentClinician;

    let otherClinicianName = this.props.isSecondary
      ? `${this.props.customerClinician?.first_name?.trim()} ${this.props.customerClinician?.last_name?.trim()}`
      : this.props.customerSecondaryClinician?.first_name
        ? `${this.props.customerSecondaryClinician?.first_name.trim()} ${this.props.customerSecondaryClinician?.last_name?.trim()}`
        : "";

    let created = sessionNoteBreakdown?.start_date
      ? new Date(sessionNoteBreakdown?.start_date)
      : new Date();
    let createdHours = ("0" + created.getHours()).slice(-2);
    let createdMinutes = ("0" + created.getMinutes()).slice(-2);
    let createdTime = `${createdHours}:${createdMinutes}`;

    let endedDate = sessionNoteBreakdown?.end_date
      ? new Date(sessionNoteBreakdown?.end_date)
      : new Date();
    let endHours = ("0" + endedDate.getHours()).slice(-2);
    let endMinutes = ("0" + endedDate.getMinutes()).slice(-2);
    let endTime = `${endHours}:${endMinutes}`;

    const currentIndex = sessionNoteBreakdown?.associated_billable_times?.findIndex(
      (n) => n.billable_time_id == sessionNoteBreakdown?.billable_time_id
    );
    const participants = sessionNoteBreakdown?.associated_billable_times
      ? this.mapParticipants(sessionNoteBreakdown?.associated_billable_times[currentIndex])
      : {};
    const templateText = sessionNoteBreakdown?.description
      ? mapServiceTypeToTemplateText(sessionNoteBreakdown.description)
      : {};

    this.setState(
      {
        parentName,
        clinicianName,
        clientName,
        otherClinicianName,
        participants: participants.participants || [],
        otherParticipant: participants.otherParticipant || "",
        note: sessionNoteBreakdown?.note || "",
        responseNote: sessionNoteBreakdown?.response || "",
        signatureUrl: sessionNoteBreakdown?.signature_url,
        isVerified: !!sessionNoteBreakdown?.note_completed,
        clinicianUserId: sessionNoteBreakdown?.clinician_user_id,
        duration:
          typeof sessionNoteBreakdown?.actual_time === "number" &&
          sessionNoteBreakdown?.actual_time >= 0
            ? sessionNoteBreakdown?.actual_time
            : this.state.duration,
        associatedSessionNotes:
          sessionNoteBreakdown?.associated_billable_times?.map((note) => {
            const participantMap = this.mapParticipants(note);
            const { participants = [], otherParticipant = "" } = participantMap;
            return {
              ...note,
              actual_time: note.actual_time || 0,
              participants,
              otherParticipant,
            };
          }) || [],
        noteCount: sessionNoteBreakdown?.associated_billable_times?.length || 1,
        noteCompleted: sessionNoteBreakdown ? sessionNoteBreakdown?.note_completed : true,
        inProgress: (sessionNoteBreakdown?.in_progress && !this.props.inVideoCall) || false,
        approved: sessionNoteBreakdown?.approved || false,
        needsEdits: sessionNoteBreakdown?.needs_edits || false,
        requestedEdits: sessionNoteBreakdown?.requested_edits || "",
        previousDuration: sessionNoteBreakdown?.actual_time || 0,
        billableTimeId: sessionNoteBreakdown?.billable_time_id || null,
        selectedClientId: sessionNoteBreakdown?.client_id || null,
        startDate: sessionNoteBreakdown?.start_date,
        endDate: sessionNoteBreakdown?.end_date,
        serviceTime: createdTime || "",
        endTime,
        locked: sessionNoteBreakdown?.locked || false,
        currentIndex,
        templateText,
        deleteNoteIndex: null,
        timezone: sessionNoteBreakdown?.call_timezone,
      },
      () => {
        if (sessionNoteBreakdown?.description) {
          this.setServiceType(sessionNoteBreakdown.description);
          if (sessionNoteBreakdown.description.includes("Tech")) {
            let techIssueList = sessionNoteBreakdown.note?.split(", ") || [];
            let otherTechIssue = techIssueList.find((i) => i.includes("Other:")) || "";
            this.setState({
              techIssueReasons: techIssueList,
              otherTechIssue: otherTechIssue.replace("Other: ", ""),
            });
          }
        }
      }
    );
  };

  mapParticipants = (sessionNote) => {
    const { videoBreakdown, currentClinician, billableItem } = this.props;
    const sessionNoteBreakdown = billableItem || {};
    let clinicianName = sessionNoteBreakdown?.clinician_name
      ? sessionNoteBreakdown.clinician_name
      : currentClinician;

    let participants = [];
    let participantProps = [];

    const participantsWithoutPrimary =
      sessionNote?.participants
        ?.replace(`${clinicianName}, `, "")
        .replace(`${clinicianName}`, "") || "";
    const onlyPrimaryClinicianParticipant = participantsWithoutPrimary.length === 0;

    if (!onlyPrimaryClinicianParticipant) {
      participantProps = participantsWithoutPrimary.split(", ");
    }
    let otherParticipantIndex = participantProps.findIndex((p) => p.includes("[other]"));
    let includesOther = otherParticipantIndex >= 0;
    let otherParticipant = "";
    if (includesOther) {
      otherParticipant = participantProps[otherParticipantIndex]
        .replace("[other]", "")
        .replaceAll("[,]", ",");
      participantProps.splice(otherParticipantIndex, 1);
    }
    participants.push(clinicianName);
    participants = [...participants, ...participantProps];
    if (otherParticipant) {
      participants.push("Other");
    }
    return { participants, otherParticipant };
  };

  onClose = () => {
    this.props.onCloseCPTCodeDialog();
    this.setState({ ...getInitialState() });
  };

  onChange = (name) => (e) => {
    const { value } = e.target;
    this.setState({ [name]: value });
    if (this.state.videoId) {
      this.mapOnChangeToAssociatedNote(name, value);
    }
  };

  mapOnChangeToAssociatedNote = (name, value) => {
    const { associatedSessionNotes, currentIndex } = this.state;
    let associatedNotes = associatedSessionNotes;
    let currentNote = associatedNotes[currentIndex];
    if (name == "note") {
      currentNote.note = value;
    } else if (name == "responseNote") {
      currentNote.response = value;
    } else if (name == "participants") {
      currentNote.participants = value;
    } else if (name == "otherParticipant") {
      currentNote.otherParticipant = value;
    }
    this.setState({ associatedSessionNotes: associatedNotes });
  };

  onChangeServiceType = (e, index) => {
    const { value } = e.target;
    this.setServiceType(value);
  };

  onChangeSelectedClient = (e) => {
    const { value } = e.target;
    this.setState({
      selectedClientId: value,
      selectedClientError: false,
      selectedClientMissingCodeError: false,
    });
    this.props.getInsuranceCodesByUser({
      clientId: value,
      videoCallId: this.props.videoCallId,
    });
  };

  setServiceType = (value) => {
    const { insuranceCodes, serviceTypeOptions, createNew, timesheetNote } = this.props;
    let codes = insuranceCodes;
    let billingCode = codes.find((code) => code.description == value);
    const serviceTypes = timesheetNote
      ? serviceTypeOptions?.timesheet
      : serviceTypeOptions?.session;
    if (createNew) {
      billingCode = serviceTypes.find((code) => code.description == value);
    }
    let templateText = mapServiceTypeToTemplateText(value) || {};
    const techIssuesCode = insuranceCodes.find((ic) => ic.description.includes("Tech"));
    let techIssues = billingCode?.id == techIssuesCode?.id;
    let note = this.state.note;
    if (this.state.serviceType?.description?.includes("Tech")) {
      note = "";
    }
    this.setState({
      serviceType: billingCode,
      templateText,
      focus: templateText?.focus || "",
      techIssues,
      techIssueReasonError: false,
      editAssociatedTimesOpenError: false,
      selectedClientMissingCodeError: false,
      note,
    });
  };

  setTimesheetServiceType = (value) => {
    const { serviceTypeOptions } = this.props;
    let codes = serviceTypeOptions?.timesheet;
    let billingCode = codes.find((code) => code.description == value);
    this.setState({ serviceType: billingCode, startDate: new Date() });
  };

  setDate = (date) => {
    let pastDue = differenceInDays(new Date(), new Date(date)) > 7;
    this.setState({ startDate: date, startDateError: pastDue });
  };

  setTime = (time) => {
    const { startDate, endDate, endTime } = this.state;
    let hours;
    let minutes;

    if (time instanceof Date) {
      hours = time.getHours().toString().padStart(2, "0");
      minutes = time.getMinutes().toString().padStart(2, "0");
    } else {
      let dateSplit = time.split(":");
      hours = dateSplit[0];
      minutes = dateSplit[1];
    }
    let serviceDate = startDate ? new Date(startDate) : new Date();
    serviceDate.setHours(hours);
    serviceDate.setMinutes(minutes);
    serviceDate.setSeconds(0);
    serviceDate.setMilliseconds(0);
    let serviceEndDate = startDate ? new Date(startDate) : new Date();
    if (endTime) {
      let endDateSplit = endTime.split(":");
      serviceEndDate.setHours(endDateSplit[0]);
      serviceEndDate.setMinutes(endDateSplit[1]);
    }
    serviceEndDate.setSeconds(0);
    serviceEndDate.setMilliseconds(0);
    if (serviceEndDate.getTime() < serviceDate.getTime()) {
      serviceEndDate = addDays(serviceEndDate, 1);
    }
    let duration = 0;
    if (endDate) {
      duration = (serviceEndDate - serviceDate) / 1000 / 60;
    }
    this.setState({
      startDate: serviceDate,
      serviceTime: time,
      endDate: serviceEndDate,
      duration,
    });
  };

  setEndTime = (time) => {
    const { endDate, startDate } = this.state;
    let hours;
    let minutes;
    if (time instanceof Date) {
      hours = time.getHours().toString().padStart(2, "0");
      minutes = time.getMinutes().toString().padStart(2, "0");
    } else {
      let dateSplit = time.split(":");
      hours = dateSplit[0];
      minutes = dateSplit[1];
    }
    let serviceEndDate = startDate ? new Date(startDate) : new Date();
    serviceEndDate.setHours(hours);
    serviceEndDate.setMinutes(minutes);
    serviceEndDate.setSeconds(0);
    serviceEndDate.setMilliseconds(0);
    let serviceStartDate = startDate ? new Date(startDate) : new Date();
    serviceStartDate.setSeconds(0);
    serviceStartDate.setMilliseconds(0);
    if (serviceEndDate.getTime() < serviceStartDate.getTime()) {
      serviceEndDate = addDays(serviceEndDate, 1);
    }
    let duration = 0;
    if (startDate) {
      duration = (serviceEndDate - serviceStartDate) / 1000 / 60;
    }
    this.setState({
      endDate: serviceEndDate,
      endTime: time,
      duration,
    });
  };

  onChangeAssociatedDuration = (e, itemIndex) => {
    const { value } = e.target;
    const { associatedSessionNotes = [] } = this.state;
    let associatedTimes = [...associatedSessionNotes];
    associatedTimes[itemIndex].actual_time = parseInt(value);
    this.setState(
      {
        associatedSessionNotes: associatedTimes,
      },
      () => {
        if (!this.isTimeInaccurate()) {
          this.setState({ durationError: false });
        }
      }
    );
  };

  onChangeAssociatedServiceType = (e, itemIndex) => {
    const { value } = e.target;
    const { insuranceCodes } = this.props;
    const { associatedSessionNotes = [], templateText, currentIndex, serviceType } = this.state;
    let associatedTimes = [...associatedSessionNotes];
    let updatedTemplateText = templateText;
    let billingCode = insuranceCodes.find((code) => code.description == value);
    // let itemIndex = associatedSessionNotes.findIndex((note) => note.billable_time_id === id);
    const isCurrentIndex = itemIndex == currentIndex;
    if (isCurrentIndex) {
      updatedTemplateText = mapServiceTypeToTemplateText(billingCode.description);
    }
    associatedTimes[itemIndex].insurance_code_id = billingCode.id;
    associatedTimes[itemIndex].description = billingCode.description;
    associatedTimes[itemIndex].code = billingCode.code;
    associatedTimes[itemIndex].needs_approval = billingCode.needs_approval;
    associatedTimes[itemIndex].rate = billingCode.rate;
    associatedTimes[itemIndex].focus = updatedTemplateText?.focus || "";
    associatedTimes[itemIndex].is_medicaid = billingCode.is_medicaid;
    associatedTimes[itemIndex].insurance_id = billingCode.insurance_id;
    associatedTimes[itemIndex].insurance_plan_id = billingCode.insurance_plan_id;
    associatedTimes[itemIndex].authorization_id = billingCode.authorization_id;
    associatedTimes[itemIndex].state = billingCode.state;
    associatedTimes[itemIndex].secondary_is_medicaid = billingCode.secondary_is_medicaid;
    associatedTimes[itemIndex].secondary_insurance_id = billingCode.secondary_insurance_id;
    associatedTimes[itemIndex].secondary_insurance_plan_id =
      billingCode.secondary_insurance_plan_id;
    associatedTimes[itemIndex].secondary_authorization_id = billingCode.secondary_authorization_id;
    const techIssuesCode = insuranceCodes.find((ic) => ic.description.includes("Tech"));
    let techIssues = isCurrentIndex ? billingCode?.id == techIssuesCode?.id : this.state.techIssues;
    this.setState({
      serviceType: isCurrentIndex ? associatedTimes[itemIndex] : serviceType,
      associatedSessionNotes: associatedTimes,
      editAssociatedTimesOpenError: false,
      templateText: updatedTemplateText,
      techIssues,
    });
  };

  onAddAssociatedServiceType = () => {
    const { clientId, userId } = this.props;
    const { associatedSessionNotes = [], videoId, totalCallTime } = this.state;
    const timeCoded = associatedSessionNotes.reduce((a, c) => {
      return c.actual_time ? a + parseInt(c.actual_time) : a;
    }, 0);
    let associatedTimes = [...associatedSessionNotes];
    let participants = this.mapParticipants({ participants: "" });
    let newService = {
      video_call_id: videoId,
      client_id: clientId,
      clinician_user_id: userId,
      create_new: true,
      actual_time: totalCallTime - timeCoded,
      billable_time_id: null,
      insurance_code: "",
      participants: participants.participants || [],
      otherParticipant: "",
      note_completed: false,
      completed_date: null,
      in_progress: true,
      approved: false,
      on_hold: false,
    };

    associatedTimes.push(newService);
    this.setState({
      associatedSessionNotes: associatedTimes,
      editAssociatedTimesOpenError: false,
    });
  };

  onRemoveAssociatedServiceType = (index) => {
    const { associatedSessionNotes = [] } = this.state;
    const { isDemo } = this.props;
    let associatedTimes = [...associatedSessionNotes];
    associatedTimes.splice(index, 1);
    this.setState(
      {
        associatedSessionNotes: associatedTimes,
        editAssociatedTimesOpenError: false,
        deleteNoteIndex: null,
      },
      () => {
        if (isDemo && !associatedTimes.length) {
          this.onAddAssociatedServiceType();
        }
      }
    );
  };

  onSaveAssociatedTimes = async (noteCompleted) => {
    const {
      associatedSessionNotes,
      billableTimeId,
      videoId,
      currentIndex,
      otherParticipant,
      techIssueReasons,
      otherTechIssue,
      signatureStarted,
    } = this.state;

    const { insuranceCodes } = this.props;
    this.setState({
      setAssociatedTimesError: false,
    });
    const techIssuesCode = insuranceCodes.find((ic) => ic.description.includes("Tech"));
    let associatedTimes = [...associatedSessionNotes];
    let startDate = new Date(this.props.startDate);
    let endDate;
    const signatureUrl =
      signatureStarted && noteCompleted ? await this.getSignatureImageURL() : null;
    let updatedAssociatedTimes = associatedTimes.map((time, index) => {
      endDate = addMinutes(new Date(startDate), parseInt(time.actual_time));
      let billingCode = insuranceCodes.find((code) => code.description == time.description);
      let techIssues = time?.insurance_code_id == techIssuesCode.id;

      let techIssueList = [...techIssueReasons];
      const otherTechIssueIndex = techIssueList.findIndex((r) => r.includes("Other: "));
      if (otherTechIssueIndex > -1) {
        techIssueList.splice(otherTechIssueIndex, 1);
      }
      if (otherTechIssue.trim() != "") {
        techIssueList.push(`Other: ${otherTechIssue}`);
      }

      let result = {
        ...time,
        insurance_code_id: billingCode?.id || null,
        start_date: startDate,
        end_date: endDate,
      };
      if (index === currentIndex && !time.locked) {
        if (noteCompleted) {
          result = {
            ...result,
            note: techIssues ? techIssueList.join(", ") : time.note,
            note_completed: true,
            completed_date: new Date(),
            in_progress: false,
            approved: videoId ? true : !time?.needs_approval,
            on_hold: false,
            signature_url: signatureUrl,
          };
        } else {
          result = {
            ...result,
            note: techIssues ? techIssueList.join(", ") : time.note,
            note_completed: false,
            completed_date: null,
            in_progress: true,
            approved: false,
            on_hold: false,
          };
        }
      }
      startDate = new Date(endDate);
      return result;
    });
    this.setState({ associatedSessionNotes: updatedAssociatedTimes });

    const billableTimes = updatedAssociatedTimes.map((note, i) => {
      const participantString =
        i === currentIndex
          ? note.participants
              ?.join(", ")
              .replace("Other", `[other]${otherParticipant.replaceAll(",", "[,]")}`) || ""
          : note.participants.join(", ");
      return { ...note, participants: participantString };
    });
    this.props.setAssociatedTimes({ billableTimes, billableTimeId });
  };

  onChangeNoteIndex = (index) => {
    const { associatedSessionNotes } = this.state;
    const { insuranceCodes, viewOnly, isDemo, downloadViewOnly } = this.props;
    const timeCompleted = !this.isTimeInaccurate() || downloadViewOnly;
    if (!timeCompleted) {
      this.setState(
        {
          durationError: true,
        },
        () => {
          this.scrollToFirstError();
        }
      );
      return;
    }
    let newIndex = index;
    if (index < 0) {
      newIndex = associatedSessionNotes.length - 1;
    } else if (index >= associatedSessionNotes.length) {
      newIndex = 0;
    }
    if (downloadViewOnly) {
      this.props.getBillableItem({
        billableTimeId: associatedSessionNotes[newIndex].billable_time_id,
        viewAllByVideoCall: downloadViewOnly,
      });
      return;
    }
    const nextSessionNote = associatedSessionNotes[newIndex];
    const templateText = nextSessionNote?.description
      ? mapServiceTypeToTemplateText(nextSessionNote.description)
      : {};
    const techIssuesCode = insuranceCodes.find((ic) => ic.description.includes("Tech"));
    let techIssues = nextSessionNote?.insurance_code_id == techIssuesCode.id;
    this.signatureRef?.clear();
    this.setState({
      serviceType: nextSessionNote,
      note: nextSessionNote?.note || "",
      responseNote: nextSessionNote?.response || "",
      signatureUrl: nextSessionNote?.signature_url,
      isVerified: !!nextSessionNote?.note_completed,
      clinicianUserId: nextSessionNote?.clinician_user_id,
      duration: nextSessionNote?.actual_time || 0,
      noteCompleted: nextSessionNote?.note_completed,
      inProgress: (nextSessionNote?.in_progress && !this.props.inVideoCall) || false,
      approved: nextSessionNote?.approved || false,
      needsEdits: nextSessionNote?.needs_edits || false,
      requestedEdits: nextSessionNote?.requested_edits || "",
      billableTimeId: nextSessionNote?.billable_time_id || null,
      selectedClientId: nextSessionNote?.client_id || null,
      locked: nextSessionNote?.locked || false,
      participants: nextSessionNote.participants,
      otherParticipant: nextSessionNote.otherParticipant,
      currentIndex: newIndex,
      templateText,
      techIssues,
      participantsError: false,
      otherParticipantError: false,
      durationError: false,
      serviceTypeError: false,
      interventionError: false,
      responseError: false,
      signatureError: false,
      verifyError: false,
      techIssueReasonError: false,
      selectedClientError: false,
      startDateError: false,
      serviceTimeError: false,
    });
    if (!viewOnly && !downloadViewOnly && !isDemo) {
      this.onSaveAssociatedTimes();
    }
  };

  onChangeOtherInputTechIssue = (e) => {
    const { value } = e.target;
    this.setState({
      otherTechIssue: value.trim(),
      techIssueReasonError: false,
    });
  };

  onChangeTechIssueReasons = (e) => {
    const { name, checked } = e.target;
    const { techIssueReasons } = this.state;
    let reasons = [...techIssueReasons];
    if (checked) {
      reasons.push(name);
    } else {
      let index = reasons.findIndex((r) => r == name);
      reasons.splice(index, 1);
    }
    this.setState({ techIssueReasons: reasons, techIssueReasonError: false });
  };

  onChangeServiceTypeMinutes = (e, index) => {
    const { value } = e.target;
    this.setState({
      duration: value || 0,
      editAssociatedTimesOpenError: false,
    });
  };

  onRadioButtonChange = (name) => (e) => {
    const { value, checked } = e.target;
    this.setState({
      [name]: checked,
      verifyError: false,
    });
  };

  scrollToFirstError = () => {
    if (
      this.state.participantsError ||
      this.state.otherParticipantError ||
      this.state.startDateError ||
      this.state.serviceTimeError ||
      this.state.selectedClientError ||
      this.state.selectedClientMissingCodeError
    ) {
      if (this.props.createNew) {
        this.adhocCallDetailsRef?.scrollIntoView({
          block: "start",
          inline: "nearest",
          behavior: "smooth",
        });
      } else {
        this.callDetailsRef?.scrollIntoView({
          block: "start",
          inline: "nearest",
          behavior: "smooth",
        });
      }
    } else if (
      this.state.durationError ||
      this.state.serviceTypeError ||
      this.state.editAssociatedTimesOpenError ||
      (this.state.serviceType?.time_limit &&
        this.state.duration > parseInt(this.state.serviceType.time_limit))
    ) {
      this.serviceDetailsRef?.scrollIntoView({
        block: "start",
        inline: "nearest",
        behavior: "smooth",
      });
    } else if (this.state.interventionError) {
      this.noteRef?.scrollIntoView({
        block: "start",
        inline: "nearest",
        behavior: "smooth",
      });
    } else if (this.state.techIssueReasonError) {
      this.techIssuesRef?.scrollIntoView({
        block: "start",
        inline: "nearest",
        behavior: "smooth",
      });
    } else if (this.state.responseError) {
      this.responseNoteRef?.scrollIntoView({
        block: "start",
        inline: "nearest",
        behavior: "smooth",
      });
    } else if (this.state.signatureError || this.state.verifyError) {
      this.signatureCanvasRef?.scrollIntoView({
        block: "start",
        inline: "nearest",
        behavior: "smooth",
      });
    }
  };

  getSignatureImageURL = async () => {
    const { selectedClientId, billableTimeId, videoId } = this.state;
    const { userId } = this.props;
    let signatureImageData, buf, signatureKey, params;
    const credentials = await getAWSCredentialsForCurrentUserSession();
    const s3 = new AWS.S3({
      credentials,
      region: "us-east-1",
      signatureVersion: "v2",
      apiVersion: "2006-03-01",
      params: { Bucket: process.env.AWS_USER_DOC_BUCKET },
    });
    signatureImageData = this.signatureRef?.getTrimmedCanvas().toDataURL("image/png");
    buf = Buffer.from(signatureImageData?.replace(/^data:image\/\w+;base64,/, ""), "base64");
    signatureKey = !videoId
      ? `${selectedClientId}_adhoc_note_bcba_${userId}_signature_${format(
          new Date(),
          "yy-MM-dd-hh-mm"
        )}.png`
      : `${videoId}_bcba_session_note_signature_${billableTimeId || new Date()}.png`;
    params = {
      ACL: "public-read",
      Body: buf,
      ContentEncoding: "base64",
      ContentType: "image/png",
      Key: signatureKey,
    };
    try {
      const s3url = await s3.upload(params).promise();
      return s3url ? s3url.Location : null;
    } catch (e) {
      throw new Error("Error saving signature");
    }
  };

  onSubmit = async () => {
    const {
      duration,
      serviceType,
      focus,
      note,
      responseNote,
      startDate,
      billableTimeId,
      associatedSessionNotes,
      clinicianUserId,
      selectedClientId,
      videoId,
      signatureStarted,
      currentIndex,
    } = this.state;
    const { userId, clientId, customerDetails } = this.props;
    if (this.state.submitClicked) {
      return;
    }
    this.setState({ submitClicked: true });

    try {
      let billingStartDate = startDate;
      const serviceTypeData = {
        ...serviceType,
        start_date: new Date(billingStartDate),
        end_date: addMinutes(new Date(billingStartDate), parseInt(duration)),
        insurance_code_id: serviceType.id,
        video_call_id: videoId,
        noteCompleted: true,
        completedDate: new Date(),
        inProgress: false,
        isApproved: true,
        onHold: false,
      };
      let totalMins = 0;
      totalMins += parseInt(duration);
      if (associatedSessionNotes?.length > 0) {
        associatedSessionNotes.map((note) => {
          totalMins += parseInt(note.actual_time);
        });
      }
      if (!this.props.isDemo) {
        if (videoId) {
          this.setState({ associatedTimesSubmitSaving: true });
          this.onSaveAssociatedTimes(true);
        } else {
          const billableTimeData = {
            clinicianUserId: clinicianUserId ? clinicianUserId : userId,
            clientId: clientId ? clientId : selectedClientId,
            billableTime: serviceTypeData,
            note,
            focus,
            responseNote,
            diagnosis: customerDetails.diagnosis,
            signatureUrl: signatureStarted ? await this.getSignatureImageURL() : null,
            billableTimeId: billableTimeId || null,
            location: serviceType?.place_of_service || "Telehealth",
          };
          this.props.sendBillableTime(billableTimeData);
          this.setState({ serviceTypes: [serviceType] });
        }
      } else {
        this.setState({ associatedTimesSubmitSaving: true });
        let associatedTimes = [...associatedSessionNotes];
        associatedTimes[currentIndex].locked = true;
        associatedTimes[currentIndex].note_completed = true;
        const allNotesSubmitted = associatedTimes?.every((n) => n.locked);
        if (associatedSessionNotes?.length > 1 && !allNotesSubmitted) {
          this.setState(
            {
              successToastOpen: true,
              locked: true,
              noteCompleted: true,
              associatedSessionNotes: associatedTimes,
            },
            () => {
              setTimeout(() => {
                this.setState({
                  successToastOpen: false,
                  associatedTimesSubmitSaving: false,
                  submitClicked: false,
                });
              }, 3000);
            }
          );
        } else {
          this.setState({ finalToastOpen: true, noteCompleted: true }, () => {
            setTimeout(() => {
              this.setState({
                finalToastOpen: false,
                associatedTimesSubmitSaving: false,
                submitClicked: false,
              });
              this.onClose();
            }, 3000);
          });
        }
      }
    } catch (error) {
      const {
        participants,
        focus,
        note,
        responseNote,
        planModified,
        planModification,
        isVerified,
        signatureStarted,
        location,
        showLocationDropdown,
      } = this.state;
      const { sessionNotesSaving, isTreatmentPlan } = this.props;
      const errorParams = {
        participants,
        focus,
        note,
        responseNote,
        planModified,
        planModification,
        isVerified,
        signatureStarted,
        showLocationDropdown,
        sessionNotesSaving,
        isTreatmentPlan,
      };
      const errorMessage = `${error} --- ${JSON.stringify(errorParams)}`;
      this.props.logError({ errorMessage, errorType: "SESSION_NOTES" });
      this.props.clearSessionNote();
      this.setState({
        notesLoading: false,
        notesError: true,
        submitClicked: false,
      });
      console.log(error);
    }
  };

  onSaveAsDraft = () => {
    const {
      duration,
      totalCallTime,
      serviceType,
      focus,
      note,
      responseNote,
      startDate,
      endDate,
      clinicianUserId,
      selectedClientId,
      videoId,
      serviceTime,
      selectedClientMissingCodeError,
      associatedSessionNotes,
      billableTimeId,
    } = this.state;
    const { userId, clientId, customerDetails, createNew } = this.props;

    this.setState({
      savedAsDraft: true,
    });

    if (
      createNew &&
      (!selectedClientId ||
        !startDate ||
        (startDate && differenceInDays(new Date(), new Date(startDate)) > 7) ||
        new Date(endDate) > new Date() ||
        !serviceTime ||
        !duration ||
        selectedClientMissingCodeError)
    ) {
      this.setState(
        {
          durationError: !duration,
          selectedClientError: createNew && !selectedClientId,
          startDateError:
            !startDate ||
            differenceInDays(new Date(), new Date(startDate)) > 7 ||
            new Date(endDate) > new Date(),
          serviceTimeError: !serviceTime,
          confirmExitAdhocDialogOpen: false,
        },
        () => {
          this.adhocCallDetailsRef.scrollIntoView({
            block: "start",
            inline: "nearest",
            behavior: "smooth",
          });
        }
      );
      return;
    } else if (videoId && this.isTimeInaccurate()) {
      this.setState({ durationError: true }, () => {
        this.scrollToFirstError();
      });
      return;
    }

    if (!this.props.isDemo) {
      if (videoId) {
        this.setState({ associatedTimesDraftSaving: true });
        this.onSaveAssociatedTimes();
      } else {
        let billingStartDate = startDate;
        const serviceTypeData = {
          ...serviceType,
          start_date: billingStartDate ? new Date(billingStartDate) : null,
          end_date:
            duration && totalCallTime && duration > totalCallTime
              ? endDate
              : addMinutes(new Date(billingStartDate), parseInt(duration)),
          insurance_code_id: serviceType?.id || null,
          video_call_id: null,
          noteCompleted: false,
          completedDate: null,
          inProgress: true,
          isApproved: false,
          onHold: false,
        };

        const billableTimeData = {
          clinicianUserId: clinicianUserId ? clinicianUserId : userId,
          clientId: clientId ? clientId : selectedClientId,
          billableTime: serviceTypeData,
          focus,
          responseNote,
          note,
          diagnosis: customerDetails.diagnosis,
          participants: null,
          signatureUrl: null,
          billableTimeId: billableTimeId || null,
          videoCallEndDate: endDate,
          videoCallId: videoId,
          location: serviceType?.place_of_service || "Telehealth",
        };
        this.props.sendBillableTime(billableTimeData);
        this.setState({ serviceTypes: [serviceType] });
      }
    } else {
      this.setState({ associatedTimesDraftSaving: true });
      if (this.state.confirmExitDialogOpen) {
        this.onClose();
      } else if (associatedSessionNotes?.length > 1) {
        this.setState({ successToastOpen: true }, () => {
          setTimeout(() => {
            this.setState({
              successToastOpen: false,
              associatedTimesDraftSaving: false,
              savedAsDraft: false,
            });
          }, 3000);
        });
      } else {
        this.setState({ finalToastOpen: true }, () => {
          setTimeout(() => {
            this.setState({
              finalToastOpen: false,
              noteCompleted: true,
              associatedTimesDraftSaving: false,
              savedAsDraft: false,
            });
            this.onClose();
          }, 3000);
        });
      }
    }
  };

  onSaveTimesheetAsDraft = () => {
    const { serviceType, note, startDate, endDate, billableTimeId, clinicianUserId } = this.state;
    const { userId } = this.props;

    this.setState({
      savedAsDraft: true,
    });

    let billableTimeData = {
      ...serviceType,
      start_date: startDate ? new Date(startDate) : new Date(),
      end_date: endDate ? new Date(endDate) : new Date(),
      insurance_code_id: serviceType?.id,
      video_call_id: null,
      noteCompleted: false,
      completedDate: null,
      inProgress: true,
      isApproved: false,
      onHold: false,
    };
    const timesheet = {
      billableTime: billableTimeData,
      billableTimeId: billableTimeId || null,
      clinicianUserId: clinicianUserId ? clinicianUserId : userId,
      clientId: null,
      note: note || "",
      focus: null,
      responseNote: null,
      diagnosis: null,
      participants: null,
      signatureUrl: null,
      videoCallEndDate: null,
      timeRemaining: null,
      videoCallId: null,
    };

    this.props.sendTimesheetBillableTime(timesheet);
  };

  onSubmitTimesheet = () => {
    const { serviceType, note, startDate, endDate, billableTimeId, clinicianUserId } = this.state;
    const { userId } = this.props;

    this.setState({
      savedAsDraft: false,
    });

    let billableTimeData = {
      start_date: new Date(startDate),
      end_date: new Date(endDate),
      insurance_code_id: serviceType?.id,
      video_call_id: null,
      noteCompleted: true,
      completedDate: new Date(),
      inProgress: false,
      isApproved: false,
      onHold: false,
    };
    const timesheet = {
      billableTime: billableTimeData,
      billableTimeId: billableTimeId || null,
      clinicianUserId: clinicianUserId ? clinicianUserId : userId,
      clientId: null,
      note: note,
      focus: null,
      responseNote: null,
      diagnosis: null,
      participants: null,
      signatureUrl: null,
      videoCallEndDate: null,
      videoCallEndDate: null,
      timeRemaining: null,
      videoCallEndDate: null,
      timeRemaining: null,
      videoCallId: null,
    };

    this.props.sendTimesheetBillableTime(timesheet);
  };

  handleDeleteParticipant = (e, i) => {
    let participants = this.state.participants;
    participants.splice(i, 1);
    this.setState({ participants });
  };

  closeTip = (name) => {
    this.setState({ [name]: false });
  };

  toggleOpenTip = (name) => (e) => {
    this.setState({ [name]: !this.state[name] }, () => {
      if (name === "noteTipOpen") {
        this.setState({ responseTipOpen: false });
      } else {
        this.setState({ noteTipOpen: false });
      }
    });
  };

  onToggleExitDialog = () => {
    const { associatedSessionNotes, videoId } = this.state;
    const allNotesSubmitted = associatedSessionNotes?.every((n) => n.locked);
    const timeCompleted = videoId ? !this.isTimeInaccurate() : true;
    if (!timeCompleted) {
      this.setState(
        {
          durationError: true,
        },
        () => {
          this.scrollToFirstError();
        }
      );
      return;
    }
    if (this.props.viewOnly || allNotesSubmitted) {
      this.props.onCloseCPTCodeDialog();
      return;
    }
    this.setState({ confirmExitDialogOpen: !this.state.confirmExitDialogOpen });
  };

  onToggleExitAdhocDialog = () => {
    if (this.props.viewOnly || this.state.locked) {
      this.props.onCloseCPTCodeDialog();
      return;
    }
    this.setState({
      confirmExitAdhocDialogOpen: !this.state.confirmExitAdhocDialogOpen,
    });
  };

  setError = (name, error) => {
    this.setState({ [name]: error });
  };

  setSignatureStarted = (signatureStarted) => {
    this.setState({ signatureStarted });
  };

  clearSignatureCanvas = () => {
    this.signatureRef?.clear();
    this.setSignatureStarted(false);
  };

  onCloseSuccessToast = () => {
    this.setState({ successToastOpen: false });
  };

  onCloseFinalToast = () => {
    this.setState({ finalToastOpen: false, notesCompleted: true });
    this.onClose();
  };

  isTimeInaccurate = () => {
    const { associatedSessionNotes, totalCallTime } = this.state;
    let totalMins = 0;
    if (associatedSessionNotes?.length > 0) {
      associatedSessionNotes.map((note) => {
        totalMins += parseInt(note.actual_time);
      });
    }
    return totalCallTime !== totalMins;
  };

  approveNote = () => {
    this.props.approveBillableTime(this.state.billableTimeId);
  };

  submitRequestChanges = () => {
    this.props.requestEdits({
      timeId: this.state.billableTimeId,
      requestedEdits: this.state.requestedEdits,
    });
  };

  onToggleRequestChangesDialog = () => {
    this.setState({
      requestChangesDialogOpen: !this.state.requestChangesDialogOpen,
    });
  };

  downloadSessionNote = async () => {
    const { billableTimeId } = this.state;
    const baseUrl = process.env.BASE_URL;
    const userSession = await Auth.currentSession();
    location.href = `${baseUrl}/download/${userSession.accessToken.jwtToken}/session-note/${billableTimeId}`;
  };

  downloadAllSessionNotes = async () => {
    const { videoCallId } = this.props;
    if (!videoCallId) {
      this.downloadSessionNote();
      return;
    }
    const baseUrl = process.env.BASE_URL;
    const userSession = await Auth.currentSession();
    location.href = `${baseUrl}/download/${userSession.accessToken.jwtToken}/all-session-notes/${videoCallId}`;
  };

  onToggleConfirmSubmitDialog = () => {
    this.setState({
      confirmSubmitDialogOpen: !this.state.confirmSubmitDialogOpen,
    });
  };

  onToggleConfirmDeleteDialog = (billableTimeIndex = null) => {
    this.setState({
      confirmDeleteDialogOpen: !this.state.confirmDeleteDialogOpen,
      deleteNoteIndex: billableTimeIndex,
    });
  };

  onConfirmDelete = () => {
    const { deleteNoteIndex, associatedSessionNotes, billableTimeId, videoId } = this.state;
    const { isDemo } = this.props;
    if (!videoId) {
      this.props.deleteBillableTime({
        deleteTimeId: billableTimeId,
        adhoc: true,
      });
    } else {
      const deleteNote = associatedSessionNotes[deleteNoteIndex];
      if (!deleteNote.billable_time_id || isDemo) {
        this.onRemoveAssociatedServiceType(deleteNoteIndex);
        this.onToggleConfirmDeleteDialog();
      } else {
        this.props.deleteBillableTime({
          deleteTimeId: deleteNote.billable_time_id,
          billableTimeId,
        });
      }
    }
  };

  onOpenConfirmSubmitDialog = async () => {
    const {
      participants,
      otherParticipant,
      duration,
      totalCallTime,
      serviceType,
      note,
      responseNote,
      signatureStarted,
      isVerified,
      startDate,
      endDate,
      templateText,
      techIssues,
      techIssueReasons,
      editAssociatedTimesOpen,
      serviceTime,
      selectedClientId,
      videoId,
    } = this.state;
    const { createNew, videoCallId } = this.props;

    this.setState(
      {
        participantsError: videoId && participants.length <= 1,
        otherParticipantError: participants.some((p) => p == "Other") && !otherParticipant,
        durationError: (videoId && this.isTimeInaccurate()) || !duration,
        serviceTypeError: !serviceType?.description,
        interventionError: !techIssues && templateText.noteBlockTitle && !note,
        responseError: !techIssues && templateText.responseBlockTitle && !responseNote,
        signatureError: !techIssues && !signatureStarted,
        verifyError: !techIssues && !isVerified,
        techIssueReasonError: techIssues && techIssueReasons.length === 0,
        selectedClientError: createNew && !selectedClientId,
        startDateError:
          !startDate ||
          (differenceInDays(new Date(), new Date(startDate)) > 7 &&
            !this.state.videoId &&
            !this.state.needsEdits) ||
          new Date(endDate) > new Date(),
        serviceTimeError: !serviceTime,
      },
      () => {
        if (
          this.state.participantsError ||
          this.state.otherParticipantError ||
          this.state.durationError ||
          this.state.serviceTypeError ||
          this.state.interventionError ||
          this.state.responseError ||
          this.state.signatureError ||
          this.state.verifyError ||
          this.state.techIssueReasonError ||
          this.state.startDateError ||
          this.state.serviceTimeError ||
          this.state.selectedClientError ||
          this.state.selectedClientMissingCodeError ||
          (serviceType?.time_limit && duration > parseInt(serviceType.time_limit))
        ) {
          this.scrollToFirstError();
        } else {
          if (!videoCallId) {
            this.onSubmit();
          } else {
            this.setState({ confirmSubmitDialogOpen: true });
          }
        }
      }
    );
  };

  onConfirmSubmit = () => {
    this.setState({ confirmSubmitDialogOpen: false }, () => {
      this.onSubmit();
    });
  };

  render() {
    const {
      classes,
      callBreakdownLoading,
      customerLoaded,
      customerCliniciansLoading,
      open,
      timesheetNote,
      createNew,
    } = this.props;
    const loading =
      (callBreakdownLoading ||
        ((this.state.videoId || (!timesheetNote && !createNew)) && !customerLoaded) ||
        customerCliniciansLoading ||
        (timesheetNote && !createNew && !this.props.billableItemSuccess)) &&
      open;
    return loading ? (
      <div className={classes.loadingContainer}>
        <CircularProgress size={30} color="primary" />
      </div>
    ) : (
      <>
        <SessionNoteComponent
          {...this.props}
          {...this.state}
          onToggleExitDialog={this.onToggleExitDialog}
          onSaveAsDraft={this.onSaveAsDraft}
          onSubmit={this.onSubmit}
          setError={this.setError}
          onChange={this.onChange}
          onRadioButtonChange={this.onRadioButtonChange}
          onChangeSelectedClient={this.onChangeSelectedClient}
          handleDeleteParticipant={this.handleDeleteParticipant}
          onChangeServiceTypeMinutes={this.onChangeServiceTypeMinutes}
          onChangeServiceType={this.onChangeServiceType}
          toggleOpenTip={this.toggleOpenTip}
          closeTip={this.closeTip}
          setDate={this.setDate}
          setTime={this.setTime}
          setEndTime={this.setEndTime}
          onChangeAssociatedServiceType={this.onChangeAssociatedServiceType}
          onChangeAssociatedDuration={this.onChangeAssociatedDuration}
          onAddAssociatedServiceType={this.onAddAssociatedServiceType}
          onChangeOtherInputTechIssue={this.onChangeOtherInputTechIssue}
          onChangeTechIssueReasons={this.onChangeTechIssueReasons}
          onRemoveAssociatedServiceType={this.onRemoveAssociatedServiceType}
          onSaveAssociatedTimes={this.onSaveAssociatedTimes}
          onEditAssociatedTimes={this.onEditAssociatedTimes}
          onCloseSuccessToast={this.onCloseSuccessToast}
          setSignatureStarted={this.setSignatureStarted}
          onToggleExitAdhocDialog={this.onToggleExitAdhocDialog}
          setTimesheetServiceType={this.setTimesheetServiceType}
          setTimesheetDate={this.setTimesheetDate}
          setTimesheetTime={this.setTimesheetTime}
          setTimesheetMinutes={this.setTimesheetMinutes}
          setTimesheetNote={this.setTimesheetNote}
          onRemoveTimesheetNote={this.onRemoveTimesheetNote}
          onAddTimesheetNote={this.onAddTimesheetNote}
          onSubmitTimesheet={this.onSubmitTimesheet}
          onSaveTimesheetAsDraft={this.onSaveTimesheetAsDraft}
          onClose={this.onClose}
          onToggleRequestChangesDialog={this.onToggleRequestChangesDialog}
          approveNote={this.approveNote}
          submitRequestedChanges={this.submitRequestChanges}
          downloadAllSessionNotes={this.downloadAllSessionNotes}
          downloadSessionNote={this.downloadSessionNote}
          callDetailsRef={(ref) => (this.callDetailsRef = ref)}
          adhocCallDetailsRef={(ref) => (this.adhocCallDetailsRef = ref)}
          serviceDetailsRef={(ref) => (this.serviceDetailsRef = ref)}
          techIssuesRef={(ref) => (this.techIssuesRef = ref)}
          noteRef={(ref) => (this.noteRef = ref)}
          responseNoteRef={(ref) => (this.responseNoteRef = ref)}
          signatureCanvasRef={(ref) => (this.signatureCanvasRef = ref)}
          signatureRef={(ref) => (this.signatureRef = ref)}
          clearSignatureCanvas={this.clearSignatureCanvas}
          onOpenConfirmSubmitDialog={this.onOpenConfirmSubmitDialog}
          onChangeNoteIndex={this.onChangeNoteIndex}
          onToggleConfirmDeleteDialog={this.onToggleConfirmDeleteDialog}
          onConfirmDelete={this.onConfirmDelete}
          onCloseFinalToast={this.onCloseFinalToast}
        />
        <ConfirmSubmitDialog
          open={this.state.confirmSubmitDialogOpen}
          onClose={this.onToggleConfirmSubmitDialog}
          callBreakdownLoading={callBreakdownLoading}
          successToastOpen={this.state.successToastOpen}
          finalToastOpen={this.state.finalToastOpen}
          notesCompleted={this.state.notesCompleted}
          onConfirmSubmit={this.onConfirmSubmit}
          classes={classes}
        />
      </>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SessionNotes))
);
