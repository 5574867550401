import { Stack } from "@mui/material";
import { useTheme } from "@mui/styles";
import actions from "actions";
import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import * as yup from "yup";

import { useGlobalToast } from "components/GlobalToastProvider";
import ANLoadingScreen from "elements/ANLoadingScreen";
import useSaveClinicianCredentials from "hooks/api/useSaveClinicianCredentials";
import { useGetClinicianCredentials } from "hooks/api/useGetClinicianCredentials";
import { useGetClinicianStateLicense } from "hooks/api/useGetClinicianStateLicense";
import { useGetClinicianCertification } from "hooks/api/useGetClinicianCertification";
import { useGetClinicianLiabilityInsurance } from "hooks/api/useGetClinicianLiabilityInsurance";
import LiabilityInsurance from "./LiabilityInsurance";
import EditActionButtons from "../EditActionButtons";
import CredentialInformation from "./CredentialInformation";
import StateLicenses from "./StateLicenses";
import Certifications from "./Certifications";
import SaveBeforeNavDialog from "components/ClientDetails/Dialogs/SaveBeforeNavDialog";

export default function LicensesCertifications({
  clinicianId,
  basicInfo,
  setUnsavedChanges,
  unsavedChanges,
}) {
  const [editMode, setEditMode] = React.useState(false);
  const [openWarning, setOpenWarning] = React.useState(false);
  const theme = useTheme();
  const dispatch = useDispatch();
  const { showToast } = useGlobalToast();
  const lastSavedValuesRef = useRef();
  const { data: credentialsResponse, isLoading: credentialsLoading } =
    useGetClinicianCredentials(clinicianId);
  const credentials = credentialsResponse?.data;
  const { data: licensesResponse, isLoading: licensesLoading } =
    useGetClinicianStateLicense(clinicianId);
  const licenses = licensesResponse?.data;
  const { certifications, certificationLoading } = useGetClinicianCertification(clinicianId);
  const { liabilityInsuranceList, liabilityLoading } =
    useGetClinicianLiabilityInsurance(clinicianId);

  const showEditButton = (action) => dispatch(actions.setPageDetails({ onEditButton: action }));

  const { mutate: saveCredentials, isLoading: isLoadingSaveCredentials } =
    useSaveClinicianCredentials({
      onSuccess: () => {
        setEditMode(false);
        showEditButton(true);
        showToast({ message: "Success!" });
      },
      onError: () => {
        showToast({
          message: "Whoops! Something went wrong",
          errorState: true,
          retryHandler: () => handleSave(lastSavedValuesRef.current),
        });
      },
    });

  useEffect(() => {
    dispatch(
      actions.setPageDetails({
        onEditButton: true,
        editButtonAction: () => {
          setEditMode(true);
          showEditButton(false);
        },
      })
    );
    return () => showEditButton(false);
  }, []);

  if (credentialsLoading || licensesLoading || certificationLoading || liabilityLoading)
    return <ANLoadingScreen />;

  const initialValues = {
    credentials: credentials.credentials || "",
    npi: credentials.npi || "",
    caqhNumber: credentials.caqh_number || "",
    medicaidNumber: credentials.medicaid_number || "",
  };

  const validationSchema = yup.object({
    credentials: yup.string().optional(),
    npi: yup.string().optional(),
    caqhNumber: yup.string().optional(),
    medicaidNumber: yup.string().optional(),
  });

  function handleSave(values) {
    // Saves the reference to the last saved values to be used in the retry handler
    lastSavedValuesRef.current = values;
    saveCredentials({ ...values, clinicianId });
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSave}>
      {({ handleSubmit, dirty, resetForm }) => {
        const resetFormAndExitEditMode = () => {
          setEditMode(false);
          showEditButton(true);
          resetForm();
        };

        const onCancel = () => {
          if (dirty) {
            setOpenWarning(true);
            return;
          }
          if (!unsavedChanges) resetFormAndExitEditMode();
        };

        useEffect(() => {
          setUnsavedChanges(dirty);
        }, [dirty]);

        return (
          <Stack margin={theme.spacing(5)} gap={theme.spacing(5)}>
            <CredentialInformation editMode={editMode} credentials={credentials} />
            <StateLicenses editMode={editMode} licenses={licenses} basicInfo={basicInfo} />
            <Certifications
              editMode={editMode}
              certifications={certifications}
              basicInfo={basicInfo}
            />
            <LiabilityInsurance
              editMode={editMode}
              liabilityInsuranceList={liabilityInsuranceList}
              basicInfo={basicInfo}
            />
            {editMode && (
              <Stack alignItems="center">
                <EditActionButtons
                  onCancel={onCancel}
                  onSave={handleSubmit}
                  loading={isLoadingSaveCredentials}
                />
              </Stack>
            )}
            {/* Cancel action it is handled internally. Moving away action (going to a new page) it 
            is still handled in the parent component */}
            <SaveBeforeNavDialog
              saveBeforeNavOpen={openWarning}
              onToggleSaveBeforeNav={() => setOpenWarning(false)}
              onContinueWithoutSaving={() => {
                setOpenWarning(false);
                resetFormAndExitEditMode();
              }}
              severetyAlert={"warning"}
              primaryActionColorButton={"error"}
              secondaryText={"Stay on Page"}
              isCancelClicked
            />
          </Stack>
        );
      }}
    </Formik>
  );
}
