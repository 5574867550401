import React, { useEffect } from "react";
import { Accordion, AccordionSummary, Typography } from "@mui/material";

import { CaretDown } from "@phosphor-icons/react/dist/ssr";

import { PeopleFilter } from "elements/PeopleFilter";
import { useQuery } from "react-query";
import { searchUsers } from "../../../api/users";

export const PeopleFiltering = ({
  canSearchAll,
  inputValue,
  userId,
  filteredPeople,
  setInputValue,
  ...props
}) => {
  const { data: response } = useQuery(
    ["searchUsers", inputValue],
    () => searchUsers(userId, { nameInput: inputValue }),
    {
      enabled: !!inputValue && !!userId,
      cacheTime: 0, // Prevent caching
      staleTime: 0, // Always consider data stale
    }
  );

  useEffect(() => {
    if (filteredPeople.length > 0) {
      setInputValue("");
    }
  }, [filteredPeople, setInputValue]);

  const searchResults = response?.data ?? [];

  return (
    <Accordion
      defaultExpanded
      square
      disableGutters
      sx={{
        "&::before": {
          display: "none",
        },
        "&.Mui-expanded": {
          margin: 0,
        },
        boxShadow: "none",
      }}
    >
      <AccordionSummary expandIcon={<CaretDown size={20} />} sx={{ padding: 0 }}>
        <Typography>{canSearchAll ? "People" : "Clients"}</Typography>
      </AccordionSummary>
      <div style={{ paddingBottom: "16px", marginBottom: "8px" }}>
        <PeopleFilter
          searchResults={searchResults}
          filteredPeople={filteredPeople}
          setInputValue={setInputValue}
          inputValue={inputValue}
          {...props}
        />
      </div>
    </Accordion>
  );
};
