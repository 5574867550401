import { getCalendarSessionsByDates } from "api/video";
import { useQuery } from "react-query";

/**
 * Custom hook to fetch calendar sessions by dates.
 *
 * @param {Object} details - The details object containing startDate, endDate, and userId.
 * @returns {Object} - The result of the useQuery hook.
 */
export const useCalendarSessionsByDates = ({ startDate, endDate, userIdList }) => {
  const cleanList = userIdList.filter(Boolean); // Remove any falsy values from the list
  const queryDetails = { startDate, endDate, userIdList: cleanList };
  return useQuery(
    ["calendarSessions", queryDetails],
    () => getCalendarSessionsByDates(queryDetails),
    {
      enabled: !!startDate && !!endDate && cleanList.length > 0,
    },
  );
};
