import React from "react";
import Modal from "elements/Modal";

const MarkCAQHCompleteDialog = (props) => {
  const { markCAQHCompleteOpen, onToggleDialog, onSubmitCAQHComplete } = props;

  return (
    <Modal
      open={markCAQHCompleteOpen}
      onClose={onToggleDialog}
      title="CAQH Complete"
      titleCentered
      description="Please confirm that this clinician has completed setting up their CAQH Account."
      primaryActionText="Submit"
      primaryActionOnClick={onSubmitCAQHComplete}
      secondaryActionText="Cancel"
      secondaryActionOnClick={onToggleDialog}
    />
  );
};

export default MarkCAQHCompleteDialog;
