import React from "react";
import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

const StyledButton = withStyles({
  root: {
    minWidth: 100,
    borderRadius: 8,
    height: 40,
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
    },
    whiteSpace: "nowrap",
  },
  outlinedSizeLarge: {
    minWidth: 120,
    boxShadow: "none",
    "& span": {
      fontWeight: 500,
    },
  },
  outlinedSizeSmall: {
    borderRadius: 6,
    minWidth: 80,
    height: 30,
  },
  containedSizeLarge: {
    minWidth: 120,
    boxShadow: "none",
    "& span": {
      fontWeight: 500,
    },
  },
  containedSizeSmall: {
    borderRadius: 6,
    minWidth: 80,
    height: 30,
  },
  label: {
    textTransform: "none",
    letterSpacing: "0.5px",
    fontWeight: 500,
  },
})(Button);

const CustomButton = (props) => {
  const { classes, children, loading, disabled, tabButton, tabSelected, color, ...buttonProps } =
    props;
  let mappedColor = `${color || "primary"}`.toLowerCase();
  mappedColor =
    mappedColor == "default" && buttonProps.variant == "outlined" ? "dark" : mappedColor;

  return (
    <StyledButton
      color={mappedColor}
      variant={buttonProps.variant || (tabButton && !tabSelected) ? "outlined" : "contained"}
      className={tabButton ? (tabSelected ? classes.tabButtonSelected : classes.tabButton) : null}
      disabled={loading || disabled}
      {...buttonProps}
    >
      {loading ? <CircularProgress style={{ color: "purple" }} size={24} /> : children}
    </StyledButton>
  );
};

const styles = (theme) => ({
  tabButton: {
    marginRight: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "1px 1px 3px 1px rgba(0,0,0,.2)",
    "&:hover": {
      cursor: "pointer",
      boxShadow: "1px 1px 3px 1px rgba(0,0,0,.2)",
    },
    whiteSpace: "nowrap",
  },
  tabButtonSelected: {
    marginRight: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      cursor: "pointer",
    },
  },
});

export default withStyles(styles)(CustomButton);
