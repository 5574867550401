export const styles = (theme) => ({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    overflow: "visible",
  },
  assignContainer: {
    width: "100%",
    maxHeight: 550,
    marginTop: 20,
    padding: "0 20px",
    overflowY: "auto",
  },
  clinicianInfoContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    margin: "20px 0px",
  },
  title: {
    fontSize: 15,
    fontWeight: 500,
    color: "#1517d",
    marginLeft: 30,
  },
  subtitle: {
    color: "#737273",
    fontSize: 14,
    fontWeight: 500,
  },
  clinicianInfo: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
    padding: "10px 0",
  },
  info: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  avatar: {
    marginRight: 10,
  },
  clinicianName: {
    fontSize: 16,
    fontWeight: 500,
    color: "#1517d",
  },
  role: {
    fontSize: 15,
    color: "#3f4456",
    fontWeight: 500,
    marginTop: 5,
  },
  clinicianNameInfo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    marginLeft: 10,
  },
  clinicianAvailabilityInfoContainer: {
    display: "flex",
    flexDirection: "column",
    border: "1px solid #c6c6c6",
    borderRadius: 8,
    margin: "10px 0",
    padding: 10,
    boxSizing: "border-box",
  },
  clinicianAvailabilityInfo: {
    display: "flex",
    alignItems: "center",
  },
  clinicianAvailabilityBasicInfo: {
    width: "90%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    marginLeft: 10,
    paddingTop: 20,
    boxSizing: "border-box",
  },
  availabilityRole: {
    fontSize: 13,
    color: "#404040",
  },
  availabilityText: {
    fontWeight: 500,
    fontSize: 13,
    color: "#404040",
  },
  availabilityList: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    maxHeight: 150,
    padding: 5,
    boxSizing: "border-box",
    [theme.breakpoints.down("xl")]: {
      maxHeight: 250,
    },
  },
  availabilityInfo: {
    maxWidth: "100%",
    display: "flex",
    margin: "5px 0",
    color: "#404040",
  },
  availabilityDay: {
    width: "40px",
    fontSize: 14,
    marginRight: 25,
  },
  availabilitySlotContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "flex-start",
  },
  unmatchedText: {
    fontSize: 15,
    marginLeft: 30,
  },
  alertContainer: {
    width: "100%",
    fontSize: "13px",
    marginTop: "20px",
  },
  divider: {
    height: 0,
    width: "100%",
    borderBottom: "2px solid #cbcbcb",
    margin: "10px 0",
  },
});
