import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import Modal from "elements/Modal";
import { CustomSelect } from "elements/DEPRECATED_elements";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

const UpdateClinicianStatusDialog = (props) => {
  const {
    updateClinicianStatusOpen,
    workflowStatus,
    onToggleDialog,
    onSubmitUpdateClinicianStatus,
    onChangeClinicianStatus,
    onClickCheckbox,
    updatedClinicianStatus,
    updateClinicianStatusRisks,
  } = props;
  return (
    <Modal
      open={updateClinicianStatusOpen}
      onClose={onToggleDialog}
      title="Manually Update Clinician Status"
      titleCentered
      content={
        <div style={{ width: 600 }}>
          <Typography component="p" color="error" style={{ textAlign: "center" }}>
            WARNING: Manually updating a clinician's status could cause potential problems with the
            user experience and it is recommended that you consult the tech team prior to manual
            changes.
          </Typography>
          <TextField
            select
            id="clinician-status"
            label="Clinician Status"
            value={updatedClinicianStatus}
            onChange={(e) => onChangeClinicianStatus(e)}
            fullWidth
            style={{ margin: "15px 0" }}
          >
            <MenuItem disabled value={""} />
            <MenuItem value={"APPLIED"}>Submitted Initial Application</MenuItem>
            <MenuItem value={"INTERVIEWING"}>Interview In Progress</MenuItem>
            <MenuItem value={"APPLICATION_IN_PROGRESS"}>
              Invited to Portal (completing documentation)
            </MenuItem>
            <MenuItem value={"SUBMITTED"}>Documentation Submitted (pending review)</MenuItem>
            <MenuItem value={"CAQH"}>Updating CAQH Account</MenuItem>
            <MenuItem value={"CREDENTIALING"}>Credentialing (application approved)</MenuItem>
            <MenuItem value={"CREDENTIALED"}>Fully Assignable</MenuItem>
          </TextField>

          <FormControlLabel
            control={
              <Checkbox
                checked={updateClinicianStatusRisks}
                onClick={() => onClickCheckbox("updateClinicianStatusRisks")}
              />
            }
            label={
              "I understand that there are risks associated with manually updating the current status of a user."
            }
          />
        </div>
      }
      primaryActionText="Submit"
      primaryActionOnClick={onSubmitUpdateClinicianStatus}
      primaryActionDisabled={!updateClinicianStatusRisks || !updatedClinicianStatus}
      secondaryActionText="Cancel"
      secondaryActionOnClick={onToggleDialog}
    />
  );
};

export default UpdateClinicianStatusDialog;
