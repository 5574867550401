import { getClinicianCertificationAsync } from "api/clinician";
import { CLINICIAN_CERTIFICATION } from "constants/reactQueryKeys";
import { useQuery } from "react-query";

export const useGetClinicianCertification = (clinicianId) => {
  const { data, isLoading, ...rest } = useQuery(
    [CLINICIAN_CERTIFICATION],
    () => getClinicianCertificationAsync(clinicianId),
    {
      enabled: !!clinicianId,
    }
  );
  return {
    certifications: data?.data || [],
    certificationLoading: isLoading,
    ...rest,
  };
};
