import React from "react";
import { Divider, Grid, Typography } from "@mui/material";

import { COUNTRIES, STATES, TIMEZONES } from "constants";
import PanelHeader from "./PanelHeader";
import { RowItem } from "./RowItem";
/**  @typedef {import("api/typesDef").Member} Member */

/**
 * The `TeamMemberDetail` component displays detailed information about a team member
 * (including their first name, last name, phone number, email, address, etc) and allows copying
 * specific information.
 * @component
 * @param {Object} props - The props of the component
 * @param {Member} props.member - The member data
 * @param {Object[]} props.researchParticipants - The research participant data
 * @param {Function} props.onClosePanel - The function to close the collapsable panel
 * @returns {JSX.Element} The `<TeamMemberDetail />` component is being returned.
 */
export default function TeamMemberDetail({ member, researchParticipants, onClosePanel }) {
  const {
    firstName,
    lastName,
    dateOfBirth,
    gender,
    phoneNumber,
    email,
    address1,
    address2,
    country,
    state,
    city,
    zipCode,
    timezone,
    notificationPreferences,
  } = member;

  const fullName = `${firstName} ${lastName}`;
  const dateOfBirthFormatted = dateOfBirth ? new Date(dateOfBirth).toLocaleDateString() : null;
  const memberGender = gender !== "Unknown" ? gender : null;
  const electronicCommunications =
    notificationPreferences?.sendEmail || notificationPreferences?.sendSms;

  return (
    <>
      <PanelHeader fullname={fullName} onClose={onClosePanel} />
      <Grid container rowSpacing={5} marginTop={2} component="section">
        <Grid item xs={12}>
          <Typography component="h3" variant="subtitle2">
            Demographics
          </Typography>
        </Grid>
        <RowItem label="First Name" value={firstName} enableCopy />
        <RowItem label="Last Name" value={lastName} enableCopy />
        <RowItem label="Date of Birth" value={dateOfBirthFormatted} enableCopy />
        <RowItem label="Gender" value={memberGender} enableCopy />
        <RowItem label="Phone Number" value={phoneNumber} enableCopy />
        <RowItem label="Email Address" value={email} enableCopy />
        <RowItem label="Address 1" value={address1} enableCopy />
        <RowItem label="Address 2" value={address2} enableCopy />
        <RowItem label="Country" value={COUNTRIES[country]} enableCopy />
        <RowItem label="State" value={STATES[state]} enableCopy />
        <RowItem label="City" value={city} enableCopy />
        <RowItem label="ZIP Code" value={zipCode} enableCopy />
        <RowItem label="Time Zone" value={TIMEZONES[timezone]} enableCopy />
        <RowItem
          label="Electronic Communications"
          value={electronicCommunications ? "Yes" : "No"}
          enableCopy
        />
      </Grid>

      <Divider sx={{ marginTop: 5 }} />

      {researchParticipants?.length && (
        <Grid container rowSpacing={5} marginTop={2}>
          <Grid item xs={12}>
            <Typography component="h3" variant="subtitle2">
              Research Participation
            </Typography>
          </Grid>

          {researchParticipants.map((participant) => (
            <RowItem
              key={participant.id}
              label={participant.fullname}
              value={participant.agreeToResearchParticipation ? "Yes" : "No"}
            />
          ))}
        </Grid>
      )}
    </>
  );
}
