import React from "react";
import Typography from "@mui/material/Typography";
import { format, addMinutes } from "date-fns";
import ScheduleIcon from "@mui/icons-material/Schedule";
import Button from "@mui/material/Button";
import SectionContainer from "elements/SectionContainer";
import ConfirmStartCallDialog from "./ConfirmStartCallDialog";
import { findDifferenceInMinutes } from "../../utils/findDifferenceInMinutes";
import momentTZ from "moment-timezone";

import { convertTokens } from "@date-fns/upgrade/v2";

const WeeklySchedule = (props) => {
  const {
    classes,
    weeklySchedule,
    weeklyScheduleLoading,
    weeklyScheduleSuccess,
    onOpenManageCallDialog,
    history,
    clinicianUserId,
    userPermissions,
    onOpenStartCallDialog,
    onCloseStartCallDialog,
    startVideoCall,
  } = props;
  const formatCallDuration = (duration) => {
    let hours = parseInt(duration / 60);
    let minutes = duration % 60;
    if (hours === 0) {
      return `${minutes} minutes`;
    } else if (hours === 1) {
      return `${hours} hour ${minutes > 0 ? minutes + " minutes" : ""}`;
    } else {
      return `${hours} hours ${minutes > 0 ? minutes + " minutes" : ""}`;
    }
  };

  return (
    <SectionContainer loading={weeklyScheduleLoading || !clinicianUserId}>
      <div className={classes.weeklySchedule}>
        <Typography component="p" className={classes.titleText}>
          This Week's Schedule
        </Typography>
        {weeklySchedule.length > 0
          ? weeklySchedule.map((call, index) => {
              const diffInMinutes = findDifferenceInMinutes(call.scheduled_date, new Date());
              const callIsExpired = momentTZ(call?.scheduled_date)
                .add(call?.call_duration, "minutes")
                .isBefore(momentTZ());
              const canModifyEvent = userPermissions?.select_scheduled_clinician;
              const isPrimaryClinician = call?.clinician_user_id === clinicianUserId;

              return (
                <div key={call.id} className={classes.callDetails}>
                  <Typography component="p" className={classes.sessionDate}>
                    {format(new Date(call.scheduled_date), convertTokens("dddd, D MMM"))}
                    {call?.off_platform
                      ? call.off_platform_type === "video"
                        ? " (Doxy)"
                        : " (Phone Call)"
                      : ""}
                  </Typography>
                  {userPermissions?.assignable_as_secondary_clinician && (
                    <div className={classes.participantsContainer}>
                      <Typography component="p" className={classes.roleText}>{`Role: ${
                        call.clinician_user_id === clinicianUserId
                          ? "Primary Clinician"
                          : "Supervising Clinician"
                      }`}</Typography>
                      {diffInMinutes < 15 &&
                        diffInMinutes > (call.call_duration + 15) * -1 &&
                        !call.end_date &&
                        !call.no_show &&
                        !call.off_platform &&
                        props.nextCall?.id !== call.id && (
                          <Button
                            onClick={() => onOpenStartCallDialog(call)}
                            size="small"
                            sx={{ marginLeft: "auto" }}
                          >
                            Join Call
                          </Button>
                        )}
                    </div>
                  )}
                  <div className={classes.sessionTimeContainer}>
                    <ScheduleIcon style={{ color: "#8f8f8f", fontSize: 14, marginRight: 5 }} />
                    <Typography component="p" className={classes.callDuration}>
                      {call.call_duration
                        ? `${format(
                            new Date(call.scheduled_date),
                            convertTokens("h:mm aa")
                          )} - ${format(
                            new Date(addMinutes(new Date(call.scheduled_date), call.call_duration)),
                            convertTokens("h:mm aa")
                          )} -- ${formatCallDuration(call.call_duration)}`
                        : format(new Date(call.scheduled_date), convertTokens("h:mm aa"))}
                    </Typography>
                    {(userPermissions?.reschedule_call || userPermissions?.cancel_call) &&
                      !call?.start_date &&
                      (isPrimaryClinician || canModifyEvent) &&
                      !callIsExpired && (
                        <Button
                          onClick={() => onOpenManageCallDialog(call)}
                          variant="text"
                          sx={{ marginLeft: "auto" }}
                        >
                          Manage Session
                        </Button>
                      )}
                  </div>

                  <div className={classes.participantsContainer}>
                    <div className={classes.callParticipants}>
                      <Typography component="p" className={classes.clientName}>
                        {`Client: ${call.child_name}`}
                      </Typography>
                    </div>
                    {userPermissions?.view_my_clients && (
                      <Button
                        onClick={() => history.push(`/clients/${call.client_id}/dashboard`)}
                        variant="text"
                        sx={{ marginLeft: "auto" }}
                      >
                        Client Details
                      </Button>
                    )}
                  </div>
                  {index !== weeklySchedule.length - 1 && <div className={classes.divider} />}
                </div>
              );
            })
          : weeklySchedule.length === 0 &&
            weeklyScheduleSuccess && (
              <div className={classes.callDetails}>
                <Typography
                  component="p"
                  className={classes.noWeeklyCalls}
                  style={{ textAlign: "center", width: "100%" }}
                >
                  No more sessions scheduled this week
                </Typography>
              </div>
            )}
      </div>
      <ConfirmStartCallDialog
        onCloseStartCallDialog={onCloseStartCallDialog}
        startVideoCall={startVideoCall}
        startCallOpen={props.startCallOpen}
        callDetails={props.startCallDetails}
      />
    </SectionContainer>
  );
};

export default WeeklySchedule;
