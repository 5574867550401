import React from "react";
import { TextField, Typography, FormLabel, FormGroup } from "@mui/material";
import DatePicker from "elements/ANDatePicker";
import { differenceInDays } from "date-fns";
import MenuItem from "@mui/material/MenuItem";
import ANTimePicker from "elements/ANTimePicker";

const TimesheetSessionDetails = (props) => {
  const {
    classes,
    startDate,
    endTime,
    serviceType,
    duration,
    serviceTime,
    insuranceCodesLoading,
    insuranceCodes,
    setDate,
    setTime,
    setEndTime,
    adhocCallDetailsRef,
    onChangeServiceType,
    serviceTypeOptions,
    dateError,
    timeError,
    setTimeError,
    totalMinutesError,
    setTotalMinutesError,
    serviceDetailsRef,
    startDateError,
  } = props;

  return (
    <div className={classes.sessionNoteDetails} ref={serviceDetailsRef}>
      <Typography component="p" variant="body2" style={{ marginBottom: 8 }}>
        Details
      </Typography>
      <div className={classes.adhocSessionDetails} ref={adhocCallDetailsRef}>
        <div className={classes.adhocDetailRow}>
          <FormGroup style={{ display: "flex", flex: "1 0 0" }}>
            <FormLabel component="legend" className={classes.callDetailLabel}>
              Service Type
            </FormLabel>
            <TextField
              id="selectedservicetype"
              name="selectedservicetype"
              value={serviceType?.description}
              onChange={(e) => onChangeServiceType(e)}
              placeholder="Please Select"
              select
              fullWidth
              disabled={insuranceCodesLoading}
            >
              {serviceTypeOptions?.timesheet?.map((code) => (
                <MenuItem
                  key={code.id}
                  value={code.description}
                  disabled={
                    insuranceCodes.length > 0 && !insuranceCodes.some((ic) => ic.id == code.id)
                  }
                >
                  {code.description}
                </MenuItem>
              ))}
            </TextField>
          </FormGroup>
          <FormGroup style={{ display: "flex", flex: "1 0 0" }}>
            <FormLabel component="legend" className={classes.callDetailLabel}>
              Date
            </FormLabel>
            <DatePicker
              value={startDate ? new Date(startDate) : null}
              onChange={(date) => setDate(date)}
              format="PP" //"MMM d, yyyy"
              fullWidth
              placeholder="Select service date"
              disableFuture
            />
            {dateError && !startDate && (
              <Typography component="p" className={classes.helperText}>
                Select the date
              </Typography>
            )}
            {startDateError &&
              startDate &&
              differenceInDays(new Date(), new Date(startDate)) > 7 && (
                <Typography component="p" className={classes.helperText}>
                  Time must be billed within 7 days of service.
                </Typography>
              )}
          </FormGroup>
        </div>
        <div className={classes.adhocDetailRow}>
          <FormGroup style={{ display: "flex", flex: "1 0 0" }}>
            <FormLabel component="legend" className={classes.callDetailLabel}>
              Start Time
            </FormLabel>
            <ANTimePicker
              id="time"
              variant="outlined"
              color="primary"
              name="serviceTime"
              placeholder="8:00 am"
              fullWidth
              error={!!timeError}
              value={serviceTime}
              onChange={(e) => setTime(e?.target?.value ?? e)}
              onFocus={() => setTimeError(false)}
              helperText={timeError && !serviceTime ? "Select start time" : null}
              FormHelperTextProps={{
                style: { backgroundColor: "#F6F7F8" },
              }}
            />
          </FormGroup>
          <FormGroup style={{ display: "flex", flex: "1 0 0" }}>
            <FormLabel component="legend" className={classes.callDetailLabel}>
              End Time
            </FormLabel>
            <ANTimePicker
              id="time"
              variant="outlined"
              color="primary"
              name="endDate"
              placeholder="9:00 am"
              fullWidth
              error={!!totalMinutesError}
              value={endTime}
              onChange={(e) => setEndTime(e?.target?.value ?? e)}
              onFocus={() => setTotalMinutesError(false)}
              helperText={totalMinutesError && !endTime ? "Select end time" : null}
              FormHelperTextProps={{
                style: { backgroundColor: "#F6F7F8" },
              }}
            />
            {serviceType?.time_limit && endTime && duration > parseInt(serviceType.time_limit) && (
              <Typography component="p" className={classes.helperText}>
                Service Type limited to {serviceType.time_limit} minutes
              </Typography>
            )}
          </FormGroup>
        </div>
      </div>
    </div>
  );
};

export default TimesheetSessionDetails;
