export default (theme) => ({
  root: {
    flexGrow: 1,
    position: "sticky",
    top: 0,
    zIndex: 3,
    opacity: 0.95,
    justifyContent: "center",
  },
  navBar: {
    backgroundColor: "#fbf7fe",
    border: "none",
  },
  grow: {
    flexGrow: 1,
    [theme.breakpoints.down("xl")]: {
      textAlign: "center",
      marginRight: 48,
    },
  },
  menuButton: {
    color: "#15171d",
    marginLeft: -12,
    marginRight: 20,
    // [theme.breakpoints.up("md")]: {
    //     display: "none",
    // },
    color: "#15171d",
  },
  logo: {
    width: 150,
    height: "auto",
    cursor: "pointer",
  },
  signinToolbar: {
    width: "100%",
    paddingLeft: "calc(20% - 75px)",
    margin: "0 auto",
    [theme.breakpoints.down("xl")]: {
      width: "95vw",
      paddingLeft: 0,
      "& a": {
        margin: "auto",
      },
    },
  },
  navButtons: {
    display: "flex",
    [theme.breakpoints.down("xl")]: {
      display: "none",
    },
  },
  chatNavButton: {
    color: "#15171d",
    fontWeight: 500,
    letterSpacing: "1px",
    fontSize: "17px",
    margin: "0 10px",
    padding: "8px 15px",
    textTransform: "none",
    borderRadius: 8,
    position: "relative",
  },
  unreadMessageCount: {
    width: 8,
    height: 8,
    borderRadius: 4,
    position: "absolute",
    top: 8,
    left: 14,
    backgroundColor: "#F87171",
  },
  navButton: {
    color: "#15171d",
    fontWeight: 500,
    letterSpacing: "1px",
    fontSize: "17px",
    margin: "0 10px",
    padding: "8px 15px",
    textTransform: "none",
    borderRadius: 8,
  },
  currentNavButton: {
    backgroundColor: "#1DE9B6",
    borderRadius: 8,
    color: "#fff",
    fontWeight: 500,
    letterSpacing: "1px",
    fontSize: "17px",
    margin: "0 10px",
    padding: "8px 15px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#18CDA1",
    },
  },
  drawerNavButton: {
    textTransform: "none",
    color: "#15171d",
    fontWeight: 500,
    letterSpacing: "1px",
    fontSize: "15px",
    margin: "5px 0px",
  },
  currentDrawerNavButton: {
    backgroundColor: "#1DE9B6",
    borderRadius: 8,
    color: "#fff",
    fontWeight: 500,
    padding: "5px 15px",
    margin: "5px 0px",
    textTransform: "none",
    fontSize: "15px",
    "&:hover": {
      backgroundColor: "#18CDA1",
    },
  },
  drawer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#ede0fa",
    zIndex: theme.zIndex.drawer - 1,
  },
  drawerList: {
    width: "100%",
    height: "100%",
    paddingTop: 60,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },

  navItem: {
    textTransform: "none",
    fontWeight: 500,
    fontSize: 16,
    zIndex: 0,
  },
  menuList: {
    padding: 0,
    zIndex: theme.zIndex.drawer + 2,
    // zIndex: "3500 !important",
  },
  menuItem: {
    fontSize: 16,
  },
  toolbar: {
    width: "100%",
    padding: "0 50px",
    margin: "0 auto",
    [theme.breakpoints.down("xl")]: {
      width: "95vw",
      padding: "0 10px",
    },
  },
  frontLogo: {
    width: 150,
    height: "auto",
  },
  toggleDrawerButtonContainer: {
    marginTop: "auto",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "20px 12px",
  },
});
