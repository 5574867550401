import React from "react";
import { IconButton, Stack, Typography } from "@mui/material";
import { X, UsersThree } from "@phosphor-icons/react";
import { useTheme } from "@mui/styles";

/**
 * The PanelHeader function renders a header component with a user's full name and a close button.
 * @component `PanelHeader`
 * @param {Object} props - The props of the component
 * @param {String} props.fullname - The full name of the user
 * @param {Function} props.onClose - The function to close the collapsable panel
 * @returns {JSX.Element} The `PanelHeader` component is being returned.
 */
export default function PanelHeader({ fullname, onClose }) {
  const theme = useTheme();
  return (
    <header>
      <Stack component="section" direction="row">
        <Stack direction="row" flexGrow={1} alignItems="center" gap={3}>
          <UsersThree weight="duotone" size="20px" color={theme.palette.action.active} />
          <Typography variant="subtitle1">{fullname}</Typography>
        </Stack>

        <IconButton onClick={onClose} size="small" fontSize="small">
          <X />
        </IconButton>
      </Stack>
    </header>
  );
}
