import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withStyles from "@mui/styles/withStyles";
import actions from "../../actions";
import styles from "./styles";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabContainer from "../TabContainer";
import Typography from "@mui/material/Typography";

import Bio from "./Bio";
import Specialties from "./Specialties";

import {
  isLoaded,
  userClinician,
  clinicianLoading,
  clinicianSpecialties,
  specialtiesLoading,
  completedQuestionnaire,
  displayClinician,
  displaySpecialties,
  clinicianReferred,
  isLegacyUser,
  getUserId,
  getClientId,
} from "../../selectors";

const mapStateToProps = (state) => ({
  loaded: isLoaded(state),
  clinicianLoading: clinicianLoading(state),
  specialtiesLoading: specialtiesLoading(state),
  displayClinician: displayClinician(state),
  displaySpecialties: displaySpecialties(state),
  completedQuestionnaire: completedQuestionnaire(state),
  clinicianSpecialties: clinicianSpecialties(state),
  clinician: userClinician(state),
  clinicianReferred: clinicianReferred(state),
  legacy: isLegacyUser(state),
  userId: getUserId(state),
  clientId: getClientId(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getClinician: actions.getUserClinician,
      selectClinician: actions.selectClinician,
    },
    dispatch,
  );

function getInitialState() {
  return {
    currentTab: 0,
  };
}

class Clinician extends Component {
  state = {
    ...getInitialState(),
  };

  componentDidMount() {
    if (this.props.loaded && this.props.clientId) {
      this.props.getClinician(this.props.clientId);
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.loaded && this.props.loaded && this.props.clientId) {
      this.props.getClinician(this.props.userId);
    }
  }

  handleTabChange = (event, newValue) => {
    this.setState({ currentTab: newValue });
  };

  navToGetMatched = () => {
    this.props.selectClinician();
    this.props.history.push("/questionnaire");
  };

  render() {
    const {
      clinicianLoading,
      classes,
      completedQuestionnaire,
      groupChat,
      displayClinician,
      displaySpecialties,
      clinicianReferred,
      legacy,
    } = this.props;
    const { currentTab } = this.state;
    const clinician = displayClinician.id ? displayClinician : this.props.clinician;
    const specialties =
      displaySpecialties.length > 0 ? displaySpecialties : this.props.clinicianSpecialties;
    return (
      <React.Fragment>
        {!clinicianLoading && clinician && (
          <React.Fragment>
            <Avatar
              alt={`${clinician.first_name} ${clinician.last_name}`}
              src={clinician.image_url}
              className={classes.cardAvatar}
            />
            <Typography
              component="h1"
              variant="h3"
              align="center"
            >{`${clinician.first_name} ${clinician.last_name}`}</Typography>
            <Typography component="p" style={{ fontSize: 16 }} align="center">
              Board Certified Clinician
            </Typography>
            <AppBar position="static" color="default" className={classes.selectionBar}>
              <Tabs
                value={currentTab}
                onChange={this.handleTabChange}
                classes={{
                  root: classes.tabBar,
                  indicator: classes.indicatorColor,
                }}
                variant="fullWidth"
              >
                <Tab
                  className={classes.tabButton}
                  classes={{
                    selected: classes.activeTab,
                    wrapper: classes.planTabHeader,
                  }}
                  label={"Bio"}
                />
                <Tab
                  className={classes.tabButton}
                  classes={{
                    selected: classes.activeTab,
                    wrapper: classes.planTabHeader,
                  }}
                  label={"Specialties"}
                />
              </Tabs>
            </AppBar>
          </React.Fragment>
        )}
        {currentTab === 0 && (
          <TabContainer>
            <Bio {...this.props} {...this.state} clinician={clinician} specialties={specialties} />
          </TabContainer>
        )}
        {currentTab === 1 && (
          <TabContainer>
            <Specialties
              {...this.props}
              {...this.state}
              clinician={clinician}
              specialties={specialties}
            />
          </TabContainer>
        )}
        {!completedQuestionnaire &&
          !clinicianLoading &&
          clinician &&
          !clinicianReferred &&
          legacy && (
            <div onClick={this.navToGetMatched} className={classes.signupButton}>
              <Typography component="p">Sign up with {clinician.first_name}</Typography>
            </div>
          )}
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Clinician));
