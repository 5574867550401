import SecureRequest from "../utils/securerequest";

export function* getAppointmentChangeLogs({ clientId }) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/appointment-change-log/?clientId=${clientId}`);
}
export async function getAppointmentChangeLogsQuery({ clientId }) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.get(`/appointment-change-log/?clientId=${clientId}`);
}
