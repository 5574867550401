import React, { useState } from "react";
import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import { dateWithoutTimezone } from "../../utils/dateWithoutTimezone";
import { format, isToday } from "date-fns";
import Chip from "@mui/material/Chip";
import CancelIcon from "@mui/icons-material/Cancel";
import { getAge } from "../../utils/getAge";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { convertTokens } from "@date-fns/upgrade/v2";
import { formatTimeZoneToAbbreviation } from "utils/formatTimeZoneToAbbreviation";

const Badge = withStyles({
  root: {
    backgroundColor: "#287979",
    color: "#F0F1F4",
    fontWeight: 500,
    height: 35,
    borderRadius: 5,
    border: "1px solid #1A5151",
    marginTop: 10,
    "& .MuiChip-icon": {
      color: "#F0F1F4",
      height: 15,
      width: 15,
    },
    "& .MuiChip-label": {
      fontWeight: 500,
      color: "#F0F1F4",
      fontSize: 10,
      letterSpacing: "0.34px",
      marginBottom: 2,
    },
  },
  colorSecondary: {
    backgroundColor: "#fee2e2",
    "& .MuiChip-icon": {
      color: "#DC2626",
      height: 15,
      width: 15,
    },
    "& .MuiChip-label": {
      fontWeight: 500,
      color: "#DC2626",
      fontSize: 10,
      letterSpacing: "0.34px",
      marginBottom: 2,
    },
  },
})(Chip);

const BasicInformation = (props) => {
  const [accordionExpanded, setAccordionExpanded] = useState(false);
  const mapWorkflowStatusToStatus = (status) => {
    switch (status) {
      case "AWAITING_ONBOARDING":
        return "Request Received";
      case "AWAITING_ELIGIBILITY":
        return "Orientation Complete";
      case "ELIGIBLE":
      case "ELIGIBLE_NOW":
        return "Assessment Approval";
      case "ASSESSMENT_SCHEDULED":
      case "ASSESSMENT_SCHEDULED_NOW":
        return "Assessment";
      case "ASSESSMENT_COMPLETED":
      case "APPROVE_PLAN":
        return "Treatment Plan";
      case "AWAITING_INSURANCE_APPROVAL":
        return "Insurance Approval";
      case "INSURANCE_APPROVED":
      case "FULL_CUSTOMER":
        return "In Therapy";
    }
  };
  const { classes, customerDetails, formatPhoneNumber, userInsurance } = props;
  let clientInfo = customerDetails?.children;
  let customerInfo = customerDetails?.details;
  let clientClinician = customerDetails?.clinician;
  let primaryInsurance = userInsurance?.find((insurance) => insurance.is_primary === true);

  const mapRelationshipToLabel = (relationship) => {
    switch (relationship) {
      case "is_parent":
        return "Parent";
      case "legal_guardian":
        return "Legal Guardian";
      case "caregiver":
        return "Caregiver";
      case "family_member":
        return "Family Member";
      case "spouse":
        return "Spouse";
      case "dependent":
        return "Dependent";
      case "child":
        return "Child";
      default:
        return "Caregiver";
    }
  };
  return !customerInfo.is_active ? null : (
    <div className={classes.basicInfoContainer}>
      <Accordion
        elevation={0}
        onChange={() => {
          setAccordionExpanded(!accordionExpanded);
        }}
        className={classes.accordion}
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary
          aria-controls="block-content"
          className={classes.accordionSummary}
          expandIcon={
            accordionExpanded ? (
              <ExpandLessIcon className={classes.expandIcon} />
            ) : (
              <ExpandMoreIcon className={classes.expandIcon} />
            )
          }
        >
          <Grid
            container
            item
            xs={12}
            spacing={4}
            alignItems="center"
            classes={{ root: classes.gridRoot }}
          >
            <Grid item xs={12} sm={4}>
              <Typography
                style={{
                  fontSize: 16,
                  fontWeight: 500,
                  color: "#15171D",
                  userSelect: "text",
                }}
              >{`${customerInfo?.name} ${customerInfo?.child_last_name}`}</Typography>
              <Typography style={{ fontSize: 13, fontWeight: 500, color: "#3F4456" }}>{`${
                customerInfo.first_name
              } ${customerInfo.last_name} - ${mapRelationshipToLabel(
                customerInfo.relationship_to_client,
              )}`}</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography
                style={{
                  fontSize: 12,
                  fontWeight: 500,
                  color: "#3F4456",
                  marginBottom: 0,
                }}
              >
                Assigned to:{" "}
                <span style={{ fontSize: 14, fontWeight: 500, color: "#15171D" }}>{`${
                  clientClinician?.first_name || "--"
                } ${clientClinician?.last_name || "--"}`}</span>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              {customerInfo.verified ? (
                <Badge
                  size="small"
                  icon={<AccessTimeIcon style={{ fill: "#F0F1F4", marginBottom: 2 }} />}
                  style={{ marginTop: 5 }}
                  label={`Last Active: ${format(
                    new Date(customerInfo?.last_activity),
                    convertTokens(
                      isToday(new Date(customerInfo?.last_activity)) ? "h:mm a" : "MMM D h:mm a",
                    ),
                  )}`}
                />
              ) : (
                <Badge
                  size="small"
                  icon={<CancelIcon style={{ fill: "#EF4444", marginBottom: 2 }} />}
                  color="secondary"
                  style={{
                    color: "#EF4444",
                    border: "1px solid #FFD2D2",
                    marginTop: 5,
                  }}
                  label="Hasn't Logged In"
                />
              )}
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
          <div className={classes.accordionDivider} />

          <Grid
            container
            item
            xs={12}
            spacing={2}
            alignItems="center"
            // classes={{ root: classes.gridRoot }}
          >
            <Grid item xs={12} md={4}>
              <Typography className={classes.accordionLabel}>Date of Birth</Typography>
              <Typography className={classes.accordionValue}>{`${
                customerInfo?.dob
                  ? format(dateWithoutTimezone(customerInfo?.dob), convertTokens("Do MMMM YYYY")) +
                    " - " +
                    getAge(dateWithoutTimezone(customerInfo?.dob)) +
                    " years old"
                  : "----"
              } `}</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography className={classes.accordionLabel}>Phone</Typography>
              <Typography className={classes.accordionValue}>
                {customerInfo?.phone_number
                  ? formatPhoneNumber(customerInfo?.phone_number)
                  : "----"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography className={classes.accordionLabel}>Last Session</Typography>
              <Typography className={classes.accordionValue}>
                {customerInfo?.last_session_date
                  ? format(
                      new Date(customerInfo.last_session_date),
                      convertTokens("Do MMMM YYYY   h:mm a"),
                    )
                  : "----"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography className={classes.accordionLabel}>Time Zone</Typography>
              <Typography className={classes.accordionValue}>
                {formatTimeZoneToAbbreviation(customerInfo?.timezone) || "----"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={5}>
              <Typography className={classes.accordionLabel}>Family Contact Email</Typography>
              <Typography className={classes.accordionValue}>{customerInfo?.email}</Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography className={classes.accordionLabel}>Prior Authorization</Typography>
              <Typography className={classes.accordionValue}>
                {customerInfo?.pre_auth_required ? "Yes" : "No"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography className={classes.accordionLabel}>Is Medicaid</Typography>
              <Typography className={classes.accordionValue}>
                {primaryInsurance?.is_medicaid ? "Yes" : "No"}
              </Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default BasicInformation;
