import React from "react";
import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const EditActions = (props) => {
  const {
    needsEdits,
    noteCompleted,
    onSaveAsDraft,
    callBreakdownLoading,
    onOpenConfirmSubmitDialog,
    confirmExitDialogOpen,
    videoId,
    associatedSessionNotes,
    currentIndex,
    submitClicked,
    associatedTimesDraftSaving,
    associatedTimesSubmitSaving,
    locked,
    onToggleConfirmDeleteDialog,
    createNew,
  } = props;
  const currentBillingItem = associatedSessionNotes[currentIndex];
  const displayActions =
    (currentBillingItem?.description && currentBillingItem?.actual_time >= 0) || !videoId;
  const submitLoading =
    associatedTimesSubmitSaving ||
    submitClicked ||
    (callBreakdownLoading && !confirmExitDialogOpen);

  return displayActions ? (
    <>
      {!noteCompleted && !needsEdits && (
        <>
          {!videoId && !createNew && (
            <Button variant="text" color="error" onClick={onToggleConfirmDeleteDialog}>
              Delete Service
            </Button>
          )}
          <LoadingButton
            color="secondary"
            style={{ marginRight: 10 }}
            onClick={onSaveAsDraft}
            disabled={associatedTimesSubmitSaving || associatedTimesDraftSaving || submitLoading}
            loading={associatedTimesDraftSaving}
          >
            Save as Draft
          </LoadingButton>
        </>
      )}
      {!locked && (
        <LoadingButton
          onClick={onOpenConfirmSubmitDialog}
          disabled={submitLoading || associatedTimesDraftSaving || associatedTimesSubmitSaving}
          loading={submitLoading || associatedTimesSubmitSaving}
        >
          Submit Service
        </LoadingButton>
      )}
    </>
  ) : null;
};

export default EditActions;
