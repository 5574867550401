import React, { Component } from "react";
import CardActivity from "./CardActivity";
import VideoActivity from "./VideoActivity";
import PromptActivity from "./PromptActivity";

class ActivityComponent extends Component {
  renderActivity = () => {
    const { activity } = this.props;
    switch (activity.type) {
      case "card":
        return <CardActivity {...this.props} />;
      case "video":
        return <VideoActivity {...this.props} />;
      case "prompt":
        return <PromptActivity {...this.props} />;
      default:
        return null;
    }
  };

  render() {
    const {
      roomLayout,
      classes,
      activity,
      animation,
      cardIndex,
      cards,
      showCard,
      cardActivityStarted,
      markCardResponse,
      promptNextCard,
      currentCard,
      videoActivityOpen,
      isCaregiver,
      clinicianJoined,
    } = this.props;
    return (
      <div
        className={
          isCaregiver
            ? roomLayout === "activity_focus" && clinicianJoined
              ? classes.clientActivityContainer
              : roomLayout === "activity_split" && clinicianJoined
                ? classes.clientActivityVideoSplit
                : classes.activityVideoFocus
            : roomLayout === "activity_focus"
              ? classes.activityContainer
              : roomLayout === "activity_split"
                ? classes.activityVideoSplit
                : classes.activityVideoFocus
        }
      >
        {activity.active && this.renderActivity()}
        {animation.active && animation.type === "video" && (
          <VideoActivity videoURL={animation.asset_url} {...this.props} />
        )}
      </div>
    );
  }
}

export default ActivityComponent;
