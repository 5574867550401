import React, { useCallback, useEffect, useState } from "react";
import { Calendar, Views, dateFnsLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
// date-fns is used to set the locale for the react-big-calendar.
import { enUS, enGB, es } from "date-fns/locale";
import { addMinutes, differenceInMinutes, format, getDay, isPast, startOfWeek } from "date-fns";

import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";

import ANCalendarEvent from "./ANCalendarEvent";
/** @typedef {import("api/typesDef").CalendarEvent} CalendarEvent */

const localizer = dateFnsLocalizer({ format, getDay, startOfWeek, locales: { enUS, enGB, es } });

// Create a new calendar component with drag and drop functionality.
const DragAndDropCalendar = withDragAndDrop(Calendar);

const MIN_DURATION_EVENT = 30;
const MAX_DURATION_EVENT = 180;

/**
 * The `ANCalendar` component renders a calendar view of events.
 * It provides the ability to drag and drop events to reschedule them.
 * It also provides the ability to resize events to change their duration.
 * It can be used to display clinician schedules, client appointments, and more.
 *
 * @component
 * @param {Object} props - The component props
 * @param {Object} props.components - Custom components to render in the calendar
 * @param {string} props.view - The view type for the calendar
 * @param {Object} props.colorIndexManager - The color index manager for the calendar
 * @param {Array} [props.nonWorkingHours] - The non-working hours for the calendar
 * @param {boolean} [props.dragAndDrop=false] - Whether to enable drag and drop functionality
 * @param {Array<CalendarEvent>} props.events - The events to display on the calendar
 * @param {Function} props.onChangeEvent - Function to call when an event is changed
 * @param {Function} [props.draggableAccessor] - Function to determine if an event is draggable
 * @param {boolean} props.reinitialize - Whether to reinitialize the calendar events (only use when the Drag and Drop is enabled)
 * @returns {React.ReactElement} The rendered component
 */
export default function ANCalendar({
  components,
  view,
  colorIndexManager,
  nonWorkingHours = [],
  dragAndDrop = false,
  events,
  onChangeEvent = () => {},
  draggableAccessor,
  reinitialize = true,
  ...props
}) {
  // Events state for the calendar with drag and drop functionality.
  const [calendarEvents, setCalendarEvents] = useState([]);

  const formats = {
    dayFormat: (date, culture, localizer) => localizer.format(date, "eee d", culture),
    dayRangeHeaderFormat: ({ end }, culture, localizer) =>
      localizer.format(end, "MMM yyyy", culture),
    timeGutterFormat: "h a",
  };

  useEffect(() => {
    if (dragAndDrop && events.length && reinitialize) {
      setCalendarEvents(events);
    }
  }, [dragAndDrop, events, reinitialize]);

  const CustomEvent = ({ event }) => <ANCalendarEvent event={event} view={view} />;

  const eventStyle = useCallback(
    (e) => {
      const isExpired = isPast(e.end);
      // If a client is selected, use its color, otherwise use the clinician's color.
      const colorSelectedClient = colorIndexManager?.find(e.client_id);
      const eventColorIndex =
        colorSelectedClient ?? colorIndexManager?.get(e.clinician_id) ?? "none";

      // If the event is a building event, add the building class to the event.
      const buildingEvent = e?.call_type === "BUILDING" ? " building" : "";

      // Define padding based on the call duration and view.
      let padding = "8px 5px 6px";

      if (e.call_duration <= 30) {
        padding = "8px 5px 0px";
      }

      if (e.call_duration === 30 && e.start.getMinutes() === 30) {
        padding = "0px 5px 6px";
      }

      if (view === Views.MONTH) {
        padding = "2px 8px";
      }

      return {
        style: {
          padding,
        },
        className: `event-color-${eventColorIndex}${buildingEvent} ${
          isExpired ? "expired" : ""
        } ${e.canceled || e.no_show ? " red-text" : ""}${e.googleEvent ? " private" : ""}${e.is_tentative ? " tentative" : ""}`,
      };
    },
    [colorIndexManager, view]
  );

  const slotPropGetter = (date) => {
    for (let hours of nonWorkingHours) {
      let start = new Date(hours.startDateTime);
      let end = new Date(hours.endDateTime);
      if (date >= start && date < end) return { className: "non-working-hours" };
    }
  };

  // Move event callback function for the calendar with drag and drop functionality.
  const moveEvent = ({ event, start, end }) => {
    // If the event is in the past, do not allow it to be moved.
    if (isPast(start)) {
      return;
    }

    let existing = calendarEvents.find((ev) => ev.id === event.id) ?? {};

    setCalendarEvents((prev) => {
      const filtered = prev.filter((ev) => ev.id !== event.id);
      return [...filtered, { ...existing, start, end }];
    });
    onChangeEvent({ ...existing, start, end });
  };

  // Resize event callback function for the calendar with drag and drop functionality.
  const resizeEvent = ({ event, start, end }) => {
    // If the event is in the past, do not allow it to be moved.
    if (isPast(start)) {
      return;
    }

    const existing = calendarEvents.find((ev) => ev.id === event.id) ?? {};

    let newEnd = end;
    // Calculate the call duration and set the end time minimum to 30 minutes and max to 3 hours.
    let call_duration = differenceInMinutes(end, start);

    if (call_duration < MIN_DURATION_EVENT) {
      call_duration = MIN_DURATION_EVENT;
      newEnd = addMinutes(start, MIN_DURATION_EVENT);
    }

    if (call_duration > MAX_DURATION_EVENT) {
      call_duration = MAX_DURATION_EVENT;
      newEnd = addMinutes(start, MAX_DURATION_EVENT);
    }

    setCalendarEvents((prev) => {
      const filtered = prev.filter((ev) => ev.id !== event.id);
      return [...filtered, { ...existing, call_duration, start, end: newEnd }];
    });
    onChangeEvent({ ...existing, call_duration, start, end: newEnd });
  };

  if (dragAndDrop) {
    return (
      <DragAndDropCalendar
        localizer={localizer}
        formats={formats}
        components={{
          event: CustomEvent,
          ...components,
        }}
        view={view}
        eventPropGetter={eventStyle}
        dayLayoutAlgorithm="no-overlap"
        slotPropGetter={slotPropGetter}
        startAccessor="start"
        endAccessor="end"
        timeslots={4}
        step={15}
        events={calendarEvents}
        onEventDrop={moveEvent}
        draggableAccessor={draggableAccessor}
        onEventResize={resizeEvent}
        resizableAccessor={draggableAccessor}
        resizable
        {...props}
      />
    );
  }

  return (
    <Calendar
      localizer={localizer}
      formats={formats}
      components={{
        event: CustomEvent,
        ...components,
      }}
      view={view}
      eventPropGetter={eventStyle}
      dayLayoutAlgorithm="no-overlap"
      slotPropGetter={slotPropGetter}
      startAccessor="start"
      endAccessor="end"
      timeslots={1}
      step={60}
      events={events}
      {...props}
    >
      ANCalendar
    </Calendar>
  );
}
