const ACTIONS = {
  GET_CLINICIANS_LIST: "GET_CLINICIANS_LIST",
  GET_ALL_CLINICIANS: "GET_ALL_CLINICIANS",
  LOAD_CLINICIANS_LIST: "LOAD_CLINICIANS_LIST",
  LOAD_CLINICIANS_LIST_FAIL: "LOAD_CLINICIANS_LIST_FAIL",
  SET_CLINICIANS_LIST: "SET_CLINICIANS_LIST",
  CLEAR_CLINICIANS_LIST: "CLEAR_CLINICIANS_LIST",
  GET_DEACTIVATED_CLINICIANS: "GET_DEACTIVATED_CLINICIANS",
  GET_DEACTIVATED_CLINICIANS_SUCCESS: "GET_DEACTIVATED_CLINICIANS_SUCCESS",
  GET_DEACTIVATED_CLINICIANS_FAIL: "GET_DEACTIVATED_CLINICIANS_FAIL",

  GET_CLINICIAN_DETAILS: "GET_CLINICIAN_DETAILS",
  LOAD_CLINICIAN_DETAILS: "LOAD_CLINICIAN_DETAILS",
  SET_CLINICIAN_DETAILS: "SET_CLINICIAN_DETAILS",
  EDIT_CLINICIAN_DETAILS: "EDIT_CLINICIAN_DETAILS",

  CREATE_CLINICIAN: "CREATE_CLINICIAN",

  SET_CLINICIANS_PAGE: "SET_CLINICIANS_PAGE",
  SET_CLINICIANS_PER_PAGE: "SET_CLINICIANS_PER_PAGE",
  GET_CLINICIANS_PAGE: "GET_CLINICIANS_PAGE",
  GET_CLINICIANS_PER_PAGE: "GET_CLINICIANS_PER_PAGE",
  GET_CLINICIANS_COUNT: "GET_CLINICIANS_COUNT",
  SET_CLINICIANS_SORT: "SET_CLINICIANS_SORT",
  SET_CLINICIANS_STATUS: "SET_CLINICIANS_STATUS",

  UPDATE_CLINICIAN_BASIC_DETAILS: "UPDATE_CLINICIAN_BASIC_DETAILS",
  UPDATE_CLINICIAN_BASIC_DETAILS_SUCCESS: "UPDATE_CLINICIAN_BASIC_DETAILS_SUCCESS",
  UPDATE_CLINICIAN_IMAGE: "UPDATE_CLINICIAN_IMAGE",
  UPDATE_CLINICIAN_ADDRESS: "UPDATE_CLINICIAN_ADDRESS",
  UPDATE_CLINICIAN_ADDRESS_SUCCESS: "UPDATE_CLINICIAN_ADDRESS_SUCCESS",
  ADD_CLINICIAN_PHONE: "ADD_CLINICIAN_PHONE",
  UPDATE_CLINICIAN_NPI: "UPDATE_CLINICIAN_NPI",
  UPDATE_CLINICIAN_NPI_SUCCESS: "UPDATE_CLINICIAN_NPI_SUCCESS",
  ADD_CLINICIAN_LICENSE: "ADD_CLINICIAN_LICENSE",
  ADD_CLINICIAN_CERTIFICATE: "ADD_CLINICIAN_CERTIFICATE",
  SET_SAVE_ERROR: "SET_SAVE_ERROR",
  CLEAR_SAVE_ERROR: "CLEAR_SAVE_ERROR",

  CREATE_SUPPORT_CHAT: "CREATE_SUPPORT_CHAT",
  CREATE_SUPPORT_CHAT_SUCCESS: "CREATE_SUPPORT_CHAT_SUCCESS",

  GET_CLINICIAN: "GET_CLINICIAN",
  GET_CLINICIAN_SUCCESS: "GET_CLINICIAN_SUCCESS",

  TOGGLE_CLINICIAN_ACTIVATION: "TOGGLE_CLINICIAN_ACTIVATION",
  TOGGLE_CLINICIAN_ACTIVATION_SUCCESS: "TOGGLE_CLINICIAN_ACTIVATION_SUCCESS",

  GET_CLINICIAN_DEACTIVATION_REASONS: "GET_CLINICIAN_DEACTIVATION_REASONS",
  GET_CLINICIAN_DEACTIVATION_REASONS_SUCCESS: "GET_CLINICIAN_DEACTIVATION_REASONS_SUCCESS",

  GET_ASSIGNABLE_CLINICIANS: "GET_ASSIGNABLE_CLINICIANS",
  GET_ASSIGNABLE_CLINICIANS_SUCCESS: "GET_ASSIGNABLE_CLINICIANS_SUCCESS",
};

const ACTION_CREATORS = {
  getCliniciansList() {
    return {
      type: ACTIONS.GET_CLINICIANS_LIST,
    };
  },
  getAllClinicians() {
    return {
      type: ACTIONS.GET_ALL_CLINICIANS,
    };
  },
  loadCliniciansList() {
    return {
      type: ACTIONS.LOAD_CLINICIANS_LIST,
    };
  },
  loadCliniciansListFail() {
    return {
      type: ACTIONS.LOAD_CLINICIANS_LIST_FAIL,
    };
  },
  setCliniciansList(clinicians, count) {
    return {
      type: ACTIONS.SET_CLINICIANS_LIST,
      payload: {
        clinicians,
        count,
      },
    };
  },
  clearCliniciansList() {
    return {
      type: ACTIONS.CLEAR_CLINICIANS_LIST,
    };
  },
  getDeactivatedClinicians() {
    return {
      type: ACTIONS.GET_DEACTIVATED_CLINICIANS,
    };
  },
  getDeactivatedCliniciansSuccess(payload) {
    return {
      type: ACTIONS.GET_DEACTIVATED_CLINICIANS_SUCCESS,
      payload,
    };
  },
  getDeactivatedCliniciansFail() {
    return {
      type: ACTIONS.GET_DEACTIVATED_CLINICIANS_FAIL,
    };
  },
  createClinician(payload) {
    return {
      type: ACTIONS.CREATE_CLINICIAN,
      payload,
    };
  },
  setCliniciansPage(page) {
    return {
      type: ACTIONS.SET_CLINICIANS_PAGE,
      payload: {
        page,
      },
    };
  },
  setCliniciansPerPage(rowsPerPage) {
    return {
      type: ACTIONS.SET_CLINICIANS_PER_PAGE,
      payload: {
        rowsPerPage,
      },
    };
  },
  getCliniciansPage() {
    return {
      type: ACTIONS.GET_CLINICIANS_PAGE,
    };
  },
  getCliniciansPerPage() {
    return {
      type: ACTIONS.GET_CLINICIANS_PER_PAGE,
    };
  },
  getCliniciansCount() {
    return {
      type: ACTIONS.GET_CLINICIANS_COUNT,
    };
  },
  setCliniciansSort(payload) {
    return {
      type: ACTIONS.SET_CLINICIANS_SORT,
      payload,
    };
  },
  setCliniciansStatus(payload) {
    return {
      type: ACTIONS.SET_CLINICIANS_STATUS,
      payload,
    };
  },
  getClinicianDetails() {
    return {
      type: ACTIONS.GET_CLINICIAN_DETAILS,
    };
  },
  loadClinicianDetails(clinicianId) {
    return {
      type: ACTIONS.LOAD_CLINICIAN_DETAILS,
      payload: {
        clinicianId,
      },
    };
  },
  setClinicianDetails(payload) {
    return {
      type: ACTIONS.SET_CLINICIAN_DETAILS,
      payload,
    };
  },
  editClinicianDetails(payload) {
    return {
      type: ACTIONS.EDIT_CLINICIAN_DETAILS,
      payload,
    };
  },
  updateBasicClinicianDetails(payload) {
    return {
      type: ACTIONS.UPDATE_CLINICIAN_BASIC_DETAILS,
      payload,
    };
  },
  updateBasicClinicianDetailsSuccess(payload) {
    return {
      type: ACTIONS.UPDATE_CLINICIAN_BASIC_DETAILS_SUCCESS,
      payload,
    };
  },
  updateClinicianImage(payload) {
    return {
      type: ACTIONS.UPDATE_CLINICIAN_IMAGE,
      payload,
    };
  },
  updateClinicianAddress(payload) {
    return {
      type: ACTIONS.UPDATE_CLINICIAN_ADDRESS,
      payload,
    };
  },
  updateClinicianAddressSuccess(payload) {
    return {
      type: ACTIONS.UPDATE_CLINICIAN_ADDRESS_SUCCESS,
      payload,
    };
  },
  addClinicianPhone(payload) {
    return {
      type: ACTIONS.ADD_CLINICIAN_PHONE,
      payload,
    };
  },
  updateClinicianNPI(payload) {
    return {
      type: ACTIONS.UPDATE_CLINICIAN_NPI,
      payload,
    };
  },
  updateClinicianNPISuccess(payload) {
    return {
      type: ACTIONS.UPDATE_CLINICIAN_NPI_SUCCESS,
      payload,
    };
  },
  addClinicianLicense(payload) {
    return {
      type: ACTIONS.ADD_CLINICIAN_LICENSE,
      payload,
    };
  },
  addClinicianCertificate(payload) {
    return {
      type: ACTIONS.ADD_CLINICIAN_CERTIFICATE,
      payload,
    };
  },
  setSaveError() {
    return { type: ACTIONS.SET_SAVE_ERROR };
  },
  clearSaveError() {
    return { type: ACTIONS.CLEAR_SAVE_ERROR };
  },
  createSupportChat(clinician) {
    return {
      type: ACTIONS.CREATE_SUPPORT_CHAT,
      payload: clinician,
    };
  },
  createSupportChatSuccess(channel) {
    return {
      type: ACTIONS.CREATE_SUPPORT_CHAT_SUCCESS,
      payload: channel,
    };
  },
  getClinician(clinicianUserId) {
    return {
      type: ACTIONS.GET_CLINICIAN,
      payload: clinicianUserId,
    };
  },
  getClinicianSuccess(details) {
    return {
      type: ACTIONS.GET_CLINICIAN_SUCCESS,
      payload: details,
    };
  },
  toggleClinicianActivation(payload) {
    return {
      type: ACTIONS.TOGGLE_CLINICIAN_ACTIVATION,
      payload,
    };
  },
  toggleClinicianActivationSuccess() {
    return {
      type: ACTIONS.TOGGLE_CLINICIAN_ACTIVATION_SUCCESS,
    };
  },
  getClinicianDeactivationReasons() {
    return {
      type: ACTIONS.GET_CLINICIAN_DEACTIVATION_REASONS,
    };
  },
  getClinicianDeactivationReasonsSuccess(payload) {
    return {
      type: ACTIONS.GET_CLINICIAN_DEACTIVATION_REASONS_SUCCESS,
      payload,
    };
  },
  getAssignableClinicians(secondary) {
    return {
      type: ACTIONS.GET_ASSIGNABLE_CLINICIANS,
      payload: {
        secondary,
      },
    };
  },
  getAssignableCliniciansSuccess(payload) {
    return {
      type: ACTIONS.GET_ASSIGNABLE_CLINICIANS_SUCCESS,
      payload,
    };
  },
};

export default { ...ACTIONS, ...ACTION_CREATORS };
