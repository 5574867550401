import { blue, blueGrey, cyan, green, indigo, orange, pink, teal } from "@mui/material/colors";

export const COLOR_CHIPS = [
  { color: "#8E42CA", backgroundColor: "#F4ECFB" },
  { color: orange[900], backgroundColor: orange[100] },
  { color: blue[900], backgroundColor: blue[100] },
  { color: green[900], backgroundColor: green[100] },
  { color: blueGrey[900], backgroundColor: blueGrey[100] },
  { color: indigo[900], backgroundColor: indigo[100] },
  { color: cyan[900], backgroundColor: cyan[100] },
  { color: pink[900], backgroundColor: pink[100] },
  { color: teal[900], backgroundColor: teal[100] },
];
