import { useQuery } from "react-query";
import { getCustomerSecureDocument } from "../api/customers";

export const useGetSecureDocument = ({ documentId, role = false, documentType }) => {
  return useQuery(
    ["secureDocument", documentId],
    () => getCustomerSecureDocument(documentId, role, documentType),
    {
      refetchOnWindowFocus: false,
    }
  );
};
