const ACTIONS = {
  GET_USER_EMAIL_LOG: "GET_USER_EMAIL_LOG",
  GET_USER_EMAIL_LOG_SUCCESS: "GET_USER_EMAIL_LOG_SUCCESS",
  SEND_SMS_TO_CAREGIVER: "SEND_SMS_TO_CAREGIVER",
  SEND_SMS_TO_CAREGIVER_SUCCESS: "SEND_SMS_TO_CAREGIVER_SUCCESS",
  SEND_SMS_TO_CAREGIVER_FAIL: "SEND_SMS_TO_CAREGIVER_FAIL",
  GET_SMS_THREAD_BY_CLIENT: "GET_SMS_THREAD_BY_CLIENT",
  GET_SMS_THREAD_BY_CLIENT_SUCCESS: "GET_SMS_THREAD_BY_CLIENT_SUCCESS",
  CLEAR_SMS_THREAD: "CLEAR_SMS_THREAD",
};

const ACTION_CREATORS = {
  getUserEmailLog(email) {
    return {
      type: ACTIONS.GET_USER_EMAIL_LOG,
      payload: email,
    };
  },
  getUserEmailLogSuccess(emailList) {
    return {
      type: ACTIONS.GET_USER_EMAIL_LOG_SUCCESS,
      payload: emailList,
    };
  },
  sendSMSToCaregiver(payload) {
    return {
      type: ACTIONS.SEND_SMS_TO_CAREGIVER,
      payload,
    };
  },
  sendSMSToCaregiverSuccess() {
    return {
      type: ACTIONS.SEND_SMS_TO_CAREGIVER_SUCCESS,
    };
  },
  sendSMSToCaregiverFail() {
    return {
      type: ACTIONS.SEND_SMS_TO_CAREGIVER_FAIL,
    };
  },
  getSMSThreadByClient(payload) {
    return {
      type: ACTIONS.GET_SMS_THREAD_BY_CLIENT,
      payload,
    };
  },
  getSMSThreadByClientSuccess(payload) {
    return {
      type: ACTIONS.GET_SMS_THREAD_BY_CLIENT_SUCCESS,
      payload,
    };
  },
  clearSMSThread() {
    return {
      type: ACTIONS.CLEAR_SMS_THREAD,
    };
  },
};

export default { ...ACTIONS, ...ACTION_CREATORS };
