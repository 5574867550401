import { useClientDetailsForm } from "../../ClientDetailsFormContext";
import { useGlobalToast } from "../../../GlobalToastProvider";
import { useMutation } from "react-query";
import { postCreateUserInsurance, postUpdateUserInsurance } from "../../../../api/insurance";

const useInsuranceSubmit = ({ userId, getUserInsurance, clientId }) => {
  const createUserInsuranceMutation = useMutation({
    mutationFn: postCreateUserInsurance,
  });
  const updateUserInsuranceMutation = useMutation({
    mutationFn: postUpdateUserInsurance,
  });

  const { showToast } = useGlobalToast();
  const { onSubmitSuccess, onSubmitError } = useClientDetailsForm();

  const saveInsurance = async (values) => {
    const insuranceData = {
      insuranceId: values.insuranceid || null,
      memberId: values.memberId,
      groupNumber: values.groupNumber,
      effectiveDate: values.effectiveDate || null,
      expirationDate: values.expirationDate || null,
      isPrimary: values.isPrimary,
      insuranceUserId: values.id,
      userId: values.userId,
      clientId: values.clientId,
      insuranceHolderDOB: values.insuranceHolderDOB,
      insuranceHolderRelationship: values.insurance_holder_relationship,
      insurancePlanId: values.insurancePlanId,
      insuranceHolderName: values.insuranceHolderName,
      isMedicaid: values.isMedicaid,
    };

    const mutation = values.id ? updateUserInsuranceMutation : createUserInsuranceMutation;

    return mutation.mutateAsync(insuranceData);
  };

  const submitForms = async (formValuesArray) => {
    try {
      await Promise.all(
        formValuesArray.map((values) => {
          return saveInsurance({ ...values, insuranceUserId: userId, clientId: clientId });
        })
      );
      showToast({ message: "Success!" });
      onSubmitSuccess();
      getUserInsurance(clientId);
    } catch (e) {
      console.error(e);
      showToast({
        message: "Whoops! Something went wrong",
        errorState: true,
      });
      onSubmitError();
    }
  };
  return { submitForms };
};

export default useInsuranceSubmit;
