import { CircularProgress, Stack, Typography } from "@mui/material";
import React from "react";

export default function ANLoadingScreen(props) {
  return (
    <Stack alignItems="center" height="100vh" justifyContent="center" {...props}>
      <CircularProgress size={30} sx={{ mb: "10px" }} />
      <Typography variant="h3" fontWeight={400}>
        Loading
      </Typography>
    </Stack>
  );
}
