import React from "react";
import { useFormik } from "formik";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  TextField,
  Grid,
  Box,
} from "@mui/material";
import { X } from "@phosphor-icons/react";
import LoadingButton from "@mui/lab/LoadingButton";

import ANUploader from "elements/ANUploader";
import ANDatePicker from "elements/ANDatePicker";
import { STATES } from "constants";
import { licenseValidationSchema } from "./validationSchema";
import { getDate } from "./utils";
/** @typedef {import("api/typesDef").License} License  */

/**
 * The `StateLicenseDialog` function is a React component that displays a dialog for adding or editing
 * State Licenses.
 * @component
 * @param {Object} props - The props of the component.
 * @param {Boolean} props.open - The open state of the dialog.
 * @param {License} props.currentLicense - The current selected license object, for editing.
 * @param {boolean} props.isNewLicense - The boolean state for new license.
 * @param {Function} props.onClose - The function to close the dialog.
 * @param {Function} props.onSubmit - The function to submit the form data to the server.
 * @return {JSX.Element} The `<StateLicenseDialog />` component.
 */
export default function StateLincenseDialog({
  open,
  currentLicense,
  isNewLicense,
  onClose,
  onSubmit,
}) {
  const initialValues = {
    licenseNumber: isNewLicense ? "" : currentLicense?.license_number ?? "",
    stateType: isNewLicense ? "" : currentLicense?.state || "",
    issuedDate: isNewLicense ? null : getDate(currentLicense?.issued_on),
    expirationDate: isNewLicense ? null : getDate(currentLicense?.expiry_date),
    licenseFile: isNewLicense
      ? []
      : [
          {
            alreadyUploaded: true,
            errors: false,
            file: {
              name: currentLicense.display_filename || currentLicense.image_name,
              size: currentLicense.file_size,
            },
          },
        ],
  };

  // Formik Configuration
  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
    touched,
    values,
    setFieldValue,
    dirty,
    isSubmitting,
  } = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: licenseValidationSchema,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const internalHandleFileUpload = (file) => {
    setFieldValue("licenseFile", [
      {
        errors: !!file.fileRejections?.[0],
        file: file.acceptedFiles[0] || file.fileRejections[0],
      },
    ]);
  };

  const handleClose = () => {
    if (!isSubmitting) {
      resetForm();
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm">
      <DialogTitle>
        {isNewLicense ? "Add" : "Edit"} State License
        <IconButton aria-label="close" onClick={handleClose} disabled={isSubmitting}>
          <X />
        </IconButton>
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <Grid container columnSpacing={{ sm: 2, md: 5 }} columns={{ sm: 4, lg: 8 }}>
            <Grid item sm={2} md={4}>
              <TextField
                fullWidth
                label="License Number"
                name="licenseNumber"
                placeholder="0000001"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                size="small"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.licenseNumber}
                error={touched.licenseNumber && !!errors.licenseNumber}
                helperText={touched.licenseNumber && errors.licenseNumber}
              />
            </Grid>

            <Grid item sm={2} md={4}>
              <TextField
                select
                fullWidth
                label="State"
                name="stateType"
                error={touched.stateType && !!errors.stateType}
                helperText={touched.stateType && errors.stateType}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.stateType}
              >
                <MenuItem disabled value={""}>
                  Select
                </MenuItem>
                {Object.entries(STATES).map(([key, value]) => {
                  return (
                    <MenuItem key={key} value={key}>
                      {value}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
          </Grid>

          <Grid container columnSpacing={{ sm: 2, md: 5 }} columns={{ sm: 4, lg: 8 }}>
            <Grid item sm={2} md={4} marginTop={"24px"}>
              <Box display={"flex"} width={"100%"} flexDirection={"column"}>
                <ANDatePicker
                  label="Issued Date"
                  format="MM/dd/yyyy"
                  disableFuture
                  style={{ fontSize: 14 }}
                  name="issuedDate"
                  value={values.issuedDate}
                  onChange={(value) => setFieldValue("issuedDate", value, true)}
                  onBlur={handleBlur}
                  slotProps={{
                    textField: {
                      placeholder: "01/01/2023",
                      error: touched.issuedDate && !!errors.issuedDate,
                      helperText: touched.issuedDate && errors.issuedDate,
                    },
                    popper: { disablePortal: false },
                  }}
                />
              </Box>
            </Grid>

            <Grid item sm={2} md={4} marginTop={"24px"}>
              <Box display={"flex"} width={"100%"} flexDirection={"column"}>
                <ANDatePicker
                  label="Expiration Date"
                  format="MM/dd/yyyy"
                  disablePast={isNewLicense ? true : false}
                  style={{ fontSize: 14 }}
                  name="expirationDate"
                  value={values.expirationDate}
                  onChange={(value) => setFieldValue("expirationDate", value, true)}
                  onBlur={handleBlur}
                  slotProps={{
                    textField: {
                      placeholder: "01/01/2024",
                      error: touched.expirationDate && !!errors.expirationDate,
                      helperText: touched.expirationDate && errors.expirationDate,
                    },
                    popper: { disablePortal: false },
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          <ANUploader
            handleFileUpload={internalHandleFileUpload}
            dropzoneFiles={values.licenseFile}
            useDropzoneOptions={{ multiple: false }}
            handleFileDelete={() => {
              setFieldValue("licenseFile", []);
            }}
            handleLoadedFileDelete={() => {
              setFieldValue("licenseFile", []);
            }}
            error={touched.licenseFile && !!errors.licenseFile}
            errorText={errors.licenseFile}
            dropZoneStyle={{ margin: "24px 0 0" }}
            filePreviewStyle={{ margin: "24px 0 0" }}
            hideDropzone={values.licenseFile.length > 0}
            removeFileButtonProps={{ disabled: isSubmitting }}
          />
        </DialogContent>

        <DialogActions>
          <Button color="secondary" onClick={handleClose} disabled={isSubmitting}>
            Cancel
          </Button>

          <LoadingButton type="submit" loading={isSubmitting} disabled={!dirty}>
            {isNewLicense ? "Add State License" : "Save Changes"}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
