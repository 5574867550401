import { bindActionCreators } from "redux";
import actions from "../../actions";
import * as selector from "../../selectors";

export const mapStateToProps = (state) => ({
  customers: selector.getCustomerList(state),
  sort: selector.getCustomersSort(state),
  direction: selector.getCustomersSortDirection(state),
  count: selector.getCustomersCount(state),
  page: selector.getCustomersPage(state),
  rowsPerPage: selector.getCustomersPerPage(state),
  expired: selector.getCustomersExpired(state),
  insuranceCustomersFilter: selector.getInsuranceCustomersFilter(state),
  insuranceCustomersType: selector.getInsuranceCustomersType(state),
  insuranceProviders: selector.getInsuranceProviders(state),
  createUserSaving: selector.getCreateCustomerSaving(state),
  createUserSuccess: selector.getCreateCustomerSuccess(state),
  createUserError: selector.getCreateCustomerError(state),
  createUserId: selector.getCreateUserId(state),
  createUserCognitoId: selector.getCreateUserCognitoId(state),
  billableTimePeriod: selector.getCustomersBillableTimePeriod(state),
  userPermissions: selector.getUserPermissionsList(state),
  loading: selector.getCustomersLoading(state),
  savingForLater: selector.getCustomerSavingForLater(state),
  savingForLaterSuccess: selector.getCustomerSavingForLaterSuccess(state),
  savingForLaterError: selector.getCustomerSavingForLaterError(state),
  savedUserId: selector.getCustomerSavedUserId(state),
  savedCustomerDetails: selector.getSavedCustomerDetails(state),
  savedCustomerLoading: selector.getSavedCustomersLoading(state),
  savedCustomerError: selector.getSavedCustomersError(state),
  saving: selector.getCreateCustomerSaving(state),
  savingLead: selector.getSavingLead(state),
  saveLeadSuccess: selector.getSaveLeadSuccess(state),
  insuranceListProviderId: selector.getInsuranceListProviderId(state),
  insuranceProvidersList: selector.getInsuranceProvidersList(state),
  insurancePlans: selector.getInsurancePlans(state),
});

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getInsuranceCustomerList: actions.getInsuranceCustomerList,
      checkAuthState: actions.checkAuthState,
      setCustomersPage: actions.setCustomersPage,
      setCustomersPerPage: actions.setCustomersPerPage,
      setPageDetails: actions.setPageDetails,
      setCustomersSort: actions.setCustomersSort,
      clearCustomers: actions.clearCustomers,
      setInsuranceCustomersFilter: actions.setInsuranceCustomersFilter,
      setInsuranceCustomersType: actions.setInsuranceCustomersType,
      getInsuranceProviders: actions.getInsuranceProviders,
      setSaving: actions.setSaving,
      createInsuranceCustomer: actions.createInsuranceCustomer,
      saveCustomerForLater: actions.saveCustomerForLater,
      uploadCustomerDocument: actions.uploadCustomerDocument,
      clearCreateCustomer: actions.clearCreateCustomer,
      setCustomersBillableTimePeriod: actions.setCustomersBillableTimePeriod,
      clearSaveForLaterInfo: actions.clearSaveForLaterInfo,
      getSavedCustomerDetails: actions.getSavedCustomerDetails,
      saveLead: actions.saveLead,
      getReferringPhysicians: actions.getReferringPhysicians,
      setInsuranceListProviderId: actions.setInsuranceListProviderId,
      getInsuranceProvidersList: actions.getInsuranceProvidersList,
      getInsurancePlans: actions.getInsurancePlans,
      setInsurancePlansRowsPerPage: actions.setInsurancePlansRowsPerPage,
    },
    dispatch,
  );
