import { getClientFormsForSignature } from "api/customers";
import { useQuery } from "react-query";

// Custom hook to fetch forms needing signature from client
const useGetClientFormsForSignature = (clientId, enabled = true) => {
  const { data, error, isLoading, refetch } = useQuery(
    ["unsignedForms", clientId],
    () => getClientFormsForSignature(clientId),
    {
      select: (data) => data?.data,
      enabled,
    }
  );

  return { forms: data ?? [], isLoading, error, refetch };
};

export default useGetClientFormsForSignature;
