import React, { createContext, useContext, useState } from "react";
import GlobalToast from "elements/GlobalToast";

/**
 * Context for managing global toast functionality.
 * Provides a hook to access the showToast function.
 */
const ToastContext = createContext();

/**
 * Custom hook to access the global toast functionality.
 * @returns {Object} An object containing the showToast function.
 */
export const useGlobalToast = () => useContext(ToastContext);

/**
 * Provider component to manage global toast state and provide toast functionality to child components.
 * @param {Object} props - Component props.
 * @param {React.ReactNode} props.children - Child components.
 */
export const GlobalToastProvider = ({ children }) => {
  // State to manage toast properties
  const [toastProps, setToastProps] = useState({
    open: false,
    message: "",
    errorState: false,
    retryHandler: null,
    errorButtonText: null,
  });

  /**
   * Function to show the toast with specified options.
   * @param {Object} options - Configuration options for the toast.
   * @param {string} options.message - The message to display.
   * @param {boolean} [options.errorState=false] - If true, shows an error message with a retry button.
   * @param {Function} [options.retryHandler=null] - Function to execute on retry button click.
   */
  const showToast = ({ message, errorState = false, retryHandler = null, errorButtonText }) => {
    setToastProps({
      open: true,
      message,
      errorState,
      retryHandler,
      errorButtonText,
    });
  };

  /**
   * Function to close the currently displayed toast.
   */
  const handleClose = () => {
    setToastProps((prevProps) => ({ ...prevProps, open: false }));
  };

  // Render the Provider with the toast context value and the GlobalToast component
  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <GlobalToast
        open={toastProps.open}
        message={toastProps.message}
        errorState={toastProps.errorState}
        onClose={handleClose}
        retryHandler={toastProps.retryHandler}
        errorButtonText={toastProps.errorButtonText}
      />
    </ToastContext.Provider>
  );
};
