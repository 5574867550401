import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import { Typography } from "@mui/material";
import { format } from "date-fns";

import { convertTokens } from "@date-fns/upgrade/v2";

const Timeline = (props) => {
  const {
    classes,
    clientTaskList,
    currentStepIndex,
    tasks,
    currentStepRef,
    mapClientTaskToButton,
  } = props;
  const phase2 = clientTaskList.some((task) => !task.phase_3);
  return (
    <div className={classes.roadmapContainer}>
      <div className={classes.timelineHeadingContainer}>
        <Typography component="p" className={classes.roadmapTitle}>
          {phase2 ? "Phase 2 Progress" : "Phase 3 Progress"}
        </Typography>
        <Typography component="p" className={classes.roadmapStepCount}>
          Step {currentStepIndex + 1}/{clientTaskList?.length}
        </Typography>
      </div>
      {clientTaskList.map((t, index) => {
        let task = { ...t, ...tasks[t.task] };
        let taskButton = mapClientTaskToButton(task);
        if (task.completed) {
          return (
            <React.Fragment key={index}>
              <div className={classes.completedRoadmapStep}>
                <div className={classes.roadmapIconContainer}>
                  <CheckIcon />
                </div>
                <div className={classes.roadmapStepTextContainer}>
                  <Typography component="h3">{task.stepTitle}</Typography>
                  <Typography component="p">
                    COMPLETED:{" "}
                    {format(new Date(task.completed_date), convertTokens("MMM D, YYYY @ hh:mm a"))}
                  </Typography>
                </div>
              </div>
              <div
                className={classes.roadmapDividerCompleted}
                style={index + 1 === currentStepIndex ? { height: 40 } : null}
              />
            </React.Fragment>
          );
        } else if (index == currentStepIndex) {
          return (
            <React.Fragment key={index}>
              <div className={classes.currentRoadmapStep} ref={currentStepRef}>
                <div className={classes.roadmapStepTextContainer}>
                  <Typography component="h3">{task.stepTitle}</Typography>
                  <Typography component="h4">{task.stepParagraphs[0]}</Typography>
                  {task.note && <Typography component="h6">{task.note}</Typography>}
                  {taskButton}
                </div>
              </div>
              {index !== clientTaskList.length - 1 && (
                <div className={classes.roadmapDivider} style={{ height: 40 }} />
              )}
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment key={index}>
              <div className={classes.incompleteRoadmapStep}>
                <div className={classes.roadmapStepTextContainer}>
                  <Typography component="h3">{task.stepTitle}</Typography>
                </div>
              </div>
              {index !== clientTaskList.length - 1 && <div className={classes.roadmapDivider} />}
            </React.Fragment>
          );
        }
      })}
    </div>
  );
};

export default Timeline;
