import {
  DefaultActiveSpeakerPolicy,
  DefaultDeviceController,
  DefaultMeetingSession,
  LogLevel,
  MeetingSessionConfiguration,
  MeetingSessionStatusCode,
} from "amazon-chime-sdk-js";
import AwsChimeMeetingEventObserver from "video/controllers/EventController";
import ContentShareController from "video/controllers/ContentShareController";
import AudioVideoController from "video/controllers/AudioVideoController";
import { UserPresenceLevel } from "video/meeting/MeetingRoster";
import { createMultiLogger, log } from "video/debug";
import ConnectionHealthPolicyConfiguration from "./ConnectionHealthPolicyConfig";

// TOTO handle "2024-04-05T11:39:54.262Z [ERROR] MessagingSession - Messaging Session failed to resolve endpoint: NotAuthorizedException: Invalid login token. Token expired: 1712316892 >= 1712301541"

export default class MeetingSession {
  get audioVideo() {
    return this.meetingSession?.audioVideo;
  }

  get devices() {
    return this.__getDevices();
  }

  get userId() {
    return this.attendee.Attendee.ExternalUserId;
  }

  /** @type {import('video/meeting/MeetingRoster').default} */
  meetingRoster;
  /** @type {MeetingSessionConfiguration} */
  meetingSessionConfig;
  /** @type {DefaultMeetingSession} */
  meetingSession;
  /** @type {AwsChimeMeetingEventObserver} */
  eventController;
  /** @type {ContentShareController} */
  contentShareController;
  /** @type {AudioVideoController} */
  audioVideoController;
  /** @type {typeof import('video/controllers/VideoInputController').default.prototype.userVideoContainerLogic} */
  userVideoContainerLogic;

  initialized = false;

  /**
   * @param {import('aws-sdk/clients/chimesdkmeetings').Meeting} meetingSessionConfig
   * @param {import('aws-sdk/clients/chimesdkmeetings').Attendee} attendee
   * @param {import('video/meeting/MeetingRoster').default} meetingRoster
   * @param {typeof this.userVideoContainerLogic} userVideoContainerLogic
   * @param {()=>{audioOutputContainer:string,audioInputDevice:string,audioOutputDevice:string,videoInputDevice:string}} getDevices
   * @param {()=>{audioInputEnabled:boolean,videoInputEnabled:boolean,audioOutputEnabled:boolean}} getDeviceState
   */
  constructor(
    meetingSessionConfig,
    attendee,
    meetingRoster,
    userVideoContainerLogic,
    getDevices,
    getDeviceState
  ) {
    log("initializing meeting;MeetingId=", meetingSessionConfig?.MeetingId);

    this.__getDevices = getDevices;
    this.__getDeviceState = getDeviceState;
    this.userVideoContainerLogic = userVideoContainerLogic;

    this.meetingRoster = meetingRoster;

    this.config = meetingSessionConfig;
    this.attendee = attendee;

    this.config = meetingSessionConfig;

    this.meetingSessionConfig = new MeetingSessionConfiguration(this.config, this.attendee);

    this.initializer = this.init();
  }

  async init() {
    this.logger = await createMultiLogger("MeetingSession", this.config.ExternalMeetingId, {
      logLevel: LogLevel.WARN,
      metadata: {
        meetingArn: this.config.MeetingArn,
        meetingId: this.config.MeetingId,
        externalMeetingId: this.config.ExternalMeetingId,
        externalUserId: this.attendee.ExternalUserId,
        attendeeId: this.attendee.AttendeeId,
      },
    });
    this.deviceController = new DefaultDeviceController(this.logger);

    // these flags' impacts are unknown

    // this.meetingSessionConfig.enableSimulcastForUnifiedPlanChromiumBasedBrowsers = true;
    // this.meetingSessionConfig.videoDownlinkBandwidthPolicy = new VideoAdaptiveProbePolicy(
    //   this.logger
    // );
    // this.meetingSessionConfig.videoUplinkBandwidthPolicy = new NScaleVideoUplinkBandwidthPolicy(
    //   this.attendee.AttendeeId,
    //   true,
    //   this.logger,
    //   {}
    // );
    this.meetingSessionConfig.keepLastFrameWhenPaused = true;
    this.meetingSessionConfig.connectionHealthPolicyConfiguration =
      ConnectionHealthPolicyConfiguration;
    this.meetingSession = new DefaultMeetingSession(
      this.meetingSessionConfig,
      this.logger,
      this.deviceController
    );

    this.setupHandlersAndObservers();

    this.initialized = true;
  }

  setupHandlersAndObservers() {
    this.eventController = new AwsChimeMeetingEventObserver(this);
    this.contentShareController = new ContentShareController(this);
    this.audioVideoController = new AudioVideoController(this, this.userVideoContainerLogic);

    this.meetingSession.audioVideo.addContentShareObserver(this.contentShareController);
    this.meetingSession.audioVideo.addDeviceChangeObserver(this.audioVideoController);
    this.meetingSession.eventController.addObserver(this.eventController);

    this.audioVideoController.setAllDevices();

    this.audioVideoController.onAudioVideoDidStart(async () => {
      this._started = true;
      await this.__onStart();
    });
    this.audioVideoController.onAudioVideoDidStop(async (sessionStatus) => {
      switch (sessionStatus.statusCode()) {
        case MeetingSessionStatusCode.Left:
        case MeetingSessionStatusCode.MeetingEnded:
          await this.deInit();
          break;
        default:
          log(
            Object.keys(sessionStatus).reduce((mem, cur) => {
              if (typeof sessionStatus[cur] === "function") {
                mem[cur] = sessionStatus[cur]();
              }
              return mem;
            }, {})
          );
      }

      this.__onMeetingEnded(sessionStatus);
    });

    this.on("meetingReconnected", (event) => this.__onReconnected(event));
    this.audioVideoController.onReconnecting(() => {
      this.__onReconnecting();
    });

    this.audioVideo.realtimeSubscribeToLocalSignalStrengthChange((signalStrength) => {
      log({ signalStrength });
    });

    this.contentShareController.onContentShareDidStart(() => {
      if (this.meetingRoster.currentPresenceLevel !== UserPresenceLevel.SCREEN_SHARING)
        this.meetingRoster.setCurrentPresence(UserPresenceLevel.SCREEN_SHARING);
    });
    this.contentShareController.onContentShareDidStop(() => {
      if (this.meetingRoster.currentPresenceLevel === UserPresenceLevel.SCREEN_SHARING)
        this.meetingRoster.setCurrentPresence(UserPresenceLevel.IN_CALL);
    });
  }

  destroy() {
    this.loggers?.splice(0).forEach((logger) => logger.destroy());
    this.eventController?.destroy();
    this.contentShareController?.destroy();
    this.audioVideoController?.destroy();
    this.meetingSession?.destroy();

    this.__getDevices = null;
    this.config = null;
    this.attendee = null;
    this.meetingSessionConfig = null;
    this.eventController = null;
    this.contentShareController = null;
    this.audioVideoController = null;
    this.meetingSession = null;
  }

  _started = false;
  _startQueue = [];
  async __onStart() {
    await Promise.all(this._startQueue.map((cb) => cb()));
  }
  onStart(cb) {
    if (this._started) cb();
    else this._startQueue.push(cb);
  }
  start() {
    if (!this._started) {
      this.audioVideo?.start();
    }
  }

  _stopQueue = [];
  stop() {
    try {
      this.audioVideo?.stop();

      // stop here, all de-init logic should be run once audioVideoDidStop is fired
      // ...
    } catch (error) {
      console.warn(error);
      console.warn("MeetingSession.stop() failed");
    }
  }

  async deInit() {
    try {
      // calls unsubscribeTo[...]() for each respective subscribeTo[...]()
      this._stopQueue.forEach((cb) => cb());

      // unhook observers
      this.meetingSession?.audioVideo?.removeDeviceChangeObserver(this.audioVideoController);
      this.meetingSession?.audioVideo?.removeContentShareObserver(this.contentShareController);
      this.meetingSession?.eventController?.removeObserver(this.eventController);

      // stop all A/V IO
      this.audioVideo?.stopContentShare();
      await this.audioVideoController?.stopAllDevices();
      this.audioVideo?.stop();
      await this.meetingSession?.deviceController?.destroy();
    } catch (error) {
      console.warn(error);
      console.warn("MeetingSession failed to clean up resources on stop()");
    }
  }

  /** @param {(sessionStatus: import('amazon-chime-sdk-js').MeetingSessionStatus)=>void} cb */
  onMeetingEnded(cb) {
    this._onMeetingEnded.push(cb);
  }
  /** @type {((sessionStatus: import('amazon-chime-sdk-js').MeetingSessionStatus)=>void)[]} */
  _onMeetingEnded = [];
  /** @param { import('amazon-chime-sdk-js').MeetingSessionStatus} sessionStatus */
  __onMeetingEnded(sessionStatus) {
    this._onMeetingEnded.forEach((cb) => cb(sessionStatus));
  }

  // custom handlers
  __onReconnectingCbs = [];
  __onReconnecting() {
    this.__onReconnectingCbs.forEach((cb) => cb());
  }
  /**
   * @param {()=>void} cb
   */
  onReconnecting(cb) {
    this.__onReconnectingCbs.push(cb);
  }
  __onReconnectedCbs = [];
  __onReconnected(event) {
    this.__onReconnectedCbs.forEach((cb) => cb(event));
  }
  /**
   * @param {()=>void} cb
   */
  onReconnected(cb) {
    this.__onReconnectedCbs.push(cb);
  }

  /**
   * @see https://aws.github.io/amazon-chime-sdk-js/modules/meetingevents.html
   * @param {import('amazon-chime-sdk-js').EventName} eventName
   * @param {(eventAttributes:import('amazon-chime-sdk-js').EventAttributes)=>void} cb
   */
  on(eventName, cb) {
    this.eventController.onEvent(eventName, cb);
  }

  // realtimeSubscribe[...] callbacks

  /**
   * @param {(error: Error) => void} cb
   */
  onFatalError(cb) {
    this.audioVideo.realtimeSubscribeToFatalError(cb);
    this._stopQueue.push(() => this.audioVideo.realtimeUnsubscribeToFatalError(cb));
  }

  /**
   * @param {(attendeeId: string, present: boolean, externalUserId?: string, dropped?: boolean, posInFrame?: import('amazon-chime-sdk-js').RealtimeAttendeePositionInFrame) => void} cb
   */
  onAttendeeIdPresence(cb) {
    this.audioVideo.realtimeSubscribeToAttendeeIdPresence(cb);
    this._stopQueue.push(() => this.audioVideo.realtimeUnsubscribeToAttendeeIdPresence(cb));
  }

  /**
   * @param {(signalStrength: number) => void} cb
   */
  onLocalSignalStrengthChange(cb) {
    this.audioVideo.realtimeSubscribeToLocalSignalStrengthChange(cb);
    this._stopQueue.push(() => this.audioVideo.realtimeUnsubscribeToLocalSignalStrengthChange(cb));
  }

  /**
   * @param {(muted: boolean) => void} cb
   */
  onMuteAndUnmuteLocalAudio(cb) {
    this.audioVideo.realtimeSubscribeToMuteAndUnmuteLocalAudio(cb);
    this._stopQueue.push(() => this.audioVideo.realtimeUnsubscribeToMuteAndUnmuteLocalAudio(cb));
  }

  /**
   * @param {string} topic
   * @param {(dataMessage: import('amazon-chime-sdk-js').DataMessage) => void} cb
   */
  onReceiveDataMessage(topic, cb) {
    this.audioVideo.realtimeSubscribeToReceiveDataMessage(topic, cb);
    this._stopQueue.push(() => this.audioVideo.realtimeUnsubscribeFromReceiveDataMessage(topic));
  }

  /**
   * @param {string} AttendeeId
   * @param {() => void} cb
   */
  onVolumeIndicator(AttendeeId, cb) {
    this.audioVideo.realtimeSubscribeToVolumeIndicator(AttendeeId, cb);
    this._stopQueue.push(() =>
      this.audioVideo.realtimeUnsubscribeFromVolumeIndicator(AttendeeId, cb)
    );
  }

  /**
   * @param {string} AttendeeId
   * @param {() => void} cb
   */
  cancelOnVolumeIndicator(AttendeeId, cb) {
    this.audioVideo.realtimeUnsubscribeFromVolumeIndicator(AttendeeId, cb);
  }

  /**
   * @param {(canUnmute: boolean) => void} cb
   */
  onSetCanUnmuteLocalAudio(cb) {
    this.audioVideo.realtimeSubscribeToSetCanUnmuteLocalAudio(cb);
    this._stopQueue.push(() => this.audioVideo.realtimeUnsubscribeToSetCanUnmuteLocalAudio(cb));
  }

  /**
   * @param {activeSpeakers: string[]} cb
   * @param {(scores: { [attendeeId: string]: number; }) => void} scoresCb
   * @param {number} [scoresCbFrequency=500]
   */
  onActiveSpeakerDetector(cb, scoresCb, scoresCbFrequency = 500) {
    this.audioVideo.subscribeToActiveSpeakerDetector(
      DefaultActiveSpeakerPolicy,
      cb,
      scoresCb,
      scoresCbFrequency
    );
    this._stopQueue.push(() => this.audioVideo.unsubscribeFromActiveSpeakerDetector(cb));
  }
}
