import React from "react";
import { Typography } from "@mui/material";

const ViewServiceDetails = (props) => {
  const { classes, associatedSessionNotes } = props;
  return (
    <div className={classes.viewServiceDetails}>
      <Typography component="p" variant="subtitle1">
        Services
      </Typography>
      {associatedSessionNotes?.map((service, i) => {
        return (
          <div key={i} className={classes.viewService}>
            <Typography component="p" variant="subtitle2">
              {service.description || "Incomplete Billing"}
            </Typography>
            <Typography component="p" variant="captionSecondary" style={{ flex: "1 0 0" }}>
              {service?.code === "00000" || service?.code === "99999" ? " " : service?.code || " "}
            </Typography>
            <Typography component="p" variant="body2">
              {service.actual_time} minutes
            </Typography>
          </div>
        );
      })}
    </div>
  );
};

export default ViewServiceDetails;
