import React from "react";
import withStyles from "@mui/styles/withStyles";
import Avatar from "elements/Avatar";
import Typography from "@mui/material/Typography";
import Modal from "elements/Modal";

const RemoveClinicianConfirmDialog = (props) => {
  const {
    classes,
    open,
    closeDialog,
    assignedRole,
    removeSecondaryClinician,
    selectedClinician,
    confirmSent,
  } = props;
  return (
    <Modal
      open={open}
      onClose={closeDialog}
      PaperProps={{
        style: {
          margin: 0,
          padding: "20px",
          boxSizing: "border-box",
        },
      }}
      content={
        <div className={classes.dialogContent} style={{ margin: 0, padding: "20px" }}>
          <div className={classes.infoContainer}>
            <Avatar imageUrl={selectedClinician.image_url} />
            <div className={classes.textContainer}>
              <Typography component="p" style={{ fontWeight: 500, fontSize: 16 }}>
                You are choosing to remove{" "}
                {selectedClinician.first_name + " " + selectedClinician.last_name} as the secondary
                clinician.
              </Typography>
              <Typography className={classes.confirmText}>Please confirm this action.</Typography>
            </div>
          </div>
        </div>
      }
      primaryActionText="Confirm"
      primaryActionOnClick={removeSecondaryClinician}
      primaryActionDisabled={confirmSent}
      secondaryActionText="Cancel"
      secondaryActionOnClick={closeDialog}
    />
  );
};

const styles = (theme) => ({
  dialogContent: {
    width: 600,
    height: "fit-content",
    maxHeight: "95%",
    maxWidth: "100%",
    color: "#404040",
  },
  infoContainer: {
    display: "flex",
    alignItems: "flex-start",
  },
  textContainer: {
    width: 450,
    padding: "15px 0 0 10px",
    boxSizing: "border-box",
  },
  confirmText: {
    fontSize: 17,
    fontWeight: 500,
    margin: "20px 0",
  },
  dialogAction: {
    fontSize: 17,
    fontWeight: 500,
  },
});

export default withStyles(styles)(RemoveClinicianConfirmDialog);
