import React from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { PhysicianForm } from "./PhysicianForm";
import { X } from "@phosphor-icons/react";

export const PhysicianFormDialog = (props) => {
  const {
    theme,
    // handlers
    handleConfirmedClosePhysicianDialog,
    toggleConfirmQuitDialog,
    submitPhysicianInfo,
    setFirstName,
    setLastName,
    setEmail,
    setBusinessName,
    setFaxNumber,
    setPhysicalAddress,
    setNpiNumber,
    setCity,
    setState,
    setZipCode,
    setPhone,
    setSpecialty,
    setWebsite,
    setAcceptsInsurance,
    // values
    firstName,
    lastName,
    email,
    businessName,
    faxNumber,
    physicalAddress,
    npiNumber,
    city,
    state,
    stateOptions,
    zipCode,
    phone,
    specialty,
    website,
    acceptsInsurance,
    physicianFormDialogOpen,
    confirmQuitEditDialogOpen,
    isNewPhysician,
    physicianSaveFail,
    physicianSaving,
    physicianModified,
  } = props;

  const [emailValidationError, setEmailValidationError] = React.useState(false);

  const { palette } = theme;
  const style = {
    width: "100%",
    minWidth: "600px",
    overflowY: "auto",
    p: "24px",
  };

  const validateEmail = (handler) => (props) => {
    const value = props.target.value;
    const valid =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value
      );
    setEmailValidationError(value === "" ? false : !valid);
    return handler(props);
  };

  const saveDisabled =
    physicianSaving ||
    firstName == "" ||
    lastName == "" ||
    phone.length < 10 ||
    faxNumber.length < 10 ||
    city == "" ||
    state == "" ||
    emailValidationError;

  return (
    <Box>
      <Dialog
        maxWidth={"sm"}
        open={physicianFormDialogOpen}
        onClose={physicianModified ? toggleConfirmQuitDialog : handleConfirmedClosePhysicianDialog}
      >
        <DialogTitle sx={{ padding: "16px 0" }}>
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            margin={0}
            width={"100%"}
            padding={"0 16px 0 0px"}
          >
            <Typography component="h6" style={{ fontSize: 20, fontWeight: "500" }}>
              {isNewPhysician ? "Add Provider" : `${firstName} ${lastName}`}
            </Typography>
            <IconButton
              onClick={
                physicianModified ? toggleConfirmQuitDialog : handleConfirmedClosePhysicianDialog
              }
            >
              <X fontSize={"24px"} color={palette.text.secondary} />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={style}>
          <PhysicianForm
            {...{
              // handlers
              handleConfirmedClosePhysicianDialog,
              toggleConfirmQuitDialog,
              submitPhysicianInfo,
              setFirstName,
              setLastName,
              setEmail: validateEmail(setEmail),
              validEmail: emailValidationError,
              setBusinessName,
              setFaxNumber,
              setPhysicalAddress,
              setNpiNumber,
              setCity,
              setState,
              setZipCode,
              setPhone,
              setSpecialty,
              setWebsite,
              setAcceptsInsurance,
              // values
              firstName,
              lastName,
              email,
              businessName,
              faxNumber,
              physicalAddress,
              npiNumber,
              city,
              state,
              stateOptions,
              zipCode,
              phone,
              specialty,
              website,
              acceptsInsurance,
              physicianFormDialogOpen,
              confirmQuitEditDialogOpen,
              isNewPhysician,
              physicianSaveFail,
              physicianSaving,
              physicianModified,
            }}
          />
        </DialogContent>
        {physicianSaveFail && (
          <Box margin={"6px"}>
            <Alert severity="error">There has been an error saving this physician</Alert>
          </Box>
        )}
        <DialogActions>
          <Button
            color="secondary"
            onClick={
              physicianModified ? toggleConfirmQuitDialog : handleConfirmedClosePhysicianDialog
            }
          >
            Cancel
          </Button>
          <Button
            startIcon={physicianSaving ? <CircularProgress size={"20px"} /> : undefined}
            disabled={saveDisabled}
            onClick={submitPhysicianInfo}
            autoFocus
          >
            {isNewPhysician ? "Add Provider" : "Save Changes"}
          </Button>
        </DialogActions>
        <Dialog open={confirmQuitEditDialogOpen}>
          <DialogContent>Are you sure you'd like to quit without saving?</DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={toggleConfirmQuitDialog}>
              Cancel
            </Button>
            <Button color="secondary" onClick={handleConfirmedClosePhysicianDialog}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Dialog>
    </Box>
  );
};

export default PhysicianFormDialog;
