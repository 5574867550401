import React, { useEffect, useState } from "react";
import useFormSteps from "./useFormSteps";
import { FormProvider, useForm } from "react-hook-form";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import useFormSubmission from "./useFormSubmit";
import { DialogTitle, IconButton } from "@mui/material";
import { X, ArrowRight } from "@phosphor-icons/react";
import LoadingButton from "@mui/lab/LoadingButton";
import { providerTypes } from "../Providers/providerTypes";

const MultiStepFormDialog = ({
  title,
  initialStep = 0,
  steps,
  defaultValues,
  onSubmit,
  open,
  onClose,
  classes = {},
  submitLabel = "Submit",
  onCloseAction,
}) => {
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const formMethods = useForm({ defaultValues });
  const {
    handleSubmit,
    formState: { errors },
    trigger,
    getValues,
    setError,
    clearErrors,
    setValue,
  } = formMethods;

  const { loading, handleFormSubmit } = useFormSubmission(onSubmit);
  const { currentStep, goToNextStep, goToPrevStep, isFirstStep, isLastStep } = useFormSteps(
    initialStep,
    steps.length - 1,
    trigger
  );

  const validateCheckboxes = () => {
    const values = getValues();
    const checkboxFields = [
      "historyAndPhysicalExamination",
      "diagnosticReportOrTesting",
      "progressNotes",
      "psychologicalReports",
      "educationPlan",
      "treatmentPlanAndGoals",
      "other",
    ];
    const isChecked = checkboxFields.some((field) => !!values[field]);
    if (!isChecked) {
      setError("checkboxes", { type: "manual", message: "At least one item must be selected." });
      return false;
    }
    clearErrors("checkboxes");
    return true;
  };

  const enhancedHandleSubmit = async (data) => {
    if (isLastStep && !defaultValues?.isEdit && !validateCheckboxes()) {
      return;
    }
    handleFormSubmit(data);
  };

  const handleCloseWithAction = () => {
    const values = getValues();
    onCloseAction(values);
  };

  const validateAndProceed = async () => {
    const result = await trigger();
    setSubmitAttempted(true);
    if (result) {
      clearErrors();
      goToNextStep();
    }
  };

  const FormComponent = steps[currentStep];

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          margin: 0,
          boxSizing: "border-box",
          width: "444px",
        },
      }}
    >
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(enhancedHandleSubmit)}>
          <DialogTitle>
            {title}
            <IconButton aria-label="close" onClick={onClose}>
              <X />
            </IconButton>
          </DialogTitle>
          <Divider />
          <DialogContent className={classes.dialogContent}>
            <FormComponent submitAttempted={submitAttempted} />
          </DialogContent>
          <Divider />
          <DialogActions>
            {!isFirstStep && (
              <Button onClick={goToPrevStep} disabled={loading} variant="text" color="primary">
                Back
              </Button>
            )}
            <Button color="secondary" onClick={onClose} disabled={loading}>
              Cancel
            </Button>
            {!isLastStep && (
              <Button onClick={validateAndProceed} disabled={loading} endIcon={<ArrowRight />}>
                Next
              </Button>
            )}
            {isLastStep && (
              <LoadingButton type="submit" loading={loading}>
                {submitLabel}
              </LoadingButton>
            )}
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default MultiStepFormDialog;
