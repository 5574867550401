import React, { useState } from "react";
import { connect } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import CalendarSyncImage from "../../../assets/cal_sync.png";
import CalendarSyncImageUnknown from "../../../assets/cal_sync_2.png";
import GoogleGIcon from "../../../assets/google_G_icon.png";
import MicrosoftIcon from "../../../assets/microsoft_icon.png";
import { Envelope } from "@phosphor-icons/react";
import {
  getClinicianCalendarSynced,
  getClinicianCalendarSyncedDate,
  getClinicianCalendarSyncedEmail,
  getLogin,
  getUserClinicianId,
} from "selectors";
import { useMutation } from "react-query";
import { syncClinicianCalendarToOnSched } from "api/clinician";
import { format } from "date-fns";
import { LoadingButton } from "@mui/lab";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SectionBox from "elements/SectionBox";

const mapStateToProps = (state) => ({
  clinicianId: getUserClinicianId(state),
  calendarSynced: getClinicianCalendarSynced(state),
  calendarSyncedDate: getClinicianCalendarSyncedDate(state),
  calendarSyncedEmail: getClinicianCalendarSyncedEmail(state),
  clinicianEmail: getLogin(state),
});

const CalendarSync = (props) => {
  const {
    classes,
    clinicianId,
    calendarSynced,
    calendarSyncedDate,
    calendarSyncedEmail,
    clinicianEmail,
  } = props;
  const [associatedEmail, setAssociatedEmail] = useState(calendarSyncedEmail || clinicianEmail);
  const [isGoogle, setIsGoogle] = useState(true);

  const {
    mutate,
    isLoading,
    data: body,
    error,
  } = useMutation(
    ({ isGoogleSync }) =>
      syncClinicianCalendarToOnSched({
        clinicianId,
        isGoogleSync,
        associatedEmail,
        redirectUrl: `${process.env.BASE_PARENT_URL}/my-info/profile?calendarSyncRedirect=true`,
      }),
    {
      onSuccess: (body, variables) => {
        window.location.href =
          variables.isGoogleSync && body.data.googleCalendarAuthUrl
            ? body.data.googleCalendarAuthUrl
            : body.data.outlookCalendarAuthUrl;
      },
      onError: (error) => {
        console.error("Error syncing calendar:", error);
      },
    }
  );

  const handleSyncButtonClick = (isGoogle) => {
    setIsGoogle(isGoogle);
    mutate({ isGoogleSync: isGoogle });
  };

  const isAnswersNowEmail = () => {
    const { clinicianEmail } = props;
    return clinicianEmail?.includes("getanswersnow.com");
  };

  return (
    <SectionBox
      title="External Calendars"
      description={"Connect your AnswersNow and external calendars"}
      columnDirection
    >
      <div className={classes.container}>
        <div className={classes.blockContainer}>
          {isAnswersNowEmail() ? (
            <GoogleSync
              classes={classes}
              handleSyncButtonClick={handleSyncButtonClick}
              isLoading={isLoading}
            />
          ) : (
            <OptionalSync
              classes={classes}
              handleSyncButtonClick={handleSyncButtonClick}
              isLoading={isLoading}
              associatedEmail={associatedEmail}
              setAssociatedEmail={setAssociatedEmail}
              isGoogle={isGoogle}
            />
          )}
        </div>
        {calendarSynced && calendarSyncedDate && (
          <Typography variant="body2" className={classes.lastSyncedText}>
            Last synced: {format(new Date(calendarSyncedDate), "MMMM do, yyyy h:mm a")}
          </Typography>
        )}
      </div>
    </SectionBox>
  );
};

const GoogleSync = (props) => {
  const { classes, isLoading, handleSyncButtonClick } = props;

  return (
    <>
      <div className={classes.textContainer}>
        <Typography variant="h4">Integrate your AnswersNow and Google Calendars</Typography>
        <Typography variant="body2" className={classes.body2}>
          AnswersNow connects with Google, automating the scheduling process from start to finish.
          Scheduled events are added directly to your calendar, removing any unnecessary steps in
          between.
        </Typography>
      </div>
      <LoadingButton
        onClick={() => handleSyncButtonClick(true)}
        color="secondary"
        className={classes.button}
        loading={isLoading}
      >
        Sync Calendars
      </LoadingButton>

      <div className={classes.backgroundImage}>
        <img src={CalendarSyncImage} />
      </div>
    </>
  );
};

const OptionalSync = (props) => {
  const {
    classes,
    handleSyncButtonClick,
    isLoading,
    associatedEmail,
    setAssociatedEmail,
    isGoogle,
  } = props;

  return (
    <>
      <div className={classes.textContainer}>
        <Typography variant="h4">Integrate your AnswersNow and External Calendar</Typography>
        <Typography variant="body2" className={classes.body2}>
          AnswersNow connects with Google and Microsoft, automating the scheduling process from
          start to finish. Scheduled events are added directly to your calendar, removing any
          unnecessary steps in between.
        </Typography>
      </div>
      <div className={classes.contentOptionalSync}>
        <div className={classes.emailContainer}>
          <TextField
            id="associated-email"
            label="Email Address"
            value={associatedEmail}
            onChange={(e) => setAssociatedEmail(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Envelope />
                </InputAdornment>
              ),
            }}
            helperText="Confirm the email address associated with your Google or Microsoft calendar"
          />
        </div>
        <div className={classes.buttonContainer}>
          <LoadingButton
            onClick={() => handleSyncButtonClick(true)}
            color="secondary"
            loading={isGoogle && isLoading}
            disabled={isLoading}
            startIcon={<img src={GoogleGIcon} style={{ height: 20, width: 20 }} />}
            fullWidth
          >
            Sign in with Google
          </LoadingButton>
          <LoadingButton
            onClick={() => handleSyncButtonClick(false)}
            color="secondary"
            loading={!isGoogle && isLoading}
            disabled={isLoading}
            startIcon={<img src={MicrosoftIcon} style={{ height: 20, width: 20 }} />}
            fullWidth
          >
            Sign in with Microsoft
          </LoadingButton>
        </div>
      </div>
      <div className={classes.backgroundImageOptional}>
        <img src={CalendarSyncImageUnknown} />
      </div>
    </>
  );
};

const styles = (theme) => ({
  container: {
    width: "100%",
    height: "fit-content",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
    marginBottom: 30,
  },
  blockContainer: {
    position: "relative",
    overflow: "hidden",
    border: "1px solid #c6c6c6",
    borderRadius: 8,
    pageBreakInside: "avoid",
    padding: theme.spacing(7, 9),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: theme.spacing(7),
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: theme.spacing(3),
    zIndex: 2,
  },
  backgroundImage: {
    width: 220,
    height: 220,
    position: "absolute",
    right: "-0.5px",
    bottom: "-63px",
    zIndex: 1,
    "& img": {
      height: "100%",
      width: "auto",
    },
  },
  backgroundImageOptional: {
    width: 220,
    height: 220,
    position: "absolute",
    right: "-0.5px",
    top: "20%",
    zIndex: 1,
    "& img": {
      height: "100%",
      width: "auto",
    },
  },
  button: {
    zIndex: 4,
  },
  body2: {
    color: theme.palette.text.secondary,
  },
  lastSyncedText: {
    color: theme.palette.text.secondary,
    fontStyle: "italic",
  },
  emailContainer: {
    width: "60%",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: theme.spacing(3),
    alignSelf: "stretch",
  },
  contentOptionalSync: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: theme.spacing(5),
    zIndex: 4,
  },
});

export default connect(mapStateToProps)(withStyles(styles)(CalendarSync));
