import theme from "theme";

// Spacing
export const space2 = theme.spacing(2); // 6px
export const space3 = theme.spacing(3); // 8px
export const space4 = theme.spacing(4); // 12px
export const space5 = theme.spacing(5); // 16px
export const space6 = theme.spacing(6); // 20px
export const space7 = theme.spacing(7); // 24px
export const space10 = theme.spacing(10); // 40px

const grey50 = theme.palette.default.hover;
const panelWidth = 512;

export const description = {
  paddingTop: space2,
  paddingBottom: space5,
};

export const assessmentCard = {
  backgroundColor: grey50,
  borderRadius: theme.radius["ex-large"],
  padding: space3,
};

export const innerCard = {
  backgroundColor: theme.palette.default.background,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.radius.large,
  boxShadow: `0px 1px 1px 0px rgba(0, 0, 0, 0.15)`,
  paddingX: space7,
  paddingY: space5,
  marginBottom: space2,
};

export const averageStatistics = {
  m: space6,
  marginTop: space10,
};

export const previousPaper = {
  width: panelWidth,
  padding: space7,
  position: "static",
};

export const panelHeader = {
  marginLeft: "-3px",
  "& .MuiPaginationItem-root": {
    borderRadius: "6px",
  },
  "& li button:not(.MuiPaginationItem-previousNext, MuiPaginationItem-previousNext)": {
    display: "none",
  },
};

export const domainScore = {
  marginTop: space5,
  columnGap: "15px",
  rowGap: "13px",
  columns: 12,
};

export const historyIcon = { marginRight: space3, lineHeight: 1, verticalAlign: "bottom" };
