import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useTheme } from "@mui/styles";
import {
  saveClinicianStateLicenseAsync,
  updateClinicianStateLicenseAsync,
  deleteClinicianStateLicenseAsync,
} from "api/clinician";
import { STATES } from "constants";
import { CLINICIAN_STATE_LICENSE } from "constants/reactQueryKeys";
import { postUploadFiles } from "hooks/postUploadFiles";
import { openS3Document } from "utils/openS3Document";
import SectionBox from "elements/SectionBox";
import { useGlobalToast } from "components/GlobalToastProvider";
import StateLincenseDialog from "./Dialogs/AddEditLicenseDialog";
import DeleteAlertDialog from "./Dialogs/DeleteAlertDialog";
import SectionList from "./SectionList";
/** @typedef {import("api/typesDef").License} License */

export default function StateLicenses({ basicInfo, editMode, licenses = [] }) {
  const queryClient = useQueryClient();
  const theme = useTheme();
  const { showToast } = useGlobalToast();
  // Retry State for mutations
  const [retry, setRetry] = useState(0);
  const list = licenses.map((license) => ({
    id: license.id,
    title: STATES[license.state] || "Missing state",
    expirationDate: license.expiry_date,
  }));

  /** @type {ReturnType<typeof useState<(License)>>} */
  const [currentLicense, setCurrentLicense] = useState(null);

  // Dialog States
  const [openAddEditDialog, setOpenAddEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [isNewLicense, setIsNewLicense] = useState(true);

  // Mutations
  const { mutate: saveStateLicense } = useMutation(
    isNewLicense ? saveClinicianStateLicenseAsync : updateClinicianStateLicenseAsync,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(CLINICIAN_STATE_LICENSE);
        showToast({ message: isNewLicense ? "License added!" : "License updated!" });
        setRetry(0);
      },
      onError: (error) => {
        console.error(error);
        showToast({
          message: "Whoops! Something went wrong",
          errorState: true,
          retryHandler: () => setRetry(1),
        });
      },
      onSettled: () => {
        handleCloseAddEditDialog();
      },
      retry,
    }
  );

  const { mutate: deleteStateLicense, isLoading: isDeletingLicense } = useMutation(
    deleteClinicianStateLicenseAsync,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(CLINICIAN_STATE_LICENSE);
        showToast({ message: "License removed!" });
        setRetry(0);
      },
      onError: (error) => {
        console.error(error);
        showToast({
          message: "Whoops! Something went wrong",
          errorState: true,
          retryHandler: () => setRetry(1),
        });
      },
      onSettled: () => {
        handleCloseDeleteDialog();
      },
      retry,
    }
  );

  const onSelectElement = (id) => {
    const selectedLicense = licenses.find((license) => license.id === parseInt(id));
    setCurrentLicense(selectedLicense);
  };

  // Dialog Handlers
  const handleOpenLicenseDialog = (isNew) => {
    setIsNewLicense(isNew);
    setOpenAddEditDialog(true);
  };
  const handleCloseAddEditDialog = () => setOpenAddEditDialog(false);
  const handleCloseDeleteDialog = () => setOpenDeleteDialog(false);

  // Formik Submit Handler
  const handleSubmitLicense = (values) => {
    const licenseFile = values.licenseFile[0]?.alreadyUploaded ? [] : values.licenseFile;

    return postUploadFiles(basicInfo.cognito_id, licenseFile).then(
      async ({ errors, success: filename }) => {
        const file = licenseFile[0]?.file;
        if (errors) {
          console.error(errors);
          return;
        }

        let stateLicense = {
          clinicianId: basicInfo.id,
          licenseNumber: values.licenseNumber,
          state: values.stateType,
          expiryDate: values.expirationDate,
          issuedOn: values.issuedDate,
          displayFilename: filename ? file?.name : currentLicense.display_filename,
          fileSize: filename ? file.size : currentLicense.file_size,
        };

        if (isNewLicense) {
          stateLicense = { ...stateLicense, imageName: filename.split("/")[1] };
        } else {
          stateLicense = {
            ...stateLicense,
            licenseId: currentLicense.id,
            imageName: filename ? filename.split("/")[1] : currentLicense.image_name,
          };
        }

        await saveStateLicense(stateLicense);
      }
    );
  };

  // Delete License handler
  const handleDeleteLicense = () => {
    const licenseData = {
      clinicianId: currentLicense.clinician_id,
      licenseId: currentLicense.id,
    };

    deleteStateLicense(licenseData);
  };

  // Download License handler
  const handleDownloadLicense = () => {
    openS3Document({ cognitoId: basicInfo.cognito_id, filename: currentLicense.image_name });
  };

  return (
    <SectionBox
      title="State Licenses"
      description={"Manage and add state licenses on file for the Clinician"}
      padding={theme.spacing(5, 0)}
    >
      <SectionList
        list={list}
        editMode={editMode}
        addHandler={() => handleOpenLicenseDialog(true)}
        editHandler={() => handleOpenLicenseDialog(false)}
        deleteHandler={() => setOpenDeleteDialog(true)}
        downloadHandler={handleDownloadLicense}
        onSelectElement={onSelectElement}
        noDataMessage="No State Licenses on the account"
        menuActionText="License"
        addButtonText="Add State License"
      />
      <StateLincenseDialog
        open={openAddEditDialog}
        isNewLicense={isNewLicense}
        currentLicense={currentLicense}
        onClose={handleCloseAddEditDialog}
        onSubmit={handleSubmitLicense}
      />

      <DeleteAlertDialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        onDelete={handleDeleteLicense}
        isLoading={isDeletingLicense}
        title="Delete State License?"
      />
    </SectionBox>
  );
}
