import { getClinicalLeads } from "api/clinicians";
import { useQuery } from "react-query";

export const useGetClinicalLeads = () => {
  const { isLoading, isError, data, error } = useQuery(
    ["clinicalLeads"],
    () => getClinicalLeads(),
    {
      keepPreviousData: true, // this is used to avoid flickering
    }
  );

  return {
    leads: data?.data || [],
    leadsLoading: isLoading,
    leadsError: isError ? error : null,
  };
};
