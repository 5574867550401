export default (theme) => ({
  signupTitle: {
    textAlign: "center",
    width: "70%",
    margin: "15px 0",
    fontWeight: 500,
    color: "#505050",
    fontSize: "1.8rem",
    [theme.breakpoints.down("xl")]: {
      width: "90%",
      fontSize: "1.4rem",
    },
  },
  eligibilityInstructions: {
    fontSize: "1rem",
    textAlign: "center",
    lineHeight: "1.6rem",
    width: "90%",
    marginBottom: 15,
    color: "#8a8a8a",
    fontWeight: 500,
    [theme.breakpoints.down("xl")]: {
      width: "90%",
      fontSize: ".9rem",
      lineHeight: "1.4rem",
    },
  },
  formContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    minHeight: "calc(100vh - 110px)",
    flex: 1,
    margin: 0,
    paddingTop: 30,
    [theme.breakpoints.down("xl")]: {
      margin: 0,
      paddingTop: 0,
    },
  },
  title: {
    color: "#15171D",
    fontSize: 30,
    fontWeight: "500",
    marginLeft: 200,
  },
  informationContainer: {
    backgroundColor: "#C3C6D2",
    borderRadius: 6,
    padding: 30,
    margin: "40px auto",
  },
  formTitleContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: 25,
    paddingLeft: 50,
  },
  intakeFormTitleContainer: {
    display: "flex",
    alignItems: "center",
    margin: "50px 0",
    paddingLeft: 50,
  },
  formTitle: {
    fontSize: 20,
    color: " #15171D",
    marginLeft: 10,
  },
  intakeFormTitle: {
    fontSize: 20,
    color: " #15171D",
    margin: "25px 0",
  },
  questionsTitleContainer: {
    marginBottom: 40,
  },
  sectionTitle: {
    fontSize: 17,
    fontWeight: 500,
    color: "#15171D",
    margin: "50px 0px 40px",
  },
  intakeFormSectionTitle: {
    fontSize: 17,
    fontWeight: 500,
    color: "#15171D",
  },
  avatar: {
    backgroundColor: "#f5f5f5",
    border: "1px solid #3F4456",
    color: "#3F4456",
  },
  form: {
    flex: 1,
    width: "100%", // Fix IE 11 issue.
    margin: "15px 0",
    display: "flex",
    flexDirection: "column",
  },
  profileInfoContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: "140px",
    [theme.breakpoints.down("xl")]: {
      width: "100%",
      paddingLeft: 24,
    },
  },
  accordionRoot: {
    margin: "8px 0",
    "&:before": {
      height: 0,
    },
  },
  intakeAccordionSummary: {
    height: 80,
    padding: "0 150px 0 200px",
    backgroundColor: "#fff",
    paddingLeft: "140px",
    [theme.breakpoints.down("xl")]: {
      padding: "0 50px 0 50px",
      height: 60,
    },
  },
  intakeAccordionSummaryContent: {
    justifyContent: "space-between",
    paddingRight: 15,
  },
  intakeAccordionDetails: {
    padding: "40px 19% 40px 21%",
    backgroundColor: "#fafafa",
    paddingLeft: "140px",
    [theme.breakpoints.down("xl")]: {
      padding: "25px 60px 40px 60px",
    },
  },
  intakeAccordionDetailsFamily: {
    padding: "40px 190px 40px 225px",
    backgroundColor: "#fafafa",
    paddingLeft: "140px",
    [theme.breakpoints.down("xl")]: {
      padding: "25px 50px 25px 60px",
    },
  },
  heading: {
    fontSize: 22,
    fontWeight: 500,
    color: "#2a2d39",
    borderBottom: "3px solid #7235A2",
    [theme.breakpoints.down("xl")]: {
      fontSize: 17,
    },
  },
  secondaryHeading: {
    fontSize: 15,
    fontWeight: "500",
    [theme.breakpoints.down("xl")]: {
      fontSize: 14,
    },
  },
  intakeFormButtonsContainer: {
    width: "20%",
    minWidth: 300,
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xl")]: {
      width: "100%",
    },
    marginRight: 150,
  },
  buttonContainer: {
    // color: "#2A2D39",
    fontSize: 14,
    textTransform: "none",
    [theme.breakpoints.down("xl")]: {
      fontSize: 12,
    },
  },
  buttonLabel: {
    fontSize: 12,
    color: "#4e4e4e",
    height: 12,
    width: "100%",
    paddingLeft: 9,
  },
  toggleButton: {
    border: "1px solid #A5AABC",
    backgroundColor: "#FCF9FF",
    boxShadow: "none",
    borderRadius: 8,
    height: 50,
    "&:hover": {
      backgroundColor: "#ede1f5",
      boxShadow: "none",
      cursor: "pointer",
    },
    "& span": {
      color: "#757575",
      textTransform: "none",
    },
  },
  toggleButtonSelected: {
    border: "1px solid #9c00d5",
    backgroundColor: "#ede1f5",
    borderRadius: 8,
    boxShadow: "none",
    height: 50,
    "&:hover": {
      backgroundColor: "#ede1f5",
      boxShadow: "none",
      cursor: "pointer",
    },
    "& span": {
      color: "#570071",
      textTransform: "none",
    },
  },
  insuranceProviderPreview: {
    width: "100%",
    height: 80,
    display: "flex",
    justifyContent: "center",
    boxShadow: "0 2px 3px 0 rgba(0,0,0,.4)",
    alignItems: "center",
    border: "2px solid #c4c4c4",
    borderRadius: 8,
    overflow: "hidden",
  },
  insuranceLogo: {
    height: "90%",
    width: "90%",
    objectFit: "contain",
  },
  insurancePreview: {
    width: "100%",
    height: 80,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #c4c4c4",
    borderRadius: 4,
    overflow: "hidden",
  },
  insuranceCardPreview: {
    width: "70%",
    height: "auto",
    maxHeight: "80%",
    objectFit: "contain",
  },
  diagnosisFormPreview: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "5px 10px",
    border: "2px solid #c4c4c4",
    borderRadius: 8,
    marginBottom: 10,
    overflow: "hidden",
  },
  uploadDiagnosisButton: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "5px 10px",
    border: "1px solid #A5AABC",
    backgroundColor: "#FCF9FF",
    borderRadius: 8,
    marginBottom: 10,
    overflow: "hidden",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#ede1f5",
    },
  },
  uploadDiagnosisIcon: {
    width: 40,
    height: 40,
    objectFit: "contain",
  },
  uploadDiagnosisTextWrapper: {
    flex: 1,
    height: 45,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "flex-start",
    marginLeft: 20,
  },
  uploadDiagnosisTitle: {
    color: "#4b4a4b",
    fontSize: 15,
    fontWeight: 500,
    [theme.breakpoints.down("xl")]: {
      fontSize: 12,
    },
  },
  uploadDiagnosisSubtitle: {
    color: "#8a8a8a",
    fontSize: 12,
    fontWeight: 500,
    whiteSpace: "nowrap",
  },
  agreeToTermsText: {
    width: "60%",
    margin: "15px 0 5px",
    fontSize: ".85rem",
    color: "#5c5c5c",
    [theme.breakpoints.down("xl")]: {
      width: "90%",
    },
  },
  stepButton: {
    width: 100,
    height: 40,
    boxShadow: "none",
    color: "#fff",
    backgroundColor: "#9c00d5",
    borderRadius: 8,
    "&:hover": {
      backgroundColor: "#51186b",
      cursor: "pointer",
    },
    "& span": {
      color: "#fff",
      fontWeight: 500,
      textTransform: "none",
    },
  },
  buttonProgress: {
    color: "#ccc",
  },
  uploadButton: {
    width: "100%",
    height: 80,
    border: "1px solid #A5AABC",
    backgroundColor: "#FCF9FF",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "5px 10px",
    borderRadius: 8,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#ede1f5",
    },
    [theme.breakpoints.down("xl")]: {
      margin: "10px 0",
      width: "100%",
    },
  },
  uploadIcon: {
    width: 40,
    height: 40,
    objectFit: "contain",
  },
  uploadText: {
    color: "#545A72",
    fontSize: 15,
    textAlign: "center",
    fontWeight: 500,
    margin: "0 10px 0 20px",
  },
  errorMessage: {
    fontSize: 14,
    color: "red",
    textAlign: "center",
    margin: "10px auto",
  },
  waitingMessage: {
    fontSize: 16,
    textAlign: "center",
    margin: "10px auto",
  },
  datePopper: {
    zIndex: 3000,
  },
  radioButtonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  nextButton: {
    marginTop: 20,
    marginBottom: 20,
    marginLeft: "85%",
  },
  previousButton: {
    marginTop: 20,
    marginBottom: 20,
    marginRight: "85%",
  },
  priorServicesContainer: {
    display: "flex",
    alignItems: "center",
  },
  successMessage: {
    fontWeight: 500,
    color: "#059669",
    fontSize: 17,
  },
  errorMessage: {
    fontWeight: 500,
    color: "#DC2626",
    fontSize: 17,
  },
  helperText: {
    padding: "0 5px",
    color: "#B91C1C",
    fontSize: 12,
    fontWeight: 500,
  },
  familyMember: {
    width: "100%",
    borderRadius: 7,
    backgroundColor: "#fff",
    boxShadow: "0px 1px 3px rgba(55, 55, 55, 0.15)",
    display: "flex",
    flexDirection: "column",
    marginTop: 40,
  },
  familyMemberHeader: {
    backgroundColor: "#F0F1F4",
    width: "100%",
    padding: "5px 30px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    "& p": {
      fontSize: 16,
      fontWeight: 500,
    },
  },
  familyMemberDetails: {
    padding: "20px 30px",
  },
  familyMemberDetailsRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    margin: "7px 0",
    "& p": {
      fontSize: 14,
      fontWeight: 500,
    },
  },
  familyMemberRowLabel: {
    width: 250,
  },
  familyMemberRowIconContainer: {
    height: 22,
    width: 22,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  drawer: {
    display: "flex",
    width: 700,
    // paddingTop: 100,
    [theme.breakpoints.down("xl")]: {
      width: "100%",
    },
    overflow: "auto",
  },
  familyDrawerPaper: {
    width: 700,
    height: "fit-content",
    minHeight: "50%",
    marginTop: "10%",
    [theme.breakpoints.down("xl")]: {
      width: "100%",
      height: "100%",
      marginTop: 0,
    },
    backgroundColor: "#fafafa",
  },
  familyDrawerContainer: {
    padding: "30px",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    overflowY: "auto",
    justifyContent: "space-between",
    alignItems: "center",
  },
  physicianDrawerContainer: {
    padding: "30px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
  physicianDrawerPaper: {
    width: 700,
    height: "fit-content",
    maxHeight: "100%",
    marginTop: "10%",
    [theme.breakpoints.down("xl")]: {
      width: "100%",
      height: "100%",
      marginTop: 0,
    },
    backgroundColor: "#fafafa",
  },
  physicianDrawerPaperCreate: {
    width: 700,
    height: "fit-content",
    maxHeight: "100%",
    [theme.breakpoints.down("xl")]: {
      width: "100%",
      height: "100%",
      marginTop: 0,
    },
    backgroundColor: "#fafafa",
  },
  tabBarContainer: {
    width: "calc(100% - 40px)",
    height: 40,
    margin: "25px 20px 0",
    paddingBottom: 10,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    position: "relative",
    borderBottom: "1px solid #C3C6D2",
  },
  closeDrawerIcon: {
    position: "absolute",
    right: 0,
    "& svg": {
      fill: "#15171d",
      fontSize: 30,
    },
  },
  drawerHeaderText: {
    fontSize: 20,
    fontWeight: 500,
    color: "#2A2D39",
  },
  drawerButtonContainer: {
    marginTop: 25,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  divider: {
    height: 0,
    width: "100%",
    borderBottom: "2px solid #cbcbcb",
    marginTop: "15px",
  },
  checkboxLabel: {
    fontSize: 14,
    fontWeight: "500",
    color: "#545A72",
    lineHeight: 1.4,
  },
  physicianContainer: {
    width: "100%",
    height: 110,
    borderRadius: 7,
    backgroundColor: "#fff",
    boxShadow: "0px 1px 3px rgba(55, 55, 55, 0.15)",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "30px",
    margin: "40px 0 10px",
  },
  physicianTextContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    color: "#3F4456",
    "& h6": {
      fontSize: 16,
      fontWeight: 500,
    },
    "& p": {
      fontSize: 14,
    },
  },
  settingsMenuItem: {
    "&:hover": {
      backgroundColor: "#e1e3e9",
    },
    "& p": {
      fontSize: 14,
      fontWeight: 500,
      fontColor: "#15171d",
    },
  },
  insuranceSubtitle: {
    fontSize: 20,
    fontWeight: 500,
    color: "#2A2D39",
  },
  insuranceFieldLabel: {
    marginBottom: 5,
    fontSize: 14,
    fontWeight: 500,
    color: "#545A72",
    lineHeight: 1.4,
  },
});
