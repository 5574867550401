import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import configureStore from "./store";

import App from "./components/App";

const main = document.querySelector("#main");
const initialState = (window && window.__INITIAL_STATE__) || {};
const store = configureStore(initialState);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  main,
);
