import * as React from "react";
import parse from "autosuggest-highlight/parse";
import { debounce } from "@mui/material/utils";
import {
  Autocomplete,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  Box,
  Grid,
} from "@mui/material";
import { MagnifyingGlass, MapPin, X } from "@phosphor-icons/react";
import { useTheme } from "@mui/styles";

function loadScript(src, position, id) {
  if (!position) return;

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const formatInitialAddress = (initialAddress) => {
  if (!initialAddress) return "";
  // If the initial address is an object (the same format as the output), format it into a string
  if (typeof initialAddress === "object") {
    return `${initialAddress.address1} ${initialAddress.address2}, ${initialAddress.city}, ${initialAddress.state} ${initialAddress.zipCode}`.replaceAll(
      " ,",
      ","
    );
  }
  return initialAddress;
};

/**
 * ANGooglePlacesInput component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.onChange - The function to be called when the value changes.
 * @param {string | object} props.value - The current value of the input. If it is an object, it should be in the format { address1, address2, city, zipCode, state, country }.
 * @param {string} props.name - The name of the input.
 * @param {boolean} props.error - If true, the label is displayed in an error state.
 * @param {string} props.helperText - The helper text to display.
 * @param {Function} props.onBlur - The function to be called when the input loses focus.
 * @param {string} props.label - The label for the input.
 * @param {string} props.placeholder - The placeholder text for the input.
 * @returns {JSX.Element} The ANGooglePlacesInput component.
 */
export default function ANGooglePlacesInput({
  // This externalValue is to allow to "control" the component from outside,
  // but in fact it is not controlling the internal state, it is just setting the initial value
  // with externalValue, and setting that externalValue using the onChange prop, so it can
  // be in sync with the parent component.
  // When selecting an address, the externalValue is updated with the selected address
  // in the object format: { address1, address2, city, zipCode, state, country }.
  // NOTE: That object format is not used internally, it is just the output format.
  onChange = () => {},
  value: externalValue = "",
  name = "address",
  error,
  helperText,
  onBlur = () => {},
  label = "Address",
  placeholder = "Search address",
}) {
  const [value, setValue] = React.useState(null);
  // Start with the externalValue if provided
  const [inputValue, setInputValue] = React.useState(formatInitialAddress(externalValue));
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);
  const autocompleteService = React.useRef(null);
  const placesService = React.useRef(null);
  const theme = useTheme();

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_PLACES_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 400),
    []
  );

  function onPredictionSelect(prediction) {
    if (!placesService.current && window.google) {
      placesService.current = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );
    }
    if (!placesService.current) return undefined;

    placesService.current.getDetails({ placeId: prediction.place_id }, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        const details = extractDetails(place);
        onChange({ target: { name, value: details } });
      }
    });
  }

  function extractDetails(place) {
    let addressDetails = {
      address1: "",
      address2: "",
      city: "",
      zipCode: "",
      state: "",
      country: "",
    };
    const typeToDetailMap = {
      street_number: "address1",
      route: "address1",
      subpremise: "address2",
      locality: "city",
      postal_code: "zipCode",
      administrative_area_level_1: "state",
      country: "country",
    };
    place.address_components.forEach((component) => {
      const types = component.types;
      types.forEach((type) => {
        if (typeToDetailMap[type]) {
          if (type === "route" && addressDetails.address1) {
            addressDetails.address1 += ` ${component.long_name}`;
          } else if (type !== "route") {
            if (["administrative_area_level_1", "country"].includes(type)) {
              addressDetails[typeToDetailMap[type]] = component.short_name;
            } else addressDetails[typeToDetailMap[type]] = component.long_name;
          }
        }
      });
    });

    return addressDetails;
  }

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) return undefined;

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue, componentRestrictions: { country: "us" } }, (results) => {
      if (active) {
        let newOptions = [];
        if (value) newOptions = [value];
        if (results) newOptions = [...newOptions, ...results];
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      id="an-google-places-input"
      sx={{
        minWidth: 200,
        "&& .MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
          padding: theme.spacing(0, 4),
          "& .MuiAutocomplete-input": {
            padding: 0,
          },
        },
      }}
      getOptionLabel={(option) => (typeof option === "string" ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText="No locations"
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        onPredictionSelect(newValue);
      }}
      onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
      renderInput={(params) => {
        const { InputProps, inputProps, ...rest } = params;
        return (
          <TextField
            {...rest}
            label={label}
            placeholder={placeholder}
            fullWidth
            inputProps={{ ...inputProps, value: inputValue }}
            InputProps={{
              ...InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <MapPin weight="duotone" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {(value || inputValue) && (
                    <IconButton
                      onClick={() => {
                        setValue(null);
                        setInputValue("");
                        onChange({ target: { name, value: "" } });
                      }}
                    >
                      <X />
                    </IconButton>
                  )}
                  <MagnifyingGlass />
                </InputAdornment>
              ),
            }}
            error={error}
            helperText={helperText}
            onBlur={() => onBlur({ target: { name } })}
          />
        );
      }}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        const matches = option.structured_formatting.main_text_matched_substrings || [];

        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length])
        );
        return (
          <li key={key} {...optionProps}>
            <Grid container sx={{ alignItems: "center" }}>
              <Grid item sx={{ display: "flex", width: 44 }}>
                <InputAdornment position="start">
                  <MapPin weight="duotone" size={20} />
                </InputAdornment>
              </Grid>
              <Grid item sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}>
                {parts.map((part, index) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? "bold" : "regular" }}
                  >
                    {part.text}
                  </Box>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
