import React from "react";
import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { dateWithoutTimezone } from "../../../../utils/dateWithoutTimezone";
import { format } from "date-fns";
import styles from "../../styles";
import Chip from "@mui/material/Chip";
import ErrorIcon from "@mui/icons-material/Error";
import DoneIcon from "@mui/icons-material/Done";
import CancelIcon from "@mui/icons-material/Cancel";
import { getAge } from "../../../../utils/getAge";

import { convertTokens } from "@date-fns/upgrade/v2";
import { formatTimeZoneToAbbreviation } from "utils/formatTimeZoneToAbbreviation";

const Badge = withStyles({
  root: {
    backgroundColor: "#d1fae5",
    color: "#059669",
    fontWeight: 500,
    height: 35,
    borderRadius: 5,
    border: "1px solid #a7f3d0",
    marginTop: 10,
    "& .MuiChip-icon": {
      color: "#059669",
      height: 16,
      width: 16,
    },
    "& .MuiChip-label": {
      fontWeight: 500,
      fontSize: 13,
      letterSpacing: "0.34px",
      marginBottom: 2,
    },
  },
  colorSecondary: {
    backgroundColor: "#fee2e2",
  },
})(Chip);

const BasicInformation = (props) => {
  const mapWorkflowStatusToStatus = (status) => {
    switch (status) {
      case "AWAITING_ONBOARDING":
        return "Request Received";
      case "AWAITING_ELIGIBILITY":
        return "Orientation Complete";
      case "ELIGIBLE":
      case "ELIGIBLE_NOW":
        return "Assessment Approval";
      case "ASSESSMENT_SCHEDULED":
      case "ASSESSMENT_SCHEDULED_NOW":
        return "Assessment";
      case "ASSESSMENT_COMPLETED":
      case "APPROVE_PLAN":
        return "Treatment Plan";
      case "AWAITING_INSURANCE_APPROVAL":
        return "Insurance Approval";
      case "INSURANCE_APPROVED":
      case "FULL_CUSTOMER":
        return "In Therapy";
    }
  };
  const { classes, customerDetails, formatPhoneNumber } = props;
  let clientInfo = customerDetails.children;
  let customerInfo = customerDetails.details;
  let clientClinician = customerDetails.clinician;
  return (
    <>
      <div className={classes.basicInfoHeading}>
        <Typography component="p">Basic Information</Typography>
      </div>
      <Divider />
      <div className={classes.basicInfo}>
        <List classes={{ root: classes.list }}>
          <ListItem>
            <ListItemText
              primary="Name"
              secondary={`${customerInfo?.name || "----"}  ${
                customerInfo?.child_last_name || "----"
              }`}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Status"
              secondary={
                customerInfo?.workflow_status
                  ? mapWorkflowStatusToStatus(customerInfo?.workflow_status)
                  : "----"
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Phone"
              secondary={
                customerInfo?.phone_number ? formatPhoneNumber(customerInfo?.phone_number) : "----"
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText primary="Family Contact Email" secondary={customerInfo?.email} />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Date of Birth"
              secondary={`${
                customerInfo?.dob
                  ? format(dateWithoutTimezone(customerInfo?.dob), convertTokens("Do MMMM YYYY")) +
                    " - " +
                    getAge(dateWithoutTimezone(customerInfo?.dob)) +
                    " years old"
                  : "----"
              } `}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Time Zone"
              secondary={formatTimeZoneToAbbreviation(customerInfo?.timezone) || "----"}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Assigned Clinician"
              secondary={`${clientClinician?.first_name || "----"}  ${
                clientClinician?.last_name || ""
              }`}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Last Session"
              secondary={
                clientInfo?.last_session_time
                  ? format(
                      new Date(clientInfo?.last_session_time),
                      convertTokens("MMM D, YYYY h:mm a"),
                    )
                  : "----"
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Been a client since"
              secondary={
                customerInfo?.dateCreated
                  ? format(new Date(customerInfo?.dateCreated), convertTokens("Do MMMM YYYY"))
                  : "----"
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Deactivated"
              secondary={
                customerInfo?.disabled_date
                  ? format(new Date(customerInfo?.disabled_date), convertTokens("Do MMMM YYYY"))
                  : "----"
              }
            />
          </ListItem>
          <ListItem>
            {!customerInfo.is_active ? (
              customerInfo.save_for_later ? (
                <Badge
                  size="small"
                  icon={<ErrorIcon style={{ color: "#D97706", marginBottom: 2 }} />}
                  color="secondary"
                  style={{
                    backgroundColor: "#FFFBEB",
                    color: "#D97706",
                    border: "1px solid #D97706",
                  }}
                  label="Awaiting Intake"
                />
              ) : (
                <Badge
                  size="small"
                  icon={<ErrorIcon style={{ color: "#dc2626", marginBottom: 2 }} />}
                  color="secondary"
                  style={{ color: "#dc2626", border: "1px solid #fecaca" }}
                  label="Deactivated"
                />
              )
            ) : customerInfo.verified ? (
              <Badge
                size="small"
                icon={<DoneIcon style={{ marginBottom: 2 }} />}
                label="Has Logged In"
              />
            ) : (
              <Badge
                size="small"
                icon={<CancelIcon style={{ color: "#dc2626", marginBottom: 2 }} />}
                label="Hasn't Logged In"
                color="secondary"
                style={{ color: "#dc2626", border: "1px solid #fecaca" }}
              />
            )}
          </ListItem>
        </List>
      </div>
    </>
  );
};

export default withStyles(styles)(BasicInformation);
