import React from "react";
import { Chip, Avatar } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

import { COLOR_CHIPS } from "constants/colors";

const getStyle = ({ color, backgroundColor }) => ({
  borderColor: backgroundColor,
  color: color,
  backgroundColor: backgroundColor,
  margin: "4px",
});

export const StatusChip = ({ id, label, colorIndex, onDelete }) => {
  const { color, backgroundColor } = COLOR_CHIPS[colorIndex ?? 0];

  return (
    <Chip
      avatar={
        <Avatar style={{ backgroundColor: color, color: "#fff" }}>
          {label
            .split(" ")
            .slice(0, 2) // Take only the first two elements after splitting
            .map((n) => (n ? `${n[0]}`.toUpperCase() : ""))
            .join("")}
        </Avatar>
      }
      label={label}
      onDelete={() => onDelete(id)}
      deleteIcon={<CancelIcon style={{ color }} />}
      variant="outlined"
      style={getStyle({ color, backgroundColor })}
    />
  );
};
