import React from "react";
import SMSMessageFacility from "../Widgets/SMSMessageFacility";
import EmailLog from "../Widgets/EmailLog";
import PastClinicians from "../Widgets/PastClinicians";

const Communication = (props) => {
  const {
    classes,
    onSendSMSMessage,
    smsThread,
    smsFacilityDialogOpen,
    onOpenSMSFacilityDialog,
    onCloseSMSFacilityDialog,
    eCommConsentFormSigned,
    isAdmin,
    isCustomerCare,
    isDirector,
    userPermissions,
    sentEmailList,
    sentEmailsLoading,
    location,
    customerDetails,
    pastCliniciansLoading,
    clientId,
  } = props;

  return (
    <div className={classes.accordionContainer}>
      <SMSMessageFacility
        onSendSMSMessage={onSendSMSMessage}
        smsThread={smsThread}
        smsFacilityDialogOpen={smsFacilityDialogOpen}
        onOpenSMSFacilityDialog={onOpenSMSFacilityDialog}
        onCloseSMSFacilityDialog={onCloseSMSFacilityDialog}
        eCommConsentFormSigned={eCommConsentFormSigned}
        isAdmin={isAdmin}
        isCustomerCare={isCustomerCare}
        isDirector={isDirector}
        location={location}
        clientId={clientId}
        userPermissions={userPermissions}
      />
      {userPermissions?.view_email_log && (
        <EmailLog sentEmailList={sentEmailList} sentEmailsLoading={sentEmailsLoading} />
      )}
      {userPermissions?.view_client_past_clinicians && (
        <PastClinicians
          pastClinicians={customerDetails.pastClinicians}
          pastCliniciansLoading={pastCliniciansLoading}
        />
      )}
    </div>
  );
};
export default Communication;
