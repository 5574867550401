import SecureRequest from "../utils/securerequest";
import { buildUrl } from "../utils/buildUrl";

/**
 * @returns {Promise<{data: {id:number,name:string}[]}>} Language list
 */
export const getLanguages = async () => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return await Request.get(buildUrl(`/v3/languages`), {});
};
