import React from "react";
import DetailAccordion from "elements/DetailAccordion";
import withStyles from "@mui/styles/withStyles";
import styles from "../styles";
import { format } from "date-fns";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const PastClinicians = (props) => {
  const { classes, pastCliniciansLoading, pastClinicians } = props;
  return (
    <DetailAccordion
      title="Past Clinicians"
      loading={pastCliniciansLoading}
      enableScroll={true}
      maxHeight={550}
    >
      {pastClinicians.length > 0 ? (
        <Table className={classes.clientDetailsTable}>
          <TableHead>
            <TableRow>
              <TableCell style={{ color: "#3E3E3E", fontWeight: 500 }}>Reassignment Date</TableCell>
              <TableCell style={{ color: "#3E3E3E", fontWeight: 500 }}>Time</TableCell>
              <TableCell style={{ color: "#3E3E3E", fontWeight: 500 }}>Clinician Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pastClinicians.length > 0 &&
              pastClinicians.map((clinician, index) => (
                <TableRow
                  key={clinician.id}
                  style={index % 2 === 0 ? { backgroundColor: "#ECFAFA" } : null}
                >
                  <TableCell align="left" width="20%">
                    {clinician.switched_away ? format(new Date(clinician.switched_away), "PP") : ""}
                  </TableCell>
                  <TableCell align="left" width="20%">
                    {clinician.switched_away ? format(new Date(clinician.switched_away), "p") : ""}
                  </TableCell>
                  <TableCell align="left">
                    {clinician.first_name + " " + clinician.last_name}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      ) : pastClinicians.length === 0 ? (
        <Typography style={{ fontSize: 17 }}>No past clinicians found for this client</Typography>
      ) : null}
    </DetailAccordion>
  );
};
export default withStyles(styles)(PastClinicians);
