import { useQuery } from "react-query";

import { checkCognitoAuthAsync } from "api/auth";

/**
 * The `getCognitoAuth` function is a custom hook that retrieves Cognito session data
 * asynchronously.
 * @param {boolean} [enableOpt=true] - If `enableOpt` is set to `true`, the query
 * will be enabled and executed; if set to `false`, the query will not be enabled.
 * @returns {Object} The object containing the Cognito session data, loading state, and success state.
 */
const getCognitoAuth = (enableOpt = true) => {
  const { data, isLoading, isSuccess, refetch, ...rest } = useQuery(
    ["getCognitoSession"],
    () => checkCognitoAuthAsync(),
    {
      enabled: enableOpt,
      retry: 3,
    }
  );

  return {
    session: data || null,
    sessionLoading: isLoading,
    sessionSuccess: isSuccess,
    refetchCognitoAuth: refetch,
    ...rest,
  };
};

export default getCognitoAuth;
