export default (theme) => ({
  mainContainer: {
    width: "100%",
    height: "calc(100vh - 75px)",
    display: "flex",
    // position: "fixed",
    [theme.breakpoints.up("lg")]: {
      marginTop: 0,
    },
  },
  loadingContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  leftBlock: {
    position: "sticky",
    top: 0,
    zIndex: 1,
    height: "calc(100vh - 75px)",
    paddingTop: "70px",
    // width: "297px",
  },
  clientContainer: {
    height: "48%",
    display: "flex",
    flexDirection: "column",
    paddingTop: 20,
  },
  searchContainer: {
    height: "20%",
    display: "flex",
    alignItems: "center",
    padding: "0px 20px",
  },
  searchBar: {
    "& .MuiOutlinedInput-root": {
      height: 30,
      fontSize: 13,
      lineHeight: "18px",
      color: "#545a72",
      backgroundColor: "#fff",
      borderRadius: 11,
      fontWeight: 500,
      "& fieldset": {
        borderRadius: 11,
        border: "1px solid #c3c6d2",
        boxShadow: "inset 0px 0px 3px rgba(0, 0, 0, 0.25)",
      },
      "& .MuiInputAdornment-root": {
        color: "#878da5",
      },
    },
  },
  clientList: {
    height: "80%",
    width: "100%",
    overflowX: "hidden",
    overflowY: "auto",
    "& .MuiTypography-root": {
      padding: "8px",
    },
    "& p": {
      lineHeight: "25px",
      fontSize: 17,
      letterSpacing: "-2%",
      cursor: "pointer",
      padding: "15px 50px",
    },
  },
  selectedClient: {
    backgroundColor: "#8e42ca",
    boxShadow: "inset 0px 0px 5px rgba(87,18,141, 0.55)",
    color: "#fff",
    fontWeight: 500,
  },
  notSelectedClient: {
    color: "#3f4456",
    fontWeight: 400,
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: 10,
    padding: "0px 20px",
    color: "#2a2d39",
    letterSpacing: "-0.02em",
    "& p": {
      fontWeight: 500,
      fontSize: 22,
    },
    "& h4": {
      fontWeight: 500,
    },
  },
  practiceAreaContainer: {
    overflow: "auto",
  },
  instructionsContainer: {
    marginTop: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    flexWrap: "wrap",
    gap: 12,
    color: "#3f4456",
    "& h6": {
      fontSize: 14,
      lineHeight: "18px",
      fontWeight: 500,
      marginBottom: 10,
    },
    "& p": {
      fontSize: 12,
      lineHeight: "16px",

      fontWeight: 400,
    },
    "& button": {
      margin: "10px 0",
    },
  },
  calendarContainer: {
    width: "100%",
    maxHeight: "calc(100vh - 64px)",
  },
  showCanceledCallsLabel: {
    fontSize: 13,
    color: "#2a2d39",
    fontWeight: "500",
  },
  showCanceledCallsRoot: {
    marginLeft: 0,
    marginBottom: 10,
  },
});
