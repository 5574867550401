import React from "react";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { getColumnDefinitions } from "./ColumnDefinitions";
export const Table = (props) => {
  const {
    // style
    theme,
    // handlers
    handleEdit,
    setSortModel,
    setPaginationModel,
    // values
    rows,
    totalRowCount,
    userPermissions,
    clinicianCredentialDataLoading,
    sortModel,
    paginationModel,
  } = props;

  const processRowData = (row) => {
    return {
      ...row,
    };
  };

  // this permission controls ability to edit (same as view for now)
  const canModifyPhysician = userPermissions?.view_physician_utility;

  return (
    <Box
      sx={{
        width: "100%",
        "& .header": {
          width: "100%",
          backgroundColor: (theme) => theme.palette.grey[50],
        },
      }}
    >
      <DataGrid
        sx={{
          "& .MuiDataGrid-cell": {
            borderBottom: "1px solid #CDD3DA",
          },
        }}
        disableRowSelectionOnClick
        columnHeader
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        rows={rows?.map(processRowData) ?? []}
        columns={getColumnDefinitions(handleEdit, canModifyPhysician, theme)}
        loading={clinicianCredentialDataLoading}
        rowCount={totalRowCount ?? 0}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        paginationMode="server"
        pageSizeOptions={[5, 10, 20]}
        page={paginationModel?.page ?? 0}
        TablePaginationActionsWrapped
        sortModel={sortModel}
        onSortModelChange={setSortModel}
        sortMode="server"
        initialState={{
          pagination: {
            paginationModel,
            paginationMode: "server",
          },
          sorting: {
            sortModel,
            sortMode: "server",
          },
          columns: {
            columnVisibilityModel: {
              action: canModifyPhysician,
            },
          },
        }}
      />
    </Box>
  );
};
