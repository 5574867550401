/**
 * The function `getBehaviorScore` categorizes a numerical value into different score levels based on
 * predefined ranges.
 * @param {Number} value - The numerical value to categorize
 * @returns {String|null} A string indicating the score level based on the input
 * `value`. The possible return values are:
 * - "Low" for values between 1 and 9
 * - "Moderately Low" for values between 10 and 12
 * - "Adequate" for values between 13 and 17
 * - "Moderately High" for values between 18 and 20
 * - "High" for values between 21 and 24
 * - "undefined score" for values outside the defined ranges
 * - `null` if the input value is not a number
 */
export function getBehaviorScore(value) {
  if (typeof value !== "number") {
    console.error("The value must be a number");
    return null;
  }

  if (value >= 1 && value <= 9) {
    return "Low";
  }

  if (value >= 10 && value <= 12) {
    return "Moderately Low";
  }

  if (value >= 13 && value <= 17) {
    return "Adequate";
  }

  if (value >= 18 && value <= 20) {
    return "Moderately High";
  }

  if (value >= 21 && value <= 24) {
    return "High";
  }

  return "undefined score";
}

/**
 * The function `getDeltaPercent` calculates the percentage change between two values and returns an object.
 * @param {?Number} currentValue - The current value to compare with the `previousValue`
 * @param {?Number} previousValue - The previous value to compare with the `currentValue`
 * @returns {{ delta: String, status: String }} An object with the following properties:
 * - `delta`: The percentage change between the two values.
 * - `status`: A string indicating the status of the change. Possible values are: "up", "down", or "equal".
 */
export function getDeltaPercent(currentValue, previousValue) {
  if (!currentValue || !previousValue) {
    return {
      delta: null,
      status: null,
    };
  }

  const delta = parseFloat(currentValue) / parseFloat(previousValue);
  let status = "equal";

  if (delta > 1) {
    status = "up";
  }

  if (delta < 1) {
    status = "down";
  }

  return {
    delta: delta.toFixed(2),
    status,
  };
}

/**
 * The function `updateMonths` updates the month of a given date by adding or subtracting the provided
 * number of months.
 * @param {String} date - The date to update
 * @param {Number} months - The number of months to add or subtract
 * @param {Boolean} isAdd - A boolean indicating whether to add or subtract the months
 * @returns {Date} A new date object with the updated month
 */
export function updateMonths(date, months, isAdd) {
  const newDate = new Date(date);
  newDate.setMonth(newDate.getMonth() + (isAdd ? months : -months));
  return newDate;
}

/**
 * The function `getMaxAndMin` calculates the maximum and minimum limits for the Y-axis in a graph
 * @param {Number[]} values - An array of numbers.
 * @returns {{ max: Number, min: Number }} An object with the maximum and minimum limits for the Y-axis.
 */
export function getMaxAndMin(values) {
  if (!Array.isArray(values)) {
    throw new Error("The input values must be an array");
  }

  const orderedValues = Float32Array.from(values).sort();

  let max = orderedValues[orderedValues.length - 1];
  let min = orderedValues[0];

  return {
    max: max > 79 ? max + 20 : 100,
    min: min < 21 ? 0 : min - 20,
  };
}

/**
 * The `formatAssessmentDate` function takes a date string as input, converts it to a formatted date in
 * the "month day, year" format, and returns the formatted date.
 * @param {String} dateString - The date string to format (e.g., "2025-11-16T00:00:00Z")
 * @returns {String} The formatted date string in the format "Month
 * Day, Year" (e.g., `November 16, 2025`). If the `dateString` is not provided returns `-`.
 */
export function formatAssessmentDate(dateString) {
  if (!dateString) {
    return "-";
  }

  const date = new Date(dateString);
  const formatter = new Intl.DateTimeFormat("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
  const formattedDate = formatter.format(date);

  return formattedDate;
}
