import React from "react";
import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { detect } from "detect-browser";
import Button from "@mui/material/Button";
import testMicEffect from "../../assets/videoTestMicSound.mp3";
import WelcomeDialog from "./WelcomeDialog";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import DoneOutline from "@mui/icons-material/DoneOutline";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import { getCredentialsFromIdentityPool } from "utils/aws";

const CancelToken = axios.CancelToken;
let cancel;

const browser = detect();
let operating_system = null;
let browser_type = null;
let browser_version = null;
if (browser) {
  operating_system = browser.os.charAt(0).toUpperCase() + browser.os.slice(1);
  browser_type = browser.name.charAt(0).toUpperCase() + browser.name.slice(1);
  browser_version = browser.version;
}
const getInitialSpeedTestState = () => {
  return {
    fileList: [
      "temp_3.1MB_file",
      "temp_4.2MB_file",
      "temp_5.2MB_file",
      "temp_6.3MB_file",
      "temp_7.3MB_file",
      "temp_8.4MB_file",
      "temp_9.4MB_file",
      "temp_10.5MB_file",
      "temp_11.5MB_file",
      "temp_12.6MB_file",
      "temp_13.6MB_file",
      "temp_14.7MB_file",
      "temp_15.7MB_file",
    ],
    uploadFileList: ["temp_2MB_file", "temp_3MB_file", "temp_4MB_file", "temp_5MB_file"],
    uploadDocument: null,
    uploadSpeedLog: [],
    downloadSpeedLog: [],
    averageDownloadMbps: null,
    averageUploadMbps: null,
    downloadRound: 0,
    uploadRound: 0,
    cancelled: false,
    internetConnectionLoading: false,
    downloadTestEnded: false,
    uploadTestEnded: false,
  };
};
const getInitialTestState = (props) => {
  return {
    welcomeDialogOpen: false,
    testingCompleted: false,
    permissionError: false,
    permissionErrorMessage: null,
    operatingSystem: null,
    browserInfo: null,
    isWebRTCSupported: null,
    isWebSocketsSupported: null,
    isWebAudioApiSupported: null,
    isScreenSharingSupported: null,
    deviceInformationLoading: false,
    microphoneLoading: false,
    speakerLoading: false,
    cameraLoading: false,
    audioInputs: null,
    cameraInputs: null,
    audioOutputs: null,
    currentMicrophone: null,
    currentSpeaker: null,
    currentCamera: null,
    microphoneTestOn: false,
    microphoneTestEnded: false,
    speakerTestOn: false,
    speakerTestEnded: false,
    cameraTestOn: false,
    cameraTestEnded: false,
    deviceInformation: {
      success: false,
      error: false,
      warning: false,
    },
    internetConnection: {
      success: false,
      error: false,
      warning: false,
    },
    microphoneTesting: {
      success: false,
      error: false,
      warning: false,
    },
    speakerTesting: {
      success: false,
      error: false,
      warning: false,
    },
    cameraTesting: {
      success: false,
      error: false,
      warning: false,
    },
  };
};

class WebrtcTestContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      ...getInitialSpeedTestState(),
      ...getInitialTestState(),
      microphoneStream: null,
      cameraStream: null,
    };
  }

  componentDidMount() {
    this.setState({ welcomeDialogOpen: true }, async () => {
      try {
        await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
        await this.delay(1500);
        this.setState({ welcomeDialogOpen: false }, () => {
          this.initTests();
        });
      } catch (err) {
        this.setState({ permissionError: true, permissionErrorMessage: err });
        console.log("err", err);
      }
    });
  }

  componentWillUnmount() {
    // Stop microphone stream tracks
    if (this.state.microphoneStream) {
      this.state.microphoneStream.getTracks().forEach((track) => track.stop());
    }

    // Stop camera stream tracks
    if (this.state.cameraStream) {
      this.state.cameraStream.getTracks().forEach((track) => track.stop());
    }
  }

  delay = (milliseconds) => new Promise((res) => setTimeout(res, milliseconds));
  booleanToDisplay = (val) => (val ? "Yes" : "No");

  closeDialog = () => {
    this.setState({ welcomeDialogOpen: false });
    this.initTests();
  };

  initTests = async () => {
    this.setState({
      deviceInformationLoading: true,
    });
    await this.delay(2000);
    await this.getDeviceInformation();
    await this.findDownloadSpeedAverage();
    await this.testUserMicrophone();
    await this.testUserSpeaker();
    await this.testUserCamera();
  };

  getDeviceInformation = async () => {
    let operatingSystem = operating_system;
    let browserInfo = `${browser_type}, version: ${browser_version}`;

    let isWebRTCSupported =
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia ||
      window.RTCPeerConnection;

    let isWebSocketsSupported = window.WebSocket || window.MozWebSocket;

    let isWebAudioApiSupported =
      window.AudioContext ||
      window.webkitAudioContext ||
      window.mozAudioContext ||
      window.msAudioContext;

    let isScreenSharingSupported;
    if (navigator?.mediaDevices && "getDisplayMedia" in navigator.mediaDevices) {
      isScreenSharingSupported = true;
    } else {
      isScreenSharingSupported = false;
    }

    this.setState(
      {
        operatingSystem,
        browserInfo,
        isWebRTCSupported,
        isWebSocketsSupported,
        isWebAudioApiSupported,
        isScreenSharingSupported,
      },
      () => {
        let deviceInformation = this.state.deviceInformation;
        let checkList = [
          operatingSystem,
          browserInfo,
          isWebRTCSupported,
          isWebSocketsSupported,
          isWebAudioApiSupported,
          isScreenSharingSupported,
        ];
        deviceInformation.warning = checkList.some((item) => !item);
        deviceInformation.success = checkList.every((item) => item);
        deviceInformation.error = checkList.every((item) => !item);
        this.setState({ deviceInformationLoading: false, deviceInformation });
      }
    );
  };

  findDownloadSpeedAverage = async () => {
    const { fileList } = this.state;
    this.setState({
      ...getInitialSpeedTestState(),
      internetConnectionLoading: true,
    });

    for (let i = 0; i < fileList.length; i++) {
      await this.handleDownloadFile(fileList[i]);
    }
    let total = this.state.downloadSpeedLog.reduce((total, el) => {
      return total + Number(el);
    }, 0);

    if (this.state.cancelled) {
      this.setState({
        internetConnectionLoading: false,
      });
    } else {
      this.setState({
        averageDownloadMbps: +(total / 13).toFixed(2),
        downloadTestEnded: true,
      });
    }
    await this.findUploadSpeedAverage();
  };

  handleDownloadFile = async (fileName) => {
    try {
      const startTime = await new Date().getTime();
      const baseUrl = process.env.BASE_URL;
      const downloadUrl = `${baseUrl}/download/file/${fileName}?cache=${new Date().getTime()}`;

      let resp = await axios({
        url: downloadUrl,
        method: "GET",
        responseType: "blob",
        cancelToken: new CancelToken(function executor(c) {
          cancel = c;
        }),
      });
      const endTime = new Date().getTime();
      let loadTime = (endTime - startTime) / 1000;
      let speed = this.findSpeed(loadTime, resp.data.size);
      this.setState((prevState) => ({
        downloadSpeedLog: [...prevState.downloadSpeedLog, speed],
      }));
    } catch (err) {
      console.log(err);
    }
  };

  findUploadSpeedAverage = async () => {
    const { uploadFileList } = this.state;
    try {
      for (let i = 0; i < uploadFileList.length; i++) {
        const credentials = await getCredentialsFromIdentityPool();
        const s3 = new AWS.S3({
          credentials: await credentials(),
        });
        const getObjectParams = {
          Bucket: process.env.AWS_PUBLIC_BUCKET,
          Key: `speedtest/${uploadFileList[i]}`,
        };
        const file = await s3.getObject(getObjectParams).promise();
        this.setState({ uploadDocument: file });
        if (this.state.cancelled) {
          break;
        }
        await this.handleUploadFile(uploadFileList[i]);
      }

      let total = this.state.uploadSpeedLog.reduce((total, el) => {
        return total + Number(el);
      }, 0);
      if (this.state.cancelled) {
        this.setState({ ...getInitialSpeedTestState });
      } else {
        this.setState(
          {
            averageUploadMbps: +(total / 4).toFixed(2),
          },
          () => {
            const { averageDownloadMbps, averageUploadMbps } = this.state;
            let internetConnection = this.state.internetConnection;
            internetConnection.error = averageUploadMbps <= 2 || averageDownloadMbps <= 5;
            internetConnection.warning =
              (averageUploadMbps > 2 && averageUploadMbps <= 5) ||
              (averageDownloadMbps > 5 && averageDownloadMbps <= 20);
            internetConnection.success = averageUploadMbps > 5 || averageDownloadMbps > 20;

            this.setState({
              uploadTestEnded: true,
              internetConnectionLoading: false,
              internetConnection,
            });
          }
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleUploadFile = async (fileName) => {
    const { uploadDocument } = this.state;
    try {
      const startTime = new Date().getTime();

      const credentials = await getCredentialsFromIdentityPool();
      const s3 = new AWS.S3({
        apiVersion: "2006-03-01",
        params: { Bucket: process.env.AWS_PUBLIC_BUCKET },
        credentials: await credentials(),
      });
      const putObjectParams = {
        Body: uploadDocument.Body,
        ContentType: uploadDocument.ContentType,
        Key: `speedtest/upload/${fileName}`,
      };
      await s3.putObject(putObjectParams).promise();
      const endTime = new Date().getTime();
      let loadTime = (endTime - startTime) / 1000;
      let speed = this.findSpeed(loadTime, uploadDocument.ContentLength);
      this.setState((prevState) => ({
        uploadSpeedLog: [...prevState.uploadSpeedLog, speed],
      }));
    } catch (err) {
      console.log(err);
    }
  };

  findSpeed = (seconds, size) => {
    let loadedBits = size * 8;
    let bps = (loadedBits / seconds).toFixed(2);
    let speedInMbps = (bps / 1000000).toFixed(2);
    return speedInMbps;
  };

  testUserMicrophone = async () => {
    let microphoneTesting = this.state.microphoneTesting;
    this.setState({
      microphoneLoading: true,
    });
    await this.delay(2000);
    if (navigator && navigator.mediaDevices && navigator.mediaDevices.enumerateDevices()) {
      let foundDevices = await navigator.mediaDevices.enumerateDevices();
      let audioInputs = foundDevices.filter(
        (val) => val.kind == "audioinput" && val.deviceId !== undefined
      );
      if (audioInputs.length == 0 || audioInputs.some((input) => !input.deviceId)) {
        microphoneTesting.error = true;
        this.setState({
          microphoneTestEnded: true,
          microphoneLoading: false,
          microphoneTesting,
        });
      } else {
        this.setState({ audioInputs, currentMicrophone: audioInputs[0].deviceId }, () => {
          this.startMicrophoneTesting();
        });
      }
    } else {
      microphoneTesting.error = true;
      this.setState({
        microphoneTestEnded: true,
        microphoneLoading: false,
        microphoneTesting,
      });
    }
  };

  startMicrophoneTesting = async () => {
    const { currentMicrophone } = this.state;
    let microphoneTesting = this.state.microphoneTesting;
    if (navigator.mediaDevices.getUserMedia !== null) {
      const constraints = {
        video: false,
        audio: {
          deviceId: currentMicrophone,
        },
      };
      try {
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        this.setState({ microphoneStream: stream });
        const audioCtx = new AudioContext();
        this.analyser = audioCtx.createAnalyser();
        this.analyser.fftSize = 256;
        this.audioSrc = audioCtx.createMediaStreamSource(stream);
        this.audioSrc.connect(this.analyser);
        let bufferLength = this.analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);
        var canvas = document.getElementById("test-microphone");
        canvas.width = 50;
        canvas.height = 35;
        var canvasCtx = canvas.getContext("2d");
        var WIDTH = canvas.width;
        var HEIGHT = canvas.height;
        this.drawTimeData = () => {
          canvasCtx.clearRect(0, 0, WIDTH, HEIGHT);
          this.audioVisualizationId = requestAnimationFrame(this.drawTimeData);
          this.analyser.getByteFrequencyData(dataArray);
          var barWidth = (WIDTH / bufferLength) * 7;
          var barHeight;
          var x = 0;
          for (var i = 0; i < bufferLength; i++) {
            barHeight = dataArray[i];
            canvasCtx.fillStyle = `rgb(142,66,202)`;
            canvasCtx.fillRect(x, HEIGHT - barHeight / 7, barWidth, barHeight / 7);
            x += barWidth + 1;
          }
        };
        this.drawTimeData();
        microphoneTesting.success = true;
        this.setState({
          microphoneTestOn: true,
          microphoneTestEnded: true,
          microphoneLoading: false,
          microphoneTesting,
        });
      } catch (err) {
        console.log(err);
        microphoneTesting.error = true;
        this.setState({
          microphoneLoading: false,
          microphoneTestEnded: true,
          microphoneTesting,
        });
      }
    }
  };

  endUserMicrophoneTesting = () => {
    cancelAnimationFrame(this.audioVisualizationId);
    this.analyser.disconnect();
    this.audioSrc.disconnect();
    this.setState({ microphoneTestOn: false });
  };

  handleMicrophoneChange = (e) => {
    if (this.state.microphoneTestOn) {
      this.endUserMicrophoneTesting();
    }
    let audioDeviceId = e.target.value;
    this.setState({ currentMicrophone: audioDeviceId }, () => {
      this.startMicrophoneTesting();
    });
  };

  testUserSpeaker = async () => {
    let speakerTesting = this.state.speakerTesting;
    this.setState({
      speakerLoading: true,
    });
    await this.delay(2000);
    if (navigator && navigator.mediaDevices && navigator.mediaDevices.enumerateDevices()) {
      let foundDevices = await navigator.mediaDevices.enumerateDevices();
      let audioOutputs = foundDevices.filter(
        (val) => val.kind == "audiooutput" && val.deviceId !== undefined
      );
      if (audioOutputs.length == 0 || audioOutputs.some((input) => !input.deviceId)) {
        speakerTesting.error = true;
        this.setState({
          speakerLoading: false,
          speakerTestEnded: true,
          speakerTesting,
        });
      } else {
        speakerTesting.success = true;
        this.setState({
          audioOutputs,
          currentSpeaker: audioOutputs[0].deviceId,
          speakerTestEnded: true,
          speakerLoading: false,
          speakerTesting,
        });
      }
    } else {
      speakerTesting.error = true;
      this.setState({
        speakerLoading: false,
        speakerTestEnded: true,
        speakerTesting,
      });
    }
  };

  handleSpeakerChange = (e) => {
    let speakerDeviceId = e.target.value;
    this.setState({ currentSpeaker: speakerDeviceId });
  };

  testSpeakerAudio = () => {
    let audio = document.getElementById("test-speaker");
    const { currentSpeaker } = this.state;
    audio.addEventListener("ended", () => {
      this.setState({ speakerTestOn: false });
    });
    audio.setSinkId(currentSpeaker).then(() => {
      audio.play();

      this.setState({ speakerTestOn: true });
    });
  };

  testUserCamera = async () => {
    let cameraTesting = this.state.cameraTesting;
    this.setState({
      cameraLoading: true,
    });
    await this.delay(2000);
    if (navigator && navigator.mediaDevices && navigator.mediaDevices.enumerateDevices()) {
      let foundDevices = await navigator.mediaDevices.enumerateDevices();
      let cameraInputs = foundDevices.filter(
        (val) => val.kind == "videoinput" && val.deviceId !== undefined
      );
      if (cameraInputs.length == 0 || cameraInputs.some((input) => !input.deviceId)) {
        cameraTesting.error = true;
        this.setState({
          cameraLoading: false,
          cameraTestEnded: true,
          cameraTesting,
          testingCompleted: true,
        });
      } else {
        this.setState(
          {
            cameraInputs,
            currentCamera: cameraInputs[0].deviceId,
          },
          () => {
            this.startCameraTesting();
          }
        );
      }
    } else {
      cameraTesting.error = true;
      this.setState({
        cameraLoading: false,
        cameraTestEnded: true,
        cameraTesting,
        testingCompleted: true,
      });
    }
  };

  startCameraTesting = () => {
    const { currentCamera, cameraStream } = this.state;
    let cameraTesting = this.state.cameraTesting;

    if (cameraStream) {
      cameraStream.getTracks().forEach((track) => {
        track.stop();
      });
    }
    const constraints = {
      audio: false,
      video: {
        deviceId: currentCamera,
      },
    };

    this.checkForOlderBrowsers();

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        this.setState({ cameraStream: stream });
        let video = document.getElementById("test-video");
        // Older browsers may not have srcObject
        if (!!video && "srcObject" in video) {
          video.srcObject = stream;
        } else {
          // Avoid using this in new browsers, as it is going away.
          video.src = window.URL.createObjectURL(stream);
        }
        cameraTesting.success = true;
        this.setState({
          cameraTestOn: true,
          cameraTestEnded: true,
          cameraLoading: false,
          cameraTesting,
          testingCompleted: true,
        });
      })
      .catch((err) => {
        console.log(err.name + ": " + err.message);
        cameraTesting.error = true;
        this.setState({
          cameraLoading: false,
          cameraTestEnded: true,
          cameraTesting,
          testingCompleted: true,
        });
      });
  };

  handleCameraChange = (e) => {
    const { cameraStream } = this.state;
    let cameraDeviceId = e.target.value;
    if (cameraStream) {
      cameraStream.getTracks().forEach((track) => {
        track.stop();
      });
    }
    const constraints = {
      audio: false,
      video: {
        deviceId: cameraDeviceId,
      },
    };
    this.checkForOlderBrowsers();
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        this.setState({ currentCamera: cameraDeviceId });
        this.setState({ cameraStream: stream });
        let video = document.getElementById("test-video");
        // Older browsers may not have srcObject
        if (!!video && "srcObject" in video) {
          video.srcObject = stream;
        } else {
          // Avoid using this in new browsers, as it is going away.
          video.src = window.URL.createObjectURL(stream);
        }
      })
      .catch((err) => {
        console.log(err.name + ": " + err.message);
        cameraTesting.error = true;
        this.setState({
          cameraLoading: false,
          cameraTestEnded: true,
          cameraTesting,
        });
      });
  };

  checkForOlderBrowsers = () => {
    // Older browsers might not implement mediaDevices at all, so we set an empty object first
    if (navigator.mediaDevices === undefined) {
      navigator.mediaDevices = {};
    }
    // Some browsers partially implement mediaDevices. We can't just assign an object
    // with getUserMedia as it would overwrite existing properties.
    // Here, we will just add the getUserMedia property if it's missing.
    if (navigator.mediaDevices.getUserMedia === undefined) {
      navigator.mediaDevices.getUserMedia = function (constraints) {
        // First get ahold of the legacy getUserMedia, if present
        var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

        // Some browsers just don't implement it - return a rejected promise with an error
        // to keep a consistent interface
        if (!getUserMedia) {
          return Promise.reject(new Error("getUserMedia is not implemented in this browser"));
        }
        // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
        return new Promise(function (resolve, reject) {
          getUserMedia.call(navigator, constraints, resolve, reject);
        });
      };
    }
  };

  repeatTest = () => {
    this.setState({ ...getInitialTestState(), ...getInitialSpeedTestState() }, () => {
      this.initTests();
    });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    const { classes } = this.props;
    const {
      deviceInformationLoading,
      internetConnectionLoading,
      deviceInformation,
      operatingSystem,
      browserInfo,
      isWebRTCSupported,
      isWebSocketsSupported,
      isWebAudioApiSupported,
      isScreenSharingSupported,
      downloadTestEnded,
      uploadTestEnded,
      averageDownloadMbps,
      averageUploadMbps,
      internetConnection,
      audioInputs,
      audioOutputs,
      cameraInputs,
      currentMicrophone,
      currentSpeaker,
      currentCamera,
      microphoneTestOn,
      microphoneLoading,
      microphoneTesting,
      microphoneTestEnded,
      speakerTestOn,
      speakerLoading,
      speakerTesting,
      speakerTestEnded,
      cameraTestOn,
      cameraLoading,
      cameraTesting,
      cameraTestEnded,
      testingCompleted,
    } = this.state;

    return (
      <div className={classes.container}>
        <WelcomeDialog closeDialog={this.closeDialog} {...this.state} {...this.props} />
        {this.props.location?.state?.fromLobby && (
          <Button
            variant="text"
            color="default"
            onClick={this.goBack}
            className={classes.goBackButton}
            startIcon={<ChevronLeftIcon />}
          >
            Back To Waiting Room
          </Button>
        )}
        <Typography component="h3" variant="h4" style={{ fontWeight: 500 }}>
          {testingCompleted ? "Test Complete" : "Test"}
        </Typography>
        <audio id="test-speaker">
          <source src={testMicEffect} type="audio/mpeg" />
        </audio>
        <div className={classes.testsContainer}>
          <div className={classes.sectionTitleContainer}>
            {deviceInformationLoading ? (
              <CircularProgress style={{ color: "#42CACA" }} size={20} />
            ) : deviceInformation.error ? (
              <div className={classes.indicator} style={{ backgroundColor: "#dc2626" }} />
            ) : deviceInformation.success ? (
              <div className={classes.indicator} style={{ backgroundColor: "#059669" }} />
            ) : deviceInformation.warning ? (
              <div className={classes.indicator} style={{ backgroundColor: "#d97706" }} />
            ) : (
              <div className={classes.indicator} />
            )}
            <Typography component="p" variant="h4" className={classes.sectionTitle}>
              Device Information
            </Typography>
          </div>
          <Grid container item xs={12} sm={12} spacing={1} className={classes.testBlock}>
            <Grid item align="center" sm={1}>
              {operatingSystem == null ? (
                <div className={classes.backupContainer} />
              ) : operatingSystem ? (
                <CheckIcon className={classes.successIcon} />
              ) : (
                <ClearIcon className={classes.errorIcon} />
              )}
            </Grid>
            <Grid item xs={5} sm={5} align="left">
              <Typography>Operating System</Typography>
            </Grid>
            <Grid item xs={6} sm={6} align="left">
              <Typography>{operatingSystem}</Typography>
            </Grid>
            <Grid item align="center" sm={1}>
              {browserInfo == null ? (
                <div className={classes.backupContainer} />
              ) : browserInfo ? (
                <CheckIcon className={classes.successIcon} />
              ) : (
                <ClearIcon className={classes.errorIcon} />
              )}
            </Grid>
            <Grid item xs={5} sm={5} align="left">
              <Typography>Browser</Typography>
            </Grid>
            <Grid item xs={6} sm={6} align="left">
              <Typography>{browserInfo}</Typography>
            </Grid>
            <Grid item align="center" sm={1}>
              {isWebRTCSupported == null ? (
                <div className={classes.backupContainer} />
              ) : isWebRTCSupported ? (
                <CheckIcon className={classes.successIcon} />
              ) : (
                <CustomTooltip
                  heading="WebRTC not supported"
                  text="It looks like your browser doesn't support Webrtc that we use its features during a video call, please run the test from a different browser."
                >
                  <ClearIcon className={classes.errorIcon} />
                </CustomTooltip>
              )}
            </Grid>
            <Grid item xs={5} sm={5} align="left">
              <Typography>Browser supports WebRTC?</Typography>
            </Grid>
            <Grid item xs={6} sm={6} align="left">
              <Typography>
                {isWebRTCSupported == null ? null : this.booleanToDisplay(isWebRTCSupported)}
              </Typography>
            </Grid>
            <Grid item align="center" sm={1}>
              {isWebSocketsSupported == null ? (
                <div className={classes.backupContainer} />
              ) : isWebSocketsSupported ? (
                <CheckIcon className={classes.successIcon} />
              ) : (
                <CustomTooltip
                  heading="WebSockets not supported"
                  text="It looks like your browser doesn't support Web Sockets Api, please run the test from a different browser."
                >
                  <ClearIcon className={classes.errorIcon} />
                </CustomTooltip>
              )}
            </Grid>
            <Grid item xs={5} sm={5} align="left">
              <Typography>Browser supports WebSockets API?</Typography>
            </Grid>
            <Grid item xs={6} sm={6} align="left">
              <Typography>
                {isWebSocketsSupported == null
                  ? null
                  : this.booleanToDisplay(isWebSocketsSupported)}
              </Typography>
            </Grid>
            <Grid item align="center" sm={1}>
              {isWebAudioApiSupported == null ? (
                <div className={classes.backupContainer} />
              ) : isWebAudioApiSupported ? (
                <CheckIcon className={classes.successIcon} />
              ) : (
                <CustomTooltip
                  heading="Web Audio not supported"
                  text="It looks like your browser doesn't support Web Audio Api, please run the test from a different browser."
                >
                  <ClearIcon className={classes.errorIcon} />
                </CustomTooltip>
              )}
            </Grid>
            <Grid item xs={5} sm={5} align="left">
              <Typography>Browser supports WebAudio API?</Typography>
            </Grid>
            <Grid item xs={6} sm={6} align="left">
              <Typography>
                {isWebAudioApiSupported == null
                  ? null
                  : this.booleanToDisplay(isWebAudioApiSupported)}
              </Typography>
            </Grid>
            <Grid item align="center" sm={1}>
              {isScreenSharingSupported == null ? (
                <div className={classes.backupContainer} />
              ) : isScreenSharingSupported ? (
                <CheckIcon className={classes.successIcon} />
              ) : (
                <CustomTooltip
                  heading="Screen Sharing not supported"
                  text="It looks like your browser or device doesn't support screen sharing, please run the test from a different browser. If the
                                        same issue still continues, please try a different device."
                >
                  <ClearIcon className={classes.errorIcon} />
                </CustomTooltip>
              )}
            </Grid>
            <Grid item xs={5} sm={5} align="left">
              <Typography>Browser supports Screen Sharing?</Typography>
            </Grid>
            <Grid item xs={6} sm={6} align="left">
              <Typography>
                {isScreenSharingSupported == null
                  ? null
                  : this.booleanToDisplay(isScreenSharingSupported)}
              </Typography>
            </Grid>
          </Grid>

          <div className={classes.sectionTitleContainer}>
            {internetConnectionLoading ? (
              <CircularProgress style={{ color: "#42CACA" }} size={20} />
            ) : internetConnection.error ? (
              <div className={classes.indicator} style={{ backgroundColor: "#dc2626" }} />
            ) : internetConnection.success ? (
              <div className={classes.indicator} style={{ backgroundColor: "#059669" }} />
            ) : internetConnection.warning ? (
              <div className={classes.indicator} style={{ backgroundColor: "#d97706" }} />
            ) : (
              <div className={classes.indicator} />
            )}
            <Typography component="p" variant="h3" className={classes.sectionTitle}>
              Internet Connection
            </Typography>
          </div>
          <Grid container item xs={12} sm={12} spacing={1} className={classes.testBlock}>
            <Grid item align="center" sm={1}>
              {downloadTestEnded ? (
                averageDownloadMbps && averageDownloadMbps > 5 ? (
                  <CheckIcon className={classes.successIcon} />
                ) : (
                  <CustomTooltip
                    heading="Download performance low"
                    text="It looks like your device doesn't have a good download performance. Please close browser tabs you don't use, close other apps running on your computer and test your connection again."
                  >
                    <ClearIcon className={classes.errorIcon} />
                  </CustomTooltip>
                )
              ) : (
                <div className={classes.backupContainer} />
              )}
            </Grid>
            <Grid item xs={5} sm={5} align="left">
              <Typography>Download Speed</Typography>
            </Grid>
            <Grid item xs={6} sm={6} align="left">
              {averageDownloadMbps == null ? null : (
                <Typography>{averageDownloadMbps} mbps</Typography>
              )}
            </Grid>
            <Grid item align="center" sm={1}>
              {uploadTestEnded ? (
                averageUploadMbps && averageUploadMbps > 2 ? (
                  <CheckIcon className={classes.successIcon} />
                ) : (
                  <CustomTooltip
                    heading="Upload performance low"
                    text="It looks like your device doesn't have a good upload performance. Please close browser tabs you don't use, close other apps running on your computer and test your connection again."
                  >
                    <ClearIcon className={classes.errorIcon} />
                  </CustomTooltip>
                )
              ) : (
                <div className={classes.backupContainer} />
              )}
            </Grid>
            <Grid item xs={5} sm={5} align="left">
              <Typography>Upload Speed</Typography>
            </Grid>
            <Grid item xs={6} sm={6} align="left">
              {averageUploadMbps == null ? null : <Typography>{averageUploadMbps} mbps</Typography>}
            </Grid>
            <Grid item xs={12} sm={12} align="left">
              {downloadTestEnded && uploadTestEnded && testingCompleted && (
                <Button
                  variant="text"
                  onClick={this.findDownloadSpeedAverage}
                  className={classes.testConnectionButton}
                >
                  Test Connection
                </Button>
              )}
            </Grid>
          </Grid>

          <div className={classes.sectionTitleContainer}>
            {microphoneLoading ? (
              <CircularProgress style={{ color: "#42CACA" }} size={20} />
            ) : microphoneTesting.error ? (
              <div className={classes.indicator} style={{ backgroundColor: "#dc2626" }} />
            ) : microphoneTesting.success ? (
              <div className={classes.indicator} style={{ backgroundColor: "#059669" }} />
            ) : (
              <div className={classes.indicator} />
            )}
            <Typography component="p" variant="h3" className={classes.sectionTitle}>
              Microphone
            </Typography>
          </div>
          <Grid container item xs={12} sm={12} spacing={1} className={classes.testBlock}>
            <Grid item align="center" sm={1}>
              {microphoneTestEnded ? (
                audioInputs &&
                audioInputs.length > 0 &&
                audioInputs.every((input) => input.deviceId) ? (
                  <CheckIcon className={classes.successIcon} />
                ) : (
                  <CustomTooltip
                    heading="No microphone available"
                    text="It looks like your device doesn't have a working microphone. Check your system settings for an available microphone. If you have an external microphone, make sure that it is plugged in
                                            and connected securely. Check for any websites or applications that may use your microphone
                                            and disable it. Refresh the page, and run the test again. If you're still experiencing microphone problems after trying the
                                            above, please run the test from a different browser."
                  >
                    <ClearIcon className={classes.errorIcon} />
                  </CustomTooltip>
                )
              ) : (
                <div className={classes.backupContainer} />
              )}
            </Grid>
            <Grid item xs={5} sm={5} align="left">
              <Typography>Microphone Device</Typography>
            </Grid>
            <Grid item xs={6} sm={6} align="left">
              {microphoneTestEnded &&
                (audioInputs && audioInputs.length > 0 ? (
                  <TextField
                    value={currentMicrophone}
                    name="currentAudioInput"
                    onChange={this.handleMicrophoneChange}
                    select
                  >
                    <MenuItem disabled value={""} />
                    {audioInputs.map((option) => (
                      <MenuItem key={option.deviceId} value={option.deviceId}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : (
                  <Typography>No microphone device available</Typography>
                ))}
            </Grid>
            <Grid item xs={12} sm={12} align="left">
              {audioInputs &&
                audioInputs.length > 0 &&
                audioInputs.every((input) => input.deviceId) && (
                  <canvas id="test-microphone" className={classes.canvas}></canvas>
                )}
            </Grid>
          </Grid>

          <div className={classes.sectionTitleContainer}>
            {speakerLoading ? (
              <CircularProgress style={{ color: "#42CACA" }} size={20} />
            ) : speakerTesting.error ? (
              <div className={classes.indicator} style={{ backgroundColor: "#dc2626" }} />
            ) : speakerTesting.success ? (
              <div className={classes.indicator} style={{ backgroundColor: "#059669" }} />
            ) : (
              <div className={classes.indicator} />
            )}
            <Typography component="p" variant="h3" className={classes.sectionTitle}>
              Speaker
            </Typography>
          </div>
          <Grid container item xs={12} sm={12} spacing={1} className={classes.testBlock}>
            <Grid item align="center" sm={1}>
              {speakerTestEnded ? (
                audioOutputs &&
                audioOutputs.length > 0 &&
                audioOutputs.every((input) => input.deviceId) ? (
                  <CheckIcon className={classes.successIcon} />
                ) : (
                  <CustomTooltip
                    heading="No speaker available"
                    text="It looks like your device doesn't have a working speaker. Check your system settings for an available speaker. If you have an external speaker, make sure that it is plugged in and
                                            connected securely.  Check for any websites or applications that may use your speaker and
                                            disable it. Refresh the page, and run the test again. If you're still experiencing speaker problems after trying the
                                            above, please run the test from a different browser."
                  >
                    <ClearIcon className={classes.errorIcon} />
                  </CustomTooltip>
                )
              ) : (
                <div className={classes.backupContainer} />
              )}
            </Grid>
            <Grid item xs={5} sm={5} align="left">
              <Typography>Speaker Device</Typography>
            </Grid>
            <Grid item xs={6} sm={6} align="left">
              {speakerTestEnded &&
                (audioOutputs && audioOutputs.length > 0 ? (
                  <TextField
                    value={currentSpeaker}
                    name="currentAudioOutput"
                    onChange={this.handleSpeakerChange}
                    select
                  >
                    <MenuItem disabled value={""} />
                    {audioOutputs.map((option) => (
                      <MenuItem key={option.deviceId} value={option.deviceId}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : (
                  <Typography>No speaker device available</Typography>
                ))}
            </Grid>
            <Grid item xs={12} sm={12} align="left">
              {audioOutputs &&
                audioOutputs.length > 0 &&
                audioOutputs.every((input) => input.deviceId) && (
                  <Button variant="text" onClick={this.testSpeakerAudio} disabled={speakerTestOn}>
                    Test Speaker
                  </Button>
                )}
            </Grid>
          </Grid>

          <div className={classes.sectionTitleContainer}>
            {cameraLoading ? (
              <CircularProgress style={{ color: "#42CACA" }} size={20} />
            ) : cameraTesting.error ? (
              <div className={classes.indicator} style={{ backgroundColor: "#dc2626" }} />
            ) : cameraTesting.success ? (
              <div className={classes.indicator} style={{ backgroundColor: "#059669" }} />
            ) : (
              <div className={classes.indicator} />
            )}
            <Typography component="p" variant="h3" className={classes.sectionTitle}>
              Camera
            </Typography>
          </div>
          <Grid container item xs={12} sm={12} spacing={1} className={classes.testBlock}>
            <Grid item align="center" sm={1}>
              {cameraTestEnded ? (
                cameraInputs &&
                cameraInputs.length > 0 &&
                cameraInputs.every((input) => input.deviceId) ? (
                  <CheckIcon className={classes.successIcon} />
                ) : (
                  <CustomTooltip
                    heading="No camera available"
                    text="It looks like your device doesn't have a working camera. Check your system settings for an available camera. If you have an external camera, make sure that it is plugged in and
                                            connected securely. Check for any websites or applications that may use your camera and disable it. Refresh the page, and run the test again. If you're still experiencing camera problems after trying the above, please run the test from a different browser."
                  >
                    <ClearIcon className={classes.errorIcon} />
                  </CustomTooltip>
                )
              ) : (
                <div className={classes.backupContainer} />
              )}
            </Grid>
            <Grid item xs={5} sm={5} align="left">
              <Typography>Camera Device</Typography>
            </Grid>
            <Grid item xs={6} sm={6} align="left">
              {cameraTestEnded &&
                (cameraInputs && cameraInputs.length > 0 ? (
                  <TextField
                    value={currentCamera}
                    name="currentCameraInput"
                    onChange={this.handleCameraChange}
                    select
                  >
                    {cameraInputs.map((option) => (
                      <MenuItem key={option.deviceId} value={option.deviceId}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : (
                  <Typography>No camera device available</Typography>
                ))}
            </Grid>
            <Grid item xs={12} sm={12} align="left">
              {cameraInputs &&
                cameraInputs.length > 0 &&
                cameraInputs.every((input) => input.deviceId) && (
                  <div className={classes.videoContainer}>
                    <video autoPlay={true} playsInline={true} id="test-video" muted></video>
                  </div>
                )}
            </Grid>
          </Grid>
          {testingCompleted && (
            <Grid item xs={12} align="center">
              <Button className={classes.repeatButton} color="secondary" onClick={this.repeatTest}>
                Repeat Test
              </Button>
            </Grid>
          )}
        </div>
        {testingCompleted && (
          <div className={classes.testsContainer}>
            <Typography component="p" variant="h3" className={classes.checklistBlockTitle}>
              Checklist
            </Typography>
            <Grid container spacing={1} className={classes.checklistBlock}>
              <Grid item align="center" sm={1}>
                <DoneOutline className={classes.doneIcon} />
              </Grid>
              <Grid item xs={11} sm={11} align="left">
                <Typography component="p">Do you see yourself?</Typography>
              </Grid>
              <Grid item align="center" sm={1}>
                <DoneOutline className={classes.doneIcon} />
              </Grid>
              <Grid item xs={11} sm={11} align="left">
                <Typography component="p">
                  Do you see your waveform visualizer while testing your microphone?
                </Typography>
              </Grid>
              <Grid item align="center" sm={1}>
                <DoneOutline className={classes.doneIcon} />
              </Grid>
              <Grid item xs={11} sm={11} align="left">
                <Typography component="p">
                  Do you hear a ringtone while testing your speaker?
                </Typography>
              </Grid>
            </Grid>
            <div className={classes.divider} />
            <Typography component="h3" variant="h4" className={classes.checklistBlockText}>
              If your answer to all questions above is yes, your device is working properly!
            </Typography>
          </div>
        )}
      </div>
    );
  }
}

const styles = (theme) => ({
  container: {
    width: "100%",
    padding: "50px 0px",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 50,
  },
  testsContainer: {
    width: "70%",
    position: "relative",
    marginTop: 15,
    borderRadius: 6,
    boxShadow: "0 0 3px rgba(0,0,0,.4)",
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "50px 0px",
    paddingLeft: 40,
    [theme.breakpoints.down("xl")]: {
      width: "90%",
      paddingLeft: 0,
    },
    [theme.breakpoints.down("lg")]: {
      width: "95%",
      padding: "25px 0px",
    },
  },
  goBackButton: {
    position: "absolute",
    top: 75,
    left: 30,
    zIndex: 3002,
    "& span": {
      textTransform: "none",
      fontSize: 16,
      fontWeight: 500,
      color: "#15171D",
    },
    [theme.breakpoints.down("xl")]: {
      left: 10,
      top: 10,
    },
  },
  sectionTitleContainer: {
    width: "74%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    margin: "15px 0px",
    [theme.breakpoints.down("xl")]: {
      width: "90%",
    },
    [theme.breakpoints.down("lg")]: {
      width: "100%",
      justifyContent: "center",
    },
  },
  sectionTitle: {
    fontSize: 18,
    letterSpacing: "-0.6px",
    color: "#15171D",
    marginLeft: 20,
    fontWeight: 500,
    [theme.breakpoints.down("xl")]: {
      marginLeft: 15,
    },
  },
  indicator: {
    width: 20,
    height: 20,
    borderRadius: "50%",
    [theme.breakpoints.down("xl")]: {
      width: 19,
      height: 19,
    },
  },
  testBlock: {
    width: "70%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& p": {
      fontWeight: 500,
      fontSize: 17,
      wordWrap: "break-word",
      letterSpacing: "-0.7px",
    },
    [theme.breakpoints.down("xl")]: {
      width: "85%",
      "& p": {
        fontSize: 16,
      },
    },
    [theme.breakpoints.down("lg")]: {
      width: "90%",
      "& p": {
        fontSize: 15,
      },
    },
  },
  canvas: {
    border: "1px solid #2a2d39",
    borderRadius: 4,
    zIndex: 3001,
    width: 80,
    height: 50,
    marginTop: 10,
    marginLeft: 18,
  },
  checklistBlock: {
    width: "70%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& p": {
      fontWeight: 500,
      fontSize: 18,
      wordWrap: "break-word",
      letterSpacing: "-0.7px",
    },
    [theme.breakpoints.down("xl")]: {
      width: "90%",
      "& p": {
        fontSize: 16,
      },
    },
    [theme.breakpoints.down("lg")]: {
      width: "95%",
      "& p": {
        fontSize: 15,
      },
    },
  },
  checklistBlockTitle: {
    fontWeight: 500,
    marginBottom: 10,
  },
  checklistBlockText: {
    textAlign: "center",
    padding: 5,
    [theme.breakpoints.down("xl")]: {
      fontSize: 18,
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: 16,
    },
  },
  dialogContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    textAlign: "center",
    "& p,h3,h4": {
      fontWeight: 500,
      letterSpacing: "-0.7px",
    },
    [theme.breakpoints.down("lg")]: {
      "& h3": {
        fontSize: 20,
      },
      "& h4": {
        fontSize: 17,
      },
      "& p": {
        fontSize: 16,
      },
    },
  },
  dialogTitle: {
    margin: "15px 0px",
  },
  dialogErrorTextContainer: {
    width: "90%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    marginBottom: 20,
  },
  dialogButton: {
    marginTop: 20,
    [theme.breakpoints.down("xl")]: {
      "& span": {
        fontSize: 13,
      },
    },
  },

  videoContainer: {
    height: 105,
    width: 150,
    overflow: "hidden",
    marginTop: 10,
    marginLeft: 18,
    borderRadius: "8px",
    MozBorderRadius: "8px",
    WebkitBorderRadius: "8px",
    backgroundColor: "#000",
    "& video": {
      height: "100%",
      width: "100%",
      transform: "rotateY(180deg)",
      WebkitTransform: "rotateY(180deg)",
      MozTransform: "rotateY(180deg)",
      objectFit: "contain",
    },
  },
  repeatButton: {
    height: 50,
    marginTop: 50,
    "& span": {
      fontSize: 17,
    },
    [theme.breakpoints.down("lg")]: {
      height: 40,
      marginTop: 40,
      "& span": {
        fontSize: 15,
      },
    },
  },
  successIcon: {
    margin: "auto",
    color: "#059669",
    [theme.breakpoints.down("xl")]: {
      fontSize: 17,
    },
  },
  errorIcon: {
    color: "#dc2626",
    [theme.breakpoints.down("xl")]: {
      fontSize: 17,
    },
  },
  backupContainer: {
    width: 24,
    [theme.breakpoints.down("xl")]: {
      width: 17,
    },
  },
  blockedCamera: {
    width: 20,
    height: 20,
  },
  list: {
    border: "2px solid blue",
    width: "70%",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    "& p": {
      fontWeight: 500,
      fontSize: 17,
      wordWrap: "break-word",
      letterSpacing: "-0.7px",
    },
    [theme.breakpoints.down("xl")]: {
      width: "90%",
    },
    [theme.breakpoints.down("lg")]: {
      width: "95%",
    },
  },
  doneIcon: {
    color: "#059669",
    fontSize: 20,
    [theme.breakpoints.down("xl")]: {
      fontSize: 15,
    },
  },
  divider: {
    height: 0,
    width: "75%",
    borderBottom: "2px solid #cbcbcb",
    margin: "10px 0",
  },
});

export default withStyles(styles)(WebrtcTestContainer);

const CustomTooltip = withStyles({
  popper: {
    zIndex: 4000,
  },
  tooltip: {
    backgroundColor: "#f0f1f4",
    color: "#2a2d39",
    fontSize: 15,
  },
  arrow: {
    color: "#f0f1f4",
  },
})((props) => (
  <Tooltip
    placement="top"
    arrow={true}
    PopperProps={{ style: { zIndex: 4001 } }}
    {...props}
    title={
      <p>
        <strong>{props.heading}</strong>
        <br />
        {props.text}
      </p>
    }
  ></Tooltip>
));

const CustomInput = React.forwardRef((props, ref) => {
  return (
    <StyledInput {...props} variant={props.variant || "outlined"} size={props.size || "small"} />
  );
});

const StyledInput = withStyles((theme) => ({
  root: {
    "& fieldset": {
      border: "1px solid #2a2d39",
      borderRadius: 5,
      fontSize: 17,
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        border: "1px solid #2a2d39",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid #2a2d39",
      },
    },
    "& .MuiInputBase-root": {
      fontSize: 17,
      letterSpacing: "-0.7px",
      [theme.breakpoints.down("lg")]: {
        fontSize: 16,
        margin: "20px 0px",
        marginLeft: 4,
      },
    },
  },
}))(TextField);
