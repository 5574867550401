import * as yup from "yup";

const licenseValidationSchema = yup.object().shape({
  licenseNumber: yup
    .number()
    .typeError("Must be a number")
    .required("State Licenses require a License Number"),
  stateType: yup.string().required("State Licenses require a State"),
  issuedDate: yup.date().required("State Licenses require a Date Issued"),
  expirationDate: yup.date().required("State Licenses require an Expiration Date"),
  licenseFile: yup.array().of(yup.object()).min(1, "Please upload a file").max(1, "Max 1 file"),
});

const certificationValidationSchema = yup.object().shape({
  certificationNumber: yup
    .number()
    .typeError("Must be a number")
    .required("Certifications require a Certification Number"),
  issuedOn: yup.date().required("Certifications require an Issued Date"),
  expiryDate: yup.date().required("Certifications require an Expiration Date"),
  certificationFile: yup
    .array()
    .of(yup.object())
    .min(1, "Please upload a file")
    .max(1, "Max 1 file"),
});

const liabilityValidationSchema = yup.object().shape({
  expiryDate: yup.date().required("Liability Insurance requires an Expiration Date"),
  liabilityFile: yup.array().of(yup.object()).min(1, "Please upload a file").max(1, "Max 1 file"),
});

export { certificationValidationSchema, licenseValidationSchema, liabilityValidationSchema };
