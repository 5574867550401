import SecureRequest from "../utils/securerequest";

export async function getEventInfo(videoId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return Request.get(`/non-video-event/${videoId}/details`);
}

export async function scheduleNonVideoEvent(eventData) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return Request.post(`/non-video-event/${eventData.clientId}/schedule`, eventData);
}

export async function rescheduleNonVideoEvent(eventData) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return Request.put(`/non-video-event/${eventData.id}/reschedule`, eventData);
}

export async function cancelNonVideoEvent(event) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return Request.put(`/non-video-event/${event.id}/cancel`, event);
}
