import React, { useEffect, useState } from "react";
import { Badge, CircularProgress, Stack, Typography } from "@mui/material";
import momentTZ from "moment-timezone";

export const LoadingScreen = () => {
  return (
    <Stack alignItems="center" height="100vh" justifyContent="center">
      <CircularProgress />
      <Typography>Loading</Typography>
    </Stack>
  );
};

export const useTimeOptions = () => {
  const [timeOptions, setTimeOptions] = useState([]);
  const generateTimeOptions = () => {
    const time = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 15)
        time.push(momentTZ({ hour, minute }).format("h:mm A"));
    }
    setTimeOptions(time);
  };

  useEffect(() => {
    generateTimeOptions();
  }, []);
  return timeOptions;
};

export const UserTitle = ({ dotColor, name }) => {
  return (
    <Stack direction="row" alignItems="center" flexGrow={1} justifyContent="center">
      <Typography variant="caption" sx={{ fontWeight: 500 }}>
        {name}
      </Typography>
      <Badge variant="dot" sx={{ "& .MuiBadge-badge": { bgcolor: dotColor, right: "-8px" } }} />
    </Stack>
  );
};

/**
 * Rounds down a given date to the nearest 15 minutes.
 *
 * @param {Date} date - The date to be rounded down.
 * @returns {Date} - The rounded down date.
 */
export const roundDownToNearest15 = (date) => {
  return momentTZ(date)
    .minute(Math.floor(momentTZ(date).minute() / 15) * 15)
    .toDate();
};

export const durationOptions = [
  { label: "30 mins", value: 30 },
  { label: "45 mins", value: 45 },
  { label: "1 hour", value: 60 },
  { label: "1 hour 15 mins", value: 75 },
  { label: "1 hour 30 mins", value: 90 },
  { label: "1 hour 45 mins", value: 105 },
  { label: "2 hours", value: 120 },
  { label: "2 hours 15 mins", value: 135 },
  { label: "2 hours 30 mins", value: 150 },
  { label: "2 hours 45 mins", value: 165 },
  { label: "3 hours", value: 180 },
];
