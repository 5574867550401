import * as AWS from "aws-sdk";
import { getAWSCredentialsForCurrentUserSession } from "./aws";

export const getAllS3ObjectVersions = async (documentData) => {
  // expects object with two keys - {cognitoId, filename}
  const { cognitoId, filename } = documentData;
  const credentials = await getAWSCredentialsForCurrentUserSession();
  var s3 = new AWS.S3({ credentials });
  const params = {
    Bucket: process.env.AWS_USER_DOC_BUCKET,
    Prefix: `${cognitoId}/${filename}`,
  };
  let versions;
  await s3
    .listObjectVersions(params)
    .promise()
    .then((result) => (versions = result.Versions));
  return versions;
};
