import SecureRequest from "../utils/securerequest";
export const getPhysicians = async (params) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return Request.get(`/v2/physicians`, { params });
};

export const addPhysician = async (body) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.post(`/v2/physicians`, body);
};

export const updatePhysician = async (body) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.put(`/v2/physicians/${body.physicianId}`, body);
};
