export default (theme) => ({
  dialogTitle: {
    display: "flex",
    padding: theme.spacing(4, 7),
    alignItems: "center",
    justifyContent: "space-between",
  },
  dialogContent: {
    display: "flex",
    padding: `${theme.spacing(5, 7)} !important`,
    flexDirection: "column",
    alignItems: "center",
    alignSelf: "stretch",
    borderTop: "1px solid #CDD3DA",
    "& img": {
      width: 315,
      height: "auto",
    },
  },
  dialogContentText: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: theme.spacing(3),
    alignSelf: "stretch",
  },
  body2: {
    color: theme.palette.text.secondary,
  },
  button: {
    width: "100%",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: theme.spacing(3),
    alignSelf: "stretch",
  },
  dialogContentOptionalSync: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(5),
  },
  dialogContentSync: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(3),
  },
});
