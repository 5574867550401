import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { CaretDown, X } from "@phosphor-icons/react";
import ANDatePicker from "elements/ANDatePicker";
import ANTimePicker from "elements/ANTimePicker";
import { Formik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import actions from "../../../../actions";
import * as selectors from "selectors";
import { LoadingButton } from "@mui/lab";
import { useGlobalToast } from "components/GlobalToastProvider";

export default function AddLogDialog({ open, handleClose, clientId, updateLogs }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const savingLog = useSelector(selectors.getNotesSaving);
  const logSaved = useSelector(selectors.getNotesSaved);
  const { showToast } = useGlobalToast();

  const initialValues = {
    noteType: "",
    noteDate: new Date(),
    withAuthorizedParty: "true",
    isOutbound: null,
    notes: "",
  };

  const validationSchema = yup.object({
    noteType: yup.string().required("Required"),
    noteDate: yup.date().when("noteType", {
      is: (val) => val !== "COMMENT",
      then: () => yup.date().required("Required"),
    }),
    withAuthorizedParty: yup.boolean().when("noteType", {
      is: (val) => val !== "COMMENT",
      then: () => yup.boolean().required("Required"),
    }),
    isOutbound: yup
      .boolean()
      .nullable()
      .when("noteType", {
        is: (val) => val !== "COMMENT",
        then: () => yup.boolean().required("Required"),
      }),
    notes: yup.string().required("Required"),
  });

  const processBooleanValues = (value) => {
    if (typeof value === "boolean") return value;
    if (value === "false") return false;
    if (value === "true") return true;
    return null;
  };

  const handleSubmit = (values) => {
    const { noteType, withAuthorizedParty, isOutbound } = values;
    const payload = {
      ...values,
      withAuthorizedParty: processBooleanValues(withAuthorizedParty),
      isOutbound: noteType === "COMMENT" ? true : processBooleanValues(isOutbound),
      clientId,
    };
    dispatch(actions.saveNotes(payload));
  };

  useEffect(() => {
    if (logSaved) {
      handleClose();
      showToast({ message: "Log saved successfully!" });
      updateLogs();
      dispatch(actions.clearNotesSuccess());
    }
  }, [logSaved]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm">
      <DialogTitle>
        Add Log
        <IconButton aria-label="close" onClick={handleClose}>
          <X />
        </IconButton>
      </DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, handleSubmit, setFieldValue, isValid, dirty }) => {
          return (
            <>
              <DialogContent dividers>
                <Stack gap={theme.spacing(7)}>
                  <TextField
                    label="Log Type"
                    name="noteType"
                    value={values.noteType}
                    onChange={handleChange}
                    select
                  >
                    <MenuItem disabled value={""}>
                      Select
                    </MenuItem>
                    <MenuItem value="PHONE">Phone Call</MenuItem>
                    <MenuItem value="EMAIL">Email</MenuItem>
                    <MenuItem value="SMS">SMS</MenuItem>
                    <MenuItem value="FAX">Fax</MenuItem>
                    <MenuItem value="COMMENT">Comment</MenuItem>
                  </TextField>
                  {values.noteType !== "COMMENT" && (
                    <>
                      <Stack direction="row" gap={theme.spacing(5)}>
                        <ANDatePicker
                          name="noteDate"
                          value={values.noteDate}
                          onChange={(value) => setFieldValue("noteDate", value, true)}
                          label="Date"
                          disableFuture
                          format="P" //"MM/dd/yyyy"
                          slots={{ textField: TextField }}
                          slotProps={{
                            field: { fullWidth: true },
                            popper: { disablePortal: false },
                          }}
                        />
                        <ANTimePicker
                          name="noteDate"
                          value={values.noteDate}
                          onChange={(value) => setFieldValue("noteDate", value, true)}
                          variant="outlined"
                          label="Time"
                          fullWidth
                          slots={{ textField: TextField }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <CaretDown />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Stack>
                      <FormControl>
                        <FormLabel>
                          Was this communication with the client or an authorized family member?
                        </FormLabel>
                        <RadioGroup
                          name="withAuthorizedParty"
                          value={values.withAuthorizedParty}
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            value={true}
                            control={<Radio size="small" />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio size="small" />}
                            label="No, someone else"
                          />
                        </RadioGroup>
                      </FormControl>
                      <FormControl>
                        <FormLabel>What this an inbound or outbound communication?</FormLabel>
                        <RadioGroup
                          name="isOutbound"
                          value={values.isOutbound}
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            value={false}
                            control={<Radio size="small" />}
                            label="Inbound"
                          />
                          <FormControlLabel
                            value={true}
                            control={<Radio size="small" />}
                            label="Outbound"
                          />
                        </RadioGroup>
                      </FormControl>
                    </>
                  )}
                  <TextField
                    label="Note"
                    multiline
                    minRows={2}
                    sx={{ "& .MuiOutlinedInput-root": { padding: theme.spacing(5, 4) } }}
                    name="notes"
                    value={values.notes}
                    onChange={handleChange}
                  />
                </Stack>
              </DialogContent>
              <DialogActions>
                <Button color="secondary" onClick={handleClose}>
                  Cancel
                </Button>
                <LoadingButton
                  onClick={handleSubmit}
                  disabled={!(isValid && dirty)}
                  loading={savingLog}
                >
                  Log
                </LoadingButton>
              </DialogActions>
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
}
