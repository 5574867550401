export function findCancelReasonByResponsible(responsible) {
  return cancelReasons.find((item) => item.responsibleForCancellation === responsible);
}

export function getKeyCancellationReason(value) {
  const responsible = findCancelReasonByResponsible(value);
  return responsible && !Array.isArray(responsible.cancelReasons)
    ? responsible.cancelReasons.key
    : value;
}
export function getCancellationReasonLabel(value) {
  const responsible = findCancelReasonByResponsible(value);
  return responsible && !Array.isArray(responsible.cancelReasons)
    ? responsible.cancelReasons.label
    : value;
}

export const cancelReasons = [
  {
    responsibleForCancellation: "client",
    label: "Client",
    cancelReasons: [
      "Client or Caregiver Sick",
      "Internet / Tech Issues",
      "Personal Conflict",
      "Last Minute Medical Appointment",
      "Client Behavior",
      "Scheduled Vacation",
      "Bereavement",
    ],
  },
  {
    responsibleForCancellation: "clinician",
    label: "Clinician",
    cancelReasons: [
      "Sick",
      "Personal Conflict",
      "Internet / Tech Issues",
      "Conflict with Full Time Job",
      "Scheduled Vacation",
      "Bereavement",
    ],
  },
  {
    responsibleForCancellation: "neither_1",
    label: "Neither, ",
    cancelReasons: {
      label: "Office Holiday",
      key: "neither",
    },
  },
  {
    responsibleForCancellation: "neither_2",
    label: "Neither, ",
    cancelReasons: {
      label: "Rearranging Client's Entire Schedule",
      key: "neither",
    },
  },
  {
    responsibleForCancellation: "neither_3",
    label: "Neither, ",
    cancelReasons: {
      label: "Insurance Hold",
      key: "neither",
    },
  },
];
