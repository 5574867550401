import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import actions from "../../actions";
import styles from "./styles";
import { format } from "date-fns";
import PageContainer from "elements/PageContainer";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import { capitalizeBillingTypeFirstLetter } from "../../utils/capitalizeBillingTypeFirstLetter";
import Button from "@mui/material/Button";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import {
  getUserId,
  getUserPermissionsList,
  getCustomerPastCallsLoading,
  getCustomerPastCalls,
  getClientId,
} from "selectors";

import { convertTokens } from "@date-fns/upgrade/v2";

const mapStateToProps = (state) => ({
  userId: getUserId(state),
  clientId: getClientId(state),
  userPermissions: getUserPermissionsList(state),
  pastCallsLoading: getCustomerPastCallsLoading(state),
  pastCalls: getCustomerPastCalls(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setPageDetails: actions.setPageDetails,
      getCustomerPastCalls: actions.getCustomerPastCalls,
    },
    dispatch,
  );

class PastSessions extends Component {
  constructor(props) {
    super();
    this.state = {
      showMissedCalls: false,
    };
  }

  componentDidMount() {
    if (this.props.clientId) {
      this.props.getCustomerPastCalls({
        clientId: this.props.clientId,
        includeMissedCalls: this.state.showMissedCalls,
        monthLimit: false,
      });
    }
    window.scrollTo(0, 0);
  }
  componentDidUpdate(prevProps) {
    if (!prevProps.clientId && this.props.clientId) {
      this.props.getCustomerPastCalls({
        clientId: this.props.clientId,
        includeMissedCalls: this.state.showMissedCalls,
        monthLimit: false,
      });
    }
  }
  toggleShowMissedCalls = () => {
    this.setState({ showMissedCalls: !this.state.showMissedCalls }, () => {
      this.props.getCustomerPastCalls({
        clientId: this.props.clientId,
        includeMissedCalls: this.state.showMissedCalls,
        monthLimit: false,
      });
    });
  };

  goToNotes = (videoId) => {
    this.props.history.push(`/session-details/${videoId}`);
  };

  render() {
    const { showMissedCalls } = this.state;
    const { classes, pastCallsLoading, pastCalls, userPermissions } = this.props;
    let rows = pastCalls;
    const isMissedCall = (call) => {
      if (!call.end_date || !call.start_date || call.no_show == true) {
        return true;
      } else {
        return false;
      }
    };

    return (
      <PageContainer loading={pastCallsLoading}>
        <div className={classes.pastSessionsContainer}>
          <Button
            onClick={() => this.props.history.goBack()}
            variant="text"
            startIcon={<ArrowBackOutlinedIcon style={{ fill: "#000" }} />}
            className={classes.goBackButton}
          >
            Go Back
          </Button>
          <Typography className={classes.pageTitle}>All Sessions</Typography>
          <div className={classes.checkboxContainer}>
            <Typography>Display:</Typography>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={showMissedCalls}
                  onClick={this.toggleShowMissedCalls}
                  style={{ color: "#2a2d39" }}
                  disableRipple
                  disableFocusRipple
                />
              }
              label={<span className={classes.checkboxLabel}>Show missed calls</span>}
            />
          </div>
          <div className={classes.tableContainer}>
            <Table stickyHeader>
              <TableHead classes={{ root: classes.tableHead }}>
                <TableRow classes={{ root: classes.tableRow }}>
                  <TableCell>Session Date</TableCell>
                  <TableCell>Session Type</TableCell>
                  <TableCell>Clinician</TableCell>
                  <TableCell>Session Details</TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ fontSize: "16px" }}>
                {rows.length > 0 &&
                  rows.map((row, i) => (
                    <TableRow
                      key={row.id}
                      style={
                        isMissedCall(row)
                          ? {
                              backgroundColor: "rgba(105,113,143,0.25)",
                            }
                          : i % 2 === 0
                            ? { backgroundColor: "#ECFAFA" }
                            : null
                      }
                      classes={{ root: classes.tableRow }}
                    >
                      <TableCell>
                        {row.scheduled_date || row.start_date
                          ? isMissedCall(row)
                            ? format(new Date(row.scheduled_date), convertTokens("MMM D YYYY"), {
                                awareOfUnicodeTokens: true,
                              })
                            : format(new Date(row.start_date), convertTokens("MMM D YYYY"), {
                                awareOfUnicodeTokens: true,
                              })
                          : null}
                      </TableCell>
                      <TableCell>
                        {row.billing_type
                          ? capitalizeBillingTypeFirstLetter(row.billing_type)
                          : null}
                      </TableCell>
                      <TableCell>{row.clinician_name}</TableCell>
                      {isMissedCall(row) ? (
                        <TableCell>Missed Call</TableCell>
                      ) : (
                        <TableCell>
                          <Button variant="text" onClick={() => this.goToNotes(row.id)}>
                            View Notes
                          </Button>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </PageContainer>
    );
  }
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PastSessions)),
);
