export const formatNumberByDecimal = (number, prefixPadding = 4) => {
  const numStr = String(number);
  if (!numStr || isNaN(Number(numStr))) {
    return "";
  }

  const isNegative = numStr.startsWith("-");
  const [integerPart, decimalPart] = numStr.replace("-", "").split(".");
  const spacesNeeded = Math.max(0, prefixPadding - integerPart.length - (isNegative ? 1 : 0));

  return `${`\u0020`.repeat(spacesNeeded)}${isNegative ? "-" : ""}${integerPart}${
    decimalPart ? `.${decimalPart}` : ""
  }`;
};
