export const LoginMethods = {
  CODE: "code",
  PASSWORD: "Password",
};

export const ContactMethods = {
  EMAIL: "email",
  PHONE: "phone",
};

export const OtpProperties = {
  LENGTH: 6,
};

export const OtpErrorTypes = {
  FORMAT: "format",
  INVALID: "invalid",
};
