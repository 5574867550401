import UserHeartbeatController from "video/controllers/UserHeartbeatController";
import UserPingController from "video/controllers/UserPingController";
import { log } from "video/debug";
import { extractUserIdFromMessage } from "video/messaging/util";

/** @enum {number} */
export const UserPresenceLevel = {
  ABSENT: 0,
  RECONNECTING: 0,
  IN_LOBBY: 1,
  JOINING: 2,
  IN_CALL: 3,
  RECONNECTED: 3,
  SCREEN_SHARING: 4,
};
const { IN_CALL, IN_LOBBY, ABSENT, JOINING, RECONNECTED, RECONNECTING, SCREEN_SHARING } =
  UserPresenceLevel;

export default class MeetingRoster {
  /** @type {import('video/messaging/MessagingSession').default} */
  messagingSession;

  /** @type {UserPingController?} */
  pingController = null;

  /** @type {UserHeartbeatController?} */
  realtimeHeartbeatController = null;

  /** @type {UserHeartbeatController?} */
  idleHeartbeatController = null;

  /** @type {{[userId:string]:UserPresenceLevel}} */
  userPresenceMap = {};

  /** @type {{[userId:string]:number}} */
  userUpdatedAtMap = {}; // ensure we don't set presence from a stale message

  // For Content (i.e. screen share) streams, the Attendee ID of the User who is sharing will be appended with "#content" and presence is tracked separately from the User itself
  /** @type {{[userId:string]:boolean}} */
  userContentPresenceMap = {};

  /** @type {{[userId:string]:Attendee}} */
  userAttendeeIdDict = {};

  /** @type {{[attendeeId:string]:Attendee}} */
  attendeeVolumeState = {};

  /** @type {{[userId:string]:Attendee}} */
  userVolumeState = {};

  pingOnPresenceChange = true;

  // consider user as ABSENT if we miss this many heartbeats in a row
  missedBeatsForAbsent = 5;

  /** @type {UserPresenceLevel} */
  _currentPresenceLevel = ABSENT;
  set currentPresenceLevel(level) {
    this.realtimeHeartbeatController.presenceLevel = level;
    this.idleHeartbeatController.presenceLevel = level;
    this.pingController.presenceLevel = level;
    this.userPresenceMap[this.userId] = level;
    this.userUpdatedAtMap[this.userId] = Date.now();
    this._currentPresenceLevel = level;
  }
  get currentPresenceLevel() {
    return this._currentPresenceLevel;
  }

  /** @type {import('video/meeting/MeetingSession').default} */
  __meetingSession;
  set meetingSession(meetingSession) {
    this.__meetingSession = meetingSession;
    this.meetingSession.onReconnecting(this._onMeetingDisconnected.bind(this));
    this.meetingSession.onReconnected(this._onMeetingConnected.bind(this));
    this.meetingSession.on("meetingStartSucceeded", this._onMeetingConnected.bind(this));
    this.meetingSession.onAttendeeIdPresence(this._onAttendeePresenceChange.bind(this));
  }
  get meetingSession() {
    return this.__meetingSession;
  }

  get userId() {
    return this.messagingSession?.userId;
  }

  /** @returns {string[]} */
  get otherUserIds() {
    return this.messagingSession.otherUserIds
      .reduce((mem, cur) => {
        if (!mem.find((uid) => uid === cur)) {
          mem.push(cur);
        }
        return mem;
      }, Object.keys(this.userPresenceMap))
      .filter((userId) => userId !== this.userId);
  }

  /**
   * iterate over userIds who are JOINING or greater
   */
  *[Symbol.iterator]() {
    for (const userId in this.userPresenceMap) {
      if (this.userPresenceMap[userId] >= JOINING) yield userId;
    }
  }

  /**
   * @param {import('video/messaging/MessagingSession').default} messagingSession
   */
  constructor(messagingSession) {
    this.pingController = new UserPingController(messagingSession, this.currentPresenceLevel);
    this.realtimeHeartbeatController = new UserHeartbeatController(
      messagingSession,
      this.currentPresenceLevel
    );
    this.realtimeHeartbeatController.rateMs = 2500;
    this.realtimeHeartbeatController.userTimeoutMs = 5000;

    // a separate heartbeat that is just for checking when the meeting is dead (i.e. no beats from any users' idleHeartbeatController in 15min)
    this.idleHeartbeatController = new UserHeartbeatController(
      messagingSession,
      this.currentPresenceLevel,
      true, // send persistent messages so we have a LastMessageTimestamp value for the Channel
      IN_CALL
    );
    this.idleHeartbeatController.rateMs = 60000;
    this.idleHeartbeatController.userTimeoutMs = 90000;

    this.pingController.onUserPing("*", (userId, presenceLevel, ts) => {
      if (userId == this.userId) return;
      log("<- ping: ", userId, presenceLevel, ts);
      this.updateUserPresence(userId, presenceLevel, ts);

      // ACK with a PONG message so they get our current status
      return this.pingController.pongUsers([userId]);
    });

    this.pingController.onUserPong("*", (userId, presenceLevel, ts) => {
      if (userId == this.userId) return;
      log("<- pong: ", userId, presenceLevel, ts);
      this.updateUserPresence(userId, presenceLevel, ts);
    });

    this.realtimeHeartbeatController.onUserBeat("*", (userId, presenceLevel, ts) => {
      if (userId == this.userId) return;
      // log("<- beat: ", userId, presenceLevel, ts);
      this.updateUserPresence(userId, presenceLevel, ts);
    });

    this.realtimeHeartbeatController.onUserTimeout("*", (userId, timeoutCount) => {
      if (userId == this.userId) return;
      log("- missed beat: ", userId, { timeoutCount });
      if (timeoutCount >= this.missedBeatsForAbsent) {
        this.updateUserPresence(userId, ABSENT);
      }
    });

    this.messagingSession = messagingSession;
    this.messagingSession.onChannelUpdated(async (details, initial) => {
      if (initial) {
        this.pingController.pingUsers();
      }
    });
    this.messagingSession.onStart(async () => {
      log("messaging session started", this.currentPresenceLevel);
      if (this.currentPresenceLevel < IN_LOBBY) {
        await this.setCurrentPresence(IN_LOBBY);
      }
      this.realtimeHeartbeatController.start();
    });
  }

  destroy() {
    this.pingController.destroy();
    this.realtimeHeartbeatController.destroy();
    this.idleHeartbeatController.destroy();
    this.pingController = null;
    this.realtimeHeartbeatController = null;
    this.idleHeartbeatController = null;
  }

  /**
   * Get the number of users current JOINING or greater
   */
  usersPresentish() {
    return Object.keys(this.userPresenceMap).reduce(
      (mem, cur) => mem + +this.checkUserPresence(cur, JOINING),
      0
    );
  }

  async _onMeetingConnected() {
    await this.setCurrentPresence(IN_CALL);
    this.realtimeHeartbeatController.rateMs = 15000;
    this.idleHeartbeatController.start(); // beat now since it has a big delay
  }

  async _onMeetingDisconnected() {
    await this.setCurrentPresence(IN_LOBBY, true);
    this.realtimeHeartbeatController.rateMs = 2500;
  }

  async pingOtherUsers() {
    const userIds = this.otherUserIds;
    return this.pingController.pingUsers(userIds);
  }

  /**
   * Update a user's presence level (to update the local User's presence, use `this.setCurrentPresence()`)
   * @param {number} userId
   * @param {UserPresenceLevel} presenceLevel
   * @param {Date|number|string} timestamp
   */
  updateUserPresence(userId, presenceLevel = ABSENT, timestamp = Date.now()) {
    const currentPresenceLevel = this.userPresenceMap[userId] || ABSENT;
    const epochTime = new Date(timestamp).valueOf();
    const lastUpdate = this.userUpdatedAtMap[userId] || 0;
    if (
      epochTime < lastUpdate ||
      // if we get two messages in the same millisecond, prefer the greater value
      (epochTime === lastUpdate && presenceLevel <= currentPresenceLevel)
    ) {
      log(`ignoring older presence message (cur: ${lastUpdate} | new: ${epochTime})`);
      return;
    }

    // want to update the timestamp even if presence doesn't change
    this.userUpdatedAtMap[userId] = epochTime;

    // dont' fire handlers if no change
    if (presenceLevel !== currentPresenceLevel) {
      this.userPresenceMap[userId] = presenceLevel;
      this.__onUserPresenceChange(userId, presenceLevel, currentPresenceLevel);
      log("updateUserPresence", userId, presenceLevel, epochTime);
    }
  }

  /**
   * Set `this.currentPresenceLevel` and, if it changed, send a PING message to the channel
   * @param {userPresenceLevel} presenceLevel
   * @param {boolean} forcePing send a PING even if the presenceLevel isn't changing
   */
  async setCurrentPresence(presenceLevel, forcePing = false) {
    const currentPresenceLevel = this.currentPresenceLevel;
    this.currentPresenceLevel = presenceLevel;

    if (forcePing || currentPresenceLevel !== presenceLevel) {
      log("updated current presence level to:", this.currentPresenceLevel, this.userId);

      this.__onUserPresenceChange(this.userId, presenceLevel, currentPresenceLevel);

      if (forcePing || this.pingOnPresenceChange) {
        await this.pingOtherUsers();
      }
    }
  }

  /**
   * Check the given User's presence level is at least `minPresenceLevel` (or equal to if `allowGreater` is FALSE)
   * @param {string} userId
   * @param {UserPresenceLevel} minPresenceLevel
   * @param {boolean} allowGreater pass FALSE to only check if presence level is EQUAL to `minPresenceLevel` (instead of greater than or equal)
   * @returns
   */
  checkUserPresence(userId = "", minPresenceLevel = ABSENT, allowGreater = true) {
    if (allowGreater) {
      if (userId == this.userId) {
        return this.currentPresenceLevel >= minPresenceLevel;
      }
      return this.userPresenceMap[userId] >= minPresenceLevel;
    }
    if (userId == this.userId) {
      return this.currentPresenceLevel === minPresenceLevel;
    }
    return this.userPresenceMap[userId] === minPresenceLevel;
  }

  /**
   * @param {string} userId
   * @param {(userId:string,curPresenceLevel:UserPresenceLevel,prevPresenceLevel:UserPresenceLevel)=>void} cb
   */
  onUserPresenceChange(userId = "", cb = () => {}) {
    this._onUserPresenceChange[userId] = this._onUserPresenceChange[userId] || [];
    this._onUserPresenceChange[userId].push(cb);
    return this;
  }

  /** @type {{[userId:string]:(userId:string,curPresenceLevel:UserPresenceLevel,prevPresenceLevel:UserPresenceLevel)=>void}} */
  _onUserPresenceChange = { "*": [] };

  /**
   * @param {string} userId
   * @param {UserPresenceLevel} curPresenceLevel
   * @param {UserPresenceLevel} prevPresenceLevel
   */
  __onUserPresenceChange(userId, curPresenceLevel, prevPresenceLevel) {
    this._onUserPresenceChange["*"].forEach((cb) =>
      cb(userId, curPresenceLevel, prevPresenceLevel)
    );
    this._onUserPresenceChange[userId]?.forEach((cb) =>
      cb(userId, curPresenceLevel, prevPresenceLevel)
    );
  }

  /** @type {import('amazon-chime-sdk-js').RealtimeSubscribeToAttendeeIdPresenceCallback} */
  _onAttendeePresenceChange(attendeeIdRaw, present, userId) {
    // we should remain source-of-truth for our own presence level
    if (userId == this.userId) return;

    // ignore AWS internal users (e.g. media pipeline, etc)
    if (/^aws/.test(userId)) return;

    // This is called when a User OR a User's content stream changes
    // A User's content stream has the same AttendeeID as the User with "#content" appended to it
    const [attendeeId, isContent] = attendeeIdRaw.split("#");

    let presenceLevel = present ? IN_CALL : ABSENT;
    if (isContent) {
      presenceLevel = present ? SCREEN_SHARING : Math.min(this.userPresenceMap[userId], IN_CALL);
    }

    log("<- attendeepresencechange: ", userId, presenceLevel);

    this.updateUserPresence(userId, presenceLevel);
  }

  /**
   * Volume is between 0.0 (min volume) and 1.0 (max volume).
   * Signal strength can be 0 (no signal), 0.5 (weak signal), or 1 (good signal).
   * A null value for any field means that it has not changed.
   * @type {import('amazon-chime-sdk-js').VolumeIndicatorCallback}
   */
  _onUserVolumeUpdated = (attendeeId, volume, muted, signalStrength, userId) => {
    this.attendeeVolumeState[attendeeId] = { volume, muted, signalStrength };
    this.userVolumeState[userId] = { volume, muted, signalStrength };
  };
}
