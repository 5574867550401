import React from "react";
import { withRouter } from "react-router";
import withStyles from "@mui/styles/withStyles";
import { format } from "date-fns";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import styles from "../../theme/globalStyles";
import { CustomTable } from "elements/DEPRECATED_elements";
import { CustomHeaderCell } from "elements/DEPRECATED_elements";

import { convertTokens } from "@date-fns/upgrade/v2";

const Customers = (props) => {
  const {
    classes,
    customers,
    viewCustomerDetails,
    handleChangePage,
    handleChangeRowsPerPage,
    handleSortClick,
    page,
    count,
    rowsPerPage,
    direction,
    sort,
    userPermissions,
  } = props;
  let rows = customers;
  return (
    <CustomTable
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      noResultsMessage="No Customers Found"
      headerCells={[
        <CustomHeaderCell
          label="ID"
          sortable={true}
          sortDirection={sort === "j.id" ? direction : false}
          active={sort === "j.id"}
          onClick={() => handleSortClick("j.id")}
        />,
        <CustomHeaderCell
          label="Name"
          sortable={true}
          sortDirection={sort === "first_name" ? direction : false}
          active={sort === "first_name"}
          onClick={() => handleSortClick("first_name")}
        />,
        <CustomHeaderCell
          label="Email"
          sortable={true}
          sortDirection={sort === "login" ? direction : false}
          active={sort === "login"}
          onClick={() => handleSortClick("login")}
          style={{ maxWidth: "fit-content" }}
        />,
        <CustomHeaderCell
          label="Child Name"
          sortable={true}
          sortDirection={sort === "cl.name" ? direction : false}
          active={sort === "cl.name"}
          onClick={() => handleSortClick("cl.name")}
        />,
        <CustomHeaderCell
          label="Child Age"
          sortable={true}
          sortDirection={sort === "cl.age" ? direction : false}
          active={sort === "cl.age"}
          onClick={() => handleSortClick("cl.age")}
        />,
        <CustomHeaderCell
          label="Date Created"
          sortable={true}
          sortDirection={sort === "created_date" ? direction : false}
          active={sort === "created_date"}
          onClick={() => handleSortClick("created_date")}
        />,
      ]}
    >
      {rows.length > 0 &&
        rows.map((row) => (
          <TableRow
            key={row.id}
            className={classes.clickableRow}
            hover
            onClick={
              userPermissions?.view_client_list_by_workflow_state
                ? () => viewCustomerDetails(row.client_id)
                : null
            }
          >
            <TableCell component="th" scope="row">
              {row.id}
            </TableCell>
            <TableCell align="left">{row.first_name}</TableCell>
            <TableCell align="left">{row.login}</TableCell>
            <TableCell align="left">{row.name}</TableCell>
            <TableCell align="left">{row.age}</TableCell>
            <TableCell align="left">
              {row?.created_date &&
                format(new Date(row.created_date), convertTokens("MMM D, YYYY h:mm a"), {
                  awareOfUnicodeTokens: true,
                })}
            </TableCell>
          </TableRow>
        ))}
    </CustomTable>
  );
};

export default withRouter(withStyles(styles)(Customers));
