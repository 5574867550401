const dateNow = Date.now(); // Avoid re-rendering DatePicker component
/**
 * This function gets the current date object if no date is provided.
 * @function getDate
 * @param {?String} date - The date string to convert to a date object.
 * @return {Date} The date object.
 */
export function getDate(date) {
  return new Date(date ?? dateNow);
}
