// ===================================================
// Navigation
// ===================================================
export const navigate = (url, replace = true) => {
  if (replace) {
    window.history.replaceState({}, "", url);
  } else {
    window.history.pushState({}, "", url);
  }
};

// ===================================================
// VALIDATION
// ===================================================
export const validatePhoneNumber = (input) => {
  var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
  return re.test(input);
};

export const validateEmail = (email) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

// ===================================================
// STRING MANIPULATION
// ===================================================
export const obscureEmail = (email = "") => {
  const [name, domain] = email.split("@");
  return `${name[0]}${new Array(name.length).join("*")}@${domain}`;
};

export const obscurePhone = (phoneNumber = "") => {
  const lastFour = phoneNumber.slice(-4);
  return `(XXX) XXX-${lastFour}`;
};

export const parsePhoneNumber = (phoneNumber = "") => {
  // Remove all non-digit characters from the input string
  const digitsOnly = phoneNumber.replace(/\D/g, "");
  if (digitsOnly.length >= 10) {
    // Extract the last 10 digits from the string
    const mainDigits = digitsOnly.substring(digitsOnly.length - 10);
    return mainDigits;
  } else {
    throw new Error();
  }
};
