import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { dateWithoutTimezone } from "../../../../utils/dateWithoutTimezone";
import AddIcon from "@mui/icons-material/Add";
import {
  Alert,
  AlertTitle,
  Box,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  Switch,
} from "@mui/material";
import ANDatePicker from "elements/ANDatePicker";
import { X } from "@phosphor-icons/react";

const UpdateAuthorization = (props) => {
  const {
    classes,
    onChange,
    onChangeDate,
    availableInsuranceCodes,
    updateAuthorizationCode,
    updateAuthorizationEndDate,
    updateAuthorizationStartDate,
    updateAuthorizationType,
    updateAuthorizationClinicianId,
    updateApprovedUnits,
    onChangeUpdateApprovedUnitAmount,
    onCloseUpdateAuthorization,
    onSubmitUpdateAuthorization,
    saveButtonDisabled,
    updateNewApprovedUnits,
    onAddUpdateNewApprovedUnit,
    onRemoveUpdateNewApprovedUnit,
    onChangeUpdateNewApprovedUnitCode,
    onChangeUpdateNewApprovedUnitAmount,
    clinicianList,
    updatePendingAuth,
    open,
  } = props;

  const [sortedClinicians, setSortedClinicians] = useState([]);

  useEffect(() => {
    if (clinicianList) {
      const sortedList = [...clinicianList].sort((a, b) =>
        a.first_name.localeCompare(b.first_name)
      );
      setSortedClinicians(sortedList);
    }
  }, [clinicianList]);

  let updatedApprovedUnitsHaveMissingInformation = updateApprovedUnits?.some(
    (unit) => !unit.code || !unit.codeId
  );
  let updateNewApprovedUnitsHaveMissingInformation = updateNewApprovedUnits?.some(
    (unit) => !unit.code || !unit.units || !unit.hours || !unit.codeId
  );

  const saveAuthDisabled =
    (!updateAuthorizationCode && !updatePendingAuth) ||
    !updateAuthorizationStartDate ||
    !updateAuthorizationEndDate ||
    !updateAuthorizationType ||
    saveButtonDisabled ||
    (updateAuthorizationType !== "secondary_auth" &&
      (!updateAuthorizationClinicianId ||
        updatedApprovedUnitsHaveMissingInformation ||
        (updateNewApprovedUnits.length > 0 && updateNewApprovedUnitsHaveMissingInformation)));
  const convertDate = (date) => {
    return dateWithoutTimezone(new Date(date).toISOString().split("T")[0]);
  };
  return (
    <Dialog
      maxWidth="md"
      open={open}
      onClose={onCloseUpdateAuthorization}
      aria-labelledby="update-authorization-title"
    >
      <DialogTitle id="update-authorization-title">
        Edit Authorization Period
        <IconButton aria-label="close" onClick={onCloseUpdateAuthorization}>
          <X />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ zIndex: 2010 }}>
        <Grid
          container
          justifyContent="space-between"
          spacing={"16px"}
          classes={{ root: classes.gridRoot }}
        >
          <Grid item xs={6} align="left">
            <TextField
              select
              label="Authorization Type"
              id="auth-type"
              onChange={onChange("updateAuthorizationType")}
              value={updateAuthorizationType}
              disabled={updateAuthorizationType === "secondary_auth"}
              fullWidth
            >
              <MenuItem disabled value="">
                Select Type
              </MenuItem>
              <MenuItem value={"pre_auth"}>Pre-Authorization</MenuItem>
              <MenuItem value={"auth"}>Ongoing Authorization</MenuItem>
              <MenuItem
                value={"secondary_auth"}
                disabled={updateAuthorizationType !== "secondary_auth"}
              >
                Secondary Authorization
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={6} align="left">
            {updateAuthorizationType !== "secondary_auth" && (
              <>
                <TextField
                  select
                  label="Approved Clinician"
                  id="auth-clinician"
                  onChange={onChange("updateAuthorizationClinicianId")}
                  value={updateAuthorizationClinicianId}
                  fullWidth
                >
                  <MenuItem disabled value={""}>
                    Select clinician
                  </MenuItem>
                  {sortedClinicians?.map((clinician) => {
                    return (
                      <MenuItem key={clinician.clinician_id} value={clinician.clinician_id}>
                        {clinician.first_name} {clinician.last_name}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </>
            )}
          </Grid>
          <Grid item xs={12} align="left" margin={"8px 0"}>
            <Alert
              action={
                <Switch
                  checked={updatePendingAuth}
                  onChange={onChange("updatePendingAuth")}
                  inputProps={{
                    "aria-label": "Temporary Authorization and Pending Credentials",
                  }}
                />
              }
              severity="info"
            >
              <AlertTitle>Temporary Authorization and Pending Credentials</AlertTitle>
              Turning on Temporary Authorization and Pending Credentials allows AnswersNow to see
              the client while authorizations and BCBA credentials are pending approvals
            </Alert>
          </Grid>
          <Grid item xs={4} align="left">
            <ANDatePicker
              label="Start Date"
              value={convertDate(updateAuthorizationStartDate)}
              onChange={(date) => onChangeDate("updateAuthorizationStartDate", date)}
              placeholder="Pick a date"
              format="PP" //"MMM d, yyyy"
              slotProps={{
                field: { fullWidth: true },
                popper: { disablePortal: false, style: { zIndex: 4000 } },
              }}
            />
          </Grid>
          <Grid item xs={4} align="left">
            <ANDatePicker
              label="End Date"
              value={convertDate(updateAuthorizationEndDate)}
              onChange={(date) => onChangeDate("updateAuthorizationEndDate", date)}
              placeholder="Pick a date"
              format="PP" //"MMM d, yyyy"
              slotProps={{
                field: { fullWidth: true },
                popper: { disablePortal: false, style: { zIndex: 4000 } },
              }}
            />
          </Grid>
          <Grid item xs={4} align="left">
            <TextField
              id="authorizationCode"
              placeholder="Enter authorization code"
              label="Authorization Number"
              onChange={onChange("updateAuthorizationCode")}
              value={updateAuthorizationCode}
              variant="outlined"
              fullWidth
              disabled={updatePendingAuth}
            />
          </Grid>
        </Grid>
        {updateAuthorizationType !== "secondary_auth" &&
          (updateApprovedUnits.length > 0 || updateNewApprovedUnits.length > 0) && (
            <Grid>
              <Card variant="outlined" style={{ margin: "24px 0" }}>
                <Grid container>
                  <Grid container style={{ backgroundColor: "#f2f2f2" }}>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={3} style={{ display: "flex", alignItems: "center" }}>
                          <Typography variant="subtitle1" style={{ padding: "16px" }}>
                            Billing Code
                          </Typography>
                        </Grid>
                        <Grid item xs={3} style={{ display: "flex", alignItems: "center" }}>
                          <Typography variant="subtitle1" style={{ padding: "16px" }}>
                            Number of Units
                          </Typography>
                        </Grid>
                        <Grid item xs={3} style={{ display: "flex", alignItems: "center" }}>
                          <Typography variant="subtitle1" style={{ padding: "16px" }}>
                            Number of Hours
                          </Typography>
                        </Grid>
                        <Grid item xs={3} style={{ display: "flex", alignItems: "center" }}>
                          <Typography
                            variant="subtitle1"
                            style={{ padding: "16px", paddingLeft: "32px" }}
                          >
                            Action
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {updateApprovedUnits.map((unit, i) => (
                    <React.Fragment key={`proved-${i}`}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={3} align="left">
                              <TextField
                                select
                                id="type"
                                disabled
                                value={unit.codeId}
                                fullWidth
                                style={{ padding: "16px 0 16px 16px" }}
                              >
                                <MenuItem disabled value="">
                                  Select a code
                                </MenuItem>
                                {availableInsuranceCodes.map((code, index) => (
                                  <MenuItem key={index} value={code.id}>
                                    {code.insurance_code}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                            <Grid item xs={3} align="left">
                              <TextField
                                id="outlined-helperText"
                                disabled={!unit.code}
                                placeholder="Enter number of units"
                                value={unit.units}
                                type="number"
                                fullWidth
                                InputProps={{ inputProps: { min: 0 } }}
                                style={{ padding: "16px 0 16px 16px" }}
                                onChange={(e) => onChangeUpdateApprovedUnitAmount(i, e)}
                              />
                            </Grid>
                            <Grid item xs={3} align="left">
                              <TextField
                                id="outlined-helperText"
                                disabled
                                placeholder="Enter number of hours"
                                value={unit.hours}
                                type="number"
                                style={{ padding: "16px 0 16px 16px" }}
                                fullWidth
                              />
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              style={{
                                display: "flex",
                                justifyContent: "left",
                                alignItems: "center",
                              }}
                            >
                              <Box style={{ padding: "16px 0 16px 16px" }}>
                                <Button
                                  variant="text"
                                  color="error"
                                  onClick={() => onRemoveUpdateNewApprovedUnit(i)}
                                  disabled={true}
                                >
                                  Delete
                                </Button>
                              </Box>
                            </Grid>
                          </Grid>
                          <Divider />
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  ))}

                  {updateNewApprovedUnits &&
                    updateNewApprovedUnits.length > 0 &&
                    updateNewApprovedUnits.map((unit, i) => (
                      <React.Fragment key={`updated-${i}`}>
                        <Grid container>
                          <Grid item xs={12}>
                            <Grid container spacing={2}>
                              <Grid item xs={3} align="left">
                                <TextField
                                  select
                                  id="type"
                                  onChange={(e) => onChangeUpdateNewApprovedUnitCode(i, e)}
                                  value={unit.code}
                                  fullWidth
                                  style={{ padding: "16px 0 16px 16px" }}
                                >
                                  <MenuItem disabled value="">
                                    Select a code
                                  </MenuItem>
                                  {availableInsuranceCodes.map((code, index) => (
                                    <MenuItem key={index} value={code.insurance_code}>
                                      {code.insurance_code}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Grid>
                              <Grid item xs={3} align="left">
                                <TextField
                                  id="outlined-helperText"
                                  disabled={!unit.code}
                                  placeholder="Enter number of units"
                                  value={unit.units}
                                  type="number"
                                  fullWidth
                                  InputProps={{ inputProps: { min: 0 } }}
                                  onChange={(e) => onChangeUpdateNewApprovedUnitAmount(i, e)}
                                  style={{ padding: "16px 0 16px 16px" }}
                                />
                              </Grid>
                              <Grid item xs={3} align="left">
                                <TextField
                                  id="outlined-helperText"
                                  disabled
                                  placeholder="Enter number of hours"
                                  value={unit.hours}
                                  type="number"
                                  fullWidth
                                  style={{ padding: "16px 0 16px 16px" }}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                style={{
                                  display: "flex",
                                  justifyContent: "left",
                                  alignItems: "center",
                                }}
                              >
                                <Box style={{ padding: "16px 0 16px 16px" }}>
                                  <Button
                                    color="error"
                                    variant="text"
                                    onClick={() => onRemoveUpdateNewApprovedUnit(i)}
                                  >
                                    Delete
                                  </Button>
                                </Box>
                              </Grid>
                            </Grid>
                            <Divider />
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    ))}
                </Grid>
              </Card>
            </Grid>
          )}

        <div className={classes.newAuthorizationButtonContainer}>
          {updateAuthorizationType !== "secondary_auth" ? (
            <Button
              onClick={onAddUpdateNewApprovedUnit}
              variant="contained"
              color="secondary"
              startIcon={<AddIcon />}
            >
              Add Billing Code
            </Button>
          ) : (
            <div />
          )}
        </div>
        <Box width="100%" display="flex" flexDirection="row" justifyContent="flex-end">
          <Button
            onClick={onCloseUpdateAuthorization}
            style={{ marginRight: "8px" }}
            variant="contained"
            color="secondary"
          >
            Cancel
          </Button>
          <Button disabled={saveAuthDisabled} onClick={onSubmitUpdateAuthorization}>
            Save Changes
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateAuthorization;
