import React from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
const localizer = momentLocalizer(moment);

export default function ANCalendar({
  components,
  view,
  colorIndexManager,
  nonWorkingHours = [],
  ...props
}) {
  const theme = useTheme();

  const formats = {
    dayFormat: (date, culture, localizer) => localizer.format(date, "ddd D", culture),
    dayRangeHeaderFormat: ({ end }, culture, localizer) =>
      localizer.format(end, "MMM yyyy", culture),
    timeGutterFormat: "h a",
  };

  const CustomEvent = ({ event }) => {
    let callStyling = parseInt(event.call_duration) >= 45 ? "long-event" : "short-event";
    const containerStyle =
      event.call_duration < 60 || view === Views.MONTH
        ? { gap: theme.spacing(1) }
        : { flexDirection: "column" };
    let eventTitle = event.title;
    if (event.no_show) eventTitle = "No-show";
    if (event.canceled) eventTitle = "Canceled";
    return (
      <div
        style={{
          display: "flex",
          whiteSpace: "nowrap",
          ...containerStyle,
        }}
      >
        {view === Views.MONTH ? (
          <div className="event-participant">
            <Typography className="event-client-name" variant="inherit">
              {event.client_name} {eventTitle}
            </Typography>
          </div>
        ) : (
          <>
            <div className="event-participant">
              <Typography className="event-client-name" variant="inherit">
                {event.client_name}
              </Typography>
            </div>
            <div className={callStyling}>
              <Typography>{eventTitle}</Typography>
            </div>
          </>
        )}
      </div>
    );
  };

  const CustomEventWrapper = (eventWrapperProps) => {
    // Function to extract percentage from string, used to remove the calc() from the width and left properties.
    // Original string: "calc(8.333333333333334% - 0px)", returns "8.333333333333334%".
    // We do this here to remove the padding from events that `dayLayoutAlgorithm="no-overlap"`
    // adds by default, we want them to 'touch' each other.
    function extractPercentage(str = "") {
      const match = str.match(/(\d+(\.\d+)?%)/);
      return match ? match[0] : null;
    }
    let style = {
      padding: "0px 10px 2px 10px",
    };
    if (view !== Views.MONTH) {
      style = {
        padding: theme.spacing(1, 0),
        position: "absolute",
        height: extractPercentage(eventWrapperProps.children.props.style.height),
        top: `${eventWrapperProps?.style?.top}%`,
        xOffset: `${eventWrapperProps?.style?.xOffset}%`,
        left: extractPercentage(eventWrapperProps.children.props.style.left),
        width: extractPercentage(eventWrapperProps.children.props.style.width),
      };
    }
    return <div style={style}>{eventWrapperProps.children}</div>;
  };

  const eventStyle = (e) => {
    const isExpired = new Date(e.end) < new Date();
    // If a client is selected, use its color, otherwise use the clinician's color.
    const colorSelectedClient = colorIndexManager?.find(e.client_id);
    const eventColorIndex = colorSelectedClient ?? colorIndexManager?.get(e.clinician_id) ?? 0;

    return {
      style: {
        padding:
          e.call_duration < 60 || view === Views.MONTH ? theme.spacing(1, 3) : theme.spacing(3),
      },
      className: `event-color-${eventColorIndex} ${
        isExpired ? "expired" : ""
      } ${e.canceled || e.no_show ? "red-text" : ""} ${e.googleEvent ? "private" : ""}`,
    };
  };

  const slotPropGetter = (date) => {
    for (let hours of nonWorkingHours) {
      let start = new Date(hours.startDateTime);
      let end = new Date(hours.endDateTime);
      if (date >= start && date < end) return { className: "non-working-hours" };
    }
  };

  return (
    <Calendar
      localizer={localizer}
      formats={formats}
      components={{
        event: CustomEvent,
        eventWrapper: CustomEventWrapper,
        ...components,
      }}
      view={view}
      eventPropGetter={eventStyle}
      dayLayoutAlgorithm="no-overlap"
      slotPropGetter={slotPropGetter}
      startAccessor="start"
      endAccessor="end"
      timeslots={1}
      step={60}
      {...props}
    >
      ANCalendar
    </Calendar>
  );
}
