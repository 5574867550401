import React from "react";
import { Typography, Button, TextField, DialogActions, Alert } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { PencilSimpleLine, WarningCircle } from "@phosphor-icons/react";

const TimesheetActions = (props) => {
  const {
    classes,
    onSaveTimesheetAsDraft,
    timesheetLoading,
    timesheetError,
    needsEdits,
    noteCompleted,
    approveTimeLoading,
    onToggleRequestChangesDialog,
    approveNote,
    userPermissions,
    approved,
    viewOnly,
    handleSubmit,
    submitting,
    setViewOnly,
    userId,
    clinicianUserId,
    locked,
    onToggleConfirmDeleteDialog,
    createNew,
  } = props;
  return (
    <DialogActions>
      {timesheetError && (
        <Alert
          severity="error"
          icon={<WarningCircle size={22} color="rgba(211, 47, 47, 1)" sx={{ marginRight: 20 }} />}
        >
          An error has occurred saving this note. Please try again.
        </Alert>
      )}
      {viewOnly ? (
        <>
          {userId === clinicianUserId && !locked && (
            <>
              <Button
                color="secondary"
                onClick={() => setViewOnly(false)}
                startIcon={<PencilSimpleLine size={20} color="rgba(0, 0, 0, 0.87)" />}
              >
                Edit
              </Button>
              {!noteCompleted && (
                <Button
                  color="primary"
                  variant="contained"
                  style={{ height: "34px" }}
                  onClick={handleSubmit}
                  disabled={submitting || timesheetLoading}
                >
                  {(submitting || timesheetLoading) && (
                    <CircularProgress style={{ color: "#C0C8D0", marginRight: 8 }} size={16} />
                  )}
                  Submit Service
                </Button>
              )}
            </>
          )}
          {userPermissions?.approve_off_platform_billing &&
            noteCompleted &&
            !needsEdits &&
            !approved && (
              <>
                <Button
                  color="secondary"
                  onClick={onToggleRequestChangesDialog}
                  disabled={approveTimeLoading}
                  startIcon={<PencilSimpleLine size={20} color="rgba(0, 0, 0, 0.87)" />}
                >
                  Suggest Edits
                </Button>
                <Button onClick={approveNote} disabled={approveTimeLoading}>
                  {approveTimeLoading && (
                    <CircularProgress style={{ color: "#C0C8D0", marginRight: 8 }} size={16} />
                  )}
                  Approve Service
                </Button>
              </>
            )}
        </>
      ) : (
        <>
          {!noteCompleted && !needsEdits && (
            <>
              {!createNew && (
                <Button variant="text" color="error" onClick={onToggleConfirmDeleteDialog}>
                  Delete Service
                </Button>
              )}
              <Button
                color="secondary"
                style={{ height: "34px" }}
                onClick={onSaveTimesheetAsDraft}
                disabled={submitting || timesheetLoading}
              >
                Save as Draft
              </Button>
            </>
          )}
          <Button
            color="primary"
            variant="contained"
            style={{ height: "34px" }}
            onClick={handleSubmit}
            disabled={submitting || timesheetLoading}
          >
            {(submitting || timesheetLoading) && (
              <CircularProgress style={{ color: "#C0C8D0", marginRight: 8 }} size={16} />
            )}
            Submit Service
          </Button>
        </>
      )}
    </DialogActions>
  );
};

export default TimesheetActions;
