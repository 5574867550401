import { createReducer } from "redux-create-reducer";
import actions from "../actions";

const initialState = {
  identity: null,
  roomId: null,
  videoId: null,
  videoKey: null,
  upcomingVideoCalls: [],
  oneTimeVideo: false,
  oneTimeVideoInfo: {},
  upcomingVideoLoading: false,
  isRunning: null,
  chatUrl: null,
  updatingLayout: false,
  layoutChangeSuccess: false,
  animations: [],
  activities: [],
  cards: [],
  sendingVideoSignatures: false,
  sendVideoSignaturesSuccess: false,
  callEnded: false,
  startDate: null,
  endDate: null,
  availability: [],
  loadingAvailability: false,
  scheduleCallSuccess: false,
  scheduleCallLoading: false,
  page: 0,
  rowsPerPage: 25,
  count: 0,
  sort: "",
  sortdirection: "asc",
  filterStartDate: "",
  filterEndDate: "",
  weekStartDate: "",
  weekEndDate: "",
  showWeekCanceledCalls: false,
  videoCallsList: [],
  loadingVideoCalls: false,
  videoChatInfo: null,
  videoBreakdown: [],
  videoBreakdownLoading: false,
  billableTimeIds: [],
  nextSessionNoteData: null,
  userPage: 0,
  userRowsPerPage: 10,
  userCount: 0,
  userSort: "vc.scheduled_date",
  userSortdirection: "desc",
  userFilterStartDate: "",
  userFilterEndDate: "",
  userVideoCalls: [],
  userVideoCallsLoading: false,
  nextClinicianCall: null,
  nextClinicianCallLoading: false,
  nextClinicianCallSuccess: false,
  clinicianCallsForWeek: [],
  clinicianCallsForWeekLoading: false,
  clinicianCallsForWeekSuccess: false,
  calendarCalls: [],
  calendarCallsLoading: false,
  type: "future",
  pastCalls: [],
  pastCallsLoading: false,
  pastCallsSort: "",
  pastCallsSortDirection: "desc",
  upcomingCalls: [],
  upcomingCallsLoading: false,
  upcomingCallsSort: "",
  upcomingCallsSortDirection: "asc",
  secondaryClinicianVideoSignatureId: "",
  secondaryClinicianJoinTime: null,
  secondaryClinicianLeaveTime: null,
  secondaryClinicianSessionNoteId: null,
  secondaryClinicianBillableTimeId: null,
  secondaryClinicianLeaveCallSuccess: false,
  clientId: null,
  primaryUserId: null,
  secondaryUserId: null,
  primarySignatureId: null,
  paymentSuccess: null,
  paymentError: null,
  rescheduleDetails: {},
  concernOptions: [],
  videoPrice: {},
  videoRoomState: {},
  callType: null,
  userGuid: null,
  checkingAccess: false,
  accessGranted: false,
  participants: [],
  callBreakdownLoading: false,
  callBreakdownSuccess: false,
  callBreakdownError: false,
  callBreakdownErrorMessage: "",
  loadingVideoBillingIssues: false,
  videoBillingIssues: [],
  setDemoCallLoading: false,
  setDemoCallSuccess: true,
  cancelVideoCallSuccess: false,
  cancelVideoCallLoading: false,
  cancelVideoCallFail: false,
  incompleteOffPlatform: [],
  completingOffPlatform: false,
  completeOffPlatformSuccess: false,
  offPlatformVideoSessionNoteId: null,
  offPlatformVideoBillableTimeId: null,
  nonWorkingHours: [],
  calendarPrivateBlocks: [],
  Attendee: null,
  Meeting: null,
};

const reducers = {
  [actions.CLEAR_MEETING_CONFIG](state) {
    return {
      ...state,
      Meeting: null,
      Attendee: null,
    };
  },
  [actions.FETCH_MEETING_CONFIG](state) {
    return {
      ...state,
      Meeting: null,
      Attendee: null,
    };
  },
  [actions.FETCH_MEETING_CONFIG_SUCCESS](state, { payload }) {
    console.log("FETCH_MEETING_CONFIG_SUCCESS: before<>after", state.roomId, payload.id);
    return {
      ...state,
      ...payload,
    };
  },
  [actions.FETCH_MESSAGING_CONFIG](state) {
    return {
      ...state,
      roomId: null,
      AppInstanceWorkerArn: null,
      AppInstanceArn: null,
      ChannelArn: null,
      MemberArn: null,
    };
  },
  [actions.FETCH_MESSAGING_CONFIG_SUCCESS](state, { payload }) {
    console.log("FETCH_MESSAGING_CONFIG_SUCCESS: before<>after", state.roomId, payload.ChannelArn);
    return {
      ...state,
      ...payload,
      roomId: payload.ChannelArn,
      identity: payload.MemberArn?.split("/").pop(),
    };
  },
  [actions.START_VIDEO_CALL](state) {
    return {
      ...state,
      videoId: null,
      sendingVideoSignatures: false,
      sendVideoSignaturesSuccess: false,
      callEnded: false,
      startDate: null,
      endDate: null,
    };
  },
  [actions.START_VIDEO_CALL_SUCCESS](state, { payload }) {
    return {
      ...state,
      videoId: payload.id,
      videoKey: payload.videoKey,
      sendingVideoSignatures: false,
      sendVideoSignaturesSuccess: false,
    };
  },
  [actions.SET_VIDEO_ROOM_ID](state, { payload }) {
    console.log("SET_VIDEO_ROOM_ID: before<>after", state.roomId, payload.room_id);
    return {
      ...state,
      roomId: payload.room_id,
      // chatUrl: payload.chatUrl,
      videoKey: payload.video_key, //PARENT INCLUDES video_key
    };
  },
  [actions.END_VIDEO_CALL](state) {
    return {
      ...state,
      videoId: state.videoId,
    };
  },
  [actions.END_VIDEO_CALL_SUCCESS](state) {
    return {
      ...state,
      callEnded: true,
      oneTimeVideo: false,
      oneTimeVideoInfo: {},
    };
  },
  [actions.GET_UPCOMING_VIDEO](state) {
    return {
      ...initialState,
      upcomingVideoLoading: true,
    };
  },
  [actions.GET_UPCOMING_VIDEO_SUCCESS](state, { payload }) {
    let sortedVideoCalls = payload.sort((a, b) => {
      var dateA = new Date(a.scheduled_date);
      var dateB = new Date(b.scheduled_date);
      return dateA - dateB;
    });
    return {
      ...state,
      upcomingVideoCalls: sortedVideoCalls,
      upcomingVideoLoading: false,
    };
  },
  [actions.SET_ONE_TIME_VIDEO_INFO](state, { payload }) {
    console.log("SET_ONE_TIME_VIDEO_INFO: before<>after", state.roomId, payload.roomId);

    return {
      ...state,
      videoId: payload.videoInfo.id,
      roomId: payload.roomId,
      videoKey: payload.videoKey,
      oneTimeVideo: true,
      sendingVideoSignatures: false,
      sendVideoSignaturesSuccess: false,
      secondaryClinicianLeaveCallSuccess: false,
    };
  },
  [actions.SET_ONE_TIME_VIDEO_INFO_SUCCESS](state, { payload }) {
    return {
      ...state,
      oneTimeVideoInfo: payload,
    };
  },
  [actions.CLEAR_ONE_TIME_VIDEO_INFO](state, { payload }) {
    return {
      ...state,
      oneTimeVideoInfo: {},
    };
  },
  [actions.GET_VIDEO_IS_RUNNING](state) {
    return {
      ...state,
      isRunning: null,
    };
  },
  [actions.GET_VIDEO_IS_RUNNING_SUCCESS](state, { payload }) {
    return {
      ...state,
      isRunning: payload.isRunning,
      videoId: payload.videoId,
    };
  },
  [actions.UPDATE_ROOM_LAYOUT](state, { payload }) {
    return {
      ...state,
      updatingLayout: true,
      layoutChangeSuccess: false,
    };
  },
  [actions.UPDATE_ROOM_LAYOUT_SUCCESS](state) {
    return {
      ...state,
      updatingLayout: false,
      layoutChangeSuccess: true,
    };
  },
  [actions.UPDATE_ROOM_LAYOUT_FAIL](state) {
    return {
      ...state,
      updatingLayout: false,
      layoutChangeSuccess: false,
    };
  },
  [actions.GET_ANIMATIONS_SUCCESS](state, { payload }) {
    return {
      ...state,
      animations: payload,
    };
  },
  [actions.GET_ACTIVITIES_SUCCESS](state, { payload }) {
    return {
      ...state,
      activities: payload,
    };
  },
  [actions.GET_CARDS_ACTIVITY_SUCCESS](state, { payload }) {
    return {
      ...state,
      cards: payload,
      promptCards: payload?.filter((card) => card.type === "Tact - Picture"),
    };
  },
  [actions.SEND_VIDEO_SIGNATURES](state) {
    return {
      ...state,
      sendingVideoSignatures: true,
    };
  },
  [actions.SEND_VIDEO_SIGNATURES_SUCCESS](state) {
    return {
      ...initialState,
      sendingVideoSignatures: false,
      sendVideoSignaturesSuccess: true,
    };
  },
  [actions.SEND_VIDEO_SIGNATURES_FAIL](state) {
    return {
      ...state,
      sendingVideoSignatures: false,
      sendVideoSignaturesSuccess: false,
    };
  },
  [actions.GET_BCBA_AVAILABILITY](state, { payload }) {
    return {
      ...state,
      availability: [],
      loadingAvailability: true,
    };
  },
  [actions.GET_BCBA_AVAILABILITY_SUCCESS](state, { payload }) {
    return {
      ...state,
      availability: payload,
      loadingAvailability: false,
    };
  },
  [actions.CLEAR_BCBA_AVAILABILITY](state) {
    return {
      ...state,
      availability: [],
      loadingAvailability: false,
    };
  },
  [actions.SCHEDULE_VIDEO_CALL](state) {
    return {
      ...state,
      scheduleCallLoading: true,
      scheduleCallSuccess: false,
    };
  },
  [actions.SCHEDULE_VIDEO_CALL_SUCCESS](state, { payload }) {
    return {
      ...state,
      scheduleCallLoading: false,
      scheduleCallSuccess: true,
      rescheduleDetails: payload, //PARENT UPDATES rescheduleDetails
    };
  },
  [actions.SCHEDULE_VIDEO_CALL_FAIL](state) {
    return {
      ...state,
      scheduleCallLoading: false,
      scheduleCallSuccess: false,
    };
  },
  [actions.SCHEDULE_VIDEO_CALL_RESET_STATE](state) {
    return {
      ...state,
      scheduleCallLoading: false,
      scheduleCallSuccess: false,
    };
  },
  [actions.RESCHEDULE_VIDEO_CALL](state) {
    return {
      ...state,
      scheduleCallLoading: true,
      scheduleCallSuccess: false,
    };
  },
  [actions.RESCHEDULE_VIDEO_CALL_SUCCESS](state, { payload }) {
    return {
      ...state,
      scheduleCallLoading: false,
      scheduleCallSuccess: true,
    };
  },
  [actions.RESCHEDULE_VIDEO_CALL_FAIL](state) {
    return {
      ...state,
      scheduleCallLoading: false,
      scheduleCallSuccess: false,
    };
  },
  [actions.CANCEL_VIDEO_CALL](state) {
    return {
      ...state,
      upcomingVideoLoading: true,
      completingOffPlatform: true,
      completeOffPlatformSuccess: false,
      cancelVideoCallLoading: true,
      cancelVideoCallFail: false,
    };
  },
  [actions.CANCEL_VIDEO_CALL_SUCCESS](state) {
    return {
      ...state,
      cancelVideoCallSuccess: true,
      upcomingVideoLoading: true,
      completingOffPlatform: false,
      completeOffPlatformSuccess: true,
      cancelVideoCallLoading: false,
      cancelVideoCallFail: false,
    };
  },
  [actions.CANCEL_VIDEO_CALL_FAIL](state) {
    return {
      ...state,
      cancelVideoCallSuccess: false,
      cancelVideoCallLoading: false,
      cancelVideoCallFail: true,
    };
  },
  [actions.RESET_CANCEL_VIDEO_CALL_STATE](state) {
    return {
      ...state,
      cancelVideoCallSuccess: false,
      cancelVideoCallLoading: false,
      cancelVideoCallFail: false,
    };
  },
  [actions.MARK_CLIENT_NO_SHOW](state) {
    return {
      ...state,
      completingOffPlatform: true,
      completeOffPlatformSuccess: false,
    };
  },
  [actions.SET_WEEK_START_DATE](state, { payload }) {
    return {
      ...state,
      weekStartDate: payload.startDate,
    };
  },
  [actions.SET_WEEK_END_DATE](state, { payload }) {
    return {
      ...state,
      weekEndDate: payload.endDate,
    };
  },
  [actions.SET_SHOW_WEEK_CANCELED_CALLS](state, { payload }) {
    return {
      ...state,
      showWeekCanceledCalls: payload,
    };
  },
  [actions.GET_CALENDAR_SESSIONS](state) {
    return {
      ...state,
      loadingVideoCalls: true,
    };
  },
  [actions.GET_VIDEO_CALLS_LIST](state) {
    return {
      ...state,
      loadingVideoCalls: true,
    };
  },
  [actions.GET_VIDEO_CALLS_FUTURE](state) {
    return {
      ...state,
      loadingVideoCalls: true,
    };
  },
  [actions.GET_VIDEO_CALLS_PAST](state) {
    return {
      ...state,
      loadingVideoCalls: true,
    };
  },
  [actions.SET_VIDEO_CALLS_LIST](state, { payload }) {
    return {
      ...state,
      videoCallsList: payload.videoCalls,
      count: payload.count,
      loadingVideoCalls: false,
    };
  },
  [actions.SET_CALENDAR_SESSIONS_LIST](state, { payload }) {
    return {
      ...state,
      videoCallsList: payload.calendarSessions,
      nonWorkingHours: payload.nonWorkingHours,
      calendarPrivateBlocks: payload.googleCalendarEvents,
      loadingVideoCalls: false,
    };
  },
  [actions.SET_VIDEO_CALLS_PAGE](state, { payload }) {
    return {
      ...state,
      page: payload.page,
    };
  },
  [actions.SET_VIDEO_CALLS_PER_PAGE](state, { payload }) {
    return {
      ...state,
      rowsPerPage: payload.rowsPerPage,
    };
  },
  [actions.SET_VIDEO_CALLS_SORT](state, { payload }) {
    let sortdirection = state.sortdirection;
    if (state.sort && state.sort !== payload.sort) {
      sortdirection = "asc";
    } else if (state.sort) {
      sortdirection = sortdirection === "asc" ? "desc" : "asc";
    }
    return {
      ...state,
      sort: payload.sort,
      sortdirection,
    };
  },
  [actions.SET_VIDEO_CALLS_TYPE](state, { payload }) {
    return {
      ...state,
      type: payload,
    };
  },
  [actions.SET_VIDEO_FILTER_START_DATE](state, { payload }) {
    return {
      ...state,
      filterStartDate: payload,
    };
  },
  [actions.SET_VIDEO_FILTER_END_DATE](state, { payload }) {
    return {
      ...state,
      filterEndDate: payload,
    };
  },
  [actions.GET_VIDEO_CHAT_INFO](state) {
    return {
      ...state,
      videoChatInfo: null,
      videoChatInfoLoading: true,
    };
  },
  [actions.GET_VIDEO_CHAT_INFO_SUCCESS](state, { payload }) {
    return {
      ...state,
      videoChatInfo: payload,
      videoChatInfoLoading: false,
    };
  },
  [actions.GET_VIDEO_CHAT_INFO_FAIL](state) {
    return {
      ...state,
      videoChatInfo: null,
      videoChatInfoLoading: false,
    };
  },
  [actions.GET_VIDEO_CALL_BREAKDOWN](state, { payload }) {
    return {
      ...state,
      videoBreakdownLoading: true,
    };
  },
  [actions.GET_VIDEO_CALL_BREAKDOWN_SUCCESS](state, { payload }) {
    return {
      ...state,
      videoBreakdownLoading: false,
      videoBreakdown: payload,
    };
  },
  [actions.GET_USER_VIDEO_CALLS](state, { payload }) {
    return {
      ...state,
      userVideoCallsLoading: true,
    };
  },
  [actions.GET_USER_VIDEO_CALLS_SUCCESS](state, { payload }) {
    return {
      ...state,
      userVideoCalls: payload.videoCalls,
      userCount: payload.count,
      userVideoCallsLoading: false,
    };
  },
  [actions.GET_USER_VIDEO_CALLS_FAIL](state, { payload }) {
    return {
      ...state,
      userVideoCallsLoading: false,
    };
  },
  [actions.SET_USER_VIDEO_CALLS_PAGE](state, { payload }) {
    return {
      ...state,
      userPage: payload.page,
    };
  },
  [actions.SET_USER_VIDEO_CALLS_PER_PAGE](state, { payload }) {
    return {
      ...state,
      userRowsPerPage: payload.rowsPerPage,
    };
  },
  [actions.SET_USER_VIDEO_CALLS_SORT](state, { payload }) {
    let userSortdirection = state.userSortdirection;
    if (state.userSort && state.userSort !== payload.sort) {
      userSortdirection = "asc";
    } else if (state.userSort) {
      userSortdirection = userSortdirection === "asc" ? "desc" : "asc";
    }
    return {
      ...state,
      userSort: payload.sort,
      userSortdirection,
    };
  },
  [actions.SET_USER_VIDEO_CALLS_TYPE](state, { payload }) {
    return {
      ...state,
      type: payload,
    };
  },
  [actions.SET_USER_VIDEO_FILTER_START_DATE](state, { payload }) {
    return {
      ...state,
      userFilterStartDate: payload,
    };
  },
  [actions.SET_USER_VIDEO_FILTER_END_DATE](state, { payload }) {
    return {
      ...state,
      userFilterEndDate: payload,
    };
  },
  [actions.GET_NEXT_CLINICIAN_CALL](state) {
    console.log("GET_NEXT_CLINICIAN_CALL", null);
    return {
      ...state,
      nextClinicianCall: null,
      nextClinicianCallLoading: true,
      nextClinicianCallSuccess: false,
      startDate: null,
      videoId: null,
      roomId: null,
      videoKey: null,
    };
  },
  [actions.GET_NEXT_CLINICIAN_CALL_SUCCESS](state, { payload }) {
    return {
      ...state,
      nextClinicianCall: payload[0] || null,
      nextClinicianCallLoading: false,
      nextClinicianCallSuccess: true,
    };
  },
  [actions.GET_NEXT_CLINICIAN_CALL_FAIL](state) {
    return {
      ...state,
      nextClinicianCallLoading: false,
      nextClinicianCallSuccess: false,
    };
  },
  [actions.GET_CLINICIAN_CALLS_WEEK](state) {
    return {
      ...state,
      clinicianCallsForWeekLoading: true,
      clinicianCallsForWeekSuccess: false,
    };
  },
  [actions.GET_CLINICIAN_CALLS_WEEK_SUCCESS](state, { payload }) {
    return {
      ...state,
      clinicianCallsForWeek: payload,
      clinicianCallsForWeekSuccess: true,
      clinicianCallsForWeekLoading: false,
    };
  },
  [actions.GET_CLINICIAN_CALLS_WEEK_FAIL](state) {
    return {
      ...state,
      clinicianCallsForWeekLoading: false,
      clinicianCallsForWeekSuccess: false,
    };
  },
  [actions.GET_CLINICIAN_CALENDAR_CALLS](state, { payload }) {
    return {
      ...state,
      calendarCallsLoading: true,
    };
  },
  [actions.GET_CLINICIAN_CALENDAR_CALLS_SUCCESS](state, { payload }) {
    return {
      ...state,
      calendarCallsLoading: false,
      calendarCalls: payload.callList,
    };
  },
  [actions.GET_CLINICIAN_PAST_CALLS](state) {
    return {
      ...state,
      pastCallsLoading: true,
    };
  },
  [actions.GET_CLINICIAN_PAST_CALLS_SUCCESS](state, { payload }) {
    return {
      ...state,
      pastCalls: payload.pastCalls,
      pastCallsLoading: false,
    };
  },
  [actions.GET_CLINICIAN_UPCOMING_CALLS](state) {
    console.log("GET_CLINICIAN_UPCOMING_CALLS", null);
    return {
      ...state,
      upcomingCallsLoading: true,
      startDate: null,
      videoId: null,
      roomId: null,
      videoKey: null,
    };
  },
  [actions.GET_CLINICIAN_UPCOMING_CALLS_SUCCESS](state, { payload }) {
    return {
      ...state,
      upcomingCalls: payload.upcomingCalls,
      upcomingCallsLoading: false,
    };
  },
  [actions.SET_CLINICIAN_PAST_CALLS_SORT](state, { payload }) {
    let pastCallsSortDirection = state.pastCallsSortDirection;
    if (state.pastCallsSort && state.pastCallsSort !== payload.sort) {
      pastCallsSortDirection = "desc";
    } else if (state.pastCallsSort) {
      pastCallsSortDirection = pastCallsSortDirection === "desc" ? "asc" : "desc";
    }
    return {
      ...state,
      pastCallsSort: payload.sort,
      pastCallsSortDirection,
    };
  },
  [actions.SET_CLINICIAN_UPCOMING_CALLS_SORT](state, { payload }) {
    let upcomingCallsSortDirection = state.upcomingCallsSortDirection;
    if (state.upcomingCallsSort && state.upcomingCallsSort !== payload.sort) {
      upcomingCallsSortDirection = "asc";
    } else if (state.upcomingCallsSort) {
      upcomingCallsSortDirection = upcomingCallsSortDirection === "asc" ? "desc" : "asc";
    }
    return {
      ...state,
      upcomingCallsSort: payload.sort,
      upcomingCallsSortDirection,
    };
  },
  [actions.CHECK_VIDEO_CALL_ACCESS](state) {
    return {
      ...state,
      accessGranted: null,
      checkingAccess: true,
    };
  },
  [actions.CHECK_VIDEO_CALL_ACCESS_SUCCESS](state, { payload }) {
    return {
      ...state,
      accessGranted: payload.access,
      checkingAccess: false,
    };
  },
  [actions.CHECK_VIDEO_CALL_ACCESS_FAIL](state) {
    return {
      ...state,
      checkingAccess: false,
    };
  },
  [actions.SECONDARY_CLINICIAN_JOIN_CALL](state, { payload }) {
    return {
      ...state,
      secondaryClinicianLeaveTime: null,
      secondaryClinicianJoinTime: null,
    };
  },
  [actions.SECONDARY_CLINICIAN_JOIN_CALL_SUCCESS](state, { payload }) {
    return {
      ...state,
      secondaryClinicianVideoSignatureId: payload.video_signature_id,
      secondaryClinicianJoinTime: payload.secondary_clinician_join_date,
    };
  },
  [actions.SECONDARY_CLINICIAN_LEAVE_CALL](state) {
    return {
      ...state,
      secondaryClinicianLeaveCallSuccess: false,
    };
  },
  [actions.SECONDARY_CLINICIAN_LEAVE_CALL_SUCCESS](state, { payload }) {
    return {
      ...state,
      secondaryClinicianLeaveTime: payload.secondary_clinician_leave_date,
      secondaryClinicianSessionNoteId: payload.session_note_id,
      secondaryClinicianBillableTimeId: payload.billable_time_id,
      secondaryClinicianLeaveCallSuccess: true,
    };
  },
  [actions.SET_SECONDARY_CLINICIAN_SESSION_TIMES](state, { payload }) {
    return {
      ...state,
      secondaryClinicianJoinTime: payload.start_date,
      secondaryClinicianLeaveTime: payload.end_date,
    };
  },
  [actions.GET_VIDEO_ROOM_STATE_SUCCESS](state, { payload }) {
    return {
      ...state,
      videoRoomState: payload,
    };
  },
  [actions.GET_CUSTOMER_UPCOMING_CALLS_SUCCESS](state, { payload }) {
    let assessmentVideo = payload.filter((video) => !video.end_date);

    return assessmentVideo.length > 0
      ? {
          ...state,
          scheduledDate: assessmentVideo[0].scheduled_date,
          videoId: assessmentVideo[0].id,
          roomId: assessmentVideo[0].room_id,
          videoKey: assessmentVideo[0].video_key,
          rescheduleDetails: {
            ...assessmentVideo[0],
            videoId: assessmentVideo[0].id,
            vyteId: assessmentVideo[0].vyte_id,
          },
        }
      : { ...state };
  },
  [actions.GET_VIDEO_CALL_INFO](state) {
    return {
      ...state,
      getCallInfoLoading: true,
    };
  },
  [actions.GET_VIDEO_CALL_INFO_FAIL](state) {
    return {
      ...state,
      getCallInfoLoading: false,
      getCallInfoSuccess: false,
    };
  },
  [actions.GET_VIDEO_CALL_INFO_SUCCESS](state, { payload }) {
    return {
      ...state,
      startDate: payload.start_date,
      endDate: payload.end_date,
      roomId: payload.room_id ? payload.room_id : state.roomId,
      videoKey: payload.video_key,
      isRunning: payload.room_id ? true : false,
      callType: payload.call_type,
      videoId: payload.id,
      userGuid: payload.user_guid,
      sendingVideoSignatures: false,
      sendVideoSignaturesSuccess: false,
      clientId: payload.client_id,
      primaryUserId: payload.clinician_user_id,
      secondaryUserId: payload.secondary_clinician_user_id,
      participants: payload.participants,
      videoChatUrl: payload.video_chat_url,
      defaultVideoPlatform: payload.defaultVideoPlatform,
      rescheduleDetails: {
        videoId: payload.id,
        vyteId: payload.vyte_id,
        clinicianUserId: payload.clinician_user_id,
        ...payload,
      },
      getCallInfoLoading: false,
      getCallInfoSuccess: true,
    };
  },
  [actions.CLEAR_VIDEO_INFO](state) {
    return {
      ...state,
      identity: null,
      roomId: null,
      videoKey: null,
      videoId: null,
      isRunning: null,
      callType: null,
      userGuid: null,
      startDate: null,
      endDate: null,
      clientId: null,
      primaryUserId: null,
      secondaryUserId: null,
      secondaryClinicianJoinTime: null,
      secondaryClinicianLeaveTime: null,
    };
  },
  [actions.SEND_PARENT_VIDEO_SIGNATURES](state) {
    return {
      ...state,
      sendingVideoSignatures: true,
    };
  },
  [actions.SEND_PARENT_VIDEO_SIGNATURES_SUCCESS](state) {
    return {
      ...initialState,
      sendingVideoSignatures: false,
      sendVideoSignaturesSuccess: true,
    };
  },
  [actions.SEND_PARENT_VIDEO_SIGNATURES_FAIL](state) {
    return {
      ...state,
      sendingVideoSignatures: false,
      sendVideoSignaturesSuccess: false,
    };
  },
  [actions.CHECK_VIDEO_CALL_ACCESS](state) {
    return {
      ...state,
      checkingAccess: true,
      accessGranted: false,
    };
  },
  [actions.CHECK_VIDEO_CALL_ACCESS_SUCCESS](state, { payload }) {
    return {
      ...state,
      checkingAccess: false,
      accessGranted: payload.access,
    };
  },
  [actions.CHECK_VIDEO_CALL_ACCESS_FAIL](state) {
    return {
      ...state,
      checkingAccess: false,
    };
  },
  [actions.SEND_BILLABLE_TIME_FOR_VIDEO](state) {
    return {
      ...state,
      callBreakdownLoading: true,
      callBreakdownSuccess: false,
      callBreakdownError: false,
      callBreakdownErrorMessage: "",
      billableTimeIds: [],
      nextSessionNoteData: null,
    };
  },
  [actions.SEND_BILLABLE_TIME_FOR_VIDEO_SUCCESS](state, { payload }) {
    return {
      ...state,
      nextSessionNoteData: payload?.id ? payload : null,
      callBreakdownLoading: false,
      callBreakdownSuccess: true,
    };
  },
  [actions.SEND_BILLABLE_TIME_FOR_VIDEO_FAIL](state, { payload }) {
    return {
      ...state,
      callBreakdownLoading: false,
      callBreakdownError: true,
      callBreakdownErrorMessage: payload,
    };
  },
  [actions.GET_VIDEO_CALL_BILLING_ISSUES](state) {
    return {
      ...state,
      loadingVideoBillingIssues: true,
    };
  },
  [actions.GET_VIDEO_CALL_BILLING_ISSUES_SUCCESS](state, { payload }) {
    return {
      ...state,
      videoBillingIssues: payload,
      loadingVideoBillingIssues: false,
    };
  },
  [actions.GET_VIDEO_CALL_BILLING_ISSUES_FAIL](state) {
    return {
      ...state,
      loadingVideoBillingIssues: false,
    };
  },
  [actions.SET_DEMO_CALL](state) {
    return {
      ...state,
      setDemoCallLoading: true,
      setDemoCallSuccess: false,
    };
  },
  [actions.SET_DEMO_CALL_SUCCESS](state, { payload }) {
    return {
      ...state,
      setDemoCallLoading: false,
      setDemoCallSuccess: true,
      rescheduleDetails: payload,
    };
  },
  [actions.SET_DEMO_CALL_FAIL](state) {
    return {
      ...state,
      setDemoCallLoading: false,
      setDemoCallSuccess: false,
    };
  },
  [actions.GET_CLINICIAN_WEEKLY_CALENDAR_SESSIONS](state) {
    return {
      ...state,
      calendarCallsLoading: true,
    };
  },
  [actions.GET_CLINICIAN_WEEKLY_CALENDAR_SESSIONS_SUCCESS](state, { payload }) {
    return {
      ...state,
      calendarCallsLoading: false,
      calendarCalls: payload.calendarSessions,
      nonWorkingHours: payload.nonWorkingHours,
      calendarPrivateBlocks: payload.googleCalendarEvents,
    };
  },
  [actions.GET_CLINICIAN_INCOMPLETE_OFF_PLATFORM_CALLS_SUCCESS](state, { payload }) {
    return {
      ...state,
      incompleteOffPlatform: payload,
      completingOffPlatform: false,
      completeOffPlatformSuccess: false,
    };
  },
  [actions.COMPLETE_OFF_PLATFORM_CALL](state) {
    return {
      ...state,
      completingOffPlatform: true,
      completeOffPlatformSuccess: false,
      offPlatformVideoSessionNoteId: null,
      offPlatformVideoBillableTimeId: null,
    };
  },
  [actions.COMPLETE_OFF_PLATFORM_CALL_SUCCESS](state, { payload }) {
    return {
      ...state,
      completingOffPlatform: false,
      completeOffPlatformSuccess: true,
      offPlatformVideoSessionNoteId: payload?.sessionNoteId || null,
      offPlatformVideoBillableTimeId: payload?.billableTimeId || null,
    };
  },
  [actions.COMPLETE_OFF_PLATFORM_CALL_FAIL](state) {
    return {
      ...state,
      completingOffPlatform: false,
    };
  },
};

export default createReducer(initialState, reducers);
