import React from "react";
import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const EditActions = (props) => {
  const {
    needsEdits,
    noteCompleted,
    onSaveAsDraft,
    callBreakdownLoading,
    onOpenConfirmSubmitDialog,
    confirmExitDialogOpen,
    videoId,
    associatedSessionNotes,
    currentIndex,
    submitClicked,
    associatedTimesDraftSaving,
    associatedTimesSubmitSaving,
    locked,
    onToggleConfirmDeleteDialog,
    createNew,
  } = props;
  const currentBillingItem = associatedSessionNotes[currentIndex];
  const displayActions =
    (currentBillingItem?.description && currentBillingItem?.actual_time >= 0) || !videoId;
  const submitLoading =
    associatedTimesSubmitSaving ||
    submitClicked ||
    (callBreakdownLoading && !confirmExitDialogOpen);

  return displayActions ? (
    <>
      {!noteCompleted && !needsEdits && (
        <>
          {!videoId && !createNew && (
            <Button variant="text" color="error" onClick={onToggleConfirmDeleteDialog}>
              Delete Service
            </Button>
          )}
          <Button
            color="secondary"
            style={{ marginRight: 10 }}
            onClick={onSaveAsDraft}
            disabled={associatedTimesSubmitSaving || associatedTimesDraftSaving || submitLoading}
          >
            {associatedTimesDraftSaving && (
              <CircularProgress style={{ color: "#C0C8D0", marginRight: 8 }} size={16} />
            )}
            Save as Draft
          </Button>
        </>
      )}
      {!locked && (
        <Button
          onClick={onOpenConfirmSubmitDialog}
          disabled={submitLoading || associatedTimesDraftSaving || associatedTimesSubmitSaving}
        >
          {(submitLoading || associatedTimesSubmitSaving) && (
            <CircularProgress style={{ color: "#C0C8D0", marginRight: 8 }} size={16} />
          )}
          Submit Service
        </Button>
      )}
    </>
  ) : null;
};

export default EditActions;
