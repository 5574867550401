import React from "react";
import ReactPlayer from "react-player/youtube";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import PauseIcon from "@mui/icons-material/PauseCircleOutline";
import PlayIcon from "@mui/icons-material/PlayCircleOutline";

const VideoActivity = (props) => {
  const {
    classes,
    youTubeRef,
    videoPlaying,
    videoStartTime,
    pauseVideo,
    playVideo,
    animation,
    setVideoPlaying,
    seekToTime,
    clinicianInControl,
    activity,
    isCaregiver,
    deviceType,
  } = props;
  const videoURL = activity.active ? activity.asset_url : animation.asset_url;
  return (
    <div className={classes.videoActivity}>
      <ReactPlayer
        playing={videoPlaying}
        url={videoURL}
        ref={youTubeRef}
        width="100%"
        height="100%"
        controls={false}
        playsinline
        onPlay={() => setVideoPlaying(true)}
        onPause={() => setVideoPlaying(false)}
        onBuffer={() => {
          if (videoStartTime) {
            seekToTime(videoStartTime);
          }
        }}
        config={{
          youtube: {
            playerVars: {
              rel: 0,
              controls: 0,
              modestbranding: 1,
              widget_referrer: process.env.BASE_PARENT_URL,
            },
          },
          onUnstarted: () => {},
        }}
      />
      {(!deviceType || !deviceType.toLowerCase().includes("ios")) && (
        <div className={classes.videoActivityOverlay} />
      )}
      {clinicianInControl && !isCaregiver && (
        <div className={classes.videoControlsContainer}>
          <Button
            startIcon={videoPlaying ? <PauseIcon /> : <PlayIcon />}
            variant="contained"
            className={classes.videoPlayPauseButton}
            aria-label="Play/Pause"
            onClick={videoPlaying ? pauseVideo : playVideo}
          >
            <Typography component="p">{videoPlaying ? "Pause" : "Play"}</Typography>
          </Button>
        </div>
      )}
    </div>
  );
};

export default VideoActivity;
