import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { X } from "@phosphor-icons/react";

import ANCalendarEvent from "./ANCalendarEvent";

/**
  @typedef {import("api/typesDef").CalendarEvent} Event
  @typedef {import("utils/ColorIndexManager").default} ColorIndexManager
*/

/**
 * @param {Object} props - The props object.
 * @param {Event} props.event - The event object that contains the event details.
 * @param {ColorIndexManager} props.colorIndexManager - The color index manager object.
 * @param {(event: Event) => void} props.onSelectEvent - The function to select an event.
 * @returns {JSX.Element} The EventWrapper component is being returned.
 */
const EventWrapper = ({ event, colorIndexManager, onSelectEvent }) => {
  const { end, client_name, client_id, clinician_id, title, googleEvent, canceled, no_show } =
    event;

  const isExpired = new Date(end) < new Date() ? "expired" : "";
  const isPrivate = googleEvent ? "private" : "";
  const isCancel = canceled || no_show ? "red-text" : "";
  // If a client is selected, use its color, otherwise use the clinician's color.
  const colorSelectedClient = colorIndexManager.find(client_id);
  const eventColorIndex = colorSelectedClient ?? colorIndexManager.get(clinician_id) ?? "none";

  return (
    <button
      title={client_name || title}
      className={`overlay-rbc-event rbc-event event-color-${eventColorIndex} ${isExpired} ${isPrivate} ${isCancel}`}
      onClick={() => onSelectEvent(event)}
    >
      <section className="rbc-event-content h100">
        <ANCalendarEvent event={event} view="DAY" fixedSize="large" />
      </section>
    </button>
  );
};

/**
 * The ANCalendarOverlay is a custom overlay for the ANCalendar event list.
 * @param {Object} props - The props object.
 * @param {string} [props.className] - The class name to apply to the overlay
 * @param {Event[]} props.events - The event object that contains the event details.
 * @param {string} props.title - The title of the overlay, e.g., "Event Details".
 * @param {boolean} props.open - The boolean value to open the overlay.
 * @param {ColorIndexManager} props.colorIndexManager - The color index manager object.
 * @param {() => void} props.onClose - The function to close the overlay.
 * @param {(event: Event) => void} props.onSelectEvent - The function to select an event.
 * @returns {JSX.Element} The ANCalendarOverlay component is being returned.
 */
const ANCalendarOverlay = ({
  className = "",
  events,
  title,
  open,
  colorIndexManager,
  onClose,
  onSelectEvent,
}) => {
  return (
    <Dialog open={open} maxWidth="xs">
      <DialogTitle>
        {title}
        <IconButton aria-label="close" onClick={onClose}>
          <X />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ height: "384px", overflowY: "auto" }} style={{ paddingTop: 24 }}>
        <Stack spacing={3} direction="column" className={`ANCalendarOverlay ${className}`}>
          {events.map((event, idx) => (
            <EventWrapper
              key={idx}
              event={event}
              colorIndexManager={colorIndexManager}
              onSelectEvent={onSelectEvent}
            />
          ))}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default ANCalendarOverlay;
