import React from "react";
import { TextField, Typography, FormLabel, FormGroup } from "@mui/material";
import DatePicker from "elements/ANDatePicker";
import { differenceInDays } from "date-fns";
import MenuItem from "@mui/material/MenuItem";
import ANTimePicker from "elements/ANTimePicker";

const EditAdhocSessionDetails = (props) => {
  const {
    classes,
    startDate,
    endDate,
    endTime,
    serviceType,
    duration,
    setError,
    selectedClientId,
    clientList,
    selectedClientError,
    selectedClientMissingCodeError,
    insuranceCodesLoading,
    insuranceCodes,
    onChangeSelectedClient,
    setDate,
    setTime,
    setEndTime,
    startDateError,
    serviceTime,
    serviceEndTimeError,
    serviceTimeError,
    adhocCallDetailsRef,
    onChangeServiceType,
    serviceTypeOptions,
    serviceDetailsRef,
  } = props;

  return (
    <div className={classes.adhocSessionDetails} ref={adhocCallDetailsRef}>
      <div className={classes.adhocDetailRow}>
        <FormGroup style={{ display: "flex", flex: "1 0 0" }}>
          <FormLabel component="legend" className={classes.callDetailLabel}>
            Client Name
          </FormLabel>
          <TextField
            id="selectedClientId"
            name="selectedClientId"
            value={selectedClientId}
            onChange={onChangeSelectedClient}
            select
            fullWidth
            disabled={insuranceCodesLoading}
            onFocus={() => setError("focusError", false)}
          >
            <MenuItem disabled value={""}>
              Select Client
            </MenuItem>
            {clientList.map((client, index) => {
              return (
                <MenuItem key={client.client_id} value={client.client_id}>
                  {client.client_name}
                </MenuItem>
              );
            })}
          </TextField>
          {selectedClientError && (
            <Typography component="p" className={classes.helperText}>
              Select the client
            </Typography>
          )}
          {selectedClientMissingCodeError && !selectedClientError && (
            <Typography component="p" className={classes.helperText}>
              Client not authorized for this service type
            </Typography>
          )}
        </FormGroup>
        <FormGroup style={{ display: "flex", flex: "1 0 0" }}>
          <FormLabel component="legend" className={classes.callDetailLabel}>
            Service Type
          </FormLabel>
          <TextField
            id="selectedservicetype"
            name="selectedservicetype"
            value={serviceType?.description}
            onChange={(e) => onChangeServiceType(e)}
            placeholder="Please Select"
            select
            fullWidth
            disabled={insuranceCodesLoading}
          >
            {serviceTypeOptions?.session?.map((code) => (
              <MenuItem
                key={code.id}
                value={code.description}
                disabled={
                  insuranceCodes.length > 0 && !insuranceCodes.some((ic) => ic.id == code.id)
                }
              >
                {code.description}
              </MenuItem>
            ))}
          </TextField>
        </FormGroup>
      </div>

      <div className={classes.adhocDetailRow} ref={serviceDetailsRef}>
        <FormGroup style={{ display: "flex", flex: "1 0 0" }}>
          <FormLabel component="legend" className={classes.callDetailLabel}>
            Date
          </FormLabel>
          <DatePicker
            id="adhocNoteDatePicker"
            value={startDate ? new Date(startDate) : null}
            onChange={(date) => setDate(date)}
            format="PP" //"MMM d, yyyy"
            fullWidth
            placeholder="Select service date"
            disableFuture
          />
          {startDateError && !startDate && (
            <Typography component="p" className={classes.helperText}>
              Select the date
            </Typography>
          )}
          {startDateError && startDate && differenceInDays(new Date(), new Date(startDate)) > 7 && (
            <Typography component="p" className={classes.helperText}>
              Time must be billed within 7 days of service.
            </Typography>
          )}
        </FormGroup>
        <FormGroup style={{ display: "flex", flex: "1 0 0" }}>
          <FormLabel component="legend" className={classes.callDetailLabel}>
            Start Time
          </FormLabel>
          <ANTimePicker
            id="time"
            variant="outlined"
            color="primary"
            name="serviceTime"
            placeholder="8:00 am"
            fullWidth
            value={serviceTime}
            onChange={(e) => setTime(e?.target?.value ?? e)}
            onFocus={() => setError("serviceTimeError", false)}
            onBlur={() => {
              !serviceTime
                ? setError("serviceTimeError", true)
                : setError("serviceTimeError", false);
            }}
            helperText={serviceTimeError && !serviceTime ? "Select start time" : null}
            FormHelperTextProps={{
              style: { backgroundColor: "#F6F7F8" },
            }}
          />
        </FormGroup>
        <FormGroup style={{ display: "flex", flex: "1 0 0" }}>
          <FormLabel component="legend" className={classes.callDetailLabel}>
            End Time
          </FormLabel>
          <ANTimePicker
            id="time"
            variant="outlined"
            color="primary"
            name="endDate"
            placeholder="9:00 am"
            fullWidth
            value={endTime}
            onChange={(e) => setEndTime(e?.target?.value ?? e)}
            onFocus={() => setError("serviceEndTimeError", false)}
            onBlur={() => {
              !serviceTime
                ? setError("serviceEndTimeError", true)
                : setError("serviceEndTimeError", false);
            }}
            helperText={serviceEndTimeError && !endTime ? "Select end time" : null}
            FormHelperTextProps={{
              style: { backgroundColor: "#F6F7F8" },
            }}
          />
          {serviceType?.time_limit && endTime && duration > parseInt(serviceType.time_limit) && (
            <Typography component="p" className={classes.helperText}>
              Service Type limited to {serviceType.time_limit} minutes
            </Typography>
          )}
          {startDateError &&
            startDate &&
            differenceInDays(new Date(), new Date(startDate)) < 7 &&
            endTime &&
            new Date(endDate) > new Date() && (
              <Typography component="p" className={classes.helperText}>
                End Time cannot be in the future
              </Typography>
            )}
        </FormGroup>
      </div>
    </div>
  );
};

export default EditAdhocSessionDetails;
