/**
 * Converts an uppercase underscore-separated string to camelCase.
 *
 * @param {string} underscoreString - The string to convert.
 * @returns {string} The converted string in camelCase.
 *
 * @example
 * // returns 'caregiverTraining'
 * titleCaseToCamelCase('CAREGIVER_TRAINING');
 */
const titleCaseToCamelCase = (underscoreString) =>
  underscoreString.toLowerCase().replace(/_./g, (match) => match.charAt(1).toUpperCase());

export default titleCaseToCamelCase;
