import React from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { CheckCircle } from "@phosphor-icons/react";

const regexs = {
  upperCase: new RegExp(/[A-Z]/),
  lowerCase: new RegExp(/[a-z]/),
  includesNumber: new RegExp(/[0-9]/),
  properLength: new RegExp(/.{8,}/),
};

export const PasswordValidationMarkers = (props) => {
  const { classes, password, confirmPassword } = props;

  const upperCase = regexs.upperCase.test(password);
  const lowerCase = regexs.lowerCase.test(password);
  const includesNumber = regexs.includesNumber.test(password);
  const properLength = regexs.properLength.test(password);
  const matches = confirmPassword && password === confirmPassword;

  const requirements = [
    { label: "8 characters", isValid: properLength },
    { label: "1 number", isValid: includesNumber },
    { label: "1 uppercase letter", isValid: upperCase },
    { label: "1 lowercase letter", isValid: lowerCase },
    { label: "Passwords match", isValid: matches },
  ];

  const listItems = requirements.map(({ label, isValid }, index) => {
    return (
      <ListItem key={index} disableGutters>
        <ListItemIcon className={classes.listIcon}>
          <CheckCircle
            size={24}
            color={isValid ? "#2E7D32" : null}
            weight={isValid ? "fill" : "regular"}
          />
        </ListItemIcon>
        <ListItemText
          disableTypography
          primary={label}
          className={isValid ? classes.listTextValid : classes.listText}
        />
      </ListItem>
    );
  });

  return (
    <Grid container marginTop={6}>
      <Grid item>
        <List sx={{ padding: 0 }} dense>
          {listItems}
        </List>
      </Grid>
    </Grid>
  );
};
