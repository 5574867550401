import React from "react";
import { withRouter } from "react-router";
import withStyles from "@mui/styles/withStyles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import styles from "../../theme/globalStyles";
import { format } from "date-fns";
import CircularProgress from "@mui/material/CircularProgress";
import { CustomTable } from "elements/DEPRECATED_elements";
import { CustomHeaderCell } from "elements/DEPRECATED_elements";

import { convertTokens } from "@date-fns/upgrade/v2";

const InsuranceCustomers = (props) => {
  const {
    classes,
    customers,
    handleChangePage,
    handleChangeRowsPerPage,
    handleSortClick,
    viewCustomerDetails,
    count,
    page,
    sort,
    direction,
    rowsPerPage,
    currentInsuranceTab,
    userPermissions,
  } = props;

  let rows = customers;

  let customerStatus = (status) => {
    switch (status) {
      case "AWAITING_ONBOARDING":
        return "Awaiting Orientation Call";
      case "AWAITING_ELIGIBILITY":
        return "Collecting Documents";
      case "FULL_CUSTOMER":
        return "Approved for Therapy";
      case "AWAITING_INSURANCE_APPROVAL":
        return "Awaiting Therapy Approval";
      case "INSURANCE_APPROVED":
        return "Approved for Therapy";
      case "ELIGIBLE":
        return "Documents Submitted - Awaiting Assessment Approval";
      case "ELIGIBLE_NOW":
        return "Documents Submitted - Awaiting Assessment Approval";
      case "ASSESSMENT_SCHEDULED":
        return "Documents Submitted - Awaiting Assessment Approval";
      case "ASSESSMENT_SCHEDULED_NOW":
        return "Approved for Assessment";
      case "ASSESSMENT_COMPLETED":
        return "Assessment In-Progress";
      case "APPROVE_PLAN":
        return "Treatment Plan Awaiting Parent Signature";
    }
  };

  return (
    <CustomTable
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      noResultsMessage="No Customers Found"
      headerCells={[
        <CustomHeaderCell
          label="Name"
          sortable={true}
          sortDirection={sort === "u.first_name" ? direction : false}
          active={sort === "u.first_name"}
          onClick={() => handleSortClick("u.first_name")}
        />,
        <CustomHeaderCell
          label="Status"
          sortable={true}
          sortDirection={sort === "u.workflow_status" ? direction : false}
          active={sort === "u.workflow_status"}
          onClick={() => handleSortClick("u.workflow_status")}
        />,
        <CustomHeaderCell
          label="Insurance"
          sortable={true}
          sortDirection={sort === "i.name" ? direction : false}
          active={sort === "i.name"}
          onClick={() => handleSortClick("i.name")}
        />,
        <CustomHeaderCell
          label="State"
          sortable={true}
          sortDirection={sort === "state" ? direction : false}
          active={sort === "state"}
          onClick={() => handleSortClick("state")}
        />,
        <CustomHeaderCell label="Logged In" />,
        <CustomHeaderCell
          label="Clinician Name"
          sortable={true}
          sortDirection={sort === "clinician_name" ? direction : false}
          active={sort === "clinician_name"}
          onClick={() => handleSortClick("clinician_name")}
        />,
        <CustomHeaderCell
          label="Child Name"
          sortable={true}
          sortDirection={sort === "c.name" ? direction : false}
          active={sort === "c.name"}
          onClick={() => handleSortClick("c.name")}
        />,
        <CustomHeaderCell label="Auth Progress" />,
        <CustomHeaderCell label="Document Completion" />,
        <CustomHeaderCell
          label="Upcoming Call"
          sortable={true}
          sortDirection={sort === "vc.scheduled_date" ? direction : false}
          active={sort === "vc.scheduled_date"}
          onClick={() => handleSortClick("vc.scheduled_date")}
        />,
      ]}
    >
      {rows.map((row, index) => (
        <TableRow
          key={index}
          className={
            row.scheduled_date || currentInsuranceTab === 0
              ? classes.clickableRow
              : classes.noVideoScheduledRow
          }
          onClick={
            userPermissions?.view_client_list_by_workflow_state
              ? () => viewCustomerDetails(row.client_id)
              : null
          }
          hover
        >
          <TableCell align="left">
            {row.first_name} {row.last_name}
            <br />
            {row.email}
          </TableCell>
          <TableCell align="left">
            {row.workflow_status ? customerStatus(row.workflow_status) : ""}
          </TableCell>
          <TableCell align="left">{row.insurance ? row.insurance : "Other"}</TableCell>
          <TableCell align="left">{row.state}</TableCell>
          <TableCell align="left">{row.verified ? "Yes" : "No"}</TableCell>
          <TableCell align="left">{row.clinician_name}</TableCell>
          <TableCell align="left">{row.name}</TableCell>
          <TableCell align="left">
            {row.billableTime?.loading ? (
              <CircularProgress size={18} />
            ) : row.billableTime?.approvedUnits > 0 ? (
              `${row.billableTime.unitsUsed} of ${row.billableTime.approvedUnits} units used`
            ) : (
              "n/a"
            )}
          </TableCell>
          <TableCell align="left">
            {row.documentStatus?.loading ? (
              <CircularProgress size={18} />
            ) : row.documentStatus?.status ? (
              row.documentStatus.status
            ) : (
              "n/a"
            )}
          </TableCell>
          <TableCell align="left">
            {row.scheduled_date ? (
              format(new Date(row.scheduled_date), convertTokens("MMM D, YYYY h:mm a"), {
                awareOfUnicodeTokens: true,
              })
            ) : (
              <span style={{ fontWeight: 500 }}>No call scheduled</span>
            )}
          </TableCell>
        </TableRow>
      ))}
    </CustomTable>
  );
};

export default withRouter(withStyles(styles)(InsuranceCustomers));
