import React, { useEffect, useState } from "react";
import { Box, Select, MenuItem, Typography, Alert, AlertTitle } from "@mui/material";
import { WarningCircle } from "@phosphor-icons/react";
import ClinicianActivation from "./ClinicianActivation";
import SectionBox from "elements/SectionBox";
import { CLINICIAN_STATUS } from "constants";

const UpdateClinicianStatus = (props) => {
  const {
    workflowStatus,
    userPermissions,
    editMode,
    onChange,
    isActive,
    setSelectedStatus,
    selectedStatus,
    clinicianDeactivationReasons,
  } = props;

  const [accountStatus, setAccountStatus] = useState(null);
  // Function to get the display text for the current status
  const getStatusDisplayText = (statusKey) => CLINICIAN_STATUS[statusKey] || "";

  useEffect(() => {
    if (editMode) {
      props.setPageDetails({
        onEditButton: false,
      });
    }
  }, [editMode]);

  return (
    <SectionBox
      title="Account Status"
      description="Manage the user account configuration settings"
      columnDirection
    >
      <Box>
        <Typography margin={"8px 0"} variant="inputLabel">
          Account Status
        </Typography>
        {editMode && workflowStatus ? (
          <>
            <Select
              disabled={!userPermissions?.override_clinician_workflow_status || !isActive}
              style={{ width: "100%" }}
              value={accountStatus || workflowStatus}
              onChange={(e) => {
                onChange("accountStatus", e.target.value);
                setSelectedStatus(e.target.value);
                setAccountStatus(e.target.value);
              }}
            >
              <MenuItem disabled value="" />
              {Object.entries(CLINICIAN_STATUS).map(([value, text]) => (
                <MenuItem key={value} value={value}>
                  {text}
                </MenuItem>
              ))}
            </Select>
            {(userPermissions.deactivate_clinician && isActive) ||
            (userPermissions.reactivate_clinician && !isActive) ? (
              <Alert
                severity={isActive ? "error" : "warning"}
                action={<ClinicianActivation {...props} userPermissions={userPermissions} />}
                style={{ margin: "20px 0" }}
                icon={<WarningCircle />}
              >
                <AlertTitle>{`${isActive ? "Deactivate Clinician" : "Rectivate Clinician Account"} `}</AlertTitle>
                {isActive
                  ? "Deactivating the clinician's account will remove their ability to log in."
                  : "Reactivating the Clinician account will return the account status to the previous state before deactivation"}
              </Alert>
            ) : null}
          </>
        ) : (
          <Typography variant="body2">
            {isActive ? getStatusDisplayText(workflowStatus || selectedStatus) : "Deactivated"}
          </Typography>
        )}
      </Box>
    </SectionBox>
  );
};

export default UpdateClinicianStatus;
