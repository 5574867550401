import { useQuery } from "react-query";

import { GET_USER_BY_ID, STALE_TIME_5_MINUTES } from "constants/reactQueryKeys";
import { getUser } from "api/users";

export const useGetUserById = (userId) => {
  const { data, isLoading, refetch, isRefetching, ...rest } = useQuery(
    [GET_USER_BY_ID, userId],
    () => getUser(userId),
    {
      enabled: Boolean(userId),
      staleTime: STALE_TIME_5_MINUTES,
      retry: 3,
    }
  );

  return {
    user: data?.data || null,
    userLoading: isLoading,
    refetchUserData: refetch,
    isUserDataRefetching: isRefetching,
    ...rest,
  };
};
