import React from "react";
import { useFormik } from "formik";
import { FormControl, FormHelperText, Grid, Link, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import LoadingButton from "@mui/lab/LoadingButton";
import * as yup from "yup";

import { formatToPhoneNumber } from "utils/formatFormFields";
import ANGooglePlacesInput from "elements/ANGooglePlacesInput";

const validationSchema = yup.object().shape({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  phoneNumber: yup.string().required("Phone Number is required"),
  agreeToTerms: yup.boolean().oneOf([true], "You must agree to the terms"),
});

/**
 * The `NewTeamMemberForm` is a form component that allows users to input
 * information for a new team member such as first name, last name, email, phone number, and address.
 * @component
 * @param {Object} props The props of the component.
 * @param {string} props.email The email of the new team member.
 * @param {Function} props.onSubmit The function to call when the form is submitted.
 * @returns {JSX.Element} The `NewTeamMemberForm` component is being returned.
 */
function NewTeamMemberForm({ email, onSubmit }) {
  // Formik configuration
  const {
    errors,
    dirty,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
    isSubmitting,
    touched,
    values,
  } = useFormik({
    validationSchema,
    initialValues: {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      address: {},
      agreeToTerms: false,
    },
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const handlePhoneNumberChange = (event) => {
    const { value } = event.target;
    const formattedValue = formatToPhoneNumber(value);

    handleChange({
      target: {
        name: "phoneNumber",
        value: formattedValue,
      },
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={7}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="First Name"
            name="firstName"
            error={Boolean(touched.firstName && errors.firstName)}
            helperText={touched.firstName && errors.firstName}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.firstName}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Last Name"
            name="lastName"
            error={Boolean(touched.lastName && errors.lastName)}
            helperText={touched.lastName && errors.lastName}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.lastName}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField fullWidth label="Email" name="email" value={email} disabled />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Phone Number"
            name="phoneNumber"
            error={Boolean(touched.phoneNumber && errors.phoneNumber)}
            helperText={touched.phoneNumber && errors.phoneNumber}
            onBlur={handleBlur}
            onChange={handlePhoneNumberChange}
            value={values.phoneNumber}
          />
        </Grid>

        <Grid item xs={12}>
          <ANGooglePlacesInput
            fullWidth
            label="Address (optional)"
            name="address"
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <FormControl error={Boolean(touched.agreeToTerms && errors.agreeToTerms)}>
            <FormControlLabel
              sx={{ alignItems: "flex-start" }}
              control={
                <Checkbox
                  name="agreeToTerms"
                  checked={values.agreeToTerms}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              }
              label={
                <Typography variant="body2">
                  I agree to receive automated text messages from AnswersNow and consent to the
                  terms outlined in the{" "}
                  <Link
                    href="https://www.getanswersnow.com/privacy"
                    underline="none"
                    target="_blank"
                    rel="noreferrer"
                    color="info.main"
                  >
                    Privacy Policy
                  </Link>{" "}
                  and{" "}
                  <Link
                    href="https://www.getanswersnow.com/terms"
                    underline="none"
                    target="_blank"
                    rel="noreferrer"
                    color="info.main"
                  >
                    Terms of Service
                  </Link>
                  . Text messages may include OTP (one-time password) messages when explicitly
                  requested for account login, reminders of upcoming scheduled appointments, and
                  document upload requests (up to 10 messages per week). Message and data rates may
                  apply. Message frequency varies. Reply HELP for help or STOP to stop receiving all
                  automated text messages from Answersnow.
                </Typography>
              }
            />
            {touched.agreeToTerms && errors.agreeToTerms && (
              <FormHelperText>{errors.agreeToTerms}</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <LoadingButton
            fullWidth
            type="submit"
            loading={isSubmitting}
            disabled={!dirty || !isValid}
          >
            Continue
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
}

export default NewTeamMemberForm;
