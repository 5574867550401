import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import Lottie from "react-lottie";
import * as CardSuccessData from "../../../lottieAnimations/cardSuccess.json";

const CardActivity = (props) => {
  const defaultLottieOptions = {
    loop: false,
    autoplay: true,
    animationData: CardSuccessData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const {
    classes,
    cardIndex,
    cards,
    showCard,
    cardActivityStarted,
    markCardResponse,
    promptNextCard,
    currentCard,
    clinicianInControl,
    isCaregiver,
  } = props;

  const renderCardActivity = () => {
    if (isCaregiver) {
      return renderClientActivity();
    } else {
      return renderClinicianActivity();
    }
  };

  const renderClinicianActivity = () => {
    return (
      <>
        {cardActivityStarted ? (
          <>
            <Typography component="h1" className={classes.activityLabel}>
              {currentCard.type}
            </Typography>
            {currentCard.url && <img src={currentCard.url} className={classes.activityImage} />}
            {currentCard.prompt && (
              <Typography component="h1" className={classes.activityPrompt}>
                "{currentCard.prompt}"
              </Typography>
            )}
            {currentCard.answer && (
              <Typography component="h1" className={classes.activityAnswer}>
                * {currentCard.answer} *
              </Typography>
            )}
          </>
        ) : (
          clinicianInControl && (
            <>
              <div style={{ height: 55 }} />
              <Button onClick={showCard}>Begin Activity</Button>
            </>
          )
        )}
        {clinicianInControl && (
          <div className={classes.activityControlsContainer}>
            <Button
              startIcon={<ThumbDownIcon />}
              variant="contained"
              className={classes.activityButtonIncorrect}
              aria-label="Incorrect"
              onClick={() => markCardResponse(false)}
              disabled={promptNextCard || !cardActivityStarted}
            >
              <Typography component="p">Incorrect</Typography>
            </Button>
            <Button
              startIcon={<ThumbUpIcon />}
              variant="contained"
              className={classes.activityButtonCorrect}
              aria-label="Correct"
              onClick={() => markCardResponse(true)}
              disabled={promptNextCard || !cardActivityStarted}
            >
              <Typography component="p">Correct</Typography>
            </Button>

            {promptNextCard && (
              <Button
                className={classes.activityButtonNext}
                disabled={!cards[cardIndex]}
                aria-label="Correct"
                onClick={showCard}
                endIcon={<SkipNextIcon color="inherit" />}
              >
                <Typography
                  component="p"
                  style={{
                    fontWeight: 500,
                    textTransform: "none",
                    justifySelf: "flex-end",
                  }}
                >
                  Next
                </Typography>
              </Button>
            )}
          </div>
        )}
      </>
    );
  };

  const renderClientActivity = () => {
    const { card, classes, cardResponse, shakeAnimation } = props;
    return card ? (
      <div className={shakeAnimation ? classes.shakeAnimation : classes.cardActivityContainer}>
        {card.url && <img src={card.url} className={classes.cardActivityImage} />}
        {card.client_prompt && (
          <Typography component="p" className={classes.cardActivityPrompt}>
            {card.client_prompt}
          </Typography>
        )}
        {cardResponse && (
          <div className={classes.successAnimationContainer}>
            <Lottie options={defaultLottieOptions} />
          </div>
        )}
      </div>
    ) : null;
  };

  return renderCardActivity();
};

export default CardActivity;
