import { Box, Switch, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useGetClientById } from "hooks/api/useGetClientById";
import { useUpdateClient } from "hooks/api/useUpdateClient";
import React from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useGlobalToast } from "components/GlobalToastProvider";
import { useQueryClient } from "react-query";

const StyledBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
`;

const StyledContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export default function RequestNewClinician() {
  const { id: clientId } = useParams();
  const { data } = useGetClientById(clientId);
  const [checked, setChecked] = React.useState(false);
  const { showToast } = useGlobalToast();
  const queryClient = useQueryClient();

  const { updateClientMutation } = useUpdateClient({
    onSuccess: () => {
      showToast({ message: "Updated Successfully!" });
      queryClient.invalidateQueries({
        predicate: (query) => {
          const queryKey = query.queryKey;
          
          // Safely check if 'client' is part of any non-null or non-undefined key
          // Ensure queryKey is an array before calling some()
          return Array.isArray(queryKey) && queryKey.some(
            (key) => key != null && key.toString().toLowerCase().includes("client")
          );
        },
      });
    },
  });

  useEffect(() => {
    setChecked(data?.data?.requests_new_clinician);
  }, [data]);

  const handleSwitchChange = () => {
    updateClientMutation({
      clientId,
      data: { requests_new_clinician: !checked },
    });
    setChecked(!checked);
  };

  return (
    <StyledBox>
      <StyledContainer>
        <Typography variant="body1">Request new clinician</Typography>
        <Typography variant="body2" color="text.secondary">
          Turning this on will allow the matching engine to recommend new clinician matches
        </Typography>
      </StyledContainer>

      <Switch checked={checked} onChange={handleSwitchChange} />
    </StyledBox>
  );
}
