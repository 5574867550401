import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import withStyles from "@mui/styles/withStyles";
import actions from "../../actions";
import Signin from "./Signin";
import ContactForm from "./ContactForm";
import styles from "./styles";
import cookie from "react-cookies";
import { Alert } from "@mui/material";
import { OtpErrorTypes } from "./Flows/types";
import {
  getSignedinState,
  getSignInFailure,
  getPasswordChange,
  getNeedsReset,
  getNotConfirm,
  getRedirect,
  getGroups,
  getSignInFailureMessage,
  getCustomerSavingForLater,
  getCustomerSavingForLaterSuccess,
  getCustomerSavingForLaterError,
  getCreateCustomerError,
} from "../../selectors";
import Link from "@mui/material/Link";
import ContactFormConfirmation from "./ContactFormConfirmation";
import ANLoadingScreen from "elements/ANLoadingScreen";

const urlParams = new URLSearchParams(window.location.search);

const mapStateToProps = (state) => ({
  signedInState: getSignedinState(state),
  passwordChange: getPasswordChange(state),
  signinFailure: getSignInFailure(state),
  signinFailureMessage: getSignInFailureMessage(state),
  needsReset: getNeedsReset(state),
  notConfirm: getNotConfirm(state),
  redirectUrl: getRedirect(state),
  groups: getGroups(state),
  savingForLater: getCustomerSavingForLater(state),
  savingForLaterSuccess: getCustomerSavingForLaterSuccess(state),
  savingForLaterError: getCustomerSavingForLaterError(state),
  createUserError: getCreateCustomerError(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSigninDetails: actions.setSigninDetails,
      startSignin: actions.startSignin,
      checkAuthState: actions.checkAuthState,
      signout: actions.signOut,
      setRedirect: actions.setRedirect,
      setPageDetails: actions.setPageDetails,
      saveCustomerForLater: actions.saveCustomerForLater,
      clearSaveForLaterInfo: actions.clearSaveForLaterInfo,
    },
    dispatch
  );

function getInitialState() {
  return {
    username: "",
    password: "",
    otp: "",
    otpError: null,
    redirected: !!urlParams?.get("email"),
    loginMethod: null,
    receivedParamsFromLink: !!urlParams?.get("email") && !!urlParams?.get("otp"),
    showPassword: false,
    errorMessage: null,
    currentTry: 0,
    guardianFirstName: "",
    guardianLastName: "",
    relationshipToClient: "",
    email: "",
    phoneNumber: "",
    clientFirstName: "",
    clientLastName: "",
    clientAge: "",
    clientState: "",
    clientZip: "",
    clientGender: "",
    clientIsVerbal: null,
    insuranceInfo: "",
    isMedicaid: null,
    additionalInfo: "",
    clientHasASDDiagnosis: null,
    contactMethod: "",
    contactTime: "",
    hasComputer: null,
    hasInternet: null,
    emailError: false,
    phoneError: false,
    zipError: false,
    contactFormOpen: false,
    contactFormConfirmationOpen: false,
    wasReferred: null,
    referralSource: "",
    isReadyToRedirect: false,
  };
}

class SigninContainer extends Component {
  state = getInitialState();

  componentDidMount() {
    this.props.setPageDetails({
      pageName: "Answersnow - Signin",
    });
    let redirect = "";
    if (this.props.location.search) {
      const params = new URLSearchParams(this.props.location.search);
      redirect = params.get("redirect");
      if (redirect !== "/not-found") {
        this.props.setRedirect(redirect);
      }
    }
    this.props.checkAuthState();
    if (this.props.signedInState === true && this.props.groups && !redirect) {
      this.goToDashboard();
    }
    this.checkForUsernameInStorage();
  }

  componentDidUpdate(prevProps) {
    const userId = cookie.load("userId");
    if (!prevProps.passwordChange && this.props.passwordChange) {
      this.props.history.push("/force-password-change");
    }
    if (this.state.isReadyToRedirect && userId && this.props.signedInState) {
      const clientId = cookie.load(`${userId}-clientId`);
      if (this.props.groups.includes("Caregivers") && !clientId) {
        this.props.history.push("/account-selection");
      } else this.goToDashboard();
    }
    if (
      (!prevProps.signedInState || prevProps.groups.length === 0) &&
      this.props.signedInState &&
      this.props.groups.length > 0
    ) {
      this.setState({ isReadyToRedirect: true });
    }
    if (!prevProps.signinFailure && this.props.signinFailure) {
      this.setState((prevState) => ({
        currentTry: prevState.currentTry + 1,
      }));
    }
    if (!prevProps.savingForLaterSuccess && this.props.savingForLaterSuccess) {
      this.toggleContactForm();
      this.toggleContactFormConfirmation();
    }
  }

  componentWillUnmount() {
    this.props.clearSaveForLaterInfo();
  }

  checkForUsernameInStorage = () => {
    const username = cookie.load("username");
    const fromStaticVideoPage = cookie.load("fromStaticVideoPage");
    if (fromStaticVideoPage) {
      cookie.remove("username", { path: "/" });
      cookie.remove("fromStaticVideoPage", { path: "/" });
    } else if (username && username != "undefined") {
      this.setState({ username });
    }
  };

  goToDashboard = () => {
    const { redirectUrl, history, groups, setRedirect } = this.props;
    if (redirectUrl) {
      history.push(redirectUrl);
    } else {
      history.push("/dashboard");
    }
    setRedirect("");
  };

  goToForgot = (e) => {
    const { history } = this.props;
    e.preventDefault();
    history.push("/forgot-password");
  };

  onChange = (name) => (e) => {
    const { value } = e.target;
    this.setState({ [name]: value });
  };

  onSubmit = (e) => {
    e.preventDefault();
    let username = this.state.username?.trim();
    let email = this.state.email?.trim();
    this.props.setSigninDetails({
      userAttributes: { username },
    });
    this.props.startSignin({
      ...this.state,
      username,
      email,
    });
  };

  toggleShowPasswords = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  navigateToGetAConsultation = () => {
    window.open("https://www.getanswersnow.com/lp/autism-support");
  };

  renderErrorMessage = (filter = []) => {
    const errorFilter = Array.isArray(filter) ? filter : [filter];
    const errorsTypes = [
      {
        type: "signIn",
        error: this.getSignInError(),
      },
      {
        type: "otp",
        error: this.getOtpError(),
      },
    ];
    const errors = errorsTypes.filter(
      ({ type }) => errorFilter.length === 0 || filter.includes(type)
    );
    return errors.map(({ error }, i) => (
      <div key={i} style={{ marginBottom: 3 }}>
        {error}
      </div>
    ));
  };

  getOtpError = () => {
    const { otpError } = this.state;
    if (!!otpError) {
      switch (otpError) {
        case OtpErrorTypes.FORMAT:
          return <Alert severity="error">Please enter a valid email or phone number</Alert>;
        case OtpErrorTypes.INVALID:
          return <Alert severity="error">Please enter a valid code</Alert>;
        default:
          return;
      }
    }
  };

  getSignInError = () => {
    if (this.props.signinFailure) {
      const { currentTry } = this.state;
      const { signinFailureMessage, classes } = this.props;

      if (currentTry >= 3) {
        return (
          <Alert severity="error">
            Looks like you're having problems logging in. Do you want to{" "}
            <Link onClick={this.goToForgot} className={classes.resetPasswordLink}>
              reset your password?
            </Link>
          </Alert>
        );
      } else {
        switch (signinFailureMessage) {
          case "Incorrect username or password.":
          case "User does not exist.":
            return <Alert severity="error">Please enter a valid email address and password</Alert>;
          case "Temporary password has expired and must be reset by an administrator.":
            return (
              <Alert severity="error">
                Your temporary password has expired and must be reset by a customer care
                representative.
              </Alert>
            );
          case "Password attempts exceeded":
          default:
            return (
              <Alert severity="error">
                Looks like you're having problems logging in. Do you want to{" "}
                <Link onClick={this.goToForgot} className={classes.resetPasswordLink}>
                  reset your password?
                </Link>
              </Alert>
            );
        }
      }
    }
  };

  onChange = (name) => (e) => {
    const { value } = e.target;
    this.setState({ [name]: value });
  };

  superOnChange = (name) => (value) => {
    this.setState({ [name]: value });
  };

  onRadioButtonChange = (name) => (e) => {
    const { value } = e.target;
    this.setState({ [name]: value === "false" ? false : true });
  };

  onNumberChange = (name) => (e) => {
    const { value } = e.target;
    this.setState({ [name]: value.replace(/[^0-9]/g, "") });
  };

  onPhoneNumberChange = (name) => (e) => {
    const { value } = e.target;
    let numberValue = value.replace(/[^0-9]/g, "");
    this.setState({
      [name]: numberValue.replace(
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/g,
        "($1)-$2-$3"
      ),
    });
  };

  setError = (name, error) => {
    this.setState({ [name]: error });
  };

  handleEmailChange = (name) => (e) => {
    const { value } = e.target;
    this.setState({ email: value.replace(" ", "") });
  };

  validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  onSaveCustomerForLater = () => {
    const { email, phoneNumber } = this.state;
    let phone = phoneNumber?.replace(/\D/g, "").trim();
    let fromContactForm = true;

    let createParams = {
      ...this.state,
      email: email.toLowerCase(),
      phone,
      fromContactForm,
    };
    this.props.saveCustomerForLater(createParams);
  };

  toggleContactForm = () => {
    this.setState({ contactFormOpen: !this.state.contactFormOpen });
  };
  toggleContactFormConfirmation = () => {
    this.setState({
      contactFormConfirmationOpen: !this.state.contactFormConfirmationOpen,
    });
  };

  render() {
    const { contactFormOpen, contactFormConfirmationOpen } = this.state;

    if (contactFormOpen) {
      return (
        <ContactForm
          {...this.props}
          {...this.state}
          onChange={this.onChange}
          handleEmailChange={this.handleEmailChange}
          validateEmail={this.validateEmail}
          onSaveCustomerForLater={this.onSaveCustomerForLater}
          setError={this.setError}
          onNumberChange={this.onNumberChange}
          onPhoneNumberChange={this.onPhoneNumberChange}
          onRadioButtonChange={this.onRadioButtonChange}
          toggleContactForm={this.toggleContactForm}
        />
      );
    }
    if (contactFormConfirmationOpen) return <ContactFormConfirmation {...this.props} />;

    // This is to avoid showing the signin form with the header and sidebar, while componentDidUpdate
    // is waiting for the userId to redirect to the dashboard
    if (this.props.signedInState) return <ANLoadingScreen />;

    return (
      <Signin
        {...this.props}
        {...this.state}
        onChange={this.onChange}
        superOnChange={this.superOnChange}
        onSubmit={this.onSubmit}
        handleMouseDownPassword={this.handleMouseDownPassword}
        toggleShowPasswords={this.toggleShowPasswords}
        renderErrorMessage={this.renderErrorMessage}
        toggleContactForm={this.toggleContactForm}
        navigateToGetAConsultation={this.navigateToGetAConsultation}
        goToForgot={this.goToForgot}
        urlParams={urlParams}
      />
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SigninContainer))
);
