import React from "react";
import { Stack, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

import ANLogoSrc from "assets/answers_now_logo_dark_purple.png";

/**
 * The SplashScreen function renders a component with the ANlogo, alert message, title, and description in
 * a centered layout.
 * @component
 * @param {Object} props The props of the SplashScreen component
 * @param {string} [props.alertSeverity="info"] The severity of the alert message (error, warning, info, success).
 * @param {React.ReactNode} props.description The description of the alert message.
 * @param {string} props.title The title of the alert message.
 * @returns {JSX.Element} The SplashScreen component
 * @example
 * <SplashScreen alertSeverity="error" description="An error occurred" title="Error" />
 */
export default function SplashScreen({ alertSeverity = "info", description, title }) {
  return (
    <Stack height="100vh" alignItems="center" justifyContent="center">
      <Stack maxWidth="330px" alignItems="center" gap={11}>
        <img src={ANLogoSrc} alt="AnswersNow" title="AnswersNow" height={48} />

        <Stack alignItems="center" gap={4}>
          <Alert severity={alertSeverity}>
            <AlertTitle>{title}</AlertTitle>
            <Typography component="p" variant="body2">
              {description}
            </Typography>
          </Alert>
        </Stack>
      </Stack>
    </Stack>
  );
}
