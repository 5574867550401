import React, { useState } from "react";
import { Files, PencilSimpleLine, DotsThree } from "@phosphor-icons/react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import RemoveProviderMenuItem from "./RemoveProviderMenuItem";
const ProvidersDetailsMenu = ({
  provider,
  onEditProviderType,
  onCreateROIForm,
  onProviderChanges,
  userPermissions,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleEditProviderType = () => {
    handleClose();
    onEditProviderType();
  };
  const handleCreateROIForm = () => {
    handleClose();
    onCreateROIForm();
  };

  return (
    <span>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size="small"
        style={{ transform: "translate(-1px, 10px)" }}
      >
        <DotsThree size={32} />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleEditProviderType}>
          <PencilSimpleLine size={20} style={{ marginRight: "1rem" }} weight={"duotone"} /> Edit
          Provider
        </MenuItem>
        <MenuItem
          onClick={handleCreateROIForm}
          disabled={!userPermissions.create_phi_release_request}
        >
          <Files size={20} style={{ marginRight: "1rem" }} weight={"duotone"} /> Create Release of
          Information Form
        </MenuItem>
        <RemoveProviderMenuItem provider={provider} onProviderChanges={onProviderChanges} />
      </Menu>
    </span>
  );
};

export default ProvidersDetailsMenu;
