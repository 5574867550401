import React from "react";
import EditNoteDetails from "./EditNoteDetails";
import CptHeader from "./CptHeader";
import ViewNoteDetails from "./ViewNoteDetails";

const NoteDetails = (props) => {
  const { classes, viewOnly } = props;

  return (
    <div className={classes.noteDetailsContainer}>
      <CptHeader {...props} />
      {viewOnly ? <ViewNoteDetails {...props} /> : <EditNoteDetails {...props} />}
    </div>
  );
};

export default NoteDetails;
