import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router";
import { BrowserRouter } from "react-router-dom";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import CssBaseline from "@mui/material/CssBaseline";
import Signin from "../Signin";
import NotFound from "../NotFound";
import SideNav from "../SideNav";
import ChangePassword from "../ChangePassword";
import ResetPassword from "../ResetPassword";
import ForcePassword from "../ForcePassword";
import ForgotPassword from "../ForgotPassword";
import VideoChat from "../VideoChat";
import Chat from "../Chat";
import Dashboard from "../Dashboard";
import VideoCall from "../VideoCalls/VideoCall";
import ClinicianDetail from "../ClinicianDetail";
import ClinicianSessions from "../ClinicianSessions";
import ClinicianClients from "../ClinicianClients";
import CustomerVideoCalls from "../ClientDetails/CustomerVideoCalls";
import Customers from "../Customers";
import Clinicians from "../Clinicians";
import Users from "../Users";
import User from "../Users/User";
import VideoCallSummary from "../VideoCallSummary";
import Chats from "../Chats";
import ChatReview from "../Chats/Chat";
import ClientOnboarding from "../ClientOnboarding";
import SavedForLaterCustomers from "../SavedForLaterCustomers";
import VideoSessionNote from "../VideoCalls/VideoSessionNote";
import ErrorBoundary from "../ErrorBoundary";
import ClientDetails from "../ClientDetails";
import WebrtcTest from "../WebrtcTest";
import CallInstructions from "../CallInstructions";
import CaregiverSessions from "../CaregiverSessions";
import CaregiverPastSessions from "../CaregiverSessions/PastSessions";
import VideoChatStaticPage from "../VideoChatStaticPage";
import InsurancePlan from "../InsuranceUtility/InsurancePlan";
import InsuranceProvider from "../InsuranceUtility/InsuranceProvider";
import BillingItems from "../BillingItems";
import Header from "../Header";
import EventScheduling from "components/EventScheduling";
import IntercomAiController from "components/IntercomAi/IntercomAiController";
import { GlobalToastProvider, useGlobalToast } from "components/GlobalToastProvider";
import { GlobalPageLoadingProvider } from "components/GlobalPageLoadingProvider";
import VideoPlayer from "components/VideoPlayer";
import AccountSelectionSplashScreen from "components/AccountSelectionSplashScreen";
import AcceptInvitation from "components/AcceptInvitation";
import DocumentViewer from "../DocumentViewer";
import AnLibraries from "../AnLibraries";

const classes = {
  layoutContainer: {
    display: "flex",
    maxWidth: "100%",
    height: "100%",
    // flex: 1,
  },
  layoutContentContainer: {
    flexGrow: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
    flexDirection: "column",
  },
  layoutContent: {
    width: "100%",
    height: "100%",
    overflow: "auto",
  },
};

const VideoChatWithToast = (props) => {
  const { showToast } = useGlobalToast();
  return <VideoChat {...props} showToast={showToast} />;
};

class Layout extends Component {
  render() {
    const { idleTimer } = this.props;
    return (
      <BrowserRouter>
        <CssBaseline />
        <IntercomAiController />
        <GlobalToastProvider>
          <Box sx={classes.layoutContainer}>
            <SideNav />
            <Box sx={classes.layoutContentContainer}>
              <Header />
              <Box sx={classes.layoutContent}>
                <ErrorBoundary>
                  <GlobalPageLoadingProvider>
                    <Switch>
                      <Route path="/" exact component={Signin} />
                      <Route
                        path="/account-selection"
                        exact
                        component={AccountSelectionSplashScreen}
                      />
                      <Route path="/dashboard" exact component={Dashboard} />
                      <Route path="/account" exact>
                        <Redirect to="/account/profile" />
                      </Route>
                      <Route path="/account/:section" exact component={ClientDetails} />
                      <Route path="/sessions" exact component={CaregiverSessions} />
                      <Route path="/all-sessions" exact component={CaregiverPastSessions} />
                      <Route path="/change-password" exact component={ChangePassword} />
                      <Route path="/reset-password" exact component={ResetPassword} />
                      <Route path="/force-password-change" exact component={ForcePassword} />
                      <Route path="/forgot-password" exact component={ForgotPassword} />
                      <Route path="/setup/:guid" exact component={ForcePassword} />
                      <Route path="/accept-invitation/:guid" exact component={AcceptInvitation} />
                      <Route path="/video" exact component={VideoChat} />
                      <Route
                        path="/stream/:videoKey"
                        exact
                        render={(props) => {
                          return (
                            <VideoPlayer
                              videoKey={props.match.params.videoKey}
                              idleTimer={idleTimer}
                              {...props}
                            />
                          );
                        }}
                      />
                      <Route
                        path="/video/:videoKey"
                        exact
                        render={(props) => <VideoChatWithToast {...props} idleTimer={idleTimer} />}
                      />
                      <Route path="/support" exact component={Chat} />
                      <Route path="/chat" exact component={Chat} />
                      <Route path="/chat/:url" exact component={Chat} />
                      <Route path="/clinician-schedule" exact component={ClinicianSessions} />
                      <Route
                        path="/clinician-schedule/create-event"
                        exact
                        component={EventScheduling}
                      />
                      <Route path="/my-clients" exact component={ClinicianClients} />
                      <Route
                        path="/video-calls/user/:clientId"
                        exact
                        component={CustomerVideoCalls}
                      />
                      <Route path="/video-calls/:id" exact component={VideoCall} />
                      <Route path="/session-notes/:id" exact component={VideoSessionNote} />

                      <Route
                        path="/my-info/:section?"
                        render={(props) => <ClinicianDetail key={"clinician_profile"} {...props} />}
                      />
                      <Route path="/clients" exact component={Customers} />
                      <Route path="/clients/:id/:section" component={ClientDetails} />
                      <Route path="/clinicians" exact component={Clinicians} />
                      <Route
                        path="/clinicians/:id/:section?"
                        exact
                        render={(props) => (
                          <ClinicianDetail
                            key={props?.match?.params?.id || "clinician_detail"}
                            {...props}
                          />
                        )}
                      />
                      <Route path="/users" exact component={Users} />
                      <Route path="/users/:id" exact component={User} />
                      <Route path="/video-call-summary" exact component={VideoCallSummary} />
                      <Route path="/chats" exact component={Chats} />
                      <Route path="/chats/:id" exact component={ChatReview} />
                      {/* <Route path="/client-onboarding" exact component={ClientOnboarding} /> */}
                      <Route path="/client-onboarding/:id" exact component={ClientOnboarding} />
                      <Route path="/saved-customers" exact component={SavedForLaterCustomers} />
                      <Route path="/test" exact component={WebrtcTest} />
                      <Route path="/call-instructions/:id" exact component={CallInstructions} />
                      <Route path="/session-details/:id" exact component={CallInstructions} />
                      <Route
                        path="/:clientid/static-video-page"
                        exact
                        component={VideoChatStaticPage}
                      />
                      <Route path="/libraries" exact component={AnLibraries} />
                      <Route path="/libraries/insurance/:id" exact component={InsuranceProvider} />
                      <Route path="/libraries/insurance/plan/:id" exact component={InsurancePlan} />
                      <Route path="/billing" exact component={BillingItems} />
                      <Route
                        path="/document-viewer/:userId/:documentId"
                        component={DocumentViewer}
                      />
                      <Route component={NotFound} />
                    </Switch>
                  </GlobalPageLoadingProvider>
                </ErrorBoundary>
              </Box>
            </Box>
          </Box>
        </GlobalToastProvider>
      </BrowserRouter>
    );
  }
}

Layout.propTypes = {
  classes: PropTypes.object,
  isMobile: PropTypes.bool,
  signedInState: PropTypes.bool,
};

export default Layout;
