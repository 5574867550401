import React from "react";
import VideoSessionDetails from "./VideoSessionDetails";
import ServiceDetails from "../ServiceDetails/index";
import Participants from "./Participants";
import ViewAdhocSessionDetails from "./ViewAdhocSessionDetails";
import EditAdhocSessionDetails from "./EditAdhocSessionDetails";

const SessionDetails = (props) => {
  const { classes, callDetailsRef, videoId, viewOnly } = props;

  return (
    <div className={classes.sessionNoteDetails} ref={callDetailsRef}>
      {videoId ? (
        <div className={viewOnly ? classes.viewCallDetails : classes.callDetails}>
          <VideoSessionDetails {...props} />
          <Participants {...props} />
          <ServiceDetails {...props} />
        </div>
      ) : (
        <div className={classes.callDetails}>
          {viewOnly ? (
            <ViewAdhocSessionDetails {...props} />
          ) : (
            <EditAdhocSessionDetails {...props} />
          )}
        </div>
      )}
    </div>
  );
};

export default SessionDetails;
