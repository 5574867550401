export default (theme) => ({
  titleContainer: {
    gap: 20,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "10px",
  },
  pageTitle: {
    marginRight: 200,
  },
  table: {
    minWidth: 700,
  },
  tableHead: {
    backgroundColor: "#efefef",
  },
  tableColumnTitle: {
    color: "#8c8c8c",
    fontWeight: 500,
  },
  loading: {
    width: "100%",
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontSize: 22,
    fontWeight: 500,
    color: "#595959",
    minHeight: 33,
    marginBottom: 25,
    letterSpacing: 1,
  },
  weeklyScheduleContainer: {
    width: "100%",
    height: "fit-content",
    borderRadius: 6,
    marginBottom: 30,
    padding: "20px 30px",
    boxShadow: "0 0 5px 0 rgba(0,0,0,.4)",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    "& p": {
      letterSpacing: ".8px",
    },
  },
});
