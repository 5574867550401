import { combineReducers } from "redux";
import auth from "./auth";
import appointmentChangeLog from "./appointmentChangeLog";
import clinicians from "./clinicians";
import clinician from "./clinician";
import customers from "./customers";
import insurance from "./insurance";
import notes from "./notes";
import parent from "./parent";
import reports from "./reports";
import signin from "./signin";
import system from "./system";
import tests from "./tests";
import user from "./user";
import users from "./users";
import video from "./video";
import chat from "./chat";
import chats from "./chats";
import sendbird from "./sendbird";
import communication from "./communication";
import billing from "./billing";

export default combineReducers({
  auth,
  appointmentChangeLog,
  clinician,
  clinicians,
  customers,
  insurance,
  notes,
  parent,
  reports,
  signin,
  system,
  tests,
  user,
  users,
  video,
  chat,
  chats,
  sendbird,
  communication,
  billing,
});
