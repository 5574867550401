import React from "react";
import {
  Autocomplete,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  ListItemIcon,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import ANDatePicker from "elements/ANDatePicker";
import { MagnifyingGlass, UsersThree } from "@phosphor-icons/react";
import momentTZ from "moment-timezone";
import { LoadingButton } from "@mui/lab";
import { addMinutesToTime, timeWithTimezoneToString } from "utils/dateTimeUtils";
import ClinicianSelect from "./ClinicianSelect";
import useStyles from "./styles";
import { StatusChip } from "elements/PeopleFilter/StatusChip";
import { useFormikContext } from "formik";
import { useTheme } from "@mui/styles";

/**
 * VideoCallSelections component.
 * Renders a form for scheduling video calls, including selecting event type, client, service type, and clinicians.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.isEditing - Whether the form is in editing mode.
 * @param {boolean} props.isOtherServicesEvent - Whether the event is for other services.
 * @param {Array} props.eventOptions - List of event options.
 * @param {Object} props.eventOptionsObject - Event options object with detailed options.
 * @param {Array} props.clientList - List of clients.
 * @param {Object} props.serviceTypeLookup - Lookup object for service types.
 * @param {Array} props.serviceTypeOptions - List of service type options.
 * @param {boolean} props.canSelectBCBA - Whether BCBA selection is allowed.
 * @param {Array} props.clinicians - List of clinicians.
 * @param {Array} props.selectedClinicians - List of selected clinicians.
 * @param {Function} props.setSelectedClinicians - Function to set the selected clinicians.
 * @param {Function} props.setClinicianOptions - Function to set the clinician options.
 * @param {Array} props.clinicianOptions - List of clinician options to display in the select menu.
 * @param {Array} props.timeOptions - List of time options.
 * @param {boolean} props.isToday - Whether the selected date is today.
 * @param {Array} props.durationOptions - List of duration options.
 * @param {Function} props.renderUserTimezone - Function to render the user's timezone.
 * @param {boolean} props.scheduleCallLoading - Whether the schedule call is loading.
 * @param {boolean} props.onSubmitLoading - Whether the form submission is loading.
 * @param {Object} props.history - History object for navigation.
 * @param {Function} props.manuallyValidateForm - Function to manually validate the form.
 * @param {Function} props.setUpdateEventDialogOpen - Function to set the update event dialog open state.
 * @param {Function} props.handleSubmit - Function to handle form submission.
 * @param {Function} props.setCancelSessionOpen - Function to set the cancel session dialog open state.
 * @param {boolean} props.hasInsuranceAuthorization - Whether the client has insurance authorization.
 * @param {Function} props.handleSelectDay - Function to handle selecting a day for repeating events.
 * @param {Function} props.getSelectedDayColor - Function to get the color of the selected day.
 * @param {Object} props.videoChatInfo - Information about the video chat.
 * @returns {React.ReactElement} - The rendered VideoCallSelections component.
 */
const VideoCallSelections = ({
  isEditing,
  isOtherServicesEvent,
  eventOptions,
  eventOptionsObject,
  clientList,
  serviceTypeLookup,
  serviceTypeOptions,
  canSelectBCBA,
  clinicians,
  selectedClinicians,
  setSelectedClinicians,
  setClinicianOptions,
  clinicianOptions,
  timeOptions,
  isToday,
  durationOptions,
  renderUserTimezone,
  scheduleCallLoading,
  onSubmitLoading,
  history,
  manuallyValidateForm,
  setUpdateEventDialogOpen,
  handleSubmit,
  setCancelSessionOpen,
  hasInsuranceAuthorization,
  handleSelectDay,
  getSelectedDayColor,
  videoChatInfo,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [filteredProviders, setFilteredProviders] = React.useState([]);
  const [personScheduleColorMap, setPersonScheduleColorMap] = React.useState({});
  const [providerScheduleColorMap, setProviderScheduleColorMap] = React.useState({});
  const [colorIndex, setColorIndex] = React.useState(0);
  const [providerColorIndex, setProviderColorIndex] = React.useState(1);
  const [controlEditFlag, setControlEditFlag] = React.useState(false);
  const { touched, errors, handleBlur, handleChange, setFieldValue, values } = useFormikContext();

  React.useEffect(() => {
    if (isEditing && clientList.length > 0 && !controlEditFlag) {
      handleAddChip(_, { clientId: videoChatInfo.client_id, userId: videoChatInfo.client_user_id });
      setControlEditFlag(true);
    }
  }, [videoChatInfo, clientList]);

  const handleInputChange = (_, newInputValue, reason) => {
    // allow "reset" if there in newInputValue (this allows to set the client name when selecting a team member)
    if (!(reason === "reset" && !newInputValue))
      setFieldValue("clientInputValue", newInputValue, true);
  };

  const onDeletePerson = (id) => {
    setFieldValue("selectedTeamMember", null);
    setFieldValue("selectedClient", null);

    // eslint-disable-next-line no-unused-vars
    const { [id]: _, ...newColorMap } = personScheduleColorMap;
    setPersonScheduleColorMap(newColorMap);
  };

  const onDeleteProvider = (id) => {
    setSelectedClinicians(selectedClinicians.filter((person) => person.clinician_id !== id));

    setFilteredProviders(filteredProviders.filter((person) => person.clinician_id !== id));

    // eslint-disable-next-line no-unused-vars
    const { [id]: _, ...newColorMap } = providerScheduleColorMap;
    setProviderScheduleColorMap(newColorMap);
  };

  const onAddPerson = (person) => {
    setFieldValue("selectedTeamMember", person);

    setPersonScheduleColorMap({
      ...personScheduleColorMap,
      [person.id]: colorIndex,
    });

    setColorIndex((prevColorIndex) => (prevColorIndex + 1) % 9);
  };

  const onAddProvider = (person) => {
    setFilteredProviders([...filteredProviders, person]);

    setProviderScheduleColorMap({
      ...providerScheduleColorMap,
      [person.clinician_id]: (providerColorIndex + colorIndex) % 9,
    });

    // Make sure the provider color index is not the same as the client color index
    setProviderColorIndex((prevColorIndex) => (prevColorIndex + colorIndex) % 9);
  };

  function findTeamMember(clientList, selectedUser) {
    // clientId is set only when editing
    if (selectedUser.clientId) {
      const client = clientList.find((client) => client.clientId === selectedUser.clientId);
      const member = client?.teamMembers?.find((member) => member.userId === selectedUser.userId);
      if (member) return { member, client };
    }
    for (const client of clientList) {
      const member = client.teamMembers.find(
        (member) =>
          (member.userId === selectedUser.userId) & (member.teamId === selectedUser.teamId)
      );
      if (member) return { member, client };
    }

    // If no match is found, return null
    return { member: null, client: null };
  }

  const handleAddChip = (_, selectedUser) => {
    const { member, client } = findTeamMember(clientList, selectedUser);

    if (member && !values.selectedTeamMember) {
      onAddPerson(member);
      setFieldValue("selectedClient", client);
    }
  };

  const handleProviderAddChip = (_, selectedUserId) => {
    const person = clinicians.find((result) => result.clinician_user_id === selectedUserId);

    if (
      person &&
      !filteredProviders.some((chip) => chip.clinician_id === person.clinician_user_id)
    ) {
      onAddProvider(person);
    }
  };

  return (
    <Stack
      sx={{
        padding: theme.spacing(11),
        paddingTop: "23px",
        borderRight: `1px solid ${theme.palette.divider}`,
        maxWidth: "459px",
        minWidth: values.timeUnit === "weeks" ? "459px" : "345px",
        overflowY: "auto",
      }}
      gap={theme.spacing(7)}
    >
      <Stack>
        <Typography variant="body2">Schedule</Typography>
        <Typography variant="h3">{!isEditing && "Create"} Event</Typography>
      </Stack>

      <Stack gap={theme.spacing(5)}>
        <TextField
          select
          label="Event Type"
          name="event"
          value={values.event}
          onChange={handleChange}
          helperText={touched.event && errors.event}
          error={touched.event && Boolean(errors.event)}
          onBlur={handleBlur}
          disabled={isEditing && isOtherServicesEvent}
        >
          {eventOptions.map(({ value, label }) => (
            <MenuItem
              key={value}
              value={value}
              disabled={isEditing && value === eventOptionsObject.otherServices.value}
            >
              {label}
            </MenuItem>
          ))}
        </TextField>

        <Autocomplete
          options={clientList}
          filterOptions={(x) => x}
          onChange={(_, newValue) => {
            setFieldValue("client", newValue, true);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Search Clients"
              label="Client"
              helperText={
                touched.selectedClient && Boolean(errors.selectedClient)
                  ? errors.selectedClient
                  : "Select the team member of the client to join the event"
              }
              onBlur={handleBlur}
              error={touched.selectedClient && Boolean(errors.selectedClient)}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <MagnifyingGlass
                    color="rgba(0, 0, 0, 0.56)"
                    style={{ position: "absolute", right: "10px" }}
                  />
                ),
                readOnly: isEditing || !!values.selectedTeamMember,
              }}
              inputProps={{
                ...params.inputProps,
                onKeyDown: (e) => {
                  if (isEditing || values.selectedTeamMember) {
                    e.preventDefault(); // Prevent manual key actions when editing
                  }
                },
              }}
            />
          )}
          getOptionLabel={(option) => option?.name || option?.userDisplayName}
          getOptionKey={(option) => option?.clientId || option?.userId}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          name="client"
          inputValue={values.clientInputValue}
          onInputChange={!isEditing ? handleInputChange : null}
          value={values.selectedClient ?? null}
          clearIcon={null}
          renderOption={(props, option) =>
            option.teamMembers.length > 0 &&
            option.teamMembers.map((member) => {
              return (
                <MenuItem
                  {...props}
                  key={member.userId}
                  onClick={(event) => {
                    if (!values.selectedTeamMember) {
                      props.onClick(event);
                      handleAddChip(_, member);
                    }
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center", paddingLeft: 10 }}>
                    <ListItemIcon>
                      <UsersThree weight="duotone" />
                    </ListItemIcon>
                    <Typography variant="body2">
                      {member.name}
                      <span
                        style={{
                          fontStyle: "italic",
                          fontSize: "12px",
                          fontWeight: 400,
                          color: "#6C7489",
                          marginLeft: 4,
                        }}
                      >
                        ({member.roleName})
                      </span>
                    </Typography>
                  </div>
                </MenuItem>
              );
            })
          }
          groupBy={(option) => {
            return option.team.name && option.child_name;
          }}
          renderGroup={(params) => {
            if (!params.group) return params.children;

            return (
              <div key={params.key} style={{ paddingLeft: 20, color: "#5B6172", fontSize: 14 }}>
                <Typography variant="body2">{params.group}&apos; Team</Typography>
                {params.children}
              </div>
            );
          }}
        />

        <Box display="flex" flexDirection="column" alignItems="flex-start">
          {values.selectedTeamMember && (
            <StatusChip
              id={values.selectedTeamMember.id}
              label={values.selectedTeamMember.name}
              colorIndex={personScheduleColorMap[values.selectedTeamMember.id]}
              onDelete={onDeletePerson}
            />
          )}
        </Box>
        <TextField
          select
          label="Service"
          SelectProps={{
            renderValue: (selected) => serviceTypeLookup[selected],
          }}
          name="serviceType"
          value={values.serviceType}
          onChange={(e) => {
            handleChange(e);
            if (e.target.value === "ORIENTATION") setFieldValue("duration", 30);
          }}
          error={touched.serviceType && Boolean(errors.serviceType)}
          helperText={
            touched.serviceType && Boolean(errors.serviceType)
              ? errors.serviceType
              : "This can be changed prior to submitting for billing"
          }
          onBlur={handleBlur}
        >
          {serviceTypeOptions.map(({ icon, value, label }) => (
            <MenuItem key={`${value}`} value={value}>
              {icon && <ListItemIcon>{icon}</ListItemIcon>}
              {label}
            </MenuItem>
          ))}
        </TextField>

        {canSelectBCBA && (
          <>
            <ClinicianSelect
              clinicians={clinicians}
              values={values}
              selectedClinicians={selectedClinicians}
              setSelectedClinicians={setSelectedClinicians}
              touched={touched}
              errors={errors}
              handleBlur={handleBlur}
              handleChange={(e) => {
                handleProviderAddChip({});
                handleChange(e);
                e.target.value.split(",").map((id) => {
                  handleProviderAddChip(_, id);
                });
              }}
              setClinicianOptions={setClinicianOptions}
              clinicianOptions={clinicianOptions}
              videoChatInfo={videoChatInfo}
            />
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              {selectedClinicians.map((person, index) => {
                return (
                  <StatusChip
                    key={index}
                    id={person.clinician_id}
                    label={person.name || `${person.first_name} ${person.last_name}`}
                    colorIndex={providerScheduleColorMap[person.clinician_id]}
                    onDelete={onDeleteProvider}
                  />
                );
              })}
            </Box>
          </>
        )}
      </Stack>

      <Divider />

      <Stack gap={theme.spacing(5)}>
        <ANDatePicker
          label="Date"
          name="eventDate"
          value={values.eventDate}
          onChange={(value) => setFieldValue("eventDate", value, true)}
          format="PP" //"MMMM d, yyyy"
          disablePast
          slotProps={{
            textField: {
              error: touched.eventDate && Boolean(errors.eventDate),
              helperText: touched.eventDate && errors.eventDate,
              onBlur: handleBlur,
            },
          }}
        />
        <Stack direction="row" gap={theme.spacing(5)}>
          <TextField
            select
            fullWidth
            label="Start Time"
            name="startTime"
            value={values.startTime}
            onChange={handleChange}
            error={touched.startTime && Boolean(errors.startTime)}
            helperText={touched.startTime && errors.startTime}
            onBlur={handleBlur}
            SelectProps={{
              MenuProps: {
                PaperProps: { style: { maxHeight: "30vh" } },
              },
            }}
          >
            {timeOptions?.map((time, i) => (
              <MenuItem
                key={i}
                value={time}
                disabled={
                  isToday(values.eventDate) ? momentTZ(time, "h:mm A").isBefore(momentTZ()) : false
                }
              >
                {time}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label="Duration"
            fullWidth
            name="duration"
            value={values.duration}
            onChange={handleChange}
            error={touched.duration && Boolean(errors.duration)}
            helperText={touched.duration && errors.duration}
            onBlur={handleBlur}
            SelectProps={{ MenuProps: { PaperProps: { style: { maxHeight: "30vh" } } } }}
          >
            {durationOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
        {values.startTime && (
          <Stack gap={theme.spacing(1)}>
            {renderUserTimezone()}
            {selectedClinicians &&
              selectedClinicians.length > 0 &&
              selectedClinicians.map((clinician) => {
                return (
                  <Typography key={clinician.user_id} variant="caption">
                    {`${timeWithTimezoneToString(
                      values.startTime,
                      clinician.timezone
                    )} - ${addMinutesToTime(
                      values.startTime,
                      values.duration,
                      clinician.timezone
                    )} for ${clinician.first_name} (${momentTZ()
                      .tz(clinician.timezone)
                      ?.format("z")})`}
                  </Typography>
                );
              })}
          </Stack>
        )}
      </Stack>

      <Divider />

      <FormGroup>
        <FormControlLabel
          control={
            <Switch name="isRepeating" checked={values.isRepeating} onChange={handleChange} />
          }
          label="Repeat event"
        />
      </FormGroup>

      {values.isRepeating && (
        <Stack gap={theme.spacing(5)}>
          <Stack direction="row" gap={theme.spacing(5)} alignItems="flex-start">
            <TextField
              name="timeInterval"
              label="Repeat every"
              type="number"
              fullWidth
              value={values.timeInterval}
              placeholder="Select"
              error={touched.timeInterval && Boolean(errors.timeInterval)}
              helperText={touched.timeInterval && errors.timeInterval}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <TextField
              name="timeUnit"
              select
              fullWidth
              value={values.timeUnit}
              onChange={handleChange}
              error={touched.timeUnit && Boolean(errors.timeUnit)}
              helperText={touched.timeUnit && errors.timeUnit}
              onBlur={handleBlur}
              sx={{ pt: "22px" }} // keeps alignment consistent with other fields with labels
            >
              <MenuItem value="days">Day</MenuItem>
              <MenuItem value="weeks">Week</MenuItem>
            </TextField>
          </Stack>
          {values.timeUnit === "weeks" && (
            <>
              <Typography variant="inputLabel" color={theme.palette.text.secondary}>
                Repeat every
              </Typography>
              <Stack direction="row" gap={theme.spacing(3)} className={classes.daysOfWeek}>
                <Button color={getSelectedDayColor(7)} onClick={() => handleSelectDay(7)}>
                  S
                </Button>
                <Button color={getSelectedDayColor(1)} onClick={() => handleSelectDay(1)}>
                  M
                </Button>
                <Button color={getSelectedDayColor(2)} onClick={() => handleSelectDay(2)}>
                  T
                </Button>
                <Button color={getSelectedDayColor(3)} onClick={() => handleSelectDay(3)}>
                  W
                </Button>
                <Button color={getSelectedDayColor(4)} onClick={() => handleSelectDay(4)}>
                  T
                </Button>
                <Button color={getSelectedDayColor(5)} onClick={() => handleSelectDay(5)}>
                  F
                </Button>
                <Button color={getSelectedDayColor(6)} onClick={() => handleSelectDay(6)}>
                  S
                </Button>
              </Stack>
            </>
          )}
          <FormControl
            error={touched.repeatEndType && Boolean(errors.repeatEndType)}
            sx={{
              paddingBottom: touched.repeatUntilDate && errors.repeatUntilDate ? "40px" : 0,
            }}
          >
            <FormLabel id="repeat-end-label">Ends</FormLabel>
            <RadioGroup
              aria-labelledby="repeat-end-label"
              name="repeatEndType"
              value={values.repeatEndType}
              onChange={handleChange}
            >
              <FormControlLabel
                control={<Radio />}
                label="End of Authorization Period"
                value="current_auth_period"
                disabled={!hasInsuranceAuthorization}
              />
              <FormControlLabel control={<Radio />} label="On" value="until_date" />
              <ANDatePicker
                name="repeatUntilDate"
                value={values.repeatUntilDate}
                onChange={(value) => setFieldValue("repeatUntilDate", value, true)}
                disabled={values.repeatEndType !== "until_date"}
                format="PP" //"MMMM d, yyyy"
                shouldDisableDate={(date) => momentTZ(date).isBefore(momentTZ(values.eventDate))}
                slotProps={{
                  textField: {
                    error: touched.repeatUntilDate && Boolean(errors.repeatUntilDate),
                    helperText: touched.repeatUntilDate && errors.repeatUntilDate,
                    onBlur: handleBlur,
                  },
                }}
                sx={{
                  position: "absolute",
                  bottom: touched.repeatEndType && errors.repeatEndType ? "25px" : 0,
                  right: 0,
                  width: "177px",
                }}
              />
            </RadioGroup>
            {touched.repeatEndType && errors.repeatEndType && (
              <FormHelperText>{errors.repeatEndType}</FormHelperText>
            )}
          </FormControl>
        </Stack>
      )}
      <Stack gap={theme.spacing(3)}>
        <LoadingButton
          loading={scheduleCallLoading || onSubmitLoading}
          onClick={async (e) => {
            if (isEditing) {
              const errors = await manuallyValidateForm();
              if (!errors) setUpdateEventDialogOpen(true);
            } else handleSubmit(e);
          }}
        >
          {isEditing ? "Save" : "Create Event"}
        </LoadingButton>
        <Button
          color="secondary"
          onClick={history.goBack}
          disabled={scheduleCallLoading || onSubmitLoading}
        >
          Cancel
        </Button>
        {isEditing && (
          <Button color="error" variant="text" onClick={() => setCancelSessionOpen(true)}>
            Cancel Event
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

export default VideoCallSelections;
