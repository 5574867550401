import React, { useState } from "react";
import { Grid, Tooltip, Typography } from "@mui/material";

/**
 * The `RowItem` function creates a row component with a label and value, allowing for
 * copying the information to the clipboard with a tooltip.
 * @component `RowItem`
 * @param {Object} props - The props of the component
 * @param {Boolean} props.enableCopy - The flag to enable copying the Name-value
 * @param {String} props.label - The label of the row item
 * @param {String|Number} props.value - The value of the row item
 * @return {JSX.Element} The `<RowItem />` component is being returned. If the `value` prop is falsy, a dash `-` is returned.
 * returned.
 * @example <RowItem label="Fullname" value="John Doe" enableCopy />
 */
export function RowItem({ enableCopy = false, label, value }) {
  const [openTooltip, setOpenTooltip] = useState(false);

  const valueToDisplay = value || "-";

  const handleCopyInfo = () => {
    if (!enableCopy) return;

    const copyText = `${valueToDisplay}`;
    navigator.clipboard.writeText(copyText.replace(/ {6}/g, ""));

    // Close the tooltip after copying the text
    setOpenTooltip(false);
  };

  const handleCloseTooltip = () => {
    setOpenTooltip(false);
  };

  const handleOpenTooltip = () => {
    enableCopy && setOpenTooltip(true);
  };

  return (
    <Tooltip
      title={`Copy to clipboard: ${valueToDisplay}`}
      followCursor
      open={openTooltip}
      onClose={handleCloseTooltip}
      onOpen={handleOpenTooltip}
    >
      <Grid
        container
        component="section"
        marginTop={5}
        onClick={handleCopyInfo}
        sx={{ width: "100%", cursor: `${enableCopy ? "pointer" : "normal"}`, userSelect: "none" }}
      >
        <Grid item xs={6}>
          <Typography component="p" variant="body2">
            {label ?? "-"}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography component="h3" variant="subtitle2" align="right" noWrap>
            {valueToDisplay}
          </Typography>
        </Grid>
      </Grid>
    </Tooltip>
  );
}
