const drawerWidth = 330;

export default (theme) => ({
  root: {
    display: "flex",
    height: "100%",
  },
  drawer: {
    [theme.breakpoints.up("lg")]: {
      width: drawerWidth,
      flexShrink: 0,
      // height: "100vh",
    },
  },
  miniDisplay: {
    position: "fixed",
    bottom: 0,
    right: 20,
    width: 350,
    height: 500,
    overflowY: "auto",
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up("lg")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    backgroundColor: "#f3f4fc",
  },
  appBarFull: {
    marginLeft: 0,
    width: "100%",
    backgroundColor: "#f3f4fc",
  },
  appBarMiniDisplay: {
    maxHeight: 60,
    paddingLeft: 14,
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#d8d8d8",
    overflowY: "hidden",
    cursor: "pointer",
  },
  title: {
    flexGrow: 1,
    textAlign: "left",
  },
  menuButton: {
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  notesButton: {
    marginLeft: 20,
    marginTop: 5,
  },
  minimizeButton: {
    height: 20,
    width: 35,
    padding: "6px 8px 6px 8px",
    display: "flex",
    marginRight: 3,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 3,
    "&:hover": {
      backgroundColor: "#c7c7c7",
    },
  },
  minimize: {
    width: 25,
    height: 2,
    backgroundColor: "#555555",
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    overflowY: "hidden",
    width: drawerWidth,
    backgroundColor: "#f3f4fc",
  },
  content: {
    flexGrow: 1,
    backgroundColor: "#fff",
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
  },
  logo: {
    maxWidth: "60%",
    display: "block",
    margin: "0 auto",
    paddingTop: "10px",
    cursor: "pointer",
  },
  indicatorOffline: {
    display: "inline-block",
    width: "9px",
    height: "9px",
    borderRadius: "50%",
    backgroundColor: "#676767",
    // margin: "auto"
  },
  indicatorOnline: {
    display: "inline-block",
    width: "9px",
    height: "9px",
    borderRadius: "50%",
    backgroundColor: "#00cb20",
    // margin: "auto"
  },
  messageArea: {
    padding: "5px 10px",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflowY: "auto",
  },
  messageList: { display: "flex", flexDirection: "column" },
  messageInput: {
    flexGrow: 1,
    // minHeight: "70px",
    padding: "5px",
    borderTop: "1px solid #ccc",
    backgroundColor: "#f6f6f6",
    display: "flex",
    alignItems: "center",
  },
  typingMessage: {
    flexGrow: 1,
    minHeight: "25px",
    padding: "10px 24px",
    display: "flex",
  },
  inputField: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    maxWidth: "100%",
    flexGrow: 1,
    justifyContent: "center",
    position: "relative",
    fontFamily: "inherit",
  },
  sendButton: {
    padding: 10,
    display: "flex",
    flexDirection: "column",
    height: "44px",
    width: "44px",
    justifyContent: "center",
  },
  sentName: {
    marginRight: "15px",
    fontWeight: 500,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  sentDate: {
    color: "rgba(0,0,0,0.5)",
    fontSize: "0.7rem",
    width: "100%",
  },
  subheader: {
    backgroundColor: "#f3f4fc",
    height: 35,
    fontSize: 17,
  },
  message: {
    color: "#000",
    whiteSpace: "pre-wrap",
    fontSize: "1rem",
    fontFamily: ["Karla", "sans-serif"].join(","),
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: "0.00938em",
    wordBreak: "break-word",
    "& a": {
      color: "blue",
    },
    "& span": {
      color: "darkblue",
    },
  },
  currentUserMessage: {
    color: "#fff",
    whiteSpace: "pre-wrap",
    fontSize: "1rem",
    fontFamily: ["Karla", "sans-serif"].join(","),
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: "0.00938em",
    wordBreak: "break-word",
    // textAlign: "left !important",
    paddingRight: 0,
    "& a": {
      color: "blue",
    },
    "& span": {
      color: "darkblue",
    },
  },
  messageBody: {
    height: "fit-content",
    backgroundColor: "#d8d8d8",
    padding: 10,
    marginLeft: 5,
    borderRadius: 10,
  },
  currentUserMessageBody: { padding: 0, marginLeft: 5, borderRadius: 10 },
  messageContainer: {
    display: "block",
  },
  currentUserMessageContainer: {
    display: "block",
    float: "right !important",
  },
  messageBubble: {
    width: "fit-content",
    maxWidth: "85%",
    borderRadius: 10,
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: 5,
    marginBottom: 5,
  },
  currentUserMessageBubble: {
    width: "fit-content",
    maxWidth: "75%",
    backgroundColor: "#987ece",
    borderRadius: 10,
    padding: 10,
    paddingTop: 0,
    paddingBottom: 10,
    marginTop: 5,
    marginBottom: 5,
    alignSelf: "flex-end",
    float: "right",
  },
  channelName: {
    display: "inline-block",
    verticalAlign: "middle",
    whiteSpace: "nowrap",
    width: 155,
    marginRight: 15,
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: 16,
  },
  privateChannelName: {
    display: "inline-block",
    verticalAlign: "middle",
    whiteSpace: "nowrap",
    width: 175,
    marginRight: 15,
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: 15,
    fontWeight: 400,
  },
  channelNameHeading: {
    display: "block",
    verticalAlign: "middle",
    fontSize: "16px",
    color: "#555555",
    lineHeight: 1,
    fontWeight: 500,
    marginRight: 5,
  },
  multinameHeading: {
    display: "inline-block",
    verticalAlign: "middle",
    marginRight: 5,
    marginLeft: 5,
  },
  statusText: {
    fontSize: "11px",
    fontWeight: 300,
    display: "block",
  },
  navButton: {
    color: "#555555",
    fontWeight: "500",
  },
  childInfo: {
    display: "block",
    fontSize: "10px",
    color: "#666",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: 13,
  },
  messageImage: {
    display: "block",
    width: 400,
    maxWidth: "100%",
    height: "auto",
    borderRadius: 5,
  },
  messageDocument: {
    display: "block",
    width: 200,
    height: "auto",
    border: "1px solid #ccc",
    textDecoration: "none",
    textAlign: "center",
    borderRadius: 4,
    padding: 15,
  },
  messageIcon: {
    width: 40,
    height: "auto",
    color: "#000",
  },
  messageFileName: {
    color: "#000",
    fontWeight: "500",
    textAlign: "center",
    marginTop: 10,
    textDecoration: "none",
    textOverflow: "ellipsis",
    width: "100%",
    overflow: "hidden",
  },
  messageVideo: {
    display: "block",
    width: "100%",
    height: "auto",
    borderRadius: 5,
  },
  bcbaImage: {
    height: 12,
    width: "auto",
    display: "inline-block",
    verticalAlign: "middle",
    marginLeft: 5,
  },
  joinButton: {
    textTransform: "none",
    fontSize: "13px",
    fontWeight: 500,
    display: "inline-block",
    margin: "0 auto",
    "&:hover": {
      backgroundColor: "#269c7b",
    },
  },
  leaveButton: {
    textTransform: "none",
    fontSize: "11px",
    zIndex: 2,
  },
  loadingGroups: {
    margin: "45px auto",
  },
  confirmationMessage: {
    margin: "15px 0 25px",
  },
  confirmationAgree: {
    display: "inline-block",
    margin: "0 auto",
  },
  groupList: {
    overflow: "auto",
    width: "auto",
    maxHeight: 300,
  },
  chatList: {
    backgroundColor: "#fff",
    margin: 10,
    borderRadius: 10,
    overflow: "hidden",
    "& button": {
      display: "none",
    },
    "&:hover": {
      "& button": {
        display: "inline-block",
      },
    },
  },
  chatListToJoin: {
    backgroundColor: "#fff",
    margin: 10,
    borderRadius: 10,
    overflow: "hidden",
    "& button": {
      backgroundColor: "#34d4a7",
      fontWeight: 500,
    },
  },
  avatar: {
    display: "inline-flex",
    marginRight: 10,
    width: 30,
    height: 30,
    verticalAlign: "middle",
  },
  headerAvatar: {
    height: 40,
    width: 40,
    position: "absolute",
    right: 24,
    top: 12,
  },
  headingFlex: {
    display: "flex",
    alignItems: "center",
  },
  topNotice: {
    fontStyle: "italic",
    fontSize: "13px",
  },
  lockIcon: {
    width: 12,
    height: 12,
    verticalAlign: "middle",
    marginLeft: 3,
    marginTop: -3,
  },
  suggestionName: {
    fontSize: 16,
    fontWeight: 400,
    marginLeft: 10,
    marginRight: 10,
  },
  joinVideoContainer: {
    display: "block",
    width: "90%",
    margin: "5px 5%",
  },
  hideVideoContainer: {
    display: "none",
  },
  joinVideoButton: {
    backgroundColor: "#1de9B6",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  joinVideoText: {
    color: "#fff",
    fontWeight: "500",
  },
  joinVideoIcon: {
    color: "#fff",
    marginRight: 10,
    marginBottom: 2,
    height: 26,
    width: "auto",
  },
  dropzoneDialogRoot: {
    zIndex: "40003 !important",
  },
});
