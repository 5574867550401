import React, { useEffect } from "react";
import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import GenericTextField from "../GenericFormFields/GenericTextField";
import InputLabel from "@mui/material/InputLabel";
import GenericCheckbox from "../GenericFormFields/GenericCheckbox";
import Grid from "@mui/material/Grid"; // Import Grid component
import { useWatch, useFormContext } from "react-hook-form";
import { checkboxes, providerTypes } from "./providerTypes";
import { FormHelperText } from "@mui/material";

const ProvidersFormDialogROIForm = (props) => {
  const { setValue, setError, clearErrors, control, formState } = useFormContext();
  const other = useWatch({ control, name: "other" });
  const { classes } = props;
  const { errors } = formState;

  const providerType = useWatch({ control, name: "provider.type" });

  const checkboxValues = useWatch({ control, name: checkboxes });

  // Set default checkbox values based on provider type
  useEffect(() => {
    if (providerType) {
      const defaults = providerTypes.find((p) => p.value === providerType)?.defaultDisclosureInfo;
      const nonDefaults = checkboxes.filter((c) => !defaults.includes(c));
      defaults.forEach((field) => {
        setValue(field, true);
      });
      nonDefaults.forEach((field) => {
        setValue(field, false);
      });
    }
  }, [providerType, setValue]);

  useEffect(() => {
    if (!checkboxValues.every((value) => !value)) {
      clearErrors("checkboxes");
    }
  }, [checkboxValues, setError, clearErrors]);

  return (
    <>
      <Grid container direction="column">
        <Typography variant="body2" className={classes.verticalSpace}>
          Send the client a release of information request for this provider
        </Typography>
        <GenericTextField
          className={classes.verticalSpace}
          name="document.title"
          placeholder="Insert title"
          label="Title of Request (Internal use only)"
          helperText={"Please enter the title of the request"}
          required
        />

        <Typography className={classes.disclosureInfo}>Disclosure Information</Typography>
        <Grid item className={classes.checkbox}>
          <GenericCheckbox
            name={"historyAndPhysicalExamination"}
            label="History & Physical Examination"
          />
        </Grid>
        <Grid item className={classes.checkbox}>
          <GenericCheckbox
            name={"diagnosticReportOrTesting"}
            label="Diagnostic Report or Testing"
          />
        </Grid>
        <Grid item className={classes.checkbox}>
          <GenericCheckbox name={"progressNotes"} label="Progress Notes" />
        </Grid>
        <Grid item className={classes.checkbox}>
          <GenericCheckbox name={"psychologicalReports"} label="Psychological Reports" />
        </Grid>
        <Grid item className={classes.checkbox}>
          <GenericCheckbox name={"educationPlan"} label="Education Plan / IEP / 504 Plan" />
        </Grid>
        <Grid item className={classes.checkbox}>
          <GenericCheckbox name={"treatmentPlanAndGoals"} label="Treatment Plan & Goals" />
        </Grid>
        <Grid item className={classes.verticalSpace}>
          <GenericCheckbox name={"other"} label="Other" />
        </Grid>
        {other && (
          <GenericTextField
            className={classes.verticalSpace}
            multiline={true}
            rows={3}
            name="otherDescription"
            placeholder="Description of Requested Information"
            label="Other"
            helperText={"Please enter a description of the requested information"}
            required={!!other}
          />
        )}
        {errors?.checkboxes && (
          <Typography color="error" variant="caption">
            Select at least one disclosure type
          </Typography>
        )}
      </Grid>
    </>
  );
};

const styles = () => ({
  verticalSpace: {
    marginBottom: "16px",
  },
  checkbox: {
    marginBottom: ".5rem",
  },
  disclosureInfo: {
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "14px",
    letterSpacing: "0.3px",
    color: "#5B6172",
    margin: "1rem 0",
  },
});

export default withStyles(styles)(ProvidersFormDialogROIForm);
