import React from "react";
import ModalAlert from "../../../elements/ModalAlert";

const SaveBeforeNavDialog = (props) => {
  const {
    saveBeforeNavOpen,
    onToggleSaveBeforeNav,
    onContinueWithoutSaving,
    isCancelClicked,
    secondaryText,
    severetyAlert,
    primaryActionColorButton,
  } = props;

  return (
    <ModalAlert
      open={saveBeforeNavOpen}
      aria-labelledby="form-dialog-title"
      key="upload-document-dialog"
      title="You have unsaved changes"
      description={
        isCancelClicked
          ? "Are you sure you want to cancel your changes? Changes you made will not be saved."
          : "Are you sure you want to leave this page? Changes you made will not be saved"
      }
      primaryActionText={isCancelClicked ? "Cancel Changes" : "Leave This Page"}
      primaryActionOnClick={onContinueWithoutSaving}
      secondaryActionText={secondaryText ?? "Cancel"}
      secondaryActionOnClick={onToggleSaveBeforeNav}
      severetyAlert={severetyAlert}
      primaryActionColorButton={primaryActionColorButton}
    />
  );
};

export default SaveBeforeNavDialog;
