import React from "react";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { STATES } from "../../constants";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";
import Logo from "../../assets/an_purple_logo.png";
import { CustomInput } from "elements/DEPRECATED_elements/CustomInput/v2";
import TextField from "@mui/material/TextField";
import CssBaseline from "@mui/material/CssBaseline";
import CircularProgress from "@mui/material/CircularProgress";
import MenuItem from "@mui/material/MenuItem";

const ContactForm = (props) => {
  const {
    classes,
    guardianFirstName,
    guardianLastName,
    email,
    phoneNumber,
    clientFirstName,
    clientLastName,
    clientAge,
    clientState,
    clientZip,
    clientGender,
    clientIsVerbal,
    clientHasASDDiagnosis,
    insuranceInfo,
    additionalInfo,
    isMedicaid,
    emailError,
    phoneError,
    zipError,
    onChange,
    onNumberChange,
    onPhoneNumberChange,
    onRadioButtonChange,
    handleEmailChange,
    validateEmail,
    onSaveCustomerForLater,
    setError,
    toggleContactForm,
    contactFormConfirmationOpen,
    contactMethod,
    contactTime,
    hasComputer,
    hasInternet,
    savingForLater,
    createUserError,
    relationshipToClient,
    wasReferred,
    referralSource,
  } = props;
  const submitButtonDisabled = () => {
    if (
      !clientFirstName ||
      !clientLastName ||
      !guardianFirstName ||
      !guardianLastName ||
      !email ||
      !phoneNumber ||
      !clientAge ||
      !clientState ||
      clientHasASDDiagnosis === null ||
      phoneError ||
      emailError
    ) {
      return true;
    } else {
      return false;
    }
  };
  if (savingForLater) {
    return (
      <div className={classes.loading}>
        <CircularProgress />
      </div>
    );
  } else {
    return (
      <div className={classes.contactFormContainer}>
        <CssBaseline />
        <div className={classes.formContainer}>
          <div className={classes.formTitleContainer}>
            <img className={classes.formLogo} src={Logo} alt="AnswersNow" title="AnswersNow" />
            <Typography className={classes.formTitle}>Contact Info</Typography>
          </div>
          <div className={classes.contactForm}>
            <Grid container className={classes.profileInfoContainer}>
              <Grid container item xs={12}>
                <Typography component="h2" className={classes.formInfoText}>
                  We're excited to get you started! Please fill in the following information and
                  we'll schedule a time to complete your profile.
                </Typography>
                <Typography component="h2" className={classes.formInfoText}>
                  If you would prefer to speak to someone now, call our customer care team at
                  (804)-215-5600.
                </Typography>
                <Typography
                  className={classes.formInfoText}
                  style={{ fontSize: 15, width: "100%" }}
                >
                  * Required fields
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={10} className={classes.sectionTitleContainer}>
              <Typography component="h1" className={classes.sectionTitle}>
                Profile Information
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={12}
              spacing={5}
              className={classes.profileInfoContainer}
            >
              <Grid item sm={12} md={6}>
                <FormControl margin="none" fullWidth>
                  <FormLabel
                    component="legend"
                    style={{
                      marginBottom: 10,
                      fontSize: 16,
                      fontWeight: 500,
                      color: "#545A72",
                      lineHeight: 1.4,
                    }}
                  >
                    Guardian's First Name *
                  </FormLabel>
                  <TextField
                    id="guardianFirstName"
                    name="guardianFirstName"
                    autoComplete="off"
                    value={guardianFirstName}
                    onChange={onChange("guardianFirstName")}
                    placeholder="first name"
                  />
                </FormControl>
              </Grid>
              <Grid item sm={12} md={6}>
                <FormControl margin="none" fullWidth>
                  <FormLabel
                    component="legend"
                    style={{
                      marginBottom: 10,
                      fontSize: 16,
                      fontWeight: 500,
                      color: "#545A72",
                      lineHeight: 1.4,
                    }}
                  >
                    Guardian's Last Name *
                  </FormLabel>
                  <TextField
                    id="guardianLastName"
                    name="guardianLastName"
                    autoComplete="off"
                    value={guardianLastName}
                    onChange={onChange("guardianLastName")}
                    placeholder="last name"
                  />
                </FormControl>
              </Grid>
              <Grid item sm={12} md={6}>
                <FormControl margin="none" fullWidth>
                  <FormLabel
                    component="legend"
                    style={{
                      marginBottom: 10,
                      fontSize: 16,
                      fontWeight: 500,
                      color: "#545A72",
                      lineHeight: 1.4,
                    }}
                  >
                    Email *
                  </FormLabel>
                  <TextField
                    id="email"
                    name="email"
                    autoComplete="off"
                    value={email}
                    onChange={handleEmailChange("email")}
                    variant="outlined"
                    placeholder="example@gmail.com"
                    onFocus={() => setError("emailError", false)}
                    onBlur={() =>
                      validateEmail(email) || !email
                        ? setError("emailError", false)
                        : setError("emailError", true)
                    }
                    helperText={emailError ? "Invalid Email" : null}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={12} md={6}>
                <FormControl margin="none" fullWidth>
                  <FormLabel
                    component="legend"
                    style={{
                      marginBottom: 10,
                      fontSize: 16,
                      fontWeight: 500,
                      color: "#545A72",
                      lineHeight: 1.4,
                    }}
                  >
                    Phone Number *
                  </FormLabel>
                  <TextField
                    id="phone"
                    name="phone"
                    autoComplete="off"
                    value={phoneNumber}
                    onChange={onPhoneNumberChange("phoneNumber")}
                    variant="outlined"
                    placeholder="(000)-000-0000"
                    inputProps={{ maxLength: 10 }}
                    onFocus={() => setError("phoneError", false)}
                    onBlur={() =>
                      !phoneNumber || phoneNumber.trim().match(/\d/g).length === 10
                        ? setError("phoneError", false)
                        : setError("phoneError", true)
                    }
                    helperText={phoneError ? "Invalid Phone Number" : null}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl margin="none" fullWidth>
                  <FormLabel
                    component="legend"
                    style={{
                      marginBottom: 10,
                      fontSize: 16,
                      fontWeight: 500,
                      color: "#545A72",
                      lineHeight: 1.4,
                    }}
                  >
                    Your Relationship to the Client
                  </FormLabel>

                  <TextField
                    id="relationshipToClient"
                    name="relationshipToClient"
                    autoComplete="off"
                    onChange={onChange("relationshipToClient")}
                    value={relationshipToClient}
                    style={{ backgroundColor: "#FFF" }}
                    select
                    SelectProps={{
                      required: true,
                    }}
                  >
                    <MenuItem disabled value="">
                      relationship
                    </MenuItem>
                    <MenuItem value="is_parent">Parent</MenuItem>
                    <MenuItem value="legal_guardian">Legal Guardian</MenuItem>
                    <MenuItem value="caregiver">Caregiver</MenuItem>
                    <MenuItem value="family_member">Family Member</MenuItem>
                    <MenuItem value="spouse">Spouse</MenuItem>
                    <MenuItem value="dependent">Dependent</MenuItem>
                    <MenuItem value="child">Child</MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item sm={12} md={6}>
                <FormControl margin="none" fullWidth>
                  <FormLabel
                    component="legend"
                    style={{
                      marginBottom: 10,
                      fontSize: 16,
                      fontWeight: 500,
                      color: "#545A72",
                      lineHeight: 1.4,
                    }}
                  >
                    Client's First Name *
                  </FormLabel>
                  <TextField
                    id="clientFirstName"
                    name="clientFirstName"
                    autoComplete="off"
                    value={clientFirstName}
                    onChange={onChange("clientFirstName")}
                    placeholder="first name"
                  />
                </FormControl>
              </Grid>
              <Grid item sm={12} md={6}>
                <FormControl margin="none" fullWidth>
                  <FormLabel
                    component="legend"
                    style={{
                      marginBottom: 10,
                      fontSize: 16,
                      fontWeight: 500,
                      color: "#545A72",
                      lineHeight: 1.4,
                    }}
                  >
                    Client's Last Name *
                  </FormLabel>
                  <TextField
                    id="clientLastName"
                    name="clientLastName"
                    autoComplete="off"
                    value={clientLastName}
                    onChange={onChange("clientLastName")}
                    placeholder="last name"
                  />
                </FormControl>
              </Grid>
              <Grid item sm={12} md={6}>
                <FormControl margin="none" fullWidth>
                  <FormLabel
                    component="legend"
                    style={{
                      marginBottom: 10,
                      fontSize: 16,
                      fontWeight: 500,
                      color: "#545A72",
                      lineHeight: 1.4,
                    }}
                  >
                    Client's Age *
                  </FormLabel>
                  <TextField
                    id="clientAge"
                    name="clientAge"
                    autoComplete="off"
                    value={clientAge}
                    onChange={onChange("clientAge")}
                    placeholder="age"
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={12} md={6}>
                <FormControl margin="none" fullWidth>
                  <FormLabel
                    component="legend"
                    style={{
                      marginBottom: 10,
                      fontSize: 16,
                      fontWeight: 500,
                      color: "#545A72",
                      lineHeight: 1.4,
                    }}
                  >
                    Client's Gender
                  </FormLabel>
                  <TextField
                    name="clientGender"
                    autoComplete="off"
                    id="clientGender"
                    onChange={onChange("clientGender")}
                    value={client}
                    style={{ backgroundColor: "#FFF" }}
                    select
                  >
                    <MenuItem disabled value={""}>
                      gender
                    </MenuItem>
                    <MenuItem value="Female">Female</MenuItem>
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item sm={12} md={6}>
                <FormControl margin="none" fullWidth>
                  <FormLabel
                    component="legend"
                    style={{
                      marginBottom: 10,
                      fontSize: 16,
                      fontWeight: 500,
                      color: "#545A72",
                      lineHeight: 1.4,
                    }}
                  >
                    State *
                  </FormLabel>
                  <TextField
                    id="clientState"
                    name="clientState"
                    value={clientState}
                    onChange={onChange("clientState")}
                    autoComplete="off"
                    style={{ backgroundColor: "#FFF" }}
                    select
                  >
                    <MenuItem disabled value={""}>
                      Select a state
                    </MenuItem>
                    {Object.keys(STATES).map((key) => {
                      return (
                        <MenuItem key={key} value={key}>
                          {STATES[key]}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item sm={12} md={6}>
                <FormControl margin="none" fullWidth>
                  <FormLabel
                    component="legend"
                    style={{
                      marginBottom: 10,
                      fontSize: 16,
                      fontWeight: 500,
                      color: "#545A72",
                      lineHeight: 1.4,
                    }}
                  >
                    ZIP Code
                  </FormLabel>
                  <TextField
                    id="clientZip"
                    name="clientZip"
                    autoComplete="off"
                    value={clientZip}
                    inputProps={{ maxLength: 5 }}
                    onChange={onNumberChange("clientZip")}
                    placeholder="ZIP Code"
                    onFocus={() => setError("zipError", false)}
                    onBlur={() =>
                      clientZip.trim().length === 5 || !clientZip
                        ? setError("zipError", false)
                        : setError("zipError", true)
                    }
                    helperText={zipError ? "Invalid ZIP Code" : null}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={12} md={6}>
                <FormControl margin="none" fullWidth>
                  <FormLabel
                    component="legend"
                    style={{
                      marginBottom: 10,
                      fontSize: 16,
                      fontWeight: 500,
                      color: "#545A72",
                      lineHeight: 1.4,
                    }}
                  >
                    Insurance Provider / Plan Name
                  </FormLabel>
                  <TextField
                    id="insuranceInfo"
                    name="insuranceInfo"
                    autoComplete="off"
                    value={insuranceInfo}
                    onChange={onChange("insuranceInfo")}
                    placeholder="insurance information"
                  />
                </FormControl>
              </Grid>
              <Grid item sm={12} md={6}>
                <FormLabel
                  component="legend"
                  style={{
                    marginBottom: 10,
                    fontSize: 16,
                    fontWeight: 500,
                    color: "#545A72",
                    lineHeight: 1.4,
                  }}
                >
                  Is this a Medicaid plan?
                </FormLabel>

                <RadioGroup
                  aria-label="isMedicaid"
                  name="isMedicaid"
                  value={isMedicaid}
                  onChange={onRadioButtonChange("isMedicaid")}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio color="primary" size="small" />}
                    label={
                      <Typography
                        component="p"
                        style={{
                          fontSize: 15,
                          color: "#2A2D39",
                        }}
                      >
                        Yes
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio color="primary" size="small" />}
                    label={
                      <Typography
                        component="p"
                        style={{
                          fontSize: 15,
                          color: "#2A2D39",
                        }}
                      >
                        No
                      </Typography>
                    }
                  />
                </RadioGroup>
              </Grid>
              <Grid item sm={12} md={6}>
                <FormLabel
                  component="legend"
                  style={{
                    marginBottom: 10,
                    fontSize: 16,
                    fontWeight: 500,
                    color: "#545A72",
                    lineHeight: 1.4,
                  }}
                >
                  Has the client received an autism diagnosis? *
                </FormLabel>

                <RadioGroup
                  aria-label="clientHasASDDiagnosis"
                  name="clientHasASDDiagnosis"
                  value={clientHasASDDiagnosis}
                  onChange={onRadioButtonChange("clientHasASDDiagnosis")}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio color="primary" size="small" />}
                    label={
                      <Typography
                        component="p"
                        style={{
                          fontSize: 15,
                          color: "#2A2D39",
                        }}
                      >
                        Yes
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio color="primary" size="small" />}
                    label={
                      <Typography
                        component="p"
                        style={{
                          fontSize: 15,
                          color: "#2A2D39",
                        }}
                      >
                        No
                      </Typography>
                    }
                  />
                </RadioGroup>
              </Grid>

              <Grid item sm={12} md={6}>
                <FormLabel
                  component="legend"
                  style={{
                    marginBottom: 10,
                    fontSize: 16,
                    fontWeight: 500,
                    color: "#545A72",
                    lineHeight: 1.4,
                  }}
                >
                  Is the client verbal?
                </FormLabel>

                <RadioGroup
                  aria-label="clientIsVerbal"
                  name="clientIsVerbal"
                  value={clientIsVerbal}
                  onChange={onRadioButtonChange("clientIsVerbal")}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio color="primary" size="small" />}
                    label={
                      <Typography
                        component="p"
                        style={{
                          fontSize: 15,
                          color: "#2A2D39",
                        }}
                      >
                        Yes
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio color="primary" size="small" />}
                    label={
                      <Typography
                        component="p"
                        style={{
                          fontSize: 15,
                          color: "#2A2D39",
                        }}
                      >
                        No
                      </Typography>
                    }
                  />
                </RadioGroup>
              </Grid>

              <Grid item sm={12} md={6}>
                <FormLabel
                  component="legend"
                  style={{
                    marginBottom: 10,
                    fontSize: 16,
                    fontWeight: 500,
                    color: "#545A72",
                    lineHeight: 1.4,
                  }}
                >
                  Do you have a home computer or tablet?
                </FormLabel>

                <RadioGroup
                  aria-label="hasComputer"
                  name="hasComputer"
                  value={hasComputer}
                  onChange={onRadioButtonChange("hasComputer")}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio color="primary" size="small" />}
                    label={
                      <Typography
                        component="p"
                        style={{
                          fontSize: 15,
                          color: "#2A2D39",
                        }}
                      >
                        Yes
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio color="primary" size="small" />}
                    label={
                      <Typography
                        component="p"
                        style={{
                          fontSize: 15,
                          color: "#2A2D39",
                        }}
                      >
                        No
                      </Typography>
                    }
                  />
                </RadioGroup>
              </Grid>
              <Grid item sm={12} md={6}>
                <FormLabel
                  component="legend"
                  style={{
                    marginBottom: 10,
                    fontSize: 16,
                    fontWeight: 500,
                    color: "#545A72",
                    lineHeight: 1.4,
                  }}
                >
                  Do you have high speed internet?
                </FormLabel>

                <RadioGroup
                  aria-label="hasInternet"
                  name="hasInternet"
                  value={hasInternet}
                  onChange={onRadioButtonChange("hasInternet")}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio color="primary" size="small" />}
                    label={
                      <Typography
                        component="p"
                        style={{
                          fontSize: 15,
                          color: "#2A2D39",
                        }}
                      >
                        Yes
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio color="primary" size="small" />}
                    label={
                      <Typography
                        component="p"
                        style={{
                          fontSize: 15,
                          color: "#2A2D39",
                        }}
                      >
                        No
                      </Typography>
                    }
                  />
                </RadioGroup>
              </Grid>

              <Grid item sm={12} md={6}>
                <FormControl margin="none" fullWidth>
                  <FormLabel
                    component="legend"
                    style={{
                      marginBottom: 10,
                      fontSize: 16,
                      fontWeight: 500,
                      color: "#545A72",
                      lineHeight: 1.4,
                    }}
                  >
                    How would you prefer to be contacted?
                  </FormLabel>
                  <TextField
                    id="contactMethod"
                    name="contactMethod"
                    autoComplete="off"
                    value={contactMethod}
                    onChange={onChange("contactMethod")}
                    select
                    style={{ backgroundColor: "#FFF" }}
                  >
                    <MenuItem disabled value={""}>
                      Select a method
                    </MenuItem>
                    <MenuItem value="phone call">Phone Call</MenuItem>
                    <MenuItem value="email">Email</MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
              {/* <Grid item xs={12} className={classes.extraBlockTwo} /> */}

              <Grid item sm={12} md={6}>
                <FormControl margin="none" fullWidth>
                  <FormLabel
                    component="legend"
                    style={{
                      marginBottom: 10,
                      fontSize: 16,
                      fontWeight: 500,
                      color: "#545A72",
                      lineHeight: 1.4,
                    }}
                  >
                    Best time to be contacted?
                  </FormLabel>
                  <TextField
                    id="contactTime"
                    name="bestContactTime"
                    autoComplete="off"
                    value={contactTime}
                    onChange={onChange("contactTime")}
                    select
                    style={{ backgroundColor: "#FFF" }}
                  >
                    <MenuItem disabled value={""}>
                      Select a time
                    </MenuItem>
                    <MenuItem value="morning">Morning - 8.00 a.m. - 11.00 a.m. </MenuItem>
                    <MenuItem value="afternoon">Afternoon - 12.00 p.m. - 5.00 p.m.</MenuItem>
                    <MenuItem value="evening">Evening - 6.00 p.m. - 8.00 p.m.</MenuItem>
                    <MenuItem value="anytime">Anytime</MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item sm={12}>
                <FormControl margin="none" fullWidth>
                  <FormLabel
                    component="legend"
                    style={{
                      marginBottom: 10,
                      fontSize: 16,
                      fontWeight: 500,
                      color: "#545A72",
                      lineHeight: 1.4,
                    }}
                  >
                    Additional Information
                  </FormLabel>
                  <TextField
                    id="additionalInfo"
                    name="additionalInfo"
                    autoComplete="off"
                    value={additionalInfo}
                    onChange={onChange("additionalInfo")}
                    multiline={true}
                    rows={3}
                    style={{ backgroundColor: "#FCF9FF" }}
                    placeholder="Anything else you would like us to know?"
                  />
                </FormControl>
              </Grid>
              <Grid item sm={12} md={6}>
                <FormLabel
                  component="legend"
                  style={{
                    marginBottom: 10,
                    fontSize: 16,
                    fontWeight: 500,
                    color: "#545A72",
                    lineHeight: 1.4,
                  }}
                >
                  Were You Referred to AnswersNow?
                </FormLabel>

                <RadioGroup
                  aria-label="wasReferred"
                  name="wasReferred"
                  value={wasReferred}
                  onChange={onRadioButtonChange("wasReferred")}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio color="primary" size="small" />}
                    label={
                      <Typography
                        component="p"
                        style={{
                          fontSize: 15,
                          color: "#2A2D39",
                        }}
                      >
                        Yes
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio color="primary" size="small" />}
                    label={
                      <Typography
                        component="p"
                        style={{
                          fontSize: 15,
                          color: "#2A2D39",
                        }}
                      >
                        No
                      </Typography>
                    }
                  />
                </RadioGroup>
              </Grid>
              {wasReferred && (
                <Grid item sm={12} md={6}>
                  <FormControl margin="none" fullWidth>
                    <FormLabel
                      component="legend"
                      style={{
                        marginBottom: 10,
                        fontSize: 16,
                        fontWeight: 500,
                        color: "#545A72",
                        lineHeight: 1.4,
                      }}
                    >
                      Who Referred You?
                    </FormLabel>
                    <TextField
                      id="referralSource"
                      name="referralSource"
                      autoComplete="off"
                      value={referralSource}
                      onChange={onChange("referralSource")}
                      placeholder="last name"
                    />
                  </FormControl>
                </Grid>
              )}
            </Grid>
            <div className={classes.formButtonsContainer}>
              <div className={classes.formButtons}>
                <Button
                  onClick={onSaveCustomerForLater}
                  className={classes.submit}
                  disabled={submitButtonDisabled()}
                >
                  SUBMIT
                </Button>
                <Button variant="text" className={classes.submit} onClick={toggleContactForm}>
                  Cancel
                </Button>
              </div>
              {createUserError && (
                <Typography className={classes.signinError}>{createUserError}</Typography>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default ContactForm;
