import AudioInputController from "video/controllers/AudioInputController";
import AudioOutputController from "video/controllers/AudioOutputController";
import VideoInputController from "video/controllers/VideoInputController";
import MeetingSession from "video/meeting/MeetingSession";
import { log } from "video/debug";

export default class AudioVideoController {
  videoDownstreamPacketLossPercent = 0;
  videoUpstreamPacketLossPercent = 0;
  availableIncomingBitrate = 0; // bps
  availableOutgoingBitrate = 0; // bps

  /**
   * number of active Video Tiles
   */
  get length() {
    return this.meetingSession?.audioVideo?.getAllVideoTiles().length || 0;
  }

  /**
   * @param {MeetingSession} meetingSession
   * @param {typeof VideoInputController.prototype.userVideoContainerLogic} userVideoContainerLogic
   * @param {"AUTO"|VideoInputQuality} desiredVideoQuality
   */
  constructor(meetingSession, userVideoContainerLogic, desiredVideoQuality = "AUTO") {
    this.meetingSession = meetingSession;
    meetingSession.meetingSession.audioVideo.addObserver(this);

    // Audio input/out controllers
    this.audioInputController = new AudioInputController(meetingSession);
    this.audioOutputController = new AudioOutputController(meetingSession);
    this.videoInputController = new VideoInputController(
      meetingSession,
      userVideoContainerLogic,
      desiredVideoQuality
    );

    // TODO do we need this still?
    this.meetingSession.eventController.onEvent("videoInputUnselected", (attr) => {
      if (attr.videoInputErrorMessage) console.error(attr.videoInputErrorMessage);

      if (this.videoInputController.__started) {
        this.changeVideoInput(this.meetingSession.devices.videoInputDevice);
      }
    });
  }

  destroy() {
    this._audioVideoDidStart = [];
    this._audioVideoDidStop = [];

    this.audioInputController.destroy();
    this.audioOutputController.destroy();
    this.videoInputController.destroy();

    this.audioOutputController = null;
    this.audioInputController = null;
    this.videoInputController = null;
  }

  /**
   * Start all A/V devices' input/output
   */
  async startAllDevices() {
    log("starting all devices");

    this.setAllDevices();

    if (this.meetingSession.__getDeviceState().audioInputEnabled) {
      await this.startAudioInput();
    }
    if (this.meetingSession.__getDeviceState().audioOutputEnabled) {
      await this.startAudioOutput();
    }
    if (this.meetingSession.__getDeviceState().videoInputEnabled) {
      await this.startVideoInput();
    }
  }

  /**
   * Restart all A/V devices' input/output
   */
  async restartAllDevices() {
    log("restarting all devices");
    this.setAllDevices();
    await this.restartAudioOutput();
    await this.restartAudioInput();
    await this.restartVideoInput();
  }

  /**
   * Stop all A/V devices' input/output
   */
  async stopAllDevices() {
    log("stopping all devices");
    await this.stopAudioInput();
    await this.stopAudioOutput();
    await this.stopVideoInput();
  }

  setAllDevices() {
    const {
      audioOutputContainer = null,
      audioInputDevice = "",
      audioOutputDevice = "",
      videoInputDevice = "",
    } = this.meetingSession.devices;

    this.setAudioOutput(audioOutputDevice, audioOutputContainer);
    this.setAudioInput(audioInputDevice);
    this.setVideoInput(videoInputDevice);
  }

  setAudioOutput(device = "", container = null) {
    return this.audioOutputController?.setDevice(device, container);
  }
  setAudioInput(device = "") {
    return this.audioInputController?.setDevice(device);
  }
  setVideoInput(device = "") {
    return this.videoInputController?.setDevice(device);
  }

  /**
   * @param {HTMLElement} container
   */
  async changeAudioOutput(device = "", container = null) {
    return this.audioOutputController?.changeDevice(device, container);
  }
  async changeAudioInput(device = "") {
    return this.audioInputController?.changeDevice(device);
  }
  async changeVideoInput(device = "") {
    return this.videoInputController?.changeDevice(device);
  }

  async startAudioOutput(restart = false) {
    return restart ? this.audioOutputController?.restart() : this.audioOutputController?.start();
  }
  async startAudioInput(restart = false) {
    return restart ? this.audioInputController?.restart() : this.audioInputController?.start();
  }
  async startVideoInput(restart = false) {
    return restart ? this.videoInputController?.restart() : this.videoInputController?.start();
  }

  async stopAudioOutput() {
    return this.audioOutputController?.stop();
  }
  async stopAudioInput() {
    return this.audioInputController?.stop();
  }
  async stopVideoInput() {
    return this.videoInputController?.stop();
  }

  async restartAudioOutput() {
    return this.startAudioOutput(true);
  }
  async restartAudioInput() {
    return this.startAudioInput(true);
  }
  async restartVideoInput() {
    return this.startVideoInput(true);
  }

  /**
   * Called when video availability has changed. This information can be used to decide whether to
   * switch the connection type to video and whether or not to offer the option to start the local
   * video tile.
   * @param {{canStartLocalVideo:boolean,remoteVideoAvailable:true}} availability
   */
  videoAvailabilityDidChange = (availability) => {
    log("videoAvailabilityDidChange", { availability });
    this.videoInputController.videoAvailabilityDidChange(availability);
  };

  /**
   * Called whenever a tile has been created or updated.
   */
  videoTileDidUpdate(/**@type {VideoTileState}*/ videoTileState) {
    log("videoTileDidUpdate", { videoTileState });
    this.videoInputController.videoTileDidUpdate(videoTileState);
    this.__onVideoTileChanged(videoTileState);
  }

  /**
   * Called whenever a tile has been removed.
   */
  videoTileWasRemoved = (tileId) => {
    log("videoTileWasRemoved", { tileId });
    this.videoInputController?.videoTileWasRemoved(tileId);
    this.__onVideoTileChanged({ tileId });
  };

  /**
   * Called when the media stats are available.
   * @param {import('amazon-chime-sdk-js').ClientMetricReport} clientMetricReport
   */
  metricsDidReceive = (clientMetricReport) => {
    // log("metricsDidReceive", { clientMetricReport });

    // get up/download metrics
    let uploadMetrics = {},
      downloadMetrics = {};
    const metricsReport = clientMetricReport.getObservableVideoMetrics();
    for (const deviceId in metricsReport) {
      for (const streamId in metricsReport[deviceId]) {
        if (metricsReport[deviceId][streamId]?.videoUpstreamBitrate) {
          uploadMetrics = metricsReport[deviceId][streamId];
        } else if (metricsReport[deviceId][streamId]?.videoDownstreamBitrate) {
          downloadMetrics = metricsReport[deviceId][streamId];
        }
      }
    }

    // store packet loss data
    if ("videoUpstreamPacketLossPercent" in uploadMetrics) {
      this.videoUpstreamPacketLossPercent = uploadMetrics.videoUpstreamPacketLossPercent || 0;
    }
    if ("videoDownstreamPacketLossPercent" in downloadMetrics) {
      this.videoDownstreamPacketLossPercent = downloadMetrics.videoDownstreamPacketLossPercent || 0;
    }

    const metricReport = clientMetricReport.getObservableMetrics();
    this.availableIncomingBitrate = metricReport.availableIncomingBitrate || 0;
    this.availableOutgoingBitrate = metricReport.availableOutgoingBitrate || 0;

    this.videoInputController.__autoSetVideoInputQuality();
  };

  _onReconnectingCbs = [];
  __onReconnecting(errorMsg = "", eventName = "") {
    this._onReconnectingCbs.forEach((cb) => cb(errorMsg, eventName));
  }
  /**
   * @param {(recentHistory:{}[])=>void} cb
   */
  onReconnecting(cb) {
    this._onReconnectingCbs.push(cb);
  }
  /**
   * Called when the session is connecting or reconnecting
   */
  audioVideoDidStartConnecting = (reconnecting) => {
    log("audioVideoDidStartConnecting", { reconnecting });
    reconnecting && this.__onReconnecting(this.meetingSession.eventController.recentHistory);
  };

  /**
   * Called when the session has started.
   */
  audioVideoDidStart = async (...args) => {
    log("audioVideoDidStart", this._audioVideoDidStart, ...args);
    await Promise.all(this._audioVideoDidStart.map((cb) => cb()));
    await this.startAllDevices();
  };
  _audioVideoDidStart = [];
  onAudioVideoDidStart(cb) {
    this._audioVideoDidStart.push(cb);
  }

  /**
   * Called when the session has stopped from a started state with the reason
   * provided in the status. See documentation for [[MeetingSessionStatus]]
   * and [[MeetingSessionStatusCode]] for more information.
   * @param {import('amazon-chime-sdk-js').MeetingSessionStatus} sessionStatus
   */
  audioVideoDidStop = async (sessionStatus) => {
    log("audioVideoDidStop", sessionStatus.statusCode());
    this._audioVideoDidStop.forEach((cb) => cb(sessionStatus));
  };
  /** @type {((sessionStatus: import('amazon-chime-sdk-js').MeetingSessionStatus)=>void)[]} */
  _audioVideoDidStop = [];
  /** @param {(sessionStatus: import('amazon-chime-sdk-js').MeetingSessionStatus)=>void} cb */
  onAudioVideoDidStop(cb) {
    this._audioVideoDidStop.push(cb);
  }

  /**
   * Called when connection health has changed.
   * @param {import('amazon-chime-sdk-js').ConnectionHealthData} connectionHealthData
   */
  connectionHealthDidChange = (connectionHealthData) => {
    // log("connectionHealthDidChange", { connectionHealthData });
  };

  /**
   * Called when the connection has been poor for a while if meeting only uses audio.
   */
  connectionDidBecomePoor = () => {
    log("connectionDidBecomePoor", {});
  };

  /**
   * Called when the connection has been poor if meeting uses video so that the observer
   * can prompt the user about turning off video.
   */
  connectionDidSuggestStopVideo = () => {
    log("connectionDidSuggestStopVideo", {});
  };

  /**
   * Called when connection has changed to good from poor. This will be fired regardless whether the meeting
   * is audio-only or uses audio video.
   */
  connectionDidBecomeGood = () => {
    log("connectionDidBecomeGood", {});
  };

  /**
   * Called when a user tries to start a video but by the time the backend processes the request,
   * video capacity has been reached and starting local video is not possible. This can be used to
   * trigger a message to the user about the situation.
   */
  videoSendDidBecomeUnavailable = () => {
    log("videoSendDidBecomeUnavailable", {});
  };

  /**
   * Called when the remote video sending sources get changed.
   */
  remoteVideoSourcesDidChange = (videoSources) => {
    log("remoteVideoSourcesDidChange", { videoSources });
  };

  /**
   * Called when simulcast is enabled and simulcast uplink encoding layers get changed.
   */
  encodingSimulcastLayersDidChange = (simulcastLayers) => {
    log("encodingSimulcastLayersDidChange", {
      simulcastLayers,
    });
  };

  /**
   * This observer callback will only be called for attendees in Replica meetings.
   *
   * Indicates that the client is no longer authenticated to the Primary meeting
   * and can no longer share media. `status` will contain a `MeetingSessionStatusCode` of the following:
   *
   * * `MeetingSessionStatusCode.OK`: `demoteFromPrimaryMeeting` was used to remove the attendee.
   * * `MeetingSessionStatusCode.AudioAuthenticationRejected`: `chime::DeleteAttendee` was called on the Primary
   *   meeting attendee used in `promoteToPrimaryMeeting`.
   * * `MeetingSessionStatusCode.SignalingBadRequest`: Other failure, possibly due to disconnect
   *   or timeout. These failures are likely retryable. Any disconnection will trigger an automatic
   *   demotion to avoid unexpected or unwanted promotion state on reconnection.
   */
  audioVideoWasDemotedFromPrimaryMeeting = (status) => {
    log("audioVideoWasDemotedFromPrimaryMeeting", {
      status,
    });
  };

  /**
   * Called when audio inputs are changed.
   * @param {MediaDeviceInfo[]} freshAudioInputDeviceList
   */
  audioInputsChanged(freshAudioInputDeviceList) {}

  /**
   * Called when audio outputs are changed.
   * @param {MediaDeviceInfo[]} freshAudioOutputDeviceList
   */
  audioOutputsChanged(freshAudioOutputDeviceList) {}

  /**
   * Called when video inputs are changed.
   * @param {MediaDeviceInfo[]} freshVideoInputDeviceList
   */
  videoInputsChanged(freshVideoInputDeviceList) {}

  /**
   * Called when the selected input device is indicated by the browser to be muted or unmuted
   * at the operating system or hardware level, and thus the SDK will be unable to send audio
   * regardless of the application's own mute state.
   *
   * This method will always be called after a device is selected or when the mute state changes
   * after selection.
   *
   * If the selected input device is a `MediaStream`, it will be passed here as
   * the value of `device`. Otherwise, the selected device ID will be provided.
   *
   * @param {string | MediaStream} device the currently selected audio input device.
   * @param {boolean} muteState whether the input device is known to be muted.
   */
  audioInputMuteStateChanged(device, muteState) {}

  /**
   * Called when the current audio input media stream ended event triggers.
   * @param {string} deviceId
   */
  audioInputStreamEnded(deviceId) {
    console.warn("audioInputStreamEnded", { deviceId });
  }

  /**
   * Called when the current video input media stream ended event triggers.
   * @param {string} deviceId
   */
  videoInputStreamEnded(deviceId) {
    console.warn("videoInputStreamEnded", { deviceId });
  }

  // event listeners

  _onVideoTileChangedCbs = [];
  /**
   * @param {{userId:string,active:boolean,isContentTile:boolean}} params
   * @param {()=>void} _cb
   */
  __onVideoTileChanged(params, _cb) {
    this._onVideoTileChangedCbs.forEach((cb) => cb(params, _cb));
  }
  /**
   * @param {(params:{userId:string,active:boolean,isContentTile:boolean},cb:()=>void)=>void} cb
   */
  onVideoTileChanged(cb) {
    this._onVideoTileChangedCbs.push(cb);
  }
}
