import React from "react";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { getTimeZoneOptions } from "utils/getTimeZoneOptions";

const TimezonePicker = (props) => {
  const {
    timezone,
    onTimezoneChange,
    classes,
    outlined,
    fullWidth,
    InputLabelProps,
    label,
    ...rest
  } = props;
  return (
    <div>
      <FormControl
        variant={outlined ? "outlined" : "standard"}
        className={classes.selectInput}
        fullWidth={fullWidth}
      >
        <TextField
          select
          value={timezone}
          onChange={onTimezoneChange}
          label={label ? label : "Time Zone"}
          fullWidth={fullWidth}
          InputLabelProps={InputLabelProps}
          {...rest}
        >
          <MenuItem value="" disabled />
          {getTimeZoneOptions().map(({ label, value }, index) => {
            return (
              <MenuItem key={index} value={value}>
                {label}
              </MenuItem>
            );
          })}
        </TextField>
      </FormControl>
    </div>
  );
};

export default TimezonePicker;
