import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import Lottie from "react-lottie";
import * as CardPointPrompt from "../../../lottieAnimations/fingerPointGesture.json";
import * as CardSuccessData from "../../../lottieAnimations/cardSuccess.json";

const PromptActivity = (props) => {
  const {
    classes,
    promptActivityData,
    showPromptSet,
    promptActivityStarted,
    clientPromptResponse,
    showPrompt,
    promptSelectionCorrect,
    clinicianInControl,
    onSelectPromptAnswer,
    promptShakeAnimation,
    firstPromptAttemptCorrect,
    isCaregiver,
  } = props;

  const renderCardStyle = (card) => {
    const { prompt_level } = promptActivityData;
    let styleObj = {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      overflow: "hidden",
      padding: 5,
    };
    if (prompt_level === "grow" && showPrompt) {
      switch (promptActivityData.field_size) {
        case 1:
          return {
            ...styleObj,
            width: "60%",
            height: "60%",
          };
        case 2:
          return {
            ...styleObj,
            width: "40%",
            height: card.correct ? "80%" : "50%",
          };
        case 3:
          return {
            ...styleObj,
            width: "30%",
            height: card.correct ? "80%" : "50",
          };
        case 4:
          return {
            ...styleObj,
            width: "40%",
            height: card.correct ? "60%" : "35%",
          };
        case 5:
          return {
            ...styleObj,
            width: "30%",
            height: card.correct ? "60%" : "35%",
          };
        case 6:
          return {
            ...styleObj,
            width: "30%",
            height: card.correct ? "60%" : "35%",
          };
        case 7:
          return {
            ...styleObj,
            width: card.correct ? "31%" : "22%",
            height: card.correct ? "60%" : "35%",
          };
        case 8:
          return {
            ...styleObj,
            width: card.correct ? "31%" : "22%",
            height: card.correct ? "60%" : "35%",
          };
        default:
          return;
      }
    } else {
      switch (promptActivityData.field_size) {
        case 1:
          return {
            ...styleObj,
            width: "60%",
            height: "60%",
          };
        case 2:
          return {
            ...styleObj,
            width: "40%",
            height: "60%",
          };
        case 3:
          return {
            ...styleObj,
            width: "30%",
            height: "60%",
          };
        case 4:
          return {
            ...styleObj,
            width: "40%",
            height: "45%",
          };
        case 5:
          return {
            ...styleObj,
            width: "30%",
            height: "45%",
          };
        case 6:
          return {
            ...styleObj,
            width: "30%",
            height: "45%",
          };
        case 7:
          return {
            ...styleObj,
            width: "24%",
            height: "45%",
          };
        case 8:
          return {
            ...styleObj,
            width: "24%",
            height: "45%",
          };
        default:
          return;
      }
    }
  };
  let selection = clientPromptResponse ? clientPromptResponse.cardId : null;
  let correctSelection = clientPromptResponse ? clientPromptResponse.correct : null;
  const defaultLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: CardPointPrompt,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const pointPromptLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: CardPointPrompt,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const successLottieOptions = {
    loop: false,
    autoplay: true,
    animationData: CardSuccessData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const renderPromptActivity = () => {
    if (isCaregiver) {
      return renderClientActivity();
    } else {
      return renderClinicianActivity();
    }
  };
  const renderClinicianActivity = () => {
    return (
      <>
        {promptActivityStarted ? (
          <div className={classes.promptActivityContainer}>
            {promptActivityData &&
              promptActivityData.cards &&
              promptActivityData.cards.map((card) => {
                const { prompt_level } = promptActivityData;
                return (
                  <div key={card.id} style={{ position: "relative", ...renderCardStyle(card) }}>
                    {promptActivityData.prompt_level === "point" &&
                      card.correct &&
                      !correctSelection &&
                      showPrompt && (
                        <div className={classes.pointPromptContainer}>
                          <Lottie options={defaultLottieOptions} />
                        </div>
                      )}

                    <img
                      src={card.url}
                      style={{
                        height: "100%",
                        width: "auto",
                        objectFit: "contain",
                        borderRadius: 6,
                        overflow: "hidden",
                        opacity: card.correct || prompt_level !== "fade" || !showPrompt ? 1 : 0.4,
                        border:
                          selection && selection === card.id
                            ? card.correct
                              ? "5px solid #1de9b6"
                              : "5px solid #ff0000"
                            : null,
                      }}
                    />

                    {card.correct && !correctSelection && (
                      <div className={classes.promptActivityAnswer} />
                    )}
                  </div>
                );
              })}
          </div>
        ) : (
          clinicianInControl && (
            <>
              <div style={{ height: 55 }} />
              <Button onClick={showPromptSet}>Begin Activity</Button>
            </>
          )
        )}
        <div className={classes.activityControlsContainer}>
          {clientPromptResponse && (
            <Typography
              component="h1"
              style={{
                fontWeight: 500,
                fontSize: 20,
                color: clientPromptResponse.correct ? "#1de9b6" : "#ff0000",
              }}
            >
              {clientPromptResponse.correct ? "Correct!" : "Incorrect"}
            </Typography>
          )}
          {clinicianInControl && (
            <Button
              className={classes.activityButtonNext}
              aria-label="next card"
              onClick={showPromptSet}
              endIcon={<SkipNextIcon color="inherit" />}
            >
              <Typography
                component="p"
                style={{
                  fontWeight: 500,
                  textTransform: "none",
                  justifySelf: "flex-end",
                }}
              >
                Next
              </Typography>
            </Button>
          )}
        </div>
      </>
    );
  };

  const renderClientActivity = () => {
    return (
      <div
        className={
          promptShakeAnimation
            ? classes.promptShakeAnimation
            : classes.clientPromptActivityContainer
        }
      >
        {promptActivityData &&
          promptActivityData.cards &&
          promptActivityData.cards.map((card) => {
            const { prompt_level } = promptActivityData;
            return (
              <div
                key={card.id}
                style={{
                  position: "relative",
                  transition: "height 0.7s ease, width 0.7s ease, opacity 0.7s ease",
                  ...renderCardStyle(card),
                }}
              >
                {promptActivityData.prompt_level === "point" &&
                  card.correct &&
                  !promptSelectionCorrect &&
                  showPrompt && (
                    <div
                      onClick={
                        promptSelectionCorrect === null ? () => onSelectPromptAnswer(card) : null
                      }
                      className={classes.pointPromptContainer}
                    >
                      <Lottie options={pointPromptLottieOptions} />
                    </div>
                  )}
                {card.correct || promptSelectionCorrect === null ? (
                  <img
                    onClick={
                      promptSelectionCorrect === null ? () => onSelectPromptAnswer(card) : null
                    }
                    className={
                      promptSelectionCorrect === null &&
                      promptActivityData.prompt_level === "fade" &&
                      showPrompt &&
                      !card.correct
                        ? classes.fadePrompt
                        : null
                    }
                    src={card.url}
                    style={{
                      height: "100%",
                      width: "auto",
                      objectFit: "contain",
                      borderRadius: 6,
                      overflow: "hidden",
                      cursor: promptSelectionCorrect === null ? "pointer" : "default",
                    }}
                  />
                ) : (
                  <div />
                )}
              </div>
            );
          })}
        {promptSelectionCorrect && firstPromptAttemptCorrect && (
          <div className={classes.successAnimationContainer}>
            <Lottie options={successLottieOptions} />
          </div>
        )}
      </div>
    );
  };
  return renderPromptActivity();
};

export default PromptActivity;
