import React, { useEffect, useState } from "react";
import { Grid, Typography, Button, Paper, Box, Stack, TextField } from "@mui/material";
import { useTheme } from "@mui/styles";
import moment from "moment";
import {
  VerificationOfBenefitsConfig,
  VerificationOfBenefitsMedicaidConfig,
} from "./VerificationOfBenefitsConfig";

const VerificationsOfBenefitsList = ({
  vobList,
  editMode,
  userPermissions,
  dataPanel,
  updateTitlePanel,
  copyToDataPanel,
  setConfigPanel,
  setIsPanelOpen,
  updateDataPanel,
  updateInputsForm,
  onChange,
  inputForms,
  insurancesId,
  moveToTop,
}) => {
  const theme = useTheme();

  const [viewMoreOngoing, setViewMoreOngoing] = useState(false);

  const displayedVobs = viewMoreOngoing ? vobList : vobList && vobList.slice(0, 3);

  const toggleViewMoreVOB = () => {
    setViewMoreOngoing(!viewMoreOngoing);
  };

  const renderAuthorizationItem = (vob, index) => (
    <Paper
      elevation={0}
      key={index}
      sx={{
        width: "100%",
        padding: "16px 24px",
        marginTop: "16px",
        border: vob.id === dataPanel?.id ? "1.5px solid #8E42CA" : "1px solid #e0e0e0",
        borderRadius: "6px",
      }}
    >
      <Grid container alignItems="center" justify="space-between" spacing={3}>
        <Grid item xs>
          <Typography variant="subtitle1">
            {} {moment(vob.created_at).format("MMMM Do, YYYY")}
          </Typography>
          <Typography variant="body2" color={(theme) => theme.palette.text.secondary}>
            {` ${vob.plan_name} | ${vob.user_name}`}
          </Typography>
        </Grid>
        {vob.id !== dataPanel?.id && (
          <Grid item>
            <Button
              variant="text"
              onClick={() => {
                updateTitlePanel(moment(vob?.created_at).format("MMMM Do, YYYY"));
                setIsPanelOpen(true);
                copyToDataPanel(vob);
                const insurance = insurancesId.find(
                  (insurance) => insurance.id === vob.insurance_plan_id
                );
                if (editMode && userPermissions?.add_client_insurance_authorization) {
                  updateInputsForm(
                    inputForms(
                      { ...vob },
                      insurancesId,
                      updateDataPanel,
                      insurance?.medicaid ?? null,
                      onChange
                    )
                  );
                } else {
                  setConfigPanel(
                    insurance?.medicaid
                      ? VerificationOfBenefitsMedicaidConfig
                      : VerificationOfBenefitsConfig
                  );
                }
                moveToTop();
              }}
            >
              {`${editMode && userPermissions?.edit_verification_of_benefits ? "Edit" : "View"}`}
            </Button>
          </Grid>
        )}
      </Grid>
    </Paper>
  );

  const additionalVOB = vobList.length - displayedVobs.length;

  return (
    <>
      {displayedVobs && displayedVobs.length > 0
        ? displayedVobs.map(renderAuthorizationItem)
        : !editMode && (
            <Typography
              variant={"body2"}
              color={(theme) => theme.palette.text.secondary}
              style={{ marginTop: "8px" }}
            >
              No previous verifications
            </Typography>
          )}
      {vobList && vobList.length > 3 && (
        <Button
          sx={{ marginTop: "16px" }}
          onClick={toggleViewMoreVOB}
          variant="text"
          color="primary"
        >
          {viewMoreOngoing ? "View Less" : `View ${additionalVOB} More`}
        </Button>
      )}
    </>
  );
};

export default VerificationsOfBenefitsList;
