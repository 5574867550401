import React from "react";
import { CustomTable } from "elements/DEPRECATED_elements";
import { CustomHeaderCell } from "elements/DEPRECATED_elements";
import { differenceInDays, format } from "date-fns";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import StatusPill from "./StatusPill";
import DueDate from "./DueDate";
import {
  Typography,
  Checkbox,
  Link,
  Menu,
  MenuItem,
  IconButton,
  TableCell,
  TableRow,
  ListItemIcon,
} from "@mui/material";
import { formatNumberByDecimal } from "utils/formatNumberByDecimal";
import {
  Eye,
  Pen,
  PencilSimpleLine,
  PauseCircle,
  ArrowRight,
  ArrowArcLeft,
} from "@phosphor-icons/react";
import ANTooltip from "elements/ANTooltip";
import InfoIconOutlined from "@mui/icons-material/InfoOutlined";

const BillingItemsTable = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [noteData, setNoteData] = React.useState({});

  const handleOpenMenu = (event, note) => {
    setAnchorEl(event.currentTarget);
    setNoteData(note);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setNoteData(null);
  };

  const {
    classes,
    rows,
    page,
    rowsPerPage,
    count,
    clearFilters,
    handleChangePage,
    handleChangeRowsPerPage,
    openSessionNoteDialog,
    navToClientDetails,
    userId,
    userPermissions,
    onChangeSelectedRow,
    onChangeSelectAllRows,
    selectedRows,
    allRowsSelected,
    onHoldRelaseBillingItem,
    allRowsIndeterminate,
    onRevertToInProgress,
  } = props;
  const canEdit =
    noteData?.end_date &&
    differenceInDays(new Date(), new Date(noteData?.video_call_end_date ?? noteData?.end_date)) < 7;
  return (
    <CustomTable
      count={count ? parseInt(count) : 0}
      rowsPerPage={rowsPerPage}
      page={page}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      clearFilters={clearFilters}
      headerCells={[
        <TableCell padding="checkbox" sx={{ width: "fit-content" }}>
          <div className={classes.tableCellCheckboxContainer}>
            {userPermissions?.approve_off_platform_billing ? (
              <Checkbox
                onChange={onChangeSelectAllRows}
                checked={allRowsSelected}
                indeterminate={allRowsIndeterminate}
              />
            ) : (
              <div style={{ width: 20 }} />
            )}
            Client
          </div>
        </TableCell>,
        <CustomHeaderCell label="Session Date" />,
        <CustomHeaderCell label="Service Type" />,
        userPermissions?.view_all_clients_billing_items && <CustomHeaderCell label="BCBA" />,
        <CustomHeaderCell label="Billable Time (min)" />,
        <CustomHeaderCell label="Status" />,
        <CustomHeaderCell label="Due Date" />,
        <CustomHeaderCell label="Actions" align="center" />,
      ]}
    >
      {rows.length > 0 &&
        rows.map((row, i) => (
          <TableRow
            key={i}
            style={
              selectedRows?.some((time) => time.billable_time_id == row.billable_time_id)
                ? { backgroundColor: "rgba(142, 66, 202, 0.08)" }
                : null
            }
          >
            {/* Client */}
            <TableCell padding="checkbox" sx={{ width: "fit-content" }}>
              <div className={classes.tableCellCheckboxContainer}>
                {userPermissions?.approve_off_platform_billing ? (
                  <Checkbox
                    onChange={(e) => onChangeSelectedRow(e, row)}
                    checked={selectedRows?.some(
                      (time) => time.billable_time_id == row.billable_time_id
                    )}
                  />
                ) : (
                  <div style={{ width: 20 }} />
                )}
                {row.client_name?.trim() ? (
                  <Link onClick={() => navToClientDetails(row.client_id)}>{row.client_name}</Link>
                ) : (
                  <Typography component="p" variant="body2">
                    N/A
                  </Typography>
                )}
              </div>
            </TableCell>
            <TableCell align="left">
              <Typography component="p" variant="body2">
                {row.start_date ? format(new Date(row.start_date), "P") : ""}
              </Typography>
            </TableCell>
            {/* Service Type */}
            <TableCell align="left">
              <Typography component="p" variant="body2">
                {row.service_type || "None Assigned"}
              </Typography>
            </TableCell>
            {/* BCBA */}
            {userPermissions?.view_all_clients_billing_items && (
              <TableCell align="left">
                <Typography component="p" variant="body2">
                  {row.clinician_name}
                </Typography>
              </TableCell>
            )}
            {/* Billable Time (min) */}
            <TableCell align="right">
              <Typography style={{ textAlign: "left" }} component="p" variant="body2">
                {formatNumberByDecimal(row.billable_time, 3)}
              </Typography>
            </TableCell>
            {/* Status */}
            <TableCell align="center" style={{ color: "red" }}>
              <StatusPill {...row} userPermissions={userPermissions} />
            </TableCell>
            {/* Due Date */}
            <TableCell align="center" style={{ color: "red" }}>
              <DueDate {...row} classes={classes} />
            </TableCell>
            {/* Action */}
            <TableCell align="center">
              {(row.in_progress || row.note_completed || userId === row.clinician_user_id) && (
                <IconButton
                  color="inherit"
                  aria-label="Open Action Menu"
                  aria-haspopup="true"
                  onClick={(e) => handleOpenMenu(e, row)}
                  size="small"
                >
                  <MoreVertIcon />
                </IconButton>
              )}
            </TableCell>
          </TableRow>
        ))}
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={1}
      >
        {noteData?.note_completed &&
        !noteData?.approved &&
        !noteData?.needs_edits &&
        userPermissions?.approve_off_platform_billing ? (
          <MenuItem
            onClick={() => {
              handleClose();
              openSessionNoteDialog(noteData, true);
            }}
          >
            <ListItemIcon>
              <Pen />
            </ListItemIcon>
            Review
          </MenuItem>
        ) : (
          <MenuItem
            onClick={() => {
              handleClose();
              openSessionNoteDialog(noteData, true);
            }}
          >
            <ListItemIcon>
              <Eye />
            </ListItemIcon>
            View
          </MenuItem>
        )}
        {userId == noteData?.clinician_user_id && !noteData?.locked && (
          <ANTooltip
            title="Notes are locked after 7 days"
            disableHoverListener={canEdit}
            component={Typography}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                openSessionNoteDialog(noteData, false);
              }}
              disabled={!canEdit}
            >
              <ListItemIcon>
                <PencilSimpleLine />
              </ListItemIcon>
              Edit
            </MenuItem>
          </ANTooltip>
        )}
        {userPermissions?.approve_off_platform_billing &&
          noteData?.note_completed &&
          noteData?.on_hold && (
            <MenuItem
              onClick={() => {
                handleClose();
                onHoldRelaseBillingItem(noteData, "released");
              }}
            >
              <ListItemIcon>
                <ArrowRight />
              </ListItemIcon>
              Release
            </MenuItem>
          )}
        {userPermissions?.approve_off_platform_billing &&
          noteData?.note_completed &&
          !noteData?.on_hold &&
          !noteData.enter_health_id && (
            <MenuItem
              onClick={() => {
                handleClose();
                onHoldRelaseBillingItem(noteData, "on hold");
              }}
            >
              <ListItemIcon>
                <PauseCircle />
              </ListItemIcon>
              Hold
            </MenuItem>
          )}
        {userPermissions?.approve_off_platform_billing &&
          noteData?.note_completed &&
          !noteData?.enter_health_id &&
          differenceInDays(new Date(), new Date(noteData?.end_date)) < 7 && (
            <MenuItem
              onClick={() => {
                handleClose();
                onRevertToInProgress(noteData.id);
              }}
            >
              <ListItemIcon>
                <ArrowArcLeft />
              </ListItemIcon>
              Back to In Progress
            </MenuItem>
          )}
      </Menu>
    </CustomTable>
  );
};

export default BillingItemsTable;
