import React from "react";
import { Stack } from "@mui/material";

const ANFormRow = ({ children, panelMode }) => {
  return (
    <Stack
      direction={{ xs: "column", sm: "row" }}
      alignItems="flex-start"
      justifyContent="space-between"
      spacing={5}
      sx={{ "&:not(:last-child)": { mb: panelMode ? 5 : 7 }, width: "100%" }}
    >
      {children}
    </Stack>
  );
};

export default ANFormRow;
