import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Radio,
  FormGroup,
  Typography,
  DialogActions,
  Button,
  Stack,
} from "@mui/material";
import { X } from "@phosphor-icons/react";
import { LoadingButton } from "@mui/lab";
import { useTheme } from "@mui/styles";
import { useFormik } from "formik";
import {
  useClientTherapyHours,
  useUpdateClientTherapyHours,
} from "../Sections/ClientAvailability/hooks";
import { useGlobalToast } from "components/GlobalToastProvider";

const RecommendedTherapyHoursDialog = ({ clientId, open, onClose }) => {
  const { showToast } = useGlobalToast();
  const theme = useTheme();

  const { mutate: saveTherapyHours, isLoading } = useUpdateClientTherapyHours({
    onSuccess: () => {
      onClose();
      showToast({ message: "Success" });
    },
    onError: () => {
      showToast({ message: "Whoops! Something went wrong", isError: true });
    },
  });

  const formik = useFormik({
    initialValues: {
      assessment_hours: 0,
      direct_therapy_hours: 0,
      protocol_modification_hours: 0,
      caregiver_training_hours: 0,
      protocol_modification_type: "",
    },
    onSubmit: (values) => {
      saveTherapyHours({ newTherapyHours: values, clientId });
    },
  });

  const { isLoading: therapyHoursLoading } = useClientTherapyHours({
    clientId: clientId,
    onSuccess: ({ data }) => {
      formik.setValues(data?.hours || {});
    },
  });

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Preliminary Therapy Hours Recommendation
        <IconButton aria-label="close" onClick={onClose} disabled={isLoading}>
          <X />
        </IconButton>
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent dividers>
          <Stack gap={theme.spacing(5)}>
            <Typography variant="body2" color="text.secondary">
              Configure the recommended therapy hours for each therapy service.
            </Typography>
            <TextField
              label="97151 - Assessment Hours"
              type="number"
              placeholder="0 hours each authorization period"
              {...formik.getFieldProps("assessment_hours")}
              helperText="Hours each 6 month authorization period"
              fullWidth
            />
            <TextField
              label="97153 - Direct Therapy Hours"
              type="number"
              placeholder="0 hours each week"
              {...formik.getFieldProps("direct_therapy_hours")}
              helperText="Hours per week"
              fullWidth
            />
            <TextField
              label="97155 - Protocol Modification Hours"
              type="number"
              placeholder="0 hours each week"
              {...formik.getFieldProps("protocol_modification_hours")}
              helperText="Hours per week"
              fullWidth
            />
            <FormGroup>
              <FormLabel>
                <Typography variant="inputLabel" component="label">
                  Protocol Modification Type
                </Typography>
              </FormLabel>
              <RadioGroup
                {...formik.getFieldProps("protocol_modification_type")}
                orientation="vertical"
              >
                <FormControlLabel
                  value="DIRECT"
                  control={<Radio size="small" />}
                  label={<Typography variant="body2">Direct Modification</Typography>}
                />
                <FormControlLabel
                  value="INDIRECT"
                  control={<Radio size="small" />}
                  label={<Typography variant="body2">Indirect Modification</Typography>}
                />
              </RadioGroup>
            </FormGroup>
            <TextField
              label="97156 - Caregiver Training Hours"
              type="number"
              placeholder="0 hours each week"
              {...formik.getFieldProps("caregiver_training_hours")}
              helperText="Hours per week"
              fullWidth
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={onClose} disabled={isLoading}>
            Cancel
          </Button>
          <LoadingButton type="submit" loading={isLoading || therapyHoursLoading}>
            Submit Recommendations
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default RecommendedTherapyHoursDialog;
