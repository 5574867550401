import React from "react";
import { Typography } from "@mui/material";
import { formatToTimeZone } from "date-fns-timezone";

const CaregiverSignature = (props) => {
  const { classes, billableItem, startDate, endDate, timezone } = props;

  return billableItem?.start_signature_url ? (
    <div className={classes.signatureContainer}>
      <div className={classes.signatureTitleContainer}>
        <Typography variant="h4">Designated Caregiver Signature</Typography>
      </div>
      <div className={classes.disabledSignatureCanvasContainer}>
        <img className={classes.signatureImage} src={billableItem.start_signature_url} />
      </div>
      <Typography
        component="p"
        variant="body2"
        color="text.secondary"
        style={{ fontStyle: "italic" }}
      >
        {`The designated caregiver confirms that the video session took place on 
        ${formatToTimeZone(startDate, "MMMM D, YYYY", {
          timeZone: timezone,
        })} from ${formatToTimeZone(startDate, "h:mm A", {
          timeZone: timezone,
        })} - ${formatToTimeZone(endDate, "h:mm A z", {
          timeZone: timezone,
        })} `}
      </Typography>
    </div>
  ) : null;
};

export default CaregiverSignature;
