import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withStyles from "@mui/styles/withStyles";
import styles from "./styles";
import Typography from "@mui/material/Typography";
import SignatureCanvas from "react-signature-canvas";
import { format, addYears } from "date-fns";
import Logo from "../../assets/an_purple_gray.png";
import actions from "../../actions";
import {
  getUserPhysicians,
  getUserDetails,
  getChildDetails,
  getUserCognitoId,
  getUserId,
  policiesReadAndAccepted,
  getParentDocumentRequests,
  getClientId,
} from "../../selectors";
import * as AWS from "aws-sdk";
import { dateWithoutTimezone } from "../../utils/dateWithoutTimezone";
import Modal from "elements/Modal";
import { getAWSCredentialsForCurrentUserSession } from "utils/aws";

const mapStateToProps = (state) => ({
  userId: getUserId(state),
  clientId: getClientId(state),
  documentRequests: getParentDocumentRequests(state),
  physicians: getUserPhysicians(state),
  userDetails: getUserDetails(state),
  childDetails: getChildDetails(state),
  userCognitoId: getUserCognitoId(state),
  policiesReadAndAccepted: policiesReadAndAccepted(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUnsignedDocumentRequests: actions.getUnsignedDocumentRequests,
      signDocumentRequest: actions.signDocumentRequest,
      getUserPhysicians: actions.getCustomerPhysicians,
    },
    dispatch
  );

class DocumentRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      signatureStarted: false,
      saveSignatureSuccess: false,
      saveSignatureError: false,
      loading: false,
    };
    this.signatureRef = null;
  }

  componentDidMount() {
    if (this.props.clientId) {
      this.props.getUnsignedDocumentRequests();
      this.props.getUserPhysicians(this.props.clientId);
    }
    if (this.props.documentRequests.length > 0) {
      this.setState({ open: true });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.clientId && this.props.clientId) {
      this.props.getUnsignedDocumentRequests();
      this.props.getUserPhysicians(this.props.clientId);
    }
    if (
      prevProps.documentRequests.length !== this.props.documentRequests.length &&
      this.props.documentRequests.length > 0
    ) {
      this.setState({ open: true });
    }
    if (prevState.loading && !this.state.loading && this.state.saveSignatureSuccess) {
      setTimeout(() => {
        this.setState(
          {
            open: false,
            signatureStarted: false,
            saveSignatureSuccess: false,
            saveSignatureError: false,
            loading: false,
          },
          () => {
            this.props.getUnsignedDocumentRequests();
          }
        );
      }, 2000);
    }
  }

  onSubmitForm = async () => {
    this.setState({ loading: true });
    const imageData = this.signatureRef.getTrimmedCanvas().toDataURL("image/png");
    const requestId = this.props.documentRequests[0].id;
    const credentials = await getAWSCredentialsForCurrentUserSession();
    const s3 = new AWS.S3({
      credentials,
      region: "us-east-1",
      signatureVersion: "v2",
      apiVersion: "2006-03-01",
      params: { Bucket: process.env.AWS_USER_DOC_BUCKET },
    });
    const buf = new Buffer(imageData.replace(/^data:image\/\w+;base64,/, ""), "base64");
    const params = {
      ACL: "public-read",
      Body: buf,
      ContentEncoding: "base64",
      ContentType: "image/png",
      Key: `${this.props.userCognitoId}/document_request_${requestId}_signature.png`,
    };
    try {
      const s3url = await s3.upload(params).promise();
      this.props.signDocumentRequest({
        signatureURL: s3url.Location,
        requestId,
      });
      this.setState({ saveSignatureSuccess: true, loading: false });
    } catch (error) {
      console.log(error);
      this.setState({ saveSignatureError: true, loading: false });
    }
  };

  buttonDisabled = () => {
    const { loading, saveSignatureError, saveSignatureSuccess, signatureStarted } = this.state;
    if (loading || saveSignatureError || saveSignatureSuccess || !signatureStarted) {
      return true;
    }
    return false;
  };

  render() {
    const {
      classes,
      documentRequests,
      physicians,
      userDetails,
      childDetails,
      policiesReadAndAccepted,
    } = this.props;

    const { loading, saveSignatureError, saveSignatureSuccess, open } = this.state;
    if (!policiesReadAndAccepted) {
      return null;
    }
    return physicians?.length > 0 && documentRequests?.length > 0 ? (
      <Modal
        open={open}
        title={`Hi, ${userDetails.first_name}. We need additional information from your
                doctor to authorize telehealth therapy. Please review and sign the form
                below.`}
        content={
          <div
            style={{
              maxWidth: "100%",
              // width: 800,
              margin: 0,
            }}
          >
            <div className={classes.phiTitleContainer}>
              <img
                className={classes.formLogo}
                style={{ marginBottom: "0 !important" }}
                src={Logo}
                alt="AnswersNow"
                title="AnswersNow"
              />
              <Typography
                component="p"
                style={{ fontSize: 10, textAlign: "center", marginBottom: 10 }}
              >
                1717 E Cary Street, Richmond Virginia, 23223 | Telephone: 804 215 5600 Fax: 804 800
                9329
              </Typography>
              <Typography component="p" className={classes.phiTitle}>
                HIPAA AUTHORIZATION TO RELEASE PATIENT INFORMATION
              </Typography>
            </div>
            <div className={classes.phiListItemDetailContainer}>
              <Typography
                component="p"
                className={classes.phiListItemDetailLabel}
                style={{ fontWeight: 500 }}
              >
                Patient's Full Name:{"    "}
              </Typography>
              <Typography
                component="p"
                className={classes.phiListItemDetailValue}
                style={{ fontWeight: 500 }}
              >
                {userDetails?.name} {userDetails?.child_last_name}
              </Typography>
            </div>
            <div className={classes.phiListItemDetailContainer}>
              <Typography component="p" className={classes.phiListItemDetailLabel}>
                Patient's Date of Birth:{"    "}
              </Typography>
              <Typography component="p" className={classes.phiListItemDetailValue}>
                {format(dateWithoutTimezone(userDetails?.dob), "PP")}
              </Typography>
            </div>
            <div className={classes.phiListItemDetailContainer}>
              <Typography component="p" className={classes.phiListItemDetailLabel}>
                Patient's Address:
              </Typography>
              <Typography component="p" className={classes.phiListItemDetailValue}>
                {userDetails.location?.line1} {userDetails.location?.line2},{" "}
                {userDetails.location?.state} {userDetails.location?.zip}
              </Typography>
            </div>
            <div className={classes.phiListItemDetailContainer}>
              <Typography component="p" className={classes.phiListItemDetailLabel}>
                Patient's Legal Guardian/Parent:
              </Typography>
              <Typography component="p" className={classes.phiListItemDetailValue}>
                {userDetails.first_name} {userDetails.last_name}
              </Typography>
            </div>
            <div className={classes.phiListItemDetailContainer}>
              <Typography component="p" className={classes.phiListItemDetailLabel}>
                Telephone Number:
              </Typography>
              <Typography component="p" className={classes.phiListItemDetailValue}>
                {userDetails.phone_number}
              </Typography>
            </div>
            <div className={classes.divider} />
            <Typography component="p" className={classes.phiSubtitle}>
              I hereby request that AnswersNow use / disclose my protected health information (PHI)
              as directed below. Specifically, I request that my PHI:
            </Typography>
            <div className={classes.phiFormList}>
              <Typography component="p" className={classes.phiListItem}>
                1. From the Following Care Center locations and/or providers:
              </Typography>
              {physicians.map((physician) => {
                return (
                  <React.Fragment key={physician.id}>
                    <div className={classes.phiListItemDetailContainer}>
                      <Typography component="p" className={classes.phiListItemDetailLabel}>
                        Physician Name:
                      </Typography>
                      <Typography component="p" className={classes.phiListItemDetailValue}>
                        {`${physician?.first_name} ${physician?.last_name}`}
                      </Typography>
                    </div>
                    <div className={classes.phiListItemDetailContainer}>
                      <Typography component="p" className={classes.phiListItemDetailLabel}>
                        Physician Address:
                      </Typography>
                      <Typography component="p" className={classes.phiListItemDetailValue}>
                        {physician.physical_address
                          ? `${physician.physical_address}  ${physician.city}, ${physician.state} ${physician.zip_code}`
                          : "not provided"}
                      </Typography>
                    </div>
                  </React.Fragment>
                );
              })}
              <Typography component="p" className={classes.phiListItem}>
                2. Be faxed to the following person:
              </Typography>
              <Typography component="p" className={classes.phiListItemDetail}>
                AnswersNow Care Coordination team
              </Typography>
              <Typography component="p" className={classes.phiListItemDetail}>
                Fax Number: 804 800 9329
              </Typography>
              <Typography component="p" className={classes.phiListItem}>
                3. I authorize disclosure of the following specific information:
              </Typography>
              <Typography component="p" className={classes.phiListItemDetail}>
                {documentRequests[0].description}
              </Typography>

              <Typography component="p" className={classes.phiListItem}>
                4. I understand that I have the right to receive a copy of my PHI in the form and
                format and manner I request, if readily producible that way.
              </Typography>
              <Typography component="p" className={classes.phiListItem}>
                5. I understand that the information used or disclosed may be subject to
                re-disclosure by the person or class of persons or entity receiving it and would
                then no longer be protected by federal privacy regulations.
              </Typography>
              <Typography component="p" className={classes.phiListItem}>
                6. I understand I may remove this authorization by notifying AnswersNow in writing
                of my desire to revoke it.
              </Typography>
              <Typography component="p" className={classes.phiListItem}>
                7. This authorization expires on {format(new Date(addYears(new Date(), 1)), "PP")}
              </Typography>
              <div className={classes.divider} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Typography component="p" className={classes.phiListItem}>
                  Patient's Legal Guardian:
                </Typography>
                <Typography
                  component="p"
                  className={classes.phiListItem}
                  style={{ fontWeight: 500, marginLeft: 10 }}
                >
                  {userDetails.first_name} {userDetails.last_name}
                </Typography>
              </div>
              <Typography component="p" className={classes.phiListItem}>
                Signature of Patient's Legal Guardian:
              </Typography>
              <div className={classes.signatureCanvas}>
                <SignatureCanvas
                  ref={(ref) => (this.signatureRef = ref)}
                  onBegin={() => this.setState({ signatureStarted: true })}
                  penColor="#000"
                  canvasProps={{
                    width:
                      window.innerWidth < 961 ? window.innerWidth * 0.9 : window.innerWidth * 0.85,
                    height: 120,
                  }}
                />
              </div>
            </div>
          </div>
        }
        loading={loading}
        success={saveSignatureSuccess}
        successMessage=" Saved successfully."
        error={saveSignatureError}
        errorMessage="There was an issue submitting your signature. Please refresh and try again."
        primaryActionText="Submit"
        primaryActionOnClick={this.onSubmitForm}
        primaryActionDisabled={this.buttonDisabled()}
      />
    ) : null;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DocumentRequest));
