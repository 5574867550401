import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import actions from "../../actions";
import ClientTable from "./ClientTable";
import PastTable from "./PastTable";
import styles from "./styles";
import PageContainer from "elements/PageContainer";
import SectionContainer from "elements/SectionContainer";
import Button from "@mui/material/Button";
import {
  getUserId,
  getUserClinicianId,
  getClinicianClientsList,
  getClinicianPastClientsList,
  getClinicianClientsLoading,
  userLoaded,
  getUserPermissionsList,
  getUser,
} from "../../selectors";

const mapStateToProps = (state) => ({
  clinicianId: getUserClinicianId(state),
  clients: getClinicianClientsList(state),
  pastClients: getClinicianPastClientsList(state),
  clientsLoading: getClinicianClientsLoading(state),
  userLoaded: userLoaded(state),
  userId: getUserId(state),
  userPermissions: getUserPermissionsList(state),
  user: getUser(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setPageDetails: actions.setPageDetails,
      getClinicianClients: actions.getClinicianClients,
      getClinicianPastClients: actions.getClinicianPastClients,
    },
    dispatch,
  );

class MyClientsContainer extends Component {
  state = {
    currentTab: 0,
  };

  componentDidMount() {
    this.props.setPageDetails({
      pageName: "My Clients",
      currentPage: "myClients",
      menu: "clinicianClients",
    });
    if (this.props.userId) this.props.getClinicianClients();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.userId && this.props.userId) {
      if (this.state.currentTab === 0) {
        this.props.getClinicianClients();
      } else {
        this.props.getClinicianPastClients();
      }
    }
    if (prevState.currentTab !== this.state.currentTab) {
      if (this.state.currentTab === 0) {
        this.props.getClinicianClients();
      } else {
        this.props.getClinicianPastClients();
      }
    }
  }

  handleTabChange = (newValue) => {
    this.setState({ currentTab: newValue });
  };

  handleDetailsClick = (clientId) => (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.history.push(`/clients/${clientId}/dashboard`);
  };

  render() {
    const { classes, clientsLoading, userId, userPermissions } = this.props;
    const { currentTab } = this.state;
    let clients = currentTab === 0 ? [...this.props.clients] : [...this.props.pastClients];
    return (
      <PageContainer>
        <SectionContainer columnDirection loading={clientsLoading || !userId} minHeight="80vh">
          <div className={classes.titleContainer}>
            <Button
              {...(currentTab === 1 && { color: "secondary" })}
              onClick={() => this.handleTabChange(0)}
            >
              Current
            </Button>

            <Button
              {...(currentTab === 0 && { color: "secondary" })}
              onClick={() => this.handleTabChange(1)}
            >
              Past
            </Button>
          </div>
          {clients.length === 0 ? (
            <div className={classes.loading}>
              <Typography component="h1" variant="h6" align="center">
                There are no customers to display.
              </Typography>
            </div>
          ) : currentTab === 0 ? (
            <ClientTable
              classes={classes}
              handleDetailsClick={this.handleDetailsClick}
              clients={[...this.props.clients]}
              customersLoading={this.props.clientsLoading}
              userId={this.props.userId}
              userPermissions={userPermissions}
            />
          ) : (
            <PastTable
              classes={classes}
              handleDetailsClick={this.handleDetailsClick}
              clients={[...this.props.pastClients]}
              clientsLoading={this.props.clientsLoading}
              userId={this.props.userId}
              userPermissions={userPermissions}
            />
          )}
        </SectionContainer>
      </PageContainer>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MyClientsContainer));
