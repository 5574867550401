import * as yup from "yup";

const validationSchema = yup.object({
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Please enter a valid password that's at least 8 characters")
    .matches(/[A-Z]/, "Please enter a valid password that must contain at least 1 uppercase letter")
    .matches(/[a-z]/, "Please enter a valid password that must contain at least 1 lowercase letter")
    .matches(/[0-9]/, "Please enter a valid password that must contain at least 1 number"),
  confirmPassword: yup
    .string()
    .required()
    .oneOf([yup.ref("password"), null], "Passwords must match"),

  // Validate currentPassword if showCurrentPassword is true
  showCurrentPassword: yup.boolean(),
  currentPassword: yup.string().when("showCurrentPassword", {
    is: true,
    then: (schema) => schema.required("Current password is required"),
  }),
  // Validate agreeToTermsAndPrivacy if showAgreeToTerms is true
  showAgreeToTerms: yup.boolean(),
  agreeToTermsAndPrivacy: yup.boolean().when("showAgreeToTerms", {
    is: true,
    then: (schema) => schema.oneOf([true], "You must agree to the terms and privacy policy"),
  }),
});

export default validationSchema;
