import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withStyles from "@mui/styles/withStyles";
import actions from "../../actions";
import { format } from "date-fns";
import PageContainer from "elements/PageContainer";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { CustomSelect } from "elements/DEPRECATED_elements";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import SectionContainer from "elements/SectionContainer";
import Chip from "@mui/material/Chip";
import ErrorIcon from "@mui/icons-material/Error";
import DoneIcon from "@mui/icons-material/Done";
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Alert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";
import MenuItem from "@mui/material/MenuItem";

import {
  getUpdateUserRolesLoading,
  getUpdateUserRolesSuccess,
  getUpdateUserRolesFail,
  getSearchUserDetails,
  getSearchUserDetailsLoading,
  getSearchUserDetailsSuccess,
  getCustomerDeactivationReasons,
  getCustomerDepartureOptions,
  getClinicianDeactivationReasons,
  getUserDeactivationReasons,
  getToggleUserActivationLoading,
  getToggleUserActivationSuccess,
} from "../../selectors";

const mapStateToProps = (state) => ({
  updateUserRolesLoading: getUpdateUserRolesLoading(state),
  updateUserRolesSuccess: getUpdateUserRolesSuccess(state),
  updateUserRolesFail: getUpdateUserRolesFail(state),
  userDetails: getSearchUserDetails(state),
  userDetailsLoading: getSearchUserDetailsLoading(state),
  userDetailsSuccess: getSearchUserDetailsSuccess(state),
  customerDeactivationReasons: getCustomerDeactivationReasons(state),
  customerDepartureOptions: getCustomerDepartureOptions(state),
  clinicianDeactivationReasons: getClinicianDeactivationReasons(state),
  userDeactivationReasons: getUserDeactivationReasons(state),
  toggleUserActivationLoading: getToggleUserActivationLoading(state),
  toggleUserActivationSuccess: getToggleUserActivationSuccess(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUserRoles: actions.updateUserRoles,
      clearUpdateUserRolesInfo: actions.clearUpdateUserRoles,
      getUserDetails: actions.getSearchUserDetails,
      clearBasicDetails: actions.clearBasicDetails,
      toggleUserActivation: actions.toggleUserActivation,
      clearToggleUserActivationInfo: actions.clearToggleUserActivation,
      getCustomerDeactivationReasons: actions.getCustomerDeactivationReasons,
      getCustomerDepartureOptions: actions.getCustomerDepartureOptions,
      getClinicianDeactivationReasons: actions.getClinicianDeactivationReasons,
      getUserDeactivationReasons: actions.getUserDeactivationReasons,
    },
    dispatch,
  );

const roleList = [
  {
    role: "ROLE_SUPER_ADMIN",
    label: "Super Admin",
    color: "#f93a2f",
    background: "#fff7f7",
  },
  {
    role: "ROLE_ADMIN",
    label: "Admin",
    color: "#fd0061",
    background: "#fff5f9",
  },
  {
    role: "ROLE_CLINICAL_DIRECTOR",
    label: "Clinical Director",
    color: "#738adb",
    background: "#f7f9ff",
  },
  {
    role: "ROLE_CLINICAL_SUPERVISOR",
    label: "Clinical Supervisor",
    color: "#287979",
    background: "#d9f4f4",
  },
  {
    role: "ROLE_CLINICIAN",
    label: "Clinician",
    color: "#7235a2",
    background: "#f4ecfa",
  },
  {
    role: "ROLE_CUSTOMER_CARE",
    label: "Care Manager",
    color: "#a6264b",
    background: "#faeaef",
  },
  {
    role: "ROLE_CLINICIAN_VIEW_ONLY",
    label: "Clinician View Only",
    color: "#b45309",
    background: "#fffbeb",
  },
  {
    role: "ROLE_FAMILY",
    label: "Family Member",
    color: "#99771a",
    background: "#fff9ea",
  },
  {
    role: "ROLE_PARENT",
    label: "Client",
    color: "#00c09a",
    background: "#f7fffb",
  },
];

class User extends React.Component {
  constructor() {
    super();
    this.state = {
      userRoles: [],
      updateRoleDialogOpen: false,
      updateUserRolesList: [],
      buttonDisabled: true,
      activationDialogOpen: false,
      reason: "",
      note: "",
      currentUserDeactivationReasons: [],
    };
  }
  componentDidMount() {
    this.props.getCustomerDeactivationReasons();
    this.props.getCustomerDepartureOptions();
    this.props.getClinicianDeactivationReasons();
    this.props.getUserDeactivationReasons();
    if (this.props.match.params.id) {
      this.props.getUserDetails(this.props.match.params.id);
    }
    if (this.props.userDetails && this.props.userDetails.authority) {
      this.setDeactivationReasons();
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.props.getUserDetails(this.props.match.params.id);
    }
    if (prevProps.userDetailsLoading && !this.props.userDetailsLoading && this.props.userDetails) {
      if (this.props.userDetails.authority) {
        let userRoles = roleList.filter((role) =>
          this.props.userDetails?.authority.some((userRole) => userRole == role.role),
        );
        this.setState({ userRoles });
        this.setDeactivationReasons();
      }
    }

    if (
      prevProps.updateUserRolesLoading &&
      !this.props.updateUserRolesLoading &&
      this.props.updateUserRolesSuccess
    ) {
      if (this.props.updateUserRolesSuccess) {
        setTimeout(() => {
          this.onCloseUpdateRoleDialog();
          this.props.getUserDetails(this.props.match.params.id);
        }, 2000);
      }
    }
    if (
      prevState.activationDialogOpen &&
      !this.state.activationDialogOpen &&
      this.props.toggleUserActivationSuccess
    ) {
      this.props.getUserDetails(this.props.match.params.id);
    }
  }

  onOpenUpdateRoleDialog = () => {
    const { userDetails } = this.props;
    this.setState({
      updateRoleDialogOpen: true,
      updateUserRolesList: userDetails.authority,
    });
  };

  onCloseUpdateRoleDialog = () => {
    this.setState({
      updateRoleDialogOpen: false,
      updateUserRolesList: [],
      buttonDisabled: true,
    });
    this.props.clearUpdateUserRolesInfo();
  };

  currentRolesDisabled = (role) => {
    return this.state.updateUserRolesList.includes(role);
  };

  otherRolesDisabled = (role) => {
    return !this.state.updateUserRolesList.includes(role);
  };

  onChangeUserRole = (role) => {
    const roles = [...this.state.updateUserRolesList];
    roles.push(role);
    this.setState({ updateUserRolesList: roles, buttonDisabled: false });
  };

  onDeleteUserRole = (role) => {
    let roles = [...this.state.updateUserRolesList];
    if (roles.length > 1) {
      const roleIndex = roles.indexOf(role);
      roles.splice(roleIndex, 1);
      this.setState({ updateUserRolesList: roles, buttonDisabled: false });
    }
  };

  onSubmitUpdateRoles = () => {
    const userRoles = {
      roles: this.state.updateUserRolesList,
      userId: this.props.userDetails.id,
    };
    this.props.updateUserRoles(userRoles);
  };

  setDeactivationReasons = () => {
    if (this.props.userDetails.authority.includes("ROLE_CLINICIAN")) {
      this.setState({
        currentUserDeactivationReasons: this.props.clinicianDeactivationReasons,
      });
    } else if (this.props.userDetails.authority.includes("ROLE_PARENT" || "ROLE_FAMILY")) {
      this.setState({
        currentUserDeactivationReasons: this.props.customerDeactivationReasons,
      });
    } else {
      this.setState({
        currentUserDeactivationReasons: this.props.userDeactivationReasons,
      });
    }
  };

  onOpenActivationDialog = () => {
    this.onCloseUpdateRoleDialog();

    this.setState({ activationDialogOpen: true });
  };

  onCloseActivationDialog = () => {
    this.setState({ activationDialogOpen: false, reason: "", note: "" }, () => {
      if (this.props.toggleUserActivationSuccess) {
        this.props.clearToggleUserActivationInfo();
      }
    });
  };

  onChangeDeactivationInfo = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmitToggleActivation = () => {
    const { reason, note } = this.state;
    const { is_active, id } = this.props.userDetails;
    if (is_active) {
      this.props.toggleUserActivation({ id, reason, note });
    } else {
      this.props.toggleUserActivation({ id });
    }
  };

  render() {
    const {
      classes,
      userDetails,
      userDetailsLoading,
      updateUserRolesLoading,
      updateUserRolesSuccess,
      updateUserRolesFail,
      toggleUserActivationLoading,
      toggleUserActivationSuccess,
    } = this.props;
    const {
      updateRoleDialogOpen,
      updateUserRolesList,
      buttonDisabled,
      activationDialogOpen,
      reason,
      note,
      userRoles,
      currentUserDeactivationReasons,
    } = this.state;
    let fullName = `${userDetails.first_name} ${userDetails.last_name}`;
    let clinicianHasClient =
      userDetails.authority?.includes("ROLE_CLINICIAN") && userDetails?.clinicianInfo?.hasClients;
    let clientHasUpcomingCall =
      userDetails.authority?.includes("ROLE_PARENT" || "ROLE_FAMILY") &&
      userDetails?.childrenInfo?.upcomingCall;

    return (
      <PageContainer loading={userDetailsLoading}>
        <div className={classes.mainContainer}>
          <Typography variant="h3" className={classes.title}>
            User Details
          </Typography>

          <SectionContainer columnDirection>
            <div className={classes.userInfoHeader}>
              <Typography variant="h4">
                {userDetails?.first_name || "----"} {userDetails?.last_name || "----"}
              </Typography>
              {!userDetails.is_active ? (
                <Badge
                  size="small"
                  icon={<ErrorIcon style={{ color: "#dc2626", marginBottom: 2 }} />}
                  color="secondary"
                  style={{ color: "#dc2626", border: "1px solid #fecaca" }}
                  label="Deactivated"
                />
              ) : userDetails.verified ? (
                <Badge
                  size="small"
                  icon={<DoneIcon style={{ marginBottom: 2 }} />}
                  label="Has Logged In"
                />
              ) : (
                <Badge
                  size="small"
                  icon={<CancelIcon style={{ color: "#dc2626", marginBottom: 2 }} />}
                  label="Hasn't Logged In"
                  color="secondary"
                  style={{ color: "#dc2626", border: "1px solid #fecaca" }}
                />
              )}
            </div>
            <Grid container spacing={4} classes={{ root: classes.gridRoot }}>
              <Grid item xs={6} sm={3}>
                <Typography>User ID</Typography>
                <Typography variant="body2">{userDetails.id || "----"}</Typography>
              </Grid>
              <Grid item xs={6} sm={5}>
                <Typography>Email</Typography>
                <Typography variant="body2">{userDetails.email || "----"}</Typography>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Typography>Created Date</Typography>
                <Typography variant="body2">
                  {(userDetails?.created_date &&
                    format(new Date(userDetails.created_date), "PP")) ||
                    "----"}
                </Typography>
              </Grid>
              {userDetails.authority?.includes("ROLE_PARENT" || "ROLE_FAMILY") &&
                userDetails?.childrenInfo && (
                  <>
                    <Grid item xs={6} sm={3}>
                      <Typography>Child Name</Typography>
                      <Typography variant="body2">
                        {userDetails.childrenInfo.name || "----"}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <Typography>Child Last Name</Typography>
                      <Typography variant="body2">
                        {userDetails.childrenInfo.child_last_name || "----"}
                      </Typography>
                    </Grid>
                  </>
                )}

              <Grid item xs={6} sm={10}>
                <Typography>Roles</Typography>
                <div className={classes.userRoleContainer}>
                  {userRoles.map((role, i) => (
                    <div
                      className={classes.userRoleBlock}
                      style={{
                        backgroundColor: role.background,
                        border: `0.1px solid ${role.color}`,
                      }}
                      key={i}
                    >
                      <Typography style={{ color: role.color, fontSize: 14 }}>
                        {role.label}
                      </Typography>
                    </div>
                  ))}
                </div>
              </Grid>
              <Grid item xs={6} sm={2}>
                <div style={{ height: 36 }} />
                <Button onClick={this.onOpenUpdateRoleDialog}>Update</Button>
              </Grid>
            </Grid>
          </SectionContainer>
        </div>
        <Dialog
          open={updateRoleDialogOpen}
          key="update-roles-dialog"
          PaperProps={{
            style: {
              margin: 0,
              padding: "20px 0px",
              boxSizing: "border-box",
              minWidth: "60%",
              maxWidth: "90%",
              minHeight: "65%",
            },
          }}
        >
          <div className={classes.dialogHeader}>
            <Button size="large" variant="text" onClick={this.onCloseUpdateRoleDialog}>
              {!(updateUserRolesSuccess || updateUserRolesFail) && "Cancel"}
            </Button>
            <Typography>Assign Roles</Typography>
            <div style={{ width: 100 }} />
          </div>
          <div className={classes.rolesContainer}>
            <div className={classes.leftContainer}>
              <Typography className={classes.containerText}>
                Roles <strong>{fullName}</strong> could have
              </Typography>
              <div className={classes.rolesList}>
                {roleList.map((role, i) => {
                  return (
                    <Typography
                      variant="h5"
                      key={`${role.role}-${i}`}
                      value={role.role}
                      style={{
                        color: this.currentRolesDisabled(role.role) ? "#c3c6d2" : role.color,
                        margin: "10px 0",
                      }}
                      onClick={() => this.onChangeUserRole(role.role)}
                      className={
                        this.currentRolesDisabled(role.role) ? classes.disabledRole : classes.role
                      }
                    >
                      {role.label}
                    </Typography>
                  );
                })}
              </div>
            </div>
            <div
              className={classes.rightContainer}
              style={{
                backgroundColor: "#fff",
                borderRadius: 10,
                border: "0.5px solid #c3c6d2",
              }}
            >
              <Typography className={classes.containerText}>
                Roles <strong>{fullName}</strong> currently has
              </Typography>
              <div className={classes.rolesList}>
                {roleList.map((role, i) => {
                  return (
                    <Typography
                      variant="h5"
                      key={`${i}-${role.role}`}
                      value={role.role}
                      style={{
                        color: this.otherRolesDisabled(role.role) ? "#c3c6d2" : role.color,
                        margin: "10px 0",
                      }}
                      onClick={() => this.onDeleteUserRole(role.role)}
                      className={
                        this.otherRolesDisabled(role.role) ? classes.disabledRole : classes.role
                      }
                    >
                      {role.label}
                    </Typography>
                  );
                })}
              </div>
            </div>
          </div>
          <div className={classes.dialogButtonsContainer}>
            {updateUserRolesSuccess && (
              <Typography className={classes.successMessage}>
                User roles have been successfully updated
              </Typography>
            )}
            {updateUserRolesFail && (
              <Typography className={classes.errorMessage}>
                An error has occurred. Please try again
              </Typography>
            )}
            <LoadingButton
              variant="outlined"
              loading={updateUserRolesLoading}
              disabled={buttonDisabled || updateUserRolesSuccess || updateUserRolesFail}
              onClick={this.onSubmitUpdateRoles}
            >
              Confirm
            </LoadingButton>
            <Button
              variant="text"
              style={{ color: "#dc2626" }}
              startIcon={<CloseIcon style={{ fontSize: 20 }} />}
              onClick={this.onOpenActivationDialog}
            >
              {userDetails.is_active ? "Disable User" : "Enable User"}
            </Button>
          </div>
        </Dialog>

        <Dialog
          open={activationDialogOpen}
          onClose={this.onCloseActivationDialog}
          key="update-roles-dialog"
          PaperProps={{
            style: {
              margin: 0,
              padding: "20px 0px",
              boxSizing: "border-box",
              minWidth: "40%",
              maxWidth: "70%",
            },
          }}
        >
          <div className={classes.dialogHeader}>
            <Typography>{userDetails.is_active ? "Disable User" : "Enable User"}</Typography>
          </div>
          <DialogContent>
            {userDetails.is_active ? (
              <div>
                <Typography className={classes.serviceTypesHeading}>
                  Are you sure that you want to disable this user ?
                </Typography>
                <TextField
                  select
                  fullWidth
                  id="reason-menu"
                  value={reason}
                  name="reason"
                  onChange={this.onChangeDeactivationInfo}
                  style={{ margin: "10px 0px 20px" }}
                >
                  <MenuItem disabled value="">
                    Select a Reason for Disabling
                  </MenuItem>
                  {currentUserDeactivationReasons?.map((el) => (
                    <MenuItem key={el.id} value={el.reason}>
                      {el.reason}
                    </MenuItem>
                  ))}
                </TextField>
                <Typography className={classes.serviceTypesHeading}>
                  Provide additional notation for disabling this user.
                </Typography>
                <TextField
                  fullWidth
                  id="reason-note"
                  name="note"
                  value={note}
                  onChange={this.onChangeDeactivationInfo}
                  style={{ marginTop: 10 }}
                  multiline={true}
                  rows={4}
                  placeholder="Type your note here"
                />
                {clinicianHasClient && (
                  <Alert severity="error" style={{ margin: "10px 50px 0px 0px" }}>
                    This clinician is currently assigned to a client.
                  </Alert>
                )}
                {clientHasUpcomingCall && (
                  <Alert severity="error" style={{ margin: "10px 50px 0px 0px" }}>
                    The client has scheduled call/s. Please, cancel all calls before deactivation.
                  </Alert>
                )}
              </div>
            ) : (
              <div>
                <Typography className={classes.serviceTypesHeading}>
                  Are you sure that you want to enable this user?
                </Typography>
                <div className={classes.resetPasswordButtonContainer}></div>
              </div>
            )}
          </DialogContent>
          <div className={classes.activationDialogButtonsContainer}>
            {userDetails.is_active ? (
              <LoadingButton
                onClick={() => {
                  this.onSubmitToggleActivation();
                }}
                loading={toggleUserActivationLoading}
                disabled={
                  !note ||
                  !reason ||
                  toggleUserActivationSuccess ||
                  clinicianHasClient ||
                  clientHasUpcomingCall
                }
              >
                Disable
              </LoadingButton>
            ) : (
              <LoadingButton
                onClick={this.onSubmitToggleActivation}
                disabled={toggleUserActivationSuccess}
                loading={toggleUserActivationLoading}
              >
                Enable
              </LoadingButton>
            )}
            <Button variant="text" onClick={this.onCloseActivationDialog}>
              {toggleUserActivationSuccess ? "Close" : "Cancel"}
            </Button>
          </div>
        </Dialog>
      </PageContainer>
    );
  }
}

const styles = (theme) => ({
  mainContainer: {
    width: "75%",
    margin: "50px auto",
  },
  userInfoHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: 20,
  },
  title: {
    fontWeight: 500,
    marginBottom: 30,
  },
  gridRoot: {
    marginTop: 30,
    "& .MuiGrid-item": {
      "& p": {
        fontWeight: 500,
        fontSize: 16,
        color: "#3f4456",
        wordWrap: "break-word",
      },
      "& .MuiTypography-body1": {
        color: "#2a2739",
      },
      "& button": {
        height: 45,
        "& span": {
          fontSize: 16,
        },
      },
    },
  },
  dialogHeader: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0px 20px",
    marginBottom: 10,
    "& p": {
      fontSize: 24,
      color: "#1c0d28",
      fontWeight: 500,
      lineHeight: "24px",
      letterSpacing: "-2%",
      margin: "auto",
    },
    "& button": {
      height: 45,
      "& span": {
        fontSize: 16,
      },
    },
  },
  rolesContainer: {
    position: "relative",
    display: "flex",
    padding: 0,
  },
  leftContainer: {
    position: "absolute",
    left: 0,
    top: 0,
    backgroundColor: "#fa3aef",
    width: "51%",
    padding: 20,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "10px 0px 0px 10px",
    border: "0.5px solid #c3c6d2",
    borderRight: "none",
    backgroundColor: "#fafafa",
  },
  rightContainer: {
    position: "absolute",
    width: "50%",
    left: "50%",
    top: 0,
    backgroundColor: "#fa3aef",
    padding: 20,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  containerText: {
    color: "#391a51",
    fontWeight: 500,
    fontSize: 17,
  },
  userRoleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    gap: 20,
    marginTop: 10,
  },
  userRoleBlock: {
    width: "fit-content",
    height: 40,
    borderRadius: 4,
    padding: "0 20px",
    display: "flex",
    alignItems: "center",
  },
  rolesList: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 20,
  },
  disabledRole: {
    fontSize: 18,
    textTransform: "uppercase",
    fontWeight: 500,
    pointerEvents: "none",
  },
  role: {
    fontSize: 18,
    textTransform: "uppercase",
    fontWeight: 500,
    cursor: "pointer",
  },
  dialogButtonsContainer: {
    position: "absolute",
    bottom: 20,
    width: "100%",
    paddingRight: 120,
    margin: "30px 0px 10px 0px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 100,
    "& button": {
      height: 45,
      "& span": {
        fontSize: 16,
      },
    },
  },
  activationDialogButtonsContainer: {
    width: "100%",
    paddingRight: 30,
    margin: "30px 0px 10px 0px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 80,
    "& button": {
      height: 45,
      "& span": {
        fontSize: 16,
      },
    },
  },
  successMessage: {
    fontWeight: 500,
    color: "#059669",
    fontSize: 17,
  },
  errorMessage: {
    fontWeight: 500,
    color: "#DC2626",
    fontSize: 17,
  },
});

const Badge = withStyles({
  root: {
    backgroundColor: "#d1fae5",
    color: "#059669",
    fontWeight: 500,
    height: 35,
    borderRadius: 5,
    border: "1px solid #a7f3d0",
    "& .MuiChip-icon": {
      color: "#059669",
      height: 16,
      width: 16,
    },
    "& .MuiChip-label": {
      fontWeight: 500,
      fontSize: 13,
      letterSpacing: "0.34px",
      marginBottom: 2,
    },
  },
  colorSecondary: {
    backgroundColor: "#fee2e2",
  },
})(Chip);
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(User));
