import React, { useState } from "react";
import { Button, Stack, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import moment from "moment";
import { Calendar as CalendarIcon, ListDashes } from "@phosphor-icons/react";
import momentTZ from "moment-timezone";
import ANCalendar from "elements/ANCalendar";
import { LoadingScreen, UserTitle, durationOptions, useTimeOptions } from "../EventSchedulingUtils";
import useStyles from "./styles"; // Adjust the import path as needed
import { blue, green, purple } from "@mui/material/colors";
import { TIMEZONES } from "constants";
import { formatTimeZoneToAbbreviation } from "utils/formatTimeZoneToAbbreviation";

/**
 * Component to view availability and schedule for clients and clinicians.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.values - Form values including the event date and client information.
 * @param {Array} props.selectedClinicians - List of selected clinicians.
 * @param {Function} props.setFieldValue - Formik's setFieldValue function to set form values.
 * @param {Array} props.allEvents - List of all events to display on the calendar.
 * @param {Array} props.nonWorkingHours - List of non-working hours to display on the calendar.
 * @param {Object} props.userScheduleColorMap - Map of user IDs to their respective colors for the calendar.
 * @param {Object} props.mergedTimeSlots - Merged time slots for clinicians and clients.
 * @param {boolean} props.loadingOnSchedAvailability - Loading state for OnSched availability.
 * @param {Array} props.clientAvailability - List of client's available time slots.
 * @returns {React.ReactElement} - The rendered AvailabilityViewer component.
 */
const AvailabilityViewer = ({
  values,
  selectedClinicians,
  setFieldValue,
  allEvents,
  nonWorkingHours,
  userScheduleColorMap,
  mergedTimeSlots,
  loadingOnSchedAvailability,
  clientAvailability,
}) => {
  const clientLastName =
    values.selectedClient?.child_last_name ||
    (values.selectedClient && values.selectedClient.teamMembers
      ? values.selectedTeamMember?.lastName
      : "");
  const clientFirstName = values.selectedClient?.name ?? "";
  const theme = useTheme();
  const classes = useStyles();
  const [toggleButtonGroupValue, setToggleButtonGroupValue] = useState("schedule");
  const [selectedTimeSlot, setSelectedTimeSlot] = useState({ clinicianId: null, index: null });

  // Get local timezone and abbreviation
  const timezone = TIMEZONES[Intl.DateTimeFormat().resolvedOptions().timeZone];
  const timezoneAbbr = formatTimeZoneToAbbreviation(timezone);

  const nameTagColors = [blue[400], green[400], purple[400]];
  return (
    <Stack flexGrow={1}>
      <Stack sx={{ marginRight: theme.spacing(5) }} alignItems="flex-end">
        <ToggleButtonGroup
          value={toggleButtonGroupValue}
          exclusive
          onChange={(_, newVal) => {
            if (newVal) setToggleButtonGroupValue(newVal);
          }}
          aria-label="Schedule / Availability"
          size="small"
        >
          <Tooltip title="Schedule">
            <ToggleButton value="schedule" aria-label="schedule">
              <CalendarIcon />
            </ToggleButton>
          </Tooltip>
          <Tooltip title="Availability">
            <ToggleButton value="availability" aria-label="availability">
              <ListDashes />
            </ToggleButton>
          </Tooltip>
        </ToggleButtonGroup>
      </Stack>
      <Stack
        direction="row"
        gap={theme.spacing(1)}
        padding={theme.spacing(5)}
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="body2">{moment(values.eventDate).format("ddd")}</Typography>
        <Typography variant="subtitle2" className={classes.selectedDate}>
          {moment(values.eventDate).format("D")}
        </Typography>
      </Stack>
      <Typography
        variant="caption"
        align="center"
        color="textSecondary"
        sx={{ marginBottom: theme.spacing(2), fontStyle: "italic" }}
      >
        All times are shown in {timezone} ({timezoneAbbr})
      </Typography>
      {toggleButtonGroupValue === "schedule" && (
        <>
          <Stack direction="row" height="20px" justifyContent="center">
            {selectedClinicians &&
              selectedClinicians?.length > 0 &&
              selectedClinicians.map((clinician) => (
                <UserTitle
                  key={clinician.clinician_id}
                  dotColor={nameTagColors[userScheduleColorMap[clinician.user_id]]}
                  name={`${clinician?.first_name} ${clinician?.last_name}`}
                />
              ))}
            {values.selectedClient && (
              <UserTitle dotColor={blue[400]} name={`${clientFirstName} ${clientLastName}`} />
            )}
          </Stack>
          <ANCalendar
            className={classes.calendarTheme}
            view="day"
            events={allEvents}
            nonWorkingHours={nonWorkingHours}
            date={values.eventDate}
            toolbar={false}
            style={{ height: 0, flexGrow: 1 }}
            userScheduleColorMap={userScheduleColorMap}
            scrollToTime={new Date(0, 0, 0, 8)}
          />
        </>
      )}
      {toggleButtonGroupValue === "availability" && (
        <Stack gap={theme.spacing(3)} padding={theme.spacing(0, 5)} overflow="auto">
          {values?.selectedClient?.name && (
            <React.Fragment>
              <Typography variant="subtitle1">
                {`${`${clientFirstName} ${clientLastName}`}`} Availability
              </Typography>
              {clientAvailability?.map(({ start }, index) => (
                <Button
                  color={
                    selectedTimeSlot.clinicianId === "client" && selectedTimeSlot.index === index
                      ? "primary"
                      : "secondary"
                  }
                  key={index}
                  onClick={() => {
                    setSelectedTimeSlot({ clinicianId: "client", index });
                    setFieldValue(
                      "startTime",
                      momentTZ(start, "YYYY-MM-DD HH:mm:ss ZZ").format("h:mm A"),
                      true
                    );
                  }}
                >
                  {momentTZ(start, "YYYY-MM-DD HH:mm:ss ZZ").format("h:mm A")}
                </Button>
              ))}
              {(!clientAvailability || clientAvailability?.length === 0) && (
                <Typography variant="body2" color={(theme) => theme.palette.text.secondary}>
                  {`No available times for ${clientFirstName} ${clientLastName} on this day.`}
                </Typography>
              )}
            </React.Fragment>
          )}
          {selectedClinicians &&
            selectedClinicians?.length > 0 &&
            selectedClinicians.map((clinician) => (
              <React.Fragment key={clinician.clinician_id}>
                <Typography
                  variant="subtitle1"
                  paddingTop={"16px"}
                >{`${clinician.first_name} ${clinician.last_name}`}</Typography>
                {mergedTimeSlots[clinician.clinician_id]?.map((time, index) => (
                  <Button
                    color={
                      selectedTimeSlot.clinicianId === clinician.clinician_id &&
                      selectedTimeSlot.index === index
                        ? "primary"
                        : "secondary"
                    }
                    key={index}
                    onClick={() => {
                      setSelectedTimeSlot({ clinicianId: clinician.clinician_id, index });
                      setFieldValue(
                        "startTime",
                        momentTZ(time, "YYYY-MM-DD HH:mm:ss ZZ").format("h:mm A"),
                        true
                      );
                    }}
                  >
                    {momentTZ(time, "YYYY-MM-DD HH:mm:ss ZZ").format("h:mm A")}
                  </Button>
                ))}
                {(!mergedTimeSlots[clinician.clinician_id] ||
                  mergedTimeSlots[clinician.clinician_id]?.length === 0) && (
                  <Typography variant="body2" color={(theme) => theme.palette.text.secondary}>
                    No available times for {clinician.first_name} {clinician.last_name} on this day.
                  </Typography>
                )}
              </React.Fragment>
            ))}
          {loadingOnSchedAvailability ? (
            <LoadingScreen />
          ) : (
            selectedClinicians?.length === 0 && (
              <Typography variant="body2" color={(theme) => theme.palette.text.secondary}>
                No available times on this day.
              </Typography>
            )
          )}
        </Stack>
      )}
    </Stack>
  );
};

export default AvailabilityViewer;
