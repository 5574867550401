/* eslint-disable no-undef */
import { v4 as uuidv4 } from "uuid";
import { getAWSCredentialsForCurrentUserSession } from "utils/aws";
/** @typedef {import("elements/ANUploader").AcceptedFile} AcceptedFile */

const API_VERSION = "2006-03-01";
const BUCKET = process.env.AWS_USER_DOC_BUCKET;

/**
 * The function `postUploadFiles` uploads a file to an AWS S3 bucket.
 * @function postUploadFiles
 * @param {string} cognitoId - The `cognitoId` parameter in the `postUploadFiles` function is typically a unique
 * identifier associated with a user in Amazon Cognito.
 * @param {AcceptedFile[]} uploadFiles - The `uploadFiles` parameter is an array containing objects with a `file`
 * property.
 * @param {string} [S3Bucket] - The `S3Bucket` parameter in the `postUploadFiles` function is an optional parameter, default is env variable `AWS_USER_DOC_BUCKET`.
 * @returns The function `postUploadFiles` returns an object with two properties: `errors` and
 * `success`. The `errors` property will contain any errors that occurred during the execution of the
 * function, while the `success` property will contain the `Key` of the uploaded file if the upload was
 * successful.
 */
export async function postUploadFiles(cognitoId, uploadFiles, S3Bucket = BUCKET) {
  let errors = null;
  let success = null;

  try {
    if (uploadFiles[0]?.file) {
      const credentials = await getAWSCredentialsForCurrentUserSession();

      const s3 = new AWS.S3({
        credentials,
        apiVersion: API_VERSION,
        params: { Bucket: S3Bucket },
      });

      const fileUUID = uuidv4();
      const [fileName, fileExtension] = uploadFiles[0].file.name.split(".");

      const params = {
        ACL: "authenticated-read",
        Body: uploadFiles[0].file,
        ContentType: uploadFiles[0].file.type,
        Key: `${cognitoId}/${fileName}-${fileUUID}.${fileExtension}`,
      };

      const response = await s3.upload(params).promise();

      if (response) {
        success = response.Key;
      }
    }
  } catch (error) {
    console.log(error);
    errors = error;
  }

  return { errors, success };
}
