import { inviteMember } from "api/team";
import { useGlobalToast } from "components/GlobalToastProvider";
import { useMutation } from "react-query";

export const useInviteMember = (options = {}) => {
  const { onError = () => {}, ...otherOptions } = options;
  const { showToast } = useGlobalToast();

  const { mutate, ...rest } = useMutation(
    ({ teamId, email, clientId }) => inviteMember(teamId, { email, clientId }),
    {
      onError: (error, variables, context) => {
        if (
          error.response.data.message === "Cannot invite this user" &&
          error.response.status === 400
        ) {
          return;
        }
        showToast({
          message: "Whoops! Something went wrong",
          errorState: true,
          retryHandler: () => mutate(variables, context),
        });
        onError();
      },
      ...otherOptions,
    }
  );

  return {
    inviteMemberMutation: mutate,
    ...rest,
  };
};
