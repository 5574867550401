import { select } from "redux-saga/effects";
import SecureRequest from "../utils/securerequest";
import { getTagMessagesPage, getTagRowsPerPage } from "../selectors";

export function* getMessageTagSummary() {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return yield Request.get(`/reports/messages-by-tag-summary`);
}

export function* getMessagesForTag(tagId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const page = yield select(getTagMessagesPage);
  const size = yield select(getTagRowsPerPage);
  const params = {
    page,
    size,
  };
  return yield Request.get(`/reports/tags/${tagId}/messages`, { params });
}

export function* getVideoCallSummary(query) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  if (query.start && query.end) {
    return yield Request.get(`/reports/video-calls?start=${query.start}&end=${query.end}`);
  }
  return yield Request.get(`/reports/video-calls`);
}

export function* getBillingSummary() {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get("/v2/reporting/billing-summary");
}
