import React, { useState } from "react";
import styles from "./styles";
import { connect } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { getLogin, getUserClinicianId } from "selectors";
import { useMutation } from "react-query";
import { syncClinicianCalendarToOnSched } from "api/clinician";
import OptionalSync from "./OptionalSync";
import GoogleSync from "./GoogleSync";

const mapStateToProps = (state) => ({
  clinicianId: getUserClinicianId(state),
  clinicianEmail: getLogin(state),
});

const CalendarSync = (props) => {
  const { classes, open, clinicianId, clinicianEmail, onClose } = props;
  const [associatedEmail, setAssociatedEmail] = useState(clinicianEmail || "");
  const [isGoogle, setIsGoogle] = useState(true);

  const {
    mutate,
    isLoading,
    data: body,
    error,
  } = useMutation(
    ({ isGoogleSync }) =>
      syncClinicianCalendarToOnSched({
        clinicianId,
        isGoogleSync,
        associatedEmail,
        redirectUrl: `${process.env.BASE_PARENT_URL}/dashboard?calendarSyncRedirect=true`,
      }),
    {
      onSuccess: (body, variables) => {
        window.location.href =
          variables.isGoogleSync && body.data.googleCalendarAuthUrl
            ? body.data.googleCalendarAuthUrl
            : body.data.outlookCalendarAuthUrl;
      },
      onError: (error) => {
        console.error("Error syncing calendar:", error);
      },
    },
  );

  const handleSyncButtonClick = (isGoogle) => {
    setIsGoogle(isGoogle);
    mutate({ isGoogleSync: isGoogle });
  };

  const isAnswersNowEmail = () => {
    const { clinicianEmail } = props;
    return clinicianEmail?.includes("getanswersnow.com");
  };

  return (
    <Dialog
      open={open}
      PaperProps={{
        style: {
          maxWidth: "95%",
          width: 444,
        },
      }}
    >
      <DialogTitle className={classes.dialogTitle} id="calendar-sync-title">
        Sync your calendars
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {isAnswersNowEmail() ? (
          <GoogleSync
            classes={classes}
            handleSyncButtonClick={handleSyncButtonClick}
            isLoading={isLoading}
            onClose={onClose}
          />
        ) : (
          <OptionalSync
            classes={classes}
            handleSyncButtonClick={handleSyncButtonClick}
            isLoading={isLoading}
            associatedEmail={associatedEmail}
            setAssociatedEmail={setAssociatedEmail}
            onClose={onClose}
            isGoogle={isGoogle}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default connect(mapStateToProps)(withStyles(styles)(CalendarSync));
