import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../../actions";
import LayoutContainer from "../Layout";
import { CssBaseline } from "@mui/material";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import theme from "../../theme";
import Auth from "@aws-amplify/auth";
import LuxonUtils from "@date-io/luxon";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import enUsLocale from "date-fns/locale/en-US";
import { QueryClient, QueryClientProvider } from "react-query";

import { LicenseInfo } from "@mui/x-license";
import { jam } from "@jam.dev/sdk";

Auth.configure({
  region: process.env.COGNITO_REGION,
  userPoolId: process.env.COGNITO_POOL_ID,
  userPoolWebClientId: process.env.COGNITO_CLIENT_ID,
});

// Create a QueryClient instance
const queryClient = new QueryClient();

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      systemLoaded: actions.systemLoaded,
    },
    dispatch
  );

export class App extends Component {
  componentDidMount() {
    this.props.systemLoaded();
    LicenseInfo.setLicenseKey(process.env.MUI_X_LICENSE_KEY);
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

    // set up Jam metadata hook
    if (process.env.NODE_ENV !== "production") {
      import("hooks/useJamMetadata").then(({ useJamMetadata }) => {
        const [jamMetadata] = useJamMetadata();
        jam.metadata(() => jamMetadata);
      });
    }
  }

  render() {
    return (
      <QueryClientProvider client={queryClient}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={enUsLocale}
              utils={LuxonUtils}
            >
              <CssBaseline />
              <div style={{ minWidth: 300, height: "100%" }}>
                <LayoutContainer {...this.props} />
              </div>
            </LocalizationProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </QueryClientProvider>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
