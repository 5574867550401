import { createReducer } from "redux-create-reducer";
import actions from "../actions";

const initialState = {
  payroll: [],
  historicalSummary: [],
  reportsLoading: false,
  messageTagSummary: [],
  messagesForTag: [],
  tagPage: 0,
  tagRowsPerPage: 25,
  tagCount: 0,
  videoCalls: [],
  billingSummary: [],
};

const reducers = {
  [actions.GET_MESSAGE_TAG_SUMMARY_SUCCESS](state, { payload }) {
    return {
      ...state,
      messageTagSummary: payload.report,
    };
  },
  [actions.GET_MESSAGES_FOR_TAG_SUCCESS](state, { payload }) {
    return {
      ...state,
      messagesForTag: payload,
      tagCount: Number(payload[0].count),
    };
  },
  [actions.SET_TAG_MESSAGES_PAGE](state, { payload }) {
    return {
      ...state,
      tagPage: payload,
    };
  },
  [actions.SET_TAG_MESSAGES_PER_PAGE](state, { payload }) {
    return {
      ...state,
      tagRowsPerPage: payload,
    };
  },
  [actions.GET_VIDEO_CALL_SUMMARY](state, { payload }) {
    return {
      ...state,
      reportsLoading: true,
    };
  },
  [actions.GET_VIDEO_CALL_SUMMARY_SUCCESS](state, { payload }) {
    return {
      ...state,
      reportsLoading: false,
      videoCalls: payload,
    };
  },
  [actions.GET_BILLING_SUMMARY](state, { payload }) {
    return {
      ...state,
      reportsLoading: true,
    };
  },
  [actions.GET_BILLING_SUMMARY_SUCCESS](state, { payload }) {
    return {
      ...state,
      reportsLoading: false,
      billingSummary: payload,
    };
  },
};

export default createReducer(initialState, reducers);
