import React, { useState, useEffect } from "react";
import { Grid, Typography, Button, Paper } from "@mui/material";
import moment from "moment";

const AuthorizationList = ({
  authorizationCodes,
  onOpenUpdateAuthorization,
  onOpenViewAuthorization,
  mapAuthTypeToDisplay,
  userPermissions,
  editMode,
}) => {
  const [viewMoreOngoing, setViewMoreOngoing] = useState(false);
  const [viewMoreSecondary, setViewMoreSecondary] = useState(false);

  // Reset view states when the authorizationCodes array changes
  useEffect(() => {
    setViewMoreOngoing(false);
    setViewMoreSecondary(false);
  }, [authorizationCodes]);

  const toggleViewMoreOngoing = () => {
    setViewMoreOngoing(!viewMoreOngoing);
  };

  const toggleViewMoreSecondary = () => {
    setViewMoreSecondary(!viewMoreSecondary);
  };

  const ongoingCodes = authorizationCodes
    .map((code, index) => ({ ...code, originalIndex: index })) // attach the original index
    .filter((code) => code.authorization_type !== "secondary_auth");

  // Secondary authorizations with the original index from authorizationCodes array
  const secondaryCodes = authorizationCodes
    .map((code, index) => ({ ...code, originalIndex: index })) // attach the original index
    .filter((code) => code.authorization_type === "secondary_auth");

  const displayedOngoingCodes = viewMoreOngoing ? ongoingCodes : ongoingCodes.slice(0, 3);
  const displayedSecondaryCodes = viewMoreSecondary ? secondaryCodes : secondaryCodes.slice(0, 3);

  const renderAuthorizationItem = (code, index) => (
    <Paper
      elevation={0}
      key={index}
      sx={{
        width: "100%",
        padding: "16px 24px",
        marginTop: "16px",
        border: "1px solid #e0e0e0",
        borderRadius: "6px",
      }}
    >
      <Grid container alignItems="center" justify="space-between" spacing={3}>
        <Grid item xs>
          <Typography variant="subtitle1">
            {mapAuthTypeToDisplay(code.authorization_type)}
          </Typography>
          <Typography variant="body2" color={(theme) => theme.palette.text.secondary}>
            {`${moment(code.start_date).utc().format("MM/DD/YYYY")} - ${moment(code.end_date).utc().format("MM/DD/YYYY")} | ${code.authorization_code} ${!!code.clinician_name ? `| ${code.clinician_name}` : ""}`}
          </Typography>
        </Grid>
        {(editMode || (code.authorization_type !== "secondary_auth" && !editMode)) && (
          <Grid item>
            <Button
              variant="text"
              onClick={() => {
                if (editMode && userPermissions?.add_client_insurance_authorization) {
                  onOpenUpdateAuthorization(code.originalIndex);
                } else {
                  onOpenViewAuthorization(code.originalIndex);
                }
              }}
            >
              {`${editMode && userPermissions?.add_client_insurance_authorization ? "Edit" : "View"}`}
            </Button>
          </Grid>
        )}
      </Grid>
    </Paper>
  );

  const additionalOngoing = ongoingCodes.length - displayedOngoingCodes.length;
  const additionalSecondary = secondaryCodes.length - displayedSecondaryCodes.length;

  return (
    <>
      <Typography variant="subtitle1">Ongoing Authorizations</Typography>
      {displayedOngoingCodes.length > 0 ? (
        displayedOngoingCodes.map(renderAuthorizationItem)
      ) : (
        <Typography
          variant={"body2"}
          color={(theme) => theme.palette.text.secondary}
          style={{ marginTop: "8px" }}
        >
          No ongoing authorizations
        </Typography>
      )}
      {ongoingCodes.length > 3 && (
        <Button
          sx={{ marginTop: "16px" }}
          onClick={toggleViewMoreOngoing}
          variant="text"
          color="primary"
        >
          {viewMoreOngoing ? "View Less" : `View ${additionalOngoing} More`}
        </Button>
      )}

      <Typography variant="subtitle1" style={{ marginTop: "20px" }}>
        Secondary Authorizations
      </Typography>
      {displayedSecondaryCodes.length > 0 ? (
        displayedSecondaryCodes.map(renderAuthorizationItem)
      ) : (
        <Typography
          variant={"body2"}
          color={(theme) => theme.palette.text.secondary}
          style={{ marginTop: "8px" }}
        >
          No secondary authorizations
        </Typography>
      )}
      {secondaryCodes.length > 3 && (
        <Button
          sx={{ marginTop: "16px" }}
          onClick={toggleViewMoreSecondary}
          variant="text"
          color="primary"
        >
          {viewMoreSecondary ? "View Less" : `View ${additionalSecondary} More`}
        </Button>
      )}
    </>
  );
};

export default AuthorizationList;
