import SecureRequest from "../utils/securerequest";

export function* registerCognitoUser(payload, isAdmin) {
  const { email, password } = payload;
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return yield Request.post("/users", { email: email.toLowerCase(), password })
    .then((res) => {
      const { username } = res.data;
      const group = isAdmin ? payload.adminType : "Clinicians";

      // Add the user to the Caregivers group
      const groupParams = {
        username,
        group,
      };

      return Request.post("/users/add-to-group", groupParams);
    })
    .then((res) => {
      const { username } = res.data;
      return username;
    })
    .catch((err) => {
      console.log(err.message || JSON.stringify(err));
    });
}
