import React from "react";
import { useFormik } from "formik";
import { Button, Grid, MenuItem } from "@mui/material";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";

import { formatToPhoneNumber, getOnlyChangedValues } from "utils/formatFormFields";
import { COUNTRIES, STATES, TIMEZONES } from "constants";
import ANDatePicker from "elements/ANDatePicker";
import PanelHeader from "./PanelHeader";
import { memberValidationSchema } from "./validationSchema";
import { validateEmail } from "utils/validation";
/**  @typedef {import("api/typesDef").Member} Member */

/**
 * The `EditTeamMember` function is a React component that allows editing of team
 * member details such as first name, last name, phone number, email, address, etc.
 * The form includes input fields for each detail, validation for each field, and buttons
 * to save changes or cancel the editing process. The form is also integrated with Formik
 * @component
 * @param {Object} props - The props of the component
 * @param {Member} props.member - The member data
 * @param {Function} props.onClosePanel - The function to close the collapsable panel
 * @param {Function} props.onSubmit - The function to submit the form data
 * @returns {JSX.Element} The `<EditTeamMember />` component is being returned.
 */
export default function EditTeamMember({ member, onClosePanel, onSubmit, userPermissions }) {
  const {
    firstName,
    lastName,
    dateOfBirth,
    gender,
    phoneNumber,
    email,
    address1,
    address2,
    country,
    state,
    city,
    zipCode,
    timezone,
  } = member;

  const fullName = `${firstName} ${lastName}`;

  // Formik configuration
  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
    isSubmitting,
    resetForm,
    touched,
    values,
    setErrors,
    setFieldTouched,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: memberValidationSchema,
    initialValues: {
      firstName: firstName ?? "",
      lastName: lastName ?? "",
      dateOfBirth: dateOfBirth ? new Date(dateOfBirth) : null,
      gender: gender ?? "",
      phoneNumber: phoneNumber ?? "",
      email: email ?? "",
      address1: address1 ?? "",
      address2: address2 ?? "",
      country: country ?? "",
      state: state ?? "",
      city: city ?? "",
      zipCode: zipCode ?? "",
      timezone: timezone ?? "",
    },
    onSubmit: (values) => {
      // Only send the values that have changed
      const changedValues = getOnlyChangedValues(member, values);

      onSubmit(changedValues).finally(() => {
        resetForm();
        onClosePanel();
      });
    },
  });

  const handleClose = () => {
    if (!isSubmitting) {
      resetForm();
      onClosePanel();
    }
  };

  const handlePhoneNumberChange = (e) => {
    const phone = e.target.value.replace(/[^\d]/g, "");
    handleChange({ target: { name: "phoneNumber", value: phone } });
  };

  const handleEmailBlur = (e) => {
    const { value } = e.target;

    // Mark the field as touched
    setFieldTouched("email", true);

    // Perform the email validation on blur
    if (!validateEmail(value)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Invalid email address",
      }));
    } else {
      // Clear the email error if valid
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: undefined,
      }));
    }

    handleBlur(e);
  };

  return (
    <form onSubmit={handleSubmit}>
      <PanelHeader fullname={fullName} onClose={handleClose} />

      <Grid container rowSpacing={7} marginTop={0}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="First Name"
            name="firstName"
            size="small"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.firstName}
            error={touched.firstName && !!errors.firstName}
            helperText={touched.firstName && errors.firstName}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Last Name"
            name="lastName"
            size="small"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.lastName}
            error={touched.lastName && !!errors.lastName}
            helperText={touched.lastName && errors.lastName}
          />
        </Grid>

        <Grid item xs={12}>
          <ANDatePicker
            label="Date of Birth"
            format="MM/dd/yyyy"
            disableFuture
            style={{ fontSize: 14 }}
            name="dateOfBirth"
            value={values.dateOfBirth}
            onChange={(value) => {
              handleChange({ target: { name: "dateOfBirth", value } });
            }}
            onBlur={handleBlur}
            slotProps={{
              textField: {
                fullWidth: true,
                placeholder: "01/01/2023",
                error: touched.dateOfBirth && !!errors.dateOfBirth,
                helperText: touched.dateOfBirth && errors.dateOfBirth,
              },
              popper: { disablePortal: false },
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            select
            fullWidth
            label="Gender"
            name="gender"
            error={touched.gender && !!errors.gender}
            helperText={touched.gender && errors.gender}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.gender}
          >
            <MenuItem disabled value={""}>
              Select
            </MenuItem>
            <MenuItem value="Female">Female</MenuItem>
            <MenuItem value="Male">Male</MenuItem>
            <MenuItem value="non_binary">Non-binary</MenuItem>
            <MenuItem value="Unknown">-</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Phone Number"
            name="phoneNumber"
            size="small"
            onChange={handlePhoneNumberChange}
            onBlur={handleBlur}
            value={formatToPhoneNumber(values.phoneNumber)}
            error={touched.phoneNumber && !!errors.phoneNumber}
            helperText={touched.phoneNumber && errors.phoneNumber}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Email Address"
            name="email"
            size="small"
            onChange={handleChange}
            onBlur={handleEmailBlur}
            value={values.email}
            error={touched.email && !!errors.email}
            helperText={touched.email && errors.email}
            disabled={!userPermissions.update_client_email_address}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Address 1"
            name="address1"
            size="small"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.address1}
            error={touched.address1 && !!errors.address1}
            helperText={touched.address1 && errors.address1}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Address 2"
            name="address2"
            size="small"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.address2}
            error={touched.address2 && !!errors.address2}
            helperText={touched.address2 && errors.address2}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            select
            fullWidth
            label="Country"
            name="country"
            error={touched.country && !!errors.country}
            helperText={touched.country && errors.country}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.country}
          >
            <MenuItem disabled value={""}>
              Select
            </MenuItem>
            {Object.entries(COUNTRIES).map(([key, value]) => {
              return (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              );
            })}
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <TextField
            select
            fullWidth
            label="State"
            name="state"
            error={touched.state && !!errors.state}
            helperText={touched.state && errors.state}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.state}
            disabled={values.country !== "US"}
          >
            <MenuItem disabled value={""}>
              Select
            </MenuItem>
            {Object.entries(STATES).map(([key, value]) => {
              return (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              );
            })}
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="City"
            name="city"
            size="small"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.city}
            error={touched.city && !!errors.city}
            helperText={touched.city && errors.city}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Zip Code"
            name="zipCode"
            size="small"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.zipCode}
            error={touched.zipCode && !!errors.zipCode}
            helperText={touched.zipCode && errors.zipCode}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            select
            fullWidth
            label="Time Zone"
            name="timezone"
            error={touched.timezone && !!errors.timezone}
            helperText={touched.timezone && errors.timezone}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.timezone}
          >
            <MenuItem disabled value={""}>
              Select
            </MenuItem>
            {Object.entries(TIMEZONES).map(([key, value]) => {
              return (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              );
            })}
          </TextField>
        </Grid>
      </Grid>

      <Grid container justifyContent="flex-end" marginTop={7} rowSpacing={3}>
        <Grid item xs={12}>
          <LoadingButton fullWidth type="submit" loading={isSubmitting} disabled={!isValid}>
            Save Changes
          </LoadingButton>
        </Grid>

        <Grid item xs={12}>
          <Button
            fullWidth
            color="secondary"
            variant="text"
            onClick={handleClose}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
