import { getClinicianDocuments } from "api/clinicians";
import { useQuery } from "react-query";

export const useGetClinicianDocuments = (clientId, params, enabled = true) => {
  return useQuery(
    ["clinicianDocuments", clientId, params],
    () => getClinicianDocuments(clientId, params),
    {
      enabled: !!clientId && !enabled,
      keepPreviousData: true, // this is used to avoid flickering
    }
  );
};
