import React, { useState } from "react";
import withStyles from "@mui/styles/withStyles";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import DoneIcon from "@mui/icons-material/Done";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import StepConnector from "@mui/material/StepConnector";
import Button from "@mui/material/Button";
import { differenceInDays, format } from "date-fns";
import LoadingButton from "@mui/lab/LoadingButton";
import CheckIcon from "@mui/icons-material/Check";
import { useSelector } from "react-redux";
import { useGetFeatureFlag } from "hooks/useGetFeatureFlag";
import * as selectors from "selectors";
import { useHistory } from "react-router-dom";

import { convertTokens } from "@date-fns/upgrade/v2";

const Connector = withStyles({
  root: {
    paddingBottom: 0,
    display: "none",
  },
  line: {
    minHeight: 0,
    borderLeftWidth: 20,
    borderRadius: 1,
    borderColor: "#B3D3EA",
    marginLeft: -10,
  },
  active: {
    "& .MuiStepConnector-line": {
      borderColor: "#357BA2",
      borderLeftWidth: 26,
      marginLeft: -12,
    },
  },
  completed: {
    "& .MuiStepConnector-line": {
      borderColor: "#357BA2",
      borderLeftWidth: 26,
      marginLeft: -12,
    },
  },
})(StepConnector);

const stepIconStyles = makeStyles({
  icon: {
    width: 22,
    height: 22,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#ECFAFA",
    border: "1px solid #68AED5",
    zIndex: 6,
    "& p": {
      fontSize: 11,
      color: "#68AED5",
      fontWeight: "500",
    },
  },
  iconActive: {
    width: 34,
    height: 34,
    borderRadius: "50%",
    backgroundColor: "#B3D3EA",
    border: "4px solid #357BA2",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: -6,
    position: "relative",
    zIndex: 6,
    "& p": {
      fontSize: 13,
      color: "#1A3A51",
      fontWeight: "500",
    },
    "&:after": {
      display: "block",
      content: "''",
      position: "absolute",
      bottom: "-10px",
      left: "50%",
      transform: "translate(-50%)",
      width: 0,
      height: 0,
      borderTop: "solid 10px #357BA2",
      borderLeft: "solid 5px transparent",
      borderRight: "solid 5px transparent",
    },
  },
  iconComplete: {
    width: 22,
    height: 22,
    borderRadius: "50%",
    backgroundColor: "#285C79",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 6,
  },
  iconSelectedComplete: {
    width: 34,
    height: 34,
    borderRadius: "50%",
    backgroundColor: "#285C79",
    border: "4px solid #357BA2",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: -6,
    position: "relative",
    zIndex: 6,
    "& p": {
      fontSize: 13,
      color: "#1A3A51",
      fontWeight: "500",
    },
    "&:after": {
      display: "block",
      content: "''",
      position: "absolute",
      bottom: "-10px",
      left: "50%",
      transform: "translate(-50%)",
      width: 0,
      height: 0,
      borderTop: "solid 10px #357BA2",
      borderLeft: "solid 5px transparent",
      borderRight: "solid 5px transparent",
    },
  },
  iconSelectedIncomplete: {
    width: 34,
    height: 34,
    borderRadius: "50%",
    backgroundColor: "#ECFAFA",
    border: "4px solid #68AED5",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: -6,
    position: "relative",
    zIndex: 6,
    "& p": {
      fontSize: 13,
      color: "#68AED5",
      fontWeight: "500",
    },
    "&:after": {
      display: "block",
      content: "''",
      position: "absolute",
      bottom: "-10px",
      left: "50%",
      transform: "translate(-50%)",
      width: 0,
      height: 0,
      borderTop: "solid 10px #68AED5",
      borderLeft: "solid 5px transparent",
      borderRight: "solid 5px transparent",
    },
  },
  container: {
    height: "140%",
    backgroundColor: "#B3D3EA",
    width: 22,
    display: "flex",
    justifyContent: "center",
    marginLeft: 2,
    // marginBottom: 8,
  },
  containerCompleted: {
    height: "185%",
    maxHeight: 70,
    backgroundColor: "#357BA2",
    // borderRadius: 14,
    width: 26,
    display: "flex",
    justifyContent: "center",
    zIndex: 1,
    marginBottom: 3,
  },
  containerActive: {
    height: "100%",
    backgroundColor: "#B3D3EA",
    width: 22,
    marginLeft: 2,
  },
});

function renderStepIcon(props, index, taskListLength, selectedTaskIndex) {
  const classes = stepIconStyles();
  const { active, completed } = props;
  const firstElementStyles = {
    borderTopRightRadius: 13,
    borderTopLeftRadius: 13,
    paddingTop: 2,
  };
  const lastElementStyles = {
    borderBottomRightRadius: selectedTaskIndex == taskListLength - 1 ? 0 : "50%",
    borderBottomLeftRadius: selectedTaskIndex == taskListLength - 1 ? 0 : "50%",
    height: "100%",
    paddingTop: 3,
    // marginBottom: 0,
    // backgroundColor: selectedTaskIndex == taskListLength - 1 ? "#B3D3EA" : "transparent",
  };
  const additionalStyles =
    index === 0 ? firstElementStyles : index === taskListLength - 1 ? lastElementStyles : null;
  return active || selectedTaskIndex === index ? (
    <div className={classes.containerActive} style={additionalStyles}>
      <div
        className={
          completed
            ? classes.iconSelectedComplete
            : !active
              ? classes.iconSelectedIncomplete
              : classes.iconActive
        }
      >
        {completed ? (
          <DoneIcon style={{ fill: "#F0F1F4", fontSize: 15 }} />
        ) : (
          <Typography component="p">{index + 1}</Typography>
        )}
      </div>
    </div>
  ) : completed ? (
    <div className={classes.containerCompleted} style={additionalStyles}>
      <div className={classes.iconComplete}>
        <DoneIcon style={{ fill: "#F0F1F4", fontSize: 15 }} />
      </div>
    </div>
  ) : (
    <div className={classes.container} style={additionalStyles}>
      <div className={classes.icon}>
        <Typography component="p">{index + 1}</Typography>
      </div>
    </div>
  );
}

const TaskListProgress = (props) => {
  const [selectedTaskIndex, setSelectedTaskIndex] = useState(null);
  const {
    classes,
    customerDetails,
    clientTaskList,
    userPermissions,
    verified,
    onToggleScheduleClientVideo,
    onToggleAssignClinician,
    onToggleTreatmentPlanDialog,
    onToggleAssessmentComplete,
    onResendLoginEmail,
    resendLoginSuccess,
    onOpenUpdateTreatmentPlan,
    isActive,
    currentUser,
    isDirector,
    treatmentPlanUploading,
    markClientTaskComplete,
    markTaskCompleteLoading,
    resendingLoginEmail,
  } = props;

  const activeIndex = clientTaskList.findIndex((task) => !task.completed);

  let isPrimaryClinician =
    currentUser?.id == customerDetails?.clinician?.clinician_user_id ? true : false;

  const onClickTaskLabel = (index) => {
    if (
      typeof selectedTaskIndex == "number" &&
      (selectedTaskIndex == index || index == activeIndex)
    ) {
      setSelectedTaskIndex(null);
    } else {
      setSelectedTaskIndex(index);
    }
  };

  const checkTaskForPrerequisite = (task) => {
    let result = { eligible: true, message: "" };
    if (task.prior_step_id) {
      let prereqIndex = clientTaskList.findIndex((t) => t.task_id === task.prior_step_id);
      let prereq = mapTaskToDisplay(clientTaskList[prereqIndex]);
      if (prereq && !prereq.completed) {
        result.eligible = false;
        result.message = `Step ${prereqIndex + 1} required (${prereq.task_name}) `;
      }
    }
    return result;
  };
  const mapTaskToDisplay = (task) => {
    switch (task?.task) {
      case "client_invited":
        return {
          task_name: "Client Invited",
          description: "",
          note: "",
          ...task,
        };
      case "client_logged_in":
        return {
          task_name: "Client Logged In",
          description: "Indicates that the client has logged in to the portal for the first time.",
          note: "",
          button: !verified &&
            isActive &&
            userPermissions?.send_reset_password &&
            !task.completed && (
              <ResendLoginButton
                onResendLoginEmail={onResendLoginEmail}
                classes={classes}
                resendLoginSuccess={resendLoginSuccess}
                resendingLoginEmail={resendingLoginEmail}
              />
            ),
          ...task,
        };
      case "orientation_complete":
        return {
          task_name: "Orientation Call Complete",
          description: "",
          note: "",
          ...task,
        };
      case "diagnosis_uploaded":
        return {
          task_name: "Diagnosis Uploaded",
          description:
            "AnswersNow requires an official Autism diagnosis to provide ABA services. Work with the client’s family and physician to get this document.",
          note: "NOTE: Private Pay clients do not need a diagnosis and can begin therapy immediately.",
          button: !task.completed && (
            <MarkTaskCompleteButton
              buttonText="Mark Complete"
              clientId={customerDetails?.details?.client_id}
              clientTaskId={task.id}
              markClientTaskComplete={markClientTaskComplete}
              loading={markTaskCompleteLoading}
              classes={classes}
            />
          ),
          ...task,
        };
      case "clinician_assigned":
        return {
          task_name: "Clinician Assigned",
          description:
            "Select a clinician that is available and assign them to the client within 2 days.",
          note: "",
          button: userPermissions?.assign_clinician && !task.completed && (
            <AssignClinicianButton
              onToggleAssignClinician={onToggleAssignClinician}
              classes={classes}
            />
          ),
          ...task,
        };
      case "pre_auth_signed":
        return {
          task_name: "Pre-Auth Signed",
          description:
            "After the pre-auth has been signed, submit it to insurance so that they can review the document.",
          note: "",
          button: !task.completed && (
            <MarkTaskCompleteButton
              buttonText="Pre-Auth Signed"
              clientId={customerDetails?.details?.client_id}
              clientTaskId={task.id}
              markClientTaskComplete={markClientTaskComplete}
              loading={markTaskCompleteLoading}
              classes={classes}
            />
          ),
          ...task,
        };
      case "pre_auth_submitted":
        return {
          task_name: "Pre-Authorization Submitted",
          description: "Submit the pre-authorization to insurance for approval.",
          note: "",
          button: !task.completed && (
            <MarkTaskCompleteButton
              buttonText="Pre-Auth Submitted"
              clientId={customerDetails?.details?.client_id}
              clientTaskId={task.id}
              markClientTaskComplete={markClientTaskComplete}
              loading={markTaskCompleteLoading}
              classes={classes}
            />
          ),
          ...task,
        };
      case "pre_auth_received":
        return {
          task_name: "Pre-Authorization Received",
          description:
            "This step will be marked complete automatically when a 'Pre' Authorization has been entered.",
          note: "NOTE: Step 8 often takes 2-4 weeks. Don’t forget to regularly check in with the client to give them an update on their status.",
          ...task,
        };
      case "assessment_scheduled":
        return {
          task_name: "Assessment Scheduled",
          description:
            "Schedule an assessment with the client within 7 days if the family is available. The treatment plan can only be created after the assessment has been completed.",
          note: "NOTE: Schedule assessment as soon as possible, bypassing previous steps if necessary.",
          button: userPermissions?.schedule_call && !task.completed && (
            <ScheduleCallButton
              onToggleScheduleClientVideo={onToggleScheduleClientVideo}
              classes={classes}
            />
          ),
          ...task,
        };
      case "first_assessment_completed":
        return {
          task_name: "First Assessment Completed",
          description:
            "This task will be marked complete automatically upon the completion of the first Assessment Call.",
          note: "",
          ...task,
        };
      case "assessment_process_completed":
        return {
          task_name: "Assessment Calls Completed",
          description:
            "To be marked complete when clinician has all information necessary to complete the treatment plan.",
          note: "",
          button: userPermissions?.mark_assessment_complete && !task.completed && (
            <MarkAssessmentCompleteButton
              onToggleAssessmentComplete={onToggleAssessmentComplete}
              userPermissions={userPermissions}
              classes={classes}
            />
          ),
          ...task,
        };
      case "re_auth_initiated":
        return {
          task_name: "Re-Auth Process Initiated",
          description: "Completed by clinician acknowledging that the Re-Auth process has begun.",
          note: "",
          ...task,
        };
      case "treatment_plan_submitted":
        return {
          task_name: "Treatment Plan Submitted to Clinical",
          description: "Clinical needs to review the treatment plan before it can be approved. ",
          note: "",
          button:
            userPermissions?.upload_treatment_plan &&
            (isPrimaryClinician || isDirector) &&
            (task.completed ? (
              <UpdateTreatmentPlanButton
                onOpenUpdateTreatmentPlan={onOpenUpdateTreatmentPlan}
                customerDetails={customerDetails}
                classes={classes}
                loading={treatmentPlanUploading}
              />
            ) : (
              <UploadTreatmentPlanButton
                onToggleTreatmentPlanDialog={onToggleTreatmentPlanDialog}
                classes={classes}
                loading={treatmentPlanUploading}
              />
            )),
          ...task,
        };
      case "treatment_plan_approved":
        return {
          task_name: "Treatment Plan Approved by Clinical",
          description: "",
          note: "",
          button: !task.completed && (
            <MarkTaskCompleteButton
              buttonText="Approve Treatment Plan"
              clientId={customerDetails?.details?.client_id}
              clientTaskId={task.id}
              markClientTaskComplete={markClientTaskComplete}
              loading={markTaskCompleteLoading}
              classes={classes}
            />
          ),
          ...task,
        };
      case "ongoing_auth_signed":
        return {
          task_name: "Ongoing Authorization Signed",
          description:
            "Now that the treatment plan has been approved, sign the ongoing authorization.",
          note: "",
          button: !task.completed && (
            <MarkTaskCompleteButton
              buttonText="Auth Signed"
              clientId={customerDetails?.details?.client_id}
              clientTaskId={task.id}
              markClientTaskComplete={markClientTaskComplete}
              loading={markTaskCompleteLoading}
              classes={classes}
            />
          ),
          ...task,
        };
      case "ongoing_auth_submitted":
        return {
          task_name: "Ongoing Authorization Submitted",
          description:
            "Submit the ongoing authorization and treatment plan to client's primary insurance company.",
          note: "",
          button: !task.completed && (
            <MarkTaskCompleteButton
              buttonText="Auth Submitted"
              clientId={customerDetails?.details?.client_id}
              clientTaskId={task.id}
              markClientTaskComplete={markClientTaskComplete}
              loading={markTaskCompleteLoading}
              classes={classes}
            />
          ),
          ...task,
        };
      case "treatment_plan_review_scheduled":
        return {
          task_name: "Treatment Plan Review with Client Scheduled",
          description:
            "Coordinate with the client to schedule a time to review the treatment plan that has been created from the assessment.",
          note: "",
          button: !task.completed && (
            <MarkTaskCompleteButton
              buttonText="Call Scheduled"
              clientId={customerDetails?.details?.client_id}
              clientTaskId={task.id}
              markClientTaskComplete={markClientTaskComplete}
              loading={markTaskCompleteLoading}
              classes={classes}
            />
          ),
          ...task,
        };
      case "treatment_plan_review_completed":
        return {
          task_name: "Treatment Plan Review with Client Completed",
          description:
            "Review treatment plan with client. Be prepared for questions they might have.",
          note: "",
          button: !task.completed && (
            <MarkTaskCompleteButton
              buttonText="Review Complete"
              clientId={customerDetails?.details?.client_id}
              clientTaskId={task.id}
              markClientTaskComplete={markClientTaskComplete}
              loading={markTaskCompleteLoading}
              classes={classes}
            />
          ),
          ...task,
        };
      case "first_therapy_call_scheduled":
        return {
          task_name: "First Therapy Call Scheduled",
          description: "Communicate with the client to schedule their first therapy call.",
          note: "",
          button: !task.complete && userPermissions?.schedule_call && (
            <ScheduleCallButton
              onToggleScheduleClientVideo={onToggleScheduleClientVideo}
              classes={classes}
            />
          ),
          ...task,
        };
      case "ongoing_auth_received":
        return {
          task_name: "Ongoing Authorization Received",
          description:
            "Waiting for the ongoing authorization to be approved and returned by insurance company. This step will be marked complete automatically when an 'Ongoing' Insurance Authorization has been entered. ",
          note: "NOTE: If this is a Private Pay client, enter an Ongoing-Auth to activate billing codes for this client.",
          ...task,
        };
      case "first_therapy_call_completed":
        return {
          task_name: "First Therapy Call Completed",
          description:
            "Meet with the client and have thier first therapy session with AnswersNow. Once the first therapy call has been completed, the client will move from Phase 2 to Phase 3.",
          note: "",
          ...task,
        };
    }
  };
  return (
    <>
      <div className={classes.taskList}>
        <Stepper
          activeStep={activeIndex}
          orientation="vertical"
          connector={<Connector />}
          classes={{ root: classes.stepperRoot }}
        >
          {clientTaskList.map((t, index) => {
            let task = mapTaskToDisplay(t);
            let eligibility = checkTaskForPrerequisite(task);
            let daysSinceStarted, critical, warning;
            if (task.start_date && !task.completed && !task.snoozed) {
              daysSinceStarted = differenceInDays(new Date(), new Date(task.start_date));
              critical = daysSinceStarted >= task.days_until_critical;
              warning = daysSinceStarted >= t.days_until_warning && !critical;
            }
            return (
              <Step key={index} last={false}>
                <StepLabel
                  onClick={() => onClickTaskLabel(index)}
                  classes={{
                    root: classes.stepLabelRoot,
                    labelContainer: classes.labelContainer,
                  }}
                  StepIconComponent={(p) =>
                    renderStepIcon(
                      { ...p, completed: task.completed },
                      index,
                      clientTaskList.length,
                      selectedTaskIndex
                    )
                  }
                >
                  <Typography
                    component="p"
                    className={
                      index < activeIndex && index != selectedTaskIndex
                        ? classes.stepLabelCompleted
                        : index > activeIndex && index != selectedTaskIndex
                          ? classes.stepLabel
                          : classes.stepLabelActive
                    }
                    style={warning ? { color: "#D97706" } : critical ? { color: "#B91C1C" } : null}
                  >
                    {`Step ${index + 1}: ${task.task_name} ${task.snoozed ? "(SNOOZED)" : ""}`}
                  </Typography>
                </StepLabel>
                {(index === activeIndex || index === selectedTaskIndex) && (
                  <StepContent
                    TransitionProps={{ in: true }}
                    classes={{
                      root:
                        index < activeIndex && index !== selectedTaskIndex
                          ? classes.stepContentRootCompleted
                          : index > activeIndex && index !== selectedTaskIndex
                            ? classes.stepContentRoot
                            : classes.stepContentRootActive,
                      last: null,
                    }}
                    style={
                      index > activeIndex && task.completed && index != selectedTaskIndex
                        ? { marginLeft: 16 }
                        : selectedTaskIndex == clientTaskList.length - 1
                          ? { marginBottom: "-30px" }
                          : null
                    }
                  >
                    {task.completed && task.completed_date && (
                      <Typography component="p" className={classes.taskNote}>
                        Completed:{" "}
                        {format(
                          new Date(task.completed_date),
                          convertTokens("MMM D, YYYY @ hh:mm a")
                        )}
                      </Typography>
                    )}
                    {critical && (
                      <Typography
                        component="p"
                        className={classes.taskNote}
                        style={{ color: "#B91C1C" }}
                      >
                        {daysSinceStarted} days in current step
                      </Typography>
                    )}
                    {warning && (
                      <Typography
                        component="p"
                        className={classes.taskNote}
                        style={{ color: "#D97706" }}
                      >
                        {daysSinceStarted} {daysSinceStarted > 1 ? "days" : "day"} in current step
                      </Typography>
                    )}
                    {task.snoozed && task.snooze_end_date && !task.completed && (
                      <>
                        <Typography component="p" className={classes.taskNote}>
                          Snoozed until{" "}
                          {format(new Date(task.snooze_end_date), convertTokens("MMM D @ h:mm a"))}{" "}
                          by {task.snoozed_by}
                        </Typography>
                        <Typography component="p" className={classes.taskNote}>
                          Reason: {task.snooze_reason}
                        </Typography>
                      </>
                    )}
                    {task.description && (
                      <Typography component="p" className={classes.taskDescription}>
                        {task.description}
                      </Typography>
                    )}
                    {task.note && (
                      <Typography component="p" className={classes.taskNote}>
                        {task.note}
                      </Typography>
                    )}
                    {eligibility && eligibility.eligible
                      ? task.button
                      : eligibility?.message && (
                          <Typography
                            component="p"
                            className={classes.taskIneligible}
                            color="secondary"
                          >
                            {eligibility.message}
                          </Typography>
                        )}
                  </StepContent>
                )}
              </Step>
            );
          })}
        </Stepper>
      </div>
    </>
  );
};

const styles = (theme) => ({
  headingContainer: {
    position: "fixed",
    top: 64,
    width: "25%",
    height: 56,
    backgroundColor: "#a568d5",
    boxShadow: "-2px 1px 3px 1px rgba(55,55,55,.18)",
    zIndex: 2,
  },
  loadingContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 1,
  },
  taskList: {
    padding: "20px 25px 30px",
    overflow: "auto",
    display: "flex",
    flexGrow: 1,
  },

  taskListContainer: {
    position: "sticky",
    top: 120,
    width: "25%",
    height: "calc(100vh - 120px)",
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    justifyContent: "flex-start",
    backgroundColor: "#f7f3fa",
    zIndex: 1,
  },
  taskListHeading: {
    height: 100,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    paddingLeft: 25,
    paddingRight: 25,
    paddingBottom: 20,
    "& h4": {
      fontSize: 20,
      lineHeight: "24px",
      fontWeight: 500,
      color: "#2A2D39",
    },
    "& p": {
      fontSize: 15,
      lineHeight: "20px",
      fontWeight: 500,
      color: "#2A2D39",
      marginTop: 5,
    },
  },
  stepperRoot: {
    backgroundColor: "inherit",
    padding: 0,
    [theme.breakpoints.down("xl")]: {
      padding: 10,
    },
  },
  stepLabelRoot: {
    display: "flex",
    alignItems: "inherit",
    cursor: "pointer",
  },
  stepLabelCompleted: {
    fontSize: 12,
    fontWeight: "500",
    color: "#878DA5",
    marginLeft: 18,
    minHeight: 22,
    lineHeight: "20px",
    [theme.breakpoints.down("xl")]: {
      fontSize: 10,
      marginLeft: 18,
    },
  },
  stepLabelActive: {
    fontSize: 16,
    fontWeight: 500,
    marginLeft: 18,
    color: "#2A2D39",
    [theme.breakpoints.down("xl")]: {
      fontSize: 14,
      marginLeft: 12,
    },
  },
  stepLabel: {
    fontSize: 13,
    fontWeight: 500,
    color: "#69718F",
    marginLeft: 18,
    lineHeight: "23px",
    cursor: "pointer",
    [theme.breakpoints.down("xl")]: {
      fontSize: 10,
      marginLeft: 18,
    },
  },
  labelContainer: {
    display: "flex",
    alignItems: "flex-start",
  },
  stepContentRoot: {
    borderWidth: 20,
    borderColor: "#B3D3EA",
    marginTop: 0,
    marginLeft: 1,
    padding: "10px 0 20px 28px",
    [theme.breakpoints.down("xl")]: {
      "& ul": {
        paddingInlineStart: 15,
      },
    },
  },
  stepContentRootCompleted: {
    borderWidth: 26,
    borderColor: "#357BA2",
    marginTop: 0,
    marginLeft: 0,
    padding: "10px 0 20px 28px",
    [theme.breakpoints.down("xl")]: {
      "& ul": {
        paddingInlineStart: 15,
      },
    },
  },
  stepContentRootActive: {
    borderWidth: 22,
    borderColor: "#B3D3EA",
    marginTop: -10,
    marginBottom: -10,
    marginLeft: 2,
    padding: "25px 0 40px 28px",
    position: "relative",
    "&:before": {
      display: "block",
      zIndex: 5,
      content: "''",
      position: "absolute",
      left: "-12px",
      bottom: "20%",
      // transform: "translate(-50%)",
      width: 0,
      height: "70%",
      // borderTop: "solid 10px #357BA2",
      borderLeft: "dashed 1px #357BA2",
      // borderRight: "solid 5px transparent",
    },
    [theme.breakpoints.down("xl")]: {
      "& ul": {
        paddingInlineStart: 15,
      },
    },
  },
  stepContent: {
    fontSize: 16,
    // fontWeight: 500,
    [theme.breakpoints.down("xl")]: {
      fontSize: 13,
    },
  },
  stepContentInactive: {
    fontSize: 16,
    color: "rgba(0, 0, 0, 0.44)",
    [theme.breakpoints.down("xl")]: {
      fontSize: 13,
    },
  },
  stepConnectorCompleted: {
    borderTopWidth: 3,
    borderLeftWidth: 3,
    borderRadius: 1,
    borderColor: "#7235A2",
  },
  taskDescription: {
    fontSize: 14,
    fontWeight: "400",
    lineHeight: "20px",
    margin: "10px 0",
  },
  taskNote: {
    fontSize: 13,
    fontWeight: 500,
    lineHeight: "20px",
  },
  taskIneligible: {
    fontSize: 13,
    fontWeight: 500,
    lineHeight: "20px",
    marginTop: 10,
  },
  taskButton: {
    marginTop: 10,
  },
});

export default withStyles(styles)(TaskListProgress);

var MarkTaskCompleteButton = (props) => {
  const { buttonText, clientTaskId, clientId, markClientTaskComplete, classes, loading } = props;
  return (
    <LoadingButton
      onClick={() => markClientTaskComplete({ clientId, clientTaskId })}
      size="small"
      className={classes.taskButton}
      loading={loading}
    >
      {buttonText}
    </LoadingButton>
  );
};

var ResendLoginButton = (props) => {
  const { onResendLoginEmail, resendLoginSuccess, resendingLoginEmail, classes } = props;
  return (
    <>
      {resendLoginSuccess ? (
        <Typography
          component="p"
          style={{
            fontSize: 15,
            color: "#059669",
            marginLeft: 50,
            fontWeight: 500,
          }}
        >
          Sent Successfully
        </Typography>
      ) : (
        <LoadingButton
          color="secondary"
          onClick={onResendLoginEmail}
          size="small"
          startIcon={resendLoginSuccess ? <CheckIcon /> : null}
          className={classes.taskButton}
          loading={resendingLoginEmail}
        >
          Resend Login Email
        </LoadingButton>
      )}
    </>
  );
};

var AssignClinicianButton = (props) => {
  const { onToggleAssignClinician, classes } = props;
  return (
    <Button onClick={onToggleAssignClinician} size="small" className={classes.taskButton}>
      Assign
    </Button>
  );
};

const ScheduleCallButton = (props) => {
  const { onToggleScheduleClientVideo, classes } = props;
  const history = useHistory();
  const userId = useSelector(selectors.getUserId);
  const { data: schedulePageFeatureFlag } = useGetFeatureFlag(userId, "scheduling_page");
  return (
    <Button
      onClick={() => {
        if (schedulePageFeatureFlag) history.push("/clinician-schedule/create-event");
        else onToggleScheduleClientVideo(false);
      }}
      size="small"
      className={classes.taskButton}
    >
      Schedule Call
    </Button>
  );
};

var UploadTreatmentPlanButton = (props) => {
  const { onToggleTreatmentPlanDialog, loading, classes } = props;
  return (
    <LoadingButton
      onClick={onToggleTreatmentPlanDialog}
      size="small"
      loading={loading}
      className={classes.taskButton}
    >
      Upload
    </LoadingButton>
  );
};

var UpdateTreatmentPlanButton = (props) => {
  const { customerDetails, onOpenUpdateTreatmentPlan, classes, loading } = props;
  return (
    <LoadingButton
      onClick={() =>
        onOpenUpdateTreatmentPlan(customerDetails.treatmentPlans.find((plan) => !plan.approved))
      }
      size="small"
      loading={loading}
      className={classes.taskButton}
    >
      Update Plan
    </LoadingButton>
  );
};

var MarkAssessmentCompleteButton = (props) => {
  const { onToggleAssessmentComplete, classes } = props;
  return (
    <Button onClick={onToggleAssessmentComplete} size="small" className={classes.taskButton}>
      Mark Assessment Complete
    </Button>
  );
};
