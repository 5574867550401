import { call, put, all, select, takeEvery } from "redux-saga/effects";
import actions from "../actions";
import { getAppointmentChangeLogs } from "../api/appointmentChangeLog";

function* getAppointmentChangeLogsAsync({ payload }) {
  try {
    const result = yield call(getAppointmentChangeLogs, payload);
    yield put(actions.getAppointmentChangeLogsSuccess(result.data));
  } catch (e) {
    yield put(actions.getAppointmentChangeLogsFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

export default function* startSaga() {
  yield takeEvery(actions.GET_APPOINTMENT_CHANGE_LOGS, getAppointmentChangeLogsAsync);
}
