import React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import withStyles from "@mui/styles/withStyles";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import AssignmentOutlined from "@mui/icons-material/AssignmentOutlined";

const MIN_BILLABLE_SESSION = 8 * 60; // as seconds
const STANDARD_BILLABLE_SESSION = 15 * 60; // as seconds

const SessionTimer = (props) => {
  const { classes, isClinician, clinicianPassedTime, isSecondary, secondaryClinicianPassedTime } =
    props;

  const msToSec = (ms) => Math.floor(ms / 1000);
  const primarySessionTime = msToSec(clinicianPassedTime);
  const secondarySessionTime = msToSec(secondaryClinicianPassedTime);

  // depends on if is primary or secondary clinician
  const billableTime = !isSecondary ? primarySessionTime : secondarySessionTime;
  const wholeBillableUnits = Math.floor(billableTime / STANDARD_BILLABLE_SESSION);
  const roundedBillableUnit =
    billableTime % STANDARD_BILLABLE_SESSION > MIN_BILLABLE_SESSION ? 1 : 0;
  const totalBillableUnits = wholeBillableUnits + roundedBillableUnit;
  const reachedBillableUnit = billableTime % STANDARD_BILLABLE_SESSION >= MIN_BILLABLE_SESSION;
  const progressValue =
    ((billableTime % STANDARD_BILLABLE_SESSION) / STANDARD_BILLABLE_SESSION) * 100;

  const displayTime = (timeInSeconds) => {
    const min = `${Math.floor(timeInSeconds / 60)}`.padStart(2, 0);
    const sec = `${Math.floor(timeInSeconds % 60)}`.padStart(2, 0);
    return `${min}:${sec}`;
  };

  return (
    <div className={classes.sessionTimerContainer}>
      <div className={classes.sessionTimerContent}>
        <div className={classes.iconLabel}>
          <TimerOutlinedIcon className={classes.timerIcon} />
          Session:&nbsp;
          <b style={{ width: 70 }}>{displayTime(primarySessionTime)}</b>
          <br />
        </div>
        {isClinician && isSecondary && (
          <div className={classes.iconLabel}>
            <TimerOutlinedIcon
              style={{
                fontSize: 16,
                marginRight: 3,
              }}
            />
            My Session:&nbsp;<b>{displayTime(secondarySessionTime)}</b>
            <br />
          </div>
        )}
        {isClinician && (
          <>
            <div className={classes.iconLabel}>
              <AssignmentOutlined
                style={{
                  fontSize: 16,
                  marginRight: 3,
                }}
              />
              Billable Units:&nbsp;<b>{totalBillableUnits}</b>
              <br />
            </div>
            <div className={classes.progressBarContainer}>
              <div
                className={reachedBillableUnit ? classes.progressBarBillable : classes.progressBar}
              >
                <LinearProgress variant="determinate" value={progressValue} />
              </div>
              <div>Next Unit</div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const styles = (theme) => ({
  sessionTimerContainer: {
    borderRadius: 4,
    backgroundColor: "rgba(239, 239, 240, 0.6)",
    position: "absolute",
    top: 24,
    left: 24,
    width: 288,
    padding: 16,
    zIndex: 200,
    [theme.breakpoints.down("sm")]: {
      width: "fit-content",
      padding: 10,
      top: 10,
      left: 10,
    },
  },
  timerIcon: {
    fontSize: 16,
    marginRight: 3,
    [theme.breakpoints.down("sm")]: {
      size: 14,
    },
  },
  iconLabel: {
    display: "flex",
    alignItems: "center",
    width: 156,
    minWidth: "fit-content",
    paddingBottom: 3,
    [theme.breakpoints.down("sm")]: {
      width: "fit-content",
    },
  },
  progressBarContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: 256,
  },
  progressBar: {
    width: 166,
    "& .MuiLinearProgress-root": {
      borderRadius: 5,
    },
    "& .MuiLinearProgress-colorPrimary": {
      backgroundColor: "lightGray",
    },
  },
  progressBarBillable: {
    width: 166,
    "& .MuiLinearProgress-root": {
      borderRadius: 5,
    },
    "& .MuiLinearProgress-colorPrimary": {
      backgroundColor: "lightGray",
    },
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "green",
    },
  },
  progressBarLabel: {
    marginLeft: 10,
    width: 80,
  },
});

export default withStyles(styles)(SessionTimer);
