import React from "react";
import { withRouter } from "react-router";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Logo from "../../assets/answers_now_logo_dark_purple.png";
import SignInFlowProvider from "./Flows";

const Signin = (props) => {
  const {
    classes,
    username,
    password,
    otp,
    otpError,
    redirected,
    loginMethod,
    receivedParamsFromLink,
    urlParams,
    signinFailure,
    notConfirm,
    needsReset,
    superOnChange,
    onSubmit,
    showPassword,
    handleMouseDownPassword,
    toggleShowPasswords,
    goToForgot,
    signupInfoOpen,
    signinFailureMessage,
    renderErrorMessage,
    toggleContactForm,
    navigateToGetAConsultation,
    startSignin,
  } = props;

  const year = new Date().getFullYear();

  return (
    <>
      <div className={classes.container}>
        <div className={classes.signinFormContainer}>
          <CssBaseline />
          <img src={Logo} className={classes.signinLogo} />
          <div className={classes.signinForm}>
            <SignInFlowProvider
              classes={classes}
              username={username}
              password={password}
              otp={otp}
              redirected={redirected}
              loginMethod={loginMethod}
              passIsVisibile={showPassword}
              handleSubmit={onSubmit}
              handleForgotPassword={goToForgot}
              handleTogglePasswordVisibility={toggleShowPasswords}
              renderErrorMessage={renderErrorMessage}
              urlParams={urlParams}
              signinFailure={signinFailure}
              notConfirm={notConfirm}
              needsReset={needsReset}
              superOnChange={superOnChange}
              otpError={otpError}
              startSignin={startSignin}
              receivedParamsFromLink={receivedParamsFromLink}
            />
          </div>
        </div>
      </div>
      <div
        className={classes.footerContainer}
        style={{ width: "100%", justifyContent: "center", padding: 10 }}
      >
        <Typography component="p" className={classes.footerText}>
          Version: {APP_VERSION} Copyright &copy; {year} AnswersNow &#x2022; All rights reserved
        </Typography>
      </div>
    </>
  );
};

export default withRouter(Signin);
