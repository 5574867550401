import React from "react";
import { Box, Typography } from "@mui/material";
import { ChartBar } from "@phosphor-icons/react";

import VinelandBanner from "./VinelandBanner";
import { description, assessmentCard, space5, space3 } from "./styled";

export default function EmptyAssessments() {
  return (
    <Box flexGrow={4} p={space5}>
      <Typography variant="body2" sx={description}>
        Manage clinical and self-reported assessment records
      </Typography>

      <Box sx={assessmentCard}>
        <VinelandBanner noAssessments={true} onClickChartButton={() => {}} />

        <Typography component="h3" variant="body2" align="center" p={space5} gutterBottom={false}>
          <ChartBar
            size={20}
            weight="duotone"
            style={{ verticalAlign: "top", marginRight: space3 }}
          />
          <span>No assessments available</span>
        </Typography>
      </Box>
    </Box>
  );
}
