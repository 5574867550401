import React, { useState, useRef, useEffect } from "react";
import withStyles from "@mui/styles/withStyles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CircularProgress from "@mui/material/CircularProgress";

const DetailAccordion = (props) => {
  const [accordionExpanded, setAccordionExpanded] = useState(false);
  const {
    classes,
    loading,
    title,
    minHeight,
    maxHeight,
    enableScroll,
    forwardedRef,
    children,
    onAccordionExpanded,
  } = props;

  useEffect(() => {
    if (accordionExpanded) {
      onAccordionExpanded?.();
    }
  }, [accordionExpanded]);

  let style = {};
  style.minHeight = minHeight ? minHeight : null;
  style.maxHeight = maxHeight ? maxHeight : null;
  style.overflowY = enableScroll ? "auto" : null;

  return (
    <div className={classes.root} ref={forwardedRef}>
      <Accordion
        elevation={0}
        onChange={() => {
          setAccordionExpanded(!accordionExpanded);
        }}
        className={classes.accordion}
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary
          aria-controls="block-content"
          className={classes.accordionSummary}
          expandIcon={
            accordionExpanded ? (
              <RemoveIcon className={classes.expandIcon} />
            ) : (
              <AddIcon className={classes.expandIcon} />
            )
          }
        >
          <Typography component="p" className={classes.accordionTitle}>
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={style}>
          {loading ? (
            <div className={classes.loadingContainer}>
              <CircularProgress size={25} color="primary" style={{ margin: 10 }} />
              <Typography>Loading</Typography>
            </div>
          ) : (
            children
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

const styles = (theme) => ({
  root: {
    width: "100%",
    marginBottom: "16px",
    // Commented out because it was causing the caledar on DatePickers to be behind the accordion
    // "& .Mui-expanded": {
    //   transform: "rotate(0deg)",
    // },
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
    "& .MuiAccordionDetails-root": {
      display: "flex",
      flexDirection: "column",
      padding: "30px 50px 0px",
    },
  },
  accordion: {
    backgroundColor: "#fafafa",
  },
  accordionSummary: {
    height: 65,
    backgroundColor: "#a568d6",
    borderRadius: 10,
    padding: "0px 50px",
    "& expandIcon": {
      color: "red",
    },
  },
  accordionTitle: {
    fontSize: 18,
    fontWeight: 400,
    color: "#fff",
  },
  expandIcon: {
    color: "#fff",
  },
  loadingContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
});

export default withStyles(styles)(DetailAccordion);
