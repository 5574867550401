export const formatPhoneNumber = (number) => {
  const cleaned = ("" + number).replace(/\D/g, "");
  let formatted = "";

  for (let i = 0; i < Math.min(cleaned.length, 10); i++) {
    if (i === 3 || i === 6) {
      formatted += "-";
    }
    formatted += cleaned[i];
  }

  return formatted;
};
