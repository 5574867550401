import { call, put, select, takeEvery } from "redux-saga/effects";
import actions from "../actions";
import {
  getUser,
  getUserPermissions,
  updateUserRoles,
  resetUserPassword,
  getUserDetails,
} from "../api/user";
import { getCurrentPage, getGroups, getUserPermissionsList } from "../selectors";
import { getClinicianStatus } from "../api/clinician";
import { toggleCustomerActivation } from "../api/customers";
import { detect } from "detect-browser";
import publicIp from "public-ip";

function* getUserAsync() {
  try {
    const result = yield call(getUser);
    const groups = yield select(getGroups);
    const currentPage = yield select(getCurrentPage);
    if (groups && groups.includes("Clinicians") && currentPage !== "cliniciandetail") {
      const status = yield call(getClinicianStatus, result.data.clinician_id);
      yield put(actions.getClinicianStatusSuccess(status.data));
    }
    if (groups && groups.includes("Caregivers")) {
      yield put(actions.getParentInfo());
    }
    const permissions = yield select(getUserPermissionsList);
    if (!permissions) {
      yield put(actions.getUserPermissions(result.data.id));
    }
    yield put(actions.getUserSuccess(result.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* getUserPermissionsAsync({ payload }) {
  try {
    const permissions = yield call(getUserPermissions, payload);
    yield put(actions.getUserPermissionsSuccess(permissions.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* updateUserRolesAsync({ payload }) {
  try {
    yield call(updateUserRoles, payload);
    yield put(actions.updateUserRolesSuccess());
  } catch (e) {
    yield put(actions.updateUserRolesFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* resetUserPasswordAsync({ payload }) {
  try {
    yield call(resetUserPassword, payload);
    yield put(actions.resetUserPasswordSuccess());
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getSearchUserDetailsAsync({ payload }) {
  try {
    const details = yield call(getUserDetails, payload);
    yield put(actions.getSearchUserDetailsSuccess(details.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* toggleUserActivationAsync({ payload }) {
  try {
    yield call(toggleCustomerActivation, payload);
    yield put(actions.toggleUserActivationSuccess());
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

export default function* userSaga() {
  yield takeEvery(actions.GET_USER, getUserAsync);
  yield takeEvery(actions.GET_USER_PERMISSIONS, getUserPermissionsAsync);
  yield takeEvery(actions.UPDATE_USER_ROLES, updateUserRolesAsync);
  yield takeEvery(actions.RESET_USER_PASSWORD, resetUserPasswordAsync);
  yield takeEvery(actions.GET_SEARCH_USER_DETAILS, getSearchUserDetailsAsync);
  yield takeEvery(actions.TOGGLE_USER_ACTIVATION, toggleUserActivationAsync);
}
