import React from "react";
import { Box, Button, TextField, MenuItem } from "@mui/material";
import { Plus } from "@phosphor-icons/react";

export const Filter = ({
  theme,
  // handlers
  handleToggleInsuranceFormDialog,
  handleSelectedInsuranceCompanyChange,
  handleSelectedStateChange,
  handleAddNewInsurance,
  // values
  selectedInsuranceCompany = "",
  selectedState = "",
  insuranceCompanyOptions = [],
  stateOptions = [],
  userPermissions,
}) => {
  const canAddInsurance = !!userPermissions?.edit_clinician_insurances;
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        "& .filterContainer": {
          width: "100%",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-between",
          alignItems: "flex-end",
          padding: "16px 24px",
          gap: "16px",
          "& .selectGroup": {
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            gap: "16px",
          },
        },
      }}
    >
      <div className="filterContainer">
        <div className="selectGroup">
          {handleSelectedInsuranceCompanyChange && (
            <TextField
              label="Insurance Company"
              value={selectedInsuranceCompany}
              onChange={handleSelectedInsuranceCompanyChange}
              sx={{ width: "256px" }}
              select
            >
              {insuranceCompanyOptions?.map(({ name, value }, index) => (
                <MenuItem key={`${index}`} name={name} value={value}>
                  {name}
                </MenuItem>
              ))}
            </TextField>
          )}
          {handleSelectedStateChange && (
            <TextField
              label="State"
              variant="outlined"
              value={selectedState}
              onChange={handleSelectedStateChange}
              size="small"
              select
              sx={{ width: 256 }}
            >
              {stateOptions?.map(({ name, value }, index) => (
                <MenuItem key={`${index}`} name={name} value={value}>
                  {name}
                </MenuItem>
              ))}
            </TextField>
          )}
        </div>
        {canAddInsurance && handleToggleInsuranceFormDialog && (
          <Button
            sx={{
              height: "36px",
              fontSize: "14px",
              width: "235px",
              boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.15)",
            }}
            color="secondary"
            startIcon={<Plus />}
            fullWidth
            onClick={handleAddNewInsurance}
          >
            Add Insurance Credential
          </Button>
        )}
      </div>
    </Box>
  );
};
