import React from "react";
import withStyles from "@mui/styles/withStyles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import { format } from "date-fns";

import { convertTokens } from "@date-fns/upgrade/v2";

const CallNotStartedDialog = (props) => {
  const {
    classes,
    sessionDetails,
    open,
    callNotStartedReason,
    onChangeCallNotStartedReason,
    onSubmitCallNotStartedReason,
    loading,
  } = props;

  return (
    <Dialog
      PaperProps={{
        style: {
          width: 600,
          maxWidth: "95%",
          padding: "20px 20px 30px 40px",
        },
      }}
      open={open}
    >
      <DialogTitle className={classes.dialogTitle}>
        Your scheduled session was never started!
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.callNotStartedDetails}>
          <div className={classes.callNotStartedDetailRow}>
            <Typography component="p">Client:</Typography>
            <Typography component="h6">{sessionDetails.client_name}</Typography>
          </div>
          <div className={classes.callNotStartedDetailRow}>
            <Typography component="p">Date:</Typography>
            <Typography component="h6">
              {format(new Date(sessionDetails.scheduled_date), convertTokens("dddd M/D/YYYY"))}
            </Typography>
          </div>
          <div className={classes.callNotStartedDetailRow}>
            <Typography component="p">Time:</Typography>
            <Typography component="h6">
              {format(new Date(sessionDetails.scheduled_date), convertTokens("h:mm A"))}
            </Typography>
          </div>
        </div>
        <FormLabel component="legend" className={classes.formLabel} style={{ marginBottom: 10 }}>
          What happened?
        </FormLabel>

        <RadioGroup
          aria-label="callNotStartedReason"
          name="callNotStartedReason"
          value={callNotStartedReason}
          onChange={(e) => onChangeCallNotStartedReason(e)}
        >
          <FormControlLabel
            value={"cancel"}
            control={<Radio color="primary" size="small" />}
            label={
              <Typography component="p" className={classes.radioLabel}>
                Session was canceled
              </Typography>
            }
          />
          <FormControlLabel
            value={"no-show"}
            control={<Radio color="primary" size="small" />}
            label={
              <Typography component="p" className={classes.radioLabel}>
                Client never showed up
              </Typography>
            }
          />
        </RadioGroup>
      </DialogContent>
      <DialogActions className={classes.dialogButtons}>
        <LoadingButton
          onClick={onSubmitCallNotStartedReason}
          variant="outlined"
          style={{ width: 51, height: 34 }}
          disabled={!callNotStartedReason}
          size="small"
          loading={loading}
        >
          {callNotStartedReason == "no-show" ? "Finish" : "Next"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
const styles = (theme) => ({
  closeIconContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  closeIcon: {
    fontSize: 34,
    color: "#15171d",
    padding: 10,
  },
  dialogTitle: {
    marginBottom: 5,
    marginTop: 30,
    display: "flex",
    flexDirection: "column",
    color: "#3F4456",
    padding: "0 0 10px 24px",
    "& h2": {
      fontSize: 24,
      lineHeight: "32px",
      fontWeight: 500,
    },
  },
  dialogContent: {
    width: "100%",
    height: "fit-content",
    "& p": {
      fontSize: 14,
      color: "#3f4456",
      fontWeight: 500,
      lineHeight: "20px",
    },
  },
  callNotStartedDetails: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "100%",
    marginBottom: 30,
    marginTop: 20,
  },
  callNotStartedDetailRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: 15,
    "& p": {
      fontSize: 22,
      fontWeight: "400",
      width: 70,
      marginRight: 10,
      color: "#3F4456",
    },
    "& h6": {
      fontSize: 22,
      fontWeight: "500",
      color: "#2A2D39",
    },
  },
  reasonTextfield: {
    margin: "10px 0px -5px",
    "& fieldset": {
      borderRadius: 7,
      border: "0.5px solid #C3C6D2",
    },
    "& .MuiOutlinedInput-root": {
      fontSize: 14,
      color: "#3F4456",
      fontWeight: 500,
      letterSpacing: "-2%",
      "&:hover fieldset": {
        border: "1px solid #C3C6D2",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid #C3C6D2",
      },
    },
  },
  dialogButtons: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 20,
    marginBottom: 30,
    "& button": {
      fontSize: 14,
    },
  },
  formLabel: {
    marginBottom: 5,
    fontSize: 22,
    color: "#3F4456",
    lineHeight: "24px",
  },
  radioLabel: {
    fontSize: 14,
    fontWeight: "600 !important",
    color: "#3F4456",
  },
});

export default withStyles(styles)(CallNotStartedDialog);
