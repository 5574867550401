import React from "react";
import { dateWithoutTimezone } from "../../../utils/dateWithoutTimezone";
import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import TimezonePicker from "../../../elements/TimezonePicker";
import FormControl from "@mui/material/FormControl";
import { STATES } from "../../../constants";
import MenuItem from "@mui/material/MenuItem";
import ANDatePicker from "elements/ANDatePicker";
import SectionBox from "../../../elements/SectionBox";
import ANFormView from "../../../elements/Forms/ANFormView";
import basicInfoConfig from "./BasicInfoConfig";

const BasicInfo = (props) => {
  const {
    classes,
    editMode,
    userPermissions,
    basicInfo,
    onChange,
    onChangeNumber,
    onChangeDate,
    onChangeTaxNumber,
    firstName,
    lastName,
    fullLegalName,
    email,
    phone,
    city,
    state,
    zip,
    dateOfBirth,
    address1,
    address2,
    gender,
    taxNumber,
    timezone,
    stateIdNnumber,
    pronouns,
  } = props;

  return (
    <SectionBox
      title="Personal Information"
      description={"Manage and change clinician personal information on file"}
      columnDirection
    >
      {editMode && userPermissions?.edit_clinician_basic_info ? (
        <>
          <Grid container spacing={7}>
            <Grid item xs={12} sm={6} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
              <TextField
                id="firstName"
                label="First Name"
                onChange={onChange("firstName")}
                value={firstName}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
              <TextField
                id="lastName"
                label="Last Name"
                onChange={onChange("lastName")}
                value={lastName}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
              <TextField
                id="fullLegalName"
                label="Full Legal Name"
                onChange={onChange("fullLegalName")}
                value={fullLegalName}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
              <TextField
                label="Email"
                id="email"
                name="email"
                onChange={onChange("email")}
                value={email}
                variant="outlined"
                size="small"
                disabled={!userPermissions?.update_clinician_email_address}
                fullWidth
                helperText={
                  !userPermissions?.update_clinician_email_address
                    ? `Can't edit. Reach out to support if a change is needed.`
                    : ``
                }
                className={classes.disabledEmail}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
              <TextField
                id="phone"
                label="Phone Number"
                onChange={onChangeNumber("phone")}
                value={phone}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
              <TextField
                id="address1"
                label="Address"
                onChange={onChange("address1")}
                value={address1}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
              <TextField
                id="address2"
                label="Address 2"
                onChange={onChange("address2")}
                value={address2}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
              <TextField
                id="city"
                label="City"
                onChange={onChange("city")}
                value={city}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
              <TextField
                select
                id="state"
                name="state"
                autoComplete="off"
                value={state}
                onChange={onChange("state")}
                label="State"
                fullWidth
              >
                <MenuItem disabled value={""} />
                {Object.keys(STATES).map((key) => {
                  return (
                    <MenuItem key={key} value={key}>
                      {STATES[key]}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={4} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
              <TextField
                id="zip"
                label="ZIP Code"
                onChange={onChange("zip")}
                value={zip}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="gender"
                label="Gender"
                select
                onChange={onChange("gender")}
                value={gender}
                fullWidth
              >
                <MenuItem disabled value={""} />
                <MenuItem value="Female">Female</MenuItem>
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="non_binary">Non-binary</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
              <TextField
                id="pronouns"
                select
                label="Pronouns"
                onChange={onChange("pronouns")}
                value={pronouns}
                fullWidth
              >
                <MenuItem value="" disabled />
                <MenuItem value="she/her">She/Her</MenuItem>
                <MenuItem value="he/him">He/Him</MenuItem>
                <MenuItem value="they/them">They/Them</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
              <FormControl margin="none" fullWidth>
                <ANDatePicker
                  value={new Date(dateOfBirth) || null}
                  onChange={(date) => onChangeDate(date, "dateOfBirth")}
                  label="Date of Birth"
                  format="PP" //"MMM d, yyyy"
                  disableFuture
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
              <TextField
                id="taxNumber"
                label="Social Security Number"
                value={taxNumber}
                onChange={onChangeTaxNumber("taxNumber")}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TimezonePicker
                timezone={timezone}
                onTimezoneChange={onChange("timezone")}
                classes={classes}
                outlined={true}
                fullWidth
                label="Time Zone"
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
              <TextField
                id="stateIdNnumber"
                label="Driver's License Number"
                value={stateIdNnumber}
                onChange={onChange("stateIdNnumber")}
                fullWidth
              />
            </Grid>
            {/*//TODO: For employment deatils*/}
            {/*{userPermissions?.approve_clinician_application && (*/}
            {/*  <Grid item xs={12}>*/}
            {/*    <TextField*/}
            {/*      select*/}
            {/*      id="employmentType"*/}
            {/*      label="Employment Type"*/}
            {/*      value={employmentType}*/}
            {/*      onChange={onChange("employmentType")}*/}
            {/*      InputLabelProps={{ shrink: true }}*/}
            {/*      fullWidth*/}
            {/*    >*/}
            {/*      <MenuItem disabled value={""}>*/}
            {/*        Select Employment Type*/}
            {/*      </MenuItem>*/}
            {/*      <MenuItem value={"W2"}>Full Time</MenuItem>*/}
            {/*      <MenuItem value={"1099"}>Contract</MenuItem>*/}
            {/*    </TextField>*/}
            {/*  </Grid>*/}
            {/*)}*/}
          </Grid>
        </>
      ) : (
        <ANFormView
          data={{
            firstName: basicInfo?.first_name || "",
            lastName: basicInfo?.last_name || "",
            fullLegalName: basicInfo?.full_legal_name || "",
            email: basicInfo?.email || "",
            phone: basicInfo?.phone || "",
            address1: basicInfo?.street_1 || "",
            address2: basicInfo?.street_2 || "",
            city: basicInfo?.city || "",
            state: basicInfo?.state || "",
            zip: basicInfo?.zip_code || "",
            dateOfBirth: basicInfo?.date_of_birth
              ? dateWithoutTimezone(basicInfo?.date_of_birth)
              : null,
            timezone: basicInfo?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
            gender: basicInfo?.gender || "",
            taxNumber: basicInfo?.last_four ? `XXX-XX-${basicInfo?.last_four}` : "",
            filename: basicInfo?.image_name || "",
            licenseState: basicInfo?.dl_state || "",
            licenseNumber: basicInfo?.state_id_number || "",
            employmentType: basicInfo?.employment_type,
            pronouns: basicInfo?.pronouns,
          }}
          config={basicInfoConfig}
        ></ANFormView>
      )}
    </SectionBox>
  );
};

const styles = (theme) => ({
  timezone: {
    fontSize: 13,
    width: "100%",
    "& label.Mui-focused": {
      color: "#885FD5",
    },
    "& fieldset": {
      border: "2px solid #c1c1c1",
      borderRadius: 8,
    },
    "&:hover": {
      "& label": {
        color: "#885FD5",
      },
    },
    "& .MuiOutlinedInput-root": {
      height: 42,
      width: "100%",
      "& .MuiInputBase-input": {
        fontSize: 12,
        color: "#565656",
        fontWeight: 500,
      },
      "& .MuiOutlinedInput-input": {
        padding: 14,
      },
      "&:hover fieldset": {
        border: "2px solid #885FD5",
      },
      "&.Mui-focused fieldset": {
        border: "2px solid #885FD5",
      },
    },
    "& .MuiFormHelperText-root": {
      margin: "0 5px",
      color: "red",
    },
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    margin: "-10px 0 10px",
  },
  licenseContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 20px 10px",
  },
  licenseInfo: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "flex-start",
  },
  licenseInfoTitle: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: ".42px",
  },
  licenseInfoText: {
    fontSize: 16,
    letterSpacing: ".42px",
  },
  licenseButtons: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  datePopper: {
    zIndex: 1000,
  },
  success: {
    width: "100%",
    textAlign: "center",
    color: "green",
    marginBottom: 10,
  },
  error: {
    width: "100%",
    textAlign: "center",
    color: "red",
    marginBottom: 10,
  },
  divider: {
    height: 0,
    width: "100%",
    borderBottom: "2px solid #cbcbcb",
    margin: "10px 0",
  },
});

export default withStyles(styles)(BasicInfo);
