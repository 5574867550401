import React from "react";
import {
  Box,
  Badge,
  Avatar,
  Popover,
  Button,
  IconButton,
  MenuList,
  MenuItem,
  ListItemIcon,
} from "@mui/material";
import { CaretDown, CaretUp, Bell, Check } from "@phosphor-icons/react";
import ClientSearch from "components/ClientSearch";
import AvatarInitials from "./AvatarInitials";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/styles";

export const Tools = ({
  handleSearchChange,
  handleBellClicked,
  unreadNotifications,
  userAvatarSrc,
  menuItems,
  userFullName,
  userInitials,
  userPermissions,
}) => {
  const anchorEl = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const [openSubMenu, setOpenSubMenu] = React.useState(false);
  const [anchorElSubMenu, setAnchorElSubMenu] = React.useState(false);
  const [subMenuItems, setSubMenuItems] = React.useState([]);
  const theme = useTheme();

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClickSubMenu = () => {
    setOpenSubMenu(!openSubMenu);
  };

  const handleMenuItemClicked = (clickHandler) => {
    setOpen(false);
    clickHandler();
  };

  const handleEventMenuItemClicked = (event, items, clickHandler) => {
    setAnchorElSubMenu(event.currentTarget);
    setSubMenuItems(items);
    setOpenSubMenu(true);
    clickHandler();
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      width={"100%"}
      justifyContent={"space-between"}
      paddingTop={"16px"}
      paddingBottom={"16px"}
    >
      {userPermissions?.search_clients ? (
        <ClientSearch onSelectSearchOption={handleSearchChange} />
      ) : (
        <div />
      )}
      <Box
        minWidth={"50%"}
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"flex-end"}
      >
        {handleBellClicked && (
          <IconButton sx={{ marginRight: 2 }} onClick={handleBellClicked}>
            <Badge badgeContent={unreadNotifications} color="primary" variant="dot">
              <Bell />
            </Badge>
          </IconButton>
        )}

        <Button
          ref={anchorEl}
          startIcon={
            userAvatarSrc || !userInitials ? (
              <Avatar src={userAvatarSrc} sx={{ width: 24, height: 24 }} />
            ) : (
              <AvatarInitials userInitials={userInitials} />
            )
          }
          endIcon={open ? <CaretUp size={20} /> : <CaretDown size={20} />}
          variant="text"
          onClick={handleClick}
          color="inherit"
          // sx={{
          //   height: 24,
          //   color: "#31333B",
          //   fontSize: 14,
          //   fontWeight: 500,
          //   letterSpacing: ".4px",
          // }}
        >
          {userFullName}
        </Button>
        <Popover
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={open || false}
          anchorEl={anchorEl.current}
          onClose={handleClick}
          PaperProps={{
            style: { width: "150px", minWidth: "fit-content" },
          }}
        >
          <MenuList>
            {menuItems.map(
              (
                {
                  value,
                  label,
                  disabled = undefined,
                  icon = "",
                  divider = false,
                  typography = false,
                  clickHandler,
                  endIcon = "",
                  subMenuItems = null,
                },
                i
              ) => (
                <MenuItem
                  key={i}
                  value={value}
                  onClick={(event) =>
                    !subMenuItems
                      ? handleMenuItemClicked(clickHandler)
                      : handleEventMenuItemClicked(event, subMenuItems, clickHandler)
                  }
                  disabled={disabled}
                  divider={divider}
                >
                  <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {icon !== "" ? (
                        <ListItemIcon>
                          {React.createElement(icon, {
                            weight: "duotone",
                            color: "text.secondary",
                          })}
                        </ListItemIcon>
                      ) : null}
                      {typography ? (
                        <Typography
                          variant="caption"
                          color="text.secondary"
                        >{`${label}`}</Typography>
                      ) : (
                        label
                      )}
                    </Box>
                    {endIcon !== "" ? (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {React.createElement(endIcon, {
                          color: "text.secondary",
                        })}
                      </Box>
                    ) : null}
                  </Box>
                </MenuItem>
              )
            )}
          </MenuList>
        </Popover>
        <Popover
          anchorEl={anchorElSubMenu}
          open={openSubMenu || false}
          onClose={handleClickSubMenu}
          anchorOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          PaperProps={{
            style: { minWidth: "264px" },
          }}
          style={{
            paddingTop: "2px",
            padding: "2px",
          }}
        >
          <MenuList>
            {subMenuItems &&
              subMenuItems.map(
                (
                  {
                    label,
                    avatar = false,
                    avatarUrl = undefined,
                    avatarInitials = undefined,
                    clickHandler,
                    checked,
                  },
                  i
                ) => (
                  <MenuItem
                    onClick={() => {
                      clickHandler();
                    }}
                    onClose={handleClickSubMenu}
                    key={i}
                  >
                    {avatar && (
                      <Avatar src={avatarUrl} sx={{ mr: theme.spacing(3) }} size="24px">
                        {avatarInitials}
                      </Avatar>
                    )}
                    <Typography flexGrow={1} variant="body2">
                      {label}
                    </Typography>
                    {checked && (
                      <Check size={24} color={theme.palette.primary.main} weight="regular" />
                    )}
                  </MenuItem>
                )
              )}
          </MenuList>
        </Popover>
      </Box>
    </Box>
  );
};
