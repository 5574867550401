import { ConsoleLogger, LogLevel, MultiLogger } from "amazon-chime-sdk-js";
import { createVideoCallPostLogger } from "utils/securerequest";

let logLevel = process.env.NODE_ENV === "development" ? LogLevel.DEBUG : LogLevel.WARN;
window.log = (level = LogLevel.WARN) => {
  logLevel = level;
};
export function setLogLevel(level = LogLevel.WARN) {
  logLevel = level;
}

let consoleLogger = console.log;
export function setConsoleLogger(newLogger) {
  consoleLogger = newLogger;
}

let postLogger;
export function setPostLogger(newLogger) {
  postLogger = newLogger;
}

export function createLogger(key) {
  return (...args) => {
    if (postLogger) {
      postLogger(args.map(JSON.stringify).join(" "));
    }
    if (logLevel <= LogLevel.DEBUG) {
      if (typeof args[0] === "string")
        consoleLogger("%s %s " + args.shift(), new Date().toISOString(), `[${key}]`, ...args);
      else consoleLogger(new Date().toISOString(), `[${key}]`, ...args);
    }
  };
}
export const log = createLogger("ANOW");

/**
 * @param {string} name
 * @param {number} videoCallId
 * @param {import('amazon-chime-sdk-js').POSTLoggerOptions} postLoggerOpts
 */
export async function createMultiLogger(name, videoCallId, postLoggerOpts) {
  const loggers = [];
  if (process.env.NODE_ENV !== "production") {
    loggers.push(new ConsoleLogger(name, LogLevel.WARN));
  }

  if (process.env.NODE_ENV !== "development") {
    const pl = await createVideoCallPostLogger(videoCallId, postLoggerOpts);
    loggers.push(pl);
    setPostLogger(pl.warn.bind(pl));
  }

  const logger = new MultiLogger(...loggers);
  return logger;
}
