import React, { Component } from "react";
import styles from "../VideoChat/styles";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import actions from "../../actions";
import {
  getLogin,
  getSignedinState,
  getStaticPageDataError,
  getStaticPageDataErrorMessage,
  getStaticPageDataLoaded,
  getStaticPageVideoKey,
  getStaticPageVideoKeyLoaded,
} from "../../selectors";
import CircularProgress from "@mui/material/CircularProgress";

const mapStateToProps = (state) => ({
  login: getLogin(state),
  staticPageLoaded: getStaticPageDataLoaded(state),
  staticPageVideoKey: getStaticPageVideoKey(state),
  staticPageVideoKeyLoaded: getStaticPageVideoKeyLoaded(state),
  staticPageDataError: getStaticPageDataError(state),
  staticPageDataErrorMessage: getStaticPageDataErrorMessage(state),
  signedInState: getSignedinState(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getClientStaticPage: actions.getClientStaticPage,
      getUser: actions.getUser,
      setStaticPageState: actions.setStaticPageState,
      signout: actions.signOut,
    },
    dispatch,
  );

class VideoChatStaticPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientId: null,
      noUpcomingCall: false,
    };
  }

  componentDidMount() {
    this.props.getClientStaticPage(this.props.match.params.clientid);
    this.setState({ clientId: this.props.match.params.clientid });
  }

  componentDidUpdate(prevProps) {
    if (
      (!prevProps.signedInState || !prevProps.staticPageVideoKeyLoaded) &&
      this.props.signedInState &&
      this.props.staticPageVideoKeyLoaded
    ) {
      this.props.setStaticPageState(this.state.clientId);
    }
    if (
      !prevProps.staticPageLoaded &&
      this.props.staticPageLoaded &&
      !this.props.staticPageDataError
    ) {
      if (this.props.staticPageVideoKey) {
        this.props.history.push(`/video/${this.props.staticPageVideoKey}`);
      } else {
        this.setState({ noUpcomingCall: true });
      }
      this.props.getUser();
    }
  }

  render() {
    const { classes, staticPageLoaded, staticPageDataError, staticPageDataErrorMessage } =
      this.props;
    const { noUpcomingCall } = this.state;
    return (
      <div className={classes.videoChatContainer}>
        <div className={classes.mainMediaContainer}>
          <div className={classes.waitingMessageContainer}>
            <div className={classes.testMedia} id="container">
              <div className={classes.testInfo}>
                <Typography component="h1" className={classes.lobbyTitle}></Typography>
                <div />
                {(noUpcomingCall || staticPageDataError) && (
                  <div
                    className={classes.infoContainer}
                    style={{ height: 150, justifyContent: "flex-end" }}
                  >
                    {staticPageDataError && (
                      <Typography
                        component="p"
                        className={classes.lobbySubtitle}
                        style={{ fontWeight: 500, color: "#e76163" }}
                      >
                        {staticPageDataErrorMessage}
                      </Typography>
                    )}
                    {noUpcomingCall && !staticPageDataError && (
                      <Typography
                        component="p"
                        className={classes.lobbySubtitle}
                        style={{ fontWeight: 500 }}
                      >
                        Looks like you don't have a call scheduled right now. Check back later.
                      </Typography>
                    )}
                  </div>
                )}

                {!staticPageLoaded && <CircularProgress size={30} style={{ margin: "auto" }} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(VideoChatStaticPage)),
);
