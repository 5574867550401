import React from "react";
import { Typography } from "@mui/material";
import Signature from "./Signature";
import TechIssues from "./TechIssues";

const ViewNoteDetails = (props) => {
  const { classes, note, responseNote, templateText, techIssues, noteRef } = props;

  return (
    <div className={classes.noteDetailsContainer}>
      {techIssues ? (
        <TechIssues {...props} />
      ) : (
        <>
          {templateText.noteBlockTitle && (
            <div className={classes.noteDetail} ref={noteRef}>
              <div className={classes.noteDetailTitleContainer}>
                <div className={classes.noteDetailTitle}>
                  <Typography component="p" variant="subtitle1">
                    {templateText.noteBlockTitle}
                  </Typography>
                </div>
                <Typography component="p" variant="body2Secondary">
                  {templateText.noteBlockInstruction}
                </Typography>
              </div>
              <Typography component="p" variant="body2">
                {note}
              </Typography>
            </div>
          )}
          {templateText.responseBlockTitle && (
            <div className={classes.noteDetail} ref={noteRef}>
              <div className={classes.noteDetailTitleContainer}>
                <div className={classes.noteDetailTitle}>
                  <Typography component="p" variant="subtitle1">
                    {templateText.responseBlockTitle}
                  </Typography>
                </div>
                <Typography component="p" variant="body2Secondary">
                  {templateText.responseBlockInstruction}
                </Typography>
              </div>
              <Typography component="p" variant="body2">
                {responseNote}
              </Typography>
            </div>
          )}
          <Signature {...props} />
        </>
      )}
    </div>
  );
};

export default ViewNoteDetails;
