import {
  videoRunning,
  videoRoomId,
  videoStartDate,
  videoEndDate,
  videoId,
  videoRoomState,
  isLegacyUser,
  sendingVideoSignatures,
  sendVideoSignaturesSuccess,
  getLogin,
  getVideoCallType,
  videoKey,
  callType,
  userGuid,
  getParentInfo,
  rescheduleVideoDetails,
  getUserId,
  getPerformanceTestLoading,
  getPerformanceTestResult,
  getPerformanceTestSuccess,
  getCheckingVideoAccess,
  getAccessToVideo,
  getPrimaryClinicianUserId,
  getSecondaryClinicianUserId,
  getVideoCallParticipants,
  getUserPermissionsList,
  getNotesClientId,
  oneTimeVideo,
  oneTimeVideoInfo,
  videoIsRunning,
  getUser,
  updatingLayout,
  layoutChangeSuccess,
  videoAnimations,
  videoActivities,
  videoCards,
  videoCallEnded,
  getCustomerLegacy,
  videoPromptCards,
  getNotes,
  getNotesLoading,
  getNotesSaved,
  getNotesSaving,
  getInsuranceCodesByUser,
  getSessionNotes,
  getSessionNotesByUser,
  getOldSessionNotesByUser,
  getSecondaryClinicianVideoJoinTime,
  getSecondaryClinicianVideoLeaveTime,
  getSecondaryClinicianVideoSignatureId,
  getSecondaryClinicianLeaveCallSuccess,
  getUserClinicianId,
  getPrimaryClinicianVideoSignatureId,
  getVideoBreakdown,
  isCustomerCare,
  isCaregiver,
  isClinician,
  getSendbirdConnection,
  getCallBreakdownLoading,
  getCallBreakdownSuccess,
  getCallBreakdownError,
  getCurrentChannelUrl,
  getVideoClientId,
  getClientUserId,
  getClientClientId,
  getAvailableInsuranceCodes,
  getUserIsAssignedClinician,
  getVideoChatChannelUrl,
  getStaticPageDataLoaded,
  getSignedinState,
  getSecondaryClinicianSessionNoteId,
  getCustomerSMSThread,
  getForm,
  getSecondaryClinicianBillableTimeId,
} from "../../../selectors";

export const mapStateToProps = (state) => ({
  videoIsRunning: videoRunning(state),
  roomId: videoRoomId(state),
  startDate: videoStartDate(state),
  endDate: videoEndDate(state),
  videoId: videoId(state),
  videoKey: videoKey(state),
  videoRoomState: videoRoomState(state),
  legacy: isLegacyUser(state),
  sendingVideoSignatures: sendingVideoSignatures(state),
  sendVideoSignaturesSuccess: sendVideoSignaturesSuccess(state),
  videoCallType: callType(state),
  login: getLogin(state),
  userGuid: userGuid(state),
  client: getParentInfo(state),
  videoDetails: rescheduleVideoDetails(state),
  userId: getUserId(state),
  getTestLoading: getPerformanceTestLoading(state),
  getSuccess: getPerformanceTestSuccess(state),
  performance: getPerformanceTestResult(state),
  checkingAccess: getCheckingVideoAccess(state),
  accessGranted: getAccessToVideo(state),
  primaryClinicianUserId: getPrimaryClinicianUserId(state),
  secondaryClinicianUserId: getSecondaryClinicianUserId(state),
  videoParticipants: getVideoCallParticipants(state),
  userPermissions: getUserPermissionsList(state),
  // clientId: getVideoClientId(state),
  clientId: getClientClientId(state),
  clientUserId: getClientUserId(state),
  oneTimeVideo: oneTimeVideo(state),
  oneTimeVideoInfo: oneTimeVideoInfo(state),
  isRunning: videoIsRunning(state),
  user: getUser(state),
  updatingLayout: updatingLayout(state),
  layoutChangeSuccess: layoutChangeSuccess(state),
  animations: videoAnimations(state),
  activities: videoActivities(state),
  cards: videoCards(state),
  promptCards: videoPromptCards(state),
  videoCallEnded: videoCallEnded(state),
  legacyUser: getCustomerLegacy(state), //isLegacy
  saving: getNotesSaving(state),
  saved: getNotesSaved(state),
  loading: getNotesLoading(state),
  notesList: getNotes(state),
  insuranceCodes: getInsuranceCodesByUser(state),
  sessionNotes: getSessionNotes(state),
  sessionNotesByUser: getSessionNotesByUser(state),
  oldSessionNotesByUser: getOldSessionNotesByUser(state),
  secondaryClinicianJoinTime: getSecondaryClinicianVideoJoinTime(state),
  secondaryClinicianLeaveTime: getSecondaryClinicianVideoLeaveTime(state),
  secondaryClinicianLeftCall: getSecondaryClinicianLeaveCallSuccess(state),
  primaryClinicianVideoSignatureId: getPrimaryClinicianVideoSignatureId(state),
  secondaryClinicianVideoSignatureId: getSecondaryClinicianVideoSignatureId(state),
  user_clinician_id: getUserClinicianId(state),
  videoBreakdown: getVideoBreakdown(state),
  isCustomerCare: isCustomerCare(state),
  isClinician: isClinician(state),
  isCaregiver: isCaregiver(state),
  sendbirdConnection: getSendbirdConnection(state),
  callBreakdownLoading: getCallBreakdownLoading(state),
  callBreakdownSuccess: getCallBreakdownSuccess(state),
  callBreakdownError: getCallBreakdownError(state),
  chatUrl: getCurrentChannelUrl(state),
  availableInsuranceCodes: getAvailableInsuranceCodes(state),
  isAssignedClinician: getUserIsAssignedClinician(state),
  videoChatUrl: getVideoChatChannelUrl(state),
  fromStaticVideoPage: getStaticPageDataLoaded(state),
  signedInState: getSignedinState(state),
  secondarySessionNoteId: getSecondaryClinicianSessionNoteId(state),
  secondaryBillableTimeId: getSecondaryClinicianBillableTimeId(state),
  consentForm: getForm(state),
  smsThread: getCustomerSMSThread(state),
});
