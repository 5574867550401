import React from "react";
import {
  Stethoscope,
  XCircle,
  UserFocus,
  PresentationChart,
  MonitorPlay,
  Gavel,
  ChatCircleText,
} from "@phosphor-icons/react";

export default {
  BEHAVIORAL_HEALTH_SERVICES_CONSENT: {
    title: "Informed Consent for Behavioral Health Services includes:",
    summaries: [
      {
        icon: <PresentationChart size={28} color="rgba(161, 74, 229, 1)" weight="duotone" />,
        text: "Treatment Information: Explanation of the services provided, potential risks and benefits, and the use of tele-behavioral health for improved access to care and your right to withdraw services",
      },
      {
        icon: <ChatCircleText size={28} color="rgba(161, 74, 229, 1)" weight="duotone" />,
        text: "Confidentiality: Consent to sharing personal health information under specific circumstances, with exceptions like threats of harm or court orders.",
      },
      {
        icon: <MonitorPlay size={28} color="rgba(161, 74, 229, 1)" weight="duotone" />,
        text: "Telehealth Consent: Consent to telehealth services, understanding they do not replace primary care, acknowledging potential technical issues, and availability of alternatives.",
      },
      {
        icon: <UserFocus size={28} color="rgba(161, 74, 229, 1)" weight="duotone" />,
        text: "Privacy and Security: Information on measures to protect health information and associated risks with electronic communication.",
      },
    ],
  },
  BEHAVIORAL_HEALTH_SERVICES_CONSENT_SPANISH: {
    title: "Informed Consent for Behavioral Health Services includes:",
    summaries: [
      {
        icon: <PresentationChart size={28} color="rgba(161, 74, 229, 1)" weight="duotone" />,
        text: "Treatment Information: Explanation of the services provided, potential risks and benefits, and the use of tele-behavioral health for improved access to care and your right to withdraw services",
      },
      {
        icon: <ChatCircleText size={28} color="rgba(161, 74, 229, 1)" weight="duotone" />,
        text: "Confidentiality: Consent to sharing personal health information under specific circumstances, with exceptions like threats of harm or court orders.",
      },
      {
        icon: <MonitorPlay size={28} color="rgba(161, 74, 229, 1)" weight="duotone" />,
        text: "Telehealth Consent: Consent to telehealth services, understanding they do not replace primary care, acknowledging potential technical issues, and availability of alternatives.",
      },
      {
        icon: <UserFocus size={28} color="rgba(161, 74, 229, 1)" weight="duotone" />,
        text: "Privacy and Security: Information on measures to protect health information and associated risks with electronic communication.",
      },
    ],
  },
  RESEARCH_CONSENT: {
    title: "Research Consent includes:",
    summaries: [
      {
        icon: <PresentationChart size={28} color="rgba(161, 74, 229, 1)" weight="duotone" />,
        text: "Data Usage: Permission to use de-identified demographic and various client data for training and dissemination purposes.",
      },
    ],
  },
  RESEARCH_CONSENT_SPANISH: {
    title: "Research Consent includes::",
    summaries: [
      {
        icon: <PresentationChart size={28} color="rgba(161, 74, 229, 1)" weight="duotone" />,
        text: "Data Usage: Permission to use de-identified demographic and various client data for training and dissemination purposes.",
      },
    ],
  },
  PHI_RELEASE: {
    title: "Release of Information consent authorizes:",
    summaries: [
      {
        icon: <Stethoscope size={28} color="rgba(161, 74, 229, 1)" weight="duotone" />,
        text: "Your healthcare provider to disclose your medical records to AnswersNow",
      },
      {
        icon: <XCircle size={28} color="rgba(161, 74, 229, 1)" weight="duotone" />,
        text: "Your right to revoke this authorization",
      },
    ],
  },
  PHI_RELEASE_SPANISH: {
    title: "El Consentimiento de Divulgación de Información autoriza:",
    summaries: [
      {
        icon: <Stethoscope size={28} color="rgba(161, 74, 229, 1)" weight="duotone" />,
        text: "Su proveedor de servicios de salud divulgará sus registros médicos a AnswersNow",
      },
      {
        icon: <XCircle size={28} color="rgba(161, 74, 229, 1)" weight="duotone" />,
        text: "Su derecho a revocar esta autorización",
      },
    ],
  },
  CANCELATION_POLICY: {},
  CANCELATION_POLICY_SPANISH: {},
  E_COMM_CONSENT: {
    title: "Electronic Communications Consent is used:",
    summaries: [
      {
        icon: <ChatCircleText size={28} color="rgba(161, 74, 229, 1)" weight="duotone" />,
        text: "To gain the your approval for using electronic means, such as email and text messages, to share and discuss your health information",
      },
    ],
  },
  E_COMM_CONSENT_SPANISH: {
    title: "El Consentimiento para Comunicaciones Electrónicas se utiliza para:",
    summaries: [
      {
        icon: <ChatCircleText size={28} color="rgba(161, 74, 229, 1)" weight="duotone" />,
        text: "Obtener su aprobación para usar medios electrónicos, como correo electrónico y mensajes de texto, para compartir y discutir su información de salud",
      },
    ],
  },
  INFORMED_CONSENT_FORM: {
    title: "Informed Consent gives AnswersNow:",
    summaries: [
      {
        icon: <UserFocus size={28} color="rgba(161, 74, 229, 1)" weight="duotone" />,
        text: "Permission to use de-identified demographic information",
      },
      {
        icon: <PresentationChart size={28} color="rgba(161, 74, 229, 1)" weight="duotone" />,
        text: "Permission to use various client data for training and dissemination purposes",
      },
    ],
  },
  INFORMED_CONSENT_FORM_SPANISH: {
    title: "El Consentimiento Informado otorga a AnswersNow:",
    summaries: [
      {
        icon: <UserFocus size={28} color="rgba(161, 74, 229, 1)" weight="duotone" />,
        text: "Permiso para utilizar información demográfica sin información de identificación",
      },
      {
        icon: <PresentationChart size={28} color="rgba(161, 74, 229, 1)" weight="duotone" />,
        text: "Permiso para utilizar varios datos del cliente con fines de formación y difusión",
      },
    ],
  },
  TABLET_CONSENT_FORM: {},
  TABLET_CONSENT_FORM_SPANISH: {},
  TELEHEALTH_INFORMED_CONSENT_FORM: {
    title: "Telehealth Informed Consent outlines:",
    summaries: [
      {
        icon: <MonitorPlay size={28} color="rgba(161, 74, 229, 1)" weight="duotone" />,
        text: "Methods, benefits, risks, and limitations of using a virtual care platform for Therapeutic Consultation Services",
      },
      {
        icon: <XCircle size={28} color="rgba(161, 74, 229, 1)" weight="duotone" />,
        text: "Your right to withdraw services",
      },
      {
        icon: <Gavel size={28} color="rgba(161, 74, 229, 1)" weight="duotone" />,
        text: "AnswersNow's adherence to professional ethical codes and state laws",
      },
    ],
  },
  TELEHEALTH_INFORMED_CONSENT_FORM_SPANISH: {
    title: "El Consentimiento Informado de Telesalud describe:",
    summaries: [
      {
        icon: <MonitorPlay size={28} color="rgba(161, 74, 229, 1)" weight="duotone" />,
        text: "Métodos, beneficios, riesgos y limitaciones del uso de una plataforma de atención virtual para Servicios de Consulta Terapéutica",
      },
      {
        icon: <XCircle size={28} color="rgba(161, 74, 229, 1)" weight="duotone" />,
        text: "Su derecho a retirar los servicios",
      },
      {
        icon: <Gavel size={28} color="rgba(161, 74, 229, 1)" weight="duotone" />,
        text: "Cumplimiento de AnswersNow con los Códigos de Ética Profesional y las Leyes Estatales",
      },
    ],
  },
};
