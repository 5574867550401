const basicInfoConfig = [
  { label: "First Name", dataKey: "firstName", cols: 6 },
  { label: "Last Name", dataKey: "lastName", cols: 6 },
  { label: "Full Legal Name", dataKey: "fullLegalName", cols: 12 },
  { label: "Email Address", dataKey: "email", cols: 6 },
  { label: "Phone Number", dataKey: "phone", cols: 6, format: "phone" },
  { label: "Address", dataKey: "address1", cols: 12 },
  { label: "Address 2", dataKey: "address2", cols: 12 },
  { label: "City", dataKey: "city", cols: 4 },
  { label: "State", dataKey: "state", cols: 4 },
  { label: "Zip Code", dataKey: "zip", cols: 4 },
  { label: "Gender", dataKey: "gender", cols: 6 },
  { label: "Pronouns", dataKey: "pronouns", cols: 6 },
  { label: "Date of Birth", dataKey: "dateOfBirth", cols: 6, format: "jsonDate" },
  { label: "Social Security Number", dataKey: "taxNumber", cols: 6 },
  { label: "Time Zone", dataKey: "timezone", cols: 6, format: "timezone" },
  { label: "Driver's License Number", dataKey: "licenseNumber", cols: 6 },
];

export default basicInfoConfig;
