import React, { useState, useRef, useEffect } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { datePickerPubSub } from "../utils/pubSub";

const ANDatePicker = ({ id, slotProps = {}, ...props }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
    datePickerPubSub.publish("datePicker:open", id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const unsubscribe = datePickerPubSub.subscribe("datePicker:open", (openedId) => {
      if (openedId !== id) {
        setOpen(false);
      }
    });

    return unsubscribe;
  }, []);

  const { field = {}, popper = {}, ...otherSlots } = slotProps;

  return (
    <DatePicker
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      slots={{ textField: TextField }}
      slotProps={{
        field: { readOnly: true, onClick: handleOpen, ...field },
        popper: { disablePortal: true, ...popper },
        ...otherSlots,
      }}
      {...props}
    />
  );
};

export default ANDatePicker;
