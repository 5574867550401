import React from "react";
import Typography from "@mui/material/Typography";

const AvatarInitials = (props) => {
  const { userInitials, size = 24 } = props;
  const styles = {
    container: {
      width: size,
      height: size,
      borderRadius: size / 2,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#EAECEF",
    },
    initials: {
      fontWeight: 500,
      fontSize: 11,
    },
  };
  return (
    <div style={styles.container}>
      <Typography color="primary" compononent="p" style={styles.initials}>
        {userInitials}
      </Typography>
    </div>
  );
};

export default AvatarInitials;
