import SecureRequest from "../utils/securerequest";

export async function getEventInfo(videoId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return Request.get(`/non-video-event/${videoId}/details`);
}

export async function scheduleNonVideoEvent(eventData) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return Request.post(`/v3/appointments/${eventData.clientId}/schedule?isNonVideo=true`, eventData);
}

export async function rescheduleNonVideoEvent(eventData) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return Request.put(`/v3/appointments/${eventData.id}/reschedule?isNonVideo=true`, eventData);
}

export async function cancelNonVideoEvent(event) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return Request.put(`/v3/appointments/${event.id}/cancel?isNonVideo=true`, event);
}
