import React from "react";
import { Box, Grid, Select, TextField, FormControlLabel } from "@mui/material";
import Divider from "elements/Divider";
import Checkbox from "@mui/material/Checkbox";
import { useTheme } from "@emotion/react";
import { formatPhoneNumber } from "utils/formatPhoneNumber";
import MenuItem from "@mui/material/MenuItem";

export const PhysicianForm = (props) => {
  const theme = useTheme();
  const {
    // handlers
    setFirstName,
    setLastName,
    validEmail,
    setEmail,
    setBusinessName,
    setFaxNumber,
    setPhysicalAddress,
    setNpiNumber,
    setCity,
    setState,
    setZipCode,
    setPhone,
    setSpecialty,
    setWebsite,
    setAcceptsInsurance,
    // values
    firstName,
    lastName,
    email,
    businessName,
    faxNumber,
    physicalAddress,
    npiNumber,
    city,
    state,
    stateOptions,
    zipCode,
    phone,
    specialty,
    website,
    acceptsInsurance,
  } = props;

  const extractNumber = (inputString) => {
    const digits = inputString.replace(/\D/g, "");
    const phoneNumber = digits.slice(0, 10);
    return phoneNumber;
  };

  const inputFields = [
    {
      field: "firstName",
      headerName: "First Name*",
      placeholder: "John",
      value: firstName,
      handler: setFirstName,
    },
    {
      field: "lastName",
      headerName: "Last Name*",
      placeholder: "Smith",
      value: lastName,
      handler: setLastName,
    },
    {
      field: "businessName",
      headerName: "Business Name",
      placeholder: "John's Health LLC",
      value: businessName,
      handler: setBusinessName,
      fullWidth: true,
    },
    {
      field: "specialty",
      headerName: "Specialty",
      placeholder: "John's Specialty",
      value: specialty,
      handler: setSpecialty,
    },
    {
      field: "acceptsInsurance",
      type: "checkbox",
      label: "Accepts Insurance",
      value: acceptsInsurance,
      handler: () => setAcceptsInsurance(!acceptsInsurance),
    },
    {
      type: "divider",
      fullWidth: true,
    },
    {
      field: "phone",
      headerName: "Phone Number*",
      placeholder: "888-888-8888",
      value: formatPhoneNumber(phone),
      handler: (e) => {
        const val = extractNumber(e.target.value);
        if (val.length <= 10 && /^([0-9\b]*)$/.test(val)) {
          setPhone(val);
        }
      },
    },
    {
      field: "faxNumber",
      headerName: "Fax Number*",
      placeholder: "888-888-8888",
      value: formatPhoneNumber(faxNumber),
      handler: (e) => {
        const val = extractNumber(e.target.value);
        if (val.length <= 10 && /^([0-9\b]*)$/.test(val)) {
          setFaxNumber(val);
        }
      },
    },
    {
      field: "npiNumber",
      headerName: "NPI Number",
      placeholder: "1112223334",
      value: npiNumber,
      handler: setNpiNumber,
      fullWidth: true,
    },
    {
      field: "email",
      headerName: "Email Address",
      placeholder: "john.smith@email.com",
      value: email,
      handler: setEmail,
      error: validEmail,
      fullWidth: true,
    },
    {
      field: "physicalAddress",
      headerName: "Physical Address",
      placeholder: "123 Bleeker Street",
      value: physicalAddress,
      handler: setPhysicalAddress,
      fullWidth: true,
    },
    {
      field: "city",
      headerName: "City*",
      placeholder: "Richmond",
      value: city,
      handler: setCity,
      md: 4,
    },
    {
      field: "state",
      type: "select",
      headerName: "State*",
      value: state,
      handler: setState,
      options: stateOptions,
      md: 4,
    },
    {
      field: "zipCode",
      headerName: "ZIP Code",
      placeholder: "23226",
      value: zipCode,
      handler: setZipCode,
      md: 4,
    },
    {
      field: "website",
      headerName: "Website",
      placeholder: "health.com",
      value: website,
      handler: setWebsite,
      fullWidth: true,
    },
  ];

  return (
    <Box sx={{ width: "100%", padding: "0 0 24px 0" }}>
      <Grid container columnSpacing={{ sm: 1, md: 2 }} columns={{ sm: 6, lg: 12 }}>
        {inputFields.map(
          (
            {
              type = "text",
              label = "",
              placeholder = null,
              error = false,
              helperText = false,
              options = [],
              headerName = `\u00A0`,
              value,
              handler,
              fullWidth = false,
              sm = 3,
              md = 6,
            },
            index,
          ) => {
            return (
              <Grid
                item
                key={index}
                sm={fullWidth ? 6 : sm}
                md={fullWidth ? 12 : md}
                marginTop={"24px"}
              >
                <Box display={"flex"} width={"100%"} flexDirection={"column"}>
                  <Box
                    paddingBottom={"8px"}
                    fontSize={"12px"}
                    fontStyle={"normal"}
                    fontWeight={"500"}
                    lineHeight={"12px"}
                    letterSpacing={"0.3px"}
                    color={theme.palette.text.primary}
                  >
                    {headerName}
                  </Box>
                  {type == "divider" && <Divider />}
                  {type == "text" && (
                    <TextField
                      sx={{ height: "40px" }}
                      value={value}
                      onChange={handler}
                      placeholder={placeholder}
                      error={error}
                      helperText={helperText}
                    />
                  )}
                  {type == "select" && (
                    <Select
                      variant="outlined"
                      sx={{ width: "100%" }}
                      value={value}
                      onChange={handler}
                    >
                      {options?.map(({ name, value, disabled }, index) => (
                        <MenuItem disabled={disabled} key={`${index}`} name={name} value={value}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                  {type == "checkbox" && (
                    <Box height={"40px"}>
                      <FormControlLabel
                        label={<div style={{ fontSize: "16px" }}>{label}</div>}
                        control={<Checkbox variant="outlined" onChange={handler} checked={value} />}
                      />
                    </Box>
                  )}
                </Box>
              </Grid>
            );
          },
        )}
      </Grid>
    </Box>
  );
};
