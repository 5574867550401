import React from "react";
import { Box, Button, Typography, Select, FormControl, InputLabel } from "@mui/material";
import { Plus } from "@phosphor-icons/react";
import MenuItem from "@mui/material/MenuItem";

export const Filter = ({
  theme,
  // handlers
  handleSelectedStateChange,
  handleAddNewPhysician,
  // values
  selectedState = "",
  stateOptions = [],
  userPermissions,
}) => {
  // this permission controls ability to add (same as view for now)
  const canAddPhysician = userPermissions?.view_physician_utility;

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        "& .filterContainer": {
          width: "100%",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-between",
          alignItems: "flex-end",
          padding: "0 24px",
          "& .selectGroup": {
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            width: "520px",
          },
          "& .select": {
            color: theme?.palette.text.secondary,
            padding: "16px 0",
            width: "256px",
          },
        },
      }}
    >
      <div className="filterContainer">
        <div className="selectGroup">
          {handleSelectedStateChange && (
            <FormControl className="select">
              <InputLabel>State</InputLabel>
              <Select value={selectedState} onChange={handleSelectedStateChange}>
                {stateOptions?.map(({ name, value }, index) => (
                  <MenuItem key={`${index}`} name={name} value={value}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </div>
        {canAddPhysician && handleAddNewPhysician && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              padding: "16px 0",
            }}
          >
            <Button
              sx={{
                height: "36px",
                fontSize: "14px",
                boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.15)",
              }}
              color="secondary"
              startIcon={<Plus />}
              fullWidth
              onClick={handleAddNewPhysician}
            >
              Add Provider
            </Button>
          </div>
        )}
      </div>
    </Box>
  );
};
