export default (theme) => ({
  mainBody: {
    width: "100%",
    // marginTop: theme.spacing(8),
    // padding: 25,
  },
  mainPaper: {
    padding: 25,
  },
  metricBlock: {
    backgroundColor: "#e1e1e1",
    paddingTop: 50,
    paddingBottom: 50,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
    marginBottom: "15px",
    padding: "0 25px",
    width: "100%",
  },
  downloadButtonsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "flex-start",
    marginBottom: "15px",
    padding: "0 25px",
    width: "100%",
    marginTop: 25,
    "& button": {
      marginRight: 20,
    },
  },
  searchContainer: {
    width: 300,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  insuranceTabsContainer: {
    "& .MuiButtonBase-root": {
      fontSize: 15,
      lineHeight: "20px",
    },
  },
});
