import React, { Component } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import Timeline from "./Timeline";
import FullRoadmap from "../../../ClientDashboard/FullRoadmap";
import Button from "@mui/material/Button";
import Statistics from "./Statistics";
import TaskListProgress from "./TaskListProgress";
import BasicInfoClassic from "./BasicInfoClassic";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSelector } from "react-redux";
import { useGetFeatureFlag } from "hooks/useGetFeatureFlag";
import * as selectors from "selectors";
import { useHistory } from "react-router-dom";

class TimelineStatistics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 0,
      fullRoadmapOpen: false,
    };
    this.currentStepRef = null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.clientTaskListLoading && !this.props.clientTaskListLoading) {
      if (this.props.clientTaskList?.length > 0) {
        this.scrollToCurrentTask();
      } else {
        this.setState({ currentTab: 1 });
      }
    }
  }

  handleTabChange = (event, newValue) => {
    this.setState({ currentTab: newValue }, () => {
      if (this.state.currentTab === 0) {
        this.scrollToCurrentTask();
      }
    });
  };

  scrollToCurrentTask = () => {
    if (!this.props.userPermissions?.view_client_tasklist) {
      setTimeout(() => {
        let pos = this.currentStepRef.style.position;
        let top = this.currentStepRef.style.top;
        this.currentStepRef.style.position = "relative";
        this.currentStepRef.style.top = "300px";
        this.currentStepRef.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
        this.currentStepRef.style.top = top;
        this.currentStepRef.style.position = pos;
      }, 400);
    }
  };

  onToggleRoadmapDialog = () => {
    this.setState({ fullRoadmapOpen: !this.state.fullRoadmapOpen });
  };

  mapClientTypeToDisplay = () => {
    const { customerDetails } = this.props;
    let details = customerDetails?.details;
    let workflowStatus = details.workflow_status;
    let clientType = "";
    if (details?.private_pay) {
      clientType = "Private Pay";
    } else if (details?.therapeutic_consultation) {
      clientType = "Therapeutic Consultation";
    } else {
      clientType = "Insurance";
    }
    if (details?.pre_auth_required && workflowStatus != "FULL_CUSTOMER") {
      clientType += ", Pre-Auth Required";
    }
    return clientType;
  };

  mapClientTaskToButton = (task) => {
    const {
      classes,
      userPermissions,
      isDirector,
      onToggleTreatmentPlanDialog,
      treatmentPlanUploading,
      onOpenUpdateTreatmentPlan,
      customerDetails,
      onToggleScheduleClientVideo,
      currentUser,
      markClientTaskComplete,
      markTaskCompleteLoading,
    } = this.props;
    let isPrimaryClinician =
      currentUser?.id == customerDetails?.clinician?.clinician_user_id ? true : false;
    switch (task.task) {
      case "assessment_scheduled":
        return !isPrimaryClinician && userPermissions?.schedule_call ? (
          <ScheduleCallButton
            onToggleScheduleClientVideo={onToggleScheduleClientVideo}
            classes={classes}
          />
        ) : null;
      case "assessment_process_completed":
        return <MarkAssessmentCompleteButton {...this.props} />;
      case "re_auth_initiated":
        return isPrimaryClinician ? (
          <MarkTaskCompleteButton
            buttonText="Acknowledge"
            clientId={customerDetails?.details?.client_id}
            clientTaskId={task.id}
            markClientTaskComplete={markClientTaskComplete}
            loading={markTaskCompleteLoading}
            classes={classes}
          />
        ) : null;
      case "treatment_plan_submitted":
        return userPermissions?.upload_treatment_plan && (isPrimaryClinician || isDirector) ? (
          <UploadTreatmentPlanButton
            onToggleTreatmentPlanDialog={onToggleTreatmentPlanDialog}
            classes={classes}
            loading={treatmentPlanUploading}
          />
        ) : null;
      case "treatment_plan_approved":
        return userPermissions?.upload_treatment_plan && (isPrimaryClinician || isDirector) ? (
          <UpdateTreatmentPlanButton
            onOpenUpdateTreatmentPlan={onOpenUpdateTreatmentPlan}
            customerDetails={customerDetails}
            classes={classes}
            loading={treatmentPlanUploading}
          />
        ) : null;
      case "treatment_plan_review_scheduled":
        return (
          <MarkTaskCompleteButton
            buttonText="Call Scheduled"
            clientId={customerDetails?.details?.client_id}
            clientTaskId={task.id}
            markClientTaskComplete={markClientTaskComplete}
            loading={markTaskCompleteLoading}
            classes={classes}
          />
        );
      case "treatment_plan_review_completed":
        return (
          <MarkTaskCompleteButton
            buttonText="Review Complete"
            clientId={customerDetails?.details?.client_id}
            clientTaskId={task.id}
            markClientTaskComplete={markClientTaskComplete}
            loading={markTaskCompleteLoading}
            classes={classes}
          />
        );
      case "first_therapy_call_scheduled":
        return userPermissions?.schedule_call ? (
          <ScheduleCallButton
            onToggleScheduleClientVideo={onToggleScheduleClientVideo}
            classes={classes}
          />
        ) : null;
      default:
        return null;
    }
  };

  renderView = () => {
    const { currentTab } = this.state;
    const {
      classes,
      clientTaskList,
      clientTaskListLoading,
      clientStatistics,
      clientStatisticsLoading,
      userPermissions,
      workflowStatus,
      customerDetails,
      ...otherProps
    } = this.props;
    const currentStepIndex = clientTaskList?.findIndex((task) => !task.completed);
    const isActive = customerDetails?.details?.is_active;
    const phase2 = clientTaskList.some((task) => !task.phase_3);

    if (!isActive) {
      return <BasicInfoClassic {...this.props} />;
    }

    if (!phase2 || clientTaskListLoading) {
      return (
        <>
          <div className={classes.timelineStatisticsHeading}>
            <Typography component="h4">Client Type: {this.mapClientTypeToDisplay()}</Typography>
            {!clientTaskListLoading && clientTaskList?.length > 0 && (
              <div className={classes.timelineStatisticsTabbar}>
                <AppBar position="static" color="default" className={classes.selectionBar}>
                  <Tabs
                    value={currentTab}
                    onChange={this.handleTabChange}
                    classes={{
                      root: classes.tabBar,
                      indicator: classes.indicatorColor,
                    }}
                    variant="fullWidth"
                  >
                    <Tab
                      className={classes.tabButton}
                      classes={{
                        selected: classes.activeTab,
                        wrapper: classes.planTabHeader,
                      }}
                      label={"Step Progression"}
                    />
                    <Tab
                      className={classes.tabButton}
                      classes={{
                        selected: classes.activeTab,
                        wrapper: classes.planTabHeader,
                      }}
                      label={"Statistical Info"}
                    />
                  </Tabs>
                </AppBar>
              </div>
            )}
          </div>
          <div className={classes.timelineStatistics}>
            {currentTab === 0 &&
              (clientTaskListLoading ? (
                <div className={classes.loadingContainer} style={{ minHeight: 300, flexGrow: 1 }}>
                  <CircularProgress size={30} />
                </div>
              ) : userPermissions?.view_client_tasklist ? (
                <TaskListProgress
                  clientTaskList={clientTaskList}
                  clientTaskListLoading={clientTaskListLoading}
                  userPermissions={userPermissions}
                  workflowStatus={workflowStatus}
                  customerDetails={customerDetails}
                  isActive={isActive}
                  {...otherProps}
                />
              ) : (
                <Timeline
                  tasks={tasks}
                  currentStepIndex={currentStepIndex}
                  currentStepRef={(ref) => (this.currentStepRef = ref)}
                  mapClientTaskToButton={this.mapClientTaskToButton}
                  isActive={isActive}
                  {...this.props}
                />
              ))}
            {currentTab === 1 &&
              (clientStatisticsLoading ? (
                <div className={classes.loadingContainer} style={{ minHeight: 300, flexGrow: 1 }}>
                  <CircularProgress size={30} />
                </div>
              ) : (
                <Statistics {...this.props} />
              ))}
          </div>
        </>
      );
    } else if (clientTaskList && userPermissions?.view_client_tasklist) {
      return (
        <>
          <div className={classes.taskListHeading}>
            <Typography component="h4">Phase 2 Progress</Typography>
            <Typography component="p">Client Type: {this.mapClientTypeToDisplay()}</Typography>
          </div>
          <TaskListProgress
            clientTaskList={clientTaskList}
            clientTaskListLoading={clientTaskListLoading}
            userPermissions={userPermissions}
            workflowStatus={workflowStatus}
            customerDetails={customerDetails}
            isActive={isActive}
            {...otherProps}
          />
        </>
      );
    } else {
      return (
        <>
          <div className={classes.timelineStatisticsHeading}>
            <Typography component="h4">Client Type: {this.mapClientTypeToDisplay()}</Typography>
          </div>
          <div className={classes.timelineStatistics}>
            {currentTab === 0 &&
              (clientTaskListLoading ? (
                <div className={classes.loadingContainer} style={{ minHeight: 300, flexGrow: 1 }}>
                  <CircularProgress size={30} />
                </div>
              ) : (
                <Timeline
                  tasks={tasks}
                  currentStepIndex={currentStepIndex}
                  currentStepRef={(ref) => (this.currentStepRef = ref)}
                  mapClientTaskToButton={this.mapClientTaskToButton}
                  isActive={isActive}
                  {...this.props}
                />
              ))}
          </div>
        </>
      );
    }
  };

  render() {
    const { currentTab } = this.state;
    const {
      classes,
      clientTaskList,
      clientTaskListLoading,
      clientStatistics,
      clientStatisticsLoading,
      customerDetails,
      workflowStatus,
    } = this.props;
    const preAuthRequired = customerDetails?.details?.pre_auth_required;
    const privatePay = customerDetails?.details?.private_pay;
    const therapeuticConsultation = customerDetails?.details?.therapeutic_consultation;
    const currentStepIndex = clientTaskList?.findIndex((task) => !task.completed);
    const phase2 = clientTaskList.some((task) => !task.phase_3);
    let steps = Object.keys(tasks).map((key) => tasks[key]);
    if (!phase2) {
      steps = steps.filter((step) => step.phase3 == true);
    } else {
      steps = steps.filter((step) => step.phase2 !== false);
    }
    if (!preAuthRequired) {
      steps = steps.filter((step) => step.preAuth != true);
      if (privatePay || therapeuticConsultation) {
        steps = steps.filter((step) => step.privatePay == true);
      }
    }
    return (
      <>
        <div className={classes.timelineStatisticsContainer}>{this.renderView()}</div>
        <FullRoadmap
          steps={steps}
          currentStepIndex={currentStepIndex}
          onToggleRoadmapDialog={this.onToggleRoadmapDialog}
          open={this.state.fullRoadmapOpen}
          classes={classes}
          workflowStatus={workflowStatus}
          clinicianView
        />
      </>
    );
  }
}

export default TimelineStatistics;

var tasks = {
  client_invited: {
    stepTitle: "Client Invited",
    stepParagraphs: [""],
    note: "",
    privatePay: true,
  },
  client_logged_in: {
    stepTitle: "Client Logged In",
    stepParagraphs: ["Indicates that the client has logged in to the portal for the first time."],
    note: "",
    privatePay: true,
  },
  orientation_complete: {
    stepTitle: "Orientation Call Complete",
    stepParagraphs: [""],
    note: "",
    privatePay: true,
  },
  diagnosis_uploaded: {
    stepTitle: "Diagnosis Uploaded",
    stepParagraphs: [
      "AnswersNow requires an official Autism diagnosis to provide ABA services. Work with the client’s family and physician to get this document.",
    ],
    note: "NOTE: Private Pay clients do not need a diagnosis and can begin therapy immediately.",
  },
  clinician_assigned: {
    stepTitle: "Clinician Assigned",
    stepParagraphs: [
      "Select a clinician that is available and assign them to the client within 2 days.",
    ],
    note: "",
    privatePay: true,
  },
  pre_auth_signed: {
    stepTitle: "Pre-Auth Signed",
    stepParagraphs: [
      "After the pre-auth has been signed, submit it to insurance so that they can review the document.",
    ],
    note: "",
    preAuth: true,
  },
  pre_auth_submitted: {
    stepTitle: "Pre-Authorization Submitted",
    stepParagraphs: ["Submit the pre-authorization to insurance for approval."],
    preAuth: true,
  },
  pre_auth_received: {
    stepTitle: "Pre-Authorization Received",
    stepParagraphs: [
      "This step will be marked complete automatically when a 'Pre' Authorization has been entered.",
    ],
    note: "Clinical team will be in contact to let you know when approval is obtained -- you are likely to be scheduled out in advanced.",
    preAuth: true,
  },
  assessment_scheduled: {
    stepTitle: "Assessment Scheduled",
    stepParagraphs: [
      "Your initial assessment call will be scheduled by the AnswersNow admin team. We will notify you when it is scheduled.",
    ],
    privatePay: true,
  },
  first_assessment_completed: {
    stepTitle: "First Assessment Completed",
    stepParagraphs: [
      "This task will be marked complete automatically upon the completion of the first Assessment Call.",
    ],
    note: "",
    privatePay: true,
  },
  assessment_process_completed: {
    stepTitle: "Assessment Calls Completed",
    stepParagraphs: [
      "To be marked complete when clinician has all information necessary to complete the treatment plan.",
    ],
    note: "",
    privatePay: true,
  },
  re_auth_initiated: {
    stepTitle: "Re-Auth Process Initiated",
    stepParagraphs: [
      "Please click below to acknowledge that the re-authorization process for this client has been triggered, and that you will begin working on a new treatment plan as soon as possible.",
    ],
    note: "",
    phase2: false,
    phase3: true,
  },
  treatment_plan_submitted: {
    stepTitle: "Treatment Plan Submitted to Clinical",
    stepParagraphs: ["Clinical needs to review the treatment plan before it can be approved. "],
    note: "",
    phase3: true,
    privatePay: true,
  },
  treatment_plan_approved: {
    stepTitle: "Treatment Plan Approved by Clinical",
    stepParagraphs: [""],
    note: "",
    phase3: true,
    privatePay: true,
  },
  ongoing_auth_signed: {
    stepTitle: "Ongoing Authorization Signed",
    stepParagraphs: [
      "Now that the treatment plan has been approved, sign the ongoing authorization.",
    ],
    note: "",
    phase3: true,
  },
  ongoing_auth_submitted: {
    stepTitle: "Ongoing Authorization Submitted",
    stepParagraphs: [
      "Submit the ongoing authorization and treatment plan to client's primary insurance company.",
    ],
    note: "",
    phase3: true,
  },
  treatment_plan_review_scheduled: {
    stepTitle: "Treatment Plan Review with Client Scheduled",
    stepParagraphs: [
      "Coordinate with the client to schedule a time to review the treatment plan that has been created from the assessment.",
    ],
    note: "",
    phase3: true,
    privatePay: true,
  },
  treatment_plan_review_completed: {
    stepTitle: "Treatment Plan Review with Client Completed",
    stepParagraphs: [
      "Review treatment plan with client. Be prepared for questions they might have.",
    ],
    note: "",
    phase3: true,
    privatePay: true,
  },
  first_therapy_call_scheduled: {
    stepTitle: "First Therapy Call Scheduled",
    stepParagraphs: ["Communicate with the client to schedule their first therapy call. "],
    note: "",
    privatePay: true,
  },
  ongoing_auth_received: {
    stepTitle: "Ongoing Authorization Received",
    stepParagraphs: [
      "Waiting for the ongoing authorization to be approved and returned by insurance company. This step will be marked complete automatically when an 'Ongoing' Insurance Authorization has been entered. ",
    ],
    note: "If this is a Private Pay client, enter an Ongoing-Auth to activate billing codes for this client.",
    phase3: true,
    privatePay: true,
  },
  first_therapy_call_completed: {
    stepTitle: "First Therapy Call Completed",
    stepParagraphs: [
      "Meet with the client and have thier first therapy session with AnswersNow. Once the first therapy call has been completed, the client will move from Phase 2 to Phase 3.",
    ],
    note: "",
    privatePay: true,
  },
};

const ScheduleCallButton = (props) => {
  const { onToggleScheduleClientVideo } = props;
  const history = useHistory();
  const userId = useSelector(selectors.getUserId);
  const { data: schedulePageFeatureFlag } = useGetFeatureFlag(userId, "scheduling_page");
  return (
    <Button
      onClick={(e) => {
        e.stopPropagation();
        if (schedulePageFeatureFlag) history.push("/clinician-schedule/create-event");
        else onToggleScheduleClientVideo(false);
      }}
    >
      Schedule Call
    </Button>
  );
};

var UploadTreatmentPlanButton = (props) => {
  const { onToggleTreatmentPlanDialog, loading } = props;
  return (
    <LoadingButton
      onClick={(e) => {
        e.stopPropagation();
        onToggleTreatmentPlanDialog();
      }}
      loading={loading}
    >
      Upload
    </LoadingButton>
  );
};

var UpdateTreatmentPlanButton = (props) => {
  const { customerDetails, onOpenUpdateTreatmentPlan, classes, loading } = props;
  return (
    <LoadingButton
      onClick={(e) => {
        e.stopPropagation();
        onOpenUpdateTreatmentPlan(customerDetails.treatmentPlans.find((plan) => !plan.approved));
      }}
      className={classes.button}
      loading={loading}
    >
      Update Plan
    </LoadingButton>
  );
};

var MarkAssessmentCompleteButton = (props) => {
  const { onToggleAssessmentComplete } = props;
  return (
    <Button
      onClick={(e) => {
        e.stopPropagation();
        onToggleAssessmentComplete();
      }}
    >
      <span style={{ fontWeight: "400" }}>Mark Complete</span>
    </Button>
  );
};

var MarkTaskCompleteButton = (props) => {
  const { buttonText, clientTaskId, clientId, markClientTaskComplete, classes, loading } = props;
  return (
    <LoadingButton
      onClick={(e) => {
        e.stopPropagation();
        markClientTaskComplete({ clientId, clientTaskId });
      }}
      size="small"
      className={classes.taskButton}
      loading={loading}
    >
      {buttonText}
    </LoadingButton>
  );
};
