import React from "react";
import withStyles from "@mui/styles/withStyles";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Close from "@mui/icons-material/Close";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import Slider from "@mui/material/Slider";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

const CustomSlider = withStyles({
  root: {
    display: "flex",
    width: "98%",
    alignItems: "center",
  },
  thumb: {
    backgroundColor: "#8E42CA",
    marginTop: 1,
    boxShadow: "#ebebeb 0 2px 2px",
    "& .bar": {
      height: 9,
      width: 1,
      backgroundColor: "#8E42CA",
    },
    zIndex: 3600,
  },
  track: {
    borderRadius: "5px 0 0 5px",
    height: 6,
    color: "#8E42CA",
  },
  mark: {
    height: 2,
    color: "#8E42CA",
  },
  rail: {
    borderRadius: 5,
    paddingLeft: 4,
    color: "rgb(142,66,202, 0.6)",
    opacity: 1,
    height: 6,
  },
})(Slider);

const PromptActivityDrawer = (props) => {
  const {
    promptDrawerOpen,
    togglePromptDrawer,
    classes,
    promptLevel,
    onFieldSizeChange,
    onPromptLevelChange,
    setupPromptActivity,
  } = props;

  const renderPromptLevelSummary = (
    <>
      <>
        <Typography component="p" className={classes.promptLevelTitle}>
          <b>None</b>
        </Typography>
        <Typography component="p" className={classes.promptLevelText}>
          Client will see all images without any type of visual cues to assist in their selection.
        </Typography>
      </>
      <>
        <Typography component="p" className={classes.promptLevelTitle}>
          <b>Grow</b>
        </Typography>
        <Typography component="p" className={classes.promptLevelText}>
          Client will see the correct answer appear slightly larger than the incorrect answers.
        </Typography>
      </>
      <>
        <Typography component="p" className={classes.promptLevelTitle}>
          <b>Fade</b>
        </Typography>
        <Typography component="p" className={classes.promptLevelText}>
          Client will see the incorrect answer appear with less opacity and the correct answer shown
          in full opacity.
        </Typography>
      </>
      <>
        <Typography component="p" className={classes.promptLevelTitle}>
          <b>Point</b>
        </Typography>
        <Typography component="p" className={classes.promptLevelText}>
          Client will see an animated finger pointing to the stimuli to help them identify the
          correct answer.
        </Typography>
      </>
    </>
  );

  return (
    <Drawer
      className={classes.promptDrawer}
      anchor="right"
      open={promptDrawerOpen}
      onClose={togglePromptDrawer}
      BackdropProps={{ invisible: true }}
      classes={{
        paper: classes.promptDrawerPaper,
      }}
    >
      <div className={classes.promptDrawerContainer}>
        <div className={classes.promptDrawerHeader}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <IconButton onClick={togglePromptDrawer} aria-label="Previous Page" size="large">
              <KeyboardArrowLeft />
            </IconButton>
            <b>Prompt</b>
          </div>
          <IconButton onClick={togglePromptDrawer} aria-label="Previous Page" size="large">
            <Close />
          </IconButton>
        </div>

        <div className={classes.promptDrawerContent}>
          Field Size
          <div className={classes.promptSliderContainer}>
            <CustomSlider
              defaultValue={4}
              onChange={onFieldSizeChange}
              name="fieldSize"
              valueLabelDisplay="auto"
              marks={[
                { value: 1, label: 1 },
                { value: 2, label: null },
                { value: 3, label: null },
                { value: 4, label: null },
                { value: 5, label: null },
                { value: 6, label: null },
                { value: 7, label: null },
                { value: 8, label: 8 },
              ]}
              min={1}
              max={8}
            />
          </div>
          <div
            style={{
              marginBottom: 3,
              marginTop: 16,
            }}
          >
            <label
              style={{
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "12px",
                letterSpacing: "0.3px",
              }}
            >
              Prompt Level
            </label>
          </div>
          <TextField
            select
            fullWidth
            id="level-menu"
            name="reason"
            variant="outlined"
            size="small"
            value={promptLevel}
            style={{ marginBottom: 16 }}
            onChange={onPromptLevelChange}
          >
            {["None", "Grow", "Fade", "Point"].map((p) => (
              <MenuItem key={p} value={p.toLowerCase()}>
                {p}
              </MenuItem>
            ))}
          </TextField>
          {renderPromptLevelSummary}
          <Button onClick={setupPromptActivity} className={classes.startPromptActivityButton}>
            Start Activity
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default PromptActivityDrawer;
