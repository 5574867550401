import React, { useEffect, useState } from "react";
import { PhysiciansPageContents } from "./PhysiciansPageContents";
import { STATES } from "constants";
import { getPhysicians, addPhysician, updatePhysician } from "api/physicians";
import { getUserPermissionsList } from "selectors";
import { useSelector } from "react-redux";

export const PhysiciansPage = (props) => {
  const userPermissions = useSelector(getUserPermissionsList);

  // filter values
  const [selectedStateFilter, setSelectedStateFilter] = useState("");
  const [isNewPhysician, setIsNewPhysician] = useState(true);
  const [confirmQuitEditDialogOpen, setConfirmQuitEditDialogOpen] = useState(false);
  const [physicianModified, setPhysicianModified] = useState(false);
  const [physicianSaving, setPhysicianSaving] = useState(false);
  const [physicianTableDataLoading, setPhysicianTableDataLoading] = useState(false);
  const [physicianSaveFail, setPhysicianSaveFail] = useState(false);
  // form values
  const [physicianId, setPhysicianId] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [faxNumber, setFaxNumber] = useState("");
  const [physicalAddress, setPhysicalAddress] = useState("");
  const [npiNumber, setNpiNumber] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [phone, setPhone] = useState("");
  const [specialty, setSpecialty] = useState("");
  const [website, setWebsite] = useState("");
  const [acceptsInsurance, setAcceptsInsurance] = useState(false);
  // table values
  const [physicianTableData, setPhysicianTableData] = useState(null);
  const [physicianFormDialogOpen, setPhysicianFormDialogOpen] = useState(false);
  // table models
  const [sortModel, setSortModel] = useState([]);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const processTableData = (data) => {
    const withColumnsNames = data.physicians.map((row) => ({
      ...row,
      firstName: row?.first_name,
      lastName: row?.last_name,
      businessName: row?.business_name,
      faxNumber: row?.fax_number,
      zipCode: row?.zip_code,
      acceptsInsurance: row?.accepts_insurance,
      physicalAddress: row?.physical_address,
      npiNumber: row?.npi_number,
    }));

    return { ...data, physicians: withColumnsNames };
  };

  useEffect(async () => {
    setPhysicianTableDataLoading(true);
    getPhysicians({
      selectedStateFilter,
      sortModel: sortModel?.[0] ?? {},
      paginationModel,
    }).then((res) => {
      setPhysicianTableData(processTableData(res.data));
      setPhysicianTableDataLoading(false);
    });
  }, [physicianSaving, selectedStateFilter, sortModel, paginationModel]);

  // filter functions
  const getStateFilterOptions = (disableSelect = false) => {
    const filteredStates = Object.keys(STATES).map((key) => ({
      name: STATES[key],
      value: key,
    }));

    return [{ value: "", name: "Select", disabled: disableSelect }, ...filteredStates];
  };

  const handleAddNewPhysician = () => {
    setPhysicianFormDialogOpen(!physicianFormDialogOpen);
    setIsNewPhysician(true);
  };

  // form functions
  const loadPhysicianInfo = (row) => {
    setPhysicianId(row?.id);
    setFirstName(row?.firstName);
    setLastName(row?.lastName);
    setEmail(row?.email);
    setBusinessName(row?.businessName);
    setFaxNumber(row?.faxNumber);
    setPhysicalAddress(row?.physicalAddress);
    setNpiNumber(row?.npiNumber);
    setCity(row?.city);
    setState(row?.state);
    setZipCode(row?.zipCode);
    setPhone(row?.phone);
    setSpecialty(row?.specialty);
    setWebsite(row?.website);
    setAcceptsInsurance(row?.acceptsInsurance);
  };

  const clearFormValues = () => {
    setPhysicianId(null);
    setFirstName("");
    setLastName("");
    setEmail("");
    setBusinessName("");
    setFaxNumber("");
    setPhysicalAddress("");
    setNpiNumber("");
    setCity("");
    setState("");
    setZipCode("");
    setPhone("");
    setSpecialty("");
    setWebsite("");
    setAcceptsInsurance(false);
  };

  const handlePhysicianEdit = ({ row }) => {
    setPhysicianFormDialogOpen(!physicianFormDialogOpen);
    setIsNewPhysician(false);
    loadPhysicianInfo(row);
  };

  const handleConfirmedClosePhysicianDialog = () => {
    setPhysicianFormDialogOpen(false);
    setConfirmQuitEditDialogOpen(false);
    clearFormValues();
    setIsNewPhysician(true);
    setPhysicianModified(false);
    setPhysicianSaveFail(false);
  };

  const submitPhysicianInfo = async () => {
    const body = {
      physicianId,
      firstName,
      lastName,
      email,
      businessName,
      faxNumber,
      physicalAddress,
      npiNumber,
      city,
      state,
      zipCode,
      phone,
      specialty,
      website,
      acceptsInsurance,
    };

    setPhysicianSaving(true);
    (isNewPhysician ? addPhysician : updatePhysician)(body)
      .then(() => {
        setPhysicianSaving(false);
        handleConfirmedClosePhysicianDialog();
      })
      .catch((e) => {
        setPhysicianSaving(false);
        setPhysicianSaveFail(true);
      });
  };

  const physicianChangeListener = (changeHandler) => (props) => {
    setPhysicianModified(true);
    changeHandler(props);
  };

  const handleChangeEvent = (setValueFunction) => (e) =>
    physicianChangeListener(setValueFunction)(e?.target?.value ?? e);

  return (
    <PhysiciansPageContents
      filter={{
        //handlers
        handleTogglePhysicianFormDialog: () => setPhysicianFormDialogOpen(!physicianFormDialogOpen),
        handleSelectedStateChange: handleChangeEvent(setSelectedStateFilter),
        handleAddNewPhysician,
        //values
        userPermissions,
        stateOptions: getStateFilterOptions(),
        selectedState: selectedStateFilter,
      }}
      table={{
        // handlers
        handleEdit: handlePhysicianEdit,
        setSortModel,
        setPaginationModel,
        // values
        userPermissions,
        physicianTableDataLoading,
        totalRowCount: physicianTableData?.count,
        rows: physicianTableData?.physicians ?? [],
        sortModel,
        paginationModel,
      }}
      physicianFormDialog={{
        // handlers
        handleConfirmedClosePhysicianDialog,
        toggleConfirmQuitDialog: () => setConfirmQuitEditDialogOpen(!confirmQuitEditDialogOpen),
        submitPhysicianInfo,
        setFirstName: handleChangeEvent(setFirstName),
        setLastName: handleChangeEvent(setLastName),
        setEmail: handleChangeEvent(setEmail),
        setBusinessName: handleChangeEvent(setBusinessName),
        setFaxNumber: handleChangeEvent(setFaxNumber),
        setPhysicalAddress: handleChangeEvent(setPhysicalAddress),
        setNpiNumber: handleChangeEvent(setNpiNumber),
        setCity: handleChangeEvent(setCity),
        setState: handleChangeEvent(setState),
        setZipCode: handleChangeEvent(setZipCode),
        setPhone: handleChangeEvent(setPhone),
        setSpecialty: handleChangeEvent(setSpecialty),
        setWebsite: handleChangeEvent(setWebsite),
        setAcceptsInsurance: handleChangeEvent(setAcceptsInsurance),
        // values
        firstName,
        lastName,
        email,
        businessName,
        faxNumber,
        physicalAddress,
        npiNumber,
        city,
        state,
        stateOptions: getStateFilterOptions(true),
        zipCode,
        phone,
        specialty,
        website,
        acceptsInsurance,
        physicianFormDialogOpen,
        confirmQuitEditDialogOpen,
        isNewPhysician,
        physicianSaveFail,
        physicianSaving,
        physicianModified,
      }}
    />
  );
};
