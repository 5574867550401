import React, { useEffect } from "react";
import { Formik } from "formik";
import { MenuItem } from "@mui/material";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import { COUNTRIES, STATES, RELATIONSHIPS } from "constants";
import { formatToPhoneNumber } from "utils/formatFormFields";
import { useGetTeams } from "hooks/api/useGetTeams";
import { useGetTeamMembers } from "hooks/api/useGetTeamMembers";
import ANDatePicker from "elements/ANDatePicker";
import validationSchema from "./validationSchema";
import DisableInsuranceButton from "./DisableInsuranceButton";
import useGetInsurancePlans from "./useGetInsurancePlans";
import { dateWithoutTimezone } from "utils/dateWithoutTimezone";

const InsuranceDetailsEdit = React.forwardRef(
  (
    {
      formId,
      insurance,
      clientId,
      userId,
      privatePayClient,
      classes,
      insuranceProvidersList,
      primaryInsuranceId,
      setPrimaryInsuranceId,
      getUserInsurance,
      onCancelCreateInsurance,
      onSubmit,
    },
    ref
  ) => {
    const defaultValues = {
      ...insurance,
      teamMemberId: "",
      insuranceid: insurance?.insuranceName
        ? // Set the default insurance provider ID if it exists in the insurance object
          insuranceProvidersList?.find(({ name }) => name === insurance.insuranceName)?.id
        : null,
      insurancePlanId: "",
      effectiveDate: insurance.effectiveDate ? new Date(insurance.effectiveDate) : null,
      expirationDate: insurance.expirationDate ? new Date(insurance.expirationDate) : null,
      insuranceHolderName: insurance?.insuranceHolderName ?? "",
      insuranceHolderDOB: insurance?.insuranceHolderDOB
        ? dateWithoutTimezone(insurance.insuranceHolderDOB)
        : null,
      insurance_holder_relationship: insurance?.insurance_holder_relationship ?? "",
      subscriberDOB: insurance?.subscriberDOB ? dateWithoutTimezone(insurance.subscriberDOB) : null,
      subscriberCountry: insurance.subscriberCountry || "US",
    };

    const { teams } = useGetTeams({ clientIds: clientId });
    const teamId = teams?.[0]?.id;
    const { teamMembers } = useGetTeamMembers({ teamIds: teamId, includeTeamInvitees: false });

    return (
      <Formik
        id={formId}
        innerRef={ref}
        initialValues={defaultValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          onSubmit({ ...values, clientId, userId });
        }}
      >
        {({ touched, errors, handleBlur, handleChange, values, setFieldValue }) => {
          const { insuranceid, insurancePlanId, isPrimary } = values;

          const { insurancePlans, fetchStatus } = useGetInsurancePlans({
            insuranceid: values?.insuranceid,
          });

          // Get the insurance plan options based on the selected insurance provider
          const insurancePlanOptions = insurancePlans
            ? insurancePlans
                ?.filter(({ insurance_id }) => parseInt(insurance_id) === parseInt(insuranceid))
                .map(({ id, name }) => ({
                  label: name,
                  value: id,
                }))
            : [];

          useEffect(() => {
            if (insurance.planName && !insurancePlanId && insurancePlans.length) {
              // Set the insurance plan id based on the insurance plan name
              const insurancePlanIdValue = insurancePlans?.find(
                ({ name }) => name === insurance.planName
              );
              setFieldValue("insurancePlanId", insurancePlanIdValue?.id || "");
            }
          }, [fetchStatus, insurancePlans.length]);

          useEffect(() => {
            setFieldValue("isPrimary", primaryInsuranceId === insurance.id);
          }, [primaryInsuranceId]);

          const onChangePrimaryInsurance = (e) => {
            if (e.target.checked) {
              setPrimaryInsuranceId(insurance.id);
            }
          };

          const handleInsuranceCompanyChange = (event) => {
            const newValue = event.target.value;

            // Check if the insuranceid has changed and reset the insurancePlanId
            if (insuranceid !== newValue) {
              setFieldValue("insurancePlanId", "");
              setFieldValue("insuranceid", newValue);
            }
          };

          //function handle when selected member as subscriber is chosen add the values to the subscribers fields
          const handleSubscribersAutoComplete = (event) => {
            const teamMemberId = event.target.value;
            const teamMember = teamMembers.find(({ id }) => id === teamMemberId);

            if (teamMember) {
              setFieldValue("subscriberFirstName", teamMember.firstName);
              setFieldValue("subscriberLastName", teamMember.lastName);
              setFieldValue("subscriberEmail", teamMember.email);
              setFieldValue("subscriberPhone", teamMember.phone);
              setFieldValue(
                "subscriberDOB",
                teamMember.dateOfBirth ? new Date(teamMember.dateOfBirth) : null
              );
              setFieldValue("subscriberGender", teamMember.gender);
              setFieldValue("subscriberRelationship", teamMember.relationship);
              setFieldValue("subscriberCountry", teamMember.country || "US");
              setFieldValue("subscriberState", teamMember.state);
              setFieldValue("subscriberCity", teamMember.city);
              setFieldValue("subscriberZip", teamMember.zip);
              setFieldValue("subscriberAddress", teamMember.address);
              setFieldValue("subscriberAddress2", teamMember.address2);
            } else {
              setFieldValue("subscriberFirstName", "");
              setFieldValue("subscriberLastName", "");
              setFieldValue("subscriberEmail", "");
              setFieldValue("subscriberPhone", "");
              setFieldValue("subscriberDOB", null);
              setFieldValue("subscriberGender", null);
              setFieldValue("subscriberRelationship", null);
              setFieldValue("subscriberCountry", "US");
              setFieldValue("subscriberState", null);
              setFieldValue("subscriberCity", "");
              setFieldValue("subscriberZip", "");
              setFieldValue("subscriberAddress", "");
              setFieldValue("subscriberAddress2", "");
            }

            setFieldValue("teamMemberId", teamMemberId);
          };

          const handlePhoneNumberChange = (e) => {
            const phone = e.target.value.replace(/[^\d]/g, "");
            handleChange({ target: { name: "subscriberPhone", value: phone } });
          };

          return (
            <>
              <Grid
                container
                justifyContent="flex-start"
                rowSpacing={7}
                columnSpacing={5}
                classes={{ root: classes.gridRoot }}
              >
                <Grid item xs={12} sm={6} align="left">
                  <TextField
                    select
                    required
                    fullWidth
                    name="insuranceid"
                    label="Insurance Company"
                    value={values.insuranceid}
                    onChange={handleInsuranceCompanyChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.insuranceid && errors.insuranceid)}
                    helperText={touched.insuranceid && errors.insuranceid}
                  >
                    <MenuItem disabled value={""} />
                    {insuranceProvidersList?.map((provider) => (
                      <MenuItem key={provider.id} value={provider.id}>
                        {provider.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={6} align="left">
                  <TextField
                    select
                    required
                    fullWidth
                    name="insurancePlanId"
                    label="Insurance Plan"
                    value={values.insurancePlanId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.insurancePlanId && errors.insurancePlanId)}
                    helperText={touched.insurancePlanId && errors.insurancePlanId}
                  >
                    <MenuItem disabled value={""} />
                    {insurancePlanOptions?.map((provider) => (
                      <MenuItem key={provider.value} value={provider.value}>
                        {provider.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={6} align="left">
                  <TextField
                    fullWidth
                    required
                    label="Member ID"
                    name="memberId"
                    error={Boolean(touched.memberId && errors.memberId)}
                    helperText={touched.memberId && errors.memberId}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.memberId}
                    disabled={privatePayClient}
                  />
                </Grid>

                <Grid item xs={12} sm={6} align="left">
                  <TextField
                    fullWidth
                    label="Group Number"
                    name="groupNumber"
                    error={Boolean(touched.groupNumber && errors.groupNumber)}
                    helperText={touched.groupNumber && errors.groupNumber}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.groupNumber}
                    disabled={privatePayClient}
                  />
                </Grid>

                {/* Keep them as long as they are in the database --------------> */}
                <Grid item xs={12} sm={6} align="left">
                  <TextField
                    fullWidth
                    required
                    label="Insurance Holder Name"
                    name="insuranceHolderName"
                    error={Boolean(touched.insuranceHolderName && errors.insuranceHolderName)}
                    helperText={touched.insuranceHolderName && errors.insuranceHolderName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.insuranceHolderName}
                    disabled={privatePayClient}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <ANDatePicker
                    name="insuranceHolderDOB"
                    label="Insurance Holder Date of Birth"
                    value={values.insuranceHolderDOB}
                    onChange={(value) => setFieldValue("insuranceHolderDOB", value, true)}
                    format="PP" //"MMM d, yyyy"
                    disableFuture
                    sx={{ width: "100%" }}
                    slotProps={{
                      textField: {
                        error: Boolean(touched.insuranceHolderDOB && errors.insuranceHolderDOB),
                        helperText: touched.insuranceHolderDOB && errors.insuranceHolderDOB,
                        onBlur: handleBlur,
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <TextField
                    select
                    required
                    fullWidth
                    name="insurance_holder_relationship"
                    label="Relationship of Client to Holder"
                    value={values.insurance_holder_relationship}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(
                      touched.insurance_holder_relationship && errors.insurance_holder_relationship
                    )}
                    helperText={
                      touched.insurance_holder_relationship && errors.insurance_holder_relationship
                    }
                  >
                    <MenuItem value="" disabled>
                      relationship
                    </MenuItem>
                    <MenuItem value={"Child"}>Child</MenuItem>
                    <MenuItem value={"Self"}>Self</MenuItem>
                    <MenuItem value={"Other"}>Other</MenuItem>
                  </TextField>
                </Grid>
                {/* <-------------------------------------------- */}

                <Grid item xs={12} sm={6} align="left">
                  <ANDatePicker
                    name="effectiveDate"
                    label="Effective Date"
                    value={values.effectiveDate}
                    onChange={(value) => setFieldValue("effectiveDate", value, true)}
                    format="PP" //"MMM d, yyyy"
                    sx={{ width: "100%" }}
                    helperText={Boolean(touched.effectiveDate && errors.effectiveDate)}
                  />
                </Grid>

                <Grid item xs={12} sm={6} align="left">
                  <ANDatePicker
                    name="expirationDate"
                    label="Expiration Date"
                    value={values.expirationDate}
                    onChange={(value) => setFieldValue("expirationDate", value, true)}
                    format="PP" //"MMM d, yyyy"
                    sx={{ width: "100%" }}
                    helperText={Boolean(touched.expirationDate && errors.expirationDate)}
                  />
                </Grid>

                <Grid item xs={12} sm={12} align="left">
                  <FormControlLabel
                    control={<Checkbox onChange={onChangePrimaryInsurance} checked={isPrimary} />}
                    label="Primary Insurance"
                    disabled={isPrimary}
                    error={errors.isPrimary}
                  />
                </Grid>
              </Grid>
              {/* ----------------- Subscriber Details ----------------- */}
              <Grid
                container
                justifyContent="flex-start"
                rowSpacing={7}
                columnSpacing={5}
                classes={{ root: classes.gridRoot }}
                mt={"7px"}
              >
                <Grid item xs={12} sm={12} align="left" rowSpacing={"8px"}>
                  <Typography component="p" textAlign={"left"} fontSize={"14px"} fontWeight={500}>
                    Insurance Subscriber
                  </Typography>
                  <Typography
                    component="p"
                    textAlign={"left"}
                    fontSize={"14px"}
                    fontWeight={400}
                    marginTop={"8px"}
                  >
                    Set the policy holder for the client&apos;s insurance. Choose a team member to
                    easily copy the necessary fields or manually add a subscriber for the Insurance
                    plan.
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={12} align="left">
                  <TextField
                    select
                    fullWidth
                    name="teamMemberId"
                    label="Team Member"
                    onChange={handleSubscribersAutoComplete}
                    value={values.teamMemberId}
                  >
                    <MenuItem disabled value={""} />
                    <MenuItem value={null}>None</MenuItem>
                    {teamMembers?.map((member) => (
                      <MenuItem key={member.id} value={member.id}>
                        {member.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={6} align="left">
                  <TextField
                    fullWidth
                    label="Please enter the First Name"
                    name="subscriberFirstName"
                    error={Boolean(touched.subscriberFirstName && errors.subscriberFirstName)}
                    helperText={touched.subscriberFirstName && errors.subscriberFirstName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.subscriberFirstName}
                    disabled={privatePayClient}
                  />
                </Grid>

                <Grid item xs={12} sm={6} align="left">
                  <TextField
                    fullWidth
                    label="Subscriber's Last Name"
                    name="subscriberLastName"
                    error={Boolean(touched.subscriberLastName && errors.subscriberLastName)}
                    helperText={touched.subscriberLastName && errors.subscriberLastName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.subscriberLastName}
                    disabled={privatePayClient}
                  />
                </Grid>

                <Grid item xs={12} sm={6} align="left">
                  <ANDatePicker
                    name="subscriberDOB"
                    label="Subscriber's Date of Birth"
                    value={values.subscriberDOB}
                    onChange={(value) => setFieldValue("subscriberDOB", value, true)}
                    format="PP" //"MMM d, yyyy"
                    sx={{ width: "100%" }}
                    disabled={privatePayClient}
                  />
                </Grid>

                <Grid item xs={12} sm={6} align="left">
                  <TextField
                    name="subscriberGender"
                    label="Subscriber's Gender"
                    select
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.subscriberGender}
                    fullWidth
                  >
                    <MenuItem disabled value={""} />
                    <MenuItem value="Female">Female</MenuItem>
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="non_binary">Non-binary</MenuItem>
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={6} align="left">
                  <TextField
                    fullWidth
                    label="Subscriber's Email Address"
                    name="subscriberEmail"
                    error={Boolean(touched.subscriberEmail && errors.subscriberEmail)}
                    helperText={touched.subscriberEmail && errors.subscriberEmail}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.subscriberEmail}
                    disabled={privatePayClient}
                  />
                </Grid>

                <Grid item xs={12} sm={6} align="left">
                  <TextField
                    fullWidth
                    label="Subscriber's Phone Number"
                    name="subscriberPhone"
                    error={Boolean(touched.subscriberPhone && errors.subscriberPhone)}
                    helperText={touched.subscriberPhone && errors.subscriberPhone}
                    onBlur={handleBlur}
                    onChange={handlePhoneNumberChange}
                    value={formatToPhoneNumber(values.subscriberPhone)}
                    disabled={privatePayClient}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    fullWidth
                    label="Relationship to Client"
                    name="subscriberRelationship"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.subscriberRelationship}
                  >
                    <MenuItem disabled value="">
                      relationship
                    </MenuItem>
                    {Object.entries(RELATIONSHIPS).map(([key, value]) => {
                      return (
                        <MenuItem key={key} value={key}>
                          {value}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    fullWidth
                    label="Country"
                    name="subscriberCountry"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.subscriberCountry}
                  >
                    <MenuItem disabled value={""}>
                      Select
                    </MenuItem>
                    {Object.entries(COUNTRIES).map(([key, value]) => {
                      return (
                        <MenuItem key={key} value={key}>
                          {value}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={4} align="left">
                  <TextField
                    select
                    fullWidth
                    label="State"
                    name="subscriberState"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.subscriberState}
                    disabled={values.subscriberCountry !== "US"}
                  >
                    <MenuItem disabled value={""}>
                      Select
                    </MenuItem>
                    {Object.entries(STATES).map(([key, value]) => {
                      return (
                        <MenuItem key={key} value={key}>
                          {value}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={4} align="left">
                  <TextField
                    fullWidth
                    label="City"
                    name="subscriberCity"
                    error={Boolean(touched.subscriberCity && errors.subscriberCity)}
                    helperText={touched.subscriberCity && errors.subscriberCity}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.subscriberCity}
                    disabled={privatePayClient}
                  />
                </Grid>

                <Grid item xs={12} sm={4} align="left">
                  <TextField
                    fullWidth
                    label="ZIP Code"
                    name="subscriberZip"
                    error={Boolean(touched.subscriberZip && errors.subscriberZip)}
                    helperText={touched.subscriberZip && errors.subscriberZip}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.subscriberZip}
                    disabled={privatePayClient}
                  />
                </Grid>

                <Grid item xs={12} align="left">
                  <TextField
                    fullWidth
                    label="Subscriber's Address"
                    name="subscriberAddress"
                    size="small"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.subscriberAddress}
                    error={touched.subscriberAddress && !!errors.subscriberAddress}
                    helperText={touched.subscriberAddress && errors.subscriberAddress}
                    disabled={privatePayClient}
                  />
                </Grid>

                <Grid item xs={12} align="left">
                  <TextField
                    fullWidth
                    label="Subscriber's Address 2"
                    name="subscriberAddress2"
                    size="small"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.subscriberAddress2}
                    error={touched.subscriberAddress2 && !!errors.subscriberAddress2}
                    helperText={touched.subscriberAddress2 && errors.subscriberAddress2}
                    disabled={privatePayClient}
                  />
                </Grid>

                {!isPrimary && (
                  <Grid item xs={12} align="left">
                    {insurance?.id ? (
                      <DisableInsuranceButton
                        insurance={insurance}
                        onSuccess={() => getUserInsurance(clientId)}
                      />
                    ) : (
                      <Button variant={"text"} color="error" onClick={onCancelCreateInsurance}>
                        Remove Insurance
                      </Button>
                    )}
                  </Grid>
                )}
              </Grid>
            </>
          );
        }}
      </Formik>
    );
  }
);

InsuranceDetailsEdit.displayName = "InsuranceDetailsEdit";

export default InsuranceDetailsEdit;
