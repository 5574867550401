import React from "react";
import Modal from "elements/Modal";
import { CustomSelect } from "elements/DEPRECATED_elements";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

const DeactivateUserDialog = (props) => {
  const {
    activationDialogOpen,
    onToggleActivationDialog,
    onSubmitToggleActivation,
    reason,
    note,
    onChangeDeactivationInfo,
    onClearDeactivationInfo,
    customerDeactivationReasons,
    isActive,
    isRoleClinician,
    clinicianDeactivationReasons,
    numberOfClients,
    hasScheduledCall,
  } = props;

  let deactivationReasons = isRoleClinician
    ? clinicianDeactivationReasons
    : customerDeactivationReasons;

  let currentUser = isRoleClinician ? "clinician" : "customer";
  return isActive ? (
    <Modal
      open={activationDialogOpen}
      onClose={() => {
        onClearDeactivationInfo();
        onToggleActivationDialog();
      }}
      titleCentered
      title={`Are you sure you would like to deactivate this ${currentUser}?`}
      content={
        <div>
          <TextField
            select
            fullWidth
            label="Reason"
            id="reason-menu"
            value={reason}
            name="reason"
            style={{ margin: "15px 0" }}
            onChange={onChangeDeactivationInfo}
          >
            <MenuItem disabled value="" />
            {deactivationReasons?.map((el) => (
              <MenuItem key={el.id} value={el.reason}>
                {el.reason}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            fullWidth
            id="reason-note"
            name="note"
            value={note}
            onChange={onChangeDeactivationInfo}
            style={{ marginTop: 5 }}
            multiline={true}
            rows={3}
            placeholder="Type your note here..."
          />
        </div>
      }
      error={(isRoleClinician && numberOfClients > 0) || hasScheduledCall}
      errorMessage={
        isRoleClinician && numberOfClients > 0
          ? " This clinician is currently assigned to a client."
          : hasScheduledCall
            ? " The client has scheduled call/s. Please, cancel all calls before deactivation."
            : null
      }
      primaryActionText="Deactivate"
      primaryActionOnClick={() => {
        onClearDeactivationInfo();
        onSubmitToggleActivation();
      }}
      primaryActionDisabled={
        !note || !reason || (isRoleClinician && numberOfClients > 0) || hasScheduledCall
      }
      secondaryActionText="Cancel"
      secondaryActionOnClick={() => {
        onClearDeactivationInfo();
        onToggleActivationDialog();
      }}
    />
  ) : (
    <Modal
      open={activationDialogOpen}
      onClose={() => {
        onClearDeactivationInfo();
        onToggleActivationDialog();
      }}
      titleCentered
      title={`Are you sure you would like to activate this ${currentUser}?`}
      primaryActionText="Activate"
      primaryActionOnClick={onSubmitToggleActivation}
      secondaryActionText="Cancel"
      secondaryActionOnClick={onToggleActivationDialog}
    />
  );
};

export default DeactivateUserDialog;
