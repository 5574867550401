import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AppBar from "@mui/material/AppBar";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import withStyles from "@mui/styles/withStyles";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import { addDays } from "date-fns";
import actions from "../../actions";
import styles from "./styles";
import InsuranceCustomers from "./InsuranceCustomers";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

import Auth from "@aws-amplify/auth";

import {
  getCustomerList,
  getCustomersSort,
  getCustomersSortDirection,
  getCustomersPage,
  getCustomersPerPage,
  getCustomersCount,
  getCustomersExpired,
  getInsuranceCustomersFilter,
  getInsuranceProviders,
  getCreateCustomerSaving,
  getCreateCustomerSuccess,
  getCreateCustomerError,
  getCreateUserId,
  getCreateUserCognitoId,
  getInsuranceCustomersType,
  getInsuranceCustomersState,
  getCustomersBillableTimePeriod,
  getUserPermissionsList,
  getCustomersLoading,
  getImportClientLoading,
  getImportClientError,
  getImportClientErrorMessage,
  getImportClientUserId,
  getImportClientClientId,
  getImportClientMissingFields,
  getImportClientMissingFieldsLoading,
  getImportClientMissingFieldsSuccess,
  getClientCriticalNeeds,
  getUserId,
  getSnoozeTaskLoading,
  getSnoozeTaskError,
} from "../../selectors";
import PageContainer from "elements/PageContainer";
import SectionContainer from "elements/SectionContainer";
import Heading from "elements/Heading";
import BillingDialog from "./BillingDialog";
import CriticalNeeds from "./CriticalNeeds/CriticalNeeds";

const mapStateToProps = (state) => ({
  customers: getCustomerList(state),
  sort: getCustomersSort(state),
  direction: getCustomersSortDirection(state),
  count: getCustomersCount(state),
  page: getCustomersPage(state),
  rowsPerPage: getCustomersPerPage(state),
  expired: getCustomersExpired(state),
  insuranceCustomersFilter: getInsuranceCustomersFilter(state),
  insuranceCustomersType: getInsuranceCustomersType(state),
  insuranceCustomersState: getInsuranceCustomersState(state),
  insuranceProviders: getInsuranceProviders(state),
  createUserSaving: getCreateCustomerSaving(state),
  createUserSuccess: getCreateCustomerSuccess(state),
  createUserError: getCreateCustomerError(state),
  createUserId: getCreateUserId(state),
  createUserCognitoId: getCreateUserCognitoId(state),
  billableTimePeriod: getCustomersBillableTimePeriod(state),
  userPermissions: getUserPermissionsList(state),
  loading: getCustomersLoading(state),
  importClientLoading: getImportClientLoading(state),
  importClientError: getImportClientError(state),
  importClientErrorMessage: getImportClientErrorMessage(state),
  importClientUserId: getImportClientUserId(state),
  importClientClientId: getImportClientClientId(state),
  criticalNeeds: getClientCriticalNeeds(state),
  userId: getUserId(state),
  snoozeTaskLoading: getSnoozeTaskLoading(state),
  snoozeTaskError: getSnoozeTaskError(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getInsuranceCustomerList: actions.getInsuranceCustomerList,
      checkAuthState: actions.checkAuthState,
      setCustomersPage: actions.setCustomersPage,
      setCustomersPerPage: actions.setCustomersPerPage,
      setPageDetails: actions.setPageDetails,
      setCustomersSort: actions.setCustomersSort,
      clearCustomers: actions.clearCustomers,
      setInsuranceCustomersFilter: actions.setInsuranceCustomersFilter,
      setInsuranceCustomersType: actions.setInsuranceCustomersType,
      setInsuranceCustomersState: actions.setInsuranceCustomersState,
      getInsuranceProviders: actions.getInsuranceProviders,
      setSaving: actions.setSaving,
      createInsuranceCustomer: actions.createInsuranceCustomer,
      uploadCustomerDocument: actions.uploadCustomerDocument,
      clearCreateCustomer: actions.clearCreateCustomer,
      setCustomersBillableTimePeriod: actions.setCustomersBillableTimePeriod,
      loadCustomerDetails: actions.loadCustomerDetails,
      getClientCriticalNeeds: actions.getClientCriticalNeeds,
      snoozeClientTask: actions.snoozeClientTask,
    },
    dispatch
  );

class AdminDashboardContainer extends Component {
  state = {
    currentInsuranceTab: 0,
    insuranceFilter: "",
    statusFilter: "",
    billingDialogOpen: false,
    billingStartDate: "",
    billingEndDate: "",
    billingState: "",
  };

  handleInsuranceTabChange = (event, newValue) => {
    this.setState({ currentInsuranceTab: newValue });
    this.props.setCustomersPage(0);
  };

  downloadBillingRoster = async () => {
    const baseUrl = process.env.BASE_URL;
    const userSession = await Auth.currentSession();
    location.href = `${baseUrl}/download/roster/${userSession.accessToken.jwtToken}`;
  };

  downloadClientCSV = async () => {
    const baseUrl = process.env.BASE_URL;
    const userSession = await Auth.currentSession();
    location.href = `${baseUrl}/download/${userSession.accessToken.jwtToken}/client-csv`;
  };

  handleChangePage = (event, page) => {
    this.props.setCustomersPage(page);
  };

  handleChangeRowsPerPage = (event) => {
    this.props.setCustomersPage(0);
    this.props.setCustomersPerPage(parseInt(event.target.value));
  };

  handleCustomerSortClick = (name) => {
    this.props.setCustomersSort({
      sort: name,
    });
  };

  viewCustomerDetails = (clientId) => {
    let customer = "/clients/" + clientId + "/dashboard";
    this.props.history.push(customer);
  };

  handleFilterChange = (name) => (e) => {
    const { value } = e.target;
    this.setState({ [name]: value }, () => {
      this.handleInsuranceCustomersFilter();
    });
  };

  handleInsuranceCustomersFilter = () => {
    let filter = "";
    if (this.state.insuranceFilter) {
      filter += `?filter=${this.state.insuranceFilter}`;
      if (this.state.statusFilter) {
        filter += `&filter=${this.state.statusFilter}`;
      }
    } else if (this.state.statusFilter) {
      filter += `?filter=${this.state.statusFilter}`;
    }
    this.props.setInsuranceCustomersFilter(filter);
  };

  handleStateChange = (e) => {
    const { value } = e.target;
    this.props.setInsuranceCustomersState(value);
  };

  componentDidMount() {
    this.props.setPageDetails({
      pageName: "Home",
      currentPage: "dashboard",
      menu: "adminHome",
    });
    this.persistInsuranceTab();
    this.props.getInsuranceCustomerList();
    this.props.getInsuranceProviders();
    this.props.getClientCriticalNeeds();
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentInsuranceTab } = this.state;
    if (
      prevProps.page !== this.props.page ||
      prevProps.rowsPerPage !== this.props.rowsPerPage ||
      prevProps.sort !== this.props.sort ||
      prevProps.direction !== this.props.direction ||
      prevProps.insuranceCustomersType !== this.props.insuranceCustomersType ||
      prevProps.billableTimePeriod !== this.props.billableTimePeriod ||
      prevProps.insuranceCustomersState !== this.props.insuranceCustomersState
    ) {
      this.props.getInsuranceCustomerList();
    }

    if (prevState.currentInsuranceTab !== currentInsuranceTab) {
      let type;
      if (currentInsuranceTab === 0) {
        type = "AWAITING";
      } else if (currentInsuranceTab === 1) {
        type = "ASSESSMENT";
      } else {
        type = "CUSTOMER";
      }
      this.props.setInsuranceCustomersType(type);
    }
  }

  componentWillUnmount() {
    this.props.clearCustomers();
    this.props.setCustomersSort("");
    this.props.setInsuranceCustomersFilter("");
    this.props.setInsuranceCustomersState("");
  }

  persistInsuranceTab = () => {
    const { insuranceCustomersType } = this.props;
    let tab;
    if (insuranceCustomersType === "AWAITING") {
      tab = 0;
    } else if (insuranceCustomersType === "ASSESSMENT") {
      tab = 1;
    } else {
      tab = 2;
    }
    this.setState({ currentInsuranceTab: tab });
  };

  downloadBillingData = async () => {
    const { billingStartDate, billingEndDate, billingState } = this.state;
    const startDate = new Date(billingStartDate).toISOString();
    const endDate = new Date(addDays(new Date(billingEndDate), 1)).toISOString();
    const baseUrl = process.env.BASE_URL;
    const userSession = await Auth.currentSession();
    if (billingState !== "") {
      location.href = `${baseUrl}/download/billing/${userSession.accessToken.jwtToken}?start_date=${startDate}&end_date=${endDate}&state=${billingState}`;
    } else {
      location.href = `${baseUrl}/download/billing/${userSession.accessToken.jwtToken}?start_date=${startDate}&end_date=${endDate}`;
    }
    this.onCloseBillingDialog();
  };

  onChangeDate = (name, date) => {
    this.setState({ [name]: date });
  };

  onChangeState = (e) => {
    const { value } = e.target;
    this.setState({ billingState: value });
  };

  onOpenBillingDialog = () => {
    this.setState({ billingDialogOpen: true });
  };

  onCloseBillingDialog = () => {
    this.setState({
      billingDialogOpen: false,
      billingStartDate: "",
      billingEndDate: "",
      billingState: "",
    });
  };

  onCreateCustomer = () => {
    this.props.history.push("/client-onboarding");
  };

  handleChangeBillableTimePeriod = (e) => {
    this.props.setCustomersBillableTimePeriod(e.target.value);
  };

  render() {
    const {
      expired,
      customerCount,
      classes,
      billableTimePeriod,
      userPermissions,
      loading,
      insuranceCustomersState,
      criticalNeeds,
      ...otherProps
    } = this.props;
    const { currentInsuranceTab } = this.state;

    return (
      <PageContainer loading={loading}>
        <CriticalNeeds
          criticalNeeds={criticalNeeds}
          history={this.props.history}
          userId={this.props.userId}
          snoozeClientTask={this.props.snoozeClientTask}
          snoozeTaskLoading={this.props.snoozeTaskLoading}
          snoozeTaskError={this.props.snoozeTaskError}
        />
        <SectionContainer noPadding columnDirection>
          <div className={classes.downloadButtonsContainer}>
            <Button onClick={this.downloadClientCSV}>Download Client CSV</Button>
            {userPermissions?.download_billing_roster && (
              <Button onClick={this.downloadBillingRoster}>Download Billing Roster</Button>
            )}
          </div>
          <div style={{ width: "100%", padding: "24px 0" }}>
            <Heading style={{ textAlign: "left", marginLeft: "25px" }}>Insurance Clients</Heading>
            <div className={classes.header}>
              <TextField
                id="state"
                select
                value={insuranceCustomersState}
                name="state"
                onChange={(e) => this.handleStateChange(e)}
                label="Filter by State"
                sx={{ width: 256 }}
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="GA">Georgia</MenuItem>
                <MenuItem value="VA">Virginia</MenuItem>
              </TextField>
              <FormControl
                component="fieldset"
                style={{
                  border: "1px solid #cecece",
                  borderRadius: 4,
                  padding: "0 5px",
                  marginTop: 20,
                }}
              >
                <FormLabel component="legend" align="center" style={{ margin: "5px 0" }}>
                  Billable Time Period
                </FormLabel>
                <RadioGroup
                  row
                  aria-label="billableTime"
                  name="billableTime"
                  value={billableTimePeriod}
                  onChange={this.handleChangeBillableTimePeriod}
                >
                  <FormControlLabel
                    value="week"
                    control={<Radio color="primary" />}
                    label="Week"
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    value="month"
                    control={<Radio color="primary" />}
                    label="Month"
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    value="authperiod"
                    control={<Radio color="primary" />}
                    label="Auth Period"
                    labelPlacement="start"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <AppBar position="static" color="default">
              <Tabs
                value={currentInsuranceTab}
                onChange={this.handleInsuranceTabChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                classes={{
                  root: classes.insuranceTabsContainer,
                }}
              >
                <Tab label="Awaiting Eligibility" />
                <Tab label="In Assessment" />
                <Tab label="Full Customer" />
              </Tabs>
            </AppBar>
            <InsuranceCustomers
              {...otherProps}
              {...this.state}
              userPermissions={userPermissions}
              viewCustomerDetails={this.viewCustomerDetails}
              handleChangePage={this.handleChangePage}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              handleSortClick={this.handleCustomerSortClick}
              handleFilterChange={this.handleFilterChange}
            />
          </div>
          <BillingDialog
            onCloseBillingDialog={this.onCloseBillingDialog}
            onChangeDate={this.onChangeDate}
            onChangeState={this.onChangeState}
            downloadBillingData={this.downloadBillingData}
            {...this.state}
          />
        </SectionContainer>
      </PageContainer>
    );
  }
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(AdminDashboardContainer)
);
