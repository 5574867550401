import React, { useEffect, useState } from "react";
import EntryPoint from "./EntryPoint";
import WithCode from "./WithCode";
import WithPassword from "./WithPassword";
import { LoginMethods, ContactMethods, OtpErrorTypes } from "./types";
import { requestOtp, verifyOtp } from "../../../api/otp";
import { validateEmail, validatePhoneNumber, parsePhoneNumber, navigate } from "./util";

const SignInFlowProvider = (props) => {
  const {
    classes,
    username,
    password,
    loginMethod,
    otp,
    otpError,
    redirected,
    receivedParamsFromLink,
    handleSubmit,
    handleForgotPassword,
    handleShowPassword,
    urlParams,
    signinFailure,
    notConfirm,
    getNeedsReset,
    renderErrorMessage,
    superOnChange,
    startSignin,
  } = props;

  // ===================================================
  // STATES
  // ===================================================
  const [contactMethod, setContactMethod] = useState(ContactMethods.EMAIL);
  const [showCodeResentMessage, setShowCodeResentMessage] = useState(false);

  // ===================================================
  // HANDLERS
  // ===================================================
  const handleUsernameChange = (e) => {
    superOnChange("otpError")(null);
    const { value } = e.target;

    const isValidPhoneNumber = validatePhoneNumber(value);
    if (isValidPhoneNumber) {
      setContactMethod(ContactMethods.PHONE);
    }

    superOnChange("username")(value);
  };

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    superOnChange("password")(value);
  };

  const handleLoginWithCode = () => {
    const isValidPhoneNumber = validatePhoneNumber(username);
    const isValidEmail = validateEmail(username);

    try {
      if (!isValidPhoneNumber && !isValidEmail) {
        throw new Error();
      }
      superOnChange("loginMethod")(LoginMethods.CODE);
      requestOtp(isValidPhoneNumber ? parsePhoneNumber(username) : username);
    } catch (e) {
      if (loginMethod === LoginMethods.PASSWORD) {
        superOnChange("loginMethod")(null);
        superOnChange("receivedParamsFromLink")(false);
        navigate("/", false);
      } else {
        superOnChange("otpError")(OtpErrorTypes.FORMAT);
        console.error(e);
      }
    }
  };

  const handleLoginWithPassword = () => {
    superOnChange("loginMethod")(LoginMethods.PASSWORD);
    superOnChange("receivedParamsFromLink")(false);

    if (urlParams.has("redirect")) {
      navigate(`/?redirect=${urlParams.get("redirect")}`, false);
    } else {
      navigate("/", false);
    }
  };

  const handleOtpChange = (value) => {
    setShowCodeResentMessage(false);
    superOnChange("otpError")(null);
    superOnChange("otp")(value);
  };

  const handleOtpComplete = (code) => {
    if (!otpError) {
      verifyOtp({ login: username, code })
        .then(({ data }) => {
          if (!data.email || !data.accessToken || !data.refreshToken) {
            throw new Error("Invalid token");
          }
          startSignin({ ...data, username: data.email });
          superOnChange("otp")("");
        })
        .catch((e) => {
          console.error(e);
          superOnChange("otpError")(OtpErrorTypes.INVALID);
        });
    }
  };

  const handleReSendCode = () => {
    setShowCodeResentMessage(true);
    requestOtp(username);
    superOnChange("otp")("");
  };

  const flowProps = {
    classes,
    loginMethod,
    contactMethod,
    username,
    password,
    otp,
    otpError,
    showCodeResentMessage,
    setShowCodeResentMessage,
    handleUsernameChange,
    handlePasswordChange,
    handleLoginWithCode,
    handleLoginWithPassword,
    handleOtpChange,
    handleOtpComplete,
    handleReSendCode,
    handleForgotPassword,
    handleShowPassword,
    validatePhoneNumber,
    onSubmit: handleSubmit,
    receivedParamsFromLink,
    signinFailure,
    notConfirm,
    getNeedsReset,
    renderErrorMessage,
    ...props,
  };

  const consumeParams = () => {
    try {
      if (receivedParamsFromLink || redirected) {
        superOnChange("loginMethod")(LoginMethods.CODE);
        if (receivedParamsFromLink) {
          superOnChange("receivedParamsFromLink")(false);
          superOnChange("otp")(urlParams.get("otp"));
          urlParams.delete("otp");
        }
        superOnChange("redirected")(false);
        const usernameFromUrl = urlParams.get("email");
        if (validateEmail(usernameFromUrl)) {
          superOnChange("username")(usernameFromUrl);
        }
        urlParams.delete("email");
      }
    } catch (e) {
      console.error(e);
      superOnChange("otpError")(OtpErrorTypes.FORMAT);
      navigate("/", false);
    }
  };

  useEffect(consumeParams, []);

  if (loginMethod == LoginMethods.CODE) {
    return <WithCode {...flowProps} />;
  } else if (loginMethod == LoginMethods.PASSWORD) {
    return <WithPassword {...flowProps} />;
  } else {
    return <EntryPoint {...flowProps} />;
  }
};

export default SignInFlowProvider;
