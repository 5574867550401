import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  getUserPermissionsList,
  getClinicianWorkflowStatus,
  isCaregiver as isCaregiverSelector,
} from "../../selectors";
import AdminDashboard from "../AdminDashboard";
import Clinicians from "../Clinicians";
import ClinicianDashboard from "../ClinicianDashboard";
import ClientDashboard from "../ClientDashboard";
import PageContainer from "elements/PageContainer";
import { useHistory, useLocation } from "react-router-dom";
import cookie from "react-cookies";
import { useGetClients } from "hooks/api/useGetClients";
import { addDays } from "date-fns";

export default function DashboardContainer() {
  const userPermissions = useSelector(getUserPermissionsList);
  const clinicianWorkflowStatus = useSelector(getClinicianWorkflowStatus);
  const isCaregiver = useSelector(isCaregiverSelector);
  const history = useHistory();

  const userId = cookie.load("userId");
  const { clients, clientsLoading } = useGetClients({ userIds: userId });

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const clientIdParam = query.get("clientId");

  useEffect(() => {
    const clientIdBelongsToUser = clients?.some((client) => client.id === Number(clientIdParam));
    // This was originally created for the treatment-plan email link
    if (clientIdParam && isCaregiver && clientIdBelongsToUser) {
      cookie.save(`${userId}-clientId`, clientIdParam, {
        path: "/",
        expires: addDays(new Date(), 30),
      });
      // Remove the clientId parameter from the URL
      query.delete("clientId");
      history.replace({ search: query.toString() });
    }
  }, [clientIdParam, isCaregiver, clients]);

  if (clientsLoading || !userPermissions) return <PageContainer loading={true} />;

  let clinicianOnboarded = true;
  if (userPermissions?.view_clinician_dashboard && !clinicianWorkflowStatus) {
    clinicianOnboarded = false;
  }

  return (
    <>
      {userPermissions?.view_clinician_dashboard && (
        <ClinicianDashboard history={history} location={location} />
      )}
      {userPermissions?.view_admin_dashboard && clinicianOnboarded && (
        <AdminDashboard history={history} />
      )}
      {userPermissions?.view_clinician_view_only_dashboard && <Clinicians history={history} />}

      {userPermissions?.view_client_dashboard && <ClientDashboard history={history} />}
    </>
  );
}
