import React, { useState } from "react";
import { useQueryClient } from "react-query";
import { Link, Container, Typography } from "@mui/material";

import SectionBox from "elements/SectionBox";
import { useGlobalToast } from "components/GlobalToastProvider";
import PrivacyItem from "./PrivacyItem";
import { useUpdateClient } from "hooks/api/useUpdateClient";
import { useGetUserNotificationPreferences } from "hooks/api/useGetUserNotificationPreferences";
import { useSetUserNotificationPreferences } from "hooks/api/useSetUserNotificationPreferences";
import { useGetClientById } from "hooks/api/useGetClientById";
import { GET_USER_BY_ID } from "constants/reactQueryKeys";

/**
 * The `DataPrivacy` component manages data privacy settings for a user, including options for Research
 * Participation and Electronic Communications.
 * @component
 * @param {Object} props - The props of the component
 * @param {boolean} props.isDesignatedCaregiver - A boolean value to determine if the user is the designated caregiver
 * @param {string} props.userId - The user ID of the member
 * @param {string} props.clientId - The ID of the client
 * @returns {JSX.Element} The `DataPrivacy` component is being returned.
 */
function DataPrivacy({ isDesignatedCaregiver, userId, clientId }) {
  const queryClient = useQueryClient();
  const { showToast } = useGlobalToast();
  // State for research participation
  const [researchDisabled, setResearchDisabled] = useState(false);
  // State for electronic communications
  const [electronicDisabled, setElectronicDisabled] = useState(false);

  // Fetch the client data
  const {
    data: clientResponse,
    isLoading: clientLoading,
    refetch: refetchGetClientById,
  } = useGetClientById(clientId);

  const researchParticipation = clientResponse?.data?.agreeToResearchParticipation;

  // Fetch the user notification preferences
  const {
    data: electronicCommunicationsResponse,
    isLoading: electronicCommunicationsLoading,
    refetch: refetchUserNotificationPreferences,
  } = useGetUserNotificationPreferences(userId);

  const electronicCommunications = electronicCommunicationsResponse?.data?.send_sms;

  const { updateClientMutation } = useUpdateClient({
    onSuccess: () => {
      showToast({ message: "Data privacy settings updated successfully" });
      // Refetch the client data
      refetchGetClientById();
    },
    onSettled: () => {
      // Re-enable the switches after the mutation is complete
      setResearchDisabled(false);
    },
  });
  const { setUserNotificationPreferencesMutation } = useSetUserNotificationPreferences({
    onSuccess: () => {
      showToast({ message: "Data privacy settings updated successfully" });
      // Refetch the user notification preferences and user data
      refetchUserNotificationPreferences();
      queryClient.invalidateQueries([GET_USER_BY_ID, userId]);
    },
    onSettled: () => {
      // Re-enable the switches after the mutation is complete
      setElectronicDisabled(false);
    },
  });

  const handleResearchChange = () => {
    // Disable the switch while the mutation is in progress
    setResearchDisabled(true);
    updateClientMutation({
      clientId,
      data: { agree_to_research_participation: !researchParticipation },
    });
  };

  const handleElectronicChange = () => {
    // Disable the switch while the mutation is in progress
    setElectronicDisabled(true);
    //One switch controls the boolean for all three values
    const preference = !electronicCommunications;
    setUserNotificationPreferencesMutation({
      userId,
      data: {
        sendSms: preference,
        sendEmail: preference,
        sendPush: preference,
      },
    });
  };

  return (
    <>
      <SectionBox
        sx={{ padding: "32px 0 0" }}
        title="Data Privacy"
        description={
          <Typography component="p" variant="body2">
            Manage how we use and share data to enhance your AnswersNow experience. AnswersNow takes
            steps to de-identify data and protect your privacy.{" "}
            <Link href="https://www.getanswersnow.com/privacy">Learn more</Link>
          </Typography>
        }
      >
        <Container
          component="section"
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          {isDesignatedCaregiver && (
            <PrivacyItem
              title="Research Participation"
              description={`Contribute session and outcomes data to AnswersNow research initiatives.`}
              checked={researchParticipation}
              onChange={handleResearchChange}
              disabled={researchDisabled || clientLoading}
            />
          )}

          <PrivacyItem
            title="Electronic Communications"
            description={`Allow AnswersNow to send communications via email/text messages.`}
            checked={electronicCommunications}
            onChange={handleElectronicChange}
            disabled={electronicDisabled || electronicCommunicationsLoading}
          />
        </Container>
      </SectionBox>
    </>
  );
}

export default DataPrivacy;
