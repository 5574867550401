import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import { bindActionCreators } from "redux";
import { subMonths } from "date-fns";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import { CaretRight, CaretLeft, CaretDoubleRight, CaretDoubleLeft } from "@phosphor-icons/react";
import styles from "../../theme/globalStyles";
import actions from "../../actions";
import {
  isClinician,
  getUserVideoCallsLoading,
  getUserVideoCallsType,
  getUserVideoCallsCount,
  getUserVideoCallsSortDirection,
  getUserVideoCallsSort,
  getUserVideoCallsPerPage,
  getUserVideoCallsPage,
  getUserVideoCalls,
  getCustomerDetails,
  getSignedinState,
  isCustomerCare,
  getUserPermissionsList,
  scheduleCallSuccess,
  getUserVideoFilterStartDate,
  getUserVideoFilterEndDate,
  getVideoBreakdown,
  getVideoBreakdownLoading,
} from "../../selectors";
import { CustomTable } from "elements/DEPRECATED_elements";
import PageContainer from "elements/PageContainer";
import { CustomHeaderCell } from "elements/DEPRECATED_elements";
import SectionContainer from "elements/SectionContainer";
import Modal from "elements/Modal";
import Button from "@mui/material/Button";
import Auth from "@aws-amplify/auth";
import { capitalizeBillingTypeFirstLetter } from "../../utils/capitalizeBillingTypeFirstLetter";
import Heading from "elements/Heading";
import moment from "moment-timezone";
import { findDifferenceInMinutes } from "../../utils/findDifferenceInMinutes";
import { Tooltip } from "@mui/material";
import ANDatePicker from "elements/ANDatePicker";

const mapStateToProps = (state) => ({
  signedInState: getSignedinState(state),
  customerDetails: getCustomerDetails(state),
  userVideoCalls: getUserVideoCalls(state),
  userVideoPage: getUserVideoCallsPage(state),
  userVideoRowsPerPage: getUserVideoCallsPerPage(state),
  userVideoSort: getUserVideoCallsSort(state),
  userVideoDirection: getUserVideoCallsSortDirection(state),
  userVideoCount: getUserVideoCallsCount(state),
  userVideoType: getUserVideoCallsType(state),
  userVideoCallsLoading: getUserVideoCallsLoading(state),
  isClinician: isClinician(state),
  isCustomerCare: isCustomerCare(state),
  userPermissions: getUserPermissionsList(state),
  scheduleCallSuccess: scheduleCallSuccess(state),
  userFilterStartDate: getUserVideoFilterStartDate(state),
  userFilterEndDate: getUserVideoFilterEndDate(state),
  videoCallBreakdown: getVideoBreakdown(state),
  videoCallBreakdownLoading: getVideoBreakdownLoading(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadCustomerDetails: actions.loadCustomerDetails,
      getUserVideoCalls: actions.getUserVideoCalls,
      setUserVideoCallsPage: actions.setUserVideoCallsPage,
      setUserVideoCallsPerPage: actions.setUserVideoCallsPerPage,
      setUserVideoCallsSort: actions.setUserVideoCallsSort,
      setUserVideoFilterStartDate: actions.setUserVideoFilterStartDate,
      setUserVideoFilterEndDate: actions.setUserVideoFilterEndDate,
      getVideoCallBreakdown: actions.getVideoCallBreakdown,
    },
    dispatch,
  );

class CustomerVideoCalls extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rescheduleCall: false,
      rescheduleDetails: null,
      noSessionNotesDialogOpen: false,
      downloadVideoCallId: null,
    };
  }

  componentDidMount() {
    if (this.props.match.params.clientId) {
      this.props.setUserVideoFilterStartDate(subMonths(new Date(), 1));
      this.props.setUserVideoFilterEndDate(new Date());
      this.props.getUserVideoCalls(this.props.match.params.clientId);
      this.props.loadCustomerDetails(this.props.match.params.clientId);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.userVideoPage !== this.props.userVideoPage ||
      prevProps.userVideoSort !== this.props.userVideoSort ||
      prevProps.userVideoDirection !== this.props.userVideoDirection ||
      prevProps.userVideoRowsPerPage !== this.props.userVideoRowsPerPage ||
      prevProps.userVideoType !== this.props.userVideoType ||
      prevProps.userFilterStartDate !== this.props.userFilterStartDate ||
      prevProps.userFilterEndDate !== this.props.userFilterEndDate ||
      (!prevProps.scheduleCallSuccess && this.props.scheduleCallSuccess)
    ) {
      this.props.getUserVideoCalls(this.props.match.params.clientId);
    }
    if (
      prevProps.videoCallBreakdownLoading &&
      !this.props.videoCallBreakdownLoading &&
      this.state.downloadVideoCallId
    ) {
      if (
        this.props.videoCallBreakdown.length > 0 &&
        this.props.videoCallBreakdown.some((call) => !!call.note_id)
      ) {
        this.downloadAll(this.state.downloadVideoCallId);
        this.setState({ downloadVideoCallId: null });
      } else {
        this.setState({ noSessionNotesDialogOpen: true });
      }
    }
  }

  handleChangeVideoPage = (event, page) => {
    this.props.setUserVideoCallsPage(page);
  };

  handleChangeVideoRowsPerPage = (event) => {
    this.props.setUserVideoCallsPerPage(parseInt(event.target.value));
    this.props.setUserVideoCallsPage(0);
  };

  handleVideoSortClick = (name) => {
    this.props.setUserVideoCallsSort({
      sort: name,
    });
  };

  viewVideoCallDetails = (id) => {
    let videoCall = "/video-calls/" + id;
    this.props.history.push(videoCall);
  };

  downloadAll = async (videoCallId) => {
    const baseUrl = process.env.BASE_URL;
    const userSession = await Auth.currentSession();
    location.href = `${baseUrl}/download/${userSession.accessToken.jwtToken}/all-session-notes/${videoCallId}`;
    this.setState({ downloadVideoCallId: null });
  };

  onClickDownloadSessionNotes = (videoCallId) => {
    this.setState({ downloadVideoCallId: videoCallId });
    this.props.getVideoCallBreakdown(videoCallId);
  };

  onCloseNoSessionNotesDialog = () => {
    this.setState({ noSessionNotesDialogOpen: false });
  };

  render() {
    const {
      classes,
      userVideoCalls,
      userVideoPage,
      userVideoCount,
      userVideoRowsPerPage,
      userVideoDirection,
      userVideoSort,
      userVideoType,
      isClinician,
      customerDetails,
      userPermissions,
      userVideoCallsLoading,
      setUserVideoFilterEndDate,
      setUserVideoFilterStartDate,
      userFilterStartDate,
      userFilterEndDate,
    } = this.props;

    let rows = userVideoCalls;

    return (
      <PageContainer loading={userVideoCallsLoading}>
        <SectionContainer columnDirection>
          <div className={classes.headerColumnDirection}>
            <Heading>
              Video Calls
              {customerDetails.details && customerDetails.details.first_name
                ? ` - ${customerDetails.details.first_name} ${customerDetails.details.last_name}`
                : ""}
            </Heading>
            <Typography component="h5" style={{ fontSize: 15, color: "#DC2626" }}>
              * The times shown are local to the client
            </Typography>
          </div>
          <div className={classes.header}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Typography component="p" style={{ fontSize: 20, fontWeight: 500, marginBottom: 20 }}>
                Filter By Date
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <ANDatePicker
                  value={new Date(userFilterStartDate) || null}
                  onChange={(date) => setUserVideoFilterStartDate(date)}
                  label="Start Date"
                  format="PP" //"MMM d, yyyy"
                  style={{ marginRight: 20 }}
                />
                <ANDatePicker
                  value={new Date(userFilterEndDate) || null}
                  onChange={(date) => setUserVideoFilterEndDate(date)}
                  label="End Date"
                  format="PP" //"MMM d, yyyy"
                  maxDate={new Date()}
                />
              </div>
            </div>
          </div>
          <CustomTable
            count={userVideoCount}
            rowsPerPage={userVideoRowsPerPage}
            page={userVideoPage}
            handleChangePage={this.handleChangeVideoPage}
            handleChangeRowsPerPage={this.handleChangeVideoRowsPerPage}
            noResultsMessage="No Calls Found"
            headerCells={[
              <CustomHeaderCell
                label="Begin"
                sortable={true}
                sortDirection={
                  userVideoSort === "vc.scheduled_date" || !userVideoSort
                    ? userVideoDirection
                    : false
                }
                active={userVideoSort === "vc.scheduled_date"}
                onClick={() => this.handleVideoSortClick("vc.scheduled_date")}
              />,

              <CustomHeaderCell
                label="End"
                sortable={true}
                sortDirection={userVideoSort === "vc.start_date" ? userVideoDirection : false}
                active={userVideoSort === "vc.start_date"}
                onClick={() => this.handleVideoSortClick("vc.start_date")}
              />,
              <CustomHeaderCell label="Call Duration" />,
              <CustomHeaderCell label="Call Type" />,
              <CustomHeaderCell label="Call Details" />,
              userPermissions?.download_session_notes && (
                <CustomHeaderCell label="Notes" align="center" />
              ),
            ]}
          >
            {rows.map((row, i) => (
              <TableRow key={row.id} style={i % 2 === 0 ? { backgroundColor: "#ECFAFA" } : null}>
                <TableCell align="left">
                  {row.start_date
                    ? moment.tz(row?.start_date, row?.call_timezone).format("MMM D, YYYY  h:mm a z")
                    : ""}
                </TableCell>
                <TableCell align="left">
                  {row.end_date
                    ? moment.tz(row?.end_date, row?.call_timezone).format("MMM D, YYYY  h:mm a z")
                    : ""}
                </TableCell>
                <TableCell align="left">
                  {row.end_date
                    ? `${findDifferenceInMinutes(row.end_date, row.start_date)} mins`
                    : ""}
                </TableCell>

                <TableCell align="left">
                  {row.billing_type ? capitalizeBillingTypeFirstLetter(row.billing_type) : null}
                </TableCell>

                <TableCell align="left">
                  <Button
                    onClick={() => this.viewVideoCallDetails(row.id)}
                    variant="text"
                    style={{ fontWeight: 500 }}
                    disabled={!userPermissions?.view_video_call_details}
                  >
                    View
                  </Button>
                </TableCell>
                {userPermissions?.download_session_notes && (
                  <TableCell align="center">
                    <Button
                      variant="text"
                      onClick={() => this.onClickDownloadSessionNotes(row.id)}
                      style={{ fontWeight: 500 }}
                    >
                      Download
                    </Button>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </CustomTable>
        </SectionContainer>

        <Modal
          open={this.state.noSessionNotesDialogOpen}
          title="No Session Notes Exist"
          description={
            <>
              This session does not have any session notes associated with it.
              <br /> Please contact the clinical department for more information.
            </>
          }
          primaryActionText="Close"
          primaryActionOnClick={this.onCloseNoSessionNotesDialog}
        />
      </PageContainer>
    );
  }
}

const actionsStyles = (theme) => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
  },
});

class TablePaginationActions extends React.Component {
  handleFirstPageButtonClick = (event) => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = (event) => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
    );
  };

  render() {
    const { className, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={className}>
        <Tooltip title="First page">
          <IconButton
            onClick={this.handleFirstPageButtonClick}
            disabled={page === 0}
            aria-label="First Page"
            size="large"
          >
            {theme.direction === "rtl" ? <CaretDoubleRight /> : <CaretDoubleLeft />}
          </IconButton>
        </Tooltip>
        <Tooltip title="Previous Page">
          <IconButton
            onClick={this.handleBackButtonClick}
            disabled={page === 0}
            aria-label="Previous Page"
            size="large"
          >
            {theme.direction === "rtl" ? <CaretRight /> : <CaretLeft />}
          </IconButton>
        </Tooltip>
        <Tooltip title="Next Page">
          <IconButton
            onClick={this.handleNextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="Next Page"
            size="large"
          >
            {theme.direction === "rtl" ? <CaretLeft /> : <CaretRight />}
          </IconButton>
        </Tooltip>
        <Tooltip title="Last Page">
          <IconButton
            onClick={this.handleLastPageButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="Last Page"
            size="large"
          >
            {theme.direction === "rtl" ? <CaretDoubleLeft /> : <CaretDoubleRight />}
          </IconButton>
        </Tooltip>
      </div>
    );
  }
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
};

const TablePaginationActionsWrapped = withStyles(actionsStyles, {
  withTheme: true,
})(TablePaginationActions);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CustomerVideoCalls));
