import React from "react";
import Modal from "elements/Modal";
import withStyles from "@mui/styles/withStyles";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import ANDatePicker from "elements/ANDatePicker";

const BillingDialog = (props) => {
  const {
    billingDialogOpen,
    onCloseBillingDialog,
    billingStartDate,
    onChangeDate,
    onChangeState,
    billingEndDate,
    downloadBillingData,
    billingState,
  } = props;
  return (
    <Modal
      open={billingDialogOpen}
      onClose={onCloseBillingDialog}
      title="Select date range and state for billing data"
      titleCentered
      content={
        <>
          <div style={{ width: "100%", margin: "10px 0" }}>
            <ANDatePicker
              value={new Date(billingStartDate) || null}
              onChange={(date) => onChangeDate("billingStartDate", date)}
              label="Start Date"
              format="PP" //"MMM d, yyyy"
              minDate={new Date("10/01/2020")}
            />
          </div>
          <div style={{ width: "100%", margin: "10px 0" }}>
            <ANDatePicker
              value={new Date(billingEndDate) || null}
              onChange={(date) => onChangeDate("billingEndDate", date)}
              label="End Date"
              format="PP" //"MMM d, yyyy"
            />
          </div>
          <div style={{ width: "100%", margin: "10px 0" }}>
            <TextField
              select
              id="state"
              value={billingState}
              name="state"
              onChange={(e) => onChangeState(e)}
              label="State"
              InputLabelProps={{ shrink: true }}
              fullWidth
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="GA">Georgia</MenuItem>
              <MenuItem value="VA">Virginia</MenuItem>
            </TextField>
          </div>
        </>
      }
      primaryActionText="Download"
      primaryActionOnClick={downloadBillingData}
      primaryActionDisabled={!billingStartDate || !billingEndDate}
      secondaryActionText="Cancel"
      secondaryActionOnClick={onCloseBillingDialog}
    />
  );
};

const styles = (theme) => ({
  datePopper: {
    zIndex: 4005,
  },
});

export default withStyles(styles)(BillingDialog);
