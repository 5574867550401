import * as AWS from "aws-sdk";
import { getAWSCredentialsForCurrentUserSession } from "./aws";

export const getS3SignedUrl = async (filename, cognito_id) => {
  const credentials = await getAWSCredentialsForCurrentUserSession();
  var s3 = new AWS.S3({ credentials });
  const params = {
    Bucket: process.env.AWS_USER_DOC_BUCKET,
    Key: `${cognito_id}/${filename}`,
  };
  var url = s3.getSignedUrl("getObject", params);
  return url;
};
