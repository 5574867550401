// Stale Time
export const STALE_TIME_60_MINUTES = 60 * 1000 * 60; // 60 minutes;
export const STALE_TIME_5_MINUTES = 60 * 1000 * 5; // 5 minutes;
export const STALE_TIME_10_MINUTES = 60 * 1000 * 10; // 5 minutes;
export const STALE_TIME_1_MINUTES = 60 * 1000;

// Keys
export const ASSESSMENTS_BATCH = "assessmentsBatch";
export const CLINICIAN_CREDENTIALS = "clinicianCredentials";
export const CLINICIAN_STATE_LICENSE = "clinicianStateLicense";
export const CLINICIAN_CERTIFICATION = "clinicianCertification";
export const CLINICIAN_LIABILITY_INSURANCE = "clinicianLiabilityInsurance";
export const USER_PERMISSIONS = "userPermissions";
export const CLIENT_ASSESSMENTS = "clientAssessments";
export const INTAKE_FLOW = "intakeFlow";
export const GET_CLIENTS = "getClients";
export const GET_INSURANCE_VOB = "getInsuranceVob";
export const GET_TEAM_MEMBERS = "getTeamMembers";
export const GET_TEAMS = "getTeams";
export const GET_TEAM_ROLES = "getTeamRoles";
export const GET_USERS = "getUsers";
export const GET_USER_BY_ID = "getUserById";
export const GET_TEAMS_CLIENT = "getTeamsClient";
export const GET_INSURANCE_PLAN = "getInsurancePlans";
export const GET_TEAM_MEMBER_INVITATION = "getTeamMemberInvitation";
export const GET_CLIENT_BY_ID = "getClientById";
export const GET_CUSTOMER_SIGNED_FORM = "GetCustomerSignedForm";
export const GET_USER_NOTIFICATION_PREFERENCES = "getUserNotificationPreferences";
export const GET_FINANCIAL_GUARANTOR = "getFinancialGuarantor";
export const GET_CLINICS = "getClinics";
export const GET_CLINIC_BY_ID = "getClinicById";
export const GET_PROVIDERS = "getProviders";
export const GET_PROVIDER_BY_ID = "getProviderById";
export const SEARCH_PROVIDERS = "searchProviders";
export const LANGUAGES = "languages";

