import React from "react";
import { Paper, Slide } from "@mui/material";

import ANLoadingScreen from "elements/ANLoadingScreen";
import { useGlobalToast } from "components/GlobalToastProvider";
import { useUpdateTeamUser } from "hooks/api/useUpdateTeamUser";
import { useGetUserById } from "hooks/api/user/useGetUserById";
import { useGetClients } from "hooks/api/useGetClients";
import TeamMemberDetail from "./TeamMemberDetail";
import EditTeamMember from "./EditTeamMember";
import { useUpdateUserEmail } from "hooks/api/useUpdateUserEmail";
/**
 * @typedef {import("api/typesDef").Member} Member
 * @typedef {import("api/typesDef").Customer} Customer
 */

/**
 * The `CollapsibleMemberView` component renders either an editable or detailed view of a team member
 * within a collapsible container based on the mode and open state.
 * @component
 * @param {Object} props - The props of the component
 * @param {Member} props.currentMember - The member data
 * @param {string} props.mode - The mode of the collapsible view (either "edit" or "view")
 * @param {boolean} props.open - The state of the collapsible view
 * @param {Function} props.setOpen - The function to set the state of the collapsible view
 * @returns {JSX.Element} The `<CollapsibleMemberView />` component is being returned.
 */
export default function CollapsibleMemberView({
  currentMember,
  mode,
  open,
  setOpen,
  userPermissions,
  refetchTeamMembers,
}) {
  const { userId } = currentMember;
  const isEditMode = mode === "edit";
  const { showToast } = useGlobalToast();

  // Fetch User data
  const { user, userLoading, refetchUserData, isUserDataRefetching } = useGetUserById(userId);

  // Fetch Clients data by Document Signer (Available only for designated caregivers)
  const isDesignatedCaregiver = currentMember.documentSigner;
  const { clients, clientsLoading } = useGetClients(
    isDesignatedCaregiver && { userIds: userId, role: "document_signer" }
  );

  // Save mutation to update Team Member
  const { updateTeamUserMutation } = useUpdateTeamUser({
    onSuccess: () => {
      showToast({ message: "Member updated successfully" });
      // Refresh the user data
      refetchUserData();
      // Refresh all team member data to account for name/email changes on table
      refetchTeamMembers();
    },
    onSettled: () => {
      // collapse the panel
      setOpen(false);
    },
  });

  // Hook to update customer email
  const { updateUserEmailMutationAsync } = useUpdateUserEmail({
    onSuccess: () => {
      showToast({ message: "Email updated successfully" });
    },
    onError: () => {
      showToast({ message: "Failed to update email", errorState: true });
    },
  });

  const handleUpdateMember = async (data) => {
    const { email: newEmail, ...memberData } = data;
    const originalEmail = user.email;

    // Check if the email has changed
    if (newEmail && newEmail !== originalEmail) {
      await updateUserEmailMutationAsync({ userId, newEmail });
    }

    return updateTeamUserMutation({ userId, data: memberData });
  };

  let researchParticipants = null;

  if (isDesignatedCaregiver && clients) {
    // Map the clients data to research participants
    researchParticipants = clients?.map(
      ({ id, agree_to_research_participation, name, child_last_name }) => ({
        id,
        fullname: `${name} ${child_last_name}`,
        agreeToResearchParticipation: agree_to_research_participation,
      })
    );
  }

  return (
    <Slide direction="left" in={open} mountOnEnter unmountOnExit>
      <Paper elevation={0} square sx={{ padding: 7, width: "400px" }}>
        {userLoading || clientsLoading || isUserDataRefetching ? (
          <ANLoadingScreen />
        ) : isEditMode ? (
          <EditTeamMember
            member={{ ...currentMember, ...user }}
            onClosePanel={() => setOpen(false)}
            onSubmit={handleUpdateMember}
            userPermissions={userPermissions}
          />
        ) : (
          <TeamMemberDetail
            member={{ ...currentMember, ...user }}
            researchParticipants={researchParticipants}
            onClosePanel={() => setOpen(false)}
          />
        )}
      </Paper>
    </Slide>
  );
}
