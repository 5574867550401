import { all } from "redux-saga/effects";
import auth from "./auth";
import appointmentChangeLog from "./appointmentChangeLog";
import clinician from "./clinician";
import clinicians from "./clinicians";
import customers from "./customers";
import insurance from "./insurance";
import notes from "./notes";
import parent from "./parent";
import reports from "./reports";
import signin from "./signin";
import tests from "./tests";
import user from "./user";
import users from "./users";
import video from "./video";
import chat from "./chat";
import chats from "./chats";
import sendbird from "./sendbird";
import system from "./system";
import communication from "./communication";
import billing from "./billing";

function* rootSaga() {
  yield all([
    auth(),
    appointmentChangeLog(),
    clinician(),
    clinicians(),
    customers(),
    insurance(),
    notes(),
    parent(),
    reports(),
    signin(),
    tests(),
    user(),
    users(),
    video(),
    chat(),
    chats(),
    sendbird(),
    system(),
    communication(),
    billing(),
  ]);
}
export default rootSaga;
