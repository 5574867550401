import React from "react";
import withStyles from "@mui/styles/withStyles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

const CustomTab = React.forwardRef((props, ref) => {
  const {
    classes,
    tabs,
    children,
    currentTab,
    handleTabChange,
    maxHeight,
    enableScroll,
    tabsWidth,
  } = props;
  let style = {};
  let tabsStyle = {};

  style.maxHeight = maxHeight ? maxHeight : null;
  style.overflowY = enableScroll ? "auto" : null;
  tabsStyle.width = tabsWidth ? tabsWidth : "100%";
  return (
    <div>
      <Tabs
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        value={currentTab}
        aria-label="client-call-tabs"
        onChange={handleTabChange}
        style={tabsStyle}
        classes={{
          root: classes.tabsContainer,
          indicator: classes.tabsIndicator,
          scroller: classes.tabsScroller,
        }}
      >
        {tabs.map((tab, i) => (
          <Tab
            classes={{ root: classes.tab, selected: classes.selectedTab }}
            key={i}
            label={tab}
            disableRipple
          />
        ))}
      </Tabs>
      <div className={classes.tabContent} style={style}>
        {children}
      </div>
    </div>
  );
});

export const styles = (theme) => ({
  tabsContainer: {
    margin: "0px auto",
    borderBottom: "2px solid #E1E3E9",
    overflow: "visible",
    "& .MuiButtonBase-root": {
      textTransform: "none",
      fontSize: 19,
      fontWeight: 300,
    },
    "& .MuiTab-textColorPrimary.Mui-selected": {
      fontWeight: 500,
    },
  },
  tabsIndicator: {
    backgroundColor: "#8e42ca",
    bottom: -2,
  },
  tabsScroller: {
    overflow: "visible !important",
  },
  tabContent: {
    width: "100%",
    margin: "10px 0px",
  },
  tab: {
    "& span": {
      textTransform: "uppercase",
      color: "#545A72",
      fontWeight: 500,
      fontSize: 15,
    },
  },
  selectedTab: {
    backgroundColor: "#F0F1F4",
    "& span": {
      color: "#7235A2",
    },
  },
});

export default withStyles(styles)(CustomTab);
