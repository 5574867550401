import SecureRequest from "../utils/securerequest";

export async function checkFeatureAccess(userId, featureName) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const url = `/v2/feature-access/check/${userId}?featureName=${encodeURIComponent(featureName)}`;

  try {
    const { data } = await Request.get(url);
    const { hasAccess } = data;
    return hasAccess;
  } catch (error) {
    console.error("Error in checkFeatureAccess:", error);
    throw error;
  }
}
