import { useMutation } from "react-query";

import { signinCognitoUserAsync } from "api/signin";
import { useGlobalToast } from "components/GlobalToastProvider";

const useSignInCognito = (options = {}) => {
  const { onError = () => {}, ...otherOptions } = options;
  const { showToast } = useGlobalToast();

  const { mutate, isSuccess, isIdle, isLoading, ...rest } = useMutation(
    (payload) => signinCognitoUserAsync(payload),
    {
      onError: () => {
        showToast({
          message: "Whoops! Something went wrong",
          errorState: true,
        });
        onError();
      },
      ...otherOptions,
    }
  );

  return {
    signinCognitoMutation: mutate,
    isSigninSuccess: isSuccess,
    isSigninIdle: isIdle,
    isSigninLoading: isLoading,
    ...rest,
  };
};

export default useSignInCognito;
