import { useMutation } from "react-query";

import { useGlobalToast } from "components/GlobalToastProvider";
import { updateTeamUser } from "api/team";
/** @typedef {mport("react-query").UseMutationOptions} UseMutationOptions */

/**
 * The useUpdateTeamUser function is a custom hook that handles updating team member data with error
 * handling and toast notifications.
 * @category Hooks
 * @param {UseMutationOptions} options It accepts an `options` object as a parameter, which can include custom configurations for the
 * mutation operation.
 * @return The `useUpdateTeamUser` function returns an object with the following properties:
 * - `updateTeamUserMutation`: A function that can be used to trigger the mutation to update a team
 * member.
 * - Other properties returned by the `useMutation` hook, such as `isLoading`, `isError`, `data`, etc.
 */
export const useUpdateTeamUser = (options = {}) => {
  const { onError = () => {}, ...otherOptions } = options;
  const { showToast } = useGlobalToast();

  const { mutate, ...rest } = useMutation((params) => updateTeamUser(params), {
    onError: (_, variables, context) => {
      showToast({
        message: "Whoops! Something went wrong",
        errorState: true,
        retryHandler: () => mutate(variables, context),
      });
      onError();
    },
    ...otherOptions,
  });

  return {
    updateTeamUserMutation: mutate,
    ...rest,
  };
};
