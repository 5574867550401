import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import { useGlobalToast } from "../../../../GlobalToastProvider";
import { useMutation } from "react-query";
import { disableUserInsuranceAsync } from "../../../../../api/insurance";
import LoadingButton from "@mui/lab/LoadingButton";

const DisableInsuranceButton = ({ insurance, onSuccess }) => {
  const [open, setOpen] = useState(false);

  const disabledInsuranceMutation = useMutation({
    mutationFn: disableUserInsuranceAsync,
  });

  const { showToast } = useGlobalToast();
  const handleRemoveProvider = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmAndClose = async () => {
    try {
      await disabledInsuranceMutation.mutateAsync({ id: insurance.id });
      showToast({ message: "Success!" });
      onSuccess?.();
    } catch (error) {
      showToast({
        message: "Whoops! Something went wrong",
        errorState: true,
      });
      console.error("An error occurred:", error);
    }
    setOpen(false);
  };

  return (
    <>
      <Button variant={"text"} color="error" onClick={handleRemoveProvider}>
        Remove Insurance
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          Are you sure you want to remove the insurance from the Client Account?
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            disabled={disabledInsuranceMutation.isLoading}
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={handleConfirmAndClose}
            color="primary"
            loading={disabledInsuranceMutation.isLoading}
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DisableInsuranceButton;
