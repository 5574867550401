import { LoadingButton } from "@mui/lab";
import { Button, Stack } from "@mui/material";
import { useTheme } from "@mui/styles";
import React from "react";

export default function EditActionButtons({ onCancel, onSave, loading, disabled, ...rest }) {
  const theme = useTheme();
  return (
    <Stack direction="row" maxWidth="1200px" width="100%" gap={theme.spacing(5)} {...rest}>
      <Button color="secondary" onClick={onCancel}>
        Cancel
      </Button>
      <LoadingButton type="submit" onClick={onSave} loading={loading} disabled={disabled}>
        Save Changes
      </LoadingButton>
    </Stack>
  );
}
