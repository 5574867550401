import React, { useState } from "react";
import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Divider from "@mui/material/Divider";
import { X, CaretLeft, CaretRight } from "@phosphor-icons/react";

import { domainScore, panelHeader, space3, space5, space7 } from "./styled";
import { StatItem } from "./StatItem";
import { HistoryItem, MaladaptiveItem } from "./RowItems";
import { getBehaviorScore, formatAssessmentDate } from "./utils";
/** @typedef {import("api/typesDef").AssessmentsClient} AssessmentClient */

/**
 * The function `PreviousPanel` renders a panel displaying the previous and the current
 * assessments, allowing users to navigate through previous assessments.
 * @component
 * @param {Object} props - The props component.
 * @param {() => void} props.onClosePanel - The function to close the panel
 * @param {AssessmentClient[]} props.dataAssessments - The assessment data for the client
 * @returns {JSX.Element} - The `PreviousPanel` component is being returned.
 */
export default function PreviousPanel({ onClosePanel, dataAssessments }) {
  const [currentAssessment, setCurrentAssessment] = useState(dataAssessments[0]);
  const {
    administration_date,
    adaptive_behavior_composite_standard_score,
    adaptive_behavior_composite_percentile,
    communication_score,
    communication_percentile,
    daily_living_score,
    daily_living_percentile,
    socialization_score,
    socialization_percentile,
    motor_score,
    motor_percentile,
    behavior_internalizing_v_scale,
    behavior_externalizing_v_scale,
    total_number_of_critical_items_scored,
  } = currentAssessment.data;

  const evaluationDate = formatAssessmentDate(administration_date);
  const createdDate = formatAssessmentDate(currentAssessment.created);
  const modifiedDate = formatAssessmentDate(currentAssessment.last_updated);

  return (
    <>
      <Stack component="header" direction="row" justifyContent="space-between">
        <Box>
          <Stack direction="row" alignItems="center">
            <Pagination
              count={dataAssessments.length}
              disabled={dataAssessments.length <= 1}
              variant="outlined"
              shape="rounded"
              sx={panelHeader}
              onChange={(event, page) => {
                setCurrentAssessment(dataAssessments[page - 1]);
              }}
              renderItem={(item) => (
                <PaginationItem slots={{ previous: CaretLeft, next: CaretRight }} {...item} />
              )}
            />

            <Typography component="h3" variant="subtitle1" marginLeft={space3}>
              {evaluationDate}
            </Typography>
          </Stack>
        </Box>

        <IconButton onClick={onClosePanel} size="small">
          <X weight="regular" size={20} />
        </IconButton>
      </Stack>

      <Stack marginTop={space7} rowGap={space3}>
        <StatItem
          label="Adaptive Behavior Composite Standard Score"
          value={adaptive_behavior_composite_standard_score}
          variant="title"
        />

        <StatItem
          label="Adaptive Behavior Composite Percentile"
          value={adaptive_behavior_composite_percentile}
          variant="title"
        />
      </Stack>

      <Grid container sx={domainScore}>
        <Grid item width="22%" minWidth={108}>
          <StatItem label="Communication Score" value={communication_score} variant="subtitle" />
        </Grid>

        <Grid item width="20%" minWidth={102}>
          <StatItem label="Daily Living Score" value={daily_living_score} variant="subtitle" />
        </Grid>

        <Grid item width="20%" minWidth={102}>
          <StatItem label="Socialization Score" value={socialization_score} variant="subtitle" />
        </Grid>

        <Grid item width="20%" minWidth={102}>
          <StatItem label="Motor Skills" value={motor_score} variant="subtitle" />
        </Grid>

        <Grid item width="22%" minWidth={108}>
          <StatItem
            label="Communication Percentile"
            value={communication_percentile}
            variant="subtitle"
          />
        </Grid>

        <Grid item width="20%" minWidth={102}>
          <StatItem
            label="Daily Living Percentile"
            value={daily_living_percentile}
            variant="subtitle"
          />
        </Grid>

        <Grid item width="20%" minWidth={102}>
          <StatItem
            label="Socialization Percentile"
            value={socialization_percentile}
            variant="subtitle"
          />
        </Grid>

        <Grid item width="20%" minWidth={102}>
          <StatItem label="Motor Percentile" value={motor_percentile} variant="subtitle" />
        </Grid>
      </Grid>

      <Grid container marginY={space7} rowGap={space3}>
        <Grid item xs={12}>
          <Typography component="h3" variant="subtitle2">
            Maladaptive
          </Typography>
        </Grid>

        <MaladaptiveItem
          title="Behavior Internalizing v-Scale"
          result={getBehaviorScore(behavior_internalizing_v_scale)}
          value={behavior_internalizing_v_scale ?? "-"}
        />

        <MaladaptiveItem
          title="Behavior Externalizing v-Scale"
          result={getBehaviorScore(behavior_externalizing_v_scale)}
          value={behavior_externalizing_v_scale ?? "-"}
        />

        <Grid item xs={6}>
          <Typography component="h3" variant="body2">
            Total Number of Critical Items Scored
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography component="p" variant="subtitle2" align="right">
            {total_number_of_critical_items_scored}
          </Typography>
        </Grid>
      </Grid>

      <Divider />

      <Grid container marginY={space7} rowGap={space5}>
        <HistoryItem type="uploadedBy" value={currentAssessment.created_by} />
        <HistoryItem type="uploadedDate" value={createdDate} />
        <HistoryItem type="modifiedBy" value={currentAssessment.last_updated_by} />
        <HistoryItem type="modifiedOn" value={modifiedDate} />
      </Grid>
    </>
  );
}
