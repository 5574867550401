import React, { Component } from "react";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import { format } from "date-fns";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styles from "./styles";
import actions from "../../actions";
import {
  getSessionNote,
  getSessionNoteLoading,
  getUserPermissionsList,
  getNotesSaving,
  getNotesSaved,
  isDirector,
  isSupervisor,
  isClinician,
  getUserId,
} from "../../selectors";
import Button from "@mui/material/Button";
import PageContainer from "elements/PageContainer";
import Logo from "../../assets/an_purple_logo.png";
import Grid from "@mui/material/Grid";
import Auth from "@aws-amplify/auth";
import { findDifferenceInMinutes } from "../../utils/findDifferenceInMinutes";
import { convertTokens } from "@date-fns/upgrade/v2";
const mapStateToProps = (state) => ({
  videoSessionNote: getSessionNote(state),
  videoSessionNoteLoading: getSessionNoteLoading(state),
  userPermissions: getUserPermissionsList(state),
  sessionNotesSaving: getNotesSaving(state),
  sessionNotesSaved: getNotesSaved(state),
  isDirector: isDirector(state),
  isSupervisor: isSupervisor(state),
  userId: getUserId(state),
  isClinician: isClinician(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setPageDetails: actions.setPageDetails,
      getSessionNote: actions.getSessionNote,
      saveSessionNotes: actions.saveSessionNote,
      sendVideoSignatures: actions.sendVideoSignatures,
    },
    dispatch,
  );
class VideoSessionNote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editSessionNoteOpen: false,
    };
    this.signatureRef = null;
  }
  componentDidMount() {
    this.props.setPageDetails({
      pageName: "Video Session Note",
    });
    if (this.props.match.params.id) {
      this.props.getSessionNote(this.props.match.params.id);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.editSessionNoteOpen && !this.state.editSessionNoteOpen) {
      this.props.getSessionNote(this.props.match.params.id);
    }
  }

  formatMin = (mins) => {
    const hours = Math.floor(mins / 60);
    const minutes = mins % 60;
    let hoursText = hours == 1 ? hours + " hour " : hours > 1 ? hours + " hours " : " ";
    let minutesText = minutes == 1 ? minutes + " minute" : minutes > 1 ? minutes + " minutes" : " ";

    return `${hoursText} ${minutesText}`;
  };

  openSessionNotesDialog = () => {
    this.setState({ editSessionNoteOpen: true });
  };

  closeSessionNotesDialog = () => {
    this.setState({ editSessionNoteOpen: false });
  };

  downloadSessionNote = async (billableTimeId) => {
    const time = new Date();
    const offset = time.getTimezoneOffset();
    const baseUrl = process.env.BASE_URL;
    const userSession = await Auth.currentSession();
    location.href = `${baseUrl}/download/${userSession.accessToken.jwtToken}/session-note/${billableTimeId}`;
  };

  render() {
    const {
      classes,
      videoSessionNote,
      videoSessionNoteLoading,
      userPermissions,
      userId,
      isSupervisor,
      isDirector,
      isClinician,
    } = this.props;
    return (
      <PageContainer loading={videoSessionNoteLoading}>
        <div className={classes.titleContainer}>
          <img className={classes.anLogo} src={Logo} alt="AnswersNow" title="AnswersNow" />
          <Typography className={classes.title}>Session Note</Typography>
        </div>
        <Grid container item xs={12} sm={12} className={classes.sessionNoteDetails}>
          <Grid item xs={12} sm={12}>
            <Typography component="h1" className={classes.subtitle}>
              Session Information
            </Typography>
          </Grid>
          <Grid container item xs={12} sm={6} spacing={3}>
            <Grid item xs={12} sm={4}>
              <Typography className={classes.leftColumn}>Client Name</Typography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Typography className={classes.rightColumnText}>
                {videoSessionNote.client_name}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography className={classes.leftColumn}>Service Type</Typography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Typography className={classes.rightColumnText}>
                {videoSessionNote.insurance_code}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography className={classes.leftColumn}>Duration</Typography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Typography className={classes.rightColumnText}>
                {this.formatMin(
                  findDifferenceInMinutes(videoSessionNote?.end_date, videoSessionNote?.start_date),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={6} spacing={3}>
            <Grid item xs={12} sm={3}>
              <Typography component="p" className={classes.leftColumn}>
                Start Time
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography component="p" className={classes.rightColumn}>
                {videoSessionNote?.start_date &&
                  format(new Date(videoSessionNote?.start_date), "p")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography component="p" className={classes.leftColumn}>
                End Time
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography component="p" className={classes.rightColumn}>
                {videoSessionNote?.end_date && format(new Date(videoSessionNote?.end_date), "p")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography component="p" className={classes.leftColumn}>
                Date of service
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography component="p" className={classes.rightColumn}>
                {videoSessionNote?.start_date &&
                  format(new Date(videoSessionNote?.start_date), convertTokens("MM/DD/YYYY"))}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography component="p" className={classes.leftColumn}>
                Place of Service
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography component="p" className={classes.rightColumn}>
                {videoSessionNote.location}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography component="p" className={classes.leftColumn}>
                Call Participants
              </Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Typography component="p" className={classes.rightColumn}>
                {videoSessionNote.participants}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={12} spacing={3} style={{ marginTop: 80 }}>
            <Grid item xs={12} sm={2}>
              <Typography component="p" className={classes.leftColumn}>
                Focus
              </Typography>
            </Grid>
            <Grid item xs={12} sm={10} style={{ marginBottom: 40 }}>
              <Typography
                component="p"
                className={classes.rightColumn}
                style={{ overflowWrap: "break-word" }}
              >
                {videoSessionNote.focus}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography component="p" className={classes.leftColumn}>
                Intervention
              </Typography>
            </Grid>
            <Grid item xs={12} sm={10}>
              <Typography
                component="p"
                className={classes.rightColumn}
                style={{ marginBottom: 40, overflowWrap: "break-word" }}
              >
                {videoSessionNote.note}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography component="p" className={classes.leftColumn}>
                Response
              </Typography>
            </Grid>
            <Grid item xs={12} sm={10} style={{ marginBottom: 40 }}>
              <Typography
                component="p"
                className={classes.rightColumn}
                style={{ overflowWrap: "break-word" }}
              >
                {videoSessionNote.response}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography component="p" className={classes.leftColumn}>
                Plan
              </Typography>
            </Grid>
            <Grid item xs={12} sm={10} style={{ marginBottom: 40 }}>
              <Typography
                component="p"
                className={classes.rightColumn}
                style={{ overflowWrap: "break-word" }}
              >
                {videoSessionNote.plan_modification
                  ? videoSessionNote.plan_modification
                  : "No change"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography
                component="p"
                className={classes.leftColumn}
                style={{ overflowWrap: "break-word" }}
              >
                Diagnosis
              </Typography>
            </Grid>
            <Grid item xs={12} sm={10} style={{ marginBottom: 40 }}>
              <Typography component="p" className={classes.rightColumn}>
                {videoSessionNote.diagnosis}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography component="p" className={classes.leftColumn}>
                Is this treatment medically necessary?
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} style={{ paddingLeft: 220 }}>
              <Typography component="p" className={classes.rightColumn}>
                {videoSessionNote.medically_necessary ? "Yes" : "No"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography component="p" className={classes.leftColumn}>
                Signature
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3} className={classes.signatureImage}>
              <img src={videoSessionNote.signature_url} />
            </Grid>
            <Grid item xs={12} sm={12} style={{ paddingLeft: 220 }}>
              <Typography component="p" className={classes.leftColumn}>
                {videoSessionNote?.clinician_name}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <div className={classes.sessionDetailButtonsContainer}>
          <Button
            variant="text"
            color="default"
            size="large"
            onClick={() => this.downloadSessionNote(videoSessionNote.billable_time_id)}
            disabled={!userPermissions?.download_session_notes}
          >
            Download
          </Button>
        </div>
      </PageContainer>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(VideoSessionNote));
