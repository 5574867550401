export default (theme) => ({
  videoBreakdownContainer: {
    width: "100%",
    height: "fit-content",
    margin: "auto",
    padding: "40px 80px",
    overflow: "auto",
  },
  videoBreakdownTitle: {
    fontSize: 30,
    fontWeight: 500,
  },
  videoBreakdownInfoContainer: {
    width: "100%",
    marginBottom: 40,
    marginTop: 40,
    "& p": {
      margin: "5px 0",
    },
  },
  videoBreakdownTime: {
    color: "#15171D",
    fontSize: 20,
  },
  videoBreakdownInfo: {
    fontSize: 16,
    color: "#3f4456",
  },
  videoBreakdownTimeContainer: {
    margin: "5px 0",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    "& p": {
      marginRight: 15,
    },
  },
  multipleServiceTypesContainer: {
    marginBottom: 40,
    width: "100%",
  },
  serviceTypeCountContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    width: "100%",
  },
  serviceTypeCountConfirm: {
    marginLeft: 30,
  },
  serviceTypeCount: {
    width: "fit-content",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  radioGroupLabel: {
    fontSize: 16,
    color: "#545a72",
    marginBottom: 10,
  },
  serviceTypesContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
  },
  serviceType: {
    // width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  serviceTypeDisplayText: {
    fontSize: 16,
    fontWeight: 400,
    color: "#15171D",
  },
  serviceTypeMinutesContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  serviceTypeMinutes: {
    fontSize: 15,
    color: "#545a72",
    marginBottom: 10,
  },
  serviceTypeBillableTimeContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  continueButtonContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  continueButton: {
    color: "#2a2d39",
  },
  billingMessagingText: {
    color: "#2a2d39",
  },
  serviceTypeCountMessage: {
    width: 250,
    "& p": {
      fontSize: 15,
    },
  },
  serviceTypeMinutesMessage: {
    width: 470,
    "& p": {
      fontSize: 15,
    },
  },
  titleContainer: {
    padding: "8px 24px",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 50,
  },
  title: {
    color: "#31333A",
    fontSize: 20,
    fontWeight: 500,
  },
  closeIcon: {
    position: "absolute",
    top: 15,
    right: 24,
  },
  noteCount: {
    color: "#3F4456",
    fontSize: 16,
    fontWeight: 500,
  },
  buttonsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  page: {
    color: "##2A2D39",
    fontWeight: 500,
    fontSize: 18,
    marginRight: 100,
  },
  viewServiceDetails: {
    width: 300,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "16px",
  },
  viewService: {
    display: "flex",
    alignItems: "flex-end",
    gap: "4px",
    alignSelf: "stretch",
  },
  editServiceDetails: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "16px",
  },
  editService: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "8px",
    alignSelf: "stretch",
    width: 435,
  },
  iconCheckContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 36,
    height: 36,
  },
  timeRemaining: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "4px",
    alignSelf: "stretch",
    maxWidth: 395,
  },
  sessionNoteDetails: {
    width: "100%",
    backgroundColor: "#F6F7F8",
    border: "1px solid #cdd3da",
    borderRadius: 6,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "24px",
  },
  sessionDetailsTitle: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingBottom: 16,
    borderBottom: "1px solid #CDD3DA",
  },
  avatar: {
    backgroundColor: "#f5f5f5",
    border: "1px solid #3F4456",
    color: "#3F4456",
  },
  billableTimeContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "48px",
    alignSelf: "stretch",
  },
  noteDetailsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "24px",
    alignSelf: "stretch",
  },
  cptHeaderContainer: {
    display: "flex",
    alignItems: "flex-start",
    gap: "16px",
    alignSelf: "stretch",
  },
  cptHeaderTitleContainer: {
    display: "flex",
    paddingTop: 4,
    alignItems: "flex-start",
    gap: "8px",
    flex: "1 0 0",
  },
  cptHeader: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: 0,
  },
  cptChip: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "8px",
    alignSelf: "stretch",
  },
  noteDetail: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "16px",
    alignSelf: "stretch",
  },
  noteDetailTitleContainer: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "stretch",
    position: "relative",
  },
  noteDetailTitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  signatureContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "24px",
    alignSelf: "stretch",
  },
  signatureTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    alignSelf: "stretch",
  },
  signatureCanvasContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "8px",
    flex: "1 0 0",
    alignSelf: "stretch",
  },
  disabledSignatureCanvasContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "8px",
    flex: "1 0 0",
    alignSelf: "stretch",
    opacity: 0.25,
  },
  formTitleContainer: {
    position: "relative",
    display: "flex",
    alignItems: "flex-start",
    marginBottom: 30,
  },
  viewOnlyFormTitleContainer: {
    position: "relative",
    display: "flex",
    alignItems: "flex-start",
    marginTop: 20,
    marginBottom: 15,
  },
  successIcon: {
    position: "absolute",
    left: 0,
    zIndex: 3000,
    color: "green",
  },
  errorIcon: {
    position: "absolute",
    left: 0,
    zIndex: 3000,
    color: "red",
  },
  formTitle: {
    fontSize: 16,
    color: " #15171D",
    fontWeight: 400,
  },
  formSubitleContainer: {
    position: "relative",
    display: "flex",
    alignItems: "flex-start",
    marginBottom: 20,
  },
  formSubitle: {
    fontSize: 16,
    color: " #15171D",
    fontWeight: 500,
  },
  dialogActionsContainer: {
    backgroundColor: "#F0F1F4",
    padding: "12px 16px",
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
  },
  leftColumn: {
    fontWeight: 500,
    color: "#3F4456",
  },
  rightColumn: {
    fontWeight: 500,
    color: "#2A2D39",
  },
  rightColumnText: {
    fontWeight: 500,
    color: "#2A2D39",
    fontSize: 18,
  },
  textRightColumnText: {
    fontWeight: 500,
    color: "#2A2D39",
    fontSize: 18,
    marginBottom: 20,
  },
  formLabel: {
    color: "#69718F !important",
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "20px",
    display: "flex",
    alignItems: "center",
  },
  formText: {
    color: "#545A72",
    fontWeight: 500,
  },
  participantText: {
    fontSize: 18,
    fontWeight: 400,
    lineHeight: "21px",
  },
  signatureCanvas: {
    width: "100%",
    height: 130,
    backgroundColor: "#fff",
    borderRadius: 6,
    border: "1px solid #CDD3DA",
    overflow: "hidden",
    margin: "5px 0",
    position: "relative",
  },
  signatureImageContainer: {
    width: "100%",
    height: 130,
    borderRadius: 6,
    border: "1px solid #CDD3DA",
    overflow: "hidden",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  signatureImage: {
    height: "auto",
    width: "auto",
    marginLeft: 16,
  },
  footerButtonsContainer: {
    width: "100%",
    margin: "0px auto",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  error: {
    marginRight: 20,
    color: "red",
  },
  success: {
    marginRight: 20,
    color: "green",
  },
  participants: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "16px",
  },
  participantChips: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    gap: "8px",
  },
  chip: {
    height: "32px",
    borderRadius: 4,
    border: "1px solid #A5AABC",
    backgroundColor: "#F0F1F4",
    marginRight: 8,
  },
  chipLabel: {
    color: "#15171D",
    fontWeight: 500,
    fontSize: 13,
  },
  adhocSessionDetails: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "24px",
    alignSelf: "stretch",
  },
  adhocDetailRow: {
    display: "flex",
    alignItems: "flex-start",
    gap: "16px",
    alignSelf: "stretch",
  },
  callDetails: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "48px",
    alignSelf: "stretch",
  },
  viewCallDetails: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "24px",
    alignSelf: "stretch",
  },
  callDetailRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    alignContent: "flex-start",
    gap: "16px 16px",
    alignSelf: "stretch",
    flexWrap: "wrap",
    marginTop: 8,
  },
  callDetail: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "8px",
  },
  callDetailLabel: {
    fontSize: 12,
    fontWeight: 500,
    color: "#5B6172",
    minWidth: 120,
    marginRight: 20,
    marginBottom: 3,
  },
  callDetailData: {
    fontSize: 17,
    fontWeight: 400,
    color: "#15171D",
    marginRight: 20,
  },
  helperText: {
    color: "#D32F2F",
    fontSize: 14,
    fontWeight: 400,
  },
  infoTextContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    "& svg": {
      fill: "#99771A",
      fontSize: 18,
      marginRight: 3,
    },
  },
  infoText: {
    color: "#99771A",
    fontSize: 12,
    fontWeight: 500,
  },
  tipIcon: {
    marginLeft: 10,
  },
  tooltip: {
    backgroundColor: "#fff",
    padding: 24,
    fontSize: 14,
    lineHeight: "20px",
    color: "#5b6172",
    fontWeight: 500,
    borderRadius: 8,
    maxWidth: 500,
    boxShadow: "0px 4px 6px -1px rgba(55, 55, 55, 0.15)",
    whiteSpace: "pre-line",
  },
  verifyText: {
    fontSize: 16,
    fontWeight: 400,
    color: "#15171D",
  },
  signatureVerifyLabel: {
    alignItems: "flex-start",
  },
  signatureCheckbox: {
    padding: "0 5px 9px 9px",
  },
  snackbarContainer: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(122, 125, 135, 0.4)",
    borderRadius: 4,
    zIndex: 3004,
    "& div": {
      border: "none",
      borderRadius: 4,
    },
  },
  loadingContainer: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(122, 125, 135, 0.4)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 3030,
  },
  snackbar: {
    backgroundColor: "#121212",
    borderRadius: 4,
    color: "#fff",
  },
  dialogButtonContainer: {
    marginTop: 10,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  closeDialogContentContainer: {
    height: 60,
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    marginBottom: 15,
    marginTop: 10,
  },
  closeDialogIconContainer: {
    height: 50,
    width: 50,
    borderRadius: 24,
    backgroundColor: "#F4ECFA",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 16,
  },
  closeDialogTitleContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  closeDialogTitle: {
    fontSize: 20,
    fontWeight: 400,
    color: "#15171D",
  },
  closeDialogContent: {
    fontSize: 16,
    fontWeight: 500,
    color: "#69718F",
  },
  confirmSubmitDialogContentContainer: {
    padding: "6px 16px",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    borderRadius: 4,
    width: "100%",
    backgroundColor: "#FFF4E5",
  },
  confirmSubmitDialogIconContainer: {
    height: 50,
    width: 40,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    // marginRight: 16,
  },
  confirmSubmitDialogTitleContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "4px",
    padding: "8px 0",
  },
  confirmSubmitDialogTitle: {
    color: "#663C00",
  },
  dialogTitle: {
    display: "flex",
    padding: "8px 24px",
    justifyContent: "space-between",
    alignItems: "center",
    alignSelf: "stretch",
  },
  confirmSubmitDialogContent: {
    color: "#663C00",
  },
  checkboxLabel: {
    fontSize: 16,
    fontWeight: 400,
    color: "#15171D",
  },
  checkbox: {
    padding: 5,
  },
  checkedCheckbox: {
    color: "#fff",
  },
  serviceDurationInputRoot: {
    height: 50,
    "& .MuiOutlinedInput-root": {
      height: 50,
    },
  },
  editServiceTypesButton: {
    position: "absolute",
    top: "-10px",
    right: 0,
    minWidth: 70,
  },
  requestedEditsContainer: {
    width: "100%",
    padding: "6px 16px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-start",
    backgroundColor: "#FFF4E5",
    borderRadius: 4,
  },
  requestEditsIcon: {
    display: "flex",
    padding: "7px 12px 7px 0",
    alignItems: "flex-start",
  },
  requestEditsContent: {
    display: "flex",
    padding: "8px 0",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "4px",
    flex: "1 0 0",
  },
  selectedParticipantItem: {
    backgroundColor: "transparent !important",
  },
  sessionNoteTitleButtons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  techIssuesContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "16px",
    alignSelf: "stretch",
  },
  techIssueReasons: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
});
