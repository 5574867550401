import React from "react";
import { Grid, Typography } from "@mui/material";
import { Calendar, IdentificationBadge } from "@phosphor-icons/react";

import { space3, historyIcon } from "./styled";

/**
 * The MaladaptiveItem function renders a component with a title, result, and value displayed in a grid
 * layout.
 * @component `MaladaptiveItem`
 * @param {Object} props - The props of the component
 * @param {String} props.title - The title of the maladaptive item
 * @param {String} props.result - The result of the maladaptive item
 * @param {String|Number} props.value - The value of the maladaptive item
 * @return {JSX.Element} The `MaladaptiveItem` component is being returned
 */
export function MaladaptiveItem({ title, result, value }) {
  return (
    <>
      <Grid item xs={6}>
        <Typography component="p" variant="body2">
          {title}
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <Typography component="h3" variant="body2" align="right">
          {result}
          <Typography component="strong" variant="subtitle2" sx={{ marginLeft: space3 }}>
            {value}
          </Typography>
        </Typography>
      </Grid>
    </>
  );
}

/**
 * The HistoryItem component displays information about the type and value of a historical event.
 * @component `HistoryItem`
 * @param {Object} props - The props of the component.
 * @param {String} props.type - The type of the historical event.
 * @param {String} props.value - The value of the historical event.
 * @return {JSX.Element} The `HistoryItem` component is being returned.
 */
export function HistoryItem({ type, value }) {
  const typeLabel = {
    uploadedBy: "Uploaded by",
    uploadedDate: "Uploaded date",
    modifiedBy: "Modified by",
    modifiedOn: "Modified On",
  };

  return (
    <>
      <Grid item xs={6}>
        <Typography component="p" variant="body2">
          <Typography component="span" variant="body2" sx={historyIcon}>
            {type === "uploadedBy" || type === "modifiedBy" ? (
              <IdentificationBadge weight="duotone" size="18px" />
            ) : (
              <Calendar weight="duotone" size="18px" />
            )}
          </Typography>

          {typeLabel[type]}
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <Typography component="h3" variant="subtitle2" align="right">
          {value}
        </Typography>
      </Grid>
    </>
  );
}
