import { FormHelperText, TextField, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { STATES } from "../../../../constants";
import React from "react";
import Checkbox from "@mui/material/Checkbox";

export const VerificationOfBenefitsFormMedicaid = (
  data,
  insurances,
  errors,
  isMedicaidForm,
  onChange
) => {
  return [
    {
      id: "insurance_plan",
      input: (
        <TextField
          label="Insurance Plan"
          onChange={onChange("insurance_plan_id", null, data)}
          value={insurances?.length === 1 ? insurances[0].id : data.insurance_plan_id ?? ""}
          select
          fullWidth
          disabled={insurances?.length === 1}
        >
          {insurances?.map(({ name, id, isMedicaid }, index) => (
            <MenuItem key={`${index}`} name={name} value={id} data-medic={isMedicaid}>
              {name}
            </MenuItem>
          ))}
        </TextField>
      ),
      cols: 12,
    },
    {
      id: "is_active_in_medicaid_portal",
      input: (
        <FormControlLabel
          control={
            <Checkbox
              checked={data.is_active_in_medicaid_portal}
              onChange={onChange("is_active_in_medicaid_portal", null, data)}
              inputProps={{
                "aria-label": "primary checkbox",
              }}
            />
          }
          label={<Typography component="p">Is active in Medicaid portal</Typography>}
        />
      ),
      cols: 12,
    },
    {
      id: "notes",
      input: (
        <TextField
          id="notes"
          label="Notes(optional)"
          onChange={onChange("notes", null, data)}
          value={data.notes ?? ""}
          fullWidth
          multiline={true}
          rows={3}
        />
      ),
    },
  ];
};
