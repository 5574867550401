import React from "react";
import withStyles from "@mui/styles/withStyles";
import TextField from "@mui/material/TextField";

const StyledInput = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#9c00d5",
    },
    "& fieldset": {
      border: "1px solid #A5AABC",
      borderRadius: 4,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset legend": {
        color: "#69718F",
      },
      "&:hover:not(.Mui-disabled) fieldset": {
        border: "2px solid #9c00d5",
      },
      "&.Mui-focused fieldset": {
        border: "2px solid #9c00d5",
      },
      "& .MuiNativeSelect-select": {
        padding: "8.5px 14px",
      },
    },
    "& .MuiFormHelperText-root": {
      margin: "0 5px !important",
      fontSize: "14px !important",
      fontWeight: "500 !important",
      color: "#B91C1C !important",
    },
  },
})(TextField);

export const CustomInput = React.forwardRef((props, ref) => {
  return (
    <StyledInput {...props} variant={props.variant || "outlined"} size={props.size || "small"} />
  );
});

export default CustomInput;
