import { updateTeamMembers } from "api/team";
import { useGlobalToast } from "components/GlobalToastProvider";
import { useMutation } from "react-query";

export const useUpdateTeamMembers = (options = {}) => {
  const { onError = () => {}, ...otherOptions } = options;
  const { showToast } = useGlobalToast();

  const { mutate, ...rest } = useMutation(
    ({ teamId, teamMembers }) => updateTeamMembers(teamId, { teamMembers }),
    {
      onError: (_, variables, context) => {
        showToast({
          message: "Whoops! Something went wrong",
          errorState: true,
          retryHandler: () => mutate(variables, context),
        });
        onError();
      },
      ...otherOptions,
    }
  );

  return {
    updateTeamMembresMutation: mutate,
    ...rest,
  };
};
