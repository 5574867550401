import React from "react";
import DetailAccordion from "elements/DetailAccordion";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import ClientSMSDialog from "../../ClientSMSDialog/index.js";

const SMSMessageFacility = (props) => {
  const { classes, onOpenSMSFacilityDialog, eCommConsentFormSigned, userPermissions } = props;

  const canMessageAnytime = userPermissions?.can_send_sms_without_consent;

  return (
    <>
      <DetailAccordion title="SMS Text Messages">
        {eCommConsentFormSigned || canMessageAnytime ? (
          <div className={classes.smsTextMessages}>
            <Typography component="p">
              Send a text message from AnswersNow to the number on file?
            </Typography>

            <Button variant="text" onClick={onOpenSMSFacilityDialog}>
              Open SMS
            </Button>
          </div>
        ) : (
          <div className={classes.smsTextMessages}>
            <Typography component="p">
              CONSENT TO RECEIVE ELECTRONIC COMMUNICATIONS REQUIRED: Before sending an SMS message,
              the primary account holder must update consent to receive Electronic Communications
              from their "Account" page.
            </Typography>
          </div>
        )}
      </DetailAccordion>
      <ClientSMSDialog {...props} canMessageAnytime={canMessageAnytime} />
    </>
  );
};

const styles = (theme) => ({
  smsTextMessages: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    "& p": {
      color: "#2A2D39",
      fontSize: 17,
      fontWeight: 500,
    },
  },
});

export default withStyles(styles)(SMSMessageFacility);
