export const getAge = (dateOfBirth) => {
  const born = new Date(dateOfBirth);
  const now = new Date();
  const birthday = new Date(now.getFullYear(), born.getMonth(), born.getDate());
  if (now >= birthday) {
    return now.getFullYear() - born.getFullYear();
  } else {
    return now.getFullYear() - born.getFullYear() - 1;
  }
};
