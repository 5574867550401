import { Alert, AlertTitle, Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import React from "react";

export default function MarkAsNoShowDialog({ open, handleClose, onMarkClientAsNoShow }) {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs">
      <DialogContent dividers>
        <Alert severity="warning">
          <AlertTitle>Mark the client as a no-show?</AlertTitle>
          Mark the client as a no-show when they fail to attend without proper warning
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button color="error" onClick={onMarkClientAsNoShow}>
          Mark as no-show
        </Button>
      </DialogActions>
    </Dialog>
  );
}
