import React from "react";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import { Dialog, DialogContent, Stack, Typography } from "@mui/material";

import LogoSprite from "../../assets/an_logo_sprites.png";

const butterflyAnimation = keyframes`
  from, 0% {
    background-position: 0 0;
  }
  100% {
    background-position: -90px 0;
  }
`;

const SpriteLogo = styled.figure`
  height: 41px;
  width: 30px;
  position: relative;
  margin: 0 auto 12px;
  background-image: url(${LogoSprite});
  background-size: 90px 41px;
  animation: ${butterflyAnimation} 0.9s steps(3) infinite;
`;

export default function LoadingChangesDialog({ title, description, open, onClose }) {
  return (
    <Dialog maxWidth="xs" open={open} onClose={onClose}>
      <DialogContent>
        <Stack spacing={0}>
          <SpriteLogo />

          <Typography variant="body1" align="center" style={{ paddingBottom: 4 }}>
            {title}
          </Typography>

          <Typography variant="body2" align="center">
            {description}
          </Typography>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
