import cookie from "react-cookies";
import {
  CaretRight,
  ChatCenteredDots,
  Fingerprint,
  Password,
  Pen,
  Question,
  SignOut,
  User,
  UserSwitch,
} from "@phosphor-icons/react";
import { useGetClients } from "../../hooks/api/useGetClients";
import { addDays } from "date-fns";
import { showChatPanel } from "components/IntercomAi";
import { useHistory } from "react-router-dom";

export default function useMenuItems(userPermissions, handleMenuClicked, logout) {
  const userId = cookie.load("userId");
  const history = useHistory();

  const { clients, clientsLoading } = useGetClients({ userIds: userId });

  if (!userPermissions) {
    return [];
  }
  let menuItems = [];
  if (userPermissions["view_client_dashboard"]) {
    menuItems.push({
      value: "client_account",
      label: "Account",
      icon: User,
      clickHandler: () => {
        handleMenuClicked();
        history.push("/account");
      },
    });
  }
  if (userPermissions["view_clinician_dashboard"]) {
    menuItems.push({
      value: "clinician_profile",
      label: "Profile",
      icon: User,
      clickHandler: () => {
        handleMenuClicked();
        history.push("/my-info/profile");
      },
    });
  }
  if (!clientsLoading && clients && clients.length > 1) {
    menuItems.push({
      value: "switch_account",
      label: "Switch Accounts",
      icon: UserSwitch,
      endIcon: CaretRight,
      clickHandler: () => {},
      subMenuItems: (clients || []).map((account) => ({
        label: account.name,
        avatar: true,
        avatarUrl: account.avatarUrl,
        avatarInitials: `${account.name?.[0]?.toUpperCase() || ""}${account.child_last_name?.[0]?.toUpperCase() || ""}`,
        checked: cookie.load(`${userId}-clientId`) === account.id,
        clickHandler: () => {
          cookie.save(`${userId}-clientId`, account.id, {
            path: "/",
            expires: addDays(new Date(), 30),
          });
          window.location.reload();
        },
      })),
    });
  }
  if (!userPermissions["view_client_dashboard"]) {
    menuItems.push({
      value: "chatSupport",
      label: "Chat with Support",
      icon: ChatCenteredDots,
      clickHandler: () => {
        handleMenuClicked();
        showChatPanel();
      },
    });
  }
  if (!userPermissions["view_client_dashboard"]) {
    menuItems.push({
      value: "helpCenter",
      label: "Help Center",
      icon: Question,
      clickHandler: () => {
        handleMenuClicked();
        window.open("https://intercom.help/ansupport/en/", "_blank", "noopener=1");
      },
    });
  }
  menuItems.push({
    value: "changePassword",
    label: "Reset Password",
    icon: Password,
    clickHandler: () => {
      handleMenuClicked();
      history.push("/change-password");
    },
  });
  menuItems.push({
    value: "privacy",
    label: "Privacy Policy",
    icon: Fingerprint,
    clickHandler: () => {
      handleMenuClicked();
      window.open("https://www.getanswersnow.com/privacy", "_blank");
    },
  });
  menuItems.push({
    value: "terms",
    label: "Terms of Use",
    icon: Pen,
    divider: true,
    clickHandler: () => {
      handleMenuClicked();
      window.open("https://www.getanswersnow.com/terms", "_blank");
    },
  });
  menuItems.push({
    value: "logout",
    label: "Sign Out",
    icon: SignOut,
    clickHandler: logout,
  });
  menuItems.push({
    value: "version",
    label: `AnswersNow © ${new Date().getFullYear()} v${APP_VERSION}`,
    disabled: true,
    typography: true,
  });
  return menuItems;
}
