import React from "react";
import UpdateClientStatus from "../Widgets/UpdateClientStatus";

const Actions = (props) => {
  const { updatedUserStatus, onChangeUserStatus, userPermissions, editMode, isActive, classes } =
    props;

  return (
    <div className={classes.accordionContainer}>
      {userPermissions?.override_client_workflow_state && (
        <UpdateClientStatus
          {...props}
          isActive={isActive}
          selectedStatus={updatedUserStatus}
          setSelectedStatus={onChangeUserStatus}
          editMode={editMode}
        />
      )}
    </div>
  );
};
export default Actions;
