import SecureRequest from "../utils/securerequest";

export function* getVersion() {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/v2/version`);
}

export function* logError(errorObject) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.post(`/v2/errors`, errorObject);
}
