import React from "react";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";

const Heading = (props) => {
  const { classes, children, ...otherProps } = props;
  return (
    <Typography component="h2" className={classes.heading} {...otherProps}>
      {children}
    </Typography>
  );
};

const styles = (theme) => ({
  heading: {
    fontSize: 34,
    lineHeight: "40px",
    letterSpacing: "-0.04em",
    width: "100%",
    textAlign: "center",
  },
});

export default withStyles(styles)(Heading);
