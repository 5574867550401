import React from "react";
import Chip from "@mui/material/Chip";

const StatusPill = (props) => {
  const {
    note_completed,
    in_progress,
    on_hold,
    needs_edits,
    approved,
    needs_approval,
    userPermissions,
    enter_health_id,
  } = props;
  if (userPermissions?.view_all_clients_billing_items) {
    if (needs_edits) {
      return <Chip label="Needs Edits" variant="outlined" size="small" color="error" />;
    } else if (on_hold) {
      return <Chip label="On Hold" variant="outlined" size="small" color="info" />;
    } else if (in_progress) {
      return <Chip label="In Progress" variant="outlined" size="small" color="warning" />;
    } else if (!in_progress && !note_completed) {
      return <Chip label="Not Started" variant="outlined" size="small" />;
    } else if (note_completed && !approved) {
      return <Chip label="Needs Review" variant="outlined" size="small" color="warning" />;
    } else if (enter_health_id) {
      return <Chip label="Sent to Enter" variant="outlined" size="small" color="primary" />;
    } else if (needs_approval && approved) {
      return <Chip label="Approved" variant="outlined" size="small" color="success" />;
    } else {
      return <Chip label="Submitted" variant="outlined" size="small" color="success" />;
    }
  } else {
    if (needs_edits) {
      return <Chip label="Needs Edits" variant="outlined" size="small" color="error" />;
    } else if (in_progress) {
      return <Chip label="In Progress" variant="outlined" size="small" color="warning" />;
    } else if (!in_progress && !note_completed) {
      return <Chip label="Not Started" variant="outlined" size="small" />;
    } else {
      return <Chip label="Submitted" variant="outlined" size="small" color="success" />;
    }
  }
};

export default StatusPill;
