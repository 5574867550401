import { Button, IconButton, ListItemIcon, Menu, MenuItem, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { DotsThree, DownloadSimple, PencilSimpleLine, Plus, Trash } from "@phosphor-icons/react";
import { format, isPast } from "date-fns";
import ANItemBox from "elements/Forms/ANItemBox";
import React from "react";

/**
 * Renders a list of sections with options for editing, deleting, and downloading.
 * @param {Object} props - The component props.
 * @param {Array} props.list - The list of elements. The object should have an id, title, and expirationDate.
 * @param {boolean} props.editMode - Indicates whether the component is in edit mode.
 * @param {Function} props.addHandler - The handler function for adding a license.
 * @param {Function} props.deleteHandler - The handler function for deleting a license.
 * @param {Function} props.downloadHandler - The handler function for downloading a license.
 * @param {Function} props.editHandler - The handler function for editing a license.
 * @param {Function} props.onSelectElement - Used to pass the selected id to the parent component.
 * @param {string} [props.noDataMessage="No Data on the account"] - The message to display when there is no data.
 * @param {string} props.menuActionText - The text for the menu buttons to place next to the action. For example, "License", will render "Edit License".
 * @param {string} [props.addButtonText] - The text for the add button. Defaults to "Add {menuActionText}".
 * @returns {JSX.Element} The rendered component.
 */
export default function SectionList({
  list = [],
  editMode,
  addHandler = () => {},
  deleteHandler = () => {},
  downloadHandler = () => {},
  editHandler = () => {},
  onSelectElement = () => {},
  noDataMessage = "No Data on the account",
  menuActionText,
  addButtonText,
}) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpenMenu = (event) => setAnchorEl(event.currentTarget);
  const handleCloseMenu = () => setAnchorEl(null);

  return (
    <Stack gap={theme.spacing(7)} width="inherit">
      {list.length === 0 && !editMode && (
        <Typography variant="body2" color="text.secondary">
          {noDataMessage}
        </Typography>
      )}
      <Menu
        id="options-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        MenuListProps={{ "aria-labelledby": "open-menu-button" }}
      >
        <MenuItem
          onClick={() => {
            editHandler();
            handleCloseMenu();
          }}
        >
          <ListItemIcon>
            <PencilSimpleLine weight="duotone" />
          </ListItemIcon>
          Edit {menuActionText}
        </MenuItem>
        <MenuItem
          onClick={() => {
            downloadHandler();
            handleCloseMenu();
          }}
        >
          <ListItemIcon>
            <DownloadSimple weight="duotone" />
          </ListItemIcon>
          Download {menuActionText}
        </MenuItem>
        <MenuItem
          onClick={() => {
            deleteHandler();
            handleCloseMenu();
          }}
        >
          <ListItemIcon>
            <Trash weight="duotone" />
          </ListItemIcon>
          Delete {menuActionText}
        </MenuItem>
      </Menu>

      <Stack gap={theme.spacing(3)}>
        {list.map((item) => {
          const isExpired = isPast(new Date(item.expirationDate));
          const color = isExpired && item.expirationDate ? "error" : "text.secondary";
          const subtitle = `${isExpired ? "Expired" : "Expires"} ${format(new Date(item.expirationDate), "MMMM do, yyyy")}`;
          return (
            <ANItemBox
              key={item.id}
              title={item.title}
              subtitle={item.expirationDate ? subtitle : "Missing expiration date"}
              subtitleProps={{ color }}
              rightSlot={
                editMode && (
                  <IconButton
                    fontSize="small"
                    data-id={item.id}
                    onClick={(event) => {
                      onSelectElement(item.id);
                      handleOpenMenu(event);
                    }}
                  >
                    <DotsThree />
                  </IconButton>
                )
              }
            />
          );
        })}
      </Stack>
      {editMode && (
        <Button
          startIcon={<Plus />}
          color="secondary"
          sx={{ width: "fit-content" }}
          onClick={addHandler}
        >
          {addButtonText ? addButtonText : `Add ${menuActionText}`}
        </Button>
      )}
    </Stack>
  );
}
