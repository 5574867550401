import React, { Component } from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import VideocamIcon from "@mui/icons-material/VideocamOutlined";
import Avatar from "@mui/material/Avatar";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import Button from "@mui/material/Button";
import withStyles from "@mui/styles/withStyles";
import styles from "./styles";
import ChatInput from "./ChatInput";
import { format } from "date-fns";
import * as linkify from "linkifyjs";
import linkifyHtml from "linkifyjs/html";
import Linkify from "linkifyjs/react";
import ReactPlayer from "react-player";
import { sendMessage } from "../../api/sendbird";
import { filter } from "lodash";

import { convertTokens } from "@date-fns/upgrade/v2";

const BUFFER = 200;

class ChatMessages extends Component {
  state = {
    scrollDirection: "down",
    scrollTop: 0,
    initialScroll: true,
    message: "",
    messageText: "",
    mentions: [],
  };

  componentDidMount() {
    this.messageArea.addEventListener("scroll", this.onScroll);
  }

  componentWillUnmount() {
    this.messageArea.removeEventListener("scroll", this.onScroll);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.messages && this.props.messages) {
      if (prevProps.messages.length !== this.props.messages.length) {
        window.setTimeout(() => {
          this.messagesEnd?.scrollIntoView({ behavior: "smooth" });
        }, 800);
      }
    }
  }

  onScroll = () => {
    const direction = this.messageArea.scrollTop < this.state.scrollTop ? "up" : "down";
    this.setState({
      scrollTop: this.messageArea.scrollTop,
      scrollDirection: direction,
    });

    if (this.messageArea.scrollTop <= BUFFER && direction === "up") {
      this.props.onScrollUp();
    }
  };

  isBCBA = (sender) => {
    if (!sender) {
      return false;
    }

    if (sender.metaData && sender.metaData.isBCBA) {
      return true;
    }

    return false;
  };

  isTeam = (sender) => {
    if (!sender) {
      return false;
    }
    if (sender.metaData && sender.metaData.isTeam) {
      return true;
    }

    return false;
  };

  changeMessage = (value, messageText, mentions) => {
    if (value) {
      this.props.currentChannel.startTyping();
    }

    if (!value) {
      this.props.currentChannel.endTyping();
    }

    this.setState({ message: value, messageText, mentions });
  };

  sendMessage = async () => {
    let { connection, currentChannel, messages, userId, onMessageSend } = this.props;
    let { message, messageText, mentions } = this.state;
    const canSendMessage = this.props.canSendMessage();

    if (canSendMessage) {
      const m = await sendMessage(messageText, mentions, currentChannel, connection);
      messages = messages.concat(m);
    }

    // now call the callback
    const members = filter(currentChannel.members, (m) => {
      return m.userId !== userId;
    });
    if (members.length === 1) {
      const online = members[0].connectionStatus === "online";
      onMessageSend(messageText, currentChannel, userId, online);
    } else {
      onMessageSend(messageText, currentChannel, userId, false);
    }
    this.setState({ message: "", mentions: [], messageText: "" }, () => {
      currentChannel.endTyping();
    });
    this.props.setNewMessageList(messages);
  };

  render() {
    const {
      classes,
      theme,
      message,
      messages,
      typingMessage,
      allMessagesDisplay,
      currentChannel,
      onLinkClick,
      connection,
      miniDisplay,
      onAvatarClick,
      onClientStartVideo,
    } = this.props;
    const currentUserId =
      connection && connection.currentUser ? connection.currentUser.userId : null;
    return (
      <main className={classes.content} style={miniDisplay ? { marginTop: -22 } : null}>
        {!miniDisplay && <div className={classes.toolbar} />}
        <div
          id="messageArea"
          className={classes.messageArea}
          ref={(el) => {
            this.messageArea = el;
          }}
        >
          {currentChannel && currentChannel.isPublic && allMessagesDisplay && (
            <Typography align="center" component="div" className={classes.topNotice}>
              You've reached the beginning of the chat history
            </Typography>
          )}
          <List id="messageList" className={classes.messageList}>
            {messages &&
              messages.length > 0 &&
              messages.map((message) => {
                const messageData = message.data ? JSON.parse(message.data) : null;
                const messageBody = message.message
                  ? message.message.replace(/\[\[(.+?)\]\]/g, "<span>@$1</span>")
                  : "";
                let body = <React.Fragment />;
                if (message.messageType === "file") {
                  if (message.type.indexOf("image/") >= 0) {
                    body = (
                      <React.Fragment>
                        <img
                          src={message.url}
                          className={classes.messageImage}
                          title={message.name}
                          alt={message.name}
                        />
                      </React.Fragment>
                    );
                  } else if (message.type.indexOf("application/") >= 0) {
                    body = (
                      <a href={message.url} target="_blank" className={classes.messageDocument}>
                        <InsertDriveFileIcon className={classes.messageIcon} />
                        <Typography className={classes.messageFileName} component="div">
                          {message.name}
                        </Typography>
                      </a>
                    );
                  } else if (message.type.indexOf("video/") >= 0) {
                    body = (
                      <Typography component="div" className={classes.messageVideo}>
                        <ReactPlayer url={message.url} controls={true} width={"100%"} />
                      </Typography>
                    );
                  } else {
                    body = <React.Fragment>Message attachment not supported</React.Fragment>;
                  }
                } else {
                  body = (
                    <div
                      className={
                        message._sender && message._sender.userId === currentUserId
                          ? classes.currentUserMessage
                          : classes.message
                      }
                      style={miniDisplay ? { fontSize: ".85rem", lineHeight: 1.2 } : null}
                      onClick={onLinkClick}
                      dangerouslySetInnerHTML={{
                        __html: linkifyHtml(messageBody),
                      }}
                    />
                  );
                }
                return (
                  <div
                    key={message.messageId}
                    className={
                      message._sender && message._sender.userId === currentUserId
                        ? classes.currentUserMessageContainer
                        : classes.messageContainer
                    }
                  >
                    {messageData && messageData.room_id ? (
                      miniDisplay ? null : (
                        <div className={classes.joinVideoContainer}>
                          <Typography className={classes.sentDate} variant="body1" inline="true">
                            {format(
                              new Date(message.createdAt),
                              convertTokens("MM/DD/YYYY h:mm a"),
                            )}
                          </Typography>
                          <Button
                            disabled={miniDisplay}
                            variant="contained"
                            className={classes.joinVideoButton}
                            fullWidth
                            onClick={() => onClientStartVideo(messageData)}
                          >
                            <VideocamIcon className={classes.joinVideoIcon} />
                            <Typography component="p" className={classes.joinVideoText}>
                              {message._sender.userId === currentUserId
                                ? "Video Link Sent"
                                : "Join Video Call"}
                            </Typography>
                          </Button>
                        </div>
                      )
                    ) : (
                      <ListItem
                        disableGutters={true}
                        alignItems="flex-start"
                        key={message.messageId}
                        className={
                          message._sender && message._sender.userId === currentUserId
                            ? classes.currentUserMessageBubble
                            : classes.messageBubble
                        }
                      >
                        {message && message._sender && message._sender.profileUrl
                          ? currentUserId !== message._sender.userId && (
                              <ListItemAvatar
                                onClick={
                                  this.isBCBA(message._sender)
                                    ? () => onAvatarClick(message._sender.userId)
                                    : null
                                }
                                style={{ minWidth: 0 }}
                              >
                                <Avatar
                                  alt={message._sender.nickname}
                                  src={message._sender.profileUrl}
                                />
                              </ListItemAvatar>
                            )
                          : message._sender &&
                            currentUserId !== message._sender.userId && (
                              <ListItemAvatar style={{ minWidth: 0 }}>
                                <Avatar>
                                  <PersonRoundedIcon
                                    color="action"
                                    style={{
                                      fontSize: 50,
                                      marginTop: 8,
                                    }}
                                  />
                                </Avatar>
                              </ListItemAvatar>
                            )}
                        <ListItemText
                          disableTypography
                          className={
                            message._sender && currentUserId !== message._sender.userId
                              ? classes.messageBody
                              : classes.currentUserMessageBody
                          }
                          primary={
                            <React.Fragment>
                              {message._sender && currentUserId !== message._sender.userId && (
                                <Typography
                                  className={classes.sentName}
                                  variant="body2"
                                  inline="true"
                                >
                                  {message && message._sender ? message._sender.nickname : "TBD"}
                                  {this.isBCBA(message._sender) && (
                                    <img
                                      src="https://an-user-profile-images.s3.amazonaws.com/verified.png"
                                      className={classes.bcbaImage}
                                    />
                                  )}
                                  {this.isTeam(message._sender) && (
                                    <img
                                      src="https://an-user-profile-images.s3.amazonaws.com/isTeam.png"
                                      className={classes.bcbaImage}
                                    />
                                  )}
                                </Typography>
                              )}
                              <Typography
                                className={classes.sentDate}
                                style={
                                  message._sender && currentUserId === message._sender.userId
                                    ? {
                                        color: "#fff",
                                        textAlign: "right",
                                      }
                                    : null
                                }
                                variant="body1"
                                inline="true"
                              >
                                {format(
                                  new Date(message.createdAt),
                                  convertTokens("MM/DD/YYYY h:mm a"),
                                )}
                              </Typography>
                            </React.Fragment>
                          }
                          secondary={body}
                        />
                      </ListItem>
                    )}
                  </div>
                );
              })}
          </List>
          <div
            ref={(el) => {
              this.messagesEnd = el;
            }}
          />
        </div>
        {typingMessage && (
          <Typography variant="body1" className={classes.typingMessage}>
            {typingMessage}
          </Typography>
        )}
        <ChatInput
          {...this.props}
          {...this.state}
          onChange={this.changeMessage}
          sendMessage={this.sendMessage}
        />
      </main>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ChatMessages);
