export default (theme) => ({
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "30px",
    width: "100%",
  },
  filtersContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    // maxWidth: "80%",
  },
  filter: {
    marginRight: 16,
  },
  filterLabel: {
    marginBottom: 3,
    fontSize: 14,
    fontWeight: 500,
    color: "#69718F",
    lineHeight: "20px",
  },
  chip: {
    height: "32px",
    borderRadius: 4,
    border: "1px solid #A5AABC",
    backgroundColor: "#F0F1F4",
    marginRight: 8,
  },
  chipLabel: {
    color: "#15171D",
    fontWeight: 400,
    fontSize: 13,
  },
  chipDeleteIcon: {
    borderRadius: "50%",
    height: 18,
    width: 18,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "visible",
    backgroundColor: "#3F4456",
  },
  statusPlaceholder: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    height: "100%",
    paddingLeft: 6,
    "& p": {
      color: "#69718F",
      fontSize: 16,
    },
  },
  inputPlaceholder: {
    "&::placeholder": {
      color: "#69718F",
      fontSize: 16,
    },
  },
  popperMenuPaper: {
    boxShadow: "0px 10px 15px -3px rgba(55, 55, 55, 0.15)",
  },
  due: {
    color: "#15171D",
  },
  pastDue: {
    color: "#B91C1C",
  },
  dueTomorrow: {
    color: "#99771A",
  },
  noteMenuPaper: {
    border: "0.5px solid #D7D9E0",
    borderRadius: 5,
    boxShadow: "0px 10px 15px -3px rgba(55, 55, 55, 0.15)",
    overflow: "hidden",
  },
  noteMenuList: {
    paddingTop: 0,
    paddingBottom: 0,
    borderRadius: 5,
  },
  noteMenuTitleText: {
    fontSize: 14,
    fontWeight: 500,
    background: "#F4ECFA",
    color: "#15171D",
    padding: "10px 14px",
    borderBottom: 2,
    margin: 0,
  },
  noteMenuOptionText: {
    fontSize: 15,
    fontWeight: 400,
    color: "#15171D",
    padding: "10px 14px",
  },
  tableDataText: {
    fontSize: 16,
    fontWeight: 400,
    color: "#15171D",
  },
  bulkHeader: {
    display: "flex",
    height: 76,
    padding: 16,
    justifyContent: "space-between",
    alignItems: "center",
    alignSelf: "stretch",
    backgroundColor: "rgba(142, 66, 202, 0.08)",
  },
  bulkHeaderCount: {
    color: "#8E42CA",
  },
  bulkHeaderButtons: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  tableCellCheckboxContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "fit-content",
    gap: "8px",
    "& .MuiCheckbox-root": {
      padding: 0,
    },
  },
});
