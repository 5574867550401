import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withStyles from "@mui/styles/withStyles";
import actions from "../../actions";
import styles from "../Signin/styles";
import ResetPassword from "../ResetPassword/ResetPassword";
import cookie from "react-cookies";
import {
  getPasswordChange,
  getUserAttributes,
  getLoginInvitation,
  getInvitationError,
  getGroups,
} from "../../selectors";

const mapStateToProps = (state) => ({
  passwordChange: getPasswordChange(state),
  userAttributes: getUserAttributes(state),
  loginInvitation: getLoginInvitation(state),
  invitationError: getInvitationError(state),
  userGroups: getGroups(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changePassword: actions.changePassword,
      getLoginInvitation: actions.getLoginInvitation,
      markInvitationUsed: actions.markInvitationUsed,
      setUserAttributes: actions.setUserAttributes,
      startSignin: actions.startSignin,
      setPageDetails: actions.setPageDetails,
      agreeToPolicies: actions.agreeToPolicies,
    },
    dispatch
  );

function getInitialState(props) {
  return {
    username: props.userAttributes.email || "",
    currentPassword: "",
    verificationCode: "ignoreme",
    invitationSignin: false,
  };
}

class ForcePasswordContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...getInitialState(this.props),
    };
  }

  componentDidMount() {
    this.props.setPageDetails({
      pageName: "Reset your Password?",
    });

    if (!this.props.passwordChange && !this.props.match.params.guid) {
      this.props.history.push("/dashboard");
    }
    if (this.props.match.params.guid) {
      this.setState({ invitationSignin: true });
      this.props.getLoginInvitation(this.props.match.params.guid);
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.loginInvitation && this.props.loginInvitation) {
      this.props.setUserAttributes({
        username: this.props.loginInvitation.email,
      });
      this.props.startSignin({
        username: this.props.loginInvitation.email,
        password: this.props.loginInvitation.password,
      });
    }
    if (prevProps.passwordChange && !this.props.passwordChange) {
      this.props.markInvitationUsed(this.props.match.params.guid);
      if (this.props.loginInvitation.authority_name === "ROLE_PARENT") {
        const userId = this.props.loginInvitation.user_id;
        this.props.agreeToPolicies({ userId });
        const clientId = cookie.load(`${userId}-clientId`);
        this.props.history.push(!clientId ? "/account-selection" : "/dashboard");
      } else {
        this.props.history.push("/dashboard");
      }
    }
  }

  onChange = (name) => (e) => {
    const { checked, type, value } = e.target;
    const val = type === "checkbox" ? checked : value;

    this.setState({ [name]: val });
  };

  onSubmit = (values) => {
    this.props.changePassword({
      ...this.state,
      ...values,
    });
  };

  onSubmitInvitationLogin = (values) => {
    this.props.changePassword({
      ...this.state,
      ...values,
      currentPassword: this.props.loginInvitation.password,
      username: this.props.loginInvitation.email,
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <ResetPassword
          {...this.props}
          {...this.state}
          onChange={this.onChange}
          onSubmit={this.onSubmit}
          onSubmitInvitationLogin={this.onSubmitInvitationLogin}
          change={true}
          showVersion={false}
        />
      </div>
    );
  }
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(ForcePasswordContainer)
);
