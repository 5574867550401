import React from "react";
import LuxonUtils from "@date-io/luxon";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import withStyles from "@mui/styles/withStyles";
import TextField from "@mui/material/TextField";
import enUsLocale from "date-fns/locale/en-US";
import { parseISO } from "date-fns";

export const CustomDatepicker = (props) => {
  const { views, value, disableFuture, disablePast, label, onChange } = props;
  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={enUsLocale}
      utils={LuxonUtils}
    >
      <DatePicker
        views={views || ["year", "month", "day"]}
        openTo={value ? "day" : "year"}
        disableFuture={!!disableFuture}
        disablePast={!!disablePast}
        value={parseISO(value)}
        label={label}
        onChange={onChange}
        format="P"
        slots={{ textField: CustomInput, ...props.slots }}
        slotProps={{
          textField: { error: false },
          popper: { style: { zIndex: 4000 } },
          ...props.slotProps,
        }}
        {...props}
        minDate={props?.minDate ? parseISO(props?.minDate) : null}
        maxDate={props?.maxDate ? parseISO(props?.maxDate) : null}
      />
    </LocalizationProvider>
  );
};

export default CustomDatepicker;

const StyledInput = withStyles({
  root: {
    backgroundColor: "#fff",
    "& fieldset": {
      borderRadius: 5,
    },
    "& .MuiOutlinedInput-root": {
      fontSize: 16,
      color: "#15171d",
      fontWeight: 500,
      letterSpacing: "-2%",
      "&:hover fieldset": {
        border: "1px solid #A5AABC",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid #A5AABC",
      },
    },
    "& .Mui-disabled": {
      opacity: "0.5",
      pointerEvents: "none",
    },
  },
})(TextField);

export const CustomInput = React.forwardRef((props, ref) => {
  return <StyledInput {...props} variant="outlined" size="small" />;
});
