import React, { useEffect, useMemo, useState } from "react";
import FormControl from "@mui/material/FormControl";
import { searchClients } from "api/customers";
import { PeopleFilter } from "./PeopleFilter";
import { useQuery } from "react-query";

export const ClientsFilter = ({ userId, clientId, classes, onChange, activeOnly = true }) => {
  const [inputValue, setInputValue] = useState("");
  const [userScheduleColorMap, setUserScheduleColorMap] = useState({});
  const [filteredClients, setFilteredClients] = useState([]);

  useEffect(() => {
    // Transitioning from having a clientId to not having one implies filters have been cleared so we clear the input value
    if (!clientId) {
      setInputValue("");
    }
  }, [clientId]);

  useEffect(() => {
    if (!inputValue) {
      setFilteredClients([]);
      onChange("");
    }
  }, [inputValue]);

  const { data: response } = useQuery(
    ["searchClients", inputValue],
    () => searchClients(userId, { nameInput: inputValue, activeOnly }),
    {
      enabled: inputValue?.length >= 2 && !!userId,
    }
  );

  const searchResults = useMemo(
    () =>
      (response?.data ?? []).map((c) => ({
        id: c.clientId,
        name: c.child_name,
      })),
    [response?.data]
  );

  const onFilteredClientsChange = (newFilteredClient) => {
    setFilteredClients([newFilteredClient]);
    onChange(newFilteredClient.id);
  };

  const getOptionLabel = ({ id, name }) => {
    const client = response?.data.find((c) => c.clientId === id);
    const is_active = client ? client.is_active : false;
    return (
      <>
        {name} {!is_active ? <span style={{ color: "gray" }}>&nbsp;deactivated</span> : null}
      </>
    );
  };

  return (
    <FormControl margin="none" className={classes.filter} style={{ width: "15rem" }}>
      <PeopleFilter
        singlePick
        label={"Clients"}
        searchResults={searchResults}
        inputValue={inputValue}
        setInputValue={setInputValue}
        filteredPeople={filteredClients}
        setFilteredPeople={onFilteredClientsChange}
        personScheduleColorMap={userScheduleColorMap}
        setPersonScheduleColorMap={setUserScheduleColorMap}
        getOptionLabel={getOptionLabel}
      />
    </FormControl>
  );
};
