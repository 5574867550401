// DayAvailabilityMenu.js
import React, { useState } from "react";
import { Menu, MenuItem, Checkbox, Typography, Button, Box } from "@mui/material";

export const CopyAvailabilityMenu = ({ anchorEl, isMenuOpen, handleMenuClose, copyToDays }) => {
  const [selectedDays, setSelectedDays] = useState([]);

  const handleDayChange = (day) => {
    setSelectedDays((prevSelectedDays) => {
      if (prevSelectedDays.includes(day)) {
        return prevSelectedDays.filter((d) => d !== day);
      } else {
        return [...prevSelectedDays, day];
      }
    });
  };
  return (
    <Menu
      anchorEl={anchorEl}
      open={isMenuOpen}
      onClose={handleMenuClose}
      sx={{
        "& .MuiPaper-root": {
          width: "220px",
        },
      }}
    >
      <Typography variant="subtitle2" style={{ marginLeft: "16px" }}>
        Apply availability to:
      </Typography>
      {["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"].map((day) => (
        <MenuItem key={day} onClick={() => handleDayChange(day)} sx={{ justifyContent: "start" }}>
          <Checkbox checked={selectedDays.includes(day)} size="small" />
          <>{day.charAt(0).toUpperCase() + day.slice(1)}</>
        </MenuItem>
      ))}
      <Box style={{ padding: "0 16px" }}>
        <Button
          variant="contained"
          onClick={() => {
            copyToDays({ selectedDays });
            handleMenuClose();
          }}
          fullWidth
        >
          Apply
        </Button>
      </Box>
    </Menu>
  );
};
