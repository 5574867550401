import React, { createContext, useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PageContainer from "elements/PageContainer";

// Create a context for loading state management
const LoadingContext = createContext();

/**
 * Custom hook to access the global loading context.
 * @returns {Object} - The loading context containing the showLoading function and isLoading state.
 * @throws {Error} - If the hook is used outside of a GlobalPageLoadingProvider.
 */
export const useGlobalPageLoading = () => {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error("useGlobalPageLoading must be used within a GlobalPageLoadingProvider");
  }
  return context;
};

/**
 * Provider component to manage global loading state and provide loading functionality to child components.
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child components to render.
 * @returns {React.ReactElement} - The rendered provider component.
 */
export const GlobalPageLoadingProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false); // State to track if the loading state is active
  const history = useHistory(); // Hook to access navigation history

  /**
   * Function to show or hide loading.
   * @param {boolean} [loading=true] - Whether to show the loading state.
   */
  const showLoading = (loading = true) => {
    setIsLoading(loading);
  };

  // Effect to reset loading state on navigation events
  useEffect(() => {
    const unlisten = history.listen(() => {
      setIsLoading(false);
    });

    return () => {
      unlisten();
    };
  }, [history]);

  // Render the provider with the loading context value and the loading component
  return (
    <LoadingContext.Provider value={{ showLoading, isLoading }}>
      {isLoading ? <PageContainer loading={isLoading} /> : children}
    </LoadingContext.Provider>
  );
};
