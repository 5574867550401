import { LoadingButton } from "@mui/lab";
import { Stack, TextField, Typography } from "@mui/material";
import { PaperPlaneTilt } from "@phosphor-icons/react";
import { useGlobalToast } from "components/GlobalToastProvider";
import { useFormik, useFormikContext } from "formik";
import { useInviteMember } from "hooks/api/useInviteMember";
import React from "react";
import * as yup from "yup";

export default function InviteMembers({ title, teamId, refetchTeamMembers, clientId }) {
  const { inviteMemberMutation, isLoading } = useInviteMember();
  const { showToast } = useGlobalToast();
  const mainForm = useFormikContext();
  const teamMembers = mainForm.values.teamSection.teamMembers;
  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: yup.object({
      email: yup
        .string()
        .email("Invalid email address")
        .required("")
        .test(
          "is-unique",
          "Member already added",
          (val) => !teamMembers?.some((member) => member.email === val)
        ),
    }),
    onSubmit: ({ email }, { resetForm }) => {
      inviteMemberMutation(
        { teamId, email, clientId },
        {
          onSuccess: () => {
            refetchTeamMembers();
            showToast({ message: "Invite successfully sent!" });
            resetForm();
          },
          onError: (error) => {
            if (
              error.response.data.message === "Cannot invite this user" &&
              error.response.status === 400
            ) {
              formik.setFieldError(
                "email",
                "The address provided is associated with a clinical or administrative account."
              );
            }
          },
        }
      );
    },
    validateOnMount: true,
  });
  return (
    <Stack gap={7}>
      <Stack gap={1}>
        <Typography variant="body1">{title}</Typography>
        <Typography variant="body2" color="text.secondary">
          Invited members will receive an invitation email and are required to sign our consent
          forms before accepting invitations.
        </Typography>
      </Stack>
      <Stack
        direction="row"
        gap={3}
        alignItems={formik.touched.email && Boolean(formik.errors.email) ? "center" : "flex-end"}
        flexWrap="wrap"
      >
        <TextField
          sx={{ flexGrow: 1 }}
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          label="Email Address"
          placeholder="Email address"
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <LoadingButton
          style={{ padding: "8px 16px" }}
          startIcon={<PaperPlaneTilt weight="duotone" />}
          loading={isLoading}
          disabled={!formik.isValid}
          onClick={formik.handleSubmit}
        >
          Send Invite
        </LoadingButton>
      </Stack>
    </Stack>
  );
}
