import React from "react";
import {
  Typography,
  IconButton,
  Box,
  Tooltip,
  ListItemIcon,
  Stack,
  Button,
  Divider,
  Menu,
} from "@mui/material";
import { Calendar, CaretLeft, CaretRight, Play, SlidersHorizontal } from "@phosphor-icons/react";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { Views } from "react-big-calendar";
import { useTheme } from "@mui/styles";
import { useHistory } from "react-router-dom";
import * as selectors from "selectors";
import { useSelector } from "react-redux";
import { useGetFeatureFlag } from "hooks/useGetFeatureFlag";

export const CalendarToolbar = ({
  toggleScheduleUtilityDrawer,
  label,
  back,
  next,
  calendarType,
  handleCalendarType,
  toggleStartDemoCallDialog,
}) => {
  const calendarTypesItems = [
    { label: "Day", value: Views.DAY, icon: <Calendar weight="duotone" /> },
    { label: "Week", value: Views.WEEK, icon: <Calendar weight="duotone" /> },
    { label: "Month", value: Views.MONTH, icon: <Calendar weight="duotone" /> },
  ];
  const menuItemLookup = calendarTypesItems.reduce((acc, item) => {
    acc[item.value] = item.label;
    return acc;
  }, {});
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openCreateEvent = Boolean(anchorEl);
  const handleOpenCreateEvent = (event) => setAnchorEl(event.currentTarget);
  const handleCloseCreateEvent = () => setAnchorEl(null);
  const history = useHistory();
  const userId = useSelector(selectors.getUserId);
  const { data: schedulePageFeatureFlag } = useGetFeatureFlag(userId, "scheduling_page");
  const isClinician = useSelector(selectors.isClinician);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        padding: theme.spacing(4, 7),
        justifyContent: "space-between",
        backgroundColor: "#F6F7F8",
        alignItems: "center",
      }}
    >
      <Stack direction="row" alignItems="center" gap={theme.spacing(3)}>
        <IconButton onClick={back} aria-label="previous month" fontSize="small">
          <CaretLeft />
        </IconButton>

        <IconButton onClick={next} aria-label="next month" fontSize="small">
          <CaretRight />
        </IconButton>

        <Typography variant="subtitle1">{label}</Typography>
      </Stack>
      <Stack direction="row" alignItems="center" gap={theme.spacing(7)}>
        <Tooltip
          title="Filters"
          placement="top"
          PopperProps={{
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [-4, -16],
                },
              },
            ],
          }}
        >
          <IconButton
            onClick={toggleScheduleUtilityDrawer}
            aria-label="filter options"
            fontSize="small"
          >
            <SlidersHorizontal />
          </IconButton>
        </Tooltip>
        <TextField
          id="calendarType"
          select
          name="calendarType"
          value={calendarType}
          onChange={(e) => {
            handleCalendarType(e.target.value);
          }}
          SelectProps={{
            renderValue: (selected) => menuItemLookup[selected],
          }}
          style={{ width: 100 }}
        >
          {calendarTypesItems.map((item) => (
            <MenuItem key={`${item.value}`} value={item.value}>
              {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
              {item.label}
            </MenuItem>
          ))}
        </TextField>
        <Divider orientation="vertical" variant="middle" flexItem />
        {schedulePageFeatureFlag && (
          <Button
            id="open-menu-button"
            aria-controls={open ? "create-event-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            startIcon={<Calendar />}
            onClick={(e) => {
              isClinician
                ? handleOpenCreateEvent(e)
                : history.push("/clinician-schedule/create-event");
            }}
          >
            Create Event
          </Button>
        )}
        <Menu
          id="create-event-menu"
          anchorEl={anchorEl}
          open={openCreateEvent}
          onClose={handleCloseCreateEvent}
          MenuListProps={{ "aria-labelledby": "open-menu-button" }}
        >
          <MenuItem onClick={() => history.push("/clinician-schedule/create-event")}>
            <ListItemIcon>
              <Calendar weight="duotone" />
            </ListItemIcon>
            Create Event
          </MenuItem>
          <MenuItem onClick={toggleStartDemoCallDialog}>
            <ListItemIcon>
              <Play weight="duotone" />
            </ListItemIcon>
            Start a demo call
          </MenuItem>
        </Menu>
      </Stack>
    </Box>
  );
};

export default CalendarToolbar;
