/** @type {import('amazon-chime-sdk-js').ConnectionHealthPolicyConfiguration} */
const ConnectionHealthPolicyConfiguration = {
  //  defaults (leave overlapping props for visibility)
  connectionUnhealthyThreshold: 25,
  connectionWaitTimeMs: 10000,
  cooldownTimeMs: 60000,
  fiveBarsTimeMs: 60000,
  fourBarsTimeMs: 20000,
  fractionalLoss: 0.5,
  goodSignalTimeMs: 15000,
  initialHealth: 1,
  maxHealth: 1,
  maximumAudioDelayDataPoints: 10,
  maximumAudioDelayMs: 60000,
  maximumTimesToWarn: 2,
  minHealth: 0,
  missedPongsLowerThreshold: 1,
  missedPongsUpperThreshold: 4,
  noSignalThresholdTimeMs: 10000,
  oneBarWeakSignalTimeMs: 5000,
  packetsExpected: 50,
  pastSamplesToConsider: 15,
  sendingAudioFailureInitialWaitTimeMs: 3000,
  sendingAudioFailureSamplesToConsider: 2,
  threeBarsTimeMs: 10000,
  twoBarsTimeMs: 5000,
  zeroBarsNoSignalTimeMs: 5000,

  // overrides

  highEncodeCpuMsThreshold: 1200,

  // trigger reconnect quicker
  connectionUnhealthyThreshold: 10,
};
export default ConnectionHealthPolicyConfiguration;
