import React from "react";
import { Typography } from "@mui/material";

const Statistics = (props) => {
  const { classes, clientStatistics } = props;
  const {
    weekly_caregiver_training,
    weekly_direct_therapy,
    future_caregiver_training,
    future_direct_therapy,
    no_shows,
    cancellations,
  } = clientStatistics;

  const calculateAreasOfImprovement = () => {
    let areasOfImprovement = 0;
    if (!weekly_caregiver_training || parseInt(weekly_caregiver_training) < 1) {
      areasOfImprovement += 1;
    }
    if (!weekly_direct_therapy || parseInt(weekly_direct_therapy) < 3) {
      areasOfImprovement += 1;
    }
    if (!future_direct_therapy || parseInt(future_direct_therapy) < 6) {
      areasOfImprovement += 1;
    }
    if (!future_caregiver_training || parseInt(future_caregiver_training) < 2) {
      areasOfImprovement += 1;
    }
    if (no_shows && parseInt(no_shows) > 2) {
      areasOfImprovement += 1;
    }
    if (cancellations && parseInt(cancellations) > 3) {
      areasOfImprovement += 1;
    }
    return areasOfImprovement;
  };
  const areasOfImprovement = calculateAreasOfImprovement();
  return (
    <div className={classes.roadmapContainer}>
      <Typography component="p" className={classes.statisticsTitle}>
        Statistical Information
      </Typography>
      {areasOfImprovement > 0 && (
        <Typography component="p" className={classes.statisticsAreaOfImprovement}>
          There {areasOfImprovement > 1 ? "are" : "is"}{" "}
          <span style={{ fontSize: 15, fontWeight: 500 }}>{areasOfImprovement}</span>{" "}
          {areasOfImprovement > 1 ? "areas" : "area"} for improvement
        </Typography>
      )}
      <div className={classes.statisticsContainer}>
        <div className={classes.statistic}>
          <Typography component="p">Direct Therapy Calls Scheduled this week:</Typography>
          <div
            className={classes.statisticResult}
            style={
              !weekly_direct_therapy || weekly_direct_therapy < 3
                ? { backgroundColor: "#FEF2F2", color: "#B91C1C" }
                : null
            }
          >
            <Typography component="p">{clientStatistics?.weekly_direct_therapy || 0}</Typography>
          </div>
        </div>
        <div className={classes.statistic}>
          <Typography component="p">Caregiver Training Calls Scheduled this week:</Typography>
          <div
            className={classes.statisticResult}
            style={
              !weekly_caregiver_training || weekly_caregiver_training < 1
                ? { backgroundColor: "#FEF2F2", color: "#B91C1C" }
                : null
            }
          >
            <Typography component="p">
              {clientStatistics?.weekly_caregiver_training || 0}
            </Typography>
          </div>
        </div>
        <div className={classes.statistic}>
          <Typography component="p">Total Direct Therapy Calls in the Future:</Typography>
          <div
            className={classes.statisticResult}
            style={
              !future_direct_therapy || future_direct_therapy < 6
                ? { backgroundColor: "#FEF2F2", color: "#B91C1C" }
                : null
            }
          >
            <Typography component="p">{clientStatistics?.future_direct_therapy || 0}</Typography>
          </div>
        </div>
        <div className={classes.statistic}>
          <Typography component="p">Total Caregiver Training Calls in the Future:</Typography>
          <div
            className={classes.statisticResult}
            style={
              !future_caregiver_training || future_caregiver_training < 2
                ? { backgroundColor: "#FEF2F2", color: "#B91C1C" }
                : null
            }
          >
            <Typography component="p">
              {clientStatistics?.future_caregiver_training || 0}
            </Typography>
          </div>
        </div>
        <div className={classes.statistic}>
          <Typography component="p">Number of No Shows in the last 30 Days:</Typography>
          <div
            className={classes.statisticResult}
            style={
              no_shows && parseInt(no_shows) > 2
                ? { backgroundColor: "#FEF2F2", color: "#B91C1C" }
                : null
            }
          >
            <Typography component="p">{clientStatistics?.no_shows || 0}</Typography>
          </div>
        </div>
        <div className={classes.statistic} style={{ border: "none" }}>
          <Typography component="p">Number of Cancellations in the last 30 Days:</Typography>
          <div
            className={classes.statisticResult}
            style={
              cancellations && parseInt(cancellations) > 3
                ? { backgroundColor: "#FEF2F2", color: "#B91C1C" }
                : null
            }
          >
            <Typography component="p">{clientStatistics?.cancellations || 0}</Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Statistics;
