import { log } from "video/debug";
export default class AudioInputController {
  __started = false;
  __device = null;
  __mediaStream = null;

  /**
   * @param {import('./MeetingSession').default} meetingSession
   * @param {string} userId
   */
  constructor(meetingSession, userId) {
    this.meetingSession = meetingSession;
    this.userId = userId;
  }

  destroy() {
    this.__mediaStream = null;
    this.__device = null;
    this.__started = null;
  }

  /** @param {import('amazon-chime-sdk-js').AudioInputDevice} device */
  setDevice(device) {
    if (device !== this.__device) {
      log("setting audio input device:", device);
      this.__device = device;
    }
  }

  async changeDevice(device) {
    if (device !== this.__device) {
      this.setDevice(device);
    }
    return this.restart();
  }

  async start() {
    log("starting audio input");
    // const { __started } = this;
    // if (__started) await this.stop();
    this.__started = true;
    this.__mediaStream = await this.meetingSession.audioVideo.startAudioInput(this.__device);
  }

  async restart() {
    log("restarting audio input");
    // if (this.__started) await this.stop();
    return this.start();
  }

  async stop() {
    log("stopping audio input");
    await this.meetingSession.audioVideo.stopAudioInput();
    this.__started = false;
  }
}
