import React, { useState } from "react";
import CriticalIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/ReportProblem";
import { Typography, Button } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { styles } from "./styles";
import CriticalNeedsDialog from "./Dialog/CriticalNeedsDialog";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const CriticalNeeds = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { classes, criticalNeeds, history } = props;
  const critical = criticalNeeds.critical || [];
  const warning = criticalNeeds.warning || [];
  return critical.length > 0 || warning.length > 0 ? (
    <>
      <Typography component="h2" className={classes.criticalNeedsTitle}>
        Critical Needs
      </Typography>
      <div className={classes.criticalNeedsContainer}>
        <div className={classes.needsContainer}>
          <div className={classes.criticalLevel}>
            {critical.length > 0 ? (
              <>
                <div className={classes.criticalIconContainer}>
                  <CriticalIcon
                    style={{
                      fill: "#B91C1C",
                      fontSize: 22,
                    }}
                  />
                </div>
                <div className={classes.criticalText}>
                  <Typography component="p">
                    There are <span>{critical.length} clients in critical need.</span> Resolve ASAP.
                  </Typography>
                </div>
              </>
            ) : (
              <>
                <div className={classes.checkIconContainer}>
                  <CheckCircleIcon
                    style={{
                      fill: "#287958",
                      fontSize: 22,
                    }}
                  />
                </div>
                <div className={classes.checkText} style={{ borderBottom: "1px solid #B4B8C7" }}>
                  <Typography component="p">
                    There are <span>0 clients in critical need!</span>
                  </Typography>
                </div>
              </>
            )}
          </div>
          <div className={classes.warningLevel}>
            {warning.length > 0 ? (
              <>
                <div className={classes.warningIconContainer}>
                  <WarningIcon
                    style={{
                      fill: "#D97706",
                      fontSize: 22,
                    }}
                  />
                </div>
                <div className={classes.warningText}>
                  <Typography component="p">
                    There are <span>{warning.length} clients in moderate need.</span> Address when
                    time is available.
                  </Typography>
                </div>
              </>
            ) : (
              <>
                <div className={classes.checkIconContainer}>
                  <CheckCircleIcon
                    style={{
                      fill: "#287958",
                      fontSize: 22,
                    }}
                  />
                </div>
                <div className={classes.checkText}>
                  <Typography component="p">
                    There are <span>0 clients in moderate need!</span>
                  </Typography>
                </div>
              </>
            )}
          </div>
        </div>
        <div className={classes.viewNeedsClients}>
          <Button
            variant="text"
            size="large"
            onClick={() => setDialogOpen(true)}
            disabled={critical.length === 0 && warning.length === 0}
          >
            View All
          </Button>
        </div>
      </div>
      <CriticalNeedsDialog
        criticalClients={critical}
        warningClients={warning}
        open={dialogOpen}
        closeDialog={() => setDialogOpen(false)}
        history={history}
        userId={props.userId}
        snoozeClientTask={props.snoozeClientTask}
        snoozeTaskLoading={props.snoozeTaskLoading}
        snoozeTaskError={props.snoozeTaskError}
      />
    </>
  ) : null;
};

export default withStyles(styles)(CriticalNeeds);
