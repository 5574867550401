import React, { useState } from "react";
import { TimePicker } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";

const convertToTimePickerDate = (timeStr) => {
  if (typeof timeStr != "string") {
    return timeStr;
  } else if (timeStr) {
    const [hours, minutes] = timeStr.split(":").map(Number);
    const time = new Date().setHours(hours, minutes, 0, 0);
    return time;
  } else {
    return null;
  }
};

/**
 * This time picker will work in place of the currently implemented
 * textfields of type "time". It make the time selection open when
 * anywhere on the input is clicked as well as utilizes the theme.
 * Currently accepts string on the 24hr clock, and dates (in order
 * to work with existing time picker implmentations), but new implementations
 * should use date objects.
 */
const ANTimePicker = ({ id, value, onChange, ...props }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <TimePicker
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      value={convertToTimePickerDate(value)}
      onChange={onChange}
      slots={{
        TextField: TextField,
      }}
      slotProps={{
        textField: {
          readOnly: true,
          onClick: handleOpen,
          ...props,
        },
        popper: {
          disablePortal: true,
        },
      }}
    />
  );
};

export default ANTimePicker;
