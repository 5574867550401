import { getSingleCustomerSearchAsync } from "api/customers";
import { useSearchCustomers } from "hooks/useSearchCustomers";
import React, { useState } from "react";
import { useQuery } from "react-query";

const withCustomerSearch = (WrappedComponent) => {
  return (props) => {
    const [searchParams, setSearchParams] = useState({
      includeTeam: false,
      disabled: false,
      search: "",
      allUsers: false,
      clients: false,
      clinicians: false,
      deactivated: false,
      parents: false,
    });

    const {
      data: searchResults,
      isLoading: searchLoading,
      refetch,
    } = useQuery({
      queryKey: ["searchCustomers", { ...searchParams }],
      queryFn: () =>
        getSingleCustomerSearchAsync({
          disabled: searchParams.disabled,
          includeTeam: searchParams.includeTeam,
          search: searchParams.search,
          allUsers: searchParams.allUsers,
          clients: searchParams.clients,
          clinicians: searchParams.clinicians,
          deactivated: searchParams.deactivated,
          parents: searchParams.parents,
        }),
      enabled: searchParams.search.length >= 2,
    });

    const getCustomers = (search, filters = {}) => {
      setSearchParams({
        ...search,
        ...filters,
      });
      refetch();
    };

    const data = searchResults?.data ?? [];

    const clearSearchResults = () => {
      setSearchParams({
        includeTeam: false,
        disabled: false,
        search: "",
        allUsers: false,
        clients: false,
        clinicians: false,
        deactivated: false,
        parents: false,
      });
    }

    return (
      <WrappedComponent
        {...props}
        customerSearchResults={data}
        customerSearchLoading={searchLoading}
        clearSearchResults={clearSearchResults}
        getCustomers={getCustomers}
      />
    );
  };
};

export default withCustomerSearch;
