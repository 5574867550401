export const providerTypes = [
  {
    value: "primary care",
    label: "Primary Physician",
    defaultDisclosureInfo: [
      "historyAndPhysicalExamination",
      "diagnosticReportOrTesting",
      "progressNotes",
      "psychologicalReports",
      "educationPlan",
      "treatmentPlanAndGoals",
      "other",
    ],
  },
  {
    value: "diagnosing",
    label: "Diagnosing Physician",
    defaultDisclosureInfo: [
      "historyAndPhysicalExamination",
      "diagnosticReportOrTesting",
      "progressNotes",
      "psychologicalReports",
      "educationPlan",
      "treatmentPlanAndGoals",
      "other",
    ],
  },
  {
    value: "school",
    label: "School",
    defaultDisclosureInfo: ["educationPlan"],
  },
  {
    value: "physical",
    label: "Physical Therapy",
    defaultDisclosureInfo: ["progressNotes", "other"],
  },
  {
    value: "occupational",
    label: "Occupational Therapy",
    defaultDisclosureInfo: ["progressNotes", "other"],
  },
  {
    value: "speech",
    label: "Speech Therapy",
    defaultDisclosureInfo: ["progressNotes", "other"],
  },
  {
    value: "daycare",
    label: "Daycare",
    defaultDisclosureInfo: ["progressNotes", "other"],
  },
  {
    value: "pediatrician",
    label: "Pediatrician",
    defaultDisclosureInfo: [
      "historyAndPhysicalExamination",
      "diagnosticReportOrTesting",
      "progressNotes",
      "psychologicalReports",
      "educationPlan",
      "treatmentPlanAndGoals",
      "other",
    ],
  },
];

export const checkboxes = [
  "historyAndPhysicalExamination",
  "diagnosticReportOrTesting",
  "progressNotes",
  "psychologicalReports",
  "educationPlan",
  "treatmentPlanAndGoals",
  "other",
];
