import { select } from "redux-saga/effects";
import SecureRequest from "../utils/securerequest";

export function* sendMessage(userId, params) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return yield Request.post(`/customers/${userId}/send-message`, params);
}

export function* logGroupMessage(params) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return yield Request.post(`/group-chat/log`, params);
}

export function* clinicianSendMessage(userId, params) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return yield Request.post(`/clinicians/${userId}/send-message`, params);
}
