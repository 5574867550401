import React from "react";
import Modal from "elements/Modal";

const DisableInsuranceDialog = (props) => {
  const { disableInsuranceOpen, onCloseDisableInsurance, onSubmitDisableInsurance } = props;
  return (
    <Modal
      open={disableInsuranceOpen}
      onClose={onCloseDisableInsurance}
      title=" Disable Client Insurance"
      titleCentered
      description="Are you sure you want to disable this client's insurance?"
      primaryActionText="Disable"
      primaryActionOnClick={onSubmitDisableInsurance}
      secondaryActionText="Cancel"
      secondaryActionOnClick={onCloseDisableInsurance}
    />
  );
};

export default DisableInsuranceDialog;
