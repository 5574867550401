import React, { useState, useRef } from "react";
import { IconButton, Slider, Stack, Tooltip, Menu, MenuItem, Box } from "@mui/material";
import {
  Play,
  Pause,
  SpeakerHigh,
  SpeakerSlash,
  CaretDoubleRight,
  Sliders,
  ArrowsOutSimple,
  ArrowsInSimple,
} from "@phosphor-icons/react";

/**
 * Formats time in seconds to a mm:ss string.
 *
 * @param {number} seconds - The time in seconds.
 * @returns {string} The formatted time string.
 */
const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const secs = Math.floor(seconds % 60);
  return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
};

/**
 * VideoPlayerControls component provides the controls for playing, pausing, seeking,
 * adjusting volume, changing playback speed, and toggling fullscreen for the video player.
 *
 * @param {Object} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 */
const VideoPlayerControls = ({
  playing,
  volume,
  played,
  playbackRate,
  anchorElSpeed,
  anchorElQuality,
  anchorElVolume,
  quality,
  handlePlayPause,
  handleVolumeChange,
  handleSeekChange,
  handleSpeedMenu,
  handleSpeedClose,
  handleQualityMenu,
  handleQualityClose,
  handleVolumeMenu,
  handleVolumeClose,
  handlePlaybackRateChange,
  handleQualityChange,
  playerRef,
  toggleFullscreen,
  isFullscreen,
  fullscreenContainer,
}) => {
  const [hoverTime, setHoverTime] = useState(null);
  const [hoverPosition, setHoverPosition] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [hoverVolume, setHoverVolume] = useState(null);
  const [hoverVolumePosition, setHoverVolumePosition] = useState(null);
  const [isVolumeDragging, setIsVolumeDragging] = useState(false);
  const sliderRef = useRef();
  const volumeSliderRef = useRef();

  /**
   * Handles mouse move on the slider to show hover time tooltip.
   */
  const handleMouseMove = (event) => {
    if (!sliderRef.current) return;
    const rect = sliderRef.current.getBoundingClientRect();
    const percent = (event.clientX - rect.left) / rect.width;
    const time = playerRef.current.getDuration() * percent;
    setHoverTime(time);
    setHoverPosition(event.clientX - rect.left);
  };

  /**
   * Handles mouse leave event on the slider.
   */
  const handleMouseLeave = () => {
    if (!isDragging) {
      setHoverTime(null);
      setHoverPosition(null);
    }
  };

  /**
   * Handles change event on the slider.
   */
  const handleSliderChange = (event, newValue) => {
    handleSeekChange(event, newValue);
    if (!sliderRef.current) return;
    const rect = sliderRef.current.getBoundingClientRect();
    const percent = (event.clientX - rect.left) / rect.width;
    const time = playerRef.current.getDuration() * percent;
    setHoverTime(time);
    setHoverPosition(event.clientX - rect.left);
  };

  /**
   * Handles slider change committed event.
   */
  const handleSliderChangeCommitted = () => {
    setIsDragging(false);
    setHoverTime(null);
    setHoverPosition(null);
  };

  /**
   * Handles mouse move on the volume slider to show hover volume tooltip.
   */
  const handleVolumeMouseMove = (event) => {
    if (!volumeSliderRef.current) return;
    const rect = volumeSliderRef.current.getBoundingClientRect();
    const percent = 1 - (event.clientY - rect.top) / rect.height;
    const volumeValue = Math.max(0, Math.min(1, percent));
    setHoverVolume(volumeValue);
    setHoverVolumePosition(event.clientY - rect.top);
  };

  /**
   * Handles mouse leave event on the volume slider.
   */
  const handleVolumeMouseLeave = () => {
    if (!isVolumeDragging) {
      setHoverVolume(null);
      setHoverVolumePosition(null);
    }
  };

  /**
   * Handles change event on the volume slider.
   */
  const handleVolumeSliderChange = (event, newValue) => {
    handleVolumeChange(event, newValue);
    if (!volumeSliderRef.current) return;
    const rect = volumeSliderRef.current.getBoundingClientRect();
    const percent = 1 - (event.clientY - rect.top) / rect.height;
    const volumeValue = Math.max(0, Math.min(1, percent));
    setHoverVolume(volumeValue);
    setHoverVolumePosition(event.clientY - rect.top);
  };

  /**
   * Handles volume slider change committed event.
   */
  const handleVolumeSliderChangeCommitted = () => {
    setIsVolumeDragging(false);
    setHoverVolume(null);
    setHoverVolumePosition(null);
  };

  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      justifyContent="center"
      padding={"12px 24px"}
    >
      <Tooltip title={playing ? "Pause" : "Play"} placement="top">
        <IconButton
          variant="outlined"
          size="medium"
          color="secondary"
          sx={{
            width: "60px",
            height: "60px",
            borderRadius: "100px",
            border: "1px solid",
            padding: "8px",
            fontSize: "large",
          }}
          onClick={handlePlayPause}
        >
          {playing ? <Pause /> : <Play />}
        </IconButton>
      </Tooltip>
      <Tooltip title="Playback Speed" placement="top">
        <IconButton onClick={handleSpeedMenu} style={{ padding: "4px" }}>
          <CaretDoubleRight />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorElSpeed}
        open={Boolean(anchorElSpeed)}
        onClose={handleSpeedClose}
        container={fullscreenContainer}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <MenuItem selected={playbackRate === 1} onClick={() => handlePlaybackRateChange(1)}>
          1x
        </MenuItem>
        <MenuItem selected={playbackRate === 1.5} onClick={() => handlePlaybackRateChange(1.5)}>
          1.5x
        </MenuItem>
        <MenuItem selected={playbackRate === 2} onClick={() => handlePlaybackRateChange(2)}>
          2x
        </MenuItem>
      </Menu>
      <Box
        padding={"8px"}
        width={"100%"}
        display={"flex"}
        flexDirection={"column"}
        alignContent={"center"}
        sx={{
          position: "relative",
          "&:hover .MuiSlider-thumb": {
            opacity: 1,
          },
        }}
        ref={sliderRef}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      >
        {hoverTime !== null && (
          <Box
            sx={{
              position: "absolute",
              left: hoverPosition,
              top: -30,
              transform: "translateX(-50%)",
              backgroundColor: "black",
              color: "white",
              padding: "4px 8px",
              borderRadius: "4px",
              fontSize: "12px",
              pointerEvents: "none",
              zIndex: 1301,
            }}
          >
            {formatTime(hoverTime)}
          </Box>
        )}
        <Slider
          value={played}
          min={0}
          max={1}
          step={0.01}
          onChange={(event, newValue) => {
            handleSliderChange(event, newValue);
            setIsDragging(true);
          }}
          onChangeCommitted={handleSliderChangeCommitted}
          sx={{
            "& .MuiSlider-thumb": {
              opacity: 0,
              height: 24,
              width: 24,
              "&:focus, &:hover, &.Mui-active": {
                boxShadow: "inherit",
              },
            },
            flexGrow: 1,
            "& .MuiSlider-track": {
              height: "12px",
            },
            "& .MuiSlider-rail": {
              height: "12px",
            },
          }}
        />
      </Box>
      <Tooltip title="Video Quality" placement="top">
        <IconButton onClick={handleQualityMenu}>
          <Sliders />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorElQuality}
        open={Boolean(anchorElQuality)}
        onClose={handleQualityClose}
        container={fullscreenContainer}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <MenuItem
          selected={quality === "720"}
          onClick={() => {
            handleQualityChange("720");
            handleQualityClose();
          }}
        >
          720
        </MenuItem>
        <MenuItem
          selected={quality === "540"}
          onClick={() => {
            handleQualityChange("540");
            handleQualityClose();
          }}
        >
          540
        </MenuItem>
        <MenuItem
          selected={quality === "360"}
          onClick={() => {
            handleQualityChange("360");
            handleQualityClose();
          }}
        >
          360
        </MenuItem>
      </Menu>
      <Tooltip title={"Volume"} placement="top">
        <IconButton onClick={handleVolumeMenu}>
          {volume === 0 ? <SpeakerSlash /> : <SpeakerHigh />}
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorElVolume}
        open={Boolean(anchorElVolume)}
        onClose={handleVolumeClose}
        PaperProps={{
          sx: {
            opacity: 0.9,
            borderRadius: "40px",
            padding: "8px",
            overflow: "visible",
          },
        }}
        container={fullscreenContainer}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            position: "relative",
            "&:hover .MuiSlider-thumb": {
              opacity: 1,
            },
            zIndex: 1301,
          }}
          ref={volumeSliderRef}
          onMouseMove={handleVolumeMouseMove}
          onMouseLeave={handleVolumeMouseLeave}
        >
          {hoverVolume !== null && (
            <Box
              sx={{
                position: "absolute",
                left: -40,
                top: hoverVolumePosition,
                transform: "translateY(-50%)",
                backgroundColor: "black",
                color: "white",
                padding: "4px 8px",
                borderRadius: "4px",
                fontSize: "12px",
                pointerEvents: "none",
                zIndex: 1301,
              }}
            >
              {`${Math.round(hoverVolume * 100)}%`}
            </Box>
          )}
          <Slider
            orientation="vertical"
            value={volume}
            min={0}
            max={1}
            step={0.01}
            onChange={(event, newValue) => {
              handleVolumeSliderChange(event, newValue);
              setIsVolumeDragging(true);
            }}
            onChangeCommitted={handleVolumeSliderChangeCommitted}
            sx={{
              height: 150,
              "& .MuiSlider-thumb": {
                opacity: 0,
                height: 24,
                width: 24,
                "&:focus, &:hover, &.Mui-active": {
                  boxShadow: "inherit",
                },
              },
              "& .MuiSlider-track": {
                width: "12px",
              },
              "& .MuiSlider-rail": {
                width: "12px",
              },
            }}
          />
        </Box>
      </Menu>
      <Tooltip title={isFullscreen ? "Exit Fullscreen" : "Fullscreen"} placement="top">
        <IconButton onClick={toggleFullscreen}>
          {isFullscreen ? <ArrowsInSimple /> : <ArrowsOutSimple />}
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

export default VideoPlayerControls;
