import React from "react";
import { Link } from "@mui/material";
import { formatPhoneNumber } from "utils/formatPhoneNumber";

export const getColumnDefinitions = (handleEdit = () => {}, canModifyInsurance, theme) => {
  const globalColumnAttributes = {
    headerClassName: "header",
    flex: 1,
    renderCell: ({ value }) => value ?? "-",
    sortable: false,
  };

  let columnDefinitions = [
    {
      field: "firstName",
      headerName: "First Name",
      sortable: true,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      sortable: true,
    },
    {
      field: "npiNumber",
      headerName: "NPI Number",
    },
    {
      field: "businessName",
      headerName: "Business Name",
      sortable: true,
    },
    {
      field: "physicalAddress",
      headerName: "Physical Address",
    },
    {
      field: "phone",
      headerName: "Phone Number",
      renderCell: ({ value }) => formatPhoneNumber(value),
    },
    {
      field: "email",
      headerName: "Email Address",
    },
    {
      field: "acceptsInsurance",
      headerName: "Accepts Insurance",
      renderCell: ({ value }) => (value ? "Yes" : "No"),
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      disableMenu: true,
      renderCell: (params) => {
        return (
          <Link
            onClick={() => handleEdit(params)}
            underline={"hover"}
            style={{
              fontSize: 14,
              cursor: "pointer",
            }}
          >
            Edit
          </Link>
        );
      },
    },
  ];

  columnDefinitions = columnDefinitions.map((colAttr) => ({
    ...globalColumnAttributes,
    ...colAttr,
  }));

  return columnDefinitions;
};

export default getColumnDefinitions;
