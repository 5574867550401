import * as yup from "yup";
import { isPast, parse } from "date-fns";

const isPastTime = (value, eventDate) => {
  const dateFormat = eventDate.toISOString().split("T")[0];
  const eventTime = parse(`${dateFormat} ${value}`, "yyyy-MM-dd h:mm a", new Date());

  if (isPast(eventTime)) {
    return false;
  }
  return true;
};

const validationSchema = (context) =>
  yup.object({
    event: yup.string().required("Please select an event type"),
    selectedClient: yup.object().required("Please select a client to include for the Event"),
    serviceType: yup.string().required("Please select a Service for the Event"),
    bcba: yup
      .string()
      .test("bcba-required", "Please select a Provider for the Event", (value) =>
        context.canSelectBCBA ? !!value : true
      ),
    eventDate: yup.date().required("Please select a date for the Event"),
    startTime: yup
      .string()
      .required("Please select the start time of the Event")
      .test("not-past-time", "Event time can't be in the past", function (value) {
        return isPastTime(value, this.parent.eventDate);
      }),
    duration: yup
      .number()
      .required("Please select the duration of the Event")
      .test("healthy-duration", "Event length can't exceed two hours", (value) =>
        !context.hasAccessToOnSched ? value <= 120 : true
      )
      .test("onSched-duration", "Event length can't exceed three hours", (value) =>
        context.hasAccessToOnSched ? value <= 180 : true
      ),
    isRepeating: yup.boolean(),
    timeUnit: yup
      .string()
      .nullable()
      .when("isRepeating", {
        is: true,
        then: () => yup.string().required("Please select the repeat pattern"),
      }),
    timeInterval: yup
      .number()
      .nullable()
      .when("isRepeating", {
        is: true,
        then: () =>
          yup
            .number()
            .positive("Must be a positive number")
            .integer("Must be an integer")
            .required("Please select the repeat pattern"),
      }),
    repeatEndType: yup.string().when("isRepeating", {
      is: true,
      then: () => yup.string().required("Please select when to end the event"),
    }),
    repeatUntilDate: yup
      .date()
      .nullable()
      .when("repeatEndType", {
        is: "until_date",
        then: (schema) =>
          schema
            .min(yup.ref("eventDate"), "Repeat until date must be after the event date")
            .required("Please select when to end the event"),
      }),
    repeatDays: yup.array().of(yup.number()),
  });

export default validationSchema;
