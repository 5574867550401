import { call, put, all, select, takeEvery } from "redux-saga/effects";
import actions from "../actions";
import {
  getChatList,
  getChatConversation,
  getChatMessages,
  getAllChatTags,
  getChatMessageTags,
  tagChatMessage,
  deleteMessageTag,
} from "../api/chats";

function* getChatsAsync() {
  try {
    const chatList = yield call(getChatList);
    yield put(actions.setChatList(chatList.data, parseInt(chatList.data[0].count)));
  } catch (e) {
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getChatDetailsAsync({ payload }) {
  const chatId = payload.id;
  try {
    const chatDetails = yield call(getChatConversation, chatId);
    yield put(actions.setChatDetails(chatDetails.data));
    const messages = yield call(getChatMessages, chatId);
    yield put(actions.loadChatMessages(messages.data));
  } catch (e) {
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getAllChatTagsAsync() {
  try {
    const tags = yield call(getAllChatTags);
    yield put(actions.setChatTags(tags.data));
  } catch (e) {
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getChatMessageTagsAsync({ payload }) {
  try {
    const tags = yield call(getChatMessageTags, payload);
    yield put(actions.setChatMessageTags(tags.data));
  } catch (e) {
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* tagChatMessageAsync({ payload }) {
  const { messageId, tagId } = payload;
  try {
    yield call(tagChatMessage, messageId, tagId);
    yield put(actions.getChatMessageTags(messageId));
  } catch (e) {
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* deleteChatMessageTagAsync({ payload }) {
  const { messageTagId, messageId } = payload;
  try {
    yield call(deleteMessageTag, messageTagId);
    yield put(actions.getChatMessageTags(messageId));
  } catch (e) {
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

export default function* chatsSaga() {
  yield takeEvery(actions.LOAD_CHAT_LIST, getChatsAsync);
  yield takeEvery(actions.LOAD_CHAT_DETAILS, getChatDetailsAsync);
  yield takeEvery(actions.GET_ALL_CHAT_TAGS, getAllChatTagsAsync);
  yield takeEvery(actions.GET_CHAT_MESSAGE_TAGS, getChatMessageTagsAsync);
  yield takeEvery(actions.TAG_CHAT_MESSAGE, tagChatMessageAsync);
  yield takeEvery(actions.DELETE_CHAT_MESSAGE_TAG, deleteChatMessageTagAsync);
}
