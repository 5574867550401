/**
 * 10kb limit
 * @type {{[key:string]:any,ts:string,build:string}}
 */
let jamMetadata = {
  ts: new Date().toISOString(),
  build: APP_VERSION,
};

/**
 * Jam Metadata - this data should be included in any recorded Jam sessions
 * - note: this is not a real React hook as it's a singleton, we're just imitating one
 * @returns {[jamMetadata: typeof jamMetadata,addJamMetadata:(data:{[key:string]:any})=>void]}
 * @example
 * import { useJamMetadata } from "hooks/useJamMetdata";
 *
 * const [jamMetadata, addJamMetadata] = useJamMetadata();
 *
 * // jamMetadata before: { ts: '1970-01-01 00:00:00.000Z', build: '123.45-0' }
 *
 * addJamMetadata({clientAuthed: true});
 *
 * // jamMetadata after: { ts: '1970-01-01 00:00:00.000Z', build: '123.45-0', clientAuthed: true }
 *
 */
export const useJamMetadata = () => {
  /**
   * @param  {{[key:string]:any}} data
   */
  const addJamMetadata = (data) => {
    try {
      const newSize = JSON.stringify({ ...jamMetadata, ...data }).length;
      if (newSize > 10000) {
        throw new Error("cannot add jam metadata - would exceed storage limit");
      }
      Object.assign(jamMetadata, data);
    } catch (e) {
      console.error(e);
    }
  };

  return [jamMetadata, addJamMetadata];
};
