import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withStyles from "@mui/styles/withStyles";
import actions from "../../actions";
import styles from "./styles";
import PageContainer from "elements/PageContainer";
import {
  getCreatePlanLoading,
  getCreatePlanSuccess,
  getInsuranceLoading,
  getInsurancePlan,
  getInsuranceProvidersList,
} from "../../selectors";
import SectionContainer from "elements/SectionContainer";
import Heading from "elements/Heading";
import { CustomInput } from "elements/DEPRECATED_elements/CustomInput/v1";
import TextField from "@mui/material/TextField";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Snackbar,
  Typography,
} from "@mui/material";
import { ArrowBackOutlined } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { COVERAGE_STATES, STATES } from "../../constants";
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import MenuItem from "@mui/material/MenuItem";

const mapStateToProps = (state) => ({
  insurancePlan: getInsurancePlan(state),
  insuranceProviders: getInsuranceProvidersList(state),
  updateLoading: getCreatePlanLoading(state),
  updateSuccess: getCreatePlanSuccess(state),
  loading: getInsuranceLoading(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateInsurancePlan: actions.updateInsurancePlan,
      getInsuranceProviders: actions.getInsuranceProvidersList,
      getInsurancePlan: actions.getInsurancePlan,
      clearInsuranceListFilters: actions.clearInsuranceListFilters,
    },
    dispatch,
  );

class InsurancePlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editEnabled: false,
      insuranceId: "",
      id: "",
      name: "",
      isMedicaid: null,
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      phone: "",
      locationModifier: null,
      providerModifier: "",
      telehealthModifier: null,
      isDirty: false,
      insuranceIdError: false,
      nameError: false,
      isMedicaidError: false,
      locationModifierError: false,
      providerModifierError: false,
      telehealthModifierError: false,
      address1Error: false,
      cityError: false,
      stateError: false,
      zipError: false,
      phoneError: false,
      payerIdError: false,
      coverageStateError: false,
    };
  }

  componentDidMount() {
    this.props.getInsurancePlan(this.props.match?.params?.id);
    this.props.getInsuranceProviders();
  }

  componentDidUpdate(prevProps, prevState) {
    const { insurancePlan } = this.props;
    if (
      (!prevProps.insurancePlan?.id && insurancePlan?.id) ||
      (prevProps.insurancePlan?.id != insurancePlan?.id && insurancePlan)
    ) {
      let numberValue = insurancePlan.phone?.replace(/[^0-9]/g, "");
      let phoneNumber = numberValue?.replace(
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/g,
        "($1)-$2-$3",
      );
      this.setState({
        id: insurancePlan.id,
        name: insurancePlan.name,
        insuranceId: insurancePlan.insurance_id,
        isMedicaid: insurancePlan.is_medicaid,
        address1: insurancePlan.address_1,
        address2: insurancePlan.address_2,
        city: insurancePlan.city,
        state: insurancePlan.state,
        zip: insurancePlan.zip,
        locationModifier: insurancePlan.location_modifier,
        providerModifier: insurancePlan.provider_modifier,
        telehealthModifier: insurancePlan.telehealth_modifier,
        phone: phoneNumber,
        payerId: insurancePlan.payer_id,
        coverageState: insurancePlan.coverage_state,
      });
    }
    if (prevProps.updateLoading && !this.props.updateLoading) {
      if (this.props.updateSuccess) {
        this.setState({ successToastOpen: true, editEnabled: false }, () => {
          setTimeout(() => {
            this.setState({ successToastOpen: false });
          }, 4000);
        });
      } else {
        this.setState({ error: true });
      }
    }
  }

  componentWillUnmount() {
    this.props.clearInsuranceListFilters();
  }

  onChange = (name) => (e) => {
    this.setState({ [name]: e.target.value, isDirty: true });
  };

  onNumberChange = (name) => (e) => {
    const { value } = e.target;
    this.setState({ [name]: value.replace(/[^0-9]/g, ""), isDirty: true });
  };

  onRadioButtonChange = (name) => (e) => {
    const { value } = e.target;
    this.setState({
      [name]: this.stringToBoolean(value),
      [`${name}Error`]: false,
      isDirty: true,
    });
  };

  stringToBoolean = (value) => {
    if (value && typeof value === "string") {
      if (value.toLowerCase() === "true") return true;
      if (value.toLowerCase() === "false") return false;
    }
    return value;
  };

  onChangePhone = (name) => (e) => {
    const { value } = e.target;
    let numberValue = value.replace(/[^0-9]/g, "");
    this.setState({
      [name]: numberValue.replace(
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/g,
        "($1)-$2-$3",
      ),
      isDirty: true,
    });
  };

  goBack = () => {
    this.props.history.push("/libraries");
  };

  onToggleEnableEdit = () => {
    this.setState({ editEnabled: !this.state.editEnabled });
  };

  onClickSave = () => {
    const {
      id,
      insuranceId,
      name,
      isMedicaid,
      address1,
      address2,
      city,
      state,
      zip,
      locationModifier,
      telehealthModifier,
      providerModifier,
      phone,
      payerId,
      coverageState,
    } = this.state;
    this.setState(
      {
        error: false,
        insuranceIdError: !insuranceId,
        nameError: !name,
        isMedicaidError: isMedicaid === null,
        locationModifierError: !locationModifier,
        providerModifierError: !providerModifier,
        telehealthModifierError: !telehealthModifier,
        address1Error: !address1,
        cityError: !city,
        stateError: !state,
        zipError: !zip || zip.trim().length != 5,
        phoneError: !phone || phone.trim().match(/\d/g).length != 10,
        payerIdError: !payerId,
        coverageStateError: !coverageState,
      },
      () => {
        if (
          this.state.insuranceIdError ||
          this.state.nameError ||
          this.state.isMedicaidError ||
          this.state.locationModifierError ||
          this.state.providerModifierError ||
          this.state.telehealthModifierError ||
          this.state.address1Error ||
          this.state.cityError ||
          this.state.stateError ||
          this.state.zipError ||
          this.state.phoneError ||
          this.state.payerIdError ||
          this.state.coverageStateError
        ) {
          return;
        } else {
          this.props.updateInsurancePlan({
            id,
            insuranceId,
            name,
            isMedicaid,
            address1,
            address2,
            city,
            state,
            zip,
            locationModifier,
            telehealthModifier,
            providerModifier,
            phone: phone?.replace(/[^0-9]/g, ""),
            payerId,
            coverageState,
          });
        }
      },
    );
  };

  render() {
    const { loading, classes, insurancePlan, updateLoading, insuranceProviders } = this.props;
    const {
      editEnabled,
      isDirty,
      insuranceId,
      name,
      isMedicaid,
      address1,
      address2,
      city,
      state,
      zip,
      locationModifier,
      telehealthModifier,
      providerModifier,
      phone,
      payerId,
      coverageState,
      nameError,
      insuranceIdError,
      isMedicaidError,
      locationModifierError,
      providerModifierError,
      telehealthModifierError,
      address1Error,
      cityError,
      stateError,
      zipError,
      phoneError,
      payerIdError,
      coverageStateError,
      successToastOpen,
      error,
    } = this.state;
    return (
      <>
        <PageContainer loading={loading}>
          <SectionContainer noPadding columnDirection>
            <div className={classes.header}>
              <Button
                variant="text"
                onClick={this.goBack}
                className={classes.goBackButton}
                startIcon={
                  <div className={classes.goBackIconContainer}>
                    <ArrowBackOutlined style={{ fill: "#3F4456", fontSize: 15 }} />
                  </div>
                }
              >
                Back to Insurances
              </Button>
              <Heading>Insurance Plan Details</Heading>
              <div
                style={{
                  width: 200,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <IconButton
                  className={editEnabled ? classes.iconButtonEnabled : classes.iconButton}
                  color="primary"
                  onClick={this.onToggleEnableEdit}
                  size="large"
                >
                  <EditIcon className={classes.editIcon} />
                </IconButton>
              </div>
            </div>
            <div className={classes.errorMessageContianer} style={{ marginTop: -20 }}>
              {error && (
                <Typography component="p">
                  Something went wrong on our end. Please try again.
                </Typography>
              )}
            </div>
            <div className={classes.planDetailsContainer}>
              {editEnabled ? (
                <>
                  <Grid container justifyContent="center" alignItems="flex-start" spacing={6}>
                    <Grid container item xs={12} sm={6} spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          id="insuranceId"
                          name="insuranceId"
                          label="Insurance Company*"
                          autoComplete="off"
                          onChange={this.onChange("insuranceId")}
                          value={insuranceId}
                          InputLabelProps={{ shrink: true }}
                          select
                          SelectProps={{
                            required: true,
                          }}
                          fullWidth
                          onBlur={() =>
                            insuranceIdError && insuranceId
                              ? this.setState({
                                  insuranceIdError: false,
                                })
                              : null
                          }
                          helperText={insuranceIdError ? "Please select an insurer" : null}
                        >
                          <MenuItem value="">All</MenuItem>
                          {insuranceProviders?.map((provider) => {
                            return (
                              <MenuItem key={provider.id} value={provider.id}>
                                {provider.name}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="name"
                          name="name"
                          label="Name of Plan*"
                          autoComplete="off"
                          onChange={this.onChange("name")}
                          value={name}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          onBlur={() =>
                            nameError && name ? this.setState({ nameError: false }) : null
                          }
                          helperText={nameError ? "Please enter a plan name" : null}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} style={{ marginBottom: 5 }}>
                        <FormControl>
                          <FormLabel>Medicaid Plan?*</FormLabel>
                          <RadioGroup
                            aria-label="isMedicaid"
                            name="isMedicaid"
                            value={isMedicaid}
                            onChange={this.onRadioButtonChange("isMedicaid")}
                            row
                          >
                            <FormControlLabel
                              value={true}
                              control={<Radio color="primary" />}
                              label="Yes"
                              size="small"
                            />
                            <FormControlLabel
                              value={false}
                              control={<Radio color="primary" />}
                              label="No"
                              size="small"
                            />
                          </RadioGroup>
                        </FormControl>
                        {isMedicaidError && (
                          <Typography component="p" className={classes.helperText}>
                            Please select an option
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <FormControl>
                          <FormLabel>Location Modifier*</FormLabel>
                          <RadioGroup
                            aria-label="locationModifier"
                            name="locationModifier"
                            value={locationModifier}
                            onChange={this.onRadioButtonChange("locationModifier")}
                            row
                            style={{ marginBottom: 5 }}
                          >
                            <FormControlLabel
                              value={"2"}
                              control={<Radio color="primary" />}
                              label="2"
                              size="small"
                            />
                            <FormControlLabel
                              value={"11"}
                              control={<Radio color="primary" />}
                              label="11"
                              size="small"
                            />
                            <FormControlLabel
                              value={"12"}
                              control={<Radio color="primary" />}
                              label="12"
                              size="small"
                            />
                          </RadioGroup>
                        </FormControl>
                        {locationModifierError && (
                          <Typography component="p" className={classes.helperText}>
                            Please select an option
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="providerModifier"
                          name="providerModifier"
                          autoComplete="off"
                          label="Provider Modifier*"
                          onChange={this.onChange("providerModifier")}
                          value={providerModifier}
                          InputLabelProps={{ shrink: true }}
                          select
                          SelectProps={{
                            required: true,
                          }}
                          fullWidth
                          style={{ marginBottom: 10 }}
                          onBlur={() =>
                            providerModifierError && providerModifier
                              ? this.setState({
                                  providerModifierError: false,
                                })
                              : null
                          }
                          helperText={providerModifierError ? "Please select a modifier" : null}
                        >
                          <MenuItem value="HO">HO</MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <FormControl>
                          <FormLabel>Telehealth Modifier*</FormLabel>
                          <RadioGroup
                            aria-label="telehealthModifier"
                            name="telehealthModifier"
                            value={telehealthModifier}
                            onChange={this.onRadioButtonChange("telehealthModifier")}
                            row
                            style={{ marginBottom: 5 }}
                          >
                            <FormControlLabel
                              value={"GT"}
                              control={<Radio color="primary" />}
                              label="GT"
                              size="small"
                            />
                            <FormControlLabel
                              value={"95"}
                              control={<Radio color="primary" />}
                              label="95"
                              size="small"
                            />
                          </RadioGroup>
                        </FormControl>
                        {telehealthModifierError && (
                          <Typography component="p" className={classes.helperText}>
                            Please select an option
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="payerId"
                          payerId="payerId"
                          label="Payer Id*"
                          autoComplete="off"
                          onChange={this.onChange("payerId")}
                          value={payerId}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          onBlur={() =>
                            payerIdError && payerId ? this.setState({ payerIdError: false }) : null
                          }
                          helperText={payerIdError ? "Please enter a payer Id" : null}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="coverageState"
                          name="coverageState"
                          autoComplete="off"
                          label="Coverage State*"
                          onChange={this.onChange("coverageState")}
                          value={coverageState}
                          InputLabelProps={{ shrink: true }}
                          select
                          SelectProps={{
                            required: true,
                          }}
                          fullWidth
                          style={{ marginBottom: 10 }}
                          onBlur={() =>
                            coverageStateError && coverageState
                              ? this.setState({
                                  coverageStateError: false,
                                })
                              : null
                          }
                          helperText={coverageStateError ? "Select Coverage State" : null}
                        >
                          <MenuItem disabled value={""}>
                            Select coverage state
                          </MenuItem>
                          {Object.keys(COVERAGE_STATES).map((key) => {
                            return (
                              <MenuItem key={key} value={key}>
                                {COVERAGE_STATES[key]}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </Grid>
                    </Grid>

                    <Grid container item xs={12} sm={6} spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          id="address1"
                          name="address1"
                          autoComplete="off"
                          label="Plan Address*"
                          onChange={this.onChange("address1")}
                          value={address1}
                          fullWidth
                          placeholder="Address line 1"
                          onBlur={() =>
                            address1Error && address1
                              ? this.setState({
                                  address1Error: false,
                                })
                              : null
                          }
                          helperText={address1Error ? "Please enter an address" : null}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="address2"
                          name="address2"
                          autoComplete="off"
                          onChange={this.onChange("address2")}
                          value={address2}
                          fullWidth
                          placeholder="Address line 2"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="city"
                          name="city"
                          autoComplete="off"
                          onChange={this.onChange("city")}
                          value={city}
                          fullWidth
                          placeholder="City"
                          onBlur={() =>
                            cityError && city ? this.setState({ cityError: false }) : null
                          }
                          helperText={cityError ? "Please enter a city" : null}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="state"
                          name="state"
                          autoComplete="off"
                          onChange={this.onChange("state")}
                          value={state}
                          fullWidth
                          placeholder="State"
                          select
                          SelectProps={{}}
                          onBlur={() =>
                            stateError && state ? this.setState({ stateError: false }) : null
                          }
                          helperText={stateError ? "Please select a state" : null}
                        >
                          <MenuItem disabled value={""}>
                            Select a state
                          </MenuItem>
                          {Object.keys(STATES).map((key) => {
                            return (
                              <MenuItem key={key} value={key}>
                                {STATES[key]}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="zip"
                          name="zip"
                          autoComplete="off"
                          onChange={this.onNumberChange("zip")}
                          inputProps={{ pattern: "[0-9]*", maxLength: 5 }}
                          value={zip}
                          fullWidth
                          placeholder="ZIP Code"
                          onBlur={() =>
                            zipError && zip && zip.trim().length === 5
                              ? this.setState({ zipError: false })
                              : null
                          }
                          helperText={zipError ? "Please enter a valid ZIP Code" : null}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="phone"
                          name="phone"
                          autoComplete="off"
                          onChange={this.onChangePhone("phone")}
                          value={phone}
                          fullWidth
                          placeholder="Phone Number"
                          onBlur={() =>
                            phoneError && phone && phone.trim().match(/\d/g).length === 10
                              ? this.setState({ phoneError: false })
                              : null
                          }
                          helperText={phoneError ? "Please enter a valid phone number" : null}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {editEnabled && (
                    <div className={classes.saveButtonContainer}>
                      <LoadingButton
                        variant={isDirty ? "contained" : "outlined"}
                        disabled={!isDirty}
                        onClick={this.onClickSave}
                        loading={updateLoading}
                      >
                        Save Changes
                      </LoadingButton>
                    </div>
                  )}
                </>
              ) : (
                <Grid container justifyContent="center" alignItems="flex-start" spacing={6}>
                  <Grid container item xs={12} sm={6} spacing={2}>
                    <Grid item xs={12}>
                      <Typography component="p" className={classes.label}>
                        Insurance Company
                      </Typography>
                      <Typography component="p" className={classes.info}>
                        {insurancePlan?.insurance_name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography component="p" className={classes.label}>
                        Name of Plan
                      </Typography>
                      <Typography component="p" className={classes.info}>
                        {name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} style={{ marginBottom: 5 }}>
                      <Typography component="p" className={classes.label}>
                        Medicaid Plan?
                      </Typography>
                      <Typography component="p" className={classes.info}>
                        {isMedicaid ? "Yes" : "No"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Typography component="p" className={classes.label}>
                        Location Modifier
                      </Typography>
                      <Typography component="p" className={classes.info}>
                        {locationModifier}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography component="p" className={classes.label}>
                        Provider Modifier
                      </Typography>
                      <Typography component="p" className={classes.info}>
                        {providerModifier}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Typography component="p" className={classes.label}>
                        Telehealth Modifier
                      </Typography>
                      <Typography component="p" className={classes.info}>
                        {telehealthModifier}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Typography component="p" className={classes.label}>
                        Payer ID
                      </Typography>
                      <Typography component="p" className={classes.info}>
                        {payerId}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Typography component="p" className={classes.label}>
                        Coverage State
                      </Typography>
                      <Typography component="p" className={classes.info}>
                        {COVERAGE_STATES[coverageState]}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container item xs={12} sm={6} spacing={2}>
                    <Grid item xs={12}>
                      <Typography component="p" className={classes.label}>
                        Plan Address
                      </Typography>
                      <Typography component="p" className={classes.info}>
                        {address1} {address2 || ""}
                      </Typography>
                      <Typography component="p" className={classes.info}>
                        {`${city}, ${state} ${zip}`}
                      </Typography>
                      <Typography component="p" className={classes.info}>
                        {phone}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </div>
          </SectionContainer>
        </PageContainer>
        {successToastOpen && (
          <div className={classes.snackbarContainer}>
            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={successToastOpen}
              classes={{ anchorOriginTopCenter: classes.snackbar }}
              onClose={() => this.setState({ successToastOpen: false })}
            >
              <Alert elevation={6} variant="outlined" severity="success">
                <Typography
                  component="p"
                  style={{ fontSize: 16, fontWeight: 500, color: "#047857" }}
                >
                  Insurance plan has been updated
                </Typography>
              </Alert>
            </Snackbar>
          </div>
        )}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(InsurancePlan));
