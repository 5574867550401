export const styles = (theme) => ({
  popperDisablePortal: {
    top: 35,
    left: 10,
  },
  searchBarContainer: {
    position: "relative",
    width: "auto",
    display: "flex",
    alignItems: "center",
  },
  searchBar: {
    border: "1px solid #CDD3DA",
    boxShadow: "0px 0px 2px 1px rgba(223,228,232,0.9)",
    display: "flex",
    alignItems: "center",
    position: "relative",
    transition: "width 450ms ease-in-out",
    borderRadius: 6,
    height: 40,
    width: 250,
    maxWidth: "80%",
    [theme.breakpoints.down("sm")]: {
      width: 55,
      paddingRight: 5,
    },
    "&:focus-within": {
      width: 250,
      maxWidth: "80%",
      "& label.Mui-focused": {
        color: "#9c00d5",
      },
      border: "1px solid #9c00d5",
    },
  },
  searchBarInput: {
    background: "transparent",
    flexGrow: 1,
    outline: 0,
    position: "absolute",
    maxWidth: "100%",
    // paddingLeft: 35,
    padding: "8px 12px",
    cursor: "pointer",
    borderRadius: "6px",
    "& .MuiInputBase-root": {
      fontSize: 14,
      color: "#15171D",
      fontWeight: "500",
    },
    "& fieldset": {
      border: "none",
      // borderRadius: 30,
    },
    "& .MuiOutlinedInput-root": {
      paddingTop: "4px !important",
      boxShadow: "none",
      minHeight: 0,
      "&:hover fieldset": {
        border: "none",
      },
      "&.Mui-focused fieldset": {
        border: "none",
      },
    },
    "& .MuiIconButton-label": {
      color: "#15171D",
    },
  },
  searchBarIcon: {
    marginRight: "auto",
    width: 55,
    height: 35,
    cursor: "pointer",
    // color: "#4876A0",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  deactivatedIcon: {
    color: "#dc2626",
    margin: "3px 10px",
    fontSize: 15,
  },
  activeIcon: {
    color: "#059669",
    margin: "3px 10px",
    fontSize: 15,
  },
  searchOptionsContainer: {
    width: "fit-content",
    display: "flex",
    flexDirection: "column",
    padding: 10,
  },
  searchOptions: {
    display: "flex",
    flexDirection: "column",
    width: "fit-content",
    margin: "0 0 10px",
  },
  filters: {
    minWidth: 300,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
    marginBottom: 8,
    justifyContent: "flex-start",

    "& .MuiFormControlLabel-root": {
      margin: "0 0 0 -5px",
    },
  },
  labelContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  label: {
    fontSize: "0.9rem",
    color: "#3f4456",
    fontWeight: "500",
    display: "inline-block",
    letterSpacing: "0.15px",
    marginLeft: 6,
    marginRight: 6,
  },
  listOptions: {
    marginLeft: -10,
    marginRight: -10,

    "& .MuiAutocomplete-option": {
      paddingLeft: 11,
    },
  },
  switchRoot: {
    "& .MuiSwitch-track": {
      // backgroundColor: "#c3c6d2",
      opacity: 1,
    },
    "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#bb8edf",
      opacity: 1,
    },
  },
  divider: {
    width: "100%",
    height: 1,
    // backgroundColor: "#E1E3E9",
    borderRadius: 0.5,
  },
  popper: {
    width: "fit-content",
  },
  paper: {
    width: "fit-content",
    border: "1px solid #CDD3DA",
  },
  checkBoxLabel: {
    fontSize: 12,
  },
});
