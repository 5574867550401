export default (theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  logo: {
    width: 150,
    height: "auto",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(),
    top: theme.spacing(),
    color: theme.palette.grey[500],
  },
  dialogTitle: {
    "& h2": {
      [theme.breakpoints.down("xl")]: {
        fontSize: 16,
      },
    },
  },
  dialogContent: {
    "& p": {
      [theme.breakpoints.down("xl")]: {
        fontSize: 15,
      },
    },
  },
});
