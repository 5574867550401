import React from "react";
import { Typography, Checkbox, FormControlLabel, Button, Alert } from "@mui/material";
import SignatureCanvas from "react-signature-canvas";

const Signature = (props) => {
  const {
    classes,
    isVerified,
    signatureError,
    verifyError,
    setError,
    onRadioButtonChange,
    signatureCanvasRef,
    signatureRef,
    setSignatureStarted,
    signatureStarted,
    clearSignatureCanvas,
    signatureUrl,
    locked,
    viewOnly,
    submitLoading,
    associatedTimesDraftSaving,
    associatedTimesSubmitSaving,
  } = props;

  return (
    <div className={classes.signatureContainer} ref={signatureCanvasRef}>
      <div className={classes.signatureTitleContainer}>
        <Typography variant="h4">Signature</Typography>
        {!locked && !viewOnly && (
          <Button
            size="medium"
            color="secondary"
            onClick={clearSignatureCanvas}
            disabled={submitLoading || associatedTimesDraftSaving || associatedTimesSubmitSaving}
          >
            Clear
          </Button>
        )}
      </div>
      <div
        className={
          locked || viewOnly
            ? classes.disabledSignatureCanvasContainer
            : classes.signatureCanvasContainer
        }
      >
        {locked || viewOnly ? (
          <div
            className={classes.signatureImageContainer}
            style={{ backgroundColor: "transparent" }}
          >
            <img className={classes.signatureImage} src={signatureUrl} />
          </div>
        ) : (
          <div className={classes.signatureCanvas}>
            <SignatureCanvas
              ref={signatureRef}
              onBegin={() => {
                setSignatureStarted(true);
                setError(signatureError, false);
              }}
              penColor="#000"
              canvasProps={{
                width: 1200,
                height: 130,
              }}
            />
          </div>
        )}
        {signatureError && !signatureStarted && (
          <Typography component="p" className={classes.helperText}>
            Draw your signature inside the box above
          </Typography>
        )}
        <Typography
          component="p"
          style={{
            fontSize: 12,
            color: "#C0C8D0",
            lineHeight: "166%",
            letterSpacing: "0.4px",
          }}
        >
          First Name, Last Name, and Title
        </Typography>
      </div>
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            checked={isVerified}
            onChange={onRadioButtonChange("isVerified")}
            disabled={locked || viewOnly}
          />
        }
        label={
          <Typography
            component="p"
            variant="body2"
            // style={{ color: "rgba(0, 0, 0, 0.38)" }}
          >
            By signing this note, I verify that the time I indicated above accurately reflects the
            services rendered. If it's determined that the information in this is inaccurate, I will
            be held liable and assume responsibility.
          </Typography>
        }
      />
      {verifyError && (
        <Typography component="p" className={classes.helperText} style={{ marginTop: "-15px" }}>
          Check to verify accuracy of content
        </Typography>
      )}
    </div>
  );
};

export default Signature;
