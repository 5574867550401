import React from "react";
import withStyles from "@mui/styles/withStyles";
import styles from "../styles";
import Typography from "@mui/material/Typography";
import { format } from "date-fns";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DetailAccordion from "elements/DetailAccordion";

import { convertTokens } from "@date-fns/upgrade/v2";

const EmailLog = (props) => {
  const { classes, sentEmailList, sentEmailsLoading } = props;
  return (
    <DetailAccordion
      title="Email Log"
      enableScroll={true}
      maxHeight={700}
      loading={sentEmailsLoading}
    >
      {sentEmailList.length > 0 && !sentEmailsLoading ? (
        <Table className={classes.clientDetailsTable}>
          <TableHead>
            <TableRow>
              <TableCell width="30%">Email Address & Subject Line</TableCell>
              <TableCell width="15%">Status</TableCell>
              <TableCell width="15%">Times Email was Opened</TableCell>
              <TableCell width="15%">Number links clicked</TableCell>
              <TableCell width="25%">Last Activity</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sentEmailList.map((email, index) => {
              return (
                <TableRow
                  key={email.msg_id}
                  style={index % 2 === 0 ? { backgroundColor: "#ECFAFA" } : null}
                >
                  <TableCell className={classes.emailFirstRow}>
                    {email.from_email} <br />
                    {email.subject}
                  </TableCell>
                  <TableCell className={classes.emailRow} style={{ textTransform: "capitalize" }}>
                    {email.status}
                  </TableCell>
                  <TableCell className={classes.emailRow}>{email.opens_count}</TableCell>
                  <TableCell className={classes.emailRow}>{email.clicks_count}</TableCell>
                  <TableCell className={classes.emailRow}>
                    {email.last_event_time
                      ? format(new Date(email.last_event_time), convertTokens("MMM D, YYYY h:mm a"))
                      : ""}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      ) : sentEmailList.length === 0 ? (
        <Typography className={classes.notFound}>No emails found for this client</Typography>
      ) : null}
    </DetailAccordion>
  );
};
export default withStyles(styles)(EmailLog);
