import React from "react";
import { Typography, Button, Alert } from "@mui/material";
import { WarningCircle } from "@phosphor-icons/react";
import Service from "./Service";

const EditServiceDetails = (props) => {
  const {
    classes,
    insuranceCodes,
    clinicianName,
    parentName,
    clientName,
    otherClinicianName,
    setError,
    serviceDetailsRef,
    associatedSessionNotes,
    onChangeAssociatedServiceType,
    onChangeAssociatedDuration,
    onAddAssociatedServiceType,
    viewOnly,
    totalCallTime,
    durationError,
    onToggleConfirmDeleteDialog,
  } = props;

  let participantsOptions = [clinicianName, clientName, parentName, "Other"];
  if (otherClinicianName) {
    participantsOptions.splice(3, 0, otherClinicianName);
  }
  const clientInsuranceCodes = insuranceCodes.filter(
    (ic) => !ic.description.includes("Tech") && !ic.description.includes("Nonbillable Time"),
  );
  const timeCoded = associatedSessionNotes.reduce((a, c) => {
    return c.actual_time ? a + parseInt(c.actual_time) : a;
  }, 0);
  return (
    <div className={classes.editServiceDetails} ref={serviceDetailsRef}>
      <Typography component="p" variant="body2">
        Please select the services and duration of each provided on this call
      </Typography>

      {associatedSessionNotes?.map((note, i) => {
        return (
          <Service
            key={i}
            index={i}
            classes={classes}
            serviceType={note}
            duration={parseInt(note.actual_time)}
            onChangeServiceType={(e) => onChangeAssociatedServiceType(e, i)}
            onChangeMinutes={(e) => onChangeAssociatedDuration(e, i)}
            setError={setError}
            clientInsuranceCodes={clientInsuranceCodes}
            insuranceCodes={insuranceCodes}
            maxDuration={totalCallTime - (timeCoded - parseInt(note.actual_time))}
            onClickDelete={() => onToggleConfirmDeleteDialog(i)}
          />
        );
      })}
      <div className={classes.timeRemaining}>
        <Typography
          component="p"
          variant={timeCoded === totalCallTime ? "subtitle2Success" : "subtitle2Error"}
        >
          {totalCallTime - timeCoded}
        </Typography>
        <Typography component="p" variant="body2Secondary">
          / {totalCallTime} minutes remaining
        </Typography>
      </div>
      {durationError && (
        <Alert
          sx={{ width: 395 }}
          severity="error"
          icon={<WarningCircle size={22} color="rgba(211, 47, 47, 1)" />}
        >
          All service minutes must equal the call duration before submission
        </Alert>
      )}
      <Button
        size="medium"
        color="secondary"
        onClick={() => onAddAssociatedServiceType()}
        disabled={viewOnly && timeCoded === totalCallTime}
        style={{ width: 395, backgroundColor: "#fff" }}
      >
        Add Service
      </Button>
    </div>
  );
};

export default EditServiceDetails;
