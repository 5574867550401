import React from "react";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import CriticalIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/ReportProblem";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import { StyledInput } from "./StyledInput";
import { differenceInDays } from "date-fns";
import { taskMap } from "./taskMap";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import MenuItem from "@mui/material/MenuItem";

export const CriticalNeedsDialogContent = (props) => {
  const {
    // handlers
    setSnoozeLength,
    onSubmitSnoozeTask,
    setSnoozeItem,
    setSnoozeReason,
    // values
    classes,
    criticalClients,
    cancelSnooze,
    snoozeReason,
    snoozeId,
    snoozeLength,
    error,
    warningClients,
    snoozeTaskLoading,
    history,
  } = props;

  return (
    <DialogContent className={classes.dialogContent}>
      {criticalClients.length > 0 && (
        <div className={classes.needsContainer}>
          <div className={classes.titleContainer}>
            <CriticalIcon
              style={{
                fill: "#B91C1C",
                fontSize: 22,
                marginRight: 10,
              }}
            />
            <Typography component="h4">Critical Needs</Typography>
            <span>-</span>

            <Typography component="p">
              Select the client you would like to see more information for.
            </Typography>
          </div>
          <Table className={classes.criticalClientsTable} classes={{ root: classes.tableRoot }}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.criticalTableColumnTitle} align="left" width="30%">
                  Client
                </TableCell>
                <TableCell className={classes.criticalTableColumnTitle} align="left" width="30%">
                  Current Step
                </TableCell>
                <TableCell className={classes.criticalTableColumnTitle} align="center" width="20%">
                  Time in Current Step
                </TableCell>
                <TableCell className={classes.criticalTableColumnTitle} align="center" width="20%">
                  Snooze Alert
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {criticalClients.map((client, index) => (
                <React.Fragment key={client.id}>
                  {snoozeId && snoozeId == client.id ? (
                    <TableCell
                      align="left"
                      colSpan={4}
                      classes={{ root: classes.snoozeTableCellRoot }}
                    >
                      <Table
                        className={classes.snoozeFormTable}
                        classes={{ root: classes.snoozeTableRoot }}
                      >
                        <TableBody>
                          <TableRow key={client.client_id}>
                            <TableCell align="left" width="30%">
                              <Button
                                variant="text"
                                size="large"
                                className={classes.clientNameButton}
                                onClick={() =>
                                  history.push(`/clients/${client.client_id}/dashboard`)
                                }
                              >
                                {client.name}
                              </Button>
                            </TableCell>
                            <TableCell
                              align="left"
                              width="30%"
                              style={{
                                color: "#3F4456",
                                fontSize: 14,
                                fontWeight: "500",
                                paddingLeft: 11,
                              }}
                            >
                              {taskMap[client.task]}
                            </TableCell>
                            <TableCell
                              align="center"
                              width="21%"
                              style={{
                                color: "#B91C1C",
                                fontSize: 16,
                                fontWeight: "500",
                                paddingLeft: 21,
                              }}
                            >
                              {`${differenceInDays(new Date(), new Date(client.start_date))} Days`}
                            </TableCell>
                            <TableCell
                              align="center"
                              width="19%"
                              classes={{
                                root: classes.lengthOfTimeSelect,
                              }}
                            >
                              <StyledInput
                                id="snoozeLength"
                                value={snoozeLength}
                                onChange={(e) => setSnoozeLength(e.target.value)}
                                size="small"
                                fullWidth
                                select
                                variant="outlined"
                              >
                                <MenuItem disabled value={0}>
                                  Length of Time
                                </MenuItem>

                                <MenuItem value={1}>1 day</MenuItem>
                                <MenuItem value={2}>2 days</MenuItem>
                                <MenuItem value={3}>3 days</MenuItem>
                                <MenuItem value={4}>4 days</MenuItem>
                                <MenuItem value={5}>5 days</MenuItem>
                                <MenuItem value={6}>6 days</MenuItem>
                                <MenuItem value={7}>7 days</MenuItem>
                              </StyledInput>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="left" colSpan={4}>
                              <Grid container item xs={12} spacing={2} style={{ margin: 0 }}>
                                <Grid
                                  item
                                  xs={8}
                                  classes={{
                                    root: classes.snoozeReasonGrid,
                                  }}
                                >
                                  <FormControl margin="none" fullWidth>
                                    <FormLabel
                                      component="legend"
                                      style={{
                                        marginBottom: 5,
                                        fontSize: 13,
                                        fontWeight: "500",
                                        color: "#3F4456",
                                        lineHeight: "20px",
                                      }}
                                    >
                                      Please provide a few words for additional context.
                                    </FormLabel>
                                    <StyledInput
                                      id="snoozeReason"
                                      name="snoozeReason"
                                      autoComplete="off"
                                      value={snoozeReason}
                                      onChange={(e) => setSnoozeReason(e.target.value)}
                                      multiline={true}
                                      variant="outlined"
                                      fullWidth
                                      rows={8}
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                  <div className={classes.snoozeButtonContainer}>
                                    <LoadingButton
                                      className={classes.snoozeButton}
                                      loading={snoozeTaskLoading}
                                      disabled={!snoozeReason || !snoozeLength}
                                      onClick={onSubmitSnoozeTask}
                                    >
                                      SNOOZE
                                    </LoadingButton>
                                    <Button
                                      variant="text"
                                      color="secondary"
                                      onClick={cancelSnooze}
                                      className={classes.snoozeButton}
                                      disabled={snoozeTaskLoading}
                                    >
                                      Cancel
                                    </Button>
                                  </div>
                                </Grid>
                              </Grid>
                              {error && (
                                <Typography component="p" className={classes.errorText}>
                                  An error has occurred. Please try again.
                                </Typography>
                              )}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableCell>
                  ) : (
                    <TableRow key={client.client_id}>
                      <TableCell align="left">
                        <Button
                          variant="text"
                          size="large"
                          className={classes.clientNameButton}
                          onClick={() => history.push(`/clients/${client.client_id}/dashboard`)}
                        >
                          {client.name}
                        </Button>
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          color: "#3F4456",
                          fontSize: 14,
                          fontWeight: "500",
                        }}
                      >
                        {taskMap[client.task]}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          color: "#B91C1C",
                          fontSize: 16,
                          fontWeight: "500",
                        }}
                      >
                        {`${differenceInDays(new Date(), new Date(client.start_date))} Days`}
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          variant="text"
                          size="small"
                          onClick={() => setSnoozeItem(client, "critical")}
                        >
                          Snooze
                        </Button>
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
      {warningClients.length > 0 && (
        <div className={classes.needsContainer}>
          <div className={classes.titleContainer}>
            <WarningIcon
              style={{
                fill: "#D97706",
                fontSize: 22,
                marginRight: 10,
              }}
            />
            <Typography component="h4">Moderate Needs</Typography>
            <span>-</span>
            <Typography component="p">
              Select the client you would like to see more information for.
            </Typography>
          </div>
          <Table className={classes.warningClientsTable} classes={{ root: classes.tableRoot }}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.warningTableColumnTitle} align="left" width="30%">
                  Client
                </TableCell>
                <TableCell className={classes.warningTableColumnTitle} align="left" width="30%">
                  Current Step
                </TableCell>
                <TableCell className={classes.warningTableColumnTitle} align="center" width="20%">
                  Time in Current Step
                </TableCell>
                <TableCell className={classes.warningTableColumnTitle} align="center" width="20%">
                  Snooze Alert
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {warningClients.map((client, index) => (
                <React.Fragment key={client.id}>
                  {snoozeId && snoozeId == client.id ? (
                    <TableCell
                      align="left"
                      colSpan={4}
                      classes={{ root: classes.snoozeTableCellRoot }}
                    >
                      <Table
                        className={classes.snoozeFormTable}
                        classes={{ root: classes.snoozeTableRoot }}
                      >
                        <TableBody>
                          <TableRow>
                            <TableCell align="left" width="30%">
                              <Button
                                variant="text"
                                size="large"
                                className={classes.clientNameButton}
                                onClick={() =>
                                  history.push(`/clients/${client.client_id}/dashboard`)
                                }
                              >
                                {client.name}
                              </Button>
                            </TableCell>
                            <TableCell
                              align="left"
                              width="30%"
                              style={{
                                color: "#3F4456",
                                fontSize: 14,
                                fontWeight: "500",
                                paddingLeft: 11,
                              }}
                            >
                              {taskMap[client.task]}
                            </TableCell>
                            <TableCell
                              align="center"
                              width="21%"
                              style={{
                                color: "#3F4456",
                                fontSize: 16,
                                fontWeight: "500",
                                paddingLeft: 21,
                              }}
                            >
                              {`${differenceInDays(new Date(), new Date(client.start_date))} Days`}
                            </TableCell>
                            <TableCell
                              align="center"
                              width="19%"
                              classes={{
                                root: classes.lengthOfTimeSelect,
                              }}
                            >
                              <StyledInput
                                id="snoozeLength"
                                value={snoozeLength}
                                onChange={(e) => setSnoozeLength(e.target.value)}
                                size="small"
                                fullWidth
                                select
                                variant="outlined"
                              >
                                <MenuItem disabled value={0}>
                                  Length of Time
                                </MenuItem>

                                <MenuItem value={1}>1 day</MenuItem>
                                <MenuItem value={2}>2 days</MenuItem>
                                <MenuItem value={3}>3 days</MenuItem>
                                <MenuItem value={4}>4 days</MenuItem>
                                <MenuItem value={5}>5 days</MenuItem>
                                <MenuItem value={6}>6 days</MenuItem>
                                <MenuItem value={7}>7 days</MenuItem>
                              </StyledInput>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="left" colSpan={4}>
                              <Grid container item xs={12} spacing={2} style={{ margin: 0 }}>
                                <Grid
                                  item
                                  xs={8}
                                  classes={{
                                    root: classes.snoozeReasonGrid,
                                  }}
                                >
                                  <FormControl margin="none" fullWidth>
                                    <FormLabel
                                      component="legend"
                                      style={{
                                        marginBottom: 5,
                                        fontSize: 13,
                                        fontWeight: "500",
                                        color: "#3F4456",
                                        lineHeight: "20px",
                                      }}
                                    >
                                      Please provide a few words for additional context.
                                    </FormLabel>
                                    <StyledInput
                                      id="snoozeReason"
                                      name="snoozeReason"
                                      autoComplete="off"
                                      value={snoozeReason}
                                      onChange={(e) => setSnoozeReason(e.target.value)}
                                      multiline={true}
                                      variant="outlined"
                                      fullWidth
                                      rows={8}
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                  <div className={classes.snoozeButtonContainer}>
                                    <LoadingButton
                                      className={classes.snoozeButton}
                                      loading={snoozeTaskLoading}
                                      disabled={!snoozeReason || !snoozeLength}
                                      onClick={onSubmitSnoozeTask}
                                    >
                                      SNOOZE
                                    </LoadingButton>
                                    <Button
                                      variant="text"
                                      color="secondary"
                                      onClick={cancelSnooze}
                                      className={classes.snoozeButton}
                                      disabled={snoozeTaskLoading}
                                    >
                                      Cancel
                                    </Button>
                                  </div>
                                </Grid>
                              </Grid>
                              {error && (
                                <Typography component="p" className={classes.errorText}>
                                  An error has occurred. Please try again.
                                </Typography>
                              )}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableCell>
                  ) : (
                    <TableRow key={client.client_id}>
                      <TableCell align="left">
                        <Button
                          variant="text"
                          size="large"
                          className={classes.clientNameButton}
                          onClick={() => history.push(`/clients/${client.client_id}/dashboard`)}
                        >
                          {client.name}
                        </Button>
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          color: "#3F4456",
                          fontSize: 14,
                          fontWeight: "500",
                        }}
                      >
                        {taskMap[client.task]}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          color: "#3F4456",
                          fontSize: 16,
                          fontWeight: "500",
                        }}
                      >
                        {`${differenceInDays(new Date(), new Date(client.start_date))} Days`}
                      </TableCell>

                      <TableCell align="center">
                        <Button
                          variant="text"
                          size="small"
                          onClick={() => setSnoozeItem(client, "warning")}
                        >
                          Snooze
                        </Button>
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
    </DialogContent>
  );
};
