import React from "react";
import Modal from "elements/Modal";

const InterviewApplicantDialog = (props) => {
  const { interviewApplicantOpen, onSubmitInterviewApplicant, handleToggleDialog } = props;
  return (
    <Modal
      title="Request Interview"
      open={interviewApplicantOpen}
      onClose={handleToggleDialog("interviewApplicantOpen")}
      description="Please confirm that you would like to request an interview with this applicant."
      primaryActionText="Submit"
      primaryActionOnClick={onSubmitInterviewApplicant}
      secondaryActionText="Cancel"
      secondaryActionOnClick={handleToggleDialog("interviewApplicantOpen")}
    />
  );
};

export default InterviewApplicantDialog;
