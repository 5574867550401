import { call, put, all, select, takeEvery } from "redux-saga/effects";
import actions from "../actions";
import {
  approveBillableTime,
  deleteBillableTime,
  editAssociatedBillableTimes,
  getBillableItemById,
  getBillingItems,
  holdBillableTime,
  releaseBillableTime,
  requestBillableTimeEdits,
  revertToInProgress,
} from "../api/billing";
import { sendSessionNoteForVideo } from "../api/video";

function* getBillingItemsAsync({ payload }) {
  try {
    const result = yield call(getBillingItems, payload);
    yield put(actions.getBillingItemsSuccess(result.data));
  } catch (e) {
    yield put(actions.getBillingItemsFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* setAssociatedBillableTimesAsync({ payload }) {
  try {
    yield call(editAssociatedBillableTimes, payload.billableTimes);
    const result = yield call(getBillableItemById, payload);
    yield put(actions.getBillableItemByIdSuccess(result.data));
    yield put(actions.setAssociatedBillableTimeSuccess());
  } catch (e) {
    yield put(actions.setAssociatedBillableTimeFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* approveBillableTimeAsync({ payload }) {
  try {
    yield call(approveBillableTime, payload);
    yield put(actions.approveBillableTimeSuccess());
  } catch (e) {
    yield put(actions.approveBillableTimeFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* requestBillableTimeEditsAsync({ payload }) {
  try {
    yield call(requestBillableTimeEdits, payload);
    yield put(actions.requestBillableTimeEditsSuccess());
  } catch (e) {
    yield put(actions.requestBillableTimeEditsFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* holdBillableTimeAsync({ payload }) {
  try {
    yield call(holdBillableTime, payload);
    yield put(actions.holdBillableTimeSuccess());
  } catch (e) {
    yield put(actions.holdBillableTimeFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* releaseBillableTimeAsync({ payload }) {
  try {
    yield call(releaseBillableTime, payload);
    yield put(actions.releaseBillableTimeSuccess());
  } catch (e) {
    yield put(actions.releaseBillableTimeFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* sendTimesheetBillableTimeAsync({ payload }) {
  try {
    yield call(sendSessionNoteForVideo, payload);
    yield put(actions.sendTimesheetBillableTimeSuccess());
  } catch (e) {
    yield put(actions.sendTimesheetBillableTimeFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* deleteBillableTimeAsync({ payload }) {
  try {
    const result = yield call(deleteBillableTime, payload.deleteTimeId);
    if (!payload.adhoc) {
      const billableTimeId =
        payload.billableTimeId == payload.deleteTimeId
          ? result.data.billableTimeId
          : payload.billableTimeId;
      const billableItem = yield call(getBillableItemById, { billableTimeId });
      yield put(actions.getBillableItemByIdSuccess(billableItem.data));
    }

    yield put(actions.deleteBillableTimeSuccess(result.data));
  } catch (e) {
    yield put(actions.deleteBillableTimeFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getBillableItemByIdAsync({ payload }) {
  try {
    const result = yield call(getBillableItemById, payload);
    yield put(actions.getBillableItemByIdSuccess(result.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* revertBillableItemToInProgressAsync({ payload }) {
  try {
    yield call(revertToInProgress, payload);
    yield put(actions.revertBillableItemToInProgressSuccess());
  } catch (e) {
    yield put(actions.revertBillableItemToInProgressFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

export default function* startSaga() {
  yield takeEvery(actions.GET_BILLING_ITEMS, getBillingItemsAsync);
  yield takeEvery(actions.SET_ASSOCIATED_BILLABLE_TIMES, setAssociatedBillableTimesAsync);
  yield takeEvery(actions.APPROVE_BILLABLE_TIME, approveBillableTimeAsync);
  yield takeEvery(actions.REQUEST_BILLABLE_TIME_EDITS, requestBillableTimeEditsAsync);
  yield takeEvery(actions.HOLD_BILLABLE_TIME, holdBillableTimeAsync);
  yield takeEvery(actions.RELEASE_BILLABLE_TIME, releaseBillableTimeAsync);
  yield takeEvery(actions.SEND_TIMESHEET_BILLABLE_TIME, sendTimesheetBillableTimeAsync);
  yield takeEvery(actions.DELETE_BILLABLE_TIME, deleteBillableTimeAsync);
  yield takeEvery(actions.GET_BILLABLE_ITEM_BY_ID, getBillableItemByIdAsync);
  yield takeEvery(actions.REVERT_BILLABLE_ITEM_TO_IN_PROGRESS, revertBillableItemToInProgressAsync);
}
