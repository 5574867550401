import React, { Component } from "react";
import { withStyles } from "@mui/styles";
import Autocomplete from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "actions";
import { isSuperAdmin, getUserPermissionsList } from "selectors";
import {
  MagnifyingGlass,
  UserCircle,
  UsersThree,
  UserRectangle,
  IdentificationBadge,
  XSquare,
} from "@phosphor-icons/react";
import { load, save } from "react-cookies";
import { CustomPopper, SearchOptionsList } from "./SearchElements";
import { CHILD, PARENT, CLINICIAN, CLIENT_ID, CLINICIAN_ID, ID } from "./UserTypesConsts";
import { styles } from "./styles";
import NestedPopperOption from "./SearchElements/CustomNestedPopper";
import Tooltip from "@mui/material/Tooltip";
import withCustomerSearch from "./SearchElements/withSearchCustomersHook";
import debounce from "lodash/debounce";

const FILTER_STATE_KEY = "ClientSearch:filterState";

const mapStateToProps = (state) => ({
  isSuperAdmin: isSuperAdmin(state),
  userPermissions: getUserPermissionsList(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearCustomerSearch: actions.clearCustomerSearch,
    },
    dispatch
  );

const initialState = () => {
  const cookieState = load(FILTER_STATE_KEY) || {};
  return {
    parents: true,
    clients: true,
    clinicians: true,
    customerCare: false,
    deactivated: false,
    allUsers: false,
    includeTeam: true,
    ...cookieState,
    search: "",
    focusOn: false,
    rerenderKey: new Date().toISOString(),
  };
};

const TypeUserIcon = ({ type }) => {
  if (type === CHILD) {
    return <UserCircle weight="duotone" />;
  }

  if (type === PARENT) {
    return <UsersThree weight="duotone" />;
  }

  if (type === CLINICIAN) {
    return <IdentificationBadge weight="duotone" />;
  }

  return <UserRectangle weight="duotone" />;
};

class ClientSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState(),
    };
    this.debouncedTriggerSearch = debounce(this.triggerSearch, 300);
  }

  componentDidMount() {
    if (this.props.userPermissions) {
      this.mapPermissionsToFilterState();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { parents, clients, clinicians, deactivated, allUsers, search } = this.state;
    const trimmedSearch = search.toLowerCase().trim();

    // If the search input was cleared, clear the results immediately.
    if (prevState.search !== search && trimmedSearch.length === 0) {
      this.props.clearCustomerSearch();
    }

    // If any filter settings change, trigger a search immediately if the search term is valid.
    if (
      prevState.parents !== parents ||
      prevState.clients !== clients ||
      prevState.clinicians !== clinicians ||
      prevState.deactivated !== deactivated ||
      prevState.allUsers !== allUsers
    ) {
      if (trimmedSearch.length >= 3) {
        this.triggerSearch();
      }
    }

    // Update permissions if they've just been provided.
    if (!prevProps.userPermissions && this.props.userPermissions) {
      this.mapPermissionsToFilterState();
    }

    // Save the current state to cookies.
    const expires = new Date();
    expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 14);
    save(FILTER_STATE_KEY, this.state, { path: "/", expires });
  }

  mapPermissionsToFilterState = () => {
    const { userPermissions } = this.props;
    let filterState = { ...this.state };
    const showClientsAndTeamMembers =
      userPermissions?.view_client_list_by_workflow_state ||
      userPermissions?.search_client_list_by_clinician;

    if (!showClientsAndTeamMembers) {
      filterState = {
        ...filterState,
        parents: false,
        clients: false,
      };
    } else {
      filterState = {
        ...filterState,
        parents: true,
        clients: true,
      };
    }

    if (!userPermissions.view_clinician_list) {
      filterState = {
        ...filterState,
        clinicians: false,
      };
    }

    this.setState({ ...filterState });
  };

  onChange = (e, v) => {
    this.setState({ search: v }, () => {
      this.debouncedTriggerSearch();
    });
  };

  triggerSearch = () => {
    const { search, parents, clients, clinicians, deactivated, allUsers, includeTeam } = this.state;
    const trimmedSearch = search.toLowerCase().trim();

    if (trimmedSearch.length >= 3) {
      const { userPermissions } = this.props;
      // Determine if we should show clients and team members based on user permissions
      const showClientsAndTeamMembers =
        userPermissions?.view_client_list_by_workflow_state ||
        userPermissions?.search_client_list_by_clinician;

      let searchObj;
      if (parents || clients || clinicians || allUsers) {
        searchObj = {
          search: trimmedSearch,
          parents,
          clients,
          clinicians,
          deactivated,
          includeTeam,
          allUsers,
        };
      } else {
        searchObj = {
          search: trimmedSearch,
          parents: showClientsAndTeamMembers,
          clients: showClientsAndTeamMembers,
          clinicians: userPermissions?.view_clinician_list,
          deactivated,
          includeTeam,
          allUsers,
        };
      }
      this.props.getCustomers(searchObj);
    }
  };

  onChangeFilter = (name, val) => {
    if (name === "allUsers") {
      this.setState({
        parents: false,
        clients: false,
        clinicians: false,
        allUsers: val,
      });
    } else {
      if (name !== "deactivated") {
        this.setState({ allUsers: false });
      }
      this.setState({ [name]: val });
    }
  };

  getUserType = (option) => {
    const { search } = this.state;
    let searchQuery = search.trim().toLowerCase();
    let type;

    if (!isNaN(parseInt(search, 10))) {
      if (option.clientId?.includes(searchQuery)) {
        type = CLIENT_ID;
      } else if (option.clinicianId?.includes(searchQuery)) {
        type = CLINICIAN_ID;
      } else {
        type = ID;
      }
    } else {
      if (option.childName && option.childName.toLowerCase()?.includes(searchQuery)) {
        type = CHILD;
      } else if (
        option.name &&
        option.name.toLowerCase()?.includes(searchQuery) &&
        option.customerId
      ) {
        type = PARENT;
      } else if (option.authority) {
        type = this.renderUserRole(option.authority);
      }
    }

    return type;
  };

  renderUserRole = (role) => {
    if (role?.includes("ROLE_SUPER_ADMIN") || role?.includes("ROLE_ADMIN")) {
      return "admin";
    } else if (role?.includes("ROLE_CLINICAL_DIRECTOR")) {
      return "director";
    } else if (role?.includes("ROLE_CLINICAL_SUPERVISOR")) {
      return "supervisor";
    } else if (role?.includes("ROLE_CUSTOMER_CARE")) {
      return "customercare";
    } else if (role?.includes("ROLE_CLINICIAN_VIEW_ONLY")) {
      return "clinicianviewonly";
    } else if (role?.includes("ROLE_CLINICIAN")) {
      return "clinician";
    } else if (role?.includes("ROLE_PARENT") && (this.state.parents || this.state.allUsers)) {
      return "parent";
    } else if (role?.includes("ROLE_PARENT") && this.state.clients) {
      return "child";
    } else if (role?.includes("ROLE_FAMILY")) {
      return "familymember";
    } else {
      return "user";
    }
  };

  renderOptionLabel = (option) => {
    let type = this.getUserType(option);

    // Check if name is null, undefined, or consists of white spaces
    const username = option.name && option.name.trim() !== "" ? option.name : option.email;
    switch (type) {
      case "admin":
        return {
          role: "Admin",
          username: username,
        };
      case "director":
        return {
          role: "Director",
          username: username,
        };
      case "supervisor":
        return {
          role: "Supervisor",
          username: username,
        };
      case "customercare":
        return {
          role: "Customer Care",
          username: username,
        };
      case "clinicianviewonly":
        return {
          role: "Clinician View Only",
          username: username,
        };
      case "clinician":
        return {
          role: "Clinician",
          username: username,
        };
      case "familymember":
        return {
          role: "Family Member",
          username: username,
        };
      case "child":
        return {
          role: "Child",
          username: option.childName || username, // Use childName if it exists, otherwise fallback to username
        };
      case "parent":
        return {
          role: "Parent",
          username: username,
        };
      case "user":
        return {
          role: "User",
          username: username,
        };
      case "clientId":
        return {
          role: "Client",
          username: option.childName || username,
        };
      case "clinicianId":
        return {
          role: "Clinician",
          username: username,
        };
      case "id":
        return {
          role: "User",
          username: username,
        };
      default:
        return null;
    }
  };

  onSelectOption = async (event, value, isTeamMember) => {
    if (this.props.customerSearchResults.length > 0) {
      const allUsers = this.state.allUsers;
      this.setState({
        ...initialState(),
        rerenderKey: new Date().toISOString(),
      });
      await this.props.clearCustomerSearch();
      this.props.onSelectSearchOption(event, value, allUsers, isTeamMember);
    }
  };

  toggleUserSearch = () => {
    this.setState({ allUsers: !this.state.allUsers });
  };

  setFocus = () => {
    if (!this.state.focusOn) {
      this.setState({ focusOn: true });
    }
  };

  render() {
    const { classes } = this.props;

    const { search, focusOn, rerenderKey, allUsers } = this.state;

    return (
      <>
        <div className={classes.searchBarContainer}>
          <div className={classes.searchBar} onClick={this.setFocus}>
            <Autocomplete
              onClose={this.props.clearSearchResults}
              key={rerenderKey}
              name="CustomerSearch"
              id="customerSearchAutocomplete"
              onInputChange={(e, v) => {
                this.onChange(e, v);
              }}
              inputValue={search}
              options={this.props.customerSearchResults ?? []}
              freeSolo
              filterOptions={(x) => x}
              getOptionLabel={(option) => this.renderOptionLabel(option)?.username}
              renderOption={(props, option) => {
                const label = this.renderOptionLabel(option);
                const typeUser = this.getUserType(option);
                const isTeamMember = option.teamMembers?.length > 1;
                const teamMembers = option.teamMembers;

                // Check if clientId exists or children have a clientId, or if allUsers is true
                const hasValidClientId =
                  option?.clientId || (option?.children && option?.children[0]?.clientId);

                const hasClinicianId = option?.clinicianId;

                const isDisabled = !hasValidClientId && !hasClinicianId && !allUsers;

                return (
                  <section
                    {...props}
                    key={`${props.id}-${option?.clientId || option?.clinicianId}`}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      opacity: isDisabled ? 0.5 : 1, // To visually indicate disabled options
                      cursor: isDisabled ? "not-allowed" : "pointer",
                    }}
                  >
                    {isTeamMember && typeUser !== CHILD ? (
                      <NestedPopperOption
                        classes={classes}
                        onClick={this.onSelectOption}
                        label={
                          <>
                            <span>
                              {!option.isActive ? (
                                <XSquare weight="duotone" />
                              ) : (
                                <TypeUserIcon type={typeUser} />
                              )}
                              <span className={classes.label}>{label.username}</span>
                            </span>
                            {allUsers && (
                              <Typography variant="caption" component="small">
                                {label.role}
                              </Typography>
                            )}
                          </>
                        }
                        parentOption={option}
                        subOptions={teamMembers}
                      />
                    ) : (
                      <>
                        <span>
                          {!option.isActive ? (
                            <XSquare weight="duotone" />
                          ) : (
                            <TypeUserIcon type={typeUser} />
                          )}
                          <Tooltip
                            title={isDisabled ? "Not associated with a client team" : ""}
                            placement="right"
                          >
                            <span className={classes.label}>{label.username}</span>
                          </Tooltip>
                        </span>
                        {allUsers && (
                          <Typography variant="caption" component="small">
                            {label.role}
                          </Typography>
                        )}
                      </>
                    )}
                  </section>
                );
              }}
              PaperComponent={({ children }) => (
                <Paper
                  elevation={8}
                  classes={{ root: classes.paper }}
                  onMouseDown={(e) => e.preventDefault()}
                >
                  <SearchOptionsList
                    onChangeFilter={this.onChangeFilter}
                    {...this.state}
                    {...this.props}
                  >
                    {children}
                  </SearchOptionsList>
                </Paper>
              )}
              fullWidth
              onChange={(event, value) => this.onSelectOption(event, value)}
              autoHighlight={true}
              disablePortal
              loading={this.props.customerSearchLoading ?? false}
              clearOnBlur
              blurOnSelect
              openOnFocus={true}
              classes={{
                popperDisablePortal: classes.popperDisablePortal,
                root: classes.searchBarInput,
                loading: classes.label,
              }}
              PopperComponent={CustomPopper}
              renderInput={(params) => (
                <TextField
                  size="small"
                  {...params}
                  onBlur={() => {
                    this.setState({ ...initialState() });
                    this.props.clearCustomerSearch();
                  }}
                  placeholder={"Search"}
                  inputRef={(input) => {
                    if (input != null && focusOn) {
                      input.focus();
                    }
                  }}
                  sx={{ height: 38 }}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: <MagnifyingGlass size={20} color="rgba(0, 0, 0, 0.56)" />,
                  }}
                />
              )}
            />
          </div>
        </div>
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withCustomerSearch(ClientSearch)));
