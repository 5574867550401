import React, { useEffect, useState } from "react";
import withStyles from "@mui/styles/withStyles";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { addDays } from "date-fns";
import { styles } from "./styles";
import { CriticalNeedsDialogContent } from "./DialogContent";

const CriticalNeedsDialog = (props) => {
  const [snoozeId, setSnoozeId] = useState(null);
  const [snoozeCount, setSnoozeCount] = useState(null);
  const [snoozeType, setSnoozeType] = useState(null); // type is 'warning' or 'critical'
  const [snoozeReason, setSnoozeReason] = useState("");
  const [snoozeLength, setSnoozeLength] = useState(0);
  const [error, setError] = useState(false);

  const {
    classes,
    open,
    closeDialog,
    snoozeTaskLoading,
    snoozeTaskError,
    snoozeClientTask,
    userId,
    criticalClients,
    warningClients,
    history,
  } = props;

  useEffect(() => {
    if (!snoozeTaskLoading && !snoozeTaskError) {
      cancelSnooze();
    } else if (!snoozeTaskLoading && snoozeTaskError) {
      setError(true);
    }
  }, [snoozeTaskLoading, snoozeTaskError]);

  const setSnoozeItem = (task, type) => {
    setSnoozeId(task.id);
    setSnoozeCount(task.snooze_count || 0);
    setSnoozeType(type);
    setError(false);
  };

  const cancelSnooze = () => {
    setSnoozeId(null);
    setSnoozeType(null);
    setSnoozeCount(null);
    setSnoozeReason("");
    setSnoozeLength(0);
    setError(false);
  };

  const onSubmitSnoozeTask = () => {
    setError(false);
    const params = {
      taskId: snoozeId,
      endDate: new Date(addDays(new Date(), snoozeLength)),
      type: snoozeType,
      count: parseInt(snoozeCount) + parseInt(snoozeLength),
      reason: snoozeReason,
      snoozeLength,
      userId,
    };
    snoozeClientTask(params);
  };

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      aria-labelledby="form-dialog-title"
      key="critical-needs-dialog"
      PaperProps={{
        style: {
          margin: 0,
          padding: "20px",
          boxSizing: "border-box",
          position: "relative",
        },
      }}
      maxWidth="lg"
    >
      <IconButton onClick={closeDialog} className={classes.closeIcon} size="large">
        <CloseIcon />
      </IconButton>
      <CriticalNeedsDialogContent
        {...{
          // handlers
          setSnoozeLength,
          onSubmitSnoozeTask,
          setSnoozeItem,
          setSnoozeReason,
          // values
          classes,
          criticalClients,
          snoozeReason,
          snoozeId,
          snoozeLength,
          error,
          warningClients,
          cancelSnooze,
          history,
          snoozeTaskLoading,
        }}
      />
    </Dialog>
  );
};

export default withStyles(styles)(CriticalNeedsDialog);
