import React from "react";
import Typography from "@mui/material/Typography";
import SectionContainer from "elements/SectionContainer";
import Button from "@mui/material/Button";
import { format } from "date-fns";

import { convertTokens } from "@date-fns/upgrade/v2";

const IncompleteOffPlatformCalls = (props) => {
  const { classes, incompleteOffPlatform, navigate } = props;

  return (
    <>
      {incompleteOffPlatform?.length > 0 &&
        incompleteOffPlatform.map((call) => {
          return (
            <SectionContainer columnDirection key={call.id}>
              <Typography variant="h3" className={classes.optionalNotificationTitle}>
                Looks like you had an off-platform session scheduled.
              </Typography>
              <Typography variant="h4" className={classes.notificationSubtitle}>
                Let's grab some details about your call with{" "}
                <span
                  onClick={() => navigate(`/clients/${call.client_id}/dashboard`)}
                  className={classes.clientNameLink}
                >
                  {call.child_first_name}
                </span>
              </Typography>
              <Typography variant="h5" className={classes.notificationContent}>
                This session was on{" "}
                <span style={{ fontWeight: 500 }}>
                  {format(new Date(call.scheduled_date), convertTokens("MMMM Do"))}
                </span>{" "}
                and because it was off-platform it cannot be marked complete until we confirm the
                call time.
              </Typography>
              <div className={classes.notificationButtonContainer}>
                <CompleteOffPlatformCallButton {...props} call={call} />
              </div>
            </SectionContainer>
          );
        })}
    </>
  );
};
export default IncompleteOffPlatformCalls;

const CompleteOffPlatformCallButton = (props) => {
  const { onToggleCompleteOffPlatformDialog, classes, call } = props;
  return (
    <Button
      onClick={() => onToggleCompleteOffPlatformDialog(call)}
      className={classes.scheduleCallButton}
    >
      COMPLETE CALL
    </Button>
  );
};
