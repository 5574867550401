import { createReducer } from "redux-create-reducer";
import actions from "../actions";

const initialState = {
  isLoaded: false,
  pageName: "AnswersNow",
  currentPage: "signin",
  menu: "",
  open: false,
  type: "",
  accepted: false,
  versionCode: null,
};

const reducers = {
  [actions.SYSTEM_LOADED](state) {
    return {
      ...state,
      isLoaded: true,
    };
  },
  [actions.SET_PAGE_DETAILS](state, { payload }) {
    if (payload.pageName) {
      document.title = payload.pageName;
    }
    return {
      ...state,
      ...payload,
    };
  },
  [actions.OPEN_CONFIRMATION](state, { payload }) {
    return {
      ...state,
      type: payload.type,
      open: true,
    };
  },
  [actions.CANCEL_CONFIRMATION](state) {
    return {
      ...state,
      open: false,
      type: "",
    };
  },
  [actions.ACCEPT_CONFIRMATION](state) {
    return {
      ...state,
      open: false,
      type: "",
      accepted: true,
    };
  },
  [actions.CLEAR_CONFIRMATION](state) {
    return {
      ...state,
      open: false,
      type: "",
      accepted: false,
    };
  },
  [actions.SIGN_OUT](state) {
    return {
      ...initialState,
    };
  },
  [actions.GET_BUILD_VERSION](state) {
    return {
      ...state,
      versionCode: null,
    };
  },
  [actions.GET_BUILD_VERSION_SUCCESS](state, { payload }) {
    return {
      ...state,
      versionCode: payload,
    };
  },
};

export default createReducer(initialState, reducers);
