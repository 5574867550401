import { Paper, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import React, { Component } from "react";
import { withRouter } from "react-router";
import actions from "../../actions";
import PageContainer from "elements/PageContainer";
import styles from "../../theme/globalStyles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logError: actions.logError,
    },
    dispatch,
  );

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    this.props.logError({ errorType: "REACT", errorMessage: error.stack });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location?.pathname !== this.props.location?.pathname) {
      this.setState({ hasError: false });
    }
  }

  render() {
    const { classes } = this.props;
    if (this.state.hasError) {
      return (
        <PageContainer>
          <Paper className={classes.mainPaper}>
            <Typography component="h1" variant="h4" className={classes.bold} align={"center"}>
              Whoops! An error has occurred.
            </Typography>
            <Typography component="p" variant="body1">
              &nbsp;
            </Typography>
            <Typography component="p" variant="body1" align={"center"}>
              We're not quite sure what happened here. A bug report has been sent and we are looking
              into the issue. In the meantime, please refresh the page.
            </Typography>
          </Paper>
        </PageContainer>
      );
    }
    return this.props.children;
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ErrorBoundary)),
);
