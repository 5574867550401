import { Alert, AlertTitle, Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import React from "react";

export default function CallEndedMarkedAsNoShowDialog({ open, handleEndCall }) {
  return (
    <Dialog open={open} PaperProps={{ style: { width: 464 } }}>
      <DialogContent dividers>
        <Alert severity="error">
          <AlertTitle>The call ended because the client didn't attend</AlertTitle>
          The primary clinician ended the call and marked the client as a no-show
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={handleEndCall}>
          Leave call
        </Button>
      </DialogActions>
    </Dialog>
  );
}
