import React from "react";
import { format, differenceInDays, endOfDay, addMinutes } from "date-fns";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { findDifferenceInMinutes } from "../../utils/findDifferenceInMinutes";

import { convertTokens } from "@date-fns/upgrade/v2";

const UpcomingSession = (props) => {
  const {
    upcomingSession,
    classes,
    user,
    onJoinVideoCall,
    onboarding,
    messageClinicianRequestingReschedule,
    userClinician,
  } = props;
  const mapStartTimeToCountdown = (startTime) => {
    const diffInDays = differenceInDays(new Date(endOfDay(new Date(startTime))), new Date());
    const diffInMinutes = findDifferenceInMinutes(startTime, new Date());
    if (diffInDays > 1) {
      return (
        <>
          <Typography component="p">Starts in</Typography>
          <Typography component="h1">{diffInDays}</Typography>
          <Typography component="p"> days</Typography>
        </>
      );
    } else if (diffInMinutes > 60) {
      return (
        <>
          <Typography component="p">Starts in</Typography>
          <Typography component="h1">{Math.round(diffInMinutes / 60)}</Typography>
          <Typography component="p">
            {Math.round(diffInMinutes / 60) > 1 ? "hours" : "hour"}
          </Typography>
        </>
      );
    } else if (diffInMinutes > 0) {
      return (
        <>
          <Typography component="p">Starts in</Typography>
          <Typography component="h1">{diffInMinutes}</Typography>
          <Typography component="p">{diffInMinutes > 1 ? "minutes" : "minute"}</Typography>
        </>
      );
    } else {
      return (
        <>
          <Typography component="p" style={{ color: "#991B1B" }}>
            Started
          </Typography>
          <Typography component="h1" style={{ color: "#991B1B" }}>
            {diffInMinutes * -1}
          </Typography>
          <Typography component="p" style={{ color: "#991B1B" }}>
            {diffInMinutes * -1 > 1 ? "minutes ago" : "minute ago"}
          </Typography>
        </>
      );
    }
  };
  const mapCallTypeToDisplay = (callType) => {
    switch (callType) {
      case "CAREGIVER_TRAINING":
        return "Caregiver Training";
      case "ORIENTATION":
        return "Orientation";
      case "DIRECT_THERAPY":
        return "Direct Therapy";
      case "ASSESSMENT":
        return "Assessment";
    }
  };
  const countdownDisplay = mapStartTimeToCountdown(upcomingSession?.scheduled_date);
  return upcomingSession ? (
    <div className={classes.upcomingSession}>
      <div className={classes.countdownContainer}>{countdownDisplay}</div>
      <div className={classes.upcomingSessionDetails}>
        <Typography component="p" className={classes.callType}>
          {mapCallTypeToDisplay(upcomingSession.billing_type)}
        </Typography>
        <Typography component="p" className={classes.dateAndTime}>
          Date and Time:{" "}
          {format(new Date(upcomingSession.scheduled_date), convertTokens("dddd, MMMM D, YYYY"))}{" "}
          <span style={{ whiteSpace: "nowrap" }}>
            {format(new Date(upcomingSession.scheduled_date), "p")} -{" "}
            {format(
              new Date(
                addMinutes(new Date(upcomingSession.scheduled_date), upcomingSession.call_duration)
              ),
              "p"
            )}
          </span>
        </Typography>
        <Typography component="p" className={classes.clinicianName}>
          Clinician:{" "}
          {upcomingSession.clinician_name ||
            `${user?.clinician?.first_name} ${user?.clinician.last_name}`}
        </Typography>
        <div className={classes.upcomingSessionButtonContainer}>
          {findDifferenceInMinutes(upcomingSession.scheduled_date, new Date()) > 15 ? (
            <>
              <Button
                color="secondary"
                size="small"
                onClick={() => props.history.push(`/call-instructions/${upcomingSession.id}`)}
              >
                Read Instructions
              </Button>
              {userClinician && userClinician?.clinician_user_id && (
                <Button
                  variant="text"
                  size="small"
                  onClick={() => messageClinicianRequestingReschedule(upcomingSession.id)}
                >
                  Request Reschedule
                </Button>
              )}
            </>
          ) : (
            <>
              {upcomingSession?.off_platform_type !== "phone" && (
                <Button size="small" onClick={() => onJoinVideoCall(upcomingSession)}>
                  Join Call
                </Button>
              )}
              <Button
                variant="text"
                size="small"
                onClick={() => props.history.push(`/call-instructions/${upcomingSession.id}`)}
              >
                Read Instructions
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  ) : onboarding ? (
    <div className={classes.upcomingSession}>
      <div className={classes.upcomingSessionDetails} style={{ width: "100%" }}>
        <Typography component="p" className={classes.callType}>
          Check out this spot in the future
        </Typography>
        <Typography component="p" className={classes.dateAndTime}>
          In this space, you&apos;ll see your upcoming session once you&apos;re fully approved.
        </Typography>
        <Typography component="p" className={classes.clinicianName} style={{ fontWeight: 500 }}>
          You can learn more about the step that you&apos;re currently on in the approval process by
          clicking the button below.
        </Typography>
      </div>
    </div>
  ) : (
    <div className={classes.upcomingSession}>
      <div className={classes.upcomingSessionDetails} style={{ width: "100%" }}>
        <Typography component="p" className={classes.callType}>
          No Session Scheduled
        </Typography>
        <Typography component="p" className={classes.dateAndTime}>
          You don&apos;t have any upcoming sessions currently scheduled.
        </Typography>
        <Typography component="p" className={classes.clinicianName}>
          Would you like to chat with your clinician?
        </Typography>
        <div className={classes.upcomingSessionButtonContainer}>
          <Button
            onClick={() => props.history.push(`/chat/${props.user?.active_conversation_url || ""}`)}
          >
            Message Clinician
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UpcomingSession;
