import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Controller, useFormContext } from "react-hook-form";

const GenericCheckbox = ({ name, label, className, disabled, required }) => {
  const { control, trigger, formState } = useFormContext();
  const { errors } = formState;

  const handleBlur = () => trigger(name);

  const handleFocus = () => {
    if (errors[name]) {
      trigger(name, { shouldFocus: false });
    }
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={{ required }}
      render={({ field: { onChange, value } }) => {
        return (
          <FormControlLabel
            className={className}
            control={<Checkbox onChange={onChange} checked={!!value} />}
            label={label}
            disabled={disabled}
            onBlur={handleBlur}
            onFocus={handleFocus}
            error={errors[name]}
          />
        );
      }}
    />
  );
};

export default GenericCheckbox;
