import React from "react";
import ResetPassword from "./ResetPassword";
import UpdateClinicianStatus from "./UpdateClinicianStatus";
import { Box } from "@mui/material";
import { isAdmin, isSuperAdmin, isSupervisor } from "selectors";

const AccountSettings = (props) => {
  const {
    updatedUserStatus,
    onChangeUserStatus,
    userPermissions,    
    userDetails,
    isActive,
    classes,
    state,
    isDirector,
    isAdmin,
    isSuperAdmin,
    isSupervisor,
  } = props;

  return (
    <Box display="flex" justifyContent="center">
      <Box width="80%" maxWidth="1200px" padding={"16px 0px"}>
        <div className={classes.accordionContainer}>
          {userPermissions?.send_reset_password && <ResetPassword {...props} />}
          {(userPermissions?.override_clinician_workflow_status ||
            userPermissions?.deactivate_clinician) && (
            <UpdateClinicianStatus
              {...state}
              {...props}
              isActive={isActive}
              selectedStatus={updatedUserStatus}
              setSelectedStatus={(e) => onChangeUserStatus(e)}
            />
          )}
        </div>
      </Box>
    </Box>
  );
};
export default AccountSettings;
