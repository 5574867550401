const ACTIONS = {
  SET_SIGNIN_DETAILS: "SET_SIGNIN_DETAILS",
  START_SIGNIN: "START_SIGNIN",
  GET_SIGNEDIN_STATE: "GET_SIGNEDIN_STATE",
  SET_SIGNEDIN_STATE: "SET_SIGNEDIN_STATE",
  SET_SIGNIN_FAILURE: "SET_SIGNIN_FAILURE",
  FORCE_PASSWORD_CHANGE: "FORCE_PASSWORD_CHANGE",
  CLEAR_PASSWORD_CHANGE: "CLEAR_PASSWORD_CHANGE",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  CHANGE_USER_PASSWORD: "CHANGE_USER_PASSWORD",
  SIGN_OUT: "SIGN_OUT",
  SET_REDIRECT: "SET_REDIRECT",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  CLEAR_FORGOT_PASSWORD: "CLEAR_FORGOT_PASSWORD",
  RESET_PASSWORD: "RESET_PASSWORD",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_ERROR: "RESET_PASSWORD_ERROR",
  CLEAR_RESET_PASSWORD: "CLEAR_RESET_PASSWORD",
  CLEAR_SIGNIN_FAILURE: "CLEAR_SIGNIN_FAILURE",
  GET_INVITATION: "GET_INVITATION",
  GET_INVITATION_SUCCESS: "GET_INVITATION_SUCCESS",
  GET_INVITATION_FAIL: "GET_INVITATION_FAIL",
  MARK_INVITATION_USED: "MARK_INVITATION_USED",
  MARK_INVITATION_USED_SUCCESS: "MARK_INVITATION_USED_SUCCESS",
  SET_NEEDS_RESET: "SET_NEEDS_RESET",
  SET_NOT_CONFIRM: "SET_NOT_CONFIRM",
  VERIFY: "VERIFY",
  SET_VERIFIED: "SET_VERIFIED",
  SET_VERIFICATION_ERROR: "SET_VERIFICATION_ERROR",
  SET_USER_ATTRIBUTES: "SET_USER_ATTRIBUTES",
};

const ACTION_CREATORS = {
  setSigninDetails(payload) {
    return {
      type: ACTIONS.SET_SIGNIN_DETAILS,
      payload,
    };
  },
  startSignin(payload) {
    return {
      type: ACTIONS.START_SIGNIN,
      payload,
    };
  },
  getSignedinState() {
    return {
      type: ACTIONS.GET_SIGNEDIN_STATE,
    };
  },
  setSignedinState(authState) {
    return {
      type: ACTIONS.SET_SIGNEDIN_STATE,
      payload: {
        authState,
      },
    };
  },
  setSigninFailure(payload) {
    return {
      type: ACTIONS.SET_SIGNIN_FAILURE,
      payload,
    };
  },
  clearSigninFailure() {
    return { type: ACTIONS.CLEAR_SIGNIN_FAILURE };
  },
  signOut(clear) {
    return {
      type: ACTIONS.SIGN_OUT,
      payload: {
        clear,
      },
    };
  },
  forcePasswordChange(payload) {
    return {
      type: ACTIONS.FORCE_PASSWORD_CHANGE,
      payload,
    };
  },
  clearPasswordChange() {
    return {
      type: ACTIONS.CLEAR_PASSWORD_CHANGE,
    };
  },
  forgotPassword(payload) {
    return {
      type: ACTIONS.FORGOT_PASSWORD,
      payload,
    };
  },
  forgotPasswordSuccess() {
    return { type: ACTIONS.FORGOT_PASSWORD_SUCCESS };
  },
  clearForgotPassword() {
    return { type: ACTIONS.CLEAR_FORGOT_PASSWORD };
  },
  resetPassword(payload) {
    return {
      type: ACTIONS.RESET_PASSWORD,
      payload,
    };
  },
  resetPasswordSuccess() {
    return { type: ACTIONS.RESET_PASSWORD_SUCCESS };
  },
  resetPasswordError(error) {
    return { type: ACTIONS.RESET_PASSWORD_ERROR, payload: error };
  },
  clearResetPassword() {
    return { type: ACTIONS.CLEAR_RESET_PASSWORD };
  },
  setNeedsReset() {
    return { type: ACTIONS.SET_NEEDS_RESET };
  },
  setNotConfirm() {
    return { type: ACTIONS.SET_NOT_CONFIRM };
  },
  changePassword(payload) {
    return {
      type: ACTIONS.CHANGE_PASSWORD,
      payload,
    };
  },
  changeUserPassword(payload) {
    return {
      type: ACTIONS.CHANGE_USER_PASSWORD,
      payload,
    };
  },
  verify(email, code, username) {
    return {
      type: ACTIONS.VERIFY,
      payload: {
        email,
        code,
        username,
      },
    };
  },
  setVerified() {
    return { type: ACTIONS.SET_VERIFIED };
  },
  setVerificationError() {
    return { type: ACTIONS.SET_VERIFICATION_ERROR };
  },
  setUserAttributes(userAttributes) {
    return {
      type: ACTIONS.SET_USER_ATTRIBUTES,
      payload: {
        userAttributes,
      },
    };
  },
  setRedirect(url) {
    return {
      type: ACTIONS.SET_REDIRECT,
      payload: {
        url,
      },
    };
  },
  getLoginInvitation(guid) {
    return {
      type: ACTIONS.GET_INVITATION,
      payload: guid,
    };
  },
  getLoginInvitationSuccess(invitation) {
    return {
      type: ACTIONS.GET_INVITATION_SUCCESS,
      payload: invitation,
    };
  },
  getLoginInvitationFail(error) {
    return {
      type: ACTIONS.GET_INVITATION_FAIL,
      payload: error,
    };
  },
  markInvitationUsed(guid) {
    return {
      type: ACTIONS.MARK_INVITATION_USED,
      payload: guid,
    };
  },
  markInvitationUsedSuccess() {
    return {
      type: ACTIONS.MARK_INVITATION_USED_SUCCESS,
    };
  },
};

export default { ...ACTIONS, ...ACTION_CREATORS };
