import { Typography } from "@mui/material";
import React from "react";
import Button from "@mui/material/Button";
import SectionContainer from "elements/SectionContainer";
import LoadingButton from "@mui/lab/LoadingButton";

const SMSNotification = (props) => {
  const {
    notification,
    classes,
    dismissClinicianNotification,
    onClickViewSMSThread,
    notificationsLoading,
  } = props;

  return (
    <SectionContainer key={notification.id} columnDirection>
      <Typography variant="h3" className={classes.notificationTitle} style={{ marginBottom: 10 }}>
        {`A new SMS message was received from ${notification.client_name}'s caregiver.`}
      </Typography>

      <div className={classes.notificationButtonContainer}>
        <Button
          color="secondary"
          onClick={() => onClickViewSMSThread(notification.client_id, notification.created)}
          disabled={notificationsLoading}
        >
          View SMS Thread
        </Button>
        <LoadingButton
          variant="text"
          onClick={() =>
            dismissClinicianNotification({
              notificationId: notification.id,
              clinicianId: notification.clinician_id,
            })
          }
          loading={notificationsLoading}
          style={{ marginLeft: 50 }}
        >
          Dismiss
        </LoadingButton>
      </div>
    </SectionContainer>
  );
};

export default SMSNotification;
