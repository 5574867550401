import { markClientFormAsSkipped } from "api/customers";
import { useMutation, useQueryClient } from "react-query";

// Custom hook to mark a client form as skipped
const useMarkClientFormAsSkipped = (clientId) => {
  const queryClient = useQueryClient();
  const mutation = useMutation((formId) => markClientFormAsSkipped(formId), {
    onSuccess: () => {
      // Invalidate queries to refetch the list of forms needing signature
      queryClient.invalidateQueries(["unsignedForms", clientId]);
    },
  });
  return mutation;
};

export default useMarkClientFormAsSkipped;
