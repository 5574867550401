/**
 * Returns an object containing the changes between two objects.
 * @param {Object} newObj - The new object.
 * @param {Object} oldObj - The old object.
 * @param {Array} ignoreKeys - An array of keys to ignore during comparison.
 * @returns {Object} - An object containing the changes between the two objects.
 */
export const getObjectChanges = (newObj, oldObj, ignoreKeys) => {
  const changes = {};
  for (const key in newObj) {
    if (ignoreKeys?.includes(key)) continue;
    if (newObj[key] !== oldObj[key]) {
      changes[key] = { old: oldObj[key], new: newObj[key] };
    }
  }
  return changes;
};
