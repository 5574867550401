import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import styles from "./styles";

import ClientDetailsPageContents from "./ClientDetailsPageContents";
import { DropzoneDialog, DropzoneDialogBase } from "mui-file-dropzone";
import { addMinutes } from "date-fns";
import Modal from "elements/Modal";
import { Typography } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import CircularProgress from "@mui/material/CircularProgress";
import SubHeading from "elements/SubHeading";
import CancelSessionDialog from "../WeeklyCalendarSessions/CancelSessionDialog";
import EditSelectionDialog from "../WeeklyCalendarSessions/EditSelectionDialog";
import Snackbar from "@mui/material/Snackbar";
import { dateWithoutTimezone } from "../../utils/dateWithoutTimezone";
import ClientDetailsTabs from "./ClientDetailsTabs";

import Auth from "@aws-amplify/auth";
import UploadDocumentDialog from "./Dialogs/DEPRECATED/UploadDocumentDialog";
import DisableInsuranceDialog from "./Dialogs/DisableInsuranceDialog";
import MarkAssessmentCompleteDialog from "./Dialogs/MarkAssessmentCompleteDialog";
import UpdateDocumentDialog from "./Dialogs/UpdateDocumentDialog";
import ApproveDocumentDialog from "./Dialogs/ApproveDocumentDialog";
import { getAllS3ObjectVersions } from "../../utils/getAllS3ObjectVersions";
import DocumentVersionsDialog from "./Dialogs/DocumentVersionsDialog";
import AssignClinicianConfirmDialog from "./Dialogs/AssignClinicianConfirmDialog";
import RemoveClinicianConfirmDialog from "./Dialogs/RemoveClinicianConfirmDialog";

import TimelineStatistics from "./Widgets/TimelineStatistics";
import { isDischargeReason, isDropReason } from "../../utils/reason";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import {
  getCancellationReasonLabel,
  getKeyCancellationReason,
} from "../../constants/cancelReasons";
import { roundDownToNearest15 } from "components/EventScheduling/EventSchedulingUtils";
import { Prompt } from "react-router-dom";
import SaveBeforeNavDialog from "./Dialogs/SaveBeforeNavDialog";
import { getInitialState } from "./InitialState";
import { mapStateToProps } from "./mapStateToProps";
import { mapDispatchToProps } from "./mapDispatchToProps";
import { getAWSCredentialsForCurrentUserSession } from "utils/aws";
import { getHasClientAccountAccess } from "api/user";
import { useGlobalPageLoading } from "components/GlobalPageLoadingProvider";
import { useQuery } from "react-query";
import { useGlobalToast } from "components/GlobalToastProvider";

class ClientDetailsContainer extends Component {
  state = {
    ...getInitialState(this.props),
  };
  assignBlockRef = null;
  insuranceAuthorizationRef = null;
  smsPolling = null;
  editableTabs = ["profile", "client-availability", "account-settings", "insurance"];
  // This array is for tabs that are not controlled by the parent (this component) but
  // the logic is in the tab component itself
  tabsExceptions = ["profile"];

  refreshPageDetails = () => {
    if (this.props.userPermissions?.view_client_list_by_workflow_state) {
      this.props.setPageDetails({
        currentPage: "customerdetail",
        pageName: "Client Detail",
        menu: "entities",
        hideDivider: true,
      });
    } else if (this.props.userPermissions?.view_my_clients) {
      this.props.setPageDetails({
        currentPage: "customerview",
        pageName: "Client View",
        menu: "entities",
        hideDivider: true,
      });
    }
  };

  componentDidMount() {
    this.refreshPageDetails();

    if (this.props.match.params.id) {
      this.getUserData();
      this.props.getCustomerDeactivationReasons();
      this.props.getCustomerDepartureOptions();
    }
    if (this.props.clinicianUserId) this.props.getClinicianClients();

    if (
      this.props.workflowStatus &&
      this.props.customerDetails?.details?.userid &&
      this.props.match.params.id
    ) {
      if (
        this.props.workflowStatus !== "INVITED" &&
        this.props.workflowStatus !== "AWAITING_ONBOARDING"
      ) {
        this.props.getCustomerSignedForm({
          clientId: this.props.match.params.id,
          type: "CONSENT_FORM",
          userId: this.props.customerDetails.details.userid,
        });
      } else {
        this.props.getCustomerSignedForm({
          clientId: this.props.match.params.id,
          type: "E_COMM_CONSENT",
          userId: this.props.customerDetails.details.userid,
        });
      }
      this.setState({ resetPasswordUserId: this.props.customerDetails.details.userid });
    }
    const params = new URLSearchParams(this.props.location.search);
    if (params.get("sms-open")) {
      this.props.clearSMSThread();
      this.onOpenSMSFacilityDialog();
    }
    this.props.setPageDetails({ hideDivider: true });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (prevProps.childName != this.props.childName && this.props?.childName != "") ||
      (!!this.props.childName &&
        prevProps.childLastName != this.props.childLastName &&
        this.props?.childLastName != "")
    ) {
      this.props.setPageDetails({
        altTitle: `${this.props.childName || ""} ${this.props.childLastName || ""}`,
      });
    }
    if (prevProps.userPermissions != this.props.userPermissions) {
      this.refreshPageDetails();
    }
    if (prevProps.clinician !== this.props.clinician) {
      this.setState({ clinician: this.props.clinician });
    }
    if (
      ((!prevProps.customerDetails.clinician && this.props.customerDetails.clinician) ||
        (prevProps.customerDetails.clinician !== this.props.customerDetails.clinician &&
          this.props.customerDetails.clinician)) &&
      this.props.customerDetails.clinician.clinician_id
    ) {
      this.props.getClinician(this.props.customerDetails.clinician.clinician_id);
    }
    if (
      ((!prevProps.customerDetails.secondaryClinician &&
        this.props.customerDetails.secondaryClinician) ||
        (prevProps.customerDetails.secondaryClinician !==
          this.props.customerDetails.secondaryClinician &&
          this.props.customerDetails.secondaryClinician)) &&
      this.props.customerDetails.secondaryClinician.clinician_id
    ) {
      this.props.getClinician(this.props.customerDetails.secondaryClinician.clinician_id);
    }
    if (
      ((!prevProps.clinicianDetails.support_conversation_url &&
        this.props.clinicianDetails.support_conversation_url) ||
        prevProps.clinicianDetails?.support_conversation_url !==
          this.props.clinicianDetails?.support_conversation_url) &&
      this.state.creatingSupportChat
    ) {
      let url = this.props.clinicianDetails.support_conversation_url;
      this.props.history.push(`/chat/${url}`);
    }

    if (prevProps.markingEligible && !this.props.markingEligible) {
      this.setState({ markEligibleSuccess: true });
      setTimeout(() => {
        this.setState({ markEligibleSuccess: false });
      }, 7000);
    }
    if (prevProps.markingApproved && !this.props.markingApproved) {
      this.setState({ markApprovedSuccess: true });
      setTimeout(() => {
        this.setState({ markApprovedSuccess: false });
      }, 7000);
    }
    if (prevProps.resendingLoginEmail && !this.props.resendingLoginEmail) {
      this.setState({ resendLoginSuccess: true });
      setTimeout(() => {
        this.setState({ resendLoginSuccess: false });
      }, 7000);
    }
    if (
      prevProps.scheduleCallLoading &&
      !this.props.scheduleCallLoading &&
      this.props.scheduleCallSuccess
    ) {
      if (this.props.customerDetails.details.workflow_status == "INSURANCE_APPROVED") {
        this.handleInsuranceApprovedScheduleCall();
      } else {
        if (!this.state.sessionNowBillingType) {
          this.props.loadCustomerDetails(this.state.id);
        }
      }
    }
    if (
      (prevProps.authorizationCodesLoading && !this.props.authorizationCodesLoading) ||
      prevState.billableTimePeriod !== this.state.billableTimePeriod
    ) {
      this.props.getBillableTime({
        clientId: this.state.id,
        period: this.state.billableTimePeriod,
      });
    }
    if (
      prevProps.offPlatformLoading &&
      !this.props.offPlatformLoading &&
      this.props.offPlatformSuccess
    ) {
      this.props.getBillableTime({
        clientId: this.state.id,
        period: this.state.billableTimePeriod,
      });
    }
    if (
      this.props.match.params.id !== prevProps.match.params.id ||
      this.props.client_id !== prevProps.client_id
    ) {
      this.props.clearCustomerForm();
      this.props.clearSMSThread();
      this.getUserData();
    }
    if (
      (!prevProps.workflowStatus && this.props.workflowStatus) ||
      prevProps.workflowStatus !== this.props.workflowStatus
    ) {
      this.mapStatusToDocumentMatrix();
    }
    if (prevProps.documentsLoading && !this.props.documentsLoading) {
      this.mapStatusToDocumentMatrix();
    }

    if (
      prevProps.scheduleCallLoading &&
      !this.props.scheduleCallLoading &&
      this.props.scheduleCallSuccess &&
      this.state.sessionNowBillingType
    ) {
      let roomId = uuidv4();
      const details = {
        videoInfo: this.props.rescheduleVideoDetails,
        roomId,
        videoKey: this.props.rescheduleVideoDetails.videoKey,
      };
      this.props.setOneTimeVideoInfo(details);
      this.props.history.push(`/video/${details.videoKey}`);
      this.onToggleStartNewVideoDialog();
    }
    if (
      ((!prevProps.customerDetails.notes && this.props.customerDetails.notes) ||
        (this.props.customerDetails.notes &&
          prevProps.customerDetails.notes.length !== this.props.customerDetails.notes.length)) &&
      !!this.props.customerDetails.notes[0]
    ) {
      this.setState({ notes: this.props.customerDetails.notes[0].note });
    }

    if (
      prevProps.userVideoPage !== this.props.userVideoPage ||
      prevProps.userVideoSort !== this.props.userVideoSort ||
      prevProps.userVideoDirection !== this.props.userVideoDirection ||
      prevProps.userVideoRowsPerPage !== this.props.userVideoRowsPerPage ||
      prevProps.userVideoType !== this.props.userVideoType
    ) {
      this.props.getUserVideoCalls(this.state.id);
    }

    if (prevProps.treatmentPlanLoading && !this.props.treatmentPlanLoading) {
      if (this.props.treatmentPlanSuccess) {
        this.openSessionNotesDialog();
      } else {
        this.setState({ treatmentPlanError: true });
      }
      this.setState({ treatmentPlanUploading: false });
    }
    if (!prevProps.clinicianUserId && this.props.clinicianUserId) {
      this.props.getClinicianClients();
    }
    if (!prevProps.resetPasswordSent && this.props.resetPasswordSent) {
      setTimeout(() => {
        this.props.clearResetUserPasswordInfo();
      }, 5000);
    }
    if (
      !prevProps.workflowStatus &&
      this.props.workflowStatus &&
      this.props.customerDetails?.details?.userid
    ) {
      if (
        this.props.workflowStatus !== "INVITED" &&
        this.props.workflowStatus !== "AWAITING_ONBOARDING" &&
        this.props.match.params.id
      ) {
        this.props.getCustomerSignedForm({
          clientId: this.props.match.params.id,
          type: "CONSENT_FORM",
          userId: this.props.customerDetails.details.userid,
        });
      } else {
        this.props.getCustomerSignedForm({
          clientId: this.props.match.params.id || this.props.client_id,
          type: "E_COMM_CONSENT",
          userId: this.props.customerDetails.details.userid,
        });
      }
    }
    if (
      ((!prevProps.form && this.props.form) ||
        (prevProps.form?.formType !== this.props.form?.formType && this.props.form)) &&
      this.props.match.params.id
    ) {
      if (this.props.form.formType === "CONSENT_FORM") {
        if (this.props.form.signed) {
          this.setState({ consentFormSigned: true });
        }
        if (this.props.customerDetails?.details?.userid) {
          this.props.getCustomerSignedForm({
            clientId: this.props.match.params.id,
            type: "E_COMM_CONSENT",
            userId: this.props.customerDetails.details.userid,
          });
        }
      }
      if (this.props.form.formType === "E_COMM_CONSENT") {
        if (this.props.form.signed) {
          this.setState({ eCommConsentFormSigned: true });
        } else {
          this.setState({ eCommConsentFormSigned: false });
        }
      }
    }
    if (
      prevProps.location?.pathname !== this.props.location?.pathname &&
      this.props.location?.pathname?.includes("/communication") &&
      this.props.match.params.id
    ) {
      if (this.props.customerDetails?.details?.userid) {
        this.props.getCustomerSignedForm({
          clientId: this.props.match.params.id,
          type: "E_COMM_CONSENT",
          userId: this.props.customerDetails.details.userid,
        });
      }
    }
    if (
      !prevProps.customerDetails.details.timezone &&
      this.props.customerDetails.details.timezone
    ) {
      this.setState({
        clientTimezone: this.props.customerDetails.details.timezone,
      });
    }
    if (prevProps.updateClientTimezoneLoading && !this.props.updateClientTimezoneLoading) {
      setTimeout(() => {
        this.props.clearTimezoneInfo();
      }, 7000);
    }

    if (prevProps.updateLinkPhysicianLoading && !this.props.updateLinkPhysicianLoading) {
      let message = "Physician Info Updated Successfully!";
      let error = false;
      if (!this.props.updateLinkPhysicianSuccess) {
        message = "An error has occurred updating link physician. Please try again.";
        error = true;
      }
      this.setState({
        snackBarOpen: true,
        snackBarError: error,
        snackBarMessage: message,
        editPhysicianInfoOpen: false,
      });
    }
    if (
      (!prevState.smsFacilityDialogOpen ||
        !prevProps.currentUser?.id ||
        !prevProps.customerDetails?.clinician?.clinician_user_id) &&
      this.state.smsFacilityDialogOpen &&
      this.props.currentUser?.id &&
      this.props.currentUser?.id == this.props.customerDetails?.clinician?.clinician_user_id &&
      this.props.match.params.id
    ) {
      this.props.dismissClinicianNotification({
        clientId: this.props.match.params.id,
        clinicianId: this.props.customerDetails?.clinician?.clinician_id,
      });
    }
    if (prevState.resetPasswordUserId === null && this.props.customerDetails.details.userid) {
      this.setState({ resetPasswordUserId: this.props.customerDetails.details.userid });
    }

    this.checkStateId();
  }

  checkStateId() {
    const newId = this.props.match.params.id ?? this.props.client_id;
    if (newId && newId !== this.state.id) {
      this.setState({ id: newId }, () => {
        this.getUserData();
      });
    }
  }

  componentWillUnmount() {
    this.props.clearCliniciansList();
    this.props.clearCustomerForm();
    this.props.clearSMSThread();
    if (this.smsPolling) {
      clearInterval(this.smsPolling);
    }
    this.props.setPageDetails({
      altTitle: null,
      hideDivider: false,
    });
  }

  getUserData = () => {
    const id = this.props.match.params.id ?? this.props.client_id;
    this.setState({ id });
    this.props.loadCustomerDetails(id);
    this.props.getAllDocumentRequests(id);
    if (this.props.match.params.id) {
      this.props.getAllClinicians();
      this.props.getDocumentTypes();
      this.props.getAvailableInsuranceCodes({
        clientId: this.props.match.params.id,
        requiresAuth: true,
      });
      this.props.getInsuranceCodesByUser({
        clientId: this.props.match.params.id,
      });
      this.props.getAuthorizationCodes(this.props.match.params.id);
      this.props.getUserVideoCalls(this.props.match.params.id);
      this.props.getUserInsurance(this.props.match.params.id);
      this.props.getInsurance();
      this.props.getSessionNotesByUser(this.props.match.params.id);
      this.props.clearOneTimeVideoInfo();
      this.props.getNotesList(this.props.match.params.id);
      this.props.getAllPhysicians();
    }
    if (this.props.customerDetails.notes?.length > 0) {
      this.setState({ notes: this.props.customerDetails.notes[0].note });
    }
    if (this.props.customerDetails.details.timezone) {
      this.setState({
        clientTimezone: this.props.customerDetails.details.timezone,
      });
    }
  };

  mapStatusToDocumentMatrix = () => {
    const { match, workflowStatus } = this.props;
    let userStatus;
    if (
      workflowStatus === "ASSESSMENT_COMPLETED" ||
      workflowStatus === "APPROVE_PLAN" ||
      workflowStatus === "AWAITING_INSURANCE_APPROVAL" ||
      workflowStatus === "INSURANCE_APPROVED" ||
      workflowStatus === "FULL_CUSTOMER"
    ) {
      userStatus = "TREATMENT";
    } else {
      userStatus = "ASSESSMENT";
    }
    if (userStatus) {
      this.props.getDocumentMatrix({
        clientId: match.params.id ?? this.props.client_id,
        userStatus,
      });
    }
  };

  onChange = (name) => (e) => {
    const { checked, type, value } = e.target;
    let val;
    switch (value) {
      case "Yes":
        val = true;
        break;
      case "No":
        val = false;
        break;
      default:
        val = type === "checkbox" ? checked : value;
    }
    this.setState({
      [name]: val,
      buttonDisabled: false,
      unsavedChanges: true,
    });
  };

  onChangeAvailability = () => {
    this.setState({
      unsavedChanges: true,
    });
  };

  onChangeDate = (name, date) => {
    this.setState({
      [name]: date,
      buttonDisabled: false,
      unsavedChanges: true,
    });
  };

  onChangeArrayToString = (name, value) => {
    this.setState({
      [name]: typeof value === "string" ? value : value.join(", "),
      buttonDisabled: false,
      unsavedChanges: true,
    });
  };

  onClickCheckbox = (name) => {
    this.setState({ [name]: !this.state[name] });
  };

  onChangeNumber = (name) => (e) => {
    const { value } = e.target;
    this.setState({
      [name]: value.replace(/[^0-9]/g, ""),
      buttonDisabled: false,
      unsavedChanges: true,
    });
  };

  setBillableTimePeriod = (period) => {
    this.setState({ billableTimePeriod: period });
  };

  onOpenAssignClinician = (value, scrollTo) => {
    this.props.history.push("./dashboard");
    this.setState(
      {
        assignedRole: value,
        confirmSent: false,
      },
      () => {
        if (this.state.assignedRole === "secondary") {
          this.props.getAssignableClinicians(true);
        } else {
          this.props.getAssignableClinicians(false);
        }
        this.setState({
          assignClinicianOpen: true,
        });
        if (scrollTo) {
          window.scrollTo(0, document.body.scrollHeight);
        }
      }
    );
  };

  onCloseAssignClinician = () => {
    this.setState({
      assignClinicianOpen: false,
    });
  };

  onOpenAssignClinicianConfirm = (clinician) => {
    this.setState({
      selectedClinician: clinician,
      assignClinicianConfirmOpen: true,
    });
    this.onCloseAssignClinician();
  };

  onCloseAssignClinicianConfirm = () => {
    this.setState({
      assignClinicianConfirmOpen: false,
      assignedRole: "",
      selectedClinician: {},
    });
  };

  onOpenRemoveClinicianConfirm = (clinician) => {
    this.setState({
      confirmSent: false,
      selectedClinician: clinician,
      removeClinicianConfirmOpen: true,
    });
  };

  onCloseRemoveClinicianConfirm = () => {
    this.setState({ removeClinicianConfirmOpen: false, selectedClinician: {} });
  };

  submitAssignClinician = () => {
    const { assignedRole, selectedClinician } = this.state;
    const { customerDetails, customerUserId, clientId } = this.props;
    if (!customerDetails.clinician && assignedRole === "primary") {
      this.props.assignClinician(clientId, selectedClinician.user_id);
    } else if (customerDetails.clinician && assignedRole === "primary") {
      this.props.switchClinician(selectedClinician.clinician_id);
    } else {
      this.props.assignSecondaryClinician(clientId, selectedClinician.user_id);
    }
    this.setState({ confirmSent: true });
    this.onCloseAssignClinicianConfirm();
  };

  removeSecondaryClinician = () => {
    this.props.removeSecondaryClinician(this.state.id);
    this.setState({ confirmSent: true });
    this.onCloseRemoveClinicianConfirm();
  };

  scrollToAssignBlock = () => {
    this.assignBlockRef.scrollIntoView({
      block: "start",
      inline: "nearest",
      behavior: "smooth",
    });
  };

  navigate = (url) => {
    this.props.history.push(url);
  };

  submitEligibility = (e) => {
    this.props.markEligible();
  };

  submitApproval = (e) => {
    this.props.markApproved();
  };

  onToggleUploadDocument = () => {
    this.setState({ uploadDocumentOpen: !this.state.uploadDocumentOpen });
    if (!this.state.uploadDocumentOpen) {
      this.setState({
        uploadDoc: null,
        uploadDocType: "",
        uploadDocInternal: false,
        uploadDocBilling: false,
      });
    }
  };

  onToggleUploadDocumentWithType = (docType) => {
    const type = this.props.documentTypes.find((doc) => doc.name === docType);
    this.setState(
      {
        uploadDocType: type.name,
        uploadDocInternal: type.internal,
        uploadDocBilling: type.billing_related,
      },
      () => {
        this.setState({ uploadDocumentOpen: !this.state.uploadDocumentOpen });
      }
    );
  };

  onToggleUpdateDocument = (doc) => {
    this.setState({ updateDocument: doc }, () => {
      this.setState({ updateDocumentOpen: !this.state.updateDocumentOpen });
    });
  };

  onToggleApproveDocument = () => {
    this.setState({ approveDocumentOpen: !this.state.approveDocumentOpen });
  };

  onApproveDocument = (doc) => {
    this.props.approveCustomerDocument(doc.document_requirements_user_id);
    this.setState({ approveDocumentOpen: false });
  };

  getDocumentVersions = async (doc) => {
    if (this.props.match.params.id) {
      const versions = await getAllS3ObjectVersions(doc);
      this.setState({ documentVersions: versions }, () => {
        this.onToggleDocumentVersions();
      });
    }
  };

  onToggleDocumentVersions = () => {
    this.setState({ documentVersionsOpen: !this.state.documentVersionsOpen });
  };

  onOpenViewDocument = (document) => {
    this.setState(
      {
        viewDocument: document,
        documentUrl: null,
      },
      () => {
        this.getCustomerDocument();
      }
    );
  };

  onCloseViewDocument = () => {
    this.setState({ viewDocumentOpen: false });
  };

  uploadUserDocument = (document) => {
    this.setState({ uploadDoc: document[0], uploadDropzoneOpen: false });
  };

  submitDocumentUpload = async () => {
    const { uploadDoc, uploadDocBilling, uploadDocInternal, uploadDocType } = this.state;
    const { customerDetails, clientId } = this.props;
    const { details } = customerDetails;
    this.setState({ uploading: true });
    try {
      const credentials = await getAWSCredentialsForCurrentUserSession();

      const s3 = new AWS.S3({
        apiVersion: "2006-03-01",
        credentials,
        params: { Bucket: process.env.AWS_USER_DOC_BUCKET },
      });
      const fileUUID = uuidv4();
      const [fileName, fileExtension] = uploadDoc.name.split(".");
      const params = {
        ACL: "authenticated-read",
        Body: uploadDoc,
        ContentType: uploadDoc.type,
        Key: `${details.cognito_id}/${fileName}-${fileUUID}.${fileExtension}`,
      };
      const upload = await s3.upload(params).promise();
      const document = {
        filename: upload.Key.split("/")[1],
        display_filename: uploadDoc.name,
        clientId: clientId,
        type: uploadDocType,
        internal: uploadDocInternal,
        billing_related: uploadDocBilling,
        uploaded_by: this.props.currentUser.id,
        file_size: uploadDoc.size,
      };
      this.props.uploadCustomerDocument(document);
      this.setState({
        uploadDocumentOpen: false,
        uploadDoc: null,
        uploadDocType: "",
        uploadDocInternal: false,
        uploadDocBilling: false,
        uploading: false,
      });
    } catch (e) {
      console.error(e);
      this.setState({ uploadError: true, uploading: false });
    }
  };

  getCustomerDocument = async () => {
    const { viewDocument } = this.state;
    const { customerDetails } = this.props;
    const { details } = customerDetails;
    const credentials = await getAWSCredentialsForCurrentUserSession();
    const s3 = new AWS.S3({ credentials });
    const params = {
      Bucket: process.env.AWS_USER_DOC_BUCKET,
      Key: `${details.cognito_id}/${viewDocument.filename}`,
    };
    var url = await s3.getSignedUrl("getObject", params);
    this.setState({ documentUrl: url });
    window.open(url, "_blank");
  };

  onChangeUploadType = (e) => {
    const type = this.props.documentTypes.find((doc) => doc.name === e.target.value);
    this.setState({
      uploadDocType: type.name,
      uploadDocInternal: type.internal,
      uploadDocBilling: type.billing_related,
    });
  };

  onToggleDocumentDialog = () => {
    this.setState({ uploadDropzoneOpen: !this.state.uploadDropzoneOpen });
  };

  clearDocument = () => {
    this.setState({ uploadDoc: null });
  };

  handleInsuranceApprovedScheduleCall = () => {
    this.props.updateUserInsuranceStatus({
      status: "FULL_CUSTOMER",
      clientId: this.state.id,
    });
  };

  onSubmitUpdateUserStatus = () => {
    if (this.state.editedAccountStatus) {
      this.props.updateUserInsuranceStatus({
        status: this.state.updatedUserStatus,
        clientId: this.state.id,
      });
    }
  };

  onChangeUserStatus = (e) => {
    this.setState({
      updatedUserStatus: e.target.value,
      unsavedChanges: true,
      editedAccountStatus: true,
    });
  };

  onResendLoginEmail = () => {
    this.props.resendLoginEmail(this.props.customerUserId);
  };

  onCloseScheduleClientVideo = () => {
    this.setState({
      scheduleCallOpen: false,
      rescheduleCall: false,
      rescheduleDetails: null,
      editAllInstances: false,
    });
  };

  onOpenScheduleClientVideo = (reschedule, rescheduleDetails, offPlatform) => {
    if (reschedule) {
      if (rescheduleDetails.is_recurring) {
        this.setState({
          editSelectionOpen: true,
          rescheduleCall: reschedule,
          rescheduleDetails,
        });
      } else {
        this.setState({
          scheduleCallOpen: !this.state.scheduleCallOpen,
          rescheduleCall: reschedule,
          rescheduleDetails,
        });
      }
    } else {
      this.setState({
        scheduleCallOpen: !this.state.scheduleCallOpen,
        scheduleOffPlatform: offPlatform,
      });
    }
  };

  onToggleEditSelection = () => {
    this.setState(
      {
        editSelectionOpen: !this.state.editSelectionOpen,
      },
      () => {
        if (!this.state.editSelectionOpen) {
          this.setState({
            rescheduleCall: false,
            rescheduleDetails: null,
            editAllInstances: false,
          });
        }
      }
    );
  };

  onChangeEditSelection = (e) => {
    const { value } = e.target;
    this.setState({ editAllInstances: this.stringToBoolean(value) });
  };

  onContinueEditSelection = () => {
    this.setState({ editSelectionOpen: false, scheduleCallOpen: true });
  };

  onSubmitToggleActivation = () => {
    const { reason, note, departedTo, dropReason, churnReason, disabledDate, id } = this.state;
    const { is_active, userid } = this.props.customerDetails.details;
    if (is_active) {
      this.props.deactivateClient({
        id: userid,
        reason,
        note,
        dropReason,
        churnReason,
        departedTo,
        disabledDate,
        clientId: id,
      });
    } else {
      this.props.toggleCustomerActivation({ id: userid, clientId: id });
    }
  };

  onAddApprovedUnit = () => {
    const approvedUnit = {
      code: "",
      units: 0,
      hours: 0,
      codeId: "",
    };
    let newApprovedUnits = [...this.state.newApprovedUnits, approvedUnit];
    this.setState({
      newApprovedUnits,
    });
  };

  onRemoveApprovedUnit = (index) => {
    let updatedApprovedUnits = this.state.newApprovedUnits;
    updatedApprovedUnits.splice(index, 1);
    this.setState({
      newApprovedUnits: updatedApprovedUnits,
    });
  };

  onChangeApprovedUnitCode = (index, event) => {
    let approvedUnits = [...this.state.newApprovedUnits];
    approvedUnits[index].code = event.target.value;
    const codeId = this.props.availableInsuranceCodes.find(
      (code) => code.insurance_code == event.target.value
    ).id;
    approvedUnits[index].codeId = codeId;
    this.setState({ newApprovedUnits: approvedUnits });
  };

  onChangeApprovedUnitAmount = (index, event) => {
    let approvedUnits = [...this.state.newApprovedUnits];
    approvedUnits[index].units = event.target.value;
    approvedUnits[index].hours = event.target.value / 4;
    this.setState({ newApprovedUnits: approvedUnits });
  };

  onSubmitNewAuthorization = (isSecondaryAuth) => {
    const authObj = {
      isSecondaryAuth,
      authorizationCode: this.state.newAuthorizationCode,
      startDate: this.state.newAuthorizationStartDate,
      endDate: this.state.newAuthorizationEndDate,
      clientId: this.state.id,
      approvedUnits: isSecondaryAuth ? null : this.state.newApprovedUnits,
      authorizationType: this.state.newAuthorizationType,
      clinicianId: this.state.newAuthorizationClinicianId,
      pendingAuth: this.state.newPendingAuth,
    };
    this.props.createAuthorizationCode(authObj);
    if (authObj.authorizationType === "pre_auth") {
      this.submitEligibility();
    } else if (authObj.authorizationType === "auth") {
      this.submitApproval();
    }
    this.setState({
      newAuthorizationCode: "",
      newAuthorizationEndDate: "",
      newAuthorizationStartDate: "",
      newAuthorizationType: "",
      newAuthorizationOpen: false,
      newAuthorizationClinicianId: "",
      newPendingAuth: false,
      newApprovedUnits: [
        {
          code: "",
          units: 0,
          hours: 0,
          codeId: "",
        },
      ],
    });
  };

  onSubmitResetPassword = () => {
    const { resetPasswordUserId } = this.state;
    this.props.resetCustomerPassword(resetPasswordUserId);
    this.setState({ resetPasswordUserId: null });
  };

  handleChangeVideoPage = (event, page) => {
    this.props.setUserVideoCallsPage(page);
  };

  handleChangeVideoRowsPerPage = (event) => {
    this.props.setUserVideoCallsPerPage(parseInt(event.target.value));
    this.props.setUserVideoCallsPage(0);
  };

  handleVideoSortClick = (name) => {
    this.props.setUserVideoCallsSort({
      sort: name,
    });
  };

  viewVideoCallDetails = (id) => {
    let videoCall = "/video-calls/" + id;
    this.props.history.push(videoCall);
  };

  onCreatePHIRelease = async (physicianId, requestId) => {
    const baseUrl = process.env.BASE_URL;
    const userSession = await Auth.currentSession();
    location.href = `${baseUrl}/download/${userSession.accessToken.jwtToken}/phi-release/${physicianId}?requestid=${requestId}`;
  };

  onOpenUpdateInsurance = (isPrimary) => {
    const { userInsurance } = this.props;
    let primary = userInsurance.find((insurance) => insurance.is_primary === true) || null;
    let secondary = userInsurance.find((insurance) => insurance.is_primary === false) || null;
    this.setState({ updateInsuranceIsPrimary: isPrimary }, () => {
      if (isPrimary && primary?.insurance_id) {
        this.props.setInsuranceListProviderId(primary.insurance_id);
      } else if (!isPrimary && secondary?.insurance_id) {
        this.props.setInsuranceListProviderId(secondary.insurance_id);
      }
      this.setState({ updateInsuranceOpen: true });
    });
  };

  onCloseUpdateInsurance = () => {
    this.setState({
      updateInsuranceOpen: false,
    });
  };

  onOpenDisableInsurance = (provider) => {
    this.setState({ disableInsuranceOpen: true, disableInsurance: provider });
  };

  onCloseDisableInsurance = () => {
    this.setState({ disableInsuranceOpen: false, disableInsurance: null });
  };

  onSubmitDisableInsurance = () => {
    this.props.disableUserInsurance(this.state.disableInsurance);
    this.onCloseDisableInsurance();
  };

  onOpenNewInsurance = (isPrimary) => {
    this.setState({ newInsuranceIsPrimary: isPrimary }, () => {
      this.setState({ newInsuranceOpen: true });
    });
  };
  onCloseNewInsurance = () => {
    this.setState({
      newInsuranceOpen: false,
    });
  };

  onSubmitNewInsurance = (insuranceObject) => {
    const {
      newIsPrimary,
      newMemberId,
      newGroupNumber,
      newEffectiveDate,
      newExpirationDate,
      newInsuranceId,
      newInsuranceHolderDOB,
      newInsuranceHolderRelationship,
      newInsuranceHolderName,
      newInsurancePlanId,
      newProviderId,
    } = insuranceObject;
    const newInsurance = {
      memberId: newMemberId,
      groupNumber: newGroupNumber,
      insurancePlanId: newInsurancePlanId,
      effectiveDate: newEffectiveDate || null,
      expirationDate: newExpirationDate || null,
      isPrimary: newIsPrimary,
      userId: this.props.customerUserId,
      clientId: this.state.id,
      insuranceHolderDOB: newInsuranceHolderDOB,
      insuranceHolderRelationship: newInsuranceHolderRelationship,
      insuranceHolderName: newInsuranceHolderName,
      insuranceId: newProviderId,
    };
    this.onCloseNewInsurance();
    this.props.createUserInsurance(newInsurance);
  };

  onClickChatWithClinician = () => {
    if (this.props.customerDetails?.clinician?.support_conversation_url) {
      let url = this.props.customerDetails.clinician.support_conversation_url;
      this.props.history.push(`/chat/${url}`);
    } else {
      this.props.createSupportChat(this.props.customerDetails?.clinician);
      this.setState({ creatingSupportChat: true });
    }
  };

  onActiveTabChange = (tab) => {
    if (this.state.editedAccountStatus && tab !== this.state.currentTab) {
      this.setState({ unsavedChanges: true, tabToChange: tab });
    } else {
      if (tab !== this.state.currentTab) {
        if (!this.editableTabs.includes(tab)) {
          this.setState({ editMode: false });
        }
        this.resetTab(tab);
      }
    }
  };

  resetTab = (tab) => {
    if (this.tabsExceptions.includes(tab)) {
      this.setState({ currentTab: tab });
      return;
    }
    this.clearData(
      {
        currentTab: tab,
        nextLocation: null,
      },
      () => {
        this.props.setPageDetails({
          onEditButton: this.canEditTab(tab),
          editButtonAction: this.onEditMode,
        });
        this.refreshDataTab(tab);
      }
    );
  };

  refreshDataTab = (tab) => {
    if (tab === this.editableTabs[0] && this.editableTabs[0] === "dashboard") {
      this.props.loadCustomerDetails(this.state.id);
    } else if (tab === this.editableTabs[2] && this.editableTabs[2] === "insurance") {
      this.props.getAvailableInsuranceCodes({
        clientId: this.state.id,
        requiresAuth: true,
      });
    }
  };

  clearData = (extra = {}, callback) => {
    this.setState(
      {
        ...{
          editMode: false,
          editedAccountStatus: false,
          unsavedChanges: false,
          updatedUserStatus: null,
          updateUserStatusRisks: false,
        },
        ...extra,
      },
      () => {
        if (callback && typeof callback === "function") {
          callback();
        }
      }
    );
  };

  /**
   * This is the global method to enable the edit mode on all tabs
   */
  onEditMode = () => {
    this.onEditProfile();
  };

  onEditProfile = () => {
    if (this.canEditTab) {
      this.onOpenEditPersonalInformation();
    }
  };

  onSubmitNewInsuranceDocuments = async () => {
    const { newBackCard, newFrontCard, newInsuranceIsPrimary } = this.state;
    const { customerDetails, clientId } = this.props;
    const { details } = customerDetails;
    details.cognito_id;
    try {
      const credentials = await getAWSCredentialsForCurrentUserSession();
      const s3 = new AWS.S3({
        credentials,
        apiVersion: "2006-03-01",
        params: { Bucket: process.env.AWS_USER_DOC_BUCKET },
      });
      let fileUUID = uuidv4();
      let fileName = newInsuranceIsPrimary
        ? "insurance_card_front"
        : "secondary_insurance_card_front";
      let fileExtension = newFrontCard[0].file.name.split(".").pop();
      let params = {
        ACL: "authenticated-read",
        Body: newFrontCard[0].file,
        ContentType: newFrontCard[0].file.type,
        Key: `${details.cognito_id}/${fileName}-${fileUUID}.${fileExtension}`,
      };
      let upload = await s3.upload(params).promise();
      const frontCardDoc = {
        filename: upload.Key.split("/")[1],
        display_filename: `${fileName}.${fileExtension}`,
        clientId: clientId,
        type: newInsuranceIsPrimary ? "INSURANCE_CARD_FRONT" : "SECONDARY_INSURANCE_CARD_FRONT",
        internal: false,
        billing_related: false,
        file_size: newFrontCard[0].file.size,
        uploaded_by: this.props.currentUser.id,
      };
      this.props.uploadCustomerDocument(frontCardDoc);

      fileUUID = uuidv4();
      fileName = newInsuranceIsPrimary ? "insurance_card_back" : "secondary_insurance_card_back";
      fileExtension = newBackCard[0].file.name.split(".").pop();
      params = {
        ACL: "authenticated-read",
        Body: newBackCard[0].file,
        ContentType: newBackCard[0].file.type,
        Key: `${details.cognito_id}/${fileName}-${fileUUID}.${fileExtension}`,
      };

      upload = await s3.upload(params).promise();
      const backCardDoc = {
        filename: upload.Key.split("/")[1],
        display_filename: `${fileName}.${fileExtension}`,
        clientId: clientId,
        type: newInsuranceIsPrimary ? "INSURANCE_CARD_BACK" : "SECONDARY_INSURANCE_CARD_BACK",
        internal: false,
        billing_related: false,
        file_size: newBackCard[0].file.size,
        uploaded_by: this.props.currentUser.id,
      };
      this.props.uploadCustomerDocument(backCardDoc);
      this.setState({
        newFrontCard: [],
        newBackCard: [],
      });
    } catch (error) {
      console.log(error);
    }
  };

  openFileDialog = (name) => (e) => {
    this.setState({ [name]: true });
  };

  closeFileDialog = (name) => (e) => {
    this.setState({ [name]: false, fileObjects: [] });
  };

  addFileObjects = (fileObjects) => {
    this.setState({ fileObjects });
  };

  persistFileObjects = (name, dialog) => {
    const { fileObjects } = this.state;
    this.setState({
      [name]: fileObjects,
      fileObjects: [],
      [dialog]: false,
    });
  };

  clearImages = (name) => (e) => {
    this.setState({ [name]: [] });
  };

  stringToBoolean = (value) => {
    if (value && typeof value === "string") {
      if (value.toLowerCase() === "true") return true;
      if (value.toLowerCase() === "false") return false;
    }
    return value;
  };

  onClickCancelSession = (details) => {
    this.setState({ sessionDetails: details, cancelSessionOpen: true });
  };

  onToggleCancelSession = () => {
    this.setState(
      {
        cancelSessionOpen: !this.state.cancelSessionOpen,
      },
      () => {
        if (!this.state.cancelSessionOpen) {
          this.setState({
            cancelReasonText: "",
            responsibleForCancellation: "",
            sessionDetails: {},
            cancelAllInstances: false,
          });
        }
      }
    );
  };

  onChangeCancelSelection = (e) => {
    const { value } = e.target;
    this.setState({ cancelAllInstances: this.stringToBoolean(value) });
  };

  onChangeCancelReason = (e) => {
    this.setState({ cancelReasonText: e.target.value });
  };

  onChangeResponsibleForCancellation = (e) => {
    let cancelReasonText = getCancellationReasonLabel(e.target.value);
    this.setState({
      responsibleForCancellation: e.target.value,
      cancelReasonText,
    });
  };

  submitCancelSession = () => {
    const { sessionDetails, cancelReasonText, cancelAllInstances, responsibleForCancellation } =
      this.state;
    const params = {
      message: cancelReasonText,
      responsibleForCancellation: getKeyCancellationReason(responsibleForCancellation),
      id: sessionDetails.id,
      clinicianUserId: sessionDetails.clinician_user_id,
      clientId: sessionDetails.client_id,
      cancelAllInstances,
    };
    this.props.cancelVideoCall(params);
    this.onToggleCancelSession();
  };

  saveNote = (noteParams) => {
    this.props.saveNotes({
      clientId: this.state.id,
      notes: noteParams.newNote,
      ...noteParams,
    });
  };

  onToggleNewAuthorization = () => {
    this.setState({ newAuthorizationOpen: !this.state.newAuthorizationOpen });
  };

  onToggleNewApprovedUnit = () => {
    this.setState({ newApprovedUnitOpen: !this.state.newApprovedUnitOpen });
  };

  onToggleAssessmentComplete = () => {
    this.setState({
      assessmentCompleteOpen: !this.state.assessmentCompleteOpen,
    });
  };

  onSubmitMarkAssessmentComplete = () => {
    this.props.updateUserInsuranceStatus({
      status: "ASSESSMENT_COMPLETED",
      clientId: this.state.id,
    });
    this.onToggleAssessmentComplete();
  };

  onChangeDeactivationInfo = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        // only one should be set
        if (this.state.churnReason && !isDischargeReason(this.state.reason)) {
          this.setState({ churnReason: "" });
        }
        if (this.state.dropReason && !isDropReason(this.state.reason)) {
          this.setState({ dropReason: "" });
        }
      }
    );
  };

  onClearDeactivationInfo = () => {
    this.setState({
      reason: "",
      dropReason: "",
      churnReason: "",
      departedTo: "",
      disabledDate: new Date(),
      note: "",
    });
  };

  hasScheduledCall = () => {
    if (
      this.props.customerDetails.upcomingVideoCalls &&
      this.props.customerDetails.upcomingVideoCalls.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  onToggleTreatmentPlanDialog = () => {
    this.setState({
      treatmentDropzoneOpen: !this.state.treatmentDropzoneOpen,
      treatmentPlan: [],
    });
  };

  openTreatmentDropzone = () => {
    if (this.state.updateTreatmentPlan) {
      this.setState({ updateTreatmentDropzoneOpen: true });
    } else {
      this.setState({ treatmentDropzoneOpen: true });
    }
  };

  onCloseUpdateTreatmentPlan = () => {
    this.setState({ updateTreatmentDropzoneOpen: false });
  };

  onOpenUpdateTreatmentPlan = (treatmentPlan) => {
    this.setState({
      updateTreatmentPlan: treatmentPlan,
      updateTreatmentDropzoneOpen: true,
    });
  };

  handleCloseDropzone = () => {
    this.setState({ treatmentDropzoneOpen: false });
  };

  persistTreatmentPlan = (update) => {
    const { fileObjects } = this.state;
    this.setState(
      {
        treatmentPlan: fileObjects,
        fileObjects: [],
        treatmentDropzoneOpen: false,
        updateTreatmentDropzoneOpen: false,
      },
      () => {
        if (update) {
          this.handleUpdateTreatmentPlan();
        } else {
          this.handleSaveTreatmentPlan();
        }
      }
    );
  };

  handleSaveTreatmentPlan = async () => {
    this.setState({ treatmentPlanUploading: true });
    const { customerDetails, currentUser } = this.props;
    const { details } = customerDetails;
    const { treatmentPlan } = this.state;
    const file = treatmentPlan[0].file;
    const credentials = await getAWSCredentialsForCurrentUserSession();
    const s3 = new AWS.S3({
      credentials,
      apiVersion: "2006-03-01",
      params: { Bucket: process.env.AWS_USER_DOC_BUCKET },
    });
    const fileUUID = uuidv4();
    const params = {
      ACL: "authenticated-read",
      Body: file,
      ContentType: file.type,
      Key: `${details.cognito_id}/treatment_plan-${fileUUID}.pdf`,
    };
    const upload = await s3.upload(params).promise();
    const treatmentPlanData = {
      filename: upload.Key.split("/")[1],
      display_filename: "treatment_plan.pdf",
      clientId: this.state.id,
      type: "TREATMENT_PLAN",
      internal: false,
      billing_related: false,
      clinicianUserId: this.props.clinicianUserId,
      file_size: file.size,
      uploaded_by: this.props.clinicianUserId,
    };
    this.props.uploadTreatmentPlan(treatmentPlanData);
  };

  handleUpdateTreatmentPlan = async () => {
    this.setState({ treatmentPlanUploading: true });
    const { customerDetails, currentUser } = this.props;
    const { details } = customerDetails;
    const { updateTreatmentPlan, treatmentPlan } = this.state;
    const file = treatmentPlan[0].file;
    const credentials = await getAWSCredentialsForCurrentUserSession();

    const s3 = new AWS.S3({
      credentials,
      apiVersion: "2006-03-01",
      params: { Bucket: process.env.AWS_USER_DOC_BUCKET },
    });
    const fileUUID = uuidv4();
    const [fileName, fileExtension] = updateTreatmentPlan.filename.split(".");
    const params = {
      ACL: "authenticated-read",
      Body: file,
      ContentType: file.type,
      Key: `${updateTreatmentPlan.cognito_id}/${fileName}-${fileUUID}.${fileExtension}`,
    };
    const upload = await s3.upload(params).promise();
    const treatmentPlanData = {
      id: updateTreatmentPlan.id,
      clientId: this.state.id,
      clinicianUserId: this.props.clinicianUserId,
    };
    this.props.updateTreatmentPlan(treatmentPlanData);
  };

  onLogTreatmentPlanTime = (newTime) => {
    this.setState({
      treatmentPlanTimeLog: [...this.state.treatmentPlanTimeLog, newTime],
    });
  };

  onRemoveTreatmentPlanTime = (index) => {
    let updatedTimeLog = [...this.state.treatmentPlanTimeLog];
    updatedTimeLog.splice(index, 1);
    this.setState({ treatmentPlanTimeLog: updatedTimeLog });
  };

  closeSessionNotesDialog = () => {
    this.setState({ sessionNotesOpen: false });
  };

  startNewVideoCallNow = () => {
    const { currentUser, customerUserId, customerDetails, clientId } = this.props;
    const { sessionNowBillingType } = this.state;
    // We want to have a time that is available in the new schedule page
    const startDate = roundDownToNearest15(new Date());
    let videoDetails = {
      startDate,
      endDate: addMinutes(startDate, 60),
      bcbaEmail: currentUser.username,
      timezone: this.state.clientTimezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
      clinicianUserId: currentUser.id,
      clientId: clientId,
      isTest: false,
      billingType: sessionNowBillingType,
      isRepeating: false,
      repeatDays: [],
      repeatEndType: "",
      callInstructions: "",
    };
    this.props.clearVideoInfo();
    this.props.scheduleVideoCall(videoDetails);
  };

  onToggleStartNewVideoDialog = () => {
    this.setState({
      startNewVideoDialogOpen: !this.state.startNewVideoDialogOpen,
      sessionNowBillingType: "",
    });
  };

  openSessionNotesDialog = () => {
    this.setState({
      sessionNotesOpen: true,
      updateTreatmentPlan: null,
      treatmentPlan: [],
    });
  };

  formatPhoneNumber = (val) => {
    let formatedPhone = val
      ? val.replace(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/g, "$1-$2-$3")
      : val;
    return formatedPhone;
  };

  scrollToInsuranceAuthorization = () => {
    this.insuranceAuthorizationRef.scrollIntoView({
      block: "start",
      inline: "nearest",
      behavior: "smooth",
    });
  };

  onSubmitUpdateUserEmail = () => {
    this.props.updateClientEmail({
      userId: this.props.customerDetails.details.userid,
      newEmail: this.state.newClientEmail.toLowerCase(),
      clientId: this.state.id,
    });
  };

  onDownloadSignedConsentForm = async () => {
    const baseUrl = process.env.BASE_URL;
    const userSession = await Auth.currentSession();
    location.href = `${baseUrl}/download/${userSession.accessToken.jwtToken}/informed-consent/${this.props.match.params.id}`;
  };

  onDownloadSignedECommConsentForm = async () => {
    const baseUrl = process.env.BASE_URL;
    const userSession = await Auth.currentSession();
    location.href = `${baseUrl}/download/${userSession.accessToken.jwtToken}/electronic-communication-consent/${this.props.customerDetails.details.userid}`;
  };

  validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  setError = (name, error) => {
    this.setState({ [name]: error });
  };

  onTimezoneChange = (e) => {
    this.setState({
      clientTimezone: e.target.value,
      timezoneSubmitDisabled: false,
    });
  };

  onSubmitUpdateUserTimezone = () => {
    this.props.updateCustomerTimezone({
      clientId: this.state.id,
      timezone: this.state.clientTimezone,
    });
  };

  mapRelationshipToDisplay = (relationship) => {
    switch (relationship) {
      case "caregiver":
        return "Caregiver";
      case "child":
        return "Child";
      case "dependent":
        return "Dependent";
      case "family_member":
        return "Family Member";
      case "legal_guardian":
        return "Legal Guardian";
      case "is_parent":
        return "Parent";
      case "spouse":
        return "Spouse";
    }
  };

  onOpenCreateAccount = (contactInfo) => {
    this.setState({
      createAccountOpen: true,
      newAccountInfo: contactInfo,
    });
  };

  onCloseCreateAccount = () => {
    this.setState({
      createAccountOpen: false,
    });
  };

  onClosePrimaryAccountSelection = () => {
    this.setState({
      primaryAccountSelectionOpen: false,
      primaryAccountHolderId: null,
      availableAccounts: [],
      primaryAccountConfirmationOpen: false,
      newAccountInfo: {},
    });
  };

  onSelectPrimaryAccountHolder = (e) => {
    const { value } = e.target;
    this.setState({ primaryAccountHolderId: value });
  };

  onOpenPrimaryAccountConfirmation = () => {
    const { newAccountInfo, primaryAccountHolderId } = this.state;
    if (newAccountInfo.id == primaryAccountHolderId) {
      this.setState({
        primaryAccountConfirmationOpen: true,
      });
    } else {
      this.importNewFamilyMember();
    }
  };

  onClosePrimaryAccountConfirmation = () => {
    this.setState({ primaryAccountConfirmationOpen: false });
  };

  onOpenEditPersonalInformation = () => {
    const { customerDetails } = this.props;
    let customerInfo = customerDetails.details;
    this.setState({
      firstName: customerInfo?.name || "",
      lastName: customerInfo?.child_last_name || "",
      dob: dateWithoutTimezone(customerInfo?.dob) || null,
      gender: customerInfo?.gender || "",
      pronouns: customerInfo?.pronouns || "",
      clientTimezone: customerInfo?.timezone_jhi || "",
      address1: customerInfo?.line1 || "",
      address2: customerInfo?.line2 || "",
      city: customerInfo?.city || "",
      state: customerInfo?.state || "",
      zipCode: customerInfo?.zip || "",
      editMode: true,
      clientDiagnosis: customerInfo?.diagnosis || "",
      therapeuticConsultation: customerInfo?.therapeutic_consultation,
      preAuthRequired: customerInfo?.pre_auth_required,
      ethnicity: customerInfo?.ethnicity,
      primaryLanguage: customerInfo?.primary_language,
      preferredFirstName: customerInfo?.preferred_first_name,
      transgender: customerInfo?.transgender,
      identifiedGender: customerInfo?.identified_gender || "",
      needsInterpreter: customerInfo?.needs_interpreter,
      medicalConditions: customerInfo?.medical_conditions || "",
      livesWith: customerInfo?.lives_with || "",
      requiresTablet: customerInfo.requires_tablet,
    });
    this.props.setPageDetails({
      onEditButton: false,
    });
  };

  onCloseEditMode = (isCancelClicked = false) => {
    if (this.state.unsavedChanges) {
      this.setState({ openWarning: true, isCancelClicked });
    } else {
      this.resetTab(this.state.currentTab);
    }
  };

  /**
   * This method enable the button based on the permissions of the client profile
   * @param tab actual tab
   * @returns {boolean}
   */
  canEditTab = (tab) => {
    let havePermissions = false;
    // If the user is a caregiver, they should not be able to edit the client profile
    if (this.props.isCaregiver) return false;
    if (
      tab === this.editableTabs[1] &&
      this.props.userPermissions?.edit_client_availability &&
      this.editableTabs.includes(tab)
    ) {
      havePermissions = true;
    }
    if (
      tab === this.editableTabs[2] &&
      (this.props.userPermissions?.send_reset_password ||
        this.props.userPermissions?.override_client_workflow_state ||
        this.props.userPermissions?.disable_client ||
        this.props.userPermissions?.update_client_email_address ||
        this.props.userPermissions?.edit_client_info ||
        this.props.userPermissions?.enable_client_static_page) &&
      this.editableTabs.includes(tab)
    ) {
      havePermissions = true;
    }

    if (
      tab === this.editableTabs[3] &&
      (this.props.userPermissions?.add_client_insurance ||
        this.props.userPermissions?.download_phi_release_request ||
        this.props.userPermissions?.create_phi_release_request ||
        this.props.userPermissions?.add_client_insurance_authorization) &&
      this.editableTabs.includes(tab)
    ) {
      havePermissions = true;
    }

    this.setState({ editButton: havePermissions });
    return havePermissions;
  };

  getPhysicianId = (physicians, type) => {
    if (!physicians) return null;
    const physician = physicians.find((p) => p.physician_type === type);
    return physician ? physician.id : null;
  };

  onOpenEditPhysicianInformation = () => {
    const diagnosingPhysicianId = this.getPhysicianId(
      this.props.customerDetails.physicians,
      "diagnosing"
    );
    const primaryCarePhysicianId = this.getPhysicianId(
      this.props.customerDetails.physicians,
      "primary care"
    );
    this.setState({
      editPhysicianInfoOpen: true,
      primaryPhysician: primaryCarePhysicianId,
      diagnosingPhysician: diagnosingPhysicianId,
    });
  };

  onCloseEditPhysicianInformation = () => {
    this.setState({ editPhysicianInfoOpen: false });
  };

  editPhysicianInfo = () => {
    const { primaryPhysician, diagnosingPhysician } = this.state;
    const physicianInfo = {
      clientId: this.state.id,
      payload: {
        physicians: {
          diagnosing: diagnosingPhysician,
          primaryCare: primaryPhysician,
        },
      },
    };
    this.props.updateLinkPhysician(physicianInfo);
  };

  setUnsavedChanges = (value) => {
    this.setState({ unsavedChanges: value });
  };

  onSendSMSMessageByTeam = (message, memberId) => {
    this.props.sendSMSToCaregiver({
      clientId: this.state.id,
      memberId: memberId,
      message,
    });
  };

  onSendSMSMessage = (message) => {
    this.props.sendSMSToCaregiver({
      clientId: this.state.id,
      message,
    });
  };

  onOpenSMSFacilityDialog = () => {
    if (this.smsPolling) {
      clearInterval(this.smsPolling);
    }
    this.setState({ smsFacilityDialogOpen: true }, () => {
      this.smsPolling = setInterval(() => this.props.getSMSThreadByClient(this.state.id), 5000);
    });
  };

  onCloseSMSFacilityDialog = () => {
    this.setState({ smsFacilityDialogOpen: false }, () => {
      clearInterval(this.smsPolling);
    });
    // Clears params from URL
    let pathname = this.props.history.location.pathname;
    this.props.history.replace({ pathname, search: "" });
  };

  getTabs = () => {
    const { userPermissions, assignedToLearner } = this.props;
    const displayActionsTab =
      userPermissions?.send_reset_password ||
      userPermissions?.override_client_workflow_state ||
      userPermissions?.disable_client ||
      userPermissions?.update_client_email_address ||
      userPermissions?.edit_client_info ||
      userPermissions?.enable_client_static_page;

    const displayClientAvailabilityTab =
      userPermissions?.edit_client_availability ||
      userPermissions?.view_client_availability ||
      assignedToLearner;

    return [
      { label: "Dashboard", link: "dashboard", hide: this.props.isCaregiver },
      { label: "Profile", link: "profile", hide: false },
      { label: "Availability", link: "client-availability", hide: !displayClientAvailabilityTab },
      { label: "Providers & Insurance", link: "insurance", hide: this.props.isCaregiver },
      { label: "Assessments", link: "assessments", hide: false },
      { label: "Documents", link: "documents", hide: false },
      { label: "Communication", link: "communication", hide: this.props.isCaregiver },
      { label: "Logs", link: "logs", hide: this.props.isCaregiver },
      {
        label: "Account Settings",
        link: "account-settings",
        hide: !displayActionsTab || this.props.isCaregiver,
      },
    ];
  };

  onContinueWithoutSaving = () => {
    this.clearData({ openWarning: false }, () => {
      if (this.state.nextLocation) {
        this.navigate(this.state.nextLocation);
      } else {
        this.resetTab(this.state.currentTab);
      }
    });
  };

  render() {
    const { classes, loaded, customerDetails, userPermissions, currentUser, isClinician } =
      this.props;
    const { fileObjects, snackBarOpen, snackBarMessage, currentTab, unsavedChanges, editButton } =
      this.state;

    return (
      userPermissions && (
        <div>
          <Prompt
            when={(unsavedChanges || editButton) && this.editableTabs.includes(currentTab)}
            message={(nextLocation, action) => {
              if (unsavedChanges) {
                if (this.currentPath !== nextLocation.pathname && action === "POP") {
                  window.history.forward();
                }

                this.setState({
                  openWarning: true,
                  isCancelClicked: false,
                  nextLocation: nextLocation.pathname,
                });

                return false;
              }

              if (editButton) {
                this.props.setPageDetails({
                  onEditButton: false,
                  editButtonAction: null,
                });
                this.setState({ editButton: false });
              }
            }}
          />
          <SaveBeforeNavDialog
            saveBeforeNavOpen={this.state.openWarning}
            onToggleSaveBeforeNav={() => {
              this.setState({ openWarning: false });
            }}
            onContinueWithoutSaving={this.onContinueWithoutSaving}
            severetyAlert={"warning"}
            primaryActionColorButton={"error"}
            secondaryText={"Stay on Page"}
            isCancelClicked={this.state.isCancelClicked}
          />
          <ClientDetailsTabs
            currentTab={currentTab}
            userPermissions={userPermissions}
            classes={classes}
            tabs={this.getTabs()}
          />
          {!loaded || !customerDetails ? (
            <div className={classes.loadingContainer}>
              <CircularProgress size={30} color="primary" style={{ marginBottom: 10 }} />
              <SubHeading>Loading</SubHeading>
            </div>
          ) : (
            <div className={classes.root}>
              {this.state.currentTab === "dashboard" ? (
                <TimelineStatistics
                  onToggleAssessmentComplete={this.onToggleAssessmentComplete}
                  onOpenUpdateTreatmentPlan={this.onOpenUpdateTreatmentPlan}
                  onToggleTreatmentPlanDialog={this.onToggleTreatmentPlanDialog}
                  onToggleScheduleClientVideo={this.onOpenScheduleClientVideo}
                  userId={customerDetails?.details?.userid}
                  customerDetails={customerDetails}
                  userPermissions={userPermissions}
                  currentUser={currentUser}
                  isClinician={isClinician}
                  onToggleAssignClinician={() => this.onOpenAssignClinician("primary", true)}
                  onResendLoginEmail={this.onResendLoginEmail}
                  submitEligibility={this.submitEligibility}
                  submitApproval={this.submitApproval}
                  formatPhoneNumber={this.formatPhoneNumber}
                  {...this.state}
                  {...this.props}
                />
              ) : (
                ""
              )}
              <ClientDetailsPageContents
                {...this.props}
                {...this.state}
                clientId={this.props.clientId ?? this.state.id}
                visibleTabs={this.getTabs().filter(({ hide }) => !hide)}
                userPermissions={userPermissions}
                setEditMode={(newState) => this.setState({ editMode: newState })}
                userInsurance={this.props.userInsurance}
                customerDetails={customerDetails}
                onChange={this.onChange}
                onChangeNumber={this.onChangeNumber}
                submitAssignClinician={this.submitAssignClinician}
                navigate={this.navigate}
                submitEligibility={this.submitEligibility}
                submitApproval={this.submitApproval}
                onToggleUploadDocument={this.onToggleUploadDocument}
                onToggleUploadDocumentWithType={this.onToggleUploadDocumentWithType}
                onToggleUpdateDocument={this.onToggleUpdateDocument}
                onToggleApproveDocument={this.onToggleApproveDocument}
                getDocumentVersions={this.getDocumentVersions}
                onOpenViewDocument={this.onOpenViewDocument}
                onCloseViewDocument={this.onCloseViewDocument}
                onChangeUploadType={this.onChangeUploadType}
                openDocumentDialog={this.onToggleDocumentDialog}
                submitDocumentUpload={this.submitDocumentUpload}
                onClickCheckbox={this.onClickCheckbox}
                clearDocument={this.clearDocument}
                onSubmitUpdateUserStatus={this.onSubmitUpdateUserStatus}
                onChangeUserStatus={this.onChangeUserStatus}
                onResendLoginEmail={this.onResendLoginEmail}
                onToggleScheduleClientVideo={this.onOpenScheduleClientVideo}
                onChangeDate={this.onChangeDate}
                onToggleNewAuthorization={this.onToggleNewAuthorization}
                onToggleNewApprovedUnit={this.onToggleNewApprovedUnit}
                onChangeApprovedUnitCode={this.onChangeApprovedUnitCode}
                onChangeApprovedUnitAmount={this.onChangeApprovedUnitAmount}
                onAddApprovedUnit={this.onAddApprovedUnit}
                onRemoveApprovedUnit={this.onRemoveApprovedUnit}
                onSubmitNewAuthorization={this.onSubmitNewAuthorization}
                onToggleResetPassword={this.onToggleResetPassword}
                onSubmitResetPassword={this.onSubmitResetPassword}
                handleChangeVideoPage={this.handleChangeVideoPage}
                handleChangeVideoRowsPerPage={this.handleChangeVideoRowsPerPage}
                handleVideoSortClick={this.handleVideoSortClick}
                viewVideoCallDetails={this.viewVideoCallDetails}
                onCreatePHIRelease={this.onCreatePHIRelease}
                onOpenUpdateInsurance={this.onOpenUpdateInsurance}
                onCloseUpdateInsurance={this.onCloseUpdateInsurance}
                onOpenDisableInsurance={this.onOpenDisableInsurance}
                onCloseDisableInsurance={this.onCloseDisableInsurance}
                onSubmitDisableInsurance={this.onSubmitDisableInsurance}
                onOpenNewInsurance={this.onOpenNewInsurance}
                onCloseNewInsurance={this.onCloseNewInsurance}
                onSubmitNewInsurance={this.onSubmitNewInsurance}
                onClickChatWithClinician={this.onClickChatWithClinician}
                clearImages={this.clearImages}
                openFileDialog={this.openFileDialog}
                saveNote={this.saveNote}
                onToggleAssessmentComplete={this.onToggleAssessmentComplete}
                setBillableTimePeriod={this.setBillableTimePeriod}
                isActive={this.props.customerDetails?.details.is_active}
                hasScheduledCall={this.hasScheduledCall()}
                onToggleTreatmentPlanDialog={this.onToggleTreatmentPlanDialog}
                openTreatmentDropzone={this.openTreatmentDropzone}
                onOpenUpdateTreatmentPlan={this.onOpenUpdateTreatmentPlan}
                onOpenAssignClinician={this.onOpenAssignClinician}
                onCloseAssignClinician={this.onCloseAssignClinician}
                onOpenAssignClinicianConfirm={this.onOpenAssignClinicianConfirm}
                onCloseAssignClinicianConfirm={this.onCloseAssignClinicianConfirm}
                onOpenRemoveClinicianConfirm={this.onOpenRemoveClinicianConfirm}
                scrollToAssignBlock={this.scrollToAssignBlock}
                assignBlockRef={(ref) => (this.assignBlockRef = ref)}
                onToggleStartNewVideoDialog={this.onToggleStartNewVideoDialog}
                handleSaveTreatmentPlan={this.handleSaveTreatmentPlan}
                handleUpdateTreatmentPlan={this.handleUpdateTreatmentPlan}
                onLogTreatmentPlanTime={this.onLogTreatmentPlanTime}
                onRemoveTreatmentPlanTime={this.onRemoveTreatmentPlanTime}
                formatPhoneNumber={this.formatPhoneNumber}
                booleanToDisplay={this.booleanToDisplay}
                onOpenEditPhysicianInformation={this.onOpenEditPhysicianInformation}
                onCloseEditPhysicianInformation={this.onCloseEditPhysicianInformation}
                onSubmitToggleActivation={this.onSubmitToggleActivation}
                onChangeDeactivationInfo={this.onChangeDeactivationInfo}
                onClearDeactivationInfo={this.onClearDeactivationInfo}
                insuranceAuthorizationRef={(ref) => (this.insuranceAuthorizationRef = ref)}
                scrollToInsuranceAuthorization={this.scrollToInsuranceAuthorization}
                onSubmitUpdateUserEmail={this.onSubmitUpdateUserEmail}
                onDownloadSignedConsentForm={this.onDownloadSignedConsentForm}
                onDownloadSignedECommConsentForm={this.onDownloadSignedECommConsentForm}
                validateEmail={this.validateEmail}
                setError={this.setError}
                onTimezoneChange={this.onTimezoneChange}
                onSubmitUpdateUserTimezone={this.onSubmitUpdateUserTimezone}
                onClickCancelSession={this.onClickCancelSession}
                mapRelationshipToDisplay={this.mapRelationshipToDisplay}
                onOpenCreateAccount={this.onOpenCreateAccount}
                onOpenChangePrimaryAccount={this.onOpenChangePrimaryAccount}
                onCloseEditMode={this.onCloseEditMode}
                resetTab={this.resetTab}
                onSendSMSMessage={this.onSendSMSMessageByTeam}
                onOpenSMSFacilityDialog={this.onOpenSMSFacilityDialog}
                onCloseSMSFacilityDialog={this.onCloseSMSFacilityDialog}
                editPhysicianInfo={this.editPhysicianInfo}
                onActiveTabChange={this.onActiveTabChange}
                currentTab={this.state.currentTab}
                onChangeArrayToString={this.onChangeArrayToString}
                onChangeAvailability={this.onChangeAvailability}
                setUnsavedChanges={this.setUnsavedChanges}
              />
            </div>
          )}
          <DropzoneDialogBase
            open={this.state.treatmentDropzoneOpen}
            filesLimit={1}
            acceptedFiles={[".pdf"]}
            fileObjects={fileObjects}
            showPreviews={false}
            showPreviewsInDropzone={true}
            maxFileSize={15728640}
            onClose={this.handleCloseDropzone}
            dropzoneText={"Drag and drop or click here"}
            dialogProps={{
              style: { zIndex: 40003 },
            }}
            onDelete={(deleteFileObj) => {
              this.addFileObjects([]);
            }}
            onSave={() => this.persistTreatmentPlan(false)}
            onAdd={(newFileObjs) => {
              this.addFileObjects(newFileObjs);
            }}
            submitButtonText="Save"
          />
          <DropzoneDialogBase
            open={this.state.updateTreatmentDropzoneOpen}
            filesLimit={1}
            acceptedFiles={[".pdf"]}
            showPreviews={false}
            showPreviewsInDropzone={true}
            maxFileSize={15728640}
            fileObjects={fileObjects}
            onClose={this.onCloseUpdateTreatmentPlan}
            dropzoneText={"Drag and drop or click here"}
            dialogProps={{
              style: { zIndex: 40003 },
            }}
            onDelete={(deleteFileObj) => {
              this.addFileObjects([]);
            }}
            onSave={() => this.persistTreatmentPlan(true)}
            onAdd={(newFileObjs) => {
              this.addFileObjects(newFileObjs);
            }}
            submitButtonText="Save"
          />
          <DropzoneDialog
            open={this.state.uploadDropzoneOpen}
            filesLimit={1}
            onSave={this.uploadUserDocument}
            acceptedFiles={[
              "image/*",
              "application/pdf",
              "application/msword",
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              "application/rtf",
              "application/zip",
              "text/plain",
            ]}
            showPreviews={false}
            showPreviewsInDropzone={true}
            maxFileSize={15728640}
            onClose={this.onToggleDocumentDialog}
            dropzoneText={"Drag and drop or click here"}
            submitButtonText="Attach"
            dialogProps={{
              style: { zIndex: 40003 },
            }}
          />
          <DropzoneDialogBase
            dialogTitle="Add Front of Insurance Card"
            acceptedFiles={["image/*"]}
            fileObjects={this.state.fileObjects}
            filesLimit={1}
            cancelButtonText={"Cancel"}
            submitButtonText={"OK"}
            maxFileSize={15728640}
            open={this.state.newFrontCardOpen}
            onDelete={(deleteFileObj) => {
              this.addFileObjects([]);
            }}
            onSave={() => {
              this.persistFileObjects("newFrontCard", "newFrontCardOpen");
            }}
            onAdd={(newFileObjs) => {
              this.addFileObjects(newFileObjs);
            }}
            onClose={this.closeFileDialog("newFrontCardOpen")}
            showPreviews={true}
            showFileNamesInPreview={true}
            dialogProps={{
              style: { zIndex: 40003 },
            }}
          />
          <DropzoneDialogBase
            dialogTitle="Add Back of Insurance Card"
            acceptedFiles={["image/*"]}
            fileObjects={this.state.fileObjects}
            filesLimit={1}
            cancelButtonText={"Cancel"}
            submitButtonText={"OK"}
            maxFileSize={15728640}
            open={this.state.newBackCardOpen}
            onDelete={(deleteFileObj) => {
              this.addFileObjects([]);
            }}
            onAdd={(newFileObjs) => {
              this.addFileObjects(newFileObjs);
            }}
            onClose={this.closeFileDialog("newBackCardOpen")}
            onSave={() => {
              this.persistFileObjects("newBackCard", "newBackCardOpen");
            }}
            showPreviews={true}
            showFileNamesInPreview={true}
            dialogProps={{
              style: { zIndex: 40003 },
            }}
          />
          <AssignClinicianConfirmDialog
            open={this.state.assignClinicianConfirmOpen}
            closeDialog={this.onCloseAssignClinicianConfirm}
            assignedRole={this.state.assignedRole}
            submitAssignClinician={this.submitAssignClinician}
            selectedClinician={this.state.selectedClinician}
            confirmSent={this.state.confirmSent}
          />
          <RemoveClinicianConfirmDialog
            open={this.state.removeClinicianConfirmOpen}
            closeDialog={this.onCloseRemoveClinicianConfirm}
            removeSecondaryClinician={this.removeSecondaryClinician}
            selectedClinician={this.state.selectedClinician}
            confirmSent={this.state.confirmSent}
          />
          <UploadDocumentDialog
            onToggleUploadDocument={this.onToggleUploadDocument}
            clearDocument={this.clearDocument}
            openDocumentDialog={this.onToggleDocumentDialog}
            onChangeUploadType={this.onChangeUploadType}
            onClickCheckbox={this.onClickCheckbox}
            submitDocumentUpload={this.submitDocumentUpload}
            {...this.props}
            {...this.state}
          />
          <UpdateDocumentDialog
            onToggleUpdateDocument={this.onToggleUpdateDocument}
            onToggleUploadDocumentWithType={this.onToggleUploadDocumentWithType}
            onToggleApproveDocument={this.onToggleApproveDocument}
            {...this.props}
            {...this.state}
          />
          <ApproveDocumentDialog
            onToggleApproveDocument={this.onToggleApproveDocument}
            onApproveDocument={this.onApproveDocument}
            {...this.state}
            {...this.props}
          />
          <DocumentVersionsDialog
            onToggleDocumentVersions={this.onToggleDocumentVersions}
            {...this.props}
            {...this.state}
          />
          <DisableInsuranceDialog
            disableInsuranceOpen={this.state.disableInsuranceOpen}
            onCloseDisableInsurance={this.onCloseDisableInsurance}
            onSubmitDisableInsurance={this.onSubmitDisableInsurance}
          />
          <CancelSessionDialog
            sessionDetails={this.state.sessionDetails}
            open={this.state.cancelSessionOpen}
            cancelReasonText={this.state.cancelReasonText}
            responsibleForCancellation={this.state.responsibleForCancellation}
            closeDialog={this.onToggleCancelSession}
            onChangeCancelReason={this.onChangeCancelReason}
            onChangeResponsibleForCancellation={this.onChangeResponsibleForCancellation}
            submitCancelSession={this.submitCancelSession}
            cancelAllInstances={this.state.cancelAllInstances}
            onChangeCancelSelection={this.onChangeCancelSelection}
          />
          <EditSelectionDialog
            sessionDetails={this.state.rescheduleDetails}
            open={this.state.editSelectionOpen}
            editAllInstances={this.state.editAllInstances}
            closeDialog={this.onToggleEditSelection}
            onChangeEditSelection={this.onChangeEditSelection}
            onContinueEditSelection={this.onContinueEditSelection}
          />
          <MarkAssessmentCompleteDialog
            isClinician={isClinician}
            onToggleAssessmentComplete={this.onToggleAssessmentComplete}
            onSubmitMarkAssessmentComplete={this.onSubmitMarkAssessmentComplete}
            open={this.state.assessmentCompleteOpen}
          />

          <Modal
            open={this.state.startNewVideoDialogOpen}
            title="Start a Session Now"
            loading={this.props.scheduleCallLoading}
            content={
              <div
                style={{
                  width: 600,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Typography component="p" style={{ margin: "20px 0" }}>
                  This will schedule a new session right now for your client to join as they
                  normally would, and will navigate you directly into the waiting room of the call.
                </Typography>
                <TextField
                  id="sessionNowBillingType"
                  label="Billing Type"
                  name="sessionNowBillingType"
                  value={this.state.sessionNowBillingType}
                  onChange={this.onChange("sessionNowBillingType")}
                  select
                  fullWidth
                >
                  <MenuItem value="" disabled />
                  <MenuItem value="ORIENTATION">Orientation</MenuItem>
                  <MenuItem value="ASSESSMENT">Assessment</MenuItem>
                  <MenuItem value="DIRECT_THERAPY">Direct Therapy</MenuItem>
                  <MenuItem value="CAREGIVER_TRAINING">Caregiver Training</MenuItem>
                </TextField>
              </div>
            }
            primaryActionText="Start Now"
            primaryActionOnClick={this.startNewVideoCallNow}
            primaryActionDisabled={!this.state.sessionNowBillingType}
            secondaryActionText="Cancel"
            secondaryActionOnClick={this.onToggleStartNewVideoDialog}
          />

          <Snackbar
            autoHideDuration={10000}
            onClose={() =>
              this.setState({
                snackBarOpen: false,
                snackBarError: false,
                snackBarMessage: "",
              })
            }
            open={snackBarOpen}
          >
            <Typography>{snackBarMessage}</Typography>
          </Snackbar>
        </div>
      )
    );
  }
}

/**
 * Functional wrapper component for ClientDetailsContainer.
 *
 * This component handles loading and access control for the client details view.
 * It uses React Query to fetch access data and displays a loading state or an error message based on the query results.
 *
 * @param {Object} props - The component props.
 * @param {string} props.clinicianUserId - The unique identifier for the clinician user.
 * @param {string} props.clientId - The unique identifier for the client.
 * @returns {React.ReactElement} - The rendered ClientDetailsContainer component with loading and access control.
 */
const FunctionalWrapperClientDetails = (props) => {
  const { showLoading, isLoading } = useGlobalPageLoading();
  const { showToast } = useGlobalToast();
  const { clinicianUserId, clientId, history } = props;

  const { data, isError } = useQuery(
    ["getHasClientAccountAccess", clinicianUserId, clientId],
    () => getHasClientAccountAccess({ userId: clinicianUserId, clientId }),
    {
      enabled: !!clinicianUserId && !!clientId, // Only enable the query when both IDs are defined
      onSuccess: ({ data }) => {
        if (data.hasAccess) {
          showLoading(false);
        } else {
          history.push("/dashboard");
          showToast({ error: true, message: "You do not have access to this client" });
        }
      },
      onError: () => {
        history.push("/dashboard");
        showToast({ error: true, message: "Error accessing this client" });
      },
    }
  );

  return <ClientDetailsContainer {...props} isLoading={isLoading} />;
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(FunctionalWrapperClientDetails)
);
