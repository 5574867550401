export default (theme) => ({
  mainBody: {
    width: "100%",
    marginTop: theme.spacing(8),
    padding: 25,
  },
  mainPaper: {
    padding: 25,
  },
  bold: {
    fontWeight: 500,
  },
  errorMessage: {
    margin: 20,
    padding: 20,
    backgroundColor: "#fff",
    border: "1px solid red",
  },
  tableLink: {
    color: "#9575cd",
  },
  clickableRow: {
    cursor: "pointer",
  },
  tableRoot: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  toolbarRow: {
    backgroundColor: "#e9e9e9",
  },
  messagesContainer: {
    paddingTop: 25,
    paddingBottom: 25,
    width: "100%",
  },
  parentMessage: {
    textAlign: "left",
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 15,
    scrollMarginTop: "70px",
  },
  clinicianMessage: {
    textAlign: "left",
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: 15,
    scrollMarginTop: "70px",
  },
  messageBlock: {
    display: "block",
    width: "40%",
  },
  messageBlue: {
    display: "block",
    padding: 12,
    backgroundColor: "#2196f3",
    color: "#fff",
    borderRadius: 6,
  },
  messageGray: {
    display: "block",
    padding: 12,
    backgroundColor: "#999999",
    color: "#fff",
    borderRadius: 6,
  },
  messageTime: {
    width: "40%",
    display: "block",
    marginTop: 5,
    fontSize: ".7rem",
    color: "#666",
  },
  messageSender: {
    width: "fit-content",
    display: "block",
    marginBottom: 5,
    fontSize: ".7rem",
    "&:hover": {
      cursor: "pointer",
      fontWeight: "500",
    },
  },
  smallText: {
    fontSize: ".8rem",
    color: "#666",
  },
  tagContainer: {
    padding: theme.spacing(2),
  },
  dialogContent: {
    minWidth: 250,
    margin: 0,
  },
  tagsLoading: {
    minWidth: 250,
    minHeight: 150,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  currentTag: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  deleteIcon: {
    color: "red",
  },
  messageTag: {
    width: "100%",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  goBackButton: {
    marginBottom: 20,
    "& span": {
      textTransform: "none",
      fontSize: 15,
    },
  },
  goBackIconContainer: {
    height: 18,
    width: 18,
    borderRadius: 9,
    backgroundColor: "#505050",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});
