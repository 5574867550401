import React from "react";

const RadioCheckmark = ({ stroke }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <circle
      className="radioCheckmark"
      cx="12"
      cy="12"
      r="8"
      fill="white"
      stroke={stroke}
      strokeWidth="8"
    />
  </svg>
);

export default RadioCheckmark;
