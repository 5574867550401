import { getClinicianLiabilityInsurance } from "api/clinician";
import { CLINICIAN_LIABILITY_INSURANCE } from "constants/reactQueryKeys";
import { useQuery } from "react-query";

export const useGetClinicianLiabilityInsurance = (clinicianId) => {
  const { data, isLoading, ...rest } = useQuery(
    [CLINICIAN_LIABILITY_INSURANCE],
    () => getClinicianLiabilityInsurance(clinicianId),
    {
      enabled: !!clinicianId,
    }
  );
  return {
    liabilityInsuranceList: data?.data || [],
    liabilityLoading: isLoading,
    ...rest,
  };
};
