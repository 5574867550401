import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import Layout from "./Layout";
import actions from "../../actions";
import { useIdleTimer } from "react-idle-timer";

const LayoutContainer = ({ signOut, ...props }) => {
  const dispatch = useDispatch();
  const idleTimerRef = useRef(null);

  const handleOnIdle = () => {
    dispatch(actions.signOut(true));
  };

  const idleTimer = useIdleTimer({
    ref: idleTimerRef,
    timeout: 1000 * 60 * 60,
    onIdle: handleOnIdle,
    debounce: 1000,
    crossTab: true,
    syncTimers: 500,
  });

  return <Layout {...props} idleTimer={idleTimer} />;
};

export default LayoutContainer;
