import React from "react";
import { addHours, format, isToday, isTomorrow } from "date-fns";
import { Typography } from "@mui/material";

import { convertTokens } from "@date-fns/upgrade/v2";

const DueDate = (props) => {
  const { classes, video_call_end_date, note_completed } = props;
  if (!video_call_end_date) {
    return null;
  }
  const dueDate = addHours(new Date(video_call_end_date), 48);
  if (dueDate < new Date() && !note_completed) {
    return (
      <Typography component="p" variant="subtitle2" color="error">
        Past Due
      </Typography>
    );
  } else if (isToday(new Date(dueDate)) && !note_completed) {
    return (
      <Typography component="p" variant="subtitle2" color="error">
        Today @ {format(new Date(dueDate), convertTokens("hh:mm A"))}
      </Typography>
    );
  } else if (isTomorrow(new Date(dueDate)) && !note_completed) {
    return (
      <Typography component="p" variant="subtitle2" className={classes.dueTomorrow}>
        Tomorrow @ {format(new Date(dueDate), convertTokens("hh:mm A"))}
      </Typography>
    );
  } else {
    return (
      <Typography component="p" variant="body2">
        {format(new Date(dueDate), convertTokens("MM/DD/YY"))}
      </Typography>
    );
  }
};

export default DueDate;
