import React from "react";
import { withRouter, useHistory } from "react-router-dom";
import { withStyles } from "@mui/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import styles from "./styles";
import { Box, Divider } from "@mui/material";

const ClientDetailsTabs = ({ currentTab, tabs, classes }) => {
  const history = useHistory();
  const handleTabChange = (event, newValue) => {
    history.push(`./${newValue}`);
  };

  return (
    <Box sx={{ position: "sticky", top: 0, zIndex: 1000, backgroundColor: "#FFF" }}>
      <Tabs
        indicatorColor="secondary"
        textColor="primary"
        value={currentTab}
        onChange={handleTabChange}
        aria-label="client-details-tabs"
        variant="scrollable"
      >
        {tabs.map((tab) =>
          tab.hide ? null : <Tab key={tab.link} value={tab.link} label={tab.label} disableRipple />
        )}
      </Tabs>
      <Divider />
    </Box>
  );
};

export default withRouter(withStyles(styles)(ClientDetailsTabs));
