import React from "react";
import { useFormik } from "formik";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Grid,
} from "@mui/material";
import { X } from "@phosphor-icons/react";
import LoadingButton from "@mui/lab/LoadingButton";

import ANUploader from "elements/ANUploader";
import ANDatePicker from "elements/ANDatePicker";
import { liabilityValidationSchema } from "./validationSchema";
import { getDate } from "./utils";
/** @typedef {import("api/typesDef").LiabilityInsurance} LiabilityInsurance */

/**
 * The `LiabilityInsuranceDialog` function is a React component that displays a dialog for adding or editing
 * Liability Insurance.
 * @component
 * @param {Object} props - The props of the component.
 * @param {Boolean} props.open - The open state of the dialog.
 * @param {LiabilityInsurance} props.currentLiability - The current selected Liability object, for editing.
 * @param {boolean} props.isNew - The boolean to determine if the Liability is new.
 * @param {Function} props.onClose - The function to close the dialog.
 * @param {Function} props.onSubmit - The function to submit the form data to the server.
 * @return {JSX.Element} The `<LiabilityInsuranceDialog />` component.
 */
export default function LiabilityInsuranceDialog({
  open,
  currentLiability,
  isNew,
  onClose,
  onSubmit,
}) {
  const initialValues = {
    expiryDate: isNew ? null : getDate(currentLiability?.expiry_date),
    liabilityFile:
      isNew || !currentLiability.document_name
        ? []
        : [
            {
              alreadyUploaded: true,
              errors: false,
              file: {
                name: currentLiability.document_name,
                size: currentLiability.file_size,
              },
            },
          ],
  };

  // Formik Configuration
  const {
    errors,
    handleBlur,
    handleSubmit,
    resetForm,
    touched,
    values,
    setFieldValue,
    dirty,
    isSubmitting,
  } = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: liabilityValidationSchema,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const handleFileUpload = (file) => {
    setFieldValue("liabilityFile", [
      {
        errors: !!file.fileRejections?.[0],
        file: file.acceptedFiles[0] || file.fileRejections[0],
      },
    ]);
  };

  const handleClose = () => {
    if (!isSubmitting) {
      resetForm();
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm">
      <DialogTitle>
        {isNew ? "Add" : "Edit"} Liability Insurance
        <IconButton aria-label="close" onClick={handleClose} disabled={isSubmitting}>
          <X />
        </IconButton>
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <Grid container>
            <Grid item xs={12}>
              <ANDatePicker
                label="Expiration Date"
                format="MM/dd/yyyy"
                disablePast={isNew ? true : false}
                style={{ fontSize: 14 }}
                name="expiryDate"
                value={values.expiryDate}
                onChange={(value) => setFieldValue("expiryDate", value, true)}
                onBlur={handleBlur}
                slotProps={{
                  textField: {
                    placeholder: "01/01/2024",
                    error: touched.expiryDate && !!errors.expiryDate,
                    helperText: touched.expiryDate && errors.expiryDate,
                  },
                  field: { fullWidth: true },
                  popper: { disablePortal: false },
                }}
              />
            </Grid>
          </Grid>

          <ANUploader
            handleFileUpload={handleFileUpload}
            dropzoneFiles={values.liabilityFile}
            useDropzoneOptions={{ multiple: false }}
            handleFileDelete={() => {
              setFieldValue("liabilityFile", []);
            }}
            handleLoadedFileDelete={() => {
              setFieldValue("liabilityFile", []);
            }}
            error={touched.liabilityFile && !!errors.liabilityFile}
            errorText={errors.liabilityFile}
            dropZoneStyle={{ margin: "24px 0 0" }}
            filePreviewStyle={{ margin: "24px 0 0" }}
            hideDropzone={values.liabilityFile.length > 0}
            removeFileButtonProps={{ disabled: isSubmitting }}
          />
        </DialogContent>

        <DialogActions>
          <Button color="secondary" onClick={handleClose} disabled={isSubmitting}>
            Cancel
          </Button>

          <LoadingButton type="submit" loading={isSubmitting} disabled={!dirty}>
            {isNew ? "Add Liability Insurance" : "Save Changes"}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
