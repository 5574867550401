import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import LogoIcon from "../../assets/an_purple_white.png";
import LogoIconMini from "../../assets/an_purple_white_mini.png";

const drawerWidth = 256;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  // MUI uses 8px scaling factor by default, calculated values were removed
  // because theme spacing changed on global theme update.
  // before: `calc(${theme.spacing(7)} + 1px)` => `${8 * 7}px + 1px` = '57px',
  width: "57px",
  [theme.breakpoints.up("sm")]: {
    width: "65px",
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  padding: "14px 10px",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function SideNavDrawer(props) {
  const { open, children, mobile, handleDrawerToggle } = props;
  return mobile ? (
    <MuiDrawer
      variant="temporary"
      key="mobile_drawer"
      color="primary"
      open={open}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        width: "100%",
        height: "100%",
        "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
      }}
      PaperProps={{
        sx: {
          backgroundColor: "#552879",
          color: "#D2B3EA",
          visibility: "visible !important",
          width: {
            xs: "100%",
            md: drawerWidth,
          },
        },
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          padding: open ? "24px 16px" : "24px 12px",
        }}
      >
        <img src={LogoIcon} style={{ height: 29, width: "auto" }} />
      </div>
      {children}
    </MuiDrawer>
  ) : (
    <Drawer
      variant="permanent"
      color="primary"
      open={open}
      key="desktop_drawer"
      PaperProps={{
        sx: {
          backgroundColor: "#552879",
          color: "#D2B3EA",
        },
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          padding: open ? "24px 16px" : "24px 12px",
        }}
      >
        <img src={open ? LogoIcon : LogoIconMini} style={{ height: 29, width: "auto" }} />
      </div>
      {children}
    </Drawer>
  );
}
