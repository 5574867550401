export function getInitialState(props) {
  return {
    id: 0,
    markEligibleSuccess: false,
    markApprovedSuccess: false,
    uploadDocumentOpen: false,
    viewDocumentOpen: false,
    viewDocument: null,
    uploadDoc: null,
    uploadDocType: "",
    uploadDocInternal: false,
    uploadDocBilling: false,
    uploadDropzoneOpen: false,
    documentUrl: null,
    updateDocument: null,
    updateDocumentOpen: false,
    approveDocumentOpen: false,
    documentVersions: [],
    documentVersionsOpen: false,
    updatedUserStatus: null,
    updateUserStatusRisks: false,
    resendLoginSuccess: false,
    uploadError: false,
    uploading: false,
    updateUnitOpen: false,
    updateUnitsAmount: 0,
    updateUnit: {},
    newUnitCode: "",
    newUnitsAmount: 0,
    scheduleCallOpen: false,
    editSelectionOpen: false,
    editAllInstances: false,
    clinicianId: "",
    rescheduleCall: false,
    rescheduleDetails: null,
    newAuthorizationCode: "",
    newAuthorizationStartDate: "",
    newAuthorizationEndDate: "",
    newAuthorizationType: "",
    newAuthorizationClinicianId: "",
    newPendingAuth: false,
    newApprovedUnits: [
      {
        code: "",
        units: 0,
        hours: 0,
        codeId: "",
      },
    ],
    fileObjects: [],
    newFrontCard: [],
    newBackCard: [],
    newFrontCardOpen: false,
    newBackCardOpen: false,
    disableInsuranceOpen: false,
    disableInsurance: null,
    newInsuranceOpen: false,
    newInsuranceIsPrimary: true,
    updateInsuranceOpen: false,
    updateInsuranceIsPrimary: true,
    creatingSupportChat: false,
    sessionDetails: {},
    cancelSessionOpen: false,
    cancelAllInstances: false,
    responsibleForCancellation: "",
    cancelReasonText: "",
    newAuthorizationOpen: false,
    newInsuranceUnitOpen: false,
    newApprovedUnitOpen: false,
    assessmentCompleteOpen: false,
    billableTimePeriod: "week",
    reason: "",
    dropReason: "",
    churnReason: "",
    departedTo: "",
    disabledDate: new Date(),
    note: "",
    updateTreatmentDropzoneOpen: false,
    updateTreatmentPlan: null,
    treatmentPlanTimeLog: [],
    treatmentDropzoneOpen: false,
    assignedRole: "",
    selectedClinician: {},
    assignClinicianOpen: false,
    assignClinicianConfirmOpen: false,
    removeClinicianConfirmOpen: false,
    confirmSent: false,
    sessionNotesOpen: false,
    treatmentPlan: [],
    startNewVideoDialogOpen: false,
    sessionNowBillingType: "",
    notes: "",
    treatmentPlanError: false,
    treatmentPlanUploading: false,
    navigateToInsuranceAuthorization: false,
    newClientEmail: "",
    confirmNewClientEmail: "",
    newEmailError: false,
    confirmEmailError: false,
    updateUserEmailRisks: false,
    consentFormSigned: false,
    clientTimezone: "",
    timezoneSubmitDisabled: true,
    createAccountOpen: false,
    newAccountInfo: {},
    currentAccountHolder: {},
    availableAccounts: [],
    primaryAccountSelectionOpen: false,
    primaryAccountHolderId: null,
    primaryAccountConfirmationOpen: false,
    newPrimaryAccountInfo: {},
    snackBarOpen: false,
    snackBarError: false,
    snackBarMessage: "",
    resetPasswordUserId: null,
    firstName: "",
    lastName: "",
    dob: "",
    gender: "",
    pronouns: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipCode: "",
    clientDiagnosis: "",
    therapeuticConsultation: false,
    preAuthRequired: false,
    buttonDisabled: true,
    zipError: false,
    editMode: false,
    editPhysicianInfoOpen: false,
    diagnosingPhysician: "",
    primaryPhysician: "",
    familyFirstName: "",
    familyLastName: "",
    familyEmail: "",
    familyPhone: "",
    familyPhoneError: false,
    familyRelationship: "",
    familyUserId: "",
    editFamilyInfoOpen: "",
    showFamilyActivation: false,
    smsFacilityDialogOpen: false,
    eCommConsentFormSigned: false,
    scheduleOffPlatform: false,
    currentTab: props.match.params.id ? "dashboard" : "profile",
    preferredFirstName: "",
    identifiedGender: "",
    transgender: false,
    ethnicity: "",
    primaryLanguage: "",
    needsInterpreter: false,
    medicalConditions: "",
    livesWith: "",
    unsavedChanges: false,
    openWarning: false,
    nextLocation: null,
    editedAvailability: false,
    editedAccountStatus: false,
    editButton: false,
    isCancelClicked: false,
    requiresTablet: false,
  };
}
