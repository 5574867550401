import { getAppointmentAvailability } from "api/clinician";
import { useQuery } from "react-query";

/**
 * Custom hook to fetch OnSched appointment availability for a clinician.
 *
 * @param {string} clinicianId - The ID of the clinician.
 * @param {string} serviceType - The type of service (this is optional BE has a default value for this parameter).
 * @param {number} callDuration - The duration of the appointment call.
 * @param {string} startDate - The start date of the availability range in format "YYYY-MM-DD".
 * @param {string} endDate - The end date of the availability range in format "YYYY-MM-DD".
 * @returns {Object} - The result of the useQuery hook.
 */
export function useGetAppointmentAvailability(
  clinicianId,
  serviceType,
  callDuration,
  startDate,
  endDate,
) {
  return useQuery(
    ["clinicianAvailableHours", clinicianId, serviceType, callDuration, startDate, endDate],
    () =>
      getAppointmentAvailability({
        clinicianId,
        startDate,
        endDate,
        serviceName: serviceType,
        duration: callDuration,
      }),
    {
      enabled: !!clinicianId && !!startDate && !!endDate && !!callDuration,
    },
  );
}
