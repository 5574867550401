import { call, put, select, takeEvery } from "redux-saga/effects";
import actions from "../actions";
import { getVersion, logError } from "../api/system";
import publicIp from "public-ip";
import { detect } from "detect-browser";
import { getGroups } from "../selectors";

function* getBuildVersionAsync() {
  try {
    const version = yield call(getVersion);
    yield put(actions.getBuildVersionSuccess(version.data?.version));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    yield put(actions.getBuildVersionFail());
    console.log(e);
  }
}

function* logErrorAsync({ payload }) {
  const { errorType, errorMessage } = payload;
  try {
    let errorObject = {};
    errorObject.errorType = errorType;
    errorObject.errorMessage = errorMessage;
    const browser = detect();
    let ipAddress = yield publicIp.v4();
    errorObject.browserType = browser.name;
    errorObject.browserVersion = browser.version;
    errorObject.operatingSystem = browser.os;
    errorObject.ipAddress = ipAddress;
    let userRole = yield select(getGroups);
    errorObject.userRole = userRole[0];
    yield call(logError, errorObject);
  } catch (e) {
    console.log(e);
  }
}

export default function* startSaga() {
  yield takeEvery(actions.GET_BUILD_VERSION, getBuildVersionAsync);
  yield takeEvery(actions.LOG_ERROR, logErrorAsync);
}
