import React from "react";
import withStyles from "@mui/styles/withStyles";

import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

import styles from "./styles";

const Bio = (props) => {
  const { clinician, clinicianLoading, classes } = props;

  if (clinicianLoading) {
    return (
      <div className={classes.loading}>
        <CircularProgress />
      </div>
    );
  } else {
    let bio = clinician && clinician.bio ? clinician.bio.replace(/(?:\r\n|\r|\n)/g, "<br>") : "";
    bio = bio.replace(/\(bullet\)/g, "<br>&bull;&nbsp;&nbsp;");

    return (
      <Typography
        className={classes.cardBio}
        variant="body2"
        component="p"
        dangerouslySetInnerHTML={{ __html: bio }}
      />
    );
  }
};

export default withStyles(styles)(Bio);
