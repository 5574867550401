export default function getChannelName(channel, userId, isPublic) {
  if (isPublic) {
    return channel.name;
  }
  return channel.members
    .filter((member) => {
      return member.userId !== userId;
    })
    .map((member) => {
      return member.nickname;
    })
    .join(", ");
}
