import React from "react";
import { withRouter } from "react-router";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Logo from "../../assets/answers_now_logo_dark_purple.png";
import FormLabel from "@mui/material/FormLabel";
import { Alert } from "@mui/material";

const ForgotPassword = (props) => {
  const {
    classes,
    username,
    onSubmit,
    forgotPasswordSent,
    handleEmailChange,
    validateEmail,
    setEmailErrorFalse,
    setEmailErrorTrue,
    emailError,
  } = props;
  const year = new Date().getFullYear();

  const useCode = (e) => {
    e.preventDefault();
    const emailParam = validateEmail(username) ? `?email=${encodeURIComponent(username)}` : "";
    props.history.push(`/${emailParam}`);
    if (emailParam) {
      window.location.reload();
    }
  };

  return (
    <>
      <div className={classes.forgotPasswordFormContainer}>
        <img src={Logo} className={classes.signinLogo} />
        <div className={classes.forgotPasswordForm}>
          <form className={classes.form} onSubmit={onSubmit}>
            <div
              style={{
                textAlign: "left",
                fontSize: 20,
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "160%",
                letterSpacing: "0.15px",
              }}
            >
              Reset Password
            </div>
            <div className={classes.forgotPasswordFormBody}>
              <div
                style={{
                  textAlign: "left",
                  fontSize: 16,
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "150%",
                  letterSpacing: "0.15px",
                }}
              >
                Enter your email address to receive recovery instructions
              </div>
              {!forgotPasswordSent && (
                <FormControl margin="normal" fullWidth>
                  <FormLabel
                    component="legend"
                    style={{
                      fontSize: 12,
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "100%",
                      letterSpacing: "0.3px",
                      marginBottom: 8,
                    }}
                  >
                    Email Address
                  </FormLabel>
                  <TextField
                    id="username"
                    name="username"
                    autoComplete="off"
                    autoFocus
                    value={username}
                    size="medium"
                    onChange={handleEmailChange("username")}
                    placeholder="Type your email here"
                    onFocus={() => setEmailErrorFalse()}
                    onBlur={() =>
                      validateEmail(username) || !username
                        ? setEmailErrorFalse()
                        : setEmailErrorTrue()
                    }
                    helperText={emailError ? "Invalid Email" : null}
                  />
                </FormControl>
              )}
            </div>
            {!forgotPasswordSent && (
              <div className={classes.recoveryEmailButtonContainer}>
                <Button
                  type="submit"
                  fullWidth
                  disabled={!username || !validateEmail(username)}
                  onClick={onSubmit}
                  style={{ marginTop: 8, height: 42 }}
                >
                  Send Recovery Email
                </Button>
                <Button
                  fullWidth
                  color="secondary"
                  onClick={useCode}
                  style={{ marginTop: 16, height: 42 }}
                >
                  Use A Code To Log In
                </Button>
              </div>
            )}
            {forgotPasswordSent && (
              <>
                <Alert severity="info" style={{ marginTop: 24, marginBottom: 24 }}>
                  If your email matches the account you'll see an email shortly to reset your
                  password
                </Alert>
                <div
                  style={{
                    textAlign: "center",
                    fontSize: 14,
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "143%",
                    letterSpacing: "0.17px",
                  }}
                >
                  Don't see the email?
                </div>
                <Button
                  onClick={onSubmit}
                  variant="text"
                  size="large"
                  color="primary"
                  disabled={!username}
                  style={{ width: "100%", height: 42 }}
                >
                  Re-Send Recovery instructions
                </Button>
              </>
            )}
          </form>
        </div>
      </div>
      <div
        className={classes.footerContainer}
        style={{ width: "100%", justifyContent: "center", padding: 10 }}
      >
        <Typography component="p" className={classes.footerText}>
          Version: {APP_VERSION} Copyright &copy; {year} AnswersNow &#x2022; All rights reserved
        </Typography>
      </div>
    </>
  );
};

export default withRouter(ForgotPassword);
