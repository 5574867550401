import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import VideoCamIcon from "@mui/icons-material/Videocam";
import VideoCamOffIcon from "@mui/icons-material/VideocamOff";
import HelpIcon from "@mui/icons-material/Help";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Modal from "elements/Modal";
import AccessDeniedDialog from "../Dialogs/AccessDeniedDialog";
import ClientNoShowDialog from "../Dialogs/ClientNoShowDialog";
import { Box } from "@mui/material";

export const LobbyDialogs = (props) => {
  const {
    // values
    classes,
    history,
    accessDeniedOpen,
    redirectIn,
    clientNotJoinedOpen,
    clientNoShowOpen,
    primaryNotStartedOpen,
    permissionsDialogOpen,
    permissionsDeniedOpen,
    oneTimeVideoInfo,
    user,
    mediaBlockedMessage,
    // handlers
    startMediaTest,
    onCloseClientNotJoined,
    onCloseClientNoShow,
    markNoShow,
    onClosePrimaryNotStarted,
  } = props;
  return (
    <Box>
      <AccessDeniedDialog
        accessDeniedOpen={accessDeniedOpen}
        goBack={() => history.push("/dashboard")}
        redirectIn={redirectIn}
      />

      <Modal
        open={clientNotJoinedOpen}
        onClose={onCloseClientNotJoined}
        title="Client has not arrived"
        content={
          <div style={{ width: 600 }}>
            <Typography>
              You won't be able to start the call until the client arrives. The start call button
              will be enabled once they are in the waiting room.
            </Typography>
          </div>
        }
        secondaryActionText="Understood"
        secondaryActionOnClick={onCloseClientNotJoined}
      />

      <ClientNoShowDialog
        open={clientNoShowOpen}
        onCloseClientNoShow={onCloseClientNoShow}
        markNoShow={() => {
          markNoShow(oneTimeVideoInfo.id);
          history.push("/dashboard");
        }}
        clientName={oneTimeVideoInfo.child_name}
      />

      <Modal
        open={primaryNotStartedOpen}
        onClose={onClosePrimaryNotStarted}
        title="Call not started"
        content={
          <div style={{ width: 600 }}>
            <Typography>
              You won't be able to join the call until the primary clinician starts the call. The
              join call button will be enabled once s/he has started the call.
            </Typography>
          </div>
        }
        secondaryActionText="Understood"
        secondaryActionOnClick={onClosePrimaryNotStarted}
      />

      <Dialog
        open={permissionsDialogOpen}
        aria-labelledby="form-dialog-title"
        style={{ zIndex: 2010 }}
        PaperProps={{ style: { overflow: "hidden" } }}
        maxWidth={false}
      >
        <div className={classes.permissionsContentContainer}>
          <div className={classes.permissionsContent}>
            <DialogTitle disableTypography classes={{ root: classes.permissionsTitle }}>
              <Typography component="h4">
                {user.first_name ? `Hi, ${user.first_name}` : "Hi"}
              </Typography>
            </DialogTitle>
            <DialogContentText className={classes.permissionsContentText} style={{ color: "#000" }}>
              In order to start the video call, we need access to your camera and microphone. Click
              'allow' when prompted.
            </DialogContentText>
            <Button
              onClick={startMediaTest}
              fullWidth
              startIcon={<VideoCamIcon className={classes.permissionCamIcon} />}
              className={classes.permissionButton}
            >
              Give access to camera and microphone
            </Button>
            <Button
              onClick={() => window.open("https://www.getanswersnow.com/troubleshooting", "_blank")}
              variant="text"
              className={classes.troubleshootingButton}
            >
              Having trouble connecting to your camera or microphone?
            </Button>
          </div>
          <div className={classes.permissionsIconContainer}>
            <VideoCamOffIcon className={classes.permissionsIcon} />
          </div>
        </div>
      </Dialog>

      <Dialog
        open={permissionsDeniedOpen}
        aria-labelledby="form-dialog-title"
        style={{ zIndex: 2010 }}
        PaperProps={{ style: { overflow: "hidden" } }}
        maxWidth={false}
      >
        <div className={classes.permissionsContentContainer}>
          <div className={classes.permissionsContent}>
            <DialogTitle classes={{ root: classes.permissionsTitle }}>
              <Typography component="h4">{mediaBlockedMessage}</Typography>
            </DialogTitle>
            <DialogContentText style={{ color: "#000", width: "100%" }}>
              To give access, you need to go to your browser settings
            </DialogContentText>
            <Button
              onClick={() => window.open("https://www.getanswersnow.com/troubleshooting", "_blank")}
              variant="contained"
              fullWidth
              className={classes.givePermissionsButton}
              startIcon={<HelpIcon style={{ fill: "#fff", fontSize: 26 }} />}
            >
              <Typography component="p" className={classes.givePermissionsButtonText}>
                View help article
              </Typography>
            </Button>
          </div>
          <div className={classes.permissionsIconContainer}>
            <VideoCamOffIcon className={classes.permissionsIcon} />
          </div>
        </div>
      </Dialog>
    </Box>
  );
};
