import React from "react";

export default function getChannelInfo(data, isPublic) {
  const childInfo = JSON.parse(data);
  if (isPublic) {
    return <React.Fragment>{childInfo.description}</React.Fragment>;
  }
  return (
    <React.Fragment>
      Child: {childInfo.childName || "No name given"}
      <br />
      Age: {childInfo.childAge || "No age given"}
    </React.Fragment>
  );
}
