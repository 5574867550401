import React, { Component } from "react";
import styles from "./styles";
import actions from "../../actions";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { format } from "date-fns";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  videoChatInfo,
  getVideoBreakdown,
  isClinician,
  getNotesSaving,
  getSessionNotes,
  getUserPermissionsList,
  getUserId,
  getVideoBreakdownLoading,
  isDirector,
  isSupervisor,
  getUserAttributes,
} from "selectors";

import Button from "@mui/material/Button";
import PageContainer from "elements/PageContainer";
import SectionContainer from "elements/SectionContainer";
import { CustomTable } from "elements/DEPRECATED_elements";
import { CustomHeaderCell } from "elements/DEPRECATED_elements";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Auth from "@aws-amplify/auth";
import moment from "moment-timezone";
import { findDifferenceInMinutes } from "../../utils/findDifferenceInMinutes";
import { convertTokens } from "@date-fns/upgrade/v2";

const mapStateToProps = (state) => ({
  userAttributes: getUserAttributes(state),
  videoChatInfo: videoChatInfo(state),
  videoBreakdown: getVideoBreakdown(state),
  isClinician: isClinician(state),
  noteSaving: getNotesSaving(state),
  sessionNotes: getSessionNotes(state),
  userPermissions: getUserPermissionsList(state),
  userId: getUserId(state),
  videoBreakdownLoading: getVideoBreakdownLoading(state),
  isDirector: isDirector(state),
  isSupervisor: isSupervisor(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getVideoChatInfo: actions.getVideoChatInfo,
      getVideoCallBreakdown: actions.getVideoCallBreakdown,
      saveSessionNotes: actions.saveSessionNote,
    },
    dispatch,
  );

class VideoCall extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sessionNotesDialogOpen: false,
      downloadSessionNotesDialogOpen: false,
      currentBreakdown: [],
      cptCodeDialogOpen: false,
      enterInsuranceCode: false,
    };
  }

  componentDidMount() {
    this.props.getVideoChatInfo(this.props.match.params.id);
    this.props.getVideoCallBreakdown(this.props.match.params.id);
  }

  goBack = () => {
    this.props.history.goBack();
  };

  formatMin = (mins) => {
    const hours = Math.floor(mins / 60);
    const minutes = mins % 60;
    let hoursText = hours == 1 ? hours + " hour " : hours > 1 ? hours + " hours " : " ";
    let minutesText = minutes == 1 ? minutes + " minute" : minutes > 1 ? minutes + " minutes" : " ";

    return `${hoursText} ${minutesText}`;
  };

  viewSessionNoteDetails = (id) => {
    const { location } = this.props;
    let sessionNote = "/session-notes/" + id;
    this.props.history.push(sessionNote);
  };

  openSessionNotesDialog = (billableTimeId) => {
    const { videoBreakdown } = this.props;
    let currentBreakdown = videoBreakdown.filter((code) => code.id == billableTimeId);
    this.setState({ sessionNotesDialogOpen: true, currentBreakdown });
  };

  closeSessionNotesDialog = () => {
    this.setState({ sessionNotesDialogOpen: false }, () => {
      this.props.getVideoCallBreakdown(this.props.match.params.id);
    });
  };

  toggleDownloadSessionNotesDialog = () => {
    this.setState({
      downloadSessionNotesDialogOpen: !this.state.downloadSessionNotesDialogOpen,
    });
  };

  openCptCodeDialog = () => {
    this.setState({
      cptCodeDialogOpen: true,
      enterInsuranceCode: true,
    });
  };

  closeCPTCodeDialog = () => {
    this.setState({
      cptCodeDialogOpen: false,
    });
  };

  downloadSessionNote = async (billableTimeId) => {
    const baseUrl = process.env.BASE_URL;
    const userSession = await Auth.currentSession();
    location.href = `${baseUrl}/download/${userSession.accessToken.jwtToken}/session-note/${billableTimeId}`;
  };

  downloadAll = async (videoCallId) => {
    const baseUrl = process.env.BASE_URL;
    const userSession = await Auth.currentSession();
    location.href = `${baseUrl}/download/${userSession.accessToken.jwtToken}/all-session-notes/${videoCallId}`;
    setTimeout(() => {
      this.toggleDownloadSessionNotesDialog();
    }, 2000);
  };

  render() {
    const { classes, videoChatInfo, videoBreakdown, userPermissions, userId } = this.props;
    let callBlockHasMissingNote = videoBreakdown.some((block) => !block.note_id);
    let allCallBlocksHaveMissingNotes = videoBreakdown.every((block) => !block.note_id);
    const clientData = {
      child_name: videoChatInfo?.name,
      child_last_name: videoChatInfo?.child_last_name,
    };
    return (
      <PageContainer loading={!videoChatInfo}>
        <SectionContainer columnDirection>
          <Button variant="text" onClick={this.goBack} startIcon={<ArrowBackOutlinedIcon />}>
            Back
          </Button>
          <div className={classes.header}>
            <div />
            <Typography component="h1" variant="h5" className={classes.title}>
              Video Session Details
            </Typography>
            <div />
          </div>
          <div className={classes.videoInfoContainer}>
            <Typography component="h2" className={classes.subtitle}>
              Call Information
            </Typography>
            <Typography component="h5" className={classes.subtext}>
              * The times shown are local to the client
            </Typography>
            <Table key="table-body">
              <TableBody>
                <TableRow key="clientName">
                  <TableCell component="th" scope="row" className={classes.tableLeftCell}>
                    <Typography component="p" style={{ fontSize: 20 }}>
                      Client Name
                    </Typography>
                  </TableCell>

                  <TableCell component="th" scope="row" className={classes.tableCell}>
                    <Typography component="p" style={{ fontSize: 20 }}>
                      {videoChatInfo?.name} {videoChatInfo?.child_last_name}
                    </Typography>
                  </TableCell>
                </TableRow>
                {!videoChatInfo?.end_date && !videoChatInfo?.start_date && (
                  <TableRow key="scheduled-date">
                    <TableCell component="th" scope="row" className={classes.tableLeftCell}>
                      <Typography component="p">Scheduled Date</Typography>
                    </TableCell>

                    <TableCell component="th" scope="row" className={classes.tableCell}>
                      <Typography component="p">
                        {videoChatInfo?.scheduled_date
                          ? moment
                              .tz(videoChatInfo?.scheduled_date, videoChatInfo?.call_timezone)
                              .format("MMM Do, YYYY  h:mm a z")
                          : "N/A"}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                <TableRow key="call-duration">
                  <TableCell component="th" scope="row" className={classes.tableLeftCell}>
                    <Typography component="p">Total Call Time</Typography>
                  </TableCell>

                  <TableCell component="th" scope="row" className={classes.tableCell}>
                    <Typography>
                      {findDifferenceInMinutes(
                        videoChatInfo?.end_date,
                        videoChatInfo?.start_date,
                      ) === 0
                        ? "N/A"
                        : this.formatMin(
                            findDifferenceInMinutes(
                              videoChatInfo?.end_date,
                              videoChatInfo?.start_date,
                            ),
                          )}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow key="start-time">
                  <TableCell component="th" scope="row" className={classes.tableLeftCell}>
                    <Typography component="p">Start Time</Typography>
                  </TableCell>

                  <TableCell component="th" scope="row" className={classes.tableCell}>
                    <Typography component="p">
                      {videoChatInfo?.start_date
                        ? moment
                            .tz(videoChatInfo?.start_date, videoChatInfo?.call_timezone)
                            .format("h:mm a z")
                        : "N/A"}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow key="end-time">
                  <TableCell component="th" scope="row" className={classes.tableLeftCell}>
                    <Typography component="p">End Time</Typography>
                  </TableCell>

                  <TableCell component="th" scope="row" className={classes.tableCell}>
                    <Typography component="p">
                      {videoChatInfo?.end_date
                        ? moment
                            .tz(videoChatInfo?.end_date, videoChatInfo?.call_timezone)
                            .format("h:mm a z")
                        : "N/A"}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow key="cll-date">
                  <TableCell component="th" scope="row" className={classes.tableLeftCell}>
                    <Typography component="p">Date</Typography>
                  </TableCell>

                  <TableCell component="th" scope="row" className={classes.tableCell}>
                    <Typography component="p">
                      {videoChatInfo?.start_date
                        ? format(new Date(videoChatInfo?.start_date), convertTokens("MMM Do, YYYY"))
                        : "N/A"}
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow key="no-show">
                  <TableCell component="th" scope="row" className={classes.tableLeftCell}>
                    <Typography component="p">Absent</Typography>
                  </TableCell>

                  <TableCell component="th" scope="row" className={classes.tableCell}>
                    <Typography component="p">
                      {videoChatInfo?.no_show === true ? "Yes" : "No"}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            {userPermissions?.view_chat_log_with_clinician &&
              videoChatInfo?.chat_conversation_id && (
                <Button
                  onClick={() =>
                    this.props.history.push(`/chats/${videoChatInfo.chat_conversation_id}`)
                  }
                  variant="text"
                  color="primary"
                  size="large"
                  style={{ marginLeft: "-11px", fontSize: 18 }}
                >
                  Session Chat Transcript
                </Button>
              )}
          </div>

          <div className={classes.sessionNotesContainer}>
            <Typography component="h2" className={classes.subtitle}>
              Session Notes
            </Typography>

            {videoBreakdown.length === 0 &&
              !videoChatInfo?.is_test &&
              !videoChatInfo?.no_show &&
              videoChatInfo?.start_date &&
              videoChatInfo?.end_date && (
                <div className={classes.missingNotesContainer}>
                  <Typography component="p">
                    <span className={classes.missingText}>MISSING NOTES</span>
                    This call doesn't have any session notes associated with it. Complete them now.
                  </Typography>
                </div>
              )}

            {callBlockHasMissingNote && (
              <div className={classes.missingNotesContainer}>
                <Typography component="p">
                  <span className={classes.missingText}>MISSING NOTES</span>
                  This call has missing session notes. Complete them now.
                </Typography>
              </div>
            )}

            {videoBreakdown.length > 0 ? (
              <>
                <CustomTable
                  headerCells={[
                    <CustomHeaderCell label="Service Type" align="center" />,
                    <CustomHeaderCell label="Begin" align="center" />,
                    <CustomHeaderCell label="End" align="center" />,
                    <CustomHeaderCell label="Duration" align="center" />,
                    <CustomHeaderCell label="Billing Clinician" align="center" />,
                    <CustomHeaderCell label="Notes" align="center" />,
                    userPermissions?.download_session_notes && (
                      <CustomHeaderCell label="Download Notes" align="center" />
                    ),
                  ]}
                >
                  {videoBreakdown.map((code) => {
                    return (
                      <TableRow
                        key={code.insurance_code_id}
                        className={!code.note_id ? classes.incompleteNotesRow : null}
                      >
                        <TableCell component="th" align="center">
                          {code.insurance_code}
                        </TableCell>
                        <TableCell component="th" align="center">
                          {moment.tz(code?.start_date, code?.call_timezone).format("h:mm a z")}
                        </TableCell>
                        <TableCell component="th" align="center">
                          {moment.tz(code?.end_date, code?.call_timezone).format("h:mm a z")}
                        </TableCell>
                        <TableCell component="th" align="center">
                          {this.formatMin(
                            findDifferenceInMinutes(code?.end_date, code?.start_date),
                          )}
                        </TableCell>
                        <TableCell component="th" align="center">
                          {code.clinician_name}
                        </TableCell>
                        <TableCell align="center">
                          {code.note_id ? (
                            <Button
                              color="primary"
                              variant="text"
                              className={classes.tableControlButton}
                              onClick={() => this.viewSessionNoteDetails(code.billable_time_id)}
                              disabled={!userPermissions?.view_session_notes}
                            >
                              View
                            </Button>
                          ) : (
                            <Button
                              onClick={() => this.props.history.push("/billing")}
                              disabled={
                                !userPermissions?.add_session_notes ||
                                userId !== code.clinician_user_id
                              }
                            >
                              Enter Note
                            </Button>
                          )}
                        </TableCell>
                        {userPermissions?.download_session_notes && (
                          <TableCell align="center">
                            <Button
                              color="primary"
                              className={classes.tableControlButton}
                              variant="text"
                              onClick={() => this.downloadSessionNote(code.billable_time_id)}
                              disabled={!code.note_id}
                            >
                              Download
                            </Button>
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                </CustomTable>
                {userPermissions?.download_session_notes && (
                  <div className={classes.downloadAllButton}>
                    <Button
                      color="secondary"
                      onClick={this.toggleDownloadSessionNotesDialog}
                      size="large"
                      disabled={
                        allCallBlocksHaveMissingNotes || !userPermissions?.view_session_notes
                      }
                    >
                      Download All
                    </Button>
                  </div>
                )}
              </>
            ) : (
              <>
                {userPermissions?.add_session_notes &&
                  !videoChatInfo?.is_test &&
                  !videoChatInfo?.no_show &&
                  videoChatInfo?.start_date &&
                  videoChatInfo?.end_date && (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => this.props.history.push("/billing")}
                      className={classes.enterNotesButton}
                    >
                      ENTER NOTES
                    </Button>
                  )}
              </>
            )}
          </div>
        </SectionContainer>
        <Dialog
          open={this.state.downloadSessionNotesDialogOpen}
          onClose={this.toggleDownloadSessionNotesDialog}
          PaperProps={{
            style: {
              maxWidth: "95%",
              width: 800,
            },
          }}
        >
          <DialogTitle id="form-dialog-title" align="center">
            Large Download File
          </DialogTitle>
          <DialogContent>
            <Typography component="div" align="center">
              <Typography component="p">
                You are about to download a zip file containing a large number of documents. Are you
                sure that you want to download a sizeable file at this time?
              </Typography>
            </Typography>
          </DialogContent>
          <DialogActions className={classes.dialogActionsContainer}>
            {userPermissions?.download_session_notes && (
              <Button size="large" onClick={() => this.downloadAll(videoChatInfo.id)}>
                Download
              </Button>
            )}
            <Button
              color="primary"
              variant="text"
              size="large"
              style={{ marginTop: 10 }}
              onClick={this.toggleDownloadSessionNotesDialog}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </PageContainer>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(VideoCall));
