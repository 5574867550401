const ACTIONS = {
  GET_APPOINTMENT_CHANGE_LOGS: "GET_APPOINTMENT_CHANGE_LOGS",
  GET_APPOINTMENT_CHANGE_LOGS_SUCCESS: "GET_APPOINTMENT_CHANGE_LOGS_SUCCESS",
  GET_APPOINTMENT_CHANGE_LOGS_FAIL: "GET_APPOINTMENT_CHANGE_LOGS_FAIL",
};

const ACTION_CREATORS = {
  getAppointmentChangeLogs(payload) {
    return {
      type: ACTIONS.GET_APPOINTMENT_CHANGE_LOGS,
      payload,
    };
  },
  getAppointmentChangeLogsSuccess(payload) {
    return {
      type: ACTIONS.GET_APPOINTMENT_CHANGE_LOGS_SUCCESS,
      payload,
    };
  },
  getAppointmentChangeLogsFail(payload) {
    return {
      type: ACTIONS.GET_APPOINTMENT_CHANGE_LOGS_FAIL,
      payload,
    };
  },
};

export default { ...ACTIONS, ...ACTION_CREATORS };
