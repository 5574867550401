import React from "react";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

const AddFamilyDrawer = (props) => {
  const {
    classes,
    familyDrawerOpen,
    editFamilyDrawerOpen,
    onToggleFamilyDrawer,
    onToggleEditFamilyDrawer,
    familyMember,
    onChangeFamilyMember,
    setError,
    validateEmail,
    familyPhoneError,
    familyEmailError,
    CustomInput,
    familyMembers,
    clientFirstName,
    onSaveFamilyMember,
    familyFirstNameError,
    familyLastNameError,
  } = props;
  const familyMemberObjectNotEmpty = familyMember && Object.keys(familyMember).length > 0;
  return (
    familyMemberObjectNotEmpty && (
      <Drawer
        className={classes.drawer}
        anchor="right"
        open={familyDrawerOpen || editFamilyDrawerOpen}
        classes={{
          paper: classes.familyDrawerPaper,
        }}
      >
        <div className={classes.tabBarContainer}>
          <Typography component="p" className={classes.drawerHeaderText}>
            {editFamilyDrawerOpen ? "Edit Family Member" : "Family Member's information"}
          </Typography>

          <IconButton
            onClick={
              familyDrawerOpen ? () => onToggleFamilyDrawer() : () => onToggleEditFamilyDrawer()
            }
            className={classes.closeDrawerIcon}
            size="small"
          >
            <CloseIcon />
          </IconButton>
          <div />
        </div>
        <div className={classes.familyDrawerContainer}>
          <Grid
            container
            item
            xs={12}
            sm={12}
            spacing={3}
            // className={classes.profileInfoContainer}
          >
            <Grid item xs={12} sm={6}>
              <FormControl margin="none" fullWidth>
                <FormLabel
                  component="legend"
                  style={{
                    marginBottom: 5,
                    fontSize: 14,
                    fontWeight: "500",
                    color: "#545A72",
                    lineHeight: 1.4,
                  }}
                >
                  First Name *
                </FormLabel>
                <TextField
                  id="first_name"
                  name="first_name"
                  autoComplete="off"
                  size="small"
                  value={familyMember.first_name}
                  onChange={onChangeFamilyMember("first_name")}
                  placeholder="first name"
                  onFocus={() => setError("familyFirstNameError", false)}
                  onBlur={() =>
                    familyMember.first_name
                      ? setError("familyFirstNameError", false)
                      : setError("familyFirstNameError", true)
                  }
                  helperText={familyFirstNameError ? "Please enter first name" : null}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl margin="none" fullWidth>
                <FormLabel
                  component="legend"
                  style={{
                    marginBottom: 5,
                    fontSize: 14,
                    fontWeight: "500",
                    color: "#545A72",
                    lineHeight: 1.4,
                  }}
                >
                  Last Name *
                </FormLabel>
                <TextField
                  id="last_name"
                  name="last_name"
                  autoComplete="off"
                  size="small"
                  value={familyMember.last_name}
                  onChange={onChangeFamilyMember("last_name")}
                  placeholder="last name"
                  onFocus={() => setError("familyLastNameError", false)}
                  onBlur={() =>
                    familyMember.last_name
                      ? setError("familyLastNameError", false)
                      : setError("familyLastNameError", true)
                  }
                  helperText={familyLastNameError ? "Please enter last name" : null}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl margin="none" fullWidth>
                <FormLabel
                  component="legend"
                  style={{
                    marginBottom: 5,
                    fontSize: 14,
                    fontWeight: "500",
                    color: "#545A72",
                    lineHeight: 1.4,
                  }}
                >
                  Phone Number *
                </FormLabel>
                <TextField
                  id="phone"
                  name="phone"
                  autoComplete="off"
                  value={familyMember.phone}
                  onChange={onChangeFamilyMember("phone")}
                  variant="outlined"
                  size="small"
                  placeholder="(000)-000-0000"
                  inputProps={{ maxLength: 10 }}
                  onFocus={() => setError("familyPhoneError", false)}
                  onBlur={() =>
                    familyMember.phone?.trim()?.match(/\d/g)?.length === 10
                      ? setError("familyPhoneError", false)
                      : setError("familyPhoneError", true)
                  }
                  helperText={familyPhoneError ? "Please enter a valid phone number" : null}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl margin="none" fullWidth>
                <FormLabel
                  component="legend"
                  style={{
                    marginBottom: 5,
                    fontSize: 14,
                    fontWeight: "500",
                    color: "#545A72",
                    lineHeight: 1.4,
                  }}
                >
                  Email *
                </FormLabel>
                <TextField
                  id="email"
                  name="email"
                  autoComplete="off"
                  value={familyMember.email}
                  onChange={onChangeFamilyMember("email")}
                  variant="outlined"
                  size="small"
                  placeholder="example@gmail.com"
                  onFocus={() => setError("familyEmailError", false)}
                  onBlur={() =>
                    validateEmail(familyMember.email)
                      ? setError("familyEmailError", false)
                      : setError("familyEmailError", true)
                  }
                  helperText={familyEmailError ? "Please enter a valid email" : null}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl margin="none" fullWidth>
                <FormLabel
                  component="legend"
                  style={{
                    marginBottom: 5,
                    fontSize: 14,
                    fontWeight: "500",
                    color: "#545A72",
                    lineHeight: 1.4,
                  }}
                >
                  Relationship to Client
                </FormLabel>

                <TextField
                  id="relationship_to_client"
                  name="relationship_to_client"
                  autoComplete="off"
                  onChange={onChangeFamilyMember("relationship_to_client")}
                  value={familyMember.relationship_to_client}
                  select
                  size="small"
                  SelectProps={{
                    required: true,
                  }}
                >
                  <MenuItem disabled value="">
                    relationship
                  </MenuItem>
                  <MenuItem value="is_parent">Parent</MenuItem>
                  <MenuItem value="legal_guardian">Legal Guardian</MenuItem>
                  <MenuItem value="caregiver">Caregiver</MenuItem>
                  <MenuItem value="family_member">Family Member</MenuItem>
                  <MenuItem value="spouse">Spouse</MenuItem>
                  <MenuItem value="dependent">Dependent</MenuItem>
                  <MenuItem value="child">Child</MenuItem>
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl margin="none" fullWidth>
                <FormLabel
                  component="legend"
                  style={{
                    marginBottom: 5,
                    fontSize: 14,
                    fontWeight: "500",
                    color: "#545A72",
                    lineHeight: 1.4,
                  }}
                >
                  Contact Type
                </FormLabel>

                <TextField
                  id="contact_type"
                  name="contact_type"
                  autoComplete="off"
                  size="small"
                  onChange={onChangeFamilyMember("contact_type")}
                  value={familyMember.contact_type}
                  select
                  SelectProps={{
                    required: true,
                  }}
                >
                  <MenuItem disabled value="">
                    contact type
                  </MenuItem>
                  <MenuItem value="adult">Adult</MenuItem>
                  <MenuItem value="minor">Minor</MenuItem>
                </TextField>
              </FormControl>
            </Grid>
            {(familyMembers.length > 1 || familyDrawerOpen) && (
              <>
                <Grid item sm={12} md={6}>
                  <FormLabel
                    component="legend"
                    style={{
                      marginBottom: 5,
                      fontSize: 14,
                      fontWeight: "500",
                      color: "#545A72",
                      lineHeight: 1.4,
                      minHeight: 40,
                    }}
                  >
                    Make an account in AnswersNow for this person?
                  </FormLabel>

                  <RadioGroup
                    aria-label="create_account"
                    name="create_account"
                    value={familyMember.create_account}
                    onChange={onChangeFamilyMember("create_account")}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio color="primary" size="small" />}
                      label={
                        <Typography
                          component="p"
                          style={{
                            fontSize: 15,
                            color: "#2A2D39",
                          }}
                        >
                          Yes
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio color="primary" size="small" />}
                      label={
                        <Typography
                          component="p"
                          style={{
                            fontSize: 15,
                            color: "#2A2D39",
                          }}
                        >
                          No
                        </Typography>
                      }
                    />
                  </RadioGroup>
                </Grid>
                <Grid item sm={12} md={6}>
                  <FormLabel
                    component="legend"
                    style={{
                      marginBottom: 5,
                      fontSize: 14,
                      fontWeight: "500",
                      color: "#545A72",
                      lineHeight: 1.4,
                      minHeight: 40,
                    }}
                  >
                    Assign as primary account for {clientFirstName || "client"}
                  </FormLabel>

                  <RadioGroup
                    aria-label="is_primary"
                    name="is_primary"
                    value={familyMember.is_primary}
                    onChange={onChangeFamilyMember("is_primary")}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio color="primary" size="small" />}
                      label={
                        <Typography
                          component="p"
                          style={{
                            fontSize: 15,
                            color: "#2A2D39",
                          }}
                        >
                          Yes
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio color="primary" size="small" />}
                      label={
                        <Typography
                          component="p"
                          style={{
                            fontSize: 15,
                            color: "#2A2D39",
                          }}
                        >
                          No
                        </Typography>
                      }
                    />
                  </RadioGroup>
                </Grid>
              </>
            )}
          </Grid>
          <div className={classes.drawerButtonContainer}>
            <Button
              variant="text"
              onClick={editFamilyDrawerOpen ? onToggleEditFamilyDrawer : onToggleFamilyDrawer}
            >
              Cancel
            </Button>
            <Button
              color="secondary"
              onClick={() => onSaveFamilyMember(familyMember.index, !!editFamilyDrawerOpen)}
              style={{ marginLeft: 30 }}
            >
              Save
            </Button>
          </div>
        </div>
      </Drawer>
    )
  );
};

export default AddFamilyDrawer;
