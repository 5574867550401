import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import SpeedIcon from "@mui/icons-material/Speed";
import DesktopMacIcon from "@mui/icons-material/DesktopMac";
import VideoSettingsIcon from "@mui/icons-material/VideoSettings";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import RouterIcon from "@mui/icons-material/Router";
import VideogameAssetIcon from "@mui/icons-material/VideogameAsset";
import TabIcon from "@mui/icons-material/Tab";
import AppsIcon from "@mui/icons-material/Apps";
import { Divider, MenuList, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import Button from "@mui/material/Button";
import { CustomSelect } from "elements/DEPRECATED_elements";
import TextField from "@mui/material/TextField";

const DEFAULT_PRIMARY_TAB = "Audio & Video";

const SettingsDialog = (props) => {
  const {
    classes,
    openedWith,
    open,
    toggleSettingsDialog,
    runSpeedTest,
    runPerformanceTest,
    runAudioTest,
    microphones,
    speakers,
    cameras,
    handleUserMicrophoneChange,
    handleUserSpeakerChange,
    handleUserCameraChange,
    currentMicrophone,
    currentSpeaker,
    currentCamera,
    canChangeSpeaker,
  } = props;

  const [selectedTab, setSelectedTab] = useState(openedWith ?? DEFAULT_PRIMARY_TAB);
  const mapDeviceOptions = (arr = []) =>
    arr.map((option) => (
      <MenuItem key={option.deviceId} value={option.deviceId}>
        {option.label}
      </MenuItem>
    ));

  return (
    <Dialog
      open={open}
      onClose={() => {
        toggleSettingsDialog();
        setSelectedTab(DEFAULT_PRIMARY_TAB);
      }}
      maxWidth="xl"
    >
      <div
        style={{
          width: 900,
          minWidth: 265,
          maxWidth: "100%",
        }}
      >
        <div className={classes.settingsAudioVideoDialogTitle}>{selectedTab}</div>
        <Divider variant="fullWidth" />
        <div className={classes.settingsAudioVideoDialogContent}>
          <div
            className="navigation"
            style={{
              width: 217,
              marginRight: 16,
            }}
          >
            <MenuList>
              <MenuItem
                onClick={() => setSelectedTab("Audio & Video")}
                selected={selectedTab == "Audio & Video"}
              >
                <ListItemIcon>
                  <VideoSettingsIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <div
                    style={{
                      fontSize: 14,
                    }}
                  >
                    Audio & Video
                  </div>
                </ListItemText>
              </MenuItem>

              <MenuItem
                onClick={() => setSelectedTab("Troubleshoot Issues")}
                selected={selectedTab == "Troubleshoot Issues"}
              >
                <ListItemIcon>
                  <HelpOutlineIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <div
                    style={{
                      fontSize: 14,
                    }}
                  >
                    Troubleshoot Issues
                  </div>
                </ListItemText>
              </MenuItem>
            </MenuList>
          </div>
          <div>
            <Divider variant="fullWidth" orientation="vertical" />
          </div>
          {selectedTab == "Audio & Video" ? (
            <div
              className="options"
              style={{
                maxWidth: "100%",
                minWidth: 200,
              }}
            >
              <div className="section">
                <div className="sectionHeading">Audio</div>
                <div className="sectionSubHeading">Microphone</div>
                <TextField
                  select
                  fullWidth
                  id="level-menu"
                  name="microphoneSelection"
                  value={currentMicrophone}
                  style={{ marginBottom: 16 }}
                  onChange={handleUserMicrophoneChange}
                >
                  {mapDeviceOptions(microphones)}
                </TextField>
                <div className="sectionSubHeading">Speaker</div>
                <TextField
                  select
                  fullWidth
                  id="level-menu"
                  name="speakerSelection"
                  value={currentSpeaker}
                  style={{ marginBottom: 16 }}
                  onChange={handleUserSpeakerChange}
                  disabled={!canChangeSpeaker()}
                >
                  {canChangeSpeaker() ? (
                    mapDeviceOptions(speakers)
                  ) : (
                    <MenuItem value={""}>Default</MenuItem>
                  )}
                </TextField>
                <Button onClick={runAudioTest} variant="text">
                  Test Audio
                </Button>
              </div>
              <Divider variant="fullWidth" style={{ marginBottom: 16 }} />
              <div className="section">
                <div className="sectionHeading">Video</div>
                <div className="sectionSubHeading">Camera</div>
                <TextField
                  select
                  fullWidth
                  id="level-menu"
                  name="cameraSelection"
                  value={currentCamera}
                  style={{ marginBottom: 16 }}
                  onChange={handleUserCameraChange}
                >
                  {mapDeviceOptions(cameras)}
                </TextField>
              </div>
            </div>
          ) : (
            <div className="options" style={{ width: "100%" }}>
              <div className="section">
                <div className="sectionHeading">Speed Test</div>
                <div className="sectionText">
                  Run a speed test to assess your internet speeds. This process may take a few
                  minutes to complete.
                </div>
                <div style={{ margin: "16px 0" }}>
                  <Button startIcon={<SpeedIcon />} onClick={runSpeedTest}>
                    Run Test
                  </Button>
                </div>
                <Divider variant="fullWidth" />
              </div>
              <div className="section">
                <div className="sectionHeading">Performance Test</div>
                <div className="sectionText">Check your computer hardware performance</div>
                <div style={{ margin: "16px 0" }}>
                  <Button startIcon={<DesktopMacIcon />} onClick={runPerformanceTest}>
                    Run Test
                  </Button>
                </div>
                <Divider variant="fullWidth" />
              </div>
              <div className="section">
                <div className="sectionHeading">Recommendations</div>
                <div className="rec">
                  <RouterIcon />
                  <p style={{ marginLeft: 16 }}>
                    If you're using Wi-Fi, try moving closer to the router
                  </p>
                </div>
                <div className="rec">
                  <VideogameAssetIcon />
                  <p style={{ marginLeft: 16 }}>
                    Avoid activities that heavily use your internet connection
                  </p>
                </div>
                <div className="rec">
                  <TabIcon />
                  <p style={{ marginLeft: 16 }}>Close browser tabs you don't need</p>
                </div>
                <div className="rec">
                  <AppsIcon />
                  <p style={{ marginLeft: 16 }}>Close other apps running on your computer</p>
                </div>
              </div>
            </div>
          )}
        </div>
        <Divider variant="fullWidth" />
        <div className={classes.buttonsContainer}>
          <div className="group">
            <Button variant="text" onClick={toggleSettingsDialog}>
              Close
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default SettingsDialog;
