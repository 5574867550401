import { useDocumentSignatures } from "hooks/useDocumentSignatures";
import React from "react";
import { formatLogText } from "./Utils";

const DocumentSignatures = ({ documentId }) => {
  const { data, isLoading, error } = useDocumentSignatures(documentId);
  const document = data?.data?.document;

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;
  if (!data) return <p>No signature data available</p>;

  return formatLogText("", document.uploaded_by, ` signed `, document.document_name);
};

export default DocumentSignatures;
