import { makeStyles } from "@mui/styles";
import { blue, green, purple } from "@mui/material/colors";

/**
 * Generates the styles used in the component.
 *
 * @param {Object} theme - The theme object provided by the ThemeProvider.
 * @returns {Object} - The styles object.
 */
const useStyles = makeStyles((theme) => ({
  // Styles for days of the week buttons
  ...theme.bigCalendarCustomStyle,
  daysOfWeek: {
    "& button": {
      minWidth: "45px",
    },
  },
  // Custom styles for the calendar component
  calendarTheme: {
    ...theme.bigCalendarCustomStyle,
    // Removes the hover and focus styles and overrides color by number
    "& .event-color-0": {
      color: blue[900],
      backgroundColor: blue[100],
      cursor: "default",
      "&.expired": { color: blue[400], backgroundColor: blue[50] },
      "&:focus": { outline: "none" },
    },
    "& .event-color-1": {
      color: green[900],
      backgroundColor: green[100],
      cursor: "default",
      "&.expired": { color: green[400], backgroundColor: green[50] },
      "&:focus": { outline: "none" },
    },
    "& .event-color-2": {
      color: purple[900],
      backgroundColor: purple[100],
      cursor: "default",
      "&.expired": { color: purple[400], backgroundColor: purple[50] },
      "&:focus": { outline: "none" },
    },
    "&& .private": {
      color: theme.palette.text.secondary,
      backgroundColor: "#EAECEF",
      cursor: "default",
      "&.expired": {
        color: theme.palette.text.disabled,
        backgroundColor: "#F6F7F8",
      },
      "&:focus": { outline: "none" },
    },
  },
  // Styles for the selected date display
  selectedDate: {
    borderRadius: "100px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrast,
    padding: theme.spacing(0, 1),
    minWidth: "22px",
    display: "flex",
    justifyContent: "center",
  },
}));

export default useStyles;
