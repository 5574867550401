import React from "react";
import { useQueryClient } from "react-query";
import { Link, Container, Typography } from "@mui/material";

import SectionBox from "elements/SectionBox";
import { useGlobalToast } from "components/GlobalToastProvider";
import PrivacyItem from "./PrivacyItem";
import { useUpdateClient } from "hooks/api/useUpdateClient";
import { useGetUserNotificationPreferences } from "hooks/api/useGetUserNotificationPreferences";
import { useSetUserNotificationPreferences } from "hooks/api/useSetUserNotificationPreferences";
import { useGetClientById } from "hooks/api/useGetClientById";
import { GET_USER_BY_ID } from "constants/reactQueryKeys";
import FormsComponent from "components/ClientDashboard/Form";
import { useGetCustomerSignedForm } from "hooks/api/useGetCustomerSignedForm";
import useToggleFormSkippedStatus from "components/ClientDashboard/hooks/useToggleFormSkippedStatus";

/**
 * The `DataPrivacy` component manages data privacy settings for a user, including options for Research
 * Participation and Electronic Communications.
 * @component
 * @param {Object} props - The props of the component
 * @param {boolean} props.isDesignatedCaregiver - A boolean value to determine if the user is the designated caregiver
 * @param {string} props.userId - The user ID of the member
 * @param {string} props.clientId - The ID of the client
 * @returns {JSX.Element} The `DataPrivacy` component is being returned.
 */
function DataPrivacy({ isDesignatedCaregiver, userId, clientId }) {
  const queryClient = useQueryClient();
  const { showToast } = useGlobalToast();
  const { customerSignedForm, isFetching: isFetchingCustomerSignedForm } = useGetCustomerSignedForm(
    { clientId, type: "RESEARCH_CONSENT" }
  );
  const { mutate: skipMutate, isLoading: skipIsLoading } = useToggleFormSkippedStatus(clientId);

  // Fetch the client data
  const {
    data: clientResponse,
    isFetching: clientFetching,
    refetch: refetchGetClientById,
  } = useGetClientById(clientId);

  const researchParticipation = clientResponse?.data?.agreeToResearchParticipation;
  const showFormComponent =
    isDesignatedCaregiver &&
    customerSignedForm &&
    !customerSignedForm.skipped &&
    !customerSignedForm.signed;

  // Fetch the user notification preferences
  const {
    data: electronicCommunicationsResponse,
    isLoading: electronicCommunicationsLoading,
    refetch: refetchUserNotificationPreferences,
  } = useGetUserNotificationPreferences(userId);

  const electronicCommunications = electronicCommunicationsResponse?.data?.send_sms;

  const { updateClientMutation, isLoadingUpdateClient } = useUpdateClient({
    onSuccess: () => {
      showToast({ message: "Data privacy settings updated successfully" });
      // Refetch the client data
      refetchGetClientById();
    },
  });
  const { setUserNotificationPreferencesMutation, isLoadingUserNotificationPreferences } =
    useSetUserNotificationPreferences({
      onSuccess: () => {
        showToast({ message: "Data privacy settings updated successfully" });
        // Refetch the user notification preferences and user data
        refetchUserNotificationPreferences();
        queryClient.invalidateQueries([GET_USER_BY_ID, userId]);
      },
    });

  const handleResearchChange = () => {
    if (customerSignedForm?.skipped && customerSignedForm?.pkey) {
      skipMutate({ formId: customerSignedForm?.pkey, skipped: false });
    } else {
      updateClientMutation({
        clientId,
        data: { agree_to_research_participation: !researchParticipation },
      });
    }
  };

  const handleElectronicChange = () => {
    // One switch controls the boolean for all three values
    const preference = !electronicCommunications;
    setUserNotificationPreferencesMutation({
      userId,
      data: {
        sendSms: preference,
        sendEmail: preference,
        sendPush: preference,
      },
    });
  };

  return (
    <>
      {showFormComponent && (
        <FormsComponent clientId={clientId} markAllFormsCompleted={refetchGetClientById} />
      )}
      <SectionBox
        sx={{ padding: "32px 0 0" }}
        title="Data Privacy"
        description={
          <Typography component="p" variant="body2">
            Manage how we use and share data to enhance your AnswersNow experience. AnswersNow takes
            steps to de-identify data and protect your privacy.{" "}
            <Link href="https://www.getanswersnow.com/privacy">Learn more</Link>
          </Typography>
        }
      >
        <Container
          component="section"
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          {isDesignatedCaregiver && (
            <PrivacyItem
              title="Research Participation"
              description={`Contribute session and outcomes data to AnswersNow research initiatives.`}
              checked={researchParticipation || false}
              onChange={handleResearchChange}
              disabled={
                isLoadingUpdateClient ||
                clientFetching ||
                skipIsLoading ||
                isFetchingCustomerSignedForm
              }
            />
          )}

          <PrivacyItem
            title="Electronic Communications"
            description={`Allow AnswersNow to send communications via email/text messages.`}
            checked={electronicCommunications || false}
            onChange={handleElectronicChange}
            disabled={isLoadingUserNotificationPreferences || electronicCommunicationsLoading}
          />
        </Container>
      </SectionBox>
    </>
  );
}

export default DataPrivacy;
