import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Paper from "@mui/material/Paper";
import withStyles from "@mui/styles/withStyles";
import { withRouter } from "react-router";
import Typography from "@mui/material/Typography";

import actions from "../../actions";
import styles from "../../theme/globalStyles";
import PageContainer from "elements/PageContainer";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      signOut: actions.signOut,
      setPageDetails: actions.setPageDetails,
    },
    dispatch,
  );

class NotFoundContainer extends Component {
  componentDidMount() {
    this.props.setPageDetails({
      pageName: "Not found",
    });
  }
  goHome = (e) => {
    e.preventDefault();
    this.props.history.push("/");
    // this.props.history.push(this.props.homeUrl);
    return false;
  };
  render() {
    const { classes } = this.props;

    return (
      <PageContainer>
        <Paper className={classes.mainPaper}>
          <Typography component="h1" variant="h4" className={classes.bold} align={"center"}>
            Whoops! We can't find that page.
          </Typography>
          <Typography component="p" variant="body1">
            &nbsp;
          </Typography>
          <Typography component="p" variant="body1" align={"center"}>
            We're not quite sure what happened here or how you ended up on a page that doesn't
            exist. In the meantime, go{" "}
            <a href="#" onClick={this.props.signOut}>
              SIGNOUT
            </a>
            .
          </Typography>
        </Paper>
      </PageContainer>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NotFoundContainer)),
);
