export default (theme) => ({
  root: {
    margin: "auto",
    overflowX: "auto",
    fontFamily: ["Karla", "sans-serif"].join(","),
  },
  mainBody: {
    width: "86%",
    margin: "0 7%",
    marginBottom: 20,
    columns: 2,
    columnGap: 30,
  },
  break: {
    breakBefore: "column",
  },
  button: {
    marginLeft: theme.spacing(5),
    float: "right",
  },
  table: {
    minWidth: 700,
  },
  menu: {
    width: 300,
  },
  tablesContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: 15,
    [theme.breakpoints.down("xl")]: {
      flexDirection: "column",
      justifyContent: "flex-start",
    },
  },
  clientTable: {
    width: "49%",
    overflowX: "auto",
    [theme.breakpoints.down("xl")]: {
      width: "100%",
      marginBottom: 15,
    },
  },
  patientTable: {
    width: "49%",
    [theme.breakpoints.down("xl")]: {
      width: "100%",
    },
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px 0 15px 0",
  },
  subHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 0 15px",
  },
  expansionPanelTable: {
    width: "100%",
    height: "fit-content",
  },
  tableHeader: {
    margin: "10px",
  },
  answer: {
    paddingTop: 8,
    paddingLeft: 12,
  },
  goalList: {
    marginRight: 16,
  },
  noteContainer: {
    width: "100%",
    margin: "15px 0",
    padding: 10,
  },
  noteHeader: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
  },
  title: {
    flexGrow: 1,
  },
  neverLogggedIn: {
    color: "red",
    fontSize: ".85rem",
    display: "block",
  },
  smallButton: {
    fontSize: ".8rem",
    textTransform: "none",
    marginBottom: 10,
  },
  fileNames: {
    fontSize: ".85rem",
    display: "block",
    color: "rgba(0, 0, 0, 0.87)",
  },
  newUnitContainer: {
    width: "100%",
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    marginBottom: 10,
  },
  newCodeContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  cptCodeTable: {
    marginTop: 10,
    borderRadius: 6,
    padding: "0 10px",
    backgroundColor: "#f1f1f1",
  },
  datePopper: {
    zIndex: 1000,
  },
  uploadButtonWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    margin: "30px 0 10px 0",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      justifyContent: "space-between",
      marginTop: 20,
      width: "100%",
    },
  },
  uploadButton: {
    width: 200,
    height: 160,
    border: "2px solid #c4c4c4",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    padding: "5px 20px",
    margin: "0 10px",
    borderRadius: 8,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#ede1f5",
    },
    [theme.breakpoints.down("lg")]: {
      margin: "10px 0",
      width: "100%",
    },
  },
  loadingContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "calc(100vh - 50px - 64px)",
  },
  uploadIcon: {
    width: 60,
    height: 60,
    objectFit: "contain",
  },
  uploadText: {
    color: "#4b4a4b",
    fontSize: 16,
    textAlign: "center",
    fontWeight: 500,
  },
  iconMenu: {
    position: "absolute",
    right: 30,
  },
  formControl: {
    width: "100%",
    paddingBottom: 15,
  },
  uploadImageButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: 40,
    padding: 14,
    border: "2px solid #c1c1c1",
    borderRadius: 8,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#ede1f5",
      border: "2px solid #885FD5",
    },
  },
  divider: {
    height: 0,
    width: "100%",
    borderBottom: "2px solid #cbcbcb",
    margin: "10px 0",
  },
  documentVersionContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "5px 0",
  },
  documentVersionText: {
    fontSize: 17,
    color: "#4e4e4e",
    marginRight: 40,
  },
  questionLabel: {
    fontSize: 15,
    color: "#4e4e4e",
    minHeight: 16,
    width: "100%",
    paddingLeft: 9,
    marginTop: 10,
  },
  snackbar: {
    backgroundColor: "#121212",
    borderRadius: 4,
    color: "#fff",
  },
});
