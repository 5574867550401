import axios from "axios";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";

class EventTracking {
  constructor() {
    this.fbPixel = ReactPixel;
    this.fbPixel.init(process.env.FACEBOOK_PIXEL_ID, {}, { debug: true });

    this.reactGA = ReactGA;
    this.reactGA.initialize(process.env.GOOGLE_UA_ID);
  }

  pageView() {
    if (process.env.NODE_ENV === "production") {
      this.fbPixel.pageView();
      this.reactGA.pageview(window.location.pathname + window.location.search);
    }
  }

  track(event, data) {
    if (process.env.NODE_ENV === "production") {
      this.fbPixel.track(event, data);
      this.reactGA.event({
        category: "Parent Web",
        action: event,
      });
    }
  }

  trackCustom(event, data) {
    if (process.env.NODE_ENV === "production") {
      this.fbPixel.trackCustom(event, data);
      this.reactGA.event({
        category: "Parent Web",
        action: event,
      });
    }
  }
}

export default EventTracking;
