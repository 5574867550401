import React from "react";
import Typography from "@mui/material/Typography";

import InsuranceDetailsEdit from "./InsuranceDetailsEdit";
import { dateWithoutTimezone } from "utils/dateWithoutTimezone";
import { booleanToDisplay } from "utils/displayUtils";
import InsuranceDetailsView from "./InsuranceDetailsView";

const getInitialState = () => {
  return {
    isPrimary: false,
    id: 0,
    insuranceName: "",
    planName: "",
    memberId: "",
    groupNumber: "",
    effectiveDate: null,
    expirationDate: null,
    isMedicaid: false,
    preAuthRequired: null,
    insuranceHolderName: "",
  };
};

const getInsuranceValues = (insurance, isViewMode = false) => {  
  return ({
    id: insurance.id,
    insuranceName: insurance.insurance_name,
    memberId: insurance.member_id,
    effectiveDate: insurance.effective_date && dateWithoutTimezone(insurance.effective_date),
    insuranceHolderName: insurance.insurance_holder_name,
    isPrimary: isViewMode ? booleanToDisplay(insurance.is_primary) : insurance.is_primary,
    planName: insurance.insurance_plan_name,
    groupNumber: insurance.group_number,
    expirationDate: insurance.expiration_date && dateWithoutTimezone(insurance.expiration_date),
    insuranceHolderDOB: insurance.insurance_holder_dob,
    isMedicaid: isViewMode ? booleanToDisplay(insurance.is_medicaid) : insurance.is_medicaid,
    insurance_holder_relationship: insurance.insurance_holder_relationship,
})};

const InsuranceDetails = (props) => {
  const {
    insurance,
    privatePayClient,
    insuranceProvidersList,
    classes,
    userId,
    clientId,
    primaryInsuranceId,
    setPrimaryInsuranceId,
    getUserInsurance,
    isEditing,
    isOnlyInsurance,
    onSave,
    onError,
    setUnsavedChanges,
  } = props;

  const insuranceValues = insurance ? getInsuranceValues(insurance) : getInitialState();

  return isEditing ? (
    <InsuranceDetailsEdit
      {...{
        insurance: insuranceValues,
        userId,
        clientId,
        privatePayClient,
        insuranceProvidersList,
        classes,
        isOnlyInsurance,
        primaryInsuranceId,
        setPrimaryInsuranceId,
        getUserInsurance,
        onSave,
        onError,
        setUnsavedChanges,
      }}
    />
  ) : privatePayClient ? (
    <Typography component="p" className={classes.noInfoText}>
      Private-Pay client
    </Typography>
  ) : (
    <InsuranceDetailsView
      insurance={insurance ? getInsuranceValues(insurance, true) : getInitialState()}
    />
  );
};

export default InsuranceDetails;
