import React, { useEffect } from "react";
import useGenerateECommConsentForm from "../../hooks/useGenerateECommConsentForm";
import { useGlobalToast } from "../GlobalToastProvider";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";

const SendConsentButton = ({ clientId }) => {
  const { mutate, isLoading, isSuccess, isError, error } = useGenerateECommConsentForm(clientId);
  const { showToast } = useGlobalToast();

  const handleGenerateForm = () => {
    mutate();
  };

  useEffect(() => {
    if (isSuccess) {
      showToast({ message: "Success! Consent form generated." });
    }
    if (isError) {
      showToast({
        message: `Whoops! Something went wrong`,
        error: true,
        retryHandler: handleGenerateForm,
      });
    }
  }, [isSuccess, isError, error]);

  return (
    <LoadingButton
      loading={isLoading}
      variant="text"
      onClick={handleGenerateForm}
      style={{ width: 325 }}
    >
      Send Consent Form
    </LoadingButton>
  );
};

export default SendConsentButton;
