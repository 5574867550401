import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import VideoComponent from "./VideoComponent";
import styles from "./styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ChatDrawer from "./Drawers/ChatDrawer";
import ActivityComponent from "./Activities/ActivityComponent";
import Animation from "./Activities/Animation";
import CardControls from "./Activities/CardControls";
import io from "socket.io-client";
import { detect } from "detect-browser";
import * as AWS from "aws-sdk";
import { Buffer } from "buffer";
import PromptActivityDrawer from "./Drawers/PromptActivityDrawer";
import PromptControls from "./Activities/PromptControls";
import { Prompt } from "react-router-dom";
import chimeEffect from "../../assets/videoChimeSound.mp3";
import testMicEffect from "../../assets/videoTestMicSound.mp3";
import { v4 as uuidv4 } from "uuid";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import VideoBreakdownDialog from "../SessionNotes";
import Modal from "elements/Modal";
import WarningIcon from "@mui/icons-material/Warning";
import Link from "@mui/material/Link";
import Snackbar from "@mui/material/Snackbar";
import SpeedTest from "./Dialogs/SpeedTest";
import PerformanceTest from "./Dialogs/PerformanceTest";
import VideoInputsControls from "./Dialogs/VideoInputsControls";
import VideoDisabledDialog from "./Dialogs/VideoDisabledDialog";
import { getChannel } from "../../api/sendbird";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import cookie from "react-cookies";
import Error from "../Error";
import axios from "axios";
import ActivityDrawer from "./Drawers/ActivityDrawer";
import HostControlsDrawer from "./Drawers/HostControlsDrawer";
import { differenceInMilliseconds, subMinutes } from "date-fns";
import MergeSessionsDialog from "./Dialogs/MergeSessionsDialog";
import SessionTimer from "./Elements/SessionTimer";
import SessionFullDialog from "./Dialogs/SessionFullDialog";
import ClientSMSDialog from "../ClientSMSDialog";
import NoShowEndCallDialog from "./Dialogs/NoShowEndCallDialog";
import CallEndedMarkedAsNoShowDialog from "./Dialogs/CallEndedMarkedAsNoShowDialog";
import {
  getInitialState,
  getInitialSpeedTestState,
  getInitialTestState,
} from "./State/initialState";
import { mapStateToProps } from "./State/mapStateToProps";
import { mapDispatchToProps } from "./State/mapDispatchToProps";
import { ErrorMessage } from "./Elements/ErrorMessage";
import { getAWSCredentialsForCurrentUserSession } from "utils/aws";

const CancelToken = axios.CancelToken;
let cancel;

const browser = detect();
let device_type = null;
let browser_type = null;
let browser_version = null;
if (browser) {
  device_type = browser.os.charAt(0).toUpperCase() + browser.os.slice(1);
  browser_type = browser.name.charAt(0).toUpperCase() + browser.name.slice(1);
  browser_version = browser.version;
}

// TODO move this leaky ref to an instance member
/** @type {import('socket.io-client').Socket} */
let socket;

class VideoContainer extends Component {
  /**
   * @param {"localMedia"|"remoteMedia"|"clinicianRemoteMedia"|"lobbyLocalVideo"|"screenShareMedia"|"remoteScreenShareMedia"} refName
   * @param {HTMLElement?} prev
   * @param {HTMLElement?} cur
   */
  onRef(refName, prev, cur) {}

  /** @type {HTMLElement} */
  __localMedia;
  setLocalMedia = (el) => {
    this.localMedia = el;
  };
  set localMedia(el) {
    const localMedia = this.localMedia;
    this.__localMedia = el;
    this.onRef("localMedia", localMedia, this.localMedia);
  }
  get localMedia() {
    return this.__localMedia;
  }

  /** @type {HTMLElement} */
  __remoteMedia;
  setRemoteMedia = (el) => {
    this.remoteMedia = el;
  };
  set remoteMedia(el) {
    const remoteMedia = this.remoteMedia;
    this.__remoteMedia = el;
    this.onRef("remoteMedia", remoteMedia, this.remoteMedia);
  }
  get remoteMedia() {
    return this.__remoteMedia;
  }

  /** @type {HTMLElement} */
  __clinicianRemoteMedia;
  setClinicianRemoteMedia = (el) => {
    this.clinicianRemoteMedia = el;
  };
  set clinicianRemoteMedia(el) {
    const clinicianRemoteMedia = this.clinicianRemoteMedia;
    this.__clinicianRemoteMedia = el;
    this.onRef("clinicianRemoteMedia", clinicianRemoteMedia, this.clinicianRemoteMedia);
  }
  get clinicianRemoteMedia() {
    return this.__clinicianRemoteMedia;
  }

  /** @type {HTMLElement} */
  __lobbyLocalVideo;
  setLobbyLocalVideo = (el) => {
    this.lobbyLocalVideo = el;
  };
  set lobbyLocalVideo(el) {
    const lobbyLocalVideo = this.lobbyLocalVideo;
    this.__lobbyLocalVideo = el;
    this.onRef("lobbyLocalVideo", lobbyLocalVideo, this.lobbyLocalVideo);
  }
  get lobbyLocalVideo() {
    return this.__lobbyLocalVideo;
  }

  /** @type {HTMLElement} */
  __screenShareMedia;
  setScreenShareMedia = (el) => {
    this.screenShareMedia = el;
  };
  set screenShareMedia(el) {
    const screenShareMedia = this.screenShareMedia;
    this.__screenShareMedia = el;
    this.onRef("screenShareMedia", screenShareMedia, this.screenShareMedia);
  }
  get screenShareMedia() {
    return this.__screenShareMedia;
  }

  /** @type {HTMLElement} */
  __remoteScreenShareMedia;
  setRemoteScreenShareMedia = (el) => {
    this.remoteScreenShareMedia = el;
  };
  set remoteScreenShareMedia(el) {
    const remoteScreenShareMedia = this.remoteScreenShareMedia;
    this.__remoteScreenShareMedia = el;
    this.onRef("remoteScreenShareMedia", remoteScreenShareMedia, this.remoteScreenShareMedia);
  }
  get remoteScreenShareMedia() {
    return this.__remoteScreenShareMedia;
  }

  /** @type {HTMLElement} */
  __microphoneTestCanvas;
  setMicrophoneTestCanvas = (el) => {
    this.microphoneTestCanvas = el;
  };
  set microphoneTestCanvas(el) {
    const microphoneTestCanvas = this.microphoneTestCanvas;
    this.__microphoneTestCanvas = el;
    this.onRef("microphoneTestCanvas", microphoneTestCanvas, this.microphoneTestCanvas);
  }
  get microphoneTestCanvas() {
    return this.__microphoneTestCanvas;
  }

  /** @type {HTMLElement} */
  __youTubeRef;
  setYouTubeRef = (el) => {
    this.youTubeRef = el;
  };
  set youTubeRef(el) {
    const youTubeRef = this.youTubeRef;
    this.__youTubeRef = el;
    this.onRef("youTubeRef", youTubeRef, this.youTubeRef);
  }
  get youTubeRef() {
    return this.__youTubeRef;
  }

  constructor(props) {
    super();
    this.state = {
      ...getInitialState(props),
      ...getInitialSpeedTestState(),
      ...getInitialTestState(),
    };
    this.currentPath = null;
    this.updateNoteInterval = null;
    this.reconnectSocketInterval = null;
    this.remoteMedia = null;
    this.getUnreadMessageCountInterval = null;
    this.youTubeRef = null;
    this.nextCallTimer = null;
    this.smsPolling = null;
  }
  async componentDidMount() {
    this.props.setPageDetails({
      pageName: "Video Chat",
      currentPage: "videoChat",
      menu: "video",
    });
    if (this.props.signedInState) {
      await this.getUserDevices();
      if (
        navigator?.mediaDevices &&
        typeof navigator.mediaDevices.removeEventListener === "function"
      ) {
        navigator.mediaDevices.addEventListener("devicechange", this.getUserDevices);
      }
    }
    // this.findDownloadSpeedAverage();
    this.getDeviceInformation();
    const fromStaticUrlEmail = cookie.load("fromStaticVideoPage");
    if (fromStaticUrlEmail) {
      this.setState({ fromStaticUrl: true });
    }
    if (!this.props.roomId && !this.props.match.params.videoKey) {
      this.props.history.goBack();
    }
    if (this.props.idleTimer) {
      this.props.idleTimer.pause();
    }
    this.currentPath = window.location.pathname;

    this.props.checkAuthState();

    if (this.props.login) {
      this.props.getUser();
    }

    if (this.props.match.params.videoKey) {
      this.props.getVideoCallInfo(this.props.match.params.videoKey);
    }

    if (this.props.videoId && this.props.userId) {
      this.props.checkVideoCallAccess(this.props.videoId);
      this.setUpSockets();
      if (this.props.isCaregiver) {
        this.props.getVideoRoomState(this.props.videoId);
      }
    }

    if (!this.props.isCaregiver) {
      this.props.getAnimations();
      this.props.getActivities();
      this.props.getCardsActivity();
    }

    if (
      this.props.videoParticipants &&
      this.props.primaryClinicianUserId &&
      this.props.isCaregiver
    ) {
      let primaryClinicianData = this.props.videoParticipants.find(
        (p) => p.user_id == this.props.primaryClinicianUserId
      );
      this.setState({ primaryClinicianData });
    }
    if (this.props.clientId) {
      if (!this.props.isCaregiver) {
        this.props.getCustomerInfo(this.props.clientId);
        this.props.getCustomerSignedForm({
          clientId: this.props.clientId,
          type: "E_COMM_CONSENT",
          userId: this.props.clientUserId,
        });
        this.props.getInsuranceCodesByUser({
          clientId: this.props.clientId,
          video: true,
          isDemo: this.props.oneTimeVideoInfo?.is_test,
        });
        this.props.getSessionNotesByUser(this.props.clientId);
        this.props.getAvailableInsuranceCodes({
          clientId: this.props.clientId,
          requiresAuth: true,
        });
      }
    }

    if (this.props.oneTimeVideoInfo && this.props.oneTimeVideoInfo.billing_type) {
      let serviceType = this.mapBillingTypeToDisplay(this.props.oneTimeVideoInfo.billing_type);
      this.setState({
        serviceType,
        callDuration: this.props.oneTimeVideoInfo.call_duration,
        primaryClinicianFirstName: this.props.oneTimeVideoInfo.primary_clinician_first_name,
      });
      if (this.props.oneTimeVideoInfo.billing_type === "ASSESSMENT" && !this.props.isCaregiver) {
        this.setState({ assessmentCall: true });
      }
    }
    if (this.props.oneTimeVideoInfo?.is_test && this.props.userId) {
      this.setState({
        isDemo: true,
        clientJoined: true,
        clientConnected: true,
        clientJoinedData: {
          name: "Sara Tucker",
          child_name: "Jake",
          child_last_name: "Tucker",
        },
      });
      if (this.props.userId === this.props.oneTimeVideoInfo.clinician_user_id) {
        this.setState({ isPrimaryClinician: true });
      }
      this.setJoinRoomReady();
    }

    if (
      this.props.primaryClinicianUserId !== null &&
      this.props.userId !== null &&
      !this.props.isCaregiver
    ) {
      if (this.props.primaryClinicianUserId === this.props.userId) {
        this.setState({ isPrimaryClinician: true });
      }
    }
    if (this.props.videoKey && this.props.videoId) {
      this.props.setOneTimeVideoInfo({
        videoInfo: { id: this.props.videoId, user_id: this.props.clientUserId },
        videoKey: this.props.videoKey,
        roomId: this.props.roomId ? this.props.roomId : uuidv4(),
      });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const { videoId, userId, videoParticipants, primaryClinicianUserId, isCaregiver } = this.props;

    const {
      activity,
      browserPermissionError,
      microphoneError,
      cameraError,
      speakerError,
      uploadTestEnded,
      downloadTestEnded,
      averageDownloadMbps,
      averageUploadMbps,
      deviceInformationError,
    } = this.state;

    if (!prevProps.signedInState && this.props.signedInState) {
      await this.getUserDevices();
      if (
        navigator?.mediaDevices &&
        typeof navigator.mediaDevices.removeEventListener === "function"
      ) {
        navigator.mediaDevices.addEventListener("devicechange", this.getUserDevices);
      }
    }

    if (
      ((!prevState.deviceInformationError && deviceInformationError) ||
        (!prevState.browserPermissionError && browserPermissionError) ||
        (!prevState.microphoneError && microphoneError) ||
        (!prevState.cameraError && cameraError) ||
        (!prevState.speakerError && speakerError) ||
        (!prevState.downloadTestEnded && downloadTestEnded && averageDownloadMbps <= 5) ||
        (!prevState.uploadTestEnded && uploadTestEnded && averageUploadMbps <= 2)) &&
      !this.state.hasJoinedRoom &&
      this.props.signedInState
    ) {
      if (!this.state.errorDialogOpen) {
        if (!downloadTestEnded && cancel) {
          cancel();
        }
        this.setState({ speedTestCancelled: true, errorDialogOpen: true });
      }
    } else if (
      // turn off the dialog once errors are cleared
      this.state.errorDialogOpen &&
      !(
        deviceInformationError ||
        browserPermissionError ||
        microphoneError ||
        cameraError ||
        speakerError
      ) &&
      (!downloadTestEnded || averageDownloadMbps > 5) &&
      (!uploadTestEnded || averageUploadMbps > 2)
    ) {
      this.setState({ errorDialogOpen: false });
    }

    if ((!prevProps.videoId || !prevProps.userId) && videoId && userId) {
      this.props.checkVideoCallAccess(this.props.videoId);
      this.setUpSockets();
      if (isCaregiver) {
        this.props.getVideoRoomState(this.props.videoId);
      }
    }

    if (prevProps.sendingVideoSignatures && !this.props.sendingVideoSignatures) {
      if (this.props.sendVideoSignaturesSuccess) {
        this.props.history.push("/dashboard");
      } else {
        this.setState({ sigError: true });
      }
    }

    if (!prevProps.login && this.props.login) {
      this.props.getUser();
    }

    if (
      (!prevProps.videoParticipants || !prevProps.primaryClinicianUserId) &&
      videoParticipants &&
      primaryClinicianUserId &&
      isCaregiver
    ) {
      let primaryClinicianData = videoParticipants.find((p) => p.user_id == primaryClinicianUserId);
      this.setState({ primaryClinicianData });
    }

    if (
      prevState.clinicianJoined &&
      !this.state.clinicianJoined &&
      !this.state.secondaryClinicianConnected
    ) {
      this.setState({ callEnded: true, roomLayout: "standard" }, () => {
        if (this.state.fromStaticUrl) {
          this.props.signOut(true);
        }
      });
    }
    if (!prevState.clinicianJoined && this.state.clinicianJoined) {
      this.setState({ callEnded: false });
    }

    if (!prevProps.videoRoomState.layout && this.props.videoRoomState.layout && isCaregiver) {
      this.setState({ roomLayout: this.props.videoRoomState.layout.type });
    }
    if (prevState.cardResponse === null && this.state.cardResponse === false && isCaregiver) {
      this.setState({ shakeAnimation: true });
      setTimeout(() => {
        this.setState({ shakeAnimation: false });
      }, 2000);
    }

    if (!prevProps.idleTimer && this.props.idleTimer) {
      this.props.idleTimer.pause();
    }
    if (
      (!prevProps.roomId || !prevProps.startDate || !prevState.readyToJoinCall) &&
      this.props.roomId &&
      this.props.startDate &&
      !this.state.hasJoinedRoom &&
      this.state.readyToJoinCall &&
      !this.props.endDate
    ) {
      if (!this.state.isPrimaryClinician && !isCaregiver) {
        this.onSecondaryClinicianJoinCall();
      } else {
        this.joinRoom();
      }
    }
    if (prevState.activity.active && !activity.active) {
      this.setState({
        cardIndex: 0,
        currentCard: {},
        cardsCorrect: 0,
        cardsIncorrect: 0,
        cardActivityStarted: false,
        promptNextCard: false,
      });
    }
    if (!prevProps.videoCallEnded && this.props.videoCallEnded && this.props.videoKey) {
      this.props.getVideoCallInfo(this.props.videoKey);
    }
    if (
      (!prevProps.clientId && this.props.clientId) ||
      prevProps.clientId !== this.props.clientId
    ) {
      // this.props.getOldSessionNotesByUser(this.props.clientId);
      if (!isCaregiver) {
        this.props.getCustomerInfo(this.props.clientId);
        this.props.getCustomerSignedForm({
          clientId: this.props.clientId,
          type: "E_COMM_CONSENT",
          userId: this.props.clientUserId,
        });
        this.props.getInsuranceCodesByUser({
          clientId: this.props.clientId,
          video: true,
          isDemo: this.props.oneTimeVideoInfo?.is_test,
        });
        this.props.getSessionNotesByUser(this.props.clientId);
        this.props.getAvailableInsuranceCodes({
          clientId: this.props.clientId,
          requiresAuth: true,
        });
      }
    }
    if (
      (!prevProps.consentForm && this.props.consentForm) ||
      (prevProps.consentForm?.formType !== this.props.consentForm?.formType &&
        this.props.consentForm?.formType === "E_COMM_CONSENT")
    ) {
      if (this.props.consentForm.signed) {
        this.setState({ eCommConsentFormSigned: true }, () => {
          this.props.getSMSThreadByClient(this.props.clientId);
        });
      } else {
        this.setState({ eCommConsentFormSigned: false });
      }
    }
    if (
      !(
        prevProps.startDate &&
        prevProps.endDate &&
        prevProps.userPermissions?.sign_after_video_call
      ) &&
      this.props.startDate &&
      this.props.endDate &&
      this.props.userPermissions?.sign_after_video_call &&
      !isCaregiver
    ) {
      if (this.state.isPrimaryClinician) {
        this.props.history.push("/billing");
      } else {
        this.onSecondaryClinicianLeaveRoom();
      }
      // if (this.props.oneTimeVideoInfo?.billing_type === "ORIENTATION") {
      //   this.props.history.push("/dashboard");
      // } else {
      //   socket.emit("check-billing-completion", {
      //     videoId: this.props.videoId,
      //     userId: this.props.userId,
      //   });
      // }
    }
    if (
      !prevProps.secondaryClinicianLeftCall &&
      this.props.secondaryClinicianLeftCall &&
      !this.state.isPrimaryClinician &&
      !isCaregiver
    ) {
      this.secondaryClinicianClearTimer();
      this.clearTimer();
      // this.setState({
      //   cptCodeDialogOpen: true,
      // });
      this.props.history.push("/billing");
    }
    if (!prevProps.oneTimeVideoInfo.billing_type && this.props.oneTimeVideoInfo.billing_type) {
      let serviceType = this.mapBillingTypeToDisplay(this.props.oneTimeVideoInfo.billing_type);
      this.setState({
        serviceType,
        callDuration: this.props.oneTimeVideoInfo.call_duration,
        primaryClinicianFirstName: this.props.oneTimeVideoInfo.primary_clinician_first_name,
      });
      if (this.props.oneTimeVideoInfo.billing_type === "ASSESSMENT") {
        this.setState({ assessmentCall: true });
      }
    }

    if (
      (!prevProps.oneTimeVideoInfo?.is_test || !prevProps.userId) &&
      this.props.userId &&
      this.props.oneTimeVideoInfo.is_test
    ) {
      this.setState({
        isDemo: true,
        clientJoined: true,
        clientConnected: true,
        clientJoinedData: {
          name: "DEMO - Sara Tucker",
          child_name: "Jake",
          child_last_name: "Tucker",
        },
      });
      if (this.props.userId === this.props.oneTimeVideoInfo.clinician_user_id) {
        this.setState({ isPrimaryClinician: true });
      }
      this.setJoinRoomReady();
    }

    if (
      (!prevProps.videoKey && this.props.videoKey) ||
      (prevProps.videoKey !== this.props.videoKey &&
        this.props.videoKey &&
        !isCaregiver &&
        !this.props.endDate)
    ) {
      this.setState({ inVideoRoom: true });
      this.props.setOneTimeVideoInfo({
        videoInfo: { id: this.props.videoId, user_id: this.props.clientUserId },
        videoKey: this.props.videoKey,
        roomId: this.props.roomId ? this.props.roomId : uuidv4(),
      });
      if (this.state.clientJoined) {
        this.setJoinRoomReady();
      }
    }

    if (
      prevProps.sessionNotes.length !== this.props.sessionNotes.length &&
      this.props.sessionNotes.length > 0
    ) {
      this.setState({
        notes: this.props.sessionNotes[0].note,
        participants: this.props.sessionNotes[0].participants,
      });
    }

    if (!prevProps.secondaryClinicianJoinTime && this.props.secondaryClinicianJoinTime) {
      this.startTimer();
      this.secondaryClinicianStartTimer();
      this.setState({ hasJoinedRoom: true });
    }

    if (
      (!prevProps.primaryClinicianUserId || !prevProps.userId) &&
      this.props.primaryClinicianUserId &&
      this.props.userId &&
      this.props.primaryClinicianUserId === this.props.userId
    ) {
      this.setState({ isPrimaryClinician: true });
    }

    // if (
    //   (!prevProps.secondaryClinicianUserId || !prevProps.userId) &&
    //   this.props.secondaryClinicianUserId &&
    //   this.props.userId &&
    //   this.props.secondaryClinicianUserId === this.props.userId &&
    //   this.props.startDate &&
    //   !this.props.endDate
    // ) {
    //   socket.emit("check-billing-completion", {
    //     videoId: this.props.videoId,
    //     userId: this.props.userId,
    //   });
    // }

    if (
      prevState.clinicianInControl &&
      !this.state.clinicianInControl &&
      this.state.hasStartedCall &&
      this.state.hasJoinedRoom
    ) {
      this.setState({ controlsPassed: true });
      if (this.state.screenTrack) {
        this.endScreenCapture();
      }
    }
    if (
      !prevState.clinicianInControl &&
      this.state.clinicianInControl &&
      prevState.inControlUserId !== null &&
      prevState.inControlUserId !== this.props.userId &&
      this.state.hasStartedCall &&
      this.state.hasJoinedRoom
    ) {
      this.setState({ controlsReceived: true });
      if (this.state.requestSentTextOpen) {
        this.setState({ requestSentTextOpen: false });
      }
    }
    if (
      (!prevState.signatureId && this.state.signatureId) ||
      (!prevProps.primaryClinicianVideoSignatureId &&
        this.props.primaryClinicianVideoSignatureId &&
        this.state.isPrimaryClinician) ||
      (!prevProps.secondaryClinicianVideoSignatureId &&
        this.props.secondaryClinicianVideoSignatureId &&
        !this.state.isPrimaryClinician)
    ) {
      let signatureId;
      if (this.state.isPrimaryClinician) {
        signatureId = this.props.primaryClinicianVideoSignatureId
          ? this.props.primaryClinicianVideoSignatureId
          : this.state.signatureId;
      } else {
        signatureId = this.props.secondaryClinicianVideoSignatureId
          ? this.props.secondaryClinicianVideoSignatureId
          : this.state.signatureId;
      }
      this.props.getSessionNotesByClinician(signatureId);
    }

    if (
      this.state.hasJoinedRoom &&
      this.state.localParticipantNetworkLevel &&
      prevState.localParticipantNetworkLevel > 2 &&
      this.state.localParticipantNetworkLevel <= 2
    ) {
      if (!this.state.localBadInternedDetected) {
        this.setState({
          localBadInternedDetected: true,
          localBadInternetDialogOpen: true,
        });
        const eventObject = {
          videoId: this.props.videoId,
          userId: this.props.userId,
        };
        socket.emit("report-bad-internet", eventObject);
        let message = `${userId} user has bad internet on ${videoId} video call.`;
        this.props.logError({
          errorType: "TWILO-NETWORK",
          errorMessage: message,
        });
      }
    }
    if (
      !prevState.hasJoinedRoom &&
      this.state.hasJoinedRoom &&
      this.state.screenShareOn &&
      this.state.screenshareUserId === this.props.userId
    ) {
      if (
        !this.state.parentScreenSharing &&
        this.state.showClientScreenShare &&
        this.props.isCaregiver
      ) {
        this.setScreenShareOff();
      }
      if (!this.state.clinicianScreenSharing && !this.props.isCaregiver) {
        this.setScreenShareOff();
      }
    }
    if (!prevState.inputAccessGranted && this.state.inputAccessGranted) {
      this.getUnreadMessageCountInterval = setInterval(() => {
        this.getUnreadMessageCount();
      }, 3000);

      if (!this.state.isPrimaryClinician && !this.props.isCaregiver && !this.props.hasJoinedRoom) {
        this.setState({
          muted: true,
        });
      }
      if (this.props.isCaregiver && window.innerWidth <= 850 && window.innerHeight <= 850) {
        this.setState({ clientOnMobile: true });
      }
    }

    if (
      (!prevState.cptCodeDialogOpen && this.state.cptCodeDialogOpen) ||
      (!prevProps.endDate && this.props.endDate && this.props.isCaregiver)
    ) {
      document.removeEventListener("mousemove", this.mouseMove);
      document.removeEventListener("mousedown", this.mouseMove);
    }

    if (
      !prevState.hasJoinedRoom &&
      this.state.hasJoinedRoom &&
      this.props.isCaregiver &&
      window.innerWidth <= 850 &&
      window.innerHeight <= 850
    ) {
      this.setState({ clientOnMobile: true });
      this.checkForUnsupportedPlatformDecision();
    }

    if (
      !prevState.hasJoinedRoom &&
      this.state.hasJoinedRoom &&
      !this.props.isCaregiver &&
      !this.canScreenShare()
    ) {
      this.setState({ screenSharingNotSupported: true });
    }
    if (
      prevState.unreadMessageCount !== this.state.unreadMessageCount &&
      this.state.unreadMessageCount > 0
    ) {
      if (!this.state.hasJoinedRoom && !this.props.endDate) {
        this.setState({ chatOpen: true });
      } else {
        this.setState({ showControls: true }, () => {
          setTimeout(() => {
            this.setState({ showControls: false });
          }, 4000);
        });
      }
    }
    if (!prevProps.isAssignedClinician && this.props.isAssignedClinician) {
      this.setState({ chatOpen: false });
    }
    if (prevProps.signedInState && !this.props.signedInState && this.state.fromStaticUrl) {
      setTimeout(() => {
        this.props.history.push("/");
      }, 2000);
    }
    if (
      (!prevProps.secondaryBillableTimeId ||
        prevProps.secondaryBillableTimeId != this.props.secondaryBillableTimeId) &&
      this.props.secondaryBillableTimeId
    ) {
      this.setState({ billableTimeId: this.props.secondaryBillableTimeId });
    }
    if (!prevState.hasJoinedRoom && this.state.hasJoinedRoom && this.state.chatOpen) {
      this.setState({ chatOpen: false });
    }

    if (prevProps.getCallInfoLoading && !this.props.getCallInfoLoading && !this.props.videoId) {
      if (this.props.isClinician) {
        this.props.showToast({
          message:
            "Whoops - it's too early to join this scheduled call. Please reschedule or start an Instant Call.",
        });
        this.props.history.goBack();
      } else {
        this.props.history.push("/");
      }
    }

    //temp solution for 1969 issue
    if (
      (!prevState.cptCodeDialogOpen || !prevState.billableTimeId) &&
      this.state.cptCodeDialogOpen &&
      this.state.billableTimeId &&
      !this.state.isDemo
    ) {
      this.props.history.push("/billing");
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousemove", this.mouseMove);
    document.removeEventListener("mousedown", this.mouseMove);
    if (
      navigator?.mediaDevices &&
      typeof navigator.mediaDevices.removeEventListener === "function"
    ) {
      navigator.mediaDevices.removeEventListener("devicechange", this.getUserDevices);
    }

    this.disconnectVideo();
    this.props.clearVideoInfo();
    this.props.clearOneTimeVideoInfo();
    this.markChatMessagesAsRead();

    clearInterval(this.reconnectSocketInterval);
    if (socket) {
      socket.disconnect(true);
    }
    this.props.idleTimer?.reset();

    if (this.getUnreadMessageCountInterval) {
      clearInterval(this.getUnreadMessageCountInterval);
    }
    if (this.state.microphoneTestOn || this.audioVisualizationId) {
      cancelAnimationFrame(this.audioVisualizationId);
      this.analyser.disconnect();
      this.audioSrc.disconnect();
    }
    window.removeEventListener("beforeunload", this.disconnectFromRoom);
    if (this.state.clientOnMobile) {
      window.removeEventListener("pagehide", this.disconnectFromRoom);
    }
    if (this.nextCallTimer) {
      clearTimeout(this.nextCallTimer);
    }
    if (this.smsPolling) {
      clearInterval(this.smsPolling);
    }
  }

  mapBillingTypeToDisplay = (billingType) => {
    switch (billingType) {
      case "ASSESSMENT":
        return "Assessment";
      case "ORIENTATION":
        return "Orientation";
      case "DIRECT_THERAPY":
        return "Direct Therapy";
      case "CAREGIVER_TRAINING":
        return "Caregiver Training";
      default:
        return "";
    }
  };

  markChatMessagesAsRead = () => {
    const { videoChatUrl } = this.props;
    if (videoChatUrl && this.props.sendbirdConnection) {
      getChannel(videoChatUrl, this.props.sendbirdConnection).then((channel) => {
        if (channel) {
          channel.markAsRead();
        }
      });
    }
  };

  disconnectVideo = () => {};

  setJoinRoomReady = () => {
    this.setState({ joinRoomReady: true });
    this.playChime();
  };

  mouseMoveTimer = () =>
    setTimeout(() => {
      this.setState({ showControls: false });
    }, 4000);

  mouseMove = () => {
    clearTimeout(this.timerId);
    this.setState({ showControls: true }, () => {
      this.timerId = this.mouseMoveTimer();
    });
  };

  goBack = () => {
    socket.disconnect(true);
    this.props.history.goBack();
  };

  setUpSockets = () => {
    this.socket = socket = io(`${process.env.BASE_URL}/ws/video`);
    socket.on("connect", () => {
      this.setState({ socketConnected: true });
      if (this.reconnectSocketInterval) {
        clearInterval(this.reconnectSocketInterval);
      }
      socket.emit("register", {
        videoId: this.props.videoId,
        userId: this.props.userId,
      });
    });
    socket.on("connect_error", (error) => {
      let message = `${this.props.userId} user had websocket connect error on ${this.props.videoId} video call because of ${error.message}.`;
      this.props.logError({
        errorType: "WEBSOCKET-CONNECT-ERROR-EVENT",
        errorMessage: message,
      });
      if (this.reconnectSocketInterval) {
        clearInterval(this.reconnectSocketInterval);
      }
      if (socket) {
        this.reconnectSocketInterval = setInterval(() => {
          socket?.connect();
        }, 3000);
      }
    });
    socket.on("disconnect", (error) => {
      let message = `${this.props.userId} user websocket disconnected on ${this.props.videoId} video call because of ${error}.`;
      this.props.logError({
        errorType: "WEBSOCKET-DISCONNECTED-EVENT",
        errorMessage: message,
      });
      this.setState({ socketConnected: false });
      if (this.reconnectSocketInterval) {
        clearInterval(this.reconnectSocketInterval);
      }
      if (socket && error !== "io client disconnect") {
        this.reconnectSocketInterval = setInterval(() => {
          socket?.connect();
        }, 3000);
      }
    });
    socket.emit("register", {
      videoId: this.props.videoId,
      userId: this.props.userId,
    });
    socket.on("event_status", (data) => {
      if (!this.state.hasJoinedRoom && data.activity.active) {
        this.endActivity();
      }
      if (!this.state.hasJoinedRoom && data.animation.active) {
        this.endAnimation();
      }
      if (data.client_connected && this.props.isCaregiver && !this.state.readyToJoinCall) {
        this.setState({ allowedToJoinCall: false }, () => {
          socket.emit("set-second-client-role");
        });
      } else {
        this.setState({ allowedToJoinCall: true });
      }
      if (data.client_connected !== this.state.clientConnected && !this.state.isDemo) {
        this.setState({ clientConnected: data.client_connected });
      }
      if (data.client_device_type && !this.props.isCaregiver) {
        const clientDeviceObj = JSON.parse(data.client_device_type);
        this.setState({
          clientDeviceType: clientDeviceObj,
          clientCanScreenshare: clientDeviceObj?.clientCanScreenshare || false,
        });
      }
      let videoStartTime = null;
      if (data.animation.active && data.animation.type === "video" && data.animation.start) {
        videoStartTime = data.animation.start;
      } else if (data.activity.active && data.activity.type === "video" && data.activity.start) {
        videoStartTime = data.activity.start;
      }
      let chatOpen = this.props.isCaregiver && data.hide_chat ? false : this.state.chatOpen;

      let clinicianInControl =
        data.in_control_clinician_user_id == this.props.userId && !this.props.endDate;
      let secondaryClinicianInControl =
        data.in_control_clinician_user_id == this.props.secondaryClinicianUserId &&
        !this.props.endDate;

      this.setState({
        roomLayout: data.layout.type,
        animation: data.animation,
        activity: data.activity,
        videoPlaying: false,
        hideChat: data.hide_chat,
        chatOpen,
        videoStartTime,
        showClientScreenShare: data.show_screenshare,
        screenShareOn: data.screenshare_on,
        inControlUserId: data.in_control_clinician_user_id,
        screenshareUserId: data.screenshare_user_id,
        secondaryClinicianInControl,
        clinicianInControl,
      });
      if (data.activity.type !== "card") {
        this.setState({ card: null, cardResponse: null });
      }
      if (data.activity.type !== "prompt") {
        this.setState({
          promptSelectionCorrect: null,
          promptActivityData: null,
          firstPromptAttemptCorrect: null,
          showPrompt: false,
        });
      }
    });
    socket.on("video_change", (data) => {
      this.setState({ videoPlaying: data.playing });
    });
    socket.on("prompt_change", (data) => {
      this.setState({ showPrompt: false });
      if (this.props.isCaregiver) {
        setTimeout(() => {
          this.setState({
            promptActivityData: data,
            promptSelectionCorrect: null,
            firstPromptAttemptCorrect: null,
          });
        }, 500);
      } else {
        this.setState({
          promptActivityData: data,
          clientPromptResponse: null,
          promptSelectionCorrect: null,
          initialPromptResponse: null,
        });
      }
      if (!this.state.clinicianInControl && !this.props.isCaregiver) {
        this.setState({
          promptActivityStarted: true,
        });
      }
      setTimeout(() => {
        this.setState({ showPrompt: true });
      }, 3000);
    });
    socket.on("card_change", (data) => {
      const { cards, isCaregiver } = this.props;
      if (isCaregiver) {
        this.setState({ card: data, cardResponse: null });
      } else {
        let currentCard = cards.find((c) => c.id === data.id);
        if (!this.state.clinicianInControl) {
          this.setState({
            cardActivityStarted: true,
            currentCard,
          });
        }
      }
    });
    socket.on("call_ended", (data) => {
      this.props.getVideoCallInfo(this.props.videoKey);
      if (this.props.isCaregiver) {
        this.clientLeaveRoom();
      } else {
        this.setState({
          hasJoinedRoom: false,
          localMediaAvailable: false,
          endCallDialogOpen: false,
          backPressDialogOpen: false,
          activityDrawerOpen: false,
          hostControlsDrawerOpen: false,
          chatOpen: false,
          confirmedGoBack: true,
          sessionNoteId: this.state.isPrimaryClinician
            ? data.session_note_id
            : this.state.sessionNoteId,
          billableTimeId: this.state.isPrimaryClinician
            ? data.billable_time_id
            : this.state.billableTimeId,
          animation: {},
          activity: {},
        });
        if (this.checkEndCall) {
          clearTimeout(this.checkEndCall);
        }
      }
    });
    socket.on("call_started", (data) => {
      if (this.state.isPrimaryClinician) {
        this.setState({ hasStartedCall: true });
      } else {
        this.props.getVideoCallInfo(this.props.match.params.videoKey);
      }
      if (!this.props.isCaregiver) {
        this.setState({ signatureId: data.signatureId });
      }
    });
    socket.on("bad_internet", (data) => {
      if (data.userId !== this.props.userId) {
        let badInternetParticipant = this.props.videoParticipants.find(
          (p) => p.user_id == data.userId
        );
        this.setState({ badInternetParticipant });
        this.toggleRemoteParticipantBadInternetDialog();
      }
    });
    socket.on("connection_failed", (data) => {
      if (data.userId !== this.props.userId) {
        let connectionFailedParticipant = this.props.videoParticipants.find(
          (p) => p.user_id == data.userId
        );
        this.setState({ connectionFailedParticipant });
        this.toggleRemoteConnectionFailedDialog();
      }
    });
    if (!this.props.isCaregiver) {
      socket.emit("get-participants", {
        videoId: this.props.videoId,
      });
      socket.on("client_joined", (data) => {
        this.setState({ clientJoined: true, clientJoinedData: data, chatOpen: false });
        if (this.state.hasJoinedRoom) {
          this.playChime();
        }
      });
      socket.on("last_login", (data) => {
        socket.on("card_chosen", (data) => {
          const { cardsCorrect, cardsIncorrect, initialPromptResponse } = this.state;
          this.setState({ clientPromptResponse: data });
          if (!initialPromptResponse) {
            if (data.correct) {
              this.setState({
                cardsCorrect: cardsCorrect + 1,
                initialPromptResponse: true,
                promptSelectionCorrect: true,
              });
            } else {
              this.setState({
                cardsIncorrect: cardsIncorrect + 1,
                initialPromptResponse: true,
                promptSelectionCorrect: false,
              });
            }
          }
        });
        this.setState({ clientLastLoginDate: data.last_activity });
      });

      socket.on("call_participants", (data) => {
        let videoParticipants = data.participants;
        let currentParticipant = data.participants?.find((p) => p.user_id === this.props.userId);
        let signatureId = currentParticipant?.signature_id;
        let remoteClinicianData = data.participants?.find(
          (p) => p.user_id !== this.props.clientUserId && p.user_id !== this.props.userId
        );
        let isPrimaryClinician = false;
        if (currentParticipant?.role === "PRIMARY") {
          isPrimaryClinician = true;
        }
        this.setState(
          {
            currentParticipant,
            signatureId,
            remoteClinicianData,
            videoParticipants,
            isPrimaryClinician,
          },
          () => {
            socket.emit("get-client-next-call", {
              videoId: this.props.videoId,
              clientId: this.props.clientId,
            });
          }
        );
      });
      socket.on("client_next_call", (data) => {
        if (this.state.isPrimaryClinician) {
          this.startTimerToNextClientCall(data);
        }
      });
      socket.on("merge_call_success", (data) => {
        this.setState({
          mergeNextSessionDialogOpen: false,
          mergeSessionLoading: false,
        });
      });
      socket.on("merge_call_fail", (data) => {
        this.setState({ mergeSessionError: true, mergeSessionLoading: false });
      });
      socket.on("pass_controls", (data) => {
        const { videoParticipants, clinicianInControl } = this.state;
        let controlsPassingClinician = videoParticipants?.find(
          (c) => c.user_id === data.inControlUserId
        );
        if (!clinicianInControl) {
          this.setState({
            passControlsRequestedDialogOpen: true,
            inControlUserId: data.inControlUserId,
            controlsPassingClinician,
          });
          this.startVideoControlsTimer();
        }
      });
      socket.on("pass_controls_denied", (data) => {
        if (this.state.clinicianInControl) {
          this.setState({ passControlsRejected: true });
        }
      });
      socket.on("pass_controls_timedout", (data) => {
        if (this.state.clinicianInControl) {
          this.setState({
            passControlsFailed: true,
          });
        }
      });
      socket.on("controls_requested", (data) => {
        const { videoParticipants, clinicianInControl } = this.state;
        let controlsRequestingClinician = videoParticipants?.find(
          (c) => c.user_id === data.passToUserId
        );
        if (clinicianInControl) {
          this.setState({
            passToUserId: data.passToUserId,
            controlsRequestingClinician,
            controlsRequestedDialogOpen: true,
          });
          this.startVideoControlsTimer();
        }
      });
      socket.on("controls_request_denied", (data) => {
        if (!this.state.clinicianInControl) {
          this.setState({
            takeControlsRejected: true,
            requestSentTextOpen: false,
          });
        }
      });
      socket.on("controls-request-timedout", (data) => {
        if (!this.state.clinicianInControl) {
          this.setState({ takeControlsFailed: true });
        }
      });

      socket.on("billing_completion", (data) => {
        if (data.signatureData?.clinician_id == this.props.user.clinician_id) {
          if (!data.billingComplete && !this.state.cptCodeDialogOpen) {
            this.setState({ signatureId: data.signatureData.id }, () => {
              if (this.props.endDate) {
                if (this.props.userId == this.props.secondaryClinicianUserId) {
                  if (data.clinicianSessionEnded) {
                    this.props.setSecondaryClinicianSessionTimes(data.signatureData);
                    if (this.state.activeRoom) {
                      this.state.activeRoom.disconnect();
                    }
                    this.setState({
                      secondaryClinicianHasIncompleteBilling: true,
                      cptCodeDialogOpen: true,
                      sessionNoteId: data.session_note_id,
                      billableTimeId: data.billable_time_id,
                    });
                  } else {
                    this.props.setSecondaryClinicianSessionTimes(data.signatureData);
                    this.onSecondaryClinicianLeaveRoom();
                  }
                } else {
                  this.clearTimer();
                  this.setState({
                    cptCodeDialogOpen: true,
                    sessionNoteId: data.session_note_id,
                    billableTimeId: data.billable_time_id,
                  });
                }
              }
              if (!this.props.endDate && data.clinicianSessionEnded) {
                this.props.setSecondaryClinicianSessionTimes(data.signatureData);
                if (this.state.activeRoom) {
                  this.state.activeRoom.disconnect();
                }
                this.setState({
                  secondaryClinicianHasIncompleteBilling: true,
                  cptCodeDialogOpen: true,
                  sessionNoteId: data.session_note_id,
                  billableTimeId: data.billable_time_id,
                });
              }
            });
          } else if (this.props.endDate && !this.state.cptCodeDialogOpen) {
            this.props.history.goBack();
          }
        } else if (
          this.props.endDate &&
          !data.signatureData &&
          data.billingComplete &&
          data.billingClinician == this.props.user.clinician_id
        ) {
          this.props.history.goBack();
        }
      });
    }
    if (this.props.isCaregiver) {
      socket.on("replace_client_user", (data) => {
        if (!this.state.replacingClientUser) {
          socket.emit("set-second-client-role");
          this.setState({ clientUserReplaced: true }, () => {
            this.clientLeaveRoom();
          });
        } else {
          this.setState({ allowedToJoinCall: true, replacingClientUser: false }, () => {
            this.onToggleSessionFullDialog();
            this.onSetReadyToJoinCall();
          });
        }
      });
      socket.emit("set-device", {
        videoId: this.props.videoId,
        deviceType: `${device_type} ${browser_type}`,
        clientCanScreenshare: this.canScreenShare(),
      });
      socket.on("card_response", (data) => {
        this.setState({ cardResponse: data.correct });
      });
      socket.on("primary_joined", (data) => {
        this.setState({ primaryClinicianData: data });
        if (this.state.hasJoinedRoom) {
          this.playChime();
        }
        socket.emit("rejoin", {
          videoId: this.props.videoId,
          userId: this.props.userId,
        });
      });
      socket.on("secondary_joined", (data) => {
        this.setState({ secondaryClinicianData: data });
        if (this.state.hasJoinedRoom) {
          this.playChime();
        }
        socket.emit("rejoin", {
          videoId: this.props.videoId,
          userId: this.props.userId,
        });
      });
      socket.on("end_client_screenshare", (data) => {
        this.endScreenCapture();
      });
    }
    socket.on("client_marked_as_no_show", (data) => {
      if (this.state.currentParticipant.role === "SECONDARY") {
        this.setState({ callEndedMarkedAsNoShow: true });
      }
    });
  };

  startTimerToNextClientCall = (call) => {
    if (!call || !call.scheduled_date) {
      return;
    }
    this.setState({ nextClientCall: call });
    let timerExpire = differenceInMilliseconds(
      new Date(subMinutes(new Date(call.scheduled_date), 5)),
      new Date()
    );
    if (timerExpire > 0) {
      this.nextCallTimer = setTimeout(() => {
        if (this.state.hasJoinedRoom) {
          this.onToggleMergeNextSessionDialog();
        }
      }, timerExpire);
    }
  };

  getClientLastLogin = () => {
    if (!socket) return;
    socket.emit("get-login", {
      videoId: this.props.videoId,
    });
  };

  canScreenShare = () => {
    if (navigator?.mediaDevices && "getDisplayMedia" in navigator.mediaDevices) {
      return true;
    } else {
      return false;
    }
  };

  isClientOnMobileDevice = () => {
    let device = this.state.clientDeviceType?.deviceType;
    if (device) {
      if (/android|ios|blackberry/i.test(device)) {
        return true;
      } else {
        return false;
      }
    }
  };

  captureScreen = async () => {};

  endScreenCapture = async () => {
    const { activeRoom, screenTrack } = this.state;
    const { isCaregiver } = this.props;
    screenTrack.stop();
    let localParticipant = activeRoom.localParticipant;
    this.detachTracks([screenTrack]);
    localParticipant.unpublishTrack(screenTrack);
    if (isCaregiver) {
      this.setState(
        {
          parentScreenSharing: false,
          screenTrack: null,
        },
        () => {
          this.setScreenShareOff();
        }
      );
    } else {
      this.setState(
        {
          clinicianScreenSharing: false,
          screenTrack: null,
        },
        () => {
          this.setScreenShareOff();
        }
      );
    }
  };

  joinRoom = () => {};

  playChime = () => {
    let audio = document.getElementById("chimeSound");
    if (audio && !this.state.endDate) {
      audio.volume = 0.2;
      audio.play();
    }
  };

  toggleAudioView = () => {
    this.setState({ muted: !this.state.muted });
  };

  toggleVideoView = () => {
    if (this.state.cameraDisabled) {
      this.onCloseVideoDisabledDialog();
    } else {
      this.onOpenVideoDisabledDialog();
    }
    this.setState({ cameraDisabled: !this.state.cameraDisabled });
  };

  onOpenVideoDisabledDialog = () => {
    this.setState({ videoDisabledOpen: true });
  };

  onCloseVideoDisabledDialog = () => {
    this.setState({ videoDisabledOpen: false });
  };

  clientLeaveRoom = () => {
    this.disconnectVideo();
    this.setState({
      hasJoinedRoom: false,
      localMediaAvailable: false,
      callEnded: true,
      clinicianJoined: false,
    });
  };

  leaveRoom = () => {};

  onSetReadyToJoinCall = () => {
    if (this.props.isCaregiver) {
      if (!this.state.allowedToJoinCall) {
        this.onToggleSessionFullDialog();
        return;
      }
      socket.emit("set-client-connected", {
        clientConnected: true,
        videoId: this.props.videoId,
      });
    }
    this.setState({ readyToJoinCall: true });
  };

  onStartVideoCall = () => {
    this.startTimer();
    this.joinRoom();
    if (!this.props.startDate) {
      if (this.props.oneTimeVideo) {
        this.startOneTimeVideo();
      } else {
        this.sendClientLink();
      }
    }
  };

  sendClientLink = () => {
    const videoDetails = {
      userId: this.props.clientUserId,
      clinicianUserId: this.props.userId,
      clientId: this.props.clientId,
      roomId: this.props.roomId,
    };
    this.props.startVideoCall(videoDetails);
    this.setState({ hasStartedCall: true });
  };

  startOneTimeVideo = async () => {
    const videoDetails = {
      roomId: this.props.roomId,
      videoId: this.props.videoId,
    };
    console.log("START-CALL SOCKET.EMIT DETAILS:", videoDetails);
    socket.emit("start-call", videoDetails);
  };

  toggleNotes = () => {
    this.setState({ notesOpen: !this.state.notesOpen });
  };

  toggleChatTab = () => {
    this.setState({ chatOpen: !this.state.chatOpen });
  };

  onOpenEndCallDialog = () => {
    if (!this.state.hasStartedCall) {
      if (this.state.activeRoom) {
        this.state.activeRoom.disconnect();
      }
      this.props.history.goBack();
    } else {
      this.setState({ endCallDialogOpen: true });
    }
  };

  onCloseEndCallDialog = () => {
    this.setState({ endCallDialogOpen: false });
  };

  onOpenNoShowEndCallDialog = () => {
    this.setState({ noShowEndCallDialogOpen: true });
  };

  onCloseNoShowEndCallDialog = () => {
    this.setState({ noShowEndCallDialogOpen: false });
  };

  markNoShow = (id) => {
    this.props.markClientNoShow(id);
    socket.emit("mark-no-show", { videoId: this.props.videoId });
  };

  toggleChatDrawer = () => {
    this.setState({ chatOpen: !this.state.chatOpen });
  };

  toggleActivityDrawer = () => {
    this.setState({ activityDrawerOpen: !this.state.activityDrawerOpen });
  };

  toggleHostControlsDrawer = () => {
    this.setState({
      hostControlsDrawerOpen: !this.state.hostControlsDrawerOpen,
    });
  };

  updateRoomLayout = (layout) => {
    this.setState({ roomLayout: layout });
    const newLayoutState = {
      userId: this.props.userId,
      action: "CHANGE_LAYOUT",
      type: layout,
      videoId: this.props.videoId,
    };
    socket.emit("update", newLayoutState);
  };

  startAnimation = (animation) => {
    const animationDetails = {
      userId: this.props.userId,
      action: "START_ANIMATION",
      type: "success",
      url: animation.url,
      videoId: this.props.videoId,
    };
    socket.emit("update", animationDetails);
    this.setState({ chatOpen: false });
  };

  startVideoReinforcement = () => {
    const videoDetails = {
      userId: this.props.userId,
      action: "START_ANIMATION",
      type: "video",
      url: this.state.videoURL,
      videoId: this.props.videoId,
      start: this.state.videoStartTime,
    };
    socket.emit("update", videoDetails);
    this.setState({ chatOpen: false });
  };

  endAnimation = () => {
    const animationDetails = {
      userId: this.props.userId,
      action: "END_ANIMATION",
      videoId: this.props.videoId,
    };
    socket.emit("update", animationDetails);
    this.setState({ videoURL: "" });
  };

  startActivity = (activity) => {
    const activityDetails = {
      userId: this.props.userId,
      action: "START_ACTIVITY",
      url: activity.url,
      videoId: this.props.videoId,
      type: activity.type,
      id: activity.id,
    };
    socket.emit("update", activityDetails);
    this.setState({ chatOpen: false });
    if (activity.type === "card") {
      this.setState({
        currentCard: this.props.cards[this.state.cardIndex],
        videoActivityOpen: false,
      });
    }
  };

  onSelectPromptActivity = (activity) => {
    this.setState({ promptDrawerOpen: true });
  };

  togglePromptDrawer = () => {
    this.setState({ promptDrawerOpen: !this.state.promptDrawerOpen });
  };

  endActivity = () => {
    const activityDetails = {
      userId: this.props.userId,
      action: "END_ACTIVITY",
      videoId: this.props.videoId,
    };
    socket.emit("update", activityDetails);
    this.setState({
      videoURL: "",
      promptActivityData: null,
      promptActivityStarted: false,
      fieldSize: 4,
      promptLevel: "none",
    });
  };

  startVideoActivity = (id) => {
    const activityDetails = {
      userId: this.props.userId,
      action: "START_ACTIVITY",
      url: this.state.videoURL,
      videoId: this.props.videoId,
      type: "video",
      start: this.state.videoStartTime,
      id: id,
    };
    socket.emit("update", activityDetails);
    this.setState({ chatOpen: false });
  };

  showCard = () => {
    const { cards } = this.props;
    const { cardIndex } = this.state;
    this.setState(
      {
        cardActivityStarted: true,
        promptNextCard: false,
        currentCard: cards[cardIndex],
        cardIndex: cardIndex + 1,
      },
      () => {
        const cardDetails = {
          videoId: this.props.videoId,
          cardId: this.state.currentCard.id,
          userId: this.props.userId,
        };
        socket.emit("show-card", cardDetails);
      }
    );
  };

  skipCard = () => {
    this.setState({ cardIndex: this.state.cardIndex + 1 });
  };

  skipPromptCard = () => {
    if (this.state.cardIndex === this.props.promptCards.length - 1) {
      this.setState({ cardIndex: 0 });
    } else {
      this.setState({ cardIndex: this.state.cardIndex + 1 });
    }
  };

  markCardResponse = (correct) => {
    const { cards, videoId, userId } = this.props;
    const { cardIndex, cardsCorrect, cardsIncorrect, currentCard } = this.state;
    const cardDetails = {
      videoId: videoId,
      cardId: currentCard.id,
      userId: userId,
      correct: correct,
    };
    socket.emit("mark-card-response", cardDetails);
    if (correct) {
      this.setState({ cardsCorrect: cardsCorrect + 1, promptNextCard: true });
    } else {
      this.setState({
        cardsIncorrect: cardsIncorrect + 1,
        promptNextCard: true,
      });
    }
  };

  setVideoURL = (e) => {
    this.setState({ videoURL: e.target.value });
  };

  pauseVideo = () => {
    const { videoId, userId } = this.props;
    const videoDetails = {
      videoId: videoId,
      userId: userId,
      playing: false,
    };
    socket.emit("video-toggle", videoDetails);
  };

  playVideo = () => {
    const { videoId, userId } = this.props;
    const videoDetails = {
      videoId: videoId,
      userId: userId,
      playing: true,
    };
    socket.emit("video-toggle", videoDetails);
  };

  setVideoStartTime = (e) => {
    this.setState({ videoStartTime: e.target.value });
  };

  seekToTime = () => {
    this.youTubeRef.seekTo(this.state.videoStartTime, "seconds");
  };

  setVideoPlaying = (playing) => {
    if (playing) {
      this.playVideo();
    } else {
      this.pauseVideo();
    }
    this.setState({ videoPlaying: playing });
  };

  setSigURLSFromCanvas = async (startSigURL) => {
    const { isCaregiver } = this.props;
    try {
      this.setState({ sigError: false });
      let startTimeURL = await this.saveSignature(startSigURL, "start");
      if (isCaregiver) {
        this.props.sendParentVideoSignatures({
          startSignatureURL: startTimeURL,
          videoId: this.props.videoId,
        });
      }
      return;
    } catch (error) {
      this.setState({ sigError: true });
    }
  };

  saveSignature = async (imageData, sigTime) => {
    const { primaryClinicianVideoSignatureId, secondaryClinicianVideoSignatureId, isCaregiver } =
      this.props;
    const { isPrimaryClinician } = this.state;
    let signatureId;
    if (isPrimaryClinician) {
      signatureId = primaryClinicianVideoSignatureId
        ? primaryClinicianVideoSignatureId
        : this.state.signatureId;
    } else {
      signatureId = secondaryClinicianVideoSignatureId
        ? secondaryClinicianVideoSignatureId
        : this.state.signatureId;
    }

    const credentials = await getAWSCredentialsForCurrentUserSession();
    let s3 = new AWS.S3({
      credentials,
      region: "us-east-1",
      signatureVersion: "v2",
      apiVersion: "2006-03-01",
      params: { Bucket: process.env.AWS_DOC_BUCKET },
    });
    const buf = new Buffer(imageData.replace(/^data:image\/\w+;base64,/, ""), "base64");
    let signatureImageName = isCaregiver
      ? `${this.props.videoId}_signature_client_${sigTime}.png`
      : `${this.props.videoId}_signature_bcba_${sigTime}_${signatureId}.png`;
    const params = {
      ACL: "public-read",
      Body: buf,
      ContentEncoding: "base64",
      ContentType: "image/png",
      Key: signatureImageName,
    };
    try {
      const s3url = await s3.upload(params).promise();
      return s3url.Location;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  onToggleAssessmentComplete = (event) => {
    this.setState({ assessmentComplete: event.target.checked });
  };

  onToggleScheduleClientVideo = () => {
    this.setState({
      scheduleCallOpen: !this.state.scheduleCallOpen,
      chatOpen: false,
    });
  };

  onFieldSizeChange = (event, value) => {
    this.setState({ fieldSize: value });
  };

  onPromptLevelChange = (event) => {
    const promptLevel = event?.target?.value || "none";
    this.setState({ promptLevel });
  };

  setupPromptActivity = () => {
    let activity = this.props.activities.find((activity) => activity.type === "prompt");
    const activityDetails = {
      userId: this.props.userId,
      action: "START_ACTIVITY",
      url: activity.url,
      videoId: this.props.videoId,
      type: activity.type,
      id: activity.id,
    };
    socket.emit("update", activityDetails);
    this.setState({
      promptDrawerOpen: false,
      chatOpen: false,
      videoActivityOpen: false,
    });
  };

  showPromptSet = () => {
    const { cardIndex } = this.state;
    const cardDetails = {
      videoId: this.props.videoId,
      userId: this.props.userId,
      cards: this.setupPromptCards(),
      prompt_level: this.state.promptLevel,
      field_size: this.state.fieldSize,
    };
    socket.emit("show-prompt", cardDetails);
    this.setState({
      promptActivityStarted: true,
      promptNextCard: false,
      correctPromptCard: this.props.promptCards[cardIndex],
      cardIndex: cardIndex + 1,
      initialResponse: false,
      firstPromptResponseCorrect: null,
    });
  };

  setupPromptCards = () => {
    const { promptCards } = this.props;
    const { cardIndex, fieldSize } = this.state;
    let correctCard = promptCards[cardIndex];
    correctCard.correct = true;
    let filteredCards = promptCards.filter((card) => card.id !== promptCards[cardIndex].id);
    let cards = this.shuffleCards(filteredCards).slice(0, fieldSize - 1);
    cards.map((card) => (card.correct = false));
    cards.push(correctCard);
    cards = this.shuffleCards(cards);
    return cards;
  };

  shuffleCards = (array) => {
    var currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  };

  onSelectPromptAnswer = (answer) => {
    const { promptActivityData, promptSelectionCorrect } = this.state;
    const cardDetails = {
      id: promptActivityData.id,
      videoId: this.props.videoId,
      userId: this.props.userId,
      cardId: answer.id,
      correct: answer.correct,
    };
    socket.emit("prompt-choice", cardDetails);
    if (answer.correct) {
      this.setState({ promptSelectionCorrect: true });
      if (promptSelectionCorrect === null) {
        this.setState({ firstPromptAttemptCorrect: true });
      }
    } else if (!answer.correct) {
      this.setState({
        promptSelectionCorrect: false,
        promptShakeAnimation: true,
      });
      setTimeout(() => {
        this.setState({ promptShakeAnimation: false });
      }, 2500);
    }
  };

  onCloseCPTCodeDialog = () => {
    this.setState({ cptCodeDialogOpen: false }, () => {
      this.props.history.push("/dashboard");
    });
  };

  onHideClientChat = () => {
    const eventObject = {
      hideChat: true,
      action: "TOGGLE_CHAT",
      userId: this.props.userId,
      videoId: this.props.videoId,
    };
    socket.emit("update", eventObject);
    this.setState({
      showClientChat: false,
    });
  };

  onShowClientChat = () => {
    const eventObject = {
      hideChat: false,
      action: "TOGGLE_CHAT",
      userId: this.props.userId,
      videoId: this.props.videoId,
    };
    socket.emit("update", eventObject);
    this.setState({
      showClientChat: true,
    });
  };

  onHideClientScreenShare = () => {
    const eventObject = {
      showScreenshare: false,
      action: "TOGGLE_SCREENSHARE",
      userId: this.props.userId,
      videoId: this.props.videoId,
    };
    socket.emit("update", eventObject);
    this.setState({
      showClientScreenShare: false,
    });
  };

  onShowClientScreenShare = () => {
    const eventObject = {
      showScreenshare: true,
      action: "TOGGLE_SCREENSHARE",
      userId: this.props.userId,
      videoId: this.props.videoId,
    };
    socket.emit("update", eventObject);
    this.setState({
      showClientScreenShare: true,
    });
  };

  onOpenBackPressDialog = () => {
    this.setState({ backPressDialogOpen: true });
  };

  onCloseBackPressDialog = () => {
    this.setState({ backPressDialogOpen: false });
  };

  onConfirmGoBack = () => {
    if (this.state.parentScreenSharing) {
      this.setState(
        {
          parentScreenSharing: false,
          screenTrack: null,
        },
        () => {
          this.setScreenShareOff();
        }
      );
    }
    if (this.state.clinicianScreenSharing) {
      this.setState(
        {
          clinicianScreenSharing: false,
          screenTrack: null,
        },
        () => {
          this.setScreenShareOff();
        }
      );
    }

    if (this.state.clinicianInControl && this.state.secondaryClinicianConnected) {
      const eventObject = {
        videoId: this.props.videoId,
        userId: this.state.remoteClinicianData?.user_id,
      };
      socket.emit("accept-controls", eventObject);
      if (this.state.roomLayout !== "standard") {
        this.updateRoomLayout("standard");
      }
      if (this.state.activity.active) {
        this.endActivity();
      }
    }
    this.setState({ confirmedGoBack: true }, () => {
      this.props.history.goBack();
    });
  };

  toggleSettingDialog = () => {
    this.setState({ settingDialogOpen: !this.state.settingDialogOpen });
  };

  getUserDevices = async () => {
    try {
      this.checkForOlderBrowsers();
      await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
      let devices = await navigator.mediaDevices.enumerateDevices();

      let audioInputs = devices.filter(
        (val) => val.kind == "audioinput" && val.deviceId !== undefined
      );
      let audioOutputs = devices.filter(
        (val) => val.kind == "audiooutput" && val.deviceId !== undefined
      );
      let cameraInputs = devices.filter(
        (val) => val.kind == "videoinput" && val.deviceId !== undefined
      );
      let mobileCameraInputs = [];
      if (cameraInputs.length > 0) {
        mobileCameraInputs = cameraInputs.filter(
          (input) =>
            input.label.toLowerCase().includes("front") ||
            input.label.toLowerCase().includes("back")
        );
      }
      this.setState(
        {
          audioInputs,
          audioOutputs,
          cameraInputs,
          mobileCameraInputs,
          browserPermissionError: false,
        },
        () => {
          this.checkForDeviceError();
        }
      );
    } catch (e) {
      console.log("error getting user devices: ", e);
      this.setState({ browserPermissionError: true }, () => setTimeout(this.getUserDevices, 2500));
    }
  };

  handleUserCameraChange = (e) => {};

  flipCamerasOnMobile = () => {};

  handleUserMicrophoneChange = (e) => {};

  handleUserSpeakerChange = (e) => {};

  setCurrentInput = (name, value) => {
    this.setState({ [name]: value }, () => {
      localStorage.setItem(name, value);
    });
  };

  handleUserLobbyCameraChange = (e) => {
    if (window.stream) {
      window.stream.getTracks().forEach((track) => {
        track.stop();
      });
    }
    var constraints = {
      audio: false,
      video: {
        height: { min: 480, max: 720 },
        frameRate: 24,
        width: { min: 640, max: 1280 },
      },
      maxAudioBitrate: 16000,
    };
    let deviceId = e.target.value;
    constraints.video.deviceId = deviceId ? { exact: deviceId } : undefined;

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        this.setCurrentInput("currentCameraInput", deviceId);
        this.video = document.querySelector("video");
        window.stream = stream;
        // Older browsers may not have srcObject
        if (!!this.video && "srcObject" in this.video) {
          this.video.srcObject = stream;
        } else {
          // Avoid using this in new browsers, as it is going away.
          this.video.src = window.URL.createObjectURL(stream);
        }
      })
      .catch((err) => {
        console.log(err.name + ": " + err.message);
      });
  };

  handleUserLobbyMicrophoneChange = (e) => {
    const { currentCameraInput } = this.state;
    if (this.state.microphoneTestOn) {
      this.endUserMicrophoneTesting();
    }
    let audioDeviceId = e.target.value;
    const constraints = {
      audio: {
        deviceId: audioDeviceId ? { exact: audioDeviceId } : undefined,
      },
      video: {
        deviceId: currentCameraInput ? { exact: this.state.currentCameraInput } : undefined,
      },
    };
    const video = this.lobbyLocalVideo;
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        this.setCurrentInput("currentAudioInput", audioDeviceId);
        // Older browsers may not have srcObject
        if (video && "srcObject" in video) {
          video.srcObject = stream;
        } else {
          // Avoid using this in new browsers, as it is going away.
          video.src = window.URL.createObjectURL(stream);
        }
        // if (!this.state.muted) {
        //     this.startUserMicrophoneTesting();
        // }
      })
      .catch((err) => {
        console.log(err.name + ": " + err.message);
      });
  };

  handleUserLobbySpeakerChange = (e) => {
    let audioDeviceId = e.target.value;
    const video = this.lobbyLocalVideo;
    try {
      video
        .setSinkId(audioDeviceId)
        .then(() => {
          this.setCurrentInput("currentAudioOutput", audioDeviceId);
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  testUserSpeaker = () => {
    let audio = document.getElementById("testMicSound");
    audio.addEventListener("ended", () => {
      this.setState({ speakerTestOn: false });
    });
    audio.setSinkId(this.state.currentAudioOutput).then(() => {
      audio.play();

      this.setState({ speakerTestOn: true });
    });
  };

  startUserMicrophoneTesting = async () => {
    if (navigator.mediaDevices.getUserMedia !== null) {
      const constraints = {
        video: false,
        audio: {
          deviceId: this.state.currentAudioInput ? this.state.currentAudioInput : undefined,
        },
      };
      try {
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        const audioCtx = new AudioContext();
        this.analyser = audioCtx.createAnalyser();
        this.analyser.fftSize = 256;
        this.audioSrc = audioCtx.createMediaStreamSource(stream);
        this.audioSrc.connect(this.analyser);
        let bufferLength = this.analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);
        var canvas = this.microphoneTestCanvas;
        canvas.width = 50;
        canvas.height = 35;
        var canvasCtx = canvas.getContext("2d");
        var WIDTH = canvas.width;
        var HEIGHT = canvas.height;
        this.drawTimeData = () => {
          canvasCtx.clearRect(0, 0, WIDTH, HEIGHT);
          this.audioVisualizationId = requestAnimationFrame(this.drawTimeData);
          this.analyser.getByteFrequencyData(dataArray);
          var barWidth = (WIDTH / bufferLength) * 7;
          var barHeight;
          var x = 0;
          for (var i = 0; i < bufferLength; i++) {
            barHeight = dataArray[i];
            canvasCtx.fillStyle = `rgb(142,66,202)`;
            canvasCtx.fillRect(x, HEIGHT - barHeight / 7, barWidth, barHeight / 7);
            x += barWidth + 1;
          }
        };
        this.drawTimeData();
        this.setState({ microphoneTestOn: true });
      } catch (err) {
        console.log(err);
      }
    }
  };

  endUserMicrophoneTesting = () => {
    cancelAnimationFrame(this.audioVisualizationId);
    this.analyser.disconnect();
    this.audioSrc.disconnect();
    this.setState({ microphoneTestOn: false });
  };

  canChangeSpeaker = () => {
    if (
      this.state.audioOutputs?.length == 0 ||
      browser_type === "Firefox" ||
      this.state.clientOnMobile
    ) {
      return false;
    } else {
      return true;
    }
  };
  canChangeVideoInput = () => {
    if (this.state.cameraInputs?.length == 0 || browser_type === "Firefox") {
      return false;
    } else {
      return true;
    }
  };
  canChangeAudioInput = () => {
    if (
      this.state.audioInputs?.length == 0 ||
      browser_type === "Firefox" ||
      this.state.clientOnMobile
    ) {
      return false;
    } else {
      return true;
    }
  };

  handlePerformanceDialogClose = () => {
    this.setState({ performanceDialogOpen: false });
  };

  handlePerformanceDialogOpen = () => {
    this.props.clearComputerPerformance();
    this.setState({ performanceDialogOpen: true });
  };

  handleSpeedTestDialogOpen = () => {
    this.setState({ speedTestDialogOpen: true });
  };

  handleSpeedTestDialogClose = () => {
    this.setState({ speedTestDialogOpen: false });
  };

  startTimer = () => {
    const { startDate } = this.props;
    let currentTime = new Date().getTime();
    var videoStart = !startDate ? currentTime : new Date(startDate).getTime();
    let difference;
    let timerId = setInterval(() => {
      currentTime = new Date().getTime();
      difference = currentTime - videoStart;
      this.setState({
        clinicianPassedTime: difference,
      });
    }, 1000);
    this.setState({ timerId });
  };

  clearTimer = () => {
    clearInterval(this.state.timerId);
  };

  setScreenShareOn = () => {
    const eventObject = {
      screenshareOn: true,
      screenshareUserId: this.props.userId,
      action: "CHECK_SCREENSHARE",
      userId: this.props.userId,
      videoId: this.props.videoId,
    };
    socket.emit("update", eventObject);
  };

  setScreenShareOff = () => {
    const eventObject = {
      screenshareOn: false,
      screenshareUserId: null,
      action: "CHECK_SCREENSHARE",
      userId: this.props.userId,
      videoId: this.props.videoId,
    };
    socket.emit("update", eventObject);
  };

  endClientScreenShare = () => {
    const eventObject = {
      videoId: this.props.videoId,
    };
    socket.emit("end-client-screenshare", eventObject);
  };

  onSecondaryClinicianJoinCall = () => {
    this.joinRoom();
    const videoDetails = {
      clinicianid: this.props.user_clinician_id,
      videoId: this.props.videoId,
    };
    this.props.secondaryClinicianJoinCall(videoDetails);
    this.startTimer();
  };

  onSecondaryClinicianLeaveRoom = () => {
    this.disconnectVideo();
    let signatureId = this.props.secondaryClinicianVideoSignatureId
      ? this.props.secondaryClinicianVideoSignatureId
      : this.state.signatureId;
    const videoDetails = {
      clinicianid: this.props.user_clinician_id,
      signatureId,
    };
    this.props.secondaryClinicianLeaveCall(videoDetails);
    this.setState({
      hasJoinedRoom: false,
      localMediaAvailable: false,
      secondaryLeaveCallDialogOpen: false,
      backPressDialogOpen: false,
      confirmedGoBack: true,
      animation: {},
      activity: {},
    });
    if (this.state.clinicianInControl) {
      const eventObject = {
        videoId: this.props.videoId,
        userId: this.state.remoteClinicianData?.user_id,
      };
      socket.emit("accept-controls", eventObject);
      if (this.state.roomLayout !== "standard") {
        this.updateRoomLayout("standard");
      }
      if (this.state.activity.active) {
        this.endActivity();
      }
    } else {
      this.setState({ roomLayout: "standard" });
    }
  };

  secondaryClinicianStartTimer = () => {
    const { secondaryClinicianJoinTime } = this.props;
    let currentTime = new Date().getTime();
    let difference;
    let secondaryClinicianTimerId = setInterval(() => {
      currentTime = new Date().getTime();
      difference = currentTime - new Date(secondaryClinicianJoinTime).getTime();
      this.setState({
        secondaryClinicianPassedTime: difference,
      });
    }, 1000);
    this.setState({ secondaryClinicianTimerId });
  };

  secondaryClinicianClearTimer = () => {
    clearInterval(this.state.secondaryClinicianTimerId);
  };

  endClientScreenShare = () => {
    const eventObject = {
      videoId: this.props.videoId,
    };
    socket.emit("end-client-screenshare", eventObject);
  };

  onOpenSecondaryLeaveCallDialog = () => {
    if (!this.state.hasStartedCall || this.props.oneTimeVideoInfo?.billing_type === "ORIENTATION") {
      if (this.state.activeRoom) {
        this.state.activeRoom.disconnect();
      }
      this.props.history.goBack();
    } else {
      this.setState({ secondaryLeaveCallDialogOpen: true });
    }
  };

  onCloseSecondaryLeaveCallDialog = () => {
    this.setState({ secondaryLeaveCallDialogOpen: false });
  };

  togglePassControlsConfirm = () => {
    this.setState({
      passControlsConfirmOpen: !this.state.passControlsConfirmOpen,
    });
  };

  onClosePassControlsFailedDialog = () => {
    this.setState({ passControlsFailed: false, passControlBtnDisabled: false });
  };

  onClosePassControlsRejectedDialog = () => {
    this.setState({
      passControlsRejected: false,
      passControlBtnDisabled: false,
    });
  };

  onCloseControlsPassedDialog = () => {
    this.setState({ controlsPassed: false, passControlBtnDisabled: false });
  };

  onCloseControlsRequestTimedOutDialog = () => {
    this.setState({ controlsRequestTimedOutDialogOpen: false });
  };

  onCloseControlsReceivedDialog = () => {
    this.setState({ controlsReceived: false });
  };

  onCloseTakeControlsRejectedDialog = () => {
    this.setState({ takeControlsRejected: false });
  };

  onCloseGiveControlsRequestTimedOutDialog = () => {
    this.setState({ giveControlsRequestTimedOutDialogOpen: false });
  };
  onCloseTakeControlsFailedDialog = () => {
    this.setState({ takeControlsFailed: false, requestSentTextOpen: false });
  };

  passControls = () => {
    const eventObject = {
      videoId: this.props.videoId,
      userId: this.props.userId,
    };
    socket.emit("pass-controls", eventObject);
    this.setState({ passControlBtnDisabled: true });
    this.togglePassControlsConfirm();
  };

  acceptPassControlsRequest = () => {
    const eventObject = {
      videoId: this.props.videoId,
      userId: this.props.userId,
    };
    socket.emit("accept-controls", eventObject);
    if (this.state.roomLayout !== "standard") {
      this.updateRoomLayout("standard");
    }
    if (this.state.activity.active) {
      this.endActivity();
    }
    this.setState({
      passControlsRequestedDialogOpen: false,
      countdown: 25,
    });

    clearInterval(this.videoControlsAcceptTimerId);
  };

  declinePassControlsRequest = () => {
    const eventObject = {
      videoId: this.props.videoId,
      userId: this.props.userId,
    };
    socket.emit("deny-controls", eventObject);
    this.setState({ passControlsRequestedDialogOpen: false, countdown: 25 });
    clearInterval(this.videoControlsAcceptTimerId);
  };

  startVideoControlsTimer = () => {
    const { passControlsRequestedDialogOpen, controlsRequestedDialogOpen } = this.state;
    clearInterval(this.videoControlsAcceptTimerId);
    const eventObject = {
      videoId: this.props.videoId,
      userId: this.props.userId,
    };
    this.videoControlsAcceptTimerId = setInterval(() => {
      const secondsLeft = this.state.countdown - 1;
      if (secondsLeft === -1) {
        clearInterval(this.videoControlsAcceptTimerId);
        if (passControlsRequestedDialogOpen) {
          this.setState({
            passControlsRequestedDialogOpen: false,
            controlsRequestTimedOutDialogOpen: true,
            countdown: 25,
          });
          socket.emit("pass-controls-timeout", eventObject);
        } else if (controlsRequestedDialogOpen) {
          this.setState({
            controlsRequestedDialogOpen: false,
            giveControlsRequestTimedOutDialogOpen: true,
            countdown: 25,
          });
          socket.emit("controls-request-timeout", eventObject);
        }
      } else {
        this.setState({ countdown: secondsLeft });
      }
    }, 1000);
  };

  makeRequest = () => {
    const eventObject = {
      videoId: this.props.videoId,
      userId: this.props.userId,
    };
    socket.emit("request-controls", eventObject);
    this.setState({ requestSentTextOpen: true });
  };

  acceptControlsRequest = () => {
    const eventObject = {
      videoId: this.props.videoId,
      userId: this.props.userId,
      passToUserId: this.state.passToUserId,
    };
    socket.emit("accept-controls-request", eventObject);
    if (this.state.roomLayout !== "standard") {
      this.updateRoomLayout("standard");
    }
    if (this.state.activity.active) {
      this.endActivity();
    }
    this.setState({
      controlsRequestedDialogOpen: false,
      countdown: 25,
    });
    if (this.state.screenTrack) {
      this.endScreenCapture();
    }
    clearInterval(this.videoControlsAcceptTimerId);
  };

  declineControlsRequest = () => {
    const eventObject = {
      videoId: this.props.videoId,
      userId: this.props.userId,
    };
    socket.emit("deny-controls-request", eventObject);
    this.setState({ controlsRequestedDialogOpen: false, countdown: 25 });
    clearInterval(this.videoControlsAcceptTimerId);
  };

  openSessionNotesDialog = () => {
    this.setState({ sessionNotesDialogOpen: true });
  };

  closeSessionNotesDialog = () => {
    this.setState({ sessionNotesDialogOpen: false }, () => {
      this.props.history.push("/dashboard");
    });
  };

  toggleRemoteParticipantBadInternetDialog = () => {
    this.setState({
      remoteBadInternetDialogOpen: !this.state.remoteBadInternetDialogOpen,
    });
  };

  toggleRemoteConnectionFailedDialog = () => {
    this.setState({
      remoteConnectionFailedDialogOpen: !this.state.remoteConnectionFailedDialogOpen,
    });
  };

  toggleLocalBadInternetDialog = () => {
    this.setState({
      localBadInternetDialogOpen: !this.state.localBadInternetDialogOpen,
    });
  };

  toggleConnectionFailedDialogOpen = () => {
    this.setState({
      connectionFailedDialogOpen: !this.state.connectionFailedDialogOpen,
    });
  };

  getUnreadMessageCount = async () => {
    const { isCaregiver, user, oneTimeVideoInfo, isAssignedClinician, videoChatUrl } = this.props;
    const { clientJoined, hasJoinedRoom } = this.state;
    let unreadMessageCount = 0;
    let channelUrl = oneTimeVideoInfo.conversation_url || videoChatUrl;
    if (videoChatUrl && (hasJoinedRoom || (!isCaregiver && !isAssignedClinician))) {
      channelUrl = videoChatUrl;
    }
    if (channelUrl && this.props.sendbirdConnection) {
      let channel = await getChannel(channelUrl, this.props.sendbirdConnection);
      if (channel) {
        unreadMessageCount = channel.unreadMessageCount;
      }
    }
    this.setState({ unreadMessageCount });
  };

  toggleAccessGranted = () => {
    this.setState({ inputAccessGranted: !this.state.inputAccessGranted });
  };

  checkForUnsupportedPlatformDecision = () => {
    const clientUsedUnsupportedPlatform = cookie.load("clientUsedUnsupportedPlatform");
    const clientAcceptedUnsupportedPlatform = cookie.load("clientAcceptedUnsupportedPlatform");
    if (!clientUsedUnsupportedPlatform && !clientAcceptedUnsupportedPlatform) {
      this.setState({ showUnsupportedPlatformDialog: true });
    } else if (clientUsedUnsupportedPlatform && !clientAcceptedUnsupportedPlatform) {
      this.setState({
        showUnsupportedPlatformDialog: true,
        showUnsupportedPlatformCheckbox: true,
      });
    } else if (clientUsedUnsupportedPlatform && clientAcceptedUnsupportedPlatform) {
      this.setState({
        showUnsupportedPlatformDialog: false,
        showUnsupportedPlatformCheckbox: false,
      });
    }
  };

  toggleUnsupportedPlatfromMessage = () => {
    this.setState(
      {
        hideUnsupportedPlatformMessage: !this.state.hideUnsupportedPlatformMessage,
      },
      () => {
        if (this.state.hideUnsupportedPlatformMessage) {
          const expires = new Date();
          expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 180);
          cookie.save("clientAcceptedUnsupportedPlatform", true, {
            path: "/video",
            expires,
          });
        } else {
          cookie.remove("clientAcceptedUnsupportedPlatform", {
            path: "/video",
          });
        }
      }
    );
  };

  closeUnsupportedPlatformDialog = () => {
    this.setState({ showUnsupportedPlatformDialog: false }, () => {
      const clientUsedUnsupportedPlatform = cookie.load("clientUsedUnsupportedPlatform");
      if (!clientUsedUnsupportedPlatform) {
        cookie.save("clientUsedUnsupportedPlatform", true, {
          path: "/video",
        });
      }
    });
  };

  leaveUnsupportedPlatform = () => {
    this.closeUnsupportedPlatformDialog();
    this.clientLeaveRoom();
  };

  closeScreenShareErrorDialog = () => {
    this.setState({ screenSharingNotSupported: false });
  };

  getDeviceInformation = () => {
    let isWebRTCSupported =
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia ||
      window.RTCPeerConnection;

    let isWebSocketsSupported = window.WebSocket || window.MozWebSocket;

    let isWebAudioApiSupported =
      window.AudioContext ||
      window.webkitAudioContext ||
      window.mozAudioContext ||
      window.msAudioContext;

    let isScreenSharingSupported;
    if (this.canScreenShare()) {
      isScreenSharingSupported = true;
    } else {
      isScreenSharingSupported = false;
    }

    let checkList = [
      isWebRTCSupported,
      isWebSocketsSupported,
      isWebAudioApiSupported,
      isScreenSharingSupported,
    ];
    let deviceInformationError = checkList.every((item) => !item);
    this.setState({ deviceInformationError });
  };

  findDownloadSpeedAverage = async () => {
    const { fileList } = this.state;
    this.setState({ ...getInitialSpeedTestState() });

    for (let i = 0; i < fileList.length; i++) {
      await this.handleDownloadFile(fileList[i]);
    }
    let total = this.state.downloadSpeedLog.reduce((total, el) => {
      return total + Number(el);
    }, 0);

    this.setState({
      averageDownloadMbps: +(total / 13).toFixed(2),
      downloadTestEnded: true,
    });

    await this.findUploadSpeedAverage();
  };

  handleDownloadFile = async (fileName) => {
    try {
      const startTime = new Date().getTime();
      const baseUrl = process.env.BASE_URL;
      const downloadUrl = `${baseUrl}/download/file/${fileName}?cache=${new Date().getTime()}`;

      let resp = await axios({
        url: downloadUrl,
        method: "GET",
        responseType: "blob",
        cancelToken: new CancelToken(function executor(c) {
          cancel = c;
        }),
      });
      const endTime = new Date().getTime();
      let loadTime = (endTime - startTime) / 1000;
      let speed = this.findSpeed(loadTime, resp.data.size);
      this.setState((prevState) => ({
        downloadSpeedLog: [...prevState.downloadSpeedLog, speed],
      }));
    } catch (err) {
      console.log(err);
    }
  };

  findUploadSpeedAverage = async () => {
    const { uploadFileList } = this.state;
    try {
      for (let i = 0; i < uploadFileList.length; i++) {
        const credentials = await getAWSCredentialsForCurrentUserSession();
        const s3 = new AWS.S3({ credentials });
        const params = {
          Bucket: process.env.AWS_USER_DOC_BUCKET,
          Key: `speedtest/${uploadFileList[i]}`,
        };
        const file = await s3.getObject(params).promise();
        this.setState({ uploadDocument: file });
        if (this.state.speedTestCancelled) {
          break;
        }
        await this.handleUploadFile(uploadFileList[i]);
      }

      let total = this.state.uploadSpeedLog.reduce((total, el) => {
        return total + Number(el);
      }, 0);
      if (this.state.speedTestCancelled) {
        this.setState({ ...getInitialSpeedTestState });
      } else {
        this.setState({
          uploadTestEnded: true,
          averageUploadMbps: +(total / 4).toFixed(2),
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleUploadFile = async (fileName) => {
    const { uploadDocument } = this.state;
    try {
      const startTime = new Date().getTime();
      const credentials = await getAWSCredentialsForCurrentUserSession();
      const s3 = new AWS.S3({
        credentials,
        apiVersion: "2006-03-01",
        params: { Bucket: process.env.AWS_USER_DOC_BUCKET },
      });
      const params = {
        ACL: "authenticated-read",
        Body: uploadDocument.Body,
        ContentType: uploadDocument.ContentType,
        Key: `speedtest/${fileName}`,
      };
      await s3.putObject(params).promise();
      const endTime = new Date().getTime();
      let loadTime = (endTime - startTime) / 1000;
      let speed = this.findSpeed(loadTime, uploadDocument.ContentLength);
      this.setState((prevState) => ({
        uploadSpeedLog: [...prevState.uploadSpeedLog, speed],
      }));
    } catch (err) {
      console.log(err);
    }
  };

  findSpeed = (seconds, size) => {
    let loadedBits = size * 8;
    let bps = (loadedBits / seconds).toFixed(2);
    let speedInMbps = (bps / 1000000).toFixed(2);
    return speedInMbps;
  };

  checkForOlderBrowsers = () => {
    // Older browsers might not implement mediaDevices at all, so we set an empty object first
    if (navigator.mediaDevices === undefined) {
      navigator.mediaDevices = {};
    }
    // Some browsers partially implement mediaDevices. We can't just assign an object
    // with getUserMedia as it would overwrite existing properties.
    // Here, we will just add the getUserMedia property if it's missing.
    if (navigator.mediaDevices.getUserMedia === undefined) {
      navigator.mediaDevices.getUserMedia = function (constraints) {
        // First get ahold of the legacy getUserMedia, if present
        var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

        // Some browsers just don't implement it - return a rejected promise with an error
        // to keep a consistent interface
        if (!getUserMedia) {
          return Promise.reject(new Error("getUserMedia is not implemented in this browser"));
        }
        // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
        return new Promise(function (resolve, reject) {
          getUserMedia.call(navigator, constraints, resolve, reject);
        });
      };
    }
  };

  onToggleMergeNextSessionDialog = () => {
    this.setState({
      mergeNextSessionDialogOpen: !this.state.mergeNextSessionDialogOpen,
    });
  };

  onMergeNextSession = () => {
    const { nextClientCall } = this.state;
    this.setState({ mergeSessionLoading: true, mergeSessionError: false });
    const details = {
      videoId: this.props.videoId,
      nextCallId: nextClientCall.video_call_id,
    };
    socket.emit("merge-sessions", details);
  };

  checkForDeviceError = () => {
    const { audioInputs, audioOutputs, cameraInputs } = this.state;
    let cameraError = cameraInputs.length == 0 || cameraInputs.some((input) => !input.deviceId);
    let microphoneError = audioInputs.length == 0 || audioInputs.some((input) => !input.deviceId);
    let speakerError =
      browser_type !== "Firefox" &&
      browser_type !== "Safari" &&
      !device_type?.toLowerCase().includes("ios") &&
      (audioOutputs.length == 0 || audioOutputs.some((input) => !input.deviceId));
    this.setState({ cameraError, microphoneError, speakerError });
  };

  goToTestPage = () => {
    socket?.disconnect(true);
    this.props.history.push({ pathname: "/test", state: { fromLobby: true } });
  };

  closeErrorDialog = () => {
    this.setState({ ...getInitialTestState(), ...getInitialSpeedTestState() });
  };

  onToggleSessionFullDialog = () => {
    this.setState({ sessionFullDialogOpen: !this.state.sessionFullDialogOpen });
  };

  onJoinFullSession = () => {
    this.setState({ replacingClientUser: true, clientUserReplaced: false }, () => {
      socket.emit("replace-current-client", { videoId: this.props.videoId });
    });
  };

  onSendSMSMessage = (message) => {
    this.props.sendSMSToCaregiver({
      clientId: this.props.clientId,
      message,
    });
  };

  onOpenSMSFacilityDialog = () => {
    this.setState({ smsFacilityDialogOpen: true, hostControlsDrawerOpen: false }, () => {
      this.smsPolling = setInterval(
        () => this.props.getSMSThreadByClient(this.props.clientId),
        5000
      );
    });
  };

  onCloseSMSFacilityDialog = () => {
    this.setState({ smsFacilityDialogOpen: false }, () => {
      clearInterval(this.smsPolling);
    });
  };

  render() {
    const {
      classes,
      videoIsRunning,
      videoId,
      isCaregiver,
      endDate,
      getComputerPerformance,
      performance,
      getTestLoading,
      getSuccess,
    } = this.props;
    const {
      clinicianJoined,
      confirmedGoBack,
      backPressDialogOpen,
      activity,
      hasStartedCall,
      inVideoRoom,
      isPrimaryClinician,
      socketConnected,
      badInternetParticipant,
      performanceDialogOpen,
      microphoneError,
      cameraError,
      speakerError,
      downloadTestEnded,
      averageDownloadMbps,
      uploadTestEnded,
      averageUploadMbps,
      currentParticipant,
      connectionFailedParticipant,
      fromStaticUrl,
      endCallTriggered,
    } = this.state;
    let videoJoinDate = isPrimaryClinician
      ? this.props.startDate
      : this.props.secondaryClinicianJoinTime;
    let videoLeaveDate = isPrimaryClinician
      ? this.props.endDate
      : this.props.secondaryClinicianLeaveTime;
    let currentClinicianName = currentParticipant?.credentials
      ? currentParticipant.full_name + ", " + currentParticipant.credentials
      : currentParticipant?.full_name;
    return (
      <>
        <audio id="chimeSound">
          <source src={chimeEffect} type="audio/mpeg" />
        </audio>
        <audio id="testMicSound">
          <source src={testMicEffect} type="audio/mpeg" />
        </audio>
        <Prompt
          when={
            (videoIsRunning &&
              clinicianJoined &&
              !confirmedGoBack &&
              isCaregiver &&
              !fromStaticUrl) ||
            (hasStartedCall && !confirmedGoBack && inVideoRoom && !isCaregiver)
          }
          message={(nextLocation, action) => {
            if (this.currentPath !== nextLocation.pathname && action === "POP") {
              window.history.forward();
            }
            this.onOpenBackPressDialog();
            return false;
          }}
        />
        <div className={classes.videoChatContainer}>
          {!isCaregiver && (
            <div
              className={classes.socketConnectionIndicator}
              style={{
                backgroundColor: socketConnected ? "#10B981" : "#EF4444",
              }}
            />
          )}

          <VideoBreakdownDialog
            //temp solution for 1969 issue - keep this closed and we will navigate to billing from componentDidUpdate
            open={this.state.cptCodeDialogOpen && this.state.billableTimeId && this.state.isDemo}
            startDate={videoJoinDate}
            endDate={videoLeaveDate}
            videoCallId={this.props.videoId}
            clientUserId={this.props.clientUserId}
            isDemo={this.state.isDemo}
            onCloseCPTCodeDialog={this.onCloseCPTCodeDialog}
            clientData={this.state.clientJoinedData}
            inVideoCall={true}
            enterInsuranceCode={true}
            billingType={this.props.oneTimeVideoInfo?.billing_type}
            isSecondary={!this.state.isPrimaryClinician}
            currentClinician={currentClinicianName}
            clientId={this.props.clientId}
            billableTimeId={this.state.billableTimeId}
          />
          <ActivityComponent
            {...this.props}
            {...this.state}
            markCardResponse={this.markCardResponse}
            showCard={this.showCard}
            endAnimation={this.endAnimation}
            playVideo={this.playVideo}
            pauseVideo={this.pauseVideo}
            youTubeRef={this.setYouTubeRef}
            setVideoPlaying={this.setVideoPlaying}
            showPromptSet={this.showPromptSet}
            seekToTime={this.seekToTime}
            onSelectPromptAnswer={this.onSelectPromptAnswer}
            deviceType={device_type}
          />
          <VideoComponent
            hideIntercomAi={
              this.state.activityDrawerOpen ||
              this.state.hostControlsDrawerOpen ||
              this.state.notesOpen ||
              this.state.chatOpen
            }
            joinRoom={this.joinRoom}
            clientLeaveRoom={this.clientLeaveRoom}
            toggleAudio={this.toggleAudio}
            toggleVideo={this.toggleVideo}
            toggleVideoView={this.toggleVideoView}
            toggleAudioView={this.toggleAudioView}
            flipCamerasOnMobile={this.flipCamerasOnMobile}
            handleRoomNameChange={this.handleRoomNameChange}
            remoteMedia={this.setRemoteMedia}
            clinicianRemoteMedia={this.setClinicianRemoteMedia}
            localMedia={this.setLocalMedia}
            lobbyLocalVideo={this.setLobbyLocalVideo}
            screenShareMedia={this.setScreenShareMedia}
            remoteScreenShareMedia={this.setRemoteScreenShareMedia}
            sendClientLink={this.sendClientLink}
            startOneTimeVideo={this.startOneTimeVideo}
            onOpenEndCallDialog={this.onOpenEndCallDialog}
            onOpenNoShowEndCallDialog={this.onOpenNoShowEndCallDialog}
            setSigURLSFromCanvas={this.setSigURLSFromCanvas}
            onToggleAssessmentComplete={this.onToggleAssessmentComplete}
            onStartVideoCall={this.onStartVideoCall}
            onSetReadyToJoinCall={this.onSetReadyToJoinCall}
            toggleNotes={this.toggleNotes}
            toggleSettingDialog={this.toggleSettingDialog}
            getClientLastLogin={this.getClientLastLogin}
            onSecondaryClinicianJoinCall={this.onSecondaryClinicianJoinCall}
            onOpenSecondaryLeaveCallDialog={this.onOpenSecondaryLeaveCallDialog}
            endScreenCapture={this.endScreenCapture}
            canScreenShare={this.canScreenShare}
            captureScreen={this.captureScreen}
            videoJoinDate={videoJoinDate}
            videoLeaveDate={videoLeaveDate}
            setSecondaryClinicianSigURLSFromCanvas={this.setSecondaryClinicianSigURLSFromCanvas}
            goBack={this.goBack}
            localParticipantNetworkLevel={this.state.localParticipantNetworkLevel}
            setCurrentInput={this.setCurrentInput}
            mouseMove={this.mouseMove}
            toggleChatTab={this.toggleChatTab}
            toggleAccessGranted={this.toggleAccessGranted}
            canChangeVideoInput={this.canChangeVideoInput}
            canChangeSpeaker={this.canChangeSpeaker}
            canChangeAudioInput={this.canChangeAudioInput}
            toggleActivityDrawer={this.toggleActivityDrawer}
            toggleHostControlsDrawer={this.toggleHostControlsDrawer}
            // Settings Dialog props
            classes={classes}
            toggleSettingsDialog={this.toggleSettingsDialog}
            open={this.state.settingMenuOpen}
            microphones={this.state.audioInputs}
            speakers={this.state.audioOutputs}
            cameras={this.state.cameraInputs}
            runSpeedTest={this.handleSpeedTestDialogOpen}
            runPerformanceTest={this.handlePerformanceDialogOpen}
            runAudioTest={this.testUserSpeaker}
            currentMicrophone={this.state.currentAudioInput}
            currentSpeaker={this.state.currentAudioOutput}
            currentCamera={this.state.currentCameraInput}
            markNoShow={this.markNoShow}
            handleUserCameraChange={
              this.state.hasJoinedRoom
                ? this.handleUserCameraChange
                : this.handleUserLobbyCameraChange
            }
            handleUserMicrophoneChange={
              this.state.hasJoinedRoom
                ? this.handleUserMicrophoneChange
                : this.handleUserLobbyMicrophoneChange
            }
            handleUserSpeakerChange={
              this.state.hasJoinedRoom
                ? this.handleUserSpeakerChange
                : this.handleUserLobbySpeakerChange
            }
            {...this.state}
            {...this.props}
          />
          {this.state.animation.active && this.state.animation.type === "success" && (
            <Animation endAnimation={this.endAnimation} {...this.props} {...this.state} />
          )}
        </div>
        {this.state.hasStartedCall &&
          !endDate &&
          this.state.hasJoinedRoom &&
          !this.props.secondaryClinicianLeftCall && (
            <SessionTimer
              isClinician={this.props.isClinician}
              clinicianPassedTime={this.state.clinicianPassedTime}
              isSecondary={this.props.isClinician && !this.state.isPrimaryClinician}
              secondaryClinicianPassedTime={this.state.secondaryClinicianPassedTime}
            />
          )}
        {activity.active &&
          activity.type === "card" &&
          this.state.clinicianInControl &&
          this.state.hasStartedCall && (
            <CardControls
              skipCard={this.skipCard}
              endActivity={this.endActivity}
              {...this.state}
              {...this.props}
            />
          )}
        {activity.active &&
          activity.type === "prompt" &&
          this.state.clinicianInControl &&
          this.state.hasStartedCall && (
            <PromptControls
              skipPromptCard={this.skipPromptCard}
              endActivity={this.endActivity}
              {...this.state}
              {...this.props}
            />
          )}
        {!endDate && videoId && (
          <ChatDrawer
            chatOpen={this.state.chatOpen}
            toggleChatDrawer={this.toggleChatDrawer}
            isClientOnMobileDevice={this.isClientOnMobileDevice}
            {...this.props}
            {...this.state}
          />
        )}
        {!endDate && (
          <ActivityDrawer
            toggleActivityDrawer={this.toggleActivityDrawer}
            updateRoomLayout={this.updateRoomLayout}
            startAnimation={this.startAnimation}
            startActivity={this.startActivity}
            setVideoURL={this.setVideoURL}
            setVideoStartTime={this.setVideoStartTime}
            startVideoReinforcement={this.startVideoReinforcement}
            startVideoActivity={this.startVideoActivity}
            endAnimation={this.endAnimation}
            endActivity={this.endActivity}
            onSelectPromptActivity={this.onSelectPromptActivity}
            handlePerformanceDialogOpen={this.handlePerformanceDialogOpen}
            handleSpeedTestDialogOpen={this.handleSpeedTestDialogOpen}
            endClientScreenShare={this.endClientScreenShare}
            isClientOnMobileDevice={this.isClientOnMobileDevice}
            setVideoPlaying={this.setVideoPlaying}
            {...this.props}
            {...this.state}
          />
        )}
        <PromptActivityDrawer
          togglePromptDrawer={this.togglePromptDrawer}
          onPromptLevelChange={this.onPromptLevelChange}
          onFieldSizeChange={this.onFieldSizeChange}
          setupPromptActivity={this.setupPromptActivity}
          {...this.state}
          {...this.props}
        />
        <HostControlsDrawer
          toggleHostControlsDrawer={this.toggleHostControlsDrawer}
          hostControlsDrawerOpen={this.state.hostControlsDrawerOpen}
          showClientChat={this.state.showClientChat}
          onHideClientChat={this.onHideClientChat}
          onShowClientChat={this.onShowClientChat}
          showClientScreenShare={this.state.showClientScreenShare}
          onHideClientScreenShare={this.onHideClientScreenShare}
          onShowClientScreenShare={this.onShowClientScreenShare}
          handleScheduleCall={this.onToggleScheduleClientVideo}
          clientId={this.props.clientId}
          // controls
          togglePassControlsConfirm={this.togglePassControlsConfirm}
          passControls={this.passControls}
          acceptPassControlsRequest={this.acceptPassControlsRequest}
          declinePassControlsRequest={this.declinePassControlsRequest}
          onClosePassControlsRejectedDialog={this.onClosePassControlsRejectedDialog}
          onCloseControlsReceivedDialog={this.onCloseControlsReceivedDialog}
          onCloseControlsPassedDialog={this.onCloseControlsPassedDialog}
          onCloseControlsRequestTimedOutDialog={this.onCloseControlsRequestTimedOutDialog}
          onClosePassControlsFailedDialog={this.onClosePassControlsFailedDialog}
          makeRequest={this.makeRequest}
          acceptControlsRequest={this.acceptControlsRequest}
          declineControlsRequest={this.declineControlsRequest}
          onCloseGiveControlsRequestTimedOutDialog={this.onCloseGiveControlsRequestTimedOutDialog}
          onCloseTakeControlsFailedDialog={this.onCloseTakeControlsFailedDialog}
          onCloseTakeControlsRejectedDialog={this.onCloseTakeControlsRejectedDialog}
          onOpenSMSFacilityDialog={this.onOpenSMSFacilityDialog}
          {...this.props}
          {...this.state}
        />
        {/* dialogs and modals */}
        <Dialog
          open={this.state.passControlsConfirmOpen}
          onClose={this.onCloseBackPressDialog}
          aria-labelledby="form-dialog-title"
          style={{ zIndex: 2010 }}
        >
          <div style={{ width: 600 }}>
            <div className={classes.passControlsConfirmContainer}>
              <div className={classes.permissionControlTitle}>
                Pass controls to the {!this.state.isPrimaryClinician ? "Primary" : "Supervisory"}{" "}
                clinician?
              </div>
              <div className={classes.permissionControlText}>
                Passing controls will remove your access to Activities and Host Controls.
              </div>
              <div className={classes.buttonsContainer}>
                <div className="group">
                  <Button color="secondary" onClick={this.togglePassControlsConfirm}>
                    Cancel
                  </Button>
                  <Button onClick={this.passControls} style={{ marginLeft: 8 }}>
                    Pass
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Dialog>

        <Dialog
          open={this.state.controlsRequestTimedOutDialogOpen}
          onClose={this.onCloseControlsRequestTimedOutDialog}
          style={{ zIndex: 2010 }}
        >
          <div style={{ width: 600 }}>
            <div className={classes.permissionControlTitle}>Request Timeout</div>
            <div style={{ marginBottom: 16 }} className={classes.permissionControlText}>
              You didn't respond to the{" "}
              {!this.state.isPrimaryClinician ? "Primary " : "Supervisory "}
              clinician's request in time
            </div>
            <div className={classes.buttonsContainer}>
              <div className="group">
                <Button color="secondary" onClick={this.onCloseControlsRequestTimedOutDialog}>
                  Close
                </Button>
              </div>
            </div>
          </div>
        </Dialog>

        <Dialog
          open={this.state.controlsReceived && this.state.hasJoinedRoom}
          onClose={this.onCloseControlsReceivedDialog}
          style={{ zIndex: 2010 }}
        >
          <div style={{ width: 600 }}>
            <div className={classes.permissionControlTitle}>You now have host controls</div>
            <div className={classes.buttonsContainer}>
              <div className="group">
                <Button color="secondary" onClick={this.onCloseControlsReceivedDialog}>
                  Close
                </Button>
              </div>
            </div>
          </div>
        </Dialog>

        <Dialog
          open={this.state.passControlsFailed}
          onClose={this.onClosePassControlsFailedDialog}
          style={{ zIndex: 2010 }}
        >
          <div style={{ width: 600 }}>
            <div className={classes.permissionControlTitle}>Request Timeout</div>
            <div style={{ marginBottom: 16 }} className={classes.permissionControlText}>
              The {!this.state.isPrimaryClinician ? "Primary" : "Supervisory"} clinican didn't
              respond to the request in time. You're still in control over all of the features.
            </div>
            <div className={classes.buttonsContainer}>
              <div className="group">
                <Button color="secondary" onClick={this.onClosePassControlsFailedDialog}>
                  Close
                </Button>
              </div>
            </div>
          </div>
        </Dialog>

        <Dialog
          open={this.state.passControlsRejected}
          onClose={this.onClosePassControlsRejectedDialog}
          style={{ zIndex: 2010 }}
        >
          <div style={{ width: 600 }}>
            <div className={classes.permissionControlTitle}>
              The {!this.state.isPrimaryClinician ? "Primary" : "Supervisory"} clinican has denied
              taking controls
            </div>
            <div className={classes.buttonsContainer}>
              <div className="group">
                <Button color="secondary" onClick={this.onClosePassControlsRejectedDialog}>
                  Close
                </Button>
              </div>
            </div>
          </div>
        </Dialog>

        <Dialog
          open={this.state.takeControlsRejected}
          onClose={this.onCloseTakeControlsRejectedDialog}
          style={{ zIndex: 2010 }}
        >
          <div style={{ width: 600 }}>
            <div className={classes.permissionControlTitle}>
              The {!this.state.isPrimaryClinician ? "Primary" : "Supervisory"} clinican has denied
              your request
            </div>
            <div className={classes.buttonsContainer}>
              <div className="group">
                <Button color="secondary" onClick={this.onCloseTakeControlsRejectedDialog}>
                  Close
                </Button>
              </div>
            </div>
          </div>
        </Dialog>

        <Dialog
          open={this.state.takeControlsFailed}
          onClose={this.onCloseTakeControlsFailedDialog}
          style={{ zIndex: 2010 }}
        >
          <div style={{ width: 600 }}>
            <div className={classes.permissionControlTitle}>Request Timeout</div>
            <div style={{ marginBottom: 16 }} className={classes.permissionControlText}>
              The {!this.state.isPrimaryClinician ? "Primary" : "Supervisory"} clinician didn't
              respond to your request. You can request controls anytime again.
            </div>
            <div className={classes.buttonsContainer}>
              <div className="group">
                <Button color="secondary" onClick={this.onCloseTakeControlsFailedDialog}>
                  Close
                </Button>
              </div>
            </div>
          </div>
        </Dialog>

        <Dialog
          open={this.state.controlsRequestedDialogOpen}
          aria-labelledby="form-dialog-title"
          style={{ zIndex: 3010 }}
        >
          <div style={{ width: 600 }}>
            <div className={classes.passControlsConfirmContainer}>
              <div className={classes.permissionControlTitle}>
                The {!this.state.isPrimaryClinician ? "Primary" : "Supervisory"} clinican wants to
                take controls from you
              </div>
              <div className={classes.permissionControlText}>
                This request will expire in <strong>{this.state.countdown} seconds.</strong>
              </div>
              <div className={classes.buttonsContainer}>
                <div className="group">
                  <Button color="secondary" onClick={this.declineControlsRequest}>
                    Deny Request
                  </Button>
                  <Button onClick={this.acceptControlsRequest} style={{ marginLeft: 8 }}>
                    Pass Controls
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
        <Dialog
          open={this.state.passControlsRequestedDialogOpen}
          aria-labelledby="form-dialog-title"
          style={{ zIndex: 3010 }}
        >
          <div style={{ width: 600 }}>
            <div className={classes.passControlsConfirmContainer}>
              <div className={classes.permissionControlTitle}>
                The {!this.state.isPrimaryClinician ? "Primary" : "Supervisory"} clinican is passing
                you the controls.
              </div>
              <div className={classes.permissionControlText}>
                This request will expire in <strong>{this.state.countdown} seconds.</strong>
              </div>
              <div className={classes.buttonsContainer}>
                <div className="group">
                  <Button color="secondary" onClick={this.declinePassControlsRequest}>
                    Decline
                  </Button>
                  <Button onClick={this.acceptPassControlsRequest} style={{ marginLeft: 8 }}>
                    Accept
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
        <Modal
          open={this.state.endCallDialogOpen}
          onClose={this.onCloseEndCallDialog}
          title="Are you sure?"
          content={
            <div style={{ width: 600 }}>
              <Typography>
                Ending this call will close this session and you will not be able to restart it.
              </Typography>
              {!socketConnected && (
                <Alert severity="warning">
                  A connection issue has occurred. Please wait while you are reconnected. If you are
                  not reconnected automatically within a few minutes, please refresh.
                </Alert>
              )}
            </div>
          }
          primaryActionText="End Call"
          primaryActionOnClick={this.leaveRoom}
          primaryActionDisabled={!socketConnected || endCallTriggered}
          secondaryActionText="Cancel"
          secondaryActionOnClick={this.onCloseEndCallDialog}
        />
        <NoShowEndCallDialog
          open={this.state.noShowEndCallDialogOpen}
          onCloseNoShowEndCallDialog={this.onCloseNoShowEndCallDialog}
          markClientNoShow={() => this.markNoShow(this.props.videoId)}
          handleEndCall={() => this.props.history.push("/dashboard")}
        />
        <CallEndedMarkedAsNoShowDialog
          open={this.state.callEndedMarkedAsNoShow}
          handleEndCall={() => this.props.history.push("/dashboard")}
        />
        <Dialog
          open={this.state.backPressDialogOpen && !isCaregiver && !fromStaticUrl}
          onClose={this.onCloseBackPressDialog}
          aria-labelledby="form-dialog-title"
          style={{ zIndex: 2010 }}
        >
          <DialogTitle id="form-dialog-title">Are you sure?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              This call is currently in progress, are you sure you want to exit without ending the
              call?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onCloseBackPressDialog} variant="outlined" color="default">
              Cancel
            </Button>
            <Button
              onClick={
                isPrimaryClinician ? this.onConfirmGoBack : this.onSecondaryClinicianLeaveRoom
              }
              color="primary"
            >
              Go Back
            </Button>

            <Button
              onClick={isPrimaryClinician ? this.leaveRoom : this.onSecondaryClinicianLeaveRoom}
              color="secondary"
            >
              {isPrimaryClinician ? "End Call" : "Leave Call"}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.showUnsupportedPlatformDialog}
          onClose={this.closeUnsupportedPlatformDialog}
          aria-labelledby="form-dialog-title"
          style={{ zIndex: 2050 }}
          PaperProps={{
            style: {
              maxWidth: "85%",
              width: 400,
            },
          }}
        >
          <div className={classes.unsupportedPlatformDialogContainer}>
            <div className={classes.notificationIconContainer}>
              <NotificationsNoneIcon color="primary" />
            </div>

            <Typography className={classes.unsupportedPlatformTitle}>
              Looks like you're on mobile!
            </Typography>
          </div>
          <DialogContent className={classes.unsupportedPlatformTextContainer}>
            <Typography>
              Mobile is not optimal for our video calls and you may not experience the full range of
              features available.
            </Typography>
          </DialogContent>
          <DialogActions className={classes.unsupportedPlatformActionsContainer}>
            <Button fullWidth onClick={this.closeUnsupportedPlatformDialog}>
              I understand, continue
            </Button>
            <Button
              fullWidth
              variant="text"
              onClick={this.leaveUnsupportedPlatform}
              style={{ margin: "5px 0px" }}
            >
              Exit call
            </Button>
            {this.state.showUnsupportedPlatformCheckbox && (
              <FormControlLabel
                className={classes.notShowCheckbox}
                control={
                  <Checkbox
                    checked={this.state.hideUnsupportedPlatformMessage}
                    onChange={this.toggleUnsupportedPlatfromMessage}
                    disableRipple
                    style={{ color: "#3f4456" }}
                  />
                }
                label="Do not show message again."
              />
            )}
          </DialogActions>
        </Dialog>
        <MergeSessionsDialog
          open={this.state.mergeNextSessionDialogOpen}
          onToggleMergeNextSessionDialog={this.onToggleMergeNextSessionDialog}
          onMergeNextSession={this.onMergeNextSession}
          clientName={`${this.state.clientJoinedData?.child_name} ${this.state.clientJoinedData?.child_last_name}`}
          mergeSessionLoading={this.state.mergeSessionLoading}
          mergeSessionError={this.state.mergeSessionError}
        />
        <SessionFullDialog
          open={this.state.sessionFullDialogOpen}
          onToggleSessionFullDialog={this.onToggleSessionFullDialog}
          onJoinFullSession={this.onJoinFullSession}
        />
        <ClientSMSDialog
          onSendSMSMessage={this.onSendSMSMessage}
          smsThread={this.props.smsThread}
          smsFacilityDialogOpen={this.state.smsFacilityDialogOpen}
          onOpenSMSFacilityDialog={this.onOpenSMSFacilityDialog}
          onCloseSMSFacilityDialog={this.onCloseSMSFacilityDialog}
          eCommConsentFormSigned={this.state.eCommConsentFormSigned}
          canMessageAnytime={false}
          location={this.props.location}
          clientId={this.props.clientId}
        />
        <Modal
          open={this.state.secondaryLeaveCallDialogOpen}
          onClose={this.onCloseSecondaryLeaveCallDialog}
          title="Are you sure?"
          description="This call is currently in progress, are you sure you want to leave the call?"
          primaryActionText="End Call"
          primaryActionOnClick={this.onSecondaryClinicianLeaveRoom}
          secondaryActionText="Cancel"
          secondaryActionOnClick={this.onCloseSecondaryLeaveCallDialog}
        />
        <Modal
          open={backPressDialogOpen && isCaregiver}
          onClose={this.onCloseBackPressDialog}
          title="Are you sure?"
          description="This call is currently in progress, are you sure you want to exit?"
          primaryActionText="Exit Call"
          primaryActionOnClick={this.onConfirmGoBack}
          secondaryActionText="Cancel"
          secondaryActionOnClick={this.onCloseBackPressDialog}
        />
        <Dialog
          open={this.state.errorDialogOpen}
          aria-labelledby="form-dialog-title"
          style={{ zIndex: 2010 }}
          PaperProps={{ style: { overflow: "hidden" } }}
          maxWidth={false}
        >
          <div className={classes.errorContentContainer}>
            <DialogContent classes={{ root: classes.errorContent }}>
              {<ErrorMessage {...this.state} />}
              <Typography>For a detailed test, you can visit our test page.</Typography>
              <Typography>
                For a browser specific solution, you can view our help article.
              </Typography>
            </DialogContent>
            <DialogActions classes={{ root: classes.errorContentButtonContainer }}>
              <Button color="secondary" onClick={this.goToTestPage}>
                Go to test page
              </Button>
              <Button
                color="secondary"
                onClick={() =>
                  window.open("https://www.getanswersnow.com/troubleshooting", "_blank")
                }
              >
                View help article
              </Button>
              {(microphoneError ||
                cameraError ||
                speakerError ||
                (downloadTestEnded && averageDownloadMbps <= 5) ||
                (uploadTestEnded && averageUploadMbps <= 2)) && (
                <Button variant="text" onClick={this.closeErrorDialog}>
                  Dismiss
                </Button>
              )}
            </DialogActions>
          </div>
        </Dialog>
        <VideoInputsControls
          handleUserCameraChange={this.handleUserCameraChange}
          handleUserMicrophoneChange={this.handleUserMicrophoneChange}
          handleUserSpeakerChange={this.handleUserSpeakerChange}
          canChangeSpeaker={this.canChangeSpeaker}
          canChangeAudioInput={this.canChangeAudioInput}
          canChangeVideoInput={this.canChangeVideoInput}
          toggleSettingDialog={this.toggleSettingDialog}
          handleUserLobbyCameraChange={this.handleUserLobbyCameraChange}
          handleUserLobbyMicrophoneChange={this.handleUserLobbyMicrophoneChange}
          handleUserLobbySpeakerChange={this.handleUserLobbySpeakerChange}
          testUserSpeaker={this.testUserSpeaker}
          microphoneTestCanvas={this.setMicrophoneTestCanvas}
          {...this.state}
        />
        <PerformanceTest
          getComputerPerformance={getComputerPerformance}
          performance={performance}
          handlePerformanceDialogClose={this.handlePerformanceDialogClose}
          performanceDialogOpen={performanceDialogOpen}
          isLoading={getTestLoading}
          isSuccess={getSuccess}
        />
        <SpeedTest
          {...this.props}
          speedTestDialogOpen={this.state.speedTestDialogOpen}
          handleSpeedTestDialogClose={this.handleSpeedTestDialogClose}
        />
        <VideoDisabledDialog
          toggleVideo={this.toggleVideo}
          onCloseVideoDisabledDialog={this.onCloseVideoDisabledDialog}
          isCaregiver={this.props.isCaregiver}
          toggleVideoView={this.toggleVideoView}
          {...this.state}
        />
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          style={{
            zIndex: 2010,
            marginTop: this.state.screenSharingNotSupported ? 60 : null,
            backgroundColor: "transparent",
            boxShadow: "none",
          }}
          autoHideDuration={10000}
          onClose={this.toggleLocalBadInternetDialog}
          open={
            this.state.localBadInternetDialogOpen &&
            this.state.hasJoinedRoom &&
            !endDate &&
            !this.state.connectionFailedDialogOpen
          }
        >
          <div className={classes.alertContainer}>
            <div className={classes.warningIconContainer}>
              <WarningIcon className={classes.warningIcon} />
            </div>
            <Typography style={{ width: 260 }}>
              It looks like your internet connection is moving slow. You may experience problems.
              Click{" "}
              <Link
                target="_blank"
                style={{ fontWeight: 500, color: "#000" }}
                href="https://www.getanswersnow.com/troubleshooting"
              >
                here
              </Link>{" "}
              to see how to resolve them.
            </Typography>
          </div>
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          style={{
            zIndex: 2010,
            marginTop: this.state.screenSharingNotSupported ? 60 : null,
            backgroundColor: "transparent",
            boxShadow: "none",
          }}
          open={this.state.connectionFailedDialogOpen}
        >
          <div className={classes.alertContainer}>
            <div className={classes.warningIconContainer}>
              <WarningIcon className={classes.warningIcon} />
            </div>
            <Typography style={{ width: 300 }}>
              It looks like your internet connection has dropped. Please wait while we try to
              connect. If you aren't connected automatically, check your internet connection and try
              again.
            </Typography>
          </div>
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          style={{
            zIndex: 2010,
            marginTop:
              this.state.localBadInternetDialogOpen && this.state.screenSharingNotSupported
                ? 170
                : this.state.localBadInternetDialogOpen && !this.state.screenSharingNotSupported
                  ? 100
                  : !this.state.localBadInternetDialogOpen && this.state.screenSharingNotSupported
                    ? 60
                    : null,
            backgroundColor: "transparent",
            boxShadow: "none",
          }}
          autoHideDuration={12000}
          onClose={this.toggleRemoteParticipantBadInternetDialog}
          open={
            this.state.remoteBadInternetDialogOpen &&
            this.state.hasJoinedRoom &&
            !endDate &&
            !this.state.remoteConnectionFailedDialogOpen
          }
        >
          <div className={classes.alertContainer}>
            <div className={classes.warningIconContainer}>
              <WarningIcon className={classes.warningIcon} />
            </div>
            <Typography style={{ width: 260 }}>
              It looks like <strong>{badInternetParticipant?.name}'s </strong> internet connection
              is moving slow. There may be problems during this call.
            </Typography>
          </div>
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          style={{
            zIndex: 2010,
            marginTop:
              this.state.localBadInternetDialogOpen && this.state.screenSharingNotSupported
                ? 170
                : this.state.localBadInternetDialogOpen && !this.state.screenSharingNotSupported
                  ? 100
                  : !this.state.localBadInternetDialogOpen && this.state.screenSharingNotSupported
                    ? 60
                    : null,
            backgroundColor: "transparent",
            boxShadow: "none",
          }}
          autoHideDuration={10000}
          onClose={this.toggleRemoteConnectionFailedDialog}
          open={
            this.state.remoteConnectionFailedDialogOpen &&
            !this.state.connectionFailedDialogOpen &&
            this.state.hasJoinedRoom
          }
        >
          <div className={classes.alertContainer}>
            <div className={classes.warningIconContainer}>
              <WarningIcon className={classes.warningIcon} />
            </div>
            <Typography style={{ width: 260 }}>
              It looks like{" "}
              <strong>
                {connectionFailedParticipant?.name
                  ? connectionFailedParticipant?.name
                  : this.props.isClinician
                    ? "the client"
                    : "your clinician"}
                's{" "}
              </strong>{" "}
              internet connection has dropped. We are attempting to reconnect.
            </Typography>
          </div>
        </Snackbar>
        <Error
          open={this.state.screenSharingNotSupported}
          onClose={this.closeScreenShareErrorDialog}
          errorMessage="Screen sharing feature is not available on your browser."
        />
      </>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(VideoContainer))
);

export const VideoChat = VideoContainer;
