import React from "react";
import { withStyles } from "@mui/styles";
import CheckIcon from "@mui/icons-material/Check";
import { Checkbox } from "@mui/material";

export const CustomCheckbox = withStyles({
  root: {
    color: "#7f7e81",
  },
  checked: {},
})((props) => (
  <Checkbox
    size="small"
    color="default"
    checkedIcon={
      <div
        style={{
          width: 16,
          height: 16,
          borderRadius: 3,
          border: "1px solid #3F4456",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CheckIcon color="primary" style={{ fontSize: 14 }} />
      </div>
    }
    icon={
      <div
        style={{
          width: 16,
          height: 16,
          borderRadius: 3,
          border: "1px solid #3F4456",
        }}
      />
    }
    {...props}
  />
));
