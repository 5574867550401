import React from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormLabel from "@mui/material/FormLabel";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const EntryPoint = (props) => {
  const {
    classes,
    username,
    otpError,
    handleUsernameChange,
    handleLoginWithCode,
    handleLoginWithPassword,
    renderErrorMessage,
    onSubmit,
  } = props;
  return (
    <form className={classes.form} onSubmit={onSubmit}>
      <div
        style={{
          fontSize: 16,
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "150%",
          letterSpacing: "0.15px",
          marginBottom: 24,
        }}
      >
        We'll send a six-digit code to your email or phone allowing you to log in without a
        password.
      </div>
      <FormControl margin="none" fullWidth>
        <FormLabel
          component="legend"
          style={{
            fontSize: 12,
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "100%",
            letterSpacing: "0.3px",
            marginBottom: 8,
          }}
        >
          Email Address or Phone Number
        </FormLabel>
        <TextField
          id="username"
          name="username"
          autoComplete="off"
          autoFocus
          size="medium"
          value={username}
          onChange={handleUsernameChange}
          placeholder="Email address or phone number"
        />
      </FormControl>
      <FormControl margin="none" fullWidth>
        <Button
          onClick={handleLoginWithCode}
          size="large"
          endIcon={<ArrowForwardIcon color="#fff" />}
          color="primary"
          disabled={!username}
          style={{ height: 42, marginTop: 16 }}
        >
          Log In With Code
        </Button>
        <Button
          onClick={handleLoginWithPassword}
          color="secondary"
          style={{ height: 42, marginTop: 16 }}
        >
          Use A Password To Log In
        </Button>
        {otpError && <div style={{ marginTop: 15 }}>{renderErrorMessage()}</div>}
      </FormControl>
    </form>
  );
};

export default EntryPoint;
