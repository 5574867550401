import { convertTokens } from "@date-fns/upgrade/v2";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import { format, isSameDay, isSameWeek } from "date-fns";
import { useGetTeamMembers } from "hooks/api/useGetTeamMembers";
import { useGetTeams } from "hooks/api/useGetTeams";
import React, { useEffect, useRef, useState } from "react";
import SendConsentButton from "./SendConsentButton";
import { StyledInput } from "./StyledInput";
import { Transition } from "./Transition";
import { styles } from "./styles";
import { formatPhoneNumber } from "../../utils/formatPhoneNumber";

import { FormControl, FormHelperText, MenuItem, Select } from "@mui/material";
import { useFormik } from "formik";

const ClientSMSDialog = (props) => {
  const {
    classes,
    onSendSMSMessage,
    smsThread,
    onOpenSMSFacilityDialog,
    onCloseSMSFacilityDialog,
    smsFacilityDialogOpen,
    eCommConsentFormSigned,
    canMessageAnytime,
    clientId,
  } = props;
  const [smsMessage, setSMSMessage] = useState("");
  const [memberId, setMemberId] = useState("");
  const [familyMember, setFamilyMember] = useState("");
  const [filteredSmsThread, setFilteredSmsThread] = useState(smsThread);
  const messagesEnd = useRef(null);
  const messageRefs = useRef({});

  const onSubmitSMS = () => {
    onSendSMSMessage(smsMessage, memberId);
    setSMSMessage("");
  };
  const keyPress = (e) => {
    if (e.keyCode == 13 && !!smsMessage.trim()) {
      e.preventDefault();
      onSubmitSMS();
    }
  };

  const { teams } = useGetTeams({ clientIds: clientId });
  const teamId = teams?.[0]?.id;
  const { teamMembers } = useGetTeamMembers({ teamIds: teamId, includeTeamInvitees: false });

  const { errors, handleChange, handleSubmit, setFieldValue, touched, values } = useFormik({
    initialValues: {
      selectedMember: "",
    },
  });

  useEffect(() => {
    if (!memberId) return; // Ensure memberId is available before executing

    const member = teamMembers.find((member) => {
      return member.userId === memberId;
    });
    if (member) {
      // Only update state if the new value is different
      if (member !== familyMember) {
        setFamilyMember(member);
      }

      refreshSms();
    }
  }, [memberId]);

  useEffect(() => {
    if (teamMembers && teamMembers.length > 0) {
      setFieldValue("selectedMember", teamMembers[0].userId);
      setMemberId(teamMembers[0].userId);
    }
  }, [teamMembers]);

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    if (smsFacilityDialogOpen && !params.get("sms-open")) {
      window.setTimeout(() => {
        messagesEnd.current.scrollIntoView({ behavior: "smooth" });
      }, 400);
    } else if (smsFacilityDialogOpen && params.get("sms-open")) {
      setTimeout(() => {
        autoScroll(params.get("date"));
      }, 1000);
    }
    refreshSms();
  }, [smsThread.length, smsFacilityDialogOpen]);

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    if (
      params.get("sms-open") &&
      params.get("date") &&
      props.smsThread?.length > 0 &&
      !smsFacilityDialogOpen
    ) {
      onOpenSMSFacilityDialog();
      setTimeout(() => {
        autoScroll(params.get("date"));
      }, 1000);
    }
  }, [props.location.search]);

  const refreshSms = () => {
    if (teamMembers) {
      const member = teamMembers.find((member) => {
        return member.userId === memberId;
      });
      if (member) {
        const filteredSMS = smsThread.filter((sms) => sms.family_id === member.familyId);
        setFilteredSmsThread(filteredSMS);
      }
    }
  };

  const autoScroll = (date) => {
    let scrollDate = Object.keys(messageRefs.current)?.find((ref) =>
      isSameDay(new Date(date), new Date(ref))
    );
    messageRefs.current[scrollDate]?.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  };

  return (
    <Dialog
      open={smsFacilityDialogOpen}
      onClose={onCloseSMSFacilityDialog}
      PaperProps={{
        style: {
          borderRadius: 8,
          width: 1100,
          height: "80%",
          maxWidth: "95%",
          overflow: "visible",
          padding: 0,
        },
      }}
      TransitionComponent={Transition}
      TransitionProps={{ direction: "up" }}
      style={{ padding: "0px 0px 0px 0px !important" }}
    >
      <DialogContent classes={{ root: classes.dialogContent }}>
        <div className={classes.smsFacilityContainer}>
          <div className={classes.header}>
            <form onSubmit={handleSubmit}>
              <div>
                <FormControl
                  fullWidth
                  margin="normal"
                  error={touched.selectedMember && Boolean(errors.selectedMember)}
                >
                  <Select
                    labelId="selectedMember-label"
                    name="selectedMember"
                    value={values.selectedMember}
                    onChange={(e) => {
                      handleChange(e);
                      setMemberId(e.target.value);
                    }}
                  >
                    {teamMembers &&
                      teamMembers.map((member) => {
                        if (member.userId && member.phone)
                          return (
                            <MenuItem value={member.userId} key={member.userId}>
                              {`${member.name} (${formatPhoneNumber(member.phone)})`}
                            </MenuItem>
                          );
                      })}
                  </Select>
                  <FormHelperText>{touched.selectedMember && errors.selectedMember}</FormHelperText>
                </FormControl>
              </div>
            </form>

            <IconButton
              onClick={onCloseSMSFacilityDialog}
              className={classes.closeIcon}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className={classes.smsThread}>
            {filteredSmsThread.map((sms, i) => {
              return (
                <React.Fragment key={i}>
                  {(!smsThread[i - 1] ||
                    !isSameDay(
                      new Date(sms.created_date),
                      new Date(smsThread[i - 1].created_date)
                    )) && (
                    <div className={classes.newDayContainer}>
                      <Typography component="p">
                        {isSameWeek(new Date(sms.created_date), new Date())
                          ? format(new Date(sms.created_date), convertTokens("ddd h:mm a"))
                          : format(
                              new Date(sms.created_date),
                              convertTokens("MMM D, YYYY, h:mm a")
                            )}
                      </Typography>
                    </div>
                  )}
                  <div
                    className={
                      sms.sender_is_family ? classes.smsContainerFamily : classes.smsContainer
                    }
                    ref={(ref) => (messageRefs.current[sms.created_date] = ref)}
                  >
                    <div className={classes.smsMessageContainer}>
                      <div
                        className={
                          sms.sender_is_family ? classes.smsMessageFamily : classes.smsMessage
                        }
                      >
                        <Typography component="p" className={classes.message}>
                          {sms.message}
                        </Typography>
                      </div>
                      <div className={classes.timeSender}>
                        {sms.sender_is_family
                          ? `${format(new Date(sms.created_date), "p")}, ${sms.first_name}`
                          : `Sent ${format(new Date(sms.created_date), "p")}`}
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
            <div ref={messagesEnd} />
          </div>
          <div className={classes.smsInputContainer}>
            <StyledInput
              variant="outlined"
              value={smsMessage}
              onChange={(e) => setSMSMessage(e.target.value)}
              placeholder="Text Message"
              fullWidth
              multiline
              style={{ marginRight: 15, marginBottom: 5 }}
              onKeyDown={keyPress}
            />
            <SendIcon
              disabled={!smsMessage}
              onClick={onSubmitSMS}
              className={!!smsMessage ? classes.sendIcon : classes.sendIconDisabled}
            />
          </div>

          {!eCommConsentFormSigned && canMessageAnytime && (
            <div className={classes.smsConsentWarningContainer}>
              <Typography component="p" className={classes.unsignedConsentWarning}>
                MISSING CONSENT TO RECEIVE ELECTRONIC COMMUNICATIONS: Please avoid sending any
                Personal Identifiable Information (PII). Click here to send a new consent form for
                the user to sign from their Home page.
              </Typography>
              <SendConsentButton clientId={clientId} />
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(ClientSMSDialog);
