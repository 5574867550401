import React from "react";
import { Stack, Typography } from "@mui/material";
import { ArrowUp, ArrowDown, Minus } from "@phosphor-icons/react";

import theme from "theme";
import { getDeltaPercent } from "./utils";

const colorSuccess = theme.palette.success.main;
const colorError = theme.palette.error.main;
const colorTextDisabled = theme.palette.text.disabled;

/**
 * The `DeltaItem` component displays a statistical information with a label and value, and a percentage change.
 * @component DeltaItem
 * @param {Object} props - The props of the component
 * @param {String} props.label - The label of the statistical information
 * @param {?Number} props.currentValue - The value of the statistical information
 * @param {?Number} props.previousValue - The previous value of the statistical information
 * @return {JSX.Element} The `DeltaItem` function is returning a JSX element
 */
export function DeltaItem({ label, currentValue, previousValue }) {
  const { delta, status } = getDeltaPercent(currentValue, previousValue);

  return (
    <Stack spacing={3} direction="row" useFlexGap flexWrap="wrap" alignItems="center">
      {currentValue ? (
        <>
          <Typography variant="h2">{currentValue}</Typography>

          {delta && (
            <Stack direction="row" alignItems="flex-start">
              <Typography paddingRight={1}>
                {status === "equal" && <Minus weight="bold" color={colorTextDisabled} />}

                {status === "up" && <ArrowUp weight="bold" color={colorSuccess} />}

                {status === "down" && <ArrowDown weight="bold" color={colorError} />}
              </Typography>

              <Typography variant="subtitle2">{status === "equal" ? 0 : delta}%</Typography>
            </Stack>
          )}
        </>
      ) : (
        <Typography variant="h2"> - </Typography>
      )}

      <Typography variant="body2" marginTop={-3} sx={{ wordWrap: "break-word", width: "100%" }}>
        {label}
      </Typography>
    </Stack>
  );
}

/**
 * The StatItem component renders a statistical information with a label and value
 * @component
 * @param {Object} props - The props of the component
 * @param {String} props.label - The label of the statistical information
 * @param {Number} props.value - The value of the statistical information
 * @param {("title"|"subtitle")} props.variant - The variant of component
 * @return {JSX.Element} The `StatItem` function is returning a JSX element
 */
export function StatItem({ label, value, variant }) {
  const title = variant === "title" ? "h1" : "h3";
  const subtitle = variant === "title" ? "subtitle2" : "body2";

  return (
    <Stack sx={{ overflowX: "hidden", wordWrap: "break-word" }}>
      <Typography variant={title} sx={{ hyphens: "auto" }}>
        {value ?? "-"}
      </Typography>
      <Typography variant={subtitle}>{label}</Typography>
    </Stack>
  );
}
