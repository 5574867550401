import React, { useEffect } from "react";
import withStyles from "@mui/styles/withStyles";
import styles from "../../styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { COUNTRIES, DIAGNOSTIC_CODES, ETHNICITIES, LANGUAGES, STATES } from "../../../../constants";
import { InputLabel, MenuItem, Select, TextField } from "@mui/material";
import ANDatePicker from "elements/ANDatePicker";
import TimezonePicker from "elements/TimezonePicker";
import SectionBox from "elements/SectionBox";

import OutlinedInput from "@mui/material/OutlinedInput";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import customerInfoConfig from "./CustomerInfo";
import ANFormView from "../../../../elements/Forms/ANFormView";
import { useTheme } from "@mui/styles";
import { useFormikContext } from "formik";

const PersonalInformation = (props) => {
  const { classes, customerDetails, editMode, isCaregiver } = props;
  let customerInfo = customerDetails?.details;

  const [ethnicityList, setEthnicityList] = React.useState([]);
  const theme = useTheme();
  const { values, handleChange, handleBlur, setFieldValue, touched, errors } = useFormikContext();

  useEffect(() => {
    if (values.personalInformation.ethnicity) {
      setEthnicityList(values.personalInformation.ethnicity.split(",").map((item) => item.trim()));
    }
  }, [values.personalInformation.ethnicity]);

  const handleEthnicityChange = (name) => (event) => {
    const { value } = event.target;
    setEthnicityList(
      typeof value === "string" ? value.split(",").map((item) => item.trim()) : value
    );
    setFieldValue(name, typeof value === "string" ? value : value.join(", "), true);
  };

  const handleChangeYesNo = (name) => (e) => {
    const { value } = e.target;
    setFieldValue(name, value === "Yes", true);
  };

  return (
    <>
      <SectionBox
        title="Personal Information"
        description={`${isCaregiver ? "Name, address, and personal details" : "Manage client details such as name, address, and demographics"}`}
        columnDirection
        padding={theme.spacing(5, 0)}
      >
        {editMode ? (
          <>
            <Grid container spacing={7}>
              <Grid item xs={12} sm={4} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
                <TextField
                  name="personalInformation.firstName"
                  label="First Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.personalInformation.firstName}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
                <TextField
                  name="personalInformation.preferredFirstName"
                  label="Preferred First Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.personalInformation.preferredFirstName}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
                <TextField
                  name="personalInformation.lastName"
                  label="Last Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.personalInformation.lastName}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
                <ANDatePicker
                  name="personalInformation.dob"
                  label="Date of Birth"
                  value={values.personalInformation.dob}
                  onChange={(value) => setFieldValue("personalInformation.dob", value, true)}
                  format="PP" //"MMM d, yyyy"
                  sx={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={4} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
                <TextField
                  name="personalInformation.gender"
                  label="Sex Assigned at Birth"
                  select
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.personalInformation.gender}
                  fullWidth
                >
                  <MenuItem disabled value={""} />
                  <MenuItem value="Female">Female</MenuItem>
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="non_binary">Non-binary</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={4} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
                <TextField
                  name="personalInformation.identifiedGender"
                  label="Identified Gender "
                  select
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.personalInformation.identifiedGender}
                  fullWidth
                >
                  <MenuItem disabled value={""} />
                  <MenuItem value="Female">Female</MenuItem>
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="non_binary">Non-binary</MenuItem>
                  <MenuItem value="gender_gliud">Gender Fliud</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={4} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
                <TextField
                  name="personalInformation.transgender"
                  label="Transgender"
                  select
                  onChange={handleChangeYesNo("personalInformation.transgender")}
                  onBlur={handleBlur}
                  value={values.personalInformation.transgender ? "Yes" : "No"}
                  fullWidth
                >
                  <MenuItem disabled value={""} />
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={4} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
                <TextField
                  name="personalInformation.pronouns"
                  select
                  label="Pronouns"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.personalInformation.pronouns}
                  fullWidth
                >
                  <MenuItem value="" disabled />
                  <MenuItem value="she/her">She/Her</MenuItem>
                  <MenuItem value="he/him">He/Him</MenuItem>
                  <MenuItem value="they/them">They/Them</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={4} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
                <TextField
                  name="personalInformation.clientDiagnosis"
                  select
                  label="Diagnosis"
                  value={values.personalInformation.clientDiagnosis}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                >
                  <MenuItem value="" disabled>
                    Select Diagnosis
                  </MenuItem>
                  {DIAGNOSTIC_CODES.map((code, index) => {
                    return (
                      <MenuItem key={index} value={code}>
                        {code}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={12} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
                <TextField
                  name="personalInformation.address1"
                  label="Address"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.personalInformation.address1}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
                <TextField
                  name="personalInformation.address2"
                  label="Address 2"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.personalInformation.address2}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
                <TextField
                  name="personalInformation.state"
                  select
                  label="State"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.personalInformation.state}
                  fullWidth
                  autoComplete="off"
                >
                  <MenuItem disabled value={""}>
                    Select a state
                  </MenuItem>
                  {Object.keys(STATES).map((key) => {
                    return (
                      <MenuItem key={key} value={key}>
                        {STATES[key]}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
                <TextField
                  name="personalInformation.city"
                  label="City"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.personalInformation.city}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
                <TextField
                  name="personalInformation.zipCode"
                  label="ZIP Code"
                  value={values.personalInformation.zipCode}
                  fullWidth
                  inputProps={{ maxLength: 5 }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.zipCode && errors.zipCode}
                  error={touched.zipCode && Boolean(errors.zipCode)}
                />
              </Grid>
              <Grid item xs={12} sm={6} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
                <TimezonePicker
                  name="personalInformation.clientTimezone"
                  timezone={values.personalInformation.clientTimezone}
                  onTimezoneChange={handleChange}
                  classes={classes}
                  outlined
                  fullWidth
                  // InputLabelProps={{ shrink: true }}
                  label="Time Zone"
                />
              </Grid>
              <Grid item xs={6} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
                <TextField
                  select
                  fullWidth
                  label="Country"
                  name="personalInformation.country"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.personalInformation.country}
                >
                  <MenuItem disabled value={""}>
                    Select
                  </MenuItem>
                  {Object.entries(COUNTRIES).map(([key, value]) => {
                    return (
                      <MenuItem key={key} value={key}>
                        {value}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={6} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
                <TextField
                  name="personalInformation.livesWith"
                  label="Client Lives With"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.personalInformation.livesWith}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
                <FormControl className="select" sx={{ width: "100%" }}>
                  <InputLabel>Race & Ethnicity</InputLabel>
                  <Select
                    value={ethnicityList}
                    onChange={handleEthnicityChange("personalInformation.ethnicity")}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => <Typography>{selected.join(", ")}</Typography>}
                    multiple
                    displayEmpty
                  >
                    {ETHNICITIES.map((key) => {
                      return (
                        <MenuItem key={key} value={key}>
                          <Checkbox checked={ethnicityList.indexOf(key) > -1} />
                          <ListItemText
                            primary={key}
                            primaryTypographyProps={{ variant: "body2" }}
                          />
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
                <TextField
                  name="personalInformation.primaryLanguage"
                  label="Primary Language"
                  value={values.personalInformation.primaryLanguage}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  select
                >
                  <MenuItem disabled value={""} />
                  {LANGUAGES.map((key) => {
                    return (
                      <MenuItem key={key} value={key}>
                        {key}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
                <TextField
                  name="personalInformation.needsInterpreter"
                  select
                  label="Interpreter Required"
                  onChange={handleChangeYesNo("personalInformation.needsInterpreter")}
                  onBlur={handleBlur}
                  value={values.personalInformation.needsInterpreter ? "Yes" : "No"}
                  fullWidth
                >
                  <MenuItem disabled value={""} />
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
                <TextField
                  name="personalInformation.medicalConditions"
                  label="Medical Conditions"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.personalInformation.medicalConditions}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
                <TextField
                  name="personalInformation.preAuthRequired"
                  label="Pre-authorization Required"
                  select
                  onChange={handleChangeYesNo("personalInformation.preAuthRequired")}
                  onBlur={handleBlur}
                  value={values.personalInformation.preAuthRequired ? "Yes" : "No"}
                  fullWidth
                >
                  <MenuItem disabled value={""} />
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="personalInformation.requiresTablet"
                      onClick={handleChange}
                      onBlur={handleBlur}
                      checked={values.personalInformation.requiresTablet}
                    />
                  }
                  label="Requires Tablet"
                />
              </Grid>
            </Grid>
          </>
        ) : (
          <ANFormView data={customerInfo} config={customerInfoConfig} />
        )}
      </SectionBox>
    </>
  );
};

export default withStyles(styles)(PersonalInformation);
