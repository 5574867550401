import "regenerator-runtime/runtime";
import { compose, createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";

import reducers from "../reducers";
import rootSaga from "../sagas";

export default function configureStore(initialState) {
  const sagaMiddleware = createSagaMiddleware();
  let middleware = [sagaMiddleware];
  let enhancers = [];

  const composeEnhancers = (window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

  const enhancer = composeEnhancers(...[applyMiddleware(...middleware), ...enhancers]);

  const store = createStore(reducers, initialState, enhancer);
  let sagaTask = sagaMiddleware.run(rootSaga);

  if (module.hot) {
    module.hot.accept("../reducers", () => {
      const nextReducer = require("../reducers").default;
      store.replaceReducer(nextReducer);
    });
    module.hot.accept("../sagas", () => {
      const getNewSagas = require("../sagas").default;
      sagaTask.cancel();
      sagaTask.done.then(() => {
        sagaTask = sagaMiddleware.run(function* replacedSaga(action) {
          yield getNewSagas();
        });
      });
    });
  }
  return store;
}
