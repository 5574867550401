import React from "react";
import EditServiceDetails from "./EditServiceDetails";
import ViewServiceDetails from "./ViewServiceDetails";

const ServiceDetails = (props) => {
  const { viewOnly, userId, clinicianUserId, associatedSessionNotes, noteCompleted } = props;
  const lockedForClinician = associatedSessionNotes?.every((note) => note.locked);
  return !viewOnly || (userId == clinicianUserId && noteCompleted && !lockedForClinician) ? (
    <EditServiceDetails {...props} />
  ) : (
    <ViewServiceDetails {...props} />
  );
};

export default ServiceDetails;
