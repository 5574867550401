export const VerificationOfBenefitsConfig = [
  {
    id: "insurance_plan_id",
    label: "Insurance Plan",
    dataKey: "plan_name",
    cols: 12,
    required: true,
  },
  {
    id: "benefit_year",
    label: "Benefit Year",
    dataKey: "benefit_year",
    cols: 6,
    required: true,
  },
  {
    id: "network_status",
    label: "Network Status",
    dataKey: "network_status",
    cols: 6,
    required: true,
  },
  {
    id: "has_deductible",
    label: "Does the client have a deductible?",
    dataKey: "has_deductible",
    cols: 6,
    type: "yes_no",
    required: true,
  },
  {
    id: "individual_deductible_amount",
    label: "Individual Deductible Amount",
    dataKey: "individual_deductible_amount",
    cols: 6,
    type: "money",
  },
  {
    id: "remaining_deductible_amount",
    label: "Remaining Deductible Amount",
    dataKey: "remaining_deductible_amount",
    cols: 6,
    type: "money",
  },
  {
    id: "family_deductible_amount",
    label: "Family Deductible Amount",
    dataKey: "family_deductible_amount",
    cols: 6,
    type: "money",
  },
  {
    id: "remaining_family_deductible_amount",
    label: "Remaining Family Deductible Amount",
    dataKey: "remaining_family_deductible_amount",
    cols: 4,
    type: "money",
  },
  {
    id: "deductible_out_of_pocket_max",
    label: "Does the deductible apply to Out-of-Pocket Max?",
    dataKey: "deductible_out_of_pocket_max",
    cols: 6,
    divider: true,
    type: "yes_no",
  },
  {
    id: "co_insurance_amount_insurance",
    label: "Co-Insurance Amount (Insurance)",
    dataKey: "co_insurance_amount_insurance",
    cols: 4,
    type: "money",
    required: true,
  },
  {
    id: "co_insurance_amount_family",
    label: "Co-Insurance Amount (Family)",
    dataKey: "co_insurance_amount_family",
    cols: 6,
    type: "money",
    required: true,
  },
  {
    id: "co_pay_amount",
    label: "Co-Pay Amount",
    dataKey: "co_pay_amount",
    cols: 6,
    type: "money",
    required: true,
  },
  {
    id: "individual_out_of_pocket_max",
    label: "Individual Out-of-Pocket Max",
    dataKey: "individual_out_of_pocket_max",
    cols: 6,
    type: "money",
    required: true,
  },
  {
    id: "individual_out_of_pocket_remaining",
    label: "Individual Out-of-Pocket Remaining",
    dataKey: "individual_out_of_pocket_remaining",
    cols: 6,
    type: "money",
    required: true,
  },
  {
    id: "family_out_of_pocket_max",
    label: "Family Out-of-Pocket Max",
    dataKey: "family_out_of_pocket_max",
    cols: 6,
    type: "money",
    required: true,
  },
  {
    id: "family_out_of_pocket_remaining",
    label: "Family Out-of-Pocket Remaining",
    dataKey: "family_out_of_pocket_remaining",
    cols: 6,
    type: "money",
    divider: true,
    required: true,
  },
  {
    id: "visit_limit",
    label: "Visit Limit",
    dataKey: "visit_limit",
    cols: 12,
    type: "number",
  },
  {
    id: "age_limit",
    label: "Age Limit",
    dataKey: "age_limit",
    cols: 12,
    type: "number",
  },
  {
    id: "annual_dollar_maximum",
    label: "Annual Dollar Maximum",
    dataKey: "annual_dollar_maximum",
    cols: 12,
    type: "money",
  },
  {
    id: "lifetime_dollar_maximum",
    label: "Lifetime Dollar Maximum",
    dataKey: "lifetime_dollar_maximum",
    cols: 12,
    type: "money",
    divider: true,
  },
  {
    id: "aba_covered",
    label: "Is ABA a covered benefit",
    dataKey: "aba_covered",
    cols: 6,
    type: "yes_no",
    required: true,
  },
  {
    id: "telehealth_covered",
    label: "Is Telehealth covered?",
    dataKey: "telehealth_covered",
    cols: 6,
    type: "yes_no",
    required: true,
  },
  {
    id: "is_same_benefit",
    label: "Is the benefit the same?",
    dataKey: "is_same_benefit",
    cols: 6,
    type: "yes_no",
    required: true,
  },
  {
    id: "specific_telehealth_platform_required",
    label: "Specific Telehealth Platform required?",
    dataKey: "specific_telehealth_platform_required",
    cols: 6,
    type: "yes_no",
    required: true,
  },
  {
    id: "auth_required_for_assessment",
    label: "Is authorization required for ABA therapy? (97151)",
    dataKey: "auth_required_for_assessment",
    cols: 6,
    type: "yes_no",
    required: true,
  },
  {
    id: "auth_required_for_treatment",
    label: "Is authorization required for treatment? (97153, 97155, 97156)",
    dataKey: "auth_required_for_treatment",
    cols: 6,
    type: "yes_no",
    divider: true,
    required: true,
  },
  {
    id: "aba_managing_entity",
    label: "ABA Managing Entity",
    dataKey: "aba_managing_entity",
    cols: 6,
    required: true,
  },
  {
    id: "phone",
    label: "Phone Number",
    dataKey: "phone",
    cols: 6,
  },
  {
    id: "fax",
    label: "Fax Number",
    dataKey: "fax",
    cols: 6,
  },
  {
    id: "address1",
    label: "Address",
    dataKey: "address1",
    cols: 6,
    required: true,
  },
  {
    id: "address2",
    label: "Address 2",
    dataKey: "address2",
    cols: 6,
  },
  {
    id: "state",
    label: "State",
    dataKey: "state",
    cols: 6,
    required: true,
  },
  {
    id: "city",
    label: "City",
    dataKey: "city",
    cols: 6,
    required: true,
  },
  {
    id: "zip",
    label: "ZIP Code",
    dataKey: "zip",
    cols: 6,
    required: true,
  },
  {
    id: "payer_id",
    label: "Payer ID Number",
    dataKey: "payer_id",
    cols: 6,
    required: true,
  },
  {
    id: "representative",
    label: "Representative",
    dataKey: "representative",
    cols: 6,
    required: true,
  },
  {
    id: "representative_id",
    label: "Representative’s ID Number",
    dataKey: "representative_id",
    cols: 6,
    required: true,
  },
  {
    id: "notes",
    label: "Notes",
    dataKey: "notes",
    cols: 6,
    fullWith: true,
  },
];

export const VerificationOfBenefitsMedicaidConfig = [
  {
    id: "insurance_plan_id",
    label: "Insurance Plan",
    dataKey: "plan_name",
    cols: 12,
    required: true,
  },
  {
    id: "is_active_in_medicaid_portal",
    label: "Representative’s ID Number",
    dataKey: "is_active_in_medicaid_portal",
    cols: 12,
  },
  {
    id: "notes",
    label: "Notes",
    dataKey: "notes",
    cols: 12,
    fullWith: true,
  },
];
