import { getTeams } from "api/team";
import { GET_TEAMS, STALE_TIME_5_MINUTES } from "constants/reactQueryKeys";
import { useQuery } from "react-query";
import { queryParamsObjIsValid } from "utils/queryParamsObjIsValid";

export const useGetTeams = (query) => {
  const { data, isLoading, ...rest } = useQuery([GET_TEAMS, { query }], () => getTeams(query), {
    enabled: queryParamsObjIsValid(query),
    staleTime: STALE_TIME_5_MINUTES,
  });
  return {
    teams: data?.data || null,
    teamsLoading: isLoading,
    ...rest,
  };
};
