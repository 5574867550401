import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";

const ResetPasswordDialog = (props) => {
  const {
    resetPasswordOpen,
    resetPasswordSent,
    sendingResetPassword,
    onSubmitResetPassword,
    onToggleResetPassword,
  } = props;
  return (
    <Dialog
      open={resetPasswordOpen}
      onClose={onToggleResetPassword}
      aria-labelledby="form-dialog-title"
      key="reset-password-dialog"
    >
      <DialogTitle id="form-dialog-title" style={{ textAlign: "center", fontWeight: 500 }}>
        Send Reset Password Link
      </DialogTitle>
      <DialogContent
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: 500,
        }}
      >
        {resetPasswordSent ? (
          <DialogContentText>The link has been successfully sent.</DialogContentText>
        ) : (
          <DialogContentText>
            Are you sure you want to send a link to this user to reset their password?
          </DialogContentText>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={onToggleResetPassword} color="default" variant="outlined">
          {resetPasswordSent ? "Close" : "Cancel"}
        </Button>
        {!resetPasswordSent && (
          <Button
            onClick={onSubmitResetPassword}
            style={{ width: 125 }}
            color="primary"
            variant="contained"
          >
            {sendingResetPassword ? (
              <CircularProgress style={{ color: "#fff" }} size={24} />
            ) : (
              "Send Link"
            )}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ResetPasswordDialog;
