import React from "react";
import withStyles from "@mui/styles/withStyles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import { Alert, AlertTitle } from "@mui/material";

const ModalAlert = (props) => {
  const {
    classes,
    open,
    onClose,
    title,
    description,
    primaryActionText,
    primaryActionOnClick,
    secondaryActionText,
    secondaryActionOnClick,
    loading,
    primaryActionDisabled,
    secondaryActionDisabled,
    transitionDirection,
    primaryActionOutlined,
    dialogContentPadding,
    width,
    sx,
    severetyAlert,
    primaryActionColorButton,
    secondaryActionColorButton,
  } = props;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          borderRadius: 8,
          maxWidth: "25%",
          overflow: "visible",
          width: width ? width : "fit-content",
        },
      }}
      TransitionProps={{ direction: transitionDirection || "down" }}
      sx={sx}
    >
      <DialogContent
        className={classes.dialogContent}
        style={dialogContentPadding ? { padding: dialogContentPadding } : null}
      >
        <div className={classes.infoContainer}>
          <Alert severity={severetyAlert ?? "success"}>
            <AlertTitle> {title}</AlertTitle>
            {description}
          </Alert>
        </div>
      </DialogContent>

      <DialogActions className={classes.dialogActionsContainer}>
        {secondaryActionText && secondaryActionOnClick && (
          <Button
            onClick={secondaryActionOnClick}
            variant={primaryActionOutlined ? "outlined" : "contained"}
            disabled={secondaryActionDisabled}
            color={secondaryActionColorButton ?? "secondary"}
          >
            {secondaryActionText}
          </Button>
        )}
        {primaryActionText && primaryActionOnClick && (
          <LoadingButton
            onClick={primaryActionOnClick}
            disabled={primaryActionDisabled}
            loading={loading}
            color={primaryActionColorButton ?? "primary"}
            variant={primaryActionOutlined ? "outlined" : "contained"}
          >
            {primaryActionText}
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};
const styles = (theme) => ({
  dialogContent: {
    height: "fit-content",
    maxHeight: "95%",
    maxWidth: "100%",
    padding: "20px 20px 16px",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    [theme.breakpoints.down("xl")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  dialogActionsContainer: {
    padding: "12px 20px",
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
    position: "relative",
    [theme.breakpoints.down("xl")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
});
export default withStyles(styles)(ModalAlert);
