import React from "react";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import { format } from "date-fns";
import Button from "@mui/material/Button";
import { capitalizeBillingTypeFirstLetter } from "../../utils/capitalizeBillingTypeFirstLetter";

import { convertTokens } from "@date-fns/upgrade/v2";

const CaregiverSessions = (props) => {
  const {
    classes,
    pastCalls,
    userId,
    toggleShowMissedCalls,
    showMissedCalls,
    monthsArray,
    selectedMonth,
    groupedCalls,
    callTimelineRefs,
    onSelectMonth,
    navigate,
  } = props;
  let thisMonthCalls = groupedCalls[monthsArray[0]];
  let lastMonthCalls = groupedCalls[monthsArray[1]];
  let threeMonthsAgoCalls = groupedCalls[monthsArray[2]];
  let fourMonthsAgoCalls = groupedCalls[monthsArray[3]];
  let fiveMonthsAgoCalls = groupedCalls[monthsArray[4]];
  let sixMonthsAgoCalls = groupedCalls[monthsArray[5]];

  const isMissedCall = (call) => {
    if (!call.end_date || !call.start_date || call.no_show == true || call.canceled == true) {
      return true;
    } else {
      return false;
    }
  };
  let lastCallInfo = pastCalls[pastCalls.length - 1];

  return (
    <div className={classes.sessionsContainer}>
      <div className={classes.leftBlock} />
      <div className={classes.rightBlock} />
      <Typography className={classes.pageTitle}>Sessions</Typography>
      <div className={classes.checkboxContainer}>
        <Typography>Display:</Typography>
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              checked={showMissedCalls}
              onClick={toggleShowMissedCalls}
              style={{ color: "#2a2d39" }}
              disableRipple
              disableFocusRipple
            />
          }
          label={<span className={classes.checkboxLabel}>Show missed calls</span>}
        />
      </div>
      <Typography variant="h3" className={classes.pageSubTitle}>
        Past Calls
      </Typography>
      <div className={classes.pastCallsContainer}>
        <div className={classes.tabContainer}>
          <Typography variant="h5">Jump to:</Typography>
          <div className={classes.monthsTab}>
            {monthsArray.map((month, i) => (
              <div
                className={selectedMonth == month ? classes.selectedMonth : classes.month}
                key={i}
                onClick={() => onSelectMonth(month)}
              >
                <Typography>{month}</Typography>
              </div>
            ))}
          </div>
        </div>

        <Timeline align="right" classes={{ root: classes.timelineRoot }}>
          <TimelineItem
            classes={{ root: classes.timelineItem }}
            ref={(ref) => (callTimelineRefs[monthsArray[0]] = ref)}
          >
            <TimelineOppositeContent>
              <TimelineContent className={classes.timelineContentMobile}>
                This Month
              </TimelineContent>

              <div className={classes.callInfoContainer}>
                {thisMonthCalls?.length == 0 || !thisMonthCalls ? (
                  <Typography>No calls found</Typography>
                ) : (
                  thisMonthCalls?.map((call) => (
                    <CallBlock
                      key={call.id}
                      call={call}
                      classes={classes}
                      isMissedCall={isMissedCall}
                      navigate={navigate}
                    />
                  ))
                )}
              </div>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot variant="outlined" />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent className={classes.timelineContentDesktop}>This Month</TimelineContent>
          </TimelineItem>
          <TimelineItem
            classes={{ root: classes.timelineItem }}
            ref={(ref) => (callTimelineRefs[monthsArray[1]] = ref)}
          >
            <TimelineOppositeContent>
              <TimelineContent className={classes.timelineContentMobile}>
                Last Month
              </TimelineContent>

              <div className={classes.callInfoContainer}>
                {lastMonthCalls?.length == 0 || !lastMonthCalls ? (
                  <Typography>No calls found</Typography>
                ) : (
                  lastMonthCalls?.map((call) => (
                    <CallBlock
                      key={call.id}
                      call={call}
                      classes={classes}
                      isMissedCall={isMissedCall}
                      navigate={navigate}
                    />
                  ))
                )}
              </div>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot variant="outlined" />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent className={classes.timelineContentDesktop}>Last Month</TimelineContent>
          </TimelineItem>
          <TimelineItem
            classes={{ root: classes.timelineItem }}
            ref={(ref) => (callTimelineRefs[monthsArray[2]] = ref)}
          >
            <TimelineOppositeContent>
              <TimelineContent className={classes.timelineContentMobile}>
                3 Months Ago
              </TimelineContent>

              <div className={classes.callInfoContainer}>
                {threeMonthsAgoCalls?.length == 0 || !threeMonthsAgoCalls ? (
                  <Typography>No calls found</Typography>
                ) : (
                  threeMonthsAgoCalls?.map((call) => (
                    <CallBlock
                      key={call.id}
                      call={call}
                      classes={classes}
                      isMissedCall={isMissedCall}
                      navigate={navigate}
                    />
                  ))
                )}
              </div>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot variant="outlined" />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent className={classes.timelineContentDesktop}>
              3 Months Ago
            </TimelineContent>
          </TimelineItem>
          <TimelineItem
            classes={{ root: classes.timelineItem }}
            ref={(ref) => (callTimelineRefs[monthsArray[3]] = ref)}
          >
            <TimelineOppositeContent>
              <TimelineContent className={classes.timelineContentMobile}>
                4 Months Ago
              </TimelineContent>

              <div className={classes.callInfoContainer}>
                {fourMonthsAgoCalls?.length == 0 || !fourMonthsAgoCalls ? (
                  <Typography>No calls found</Typography>
                ) : (
                  fourMonthsAgoCalls?.map((call) => (
                    <CallBlock
                      key={call.id}
                      call={call}
                      classes={classes}
                      isMissedCall={isMissedCall}
                      navigate={navigate}
                    />
                  ))
                )}
              </div>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot variant="outlined" />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent className={classes.timelineContentDesktop}>
              4 Months Ago
            </TimelineContent>
          </TimelineItem>
          <TimelineItem
            classes={{ root: classes.timelineItem }}
            ref={(ref) => (callTimelineRefs[monthsArray[4]] = ref)}
          >
            <TimelineOppositeContent>
              <TimelineContent className={classes.timelineContentMobile}>
                5 Months Ago
              </TimelineContent>

              <div className={classes.callInfoContainer}>
                {fiveMonthsAgoCalls?.length == 0 || !fiveMonthsAgoCalls ? (
                  <Typography>No calls found</Typography>
                ) : (
                  fiveMonthsAgoCalls?.map((call) => (
                    <CallBlock
                      key={call.id}
                      call={call}
                      classes={classes}
                      isMissedCall={isMissedCall}
                      navigate={navigate}
                    />
                  ))
                )}
              </div>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot variant="outlined" />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent className={classes.timelineContentDesktop}>
              5 Months Ago
            </TimelineContent>
          </TimelineItem>
          <TimelineItem
            classes={{ root: classes.timelineItem }}
            ref={(ref) => (callTimelineRefs[monthsArray[5]] = ref)}
          >
            <TimelineOppositeContent>
              <TimelineContent className={classes.timelineContentMobile}>
                6 Months Ago
              </TimelineContent>

              <div className={classes.callInfoContainer}>
                {sixMonthsAgoCalls?.length == 0 || !sixMonthsAgoCalls ? (
                  <Typography>No calls found</Typography>
                ) : (
                  sixMonthsAgoCalls?.map((call) => (
                    <CallBlock
                      key={call.id}
                      call={call}
                      classes={classes}
                      isMissedCall={isMissedCall}
                      navigate={navigate}
                    />
                  ))
                )}
              </div>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot variant="outlined" />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent className={classes.timelineContentDesktop}>
              6 Months Ago
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      </div>
      <div className={classes.infoContainer}>
        <Button variant="text" onClick={() => navigate("/all-sessions")}>
          View All Calls
        </Button>
        <Typography variant="body1">
          Oldest record from{" "}
          {lastCallInfo?.start_date || lastCallInfo?.scheduled_date
            ? format(
                new Date(lastCallInfo?.start_date || lastCallInfo?.scheduled_date),
                convertTokens("M/D/YYYY"),
              )
            : null}{" "}
        </Typography>
      </div>
    </div>
  );
};
export default CaregiverSessions;

var CallBlock = (props) => {
  const { classes, call, isMissedCall, navigate } = props;
  return isMissedCall(call) ? (
    <div className={classes.missedCallBlock}>
      <div className={classes.callDate}>
        <Typography>
          {call.start_date || call.scheduled_date
            ? format(new Date(call.start_date || call.scheduled_date), convertTokens("MMM"))
            : null}
        </Typography>
        <Typography variant="h3" style={{ fontWeight: 500 }}>
          {call.start_date || call.scheduled_date
            ? format(new Date(call.start_date || call.scheduled_date), convertTokens("D"))
            : null}
        </Typography>
        <Typography>
          {call.start_date || call.scheduled_date
            ? format(new Date(call.start_date || call.scheduled_date), convertTokens("YYYY"))
            : null}
        </Typography>
      </div>
      <div className={classes.callInfo}>
        <Typography className={classes.billingType}>
          {call.billing_type ? capitalizeBillingTypeFirstLetter(call.billing_type) : null}
          <br />
          {call.canceled ? (
            <span style={{ fontSize: 13 }}>
              (Cancelled
              {call.responsible_for_cancellation
                ? call.responsible_for_cancellation == "client"
                  ? " by Client"
                  : call.responsible_for_cancellation == "clinician"
                    ? " by Clinician"
                    : " - Holiday"
                : ""}
              )
            </span>
          ) : call.no_show ? (
            <span style={{ fontSize: 13 }}>(Client No-Show)</span>
          ) : (
            <span style={{ fontSize: 13 }}>(Missed)</span>
          )}
        </Typography>
        <Typography className={classes.clinicianName}>Clinician: {call?.clinician_name}</Typography>
      </div>
    </div>
  ) : (
    <div className={classes.callBlock}>
      <div className={classes.callDate}>
        <Typography>
          {call.start_date ? format(new Date(call.start_date), convertTokens("MMM")) : null}
        </Typography>
        <Typography variant="h3" style={{ fontWeight: 500 }}>
          {call.start_date ? format(new Date(call.start_date), convertTokens("D")) : null}
        </Typography>
        <Typography>
          {call.start_date ? format(new Date(call.start_date), convertTokens("YYYY")) : null}
        </Typography>
      </div>
      <div className={classes.callInfo}>
        <Typography className={classes.billingType}>
          {call.billing_type ? capitalizeBillingTypeFirstLetter(call.billing_type) : null}
        </Typography>
        <Typography className={classes.clinicianName}>Clinician: {call?.clinician_name}</Typography>
        <Button variant="text" onClick={() => navigate(`/session-details/${call.id}`)}>
          View Notes
        </Button>
      </div>
    </div>
  );
};
