import { getHasInsuranceAuthorization } from "api/insurance";
import { useQuery } from "react-query";

/**
 * Custom hook to get the insurance authorization status for a client.
 *
 * @param {string} clientId  - The ID of the client.
 * @returns {Object} - The result of the query.
 */
export function useGetHasInsuranceAuthorization(clientId) {
  return useQuery(
    ["getHasInsuranceAuthorization", clientId],
    () => getHasInsuranceAuthorization(clientId),
    {
      enabled: !!clientId,
    },
  );
}
