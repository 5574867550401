import React, { Component } from "react";
import PropTypes from "prop-types";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import styles from "./styles";

class ConfirmationDialog extends Component {
  render() {
    const { classes, openConfirm, confirmationType, handleJoin, handleLeave, handleCloseConfirm } =
      this.props;

    return (
      <Dialog
        open={openConfirm}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        disableEscapeKeyDown={true}
        onClose={handleCloseConfirm}
        PaperProps={{
          style: {
            maxWidth: "95%",
            width: 350,
          },
        }}
      >
        {confirmationType === "join" && (
          <DialogTitle id="form-dialog-title" align="center">
            Community Agreement
          </DialogTitle>
        )}
        <DialogContent>
          <Typography component="div" align="center">
            {confirmationType === "leave" ? (
              <Typography component="p" className={classes.confirmationMessage}>
                Are you sure you want to leave this group?
              </Typography>
            ) : (
              <Typography component="p" className={classes.confirmationMessage}>
                We're all here to learn from and support each other! Those who don't will be told to
                leave.
              </Typography>
            )}
          </Typography>
        </DialogContent>
        <DialogActions>
          {confirmationType === "leave" ? (
            <React.Fragment>
              <Button onClick={handleCloseConfirm} variant="contained" color="inherit">
                No
              </Button>
              &nbsp;
              <Button onClick={handleLeave} variant="contained" color="primary">
                Yes
              </Button>
            </React.Fragment>
          ) : (
            <Button
              onClick={handleJoin}
              variant="contained"
              color="primary"
              className={classes.confirmationAgree}
            >
              Accept and Continue
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ConfirmationDialog);
