import React from "react";
import Typography from "@mui/material/Typography";

// We create this custom component to handle the bug when clicking on the text
const CustomTypography = ({ onClick, text }) => (
  <Typography
    variant="body2"
    onClick={(e) => {
      // This preventDefault() avoid the Autocomplete lost its focus and closing
      e.preventDefault();
      onClick();
    }}
  >
    {text}
  </Typography>
);

export default CustomTypography;
