const insuranceDetailConfig = [
  { label: "Insurance Company", dataKey: "insuranceName", cols: 6 },
  { label: "Insurance Plan", dataKey: "planName", cols: 6 },
  { label: "Member ID", dataKey: "memberId", cols: 6 },
  { label: "Group Number", dataKey: "groupNumber", cols: 6 },
  { label: "Effective Date", dataKey: "effectiveDate", cols: 6, format: "jsonDate" },
  { label: "Expiration Date", dataKey: "expirationDate", cols: 6, format: "jsonDate" },
  { label: "Insurance Holder Name", dataKey: "insuranceHolderName", cols: 6 },
  {
    label: "Insurance Holder Date of Birth",
    dataKey: "insuranceHolderDOB",
    cols: 6,
    format: "jsonDate",
  },
  { label: "Insurance Holder Relationship", dataKey: "insurance_holder_relationship", cols: 12 },
  { label: "Medicaid", dataKey: "isMedicaid", cols: 6 },
  { label: "Primary Insurance", dataKey: "isPrimary", cols: 12 },
];

export default insuranceDetailConfig;
