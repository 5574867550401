import React, { useState } from "react";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { DIAGNOSTIC_CODES, GLOBAL_STATES } from "../../constants";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import StarIcon from "@mui/icons-material/Star";
import IconButton from "@mui/material/IconButton";
import AddFamilyDrawer from "./AddFamilyDrawer";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AddPhysicianDrawer from "./AddPhysicianDrawer";
import Physician from "./Physician";
import LoadingButton from "@mui/lab/LoadingButton";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import ANDatePicker from "elements/ANDatePicker";
import { getTimeZoneOptions } from "utils/getTimeZoneOptions";

const IntakeForm = (props) => {
  const [physicianSelected, setPhysicianSelected] = useState(null);

  const onTogglePhysicianMenu = (physician) => {
    if (physician) {
      setPhysicianSelected(physician);
    } else {
      setPhysicianSelected(null);
    }
  };

  const onSelectEditPhysician = () => {
    props.onTogglePhysicianDrawer();
  };

  const onSelectRemovePhysician = () => {
    props.onRemovePhysician(physicianSelected);
    setPhysicianSelected(null);
  };

  const {
    classes,
    onChange,
    onNumberChange,
    handleDateChange,
    onSubmit,
    createUserError,
    onSaveCustomerForLater,
    savingForLater,
    savingForLaterSuccess,
    clientAddress1,
    clientAddress2,
    clientCity,
    clientState,
    clientZip,
    clientCountry,
    clientFirstName,
    clientLastName,
    clientDOB,
    clientGender,
    clientHasASDDiagnosis,
    insuranceHolderRelationship,
    insuranceId,
    insurancePlanId,
    insuranceHolderDOB,
    insuranceHolderName,
    insuranceMemberId,
    insuranceGroupNumber,
    hasSecondaryInsurance,
    secondaryInsuranceId,
    secondaryInsurancePlanId,
    secondaryInsuranceMemberId,
    secondaryInsuranceGroupNumber,
    secondaryInsuranceHolderRelationship,
    secondaryInsuranceHolderName,
    secondaryInsuranceHolderDOB,
    privatePay,
    setError,
    CustomInput,
    onRadioButtonChange,
    timezone,
    onTimezoneChange,
    clientPronouns,
    onToggleEditFamilyDrawer,
    familyDrawerOpen,
    familyMembers,
    onToggleHasSecondaryInsurance,
    onTogglePhysicianDrawer,
    physicianDrawerOpen,
    onSelectPhysician,
    physicians,
    isVerbal,
    preAuthRequired,
    clientDiagnosis,
    onChangeInsuranceProvider,
    insuranceProvidersList,
    primaryInsurancePlans,
    secondaryInsurancePlans,
    clientFirstNameError,
    clientLastNameError,
    clientDOBError,
    clientGenderError,
    clientHasASDDiagnosisError,
    timezoneError,
    clientAddress1Error,
    clientCityError,
    clientStateError,
    clientZipError,
    isPrivatePayError,
    preAuthRequiredError,
    insuranceIdError,
    insurancePlanIdError,
    insuranceMemberIdError,
    insuranceHolderDOBError,
    insuranceHolderNameError,
    insuranceHolderRelationshipError,
    secondaryInsuranceIdError,
    secondaryInsurancePlanIdError,
    secondaryInsuranceMemberIdError,
    secondaryInsuranceHolderDOBError,
    secondaryInsuranceHolderNameError,
    secondaryInsuranceHolderRelationshipError,
    familyMembersError,
    onToggleSectionOpen,
    personalInfoOpen,
    clientAddressOpen,
    familyMembersOpen,
    insuranceOpen,
    physiciansOpen,
  } = props;

  const mapRelationshipToDisplay = (relationship) => {
    switch (relationship) {
      case "caregiver":
        return "Caregiver";
      case "child":
        return "Child";
      case "dependent":
        return "Dependent";
      case "family_member":
        return "Family Member";
      case "legal_guardian":
        return "Legal Guardian";
      case "is_parent":
        return "Parent";
      case "spouse":
        return "Spouse";
      default:
        return "--";
    }
  };

  const mapContactTypeToDisplay = (contactType) => {
    switch (contactType) {
      case "adult":
        return "Adult";
      case "minor":
        return "Minor";
      default:
        return "--";
    }
  };

  return (
    <div className={classes.formContainer}>
      <Grid
        container
        item
        xs={12}
        sm={12}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography className={classes.title}>Client Intake</Typography>
        {createUserError && (
          <Typography className={classes.errorMessage}>{createUserError}</Typography>
        )}
        <div className={classes.intakeFormButtonsContainer}>
          <LoadingButton
            variant="text"
            onClick={onSaveCustomerForLater}
            loading={savingForLater}
            className={classes.buttonContainer}
          >
            Save for Later
          </LoadingButton>
          <Button className={classes.buttonContainer} onClick={onSubmit}>
            Save and Invite
          </Button>
        </div>
      </Grid>
      <div className={classes.form}>
        <Accordion
          expanded={personalInfoOpen}
          onChange={() => onToggleSectionOpen("personalInfoOpen", !personalInfoOpen)}
          elevation={0}
          classes={{ root: classes.accordionRoot }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ fontSize: 35, fill: "#2A2D39" }} />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className={classes.intakeAccordionSummary}
            classes={{ content: classes.intakeAccordionSummaryContent }}
          >
            <Typography className={classes.heading}>Personal Information Basics</Typography>
            <Typography className={classes.secondaryHeading}>Part 1 of 5</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.intakeAccordionDetails}>
            <Grid container item xs={12} spacing={5} className={classes.profileInfoContainer}>
              <Grid item sm={12} md={7}>
                <FormControl margin="none" style={{ width: 310 }}>
                  <FormLabel
                    component="legend"
                    style={{
                      marginBottom: 5,
                      fontSize: 14,
                      fontWeight: 500,
                      color: "#545A72",
                      lineHeight: 1.4,
                    }}
                  >
                    Client&apos;s First Name*
                  </FormLabel>

                  <TextField
                    id="clientFirstName"
                    name="clientFirstName"
                    autoComplete="off"
                    value={clientFirstName}
                    onChange={onChange("clientFirstName")}
                    size="small"
                    fullWidth
                    onFocus={() => setError("clientFirstNameError", false)}
                    onBlur={() =>
                      clientFirstName
                        ? setError("clientFirstNameError", false)
                        : setError("clientFirstNameError", true)
                    }
                    helperText={clientFirstNameError ? "Please enter first name" : null}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={12} md={5}>
                <FormControl margin="none" style={{ width: 310 }}>
                  <FormLabel
                    component="legend"
                    style={{
                      marginBottom: 5,
                      fontSize: 14,
                      fontWeight: 500,
                      color: "#545A72",
                      lineHeight: 1.4,
                    }}
                  >
                    Client&apos;s Last Name*
                  </FormLabel>

                  <TextField
                    id="clientLastName"
                    name="clientLastName"
                    autoComplete="off"
                    value={clientLastName}
                    onChange={onChange("clientLastName")}
                    size="small"
                    fullWidth
                    onFocus={() => setError("clientLastNameError", false)}
                    onBlur={() =>
                      clientLastName
                        ? setError("clientLastNameError", false)
                        : setError("clientLastNameError", true)
                    }
                    helperText={clientLastNameError ? "Please enter last name" : null}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={12} md={7}>
                <FormControl margin="none">
                  <FormLabel
                    component="legend"
                    style={{
                      marginBottom: 5,
                      fontSize: 14,
                      fontWeight: 500,
                      color: "#545A72",
                      lineHeight: 1.4,
                    }}
                  >
                    Client&apos;s Birthdate*
                  </FormLabel>

                  <ANDatePicker
                    value={clientDOB ? new Date(clientDOB) : null}
                    onChange={(date) => handleDateChange(date, "clientDOB")}
                    format="P" //"MM/dd/yyyy"
                    disableFuture
                    size="medium"
                    placeholder="MM/DD/YYYY"
                    slots={{ textField: CustomInput }}
                    onFocus={() => setError("clientDOBError", false)}
                    onBlur={() =>
                      clientDOB && clientDOBError ? setError("clientDOBError", false) : null
                    }
                    helperText={clientDOBError ? "Please enter date of birth" : null}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={12} md={5}>
                <FormControl margin="none" style={{ maxWidth: 300 }}>
                  <FormLabel
                    component="legend"
                    style={{
                      marginBottom: 5,
                      fontSize: 14,
                      fontWeight: 500,
                      color: "#545A72",
                      lineHeight: 1.4,
                    }}
                  >
                    Time Zone*
                  </FormLabel>
                  <TextField
                    size="medium"
                    select
                    value={timezone}
                    onChange={onTimezoneChange}
                    inputProps={{
                      name: "timezone",
                    }}
                    onFocus={() => setError("timezoneError", false)}
                    onBlur={() =>
                      timezone ? setError("timezoneError", false) : setError("timezoneError", true)
                    }
                    helperText={timezoneError ? "Please select Time Zone" : null}
                  >
                    <MenuItem value="" disabled>
                      Time Zone
                    </MenuItem>
                    {getTimeZoneOptions().map(({ label, value }, index) => {
                      return (
                        <MenuItem key={index} value={value}>
                          {label}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item sm={12} md={7}>
                <FormControl margin="none">
                  <FormLabel
                    component="legend"
                    style={{
                      marginBottom: 5,
                      fontSize: 14,
                      fontWeight: 500,
                      color: "#545A72",
                      lineHeight: 1.4,
                    }}
                  >
                    Client&apos;s Gender*
                  </FormLabel>
                  <TextField
                    id="gender"
                    name="gender"
                    value={clientGender}
                    onChange={onChange("clientGender")}
                    size="medium"
                    select
                    onFocus={() => setError("clientGenderError", false)}
                    onBlur={() =>
                      clientGender
                        ? setError("clientGenderError", false)
                        : setError("clientGenderError", true)
                    }
                    helperText={clientGenderError ? "Please select gender" : null}
                  >
                    <MenuItem value="" disabled>
                      Select Gender
                    </MenuItem>
                    <MenuItem value="Female">Female</MenuItem>
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="non_binary">Non-binary</MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item sm={12} md={5}>
                <FormControl margin="none">
                  <FormLabel
                    component="legend"
                    style={{
                      marginBottom: 5,
                      fontSize: 14,
                      fontWeight: 500,
                      color: "#545A72",
                      lineHeight: 1.4,
                    }}
                  >
                    Client&apos;s Pronouns
                  </FormLabel>
                  <TextField
                    id="pronouns"
                    name="pronouns"
                    value={clientPronouns}
                    onChange={onChange("clientPronouns")}
                    fullWidth
                    size="medium"
                    select
                  >
                    <MenuItem value="" disabled style={{ color: "#A5AABC" }}>
                      Select Pronouns
                    </MenuItem>
                    <MenuItem value="she/her">She/Her</MenuItem>
                    <MenuItem value="he/him">He/Him</MenuItem>
                    <MenuItem value="they/them">They/Them</MenuItem>
                    <MenuItem value="she/they">She/They</MenuItem>
                    <MenuItem value="he/they">He/They</MenuItem>
                    <MenuItem value="he/she/they">He/She/They (All)</MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item sm={12} md={7}>
                <FormLabel
                  component="legend"
                  style={{
                    marginBottom: 5,
                    fontSize: 14,
                    fontWeight: 500,
                    color: "#545A72",
                    lineHeight: 1.4,
                  }}
                >
                  Do you have an autism diagnosis?*
                </FormLabel>

                <RadioGroup
                  aria-label="clientHasASDDiagnosis"
                  name="clientHasASDDiagnosis"
                  value={clientHasASDDiagnosis}
                  onChange={onRadioButtonChange("clientHasASDDiagnosis")}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio color="primary" size="small" />}
                    label={
                      <Typography
                        component="p"
                        style={{
                          fontSize: 15,
                          color: "#2A2D39",
                        }}
                      >
                        Yes
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio color="primary" size="small" />}
                    label={
                      <Typography
                        component="p"
                        style={{
                          fontSize: 15,
                          color: "#2A2D39",
                        }}
                      >
                        No
                      </Typography>
                    }
                  />
                </RadioGroup>
                {clientHasASDDiagnosisError && (
                  <Typography component="p" className={classes.helperText}>
                    Please select an option
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={5}>
                <FormControl margin="none">
                  <FormLabel
                    component="legend"
                    style={{
                      marginBottom: 5,
                      fontSize: 14,
                      fontWeight: 500,
                      color: "#545A72",
                      lineHeight: 1.4,
                    }}
                  >
                    Diagnosis
                  </FormLabel>
                  <TextField
                    id="daignosis"
                    name="diagnosis"
                    value={clientDiagnosis}
                    onChange={onChange("clientDiagnosis")}
                    fullWidth
                    size="medium"
                    select
                  >
                    <MenuItem value="" disabled style={{ color: "#A5AABC" }}>
                      Select Diagnosis
                    </MenuItem>
                    {DIAGNOSTIC_CODES.map((code, index) => {
                      return (
                        <MenuItem key={index} value={code}>
                          {code}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item sm={12} md={7}>
                <FormLabel
                  component="legend"
                  style={{
                    marginBottom: 5,
                    fontSize: 14,
                    fontWeight: 500,
                    color: "#545A72",
                    lineHeight: 1.4,
                  }}
                >
                  Is the client verbal?
                </FormLabel>

                <RadioGroup
                  aria-label="isVerbal"
                  name="isVerbal"
                  value={isVerbal}
                  onChange={onRadioButtonChange("isVerbal")}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio color="primary" size="small" />}
                    label={
                      <Typography
                        component="p"
                        style={{
                          fontSize: 15,
                          color: "#2A2D39",
                        }}
                      >
                        Yes
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio color="primary" size="small" />}
                    label={
                      <Typography
                        component="p"
                        style={{
                          fontSize: 15,
                          color: "#2A2D39",
                        }}
                      >
                        No
                      </Typography>
                    }
                  />
                </RadioGroup>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={clientAddressOpen}
          onChange={() => onToggleSectionOpen("clientAddressOpen", !clientAddressOpen)}
          elevation={0}
          classes={{ root: classes.accordionRoot }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ fontSize: 35, fill: "#2A2D39" }} />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className={classes.intakeAccordionSummary}
            classes={{ content: classes.intakeAccordionSummaryContent }}
          >
            <Typography className={classes.heading}>Client&apos;s Address</Typography>
            <Typography className={classes.secondaryHeading}>Part 2 of 5</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.intakeAccordionDetails}>
            <Grid container item xs={12} spacing={5} className={classes.profileInfoContainer}>
              <Grid item sm={12} md={7}>
                <FormControl margin="none" style={{ width: 310 }}>
                  <FormLabel
                    component="legend"
                    style={{
                      marginBottom: 5,
                      fontSize: 14,
                      fontWeight: 500,
                      color: "#545A72",
                      lineHeight: 1.4,
                    }}
                  >
                    Address Line 1*
                  </FormLabel>

                  <TextField
                    id="clientAddress1"
                    name="clientAddress1"
                    autoComplete="off"
                    value={clientAddress1}
                    onChange={onChange("clientAddress1")}
                    size="small"
                    fullWidth
                    placeholder="address 1"
                    onFocus={() => setError("clientAddress1Error", false)}
                    onBlur={() =>
                      clientAddress1
                        ? setError("clientAddress1Error", false)
                        : setError("clientAddress1Error", true)
                    }
                    helperText={clientAddress1Error ? "Please enter address" : null}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={12} md={5}>
                <FormControl margin="none" style={{ width: 310 }}>
                  <FormLabel
                    component="legend"
                    style={{
                      marginBottom: 5,
                      fontSize: 14,
                      fontWeight: 500,
                      color: "#545A72",
                      lineHeight: 1.4,
                    }}
                  >
                    Address Line 2
                  </FormLabel>

                  <TextField
                    id="clientAddress2"
                    name="clientAddress2"
                    autoComplete="off"
                    value={clientAddress2}
                    onChange={onChange("clientAddress2")}
                    size="small"
                    fullWidth
                    placeholder="address 2"
                  />
                </FormControl>
              </Grid>
              <Grid item sm={12} md={7}>
                <FormControl margin="none" style={{ width: 310 }}>
                  <FormLabel
                    component="legend"
                    style={{
                      marginBottom: 5,
                      fontSize: 14,
                      fontWeight: 500,
                      color: "#545A72",
                      lineHeight: 1.4,
                    }}
                  >
                    City*
                  </FormLabel>

                  <TextField
                    id="clientCity"
                    name="clientCity"
                    autoComplete="off"
                    value={clientCity}
                    onChange={onChange("clientCity")}
                    size="small"
                    fullWidth
                    placeholder="city"
                    onFocus={() => setError("clientCityError", false)}
                    onBlur={() =>
                      clientCity
                        ? setError("clientCityError", false)
                        : setError("clientCityError", true)
                    }
                    helperText={clientCityError ? "Please enter city" : null}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={12} md={5}>
                <FormControl margin="none" style={{ width: 200 }}>
                  <FormLabel
                    component="legend"
                    style={{
                      marginBottom: 5,
                      fontSize: 14,
                      fontWeight: 500,
                      color: "#545A72",
                      lineHeight: 1.4,
                    }}
                  >
                    State*
                  </FormLabel>

                  <TextField
                    id="clientState"
                    name="clientState"
                    autoComplete="off"
                    value={clientState}
                    onChange={onChange("clientState")}
                    size="small"
                    select
                    onFocus={() => setError("clientStateError", false)}
                    onBlur={() =>
                      clientState
                        ? setError("clientStateError", false)
                        : setError("clientStateError", true)
                    }
                    helperText={clientStateError ? "Please select state" : null}
                  >
                    <MenuItem disabled value={""}>
                      state
                    </MenuItem>
                    {Object.keys(GLOBAL_STATES).map((key) => {
                      return (
                        <MenuItem key={key} value={key}>
                          {GLOBAL_STATES[key]}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item sm={12} md={7}>
                <FormControl margin="none" style={{ width: 200 }}>
                  <FormLabel
                    component="legend"
                    style={{
                      marginBottom: 5,
                      fontSize: 14,
                      fontWeight: 500,
                      color: "#545A72",
                      lineHeight: 1.4,
                    }}
                  >
                    ZIP Code*
                  </FormLabel>
                  <TextField
                    id="clientZip"
                    name="clientZip"
                    autoComplete="off"
                    value={clientZip}
                    onChange={onNumberChange("clientZip")}
                    placeholder="ZIP Code"
                    inputProps={{ pattern: "[0-9]*", maxLength: 5 }}
                    size="small"
                    onFocus={() => setError("clientZipError", false)}
                    onBlur={() =>
                      clientZip.trim().length === 5
                        ? setError("clientZipError", false)
                        : setError("clientZipError", true)
                    }
                    helperText={clientZipError ? "Please enter a valid ZIP Code" : null}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={12} md={5}>
                <FormControl margin="none" style={{ width: 200 }}>
                  <FormLabel
                    component="legend"
                    style={{
                      marginBottom: 5,
                      fontSize: 14,
                      fontWeight: 500,
                      color: "#545A72",
                      lineHeight: 1.4,
                    }}
                  >
                    Country*
                  </FormLabel>

                  <TextField
                    id="clientCountry"
                    name="clientCountry"
                    autoComplete="off"
                    value={clientCountry}
                    onChange={onChange("clientCountry")}
                    size="small"
                    disabled={clientState != "Out"}
                    helperText={clientState == "Out" && !clientCountry ? "Select Country" : null}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={familyMembersOpen}
          onChange={() => onToggleSectionOpen("familyMembersOpen", !familyMembersOpen)}
          elevation={0}
          classes={{ root: classes.accordionRoot }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ fontSize: 35, fill: "#2A2D39" }} />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className={classes.intakeAccordionSummary}
            classes={{ content: classes.intakeAccordionSummaryContent }}
          >
            <Typography className={classes.heading}>Family Members</Typography>
            <Typography className={classes.secondaryHeading}>Part 3 of 5</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.intakeAccordionDetailsFamily}>
            <Grid container item xs={12} spacing={5} className={classes.profileInfoContainer}>
              {familyMembers ? (
                familyMembers.map((familyMember, i) => {
                  return (
                    <div key={i} className={classes.familyMember}>
                      <div className={classes.familyMemberHeader}>
                        <Typography component="p">{`${familyMember.first_name} ${familyMember.last_name}`}</Typography>
                        <IconButton
                          color="inherit"
                          aria-label="Open family member drawer"
                          onClick={() => onToggleEditFamilyDrawer(i)}
                          size="large"
                        >
                          <MoreHorizIcon
                            style={{
                              fontSize: 26,
                              marginBottom: 0,
                              fill: "#552879",
                            }}
                          />
                        </IconButton>
                      </div>
                      <div className={classes.familyMemberDetails}>
                        <div className={classes.familyMemberDetailsRow}>
                          <Typography component="p" className={classes.familyMemberRowLabel}>
                            Relationship
                          </Typography>
                          <Typography component="p">
                            {mapRelationshipToDisplay(familyMember.relationship_to_client)}
                          </Typography>
                        </div>
                        <div className={classes.familyMemberDetailsRow}>
                          <Typography component="p" className={classes.familyMemberRowLabel}>
                            Phone Number
                          </Typography>
                          <Typography component="p">{familyMember.phone || "--"}</Typography>
                        </div>

                        <div className={classes.familyMemberDetailsRow}>
                          <Typography component="p" className={classes.familyMemberRowLabel}>
                            Email
                          </Typography>
                          <Typography component="p">{familyMember.email || "--"}</Typography>
                        </div>
                        <div className={classes.familyMemberDetailsRow}>
                          <Typography component="p" className={classes.familyMemberRowLabel}>
                            Contact Type
                          </Typography>
                          <Typography component="p">
                            {mapContactTypeToDisplay(familyMember.contact_type)}
                          </Typography>
                        </div>
                        {familyMember.is_primary && (
                          <div className={classes.familyMemberDetailsRow}>
                            <div className={classes.familyMemberRowLabel}>
                              <div
                                className={classes.familyMemberRowIconContainer}
                                style={{
                                  marginBottom: 0,
                                  backgroundColor: "#d2b3ea",
                                }}
                              >
                                <StarIcon
                                  style={{
                                    fontSize: 14,
                                    marginBottom: 0,
                                    color: "#552879",
                                  }}
                                />
                              </div>
                            </div>
                            <Typography component="p">Primary Account Holder</Typography>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })
              ) : (
                // This only renders when there are no family members associated
                <Typography component="p" paddingTop={"20px"}>
                  No family members associated with this learner
                </Typography>
              )}

              {/* <Grid item sm={12}>
                                <Button
                                    variant="text"
                                    size="small"
                                    startIcon={<AddIcon style={{ fontSize: 15 }} />}
                                    onClick={() => onToggleFamilyDrawer()}
                                >
                                    Add Family Member
                                </Button>
                            </Grid> */}
              {familyMembersError && (
                <Typography component="p" className={classes.helperText}>
                  {familyMembers.length > 0
                    ? "Please complete all family member fields"
                    : "Please enter a family member"}
                </Typography>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={insuranceOpen}
          onChange={() => onToggleSectionOpen("insuranceOpen", !insuranceOpen)}
          elevation={0}
          classes={{ root: classes.accordionRoot }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ fontSize: 35, fill: "#2A2D39" }} />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className={classes.intakeAccordionSummary}
            classes={{ content: classes.intakeAccordionSummaryContent }}
          >
            <Typography className={classes.heading}>Insurance</Typography>
            <Typography className={classes.secondaryHeading}>Part 4 of 5</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.intakeAccordionDetails}>
            <Grid container item xs={12} spacing={3} className={classes.profileInfoContainer}>
              <Grid item sm={12}>
                <Typography component="p" className={classes.insuranceSubtitle}>
                  Client Insurance Details
                </Typography>
              </Grid>
              <Grid item sm={12} md={5}>
                <FormLabel component="legend" className={classes.insuranceFieldLabel}>
                  Is the client paying with Insurance?
                </FormLabel>

                <RadioGroup
                  aria-label="privatePay"
                  name="privatePay"
                  value={privatePay}
                  onChange={onRadioButtonChange("privatePay")}
                >
                  <FormControlLabel
                    value={false}
                    control={<Radio color="primary" size="small" />}
                    label={
                      <Typography
                        component="p"
                        style={{
                          fontSize: 14,
                          color: "#2A2D39",
                        }}
                      >
                        Yes
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value={true}
                    control={<Radio color="primary" size="small" />}
                    label={
                      <Typography
                        component="p"
                        style={{
                          fontSize: 14,
                          color: "#2A2D39",
                        }}
                      >
                        No, this is private pay
                      </Typography>
                    }
                  />
                </RadioGroup>
                {isPrivatePayError && (
                  <Typography component="p" className={classes.helperText}>
                    Please select an option
                  </Typography>
                )}
              </Grid>

              {!privatePay && (
                <>
                  <Grid item sm={12} md={7}>
                    <FormLabel component="legend" className={classes.insuranceFieldLabel}>
                      Pre-auth required?
                    </FormLabel>

                    <RadioGroup
                      aria-label="preAuthRequired"
                      name="preAuthRequired"
                      value={preAuthRequired}
                      onChange={onRadioButtonChange("preAuthRequired")}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio color="primary" size="small" />}
                        label={
                          <Typography
                            component="p"
                            style={{
                              fontSize: 14,
                              color: "#2A2D39",
                            }}
                          >
                            Yes
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio color="primary" size="small" />}
                        label={
                          <Typography
                            component="p"
                            style={{
                              fontSize: 14,
                              color: "#2A2D39",
                            }}
                          >
                            No
                          </Typography>
                        }
                      />
                    </RadioGroup>
                    {preAuthRequiredError && (
                      <Typography component="p" className={classes.helperText}>
                        Please select an option
                      </Typography>
                    )}
                  </Grid>
                  <Grid item sm={12}>
                    <Typography component="p" className={classes.insuranceSubtitle}>
                      Primary Insurance
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl margin="none" style={{ width: 250 }}>
                      <FormLabel component="legend" className={classes.insuranceFieldLabel}>
                        Company*
                      </FormLabel>
                      <TextField
                        id="insuranceId"
                        name="insuranceId"
                        autoComplete="no"
                        onChange={onChangeInsuranceProvider("insuranceId")}
                        value={insuranceId}
                        select
                        SelectProps={{
                          required: true,
                        }}
                        onFocus={() => setError("insuranceIdError", false)}
                        onBlur={() =>
                          insuranceId
                            ? setError("insuranceIdError", false)
                            : setError("insuranceIdError", true)
                        }
                        helperText={insuranceIdError ? "Please select an insurer" : null}
                      >
                        <MenuItem value="">Select Insurance Company</MenuItem>
                        {insuranceProvidersList?.map((provider) => {
                          return (
                            <MenuItem key={provider.id} value={provider.id}>
                              {provider.name}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} align="left">
                    <FormControl margin="none" style={{ width: 250 }}>
                      <FormLabel component="legend" className={classes.insuranceFieldLabel}>
                        Plan*
                      </FormLabel>
                      <TextField
                        id="insurancePlanId"
                        name="insurancePlanId"
                        autoComplete="no"
                        onChange={onChange("insurancePlanId")}
                        value={insurancePlanId}
                        disabled={!insuranceId}
                        select
                        SelectProps={{
                          required: true,
                        }}
                        onFocus={() => setError("insurancePlanIdError", false)}
                        onBlur={() =>
                          insurancePlanId
                            ? setError("insurancePlanIdError", false)
                            : setError("insurancePlanIdError", true)
                        }
                        helperText={insurancePlanIdError ? "Please select an insurance plan" : null}
                      >
                        <MenuItem value="">Select Insurance Plan</MenuItem>
                        {primaryInsurancePlans?.map((plan) => {
                          return (
                            <MenuItem key={plan.id} value={plan.id}>
                              {plan.name}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </FormControl>
                  </Grid>
                  <Grid item sm={12}>
                    <FormControl margin="none" style={{ width: 250 }}>
                      <FormLabel component="legend" className={classes.insuranceFieldLabel}>
                        Member ID*
                      </FormLabel>

                      <TextField
                        id="insuranceMemberId"
                        name="insuranceMemberId"
                        autoComplete="off"
                        value={insuranceMemberId}
                        onChange={onChange("insuranceMemberId")}
                        size="small"
                        placeholder="member id"
                        onFocus={() => setError("insuranceMemberIdError", false)}
                        onBlur={() =>
                          insuranceMemberId
                            ? setError("insuranceMemberIdError", false)
                            : setError("insuranceMemberIdError", true)
                        }
                        helperText={insuranceMemberIdError ? "Please enter the Member ID" : null}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={12}>
                    <FormControl margin="none" style={{ width: 250 }}>
                      <FormLabel component="legend" className={classes.insuranceFieldLabel}>
                        Group #
                      </FormLabel>

                      <TextField
                        id="insuranceGroupNumber"
                        name="insuranceGroupNumber"
                        autoComplete="off"
                        value={insuranceGroupNumber}
                        onChange={onChange("insuranceGroupNumber")}
                        size="small"
                        placeholder="group number"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={12}>
                    <FormControl margin="none" style={{ width: 250 }}>
                      <FormLabel component="legend" className={classes.insuranceFieldLabel}>
                        Name of Insurance Holder*
                      </FormLabel>

                      <TextField
                        id="insuranceHolderName"
                        name="insuranceHolderName"
                        autoComplete="off"
                        value={insuranceHolderName}
                        onChange={onChange("insuranceHolderName")}
                        size="small"
                        placeholder="First name last name"
                        onFocus={() => setError("insuranceHolderNameError", false)}
                        onBlur={() =>
                          insuranceHolderName
                            ? setError("insuranceHolderNameError", false)
                            : setError("insuranceHolderNameError", true)
                        }
                        helperText={insuranceHolderNameError ? "Please enter a name" : null}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={12}>
                    <FormControl margin="none" style={{ width: 250 }}>
                      <FormLabel component="legend" className={classes.insuranceFieldLabel}>
                        DOB*
                      </FormLabel>

                      <ANDatePicker
                        value={insuranceHolderDOB ? new Date(insuranceHolderDOB) : null}
                        onChange={(date) => handleDateChange(date, "insuranceHolderDOB")}
                        format="P" //"MM/dd/yyyy"
                        disableFuture
                        size="medium"
                        placeholder="MM/DD/YYYY"
                        slots={{ textField: CustomInput }}
                        onFocus={() => setError("insuranceHolderDOBError", false)}
                        onBlur={() =>
                          insuranceHolderDOB && insuranceHolderDOBError
                            ? setError("insuranceHOlderDOBError", false)
                            : null
                        }
                        helperText={insuranceHolderDOBError ? "Please enter date of birth" : null}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={12}>
                    <FormControl margin="none" style={{ width: 250 }}>
                      <FormLabel component="legend" className={classes.insuranceFieldLabel}>
                        Relationship of Client to Holder*
                      </FormLabel>
                      <TextField
                        id="insuranceHolderRelationship"
                        name="insuranceHolderRelationship"
                        value={insuranceHolderRelationship}
                        onChange={onChange("insuranceHolderRelationship")}
                        fullWidth
                        size="medium"
                        select
                        onFocus={() => setError("insuranceHolderRelationshipError", false)}
                        onBlur={() =>
                          insuranceHolderRelationship
                            ? setError("insuranceHolderRelationshipError", false)
                            : setError("insuranceHolderRelationshipError", true)
                        }
                        helperText={
                          insuranceHolderRelationshipError ? "Please select an option" : null
                        }
                      >
                        <MenuItem value="" disabled>
                          relationship
                        </MenuItem>
                        <MenuItem value={"Child"}>Child</MenuItem>
                        <MenuItem value={"Self"}>Self</MenuItem>
                        <MenuItem value={"Other"}>Other</MenuItem>
                      </TextField>
                    </FormControl>
                  </Grid>
                </>
              )}
              {hasSecondaryInsurance ? (
                <>
                  <Grid item sm={12}>
                    <Typography component="p" className={classes.insuranceSubtitle}>
                      Secondary Insurance
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl margin="none" style={{ width: 250 }}>
                      <FormLabel component="legend" className={classes.insuranceFieldLabel}>
                        Company*
                      </FormLabel>
                      <TextField
                        id="secondaryInsuranceId"
                        name="secondaryInsuranceId"
                        autoComplete="no"
                        onChange={onChangeInsuranceProvider("secondaryInsuranceId")}
                        value={secondaryInsuranceId}
                        select
                        SelectProps={{
                          required: true,
                        }}
                        onFocus={() => setError("secondaryInsuranceIdError", false)}
                        onBlur={() =>
                          secondaryInsuranceId
                            ? setError("secondaryInsuranceIdError", false)
                            : setError("secondaryInsuranceIdError", true)
                        }
                        helperText={secondaryInsuranceIdError ? "Please select an insurer" : null}
                      >
                        <MenuItem value="">Select Insurance Company</MenuItem>
                        {insuranceProvidersList?.map((provider) => {
                          return (
                            <MenuItem key={provider.id} value={provider.id}>
                              {provider.name}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} align="left">
                    <FormControl margin="none" style={{ width: 250 }}>
                      <FormLabel component="legend" className={classes.insuranceFieldLabel}>
                        Plan*
                      </FormLabel>
                      <TextField
                        id="secondaryInsurancePlanId"
                        name="secondaryInsurancePlanId"
                        autoComplete="no"
                        onChange={onChange("secondaryInsurancePlanId")}
                        value={secondaryInsurancePlanId}
                        disabled={!secondaryInsuranceId}
                        select
                        SelectProps={{
                          required: true,
                        }}
                        onFocus={() => setError("secondaryInsurancePlanIdError", false)}
                        onBlur={() =>
                          secondaryInsurancePlanId
                            ? setError("secondaryInsurancePlanIdError", false)
                            : setError("secondaryInsurancePlanIdError", true)
                        }
                        helperText={
                          secondaryInsurancePlanIdError ? "Please select an insurance plan" : null
                        }
                      >
                        <MenuItem value="">Select Insurance Plan</MenuItem>
                        {secondaryInsurancePlans?.map((plan) => {
                          return (
                            <MenuItem key={plan.id} value={plan.id}>
                              {plan.name}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </FormControl>
                  </Grid>
                  <Grid item sm={12}>
                    <FormControl margin="none" style={{ width: 250 }}>
                      <FormLabel component="legend" className={classes.insuranceFieldLabel}>
                        Member ID*
                      </FormLabel>

                      <TextField
                        id="secondaryInsuranceMemberId"
                        name="secondaryInsuranceMemberId"
                        autoComplete="off"
                        value={secondaryInsuranceMemberId}
                        onChange={onChange("secondaryInsuranceMemberId")}
                        size="small"
                        placeholder="member id"
                        onFocus={() => setError("secondaryInsuranceMemberIdError", false)}
                        onBlur={() =>
                          secondaryInsuranceMemberId
                            ? setError("secondaryInsuranceMemberIdError", false)
                            : setError("secondaryInsuranceMemberIdError", true)
                        }
                        helperText={
                          secondaryInsuranceMemberIdError ? "Please enter the Member ID" : null
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={12}>
                    <FormControl margin="none" style={{ width: 250 }}>
                      <FormLabel component="legend" className={classes.insuranceFieldLabel}>
                        Group #
                      </FormLabel>

                      <TextField
                        id="secondaryInsuranceGroupNumber"
                        name="secondaryInsuranceGroupNumber"
                        autoComplete="off"
                        value={secondaryInsuranceGroupNumber}
                        onChange={onChange("secondaryInsuranceGroupNumber")}
                        size="small"
                        placeholder="group number"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={12}>
                    <FormControl margin="none" style={{ width: 250 }}>
                      <FormLabel component="legend" className={classes.insuranceFieldLabel}>
                        Name of Insurance Holder*
                      </FormLabel>

                      <TextField
                        id="secondaryInsuranceHolderName"
                        name="secondaryInsuranceHolderName"
                        autoComplete="off"
                        value={secondaryInsuranceHolderName}
                        onChange={onChange("secondaryInsuranceHolderName")}
                        size="small"
                        placeholder="First name last name"
                        onFocus={() => setError("secondaryInsuranceHolderNameError", false)}
                        onBlur={() =>
                          secondaryInsuranceHolderName
                            ? setError("secondaryInsuranceHolderNameError", false)
                            : setError("secondaryInsuranceHolderNameError", true)
                        }
                        helperText={
                          secondaryInsuranceHolderNameError ? "Please enter a name" : null
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={12}>
                    <FormControl margin="none" style={{ width: 250 }}>
                      <FormLabel component="legend" className={classes.insuranceFieldLabel}>
                        DOB*
                      </FormLabel>

                      <ANDatePicker
                        value={
                          secondaryInsuranceHolderDOB ? new Date(secondaryInsuranceHolderDOB) : null
                        }
                        onChange={(date) => handleDateChange(date, "secondaryInsuranceHolderDOB")}
                        format="P" //"MM/dd/yyyy"
                        disableFuture
                        size="medium"
                        placeholder="MM/DD/YYYY"
                        slots={{ textField: CustomInput }}
                        onFocus={() => setError("secondaryInsuranceHolderDOBError", false)}
                        onBlur={() =>
                          secondaryInsuranceHolderDOB && secondaryInsuranceHolderDOBError
                            ? setError("secondaryInsuranceHOlderDOBError", false)
                            : null
                        }
                        helperText={
                          secondaryInsuranceHolderDOBError ? "Please enter date of birth" : null
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={12}>
                    <FormControl margin="none" style={{ width: 250 }}>
                      <FormLabel component="legend" className={classes.insuranceFieldLabel}>
                        Relationship of Client to Holder*
                      </FormLabel>
                      <TextField
                        id="secondaryInsuranceHolderRelationship"
                        name="secondaryInsuranceHolderRelationship"
                        value={secondaryInsuranceHolderRelationship}
                        onChange={onChange("secondaryInsuranceHolderRelationship")}
                        fullWidth
                        size="medium"
                        select
                        onFocus={() => setError("secondaryInsuranceHolderRelationshipError", false)}
                        onBlur={() =>
                          secondaryInsuranceHolderRelationship
                            ? setError("secondaryInsuranceHolderRelationshipError", false)
                            : setError("secondaryInsuranceHolderRelationshipError", true)
                        }
                        helperText={
                          secondaryInsuranceHolderRelationshipError
                            ? "Please select an option"
                            : null
                        }
                      >
                        <MenuItem value="" disabled>
                          relationship
                        </MenuItem>
                        <MenuItem value={"Child"}>Child</MenuItem>
                        <MenuItem value={"Self"}>Self</MenuItem>
                        <MenuItem value={"Other"}>Other</MenuItem>
                      </TextField>
                    </FormControl>
                  </Grid>

                  <Grid item sm={12}>
                    <Button
                      variant="text"
                      color="default"
                      size="small"
                      onClick={onToggleHasSecondaryInsurance}
                    >
                      Remove Secondary Insurance
                    </Button>
                  </Grid>
                </>
              ) : (
                <>
                  {!privatePay && (
                    <Grid item sm={12}>
                      <Button
                        variant="text"
                        size="small"
                        startIcon={<AddIcon style={{ fontSize: 15 }} />}
                        onClick={onToggleHasSecondaryInsurance}
                      >
                        Secondary Insurance
                      </Button>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={physiciansOpen}
          onChange={() => onToggleSectionOpen("physiciansOpen", !physiciansOpen)}
          elevation={0}
          classes={{ root: classes.accordionRoot }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ fontSize: 35, fill: "#2A2D39" }} />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className={classes.intakeAccordionSummary}
            classes={{ content: classes.intakeAccordionSummaryContent }}
          >
            <Typography className={classes.heading}>Physicians</Typography>
            <Typography className={classes.secondaryHeading}>Part 5 of 5</Typography>
          </AccordionSummary>
          <AccordionDetails
            className={classes.intakeAccordionDetailsFamily}
            style={{ paddingTop: 30 }}
          >
            <Grid container item xs={12} spacing={5} className={classes.profileInfoContainer}>
              {physicians &&
                physicians.map((physician) => {
                  return (
                    <Physician
                      key={physician.id}
                      classes={classes}
                      physician={physician}
                      onTogglePhysicianMenu={onTogglePhysicianMenu}
                      onSelectEditPhysician={onSelectEditPhysician}
                      onSelectRemovePhysician={onSelectRemovePhysician}
                    />
                  );
                })}
              <Grid item sm={12}>
                <Button
                  variant="text"
                  startIcon={<AddIcon style={{ fontSize: 15 }} />}
                  onClick={() => {
                    setPhysicianSelected(null);
                    onTogglePhysicianDrawer();
                  }}
                >
                  Add Physician
                </Button>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </div>
      <Grid
        container
        item
        xs={12}
        sm={12}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: 15,
        }}
      >
        <div style={{ width: 175, marginLeft: 200 }} />
        {createUserError && (
          <Typography className={classes.errorMessage}>{createUserError}</Typography>
        )}
        <div className={classes.intakeFormButtonsContainer}>
          <LoadingButton
            variant="text"
            onClick={onSaveCustomerForLater}
            loading={savingForLater}
            disabled={savingForLaterSuccess}
            className={classes.buttonContainer}
          >
            Save for Later
          </LoadingButton>
          <Button className={classes.buttonContainer} onClick={onSubmit}>
            Save and Invite
          </Button>
        </div>
      </Grid>
      <AddFamilyDrawer
        {...props}
        familyMember={familyDrawerOpen ? props.addFamilyMember : props.updateFamilyMember}
      />
      <AddPhysicianDrawer
        physicianDrawerOpen={physicianDrawerOpen}
        onTogglePhysicianDrawer={onTogglePhysicianDrawer}
        onSelectPhysician={onSelectPhysician}
        CustomInput={CustomInput}
        physicianSelected={physicianSelected}
        setPhysicianSelected={setPhysicianSelected}
      />
    </div>
  );
};

export default IntakeForm;

{
  /* <Grid item xs={12} sm={6}>
                    <Typography>Relationship to Client</Typography>
                    <TextField
select
                        id="familyRelationship"
                        onChange={onChange("familyRelationship")}
                        value={familyRelationship}
                        variant="outlined"
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                    >
                        <MenuItem disabled value={""} />
                        <MenuItem value="caregiver">Caregiver</MenuItem>
                        <MenuItem value="child">Child</MenuItem>
                        <MenuItem value="dependent">Dependent</MenuItem>
                        <MenuItem value="family_member">Family Member</MenuItem>
                        <MenuItem value="legal_guardian">Legal Guardian</MenuItem>
                        <MenuItem value="is_parent">Parent</MenuItem>
                        <MenuItem value="spouse">Spouse</MenuItem>
                    </TextField>
                </Grid> */
}

// contact type == "adult" or "minor"
