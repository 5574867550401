import { useState, useEffect, useRef } from "react";

/**
 * Custom hook for managing video player controls and state.
 *
 * @param {Object} playerRef - Reference to the video player instance.
 * @param {Object} videoContainerRef - Reference to the video container element.
 * @returns {Object} The video player control functions and states.
 */
const useVideoPlayerControls = (playerRef, videoContainerRef) => {
  const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(false);
  const [volume, setVolume] = useState(0.8);
  const [played, setPlayed] = useState(0);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [anchorElSpeed, setAnchorElSpeed] = useState(null);
  const [anchorElQuality, setAnchorElQuality] = useState(null);
  const [anchorElVolume, setAnchorElVolume] = useState(null);
  const [controlsVisible, setControlsVisible] = useState(true);
  const [quality, setQuality] = useState("720"); // Default quality
  const visibilityTimeout = useRef(null);

  // Quality resolutions map
  const qualityResolutions = {
    360: "640x360",
    540: "960x540",
    720: "1280x720",
  };

  const [formattedResolution, setFormattedResolution] = useState(qualityResolutions[quality]);

  // Update formattedResolution whenever quality changes
  useEffect(() => {
    setFormattedResolution(qualityResolutions[quality]);
  }, [quality]);

  // Handle fullscreen change
  useEffect(() => {
    const handleFullscreenChange = () => {
      const isFullscreen = document.fullscreenElement !== null;
      setIsFullscreen(isFullscreen);
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  // Toggle play/pause state
  const handlePlayPause = () => setPlaying((prev) => !prev);

  // Toggle mute state
  const handleMute = () => setMuted((prev) => !prev);

  // Change volume
  const handleVolumeChange = (e, newValue) => setVolume(newValue);

  // Change seek position
  const handleSeekChange = (e, newValue) => {
    if (playerRef.current) {
      playerRef.current.seekTo(newValue);
      setPlayed(newValue);
    }
  };

  // Open speed menu
  const handleSpeedMenu = (event) => setAnchorElSpeed(event.currentTarget);

  // Close speed menu
  const handleSpeedClose = () => setAnchorElSpeed(null);

  // Open quality menu
  const handleQualityMenu = (event) => setAnchorElQuality(event.currentTarget);

  // Close quality menu
  const handleQualityClose = () => setAnchorElQuality(null);

  // Open volume menu
  const handleVolumeMenu = (event) => setAnchorElVolume(event.currentTarget);

  // Close volume menu
  const handleVolumeClose = () => setAnchorElVolume(null);

  // Change playback rate
  const handlePlaybackRateChange = (rate) => {
    setPlaybackRate(rate);
    handleSpeedClose();
  };

  // Change video quality
  const handleQualityChange = (newQuality) => {
    setQuality(newQuality);
    handleQualityClose();
  };

  // Toggle fullscreen mode
  const toggleFullscreen = () => {
    if (!isFullscreen) {
      if (videoContainerRef.current) {
        videoContainerRef.current.requestFullscreen();
      }
    } else {
      document.exitFullscreen();
    }
  };

  // Handle controls visibility
  const handleControlsVisibility = (isVisible) => {
    setControlsVisible(isVisible);

    if (isVisible) {
      if (visibilityTimeout.current) {
        clearTimeout(visibilityTimeout.current);
      }

      visibilityTimeout.current = setTimeout(() => {
        setControlsVisible(false);
      }, 2000);
    }
  };

  // Show controls on mouse move
  useEffect(() => {
    const handleMouseMove = () => {
      handleControlsVisibility(true);
    };

    if (videoContainerRef.current) {
      videoContainerRef.current.addEventListener("mousemove", handleMouseMove);
    }

    return () => {
      if (visibilityTimeout.current) {
        clearTimeout(visibilityTimeout.current);
      }

      if (videoContainerRef.current) {
        videoContainerRef.current.removeEventListener("mousemove", handleMouseMove);
      }
    };
  }, [videoContainerRef]);

  return {
    playing,
    muted,
    volume,
    played,
    playbackRate,
    anchorElSpeed,
    anchorElQuality,
    anchorElVolume,
    quality,
    formattedResolution,
    handlePlayPause,
    handleMute,
    handleVolumeChange,
    handleSeekChange,
    handleSpeedMenu,
    handleSpeedClose,
    handleQualityMenu,
    handleQualityClose,
    handleVolumeMenu,
    handleVolumeClose,
    handlePlaybackRateChange,
    handleQualityChange,
    toggleFullscreen,
    isFullscreen,
    controlsVisible,
    handleControlsVisibility,
  };
};

export default useVideoPlayerControls;
