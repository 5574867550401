import React from "react";
import Modal from "elements/Modal";

const InviteClinicianDialog = (props) => {
  const { inviteClinicianOpen, onSubmitInviteClinician, handleToggleDialog } = props;
  return (
    <Modal
      title="Invite Clinician"
      open={inviteClinicianOpen}
      onClose={handleToggleDialog("inviteClinicianOpen")}
      description="Please confirm that you would like to invite this clinician to the portal to complete their new-hire documentation."
      primaryActionText="Submit"
      primaryActionOnClick={onSubmitInviteClinician}
      secondaryActionText="Cancel"
      secondaryActionOnClick={handleToggleDialog("inviteClinicianOpen")}
    />
  );
};

export default InviteClinicianDialog;
