import React from "react";
import { Chip, Link } from "@mui/material";
import { format } from "date-fns";

export const getColumnDefinitions = (handleEdit = () => {}, canModifyInsurance, theme) => {
  const STATUS = {
    UNSUBMITTED: "unsubmitted",
    SUBMITTED: "submitted",
    CREDENTIALED: "credentialed",
    EXPIRED: "expired",
  };

  const mapStatusToColor = (status) => {
    const map = {
      [STATUS.SUBMITTED]: "default",
      [STATUS.CREDENTIALED]: "success",
      [STATUS.EXPIRED]: "error",
    };
    return map[status];
  };

  const DateValueGetter = ({ value }) => (value ? format(new Date(value), "P") : null);

  const statusCompare = (v1, v2) => {
    const { UNSUBMITTED, SUBMITTED, CREDENTIALED, EXPIRED } = STATUS;
    const sortMap = [UNSUBMITTED, SUBMITTED, CREDENTIALED, EXPIRED];
    return String(sortMap.indexOf(v1)).localeCompare(String(sortMap.indexOf(v2)));
  };

  const globalColumnAttributes = {
    headerClassName: "header",
    flex: 1,
    renderCell: ({ value }) => value ?? "-",
    sortable: false,
  };

  let columnDefinitions = [
    {
      field: "insuranceCompanyLink",
      headerName: "Insurance Company",
      cellClassName: "insuranceCompanyLink",
      sortable: true,
      renderCell: ({ value }) => {
        return (
          (
            <Link
              underline="hover"
              className="insuranceCompanyLink"
              sx={{ pointerEvents: canModifyInsurance ? "auto" : "none" }}
              href={canModifyInsurance ? value.link : ""}
            >
              {value.label}
            </Link>
          ) ?? "-"
        );
      },
    },
    {
      field: "insurancePlanLink",
      headerName: "Insurance Plan",
      cellClassName: "insurancePlanLink",
      renderCell: ({ value }) => {
        return (
          (
            <Link
              underline="hover"
              className="insurancePlanLink"
              sx={{ pointerEvents: canModifyInsurance ? "auto" : "none" }}
              href={canModifyInsurance ? value.link : ""}
            >
              {value.label}
            </Link>
          ) ?? "-"
        );
      },
    },
    {
      field: "submittedDate",
      headerName: "Submitted Date",
      valueGetter: DateValueGetter,
    },
    {
      field: "initialCredentialedDate",
      headerName: "Initial Credentialed Date",
      valueGetter: DateValueGetter,
    },
    {
      field: "approvalDate",
      headerName: "Approval Date",
      valueGetter: DateValueGetter,
    },
    {
      field: "reCredentialedDate",
      headerName: "Recredentialed Date",
      valueGetter: DateValueGetter,
    },
    {
      field: "termDate",
      headerName: "Term Date",
      valueGetter: DateValueGetter,
    },
    {
      field: "status",
      headerName: "Status",
      sortable: true,
      sortComparator: statusCompare,
      renderCell: ({ value }) => (
        <Chip color={mapStatusToColor(value)} variant="outlined" size="small" label={value} />
      ),
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      disableMenu: true,
      renderCell: (params) => {
        return (
          <Link
            color="primary"
            variant="body2"
            underline="hover"
            onClick={() => handleEdit(params)}
            sx={{ "&:hover": { cursor: "pointer" } }}
          >
            Edit
          </Link>
        );
      },
    },
  ];

  columnDefinitions = columnDefinitions.map((colAttr) => ({
    ...globalColumnAttributes,
    ...colAttr,
  }));

  return columnDefinitions;
};

export default getColumnDefinitions();
