import React, { useEffect, useRef, useState } from "react";
import { Views } from "react-big-calendar";
import Toolbar from "react-big-calendar/lib/Toolbar";
import moment from "moment";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { createPortal } from "react-dom";
import { useSelector } from "react-redux";
import { isPast } from "date-fns";

import "./styles/calendar.css";
import useRescheduleVideoCall from "hooks/api/appointments/useRescheduleVideoCall";
import { getClinicianCalendarPrivateBlocks, getClinicianNonWorkingHours } from "selectors";
import ANCalendar from "elements/ANCalendar";
import ANCalendarOverlay from "elements/ANCalendarOverlay";
import { formatRepeatDays } from "components/EventScheduling/EventSchedulingUtils";
import CalendarToolbar from "./CalendarToolbar";
import { createCalendarCallEvents, createPrivateEventList } from "./CalendarUtils";
import useStyles from "components/EventScheduling/FormRendering/styles";
import ConfirmEditEventDialog from "./ConfirmEditEventDialog";

class CustomToolbar extends Toolbar {
  render() {
    const {
      label,
      toggleScheduleUtilityDrawer,
      calendarType,
      handleCalendarType,
      toggleStartDemoCallDialog,
    } = this.props;
    return (
      <CalendarToolbar
        label={label}
        back={() => this.navigate("PREV")}
        next={() => this.navigate("NEXT")}
        toggleScheduleUtilityDrawer={toggleScheduleUtilityDrawer}
        calendarType={calendarType}
        handleCalendarType={handleCalendarType}
        toggleStartDemoCallDialog={toggleStartDemoCallDialog}
      />
    );
  }
}

const CustomTimeIndicator = () => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const theme = useTheme();

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 30000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div>
      <Typography>
        {
          currentTime
            .toLocaleString("en-US", {
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            })
            .split(" ")[0]
        }
      </Typography>
      <div
        style={{
          borderBottom: `1px dashed ${theme.palette.primary.main}`,
          width: "100%",
        }}
      ></div>
    </div>
  );
};

/**
 * CalendarSessions component renders a weekly calendar with events and allows for interaction
 * such as selecting sessions, navigating through weeks, and viewing event details in an overlay.
 * It also supports drag and drop functionality for editing events.
 *
 * @component
 * @param {Object} props - The properties object.
 * @param {Array} props.calendarCalls - List of calendar call events.
 * @param {boolean} props.calendarCallsLoading - Flag to determine if calendar calls are loading.
 * @param {Function} props.setCurrentWeek - Function to set the current week.
 * @param {Function} props.onSelectSession - Function to handle session selection.
 * @param {Date} props.weekStartDate - The start date of the week.
 * @param {Object} props.colorIndexManager - Manager for event color indexing.
 * @param {Function} props.toggleStartDemoCallDialog - Function to toggle the demo call dialog.
 * @param {boolean} props.canEditEvents - Flag to determine if events can be edited.
 * @param {Function} props.toggleScheduleUtilityDrawer - Function to toggle the schedule utility drawer.
 *
 * @returns {JSX.Element} The rendered CalendarSessions component.
 */
const CalendarSessions = (props) => {
  const {
    calendarCalls,
    calendarCallsLoading,
    setCurrentWeek,
    onSelectSession,
    weekStartDate,
    colorIndexManager,
    toggleStartDemoCallDialog,
    canEditEvents,
  } = props;

  const nonWorkingHours = useSelector(getClinicianNonWorkingHours);
  const privateEvents = useSelector(getClinicianCalendarPrivateBlocks);
  const [calendarType, handleCalendarType] = useState(Views.WEEK);
  const [date, setDate] = useState(new Date());
  const prevCalendarTypeRef = useRef();
  // Calendar Overlay state
  const [showOverlay, setShowOverlay] = useState(false);
  const [eventsOfDay, setEventsOfDay] = useState([]);
  const [overlayTitle, setOverlayTitle] = useState("");
  // Confirm Edit Event Dialog state
  const [showConfirmEditEvent, setShowConfirmEditEvent] = useState(false);
  const [currentEditEvent, setCurrentEditEvent] = useState(null);

  // Reschedule Video Call mutation
  const { isRescheduleVideoCallLoading, rescheduleVideoCall } = useRescheduleVideoCall({
    onSuccess: () => {
      // Refresh the calendar to show the updated event
      setCurrentWeek(date, calendarType);
      setShowConfirmEditEvent(false);
    },
  });

  useEffect(() => {
    const prevCalendarType = prevCalendarTypeRef.current;
    if (prevCalendarType !== calendarType && prevCalendarType !== undefined) {
      setCurrentWeek(date, calendarType);
    }
    prevCalendarTypeRef.current = calendarType;
  }, [calendarType]);

  const classes = useStyles();

  useEffect(() => {
    const content = document.querySelector(".rbc-time-content");
    if (content) {
      content.scrollTop = 700;
    }
  }, [weekStartDate]);

  const callList = createCalendarCallEvents(calendarCalls);
  const privateEventsList = createPrivateEventList(privateEvents);
  const allEvents = [...callList, ...privateEventsList];

  const CustomHeader = ({ date }) => {
    return (
      <>
        {calendarType === Views.MONTH ? (
          ""
        ) : (
          <>
            <Typography variant="body2" className="current-day">
              {moment(date).format("ddd")}
            </Typography>
            <Typography variant="subtitle2" className="current-date">
              {moment(date).format("D")}
            </Typography>
          </>
        )}
      </>
    );
  };

  const isOutsideMonth = (dateToCompare, referenceDate) => {
    const dateToCompareMoment = moment(dateToCompare);
    const referenceDateMoment = moment(referenceDate);
    return !dateToCompareMoment.isSame(referenceDateMoment, "month");
  };

  const MonthDayWrapper = ({ value }) => {
    let style = {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "50px",
      width: "100%",
      textAlign: "center",
    };

    if (isOutsideMonth(value, date)) {
      style = {
        ...style,
        color: "gray",
      };
    }
    return (
      <div style={style}>
        <Typography variant="body2" className="current-date">
          {moment(value).format("ddd")}
        </Typography>
        <Typography variant="subtitle2" className="current-date">
          {moment(value).format("DD")}
        </Typography>
      </div>
    );
  };

  const components = {
    dateCellWrapper: MonthDayWrapper,
    toolbar: (toolbarProps) => (
      <CustomToolbar
        {...toolbarProps}
        toggleScheduleUtilityDrawer={props.toggleScheduleUtilityDrawer}
        calendarType={calendarType}
        handleCalendarType={handleCalendarType}
        toggleStartDemoCallDialog={toggleStartDemoCallDialog}
      />
    ),
    header: CustomHeader,
  };

  const currentTimeIndicatorEl = document.querySelector(".rbc-current-time-indicator");

  // Update Event handlers
  const handleOpenEditEventDialog = (event) => {
    setShowConfirmEditEvent(true);
    setCurrentEditEvent(event);
  };

  const handleCloseEditEventDialog = () => {
    if (isRescheduleVideoCallLoading) return;

    setShowConfirmEditEvent(false);
  };

  // only allow dragging if the event is not a past event or a google event
  const draggableAccessor = (e) => !e.googleEvent && !isPast(e.start);

  const handleSaveEvent = () => {
    const sharedPayload = {
      startDate: currentEditEvent.start,
      endDate: currentEditEvent.end,
      clinicianUserId:
        currentEditEvent.primary_clinician_user_id || currentEditEvent.clinician_user_id,
      secondaryClinicianUserId: currentEditEvent.secondary_clinician_user_id,
      isRepeating: currentEditEvent.is_recurring,
      repeatDays: formatRepeatDays(currentEditEvent.repeat_days),
      repeatEndType: currentEditEvent.repeat_type,
      repeatUntilDate: currentEditEvent.repeat_until_date,
      overrideSchedule: true,
      timeInterval: currentEditEvent.time_interval,
      timeUnit: currentEditEvent.time_unit,
      timezone: currentEditEvent.call_timezone,
      memberId: currentEditEvent.user_id,
      isTentative: currentEditEvent.is_tentative,
      rescheduleAllInstances: false,
    };

    let payload = {};

    if (currentEditEvent.is_video_call) {
      payload = {
        ...sharedPayload,
        videoId: currentEditEvent.id,
        offPlatform: currentEditEvent.off_platform,
        billingType: currentEditEvent.billing_type,
      };
    } else {
      payload = {
        ...sharedPayload,
        id: currentEditEvent.id,
        override: true,
        serviceType: currentEditEvent.service_type,
      };
    }

    rescheduleVideoCall(payload);
  };

  return (
    <>
      <ANCalendar
        popup
        dragAndDrop={canEditEvents}
        reinitialize={!showConfirmEditEvent && !calendarCallsLoading}
        defaultDate={weekStartDate ? new Date(weekStartDate) : new Date()}
        events={allEvents}
        view={calendarType}
        date={date}
        views={[Views.WEEK, Views.DAY, Views.MONTH]}
        onView={(view) => handleCalendarType(view)}
        components={components}
        onSelectEvent={(e) => onSelectSession(e)}
        className={classes.calendarTheme}
        colorIndexManager={colorIndexManager}
        nonWorkingHours={nonWorkingHours}
        onChangeEvent={handleOpenEditEventDialog}
        draggableAccessor={draggableAccessor}
        onNavigate={(date) => {
          setDate(date);
          setCurrentWeek(date, calendarType ?? Views.WEEK);
        }}
        onShowMore={(events, date) => {
          setEventsOfDay(events);
          setShowOverlay(true);
          setOverlayTitle(moment(date).format("dddd MMM Do"));
        }}
      />
      {currentTimeIndicatorEl && createPortal(<CustomTimeIndicator />, currentTimeIndicatorEl)}

      <ANCalendarOverlay
        className={classes.calendarTheme}
        events={eventsOfDay}
        title={overlayTitle}
        open={showOverlay}
        colorIndexManager={colorIndexManager}
        onClose={() => setShowOverlay(false)}
        onSelectEvent={onSelectSession}
      />

      <ConfirmEditEventDialog
        open={showConfirmEditEvent}
        event={currentEditEvent}
        isLoading={isRescheduleVideoCallLoading || calendarCallsLoading}
        onClose={handleCloseEditEventDialog}
        onSaveEvent={handleSaveEvent}
      />
    </>
  );
};

export default CalendarSessions;
