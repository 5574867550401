export const styles = (theme) => ({
  dialogContent: {
    height: "fit-content",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    maxHeight: "95%",
    width: "100%",
    color: "#404040",
  },
  dialogPaperWidthLg: {
    maxWidth: 1400,
  },
  needsContainer: {
    width: "100%",
    height: "fit-content",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  titleContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "20px 0",
    "& h4": {
      fontSize: 22,
      fontWeight: 500,
      color: "#1C0D28",
    },
    "& span": {
      margin: "0 15px",
    },
    "& p": {
      fontSize: 16,
      fontWeight: "500",
      color: "#3F4456",
      lineHeight: "21px",
    },
  },
  tableRoot: {
    borderCollapse: "separate",
    marginBottom: 20,
    "& tr > :first-child": {
      paddingLeft: 37,
    },
    "& tr > :last-child": {
      paddingRight: 42,
    },
    "& td": {
      border: "none",
    },
  },
  clientNameButton: {
    "& span": {
      overflowWrap: "break-word",
    },
  },
  criticalClientsTable: {
    width: "100%",
    border: "2px solid #F87171",
    borderRadius: 8,
  },
  warningClientsTable: {
    width: "100%",
    border: "2px solid #D9903C",
    borderRadius: 8,
  },
  criticalTableColumnTitle: {
    fontSize: 20,
    fontWeight: 500,
    color: "#2A2D39",
    borderBottom: "2px solid #F87171",
  },
  warningTableColumnTitle: {
    fontSize: 20,
    fontWeight: 500,
    color: "#2A2D39",
    borderBottom: "2px solid #D9903C",
  },
  clientNameButton: {
    padding: 0,
    paddingLeft: 5,
    marginLeft: "-5px",
    "& span": {
      justifyContent: "flex-start",
    },
  },
  closeIcon: {
    position: "absolute",
    top: 20,
    right: 25,
  },
  snoozeForm: {
    width: "calc(100% - 20px)",
    margin: 10,
    padding: "15px 22px",
  },
  snoozeButtonContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-around",
    padding: "20px 0 20px 30px",
  },
  snoozeButton: {
    "& span": {
      fontWeight: 400,
    },
  },
  snoozeFormTable: {
    width: "100%",
    border: "1px solid #606782",
    boxShadow: "inset 0px 0px 10px 1px rgba(102, 102, 102, 0.15)",
    borderRadius: 4,
  },
  snoozeTableRoot: {
    borderCollapse: "separate",
    "& tr > :first-child": {
      paddingLeft: 27,
    },
    "& tr > :last-child": {
      paddingRight: 15,
    },
    "& td": {
      border: "none",
    },
  },
  snoozeTableCellRoot: {
    padding: 10,
  },
  lengthOfTimeSelect: {
    padding: "0 15px 0 0",
  },
  snoozeReasonGrid: {
    padding: "0 !important",
  },
  errorText: {
    fontSize: 14,
    textAlign: "right",
    fontWeight: "500",
    color: "#DC2626",
  },
});
