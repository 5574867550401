import React from "react";
import { DialogContent } from "@mui/material";
import RequestedEdits from "./Tools/RequestedEdits";
import SessionDetails from "./SessionDetails";
import Divider from "elements/Divider";
import NoteDetails from "./NoteDetails";

const ViewSessionNote = (props) => {
  const { classes, needsEdits, videoId, requestedEdits, associatedSessionNotes, currentIndex } =
    props;
  const currentBillingItem = associatedSessionNotes[currentIndex];
  return (
    <DialogContent style={{ overflow: "auto", padding: "24px" }}>
      <div className={classes.billableTimeContent}>
        <SessionDetails {...props} />
        {((currentBillingItem?.description && currentBillingItem?.actual_time >= 0) ||
          !videoId) && (
          <>
            <Divider />
            {needsEdits && <RequestedEdits requestedEdits={requestedEdits} />}
            <NoteDetails {...props} />
          </>
        )}
      </div>
    </DialogContent>
  );
};

export default ViewSessionNote;
