import React from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Modal from "elements/Modal";

const RequestApplicationChangesDialog = (props) => {
  const {
    requestChangesOpen,
    onToggleDialog,
    applicationLoading,
    success,
    error,
    changesRequested,
    submitRequestChanges,
    onChange,
  } = props;

  return (
    <Modal
      open={requestChangesOpen}
      onClose={onToggleDialog}
      title="Request Changes"
      titleCentered
      content={
        <div>
          <Typography style={{ marginBottom: 15 }}>
            Please give a description of the changed you are requesting to this clinician's
            application.
          </Typography>
          <TextField
            id="changedRequested"
            name="Changes Requested"
            value={changesRequested}
            label="Changes Requested"
            onChange={onChange("changesRequested")}
            multiline
            rows={5}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </div>
      }
      primaryActionText="Submit"
      primaryActionOnClick={submitRequestChanges}
      primaryActionDisabled={applicationLoading || success || !changesRequested}
      secondaryActionText={success ? "Close" : "Cancel"}
      secondaryActionOnClick={onToggleDialog}
      secondaryActionDisabled={applicationLoading}
      loading={applicationLoading}
      success={success}
      successMessage=" Requested changes sent successfully"
      error={error}
      errorMessage="There was an error submitting this request. Please try again."
    />
  );
};

export default RequestApplicationChangesDialog;
