import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import DetailSection from "elements/DetailSection";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Typography from "@mui/material/Typography";

class PatientProgress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 0,
    };
  }

  handleTabChange = (event, newValue) => {
    const { setBillableTimePeriod } = this.props;
    let period;
    if (newValue === 0) {
      period = "week";
    } else if (newValue === 1) {
      period = "month";
    } else {
      period = "authperiod";
    }
    this.setState({ currentTab: newValue });
    setBillableTimePeriod(period);
  };

  navigateToInsuranceAuthorization = async () => {
    await this.props.history.push(`./insurance`);
    await this.props.scrollToInsuranceAuthorization();
  };

  render() {
    const { currentTab } = this.state;
    const { classes, billableTimeLoading, workflowStatus, billableTime } = this.props;
    return (
      <DetailSection
        title="Patient Progress"
        loading={billableTimeLoading}
        noPadding
        columnDirection
      >
        <div className={classes.callsTabContainer}>
          <Tabs
            value={currentTab}
            onChange={this.handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="patient-progress-tabs"
            classes={{
              root: classes.callsTabsContainer,
              indicator: classes.callsTabIndicator,
            }}
          >
            <Tab label="This Week" disableRipple />
            <Tab label="This Month" disableRipple />
            <Tab label="Auth Period" disableRipple />
          </Tabs>
        </div>
        <div className={classes.container}>
          {billableTime.map((unit, index) => {
            return (
              <div key={index} className={classes.billableTimeContainer}>
                <div className={classes.circularProgressContainer}>
                  <CircularProgressbarWithChildren
                    value={unit.used_units}
                    maxValue={unit.approved_units}
                    styles={buildStyles({
                      pathColor: "#40B89F",
                      trailColor: "#C0F3D9",
                      strokeLinecap: "butt",
                    })}
                  >
                    <Typography component="p" className={classes.progressText}>
                      {unit.used_units / 4} of {unit.approved_units / 4} hours completed
                    </Typography>
                  </CircularProgressbarWithChildren>
                </div>
                <div className={classes.textContainer}>
                  <Typography component="p" className={classes.unitTitle}>
                    {unit.cpt_code}
                  </Typography>
                  <Typography component="p" className={classes.hoursRemaining}>
                    {(unit.approved_units - unit.used_units) / 4 < 0
                      ? 0
                      : (unit.approved_units - unit.used_units) / 4}{" "}
                    hours remaining
                  </Typography>
                </div>
              </div>
            );
          })}
        </div>
      </DetailSection>
    );
  }
}

const styles = (theme) => ({
  container: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    minHeight: 175,
    margin: "30px 0px",
  },
  billableTimeContainer: {
    width: "25%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  circularProgressContainer: {
    width: "60%",
  },
  progressText: {
    fontSize: 10,
    textAlign: "center",
    maxWidth: "75%",
  },
  textContainer: {
    marginTop: 15,
  },
  unitTitle: {
    fontSize: 14,
    fontWeight: 500,
    textAlign: "center",
    marginBottom: 3,
  },
  hoursRemaining: {
    fontSize: 13,
    textAlign: "center",
  },
  callsTabContainer: {
    width: "100%",
  },
  callsTabsContainer: {
    margin: "0px auto",
    borderBottom: "1px solid #545a72",
    "& .MuiButtonBase-root": {
      textTransform: "none",
      fontSize: 19,
      fontWeight: 300,
    },
    "& .MuiTab-textColorPrimary.Mui-selected": {
      fontWeight: 500,
    },
  },
  callsTabIndicator: {
    backgroundColor: "#8e42ca",
  },
  editContainer: {
    width: "55%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
});

export default withStyles(styles)(PatientProgress);
