import { getClientLogs } from "api/customers";
import { useInfiniteQuery } from "react-query";

export const useGetInfiniteClientLogs = (clientId, params) => {
  return useInfiniteQuery(
    ["clientLogs", clientId, params.filters],
    ({ pageParam = 0 }) => getClientLogs(clientId, { ...params, page: pageParam }),
    {
      enabled: !!clientId,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, pages) => {
        const morePagesExist = pages.length * params.size < parseInt(lastPage.data.logsCount);
        return morePagesExist ? pages.length : false;
      },
    }
  );
};
