import { createReducer } from "redux-create-reducer";
import actions from "../actions";
import cookie from "react-cookies";

const initialState = {
  loaded: false,
  username: null,
  email: null,
  id: null,
  first_name: null,
  last_name: null,
  groups: [],
  sendingReferralEmail: false,
  referralEmailSent: false,
  pendingSignature: [],
  // cognito_id: "",
  defaultVideoPlatform: "",
  permissions: {},
  updateUserRolesLoading: false,
  updateUserRolesSuccess: false,
  updateUserRolesFail: false,
  sendingResetPassword: false,
  resetPasswordSent: false,
  searchUserDetails: {},
  searchUserDetailsLoading: false,
  searchUserDetailsSuccess: false,
  toggleUserActivationLoading: false,
  toggleUserActivationSuccess: false,
  intercomHash: null,
};

const reducers = {
  [actions.SET_BASIC_DETAILS](state, { payload }) {
    return {
      ...state,
      ...payload,
      username: payload.email,
    };
  },
  [actions.CLEAR_BASIC_DETAILS](state, { payload }) {
    return { ...initialState };
  },
  [actions.GET_USER_SUCCESS](state, { payload }) {
    const expires = new Date();
    expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 14);
    cookie.save("userId", payload.user.id, { path: "/", expires });
    return {
      ...state,
      ...payload.user,
      loaded: true,
    };
  },
  // [actions.SEND_REFERRAL_EMAIL](state) {
  //   return {
  //     ...state,
  //     sendingReferralEmail: true,
  //   };
  // },
  // [actions.SEND_REFERRAL_EMAIL_SUCCESS](state) {
  //   return {
  //     ...state,
  //     sendingReferralEmail: false,
  //   };
  // },
  [actions.GET_USER_PERMISSIONS](state) {
    return {
      ...state,
      permissions: {},
    };
  },
  [actions.GET_USER_PERMISSIONS_SUCCESS](state, { payload }) {
    return {
      ...state,
      permissions: payload,
    };
  },
  [actions.UPDATE_USER_ROLES](state) {
    return {
      ...state,
      updateUserRolesLoading: true,
      updateUserRolesSuccess: false,
      updateUserRolesFail: false,
    };
  },
  [actions.UPDATE_USER_ROLES_SUCCESS](state) {
    return {
      ...state,
      updateUserRolesLoading: false,
      updateUserRolesSuccess: true,
    };
  },
  [actions.UPDATE_USER_ROLES_FAIL](state) {
    return {
      ...state,
      updateUserRolesLoading: false,
      updateUserRolesSuccess: false,
      updateUserRolesFail: true,
    };
  },
  [actions.CLEAR_UPDATE_USER_ROLES](state) {
    return {
      ...state,
      updateUserRolesLoading: false,
      updateUserRolesSuccess: false,
      updateUserRolesFail: false,
    };
  },
  [actions.GET_INVITATION_SUCCESS](state, { payload }) {
    return {
      ...state,
      username: payload.email,
      email: payload.email,
      id: payload.userId,
    };
  },
  [actions.RESET_USER_PASSWORD](state) {
    return {
      ...state,
      sendingResetPassword: true,
      resetPasswordSent: false,
    };
  },
  [actions.RESET_USER_PASSWORD_SUCCESS](state) {
    return {
      ...state,
      sendingResetPassword: false,
      resetPasswordSent: true,
    };
  },
  [actions.CLEAR_RESET_USER_PASSWORD_INFO](state) {
    return {
      ...state,
      sendingResetPassword: false,
      resetPasswordSent: false,
    };
  },
  [actions.GET_SEARCH_USER_DETAILS](state) {
    return {
      ...state,
      searchUserDetailsLoading: true,
    };
  },
  [actions.GET_SEARCH_USER_DETAILS_SUCCESS](state, { payload }) {
    return {
      ...state,
      searchUserDetails: payload,
      searchUserDetailsLoading: false,
      searchUserDetailsSuccess: true,
    };
  },
  [actions.GET_SEARCH_USER_DETAILS_FAIL](state) {
    return {
      ...state,
      searchUserDetailsLoading: false,
      searchUserDetailsSuccess: false,
    };
  },
  [actions.TOGGLE_USER_ACTIVATION](state) {
    return {
      ...state,
      toggleUserActivationLoading: true,
      toggleUserActivationSuccess: false,
    };
  },
  [actions.TOGGLE_USER_ACTIVATION_SUCCESS](state, { payload }) {
    return {
      ...state,
      toggleUserActivationLoading: false,
      toggleUserActivationSuccess: true,
    };
  },
  [actions.CLEAR_TOGGLE_USER_ACTIVATION](state) {
    return {
      ...state,
      toggleUserActivationLoading: false,
      toggleUserActivationSuccess: false,
    };
  },
  [actions.SIGN_OUT](state) {
    return {
      ...initialState,
    };
  },
  [actions.SET_STATIC_PAGE_STATE_SUCCESS](state, { payload }) {
    return {
      ...state,
      email: payload.email,
      username: payload.email,
    };
  },
};

export default createReducer(initialState, reducers);
