import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

const CallNotEndedDialog = (props) => {
  const { open, notification, onNavToBillingFromCallNotEnded, onCloseCallNotEndedDialog } = props;
  return (
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      hideBackdrop={true}
      PaperProps={{
        style: {
          maxWidth: "90%",
          width: 444,
        },
      }}
    >
      <DialogTitle
        style={{
          fontSize: 20,
          fontWeight: "500",
          color: "#212121",
          letterSpacing: "0.15px",
        }}
      >
        Your last session wasn't closed properly
      </DialogTitle>
      <DialogContent style={{ padding: "0 24px" }}>
        <DialogContentText style={{ fontSize: 16, fontWeight: "400", color: "#212121" }}>
          We closed out the previous session for you. You can find a draft of the session on your
          Billing page.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onCloseCallNotEndedDialog(notification)} style={{ height: 36 }}>
          Close
        </Button>
        <Button
          onClick={() => onNavToBillingFromCallNotEnded(notification)}
          style={{ width: 140, height: 36 }}
        >
          Go To Billing
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CallNotEndedDialog;
