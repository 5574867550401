import React from "react";
import {
  Dialog,
  Typography,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/NotificationsNone";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const ExitAdhocConfirmationDialog = (props) => {
  const {
    classes,
    confirmExitAdhocDialogOpen,
    successToastOpen,
    notesCompleted,
    callBreakdownLoading,
    onToggleExitAdhocDialog,
    onSaveAsDraft,
    onClose,
    noteCompleted,
    setAssociatedTimesLoading,
  } = props;

  return (
    <Dialog
      open={confirmExitAdhocDialogOpen && !successToastOpen && !notesCompleted}
      disableEscapeKeyDown={true}
      PaperProps={{
        style: {
          maxWidth: "95%",
          width: 600,
        },
      }}
    >
      <IconButton onClick={onToggleExitAdhocDialog} className={classes.closeIcon} size="large">
        <CloseIcon style={{ fill: "#3F4456" }} />
      </IconButton>
      <DialogContent>
        <div className={classes.closeDialogContentContainer}>
          <div className={classes.closeDialogIconContainer}>
            <NotificationsIcon size={25} style={{ fill: "#8E42CA" }} />
          </div>
          <div className={classes.closeDialogTitleContainer}>
            <Typography component="h2" className={classes.closeDialogTitle}>
              Are you sure you want to exit?
            </Typography>
            <Typography component="h2" className={classes.closeDialogContent}>
              {noteCompleted
                ? "Changes you made will be lost"
                : "You can exit without saving or save this note as a draft."}
            </Typography>
          </div>
        </div>
      </DialogContent>
      <DialogActions style={{ margin: "0px 15px 15px 0" }}>
        <Button
          onClick={onClose}
          color="secondary"
          disabled={callBreakdownLoading || setAssociatedTimesLoading}
        >
          Exit without saving
        </Button>
        {!noteCompleted && (
          <Button
            onClick={onSaveAsDraft}
            disabled={callBreakdownLoading || setAssociatedTimesLoading}
          >
            {(callBreakdownLoading || setAssociatedTimesLoading) && (
              <CircularProgress style={{ color: "#C0C8D0", marginRight: 8 }} size={16} />
            )}
            Save as draft
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ExitAdhocConfirmationDialog;
