import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

const RemovedFromSessionDialog = (props) => {
  const { open, navToDashboard } = props;
  return (
    <Dialog
      open={open}
      hideBackdrop={true}
      aria-labelledby="form-dialog-title"
      style={{ zIndex: 2010 }}
      PaperProps={{
        style: {
          maxWidth: "90%",
          width: 444,
        },
      }}
    >
      <DialogTitle
        style={{
          fontSize: 20,
          fontWeight: "500",
          color: "#212121",
          letterSpacing: "0.15px",
        }}
      >
        This device has been removed from the session
      </DialogTitle>
      <DialogContent style={{ padding: "0 24px" }}>
        <DialogContentText style={{ fontSize: 16, fontWeight: "400", color: "#212121" }}>
          Another device on your account has joined the session and removed this device.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={navToDashboard}
          style={{ width: 150, height: 36, fontWeight: "500" }}
          color="primary"
        >
          Go To Dashboard
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemovedFromSessionDialog;
