export default (theme) => ({
  mainContainer: {
    width: "90%",
    margin: "auto",
  },
  title: {
    color: "#595959",
    minHeight: 33,
    marginBottom: 25,
  },
  weeklySchedule: {
    width: "42%",
  },
  titleText: {
    color: "#595959",
    marginBottom: 10,
  },
  sessionInfoContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  callDetails: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    marginBottom: 15,
  },
  sessionDate: {
    fontSize: 20,
    color: "#444444",
    fontWeight: 500,
  },
  noWeeklyCalls: {
    fontSize: 18,
    color: "#444444",
    fontWeight: 500,
  },
  sessionTimeContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
  },
  callDuration: {
    fontSize: 13,
    color: "#878787",
    fontWeight: 500,
  },
  manageCallButton: {
    borderRadius: 2,
    height: 30,
    width: 130,
    marginLeft: "auto",
    "& span": {
      fontSize: 13,
    },
  },
  participantsContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  callParticipants: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  clientName: {
    lineHeight: "18px",
    fontSize: 18,
    color: "#595959",
    fontWeight: 500,
  },
  guardianName: {
    fontSize: 12,
    color: "#878787",
    marginTop: 3,
  },
  callTypeContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  callType: {
    fontSize: 14,
    color: "#878787",
  },
  loading: {
    display: "flex",
    width: "100%",
    height: 170,
    justifyContent: "center",
    alignItems: "center",
  },
  roleText: {
    fontSize: 14,
    color: "#737373",
    margin: "3px 0",
    fontWeight: 500,
  },
  missingBillingMessage: {
    width: "100%",
    margin: "20px 20px 40px 10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  missingBillingText: {
    color: "#DC2626",
    // fontSize: 17,
    // fontWeight: 500,
  },
  enterBillingLink: {
    color: "#DC2626",
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer",
    },
  },
  notificationTitle: {
    color: "#b45309",
    // fontWeight: 500,
    fontSize: 22,
    letterSpacing: "-0.03em",
  },
  optionalNotificationTitle: {
    color: "#287979",
    // fontWeight: 500,
    fontSize: 22,
    letterSpacing: "-0.03em",
  },
  notificationSubtitle: {
    color: "#2a2d39",
    // fontWeight: 700,
    // letterSpacing: "-0.03em",
    margin: "15px 0px",
  },
  notificationContent: {
    color: "#3f4456",
    // fontWeight: 500,
    // letterSpacing: "-0.03em",
    // fontSize: 17,
    marginBottom: 20,
    width: "90%",
  },
  notificationButtonContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  scheduleCallButton: {
    "& span": {
      fontWeight: 500,
    },
  },
  successIcon: {
    color: "#047857",
    fontSize: 20,
    marginLeft: "-25px",
  },
  clientNameLink: {
    color: "#2278c2",
    cursor: "pointer",
  },
  snackbar: {
    backgroundColor: "#121212",
    borderRadius: 4,
    color: "#fff",
  },
});
