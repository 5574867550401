import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import DetailSection from "elements/DetailSection";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { CustomTab } from "elements/DEPRECATED_elements";
import { format } from "date-fns";
import { capitalizeBillingTypeFirstLetter } from "../../../utils/capitalizeBillingTypeFirstLetter";
import Divider from "@mui/material/Divider";
import ClientPastCalls from "./ClientPastCalls";
import CircularProgress from "@mui/material/CircularProgress";

import { convertTokens } from "@date-fns/upgrade/v2";
import { checkFeatureAccess } from "api/featureAccess";
import { Calendar } from "@phosphor-icons/react";

class ClientCalls extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 0,
      schedulePageFeatureFlag: false,
    };
  }
  handleTabChange = (event, newValue) => {
    this.setState({ currentTab: newValue });
  };

  componentDidMount() {
    if (this.props?.currentUserId) {
      checkFeatureAccess(this.props?.currentUserId, "scheduling_page").then((includeFeature) =>
        this.setState({ schedulePageFeatureFlag: includeFeature })
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (
      (!prevProps?.currentUserId || prevProps?.currentUserId !== this.props?.currentUserId) &&
      this.props?.currentUserId
    ) {
      checkFeatureAccess(this.props.currentUserId, "scheduling_page").then((includeFeature) =>
        this.setState({ schedulePageFeatureFlag: includeFeature })
      );
    }
  }

  render() {
    const {
      classes,
      videoCalls,
      loading,
      onToggleScheduleClientVideo,
      userId,
      clientId,
      navigate,
      editDisabled,
      userPermissions,
      onToggleStartNewVideoDialog,
      isAssignedClinician,
      onClickCancelSession,
      currentUserId,
      isSecondaryClinician,
      isDirector,
      isSupervisor,
      history,
      clientName,
    } = this.props;
    const { currentTab, schedulePageFeatureFlag } = this.state;
    const canModifyEvent = userPermissions?.select_scheduled_clinician;
    const restrictScheduling = isSecondaryClinician && !isDirector && !isSupervisor;
    return (
      <DetailSection noPadding columnDirection title="Calls">
        <div className={classes.callsTabContainer}>
          <CustomTab
            currentTab={currentTab}
            handleTabChange={this.handleTabChange}
            tabs={["Upcoming Calls", "Past Calls"]}
          >
            {currentTab == 0 && (
              <div className={classes.upcomingCallsContainer}>
                <div className={classes.videoCallContainer}>
                  {videoCalls && videoCalls.length > 0 && !loading ? (
                    videoCalls.map((videoCall) => {
                      const currentUserAssignedToCall =
                        currentUserId === videoCall?.clinician_user_id;
                      return (
                        <div className={classes.videoCall} key={videoCall.id}>
                          <div className={classes.videoCallInfo}>
                            <Typography component="p" className={classes.videoCallDate}>
                              {format(
                                new Date(videoCall.scheduled_date),
                                convertTokens("MMMM Do h:mm a")
                              )}
                            </Typography>
                            <Typography component="p" className={classes.videoCallType}>
                              {videoCall.billing_type
                                ? `${capitalizeBillingTypeFirstLetter(videoCall.billing_type)} Call`
                                : null}
                            </Typography>
                          </div>
                          {userPermissions?.reschedule_call &&
                            (canModifyEvent || currentUserAssignedToCall) && (
                              <div className={classes.buttonContainer}>
                                <Button
                                  variant="text"
                                  className={classes.callButton}
                                  style={{ marginRight: 30 }}
                                  disabled={editDisabled}
                                  onClick={() => {
                                    if (schedulePageFeatureFlag) {
                                      history.push(
                                        `/clinician-schedule/create-event?id=${videoCall.id}`
                                      );
                                    } else {
                                      onToggleScheduleClientVideo(true, videoCall);
                                    }
                                  }}
                                >
                                  Reschedule
                                </Button>
                              </div>
                            )}
                          {userPermissions?.cancel_call &&
                            (canModifyEvent || currentUserAssignedToCall) && (
                              <div className={classes.buttonContainer}>
                                <Button
                                  color="secondary"
                                  variant="text"
                                  className={classes.callButton}
                                  onClick={() => onClickCancelSession(videoCall)}
                                >
                                  Cancel
                                </Button>
                              </div>
                            )}
                        </div>
                      );
                    })
                  ) : loading ? (
                    <div className={classes.loadingContainer}>
                      <CircularProgress size={25} color="primary" style={{ marginBottom: 10 }} />
                      <Typography>Loading</Typography>
                    </div>
                  ) : videoCalls?.length == 0 ? (
                    <div>
                      <Typography
                        component="p"
                        className={classes.noVideoCalls}
                        style={{ marginBottom: 10 }}
                      >
                        No calls scheduled for this client
                      </Typography>
                    </div>
                  ) : null}
                </div>
                <Divider />
                {userPermissions?.schedule_call && !restrictScheduling && (
                  <div className={classes.videoCallContainer}>
                    <div className={classes.scheduleCallContainer}>
                      <Typography component="p" className={classes.noVideoCalls}>
                        Schedule a new call
                      </Typography>
                      {schedulePageFeatureFlag ? (
                        <Button
                          onClick={() =>
                            history.push(`/clinician-schedule/create-event?name=${clientName}`)
                          }
                          disabled={editDisabled}
                          startIcon={<Calendar />}
                        >
                          Create Event
                        </Button>
                      ) : (
                        <>
                          <div className={classes.buttonContainer}>
                            <Button
                              className={classes.callButton}
                              style={{ marginRight: 30 }}
                              onClick={() => onToggleScheduleClientVideo(false)}
                              disabled={editDisabled}
                            >
                              ON-PLATFORM
                            </Button>
                          </div>
                          <div className={classes.buttonContainer}>
                            <Button
                              variant="text"
                              className={classes.callButton}
                              style={{ marginRight: 30 }}
                              onClick={() => onToggleScheduleClientVideo(false, null, true)}
                              disabled={editDisabled}
                            >
                              Off-Platform
                            </Button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                )}
                {isAssignedClinician && !isSecondaryClinician && (
                  <>
                    <Divider />
                    <div className={classes.videoCallContainer}>
                      <div className={classes.scheduleCallContainer}>
                        <Typography component="p" className={classes.noVideoCalls}>
                          Start an instant call now
                        </Typography>
                        <div className={classes.buttonContainer}>
                          <Button
                            color="secondary"
                            className={classes.callButton}
                            onClick={onToggleStartNewVideoDialog}
                            disabled={editDisabled}
                            style={{ marginRight: 30 }}
                          >
                            Start Call
                          </Button>
                        </div>
                        <div className={classes.buttonContainer}>
                          <div />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
            {currentTab == 1 && userPermissions?.view_client_call_schedule && (
              <ClientPastCalls userId={userId} clientId={clientId} navigate={navigate} />
            )}
          </CustomTab>
        </div>
      </DetailSection>
    );
  }
}
export const styles = (theme) => ({
  callsTabContainer: {
    width: "100%",
  },
  upcomingCallsContainer: {
    width: "100%",
  },
  videoCallContainer: {
    padding: "25px 0px 25px 40px",
    "& :last-child": {
      marginBottom: 0,
    },
  },
  videoCall: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    marginBottom: 25,
  },
  videoCallInfo: {
    width: "33%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  videoCallDate: {
    fontSize: 15,
    fontWeight: 500,
    color: "#2a2d39",
    letterSpacing: ".5px",
    marginBottom: 5,
  },
  videoCallType: {
    fontSize: 14,
    color: "#3f4456",
    letterSpacing: ".5px",
    fontWeight: 500,
  },
  buttonContainer: {
    width: "33%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingRight: 10,
    // marginRight: 20,
  },
  callButton: {
    height: 35,
    fontSize: 14,
  },
  noVideoCalls: {
    width: "33%",
    fontSize: 17,
    color: "#3f4456",
    letterSpacing: ".5px",
    fontWeight: 500,
  },
  scheduleCallContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  loadingContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 200,
  },
});

export default withStyles(styles)(ClientCalls);
