import React from "react";
import Typography from "@mui/material/Typography";
import { format, isBefore } from "date-fns";
import UpcomingSession from "./UpcomingSession";
import Calendar from "./Calendar";
import EventNoteIcon from "@mui/icons-material/EventNote";
import Roadmap from "./Roadmap";
import Notifications from "./Notifications";
import moment from "moment-timezone";
import { findDifferenceInMinutes } from "../../utils/findDifferenceInMinutes";

import { convertTokens } from "@date-fns/upgrade/v2";

const Dashboard = (props) => {
  const {
    classes,
    getPartofDayLabel,
    user,
    calendarDisplayDate,
    selectedDayCalls,
    videoCalls,
    onboarding,
  } = props;

  const mapCallTypeToDisplayBlock = (call) => {
    const pastSession = isBefore(new Date(call.scheduled_date), new Date());
    switch (call.billing_type) {
      case "CAREGIVER_TRAINING":
        return (
          <div
            key={call.id}
            onClick={() =>
              props.history.push(
                pastSession ? `/session-details/${call.id}` : `/call-instructions/${call.id}`
              )
            }
            className={classes.calendarEvent}
            style={{ backgroundColor: "#FFF4EB" }}
          >
            <div className={classes.calendarEventIconContainer}>
              <EventNoteIcon style={{ fill: "#C96F26", fontSize: 26 }} />
            </div>
            <div className={classes.calendarEventInfo}>
              <Typography
                component="h1"
                className={classes.eventTypeTitle}
                style={{ color: "#C96F26" }}
              >
                Caregiver Training
              </Typography>
              <Typography component="p">
                {pastSession ? (
                  <>
                    {moment
                      .tz(
                        call.start_date ? call.start_date : call.scheduled_date,
                        call.call_timezone
                      )
                      .format("h:mm a z")}
                    -{" "}
                    {call.end_date
                      ? moment.tz(call.end_date, call.call_timezone).format("h:mm a z")
                      : moment
                          .tz(call.scheduled_date, call.call_timezone)
                          .add(call.call_duration, "minutes")
                          .format("h:mm a z")}
                    <span style={{ fontWeight: 500 }}> (PAST SESSION)</span>
                  </>
                ) : (
                  <>
                    {moment.tz(call.scheduled_date, call.call_timezone).format("h:mm a z")}-{" "}
                    {moment
                      .tz(call.scheduled_date, call.call_timezone)
                      .add(call.call_duration, "minutes")
                      .format("h:mm a z")}
                  </>
                )}
              </Typography>
              <Typography component="p">
                {call.clinician_first_name && call.clinician_last_name
                  ? `${call.clinician_first_name} ${call.clinician_last_name}`
                  : `${user?.clinician?.first_name} ${user?.clinician?.last_name}`}
              </Typography>
            </div>
          </div>
        );
      case "ORIENTATION":
        return (
          <div
            key={call.id}
            onClick={() =>
              props.history.push(
                pastSession ? `/session-details/${call.id}` : `/call-instructions/${call.id}`
              )
            }
            className={classes.calendarEvent}
            style={{ backgroundColor: "#EDEBFF" }}
          >
            <div className={classes.calendarEventIconContainer}>
              <EventNoteIcon style={{ fill: "#2653C9", fontSize: 26 }} />
            </div>
            <div className={classes.calendarEventInfo}>
              <Typography
                component="h1"
                className={classes.eventTypeTitle}
                style={{ color: "#2653C9" }}
              >
                Orientation
              </Typography>
              <Typography component="p">
                {pastSession ? (
                  <>
                    {moment
                      .tz(
                        call.start_date ? call.start_date : call.scheduled_date,
                        call.call_timezone
                      )
                      .format("h:mm a z")}
                    -{" "}
                    {call.end_date
                      ? moment.tz(call.end_date, call.call_timezone).format("h:mm a z")
                      : moment
                          .tz(call.scheduled_date, call.call_timezone)
                          .add(call.call_duration, "minutes")
                          .format("h:mm a z")}
                    <span style={{ fontWeight: 500 }}> (PAST SESSION)</span>
                  </>
                ) : (
                  <>
                    {moment.tz(call.scheduled_date, call.call_timezone).format("h:mm a z")}-{" "}
                    {moment
                      .tz(call.scheduled_date, call.call_timezone)
                      .add(call.call_duration, "minutes")
                      .format("h:mm a z")}
                  </>
                )}
              </Typography>
              <Typography component="p">
                {call.clinician_first_name && call.clinician_last_name
                  ? `${call.clinician_first_name} ${call.clinician_last_name}`
                  : `${user?.clinician?.first_name} ${user?.clinician?.last_name}`}
              </Typography>
            </div>
          </div>
        );
      case "DIRECT_THERAPY":
        return (
          <div
            key={call.id}
            onClick={() =>
              props.history.push(
                pastSession ? `/session-details/${call.id}` : `/call-instructions/${call.id}`
              )
            }
            className={classes.calendarEvent}
            style={{ backgroundColor: "#E5F0FF" }}
          >
            <div className={classes.calendarEventIconContainer}>
              <EventNoteIcon style={{ fill: "#2C88BC", fontSize: 26 }} />
            </div>
            <div className={classes.calendarEventInfo}>
              <Typography
                component="h1"
                className={classes.eventTypeTitle}
                style={{ color: "#2C88BC" }}
              >
                Direct Therapy
              </Typography>
              <Typography component="p">
                {pastSession ? (
                  <>
                    {moment
                      .tz(
                        call.start_date ? call.start_date : call.scheduled_date,
                        call.call_timezone
                      )
                      .format("h:mm a z")}
                    -{" "}
                    {call.end_date
                      ? moment.tz(call.end_date, call.call_timezone).format("h:mm a z")
                      : moment
                          .tz(call.scheduled_date, call.call_timezone)
                          .add(call.call_duration, "minutes")
                          .format("h:mm a z")}
                    <span style={{ fontWeight: 500 }}> (PAST SESSION)</span>
                  </>
                ) : (
                  <>
                    {moment.tz(call.scheduled_date, call.call_timezone).format("h:mm a z")}-{" "}
                    {moment
                      .tz(call.scheduled_date, call.call_timezone)
                      .add(call.call_duration, "minutes")
                      .format("h:mm a z")}
                  </>
                )}
              </Typography>
              <Typography component="p">
                {call.clinician_first_name && call.clinician_last_name
                  ? `${call.clinician_first_name} ${call.clinician_last_name}`
                  : `${user?.clinician?.first_name} ${user?.clinician?.last_name}`}
              </Typography>
            </div>
          </div>
        );
      case "ASSESSMENT":
        return (
          <div
            key={call.id}
            onClick={() =>
              props.history.push(
                pastSession ? `/session-details/${call.id}` : `/call-instructions/${call.id}`
              )
            }
            className={classes.calendarEvent}
            style={{ backgroundColor: "#FFEBFB" }}
          >
            <div className={classes.calendarEventIconContainer}>
              <EventNoteIcon style={{ fill: "#B226C9", fontSize: 26 }} />
            </div>
            <div className={classes.calendarEventInfo}>
              <Typography
                component="h1"
                className={classes.eventTypeTitle}
                style={{ color: "#B226C9" }}
              >
                Assessment
              </Typography>
              <Typography component="p">
                {pastSession ? (
                  <>
                    {moment
                      .tz(
                        call.start_date ? call.start_date : call.scheduled_date,
                        call.call_timezone
                      )
                      .format("h:mm a z")}
                    -{" "}
                    {call.end_date
                      ? moment.tz(call.end_date, call.call_timezone).format("h:mm a z")
                      : moment
                          .tz(call.scheduled_date, call.call_timezone)
                          .add(call.call_duration, "minutes")
                          .format("h:mm a z")}
                    <span style={{ fontWeight: 500 }}> (PAST SESSION)</span>
                  </>
                ) : (
                  <>
                    {moment.tz(call.scheduled_date, call.call_timezone).format("h:mm a z")}-{" "}
                    {moment
                      .tz(call.scheduled_date, call.call_timezone)
                      .add(call.call_duration, "minutes")
                      .format("h:mm a z")}
                  </>
                )}
              </Typography>
              <Typography component="p">
                {call.clinician_first_name && call.clinician_last_name
                  ? `${call.clinician_first_name} ${call.clinician_last_name}`
                  : `${user?.clinician?.first_name} ${user?.clinician?.last_name}`}
              </Typography>
            </div>
          </div>
        );
    }
  };
  let callList =
    videoCalls?.length > 0
      ? videoCalls.filter(
          (call) =>
            !call.end_date &&
            findDifferenceInMinutes(new Date(), call.scheduled_date) < call.call_duration
        )
      : [];

  return (
    <>
      <div className={classes.dashboardSections}>
        <Typography component="h2" variant="h3" className={classes.greeting}>
          Good {getPartofDayLabel()}, {user.first_name}!
        </Typography>
        <Typography component="h1" variant="h6" className={classes.sectionTitle}>
          Upcoming Session
        </Typography>
        <UpcomingSession upcomingSession={callList[0] || null} {...props} />
        <Typography component="h1" variant="h6" className={classes.sectionTitle}>
          Notifications
        </Typography>
        <Notifications {...props} />
      </div>
      {onboarding ? (
        <Roadmap {...props} />
      ) : (
        <div className={classes.calendarContainer}>
          <Calendar {...props} />
          <div className={classes.calendarEventsContainer}>
            <div className={classes.calendarEventsDateContainer}>
              <Typography component="p">Selected Day:</Typography>
              <Typography component="p">
                {format(new Date(calendarDisplayDate), convertTokens("ddd, Do"))}
              </Typography>
            </div>
            {selectedDayCalls.length > 0 ? (
              selectedDayCalls.map((call) => {
                return mapCallTypeToDisplayBlock(call);
              })
            ) : (
              <div className={classes.noCalendarEvents}>
                <Typography component="p">Nothing Scheduled this Day</Typography>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;
