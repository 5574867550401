import React from "react";
import { Box, Typography, Paper, Divider } from "@mui/material";
import { Star } from "@phosphor-icons/react";
import camelCaseToTitleCase from "utils/camelCaseToTitleCase";
import moment from "moment-timezone";
import { useTheme } from "@emotion/react";
import { formatTimeZoneToAbbreviation } from "utils/formatTimeZoneToAbbreviation";

const formatRange = (range, timezone) => {
  // Ensure the moments are in the correct timezone
  const startTime = moment(range.start).tz(timezone).format("h:mm A"); // 'h:mm A' for 12-hour format
  const endTime = moment(range.end).tz(timezone).format("h:mm A");

  return `${startTime} - ${endTime} (${formatTimeZoneToAbbreviation(timezone)})`;
};

export const PreferredAvailabilityCard = ({
  serviceTypesOrder,
  availableHours,
  clientTimeZone,
  basicInfo,
}) => {
  const theme = useTheme();

  // Determine if there are any preferred times across all services
  const hasAnyPreferredTimes = serviceTypesOrder.some((serviceType) => {
    const service = availableHours.services.find((s) => s.serviceType === serviceType);
    return service
      ? Object.values(service.availability).some(
          (details) => details.isActive && details.ranges.some((r) => r.preferred)
        )
      : false;
  });

  return (
    hasAnyPreferredTimes && (
      <Box>
        <Paper
          elevation={0}
          sx={{
            borderColor: "divider",
            borderRadius: 2,
            borderStyle: "solid",
            width: "100%",
          }}
        >
          <Box
            display="flex"
            flexDirection={"column"}
            sx={{
              borderRadius: 2,
              padding: "16px 24px",
            }}
            bgcolor={theme.palette.grey[50]}
            minHeight={"80px"}
          >
            <Box display="flex" flexDirection={"row"} alignItems={"center"}>
              <Star size={24} color="#FFD700" weight="fill" />
              <Box marginLeft={"16px"}>
                <Typography variant="subtitle1">
                  {`${basicInfo?.details?.name || "Client"}'s Preferred Availability`}
                </Typography>
                <Typography variant="body2">
                  {`Below are ${basicInfo?.details?.name || "the client"}'s preferred time slots for therapy sessions`}
                </Typography>
              </Box>
            </Box>
          </Box>
          {serviceTypesOrder.map((serviceType) => {
            const service = availableHours.services.find((s) => s.serviceType === serviceType);
            if (!service) return null;

            const hasPreferredTimes = Object.values(service.availability).some(
              (details) => details.isActive && details.ranges.some((r) => r.preferred)
            );

            if (!hasPreferredTimes) return null;

            return (
              <Box key={serviceType} sx={{ padding: "16px 24px" }}>
                <Typography variant="body2" gutterBottom>
                  {camelCaseToTitleCase(serviceType)}
                </Typography>
                {Object.entries(service.availability).map(([day, details], index) => {
                  const preferredRanges = details.ranges.filter((r) => r.preferred);
                  if (details.isActive && preferredRanges.length > 0) {
                    return (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="subtitle2" sx={{ mr: 2 }}>
                          {`${day.charAt(0).toUpperCase() + day.slice(1)}s`}
                        </Typography>

                        <Divider
                          flexItem
                          sx={{
                            alignSelf: "flex-start",
                            marginTop: "9px",
                            marginRight: "8px",
                            flexGrow: 4,
                          }}
                        />
                        <Box display={"flex"} flexDirection={"column"} minWidth={"max-content"}>
                          {preferredRanges.map((range, rangeIndex) => (
                            <Typography key={rangeIndex} variant="body2">
                              {formatRange(range, clientTimeZone)}
                            </Typography>
                          ))}
                        </Box>
                      </Box>
                    );
                  }
                  return null;
                })}
              </Box>
            );
          })}
        </Paper>
      </Box>
    )
  );
};
