import React from "react";
import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";
import { Filter, Table, PhysicianFormDialog } from "./PageComponents";

export const PhysiciansPageContents = (props) => {
  const { filter, table, physicianFormDialog } = props;
  const theme = useTheme();

  return (
    <Box width={"100%"}>
      <Filter {...filter} theme={theme} />
      <Table {...table} theme={theme} />
      <PhysicianFormDialog {...physicianFormDialog} theme={theme} />
    </Box>
  );
};
