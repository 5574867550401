const { FLIPPED_TIMEZONES, TIMEZONE_ABBREVIATIONS } = require("constants");

/**
 * Get the time zone abbreviation using Moment Timezone.
 * @param {string} input - The descriptive name or time zone identifier.
 * @returns {string} The abbreviation of the time zone.
 */
export const formatTimeZoneToAbbreviation = (input) => {
  // Check if the input is a descriptive name and map it to a time zone identifier
  const timeZoneId = FLIPPED_TIMEZONES[input] || input;
  const abbrev = TIMEZONE_ABBREVIATIONS[timeZoneId];
  return abbrev;
};
