export const CHILD = "child";
export const PARENT = "parent";
export const ADMIN = "admin";
export const DIRECTOR = "director";
export const SUPERVISOR = "supervisor";
export const CUSTOMER_CARE = "customercare";
export const CLINICIAN_VIEWONLY = "clinicianviewonly";
export const CLINICIAN = "clinician";
export const FAMILY_MEMBER = "familymember";
export const USER = "user";
export const CLIENT_ID = "clientId";
export const CLINICIAN_ID = "clinicianId";
export const ID = "id";
