import { detect } from "detect-browser";
import cookie from "react-cookies";
import { Auth } from "@aws-amplify/auth";

class SecureRequestFetch {
  constructor() {
    this.browser = detect();
    this.user_id = cookie.load("userId");
    this.system_version = this.browser ? this.browser.version : null;
    this.browser_type = this.browser ? this.browser.name : null;
    this.device_type = this.browser ? this.browser.os : null;
    this.baseURL = process.env.BASE_URL;
  }

  async get(url, additionalHeaders = {}) {
    const headers = await this.createHeaders(additionalHeaders);
    const response = await fetch(`${this.baseURL}${url}`, { method: "GET", headers });
    return this.processResponse(response);
  }

  async createHeaders(additionalHeaders) {
    let accessToken = "";

    try {
      const session = await Auth.currentSession();
      accessToken = session.accessToken.jwtToken;
    } catch (error) {
      console.warn("No session found:", error);
    }

    return {
      "X-Requested-With": "XMLHttpRequest",
      "Content-Type": "application/json",
      accessToken: accessToken,
      user_id: this.user_id,
      system_version: this.system_version,
      browser_type: this.browser_type,
      device_type: this.device_type,
      ...additionalHeaders,
    };
  }

  async processResponse(response) {
    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    const contentType = response.headers.get("Content-Type");
    let data = "";
    if (contentType.includes("application/json")) {
      data = await response.json();
      return {
        data: data,
        headers: response.headers,
      };
    } else if (contentType.includes("application/pdf") || contentType.includes("blob")) {
      data = await response.blob();
      return {
        data: data,
        headers: response.headers,
      };
    } else {
      throw new Error("Unsupported content type: " + contentType);
    }
  }
}

export default SecureRequestFetch;
