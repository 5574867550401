import { useMutation } from "react-query";

import { saveAcceptInvitationMember } from "api/team";
import { useGlobalToast } from "components/GlobalToastProvider";

const useSaveAcceptInvitation = (options = {}) => {
  const { onError = () => {}, ...otherOptions } = options;
  const { showToast } = useGlobalToast();

  const { mutate, isIdle, isLoading, ...rest } = useMutation(
    (params) => saveAcceptInvitationMember(params),
    {
      onError: () => {
        showToast({
          message: "Whoops! Something went wrong",
          errorState: true,
        });
        onError();
      },
      ...otherOptions,
    }
  );

  return {
    acceptInvitationMutation: mutate,
    isMutationInvitationIdle: isIdle,
    isMutationInvitationLoading: isLoading,
    ...rest,
  };
};

export default useSaveAcceptInvitation;
