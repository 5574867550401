import React from "react";
import Grid from "@mui/material/Grid";

import { DownloadSimple, Files } from "@phosphor-icons/react";

import withStyles from "@mui/styles/withStyles";
import { Card } from "@mui/material";
import Typography from "@mui/material/Typography";
import { addYears, format } from "date-fns";
import { convertTokens } from "@date-fns/upgrade/v2";
import ProvidersDetailsMenu from "./ProvidersDetailsMenu";
import IconButton from "@mui/material/IconButton";
import { providerTypes } from "./providerTypes";

const styles = () => {
  return {
    providerName: {
      fontSize: "16px",
      lineHeight: "28px",
      paddingTop: 0,
    },
    providerType: {
      fontSize: "14px",
      lineHeight: "20px",
      color: "#5B6172",
    },
    body: {
      background: "#F6F7F8",
    },
    primaryText: {
      fontSize: "14px",
      lineHeight: "20px",
      color: "#31333A",
    },
    secondaryText: {
      fontSize: "14px",
      lineHeight: "20px",
      color: "#5B6172",
      paddingRight: 8,
    },
    italic: {
      fontStyle: "italic",
    },
    expired: {
      color: "#D32F2F",
    },
    filesIcon: {
      marginRight: "8px",
    },
    downloadIcon: {
      marginLeft: "16px",
    },
    downloadIconButton: {
      padding: "0 !important",
      width: "20px",
    },
  };
};

const mapRequestTypeToTitle = (request) => {
  if (request.request_type === "ADDITIONAL_REQUEST") {
    if (request.title) {
      return `${request.title} - ${format(
        new Date(request.created),
        convertTokens("MMM DD, YYYY")
      )}`;
    } else {
      return `Document request - ${format(
        new Date(request.created),
        convertTokens("MMM DD, YYYY")
      )}`;
    }
  }
  return "PHI Release Form";
};

const ProvidersDetails = (props) => {
  const {
    provider,
    editDisabled,
    documentRequests,
    onCreatePHIRelease,
    classes,
    editMode,
    onEditProviderType,
    onCreateROIForm,
    userPermissions,
    onProviderChanges,
    currentUserId,
  } = props;
  const { first_name, last_name, physician_type, id: physician_id } = provider;

  const handleOpenPHIRelease = (request) => {
    if (request.document_id) {
      const url = `/document-viewer/${currentUserId}/${request.document_id}`;
      window.open(url, "_blank", "noopener,noreferrer");
      return;
    }
    onCreatePHIRelease(provider.id, request.id);
  };

  const mapProviderTypeToLabel = () => {
    const providerType = providerTypes.find((p) => p.value === physician_type);
    return providerType?.label || "";
  };

  return (
    <Card variant="outlined" sx={{ borderRadius: "6px", marginBottom: "24px" }}>
      <Grid
        container
        spacing={3}
        justifyContent="space-between"
        style={{ padding: "var(--5, 16px) var(--7, 24px) 20px", height: "84px", marginTop: 0 }}
      >
        <Grid item xs={12} justifyContent="space-between" style={{ paddingTop: "0px" }}>
          <Grid item xs={6}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" className={classes.providerName}>
                {first_name} {last_name}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: "0px" }}>
              <Typography variant={"body2"} className={classes.providerType}>
                {mapProviderTypeToLabel()}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={6} container justifyContent="flex-end">
            {editMode && (
              <ProvidersDetailsMenu
                onProviderChanges={onProviderChanges}
                provider={provider}
                onEditProviderType={onEditProviderType}
                onCreateROIForm={onCreateROIForm}
                userPermissions={userPermissions}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid style={{ padding: "16px 24px" }} className={classes.body}>
        <Grid container spacing={3} justifyContent="space-between">
          <Grid item xs={12} alignItems="center">
            <Files className={classes.filesIcon} size={"20px"} weight="duotone" />
            <Typography className={classes.primaryText}>Release of Information Forms</Typography>
          </Grid>
          {documentRequests?.map((request) => {
            const expirationDate = new Date(addYears(new Date(request.created), 1));
            const isPastDate = expirationDate < new Date();
            if (request.provider_id && request.provider_id != physician_id) {
              return null;
            }

            return (
              <React.Fragment key={request.id}>
                <Grid item xs={7} container alignItems="center">
                  <Typography className={classes.primaryText}>
                    {mapRequestTypeToTitle(request)}
                  </Typography>
                </Grid>
                <Grid item xs={5} container justifyContent="flex-end" alignItems="center">
                  {request.signature_url || request.document_id ? (
                    <Typography
                      className={`${classes.secondaryText} ${isPastDate ? classes.expired : ""}`}
                    >
                      Expire{isPastDate ? "d" : "s"}:{" "}
                      {format(expirationDate, convertTokens("MMM DD, YYYY"))}
                    </Typography>
                  ) : (
                    <Typography className={`${classes.secondaryText} ${classes.italic}`}>
                      Pending Signature
                    </Typography>
                  )}
                  <IconButton
                    key={request.id}
                    color="primary"
                    fontSize="small"
                    size="small"
                    disabled={editDisabled || (!request.signature_url && !request.document_id)}
                    onClick={() => handleOpenPHIRelease(request)}
                  >
                    <DownloadSimple />
                  </IconButton>
                </Grid>
              </React.Fragment>
            );
          })}
        </Grid>
      </Grid>
    </Card>
  );
};

export default withStyles(styles)(ProvidersDetails);
