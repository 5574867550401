import React from "react";
import MenuItem from "@mui/material/MenuItem";
import { LinkBreak } from "@phosphor-icons/react";
import useProvidersFormDialogMutations from "./useProvidersFormDialogMutations";
import { useGlobalToast } from "../../../GlobalToastProvider";
import { useParams } from "react-router-dom";

const RemoveProviderMenuItem = ({ provider, onProviderChanges }) => {
  const { id: clientId } = useParams();
  const { unlinkPhysicianMutation } = useProvidersFormDialogMutations();
  const { showToast } = useGlobalToast();

  const handleConfirmAndClose = async () => {
    try {
      await unlinkPhysicianMutation.mutateAsync({
        clientId,
        physician: { id: provider.id },
        clinic: { id: provider.clinic_id },
      });
      showToast({ message: "Provider Successfully removed" });
      onProviderChanges();
    } catch (error) {
      showToast({
        message: "Whoops! Something went wrong",
        errorState: true,
      });
      console.error("An error occurred:", error);
    }
  };

  return (
    <MenuItem onClick={handleConfirmAndClose}>
      <LinkBreak size={20} style={{ marginRight: "1rem" }} weight={"duotone"} /> Remove Provider
      from Client Account
    </MenuItem>
  );
};

export default RemoveProviderMenuItem;
