import { FormHelperText, InputAdornment, TextField, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { STATES } from "../../../../constants";
import React from "react";

export const VerificationOfBenefitsForm = (data, insurances, errors, isMedicaidForm, onChange) => {
  return [
    {
      id: "insurance_plan",
      input: (
        <TextField
          label="Insurance Plan"
          onChange={onChange("insurance_plan_id", null, data)}
          value={insurances?.length === 1 ? insurances[0].id : data.insurance_plan_id ?? ""}
          select
          fullWidth
          disabled={insurances?.length === 1}
        >
          {insurances?.map(({ name, id }, index) => {
            return (
              <MenuItem key={`${index}`} name={name} value={id}>
                {name}
              </MenuItem>
            );
          })}
        </TextField>
      ),
      cols: 12,
    },
    {
      id: "benefit_year",
      title: "Benefit Year",
      input: (
        <>
          <RadioGroup
            id="benefit_year"
            aria-label="benefit_year"
            name="benefit_year"
            value={data.benefit_year ?? ""}
            row
            onChange={onChange("benefit_year", null, data)}
          >
            <FormControlLabel
              value={"Calendar Year"}
              control={<Radio color="primary" size="small" />}
              label={
                <Typography
                  component="p"
                  style={{
                    fontSize: 14,
                    color: "#2A2D39",
                  }}
                >
                  Calendar Year
                </Typography>
              }
            />
            <FormControlLabel
              value={"Plan Year"}
              control={<Radio color="primary" size="small" />}
              label={
                <Typography
                  component="p"
                  style={{
                    fontSize: 14,
                    color: "#2A2D39",
                  }}
                >
                  Plan Year
                </Typography>
              }
            />
          </RadioGroup>
        </>
      ),
      cols: 12,
    },
    {
      id: "network_status",
      title: "Network Status",
      input: (
        <>
          <RadioGroup
            aria-label="network_status"
            name="network_status"
            value={data.network_status ?? ""}
            onChange={onChange("network_status", null, data)}
            row
          >
            <FormControlLabel
              value={"In-Network"}
              control={<Radio color="primary" size="small" />}
              label={
                <Typography
                  component="p"
                  style={{
                    fontSize: 14,
                    color: "#2A2D39",
                  }}
                >
                  In-Network
                </Typography>
              }
            />
            <FormControlLabel
              value={"Out-Of-Network"}
              control={<Radio color="primary" size="small" />}
              label={
                <Typography
                  component="p"
                  style={{
                    fontSize: 14,
                    color: "#2A2D39",
                  }}
                >
                  Out-Of-Network
                </Typography>
              }
            />
          </RadioGroup>
        </>
      ),
      cols: 12,
    },
    {
      id: "has_deductible",
      title: "Does the client have a deductible?",
      input: (
        <>
          <RadioGroup
            aria-label="has_deductible"
            name="has_deductible"
            value={data.has_deductible}
            onChange={onChange("has_deductible", "radioButtonChange", data)}
            row
          >
            <FormControlLabel
              value={true}
              control={<Radio color="primary" size="small" />}
              label={
                <Typography
                  component="p"
                  style={{
                    fontSize: 14,
                    color: "#2A2D39",
                  }}
                >
                  Yes
                </Typography>
              }
            />
            <FormControlLabel
              value={false}
              control={<Radio color="primary" size="small" />}
              label={
                <Typography
                  component="p"
                  style={{
                    fontSize: 14,
                    color: "#2A2D39",
                  }}
                >
                  No
                </Typography>
              }
            />
          </RadioGroup>
        </>
      ),
      cols: 12,
    },
    {
      id: "individual_deductible_amount",
      title: "Individual Deductible Amount",
      hide: !data.has_deductible,
      input: (
        <TextField
          id="individual_deductible_amount"
          name="individual_deductible_amount"
          autoComplete="off"
          value={data.individual_deductible_amount ?? ""}
          onChange={onChange("individual_deductible_amount", null, data)}
          type="number"
          InputProps={{
            inputProps: { min: 0 },
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      ),
      cols: 6,
    },
    {
      id: "remaining_deductible_amount",
      title: "Remaining Deductible Amount",
      hide: !data.has_deductible,
      input: (
        <TextField
          id="remaining_deductible_amount"
          name="remaining_deductible_amount"
          autoComplete="off"
          value={data.remaining_deductible_amount ?? ""}
          onChange={onChange("remaining_deductible_amount", null, data)}
          type="number"
          InputProps={{
            inputProps: { min: 0 },
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      ),
      cols: 6,
    },
    {
      id: "family_deductible_amount",
      title: "Family Deductible Amount",
      hide: !data.has_deductible,
      input: (
        <TextField
          id="family_deductible_amount"
          name="family_deductible_amount"
          autoComplete="off"
          value={data.family_deductible_amount ?? ""}
          onChange={onChange("family_deductible_amount", null, data)}
          type="number"
          InputProps={{
            inputProps: { min: 0 },
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      ),
      cols: 6,
    },
    {
      id: "remaining_family_deductible_amount",
      title: "Remaining Family Deductible Amount",
      hide: !data.has_deductible,
      input: (
        <TextField
          id="remaining_family_deductible_amount"
          name="remaining_family_deductible_amount"
          autoComplete="off"
          value={data.remaining_family_deductible_amount ?? ""}
          onChange={onChange("remaining_family_deductible_amount", null, data)}
          type="number"
          InputProps={{
            inputProps: { min: 0 },
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      ),
      cols: 6,
    },
    {
      id: "deductible_out_of_pocket_max",
      title: "Does the deductible apply to Out-of-Pocket Max?",
      hide: !data.has_deductible,
      input: (
        <>
          <RadioGroup
            aria-label="deductible_out_of_pocket_max"
            name="deductible_out_of_pocket_max"
            value={data.deductible_out_of_pocket_max ?? ""}
            onChange={onChange("deductible_out_of_pocket_max", null, data)}
            row
          >
            <FormControlLabel
              value={true}
              disabled={!data.has_deductible}
              control={<Radio color="primary" size="small" />}
              label={
                <Typography
                  component="p"
                  style={{
                    fontSize: 14,
                    color: "#2A2D39",
                  }}
                >
                  Yes
                </Typography>
              }
            />
            <FormControlLabel
              value={false}
              disabled={!data.has_deductible}
              control={<Radio color="primary" size="small" />}
              label={
                <Typography
                  component="p"
                  style={{
                    fontSize: 14,
                    color: "#2A2D39",
                  }}
                >
                  No
                </Typography>
              }
            />
          </RadioGroup>
        </>
      ),
      cols: 12,
      divider: true,
    },
    {
      id: "co_insurance_amount_insurance",
      title: "Co-Insurance Amount (Insurance)",
      input: (
        <TextField
          id="co_insurance_amount_insurance"
          name="co_insurance_amount_insurance"
          autoComplete="off"
          value={data.co_insurance_amount_insurance ?? ""}
          onChange={onChange("co_insurance_amount_insurance", null, data)}
          type="number"
          InputProps={{
            inputProps: { min: 0 },
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      ),
      cols: 6,
    },
    {
      id: "co_insurance_amount_family",
      title: "Co-Insurance Amount (Family)",
      input: (
        <TextField
          id="co_insurance_amount_family"
          name="co_insurance_amount_family"
          autoComplete="off"
          value={data.co_insurance_amount_family ?? ""}
          onChange={onChange("co_insurance_amount_family", null, data)}
          type="number"
          InputProps={{
            inputProps: { min: 0 },
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      ),
      cols: 6,
    },
    {
      id: "co_pay_amount",
      title: "Co-Pay Amount",
      input: (
        <TextField
          id="co_pay_amount"
          name="co_pay_amount"
          autoComplete="off"
          value={data.co_pay_amount ?? ""}
          onChange={onChange("co_pay_amount", null, data)}
          type="number"
          InputProps={{
            inputProps: { min: 0 },
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      ),
      cols: 12,
    },
    {
      id: "individual_out_of_pocket_max",
      title: "Out-of-Pocket Max",
      input: (
        <TextField
          id="individual_out_of_pocket_max"
          name="individual_out_of_pocket_max"
          autoComplete="off"
          value={data.individual_out_of_pocket_max ?? ""}
          onChange={onChange("individual_out_of_pocket_max", null, data)}
          type="number"
          InputProps={{
            inputProps: { min: 0 },
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      ),
      cols: 6,
    },
    {
      id: "individual_out_of_pocket_remaining",
      title: "Individual Out-of-Pocket Remaining",
      input: (
        <TextField
          id="individual_out_of_pocket_remaining"
          name="individual_out_of_pocket_remaining"
          autoComplete="off"
          value={data.individual_out_of_pocket_remaining ?? ""}
          onChange={onChange("individual_out_of_pocket_remaining", null, data)}
          type="number"
          InputProps={{
            inputProps: { min: 0 },
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      ),
      cols: 6,
    },
    {
      id: "family_out_of_pocket_max",
      title: "Family Out-of-Pocket Max",
      input: (
        <TextField
          id="family_out_of_pocket_max"
          name="family_out_of_pocket_max"
          autoComplete="off"
          value={data.family_out_of_pocket_max ?? ""}
          onChange={onChange("family_out_of_pocket_max", null, data)}
          type="number"
          InputProps={{
            inputProps: { min: 0 },
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      ),
      cols: 6,
    },
    {
      id: "family_out_of_pocket_remaining",
      title: "Family Out-of-Pocket Remaining",
      input: (
        <TextField
          id="family_out_of_pocket_remaining"
          name="family_out_of_pocket_remaining"
          autoComplete="off"
          value={data.family_out_of_pocket_remaining ?? ""}
          onChange={onChange("family_out_of_pocket_remaining", null, data)}
          type="number"
          InputProps={{
            inputProps: { min: 0 },
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      ),
      cols: 6,
      divider: true,
    },
    {
      id: "visit_limit",
      title: "Visit Limit (optional)",
      input: (
        <TextField
          id="visit_limit"
          name="visit_limit"
          autoComplete="off"
          value={data.visit_limit ?? ""}
          onChange={onChange("visit_limit", null, data)}
          type="number"
          InputProps={{ inputProps: { min: 0 } }}
        />
      ),
      cols: 12,
    },
    {
      id: "age_limit",
      title: "Age Limit (optional)",
      input: (
        <TextField
          id="age_limit"
          name="age_limit"
          autoComplete="off"
          value={data.age_limit ?? ""}
          onChange={onChange("age_limit", null, data)}
          type="number"
          InputProps={{ inputProps: { min: 0 } }}
        />
      ),
      cols: 12,
    },
    {
      id: "annual_dollar_maximum",
      title: "Annual Dollar Maximum (optional)",
      input: (
        <TextField
          id="annual_dollar_maximum"
          name="annual_dollar_maximum"
          autoComplete="off"
          value={data.annual_dollar_maximum ?? ""}
          onChange={onChange("annual_dollar_maximum", null, data)}
          type="number"
          InputProps={{
            inputProps: { min: 0 },
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      ),
      cols: 12,
    },
    {
      id: "lifetime_dollar_maximum",
      title: "Lifetime Dollar Maximum (optional)",
      input: (
        <TextField
          id="lifetime_dollar_maximum"
          name="lifetime_dollar_maximum"
          autoComplete="off"
          value={data.lifetime_dollar_maximum ?? ""}
          onChange={onChange("lifetime_dollar_maximum", null, data)}
          type="number"
          InputProps={{
            inputProps: { min: 0 },
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      ),
      cols: 12,
      divider: true,
    },
    {
      id: "aba_covered",
      title: "Is ABA a covered benefit?",
      input: (
        <>
          <RadioGroup
            aria-label="aba_covered"
            name="aba_covered"
            value={data.aba_covered ?? ""}
            onChange={onChange("aba_covered", "radioButtonChange", data)}
            row
          >
            <FormControlLabel
              value={true}
              control={<Radio color="primary" size="small" />}
              label={
                <Typography
                  component="p"
                  style={{
                    fontSize: 14,
                    color: "#2A2D39",
                  }}
                >
                  Yes
                </Typography>
              }
            />
            <FormControlLabel
              value={false}
              control={<Radio color="primary" size="small" />}
              label={
                <Typography
                  component="p"
                  style={{
                    fontSize: 14,
                    color: "#2A2D39",
                  }}
                >
                  No
                </Typography>
              }
            />
          </RadioGroup>
        </>
      ),
      cols: 12,
    },
    {
      id: "telehealth_covered",
      title: "In Telehealth covered?",
      input: (
        <>
          <RadioGroup
            aria-label="telehealth_covered"
            name="telehealth_covered"
            value={data.telehealth_covered ?? ""}
            onChange={onChange("telehealth_covered", "radioButtonChange", data)}
            row
          >
            <FormControlLabel
              value={true}
              control={<Radio color="primary" size="small" />}
              label={
                <Typography
                  component="p"
                  style={{
                    fontSize: 14,
                    color: "#2A2D39",
                  }}
                >
                  Yes
                </Typography>
              }
            />
            <FormControlLabel
              value={false}
              control={<Radio color="primary" size="small" />}
              label={
                <Typography
                  component="p"
                  style={{
                    fontSize: 14,
                    color: "#2A2D39",
                  }}
                >
                  No
                </Typography>
              }
            />
          </RadioGroup>
        </>
      ),
      cols: 12,
    },
    {
      id: "is_same_benefit",
      title: "Is the benefit the same?",
      input: (
        <>
          <RadioGroup
            aria-label="is_same_benefit"
            name="is_same_benefit"
            value={data.is_same_benefit ?? ""}
            onChange={onChange("is_same_benefit", "radioButtonChange", data)}
            row
          >
            <FormControlLabel
              value={true}
              control={<Radio color="primary" size="small" />}
              label={
                <Typography
                  component="p"
                  style={{
                    fontSize: 14,
                    color: "#2A2D39",
                  }}
                >
                  Yes
                </Typography>
              }
            />
            <FormControlLabel
              value={false}
              control={<Radio color="primary" size="small" />}
              label={
                <Typography
                  component="p"
                  style={{
                    fontSize: 14,
                    color: "#2A2D39",
                  }}
                >
                  No
                </Typography>
              }
            />
          </RadioGroup>
        </>
      ),
      cols: 12,
    },
    {
      id: "specific_telehealth_platform_required",
      title: "Specific Telehealth Platform required?",
      input: (
        <>
          <RadioGroup
            aria-label="specific_telehealth_platform_required"
            name="specific_telehealth_platform_required"
            value={data.specific_telehealth_platform_required ?? ""}
            onChange={onChange("specific_telehealth_platform_required", "radioButtonChange", data)}
            row
          >
            <FormControlLabel
              value={true}
              control={<Radio color="primary" size="small" />}
              label={
                <Typography
                  component="p"
                  style={{
                    fontSize: 14,
                    color: "#2A2D39",
                  }}
                >
                  Yes
                </Typography>
              }
            />
            <FormControlLabel
              value={false}
              control={<Radio color="primary" size="small" />}
              label={
                <Typography
                  component="p"
                  style={{
                    fontSize: 14,
                    color: "#2A2D39",
                  }}
                >
                  No
                </Typography>
              }
            />
          </RadioGroup>
        </>
      ),
      cols: 12,
    },
    {
      id: "auth_required_for_assessment",
      title: "Is authorization required for assessment? (97151)",
      input: (
        <>
          <RadioGroup
            aria-label="auth_required_for_assessment"
            name="auth_required_for_assessment"
            value={data.auth_required_for_assessment ?? ""}
            onChange={onChange("auth_required_for_assessment", "radioButtonChange", data)}
            row
          >
            <FormControlLabel
              value={true}
              control={<Radio color="primary" size="small" />}
              label={
                <Typography
                  component="p"
                  style={{
                    fontSize: 14,
                    color: "#2A2D39",
                  }}
                >
                  Yes
                </Typography>
              }
            />
            <FormControlLabel
              value={false}
              control={<Radio color="primary" size="small" />}
              label={
                <Typography
                  component="p"
                  style={{
                    fontSize: 14,
                    color: "#2A2D39",
                  }}
                >
                  No
                </Typography>
              }
            />
          </RadioGroup>
        </>
      ),
      cols: 12,
    },
    {
      id: "auth_required_for_treatment",
      title: "Is authorization required for treatment? (97153, 97155, 97156)",
      input: (
        <>
          <RadioGroup
            aria-label="auth_required_for_treatment"
            name="auth_required_for_treatment"
            value={data.auth_required_for_treatment ?? ""}
            onChange={onChange("auth_required_for_treatment", "radioButtonChange", data)}
            row
          >
            <FormControlLabel
              value={true}
              control={<Radio color="primary" size="small" />}
              label={
                <Typography
                  component="p"
                  style={{
                    fontSize: 14,
                    color: "#2A2D39",
                  }}
                >
                  Yes
                </Typography>
              }
            />
            <FormControlLabel
              value={false}
              control={<Radio color="primary" size="small" />}
              label={
                <Typography
                  component="p"
                  style={{
                    fontSize: 14,
                    color: "#2A2D39",
                  }}
                >
                  No
                </Typography>
              }
            />
          </RadioGroup>
        </>
      ),
      cols: 12,
      divider: true,
    },
    {
      id: "aba_managing_entity",
      input: (
        <TextField
          id="aba_managing_entity"
          label="ABA Managing Entity"
          onChange={onChange("aba_managing_entity", null, data)}
          value={data.aba_managing_entity ?? ""}
          fullWidth
          helperText={"Who is the authorization through?"}
        />
      ),
    },
    {
      id: "phone",
      input: (
        <TextField
          id="phone"
          label="Phone Number (optional)"
          onChange={onChange("phone", "phoneNumberchange", data)}
          value={data.phone ?? ""}
          fullWidth
        />
      ),
    },
    {
      id: "fax",
      input: (
        <TextField
          id="fax"
          label="Fax Number (optional)"
          onChange={onChange("fax", "phoneNumberchange", data)}
          value={data.fax ?? ""}
          fullWidth
        />
      ),
    },
    {
      id: "address1",
      input: (
        <TextField
          id="address1"
          name="address1"
          autoComplete="off"
          value={data.address1 ?? ""}
          onChange={onChange("address1", null, data)}
          variant="outlined"
          label="Address"
          fullWidth
        />
      ),
    },
    {
      id: "address2",
      input: (
        <TextField
          id="address2"
          label="Address 2 (optional)"
          autoComplete="off"
          onChange={onChange("address2", null, data)}
          value={data.address2 ?? ""}
          fullWidth
        />
      ),
    },
    {
      id: "state",
      input: (
        <TextField
          id="state"
          select
          label="State"
          onChange={onChange("state", null, data)}
          value={data.state ?? ""}
          fullWidth
          autoComplete="off"
        >
          <MenuItem disabled value={""}>
            Select a state
          </MenuItem>
          {Object.keys(STATES).map((key) => {
            return (
              <MenuItem key={key} value={key}>
                {STATES[key]}
              </MenuItem>
            );
          })}
        </TextField>
      ),
    },
    {
      id: "city",
      input: (
        <TextField
          id="city"
          label="City"
          onChange={onChange("city", null, data)}
          value={data.city ?? ""}
          fullWidth
        />
      ),
      cols: 6,
    },
    {
      id: "zip",
      input: (
        <TextField
          id="zip"
          label="ZIP Code"
          onChange={onChange("zip", "zipCode", data)}
          value={data.zip ?? ""}
          fullWidth
          inputProps={{ maxLength: 5 }}
          helperText={
            errors["zip"]
              ? "Please enter ZIP Code"
              : errors["zip_format_error"]
                ? "Invalid ZIP Code"
                : ""
          }
        />
      ),
      cols: 6,
    },
    {
      id: "payer_id",
      input: (
        <TextField
          id="payer_id"
          label="Payer ID Number"
          onChange={onChange("payer_id", null, data)}
          value={data.payer_id ?? ""}
          fullWidth
        />
      ),
    },
    {
      id: "representative",
      input: (
        <TextField
          id="representative"
          label="Representative"
          onChange={onChange("representative", null, data)}
          value={data.representative ?? ""}
          fullWidth
        />
      ),
    },
    {
      id: "representative_id",
      input: (
        <TextField
          id="representative_id"
          label="Representative's ID Number"
          onChange={onChange("representative_id", null, data)}
          value={data.representative_id ?? ""}
          fullWidth
        />
      ),
    },
    {
      id: "notes",
      input: (
        <TextField
          id="notes"
          label="Notes (optional)"
          onChange={onChange("notes", null, data)}
          value={data.notes ?? ""}
          fullWidth
          multiline={true}
          rows={3}
        />
      ),
    },
  ];
};
