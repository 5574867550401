import React from "react";
import { Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import ANUploader from "elements/ANUploader";

const NoDocumentsWithPermissionOverlay = ({ handleFileUpload }) => {
  const theme = useTheme();
  return (
    <Stack height="100%" justifyContent="center" alignItems="center">
      <Stack height="168px" width="600px" alignItems="center">
        <Typography variant="h3" mb={theme.spacing(5)}>
          Let’s add the first document
        </Typography>
        {/* Here we only need handleFileUpload for ANUploader since all we want is to add 
        the file and open the dialog, the rest of the functionality is on charge of the dialog. */}
        <ANUploader
          handleFileUpload={handleFileUpload}
          useDropzoneOptions={{ multiple: false }}
          dropZoneStyle={{ margin: 0, width: "600px" }}
        />
      </Stack>
    </Stack>
  );
};

export default NoDocumentsWithPermissionOverlay;
