import { call, put, select, takeEvery } from "redux-saga/effects";
import actions from "../actions";
import {
  getCliniciansList,
  getClinician,
  updateClinicianBasicDetails,
  updateClinicianAddress,
  addClinicianPhone,
  updateClinicianNPI,
  addClinicianLicense,
  addClinicianCertificate,
  updateClinicianImage,
  createClinician,
  createSupportChat,
  getClinicianDeactivationReasons,
  getDeactivatedClinicians,
  getAssignableClinicians,
} from "../api/clinicians";
import { toggleCustomerActivation } from "../api/customers";
import { getClinicianId } from "../selectors";

function* getClinicians() {
  try {
    const cliniciansList = yield call(getCliniciansList);
    yield put(
      actions.setCliniciansList(
        cliniciansList.data,
        cliniciansList.data.length > 0 ? parseInt(cliniciansList.data[0].count) : 0
      )
    );
  } catch (e) {
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    yield put(actions.loadCliniciansListFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getDeactivatedCliniciansAsync() {
  try {
    const deactivatedClinicians = yield call(getDeactivatedClinicians);
    yield put(actions.getDeactivatedCliniciansSuccess(deactivatedClinicians.data));
  } catch (e) {
    yield put(actions.getDeactivatedCliniciansFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* editClinicianBasicAsync({ payload }) {
  try {
    const result = yield call(updateClinicianBasicDetails, payload);
    yield put(actions.updateBasicClinicianDetailsSuccess(result.data));
  } catch (e) {
    yield put(actions.setSaveError());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
  }
}

function* editClinicianImageAsync({ payload }) {
  try {
    const result = yield call(updateClinicianImage, payload);
    yield put(actions.updateBasicClinicianDetailsSuccess(result.data));
  } catch (e) {
    yield put(actions.setSaveError());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
  }
}

function* editClinicianAddressAsync({ payload }) {
  try {
    const result = yield call(updateClinicianAddress, payload);
    yield put(actions.updateClinicianAddressSuccess(result.data));
  } catch (e) {
    console.log(e);
    yield put(actions.setSaveError());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
  }
}

function* addClinicianPhoneAsync({ payload }) {
  try {
    const clinicianId = yield select(getClinicianId);
    yield call(addClinicianPhone, payload);
    yield put(actions.loadClinicianDetails(clinicianId));
  } catch (e) {
    console.log(e);
    yield put(actions.setSaveError());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
  }
}

function* editClinicianNPIAsync({ payload }) {
  try {
    const result = yield call(updateClinicianNPI, payload);
    yield put(actions.updateClinicianNPISuccess(result.data));
  } catch (e) {
    console.log(e);
    yield put(actions.setSaveError());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
  }
}

function* addClinicianLicenseAsync({ payload }) {
  try {
    const clinicianId = yield select(getClinicianId);
    yield call(addClinicianLicense, payload);
    yield put(actions.loadClinicianDetails(clinicianId));
  } catch (e) {
    console.log(e);
    yield put(actions.setSaveError());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
  }
}

function* addClinicianCertificateAsync({ payload }) {
  try {
    const clinicianId = yield select(getClinicianId);
    yield call(addClinicianCertificate, payload);
    yield put(actions.loadClinicianDetails(clinicianId));
  } catch (e) {
    console.log(e);
    yield put(actions.setSaveError());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
  }
}

function* updateClinicianExpertiseAsync({ payload }) {
  try {
    const currentVal = yield select(getClinicianDetails);
    const clinicianId = yield select(getClinicianId);

    let details = payload;

    let behaviors = details.behaviorsList;
    let oldBehaviors = currentVal.behaviors.map((a) => a.id);
    if (behaviors.toString() != oldBehaviors.toString()) {
      yield call(updateClinicianBehaviors, behaviors, clinicianId);
    }

    let diagnoses = details.diagnosisList;
    let oldDiagnoses = currentVal.diagnoses.map((a) => a.id);
    if (diagnoses.toString() != oldDiagnoses.toString()) {
      yield call(updateClinicianDiagnoses, diagnoses, clinicianId);
    }

    let specialties = details.specialtiesList;
    let oldSpecialties = currentVal.specialties.map((a) => a.id);
    if (specialties.toString() != oldSpecialties.toString()) {
      yield call(updateClinicianSpecialties, specialties, clinicianId);
    }

    yield all({
      ageGroups: call(updateClinicianExpertise, "age-groups", details.ageGroups),
      schoolPlacements: call(
        updateClinicianExpertise,
        "school-placements",
        details.schoolPlacements
      ),
      empathyTypes: call(updateClinicianExpertise, "empathy-types", details.empathyTypes),
      playSkills: call(updateClinicianExpertise, "play-skills", details.playSkills),
      friendAbilities: call(updateClinicianExpertise, "friend-abilities", details.friendAbilities),
      talkingLevels: call(updateClinicianExpertise, "talking-levels", details.talkingLevels),
      verbalLevels: call(updateClinicianExpertise, "verbal-levels", details.verbalLevels),
    });
    yield put(actions.loadClinicianDetails(clinicianId));
  } catch (e) {
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    console.log(e);
    yield put(actions.setSaveError());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
  }
}

function* createClinicianAsync({ payload }) {
  try {
    yield call(createClinician, payload);
    yield put(actions.loadCliniciansList());
  } catch (e) {
    console.log(e);
    yield put(actions.setSaveError());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
  }
}

function* createSupportChatAsync({ payload }) {
  try {
    yield call(createSupportChat, payload.clinician_user_id);
    yield put(actions.getClinician(payload.clinician_id));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* getClinicianAsync({ payload }) {
  try {
    const details = yield call(getClinician, payload);
    yield put(actions.getClinicianSuccess(details.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* toggleClinicianActivationAsync({ payload }) {
  try {
    yield call(toggleCustomerActivation, payload);
    yield put(actions.loadClinicianDetails(payload.clinicianId));
    yield put(actions.toggleClinicianActivationSuccess());
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getClinicianDeactivationReasonsAsync() {
  try {
    const reasons = yield call(getClinicianDeactivationReasons);
    yield put(actions.getClinicianDeactivationReasonsSuccess(reasons.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* getAssignableCliniciansAsync({ payload }) {
  try {
    const clinicians = yield call(getAssignableClinicians, payload.secondary);
    yield put(actions.getAssignableCliniciansSuccess(clinicians.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

export default function* startSaga() {
  yield takeEvery(actions.LOAD_CLINICIANS_LIST, getClinicians);
  yield takeEvery(actions.GET_ALL_CLINICIANS, getClinicians);
  yield takeEvery(actions.GET_DEACTIVATED_CLINICIANS, getDeactivatedCliniciansAsync);
  yield takeEvery(actions.UPDATE_CLINICIAN_BASIC_DETAILS, editClinicianBasicAsync);
  yield takeEvery(actions.UPDATE_CLINICIAN_ADDRESS, editClinicianAddressAsync);
  yield takeEvery(actions.ADD_CLINICIAN_PHONE, addClinicianPhoneAsync);
  yield takeEvery(actions.UPDATE_CLINICIAN_NPI, editClinicianNPIAsync);
  yield takeEvery(actions.ADD_CLINICIAN_LICENSE, addClinicianLicenseAsync);
  yield takeEvery(actions.ADD_CLINICIAN_CERTIFICATE, addClinicianCertificateAsync);
  yield takeEvery(actions.UPDATE_CLINICIAN_IMAGE, editClinicianImageAsync);
  yield takeEvery(actions.CREATE_CLINICIAN, createClinicianAsync);
  yield takeEvery(actions.CREATE_SUPPORT_CHAT, createSupportChatAsync);
  yield takeEvery(actions.GET_CLINICIAN, getClinicianAsync);
  yield takeEvery(actions.TOGGLE_CLINICIAN_ACTIVATION, toggleClinicianActivationAsync);
  yield takeEvery(actions.GET_CLINICIAN_DEACTIVATION_REASONS, getClinicianDeactivationReasonsAsync);
  yield takeEvery(actions.GET_ASSIGNABLE_CLINICIANS, getAssignableCliniciansAsync);
}
