import { createReducer } from "redux-create-reducer";
import actions from "../actions";

const initialState = {
  clinicians: [],
  deactivatedClinicians: [],
  page: 0,
  rowsPerPage: 10,
  count: 0,
  sort: "",
  status: "",
  sortdirection: "asc",
  clinicianLoaded: false,
  saving: false,
  saveError: false,
  clinician: {
    details: {},
    customers: [],
    behaviors: [],
    expertiseInfoId: 0,
    diagnoses: [],
    specialties: [],
    languages: [],
    certificates: [],
    licenses: [],
    degrees: [],
    ageGroups: {},
    schoolPlacements: {},
    empathyTypes: {},
    playSkills: {},
    friendAbilities: {},
    talkingLevels: {},
    verbalLevels: {},
    workflowStatus: "",
  },
  inviteClinicianLoading: false,
  inviteClinicianSuccess: false,
  inviteClinicianError: null,
  deactivationReasons: [],
  assignableClinicians: [],
  assignableCliniciansLoading: false,
  cliniciansLoading: false,
};

const reducers = {
  [actions.LOAD_CLINICIANS_LIST](state) {
    return {
      ...state,
      cliniciansLoading: true,
    };
  },
  [actions.LOAD_CLINICIANS_LIST_FAIL](state) {
    return {
      ...state,
      cliniciansLoading: false,
    };
  },
  [actions.SET_CLINICIANS_LIST](state, { payload }) {
    return {
      ...state,
      clinicians: payload.clinicians,
      count: payload.count,
      saving: false,
      cliniciansLoading: false,
    };
  },
  [actions.GET_DEACTIVATED_CLINICIANS](state) {
    return {
      ...state,
      cliniciansLoading: true,
    };
  },
  [actions.GET_DEACTIVATED_CLINICIANS_SUCCESS](state, { payload }) {
    return {
      ...state,
      deactivatedClinicians: payload,
      count: payload[0] ? parseInt(payload[0].count) : 0,
      cliniciansLoading: false,
    };
  },
  [actions.GET_DEACTIVATED_CLINICIANS_FAIL](state) {
    return {
      ...state,
      cliniciansLoading: false,
    };
  },
  [actions.CLEAR_CLINICIANS_LIST](state) {
    return {
      ...state,
      clinicians: [],
      count: 0,
      rowsPerPage: 10,
    };
  },
  [actions.CREATE_CLINICIAN](state, { payload }) {
    return {
      ...state,
      saving: true,
    };
  },
  [actions.SET_CLINICIANS_PAGE](state, { payload }) {
    return {
      ...state,
      page: payload.page,
    };
  },
  [actions.SET_CLINICIANS_PER_PAGE](state, { payload }) {
    return {
      ...state,
      rowsPerPage: payload.rowsPerPage,
    };
  },
  [actions.SET_CLINICIANS_SORT](state, { payload }) {
    let sortdirection = state.sortdirection;
    if (state.sort && state.sort !== payload.sort) {
      sortdirection = "asc";
    } else if (state.sort) {
      sortdirection = sortdirection === "asc" ? "desc" : "asc";
    }
    return {
      ...state,
      sort: payload.sort,
      sortdirection,
    };
  },
  [actions.SET_CLINICIANS_STATUS](state, { payload }) {
    return {
      ...state,
      status: payload.status,
      page: 0,
    };
  },
  [actions.SET_CLINICIAN_DETAILS](state, { payload }) {
    return {
      ...state,
      clinicianLoaded: true,
      saving: false,
      clinician: {
        details: payload[0][0],
        customers: payload[1],
        behaviors: payload[2],
        diagnoses: payload[3],
        specialties: payload[4],
        languages: payload[5],
        certificates: payload[6],
        licenses: payload[7],
        degrees: payload[8],
        phoneNumbers: payload[9],
        address: payload[10],
        ageGroups: payload[11],
        schoolPlacements: payload[12],
        empathyTypes: payload[13],
        playSkills: payload[14],
        friendAbilities: payload[15],
        talkingLevels: payload[16],
        verbalLevels: payload[17],
      },
    };
  },
  [actions.UPDATE_CLINICIAN_BASIC_DETAILS](state) {
    return {
      ...state,
      saving: true,
      saveError: false,
    };
  },
  [actions.UPDATE_CLINICIAN_BASIC_DETAILS_SUCCESS](state, { payload }) {
    return {
      ...state,
      saving: false,
      saveError: false,
      clinician: {
        ...state.clinician,
        details: {
          ...state.clinician.details,
          ...payload,
        },
      },
    };
  },
  [actions.UPDATE_CLINICIAN_ADDRESS](state) {
    return {
      ...state,
      saving: true,
      saveError: false,
    };
  },
  [actions.UPDATE_CLINICIAN_ADDRESS_SUCCESS](state, { payload }) {
    return {
      ...state,
      saving: false,
      saveError: false,
      clinician: {
        ...state.clinician,
        address: {
          ...state.clinician.address,
          ...payload,
        },
      },
    };
  },
  [actions.ADD_CLINICIAN_PHONE](state) {
    return {
      ...state,
      saving: true,
      saveError: false,
    };
  },
  [actions.UPDATE_CLINICIAN_NPI](state) {
    return {
      ...state,
      saving: true,
      saveError: false,
    };
  },
  [actions.UPDATE_CLINICIAN_NPI_SUCCESS](state, { payload }) {
    return {
      ...state,
      saving: false,
      saveError: false,
      clinician: {
        ...state.clinician,
        details: {
          ...state.clinician.details,
          ...payload,
        },
      },
    };
  },
  [actions.ADD_CLINICIAN_LICENSE](state) {
    return {
      ...state,
      saving: true,
      saveError: false,
    };
  },
  [actions.ADD_CLINICIAN_CERTIFICATE](state) {
    return {
      ...state,
      saving: true,
      saveError: false,
    };
  },
  [actions.SET_SAVE_ERROR](state) {
    return {
      ...state,
      saving: false,
      saveError: true,
    };
  },
  [actions.CLEAR_SAVE_ERROR](state) {
    return {
      ...state,
      saveError: false,
    };
  },
  [actions.GET_CLINICIAN_SUCCESS](state, { payload }) {
    return {
      ...state,
      clinician: {
        ...state.clinician,
        details: payload[0],
      },
    };
  },
  [actions.INVITE_CLINICIAN](state) {
    return {
      ...state,
      inviteClinicianLoading: true,
      inviteClinicianSuccess: false,
      inviteClinicianError: null,
    };
  },
  [actions.INVITE_CLINICIAN_SUCCESS](state) {
    return {
      ...state,
      inviteClinicianLoading: false,
      inviteClinicianSuccess: true,
    };
  },
  [actions.INVITE_CLINICIAN_FAIL](state, { payload }) {
    return {
      ...state,
      inviteClinicianLoading: false,
      inviteClinicianSuccess: false,
      inviteClinicianError: payload,
    };
  },
  [actions.CLEAR_INVITE_CLINICIAN](state) {
    return {
      ...state,
      inviteClinicianLoading: false,
      inviteClinicianSuccess: false,
      inviteClinicianError: null,
    };
  },
  [actions.GET_CLINICIAN_DEACTIVATION_REASONS_SUCCESS](state, { payload }) {
    return {
      ...state,
      deactivationReasons: payload,
    };
  },
  [actions.GET_ASSIGNABLE_CLINICIANS](state) {
    return {
      ...state,
      assignableCliniciansLoading: true,
    };
  },
  [actions.GET_ASSIGNABLE_CLINICIANS_SUCCESS](state, { payload }) {
    return {
      ...state,
      assignableCliniciansLoading: false,
      assignableClinicians: payload,
    };
  },
};

export default createReducer(initialState, reducers);
