import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import actions from "../../actions";
import { Box, Typography } from "@mui/material";

const DynamicPageError = ({ title, message, gotToHome, signOutSession }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(actions.setPageDetails({ pageName: title || "Not found" }));
  }, [dispatch]);

  const goHome = (e) => {
    e.preventDefault();
    history.push("/");
  };

  const signOut = (e) => {
    e.preventDefault();
    dispatch(actions.signOut());
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      style={{ height: "100%", width: "100%" }}
    >
      {title && (
        <>
          <Typography component="h1" variant="h4" align="center">
            {title}
          </Typography>
          <Typography component="p" variant="body1">
            &nbsp;
          </Typography>
        </>
      )}
      {message && (
        <Typography
          component="p"
          variant="body2"
          style={{
            color: "#FFFFFF",
          }}
          align="center"
        >
          {message}{" "}
          {gotToHome && (
            <>
              <a href="/" onClick={goHome}>
                Home
              </a>
              {signOutSession && " or "}
            </>
          )}
          {signOutSession && (
            <a href="#" onClick={signOut}>
              SIGNOUT
            </a>
          )}
        </Typography>
      )}
    </Box>
  );
};

export default DynamicPageError;
