import React from "react";
import withStyles from "@mui/styles/withStyles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const AccessDeniedDialog = (props) => {
  const { classes, accessDeniedOpen, goBack, redirectIn } = props;
  return (
    <Dialog
      open={accessDeniedOpen}
      aria-labelledby="form-dialog-title"
      key="access-denied-dialog"
      style={{
        zIndex: 3000,
      }}
      PaperProps={{
        style: {
          maxWidth: 700,
          margin: 0,
          padding: "40px 40px 30px",
          boxSizing: "border-box",
        },
      }}
    >
      <DialogTitle style={{ padding: 0 }}>
        <Typography component="h2" style={{ fontWeight: 500, fontSize: 20 }}>
          ACCESS DENIED
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.infoContainer}>
          <div className={classes.textContainer}>
            <Typography component="p" style={{ fontSize: 18, marginBottom: 10 }}>
              You don't have authorization to be a part of this session. Return to the dashboard.
            </Typography>
            <Typography component="p" style={{ fontSize: 16 }}>
              You will be automatically redirected in{" "}
              <span style={{ color: "red", fontWeight: 500 }}>{redirectIn} seconds</span>
            </Typography>
          </div>
        </div>
      </DialogContent>
      <DialogActions style={{ margin: 0, padding: 0 }}>
        <Button
          onClick={goBack}
          color="primary"
          variant="text"
          className={classes.dialogAction}
          style={{
            fontWeight: 500,
            color: "#848384",
            fontSize: 20,
            letterSpacing: ".85px",
          }}
        >
          LEAVE SESSION
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const styles = (theme) => ({
  dialogContent: {
    width: 700,
    height: "fit-content",
    maxHeight: "95%",
    maxWidth: "100%",
    color: "#404040",
    padding: 0,
  },
  infoContainer: {
    display: "flex",
    alignItems: "flex-start",
  },
  textContainer: {
    width: "100%",
    padding: "15px 0",
    boxSizing: "border-box",
  },
  confirmText: {
    fontSize: 17,
    fontWeight: 500,
    margin: "20px 0",
  },
  dialogAction: {
    fontSize: 17,
    fontWeight: 500,
  },
});

export default withStyles(styles)(AccessDeniedDialog);
