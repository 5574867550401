export const styles = (theme) => ({
  criticalNeedsTitle: {
    fontSize: 20,
    fontWeight: "500",
    color: "#1C0D28",
    margin: "20px 0 16px",
  },
  criticalNeedsContainer: {
    width: "100%",
    height: 170,
    display: "flex",
    flexDirection: "row",
    borderRadius: 5,
    borderBottomLeftRadius: 0,
    overflow: "hidden",
    boxShadow: "0px 0px 6px .5px rgba(0,0,0,0.15)",
  },
  needsContainer: {
    width: "70%",
    display: "flex",
    flexDirection: "column",
    borderRight: "1px solid #B4B8C7",
  },
  criticalLevel: {
    width: "100%",
    height: 85,
    display: "flex",
    flexDirection: "row",
  },
  criticalIconContainer: {
    width: 75,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FECACA",
    borderBottom: "2px solid #B91C1C",
  },
  criticalText: {
    width: "calc(100% - 75px)",
    heigth: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "0 22px",
    borderBottom: "1px solid #B4B8C7",
    "& p": {
      fontSize: 16,
      fontWeight: "500",
      color: "#3F4456",
    },
    "& span": {
      fontWeight: 500,
      color: "#B91C1C",
    },
  },
  warningLevel: {
    width: "100%",
    height: 85,
    display: "flex",
    flexDirection: "row",
  },
  warningIconContainer: {
    width: 75,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FDE68A",
    borderBottom: "2px solid #D97706",
  },
  warningText: {
    width: "calc(100% - 75px)",
    heigth: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "0 22px",
    "& p": {
      fontSize: 16,
      fontWeight: "500",
      color: "#3F4456",
    },
    "& span": {
      fontWeight: 500,
      color: "#D97706",
    },
  },
  checkText: {
    width: "calc(100% - 75px)",
    heigth: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "0 22px",
    "& p": {
      fontSize: 16,
      fontWeight: "500",
      color: "#3F4456",
    },
    "& span": {
      fontWeight: 500,
      color: "#287958",
    },
  },
  viewNeedsClients: {
    width: "30%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  checkIconContainer: {
    backgroundColor: "#B3EAD080",
    width: 75,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderBottom: "2px solid #287958",
  },
});
