import { getClientVideoCallLogs } from "api/customers";
import { useQuery } from "react-query";

export const useGetClientVideoCallLogs = (clientId, videoCallId) => {
  return useQuery(
    ["clientVideoCallLogs", clientId, videoCallId],
    () => getClientVideoCallLogs(clientId, videoCallId),
    { enabled: !!videoCallId && !!clientId }
  );
};
