import React from "react";
import { Typography, Chip, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { ArrowLeft, ArrowRight } from "@phosphor-icons/react";

const CptHeader = (props) => {
  const {
    classes,
    noteCompleted,
    associatedSessionNotes,
    currentIndex,
    onChangeNoteIndex,
    videoId,
    serviceType,
    setAssociatedTimesLoading,
    downloadViewOnly,
  } = props;
  const service = videoId ? associatedSessionNotes[currentIndex] : serviceType;
  const clinicianName =
    associatedSessionNotes.length && associatedSessionNotes[currentIndex]
      ? associatedSessionNotes[currentIndex].clinician_name
      : null;
  return (
    <div className={classes.cptHeaderContainer}>
      <div className={classes.cptHeaderTitleContainer}>
        <div className={classes.cptHeader}>
          <div className={classes.cptChip}>
            <Typography component="p" variant="h4">
              {service?.description || ""}
            </Typography>
            <Chip
              label={noteCompleted ? "Submitted" : "Draft"}
              size="small"
              color={noteCompleted ? "success" : "default"}
              variant="outlined"
              sx={
                noteCompleted
                  ? { backgroundColor: "#E8F5E9" }
                  : {
                      backgroundColor: "#f6f7f8",
                      border: "1px solid #CDD3DA",
                      color: "#31333A",
                    }
              }
            />
          </div>
          <Typography component="p" variant="captionSecondary">
            {service?.code === "00000" || service?.code === "99999" ? " " : service?.code || " "}
            {downloadViewOnly && clinicianName && ` - Submitted by ${clinicianName}`}
          </Typography>
        </div>
      </div>
      {associatedSessionNotes?.length > 1 && (
        <ToggleButtonGroup size="small">
          <ToggleButton
            onClick={() => onChangeNoteIndex(currentIndex - 1)}
            disableRipple
            disabled={setAssociatedTimesLoading}
            value="left"
            aria-label="left aligned"
          >
            <ArrowLeft size={16} color="rgba(91, 97, 114, 1)" />
          </ToggleButton>
          <ToggleButton
            onClick={() => onChangeNoteIndex(currentIndex + 1)}
            disableRipple
            disabled={setAssociatedTimesLoading}
            value="center"
            aria-label="centered"
          >
            <ArrowRight size={16} color="rgba(91, 97, 114, 1)" />
          </ToggleButton>
        </ToggleButtonGroup>
      )}
    </div>
  );
};

export default CptHeader;
