import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import MenuItem from "@mui/material/MenuItem";
import withStyles from "@mui/styles/withStyles";
import styles from "../styles";
import ANDatePicker from "elements/ANDatePicker";
import { isDischargeReason, isDropReason } from "../../../utils/reason";
import { useGlobalToast } from "components/GlobalToastProvider";
import { Box } from "@mui/material";

const ClientActivation = (props) => {
  const {
    onSubmitToggleActivation,
    reason,
    dropReason,
    churnReason: dischargeReason,
    departedTo,
    customerDepartureOptions,
    disabledDate,
    onChangeDeactivationInfo,
    onClearDeactivationInfo,
    customerDeactivationReasons,
    isActive,
    isRoleClinician,
    clinicianDeactivationReasons,
    customerDropReasons,
    customerChurnReasons: customerDischargeReasons,
    numberOfClients,
    hasScheduledCall,
    userPermissions,
  } = props;

  // State for dialog visibility
  const [dialogOpen, setDialogOpen] = useState(false);

  // Dialog open/close handlers
  const handleDialogOpen = () => setDialogOpen(true);
  const handleDialogClose = () => setDialogOpen(false);

  // Deactivation reasons based on role
  const deactivationReasons = isRoleClinician
    ? clinicianDeactivationReasons
    : customerDeactivationReasons;
  const dropReasons = customerDropReasons;
  const dischargeReasons = customerDischargeReasons;
  const departureOptions = customerDepartureOptions;

  const enableDropReasons = dropReasons && isDropReason(reason);
  const enableDischargeReasons =
    !enableDropReasons && dischargeReasons && isDischargeReason(reason);

  const { showToast } = useGlobalToast();
  const activationHandler = () => {
    try {
      onSubmitToggleActivation();
      onClearDeactivationInfo();
      handleDialogClose();
      showToast({
        message: `Success! Client account ${isActive ? "deactivated" : "activated"}`,
      });
    } catch (e) {
      console.error(e);
      showToast({
        errorState: true,
        message: `Whoops! Client account ${isActive ? "deactivation" : "activation"} failed`,
      });
    }
  };

  const reactivationReq = !isActive && userPermissions.reactivate_clients;
  const deactivationReq = isActive && userPermissions.disable_client;

  return (
    <Box minWidth={"max-content"}>
      <Button
        variant={isActive ? "contained" : "contained"}
        color={isActive ? "error" : "secondary"}
        onClick={handleDialogOpen}
        fullWidth
        disabled={!reactivationReq && !deactivationReq}
      >
        {isActive ? "Deactivate Client" : "Activate Client"}
      </Button>
      <Dialog open={dialogOpen} onClose={handleDialogClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {isActive ? "Deactivate Client" : "Activate Client"}
        </DialogTitle>
        <DialogContent>
          {isActive ? (
            <>
              {/* Reason for deactivation or activation */}
              <TextField
                select
                fullWidth
                label="Deactivation timeframe"
                value={reason}
                name="reason"
                onChange={onChangeDeactivationInfo}
                margin="normal"
              >
                {deactivationReasons.map((option) => (
                  <MenuItem key={option.id} value={option.reason}>
                    {option.reason}
                  </MenuItem>
                ))}
              </TextField>

              {/* Conditionally rendered drop reason */}
              {enableDropReasons && (
                <TextField
                  select
                  fullWidth
                  label="Drop reason"
                  name="dropReason"
                  value={dropReason}
                  onChange={onChangeDeactivationInfo}
                  margin="normal"
                >
                  {dropReasons.map((option) => (
                    <MenuItem key={option.id} value={option.reason}>
                      {option.reason}
                    </MenuItem>
                  ))}
                </TextField>
              )}

              {/* Conditionally rendered discharge reason */}
              {enableDischargeReasons && (
                <TextField
                  select
                  fullWidth
                  label="Discharge reason"
                  id="churn-reason-menu"
                  name="churnReason"
                  value={dischargeReason}
                  onChange={onChangeDeactivationInfo}
                  margin="normal"
                >
                  {dischargeReasons.map((option) => (
                    <MenuItem key={option.id} value={option.reason}>
                      {option.reason}
                    </MenuItem>
                  ))}
                </TextField>
              )}

              {/* Departed to (destination after deactivation) */}
              <TextField
                select
                fullWidth
                label="Where did they go?"
                name="departedTo"
                value={departedTo}
                onChange={onChangeDeactivationInfo}
                margin="normal"
              >
                {departureOptions.map((option) => (
                  <MenuItem key={option.id} value={option.option}>
                    {option.option}
                  </MenuItem>
                ))}
              </TextField>

              {/* Deactivation date picker */}
              <ANDatePicker
                value={disabledDate}
                onChange={(date) =>
                  onChangeDeactivationInfo({ target: { name: "disabledDate", value: date } })
                }
                fullWidth={true}
                format="PP"
                placeholderText="Choose a date"
                label="Deactivation date"
                sx={{ marginTop: "16px" }}
                slotProps={{
                  field: {
                    fullWidth: true,
                  },
                  popper: {
                    disablePortal: false,
                    style: { zIndex: 1300 },
                  },
                }}
              />

              {/* Alerts for specific conditions */}
              {!isRoleClinician && numberOfClients === 0 && (
                <Alert severity="error" style={{ marginTop: "10px" }}>
                  This clinician is currently assigned to a client.
                </Alert>
              )}
              {hasScheduledCall && (
                <Alert severity="error" style={{ marginTop: "10px" }}>
                  The client has scheduled calls. Please cancel all calls before deactivation.
                </Alert>
              )}
            </>
          ) : (
            <Typography>
              Would you like to activate the Primary Account holder for this client?
            </Typography>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              onClearDeactivationInfo();
              handleDialogClose();
            }}
            variant="outlined"
            color="default"
          >
            Cancel
          </Button>
          <Button
            disabled={
              isActive &&
              (!reason ||
                !disabledDate ||
                !departedTo ||
                (enableDischargeReasons && !dischargeReason) ||
                (enableDropReasons && !dropReason) ||
                (isRoleClinician && numberOfClients > 0) ||
                hasScheduledCall)
            }
            onClick={activationHandler}
            color={isActive ? "error" : "primary"}
          >
            {`${isActive ? "Deactivate" : "Activate"} client`}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default withStyles(styles)(ClientActivation);
