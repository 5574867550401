import React from "react";
import Modal from "elements/Modal";

const ConfirmCredentialedDialog = (props) => {
  const { confirmCredentialedOpen, onToggleDialog, applicationLoading, submitCredentialed } = props;

  return (
    <Modal
      open={confirmCredentialedOpen}
      onClose={onToggleDialog}
      title="Confirm Clinician is Credentialed"
      titleCentered
      description="Please confirm that this clinician is now credentialed with at least one insurance company."
      loading={applicationLoading}
      primaryActionText="Submit"
      primaryActionOnClick={submitCredentialed}
      primaryActionDisabled={applicationLoading}
      secondaryActionText="Cancel"
      secondaryActionOnClick={onToggleDialog}
      secondaryActionDisabled={applicationLoading}
    />
  );
};

export default ConfirmCredentialedDialog;
