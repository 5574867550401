import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import Snackbar from "@mui/material/Snackbar";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
});

class Error extends Component {
  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.props.onClose();
  };

  render() {
    const { classes, errorMessage, open } = this.props;
    return (
      <div className={classes.root}>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={open}
          autoHideDuration={5000}
          onClose={this.handleClose}
          style={{
            zIndex: 2010,
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          <Alert severity="error">
            {errorMessage || "An error has occurred. Please try again"}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

Error.propTypes = {
  classes: PropTypes.object.isRequired,
  errorMessage: PropTypes.string,
};

export default withStyles(styles)(Error);
