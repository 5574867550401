import React from "react";
import { Snackbar, Button, Stack, Typography } from "@mui/material";

/**
 * GlobalToast is designed to display global toast notifications.
 * It is intended to be used with the corresponding `useGlobalToast` hook which manages
 * its visibility, message content, error state, and retry functionality. This component
 * should be included once, typically at the root level of your application, such as in App.js.
 * The `useGlobalToast` hook controls the props passed down to this component.
 *
 * @param {Object} props The component props.
 * @param {boolean} props.open Controls the visibility of the toast.
 * @param {string} props.message The message to be displayed inside the toast.
 * @param {boolean} [props.errorState=false] Determines if the toast is showing an error message.
 * @param {Function} props.onClose Function to call when the toast is closed.
 * @param {Function} [props.retryHandler=null] Optional retry function triggered upon 'Try again' button click.
 * @returns {React.Component} Rendered GlobalToast.
 */
export const GlobalToast = ({
  open,
  message,
  errorState = false,
  onClose,
  retryHandler = null,
  errorButtonText = "Try again",
}) => (
  <Snackbar open={open} autoHideDuration={6000} onClose={onClose}>
    <Stack spacing={errorState ? 2 : 1} alignItems="center">
      <Typography align="center">{message}</Typography>
      {errorState && retryHandler && (
        <Button color="primary" variant="text" onClick={retryHandler}>
          {errorButtonText}
        </Button>
      )}
    </Stack>
  </Snackbar>
);

export default GlobalToast;
