import momentTZ from "moment-timezone";
import moment from "moment/moment";

/**
 * Converts a time string to a moment object.
 *
 * @param {string} time - The time string in format "h:mm A".
 * @returns {moment} The moment object representing the converted time.
 */
export const timeToMoment = (time) => momentTZ(time, "h:mm A");

/**
 * Converts a time string to a moment object taking a given timezone.
 *
 * @param {string} time - The time string in format "h:mm A".
 * @param {string} timezone - The timezone to apply the moment object.
 * @returns {string} A string representing the converted time.
 */
export const timeWithTimezoneToString = (time, timezone) => {
  if (!timezone) {
    console.log("timezone not provided");
    return timeToMoment(time);
  }

  return timeToMoment(time).tz(timezone).format("h:mm A");
};

export const getISODateTime = (date, time, timezone) =>
  `${date}T${timeToMoment(time).tz(timezone).format("HH:mm:ssZ")}`;

export const getDateString = (date) => moment(date).format("YYYY-MM-DD");

/**
 * Adds the specified number of minutes to the given String time.
 *
 * @param {string} time - The time in the format "h:mm A".
 * @param {number} minutes - The number of minutes to add.
 * @param {string} timezone - The timezone for the time. Defaults to the computer's timezone.
 * @returns {string} The resulting time in the format "h:mm A".
 */
export const addMinutesToTime = (time, minutes, timezone = moment.tz.guess()) =>
  timeToMoment(time).add(minutes, "minutes").tz(timezone).format("h:mm A");
