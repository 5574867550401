export default (theme) => ({
  main: {
    width: "100%",
    display: "block", // Fix IE 11 issue.
    minHeight: "100vh",
    background: "#fff",
    paddingTop: "10px",
    overflow: "hidden",
  },
  eligibilityPaper: {
    width: "100%",
    minHeight: "100%",
    margin: "0",
    boxShadow: "none",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: 20,
  },
  signupTitle: {
    textAlign: "center",
    width: "70%",
    margin: "15px 0",
    fontWeight: 500,
    color: "#505050",
    fontSize: "2rem",
    [theme.breakpoints.down("xl")]: {
      width: "90%",
      fontSize: "1.4rem",
    },
  },
  secondaryTitle: {
    textAlign: "center",
    width: "70%",
    margin: "25px 0 15px",
    fontWeight: 500,
    color: "#505050",
    fontSize: "1.5rem",
    [theme.breakpoints.down("xl")]: {
      width: "90%",
      fontSize: "1.2rem",
    },
  },
  eligibilityInstructions: {
    fontSize: "1rem",
    textAlign: "center",
    lineHeight: "1.6rem",
    width: "60%",
    marginBottom: 15,
    color: "#8a8a8a",
    fontWeight: 500,
    [theme.breakpoints.down("xl")]: {
      width: "90%",
      fontSize: ".9rem",
      lineHeight: "1.4rem",
    },
  },
  sectionHeading: {
    fontSize: ".95rem",
    fontWeight: 500,
    color: "rgba(0, 0, 0, 0.87)",
    marginTop: 25,
  },
  formLabel: {
    marginTop: 15,
    color: "rgba(0, 0, 0, 0.54)",
  },
  smallButton: {
    fontSize: ".7rem",
    textTransform: "none",
    marginTop: 10,
  },
  buttonWrapper: {
    width: 240,
    margin: "30px 4px 15px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  buttonProgress: {
    color: "#ccc",
    // position: "absolute",
    // top: "50%",
    // left: "50%",
    // marginTop: theme.spacing(-),
    // marginLeft: -12,
  },
  fileNames: {
    fontSize: ".85rem",
    display: "block",
    color: "rgba(0, 0, 0, 0.87)",
  },
  terms: {
    fontSize: ".8rem",
    color: "rgba(0, 0, 0, 0.87)",
  },
  termsBox: {
    marginTop: `${theme.spacing(3)}`,
  },
  successMessageContainer: {
    height: "85vh",
    width: "95%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  successMessage: {
    fontSize: "1.3rem",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("xl")]: {
      fontSize: "1.2rem",
    },
  },
  helpIcon: {
    marginLeft: theme.spacing(),
    width: "0.95rem",
    height: "0.95rem",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: 0,
    marginLeft: 20,
    [theme.breakpoints.up("md")]: {
      width: "30%",
    },
    [theme.breakpoints.down("xl")]: {
      padding: 0,
      margin: 0,
    },
    background: "#fff",
    boxShadow: "none",
  },
  paperError: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`,
    marginTop: 40,
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
      width: 352,
      marginLeft: "auto",
      marginRight: "auto",
    },
    boxShadow: "none",
  },
  logo: {
    width: 170,
    height: "auto",
    margin: "5px 0 20px 0",
    alignSelf: "center",
  },
  title: {
    color: "#7f7e81",
  },
  form: {
    width: "40%", // Fix IE 11 issue.
    marginTop: 15,
    [theme.breakpoints.down("xl")]: {
      width: "90%",
    },
  },
  submit: {
    marginTop: 5,
    boxShadow: "none",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#9c00d5",
    },
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  body: {
    marginTop: 25,
    fontSize: 15,
  },
  errorMessage: {
    fontSize: 14,
    color: "red",
    textAlign: "center",
    margin: "10px auto",
  },
  successMessage: {
    fontSize: 14,
    color: "green",
    textAlign: "center",
    margin: "10px auto",
  },
  waitingMessage: {
    fontSize: 16,
    textAlign: "center",
    margin: "10px auto",
  },
  stepperContainer: {
    width: "100%",
    height: 60,
    backgroundColor: "#ede1f5",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 20,
    [theme.breakpoints.down("xl")]: {
      height: 45,
      marginBottom: 10,
    },
  },
  stepTitleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  stepText: {
    color: "#9e82b0",
    fontSize: 14,
    fontWeight: 500,
    [theme.breakpoints.down("xl")]: {
      fontSize: 10,
    },
  },
  stepTextCurrent: {
    color: "#51186b",
    fontSize: 14,
    fontWeight: 500,
    [theme.breakpoints.down("xl")]: {
      fontSize: 10,
    },
  },
  stepChevron: {
    color: "#9e82b0",
    fontSize: 30,
    margin: "0 20px",
    [theme.breakpoints.down("xl")]: {
      fontSize: 20,
      margin: "0 2px 0 0",
    },
  },
  stepChevronCurrent: {
    color: "#51186b",
    fontSize: 30,
    margin: "0 20px",
    [theme.breakpoints.down("xl")]: {
      fontSize: 20,
      margin: "0 2px 0 0",
    },
  },
  stepButton: {
    width: 100,
    height: 40,
    boxShadow: "none",
    color: "#fff",
    backgroundColor: "#9c00d5",
    borderRadius: 8,
    "&:hover": {
      backgroundColor: "#51186b",
      cursor: "pointer",
    },
    "& span": {
      color: "#fff",
      fontWeight: 500,
      textTransform: "none",
    },
  },
  stepButtonDisabled: {
    backgroundColor: "#be9de9 !important",
  },
  buttonLabel: {
    fontSize: 11,
    color: "#4e4e4e",
    height: 12,
    width: "100%",
    paddingLeft: 9,
  },
  toggleButton: {
    border: "2px solid #c4c4c4",
    backgroundColor: "#fff",
    boxShadow: "none",
    borderRadius: 8,
    "&:hover": {
      backgroundColor: "#ede1f5",
      boxShadow: "none",
      cursor: "pointer",
    },
    "& span": {
      color: "#757575",
      textTransform: "none",
    },
  },
  toggleButtonSelected: {
    border: "2px solid #9c00d5",
    backgroundColor: "#ede1f5",
    borderRadius: 8,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#ede1f5",
      boxShadow: "none",
      cursor: "pointer",
    },
    "& span": {
      color: "#570071",
      textTransform: "none",
    },
  },
  insuranceOptions: {
    width: "90%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
  },
  insuranceButton: {
    margin: "10px",
    width: 130,
    height: 80,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "2px solid #c4c4c4",
    borderRadius: 8,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#ede1f5",
    },
  },
  insuranceButtonSelected: {
    width: 130,
    height: 80,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "2px solid #4d3371",
    boxShadow: "0 2px 3px 0 rgba(0,0,0,.4)",
    borderRadius: 8,
    margin: "0 10px",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#ede1f5",
    },
  },
  insuranceLogo: {
    height: "90%",
    width: "90%",
    objectFit: "contain",
  },
  uploadButtonWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    margin: "30px 0 10px 0",
    [theme.breakpoints.down("xl")]: {
      flexDirection: "column",
      justifyContent: "space-between",
      marginTop: 20,
      width: 280,
    },
  },
  uploadButton: {
    width: 200,
    height: 160,
    border: "2px solid #c4c4c4",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    padding: "5px 20px",
    margin: "0 10px",
    borderRadius: 8,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#ede1f5",
    },
    [theme.breakpoints.down("xl")]: {
      margin: "10px 0",
      width: "100%",
    },
  },
  uploadIcon: {
    width: 60,
    height: 60,
    objectFit: "contain",
  },
  uploadText: {
    color: "#4b4a4b",
    fontSize: 16,
    textAlign: "center",
    fontWeight: 500,
  },
  hasDiagnosisWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    margin: "30px 0",
    [theme.breakpoints.down("xl")]: {
      flexDirection: "column",
      width: "90%",
      margin: 0,
    },
  },
  hasDiagnosisButton: {
    width: 180,
    height: 180,
    border: "2px solid #c4c4c4",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    padding: "5px",
    margin: "0 10px",
    borderRadius: 8,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#ede1f5",
    },
    [theme.breakpoints.down("xl")]: {
      margin: "10px 0",
      width: "100%",
    },
  },
  hasDiagnosisSelected: {
    width: 180,
    height: 180,
    border: "2px solid #9c00d5",
    backgroundColor: "#ede1f5",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    padding: "5px",
    margin: "0 10px",
    borderRadius: 8,
    "&:hover": {
      cursor: "pointer",
    },
    [theme.breakpoints.down("xl")]: {
      margin: "10px 0",
      width: "100%",
    },
  },
  uploadDiagnosisButton: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "5px 10px",
    border: "2px solid #c4c4c4",
    borderRadius: 8,
    marginBottom: 10,
    overflow: "hidden",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#ede1f5",
    },
  },
  uploadDiagnosisIcon: {
    width: 40,
    height: 40,
    objectFit: "contain",
  },
  uploadDiagnosisTextWrapper: {
    flex: 1,
    height: 45,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "flex-start",
    marginLeft: 20,
  },
  uploadDiagnosisTitle: {
    color: "#4b4a4b",
    fontSize: 16,
    fontWeight: 500,
    [theme.breakpoints.down("xl")]: {
      fontSize: 12,
    },
  },
  uploadDiagnosisSubtitle: {
    color: "#8a8a8a",
    fontSize: 12,
    fontWeight: 500,
    whiteSpace: "nowrap",
  },
  insuranceProviderPreview: {
    width: "100%",
    height: 80,
    display: "flex",
    justifyContent: "center",
    boxShadow: "0 2px 3px 0 rgba(0,0,0,.4)",
    alignItems: "center",
    border: "2px solid #c4c4c4",
    borderRadius: 8,
    overflow: "hidden",
  },
  insurancePreview: {
    width: "100%",
    height: 80,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #c4c4c4",
    borderRadius: 4,
    overflow: "hidden",
  },
  insuranceCardPreview: {
    width: "100%",
    height: 80,
    objectFit: "cover",
  },
  signatureCanvas: {
    backgroundColor: "#fff",
    borderRadius: 8,
    border: "2px dashed #c4c4c4",
    overflow: "hidden",
    marginTop: 10,
    width: "fit-content",
  },
  agreeToTermsText: {
    width: "60%",
    margin: "15px 0 5px",
    fontSize: ".85rem",
    color: "#5c5c5c",
    [theme.breakpoints.down("xl")]: {
      width: "90%",
    },
  },
  diagnosisFormPreview: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "5px 10px",
    border: "2px solid #c4c4c4",
    borderRadius: 8,
    marginBottom: 10,
    overflow: "hidden",
  },
  openDiagnosisDialogText: {
    fontSize: "1rem",
    textAlign: "center",
    lineHeight: "1.6rem",
    width: "100%",
    margin: "15px 0",
    color: "#8a8a8a",
    fontWeight: 500,
    "&:hover": {
      cursor: "pointer",
      color: "#787878",
    },
    [theme.breakpoints.down("xl")]: {
      width: "90%",
    },
  },
  rejectionContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  phiTitleContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 20,
  },
  phiTitle: {
    fontSize: 18,
    fontWeight: 500,
    textAlign: "center",
    [theme.breakpoints.down("xl")]: {
      fontSize: 15,
    },
  },
  phiSubtitle: {
    fontSize: 14,
    [theme.breakpoints.down("xl")]: {
      fontSize: 12,
    },
  },
  phiFormList: {
    width: "100%",
    padding: 20,
    [theme.breakpoints.down("xl")]: {
      padding: 0,
    },
  },
  phiListItem: {
    fontSize: 14,
    margin: "15px 0 5px 0",
    [theme.breakpoints.down("xl")]: {
      fontSize: 12,
    },
  },
  phiListItemDetailContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    margin: "0 30px",
    [theme.breakpoints.down("xl")]: {
      margin: "0 10px",
    },
  },
  phiListItemDetailLabel: {
    fontSize: 14,
    width: 240,
    [theme.breakpoints.down("xl")]: {
      width: "fit-content",
      fontSize: 12,
    },
  },
  phiListItemDetailValue: {
    fontSize: 14,
    margin: "5px 20px",
    [theme.breakpoints.down("xl")]: {
      fontSize: 12,
      margin: "5px",
    },
  },
  phiListItemDetail: {
    fontWeight: 500,
    marginLeft: 30,
    fontSize: 14,
    [theme.breakpoints.down("xl")]: {
      fontSize: 12,
      margin: "0 10px",
    },
  },
  divider: {
    width: "100%",
    height: 0,
    border: "1px solid #000",
    margin: "20px 0",
  },
  diagnosisBulletText: {
    fontSize: 16,
    textAlign: "left",
    [theme.breakpoints.down("xl")]: {
      fontSize: 14,
    },
  },
  dialogPaper: {
    margin: 0,
  },
  formLogo: {
    width: 170,
    height: "auto",
    margin: "5px 0 20px 0",
    alignSelf: "center",
    [theme.breakpoints.down("xl")]: {
      margin: 0,
      width: 120,
    },
  },
});
