import { UserPresenceLevel } from "video/meeting/MeetingRoster";
import { log } from "video/debug";
import { extractUserIdFromMessage } from "video/messaging/util";

export default class UserPingController {
  /** @type {UserPresenceLevel} */
  presenceLevel = 0;

  /**
   * @param {import('video/messaging/MessagingSession').default} messagingSession
   * @param {UserPresenceLevel} presenceLevel
   */
  constructor(messagingSession, presenceLevel) {
    this.presenceLevel = presenceLevel;
    this.messagingSession = messagingSession;
    this.messagingSession.addMessageListener(this.processPongMessage, "PO");
    this.messagingSession.addMessageListener(this.processPingMessage, "PI");
  }

  destroy() {
    this.messagingSession.removeMessageListener(this.processPongMessage, "PO");
    this.messagingSession.removeMessageListener(this.processPingMessage, "PI");
  }

  /**
   * Called when we receive a PI(ng) message from the given `userId`
   * - pass '*' as userId to listen to any user pings
   * @param {string} [userId="*"]
   * @param {(userId:string,userPresenceLevel:UserPresenceLevel,CreatedTimestamp:Date)=>void} cb
   */
  onUserPing(userId, cb) {
    if (!this._onUserPing[userId]) this._onUserPing[userId] = [];
    this._onUserPing[userId].push(cb);
  }
  /** @type {{[userId:string]:(userId:string,userPresenceLevel:UserPresenceLevel,CreatedTimestamp:Date)=>void}} */
  _onUserPing = { "*": [] };
  __onUserPing(
    userId = "",
    presenceLevel = UserPresenceLevel.ABSENT,
    CreatedTimestamp = new Date()
  ) {
    this._onUserPing["*"].forEach((cb) => cb(userId, presenceLevel, CreatedTimestamp));
    this._onUserPing[userId]?.forEach((cb) => cb(userId, presenceLevel, CreatedTimestamp));
  }

  /**
   * Called when we receive a PO(ng) message from the given `userId`
   * - pass '*' as userId to listen to any user pongs
   * @param {string} [userId="*"]
   * @param {(userId:string,userPresenceLevel:UserPresenceLevel,CreatedTimestamp:Date)=>void} cb
   */
  onUserPong(userId, cb) {
    if (!this._onUserPong[userId]) this._onUserPong[userId] = [];
    this._onUserPong[userId].push(cb);
  }
  /** @type {{[userId:string]:(userId:string,userPresenceLevel:UserPresenceLevel,CreatedTimestamp:Date)=>void}} */
  _onUserPong = { "*": [] };
  __onUserPong(
    userId = "*",
    presenceLevel = UserPresenceLevel.ABSENT,
    CreatedTimestamp = new Date()
  ) {
    this._onUserPong["*"].forEach((cb) => cb(userId, presenceLevel, CreatedTimestamp));
    this._onUserPong[userId]?.forEach((cb) => cb(userId, presenceLevel, CreatedTimestamp));
  }

  /**
   * @param {string[]} userIds if empty, send a ping without targeting anybody (i.e. send to all channel members)
   */
  async pingUsers(userIds = []) {
    log("-> ping: ", userIds, this.presenceLevel, this.__id);
    await this.messagingSession
      ?.sendNonPersistentControlMessage(`${this.presenceLevel}`, "PI", userIds)
      .catch(console.error);
  }

  /**
   * @param {string[]} userIds
   */
  async pongUsers(userIds = []) {
    log("-> pong: ", userIds, this.presenceLevel, this.__id);
    this.messagingSession
      ?.sendNonPersistentControlMessage(`${this.presenceLevel}`, "PO", userIds)
      .catch(console.error);
  }

  /**
   * @param {import('@aws-sdk/client-chime-sdk-messaging').ChannelMessage} message
   */
  processPingMessage = async (message) => {
    // dbg("processPingMessage", message);
    const userId = extractUserIdFromMessage(message);
    if (!userId) console.warn(message);
    const presenceLevel = parseInt(message.Content, 10);
    this.__onUserPing(userId, presenceLevel, message.CreatedTimestamp);
  };

  /**
   * @param {import('@aws-sdk/client-chime-sdk-messaging').ChannelMessage} message
   */
  processPongMessage = (message) => {
    // dbg("processPongMessage", message);
    const userId = extractUserIdFromMessage(message);
    if (!userId) console.warn(message);
    const presenceLevel = parseInt(message.Content, 10);
    this.__onUserPong(userId, presenceLevel, message.CreatedTimestamp);
  };
}
