import React from "react";
import { Button, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";

const ANNoResultsOverlay = ({ handleClearFilters }) => {
  const theme = useTheme();
  return (
    <Stack height="100%" justifyContent="center" alignItems="center">
      <Stack alignItems="center" mb={theme.spacing(5)}>
        <Typography variant="h3">No Results Found</Typography>
        <Typography variant="body1" color={theme.palette.text.secondary}>
          Try adjusting your search or filter options to find what you are looking for.
        </Typography>
      </Stack>
      <Button color="info" variant="text" onClick={handleClearFilters}>
        Clear Filters
      </Button>
    </Stack>
  );
};

export default ANNoResultsOverlay;
