import React from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import { X, PencilSimpleLine } from "@phosphor-icons/react";
/** @typedef {import("api/typesDef").CalendarEvent} CalendarEvent */

/**
 * The `ConfirmEditEventDialog` component renders a dialog that asks the user to confirm changes to an event.
 * It explains that changes will apply only to the single event instance, not the recurring series.
 * It also provides information about editing recurring events and warns that participants
 * will be notified of any changes.
 *
 * @component
 * @param {Object} props - The component props
 * @param {CalendarEvent} props.event - The event object being edited
 * @param {boolean} props.isLoading - Whether a save operation is in progress
 * @param {boolean} props.open - Whether the dialog is open
 * @param {Function} props.onClose - Function to call when the dialog is closed
 * @param {Function} props.onSaveEvent - Function to call when the save button is clicked
 * @returns {React.ReactElement} The rendered component
 */
const ConfirmEditEventDialog = ({ event, isLoading, open, onClose, onSaveEvent }) => (
  <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
    <DialogTitle>
      <Typography variant="h4">Save Changes To This Event?</Typography>
      <IconButton aria-label="close" onClick={onClose} disabled={isLoading}>
        <X />
      </IconButton>
    </DialogTitle>

    <DialogContent>
      <Typography component="p" variant="body1" mb={3}>
        Saving changes will only apply to this single event.
      </Typography>

      <Alert
        severity="info"
        sx={{ mb: 3 }}
        action={
          <Button
            variant="contained"
            color="secondary"
            size="small"
            startIcon={<PencilSimpleLine format="outline" weight="duotone" />}
            component={Link}
            href={`/clinician-schedule/create-event?id=${event?.id}&isNonVideoEvent=${!event?.is_video_call}`}
          >
            Edit
          </Button>
        }
      >
        <AlertTitle>Looking to update the recurring schedule?</AlertTitle>
        <Typography component="p" variant="body2" sx={{ maxWidth: "440px" }}>
          Click and edit any recurring event to make changes to the recurring schedule.
        </Typography>
      </Alert>

      <Alert severity="warning">Event participants will receive event updates</Alert>
    </DialogContent>

    <DialogActions>
      <Button onClick={onClose} color="secondary" disabled={isLoading}>
        Cancel
      </Button>

      <LoadingButton onClick={onSaveEvent} color="primary" loading={isLoading} disabled={isLoading}>
        Save and update event
      </LoadingButton>
    </DialogActions>
  </Dialog>
);

export default ConfirmEditEventDialog;
