import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import RefreshIcon from "@mui/icons-material/Refresh";
import GaugeChart from "react-gauge-chart";
import Grow from "@mui/material/Grow";
import Auth from "@aws-amplify/auth";
import axios from "axios";
import styles from "../styles";
import Modal from "elements/Modal";
import { getAWSCredentialsForCurrentUserSession } from "utils/aws";

const CancelToken = axios.CancelToken;
let cancel;
const initialState = () => {
  return {
    uploadDocument: null,
    uploadSpeedLog: [],
    uploadPercent: 0,
    uploadProgress: 0,
    downloadSpeedLog: [],
    downloadPercent: 0,
    downloadProgress: 0,
    averageDownloadMbps: 0,
    averageUploadMbps: 0,
    loading: false,
    success: false,
    downloadRound: 0,
    uploadRound: 0,
    showResult: false,
    cancelled: false,
    fileList: [
      "temp_3.1MB_file",
      "temp_4.2MB_file",
      "temp_5.2MB_file",
      "temp_6.3MB_file",
      "temp_7.3MB_file",
      "temp_8.4MB_file",
      "temp_9.4MB_file",
      "temp_10.5MB_file",
      "temp_11.5MB_file",
      "temp_12.6MB_file",
      "temp_13.6MB_file",
      "temp_14.7MB_file",
      "temp_15.7MB_file",
    ],
    uploadFileList: ["temp_2MB_file", "temp_3MB_file", "temp_4MB_file", "temp_5MB_file"],
  };
};

class SpeedTest extends Component {
  constructor() {
    super();
    this.state = {
      ...initialState(),
    };
  }

  findSpeed = (seconds, size) => {
    let loadedBits = size * 8;
    let bps = (loadedBits / seconds).toFixed(2);
    let speedInMbps = (bps / 1000000).toFixed(2);
    return speedInMbps;
  };

  handleUploadFile = async (fileName) => {
    const { uploadDocument } = this.state;
    if (this.props.speedTestDialogOpen) {
      try {
        const startTime = new Date().getTime();

        const credentials = await getAWSCredentialsForCurrentUserSession();
        const s3 = new AWS.S3({
          credentials,
          apiVersion: "2006-03-01",
          params: { Bucket: process.env.AWS_USER_DOC_BUCKET },
        });
        const params = {
          ACL: "authenticated-read",
          Body: uploadDocument.Body,
          ContentType: uploadDocument.ContentType,
          Key: `speedtest/${fileName}`,
        };
        await s3
          .putObject(params)
          .on("httpUploadProgress", (progressEvent) => {
            const roundStart = this.state.uploadRound * 25;
            const percentCompleted =
              Math.round((progressEvent.loaded * 100) / progressEvent.total) / 4;
            this.setState({
              uploadProgress: roundStart + percentCompleted,
            });
          })
          .promise();

        const endTime = new Date().getTime();
        let loadTime = (endTime - startTime) / 1000;
        let speed = this.findSpeed(loadTime, uploadDocument.ContentLength);
        this.setState((prevState) => ({
          uploadSpeedLog: [...prevState.uploadSpeedLog, speed],
          uploadRound: prevState.uploadRound + 1,
        }));
      } catch (err) {
        console.log(err);
      }
    }
  };

  findUploadSpeedAverage = async () => {
    const { uploadFileList } = this.state;
    try {
      for (let i = 0; i < uploadFileList.length; i++) {
        const credentials = await getAWSCredentialsForCurrentUserSession();
        const s3 = new AWS.S3({ credentials });
        const params = {
          Bucket: process.env.AWS_USER_DOC_BUCKET,
          Key: `speedtest/${uploadFileList[i]}`,
        };
        const file = await s3.getObject(params).promise();
        this.setState({ uploadDocument: file });
        if (this.state.cancelled) {
          break;
        }
        await this.handleUploadFile(uploadFileList[i]);
      }

      let total = this.state.uploadSpeedLog.reduce((total, el) => {
        return total + Number(el);
      }, 0);
      if (this.state.cancelled) {
        this.setState({ ...initialState() });
      } else {
        this.setState({
          averageUploadMbps: (total / 4).toFixed(2),
          loading: false,
          success: true,
          showResult: true,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleDownloadFile = async (fileName) => {
    if (this.props.speedTestDialogOpen) {
      try {
        const startTime = await new Date().getTime();

        const baseUrl = process.env.BASE_URL;
        const userSession = await Auth.currentSession();
        const downloadUrl = `${baseUrl}/download/${
          userSession.accessToken.jwtToken
        }/file/${fileName}?cache=${new Date().getTime()}`;

        let resp = await axios({
          url: downloadUrl,
          method: "GET",
          responseType: "blob",
          cancelToken: new CancelToken(function executor(c) {
            cancel = c;
          }),
          onDownloadProgress: (progressEvent) => {
            const roundStart = this.state.downloadRound * 7.69230769;
            const percentCompleted =
              Math.round((progressEvent.loaded * 100) / progressEvent.total) / 13;
            this.setState({
              downloadProgress: roundStart + percentCompleted,
            });
          },
        });
        const endTime = new Date().getTime();
        let loadTime = (endTime - startTime) / 1000;
        let speed = this.findSpeed(loadTime, resp.data.size);
        this.setState((prevState) => ({
          downloadSpeedLog: [...prevState.downloadSpeedLog, speed],
          downloadRound: prevState.downloadRound + 1,
        }));
      } catch (err) {
        console.log(err);
      }
    }
  };

  findDownloadSpeedAverage = async () => {
    const { fileList } = this.state;
    this.setState({ ...initialState(), loading: true });

    for (let i = 0; i < fileList.length; i++) {
      await this.handleDownloadFile(fileList[i]);
    }
    let total = this.state.downloadSpeedLog.reduce((total, el) => {
      return total + Number(el);
    }, 0);

    if (this.state.cancelled) {
      this.setState({
        loading: false,
        downloadProgress: 0,
      });
    } else {
      this.setState({
        averageDownloadMbps: (total / 13).toFixed(2),
      });
    }
    this.findUploadSpeedAverage();
  };

  handle = () => {
    this.findDownloadSpeedAverage();
  };

  resultGauge = (avg) => {
    if (avg === 0) {
      return 0;
    } else if (avg > 0 && avg <= 5) {
      return 0.16;
    } else if (avg > 5 && avg <= 20) {
      return 0.5;
    } else if (avg > 20) {
      return 0.83;
    }
  };

  resultText = (avg) => {
    if (avg > 0 && avg <= 5) {
      return "MAY EXPERIENCE ISSUES";
    } else if (avg > 5 && avg <= 10) {
      return "SLOW";
    } else if (avg > 10 && avg <= 20) {
      return "FAIR";
    } else if (avg > 20) {
      return "EXCELLENT";
    }
  };

  handleCancel = () => {
    const { downloadProgress } = this.state;

    this.props.handleSpeedTestDialogClose();
    this.setState({ ...initialState(), cancelled: true });

    if (downloadProgress > 0 && downloadProgress < 100) {
      cancel();
    }
  };

  render() {
    const { classes, speedTestDialogOpen } = this.props;
    const { averageDownloadMbps, averageUploadMbps, downloadProgress, uploadProgress, showResult } =
      this.state;

    return (
      <Modal
        open={speedTestDialogOpen}
        title="Speed Test"
        titleCentered
        content={
          <div style={{ maxWidth: 500 }}>
            <Typography>
              Check your internet speed before the session. This process may take 2-3 minutes to
              complete.
            </Typography>

            <div className={classes.speedContainer}>
              <div className={classes.speedCard}>
                <Typography variant="h4" className={classes.wrapIcon}>
                  <ArrowDownwardIcon color="primary" />
                  DOWNLOAD- <span style={{ color: "#9193a8" }}> Mbps</span>
                </Typography>
                <Typography variant="h4">{averageDownloadMbps} </Typography>
              </div>
              <div className={classes.speedCard}>
                <Typography variant="h4" className={classes.wrapIcon}>
                  <ArrowUpwardIcon color="secondary" />
                  UPLOAD- <span style={{ color: "#9193a8" }}> Mbps</span>
                </Typography>
                <Typography variant="h4">{averageUploadMbps} </Typography>
              </div>
            </div>
            {showResult ? (
              <Grow in={showResult}>
                <div className={classes.gaugeContainer}>
                  <div className={classes.gauge}>
                    <GaugeChart
                      id="gauge-chart1"
                      percent={this.resultGauge(averageDownloadMbps)}
                      className={classes.chart}
                      colors={["#FF0000", "#FFFF00", "#00FF00"]}
                      formatTextValue={(val) => val}
                      hideText
                      animate={false}
                    />
                    <Typography variant="p" style={{ fontWeight: 500 }}>
                      {this.resultText(averageDownloadMbps)}
                    </Typography>
                  </div>
                  <div className={classes.gauge}>
                    <GaugeChart
                      id="gauge-chart1"
                      percent={this.resultGauge(averageUploadMbps)}
                      className={classes.chart}
                      colors={["#FF0000", "#FFFF00", "#00FF00"]}
                      formatTextValue={(val) => val}
                      hideText
                      animate={false}
                    />
                    <Typography variant="p" style={{ fontWeight: 500 }}>
                      {this.resultText(averageUploadMbps)}
                    </Typography>
                  </div>
                </div>
              </Grow>
            ) : null}
            <div className={classes.progressContainer}>
              <div className={classes.progressBar}>
                <div style={{ width: "94%" }}>
                  <LinearProgress variant="determinate" value={downloadProgress} />
                </div>
                <div style={{ width: "6%", marginLeft: 10 }}>
                  <Typography variant="caption">{`${Math.round(downloadProgress)}%`}</Typography>
                </div>
              </div>

              <div className={classes.progressBar}>
                <div style={{ width: "94%" }}>
                  <LinearProgress variant="determinate" value={uploadProgress} color="secondary" />
                </div>
                <div style={{ width: "6%", marginLeft: 10 }}>
                  <Typography variant="caption">{`${Math.round(uploadProgress)}%`}</Typography>
                </div>
              </div>

              <div className={classes.buttonContainer}>
                {this.state.loading ? (
                  <CircularProgress size={65} />
                ) : this.state.success ? (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={this.handle}
                    className={classes.speedTest}
                  >
                    <RefreshIcon style={{ fontSize: 35, color: "#fff" }} />
                  </Button>
                ) : (
                  <Button
                    onClick={this.handle}
                    color="primary"
                    style={{ color: "#fff" }}
                    variant="contained"
                    className={classes.speedTest}
                  >
                    Start
                  </Button>
                )}
              </div>
            </div>
          </div>
        }
        secondaryActionText="Close"
        secondaryActionOnClick={this.handleCancel}
      />
    );
  }
}

export default withStyles(styles)(SpeedTest);
