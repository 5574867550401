import React, { useState } from "react";
import { Box, Checkbox, Typography, IconButton, Select, MenuItem, Menu } from "@mui/material";
import { DotsThreeVertical, Plus, CopySimple, Minus, Star } from "@phosphor-icons/react";
import moment from "moment-timezone";
import { CopyAvailabilityMenu } from "./CopyAvailabilityMenu";
import { formatTimeZoneToAbbreviation } from "utils/formatTimeZoneToAbbreviation";

const defaultStartTime = "08:00 AM";
const defaultEndTime = "05:00 PM";

export const AvailableHoursSelector = ({
  day,
  isActive,
  timeRanges = [],
  updateDayData,
  copyToDays,
  editMode,
  clientTimeZone,
}) => {
  const [optionsMenuAnchorEl, setOptionsMenuAnchorEl] = useState(null);
  const [isCopyMenuOpen, setIsCopyMenuOpen] = useState(false); // State variable to track copy menu open state

  const defaultNewRange = {
    start: moment.tz(defaultStartTime, "h:mm A", clientTimeZone).toISOString(),
    end: moment.tz(defaultEndTime, "h:mm A", clientTimeZone).toISOString(),
    preferred: false, // Add preferred status to default range
  };

  if (isActive && timeRanges.length === 0) {
    timeRanges = [defaultNewRange];
  }

  const handleOptionsMenuOpen = (event) => {
    setOptionsMenuAnchorEl(event.currentTarget);
  };

  const handleOptionsMenuClose = () => {
    setOptionsMenuAnchorEl(null);
  };

  const formatTime = (dateString) => {
    const date = moment.tz(dateString, clientTimeZone);
    return date.format("h:mm A");
  };

  const handleTimeChange = (index, field, value) => {
    const updatedRanges = timeRanges.map((range, i) => {
      if (i === index) {
        let newValue = moment.tz(value, "h:mm A", clientTimeZone);
        const todayInClientTimeZone = moment.tz(clientTimeZone).format("YYYY-MM-DD");
        newValue = moment
          .tz(
            `${todayInClientTimeZone} ${newValue.format("HH:mm")}`,
            "YYYY-MM-DD HH:mm",
            clientTimeZone
          )
          .toISOString();
        let newRange = { ...range, [field]: newValue };
        let startMoment = moment.tz(newRange.start, clientTimeZone);
        let endMoment = moment.tz(newRange.end, clientTimeZone);

        if (field === "start" && endMoment.isBefore(startMoment)) {
          newRange.end = newRange.start;
        } else if (field === "end" && startMoment.isAfter(endMoment)) {
          newRange.start = newRange.end;
        }

        return newRange;
      }
      return range;
    });

    updateDayData({ day, isActive, timeRanges: updatedRanges });
    return updatedRanges;
  };

  const addTimeRange = (index) => {
    const updatedTimeRanges = [
      ...timeRanges.slice(0, index + 1),
      defaultNewRange,
      ...timeRanges.slice(index + 1),
    ];

    updateDayData({ day, isActive, timeRanges: updatedTimeRanges });
  };

  const removeTimeRange = (index) => {
    const newTimeRanges = timeRanges.filter((_, i) => i !== index);
    updateDayData({ day, isActive, timeRanges: newTimeRanges });
  };

  const timeOptions = generateTimeOptions();

  const isOptionsMenuOpen = Boolean(optionsMenuAnchorEl);

  const handleCopyMenuOpen = () => {
    setIsCopyMenuOpen(true); // Open copy menu
  };

  const togglePreferred = (index) => {
    const updatedTimeRanges = timeRanges.map((range, i) => {
      if (i === index) {
        return { ...range, preferred: !range.preferred };
      }
      return range;
    });

    updateDayData({ day, isActive, timeRanges: updatedTimeRanges });
  };

  return (
    <Box display="flex" flexDirection="column" paddingTop={"8px"} width={"100%"}>
      <Box display="flex" alignItems="flex-start">
        {editMode && (
          <Box style={{ display: "flex", alignItems: "center", height: "40px" }}>
            <Checkbox
              checked={isActive}
              onChange={() => {
                updateDayData({
                  day,
                  isActive: !isActive,
                  timeRanges: !timeRanges.length ? [defaultNewRange] : timeRanges,
                });
              }}
            />
          </Box>
        )}
        <Typography
          variant="body2"
          style={{
            display: "flex",
            alignItems: "center",
            width: "128px",
            height: editMode ? "40px" : "20px",
          }}
        >
          {`${day.charAt(0).toUpperCase() + day.slice(1)}`}
        </Typography>
        {isActive && (
          <Box width={editMode ? "100%" : null}>
            {timeRanges.map((timeRange, index) => (
              <Box key={index} display="flex" alignItems="center" paddingBottom={"8px"}>
                {editMode ? (
                  <>
                    <Select
                      value={formatTime(timeRange.start)}
                      onChange={(e) => handleTimeChange(index, "start", e.target.value)}
                      style={{ minWidth: "128px", flexGrow: 1 }}
                    >
                      {timeOptions.map((time, i) => (
                        <MenuItem key={i} value={time}>
                          {time}
                        </MenuItem>
                      ))}
                    </Select>

                    <Typography margin={"0 16px"} variant="body2">
                      to
                    </Typography>

                    <Select
                      value={formatTime(timeRange.end)}
                      onChange={(e) => handleTimeChange(index, "end", e.target.value)}
                      style={{ minWidth: "128px", flexGrow: 1 }}
                    >
                      {timeOptions.map((time, i) => (
                        <MenuItem key={i} value={time}>
                          {time}
                        </MenuItem>
                      ))}
                    </Select>
                    <IconButton
                      size="medium"
                      onClick={() => togglePreferred(index)}
                      style={{ margin: "0 16px" }}
                    >
                      <Star
                        weight={timeRange.preferred ? "fill" : "regular"} // Change icon weight based on preferred status
                        color={timeRange.preferred ? "#FFD700" : "black"} // Change icon color based on preferred status
                      />
                    </IconButton>
                    <IconButton size="medium" onClick={handleOptionsMenuOpen}>
                      <DotsThreeVertical />
                    </IconButton>

                    <Menu
                      anchorEl={optionsMenuAnchorEl}
                      open={isOptionsMenuOpen}
                      onClose={handleOptionsMenuClose}
                    >
                      <MenuItem
                        onClick={(e) => {
                          addTimeRange(index);
                          handleOptionsMenuClose();
                        }}
                      >
                        <Plus sx={{ mr: 1 }} />
                        <Box marginLeft={"8px"}>
                          {`Add interval for ${day.charAt(0).toUpperCase() + day.slice(1)}`}
                        </Box>
                      </MenuItem>
                      {timeRanges.length > 1 && (
                        <MenuItem
                          onClick={() => {
                            removeTimeRange(index);
                            handleOptionsMenuClose();
                          }}
                        >
                          <Minus sx={{ mr: 1 }} />
                          <Box marginLeft={"8px"}>Remove interval</Box>
                        </MenuItem>
                      )}
                      <MenuItem onClick={handleCopyMenuOpen}>
                        <CopySimple />
                        <Box marginLeft={"8px"}>
                          {`Copy ${day.charAt(0).toUpperCase() + day.slice(1)}'s availability`}
                        </Box>
                      </MenuItem>
                    </Menu>
                  </>
                ) : (
                  <Typography variant="subtitle2">
                    {`${formatTime(timeRange.start)} to ${formatTime(timeRange.end)} (${formatTimeZoneToAbbreviation(
                      clientTimeZone
                    )})`}
                  </Typography>
                )}
              </Box>
            ))}
          </Box>
        )}
        {!isActive && (
          <Typography
            variant="subtitle2"
            style={{
              display: "flex",
              alignItems: "center",
              width: "128px",
              height: editMode ? "40px" : "20px",
            }}
          >
            Unavailable
          </Typography>
        )}
      </Box>
      {isCopyMenuOpen && (
        <CopyAvailabilityMenu
          anchorEl={optionsMenuAnchorEl}
          isMenuOpen={isCopyMenuOpen}
          handleMenuClose={() => {
            setIsCopyMenuOpen(false);
            handleOptionsMenuClose();
          }}
          copyToDays={copyToDays}
        />
      )}
    </Box>
  );
};

const generateTimeOptions = () => {
  const times = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      times.push(moment({ hour, minute }).format("h:mm A"));
    }
  }
  return times;
};
