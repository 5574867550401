import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Modal from "elements/Modal";

const UpdateDocumentDialog = (props) => {
  const {
    classes,
    updateDocumentOpen,
    updateDocument,
    onToggleApproveDocument,
    onToggleUploadDocumentWithType,
    onToggleUpdateDocument,
    userPermissions,
  } = props;

  const onToggleApprove = (doc) => {
    onToggleUpdateDocument(doc);
    onToggleApproveDocument();
  };

  const onToggleReplace = (doc) => {
    onToggleUpdateDocument();
    onToggleUploadDocumentWithType(doc);
  };

  return (
    <Modal
      open={updateDocumentOpen}
      onClose={() => onToggleUpdateDocument()}
      title="How would you like to update this document?"
      titleCentered
      content={
        <div>
          <Typography component="p">
            <span style={{ fontWeight: 500 }}>Replace Document</span> - upload a new document to
            replace the current one
          </Typography>
          <Button
            onClick={() => onToggleReplace(updateDocument.name)}
            color="secondary"
            style={{ marginTop: 10 }}
          >
            Replace
          </Button>
          <div className={classes.divider} />

          <Typography component="p">
            <span style={{ fontWeight: 500 }}>Approve Document</span> - after reviewing this
            document you are ready to mark it as approved
          </Typography>
          <Button
            onClick={() => onToggleApprove(updateDocument)}
            style={{ marginTop: 10 }}
            disabled={!userPermissions?.approve_client_documents}
          >
            Approve
          </Button>

          <div className={classes.divider} />
        </div>
      }
      secondaryActionText="Close"
      secondaryActionOnClick={() => onToggleUpdateDocument()}
    />
  );
};

export default UpdateDocumentDialog;
