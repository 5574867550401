import React from "react";

import { Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";

const ANNoDataOverlay = ({ imgSrc, title, description }) => {
  const theme = useTheme();
  return (
    <Stack height="100%" justifyContent="center" alignItems="center">
      <Stack width="356px" alignItems="center">
        <img style={{ height: "76px", marginBottom: theme.spacing(5) }} src={imgSrc} alt={title} />
        <Typography variant="subtitle1">{title}</Typography>
        <Typography
          variant="body1"
          sx={{ textAlign: "center", color: theme.palette.text.secondary }}
        >
          {description}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ANNoDataOverlay;
