import React from "react";
import Typography from "@mui/material/Typography";
import { format, differenceInHours } from "date-fns";
import Modal from "elements/Modal";

import { convertTokens } from "@date-fns/upgrade/v2";

const ConfirmStartCallDialog = (props) => {
  const {
    startCallOpen,
    onCloseStartCallDialog,
    callDetails,
    startVideoCall,
    primaryDisabled,
    secondaryDisabled,
  } = props;

  return (
    <Modal
      open={startCallOpen}
      onClose={onCloseStartCallDialog}
      title="Are you sure?"
      content={
        <div style={{ width: 500 }}>
          <Typography>
            {differenceInHours(new Date(callDetails.scheduled_date), new Date()) >= 2 ? (
              <>
                This call isn't scheduled to begin until{" "}
                <span style={{ fontWeight: 500 }}>
                  {format(
                    new Date(callDetails.scheduled_date),
                    convertTokens("dddd, MMMM Do, h:mm a"),
                  )}
                </span>
                . Are you sure you want to start this call early?
              </>
            ) : (
              <>
                You are about to begin the call with{" "}
                <span style={{ fontWeight: 500 }}>{callDetails.parent_name}</span>
              </>
            )}
          </Typography>
        </div>
      }
      primaryActionText="Start Call"
      primaryActionOnClick={startVideoCall}
      secondaryActionText="Cancel"
      secondaryActionOnClick={onCloseStartCallDialog}
      primaryActionDisabled={primaryDisabled}
      secondaryActionDisabled={secondaryDisabled}
    />
  );
};

export default ConfirmStartCallDialog;
