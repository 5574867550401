import React from "react";
import { DialogTitle, DialogContent, IconButton, Button } from "@mui/material";
import { Play, X } from "@phosphor-icons/react";

const VideoPlayerErrorContent = ({ asModal, modalControl, retryHandler }) => {
  return (
    <>
      {asModal ? (
        <>
          <DialogTitle
            id="form-dialog-title"
            align="left"
            style={{
              borderBottom: "1px solid #CDD3DA",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            Video Player
            <IconButton onClick={modalControl?.closeModal}>
              <X />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <div
              style={{
                textAlign: "center",
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                color="secondary"
                onClick={retryHandler}
                startIcon={<Play />}
              >
                Retry
              </Button>
            </div>
          </DialogContent>
        </>
      ) : (
        <div
          style={{
            textAlign: "center",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button variant="contained" color="secondary" onClick={retryHandler} startIcon={<Play />}>
            Retry
          </Button>
        </div>
      )}
    </>
  );
};

export default VideoPlayerErrorContent;
