import React, { useMemo } from "react";
import { Files, FileX } from "@phosphor-icons/react";
import { Typography, Link, Box, IconButton, Grid, Button } from "@mui/material";
import { formatFileSize } from "utils/formatFileSize";
import { X } from "@phosphor-icons/react";
import { useDropzone } from "react-dropzone";
import { useTheme } from "@mui/styles";

/**
 * @typedef {Object} AcceptedFile - The accepted file object.
 * @property {Boolean} AcceptedFile.errors - The boolean state of the uploaded file.
 * @property {Object} AcceptedFile.file - The uploaded file object.
 * @property {String} AcceptedFile.file.path - The path of the uploaded file.
 * @property {Number} AcceptedFile.file.lastModified - The last modified date of the uploaded file.
 * @property {Object} AcceptedFile.file.lastModifiedDate - The last modified date object of the uploaded file.
 * @property {String} AcceptedFile.file.name - The name of the uploaded file.
 * @property {Number} AcceptedFile.file.size - The size of the uploaded file.
 * @property {String} AcceptedFile.file.type - The type of the uploaded file.
 * @property {Boolean} AcceptedFile.alreadyUploaded - The boolean state of the uploaded file.
 */

const ANUploader = ({
  // handlers
  handleFileUpload,
  handleFileDelete,
  handleLoadedFileDelete,
  onViewFile,
  // values
  alreadyUploadedFiles,
  dropzoneFiles,
  error,
  errorText,
  useDropzoneOptions,
  dropZoneStyle,
  filePreviewStyle,
  hideDropzone,
  removeFileButtonProps,
}) => {
  const theme = useTheme();
  const FilePreview = ({ file, error, handleDelete }) => {
    return (
      <Grid
        item
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignContent: "center",
          alignItems: "center",
          marginTop: "24px",
          flex: "0 0 100%",
          width: "532px",
          maxWidth: "100%",
          ...filePreviewStyle,
        }}
      >
        <div
          style={{
            display: "flex",
            width: "600px",
            padding: "var(--0, 0px) var(--5, 16px)",
            alignItems: "center",
            gap: "var(--5, 16px)",
          }}
        >
          {error ? (
            <FileX
              size={24}
              color={error ? palette.error.main : palette.primary.main}
              weight="duotone"
            />
          ) : (
            <Files
              size={24}
              color={error ? palette.error.main : palette.primary.main}
              weight="duotone"
            />
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              flex: "1 0 0",
            }}
          >
            <div>
              <Typography
                variant="subtitle3"
                color={error ? palette.error.main : palette.text.primary}
              >{`${file?.name}`}</Typography>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              {!!file?.size && (
                <Typography
                  variant="body2"
                  color={error ? palette.error.main : palette.text.secondary}
                >
                  {error
                    ? "File too large \u2022 Failed"
                    : `${formatFileSize(file.size)} \u2022 Complete`}
                </Typography>
              )}
              {onViewFile && file?.key && (
                <Button
                  color="primary"
                  size="small"
                  variant="text"
                  onClick={() => onViewFile(file.key)}
                >
                  View
                </Button>
              )}
            </div>
          </div>
        </div>
        <div>
          <IconButton
            size="small"
            fontSize="small"
            onClick={handleDelete}
            {...removeFileButtonProps}
          >
            <X fontSize="small" />
          </IconButton>
        </div>
      </Grid>
    );
  };
  const { palette } = theme;
  const [isDragActive, setIsDragActive] = React.useState(false);
  const [isHovered, setIsHovered] = React.useState(false);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDragEnter: () => setIsDragActive(true),
    onDragLeave: () => setIsDragActive(false),
    onDropAccepted: () => setIsDragActive(false),
    onDropRejected: () => setIsDragActive(false),
    onDrop: (acceptedFiles, fileRejections) => {
      handleFileUpload({
        acceptedFiles: acceptedFiles,
        fileRejections: fileRejections,
      });
    },
    ...useDropzoneOptions,
  });

  const removeFile = (file) => () => {
    if (acceptedFiles.indexOf(file) >= 0) {
      acceptedFiles.splice(acceptedFiles.indexOf(file), 1);
    }
    if (dropzoneFiles.indexOf(file) >= 0) {
      handleFileDelete(file);
    }
    if (alreadyUploadedFiles?.indexOf(file) >= 0) {
      handleLoadedFileDelete(file);
    }
  };

  const getPreviews = () => (
    <div>
      {dropzoneFiles?.map((file, i) => (
        <FilePreview key={i} file={file.file} error={file.errors} handleDelete={removeFile(file)} />
      ))}
      {alreadyUploadedFiles?.map((file, i) => (
        <FilePreview key={i} file={file} error={file.errors} handleDelete={removeFile(file)} />
      ))}
    </div>
  );

  const baseStyle = {
    width: "100%",
    margin: "24px 0px 50px 0px",
    padding: "16px",
    borderRadius: "4px",
    borderColor: "var(--divider, #CDD3DA)",
    borderStyle: "dashed",
    borderWidth: "1px",
    transition: "border .24s ease-in-out",
    ...dropZoneStyle,
  };

  const activeStyle = {
    borderColor: palette.primary.main,
    backgroundColor: palette.primary.selected,
  };

  const errorStyle = {
    borderColor: palette.error.main,
    backgroundColor: "var(--error-states-hover, rgba(211, 47, 47, 0.04))",
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isHovered && !isDragActive ? activeStyle : {}),
      ...(error ? errorStyle : {}),
      display: hideDropzone ? "none" : "block",
    }),
    [isDragActive, isHovered, error, hideDropzone]
  );

  return (
    <Box>
      <section className="container">
        <div
          {...getRootProps({
            style,
            onMouseEnter: () => setIsHovered(true),
            onMouseLeave: () => setIsHovered(false),
          })}
        >
          <input {...getInputProps()} />
          <Box
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            display="flex"
          >
            <Files color={palette.action.active} size={26} style={{ margin: "15px 0 10px" }} />
            <Typography padding={"8px 0"} style={{ fontSize: 16 }}>
              <Link>Click to upload</Link> or drag and drop
            </Typography>
            {error && errorText && (
              <Typography variant="h6" sx={{ color: palette.error.main }}>
                {errorText}
              </Typography>
            )}
          </Box>
        </div>
        {getPreviews()}
      </section>
    </Box>
  );
};

export default ANUploader;
