import React from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  Checkbox,
  Typography,
  Accordion,
  AccordionSummary,
} from "@mui/material";
import { CaretDown } from "@phosphor-icons/react";

const serviceTypes = [
  "Direct Therapy",
  "Caregiver Training",
  "Assessment",
  "Orientation",
  "Other Services",
];

export const ServiceFiltering = ({ selectedServices, onToggleService }) => {
  const convertToUpperCaseWithUnderscores = (text) => text.toUpperCase().replace(/\s+/g, "_");

  return (
    <Accordion
      defaultExpanded
      square
      disableGutters
      sx={{
        "&::before": {
          display: "none",
        },
        "&.Mui-expanded": {
          margin: 0,
        },
        boxShadow: "none",
      }}
    >
      <AccordionSummary expandIcon={<CaretDown size={20} />} sx={{ padding: 0 }}>
        <Typography variant="subtitle1">Service Type</Typography>
      </AccordionSummary>
      <div style={{ paddingBottom: "16px" }}>
        <List sx={{ padding: 0 }}>
          {serviceTypes.map((text, index) => {
            const labelId = `checkbox-list-label-${index}`;

            return (
              <ListItem
                key={text}
                dense
                onClick={() => onToggleService(convertToUpperCaseWithUnderscores(text))}
              >
                <ListItemIcon sx={{ minWidth: "auto", marginRight: "8px" }}>
                  <Checkbox
                    edge="start"
                    checked={selectedServices.includes(convertToUpperCaseWithUnderscores(text))}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </ListItemIcon>
                <Typography variant="body2">{text}</Typography>
              </ListItem>
            );
          })}
        </List>
      </div>
    </Accordion>
  );
};
