import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withStyles from "@mui/styles/withStyles";

import styles from "../../theme/globalStyles";
import actions from "../../actions";
import Customers from "./Customers";
import {
  getCustomerList,
  getSignedinState,
  getCustomersPage,
  getCustomersPerPage,
  getCustomersSort,
  getCustomersSortDirection,
  getCustomersCount,
  getUserPermissionsList,
  getCustomersLoading,
} from "../../selectors";
import PageContainer from "elements/PageContainer";
import SectionContainer from "elements/SectionContainer";
import Heading from "elements/Heading";

const mapStateToProps = (state) => ({
  customers: getCustomerList(state),
  page: getCustomersPage(state),
  rowsPerPage: getCustomersPerPage(state),
  sort: getCustomersSort(state),
  direction: getCustomersSortDirection(state),
  count: getCustomersCount(state),
  signedInState: getSignedinState(state),
  userPermissions: getUserPermissionsList(state),
  customersLoading: getCustomersLoading(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadCustomerList: actions.loadCustomerList,
      setCustomerList: actions.setCustomerList,
      checkAuthState: actions.checkAuthState,
      setCustomersPage: actions.setCustomersPage,
      setCustomersPerPage: actions.setCustomersPerPage,
      setCustomersSort: actions.setCustomersSort,
      setPageDetails: actions.setPageDetails,
    },
    dispatch,
  );

class CustomersContainer extends Component {
  state = {
    page: 0,
    rowsPerPage: 5,
  };

  componentDidMount() {
    this.props.setPageDetails({
      pageName: "Clients",
      currentPage: "customers",
      menu: "entities",
    });
    this.props.loadCustomerList();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.page !== this.props.page ||
      prevProps.sort !== this.props.sort ||
      prevProps.direction !== this.props.direction ||
      prevProps.rowsPerPage !== this.props.rowsPerPage
    ) {
      this.props.loadCustomerList();
    }
  }

  componentWillUnmount() {
    this.props.setCustomersSort("");
  }

  handleChangePage = (event, page) => {
    this.props.setCustomersPage(page);
  };

  handleChangeRowsPerPage = (event) => {
    this.props.setCustomersPerPage(parseInt(event.target.value));
    this.props.setCustomersPage(0);
  };

  handleSortClick = (name) => {
    this.props.setCustomersSort({
      sort: name,
    });
  };

  viewCustomerDetails = (clientId) => {
    let customer = "/clients/" + clientId + "/dashboard";
    this.props.history.push(customer);
  };

  render() {
    const { classes, customersLoading } = this.props;
    return (
      <PageContainer loading={customersLoading}>
        <SectionContainer noPadding columnDirection>
          <Customers
            {...this.props}
            {...this.state}
            viewCustomerDetails={this.viewCustomerDetails}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            handleSortClick={this.handleSortClick}
            rowsPerPage={this.props.rowsPerPage}
            page={this.props.page}
            count={this.props.count}
          />
        </SectionContainer>
      </PageContainer>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CustomersContainer));
