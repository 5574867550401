import { addMinutes, format } from "date-fns";
import { formatToTimeZone } from "date-fns-timezone";

/**
 * Splits the data from original objects into separate objects,
 *
 * @param {Array} originalObjects - The array of original objects.
 * @returns {Array} - The array of new objects with split data changes.
 */
export function splitDataChanges(originalObjects) {
  const result = [];

  originalObjects.forEach((originalObject) => {
    if (originalObject.action === "RESCHEDULED") {
      // If the action is RESCHEDULED, split the data_changes into separate objects
      // for each key-value pair.
      const dataChanges = originalObject.data_changes;
      const keys = Object.keys(dataChanges);
      if (keys.length === 0) {
        result.push(originalObject);
        return;
      }

      keys.forEach((key) => {
        const newObject = JSON.parse(JSON.stringify(originalObject)); // Deep copy
        newObject.data_changes = { [key]: dataChanges[key] };
        result.push(newObject);
      });
    } else if (originalObject.action === "SCHEDULED") {
      // If the action is SCHEDULED, split the prev_data into separate objects
      // for each key-value pair that needs to be tracked (values we want to show in the logs).
      // Do not change the prev_data object, just add the data_changes object.
      const eventData = originalObject.prev_data;
      const keys = Object.keys(eventData);
      if (keys.length === 0) {
        result.push(originalObject);
        return;
      }

      const keysToTrack = [
        "billing_type",
        "clinician_user_id",
        "repeat_days",
        "repeat_until_date",
        "client_id",
        "off_platform",
        "begin_date",
        "start_time",
      ];
      keys.forEach((key) => {
        if (keysToTrack.includes(key)) {
          const newObject = JSON.parse(JSON.stringify(originalObject)); // Deep copy
          // start_time is a value like "13:00:00", we want begin_date instead, which is a date,
          // that we can format.
          if (key === "start_time") newObject.data_changes = { [key]: eventData["begin_date"] };
          else newObject.data_changes = { [key]: eventData[key] };
          result.push(newObject);
        }
      });
    } else {
      result.push(originalObject);
    }
  });

  return result;
}

export function getRepeatUntilDateFormatted(value) {
  if (value === "current_auth_period") return "end of authorization";
  const date = new Date(value);
  if (isNaN(date.getTime())) return "no date";
  return format(date, "MMMM do");
}

export const getEventTime = (eventData) => {
  const { begin_date, call_duration } = eventData;
  if (!begin_date) return "No data available";
  return `${format(new Date(begin_date), "h:mm a")} - ${formatToTimeZone(
    addMinutes(new Date(begin_date), call_duration),
    "h:mm A (z)",
    {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }
  )}`;
};

/**
 * This function is used after splitDataChanges, when data_changes has only one key-value pair.
 * Evaluates the key-value pair from data_changes and if it is a falsy value removes the object from the logs.
 * @param {*} logs
 */
export const clearLogs = (logs) => {
  return logs.filter((log) => {
    if (log.data_changes) {
      const key = Object.keys(log.data_changes)[0];
      // off_platform is a boolean value, so we want to keep it even if it is false.
      if (key === "off_platform") return true;
      return log.data_changes[key];
    }
    return true;
  });
};
