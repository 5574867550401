import axios from "axios";
import { detect } from "detect-browser";

const browser = detect();
let system_version = null;
let device_type = null;
let browser_type = null;
if (browser) {
  system_version = browser.version;
  browser_type = browser.name;
  device_type = browser.os;
}

export default axios.create({
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json",
    system_version,
    browser_type,
    device_type,
  },
  baseURL: process.env.BASE_URL,
});
