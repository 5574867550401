import ClientSignatureBG from "../../../assets/client_signature_bg.png";

export default (theme) => ({
  root: {
    boxShadow: "none",
    padding: "0 20px",
  },
  videoChatContainer: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 200,
    backgroundColor: "#000000",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },
  clinicianRoleContainer: {
    backgroundColor: "#000",
    width: 305,
    opacity: "55%",
    position: "absolute",
    top: 15,
    left: "auto",
    zIndex: 202,
    padding: 5,
    boxSizing: "border-box",
    borderRadius: 3,
  },
  clinicianRoleText: {
    fontSize: 18,
    color: "#fff",
    [theme.breakpoints.down("xl")]: {
      fontSize: 13,
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: 11,
    },
  },
  notesSection: {
    padding: 20,
  },
  settingsAudioVideoDialogTitle: {
    padding: "16px 24px 16px 24px",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "160%",
    letterSpacing: "0.15px",
  },
  settingsAudioVideoDialogContent: {
    overflow: "hidden",
    padding: "16px 24px 16px 24px",
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    marginBottom: 0,
    "& .section": {
      paddingBottom: 16,
      "& .rec": {
        minHeight: "20px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        fontSize: 14,
        marginTop: 16,
      },
    },
    "& .sectionHeading": {
      fontSize: 14,
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "157%",
      letterSpacing: "0.1px",
    },
    "& .sectionSubHeading": {
      marginTop: 16,
      marginBottom: 3,
      fontSize: 12,
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "157%",
      letterSpacing: "0.1px",
    },
    "& .sectionText": {
      fontSize: 12,
    },
    "& .options": {
      padding: 16,
    },
  },
  hostControlsDrawerContainer: {
    padding: "0 16px 0 16px",
  },
  hostControlsDrawer: {
    display: "flex",
    width: 320,
    height: "100%",
    [theme.breakpoints.down("xl")]: {
      width: "100%",
    },
    zIndex: "350 !important",
  },
  hostControlsDrawerPaper: {
    width: 320,
    height: "100%",
    [theme.breakpoints.down("xl")]: {
      width: "100%",
    },
    backgroundColor: "#fff",
  },
  hostControlsDrawerHeader: {
    fontSize: "20px",
    display: "flex",
    flexDirection: "row",
    height: 64,
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  hostControlsDrawerContent: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "166%",
    letterSpacing: "0.4px",
    "& .switchDescription": {
      margin: "0 0 16px 35px",
    },
    "& .switch": {
      paddingLeft: 6,
    },
    "& .title": {
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "150%",
      letterSpacing: "0.15px",
    },
    "& .btnGroup": {
      display: "flex",
      flexDirection: "column",
      padding: "16px 0 0 0",
    },
    "& .btn": {
      paddingTop: 8,
    },
  },
  activityDrawerContainer: {
    padding: "0 16px 0 16px",
  },
  activityDrawer: {
    display: "flex",
    height: "100%",
    width: 320,
    [theme.breakpoints.down("xl")]: {
      width: "100%",
    },
    zIndex: "350 !important",
  },
  activityDrawerTitle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "8px",
    alignSelf: "stretch",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "266%" /* 31.92px */,
    letterSpacing: "1px",
    textTransform: "uppercase",
  },
  activityDrawerPaper: {
    width: 320,
    height: "100%",
    [theme.breakpoints.down("xl")]: {
      width: "100%",
    },
    backgroundColor: "#fff",
  },
  activityDrawerHeader: {
    fontSize: "20px",
    fontWeight: 500,
    display: "flex",
    flexDirection: "row",
    height: 64,
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  videoActivityDialog: {
    position: "fixed",
    top: "50%",
    left: "auto",
    "& .btnGroup": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
    },
    "& .btn": {
      padding: 8,
    },
  },
  videoActivityDialogTitle: {
    padding: "16px 24px 16px 24px",
    display: "flex",
    justifyContent: "flex-start",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "160%" /* 32px */,
    letterSpacing: "0.15px",
  },
  videoActivityDialogContent: {
    display: "flex",
    flexDirection: "column",
    padding: "0 24px 20px 24px",
    "& .input": {
      width: "100%",
      margin: "8px 0",
      borderRadius: "var(--input, 6px)",
      border: "1px solid var(--input-outlined-enabled-border, rgba(0, 0, 0, 0.12))",
      background: "var(--background-default, #FFF)",

      /* input/enabled/outline */
      boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.15)",
    },
    "& .inputLabel": {
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "12px" /* 100% */,
      letterSpacing: "0.3px",
    },
    "& .inputInfo": {
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "166%" /* 19.92px */,
      letterSpacing: "0.4px",
    },
  },
  chatDrawerHeader: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 16px",
    fontSize: "20px",
    fontWeight: 500,
    height: 64,
  },
  chatDrawer: {
    display: "flex",
    width: 320,
    height: "100%",
    // paddingTop: 100,
    [theme.breakpoints.down("xl")]: {
      width: "100%",
    },
    zIndex: "350 !important",
  },
  chatDrawerPaper: {
    width: 320,
    height: "100%",
    [theme.breakpoints.down("xl")]: {
      width: "100%",
    },
    backgroundColor: "#fff",
  },
  chatDrawerContent: {
    display: "flex",
    flexDirection: "column",
    overflowY: "hidden",
    flexGrow: 1,
  },
  tabBarContainer: {
    width: "94%",
    height: 40,
    margin: " 15px 3%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  closeDrawerIcon: {
    position: "absolute",
    left: 0,
    "& svg": {
      fill: "#15171d",
      fontSize: 30,
    },
  },
  drawerHeaderText: {
    fontSize: 20,
    fontWeight: 500,
    color: "#391a51",
  },
  tabBarText: {
    color: "#4e4e4e",
    fontSize: 15,
    fontWeight: 500,
  },
  clientDeviceTypeContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    padding: "0 20px 20px",
    flex: 1,
    "& p": {
      fontSize: 14,
      color: "#2A2D39",
      fontWeight: 500,
    },
  },
  promptDrawerContainer: {
    padding: "0 16px 0 16px",
  },
  promptDrawer: {
    display: "flex",
    width: 300,
    height: "100%",
    overflow: "hidden",
    // paddingTop: 100,
    [theme.breakpoints.down("xl")]: {
      width: "100%",
    },
    zIndex: "401 !important",
  },
  promptDrawerPaper: {
    width: 320,
    height: "100%",
    [theme.breakpoints.down("xl")]: {
      width: "100%",
    },
    backgroundColor: "white",
  },
  promptDrawerHeader: {
    fontSize: "20px",
    display: "flex",
    flexDirection: "row",
    height: 64,
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  promptDrawerTitle: {
    color: "#4e4e4e",
    fontSize: 18,
    fontWeight: 500,
  },
  promptDrawerContent: {
    flex: 1,
    width: "100%",
    textAlign: "left",
  },
  promptSliderContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  promptLevelSummary: {
    width: "100%",
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    backgroundColor: "#fff",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "143%",
    letterSpacing: "0.17px",
  },
  promptLevelTitle: {
    fontSize: 14,
    fontWeight: 500,
    color: "#000",
  },
  promptLevelText: {
    fontSize: 14,
    textAlign: "left",
    margin: "0 0 16px 0",
  },
  startPromptActivityButton: {
    width: "100%",
    borderRadius: 10,
    marginTop: 10,
  },
  promptActivityContainer: {
    width: "100%",
    height: "78%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    flexWrap: "wrap",
  },
  drawer: {
    width: 450,
    [theme.breakpoints.down("xl")]: {
      width: "100%",
    },
    zIndex: "250 !important",
  },
  drawerPaper: {
    width: 450,
    [theme.breakpoints.down("xl")]: {
      width: "100%",
    },
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
    justifyContent: "space-between",
  },
  notesQuestion: {
    fontSize: 16,
    color: "#4e4e4e",
  },
  notesAnswer: {
    fontSize: 15,
  },
  videoContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    background: "#000",
    overflow: "hidden",
  },
  activityContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    height: "55%",
    width: "50%",
    background: "#d7d7d7",
    borderRadius: 8,
    boxShadow: "0 0 5px rgba(0,0,0,.4)",
    position: "relative",
    overflow: "hidden",
    [theme.breakpoints.down("xl")]: {
      width: "100%",
    },
  },
  videoActivitySplit: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "48%",
    width: "50%",
    background: "#000",
    borderRadius: 8,
    boxShadow: "0 0 5px rgba(0,0,0,.4)",
    overflow: "hidden",
    marginTop: 10,
    [theme.breakpoints.down("xl")]: {
      width: "100%",
      height: "40%",
    },
  },
  activityVideoSplit: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    height: "48%",
    width: "50%",
    background: "#d7d7d7",
    borderRadius: 8,
    boxShadow: "0 2px 3px rgba(0,0,0,.4)",
    position: "relative",
    overflow: "hidden",
  },
  videoActivityFocus: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "35%",
    width: "35%",
    background: "#000",
    borderRadius: 8,
    overflow: "hidden",
    marginTop: 10,
    [theme.breakpoints.down("xl")]: {
      width: "100%",
      height: "35%",
    },
  },
  activityVideoFocus: {
    display: "none",
  },
  activityImage: {
    height: "45%",
    margin: "5px 2%",
    borderRadius: 4,
  },
  activityPrompt: {
    fontSize: 24,
    fontWeight: 500,
    color: "#4e4e4e",
  },
  activityLabel: {
    fontSize: 16,
    color: "#4e4e4e",
    marginTop: 15,
  },
  activityAnswer: {
    fontSize: 16,
    fontWeight: 500,
    color: "#4e4e4e",
  },
  activityControlsContainer: {
    width: "100%",
    height: "18%",
    backgroundColor: "#f4f4f4",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  activityButtonIncorrect: {
    margin: "0 10px",
    height: 36,
    borderRadius: 18,
    color: "#fff",
    backgroundColor: "#ff0000",
    "&:hover": {
      backgroundColor: "#9c0000",
    },
    "& p": {
      textTransform: "none",
    },
  },
  activityButtonCorrect: {
    margin: "0 10px",
    color: "#fff",
    height: 36,
    borderRadius: 18,
    backgroundColor: "#00ef46",
    "&:hover": {
      backgroundColor: "#00b834",
    },
    "& p": {
      textTransform: "none",
    },
  },
  activityButtonNext: {
    color: "#fff",
    width: 100,
    height: 36,
    borderRadius: 18,
    backgroundColor: "#1de9b6",
    "&:hover": {
      backgroundColor: "#1de9b6",
    },
    position: "absolute",
    right: 10,
  },
  videoPlayPauseButton: {
    margin: "0 10px",
    color: "#fff",
    height: 36,
    width: 130,
    borderRadius: 18,
    backgroundColor: "#a389d4",
    "&:hover": {
      backgroundColor: "#907abc",
    },
    "& p": {
      textTransform: "none",
    },
  },
  waitingMessageContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 250,
    background: "#fff",
    backgroundImage: `url(${ClientSignatureBG})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  waitingMessage: {
    color: "#000",
    textAlign: "center",
    fontSize: 22,
    [theme.breakpoints.down("xl")]: {
      fontSize: 18,
      maxWidth: "95%",
    },
  },
  sendClientLink: {
    width: 250,
  },
  mainMediaContainer: {
    width: "100%",
    height: "100%",
    zIndex: 201,
  },
  mainMediaClientOnMobile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    "& video": {
      height: "100%",
      width: "100%",
      objectFit: "contain",
    },
  },
  mainMedia: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    "& video": {
      height: "100%",
      width: "100%",
      objectFit: "cover",
    },
  },
  mainMediaDisabled: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  mainMediaDisabledPlaceholder: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 300,
    backgroundColor: "#000",
  },
  mainMediaDisabledInitial: {
    height: 160,
    width: 160,
    borderRadius: 80,
    backgroundColor: "#8E42CA",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& p": {
      fontSize: 72,
      color: "#fff",
      fontWeight: 500,
    },
    zIndex: 301,
  },
  mainMediaHeadShotDisabledInitial: {
    height: 40,
    width: 40,
    borderRadius: 20,
    backgroundColor: "#8E42CA",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& p": {
      fontSize: 17,
      color: "#fff",
      fontWeight: 500,
    },
    zIndex: 301,
  },
  mobileLocalMediaContainer: {
    animation: "$slideDown 0.5s ease-in-out forwards",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "flex-start",
    width: 230,
    zIndex: 240,
    position: "absolute",
    bottom: 10,
    left: 10,
    margin: 15,
    [`${theme.breakpoints.down("xl")} and (orientation: portrait)`]: {
      width: 165,
    },
    [`${theme.breakpoints.down("lg")} and (orientation: portrait)`]: {
      width: 140,
      margin: 5,
    },
    [`${theme.breakpoints.down("xl")} and (orientation: landscape)`]: {
      width: 165,
      bottom: 5,
    },
    [`${theme.breakpoints.down("lg")} and (orientation:landscape)`]: {
      width: 140,
      margin: 5,
      left: 5,
    },
  },
  mobileLocalMediaContainerShowControls: {
    animation: "$slideUp 0.5s ease-in-out forwards",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "flex-start",
    width: 230,
    zIndex: 240,
    position: "absolute",
    bottom: 90,
    left: 10,
    margin: 15,
    [`${theme.breakpoints.down("xl")} and (orientation: portrait)`]: {
      width: 165,
      bottom: 88,
    },
    [`${theme.breakpoints.down("lg")} and (orientation: portrait)`]: {
      width: 140,
      margin: 5,
    },
    [`${theme.breakpoints.down("xl")} and (orientation: landscape)`]: {
      width: 165,
      bottom: 5,
    },
    [`${theme.breakpoints.down("lg")} and (orientation:landscape)`]: {
      width: 140,
      margin: 5,
      left: 5,
    },
  },
  localMediaContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "flex-start",
    width: 288,
    zIndex: 240,
    position: "absolute",
    bottom: 24,
    left: 24,
    padding: 16,
    backgroundColor: "rgba(255, 255, 255, 0.50)",
    borderRadius: 8,
    [theme.breakpoints.down("xl")]: {
      width: 210,
    },
    [theme.breakpoints.down("xl")]: {
      width: 165,
      padding: 10,
    },
    [theme.breakpoints.down("lg")]: {
      width: 140,
      padding: 5,
    },
  },
  localMedia: {
    height: 160,
    width: "100%",
    overflow: "hidden",
    position: "relative",
    "& video": {
      height: "100%",
      width: "100%",
      transform: "rotateY(180deg)",
      WebkitTransform: "rotateY(180deg)",
      MozTransform: "rotateY(180deg)",
      borderRadius: "6px",
      MozBorderRadius: "6px",
      WebkitBorderRadius: "6px",
      backgroundColor: "#000",
      objectFit: "cover",
    },
    [theme.breakpoints.down("xl")]: {
      height: 140,
    },
    [theme.breakpoints.down("xl")]: {
      height: 115,
    },
    [theme.breakpoints.down("lg")]: {
      height: 85,
    },
  },
  lobbyLocalMedia: {
    marginTop: 24,
    height: 503,
    width: "100%",
    maxWidth: 738,
    overflow: "hidden",
    borderRadius: 8,
    position: "relative",
    "& video": {
      height: "100%",
      width: "100%",
      transform: "rotateY(180deg)",
      WebkitTransform: "rotateY(180deg)",
      MozTransform: "rotateY(180deg)",
      borderRadius: "8px",
      MozBorderRadius: "8px",
      WebkitBorderRadius: "8px",
      backgroundColor: "#FCF9FF",
      objectFit: "cover",
    },
    [theme.breakpoints.down("xl")]: {
      flex: 1,
      borderRadius: 6,
    },
  },
  lobbyInputControlsContainer: {
    position: "absolute",
    bottom: 16,
    left: 0,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 16px",
    overflow: "visible",
    zIndex: 202,
  },
  lobbyInputControl: {
    position: "relative",
    width: "calc(33% - 8px)",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: 26,
    "& .MuiNativeSelect-select": {
      alignItems: "center",
    },
    "& .MuiInputBase-root": {
      paddingLeft: 22,
    },
  },
  mobileLobbyInputControlsContainer: {
    width: "80%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    overflow: "visible",
  },
  mobileLobbyInputControl: {
    position: "relative",
    width: "100%",
    marginTop: "16px",
  },
  pauseTextContainer: {
    position: "absolute",
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 3,
    zIndex: 300,
  },
  pauseText: {
    fontSize: "18px",
    color: "#fff",
  },
  screenShareMediaContainer: {
    width: "68%",
    height: "auto",
    zIndex: 201,
    position: "relative",
  },
  screenShareMedia: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    "& video": {
      height: "100%",
      width: "100%",
      objectFit: "contain",
    },
  },
  screenshareMessage: {
    position: "absolute",
    zIndex: 300,
    top: 0,
    padding: "5px 20px",
    borderRadius: 3,
    backgroundColor: "rgba(255,255,255,0.8)",
    height: "fit-content",
    "& p": {
      fontSize: 15,
      color: "#4e4e4e",
      fontWeight: 500,
      [theme.breakpoints.down("xl")]: {
        fontSize: 13,
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: 11,
      },
    },
  },
  mobileTopHeadShotMediaContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    height: 160,
    width: 230,
    zIndex: 240,
    position: "absolute",
    bottom: 480,
    right: 10,
    margin: 15,
    [`${theme.breakpoints.down("xl")} and (orientation: portrait)`]: {
      height: 115,
      width: 165,
      bottom: 390,
    },
    [`${theme.breakpoints.down("lg")} and (orientation: portrait)`]: {
      height: 85,
      width: 140,
      bottom: 320,
      margin: 5,
    },
    [`${theme.breakpoints.down("xl")} and (orientation:landscape)`]: {
      height: 115,
      width: 165,
      bottom: 255,
      right: 5,
    },
    [`${theme.breakpoints.down("lg")} and (orientation:landscape)`]: {
      height: 85,
      width: 140,
      bottom: 195,
      margin: 5,
    },
  },
  topHeadShotMediaContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: 288,
    zIndex: 240,
    position: "absolute",
    bottom: 393,
    left: 24,
    padding: 16,
    paddingBottom: 0,
    backgroundColor: "rgba(255, 255, 255, 0.50)",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    [theme.breakpoints.down("xl")]: {
      height: 140,
      width: 210,
      bottom: 336,
    },
    [theme.breakpoints.down("xl")]: {
      height: 115,
      width: 165,
      bottom: 274,
      padding: 10,
    },
    [theme.breakpoints.down("lg")]: {
      height: 85,
      width: 140,
      bottom: 204,
      padding: 5,
    },
  },
  mobileMiddleHeadShotMediaContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "flex-start",
    height: 160,
    width: 230,
    zIndex: 240,
    position: "absolute",
    bottom: 300,
    right: 10,
    margin: 15,
    [`${theme.breakpoints.down("xl")} and (orientation: portrait)`]: {
      height: 115,
      width: 165,
      bottom: 255,
    },
    [`${theme.breakpoints.down("lg")} and (orientation: portrait)`]: {
      height: 85,
      width: 140,
      bottom: 220,
      margin: 5,
    },
    [`${theme.breakpoints.down("xl")} and (orientation:landscape)`]: {
      height: 115,
      width: 165,
      bottom: 130,
      right: 5,
    },
    [`${theme.breakpoints.down("lg")} and (orientation:landscape)`]: {
      height: 85,
      width: 140,
      bottom: 100,
      margin: 5,
    },
  },
  middleHeadShotMediaContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "flex-start",
    height: 177,
    width: 288,
    zIndex: 240,
    position: "absolute",
    bottom: 216,
    left: 24,
    padding: 16,
    paddingBottom: 0,
    backgroundColor: "rgba(255, 255, 255, 0.50)",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    [theme.breakpoints.down("xl")]: {
      height: 140,
      width: 210,
      bottom: 196,
    },
    [theme.breakpoints.down("xl")]: {
      height: 115,
      width: 165,
      bottom: 159,
      padding: 10,
    },
    [theme.breakpoints.down("lg")]: {
      height: 85,
      width: 140,
      bottom: 119,
      padding: 5,
    },
  },
  headShotMedia: {
    height: 160,
    width: "100%",
    overflow: "hidden",
    "& video": {
      height: "100%",
      width: "100%",
      objectFit: "cover",
      borderRadius: "6px",
      MozBorderRadius: "6px",
      WebkitBorderRadius: "6px",
    },
    [theme.breakpoints.down("xl")]: {
      height: 140,
    },
    [theme.breakpoints.down("xl")]: {
      height: 115,
    },
    [theme.breakpoints.down("lg")]: {
      height: 85,
    },
  },
  headShotMediaDisabled: {
    height: "100%",
    width: "100%",
    overflow: "hidden",
    "& video": {
      display: "none",
      borderRadius: "6px",
      MozBorderRadius: "6px",
      WebkitBorderRadius: "6px",
    },
  },
  controlsContainer: {
    position: "absolute",
    bottom: 5,
    // right: 0,
    width: "fit-content",
    height: "fit-content",
    zIndex: 260,
    "& .settingsTip": {
      "& .MuiTooltip-tooltip": {
        marginBottom: 17,
        zIndex: 200,
        backgroundColor: "transparent",
        pointerEvents: "none",
      },
      "& .MuiTooltip-popper": {
        zIndex: 200,
        pointerEvents: "none",
      },
    },
  },
  controlsContainerMobile: {
    position: "absolute",
    bottom: 5,
    // right: 0,
    width: "fit-content",
    height: "fit-content",
    animation: "$slideIn 0.5s ease-out forwards",
    zIndex: 260,
    "& .settingsTip": {
      "& .MuiTooltip-tooltip": {
        marginBottom: 17,
        zIndex: 200,
        backgroundColor: "transparent",
        pointerEvents: "none",
      },
      "& .MuiTooltip-popper": {
        zIndex: 200,
        pointerEvents: "none",
      },
    },
  },
  controlsContainerHidden: {
    position: "absolute",
    bottom: 5,
    // right: 0,
    width: "fit-content",
    height: "fit-content",
    animation: "$slideOut 0.5s ease-out forwards",
    zIndex: 260,
    "& .settingsTip": {
      "& .MuiTooltip-tooltip": {
        marginBottom: 17,
        zIndex: 200,
        backgroundColor: "transparent",
        pointerEvents: "none",
      },
      "& .MuiTooltip-popper": {
        zIndex: 200,
        pointerEvents: "none",
      },
    },
  },
  clinicianOnlyControlsContainer: {
    position: "absolute",
    bottom: 5,
    right: 5,
    display: "flex",
    flexDirection: "column",
    width: "fit-content",
    height: "fit-content",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  controls: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
    height: "fit-content",
  },
  controlButtons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    margin: 15,
    zIndex: 203,
    height: 64,
    borderRadius: "64px",
    padding: "8px",
    gap: "8px",
  },
  control: {
    width: 48,
    height: 48,
    borderRadius: "100px",
  },
  endCallControl: {
    width: 48,
    height: 48,
    margin: "0 2px",
    fill: "#ffffff",
    backgroundColor: theme.palette.error.main,
    "&&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
    "&&:active": {
      backgroundColor: theme.palette.error.main,
    },
    borderRadius: "100px",
  },
  controlInUse: {
    width: 48,
    height: 48,
    borderRadius: "100px",
  },
  controlDisabled: {
    width: 48,
    height: 48,
    margin: "0 2px",
    pointerEvents: "none",
    color: "#D32F2F",
    "&:hover": {
      backgroundColor: "rgba(211, 47, 47, 0.04)",
    },
  },
  notesControl: {
    margin: "0 10px",
    color: "#fff",
    backgroundColor: "#a389d4",
    "&:hover": {
      backgroundColor: "#ba9ee2",
    },
    [theme.breakpoints.down("xl")]: {
      width: 45,
      height: 45,
      margin: "0 5px",
    },
  },
  exitControl: {
    width: 76,
    height: 56,
    margin: "0 10px",
    color: "#FAFAFA",
    backgroundColor: "#DC2626",
    "&:hover": {
      backgroundColor: "#991B1B",
    },
    borderRadius: 30,
    [theme.breakpoints.down("xl")]: {
      width: 45,
      height: 45,
      margin: "0 5px",
    },
  },
  clientMobileExitControl: {
    width: 56,
    height: 56,
    margin: "0 10px",
    color: "#FAFAFA",
    backgroundColor: "#DC2626",
    "&:hover": {
      backgroundColor: "#991B1B",
    },
    [theme.breakpoints.down("xl")]: {
      width: 45,
      height: 45,
      margin: "0 5px",
    },
  },
  settingControl: {
    width: 48,
    height: 48,
    margin: "0 2px",
    color: "rgba(0, 0, 0, 0.87)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
    position: "absolute",
    right: "15px",
    zIndex: 240,
  },
  settingsMenuItem: {
    "&:hover": {
      backgroundColor: "#e1e3e9",
    },
    "& p": {
      fontSize: 17,
      fontWeight: 500,
      fontColor: "#15171d",
    },
  },
  noteControl: {
    width: 48,
    height: 48,
    margin: "0 2px",
    color: "rgba(0, 0, 0, 0.87)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
    position: "absolute",
    right: "67px",
    zIndex: 240,
    [theme.breakpoints.down("xl")]: {
      width: 45,
      height: 45,
      margin: "0 5px",
    },
    [theme.breakpoints.down("lg")]: {
      position: "static",
    },
  },
  timerLabelContainer: {
    position: "absolute",
    top: 0,
    width: "100%",
    height: 20,
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    zIndex: 301,
    backgroundColor: "rgba(0,0,0,0.5)",
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },
  timerLabel: {
    color: "#d3d3d3",
    fontSize: 14,
    [theme.breakpoints.down("xl")]: {
      fontSize: 13,
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: 12,
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: 10,
    },
  },
  planTabHeader: {
    textTransform: "none",
    color: "black",
  },

  selectionBar: {
    boxShadow: "none",
    backgroundColor: "#d8d8d8",
    borderRadius: 24,
    margin: "15px 0",
  },
  tabBar: {
    backgroundColor: "#d8d8d8",
    borderLeft: "2px solid #d8d8d8",
    borderRight: "2px solid #d8d8d8",
    borderRadius: 24,
    minWidth: 0,
  },
  tabButton: {
    backgroundColor: "transparent",
    borderTop: "2px solid #d8d8d8",
    borderBottom: "2px solid #d8d8d8",
    width: "50%",
    opacity: 1,
    "& span": {
      color: "#4e4e4e",
      fontWeight: 500,
      fontSize: 15,
    },
  },
  activeTab: {
    backgroundColor: "#8E42CA",
    borderRadius: 24,
    width: "50%",
    "& span": {
      color: "#fff",
      fontWeight: 500,
      fontSize: 17,
    },
  },
  tabRoot: {
    minWidth: 0,
  },
  indicatorColor: {
    // backgroundColor: "#d8d8d8"
    display: "none",
  },
  engineControls: {
    minWidth: 100,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    marginLeft: "auto",
    marginRight: "auto",
    left: 0,
    right: 0,
    bottom: 85,
    padding: 10,
    zIndex: 260,
    [theme.breakpoints.down("xl")]: {
      padding: 0,
      right: 5,
      fontSize: 12,
    },
  },
  engineControl: {
    width: "100%",
    borderRadius: 20,
    backgroundColor: "#545a72",
    marginBottom: 10,
    justifyContent: "center",
    position: "relative",
    [theme.breakpoints.down("xl")]: {
      fontSize: 12,
    },
    "&:hover": {
      backgroundColor: "#495172",
    },
  },
  engineControlLobby: {
    margin: "20px 10px",
    [theme.breakpoints.down("xl")]: {
      height: 35,
      margin: 5,
    },
  },
  layoutContainer: {
    position: "relative",
    margin: "10px 0",
    width: 130,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: 8,
    backgroundColor: "#939393",
  },
  layout: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      "& p": {
        fontWeight: 500,
      },
      cursor: "pointer",
    },
  },
  currentLayout: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
    border: "2px solid #8E42CA",
    padding: 10,
    borderRadius: 6,
    "&:hover": {
      cursor: "pointer",
    },
  },
  layoutImage: {
    height: 60,
    width: "auto",
  },
  currentLayoutImage: {
    height: 60,
    width: "auto",
    borderRadius: 6,
  },
  layoutText: {
    fontSize: 12,
    color: "#fff",
    textAlign: "center",
    // lineHeight: 0.7
  },
  currentLayoutText: {
    fontSize: 12,
    color: "#fff",
    textAlign: "center",
    fontWeight: 500,
  },
  arrowUp: {
    position: "absolute",
    top: -10,
    width: 0,
    height: 0,
    borderLeft: "7px solid transparent",
    borderRight: "7px solid transparent",
    borderBottom: "10px solid #939393",
  },
  sliderValueLabel: {
    padding: 3,
    height: 14,
    borderRadius: 7,
    backgroundColor: "#8E42CA",
    width: "fit-content",
    "& p": {
      fontSize: 12,
      color: "#fff",
    },
  },
  arrowDown: {
    position: "absolute",
    bottom: -6,
    width: 0,
    height: 0,
    borderLeft: "4px solid transparent",
    borderRight: "4px solid transparent",
    borderTop: "6px solid #8E42CA",
  },
  drawerLayoutsContainer: {
    display: "flex",
    flexDirection: "column",
    height: 70,
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  drawerLayouts: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginBottom: 10,
    "& .tip": {
      padding: "0 15px",
      color: "var(--text-secondary, var(--text-secondary, rgba(0, 0, 0, 0.60)))",
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "166%" /* 19.92px */,
      letterSpacing: "0.4px",
    },
  },
  drawerLayout: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    "&:hover": {
      "& p": {
        fontWeight: 500,
      },
      cursor: "pointer",
    },
  },
  drawerCurrentLayout: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: "#fff",
    "& p": {
      fontWeight: 500,
    },
    "&:hover": {
      cursor: "pointer",
    },
  },
  drawerLayoutImage: {
    height: 50,
    width: "auto",
    marginTop: 16,
    marginBottom: 16,
  },
  drawerLayoutText: {
    fontSize: 11,
    color: "#4e4e4e",
    textAlign: "center",
    fontWeight: 500,
    // lineHeight: 0.7
  },
  drawerSectionContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "10px 20px",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  drawerSectionLabel: {
    color: "#2A2D39",
    fontSize: 18,
    fontWeight: 500,
    marginBottom: 10,
  },
  expansionPanel: {
    width: "100%",
    backgroundColor: "#C3C6D2",
    margin: "5px 0 !important",
    borderRadius: 5,
    "&:before": {
      backgroundColor: "transparent",
    },
  },
  expansionPanelSummary: {
    height: 30,
    minHeight: "0 !important",
    paddingRight: 0,
    margin: "0 !important",
    boxShadow: "none",
    "& div": {
      margin: "0",
      "& h1": {
        fontSize: 14,
        color: "#2A2D39",
        fontWeight: 500,
      },
    },
  },
  expandIconContainer: {
    height: 18,
    width: 18,
    borderRadius: 9,
    backgroundColor: "#4e4e4e",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  expansionDetailsRoot: {
    padding: 0,
  },
  animationsContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  animation: {
    width: "100%",
    height: 55,
    borderRadius: 4,
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 0,
    "&:hover": {
      backgroundColor: "#cfcfcf",
    },
    "& img": {
      height: "45%",
      paddingLeft: 10,
      paddingRight: 15,
    },
    "& .lottieContainer": {},
    "& .lottieTitle": {
      textTransform: "capitalize",
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "flex-start",
    },
  },
  animationTitle: {
    fontSize: 12,
  },
  animationDisplayContainer: {
    position: "absolute",
    height: 400,
    width: 300,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    zIndex: 302,
  },
  playingAnimation: {
    height: 300,
    width: 300,
  },
  endAnimationButton: {
    height: 36,
    borderRadius: 6,
    boxShadow: "none",
    backgroundColor: "#D32F2F",
    marginTop: 10,
    "&:hover": {
      backgroundColor: "#9c0000",
    },
    "& p": {
      color: "#fff",
      textTransform: "none",
      fontSize: 16,
      fontWeight: 500,
    },
    "& span": {
      color: "#fff",
    },
  },
  cardActivityControls: {
    zIndex: 360, // above drawer index
    width: 288,
    backgroundColor: "rgb(250, 250, 250, 0.5)",
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: 15,
    position: "absolute",
    top: 200,
    left: 24,
    "& p": {
      color: "black",
      fontSize: 14,
    },
  },
  cardUpcomingContainer: {
    // maxHeight: 200,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    backgroundColor: "rgb(250, 250, 250)",
    borderRadius: 8,
    padding: 5,
    "& p": {
      color: "black",
    },
  },
  cardUpcomingImage: {
    width: "45%",
    height: "auto",
    margin: "5px 0",
  },
  cardResults: {
    width: 256,
    height: 88,
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    backgroundColor: "rgb(250, 250, 250)",
    padding: 15,
    "& p": {
      color: "black",
      lineHeight: 1.2,
    },
  },
  endCardActivityButton: {
    height: 36,
    borderRadius: 6,
    boxShadow: "none",
    backgroundColor: "#D32F2F",
    marginTop: 10,
    "&:hover": {
      backgroundColor: "#9c0000",
    },
    "& p": {
      color: "#fff",
      textTransform: "none",
      fontSize: 16,
      fontWeight: 500,
    },
    "& span": {
      color: "#fff",
    },
  },
  skipCardButton: {
    height: 30,
    padding: "3px 0",
    alignSelf: "center",
    "& p": {
      textTransform: "none",
      fontSize: 13,
      color: "#fff",
    },
    "&:hover": {
      textDecoration: "underline",
      backgroundColor: "transparent",
    },
  },
  shareScreenContainer: {
    padding: "0 20px",
  },
  screenShareButton: {
    width: "100%",
    backgroundColor: "#C3C6D2",
    justifyContent: "flex-start",
    borderRadius: 3,
  },
  mobileClientScreenShareMediaContainer: {
    width: "75%",
    height: "auto",
    zIndex: 201,
    position: "relative",
    [`${theme.breakpoints.down("xl")} and (orientation: landscape)`]: {
      width: "70%",
      marginRight: "25%",
    },
  },
  clientScreenShareMediaContainer: {
    width: "75%",
    marginLeft: "19%",
    height: "auto",
    zIndex: 201,
    position: "relative",
    [theme.breakpoints.down("xl")]: {
      width: "70%",
      marginLeft: "20%",
    },
    [theme.breakpoints.down("xl")]: {
      width: "65%",
      marginLeft: "20%",
    },
    [theme.breakpoints.down("lg")]: {
      width: "70%",
      marginLeft: "25%",
    },
  },
  videoActivity: {
    height: "100%",
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 300,
  },
  videoPopover: {
    padding: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    borderRadius: 8,
  },
  endVideoButton: {
    height: 40,
    position: "absolute",
    right: -100,
    backgroundColor: "#1de9b6",
    "& p": {
      color: "#fff",
      textTransform: "none",
    },
  },
  videoControlsContainer: {
    width: "100%",
    height: "18%",
    backgroundColor: "rgba(244,244,244,0.95)",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    bottom: 0,
    left: 0,
    zIndex: 302,
  },
  endVideoButton: {
    height: 36,
    width: "100%",
    borderRadius: 18,
    boxShadow: "none",
    backgroundColor: "#d7d7d7",
    border: "1px solid #ff0000",
    marginTop: 10,
    "&:hover": {
      backgroundColor: "#4e4e4e",
    },
    "& p": {
      color: "#ff0000",
      textTransform: "none",
      fontSize: 16,
      fontWeight: 500,
    },
    "& span": {
      color: "#ff0000",
    },
  },
  videoActivityPaused: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    left: 0,
    filter: "blur(20px) brightness(0.4)",
    zIndex: 301,
  },
  signatures: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 250,
  },
  signaturesMessage: {
    maxWidth: 700,
    color: "#fff",
    textAlign: "center",
    [theme.breakpoints.down("xl")]: {
      fontSize: 15,
      maxWidth: "95%",
    },
  },
  signaturesContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: 24,
    borderRadius: 4,
    backgroundColor: "#F0F1F4",
    "& h5": {
      fontSize: 17,
      fontWeight: 500,
      color: "#15171D",
      marginBottom: 10,
    },
    [theme.breakpoints.down("xl")]: {
      flexDirection: "column",
      padding: 16,
      "& h5": {
        fontSize: 15,
        fontWeight: 500,
        color: "#15171D",
        marginBottom: 10,
      },
    },
  },
  signatureCanvasContainer: {
    margin: "8px 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    "& p": {
      color: "#fff",
      fontWeight: 500,
    },
  },
  signatureCanvas: {
    width: "100%",
    backgroundColor: "#fff",
    borderRadius: 4,
    border: "1px solid #A5AABC",
    "& canvas": {
      width: "100%",
      maxWidth: "100%",
      height: 120,
    },
  },
  sigError: {
    fontSize: 15,
    fontWeight: "500",
    color: "#B91C1C",
    marginTop: 5,
  },
  signatureStartEndTimesContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    [theme.breakpoints.down("xl")]: {
      flexDirection: "column",
    },
  },
  signatureStartEndTimes: {
    width: "50%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    "& p": {
      fontSize: 15,
      fontWeight: "500",
      marginRight: 24,
      color: "#69718F",
    },
    "& h6": {
      fontSize: 17,
      fontWeight: "400",
      color: "#15171D",
    },
    [theme.breakpoints.down("xl")]: {
      width: "100%",
      "& p": {
        fontSize: 13,
        fontWeight: "500",
        marginRight: 24,
        color: "#69718F",
      },
      "& h6": {
        fontSize: 15,
        fontWeight: "400",
        color: "#15171D",
      },
    },
  },
  clientSignatureTitle: {
    width: "100%",
    fontSize: "24px",
    fontWeight: 500,
    color: "#15171D",
    padding: "36px 24px 16px 24px",
    [theme.breakpoints.down("lg")]: {
      fontSize: 20,
      padding: "26px 16px 10px",
    },
  },
  clientSignatureContent: {
    padding: "0 24px",
    [theme.breakpoints.down("lg")]: {
      padding: "0 16px",
    },
  },
  popoverEndActivityMessage: {
    fontSize: 12,
    color: "red",
    margin: "5px auto 0",
  },

  videoActivityOverlay: {
    height: "100%",
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 301,
  },
  pointPromptContainer: {
    position: "absolute",
    bottom: -10,
    height: "60%",
    width: "60%",
    zIndex: 275,
  },
  promptActivityAnswer: {
    borderBottom: "6px solid #1de9b6",
    width: "70%",
    position: "absolute",
    bottom: 0,
    zIndex: 271,
  },
  formControl: {
    width: "100%",
    paddingBottom: 15,
  },
  noteHeader: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
  },
  newCodeContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  cptCodeTable: {
    marginTop: 10,
    borderRadius: 6,
    padding: "0 10px",
    backgroundColor: "#c7c7c7",
  },
  cptCodeMessage: {
    flex: 1,
    textAlign: "center",
    paddingLeft: 80,
  },
  waitingRoom: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    padding: "10px 10% 6%",
    backgroundColor: "#FCF9FF",
    [`${theme.breakpoints.down("lg")} and (orientation: portrait)`]: {
      padding: "7% 15px 15%",
    },
    [`${theme.breakpoints.down("lg")} and (orientation: landscape)`]: {
      padding: "10px 10% 5%",
    },
  },
  readyToJoinRoom: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    width: "100%",
    height: "100%",
    padding: "5% 10% 15%",
    backgroundColor: "#000",
    [`${theme.breakpoints.down("xl")} and (orientation: portrait)`]: {
      padding: "15% 24px",
    },
    [`${theme.breakpoints.down("xl")} and (orientation: landscape)`]: {
      padding: "10px 10%",
    },
  },
  hangTightStartCall: {
    height: 150,
    width: 363,
    padding: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#808080",
    borderRadius: 4,
    marginTop: 24,
    "& h5": {
      fontSize: 18,
      fontWeight: 500,
      color: "#fff",
    },
    "& p": {
      fontSize: 14,
      fontWeight: 500,
      color: "#fff",
    },
    [`${theme.breakpoints.down("xl")} and (orientation: portrait)`]: {
      maxWidth: "100%",
    },
  },
  hangTightEndCall: {
    height: 150,
    width: 363,
    padding: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: 4,
    marginTop: 24,
    backgroundColor: "#F0F1F4",
    border: "1px solid #E1E3E9",
    "& h5": {
      fontSize: 18,
      fontWeight: 500,
      color: "#202020",
    },
    "& p": {
      fontSize: 14,
      fontWeight: 500,
      color: "#202020",
    },
    [`${theme.breakpoints.down("xl")} and (orientation: portrait)`]: {
      maxWidth: "100%",
    },
  },
  reactMicContainer: {
    position: "absolute",
    bottom: 5,
    left: 5,
    width: 26,
    height: 26,
    borderRadius: 13,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 301,
    [theme.breakpoints.down("xl")]: {
      width: 20,
      height: 20,
      borderRadius: 10,
    },
  },
  micIcon: {
    color: "#fff",
    fontSize: 18,
    [theme.breakpoints.down("xl")]: {
      fontSize: 12,
    },
  },
  videoDisabledIconContainer: {
    position: "absolute",
    bottom: 5,
    left: 36,
    width: 26,
    height: 26,
    borderRadius: 13,
    backgroundColor: "#DC2626",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 301,
    [theme.breakpoints.down("xl")]: {
      width: 20,
      height: 20,
      borderRadius: 10,
    },
  },
  cameraDisabledPlaceholder: {
    width: "100%",
    height: "100%",
    backgroundColor: "#000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    left: 0,
    borderRadius: 6,
    zIndex: 201,
  },
  cameraDisabledLobby: {
    height: 100,
    width: 100,
    borderRadius: 50,
    backgroundColor: "#8E42CA",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cameraDisabledInitial: {
    height: 40,
    width: 40,
    borderRadius: 20,
    backgroundColor: "#8E42CA",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  testInfo: {
    height: "fit-content",
    maxHeight: "85%",
    width: "100%",
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: 40,
    [theme.breakpoints.down("lg")]: {
      padding: 10,
    },
  },
  infoContainer: {
    width: "80%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    padding: 10,
    marginTop: 30,
    marginBottom: 10,
    [theme.breakpoints.down("xl")]: {
      width: "100%",
      padding: 5,
      marginTop: 0,
    },
  },
  lobbyTitle: {
    fontSize: 24,
    fontWeight: 500,
    color: "#15171D",
    textAlign: "center",
    // [theme.breakpoints.down("sm")]: {
    //     fontSize: 18,
    //     marginTop: 5,
    // },
  },
  lobbyCallTime: {
    fontSize: 14,
    fontWeight: 500,
    color: "#545A72",
    marginTop: 10,
    [theme.breakpoints.down("xl")]: {
      fontSize: 12,
      marginTop: 5,
    },
  },
  lobbySubtitle: {
    marginTop: 16,
    fontSize: 24,
    color: "#2A2D39",
    textAlign: "center",
    [theme.breakpoints.down("xl")]: {
      fontSize: 17,
    },
  },
  lobbyMessageContainer: {
    width: 380,
    [theme.breakpoints.down("xl")]: {
      width: "95%",
    },
  },
  lobbyMessage: {
    marginTop: 15,
    color: "#2A2D39",
    textAlign: "center",
    fontSize: 19,
    [theme.breakpoints.down("xl")]: {
      fontSize: 15,
    },
  },
  lobbyTimerText: {
    width: 160,
    textAlign: "center",
    "& p": {
      fontSize: 16,
      color: "#2A2D39",
      fontWeight: 500,
    },
    [theme.breakpoints.down("xl")]: {
      width: 200,
    },
  },
  lobbyClinicianInfoContainer: {
    zIndex: 261,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    position: "absolute",
    bottom: 24,
    left: 24,
    [theme.breakpoints.down("lg")]: {
      width: "100%",
      alignItems: "center",
      position: "static",
      marginTop: 10,
    },
  },
  lastActiveText: {
    fontSize: 14,
    fontWeight: 500,
    color: "#545A72",
  },
  lobbyButtonsContainer: {
    width: "55%",
    height: 100,
    marginTop: 50,
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    [theme.breakpoints.down("xl")]: {
      width: "70%",
    },
    [theme.breakpoints.down("xl")]: {
      marginTop: 10,
      height: 200,
      flexDirection: "column",
    },
  },
  lobbyButton: {
    height: 50,
    fontSize: 15,
  },
  callReadyContent: {
    height: "100%",
    width: "60%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    paddingBottom: 0,
    backgroundColor: "#fff",
    [`${theme.breakpoints.down("xl")} and (orientation: portrait)`]: {
      padding: 10,
      width: "100%",
      height: "50%",
    },
  },
  callReadyVideoContainer: {
    height: "100%",
    width: "40%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    textAlign: "center",
    overflow: "hidden",
    "& video": {
      height: "100%",
      width: "auto",
      [`${theme.breakpoints.down("xl")} and (orientation: portrait)`]: {
        height: "100%",
        width: "100%",
        objectFit: "cover",
      },
    },
    [`${theme.breakpoints.down("xl")} and (orientation: portrait)`]: {
      height: "50%",
      width: "100%",
    },
  },
  permissionsContentContainer: {
    height: 210,
    width: 770,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: 0,
    margin: 0,
    backgroundColor: "#131333",
    [`${theme.breakpoints.down("xl")} and (orientation: portrait)`]: {
      flexDirection: "column",
      width: "100%",
      height: "55vh",
    },
    [`${theme.breakpoints.down("xl")} and (orientation: landscape)`]: {
      width: "100%",
    },
  },
  permissionsContent: {
    height: "100%",
    width: "70%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    padding: 20,
    paddingBottom: 0,
    backgroundColor: "#fff",
    [`${theme.breakpoints.down("xl")} and (orientation: portrait)`]: {
      padding: 10,
      width: "100%",
      height: "70%",
    },
  },
  permissionsIconContainer: {
    height: "100%",
    width: "30%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    "& video": {
      height: "100%",
      width: "auto",
    },
    [`${theme.breakpoints.down("xl")} and (orientation: portrait)`]: {
      height: "30%",
      width: "100%",
    },
  },
  permissionsIcon: {
    fill: "#5d5470",
    fontSize: 120,
    [theme.breakpoints.down("xl")]: {
      fontSize: 80,
    },
  },
  permissionsTitle: {
    width: "100%",
    padding: 0,
    "& h4": {
      fontSize: 22,
      fontWeight: 500,
      [theme.breakpoints.down("xl")]: {
        fontSize: 18,
      },
    },
  },
  permissionButton: {
    [theme.breakpoints.down("xl")]: {
      "& span": {
        fontSize: 12,
      },
    },
    [theme.breakpoints.down("lg")]: {
      "& span": {
        fontSize: 10,
      },
    },
  },
  troubleshootingButton: {
    "& span": {
      [theme.breakpoints.down("xl")]: {
        fontSize: 13,
      },
    },
  },
  permissionCamIcon: {
    color: "#fff",
    fontSize: 26,
    [theme.breakpoints.down("xl")]: {
      fontSize: 20,
    },
  },
  permissionsContentText: {
    color: "#000",
    fontSize: 16,
    [theme.breakpoints.down("xl")]: {
      fontSize: 14,
    },
  },
  givePermissionsButton: {
    backgroundColor: "#5CD0B4",
    "&:hover": {
      backgroundColor: "#7BDCBB",
    },
  },
  givePermissionsButtonText: {
    textTransform: "none",
    fontWeight: 500,
    color: "#fff",
    [theme.breakpoints.down("xl")]: {
      fontSize: 14,
    },
  },
  explainPermissionsText: {
    textTransform: "none",
    color: "#000",
    fontSize: 13,
    textDecoration: "underline",
    "&:hover": {
      color: "#4e4e4e",
      textDecoration: "none",
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: 12,
    },
  },
  startButton: {
    marginTop: 24,
  },
  startButtonDisabled: {
    backgroundColor: "#c3c6d2",
    color: "#878da5",
    "&:hover": {
      backgroundColor: "#e1e3e9",
    },
    height: 50,
  },
  secondaryJoinButton: {
    backgroundColor: "#1DE9B6",
    "&:hover": {
      backgroundColor: "#26b591",
    },
    height: 50,
  },
  standardButton: {
    backgroundColor: "#5CD0B4",
    boxShadow: "none",
    "& span": {
      textTransform: "none",
      color: "#fff",
      fontWeight: 500,
    },
    "&:hover": {
      backgroundColor: "#56c2a7",
      boxShadow: "none",
    },
  },
  anLogo: {
    position: "absolute",
    top: 24,
    left: 24,
    zIndex: 302,
    height: 30,
    width: "auto",
    [theme.breakpoints.down("xl")]: {
      top: 18,
      left: 18,
    },
  },
  goBackButton: {
    position: "absolute",
    top: 24,
    right: 24,
    zIndex: 302,
    "& span": {
      textTransform: "none",
      fontSize: 12,
      fontWeight: 500,
      color: "#8E42CA",
    },
    [theme.breakpoints.down("xl")]: {
      right: 18,
      top: 18,
      "& span": {
        fontSize: 11,
      },
    },
  },
  sessionNotesContainer: {
    width: "100%",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "20px",
  },
  noteContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flex: 1,
  },
  noteDetailsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flex: 1,
    width: "100%",
  },
  noteTitleContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  noteAuthor: {
    fontSize: 15,
    fontWeight: 500,
    letterSpacing: 0.5,
    color: "#3e3e3e",
    marginBottom: 5,
  },
  noteBillingType: {
    fontSize: 16,
    letterSpacing: 0.5,
    color: "#552879",
    fontWeight: 500,
  },
  noteDate: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.5,
    color: "#2a2d39",
  },
  noteEntryTitle: {
    fontSize: 16,
    color: "#2a2d39",
    fontWeight: 500,
    margin: "20px 0 5px",
  },
  oldNoteDate: {
    fontSize: 13,
    fontWeight: "500",
    letterSpacing: 0.5,
    color: "#3e3e3e",
    marginLeft: 10,
  },
  participants: {
    width: "100%",
    fontSize: 13,
    letterSpacing: 0.5,
    marginBottom: 5,
  },
  note: {
    width: "100%",
    fontSize: 15,
    letterSpacing: 0.5,
    color: "#2a2d39",
  },
  noteDivider: {
    width: "100%",
    height: 0,
    border: "1px solid #a5aabc",
    backgroundColor: "#a5aabc",
    margin: "15px 0",
  },
  closeButton: {
    position: "absolute",
    left: "1",
    top: "1",
  },
  speedContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    marginTop: "15px",
  },
  speedCard: {
    width: "46%",
    textAlign: "center",
    padding: "5px",
    boxSizing: "border-box",
  },
  progressContainer: {
    width: "100%",
    padding: "5px",
    boxSizing: "border-box",
  },
  progressBar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 5px",
    boxSizing: "border-box",
  },
  downloadProgressBar: {
    color: "#9575cd",
  },
  wrapIcon: {
    display: "inline-flex",
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      fontSize: 13,
    },
  },
  gaugeContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
  },
  gauge: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  chart: {
    width: "80%",
    display: "flex",
    justifyContent: "center",
  },
  buttonContainer: {
    width: "100%",
    position: "relative",
    padding: "10px",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "5px",
  },
  speedTest: {
    width: "65px",
    height: "65px",
    borderRadius: "50%",
    fontSize: "15px",
  },

  performanceTitle: {
    fontWeight: 500,
    textAlign: "center",
    borderBottom: "1px solid #d3d3d3",
    padding: "8px",
    boxSizing: "border-box",
  },
  checkButton: {
    width: "65px",
    height: "65px",
    borderRadius: "50%",
    fontSize: "15px",
  },
  performanceContainer: {
    textAlign: "center",
    padding: "5px 25px",
    boxSizing: "border-box",
    margin: "5px 0px 15px",
  },
  selectionContainer: {
    margin: "10px auto",
    width: "100%",
  },
  selectionDropdown: {
    width: "75%",
  },
  settingTitle: {
    marginBottom: "15px",
    fontSize: "20px",
    fontWeight: 500,
  },
  settingSubtitle: {
    marginBottom: "10px",
  },
  audioContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  micContainer: {
    width: "20%",
    height: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  micMutedText: {
    width: "100%",
    height: 35,
    backgroundColor: "#f0f1f4",
    display: "flex",

    justifyContent: "center",
    alignItems: "center",
    borderRadius: 3,
    "& p": {
      fontSize: "13px",
      color: "#3f4456",
      fontWeight: 500,
    },
  },
  canvas: {
    border: "1px solid #c3c6d2",
    borderRadius: 3,
    zIndex: 301,
  },
  microphoneIcon: {
    color: "#8E42CA",
    fontSize: 28,
    [theme.breakpoints.down("xl")]: {
      fontSize: 25,
    },
  },
  lastActiveContainer: {
    position: "absolute",
    bottom: 30,
    right: 280,
    padding: 5,
  },
  roleLabelContainer: {
    position: "absolute",
    bottom: 0,
    width: "calc(100% - 32px)",
    height: 18,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: "rgba(255, 255, 255, 0.05)",
    backdropFilter: "blur(2px)",
    borderBottomRightRadius: 6,
    borderBottomLeftRadius: 6,
    zIndex: 301,
    paddingLeft: 8,
    [theme.breakpoints.down("xl")]: {
      width: "calc(100% - 20px)",
    },
    [theme.breakpoints.down("lg")]: {
      width: "calc(100% - 10px)",
    },
  },
  roleLabel: {
    fontSize: 12,
    fontWeight: "500",
    color: "#ffffff",
    [theme.breakpoints.down("xl")]: {
      fontSize: 11,
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: 10,
    },
  },
  videoControlsDisabled: {
    opacity: "0.3",
    pointerEvents: "none",
  },
  permissionControlContainer: {
    width: "100%",
    padding: 10,
    marginTop: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  controlContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  passControlBtn: {
    backgroundColor: "#e8e8e8",
    height: 36,
    margin: "10px 0",
  },
  makeRequestBtn: {
    height: 36,
    margin: "10px 0",
  },
  buttonsContainer: {
    "& .group": {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      padding: 8,
    },
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  permissionControlTitle: {
    fontWeight: 500,
    fontSize: 14,
    textAlign: "flex-start",
    width: "100%",
    color: "var(--text-primary, rgba(0, 0, 0, 0.87))",
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "160%" /* 32px */,
    letterSpacing: "0.15px",
    padding: "16px 24px 16px 24px",
  },
  permissionControlText: {
    fontSize: 14,
    width: "100%",
    padding: "0 24px 20px 24px",
  },
  alertText: {
    fontSize: 12,
    "&.MuiAlert-root": {
      padding: 0,
      width: "100%",
    },
  },
  passControlsConfirmContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flext-start",
    alignItems: "center",
  },
  socketConnectionIndicator: {
    width: 8,
    height: 8,
    borderRadius: 4,
    zIndex: 400,
    position: "absolute",
    bottom: 6,
    left: 6,
  },
  logo: {
    width: 150,
    height: "auto",
    marginBottom: 20,
    [theme.breakpoints.down("xl")]: {
      width: 120,
    },
  },
  testsContainer: {
    marginTop: 20,
    display: "flex",
    alignItems: "center",
    [`${theme.breakpoints.down("xl")} and (orientation: portrait)`]: {
      flexDirection: "column",
      marginTop: 10,
    },
    [`${theme.breakpoints.down("xl")} and (orientation: landscape)`]: {
      marginTop: 10,
      marginLeft: 20,
    },
  },
  // PARENT
  cardActivityContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
  },
  shakeAnimation: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    animation: "$shake 0.82s cubic-bezier(.36,.07,.19,.97) both",
    transform: "translate3d(0, 0, 0)",
  },
  "@keyframes shake": {
    "10%": {
      transform: "translate3d(-1px, 0, 0)",
    },
    "20%": {
      transform: "translate3d(2px, 0, 0)",
    },
    "30%": {
      transform: "translate3d(-4px, 0, 0)",
    },
    "40%": {
      transform: "translate3d(4px, 0, 0)",
    },
    "50%": {
      transform: "translate3d(-4px, 0, 0)",
    },
    "60%": {
      transform: "translate3d(4px, 0, 0)",
    },
    "70%": {
      transform: "translate3d(-4px, 0, 0)",
    },
    "80%": {
      transform: "translate3d(2px, 0, 0)",
    },
    "90%": {
      transform: "translate3d(-1px, 0, 0)",
    },
  },
  pointPromptContainer: {
    position: "absolute",
    bottom: -10,
    height: "60%",
    width: "60%",
    zIndex: 275,
    cursor: "pointer",
  },
  fadePrompt: {
    animation: "$fade 1s ease",
    opacity: 0.4,
  },
  "@keyframes fade": {
    "0%": {
      opacity: 1,
    },
    "100%": {
      opacity: 0.4,
    },
  },
  cardActivityImage: {
    height: "60%",
    width: "auto",
    zIndex: 271,
  },
  cardActivityPrompt: {
    fontSize: 24,
    fontWeight: 500,
    color: "#4e4e4e",
    textAlign: "center",
    zIndex: 271,
  },
  successAnimationContainer: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 30,
    zIndex: 270,
  },
  clientPromptActivityContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    flexWrap: "wrap",
    margin: "auto",
  },
  promptShakeAnimation: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    flexWrap: "wrap",
    animation: "$shake 0.82s cubic-bezier(.36,.07,.19,.97) both",
    transform: "translate3d(0, 0, 0)",
  },
  animationContainer: {
    width: 400,
    height: 400,
    position: "absolute",
    zIndex: 301,
  },
  clientActivityContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
    width: "100%",
    background: "#d7d7d7",
    position: "relative",
    overflow: "hidden",
  },
  clientActivityVideoSplit: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    height: "48%",
    width: "50%",
    background: "#d7d7d7",
    borderRadius: 8,
    boxShadow: "0 2px 3px rgba(0,0,0,.4)",
    position: "relative",
    overflow: "hidden",
    [theme.breakpoints.down("xl")]: {
      width: "100%",
      height: "60%",
    },
  },
  localMediaNetworkLevelContainer: {
    position: "absolute",
    bottom: 24,
    right: 24,
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 302,
    [theme.breakpoints.down("xl")]: {
      width: 20,
      height: 20,
    },
  },
  signalIcon: {
    color: "#FAFAFA",
    fontSize: 24,
    [theme.breakpoints.down("xl")]: {
      fontSize: 18,
    },
  },
  alertContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    backgroundColor: "#fff",
    border: "none",
    padding: 10,
    color: theme.palette.text.primary,
    "& p": {
      fontSize: "13px",
      color: "#69718F",
    },
  },
  warningIconContainer: {
    width: 40,
    height: 40,
    backgroundColor: "#f0f1f4",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    marginRight: 10,
    backgroundColor: "#FEF2F2",
  },
  warningIcon: {
    color: "#EF4444",
    fontSize: 20,
  },
  unsupportedPlatformDialogContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  notificationIconContainer: {
    width: 40,
    height: 40,
    backgroundColor: "#f0f1f4",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
  },
  unsupportedPlatformTitle: {
    marginTop: 10,
    fontSize: 16,
    color: "#15171D",
    fontWeight: 500,
  },
  unsupportedPlatformTextContainer: {
    marginTop: -10,
    lineHeight: 20,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    "& p": {
      fontSize: 14,
      color: "#69718F",
      fontWeight: 500,
    },
  },
  unsupportedPlatformActionsContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    backgroundColor: "#F0F1F4",
    padding: 15,
    marginTop: 10,
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
  },
  notShowCheckbox: {
    color: "#545a72",
    "& span": {
      fontSize: 14,
      fontWeight: 500,
    },
  },
  errorContentContainer: {
    minHeight: 210,
    width: 770,

    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    padding: 20,
    backgroundColor: "#fff",
    [`${theme.breakpoints.down("xl")} and (orientation: portrait)`]: {
      padding: 10,
      width: "100%",
      height: "70%",
    },
  },
  errorContent: {
    color: "#000",
    width: "100%",
    "& h4": {
      textAlign: "center",
      fontSize: 19,
      fontWeight: 500,
      [theme.breakpoints.down("xl")]: {
        fontSize: 17,
      },
    },
    "& p": {
      fontSize: 17,
      marginTop: 10,
      [theme.breakpoints.down("xl")]: {
        fontSize: 15,
      },
    },
    "& ul li p": {
      fontSize: 16,
      [theme.breakpoints.down("xl")]: {
        fontSize: 14,
      },
    },
  },
  solutionList: {
    border: "1px solid blue",
  },
  errorContentButtonContainer: {
    width: "80%",
    display: "flex",
    justifyContent: "space-evenly",
    margin: "10px 0px",
    "& button": {
      "& span": {
        fontSize: 15,
        [theme.breakpoints.down("xl")]: {
          fontSize: 12,
        },
        [theme.breakpoints.down("lg")]: {
          fontSize: 10,
        },
      },
    },
  },
  infoAlertContainer: {
    width: "100%",
    marginTop: 8,
    padding: "6px 16px",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    backgroundColor: "#E5F6FD",
    borderRadius: 4,
    "& p": {
      fontSize: 14,
      fontWeight: "400",
      color: "#014361",
      letterSpacing: "0.17px",
      padding: "8px 0",
    },
  },
  infoAlertIconContainer: {
    width: 34,
    height: 36,
    padding: "7px 12px 7px 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  "@keyframes slideIn": {
    "0%": {
      transform: "translateY(100%)",
    },
    "100%": {
      transform: "translateY(0)",
    },
  },
  "@keyframes slideOut": {
    "0%": {
      transform: "translateY(0)",
    },
    "100%": {
      transform: "translateY(100%)",
    },
  },
  "@keyframes slideUp": {
    from: {
      bottom: 10,
    },
    to: {
      bottom: 90,
    },
  },
  "@keyframes slideDown": {
    from: {
      bottom: 90,
    },
    to: {
      bottom: 10,
    },
  },
});
