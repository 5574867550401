import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withStyles from "@mui/styles/withStyles";
import actions from "../../actions";
import styles from "./styles";
import PageContainer from "elements/PageContainer";
import {
  getCreateProviderLoading,
  getCreateProviderSuccess,
  getInsuranceLoading,
  getInsuranceProvider,
} from "../../selectors";
import SectionContainer from "elements/SectionContainer";
import Heading from "elements/Heading";
import TextField from "@mui/material/TextField";
import {
  Button,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Snackbar,
  Typography,
} from "@mui/material";
import { ArrowBackOutlined } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Alert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";

const mapStateToProps = (state) => ({
  insuranceProvider: getInsuranceProvider(state),
  loading: getInsuranceLoading(state),
  updateLoading: getCreateProviderLoading(state),
  updateSuccess: getCreateProviderSuccess(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateInsuranceProvider: actions.updateInsuranceProvider,
      getInsuranceProvider: actions.getInsuranceProvider,
    },
    dispatch,
  );

class InsuranceProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editEnabled: false,
      isDirty: false,
      id: null,
      name: "",
      contractType: "",
      nameError: false,
      contractTypeError: false,
      successToastOpen: false,
      error: false,
    };
  }

  componentDidMount() {
    this.props.getInsuranceProvider(this.props.match?.params?.id);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (!prevProps.insuranceProvider && this.props.insuranceProvider) ||
      (prevProps.insuranceProvider?.id !== this.props.insuranceProvider?.id &&
        this.props.insuranceProvider?.id)
    ) {
      this.setState({
        name: this.props.insuranceProvider.name,
        contractType: this.props.insuranceProvider.contract_type,
        id: this.props.insuranceProvider.id,
      });
    }
    if (prevProps.updateLoading && !this.props.updateLoading) {
      if (this.props.updateSuccess) {
        this.setState({ successToastOpen: true, editEnabled: false }, () => {
          setTimeout(() => {
            this.setState({ successToastOpen: false });
          }, 4000);
        });
      } else {
        this.setState({ error: true });
      }
    }
  }

  onChange = (name) => (e) => {
    this.setState({ [name]: e.target.value, isDirty: true });
  };

  onRadioButtonChange = (name) => (e) => {
    const { value } = e.target;
    this.setState({
      [name]: this.stringToBoolean(value),
      [`${name}Error`]: false,
      isDirty: true,
    });
  };

  stringToBoolean = (value) => {
    if (value && typeof value === "string") {
      if (value.toLowerCase() === "true") return true;
      if (value.toLowerCase() === "false") return false;
    }
    return value;
  };

  goBack = () => {
    this.props.history.push("/libraries");
  };

  onToggleEnableEdit = () => {
    this.setState({ editEnabled: !this.state.editEnabled });
  };

  onClickSave = () => {
    const { id, name, contractType } = this.state;
    this.setState(
      {
        nameError: !name,
        contractTypeError: !contractType,
        error: false,
      },
      () => {
        if (this.state.nameError || this.state.contractTypeError) {
          return;
        } else {
          this.props.updateInsuranceProvider({
            id,
            name,
            contractType,
          });
        }
      },
    );
  };

  render() {
    const { loading, updateLoading, classes, insuranceProvider } = this.props;
    const {
      editEnabled,
      isDirty,
      name,
      contractType,
      nameError,
      contractTypeError,
      successToastOpen,
      error,
    } = this.state;

    return (
      <>
        <PageContainer loading={loading}>
          <SectionContainer noPadding columnDirection>
            <div className={classes.header}>
              <Button
                variant="text"
                onClick={this.goBack}
                className={classes.goBackButton}
                startIcon={
                  <div className={classes.goBackIconContainer}>
                    <ArrowBackOutlined style={{ fill: "#3F4456", fontSize: 15 }} />
                  </div>
                }
              >
                Back to Insurances
              </Button>
              <Heading>Insurance Company Details</Heading>
              <div
                style={{
                  width: 200,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <IconButton
                  className={editEnabled ? classes.iconButtonEnabled : classes.iconButton}
                  color="primary"
                  onClick={this.onToggleEnableEdit}
                  size="large"
                >
                  <EditIcon className={classes.editIcon} />
                </IconButton>
              </div>
            </div>
            <div className={classes.errorMessageContianer} style={{ marginTop: -20 }}>
              {error && (
                <Typography component="p">
                  Something went wrong on our end. Please try again.
                </Typography>
              )}
            </div>
            <div className={classes.providerDetailsContainer}>
              {editEnabled ? (
                <>
                  <Grid container justifyContent="center" alignItems="flex-start" spacing={4}>
                    <Grid item xs={12}>
                      <FormLabel
                        component="legend"
                        className={classes.formLabel}
                        style={{ marginBottom: 10 }}
                      >
                        Insurance Company Name*
                      </FormLabel>
                      <TextField
                        id="name"
                        name="name"
                        autoComplete="off"
                        onChange={this.onChange("name")}
                        value={name}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        placeholder="Enter insurance company name"
                        onBlur={() =>
                          nameError && name ? this.setState({ nameError: false }) : null
                        }
                        helperText={nameError ? "Please enter an insurance name" : null}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} style={{ marginBottom: 5 }}>
                      <FormLabel component="legend" className={classes.formLabel}>
                        Contract Type*
                      </FormLabel>
                      <RadioGroup
                        aria-label="contractType"
                        name="contractType"
                        value={contractType}
                        onChange={this.onRadioButtonChange("contractType")}
                      >
                        <FormControlLabel
                          value={"group"}
                          control={<Radio color="primary" />}
                          label="Group Contract"
                          size="small"
                        />
                        <FormControlLabel
                          value={"single_case"}
                          control={<Radio color="primary" />}
                          label="Single Case Agreement"
                          size="small"
                        />
                      </RadioGroup>
                      {contractTypeError && (
                        <Typography component="p" className={classes.helperText}>
                          Please select a contract type
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                  {editEnabled && (
                    <div className={classes.saveButtonContainer}>
                      <LoadingButton
                        variant={isDirty ? "contained" : "outlined"}
                        disabled={!isDirty}
                        onClick={this.onClickSave}
                        loading={updateLoading}
                      >
                        Save Changes
                      </LoadingButton>
                    </div>
                  )}
                </>
              ) : (
                <Grid container justifyContent="center" alignItems="flex-start" spacing={4}>
                  <Grid item xs={12}>
                    <Typography component="p" className={classes.label}>
                      Insurance Company Name
                    </Typography>
                    <Typography component="p" className={classes.info}>
                      {name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography component="p" className={classes.label}>
                      Contract Type
                    </Typography>
                    <Typography component="p" className={classes.info}>
                      {contractType
                        ? contractType === "group"
                          ? "Group Contract"
                          : "Single Case Agreement"
                        : "----"}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </div>
          </SectionContainer>
        </PageContainer>
        {successToastOpen && (
          <div className={classes.snackbarContainer}>
            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={successToastOpen}
              classes={{ anchorOriginTopCenter: classes.snackbar }}
              onClose={() => this.setState({ successToastOpen: false })}
            >
              <Alert elevation={6} variant="outlined" severity="success">
                <Typography
                  component="p"
                  style={{ fontSize: 16, fontWeight: 500, color: "#047857" }}
                >
                  Insurance provider has been updated
                </Typography>
              </Alert>
            </Snackbar>
          </div>
        )}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(InsuranceProvider));
