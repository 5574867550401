export const mapServiceTypeToTemplateText = (serviceType) => {
  if (serviceType.includes("Initial Assessment")) {
    return {
      focus: "Face-to-Face Parent Interview and/or Skill Assessment",
      noteBlockTitle: "Client Observation",
      noteBlockInstruction:
        "In this section notate what was observed, as well as any probe data conducted",
      noteBlockExample: `Example: \n
            -During the assessment session, it was noted that CLIENT was able to initiate manding by pulling his mother towards preferred items. No verbal mands were observed. \n
            -CLIENT was able to tact mom, dog, and cat. No independent or interactive play was observed. \n
            -CLIENT engaged with play cars but threw them across the room or lined them up. \n
            -CLIENT eloped from the room 4 times and responded to name once. \n
            -CLIENT acknowledged BCBA by saying ""hi"" with a prompt from his mother. \n
            -Client imitated arms up and throwing in the context of playing in a ball pit.`,
      noteBlockPlaceholder: "Enter a summary of observations during session",
      responseBlockTitle: "Assessment Items Completed",
      responseBlockInstruction:
        "The note clearly details the evaluations and assessments conducted during the session. If the appointment occurred for the purpose of reviewing the treatment plan with the family, does the note clearly outline the information that was shared with the family, the family's feedback, and changes that will be made to the treatment plan based on that feedback.",
      responseBlockExample:
        "Example: BCBA completed the VBMAPP Barriers assessment through direct assessment and parent interview. BCBA Completed level 1 of the VBMAPP Milestones by direct assessment with Client. BCBA will begin to summarize results and develop initial treatment plan based off skill deficits and barriers.",
      responseBlockPlaceholder: "Enter a summary of assessment items completed",
    };
  } else if (serviceType.includes("Direct Therapy")) {
    return {
      focus: "1:1 direct therapy to address skills deficits",
      noteBlockTitle: "Summary of Client Response",
      noteBlockInstruction:
        "In this section notate client's interaction with BCBA during session, any barriers to progress, client's response to programming, and prompt methods used throughout treatment session.",
      noteBlockExample: `Example: CLIENT NAME was an active participant in today's direct treatment session with Writer. CLIENT NAME displayed two elopement behaviors but was easily vocally prompted to return to session. CLIENT NAME completed trials on matching and needed two gestural prompts before fading to independent responding. BCBA probed reinforcers throughout session where CLIENT NAME chose to work for her favorite items throughout programming trials. `,
      noteBlockPlaceholder: "Enter a summary of client response",
      responseBlockTitle: "Summary of Client Progress and Data",
      responseBlockInstruction:
        "The note contains data collected during the session on specific targets. The note highlights progress and barriers on individual targets.  The note includes the domains/goals targeted (tied back to the client's treatment plan). The note provides details about the specific teaching, behavior reduction, or assessment procedures implemented.",
      responseBlockExample:
        "Example: During today's session CLIENT NAME completed trials on wh- questions, listener responding skills by function, feature, and class as well as activities of daily living goal for brushing teeth. CLIENT NAME's session data is as follows: Wh-questions 8/10, LRFFC 5/10 or 50%, ADL Brushing Teeth TA: 17 out of 20 steps independently completed. ",
      responseBlockPlaceholder: "Enter a summary of client progress and data",
    };
  } else if (serviceType.includes("Multiple-family Caregiver")) {
    return {
      focus: "Multiple-family group adaptive behavior treatment guidance",
      noteBlockTitle: "Summary of Parent Training Implemented",
      noteBlockInstruction:
        "The note clearly describes the ABA principles covered during the session and/ or recommendations provided by the BCBA",
      noteBlockExample: `Example: BCBA Provided training to CLIENTS Mother on antecedents, behaviors, and consequences. BCBA Provide examples of CLIENTS behavior and had his Mother practice collecting ABC data to best determine target behaviors function not observed during ABA sessions. BCBA Modeled collecting this data and provdied feedback on CLIENTS Mother's data.`,
      noteBlockPlaceholder: "Enter a summary of the meeting",
      responseBlockTitle: "Summary of response and progress",
      responseBlockInstruction:
        "The note clearly documents progress toward caregiver training goals (including specific data collected during the session when appropriate).",
      responseBlockExample:
        "Example: While BCBA provided modeling, Client's parents practiced collecting ABC Data on CLIENTS elopement behavior. Client's parents were able to determine the antecedent is told no and determined the consequence is normally chasing after the client. BCBA and Client's parents discussed other consequences more appropriate in hopes of elopement extinction.",
      responseBlockPlaceholder: "Enter a summary of response and progress",
    };
  } else if (serviceType.includes("Caregiver")) {
    return {
      focus: "Parent/Caregiver training on treatment plan protocols related to ASD diagnosis",
      noteBlockTitle: "Summary of Parent Training Implemented",
      noteBlockInstruction:
        "The note clearly describes the ABA principles covered during the session and/ or recommendations provided by the BCBA",
      noteBlockExample: `Example: BCBA Provided training to CLIENTS Mother on antecedents, behaviors, and consequences. BCBA Provide examples of CLIENTS behavior and had his Mother practice collecting ABC data to best determine target behaviors function not observed during ABA sessions. BCBA Modeled collecting this data and provdied feedback on CLIENTS Mother's data.`,
      noteBlockPlaceholder: "Enter a summary of the meeting",
      responseBlockTitle: "Summary of response and progress",
      responseBlockInstruction:
        "The note clearly documents progress toward caregiver training goals (including specific data collected during the session when appropriate).",
      responseBlockExample:
        " EXAMPLE:  Moving forward, the behavior intervention plan will be modified and shared with the school. In addition, a new goal surrounding waiting for preferred items will be added, as this is also being worked on in the school. Consistent implementation in both locations will occur, for added trials and generalization.",
      responseBlockPlaceholder: "Enter a summary of response and progress",
    };
  } else if (serviceType.includes("Reassessment Treatment")) {
    return {
      focus: "Indirect assessment to include treatment plan writing",
      noteBlockTitle: "Treatment Plan Sections Completed",
      noteBlockInstruction:
        "In this section notate what was observed, as well as any probe data conducted.",
      noteBlockExample: ` Example: BCBA completed the following areas of the treatment plan: strengths and weaknesses, diagnosis, therapy schedule and summary of outlined schedule. In addition, scoring of the ABLLS-R occured across all domain areas. `,
      noteBlockPlaceholder: "Enter a summary of treatment plan areas completed",
      responseBlockTitle: "Assessment items completed",
      responseBlockInstruction:
        "The note clearly details activities completed during the session (e.g., analyzing assessment results, developing goals)",
      responseBlockExample:
        "EXAMPLE: (Feel free to copy/paste from the treatment plan) The following goals were added for CLIENT today: CLIENT will ask at least two different social questions (e.g., name, age, family names, pet names) to maintain conversations in 80% of opportunities across 3 consecutive sessions. CLIENT will identify 15 different situations as dangerous or safe in 80% of opportunities across 3 consecutive sessions, CLIENT will identify the appropriate level of touch, interaction, and how to respond in different scenarios for 6 different levels of relationships (self, family, friend, acquaintance, stranger, and community helpers) with 80% accuracy across 3 consecutive sessions. CLIENT will  identify and practice skills related to identifying what makes a friend, as well as initiating friendships with others with 80% success across 3 consecutive sessions.",
      responseBlockPlaceholder: "Enter a summary of assessment items completed",
    };
  } else if (serviceType.includes("Magellan")) {
    return {
      focus: "Indirect assessment to include treatment plan writing",
      noteBlockTitle: "Treatment Plan Areas Completed",
      noteBlockInstruction:
        "In this section notate what areas of the treatment plan were completed today.",
      noteBlockExample: ` Example: BCBA completed the following areas of the treatment plan: strengths and weaknesses, diagnosis, therapy schedule and summary of outlined schedule. In addition, scoring of the ABLLS-R occured across all domain areas. `,
      noteBlockPlaceholder: "Enter a summary of treatment plan areas completed",
      responseBlockTitle: "Assessment items completed",
      responseBlockInstruction:
        "In this section please note here goals developed or narrative sections from the treatment plan.",
      responseBlockExample:
        "EXAMPLE: (Feel free to copy/paste from the treatment plan) The following goals were added for CLIENT today: CLIENT will ask at least two different social questions (e.g., name, age, family names, pet names) to maintain conversations in 80% of opportunities across 3 consecutive sessions. CLIENT will identify 15 different situations as dangerous or safe in 80% of opportunities across 3 consecutive sessions, CLIENT will identify the appropriate level of touch, interaction, and how to respond in different scenarios for 6 different levels of relationships (self, family, friend, acquaintance, stranger, and community helpers) with 80% accuracy across 3 consecutive sessions. CLIENT will  identify and practice skills related to identifying what makes a friend, as well as initiating friendships with others with 80% success across 3 consecutive sessions.",
      responseBlockPlaceholder: "Enter a summary of assessment items completed",
    };
  } else if (serviceType.includes("Reassessment")) {
    return {
      focus: "Face-to-Face Parent Interview and/or Skill Assessment",
      noteBlockTitle: "Client Observation",
      noteBlockInstruction:
        "In this section notate what was observed, as well as any probe data conducted.",
      noteBlockExample: `Example: \n
            -During the assessment session, it was noted that CLIENT was able to initiate manding by pulling his mother towards preferred items. No verbal mands were observed. \n
            -CLIENT was able to tact mom, dog, and cat. No independent or interactive play was observed. \n
            -CLIENT engaged with play cars but threw them across the room or lined them up. \n
            -CLIENT eloped from the room 4 times and responded to name once. \n
            -CLIENT acknowledged BCBA by saying ""hi"" with a prompt from his mother. \n
            –Client imitated arms up and throwing in the context of playing in a ball pit.`,
      noteBlockPlaceholder: "Enter a summary of your observations during session",
      responseBlockTitle: "Assessment items completed",
      responseBlockInstruction:
        "The note clearly details the evaluations and assessments conducted during the session. If the appointment occurred for the purpose of reviewing the treatment plan with the family, does the note clearly outline the information that was shared with the family, the family's feedback, and changes that will be made to the treatment plan based on that feedback.",
      responseBlockExample:
        "Example: BCBA completed the VB MAPP Barriers assessment through direct assessment and parent interview. BCBA will compare baseline to 6 month reassessment results. BCBA Completed level 2 of the VB MAPP Milestones by direct assessment with Client. BCBA will begin to summarize results and develop assessment treatment plan based off skill deficits and barriers.",
      responseBlockPlaceholder: "Enter a summary of assessment items completed",
    };
  } else if (serviceType.includes("Initial Treatment")) {
    return {
      focus: "Indirect assessment to include treatment plan writing",
      noteBlockTitle: "Treatment Plan Sections Completed",
      noteBlockInstruction:
        "The note clearly details activities completed during the session (adding information to the treatment plan)",
      noteBlockExample: ` Example: BCBA completed the following areas of the treatment plan: strengths and weaknesses, diagnosis, therapy schedule and summary of outlined schedule. In addition, scoring of the ABLLS-R occured across all domain areas. `,
      noteBlockPlaceholder: "Enter a summary of treatment plan areas completed",
      responseBlockTitle: "Assessment items completed",
      responseBlockInstruction:
        "The note clearly details activities completed during the session (e.g., analyzing assessment results, developing goals)",
      responseBlockExample:
        "EXAMPLE: (Feel free to copy/paste from the treatment plan) The following goals were added for CLIENT today: CLIENT will ask at least two different social questions (e.g., name, age, family names, pet names) to maintain conversations in 80% of opportunities across 3 consecutive sessions. CLIENT will identify 15 different situations as dangerous or safe in 80% of opportunities across 3 consecutive sessions, CLIENT will identify the appropriate level of touch, interaction, and how to respond in different scenarios for 6 different levels of relationships (self, family, friend, acquaintance, stranger, and community helpers) with 80% accuracy across 3 consecutive sessions. CLIENT will  identify and practice skills related to identifying what makes a friend, as well as initiating friendships with others with 80% success across 3 consecutive sessions.",
      responseBlockPlaceholder: "Enter a summary of Assessment items completed",
    };
  } else if (serviceType.includes("Nonbillable Care")) {
    return {
      focus: "Coordination of care with client's related providers",
      noteBlockTitle: "Purpose of Meeting",
      noteBlockInstruction:
        "In this section notate why the meeting took place and who was coordinated with",
      noteBlockExample: `Example: Met with CLIENTS school team in order to discuss IEP and current behavioral issues noted at school.`,
      noteBlockPlaceholder: "Enter a summary of the meeting",
      responseBlockTitle: "Impact on clients care",
      responseBlockInstruction:
        "In this section please summarize how your meeting will impact your programming and/or the supports for the client",
      responseBlockExample:
        "EXAMPLE: generalization of social skills to the school setting will occur, along with continued coordination for consistent strategies used across teams for behavior reduction goals.",
      responseBlockPlaceholder: "Enter a summary of impact on client care",
    };
  } else if (serviceType.includes("Care Coordination/ Adaptive Behavior ")) {
    return {
      focus: "Care Coordination/Protocol modification including data analysis and review",
      noteBlockTitle: "Adaptive Behavior Protocol Modification or Care Coordination",
      noteBlockInstruction:
        "In this section please note why changes were needed to protocols, goals, and interventions or note here finding of the data analysis completed. In addition, please note  the purpose of the meeting and what topics were discussed if care coordination took place",
      noteBlockExample: `Example: After data analysis, BCBA noted that Client reached mastery of goal 3.BCBA also developed a maintenance plan to make sure Client maintains the previous goal, accepting a transition from preferred to preferred task. BCBA met with CLIENTS school team in order to address barriers related to problem behavior at school. Strategies implemented both in sessions and in school were discussed. BCBA and CLIENT school team came to conclusions of what is/is not working and came to a decision about how to move forward.`,
      noteBlockPlaceholder: "Enter a protocol modification or care coordination",
      responseBlockTitle: "Treatment plan updates & Impact on client's care",
      responseBlockInstruction:
        "In this section please summarize what modifications were made to treatment protocols, goals, interventions AND/OR please report how the care meeting impacted next steps for the client and the purpose and outcome of this meeting.",
      responseBlockExample:
        " Example:  BCBA developed a new goal 3 of “Client will transition from a preferred task to non preferred task with no more than 1 prompt and within 3 seconds of the initial request for 4 out of 5 trials per session for 8 consecutive sessions.” Goal was added to the treatment plan and will begin data collection and teaching trials next session scheduled for next week.  After discussion with CLIENTS NAME school team, the behavior intervention plan will be modified and shared with the school. In addition, a new goal surrounding waiting for preferred items will be added, as this is also being worked on in the school. Consistent implementation in both locations will occur, for added trials and generalization.",
      responseBlockPlaceholder: "Enter a summary of impact on client care",
    };
  } else if (serviceType.includes("Protocol Modification")) {
    return {
      focus: "Face-to-Face services to modify/add protocols based on client programming needs",
      noteBlockTitle: "Summary of Protocol modification by BCBA",
      noteBlockInstruction:
        "In this section notate changing treatment in session, changing targets, using an intervention protocol, adapting any goals/moving to next, changing prompting methods, or thinking about treatment intervention changes. In addition, probing new skills or testing new items, discussing and adding a treatment goal in with client/parent feedback also fall here.",
      noteBlockExample: `Example: CLIENT NAME was an active participant in today's session with BCBA. A shift in prompt strategies were probed for conversation, social problem solving, and inappropriate social comments. Inappropriate social comments was further evaluated and determined that it is maintained by attention from others-thus ignoring and teaching difference between public and private statements was implemented today. In addition, conversation was not progressing so prompting was changed. Social problem solving has continued to make progress and thus new targets were added with increased social nuances. Caregivers agreed.`,
      noteBlockPlaceholder: "Enter a summary of protocol modification",
      responseBlockTitle: "Treatment plan updates, Impact on client's care & data from probes",
      responseBlockInstruction:
        "In this section report on how the probes or modifications in prompting will change future programming. Also notate any changes to programming made in session (e.g. new target or goal added). Add completed probe data.",
      responseBlockExample:
        "Example: During today's session CLIENT NAME probed 100% on personal questions but score 20% on safety based scenarios. In addition, program updates are necessary for the prompting methods for the conversation program, BIP for innapropriate social comments, as current prompt fading is not effective-a new visual and prompt fading plan will be updated.",
      responseBlockPlaceholder: "Enter a summary of protocol modifations",
    };
  } else if (serviceType.includes("Transition")) {
    return {
      focus: "Coordination of care with client's related providers",
      noteBlockTitle: "Purpose of meeting",
      noteBlockInstruction: "In this section please notate why the meeting occured.",
      noteBlockExample: `Example: Current outgoing BCBA met with incoming BCBA to discuss CLIENTs case and discussed client needs, goals, and trajectory for the next 6 months.`,
      noteBlockPlaceholder: "Enter a summary of the meeting",
      responseBlockTitle: "Impact on clients care",
      responseBlockInstruction:
        "In this section please notate how this meeting or service impacts next steps for the client.",
      responseBlockExample: "EXAMPLE: new BCBA will begin seeing CLIENT as of 3/4/23",
      responseBlockPlaceholder: "Enter a summary of impact on client care",
    };
  } else if (serviceType.includes("Group Adaptive")) {
    return {
      focus: "Group adaptive behavior treatment by protocol",
      noteBlockTitle: "Summary of Client Response",
      noteBlockInstruction:
        "In this section notate client's interaction with BCBA and other clients during session, any barriers to progress, client's response to programming, and prompt methods used throughout treatment session.",
      noteBlockExample: `Example: CLIENT NAME was an active participant in today's direct treatment session with Writer. CLIENT NAME displayed two elopement behaviors but was easily vocally prompted to return to session. CLIENT NAME completed trials on matching and needed two gestural prompts before fading to independet responding. BCBA probed reinforcers throughout session where CLIENT NAME chose to work for her favorite items throughout programming trials. `,
      noteBlockPlaceholder: "Enter a summary of the meeting",
      responseBlockTitle: "Summary of Client Progress and Data",
      responseBlockInstruction:
        "The note contains data collected during the session on specific targets. The note highlights progress and barriers on individual targets.  The note includes the domains/goals targeted (tied back to the client's treatment plan). The note provides details about the specific teaching, behavior reduction, or assessment procedures implemented.",
      responseBlockExample:
        "Example: During today's session CLIENT NAME completed trials on wh- questions, listener responding skills by function, feature, and class as well as activities of daily living goal for brushing teeth. CLIENT NAME's session data is as follows: Wh-questions 8/10, LRFFC 5/10 or 50%, ADL Brushing Teeth TA: 17 out of 20 steps independently completed.",
      responseBlockPlaceholder: "Enter a summary of client progress",
    };
  } else if (
    serviceType.includes("Supervision Meeting") ||
    serviceType.includes("Meeting With Clinical")
  ) {
    return {
      focus: "Meeting with clinical supervisor for case review",
      noteBlockTitle: "Overview of Meeting with Billing Supervisor",
      noteBlockInstruction:
        "In this section please document that you met with the billing provider and what was discussed with that provider.",
      noteBlockExample: `Example: met with billing provider (Allie Sheehan) to discuss CLIENT NAME progress, barriers, and next steps. Rendering provider will continue to meet on an ongoing basis with the billing provider for note review, progress monitoring, and and updates needed.`,
      noteBlockPlaceholder: "Enter a summary of the meeting",
      responseBlockTitle: "",
      responseBlockInstruction: "",
      responseBlockExample: "",
      responseBlockPlaceholder: "",
    };
  } else if (serviceType.includes("Observation")) {
    return {
      focus: "Client Observation for Training or Case Transfer",
      noteBlockTitle: "Purpose of observation",
      noteBlockInstruction:
        "In this section please document why the observation took place (case transfer or observation for training/learning purposes)",
      noteBlockExample: `Example: Observed session with current BCBA in order to faciliate transition of care. CLIENT NAME was observed engaging in X Y and Z skills. EXAMPLE for training/observation: New BCBA to the organization observed a session facilitated by INSERT CURRENT BCBA NAME in order to faciliate further training, platform use, and telehealth based ABA sessions."`,
      noteBlockPlaceholder: "Enter the purpose of observation",
      responseBlockTitle: "",
      responseBlockInstruction: "",
      responseBlockExample: "",
      responseBlockPlaceholder: "",
    };
  } else if (serviceType.includes("Nonbillable")) {
    return {
      focus: "Non-billable services",
      noteBlockTitle: "Summary of Nonbillable Time",
      noteBlockInstruction: "Please briefly explain why this portion of the call is not billable",
      noteBlockExample: "",
      noteBlockPlaceholder: "Enter summary of nonbillable time",
      responseBlockTitle: "",
      responseBlockInstruction: "",
      responseBlockExample: "",
      responseBlockPlaceholder: "",
    };
  } else if (serviceType.includes("Medical Team Conference")) {
    return {
      focus: "Medical team meeting to discuss treatment plan",
      noteBlockTitle: "Summary of Medical Team Conference Meeting",
      noteBlockInstruction: "In this section please outline meeting details to include team members and roles, discussion of treatment plan, barriers to implementation, and client progress.",
      noteBlockExample: "Example: BCBA met with Client’s Medical Team to include his parents, pediatrician, and speech therapist to provide care coordination between ABA and related services. Team discussed ways to increase Client’s expressive language skills across multiple environments and also discussed his current progress with reducing aggression towards peers. BCBA shared Client’s treatment plan during this meeting and noted progress on goal 2, 3, and 4 to the team. Client's medical team plans to meet again on XX date (or 6 months from now) to review any continued barriers and progress to treatment. ",
      noteBlockPlaceholder: "",
      responseBlockTitle: "Treatment plan updates & Impact on client's care",
      responseBlockInstruction: "Please summarize any changes to treatment plan or next steps.",
      responseBlockExample: "Example: BCBA developed new generalization criteria based on parent and PCP feedback for the following goal [insert goal]. BCBA will continue to proram for generalization to promote client's progressi n this skill area across multiple enviornements, people, and stimuli. BCBA will montior progress on a weekly basis and adjust as needed.",
      responseBlockPlaceholder: "",
    };
  }
  else if (serviceType.includes("Treatment Plan Review")) {
    return {
      focus: "Face-to-Face treatment plan review with client/caregiver",
      noteBlockTitle: "Treatment Plan Sections Reviewed",
      noteBlockInstruction: "In this section, notate components of of treatment plan reviewed with family and any caregiver concerns with proposed goals",
      noteBlockExample: "Example: BCBA conducted treatment plan review with CLIENT's caregiver. BCBA reviewed assessment results, recommended hours for service, skill acquisition goals, behavior reduction goals, and proposed caregiver training goals. Parent expressed concerns with recommended hours. BCBA discussed significance of weekly caregiver training and rationale behind clinical recommendation.",
      noteBlockPlaceholder: "",
      responseBlockTitle: "Caregiver Response and Treatment Plan Updates",
      responseBlockInstruction: "The note clearly details any adjustments made to the treatment plan or notes caregiver agreement. The note should also include next steps for treatment.",
      responseBlockExample: "Example: BCBA will adjust CLIENT's proposed schedule due to family conflict. BCBA will prioritize functional communication goals per parent request. Incoming BCBA will conduct first session with CLIENT on DATE.",
      responseBlockPlaceholder: "",
    };
  } else {
    return {
      focus: "1:1 direct therapy to address skills deficits",
      noteBlockTitle: "Summary of Client Response",
      noteBlockInstruction:
        "In this section notate client's interaction with BCBA during session, any barriers to progress, client's response to programming, and prompt methods used throughout treatment session.",
      noteBlockExample: `Example: CLIENT NAME was an active participant in today's direct treatment session with Writer. CLIENT NAME displayed two elopement behaviors but was easily vocally prompted to return to session. CLIENT NAME completed trials on matching and needed two gestural prompts before fading to independet responding. BCBA probed reinforcers throughout session where CLIENT NAME chose to work for her favorite items throughout programming trials. `,
      noteBlockPlaceholder: "Enter a summary of client response",
      responseBlockTitle: "Summary of Client Progress and Data",
      responseBlockInstruction:
        "The note contains data collected during the session on specific targets. The note highlights progress and barriers on individual targets.  The note includes the domains/goals targeted (tied back to the client's treatment plan). The note provides details about the specific teaching, behavior reduction, or assessment procedures implemented.",
      responseBlockExample:
        "Example: During today's session CLIENT NAME completed trials on wh- questions, listener responding skills by function, feature, and class as well as activities of daily living goal for brushing teeth. CLIENT NAME's session data is as follows: Wh-questions 8/10, LRFFC 5/10 or 50%, ADL Brushing Teeth TA: 17 out of 20 steps independently completed. ",
      responseBlockPlaceholder: "Enter a summary of client progress and data",
    };
  }
};
