import { useQuery, useMutation } from "react-query";
import { getClientAvailability, updateClientAvailability } from "api/customers";

/**
 * Custom hook for fetching client availability.
 *
 * @param {Object} basicInfo The basic information object containing client details.
 * @param {Function} onSuccess Callback function to be called upon successful query.
 * @param {boolean} enabled Condition to enable or disable the query.
 * @returns The React Query's useQuery result object containing data, isLoading, and refetch.
 */
export const useClientAvailability = ({ onSuccess, enabled, ...params }) => {
  const { data, isLoading, refetch } = useQuery(
    "clientAvailability",
    () => getClientAvailability(params),
    {
      enabled,
      onSuccess,
      onError: (error) => {
        console.error("Error retrieving client available hours:", error);
      },
    }
  );
  return { data, isLoading, refetch }; // Adjusted for direct return of data object
};

/**
 * Custom hook for updating client availability.
 *
 * @param {Function} onSuccess Callback function to be called upon successful mutation.
 * @param {Function} onError Callback function to be called upon an error in the mutation.
 * @returns The React Query's useMutation result object containing mutate and isLoading.
 */
export const useUpdateClientAvailability = ({ onSuccess, onError }) => {
  const { mutate, isLoading } = useMutation(
    ({ newAvailableHours, clientInfo }) =>
      updateClientAvailability({ newAvailableHours, clientInfo }),
    { onSuccess, onError }
  );

  return { mutate, isLoading };
};
