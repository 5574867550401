import { useMutation } from "react-query";

import { useGlobalToast } from "components/GlobalToastProvider";
import { rescheduleVideoCallAsync } from "api/video";

const useRescheduleVideoCall = (options = {}) => {
  const { onError = () => {}, ...otherOptions } = options;
  const { showToast } = useGlobalToast();

  const { mutate, isLoading } = useMutation((payload) => rescheduleVideoCallAsync(payload), {
    onError: () => {
      showToast({
        message: "Error updating appointment",
        errorState: true,
      });
      onError();
    },
    ...otherOptions,
  });

  return {
    rescheduleVideoCall: mutate,
    isRescheduleVideoCallLoading: isLoading,
  };
};

export default useRescheduleVideoCall;
