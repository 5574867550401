import { Auth } from "@aws-amplify/auth";

export function* checkCognitoAuth() {
  return yield new Promise((resolve, reject) => {
    Auth.currentSession()
      .then((user) => {
        resolve({
          email: user.idToken.payload.email,
          groups: user.idToken.payload["cognito:groups"],
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function* refreshCognitoToken() {
  const cognitoUser = yield Auth.currentAuthenticatedUser();
  const currentSession = yield Auth.currentSession();
  yield cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
    if (err) {
      return false;
    }
  });
  return true;
}

/**
 * The function `checkCognitoAuthAsync` asynchronously retrieves the user's email and groups from the
 * current Cognito session, or returns false if an error occurs.
 * @returns {Promise<{ email: string, groups: string[] } | boolean>} The user's email and groups, or false if an error occurs.
 */
export async function checkCognitoAuthAsync() {
  try {
    const user = await Auth.currentSession();

    return {
      email: user.idToken.payload.email,
      groups: user.idToken.payload["cognito:groups"],
    };
  } catch (e) {
    return false;
  }
}
