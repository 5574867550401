import MeetingSession from "video/meeting/MeetingSession";

export default class ContentShareController {
  /** @type {MediaStream} */
  stream;
  /**
   * @param {MeetingSession} meetingSession
   */
  constructor(meetingSession) {
    this.meetingSession = meetingSession;
    this.meetingSession.audioVideo.addContentShareObserver(this);
  }

  destroy() {
    this.meetingSession.audioVideo.removeContentShareObserver(this);
    this.stop();

    this.stream = undefined;
    this._contentShareDidStart = [];
    this._contentShareDidStop = [];
    this._contentShareDidPause = [];
    this._contentShareDidUnpause = [];
  }

  async start() {
    this.__started = true;
    this.stream = await navigator.mediaDevices.getDisplayMedia({
      video: true,
      audio: true,
      systemAudio: "include",
    });

    this.meetingSession.audioVideo.enableSimulcastForContentShare(true);

    if (this.stream && typeof this.stream === "string") {
      await this.meetingSession.audioVideo.startContentShareFromScreenCapture(this.stream);
    } else if (this.stream instanceof MediaStream) {
      await this.meetingSession.audioVideo.startContentShare(this.stream);
    } else {
      await this.meetingSession.audioVideo.startContentShareFromScreenCapture();
    }

    return this.stream;
  }

  stop() {
    this.__started = false;
    this.meetingSession.audioVideo.stopContentShare();
  }

  // ContentShareObserver interface //

  /**
   * Called when a content share session is started.
   */
  contentShareDidStart() {
    this._contentShareDidStart.forEach((cb) => cb());
  }
  onContentShareDidStart(cb) {
    this._contentShareDidStart.push(cb);
  }
  _contentShareDidStart = [];

  /**
   * Called when a content share session is stopped.
   */
  contentShareDidStop() {
    this._contentShareDidStop.forEach((cb) => cb());
  }
  onContentShareDidStop(cb) {
    this._contentShareDidStop.push(cb);
  }
  _contentShareDidStop = [];

  /**
   * Called when a content share session is paused.
   */
  contentShareDidPause() {
    this._contentShareDidPause.forEach((cb) => cb());
  }
  onContentShareDidPause(cb) {
    this._contentShareDidPause.push(cb);
  }
  _contentShareDidPause = [];

  /**
   * Called when a content share session is unpaused.
   */
  contentShareDidUnpause() {
    this._contentShareDidUnpause.forEach((cb) => cb());
  }
  onContentShareDidUnpause(cb) {
    this._contentShareDidUnpause.push(cb);
  }
  _contentShareDidUnpause = [];
}
