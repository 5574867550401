export const dateWithoutTimezone = (date) => {
  const day = date ? date.toString().substr(0, 10) : "";
  if (!day) {
    return new Date();
  }

  const dateArr = day.split("-");
  return new Date(
    parseInt(dateArr[0]),
    parseInt(dateArr[1] - 1), // month is 0 index
    parseInt(dateArr[2])
  );
};
