import { call, put, takeEvery } from "redux-saga/effects";
import actions from "../actions";
import { getComputerPerformance } from "../api/tests";

function* getComputerPerformanceAsync() {
  try {
    const result = yield call(getComputerPerformance);
    yield put(actions.getComputerPerformanceSuccess(result.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

export default function* testsSaga() {
  yield takeEvery(actions.GET_COMPUTER_PERFORMANCE, getComputerPerformanceAsync);
}
