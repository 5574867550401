const ACTIONS = {
  GET_CHAT_LIST: "GET_CHAT_LIST",
  LOAD_CHAT_LIST: "LOAD_CHAT_LIST",
  SET_CHAT_LIST: "SET_CHAT_LIST",
  GET_CHAT_DETAILS: "GET_CHAT_DETAILS",
  LOAD_CHAT_DETAILS: "LOAD_CHAT_DETAILS",
  SET_CHAT_DETAILS: "SET_CHAT_DETAILS",

  SET_CHATS_PAGE: "SET_CHATS_PAGE",
  SET_CHATS_PER_PAGE: "SET_CHATS_PER_PAGE",
  GET_CHATS_PAGE: "GET_CHATS_PAGE",
  GET_CHATS_PER_PAGE: "GET_CHATS_PER_PAGE",
  GET_CHATS_COUNT: "GET_CHATS_COUNT",
  SET_CHATS_SORT: "SET_CHATS_SORT",
  SET_CHATS_FILTER: "SET_CHATS_FILTER",

  GET_CHAT_MESSAGES: "GET_CHAT_MESSAGES",
  LOAD_CHAT_MESSAGES: "LOAD_CHAT_MESSAGES",

  GET_ALL_CHAT_TAGS: "GET_ALL_CHAT_TAGS",
  SET_CHAT_TAGS: "SET_CHAT_TAGS",
  GET_CHAT_MESSAGE_TAGS: "GET_CHAT_MESSAGE_TAGS",
  SET_CHAT_MESSAGE_TAGS: "SET_CHAT_MESSAGE_TAGS",
  TAG_CHAT_MESSAGE: "TAG_CHAT_MESSAGE",
  DELETE_CHAT_MESSAGE_TAG: "DELETE_CHAT_MESSAGE_TAG",
};

const ACTION_CREATORS = {
  getChatList() {
    return {
      type: ACTIONS.GET_CHAT_LIST,
    };
  },
  loadChatList() {
    return {
      type: ACTIONS.LOAD_CHAT_LIST,
    };
  },
  setChatList(chats, count) {
    return {
      type: ACTIONS.SET_CHAT_LIST,
      payload: {
        chats,
        count,
      },
    };
  },
  getChatDetails() {
    return {
      type: ACTIONS.GET_CHAT_DETAILS,
    };
  },
  loadChatDetails(id) {
    return {
      type: ACTIONS.LOAD_CHAT_DETAILS,
      payload: {
        id,
      },
    };
  },
  setChatDetails(chat) {
    return {
      type: ACTIONS.SET_CHAT_DETAILS,
      payload: {
        chat,
      },
    };
  },
  setChatsPage(page) {
    return {
      type: ACTIONS.SET_CHATS_PAGE,
      payload: {
        page,
      },
    };
  },
  setChatsPerPage(rowsPerPage) {
    return {
      type: ACTIONS.SET_CHATS_PER_PAGE,
      payload: {
        rowsPerPage,
      },
    };
  },
  getChatsPage() {
    return {
      type: ACTIONS.GET_CHATS_PAGE,
    };
  },
  getChatsPerPage() {
    return {
      type: ACTIONS.GET_CHATS_PER_PAGE,
    };
  },
  getChatsCount() {
    return {
      type: ACTIONS.GET_CHATS_COUNT,
    };
  },
  setChatsSort(payload) {
    return {
      type: ACTIONS.SET_CHATS_SORT,
      payload,
    };
  },
  setChatsFilter(payload) {
    return {
      type: ACTIONS.SET_CHATS_FILTER,
      payload,
    };
  },
  getChatMessages(id) {
    return {
      type: ACTIONS.GET_CHAT_MESSAGES,
      payload: {
        id,
      },
    };
  },
  loadChatMessages(messages) {
    return {
      type: ACTIONS.LOAD_CHAT_MESSAGES,
      payload: {
        messages,
      },
    };
  },
  getAllChatTags() {
    return {
      type: ACTIONS.GET_ALL_CHAT_TAGS,
    };
  },

  setChatTags(tags) {
    return {
      type: ACTIONS.SET_CHAT_TAGS,
      payload: tags,
    };
  },
  getChatMessageTags(messageId) {
    return {
      type: ACTIONS.GET_CHAT_MESSAGE_TAGS,
      payload: messageId,
    };
  },
  setChatMessageTags(tags) {
    return {
      type: ACTIONS.SET_CHAT_MESSAGE_TAGS,
      payload: tags,
    };
  },
  tagChatMessage(messageId, tagId) {
    return {
      type: ACTIONS.TAG_CHAT_MESSAGE,
      payload: {
        messageId,
        tagId,
      },
    };
  },
  deleteMessageTag(messageTagId, messageId) {
    return {
      type: ACTIONS.DELETE_CHAT_MESSAGE_TAG,
      payload: {
        messageTagId,
        messageId,
      },
    };
  },
};

export default { ...ACTIONS, ...ACTION_CREATORS };
