import React from "react";
import Button from "@mui/material/Button";
import {
  Typography,
  MenuItem,
  Checkbox,
  ListItemText,
  Chip,
  TextField,
  InputLabel,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import CancelIcon from "@mui/icons-material/Cancel";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import "./styles/datepicker.css";
import Select from "@mui/material/Select";

import { ClientsFilter } from "elements/ClientsFilter";

const BillingItemFilters = ({
  classes,
  userId,
  clientId,
  clinicianUserId,
  dateCreated,
  statusSelection,
  clinicianList,
  userPermissions,
  onChange,
  onDateChange,
  handleDeleteStatus,
  getBillingItems,
  clearFilters,
  availableInsuranceCodes,
  noteType,
}) => {
  const statusOptions = [
    "Not Started",
    "In Progress",
    "Submitted",
    "Needs Edits",
    ...(userPermissions?.approve_off_platform_billing
      ? ["Needs Review", "On Hold", "Sent To Enter", "Past Due"]
      : []),
  ];

  const showResetFilters =
    !!clientId ||
    !!clinicianUserId ||
    (dateCreated && dateCreated[0]) ||
    !!noteType ||
    statusSelection?.length > 0;

  return (
    <div id="billing-filters-container" className={classes.header}>
      <div className={classes.filtersContainer}>
        <ClientsFilter
          userId={userId}
          clientId={clientId}
          userPermissions={userPermissions}
          onChange={onChange("clientId")}
          classes={classes}
          activeOnly={false}
        />
        {userPermissions?.view_all_clients_billing_items && (
          <FormControl margin="none" className={classes.filter}>
            <TextField
              label="BCBA"
              id="clinicianUserId"
              name="clinicianUserId"
              value={clinicianUserId}
              onChange={onChange("clinicianUserId")}
              select
              sx={{ width: 204 }}
            >
              <MenuItem value="">Select clinician</MenuItem>
              {clinicianList?.map((clinician) => {
                return (
                  <MenuItem key={`clinician${clinician.user_id}`} value={clinician.user_id}>
                    {clinician.name}
                  </MenuItem>
                );
              })}
            </TextField>
          </FormControl>
        )}
        <FormControl margin="none" className={classes.filter}>
          <TextField
            label="Service Type"
            id="noteType"
            name="noteType"
            value={noteType}
            onChange={onChange("noteType")}
            select
            style={{ width: 204 }}
          >
            <MenuItem value="">Select</MenuItem>
            {availableInsuranceCodes?.map((code) => {
              return (
                <MenuItem key={`insurance_code${code.id}`} value={code.id}>
                  {code.description}
                </MenuItem>
              );
            })}
          </TextField>
        </FormControl>
        <FormControl margin="none" className={classes.filter}>
          <InputLabel>Session Dates</InputLabel>
          <DatePicker
            selectsRange={true}
            startDate={dateCreated[0]}
            endDate={dateCreated[1]}
            onChange={onDateChange}
            placeholderText={format(new Date(), "MM/dd/yy")}
            onCalendarClose={getBillingItems}
            customInput={<TextField onChange={onDateChange} />}
          />
        </FormControl>
        <FormControl className={classes.filter}>
          <InputLabel>Status</InputLabel>
          <Select
            multiple
            displayEmpty
            value={statusSelection}
            onChange={onChange("statusSelection")}
            onClose={getBillingItems}
            sx={{ minWidth: 204 }}
            MenuProps={{
              style: { zIndex: 3001 },
              getcontentanchorel: null,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
            }}
            renderValue={(selected) =>
              selected.length === 0 ? (
                <div className={classes.statusPlaceholder}>
                  <Typography component="p">Select</Typography>
                </div>
              ) : (
                <div>
                  {selected.map((value, i) => (
                    <Chip
                      key={value}
                      label={value}
                      clickable
                      className={classes.chip}
                      classes={{
                        label: classes.chipLabel,
                      }}
                      style={{ zIndex: 1299 }}
                      onDelete={(e) => handleDeleteStatus(e, i)}
                      deleteIcon={
                        <div className={classes.chipDeleteIcon}>
                          <CancelIcon
                            style={{
                              fill: "#E1E3E9",
                            }}
                            onMouseDown={(event) => event.stopPropagation()}
                          />
                        </div>
                      }
                    />
                  ))}
                </div>
              )
            }
          >
            <MenuItem value={""} disabled>
              <ListItemText primary={"Select Status"} />
            </MenuItem>
            {statusOptions.map((name) => (
              <MenuItem
                key={name}
                value={name}
                disableGutters
                style={{
                  padding: "0 5px",
                }}
              >
                <Checkbox checked={statusSelection.includes(name)} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
            {statusSelection?.length > 0 && (
              <div
                key="status-select-apply"
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button variant="text" onClick={getBillingItems} style={{ minWidth: 0 }}>
                  Apply
                </Button>
              </div>
            )}
          </Select>
        </FormControl>
        {showResetFilters && (
          <Button style={{ minWidth: 70, marginTop: 20 }} variant="text" onClick={clearFilters}>
            Reset Filters
          </Button>
        )}
      </div>
    </div>
  );
};

export default BillingItemFilters;
