import React from "react";
import { Stack, Typography } from "@mui/material";

const StyledDetailSection = (props) => {
  const { title, subHeader } = props;

  return (
    <Stack display="flex" flexDirection="column" width="100%" height={"376px"}>
      <Stack>
        <Typography variant="h3">{title}</Typography>
        <Typography variant="body2" color={"text.secondary"}>
          {subHeader}
        </Typography>
        {props.children}
      </Stack>
    </Stack>
  );
};

export default StyledDetailSection;
