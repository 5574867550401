import React from "react";
import { Chip, Avatar } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { blue, blueGrey, cyan, green, indigo, orange, pink, teal } from "@mui/material/colors";

const colorPairs = [
  { color: "#8E42CA", backgroundColor: "#F4ECFB" },
  { color: orange[900], backgroundColor: orange[100] },
  { color: blue[900], backgroundColor: blue[100] },
  { color: green[900], backgroundColor: green[100] },
  { color: blueGrey[900], backgroundColor: blueGrey[100] },
  { color: indigo[900], backgroundColor: indigo[100] },
  { color: cyan[900], backgroundColor: cyan[100] },
  { color: pink[900], backgroundColor: pink[100] },
  { color: teal[900], backgroundColor: teal[100] },
];

const getStyle = ({ color, backgroundColor }) => ({
  borderColor: backgroundColor,
  color: color,
  backgroundColor: backgroundColor,
  margin: "4px",
});
export const StatusChip = ({ id, label, colorIndex, onDelete }) => {
  const { color, backgroundColor } = colorPairs[colorIndex ?? 0];

  return (
    <Chip
      avatar={
        <Avatar style={{ backgroundColor: color, color: "#fff" }}>
          {label
            .split(" ")
            .slice(0, 2) // Take only the first two elements after splitting
            .map((n) => (n ? `${n[0]}`.toUpperCase() : ""))
            .join("")}
        </Avatar>
      }
      label={label}
      onDelete={() => onDelete(id)}
      deleteIcon={<CancelIcon style={{ color }} />}
      variant="outlined"
      style={getStyle({ color, backgroundColor })}
    />
  );
};
