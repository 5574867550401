import React from "react";
import { Select } from "@mui/material";

const addStyles = () => ({
  sx: {
    width: "100%",
    padding: "2px 8px 0 24px",
    height: 26,
    borderRadius: 13,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "auto",
    backgroundColor: "#3F4456",
    opacity: "0.8",
    fontSize: 12,
    lineHeight: "18px",
    color: "#fff",
    "&:focus": {
      backgroundColor: "#3F4456",
      borderRadius: 13,
      opacity: "0.8",
    },
    "& .MuiFormHelperText-root": {
      margin: 0,
      color: "#B91C1C",
      fontSize: 14,
      fontWeight: 500,
      padding: "0 5px",
    },
  },
});

export const CustomSelect = React.forwardRef((props, ref) => {
  return <Select {...props} variant="standard" size="small" {...addStyles()} />;
});
