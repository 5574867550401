import { getClientDocuments } from "api/customers";
import { useQuery } from "react-query";

export const useGetClientDocuments = (clientId, params) => {
  return useQuery(
    ["clientDocuments", clientId, params],
    () => getClientDocuments(clientId, params),
    {
      enabled: !!clientId,
      keepPreviousData: true, // this is used to avoid flickering
    }
  );
};
