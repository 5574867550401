import { format } from "date-fns";
import { dateWithoutTimezone } from "./dateWithoutTimezone";

export const formatJsonDate = (date) => {
  if (!date) return null;

  const noTimezoneDate = dateWithoutTimezone(date.toJSON?.() ?? date);
  const dateWithFormat = format(noTimezoneDate, "P");

  return dateWithFormat;
};
