import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withStyles from "@mui/styles/withStyles";
import styles from "../../theme/globalStyles";
import actions from "../../actions";
import Chats from "./Chats";
import {
  getChatsList,
  getSignedinState,
  getChatsPerPage,
  getChatsPage,
  getChatsCount,
  getChatsSort,
  getChatsSortDirection,
  getChatsFilter,
  getChatsLoading,
} from "selectors";
import PageContainer from "elements/PageContainer";
import SectionContainer from "elements/SectionContainer";
import Heading from "elements/Heading";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const mapStateToProps = (state) => ({
  chats: getChatsList(state),
  page: getChatsPage(state),
  rowsPerPage: getChatsPerPage(state),
  count: getChatsCount(state),
  signedInState: getSignedinState(state),
  sort: getChatsSort(state),
  direction: getChatsSortDirection(state),
  filter: getChatsFilter(state),
  loading: getChatsLoading(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadChatList: actions.loadChatList,
      setChatList: actions.setChatList,
      checkAuthState: actions.checkAuthState,
      setChatsPage: actions.setChatsPage,
      setChatsPerPage: actions.setChatsPerPage,
      setPageDetails: actions.setPageDetails,
      setChatsSort: actions.setChatsSort,
      setChatsFilter: actions.setChatsFilter,
    },
    dispatch,
  );

class ChatsContainer extends Component {
  state = {
    page: 0,
    rowsPerPage: 5,
  };

  componentDidMount() {
    this.props.setPageDetails({
      pageName: "Chats",
      currentPage: "chats",
      menu: "entities",
    });
    this.props.loadChatList();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.page !== this.props.page ||
      prevProps.sort !== this.props.sort ||
      prevProps.filter !== this.props.filter ||
      prevProps.direction !== this.props.direction ||
      prevProps.rowsPerPage !== this.props.rowsPerPage
    ) {
      this.props.loadChatList();
    }
  }

  handleChangePage = (event, page) => {
    this.props.setChatsPage(page);
    this.props.loadChatList();
  };

  handleChangeRowsPerPage = (event) => {
    this.props.setChatsPage(0);
    this.props.setChatsPerPage(parseInt(event.target.value));
  };

  viewChatDetails = (id) => {
    let chat = "/chats/" + id;
    this.props.history.push(chat);
  };

  handleSortClick = (name) => {
    this.props.setChatsSort({
      sort: name,
    });
  };

  toggleFilter = () => {
    this.props.setChatsFilter({
      filter: !this.props.filter,
    });
  };

  render() {
    const { rowsPerPage, page } = this.state;
    const { classes, loading, filter } = this.props;

    return (
      <PageContainer loading={loading}>
        <SectionContainer noPadding columnDirection>
          <div className={classes.header}>
            <div />
            <Heading>Chats</Heading>
            <div />
          </div>
          <div className={classes.header}>
            <FormControlLabel
              control={<Checkbox checked={filter} onChange={this.toggleFilter} />}
              label="Hide conversations where parent never sent a message"
            />
          </div>
          <Chats
            {...this.props}
            {...this.state}
            viewChatDetails={this.viewChatDetails}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            rowsPerPage={this.props.rowsPerPage}
            page={this.props.page}
            count={this.props.count}
            handleSortClick={this.handleSortClick}
            toggleFilter={this.toggleFilter}
          />
        </SectionContainer>
      </PageContainer>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ChatsContainer));
