export const taskMap = {
  client_invited: "Client Invited",
  client_logged_in: "Client Logged In",
  orientation_complete: "Orientation Call Complete",
  diagnosis_uploaded: "Diagnosis Uploaded",
  clinician_assigned: "Clinician Assigned",
  pre_auth_signed: "Pre-Auth Signed",
  pre_auth_submitted: "Pre-Authorization Submitted",
  pre_auth_received: "Pre-Authorization Received",
  assessment_scheduled: "Assessment Scheduled",
  first_assessment_completed: "First Assessment Completed",
  assessment_process_completed: "Assessment Calls Completed",
  re_auth_initiated: "Re-Auth Process Initiated",
  treatment_plan_submitted: "Treatment Plan Submitted to Clinical",
  treatment_plan_approved: "Treatment Plan Approved by Clinical",
  ongoing_auth_signed: "Ongoing Authorization Signed",
  ongoing_auth_submitted: "Ongoing Authorization Submitted",
  treatment_plan_review_scheduled: "Treatment Plan Review with Client Scheduled",
  treatment_plan_review_completed: "Treatment Plan Review with Client Completed",
  first_therapy_call_scheduled: "First Therapy Call Scheduled",
  ongoing_auth_received: "Ongoing Authorization Received",
  first_therapy_call_completed: "First Therapy Call Completed",
};
