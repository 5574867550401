import React from "react";
import PropTypes from "prop-types";
import { MentionsInput, Mention } from "react-mentions";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import withStyles from "@mui/styles/withStyles";
import styles from "./styles";
import inputStyle from "./styles/inputStyle";
import getChannelName from "./utils/getChannelName";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import Avatar from "@mui/material/Avatar";

const ChatInput = (props, state) => {
  const {
    classes,
    theme,
    connection,
    currentChannel,
    onChange,
    onUploadClick,
    sendMessage,
    message,
    miniDisplay,
  } = props;

  const currentUser = connection && connection.currentUser ? connection.currentUser : null;
  const typeMessage = (e, value, text, mentions) => {
    onChange(
      value,
      text,
      mentions.map((mention) => mention.id),
    );
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      if (!e.shiftKey) {
        e.preventDefault();
        if (!message) {
          return;
        }
        sendMessage();
      }
    }
  };

  const displayTransform = (id, display) => {
    return `@${display}`;
  };

  let placeholder = "Type your message";
  let members = [];
  // if (currentChannel) {
  //     if (currentChannel.isPublic) {
  //         placeholder = "Type your message";
  //     } else {
  //         placeholder = `Message ${getChannelName(
  //             currentChannel,
  //             currentUser.userId
  //         )}`;
  //     }
  // }

  if (currentChannel) {
    members = currentChannel.members.map((member) => {
      return {
        id: member.userId,
        display: member.nickname,
        connectionStatus: member.connectionStatus,
        profileImage: member.profileUrl,
      };
    });
  }

  const renderSuggestion = (user) => {
    return (
      <React.Fragment>
        {user.profileImage ? (
          <ListItemAvatar style={{ minWidth: 0 }}>
            <Avatar alt={user.display} src={user.profileImage} />
          </ListItemAvatar>
        ) : (
          <ListItemAvatar style={{ minWidth: 0 }}>
            <Avatar>
              <PersonRoundedIcon color="action" style={{ fontSize: 50, marginTop: 8 }} />
            </Avatar>
          </ListItemAvatar>
        )}
        <p className={classes.suggestionName}>{user.display}</p>
        <div
          className={
            user.connectionStatus === "offline" ? classes.indicatorOffline : classes.indicatorOnline
          }
        />
      </React.Fragment>
    );
  };

  return (
    <Paper className={classes.messageInput} elevation={0}>
      <IconButton
        className={classes.sendButton}
        variant="contained"
        color="primary"
        aria-label="Attach File"
        disabled={!currentChannel || !!message}
        onClick={onUploadClick}
        size="large"
      >
        <AttachFileIcon />
      </IconButton>
      <div className={classes.inputField}>
        <MentionsInput
          placeholder={placeholder}
          style={inputStyle}
          singleLine={false}
          disabled={!currentChannel}
          onChange={typeMessage}
          value={message}
          onKeyDown={handleKeyDown}
          allowSpaceInQuery
          allowSuggestionsAboveCursor
        >
          <Mention
            trigger="@"
            data={miniDisplay ? [] : members}
            appendSpaceOnAdd
            renderSuggestion={renderSuggestion}
            displayTransform={displayTransform}
          />
        </MentionsInput>
      </div>
      <IconButton
        className={classes.sendButton}
        variant="contained"
        color="primary"
        aria-label="Send"
        disabled={!currentChannel || !message}
        onClick={sendMessage}
        size="large"
      >
        <SendIcon />
      </IconButton>
    </Paper>
  );
};

export default withStyles(styles, { withTheme: true })(ChatInput);
