/* eslint-disable no-undef */
import React, { useState } from "react";
import { useFormik } from "formik";
import { withRouter } from "react-router";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import { FormControl, FormControlLabel, FormLabel, Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import LoadingButton from "@mui/lab/LoadingButton";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { Eye, EyeClosed } from "@phosphor-icons/react";
import { Alert, Link } from "@mui/material";

import ANLogoSrc from "assets/an_purple_gray.png";
import styles from "../Signin/styles";
import { PasswordValidationMarkers } from "./PasswordValidationMarkers";
import validationSchema from "./validationSchema";

const year = new Date().getFullYear();

const ResetPassword = (props) => {
  const {
    classes,
    username,
    currentPassword,
    verificationCode,
    resetPasswordError,
    resetPasswordErrorMessage,
    onChange,
    onSubmit,
    change,
    invitationSignin,
    onSubmitInvitationLogin,
    invitationError,
    loginInvitation,
    showVersion = true,
  } = props;

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    isValid,
    dirty,
    touched,
    resetForm,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: "",
      confirmPassword: "",
      // Initialize currentPassword only if it's a change password
      showCurrentPassword: (change && !invitationSignin) || false,
      currentPassword: currentPassword || "",
      // Initialize agreeToTermsAndPrivacy only if it's an invitation signin
      showAgreeToTerms: invitationSignin || false,
      agreeToTermsAndPrivacy: false,
    },
    validationSchema,
    onSubmit: (values) => {
      if (invitationSignin) {
        onSubmitInvitationLogin(values).then(() => {
          resetForm();
        });
      } else {
        onSubmit(values);
      }
    },
  });

  const buttonDisabled =
    invitationSignin && loginInvitation
      ? !loginInvitation.email || invitationError
      : change
        ? !username || !verificationCode || !currentPassword
        : !username || !verificationCode;

  const handleShowPassword = () => {
    setShowPassword((prevStatus) => !prevStatus);
  };

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword((prevStatus) => !prevStatus);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleCurrentPasswordChange = (e) => {
    handleChange(e);
    onChange("currentPassword")(e);
  };

  return (
    <Grid container justifyContent="center" alignContent="space-between">
      <Grid item className={classes.resetPasswordFormContainer} marginTop="60px">
        <img src={ANLogoSrc} alt="logo purple and gray" width={198} height={48} />

        <div className={classes.signinForm}>
          <form className={classes.form} onSubmit={handleSubmit}>
            <Typography component="h1" variant="h2" align="center">
              {change
                ? invitationSignin
                  ? "Create a password for your account."
                  : "Change Password"
                : "Reset Password"}
            </Typography>

            {change && !invitationSignin && (
              <FormControl fullWidth sx={{ marginTop: 7 }}>
                <FormLabel
                  component="legend"
                  style={{
                    marginBottom: 10,
                    fontSize: 16,
                    fontWeight: 500,
                    color: "#2A2D39",
                  }}
                >
                  Current Password
                </FormLabel>
                <TextField
                  name="currentPassword"
                  type="password"
                  id="currentPassword"
                  autoComplete="off"
                  value={values.currentPassword}
                  error={touched.currentPassword && Boolean(errors.currentPassword)}
                  onChange={handleCurrentPasswordChange}
                  onBlur={handleBlur}
                  placeholder="Current password"
                />
              </FormControl>
            )}

            <FormControl fullWidth sx={{ marginTop: 7 }}>
              <FormLabel
                component="legend"
                style={{
                  fontSize: 12,
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "100%",
                  letterSpacing: "0.3px",
                  marginBottom: 8,
                }}
              >
                New password
              </FormLabel>

              <TextField
                name="password"
                type={showPassword ? "text" : "password"}
                id="password"
                placeholder="New password"
                autoComplete="off"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.password && Boolean(errors.password)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        size="small"
                      >
                        {showPassword ? <Eye /> : <EyeClosed />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>

            <FormControl fullWidth sx={{ marginTop: 7 }}>
              <FormLabel
                component="legend"
                style={{
                  fontSize: 12,
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "100%",
                  letterSpacing: "0.3px",
                  marginBottom: 8,
                }}
              >
                Re-enter your new password
              </FormLabel>
              <TextField
                name="confirmPassword"
                type={showConfirmPassword ? "text" : "password"}
                id="confirmPassword"
                placeholder="Confirm password"
                autoComplete="off"
                value={values.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle confirm password visibility"
                        onClick={handleShowConfirmPassword}
                        onMouseDown={handleMouseDownPassword}
                        size="small"
                      >
                        {showConfirmPassword ? <Eye /> : <EyeClosed />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>

            <PasswordValidationMarkers
              classes={classes}
              password={values.password}
              confirmPassword={values.confirmPassword}
            />

            {invitationSignin && (
              <FormControl sx={{ marginTop: 7 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="agreeToTermsAndPrivacy"
                      checked={values.agreeToTermsAndPrivacy}
                      onClick={handleChange}
                      onBlur={handleBlur}
                    />
                  }
                  label={
                    <Typography component="p" className={classes.agreeToTermsPrivacyText}>
                      By clicking &quot;Create Password&quot;, you agree to the{" "}
                      <Link
                        target="_blank"
                        href="https://www.getanswersnow.com/terms"
                        rel="noreferrer"
                        underline="none"
                        color={"info.main"}
                      >
                        Terms of Use
                      </Link>{" "}
                      and
                      <Link
                        href="https://www.getanswersnow.com/privacy"
                        target="_blank"
                        rel="noreferrer"
                        underline="none"
                        color={"info.main"}
                      >
                        {" "}
                        Privacy Policy
                      </Link>
                    </Typography>
                  }
                />
              </FormControl>
            )}

            <div className={classes.buttonContainer}>
              <LoadingButton
                type="submit"
                loading={isSubmitting}
                disabled={!isValid || !dirty || buttonDisabled}
                sx={{ height: 42, width: "100%", marginTop: 7 }}
                color="primary"
              >
                {change
                  ? invitationSignin
                    ? "Create Password"
                    : "Change Password"
                  : "Reset Password"}
              </LoadingButton>
            </div>

            {invitationSignin && (
              <Typography variant="caption" component="p" textAlign="center" sx={{ marginTop: 7 }}>
                AnswersNow is a company that conducts ABA services via telehealth. By clicking
                &quot;Continue&quot;, you acknowledge that AnswersNow may record video during
                telehealth sessions.
              </Typography>
            )}

            {resetPasswordError && (
              <div style={{ marginTop: 15, marginBottom: 15 }}>
                {resetPasswordErrorMessage &&
                resetPasswordErrorMessage !== "Incorrect username or password." ? (
                  <Alert severity="error">
                    {resetPasswordErrorMessage.includes("Invalid verification code provided")
                      ? "Your reset password link has expired. Please request a new one."
                      : resetPasswordErrorMessage}
                  </Alert>
                ) : (
                  <Alert severity="error">
                    There was an error changing your password. Please check your spelling of your
                    current password and try again.
                  </Alert>
                )}
              </div>
            )}

            {invitationError && (
              <div style={{ marginTop: 15, marginBottom: 15 }}>
                <Alert severity="error">{invitationError}</Alert>
              </div>
            )}
          </form>
        </div>
      </Grid>

      <Grid
        item
        xs={12}
        component="footer"
        textAlign="center"
        color="text.secondary"
        sx={{ marginTop: 7 }}
      >
        {showVersion && (
          <div>
            <Typography component="p" className={classes.footerText}>
              Version: {APP_VERSION} &copy; {year} AnswersNow &#x2022; All rights reserved
            </Typography>
          </div>
        )}

        {invitationSignin && (
          <>
            <Typography component="p" variant="body2">
              Have questions? Reach us directly at
            </Typography>
            <Typography component="span" variant="body2" color="primary">
              804-215-5600
            </Typography>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default withRouter(withStyles(styles)(ResetPassword));
