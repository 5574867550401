import { Stack, TextField } from "@mui/material";
import { useTheme } from "@mui/styles";
import ANFormView from "elements/Forms/ANFormView";
import SectionBox from "elements/SectionBox";
import { useFormikContext } from "formik";
import React from "react";

export default function CredentialInformation({ editMode, credentials }) {
  const theme = useTheme();
  const { values, handleChange, handleBlur } = useFormikContext();

  return (
    <SectionBox
      title="Credential Information"
      description={"Manage and change Credential records"}
      padding={theme.spacing(5, 0)}
    >
      {editMode ? (
        <Stack gap={theme.spacing(7)} width="inherit">
          <TextField
            name="credentials"
            label="Credential Abbreviations"
            value={values.credentials}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            placeholder="List credentials such as BCBA, LCP, LCSW, ..."
          />
          <Stack direction="row" gap={theme.spacing(5)} flexWrap="wrap">
            <TextField
              name="npi"
              label="NPI Number"
              value={values.npi}
              onChange={handleChange}
              onBlur={handleBlur}
              sx={{ flexGrow: 1 }}
            />
            <TextField
              name="caqhNumber"
              label="CAQH Number"
              value={values.caqhNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              sx={{ flexGrow: 1 }}
            />
            <TextField
              name="medicaidNumber"
              label="Medicaid Number"
              value={values.medicaidNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              sx={{ flexGrow: 1 }}
            />
          </Stack>
        </Stack>
      ) : (
        <ANFormView
          data={{
            credentials: credentials.credentials || "",
            NPINumber: credentials.npi || "",
            CAQHNumber: credentials.caqh_number || "",
            medicaidNumber: credentials.medicaid_number || "",
          }}
          config={[
            { label: "Credential Abbreviations", dataKey: "credentials", cols: 12 },
            { label: "NPI Number", dataKey: "NPINumber", cols: 4 },
            { label: "CAQH Number", dataKey: "CAQHNumber", cols: 4 },
            { label: "Medicaid Number", dataKey: "medicaidNumber", cols: 4 },
          ]}
        />
      )}
    </SectionBox>
  );
}
