import React from "react";
import {
  Typography,
  MenuItem,
  ListItemText,
  Chip,
  FormControl,
  FormHelperText,
  Select,
  Checkbox,
  TextField,
  Avatar,
  Alert,
} from "@mui/material";
import FormLabel from "@mui/material/FormLabel";
import { WarningCircle } from "@phosphor-icons/react";

const Participants = (props) => {
  const {
    classes,
    participants,
    clinicianName,
    parentName,
    clientName,
    otherParticipant,
    participantsError,
    otherParticipantError,
    otherClinicianName,
    setError,
    handleDeleteParticipant,
    onChange,
    locked,
    viewOnly,
    currentIndex,
    associatedSessionNotes,
  } = props;
  const currentService = associatedSessionNotes[currentIndex];
  let participantsOptions = [clinicianName, clientName, parentName, "Other"];
  if (otherClinicianName) {
    participantsOptions.splice(3, 0, otherClinicianName);
  }
  return (
    <div className={classes.participants}>
      {viewOnly ? (
        <Typography componeont="p" variant="body1" style={{ fontSize: 16 }}>
          {currentService?.description ? currentService.description : "Call"} Participants
        </Typography>
      ) : (
        <FormControl error={!!participantsError}>
          <FormLabel component="legend" className={classes.callDetailLabel}>
            {currentService?.description ? currentService.description : "Call"} Participants
          </FormLabel>
          <Select
            labelId="demo-mutiple-chip-checkbox-label"
            id="demo-mutiple-chip-checkbox"
            variant="outlined"
            multiple
            displayEmpty
            value={participants}
            onChange={(event) => {
              const value = event.target.value;
              // Prevent deselecting clinicianName
              if (value.includes(clinicianName) || event.target.value.length === 0) {
                onChange("participants")(event);
              }
            }}
            onFocus={() => setError("participantsError", false)}
            placeholder="Select Status"
            style={{ width: "319px" }}
            disabled={locked}
            MenuProps={{
              style: { zIndex: 3007 },
            }}
            renderValue={() => (
              <Typography component="p" variant="body2" style={{ lineHeight: "26px" }}>
                Select participants
              </Typography>
            )}
          >
            {participantsOptions
              .filter((p) => !!p)
              .map((name) => (
                <MenuItem
                  key={name}
                  value={name}
                  disableGutters
                  classes={{
                    selected: classes.selectedParticipantItem,
                  }}
                  style={
                    name == "Other"
                      ? {
                          borderTop: "1px solid #C3C6D2",
                          marginTop: 10,
                          padding: "0 5px",
                        }
                      : {
                          padding: "0 5px",
                        }
                  }
                >
                  <Checkbox color="primary" checked={participants?.includes(name)} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
          </Select>
          {participantsError && (
            <FormHelperText className={classes.helperText} style={{ margin: 0 }}>
              Select two or more participants
            </FormHelperText>
          )}
        </FormControl>
      )}
      <div className={classes.participantChips}>
        {participants &&
          participants.map((value, i) => {
            const name = value == "Other" && otherParticipant ? otherParticipant : value;
            const initials =
              value == "Other" || !value || value.split(" ").length < 2 ? null : (
                <Avatar
                  sx={{
                    backgroundColor: "#BDBDBD",
                    color: "#FFFFFF !important",
                  }}
                >
                  {`${value.split(" ")[0][0]?.toUpperCase() || ""}${
                    value.split(" ")[1][0]?.toUpperCase() || ""
                  }`}
                </Avatar>
              );
            const allowDelete = !locked && !viewOnly && !value.includes(clinicianName);
            return (
              <Chip
                key={value}
                label={name}
                avatar={initials}
                onDelete={allowDelete ? (e) => handleDeleteParticipant(e, i) : null}
              />
            );
          })}
      </div>

      {participants?.some((p) => p == "Other") && !viewOnly && (
        <FormControl>
          <FormLabel component="legend" className={classes.callDetailLabel}>
            Other Participant
          </FormLabel>

          <TextField
            id="otherParticipant"
            name="otherParticipant"
            autoComplete="off"
            value={otherParticipant}
            style={{ width: "319px" }}
            onChange={onChange("otherParticipant")}
            placeholder="Who else joined the call?"
            disabled={locked}
            error={!!otherParticipantError}
            onFocus={() => setError("otherParticipantError", false)}
            helperText={otherParticipantError ? "Enter the other participant" : null}
          />
        </FormControl>
      )}
    </div>
  );
};

export default Participants;
