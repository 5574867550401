import { useState } from "react";

const useFormSubmission = (onSubmit) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleFormSubmit = async (values) => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      await onSubmit(values);
      setSuccess(true);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    error,
    success,
    handleFormSubmit,
  };
};

export default useFormSubmission;
