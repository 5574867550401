import React from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormLabel from "@mui/material/FormLabel";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { Eye, EyeClosed } from "@phosphor-icons/react";
import { Alert } from "@mui/material";
const WithPassword = (props) => {
  const {
    classes,
    username,
    password,
    otpError,
    passIsVisibile,
    handleUsernameChange,
    handlePasswordChange,
    handleLoginWithCode,
    handleForgotPassword,
    handleTogglePasswordVisibility,
    renderErrorMessage,
    signinFailure,
    needsReset,
    notConfirm,
    onSubmit,
  } = props;

  const isError = signinFailure || otpError || needsReset || notConfirm;

  return (
    <form className={classes.form} onSubmit={onSubmit}>
      <FormControl margin="none" fullWidth>
        <FormLabel
          component="legend"
          style={{
            fontSize: 12,
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "100%",
            letterSpacing: "0.3px",
            marginBottom: 8,
            color: isError ? "red" : "inherit",
          }}
        >
          Email Address
        </FormLabel>
        <TextField
          error={isError}
          id="username"
          name="username"
          autoComplete="off"
          autoFocus
          size="medium"
          value={username}
          onChange={handleUsernameChange}
          placeholder="Email address"
        />
      </FormControl>
      <FormControl margin="none" fullWidth>
        <FormLabel
          component="legend"
          style={{
            fontSize: 12,
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "100%",
            letterSpacing: "0.3px",
            marginBottom: 8,
            marginTop: 24,
            color: isError ? "red" : "inherit",
          }}
        >
          Password
        </FormLabel>
        <TextField
          error={isError}
          name="password"
          type={passIsVisibile ? "text" : "password"}
          id="password"
          autoComplete="off"
          value={password}
          onChange={handlePasswordChange}
          placeholder="Type your password here"
          size="medium"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleTogglePasswordVisibility}
                  size="small"
                >
                  {passIsVisibile ? <Eye /> : <EyeClosed />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button
          type="submit"
          fullWidth
          disabled={!username || !password}
          onClick={onSubmit}
          style={{ height: 42, marginTop: 16 }}
        >
          Login
        </Button>
        <Button
          onClick={handleLoginWithCode}
          color="secondary"
          style={{ height: 42, marginTop: 16 }}
        >
          Use A Code To Log In
        </Button>
        <Button
          variant="text"
          color="primary"
          onClick={handleForgotPassword}
          style={{ height: 42, marginTop: 16 }}
        >
          Forgot password?
        </Button>
        {(signinFailure || otpError) && <div style={{ marginTop: 15 }}>{renderErrorMessage()}</div>}
        {needsReset && (
          <div style={{ marginTop: 15 }}>
            <Alert severity="error">
              Sorry, but your password no longer meets our password requirements. You should receive
              an email with instructions on how to reset your password that meet our new password
              standards.
            </Alert>
          </div>
        )}
        {notConfirm && (
          <div style={{ marginTop: 15 }}>
            <Alert severity="error">
              You have not confirmed your email address. We have resent your verification email for
              your convenience.
            </Alert>
          </div>
        )}
      </FormControl>
    </form>
  );
};

export default WithPassword;
