import { call, put, select, takeEvery } from "redux-saga/effects";
import actions from "../actions";
import { getCustomer } from "../api/customers";
import {
  saveNotes,
  getNotesList,
  getSessionNote,
  getSessionNotes,
  saveSessionNote,
  updateSessionNote,
  getSessionNotesByUser,
  getOldSessionNotesByUser,
  getSessionNotesByClinician,
} from "../api/notes";
import { getVideoCallBreakdown } from "../api/video";

function* getNotesListAsync({ payload }) {
  try {
    const result = yield call(getNotesList, payload);
    let details = yield call(getCustomer, payload);
    yield put(
      actions.getNotesListSuccess({
        details: details.data?.customer,
        notes: result.data,
      }),
    );
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    console.error(e);
  }
}

function* saveNotesAsync({ payload }) {
  try {
    yield call(saveNotes, payload);
    yield put(actions.getNotesList(payload.clientId));
    yield put(actions.saveNotesSuccess());
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    yield put(actions.saveNotesFail());
    console.log(e);
  }
}

function* getSessionNoteAsync({ payload }) {
  try {
    const note = yield call(getSessionNote, payload);
    yield put(actions.getSessionNoteSuccess(note.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* getSessionNotesAsync({ payload }) {
  try {
    const notes = yield call(getSessionNotes, payload);
    yield put(actions.getSessionNotesSuccess(notes.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* getSessionNotesByUserAsync({ payload }) {
  try {
    const notes = yield call(getSessionNotesByUser, payload);
    yield put(actions.getSessionNotesByUserSuccess(notes.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* getOldSessionNotesByUserAsync({ payload }) {
  try {
    const notes = yield call(getOldSessionNotesByUser, payload);
    yield put(actions.getOldSessionNotesByUserSuccess(notes.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* getSessionNotesByClinicianAsync({ payload }) {
  try {
    const notes = yield call(getSessionNotesByClinician, payload);
    yield put(actions.getSessionNotesByClinicianSuccess(notes.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* saveSessionNoteAsync({ payload }) {
  try {
    yield call(saveSessionNote, payload);
    yield put(actions.saveSessionNoteSuccess());
    if (payload.videoCallId) {
      const breakdown = yield call(getVideoCallBreakdown, payload.videoCallId);
      yield put(actions.getVideoCallBreakdownSuccess(breakdown.data));
    }
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    yield put(actions.saveSessionNoteFail());
    console.log(e);
  }
}

function* updateSessionNoteAsync({ payload }) {
  try {
    yield call(updateSessionNote, payload);
    yield put(actions.updateSessionNoteSuccess());
    yield put(actions.getSessionNotes(payload.videoCallId));
  } catch (e) {
    yield put(actions.updateSessionNoteFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

export default function* notesSaga() {
  yield takeEvery(actions.SAVE_NOTES, saveNotesAsync);
  yield takeEvery(actions.GET_NOTES_LIST, getNotesListAsync);
  yield takeEvery(actions.GET_SESSION_NOTES, getSessionNotesAsync);
  yield takeEvery(actions.GET_SESSION_NOTE, getSessionNoteAsync);
  yield takeEvery(actions.GET_SESSION_NOTES_BY_USER, getSessionNotesByUserAsync);
  yield takeEvery(actions.GET_OLD_SESSION_NOTES_BY_USER, getOldSessionNotesByUserAsync);
  yield takeEvery(actions.GET_SESSION_NOTES_BY_CLINICIAN, getSessionNotesByClinicianAsync);
  yield takeEvery(actions.SAVE_SESSION_NOTE, saveSessionNoteAsync);
  yield takeEvery(actions.UPDATE_SESSION_NOTE, updateSessionNoteAsync);
}
