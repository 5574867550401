import React, { useEffect, useRef, useState } from "react";
import "./styles/calendar.css";
import { Views } from "react-big-calendar";
import Toolbar from "react-big-calendar/lib/Toolbar";
import moment from "moment";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { createPortal } from "react-dom";
import { useSelector } from "react-redux";
import { getClinicianCalendarPrivateBlocks, getClinicianNonWorkingHours } from "selectors";
import CalendarToolbar from "./CalendarToolbar";
import { createCalendarCallEvents, createPrivateEventList } from "./CalendarUtils";
import ANCalendar from "elements/ANCalendar";

class CustomToolbar extends Toolbar {
  render() {
    const {
      label,
      toggleScheduleUtilityDrawer,
      calendarType,
      handleCalendarType,
      toggleStartDemoCallDialog,
    } = this.props;
    return (
      <CalendarToolbar
        label={label}
        back={() => this.navigate("PREV")}
        next={() => this.navigate("NEXT")}
        toggleScheduleUtilityDrawer={toggleScheduleUtilityDrawer}
        calendarType={calendarType}
        handleCalendarType={handleCalendarType}
        toggleStartDemoCallDialog={toggleStartDemoCallDialog}
      />
    );
  }
}

const CustomTimeIndicator = () => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const theme = useTheme();
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 30000);

    return () => clearInterval(timer);
  }, []);
  return (
    <div>
      <Typography>
        {
          currentTime
            .toLocaleString("en-US", {
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            })
            .split(" ")[0]
        }
      </Typography>
      <div
        style={{
          borderBottom: `1px dashed ${theme.palette.primary.main}`,
          width: "100%",
        }}
      ></div>
    </div>
  );
};

const CalendarSessions = (props) => {
  const nonWorkingHours = useSelector(getClinicianNonWorkingHours);
  const privateEvents = useSelector(getClinicianCalendarPrivateBlocks);
  const [calendarType, handleCalendarType] = useState(Views.WEEK);
  const [date, setDate] = useState(new Date());
  const prevCalendarTypeRef = useRef();

  useEffect(() => {
    const prevCalendarType = prevCalendarTypeRef.current;
    if (prevCalendarType !== calendarType && prevCalendarType !== undefined) {
      setCurrentWeek(date, calendarType);
    }
    prevCalendarTypeRef.current = calendarType;
  }, [calendarType]);

  const {
    classes,
    calendarCalls,
    calendarCallsLoading,
    setCurrentWeek,
    onSelectSession,
    weekStartDate,
    userScheduleColorMap,
    toggleStartDemoCallDialog,
  } = props;
  useEffect(() => {
    const content = document.querySelector(".rbc-time-content");
    if (content) {
      content.scrollTop = 700;
    }
  }, [weekStartDate]);

  const callList = createCalendarCallEvents(calendarCalls);
  const privateEventsList = createPrivateEventList(privateEvents);
  const allEvents = [...callList, ...privateEventsList];

  const CustomHeader = ({ date }) => {
    return (
      <>
        {calendarType === Views.MONTH ? (
          ""
        ) : (
          <>
            <Typography variant="body2" className="current-day">
              {moment(date).format("ddd")}
            </Typography>
            <Typography variant="subtitle2" className="current-date">
              {moment(date).format("D")}
            </Typography>
          </>
        )}
      </>
    );
  };

  const isOutsideMonth = (dateToCompare, referenceDate) => {
    const dateToCompareMoment = moment(dateToCompare);
    const referenceDateMoment = moment(referenceDate);
    return !dateToCompareMoment.isSame(referenceDateMoment, "month");
  };

  const MonthDayWrapper = ({ children, value }) => {
    let style = {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "50px",
      width: "100%",
      textAlign: "center",
    };

    if (isOutsideMonth(value, date)) {
      style = {
        ...style,
        color: "gray",
      };
    }
    return (
      <div style={style}>
        <Typography variant="body2" className="current-date">
          {moment(value).format("ddd")}
        </Typography>
        <Typography variant="subtitle2" className="current-date">
          {moment(value).format("DD")}
        </Typography>
      </div>
    );
  };

  const components = {
    dateCellWrapper: MonthDayWrapper,
    toolbar: (toolbarProps) => (
      <CustomToolbar
        {...toolbarProps}
        toggleScheduleUtilityDrawer={props.toggleScheduleUtilityDrawer}
        calendarType={calendarType}
        handleCalendarType={handleCalendarType}
        toggleStartDemoCallDialog={toggleStartDemoCallDialog}
      />
    ),
    header: CustomHeader,
  };

  const currentTimeIndicatorEl = document.querySelector(".rbc-current-time-indicator");
  return (
    <>
      <ANCalendar
        defaultDate={weekStartDate ? new Date(weekStartDate) : new Date()}
        events={allEvents}
        view={calendarType}
        date={date}
        views={[Views.WEEK, Views.DAY, Views.MONTH]}
        onNavigate={(date) => {
          setDate(date);
          setCurrentWeek(date, calendarType ?? Views.WEEK);
        }}
        onView={(view) => handleCalendarType(view)}
        components={components}
        onSelectEvent={(e) => onSelectSession(e)}
        className={classes.allCalendarSessions}
        userScheduleColorMap={userScheduleColorMap}
        nonWorkingHours={nonWorkingHours}
        popup
      />
      {currentTimeIndicatorEl && createPortal(<CustomTimeIndicator />, currentTimeIndicatorEl)}
    </>
  );
};

export default CalendarSessions;
