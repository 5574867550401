import { createReducer } from "redux-create-reducer";
import actions from "../actions";

const initialState = {
  authorized: false,
};

const reducers = {
  [actions.SET_AUTH_STATE](state) {
    return {
      ...state,
      authorized: true,
    };
  },
};

export default createReducer(initialState, reducers);
