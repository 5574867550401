import * as yup from "yup";

const memberValidationSchema = yup.object().shape({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  dateOfBirth: yup.date().nullable(),
  gender: yup.string(),
  phoneNumber: yup.string(),
  email: yup.string().email("Invalid email"),
  address: yup.string(),
  address2: yup.string(),
  country: yup.string(),
  state: yup.string(),
  city: yup.string(),
  zipCode: yup.number().typeError("Must be a number"),
  timezone: yup.string(),
});

export { memberValidationSchema };
