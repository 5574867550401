import React from "react";
import withStyles from "@mui/styles/withStyles";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import styles from "../styles";
import Alert from "@mui/material/Alert";
import Modal from "elements/Modal";
import { CustomSelect } from "elements/DEPRECATED_elements";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

const VideoInputs = (props) => {
  const {
    classes,
    settingDialogOpen,
    toggleSettingDialog,
    cameraInputs,
    audioInputs,
    audioOutputs,
    currentAudioInput,
    currentCameraInput,
    currentAudioOutput,
    handleUserCameraChange,
    handleUserMicrophoneChange,
    handleUserSpeakerChange,
    canChangeSpeaker,
    canChangeAudioInput,
    canChangeVideoInput,
    hasJoinedRoom,
    handleUserLobbyCameraChange,
    handleUserLobbyMicrophoneChange,
    handleUserLobbySpeakerChange,
    testUserSpeaker,
    speakerTestOn,
    muted,
  } = props;

  return (
    <Modal
      open={settingDialogOpen}
      onClose={toggleSettingDialog}
      title="Audio and Video Settings"
      content={
        <div>
          <Divider />
          <div className={classes.selectionContainer}>
            <Typography variant="h6" className={classes.settingTitle}>
              Video
            </Typography>
            <FormControl fullWidth>
              <Typography variant="subtitle2" className={classes.settingSubtitle}>
                Video Input
              </Typography>
              {canChangeVideoInput() ? (
                <TextField
                  select
                  label="Camera"
                  value={currentCameraInput}
                  onChange={hasJoinedRoom ? handleUserCameraChange : handleUserLobbyCameraChange}
                  name="currentCameraInput"
                  className={!hasJoinedRoom ? classes.selectionDropdown : null}
                  fullWidth
                >
                  {cameraInputs.length > 0 &&
                    cameraInputs.map((option) => (
                      <MenuItem key={option.deviceId} value={option.deviceId} test={option}>
                        {option.label}
                      </MenuItem>
                    ))}
                </TextField>
              ) : (
                <Alert severity="warning">
                  Your browser doesn't support video input selection.
                </Alert>
              )}
            </FormControl>
          </div>
          <Divider />
          <div className={classes.selectionContainer}>
            <Typography variant="h6" className={classes.settingTitle}>
              Audio
            </Typography>

            <FormControl fullWidth>
              <Typography variant="subtitle2" className={classes.settingSubtitle}>
                Audio Input
              </Typography>
              {canChangeAudioInput() ? (
                <div className={classes.audioContainer}>
                  <TextField
                    select
                    label="Microphone"
                    value={currentAudioInput}
                    onChange={
                      hasJoinedRoom ? handleUserMicrophoneChange : handleUserLobbyMicrophoneChange
                    }
                    name="currentAudioInput"
                    className={!hasJoinedRoom ? classes.selectionDropdown : null}
                    fullWidth
                  >
                    {audioInputs.length > 0 &&
                      audioInputs.map((option) => (
                        <MenuItem key={option.deviceId} value={option.deviceId}>
                          {option.label}
                        </MenuItem>
                      ))}
                  </TextField>
                  {!hasJoinedRoom && (
                    <div className={classes.micContainer}>
                      {muted ? (
                        <div className={classes.micMutedText}>
                          <Typography>You're muted</Typography>
                        </div>
                      ) : (
                        <canvas
                          id="canvas"
                          className={classes.canvas}
                          ref={props.microphoneTestCanvas}
                        ></canvas>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <Alert severity="warning">
                  Your browser doesn't support audio input selection.
                </Alert>
              )}
            </FormControl>
          </div>
          <div className={classes.selectionContainer}>
            <FormControl fullWidth>
              <Typography variant="subtitle2" className={classes.settingSubtitle}>
                Audio Output
              </Typography>
              {canChangeSpeaker() ? (
                <div className={classes.audioContainer}>
                  <TextField
                    select
                    label="Speaker"
                    value={currentAudioOutput}
                    onChange={
                      hasJoinedRoom ? handleUserSpeakerChange : handleUserLobbySpeakerChange
                    }
                    name="currentAudioOutput"
                    className={!hasJoinedRoom ? classes.selectionDropdown : null}
                    fullWidth
                  >
                    {audioOutputs.length > 0 &&
                      audioOutputs.map((option) => (
                        <MenuItem key={option.deviceId} value={option.deviceId}>
                          {option.label}
                        </MenuItem>
                      ))}
                  </TextField>
                  {!hasJoinedRoom && canChangeSpeaker() && (
                    <Button
                      variant="text"
                      onClick={testUserSpeaker}
                      size="small"
                      disabled={speakerTestOn}
                    >
                      Test Audio
                    </Button>
                  )}
                </div>
              ) : (
                <Alert severity="warning">
                  Your browser doesn't support audio output selection.
                </Alert>
              )}
            </FormControl>
          </div>
        </div>
      }
      secondaryActionText="Done"
      secondaryActionOnClick={toggleSettingDialog}
    />
  );
};
export default withStyles(styles)(VideoInputs);
