import React from "react";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { format } from "date-fns";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { mapServiceTypeToTemplateText } from "../../../utils/mapServiceTypeToTemplateText";

import { convertTokens } from "@date-fns/upgrade/v2";

const NotesDrawer = (props) => {
  const {
    classes,
    notesOpen,
    toggleNotes,
    sessionNotesByUser,
    userPermissions,
    availableInsuranceCodes,
  } = props;
  const mapInsuranceCodeToDisplay = (code) => {
    let formattedInsuranceCodes = availableInsuranceCodes.map((code) => {
      let service_type = code.insurance_code.split("-").splice(1, 1).join("").trim();
      return { ...code, service_type };
    });
    let insuranceCode = formattedInsuranceCodes.find((c) => c.id == code);
    let supervision = insuranceCode?.service_type?.includes("Supervision");
    let display = supervision ? "Clinical Supervision" : insuranceCode?.service_type;
    return display ? display : null;
  };
  return (
    <Drawer
      className={classes.chatDrawer}
      variant="temporary"
      anchor="right"
      open={notesOpen}
      onClose={toggleNotes}
      classes={{
        paper: classes.chatDrawerPaper,
      }}
    >
      <div className={classes.tabBarContainer}>
        <IconButton onClick={toggleNotes} className={classes.closeDrawerIcon} size="small">
          <ChevronRightIcon />
        </IconButton>

        <Typography component="p" className={classes.drawerHeaderText}>
          Notes
        </Typography>
        <div />
      </div>
      {sessionNotesByUser.length > 0 && userPermissions?.view_session_notes && (
        <div className={classes.sessionNotesContainer}>
          {sessionNotesByUser &&
            sessionNotesByUser.map((note) => {
              let serviceTypeDisplay = mapInsuranceCodeToDisplay(note.insurance_code_id) || "";
              let templateText = mapServiceTypeToTemplateText(serviceTypeDisplay);
              return note.note ? (
                <div key={note.id} className={classes.noteContainer}>
                  <div className={classes.noteDetailsContainer}>
                    <div className={classes.noteTitleContainer}>
                      <Typography component="p" className={classes.noteBillingType}>
                        {mapInsuranceCodeToDisplay(note.insurance_code_id)}
                      </Typography>
                      <Typography component="p" className={classes.noteDate}>
                        {format(new Date(note.start_date), convertTokens("M/D/YYYY"))}
                      </Typography>
                    </div>
                    <div style={{ width: "100%" }}>
                      <Typography component="p" className={classes.noteEntryTitle}>
                        {templateText?.noteBlockTitle
                          ? templateText.noteBlockTitle
                          : "Intervention"}
                      </Typography>
                      <Typography component="p" className={classes.note}>
                        {note.note}
                      </Typography>
                      {note.response && (
                        <>
                          <Typography component="p" className={classes.noteEntryTitle}>
                            {templateText?.responseBlockTitle
                              ? templateText.responseBlockTitle
                              : "Response"}
                          </Typography>
                          <Typography component="p" className={classes.note}>
                            {note.response}
                          </Typography>
                        </>
                      )}
                    </div>
                  </div>
                  <div className={classes.noteDivider} />
                </div>
              ) : null;
            })}
        </div>
      )}
    </Drawer>
  );
};

export default NotesDrawer;
