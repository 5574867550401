import React, { Component } from "react";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import styles from "../styles";
import CircularProgress from "@mui/material/CircularProgress";
import { addDays, addMinutes, format } from "date-fns";
import { formatToTimeZone } from "date-fns-timezone";
import VideoCamIcon from "@mui/icons-material/Videocam";
import VideoCamOffIcon from "@mui/icons-material/VideocamOff";
import Button from "@mui/material/Button";
import FrontLogo from "assets/Update_Logo.png";
import HangTight from "../Elements/HangTight";
import SpeakerIcon from "@mui/icons-material/SpeakerOutlined";
import MicIcon from "@mui/icons-material/Mic";
import { connect } from "react-redux";
import { convertTokens } from "@date-fns/upgrade/v2";
import { getUser } from "selectors";
import { LobbyDialogs } from "./LobbyDialogs";
import { CustomSelect } from "./CustomSelect";
import cookie from "react-cookies";

const mapStateToProps = (state) => ({
  user: getUser(state),
});

class Lobby extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mediaActive: true,
      record: false,
      audioError: false,
      videoError: false,
      audioSuccess: false,
      videoSuccess: false,
      testComplete: false,
      permissionsDialogOpen: false,
      permissionsDeniedOpen: false,
      clientNotJoinedOpen: false,
      clientNoShowOpen: false,
      clientNoShowDismissed: false,
      timeIntervalId: null,
      clientNoShow: false,
      callScheduledDate: null,
      accessDeniedOpen: false,
      redirectIn: 15,
      primaryNotStartedOpen: false,
      callReadyOpen: false,
      videoCallUrlCopied: false,
      joinCallIn: 20,
    };
    this.video = null;
    this.audio = null;
  }

  componentDidMount() {
    if (!this.props.match.params.videoKey) {
      this.checkPermissions();
    }
    if (!this.props.isCaregiver) {
      let timeIntervalId = setInterval(() => {
        this.checkDeadline();
        this.props.getClientLastLogin();
      }, 6000);
      this.setState({ timeIntervalId });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { videoSuccess, audioSuccess } = this.state;
    if (
      (!prevState.videoSuccess ||
        !prevState.audioSuccess ||
        !prevProps.readyToJoinCall ||
        !prevProps.clientConnected) &&
      videoSuccess &&
      audioSuccess &&
      this.props.readyToJoinCall &&
      !this.props.errorDialogOpen &&
      !this.props.startDate &&
      this.props.isPrimaryClinician &&
      this.props.clientConnected
    ) {
      console.log("ON START VIDEO CALL TRIGGER FROM LOBBY");
      this.props.onStartVideoCall();
    }
    if (prevProps.checkingAccess && !this.props.checkingAccess && !this.props.accessGranted) {
      this.setState({ accessDeniedOpen: true }, () => {
        this.setRedirectTimer();
        this.setTimeout(() => {
          this.props.history.push("/dashboard");
        }, 15000);
      });
    }
    if (
      (prevProps.errorDialogOpen || !prevProps.accessGranted) &&
      !this.props.errorDialogOpen &&
      this.props.accessGranted
    ) {
      this.checkPermissions();
    }
    if (!prevProps.accessGranted && this.props.accessGranted && this.props.isCaregiver) {
      cookie.save(`${this.props.userId}-clientId`, this.props.clientId, {
        path: "/",
        expires: addDays(new Date(), 30),
      });
    }
    if ((!prevState.videoSuccess || !prevState.audioSuccess) && videoSuccess && audioSuccess) {
      this.props.toggleAccessGranted();
      document.addEventListener("mousemove", this.props.mouseMove);
      document.addEventListener("mousedown", this.props.mouseMove);
    }
    if (!prevState.callReadyOpen && this.state.callReadyOpen && this.props.isCaregiver) {
      this.setClientJoinCallTimer();
    }
  }

  __timers = {};
  setTimeout = (cb, delayMs = 0) => {
    const id = setTimeout(() => {
      cb();
      clearTimeout(id);
      delete this.__timers[id];
    }, delayMs);
    this.__timers[id] = 1;
  };

  setRedirectTimer = () => {
    if (!this.accessDeniedRedirectTimer) {
      this.accessDeniedRedirectTimer = setInterval(() => {
        let redirectIn = this.state.redirectIn - 1;
        if (redirectIn === -1) {
          redirectIn = 0;
        }
        this.setState({ redirectIn });
      }, 1000);
    }
  };

  checkPermissions = async (att = 1) => {
    try {
      // Request permissions and get user media stream
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
      });

      // Enumerate devices
      const devices = await navigator.mediaDevices.enumerateDevices();

      // Filter devices by kind
      let audioGranted = devices.some((val) => val.kind === "audioinput" && val.label !== "");
      let videoGranted = devices.some((val) => val.kind === "videoinput" && val.label !== "");
      if (!audioGranted) {
        this.setAudioError();
      }
      if (!videoGranted) {
        this.setVideoError();
      }
      stream.getTracks().forEach((track) => track.stop());

      // Check if permissions were granted or denied
      try {
        const [audioPermission, videoPermission] = await Promise.all([
          navigator.permissions.query({ name: "microphone" }),
          navigator.permissions.query({ name: "camera" }),
        ]);
        if (audioPermission.state === "prompt" || videoPermission.state === "prompt") {
          // Permissions not granted or denied yet
          this.setTimeout(() => {
            this.checkPermissions();
          }, 1500);
        } else if (audioPermission.state === "denied" || videoPermission.state === "denied") {
          // Audio or video permissions were denied
          this.setState({ permissionsDialogOpen: true });
        } else {
          // Permissions were granted, and you have access to devices
          this.setState({ permissionsDialogOpen: false });
          this.setTimeout(() => {
            this.startMediaTest();
          }, 1000);
        }
      } catch (error) {
        console.error("Error accessing media permissions:", error);
        if (!audioGranted || !videoGranted) {
          this.setState({ permissionsDialogOpen: true });
        } else {
          this.setTimeout(() => {
            this.startMediaTest();
          }, 1000);
        }
      }
    } catch (error) {
      if (att < 3) {
        console.error("Error accessing media devices (%s), retrying...", error);
        await new Promise((res) => this.setTimeout(res, att ** 2 * 100));
        if (!this.__unmounting) await this.checkPermissions(att + 1);
      } else {
        console.error("Error accessing media devices (%s), giving up...", error);
      }
    }
  };

  setClientJoinCallTimer = () => {
    if (this.joinCallTimer) {
      clearInterval(this.joinCallTimer);
    }
    this.joinCallTimer = setInterval(() => {
      if (this.props.joinRoomTriggered || this.props.hasJoinedRoom) {
        clearInterval(this.joinCallTimer);
        return;
      }
      let joinCallIn = this.state.joinCallIn - 1;
      if (joinCallIn === 0 && !this.props.hasJoinedRoom && !this.props.errorDialogOpen) {
        this.props.joinRoom();
        clearInterval(this.joinCallTimer);
      }
      if (joinCallIn === 0 && !this.props.hasJoinedRoom && this.props.errorDialogOpen) {
        joinCallIn = 20;
        this.setClientJoinCallTimer();
      }
      this.setState({ joinCallIn });
    }, 1000);
  };

  componentWillUnmount() {
    this.__unmounting = true;

    // sever the state - most errors occur when attempting to set state after unmount
    this.setState = () => {};

    this.stopActiveStreams();
    clearInterval(this.state.timeIntervalId);
    if (this.accessDeniedRedirectTimer) {
      clearInterval(this.accessDeniedRedirectTimer);
    }

    if (this.joinCallTimer) {
      clearInterval(this.joinCallTimer);
    }

    // clear any pending timers
    Object.keys(this.__timers).forEach(clearTimeout);
  }

  stopActiveStreams = () => {
    try {
      if (this.video) {
        if (typeof this.video.srcObject == "object") {
          this.video.srcObject.getTracks().forEach((track) => {
            track.stop();
            this.video.srcObject.removeTrack(track);
          });
          this.video.srcObject = null;
        } else {
          this.video.src.getTracks().forEach((track) => {
            track.stop();
          });
          this.video.src = null;
        }
        this.video = null;
      }
      if (window.stream) {
        window.stream.getTracks().forEach((track) => {
          track.stop();
        });
      }

      navigator.mediaDevices.enumerateDevices().then((devices) => {
        const activeStreams = devices.filter((device) => device.deviceId !== "");
        activeStreams.forEach((device) => {
          navigator.mediaDevices
            .getUserMedia({
              audio: {
                deviceId: device.kind === "audioinput" ? device.deviceId : undefined,
              },
              video: {
                deviceId: device.kind === "videoinput" ? device.deviceId : undefined,
              },
            })
            .then((stream) => {
              stream.getTracks().forEach((track) => {
                track.stop();
              });
            })
            .catch((error) => {
              console.error("Error stopping stream:", error);
            });
        });
      });
    } catch (error) {
      console.error("Error enumerating devices:", error);
    }
  };

  startMediaTest = () => {
    this.startVideo();
    this.startAudio();
    this.onClosePermissionsDialog();
  };

  storedAudioInputAvailable = () => {
    const storedAudioInput = localStorage.getItem("currentAudioInput");
    if (storedAudioInput) {
      return this.props.audioInputs.some((device) => device.deviceId == storedAudioInput);
    }
  };

  storedAudioOutputAvailable = () => {
    const storedAudioOutput = localStorage.getItem("currentAudioOutput");
    if (storedAudioOutput) {
      return this.props.audioOutputs.some((device) => device.deviceId == storedAudioOutput);
    }
  };

  storedCameraInputAvailable = () => {
    const storedCameraInput = localStorage.getItem("currentCameraInput");
    if (storedCameraInput) {
      return this.props.cameraInputs.some((device) => device.deviceId == storedCameraInput);
    }
  };

  startAudio = () => {
    const storedAudioInput = localStorage.getItem("currentAudioInput");
    const storedAudioOutput = localStorage.getItem("currentAudioOutput");
    const storedCameraInput = localStorage.getItem("currentCameraInput");
    let audioInputDeviceId = this.storedAudioInputAvailable()
      ? storedAudioInput
      : this.props.audioInputs[0]?.deviceId
        ? this.props.audioInputs[0]?.deviceId
        : "";
    let audioOutputDeviceId = this.storedAudioOutputAvailable()
      ? storedAudioOutput
      : this.props.audioOutputs[0]?.deviceId
        ? this.props.audioOutputs[0]?.deviceId
        : "";
    let cameraInputDeviceId = this.storedCameraInputAvailable()
      ? storedCameraInput
      : this.props.cameraInputs[0]?.deviceId
        ? this.props.cameraInputs[0]?.deviceId
        : "";
    const constraints = {
      audio: {
        deviceId: audioInputDeviceId ? { exact: audioInputDeviceId } : undefined,
      },
      video: {
        deviceId: cameraInputDeviceId ? { exact: cameraInputDeviceId } : undefined,
      },
    };
    if (window.stream) {
      window.stream.getTracks().forEach((track) => {
        track.stop();
      });
    }
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        window.stream = stream;
        this.video = document.querySelector("video");
        if (!!this.video && "srcObject" in this.video) {
          this.video.srcObject = stream;
        } else {
          this.video.src = window.URL.createObjectURL(stream);
        }
        if (audioInputDeviceId && !!this.video && typeof this.video.setSinkId === "function") {
          this.video
            .setSinkId(audioOutputDeviceId)
            .then(() => {
              if (stream.getAudioTracks().length > 0) {
                this.setAudioSuccess();
              }
            })
            .catch((error) => {
              console.log(error);
              this.setAudioError();
              this.onOpenPermissionsDenied();
            });
        }
        this.setAudioSuccess();
        this.props.setCurrentInput("currentAudioInput", audioInputDeviceId);
        this.props.setCurrentInput("currentAudioOutput", audioOutputDeviceId);
        this.props.setCurrentInput("currentCameraInput", cameraInputDeviceId);
      })
      .catch((error) => {
        console.log(error);
        this.setAudioError();
        this.onOpenPermissionsDenied();
      });
  };

  startVideo = () => {
    if (window.stream) {
      window.stream.getTracks().forEach((track) => {
        track.stop();
      });
    }
    var constraints = {
      audio: false,
      video: {
        height: { min: 480, max: 720 },
        frameRate: 24,
        width: { min: 640, max: 1280 },
      },
      maxAudioBitrate: 16000,
    };

    // Older browsers might not implement mediaDevices at all, so we set an empty object first
    if (navigator.mediaDevices === undefined) {
      navigator.mediaDevices = {};
    }

    // Some browsers partially implement mediaDevices. We can't just assign an object
    // with getUserMedia as it would overwrite existing properties.
    // Here, we will just add the getUserMedia property if it's missing.
    if (navigator.mediaDevices.getUserMedia === undefined) {
      navigator.mediaDevices.getUserMedia = function (constraints) {
        // First get ahold of the legacy getUserMedia, if present
        var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

        // Some browsers just don't implement it - return a rejected promise with an error
        // to keep a consistent interface
        if (!getUserMedia) {
          return Promise.reject(new Error("getUserMedia is not implemented in this browser"));
        }

        // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
        return new Promise(function (resolve, reject) {
          getUserMedia.call(navigator, constraints, resolve, reject);
        });
      };
    }
    const storedCameraInput = localStorage.getItem("currentCameraInput");
    let videoDeviceId = this.storedCameraInputAvailable()
      ? storedCameraInput
      : this.props.cameraInputs[0]?.deviceId
        ? this.props.cameraInputs[0]?.deviceId
        : "";
    constraints.video.deviceId = videoDeviceId ? { exact: videoDeviceId } : undefined;

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        window.stream = stream;
        this.video = document.querySelector("video");
        // Older browsers may not have srcObject
        if (!!this.video && "srcObject" in this.video) {
          this.video.srcObject = stream;
        } else {
          // Avoid using this in new browsers, as it is going away.
          this.video.src = window.URL.createObjectURL(stream);
        }
        if (stream.getVideoTracks().length > 0) {
          this.setVideoSuccess();
        }
        this.props.setCurrentInput("currentCameraInput", videoDeviceId);
      })
      .catch((err) => {
        console.log(err.name + ": " + err.message);
        this.onOpenPermissionsDenied();
        this.setVideoError();
      });
  };

  startRecording = () => {
    this.setState({ record: true });
  };

  stopRecording = () => {
    this.setState({ record: false });
  };

  setVideoError = () => {
    this.setState({ videoError: true });
  };

  setVideoSuccess = () => {
    this.setState({ videoSuccess: true, videoError: false });
  };

  setAudioError = () => {
    this.setState({ audioError: true });
  };

  setAudioSuccess = (recordedBlob) => {
    this.setState({ audioSuccess: true, audioError: false });
  };

  onClosePermissionsDialog = () => {
    this.setState({ permissionsDialogOpen: false });
  };

  onOpenPermissionsDialog = () => {
    this.setState({ permissionsDialogOpen: true });
  };

  onClosePermissionsDenied = () => {
    this.setState({ permissionsDeniedOpen: false });
  };

  onOpenPermissionsDenied = () => {
    this.setState({ permissionsDeniedOpen: true });
  };

  onOpenCallReady = () => {
    this.setState({ callReadyOpen: true });
  };

  onOpenClientNotJoined = () => {
    this.setState({ clientNotJoinedOpen: true });
  };

  onCloseClientNotJoined = () => {
    this.setState({ clientNotJoinedOpen: false });
  };

  onCloseClientNoShow = () => {
    this.setState({ clientNoShowOpen: false, clientNoShowDismissed: true });
    clearInterval(this.state.timeIntervalId);
  };

  checkDeadline = () => {
    const { hasJoinedRoom, readyToJoinCall, startDate, isPrimaryClinician } = this.props;
    const { clientNoShowDismissed } = this.state;
    let currentDate = new Date();
    let deadline = addMinutes(new Date(this.props.oneTimeVideoInfo.scheduled_date), 10);
    if (
      currentDate > deadline &&
      !clientNoShowDismissed &&
      readyToJoinCall &&
      !hasJoinedRoom &&
      !startDate &&
      isPrimaryClinician
    ) {
      this.setState({ clientNoShowOpen: true });
    }
  };

  onOpenPrimaryNotStarted = () => {
    this.setState({ primaryNotStartedOpen: true });
  };

  onClosePrimaryNotStarted = () => {
    this.setState({ primaryNotStartedOpen: false });
  };

  copyVideoURL = () => {
    const baseUrl = process.env.BASE_PARENT_URL;
    if (this.props.match.params.videoKey) {
      navigator.clipboard.writeText(`${baseUrl}/video/${this.props.match.params.videoKey}`);
      this.setState({ videoCallUrlCopied: true });
    }
  };

  renderWaitingRoomTitle = () => {
    const { oneTimeVideoInfo, serviceType, isCaregiver, primaryClinicianFirstName } = this.props;
    let name;
    if (isCaregiver) {
      name = primaryClinicianFirstName || "";
    } else {
      if (serviceType === "Orientation" || serviceType === "Caregiver Training") {
        name = oneTimeVideoInfo.parent_name;
      } else {
        name = oneTimeVideoInfo.child_name;
      }
    }
    return `${serviceType} Call with ${name}`;
  };

  renderScheduledTimes = () => {
    const { oneTimeVideoInfo, callDuration } = this.props;
    if (oneTimeVideoInfo?.call_timezone) {
      return `${formatToTimeZone(new Date(oneTimeVideoInfo?.scheduled_date), "h:mm A", {
        timeZone: oneTimeVideoInfo?.call_timezone,
      })} - ${formatToTimeZone(
        addMinutes(new Date(oneTimeVideoInfo?.scheduled_date), parseInt(callDuration)),
        "h:mm A z",
        { timeZone: oneTimeVideoInfo?.call_timezone }
      )}`;
    } else {
      return `${format(
        new Date(oneTimeVideoInfo?.scheduled_date),
        convertTokens("h:mm A")
      )} - ${format(
        new Date(addMinutes(new Date(oneTimeVideoInfo?.scheduled_date), parseInt(callDuration))),
        convertTokens("h:mm A")
      )}`;
    }
  };

  render() {
    let {
      classes,
      accessGranted,
      oneTimeVideoInfo,
      clientLastLoginDate,
      isCaregiver,
      clientOnMobile,
      fromStaticUrl,
      onSetReadyToJoinCall,
      readyToJoinCall,
      currentAudioInput,
      currentCameraInput,
      currentAudioOutput,
      cameraInputs,
      audioInputs,
      audioOutputs,
      canChangeSpeaker,
      canChangeAudioInput,
      canChangeVideoInput,
      handleUserCameraChange,
      handleUserMicrophoneChange,
      handleUserSpeakerChange,
      cameraDisabled,
      allowedToJoinCall,
      inputAccessGranted,
    } = this.props;
    let mediaBlockedMessage;
    if (this.state.videoError || this.state.audioError) {
      if (this.state.videoError) {
        mediaBlockedMessage = "Your camera ";
        if (this.state.audioError) {
          mediaBlockedMessage += "and microphone are blocked";
        } else {
          mediaBlockedMessage += "is blocked";
        }
      } else {
        mediaBlockedMessage = "Your microphone is blocked";
      }
    }
    const allowJoinCall = readyToJoinCall && allowedToJoinCall;
    return (
      <>
        <div
          className={allowJoinCall ? classes.readyToJoinRoom : classes.waitingRoom}
          id="container"
        >
          <div className={classes.testInfo} style={allowJoinCall ? { maxHeight: "95%" } : null}>
            {accessGranted || !this.props.match.params.videoKey ? (
              <>
                {!allowJoinCall && oneTimeVideoInfo && oneTimeVideoInfo?.scheduled_date && (
                  <>
                    <Typography component="h1" className={classes.lobbyTitle}>
                      {this.renderWaitingRoomTitle()}
                    </Typography>
                    <Typography component="h1" className={classes.lobbyCallTime}>
                      {this.renderScheduledTimes()}
                    </Typography>
                  </>
                )}
                <div className={classes.lobbyLocalMedia}>
                  {cameraDisabled && (
                    <>
                      <div className={classes.cameraDisabledPlaceholder}>
                        <div className={classes.cameraDisabledLobby}>
                          <VideoCamOffIcon
                            style={{
                              fontSize: 40,
                              fill: "#fff",
                            }}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  <video
                    autoPlay={true}
                    playsInline={true}
                    id="videoElement"
                    muted
                    ref={this.props.lobbyLocalVideo}
                  ></video>

                  {!clientOnMobile &&
                    !allowJoinCall &&
                    window.innerWidth > 400 &&
                    inputAccessGranted && (
                      <div className={classes.lobbyInputControlsContainer}>
                        {canChangeAudioInput() && (
                          <div className={classes.lobbyInputControl}>
                            <MicIcon
                              style={{
                                position: "absolute",
                                left: 5,
                                fill: "#fff",
                                zIndex: 1,
                                fontSize: 16,
                              }}
                            />
                            <CustomSelect
                              name="currentAudioInputLobby"
                              id="currentAudioInputLobby"
                              native
                              label="Microphone"
                              value={currentAudioInput}
                              onChange={handleUserMicrophoneChange}
                              disableUnderline
                            >
                              {audioInputs.length > 0 &&
                                audioInputs.map((option) => (
                                  <option key={option.deviceId} value={option.deviceId}>
                                    {`${
                                      option.deviceId === currentAudioInput ? "Microphone: " : ""
                                    }${option.label}`}
                                  </option>
                                ))}
                            </CustomSelect>
                          </div>
                        )}
                        {canChangeSpeaker() && (
                          <div className={classes.lobbyInputControl}>
                            <SpeakerIcon
                              style={{
                                position: "absolute",
                                left: 5,
                                fill: "#fff",
                                zIndex: 1,
                                fontSize: 16,
                              }}
                            />
                            <CustomSelect
                              native
                              name="currentAudioOutputLobby"
                              id="currentAudioOutputLobby"
                              label="Speaker"
                              value={currentAudioOutput}
                              onChange={handleUserSpeakerChange}
                              fullWidth
                              disableUnderline
                            >
                              {audioOutputs.length > 0 &&
                                audioOutputs.map((option) => (
                                  <option key={option.deviceId} value={option.deviceId}>
                                    {`${
                                      option.deviceId === currentAudioOutput ? "Speakers: " : ""
                                    }${option.label}`}
                                  </option>
                                ))}
                            </CustomSelect>
                          </div>
                        )}
                        {canChangeVideoInput() && (
                          <div className={classes.lobbyInputControl}>
                            <VideoCamIcon
                              style={{
                                position: "absolute",
                                left: 5,
                                fill: "#fff",
                                zIndex: 1,
                                fontSize: 16,
                              }}
                            />
                            <CustomSelect
                              name="currentCameraInputLobby"
                              id="currentCameraInputLobby"
                              label="Camera"
                              native
                              value={currentCameraInput}
                              onChange={handleUserCameraChange}
                              disableUnderline
                              fullWidth
                            >
                              {cameraInputs.length > 0 &&
                                cameraInputs.map((option) => (
                                  <option key={option.deviceId} value={option.deviceId}>
                                    {`${option.deviceId === currentCameraInput ? "Camera: " : ""}${
                                      option.label
                                    }`}
                                  </option>
                                ))}
                            </CustomSelect>
                          </div>
                        )}
                      </div>
                    )}
                </div>
                {(clientOnMobile || window.innerWidth < 400) &&
                  !allowJoinCall &&
                  inputAccessGranted && (
                    <div className={classes.mobileLobbyInputControlsContainer}>
                      {canChangeAudioInput() && (
                        <div className={classes.mobileLobbyInputControl}>
                          <MicIcon
                            style={{
                              position: "absolute",
                              left: 5,
                              top: 5,
                              fill: "#fff",
                              zIndex: 1,
                              fontSize: 16,
                            }}
                          />
                          <CustomSelect
                            name="currentAudioInputLobby"
                            id="currentAudioInputLobby"
                            native
                            label="Microphone"
                            value={currentAudioInput}
                            onChange={handleUserMicrophoneChange}
                            disableUnderline
                          >
                            {audioInputs.length > 0 &&
                              audioInputs.map((option) => (
                                <option key={option.deviceId} value={option.deviceId}>
                                  {`${option.deviceId === currentAudioInput ? "Microphone: " : ""}${
                                    option.label
                                  }`}
                                </option>
                              ))}
                          </CustomSelect>
                        </div>
                      )}
                      {canChangeSpeaker() && (
                        <div className={classes.mobileLobbyInputControl}>
                          <SpeakerIcon
                            style={{
                              position: "absolute",
                              left: 5,
                              top: 5,
                              fill: "#fff",
                              zIndex: 1,
                              fontSize: 16,
                            }}
                          />
                          <CustomSelect
                            native
                            name="currentAudioOutputLobby"
                            id="currentAudioOutputLobby"
                            label="Speaker"
                            value={currentAudioOutput}
                            onChange={handleUserSpeakerChange}
                            fullWidth
                            disableUnderline
                          >
                            {audioOutputs.length > 0 &&
                              audioOutputs.map((option) => (
                                <option key={option.deviceId} value={option.deviceId}>
                                  {`${option.deviceId === currentAudioOutput ? "Speakers: " : ""}${
                                    option.label
                                  }`}
                                </option>
                              ))}
                          </CustomSelect>
                        </div>
                      )}
                      {canChangeVideoInput() && (
                        <div className={classes.mobileLobbyInputControl}>
                          <VideoCamIcon
                            style={{
                              position: "absolute",
                              left: 5,
                              top: 5,
                              fill: "#fff",
                              zIndex: 1,
                              fontSize: 16,
                            }}
                          />
                          <CustomSelect
                            name="currentCameraInputLobby"
                            id="currentCameraInputLobby"
                            label="Camera"
                            native
                            value={currentCameraInput}
                            onChange={handleUserCameraChange}
                            disableUnderline
                            fullWidth
                          >
                            {cameraInputs.length > 0 &&
                              cameraInputs.map((option) => (
                                <option key={option.deviceId} value={option.deviceId}>
                                  {`${option.deviceId === currentCameraInput ? "Camera: " : ""}${
                                    option.label
                                  }`}
                                </option>
                              ))}
                          </CustomSelect>
                        </div>
                      )}
                    </div>
                  )}
                {allowJoinCall ? (
                  <HangTight classes={classes} isClient={isCaregiver} startCall />
                ) : this.state.audioSuccess && this.state.videoSuccess ? (
                  <Button
                    onClick={onSetReadyToJoinCall}
                    className={classes.startButton}
                    disableRipple
                  >
                    Join Video Call
                  </Button>
                ) : null}
                {!this.props.isCaregiver && window.innerWidth < 960 && (
                  <div className={classes.lobbyClinicianInfoContainer}>
                    <Button
                      onClick={this.copyVideoURL}
                      style={{
                        position: "relative",
                        //zindex: 2601
                      }}
                      color="primary"
                      variant="text"
                    >
                      Copy Video Call URL
                    </Button>
                    {clientLastLoginDate && oneTimeVideoInfo?.call_timezone && (
                      <Typography component="p" className={classes.lastActiveText}>
                        {oneTimeVideoInfo.child_name} was last active:{" "}
                        {clientLastLoginDate &&
                          formatToTimeZone(clientLastLoginDate, "M/D/YY @ h:mm A z", {
                            timeZone: oneTimeVideoInfo?.call_timezone,
                          })}
                      </Typography>
                    )}
                  </div>
                )}
              </>
            ) : (
              <CircularProgress size={30} style={{ margin: "auto" }} />
            )}
          </div>
          {!allowJoinCall && (
            <>
              <img className={classes.anLogo} src={FrontLogo} alt="AnswersNow" title="AnswersNow" />
              {!fromStaticUrl && (
                <Button
                  variant="text"
                  color="primary"
                  className={classes.goBackButton}
                  onClick={() => this.props.history.push("/dashboard")}
                >
                  Back to Dashboard
                </Button>
              )}
            </>
          )}

          {!this.props.isCaregiver && window.innerWidth > 959 && (
            <div className={classes.lobbyClinicianInfoContainer}>
              <Button
                onClick={this.copyVideoURL}
                style={{
                  position: "relative",
                  //zindex: 2601
                }}
                color="primary"
                variant="text"
              >
                Copy Video Call URL
              </Button>
              {clientLastLoginDate && oneTimeVideoInfo?.call_timezone && (
                <Typography component="p" className={classes.lastActiveText}>
                  {oneTimeVideoInfo.child_name} was last active:{" "}
                  {clientLastLoginDate &&
                    formatToTimeZone(clientLastLoginDate, "M/D/YY @ h:mm A z", {
                      timeZone: oneTimeVideoInfo?.call_timezone,
                    })}
                </Typography>
              )}
            </div>
          )}
        </div>
        <LobbyDialogs
          {...{
            // values
            classes,
            history: this.props.history,
            accessDeniedOpen: this.state.accessDeniedOpen,
            redirectIn: this.state.redirectIn,
            clientNotJoinedOpen: this.state.clientNotJoinedOpen,
            clientNoShowOpen: this.state.clientNoShowOpen,
            primaryNotStartedOpen: this.state.primaryNotStartedOpen,
            permissionsDialogOpen: this.state.permissionsDialogOpen,
            permissionsDeniedOpen: this.state.permissionsDeniedOpen,
            oneTimeVideoInfo: this.props.oneTimeVideoInfo,
            user: this.props.user,
            mediaBlockedMessage,
            // handlers
            startMediaTest: this.startMediaTest,
            onCloseClientNotJoined: this.onCloseClientNotJoined,
            onCloseClientNoShow: this.onCloseClientNoShow,
            markNoShow: this.props.markNoShow,
            onClosePrimaryNotStarted: this.onClosePrimaryNotStarted,
          }}
        />
      </>
    );
  }
}

export default connect(mapStateToProps, {})(withStyles(styles)(Lobby));
