import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
} from "chart.js";
import { Line } from "react-chartjs-2";

import { space5, space6 } from "./styled";
import { getMaxAndMin, updateMonths } from "./utils";
/** @typedef {import("api/typesDef").AssessmentsClient} AssessmentClient */

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip);

// The options for the graph
const optionsGraph = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: "index",
    intersect: false,
  },
  stacked: false,
  scales: {
    y: {
      type: "linear",
      display: true,
      position: "left",
      border: {
        dash: [5, 5],
        color: "rgba(9, 26, 42, 1)",
      },
      grid: {
        tickLength: 0,
      },
      ticks: {
        padding: 20,
      },
    },
    x: {
      border: {
        dash: [5, 5],
        color: "rgba(9, 26, 42, 1)",
      },
      grid: {
        tickLength: 0,
      },
      ticks: {
        padding: 15,
      },
    },
  },
  plugins: {
    tooltip: {
      usePointStyle: true,
      padding: 8,
      backgroundColor: "rgba(0, 0, 0, 1)",
      boxPadding: 2,
      boxWidth: 7,
      boxHeight: 7,
      bodyFont: {
        LineHeight: 14,
      },
    },
  },
};

// Dataset template for the graph
const ABS_LINE = {
  label: "Adaptive Behavior Composite",
  borderColor: "rgb(142, 66, 202)",
  backgroundColor: "rgba(142, 66, 202, 0.5)",
};

const COMMUNICATION_LINE = {
  label: "Communication Score",
  borderColor: "rgb(255, 193, 7)",
  backgroundColor: "rgba(255, 193, 7, 0.5)",
};

const DAILY_LIVING_LINE = {
  label: "Daily Living Score",
  borderColor: "rgb(244, 67, 54)",
  backgroundColor: "rgba(244, 67, 54, 0.5)",
};

const SOCIALIZATION_LINE = {
  label: "Socialization Score",
  borderColor: "rgb(76, 175, 80)",
  backgroundColor: "rgba(76, 175, 80, 0.5)",
};

const MOTOR_SKILLS_LINE = {
  label: "Motor Skills",
  borderColor: "rgb(33, 150, 243)",
  backgroundColor: "rgba(33, 150, 243, 0.5)",
};

/**
 * The `LinesGraph` function generates a graph displaying ABC and Domain scores over time based on the
 * provided data assessments.
 * @component
 * @param {Object} props - The props object.
 * @param {AssessmentClient[]} props.dataAssessments - The assessments data for the client
 * @return {JSX.Element} A `LinesGraph` component.
 */
export default function LinesGraph({ dataAssessments }) {
  // The initial and end date range: X-axis
  const initDate = updateMonths(dataAssessments[0].data.administration_date, 6, false);
  const endDate = updateMonths(dataAssessments.at(-1).data.administration_date, 6, true);

  // The labels for the graph: X-axis
  const labels = [initDate.toLocaleDateString()];

  /*
   * Dataset values for the graph: Y-axis
   * The first value is set to `null` to align the first date as in the design
   */
  const ABS_data = [null];
  const CL_data = [null];
  const DL_data = [null];
  const SO_data = [null];
  const MS_data = [null];

  // Array to store the values of the Y-axis to calculate the min and max limits
  const valuesY = [];

  dataAssessments.forEach((assessment) => {
    labels.push(assessment.data.administration_date);

    ABS_data.push(assessment.data.adaptive_behavior_composite_standard_score);
    CL_data.push(assessment.data.communication_score);
    DL_data.push(assessment.data.daily_living_score);
    SO_data.push(assessment.data.socialization_score);
    MS_data.push(assessment.data.motor_score);

    valuesY.push(
      assessment.data.adaptive_behavior_composite_standard_score,
      assessment.data.communication_score,
      assessment.data.daily_living_score,
      assessment.data.socialization_score,
      assessment.data.motor_score
    );
  });

  labels.push(endDate.toLocaleDateString());

  const dataLines = {
    labels,
    datasets: [
      { ...ABS_LINE, data: ABS_data },
      { ...COMMUNICATION_LINE, data: CL_data },
      { ...DAILY_LIVING_LINE, data: DL_data },
      { ...SOCIALIZATION_LINE, data: SO_data },
      { ...MOTOR_SKILLS_LINE, data: MS_data },
    ],
  };

  const { max, min } = getMaxAndMin(valuesY);

  optionsGraph.scales.y.suggestedMax = max;
  optionsGraph.scales.y.suggestedMin = min;

  return (
    <Stack m={space6}>
      <Box marginBottom={space5}>
        <Typography variant="subtitle1">ABC and Domain Score Profile</Typography>
        <Typography variant="body2">
          This graph shows the ABC and Domain scores over time.
        </Typography>
      </Box>

      <section style={{ height: 270 }}>
        <Line options={optionsGraph} data={dataLines} />
      </section>
    </Stack>
  );
}
