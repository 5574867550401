import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import * as selectors from "selectors";
import IntercomAiProvider from "./IntercomAiProvider";
import { closeIntercomAi, openIntercomAi } from "./IntercomAi";
import { useLocation } from "react-router-dom";

const IntercomAiController = () => {
  const user = useSelector(selectors.getUser);
  const isClient = useSelector(selectors.isCaregiver);
  const isClinician = useSelector(selectors.isClinician);
  const isSupervisor = useSelector(selectors.isSupervisor);
  const isDirector = useSelector(selectors.isDirector);
  const routerLocation = useLocation();
  // List of paths where we don't want to show Intercom
  const urlBlacklist = ["/chat", "/document-viewer"];
  const intercomUser = user?.loaded && {
    name: `${user.first_name} ${user.last_name}`,
    email: user.email,
    userId: user.id,
    isClient,
    isClinician,
    isSupervisor,
    isDirector,
    user_hash: user.intercomHash,
  };
  // Paths where we want to position Intercom in a different place (using location)
  const customLocationList = [
    { url: "/video", location: { verticalPadding: 130, horizontalPadding: 25 } },
    { url: "/billing", location: { verticalPadding: 100, horizontalPadding: 20 } },
    { url: "/libraries", location: { verticalPadding: 100, horizontalPadding: 20 } },
    { url: "/stream", location: { verticalPadding: 100, horizontalPadding: 20 } },
  ];

  const closeInCurrentPath = useMemo(() => {
    const normalizedPath = routerLocation?.pathname.endsWith("/")
      ? routerLocation.pathname.slice(0, -1)
      : routerLocation.pathname;

    return urlBlacklist.some((prefix) => normalizedPath.startsWith(prefix));
  }, [routerLocation?.pathname]);

  const getCustomLocation = () => {
    return customLocationList.find((location) => routerLocation?.pathname.includes(location.url));
  };

  useEffect(() => {
    return () => {
      if (window?.Intercom) closeIntercomAi();
    };
  }, []);

  useEffect(() => {
    if (user?.loaded && !isClient && !window?.Intercom && !closeInCurrentPath) {
      IntercomAiProvider(intercomUser, getCustomLocation()?.location); // Initialize Intercom
    }
  }, [user, routerLocation]);

  useEffect(() => {
    if (window?.Intercom && closeInCurrentPath) closeIntercomAi();
    else if (intercomUser && !isClient) openIntercomAi(intercomUser, getCustomLocation()?.location);
  }, [routerLocation]);

  return null;
};

export default IntercomAiController;
