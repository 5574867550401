import React from "react";
import { DialogActions, Alert } from "@mui/material";
import EditActions from "./EditActions";
import ViewActions from "./ViewActions";
import { WarningCircle } from "@phosphor-icons/react";

const Actions = (props) => {
  const { callBreakdownError, notesError, setAssociatedTimesError, viewOnly } = props;
  return (
    <DialogActions
      sx={{
        borderTop: "1px solid #CDD3DA", // Matches the dialog header's borderBottom
      }}
    >
      {(callBreakdownError || notesError || setAssociatedTimesError) && (
        <Alert
          severity="error"
          icon={<WarningCircle size={22} color="rgba(211, 47, 47, 1)" />}
          sx={{ marginRight: 20 }}
        >
          An error has occurred saving this note. Please try again.
        </Alert>
      )}
      {viewOnly ? <ViewActions {...props} /> : <EditActions {...props} />}
    </DialogActions>
  );
};

export default Actions;
