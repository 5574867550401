import { useMutation } from "react-query";
import { updateClinicianWorkingHours } from "../../api/clinician";

/**
 * Custom hook for updating clinician availability.
 *
 * @param {Function} onSuccess Callback function to be called upon successful mutation.
 * @param {Function} onError Callback function to be called upon an error in the mutation.
 * @returns The React Query's useMutation result object containing mutate and isLoading.
 */
export const useUpdateClinicianAvailability = ({ onSuccess, onError }) => {
  const { mutate, isLoading } = useMutation(
    ({ basicInfo, newWorkingHours }) =>
      updateClinicianWorkingHours({
        clinicianId: basicInfo?.id,
        clinicianTimeZone: basicInfo?.timezone,
        clinicianEmail: basicInfo?.timezone,
        newWorkingHours,
      }),
    { onSuccess, onError }
  );
  return { mutate, isLoading };
};
