import { UserPresenceLevel } from "video/meeting/MeetingRoster";
import { extractUserIdFromMessage } from "video/messaging/util";
import { log } from "video/debug";

export default class UserHeartbeatController {
  __heartbeatTimeouts = {};
  __heartbeatTimer;
  __userTimeoutCount = {};
  __started = false;

  /** @type {UserPresenceLevel} */
  presenceLevel = 0;

  // we can ignore any heartbeat messages older than our ttl
  _rateMs = 15000;
  set rateMs(ms) {
    this._rateMs = ms;
    this.userTimeoutMs = this._rateMs * 2.5;
  }
  get rateMs() {
    return this._rateMs;
  }

  // this is set to 2.5x this.rateMs anytime it is set
  userTimeoutMs = 38000;

  // on error sending heartbeat, increase this to increase the beat rate, and gradually decrease back to 1 as we successfully send beats
  heartbeatRateDenom = 1;

  persistentMessages = false;

  /**
   * @param {import('video/messaging/MessagingSession').default} messagingSession
   * @param {UserPresenceLevel} presenceLevel
   */
  constructor(messagingSession, presenceLevel, persistentMessages = false) {
    this.presenceLevel = presenceLevel;
    this.messagingSession = messagingSession;
    this.persistentMessages = persistentMessages;
    this.messagingSession.addMessageListener(this.processHeartbeatMessage, "HB");
    this.messagingSession.addMessageListener(this.processHeartbeatMessage, "PI");
    this.messagingSession.addMessageListener(this.processHeartbeatMessage, "PO");
  }

  destroy() {
    this.stop();
    this.rateMs = 15000;
    this.presenceLevel = "";
  }

  start() {
    if (this.__started) {
      return;
    }
    this.__started = true;
    if (!this.__heartbeatTimer) {
      this.beat();
    }
  }

  stop() {
    this.__started = false;
    clearTimeout(this.__heartbeatTimer);
    this.__heartbeatTimer = null;
    this.__heartbeatTimeouts = [];
    this.messagingSession.removeMessageListener(this.processHeartbeatMessage, "HB");
    this.messagingSession.removeMessageListener(this.processHeartbeatMessage, "PI");
    this.messagingSession.removeMessageListener(this.processHeartbeatMessage, "PO");
  }

  /**
   * calling this.beat() will interrupt the heartbeat timer (if it is active)
   * to send a beat right away; then it will resume the timer (if it was active)
   */
  beat() {
    clearTimeout(this.__heartbeatTimer);

    if (!this.__started) return;

    this._beat().then(() => {
      if (this.__started) {
        const t0 = Math.ceil(this.rateMs / this.heartbeatRateDenom);
        // log("next beat:", t0);
        this.__heartbeatTimer = setTimeout(() => this.beat(), t0);
      }
    });
  }

  async _beat() {
    if (!this.__started) return;

    try {
      if (typeof this.presenceLevel !== "number") throw new Error("Nothing to send");

      if (this.persistentMessages) log("-> beat: ", "", this.presenceLevel);

      let res;
      if (this.persistentMessages)
        res = await this.messagingSession.sendPersistentControlMessage(
          `${this.presenceLevel}`,
          "HB"
        );
      else
        res = await this.messagingSession.sendNonPersistentControlMessage(
          `${this.presenceLevel}`,
          "HB"
        );

      if (this.heartbeatRateDenom > 1) {
        this.heartbeatRateDenom -= 1;
      }

      return res;
    } catch (err) {
      console.error("failed to send heartbeat");
      console.error(err);

      // only increase up to 4 (i.e. 4x faster than the standard rate defined in `this.heartbeatRateIn[Lobby|Call]`)
      this.heartbeatRateDenom = Math.min(4, this.heartbeatRateDenom + 1);
    }
  }

  /**
   * Called when we receive a PI(ng) message from the given `userId`
   * - pass '*' as userId to listen to any user beats
   * @param {string} [userId="*"]
   * @param {(userId:string,presenceLevel:UserPresenceLevel,CreatedTimestamp:Date)=>void} cb
   */
  onUserBeat(userId, cb) {
    if (!this._onUserBeat[userId]) this._onUserBeat[userId] = [];
    this._onUserBeat[userId].push(cb);
  }
  /** @type {{[userId:string]:(userId:string?,presenceLevel:UserPresenceLevel,CreatedTimestamp:Date)=>void}} */
  _onUserBeat = { "*": [] };
  __onUserBeat(userId, presenceLevel, CreatedTimestamp) {
    this._onUserBeat["*"].forEach((cb) => cb(userId, presenceLevel, CreatedTimestamp));
    this._onUserBeat[userId]?.forEach((cb) => cb(userId, presenceLevel, CreatedTimestamp));
  }

  /**
   * Called when we receive a PI(ng) message from the given `userId`
   * - pass '*' as userId to listen to any user timeouts
   * @param {string} [userId="*"]
   * @param {(userId:string,timeoutCount:number)=>void} cb
   */
  onUserTimeout(userId, cb) {
    if (!this._onUserTimeout[userId]) this._onUserTimeout[userId] = [];
    this._onUserTimeout[userId].push(cb);
  }
  /** @type {{[userId:string]:(userId:string,timeoutCount:number)=>void}} */
  _onUserTimeout = { "*": [] };
  __onUserTimeout(userId, timeoutCount = 1) {
    this._onUserTimeout["*"].forEach((cb) => cb(userId, timeoutCount));
    this._onUserTimeout[userId]?.forEach((cb) => cb(userId, timeoutCount));
  }

  /**
   * This will take a HB message and apply it to `this.userPresenceMap`
   * @param {import('@aws-sdk/client-chime-sdk-messaging').ChannelMessage} hb heartbeat message
   */
  processHeartbeatMessage = (hb) => {
    if (!this.__started) return;

    const userId = extractUserIdFromMessage(hb);
    clearTimeout(this.__heartbeatTimeouts[userId]);

    this.__userTimeoutCount[userId] = 0;
    const presenceLevel = parseInt(hb.Content, 10);

    // log("<- beat", { userId, presenceLevel });

    if (this.userTimeoutMs) {
      this.__heartbeatTimeouts[userId] = setTimeout(() => {
        if (!this.__started) return;

        if (!this.__userTimeoutCount[userId]) this.__userTimeoutCount[userId] = 1;
        else this.__userTimeoutCount[userId] += 1;

        this.__onUserTimeout(userId, this.__userTimeoutCount[userId]);
      }, this.userTimeoutMs);
    }

    this.__onUserBeat(userId, presenceLevel, hb.CreatedTimestamp);
  };
}
