import React from "react";
import NoClientsScreen from "./NoClientsScreen";
import ClientAccountSelectionScreen from "./ClientAccountSelectionScreen";
import ANLoadingScreen from "elements/ANLoadingScreen";
import { useGetClients } from "hooks/api/useGetClients";
import cookie from "react-cookies";

export default function AccountSelectionSplashScreen() {
  const userId = cookie.load("userId");
  const { clients, clientsLoading } = useGetClients({ userIds: userId });

  if (clientsLoading) return <ANLoadingScreen />;

  return clients?.length > 0 ? (
    <ClientAccountSelectionScreen clients={clients} userId={userId} />
  ) : (
    <NoClientsScreen />
  );
}
