import React from "react";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { format } from "date-fns";

import { convertTokens } from "@date-fns/upgrade/v2";

const Notifications = (props) => {
  const {
    documentMatrix,
    notifications,
    displayUnreadMessageNotification,
    workflowStatus,
    userPermissions,
    user,
    eCommConsentSkipped,
  } = props;
  const isPrimaryAccountHolder = user?.primary_account;
  const missingDocs = documentMatrix?.filter((doc) => {
    return !doc.internal && doc.can_upload && doc.required && !doc.filename;
  });

  const mapNotificationsToDisplayBlocks = () => {
    let notificationsArray = [];

    if (
      workflowStatus == "APPROVE_PLAN" &&
      userPermissions?.approve_treatment_plan &&
      user.primary_account
    ) {
      notificationsArray.push(<ApproveTreatmentPlan {...props} />);
    }

    if (missingDocs && missingDocs.length > 0 && isPrimaryAccountHolder) {
      let diagnosisDoc = notifications.find(
        (notification) => notification.type === "UPLOAD_DIAGNOSIS_FORM",
      );
      missingDocs.map((doc) => {
        switch (doc.name) {
          case "INSURANCE_CARD_FRONT":
            notificationsArray.push(<UploadInsuranceCardFront key={1} doc={doc} {...props} />);
            return;
          case "INSURANCE_CARD_BACK":
            notificationsArray.push(<UploadInsuranceCardBack key={2} doc={doc} {...props} />);
            return;
          case "DIAGNOSIS_FORM":
            if (diagnosisDoc) {
              notificationsArray.push(
                <UploadDiagnosisForm key={3} doc={doc} notification={diagnosisDoc} {...props} />,
              );
            }
            return;
        }
      });
    }
    if (eCommConsentSkipped) {
      notificationsArray.push(<ECommConsentSkipped key={4} {...props} />);
    }
    notifications.map((notification) => {
      switch (notification.type) {
        case "CALL_SCHEDULED":
          notificationsArray.push(
            <NewSessionScheduled key={notification.id} notification={notification} {...props} />,
          );
          return;
        case "RECURRING_CALL_SCHEDULED":
          notificationsArray.push(
            <RecurringSessionScheduled
              key={notification.id}
              notification={notification}
              {...props}
            />,
          );
          return;
        case "CALL_RESCHEDULED":
          notificationsArray.push(
            <SessionRescheduled key={notification.id} notification={notification} {...props} />,
          );
          return;
        case "RECURRING_CALL_RESCHEDULED":
          notificationsArray.push(
            <RecurringSessionRescheduled
              key={notification.id}
              notification={notification}
              {...props}
            />,
          );
          return;
        case "CALL_CANCELLED":
          notificationsArray.push(
            <SessionCancelled key={notification.id} notification={notification} {...props} />,
          );
          return;
        case "RECURRING_CALL_CANCELLED":
          notificationsArray.push(
            <RecurringSessionCancelled
              key={notification.id}
              notification={notification}
              {...props}
            />,
          );
          return;
        case "SUPER_BILL_CREATED":
          notificationsArray.push(
            <SuperBillCreated key={notification.id} notification={notification} {...props} />,
          );
      }
    });
    if (displayUnreadMessageNotification) {
      notificationsArray.push(<UnreadMessage key={4} {...props} />);
    }
    return notificationsArray;
  };
  const displayNotifications = mapNotificationsToDisplayBlocks();
  return displayNotifications.length > 0 ? (
    displayNotifications.map((notification) => notification)
  ) : (
    <NoNotificationsToDisplay {...props} />
  );
};

export default Notifications;

var NotificationBlock = (props) => {
  const {
    classes,
    title,
    description,
    color,
    buttonText,
    handleClick,
    allowDissmiss,
    handleDismiss,
    primaryButtonVariant,
    secondaryButtonText,
    handleSecondaryClick,
  } = props;
  return (
    <div className={classes.notificationContainer}>
      <div className={classes.notificationHighlight} style={{ backgroundColor: color }} />
      <div className={classes.notificationDetails}>
        <Typography component="p" className={classes.notificationTitle}>
          {title}
        </Typography>
        <Typography component="p" className={classes.notificationDescription}>
          {description}
        </Typography>

        <div className={classes.notificationButtonContainer}>
          {handleClick && buttonText && (
            <Button variant={primaryButtonVariant || "text"} size="small" onClick={handleClick}>
              {buttonText}
            </Button>
          )}
          {handleSecondaryClick && secondaryButtonText && (
            <Button variant="text" size="small" onClick={handleSecondaryClick}>
              {secondaryButtonText}
            </Button>
          )}
          {allowDissmiss && (
            <Button variant="text" size="small" onClick={handleDismiss}>
              Dismiss
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

var ApproveTreatmentPlan = (props) => {
  const { onToggleTreatmentPlan, onToggleConfirmTreatmentPlanApproval } = props;
  return (
    <NotificationBlock
      title="Treatment Plan Needs Approval"
      description="Your Clinician has created a treatment plan for you and it is ready for review. It must be approved in order to be submitted to insurance. You can speak with your clinician if you have questions regarding the plan."
      color="#e283bc"
      buttonText="Approve Plan"
      handleClick={onToggleConfirmTreatmentPlanApproval}
      primaryButtonVariant="outlined"
      handleSecondaryClick={onToggleTreatmentPlan}
      secondaryButtonText="Review Plan"
      {...props}
    />
  );
};

var ECommConsentSkipped = (props) => {
  const { history } = props;
  return (
    <NotificationBlock
      title="Want to receive text messages regarding treatment?"
      description="Consenting to Electronic Communications will allow us to communicate with you through text message. Consent can be granted or removed anytime from your Account page."
      color="#E8C1A1"
      buttonText="Consent to Electronic Communications"
      handleClick={() => history.push("/account/ecomm-consent")}
      {...props}
    />
  );
};

var UploadInsuranceCardFront = (props) => {
  const { onOpenUploadUserDocument, doc } = props;
  return (
    <NotificationBlock
      title="Upload Front of Insurance Card"
      description="We need a copy of both sides of your insurance card to ensure your Insurance Company is billed properly. Upload an image of the front of your card here."
      color="#e283bc"
      buttonText="Upload Insurance Card - Front"
      handleClick={() => onOpenUploadUserDocument(doc.name)}
      {...props}
    />
  );
};

var UploadInsuranceCardBack = (props) => {
  const { onOpenUploadUserDocument, doc } = props;
  return (
    <NotificationBlock
      title="Upload Back of Insurance Card"
      description="We need a copy of both sides of your insurance card to ensure your Insurance Company is billed properly. Upload an image of the back of your card here."
      color="#e283bc"
      buttonText="Upload Insurance Card - Back"
      handleClick={() => onOpenUploadUserDocument(doc.name)}
      {...props}
    />
  );
};

var UploadDiagnosisForm = (props) => {
  const { onOpenUploadUserDocument, doc, notification, markNotificationAsRead } = props;
  return (
    <NotificationBlock
      title="Upload Diagnosis Form"
      description="The official form providing a diagnosis is required for us to proceed at this time."
      color="#E8C1A1"
      buttonText="Upload Diagnosis Form"
      handleClick={() => onOpenUploadUserDocument(doc.name)}
      handleDismiss={() => markNotificationAsRead(notification.id)}
      allowDissmiss
      {...props}
    />
  );
};

var UnreadMessage = (props) => {
  const { dismissUnreadMessageNotification } = props;
  return (
    <NotificationBlock
      title="New Message"
      description="You've received a new message in the chat. Click on the top of the screen to view it or the button below."
      color="#68D5D5"
      buttonText="Read Message"
      handleClick={() => props.history.push(`/chat`)}
      handleDismiss={dismissUnreadMessageNotification}
      allowDissmiss
      {...props}
    />
  );
};

var mapCallTypeToDisplay = (callType) => {
  switch (callType) {
    case "CAREGIVER_TRAINING":
      return "Caregiver Training";
    case "ORIENTATION":
      return "Orientation";
    case "DIRECT_THERAPY":
      return "Direct Therapy";
    case "ASSESSMENT":
      return "Assessment";
  }
};

var NewSessionScheduled = (props) => {
  const { notification, markNotificationAsRead } = props;
  return (
    <NotificationBlock
      title="New Session Scheduled"
      description={`${mapCallTypeToDisplay(notification.video_call_billing_type)} with ${
        notification.clinician_name
      } on ${format(
        new Date(notification.video_call_scheduled_date),
        convertTokens("dddd, MMMM D, YYYY"),
      )} at ${format(new Date(notification.video_call_scheduled_date), "p")}`}
      color="#68D5D5"
      buttonText="View Instructions"
      handleClick={() => props.history.push(`/call-instructions/${notification.video_call_id}`)}
      handleDismiss={() => markNotificationAsRead(notification.id)}
      allowDissmiss
      {...props}
    />
  );
};

var RecurringSessionScheduled = (props) => {
  const { notification, markNotificationAsRead } = props;
  return (
    <NotificationBlock
      title="Recurring Session Scheduled"
      description={`A recurring appointment with ${
        notification.clinician_name
      } has been scheduled starting on ${format(
        new Date(notification.video_call_scheduled_date),
        convertTokens("dddd, MMMM D, YYYY"),
      )} at ${format(new Date(notification.video_call_scheduled_date), "p")}.`}
      color="#68D5D5"
      buttonText="View Instructions"
      handleClick={() => props.history.push(`/call-instructions/${notification.video_call_id}`)}
      handleDismiss={() => markNotificationAsRead(notification.id)}
      allowDissmiss
      {...props}
    />
  );
};

var SessionRescheduled = (props) => {
  const { notification, markNotificationAsRead } = props;
  return (
    <NotificationBlock
      title="Session Rescheduled"
      description={`An appointment with ${
        notification.clinician_name
      } has been rescheduled for ${format(
        new Date(notification.video_call_scheduled_date),
        convertTokens("dddd, MMMM D, YYYY"),
      )} at ${format(new Date(notification.video_call_scheduled_date), "p")}`}
      color="#E8C1A1"
      buttonText="View Instructions"
      handleClick={() => props.history.push(`/call-instructions/${notification.video_call_id}`)}
      handleDismiss={() => markNotificationAsRead(notification.id)}
      allowDissmiss
      {...props}
    />
  );
};

var RecurringSessionRescheduled = (props) => {
  const { notification, markNotificationAsRead } = props;
  return (
    <NotificationBlock
      title="Recurring Session Rescheduled"
      description={`Your recurring appointment with ${
        notification.clinician_name
      } has been rescheduled for ${format(
        new Date(notification.video_call_scheduled_date),
        "p",
      )} starting on ${format(
        new Date(notification.video_call_scheduled_date),
        convertTokens("dddd, MMMM D, YYYY"),
      )}.`}
      color="#E8C1A1"
      buttonText="View Instructions"
      handleClick={() => props.history.push(`/call-instructions/${notification.video_call_id}`)}
      handleDismiss={() => markNotificationAsRead(notification.id)}
      allowDissmiss
      {...props}
    />
  );
};

var SessionCancelled = (props) => {
  const { notification, markNotificationAsRead } = props;
  return (
    <NotificationBlock
      title="Session Canceled"
      description={`${mapCallTypeToDisplay(notification.video_call_billing_type)} with ${
        notification.clinician_name
      } (previously scheduled for ${format(
        new Date(notification.video_call_scheduled_date),
        convertTokens("dddd, MMMM D, YYYY"),
      )} at ${format(
        new Date(notification.video_call_scheduled_date),
        "p",
      )}) has been canceled. If you have questions about this cancellation, please click below to message your clinician.`}
      color="#E8C1A1"
      buttonText="Message Clinician"
      handleClick={() => props.history.push(`/chat/${props.user?.active_conversation_url || ""}`)}
      handleDismiss={() => markNotificationAsRead(notification.id)}
      allowDissmiss
      {...props}
    />
  );
};

var RecurringSessionCancelled = (props) => {
  const { notification, markNotificationAsRead } = props;
  return (
    <NotificationBlock
      title="Recurring Session Canceled"
      description={`Your recurring ${mapCallTypeToDisplay(
        notification.video_call_billing_type,
      )} appointments with ${
        notification.clinician_name
      } have been canceled. If you have questions about this cancellation, please click below to message your clinician.`}
      color="#E8C1A1"
      buttonText="Message Clinician"
      handleClick={() => props.history.push(`/chat/${props.user?.active_conversation_url || ""}`)}
      handleDismiss={() => markNotificationAsRead(notification.id)}
      allowDissmiss
      {...props}
    />
  );
};

var NoNotificationsToDisplay = (props) => {
  return (
    <NotificationBlock
      title="You're all caught up!"
      description="We’ll let you know when your next session is.  Feel free to contact your clinician if you want to schedule a session or have any questions."
      color="#D2B3EA"
      buttonText="Message Clinician"
      handleClick={() => props.history.push(`/chat/${props.user?.active_conversation_url || ""}`)}
      {...props}
    />
  );
};

var SuperBillCreated = (props) => {
  const { notification, markNotificationAsRead } = props;
  return (
    <NotificationBlock
      title="New Super Bill Created"
      description="Click below to go to your Account page and view or download your Super Bills."
      color="#68D5D5"
      buttonText="Go to Account"
      handleClick={() => {
        markNotificationAsRead(notification.id);
        props.history.push(`/account`);
      }}
      primaryButtonVariant="outlined"
      handleDismiss={() => markNotificationAsRead(notification.id)}
      allowDissmiss
      {...props}
    />
  );
};
