import { createReducer } from "redux-create-reducer";
import actions from "../actions";

const initialState = {
  emailList: [],
  sentEmailsLoading: false,
  sentEmailsSuccess: false,
};

const reducers = {
  [actions.GET_USER_EMAIL_LOG](state, { payload }) {
    return {
      ...state,
      sentEmailsLoading: true,
      sentEmailsSuccess: false,
    };
  },
  [actions.GET_USER_EMAIL_LOG_SUCCESS](state, { payload }) {
    return {
      ...state,
      emailList: payload,
      sentEmailsLoading: false,
      sentEmailsSuccess: true,
    };
  },
};

export default createReducer(initialState, reducers);
