import React from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { CredentialForm } from "./CredentialForm";
import { X } from "@phosphor-icons/react";
import ANUploader from "elements/ANUploader";

export const InsuranceFormDialog = (props) => {
  const {
    // handlers
    submitInsurance,
    handleFileUpload,
    handleFileDelete,
    onCompanyChange,
    onPlanChange,
    setSubmittedDate,
    setInitialCredentialDate,
    setApprovalDate,
    setRecredentialDate,
    setTermDate,
    handleLoadedFileDelete,
    handleCloseInsuranceFormDialog,
    handleConfirmQuitCredentialEdit,
    handleCancelQuitCredentialEdit,
    handleToggleConfirmQuitCredentialEdit,
    credentialModifiedListener,
    onViewCredentialFile,
    // values
    insuranceFormDialogOpen,
    insuranceCompanyNameId,
    insurancePlanId,
    isNewCredential,
    selectedCredentialRow,
    submittedDate,
    initialCredentialDate,
    approvalDate,
    recredentialDate,
    termDate,
    insuranceCompanyOptions,
    insurancePlanOptions,
    dropzoneFiles,
    documentUploadError,
    insuranceSaveFail,
    insuranceSaving,
    theme,
    confirmQuitCredentialEditDialogOpen,
    credentialModified,
  } = props;

  const credProps = {
    // handlers
    onCompanyChange,
    onPlanChange,
    setInitialCredentialDate,
    setSubmittedDate,
    setApprovalDate,
    setRecredentialDate,
    setTermDate,
    credentialModifiedListener,
    // values
    insuranceCompanyNameId,
    insurancePlanId,
    insuranceCompanyOptions,
    insurancePlanOptions,
    initialCredentialDate,
    submittedDate,
    approvalDate,
    recredentialDate,
    termDate,
  };

  const { palette } = theme;
  const style = {
    width: "100%",
    minWidth: "500px",
    overflowY: "auto",
    p: "24px",
  };
  return (
    <Box>
      <Dialog
        maxWidth={"sm"}
        open={insuranceFormDialogOpen}
        onClose={
          credentialModified
            ? handleToggleConfirmQuitCredentialEdit
            : handleCloseInsuranceFormDialog
        }
      >
        <DialogTitle sx={{ padding: "16px 0" }}>
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            margin={0}
            width={"100%"}
            padding={"0 16px 0 24px"}
          >
            <Typography component="h6" style={{ fontSize: 20, fontWeight: "500" }}>
              {isNewCredential ? "Add" : "Edit"} Insurance Credentials
            </Typography>
            <IconButton
              onClick={
                credentialModified
                  ? handleToggleConfirmQuitCredentialEdit
                  : handleCloseInsuranceFormDialog
              }
            >
              <X fontSize={"24px"} color={palette.text.secondary} />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={style}>
          <CredentialForm {...credProps} />
          <ANUploader
            alreadyUploadedFiles={selectedCredentialRow?.files}
            handleFileUpload={credentialModifiedListener(handleFileUpload)}
            handleFileDelete={credentialModifiedListener(handleFileDelete)}
            dropzoneFiles={dropzoneFiles}
            handleLoadedFileDelete={credentialModifiedListener(handleLoadedFileDelete)}
            onViewFile={onViewCredentialFile}
          />
        </DialogContent>
        {(documentUploadError || insuranceSaveFail) && (
          <Box margin={"6px"}>
            <Alert severity="error">There has been an error saving this credential</Alert>
          </Box>
        )}
        <DialogActions>
          <Button
            color="secondary"
            onClick={
              credentialModified
                ? handleToggleConfirmQuitCredentialEdit
                : handleCloseInsuranceFormDialog
            }
          >
            Cancel
          </Button>
          <Button
            startIcon={insuranceSaving ? <CircularProgress size={"20px"} /> : undefined}
            disabled={insuranceSaving || insuranceCompanyNameId == ""}
            onClick={submitInsurance}
            autoFocus
          >
            {isNewCredential ? "Add Insurance" : "Save"}
          </Button>
        </DialogActions>
        <Dialog open={confirmQuitCredentialEditDialogOpen}>
          <DialogContent>Are you sure you'd like to quit without saving?</DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={handleCancelQuitCredentialEdit}>
              Cancel
            </Button>
            <Button color="secondary" onClick={handleConfirmQuitCredentialEdit}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Dialog>
    </Box>
  );
};

export default InsuranceFormDialog;
