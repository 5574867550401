import React from "react";
import { TextField } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
const StyledInput = withStyles({
  root: {
    backgroundColor: "#FCF9FF",
    "& label.Mui-focused": {
      color: "#545A72",
    },
    "& fieldset": {
      borderRadius: 2,
      color: "#3F4456",
    },
    "& .MuiOutlinedInput-root": {
      fontSize: 14,
      "&:hover fieldset": {
        border: "1px solid #A5AABC",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid #A5AABC",
      },
    },
    "& .MuiInputLabel-root": {
      fontSize: 15,
    },
    "& .MuiFormHelperText-root": {
      backgroundColor: "#fafafa",
      margin: 0,
      padding: "0 5px",
      fontWeight: 500,
      color: "#B91C1C",
    },
  },
})(TextField);

export const CustomInput = React.forwardRef((props, ref) => {
  return <StyledInput {...props} variant="outlined" size="small" />;
});
