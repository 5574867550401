import React from "react";
import Typography from "@mui/material/Typography";
import ChromeCamera from "../../../assets/blocked-camera-chrome.jpg";

export const ErrorMessage = (props) => {
  const {
    deviceInformationError,
    browserPermissionError,
    microphoneError,
    cameraError,
    speakerError,
    downloadTestEnded,
    averageDownloadMbps,
    uploadTestEnded,
    averageUploadMbps,
  } = props;
  if (deviceInformationError) {
    return (
      <>
        <Typography component="h4">Browser or Device not supported</Typography>
        <Typography component="p">
          Your browser doesn't support some of the features that we use during your video call.
          Please, log into the portal from a different browser. If the issue continues, please try a
          different device and join the call again.
        </Typography>
      </>
    );
  } else if (browserPermissionError) {
    return (
      <>
        <Typography component="h4">Browser Camera and Audio Access Blocked </Typography>
        <Typography component="p">
          You blocked your browser’s camera/audio access. To give access, you may need to try some
          of the solutions below, refresh the page & join the call again.
        </Typography>
        <ul>
          <li>
            <Typography component="p">
              Go to your browser’s settings and update the Answersnow site camera and microphone
              permissions,
            </Typography>
          </li>
          <li>
            <Typography component="p">
              Click the device blocked icon{" "}
              <img src={ChromeCamera} style={{ width: 20, height: 20 }} /> above in your browser's
              address bar and allow access.
            </Typography>
          </li>
        </ul>
      </>
    );
  } else if (microphoneError) {
    return (
      <>
        <Typography component="h4">Microphone Error</Typography>
        <Typography component="p">
          There is an error related to your microphone. You may need to try some of the solutions
          below, refresh the page and join the call again.
        </Typography>
        <ul>
          <li>
            <Typography component="p">
              Check your system settings for an available microphone,
            </Typography>
          </li>
          <li>
            <Typography component="p">
              If you have an external microphone, make sure that it is plugged in and connected
              securely,
            </Typography>
          </li>
          <li>
            <Typography component="p">
              Check for any websites or applications that may use your microphone and disable it,
            </Typography>
          </li>
          <li>
            <Typography component="p">
              If you're still experiencing microphone problems after trying the above, log into the
              portal from a different browser.
            </Typography>
          </li>
        </ul>
      </>
    );
  } else if (cameraError) {
    return (
      <>
        <Typography component="h4">Camera Error</Typography>
        <Typography component="p">
          There is an error related to your camera. You may need to try some of the solutions below,
          refresh the page and join call again.
        </Typography>
        <ul>
          <li>
            <Typography component="p">
              Check your system settings for an available camera,
            </Typography>
          </li>
          <li>
            <Typography component="p">
              If you have an external camera, make sure that it is plugged in and connected
              securely,
            </Typography>
          </li>
          <li>
            <Typography component="p">
              Check for any websites or applications that may use your camera and disable it,
            </Typography>
          </li>
          <li>
            <Typography component="p">
              If you're still experiencing camera problems after trying the above, log into the
              portal from a different browser.
            </Typography>
          </li>
        </ul>
      </>
    );
  } else if (speakerError) {
    return (
      <>
        <Typography component="h4">Speaker Error</Typography>
        <Typography component="p">
          There is an error related to your speaker. You may need to try some of the solutions
          below, refresh the page and join the call again.
        </Typography>
        <ul>
          <li>
            <Typography component="p">
              Check your system settings for an available speaker,
            </Typography>
          </li>
          <li>
            <Typography component="p">
              If you have an external speaker, make sure that it is plugged in and connected
              securely,
            </Typography>
          </li>
          <li>
            <Typography component="p">
              Check for any websites or applications that may use your speaker and disable it,
            </Typography>
          </li>
          <li>
            <Typography component="p">
              If you're still experiencing speaker problems after trying the above, log into the
              portal from a different browser.
            </Typography>
          </li>
        </ul>
      </>
    );
  } else if (
    (downloadTestEnded && averageDownloadMbps <= 5) ||
    (uploadTestEnded && averageUploadMbps <= 2)
  ) {
    return (
      <>
        <Typography component="h4">Bad Internet Connection</Typography>
        <Typography component="p">
          There is an error related to internet connection. You may need to try some of the
          solutions below, refresh the page and join the call again.
        </Typography>
        <ul>
          <li>
            <Typography component="p">Close browser tabs you don't use,</Typography>
          </li>
          <li>
            <Typography component="p">Close other apps running on your computer.</Typography>
          </li>
        </ul>
      </>
    );
  } else {
    return null;
  }
};
