import {
  getCustomersPage,
  getCustomersPerPage,
  getCustomersSort,
  getCustomersSortDirection,
  getCustomersSearch,
  getInsuranceCustomersType,
  getInsuranceCustomersState,
  getCustomerBillableItemsPage,
  getCustomerBillableItemsRowsPerPage,
} from "../selectors";
import { select } from "redux-saga/effects";
import SecureRequest from "../utils/securerequest";
import SecureRequestFetch from "../utils/securerequestfetch";
import { buildUrl } from "utils/buildUrl";
/**
 * @typedef {import("api/typesDef").AssessmentClient} AssessmentClient
 * @typedef {import("api/typesDef").Customer} Customer
 * @typedef {import("api/typesDef").FinancialGuarantor} FinancialGuarantor
 * @typedef {import("api/typesDef").IntakeFlow} IntakeFlow
 */

export function* getCustomerList() {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const page = yield select(getCustomersPage);
  const size = yield select(getCustomersPerPage);
  const sort = yield select(getCustomersSort);
  const direction = yield select(getCustomersSortDirection);
  const search = yield select(getCustomersSearch);

  const params = {
    page,
    size,
    sort,
    direction,
    search,
  };

  return yield Request.get(`/customers`, { params });
}

export function* getAllActiveClients(clinicianUserId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const params = {
    clinicianUserId,
  };
  return yield Request.get(`/v2/customers/all`, { params });
}

export function* getInsuranceCustomerList() {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const page = yield select(getCustomersPage);
  const size = yield select(getCustomersPerPage);
  const sort = yield select(getCustomersSort);
  const direction = yield select(getCustomersSortDirection);
  const search = yield select(getCustomersSearch);
  const type = yield select(getInsuranceCustomersType);
  const state = yield select(getInsuranceCustomersState);

  const params = {
    page,
    size,
    sort,
    direction,
    search,
    type,
    state,
  };

  return yield Request.get(`/customers/insurance-customers`, { params });
}

export function* getDeactivatedCustomerList() {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const page = yield select(getCustomersPage);
  const size = yield select(getCustomersPerPage);
  const sort = yield select(getCustomersSort);
  const direction = yield select(getCustomersSortDirection);

  const params = {
    page,
    size,
    sort,
    direction,
  };

  return yield Request.get("/customers/deactivated", { params });
}

export function* getCustomer(clientId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return yield Request.get(`/customers/${clientId}`);
}

export function* getCustomerChildren(clientId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return yield Request.get(`/customers/${clientId}/children`);
}

export function* getCustomerClinicians(clientId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return yield Request.get(`/v2/customers/${clientId}/clinicians`);
}

export function* getCustomerTreatmentPlans(id) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return yield Request.get(`/treatment-plans/${id}/plans`);
}

export function* uploadTreatmentPlan(plan) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return yield Request.post(`/treatment-plans/${plan.clientId}/upload`, plan);
}

export function* updateTreatmentPlan(plan) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return yield Request.put(`/treatment-plans/${plan.id}/replace`, plan);
}

export function* getCustomerDocuments(clientId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return yield Request.get(`/documents/${clientId}/list`);
}

export function* getDocumentTypes() {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return yield Request.get(`/documents/types`);
}

export function* getDocumentMatrix(userData) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return yield Request.get(`/documents/${userData.clientId}/${userData.userStatus}`);
}

export function* approveCustomerDocument(docReqUserId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return yield Request.put(`/documents/${docReqUserId}/accept`, {});
}

export function* getCompletedDocumentStatus(customer) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return yield Request.get(`/documents/completed/${customer.clientId}/${customer.status}`);
}

export function* uploadCustomerDocument(documentObj) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return yield Request.post(`/documents/${documentObj.clientId}/upload`, documentObj);
}

export function* updateChild(payload) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return yield Request.post("/customers/child", payload);
}

export function* switchClinician(id, clinicianId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const params = {
    clinicianId,
  };
  return yield Request.put(`/customers/${id}/switch-clinician`, params);
}

export function* assignClinician(clientId, clinicianUserId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const params = {
    clinicianUserId,
  };
  return yield Request.post(`/v2/customers/${clientId}/assign-clinician`, params);
}

export function* assignSecondaryClinician(clientId, clinicianUserId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const params = {
    clinicianUserId,
  };
  return yield Request.post(`/v2/customers/${clientId}/assign-secondary-clinician`, params);
}

export function* removeSecondaryClinician(clientId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.put(`/v2/customers/${clientId}/remove-secondary-clinician`, {});
}

export function* markEligible(clientId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const params = {};
  return yield Request.put(`/customers/${clientId}/mark-eligible`, params);
}

export function* markApproved(clientId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const params = {};
  return yield Request.put(`/customers/${clientId}/mark-approved`, params);
}

export function* updateUserStatus(status) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.put(`/roadmap/${status.clientId}/change-status`, status);
}

export function* resendLoginEmail(userId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.post(`/roadmap/${userId}/resend-login`, {});
}

export function* toggleCustomerActivation(info) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const { id, reason, dropReason, churnReason, disabledDate, note } = info;
  return yield Request.put(`/users/${id}/toggle-activation`, {
    reason,
    dropReason,
    churnReason,
    disabledDate,
    note,
  });
}

export function* deactivateClient(info) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.put(`/users/${info.clientId}/deactivate-client`, info);
}

export function* getCustomerPhysicians(clientId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/v2/customers/${clientId}/physicians`);
}

export function* createInsuranceCustomer(params) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.post(`/v2/customers/save-and-invite`, params);
}

export function* saveCustomerForLater(payload) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.post(`/v2/customers/save-for-later`, payload);
}

export function* createUnsignedRequest(payload) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.post(`/document-requests/${payload.clientId}/create-unsigned`, payload);
}

export async function createUnsignedRequestAsync(payload) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return Request.post(`/document-requests/${payload.clientId}/create-unsigned`, payload);
}

export function* getAllDocumentRequests(clientId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/document-requests/${clientId}/all`);
}

export function* getSingleCustomerSearch(customer) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  let query = "";
  if (customer.disabled) {
    query += "&disabled=true";
  }
  if (customer.includeTeam) {
    query += "&includeTeam=true";
  }
  if (customer.allUsers) {
    query += "&allusers=true";
  }
  if (customer.clients) {
    query += "&clients=true";
  }
  if (customer.parents) {
    query += "&parents=true";
  }
  if (customer.clinicians) {
    query += "&clinicians=true";
  }
  if (customer.deactivated) {
    query += "&deactivated=true";
  }
  return yield Request.get(`/v3/clients/search?search=${customer.search}${query}`);
}

export async function getSingleCustomerSearchAsync(customer) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  let query = "";
  if (customer.disabled ?? false) {
    query += "&disabled=true";
  }
  if (customer.includeTeam) {
    query += "&includeTeam=true";
  }
  if (customer.allUsers) {
    query += "&allusers=true";
  }
  if (customer.clients) {
    query += "&clients=true";
  }
  if (customer.parents) {
    query += "&parents=true";
  }
  if (customer.clinicians) {
    query += "&clinicians=true";
  }
  if (customer.deactivated) {
    query += "&deactivated=true";
  }
  return Request.get(`/v3/clients/search?search=${customer.search}${query}`);
}

export function* getCustomerDeactivationReasons() {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get("customers/deactivation/reasons");
}

export function* getCustomerDepartureOptions() {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get("customers/deactivation/departureOptions");
}

export function* getCustomerStatusUpdates(clientId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/v2/customers/${clientId}/status-updates`);
}

export function* getCustomerPastClinicians(clientId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/customers/${clientId}/past-clinicians`);
}

export function* getSavedForLaterCustomers() {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  const page = yield select(getCustomersPage);
  const size = yield select(getCustomersPerPage);
  const sort = yield select(getCustomersSort);
  const direction = yield select(getCustomersSortDirection);

  const params = {
    page,
    size,
    sort,
    direction,
  };

  return yield Request.get(`/customers/saved-for-later`, { params });
}

export function* getSavedCustomerDetails(userId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/v2/customers/${userId}/saved-for-later`);
}

export function* updatePatientInfo(payload) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.put(`/v2/customers/${payload.clientId}/patient`, payload);
}

export function* updateGuardianInfo(payload) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.put(`/v2/customers/${payload.userId}/guardian`, payload);
}

export function* saveCustomerPhysician(payload) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.post(`/v2/customers/${payload.clientId}/physician`, payload);
}

export function* updateCustomerPhysician(payload) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.put(`/v2/customers/${payload.physicianId}/physician`, payload);
}

export function* getCustomerBillableItems(clientId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  const page = yield select(getCustomerBillableItemsPage);
  const size = yield select(getCustomerBillableItemsRowsPerPage);

  return yield Request.get(`/v2/billable-time/${clientId}/items?page=${page}&size=${size}`);
}

export function* switchPrimaryAccountHolder(params) {
  //NEW ENDPOINT
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.put(`/v2/customers/${params.clientId}/primary-account`, params);
}

export function* updateCustomerEmail(user) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return yield Request.put(`/v2/customers/${user.userId}/email`, user);
}

// generator function not working with mutateAsync, but don't want to affect sagas so I'm duplicating as async function
export async function updateCustomerEmailAsync(user) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return await Request.put(`/v2/customers/${user.userId}/email`, user);
}

export function* updateCustomerTimezone(user) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return yield Request.put(`/v2/customers/${user.clientId}/timezone`, user);
}

export function* getCustomerPastCalls(payload) {
  const { includeMissedCalls, monthLimit, clientId } = payload;
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  let query = "";
  if (monthLimit && !includeMissedCalls) {
    query = "?monthlimit=true";
  } else if (!monthLimit && includeMissedCalls) {
    query = `?missedcalls=${includeMissedCalls}`;
  } else if (monthLimit && includeMissedCalls) {
    query = `?missedcalls=${includeMissedCalls}&monthlimit=true`;
  }
  return yield Request.get(`/v2/customers/${clientId}/past-calls${query}`);
}

export function* enableClientStaticPage(clientId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.post(`/v2/client/${clientId}/enable-static`, {});
}

export function* disableClientStaticPage(clientId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.post(`/v2/client/${clientId}/disable-static`, {});
}

export function* getClientTaskList(clientId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/v2/customers/${clientId}/task-list`);
}

export function* markClientTaskComplete({ clientId, clientTaskId }) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.put(`/v2/customers/${clientId}/complete-task`, {
    clientTaskId,
  });
}

export function* getClientSessionStatistics(clientId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/v2/customers/${clientId}/session-statistics`);
}

export function* getClientCriticalNeeds() {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/v2/customers/critical-needs`);
}

export function* snoozeClientTask(params) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.put(`/v2/customers/${params.taskId}/snooze`, params);
}

export function* updateLinkPhysician(data) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.post(`/v2/customers/${data.clientId}/link-physician`, data.payload);
}

export async function updateLinkPhysicianAsync(payload) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return Request.post(`/v2/customers/${payload.clientId}/link-single-physician`, payload);
}
export async function unlinkPhysicianAsync(payload) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return Request.post(`/v2/customers/${payload.clientId}/unlink-physician`, payload);
}
export async function updateLinkedPhysician(payload) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return Request.put(`/v2/customers/${payload.clientId}/update-physician`, payload);
}

export function* getAllPhysicians() {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/v2/customers/physicians`);
}

export const searchClients = async (userId, params) => {
  const url = `/v3/clients/${userId}/search-clients`;
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return await Request.get(url, { params });
};

/**
 * Retrieves a client's availability.
 *
 * @param {Object} params - The parameters for the request.
 * @param {Object} params.basicInfo - Object containing client details.
 * @param {string} params.basicInfo.clientId - The unique identifier for the client.
 *                                             This parameter is used to construct the endpoint URL.
 * @returns {Promise<Object>} A promise that resolves with the availability data for the client.
 */
export const getClientAvailability = async (params) => {
  const { clientId } = params;
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.get(`/v3/clients/${clientId}/availability`, { params });
};

/**
 * Updates one or more service availabilities for a client.
 *
 * @param {Object} params - The parameters for the update request.
 * @param {Object} params.clientInfo - Object containing client details.
 * @param {string} params.clientInfo.details.id - Client's unique identifier.
 * @param {Array<{availability: Object, serviceType: string}>} params.newAvailableHours.services -
 *                          Array of objects detailing service availability updates.
 * @returns {Promise<Object>} Promise resolving with the operation response.
 */
export const updateClientAvailability = async (params) => {
  const { clientInfo } = params;
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.post(`/v3/clients/${clientInfo.details.client_id}/availability`, params);
};

export const getClientTherapyHours = async (params) => {
  const { clientId } = params;
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.get(`/v3/clients/${clientId}/therapy-hours`);
};

export const updateClientTherapyHours = async (params) => {
  const { clientId } = params;
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.post(`/v3/clients/${clientId}/therapy-hours`, params);
};

export const getClientDocuments = async (clientId, params) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.get(`/v2/client/${clientId}/documents`, { params });
};

export const verifyClientDocument = async (documentId) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.put(`/documents/${documentId}/verify`, {});
};

export const unverifyClientDocument = async (documentId) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.put(`/documents/${documentId}/unverify`, {});
};

export const updateDocument = async (clientId, documentData) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return Request.put(`/v2/client/${clientId}/documents`, documentData);
};

export const getClientFormsForSignature = async (clientId, query) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return Request.get(buildUrl(`/v2/forms/${clientId}/requires-signature`, query));
};

export const toggleFormSkippedStatus = async (formId, skipped = true) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return Request.put(`/v2/forms/${formId}/skip`, { skipped });
};

export const getClientLogs = async (clientId, params) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return Request.get(`/v2/client/${clientId}/logs`, { params });
};

export async function getCustomerSecureDocument(
  documentId,
  role = false,
  documentType = "document"
) {
  const sr = new SecureRequestFetch();
  try {
    const response = await sr.get(`/documents/secure-document/${documentId}`, {
      docType: "base64",
      role: role ? "clinician" : "customer",
      documentType: documentType,
    });
    const binaryString = window.atob(response.data.file);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    const blob = new Blob([bytes], { type: response.data.contentType });

    const fileURL = URL.createObjectURL(blob);
    return {
      document: fileURL,
      filename: response.data.filename,
      contentType: response.data.contentType,
    };
  } catch (error) {
    console.error("Failed to fetch document:", error);
    throw new Error("Failed to fetch document");
  }
}

export const generateECommConsentForm = async (clientId) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const response = await Request.post(`/v2/forms/${clientId}/generate-e-comm-consent`, {});
  return response.data;
};

export const getClientVideoCallLogs = async (clientId, videoCallId) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return Request.get(`/v2/client/${clientId}/video-call-logs/${videoCallId}`);
};

export const getInsuranceVOB = async (clientId, params) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return Request.get(`/v2/client/${clientId}/vob`, { params });
};

export const createInsuranceVOB = async (clientId, payload) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return Request.post(`/v2/client/${clientId}/vob`, payload);
};

export const updateInsuranceVOB = async (clientId, payload) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return Request.put(`/v2/client/${clientId}/vob`, payload);
};

/**
 * Retrieves clinicians for a specific client.
 *
 * @param {string} clientId - The unique identifier for the client.
 * @param {Object} [params=null] - Optional query parameters for filtering the clinicians.
 * @param {string} [params.providerType] - An optional filter for the type of provider.
 * @returns {Promise<Object>} A promise that resolves with the list of clinicians associated with the client.
 */
export const getClientClinicians = async (clientId, params = null) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return Request.get(`/v2/client/${clientId}/clinicians`, { params });
};

export const getAssessementsBatch = async (params) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.get(`/v2/client/assessment-batch`, { params });
};

export const uploadAssessmentBatch = async (payload) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.post(`/v2/client/assessment-batch`, payload);
};

/**
 * The getClients function is used to fetch a list of clients from the `/v3/clients` endpoint.
 * @param {Object} params - The `params` object in the `getClients` function likely contains any query
 * parameters that need to be included in the request to fetch clients. These parameters could include
 * filters, sorting options, pagination details, or any other information needed to customize the
 * request to the `/v3/clients` endpoint.
 * @returns {Promise<Customer[]>} A promise that resolves with an array of
 * `Customer` objects.
 */
export const getClients = async (params) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.get("/v3/clients", { params });
};

export const getClientById = async (clientId) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.get(`/v3/clients/${clientId}`);
};

export const updateClient = async (payload) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const { clientId, data } = payload;
  return await Request.patch(`/v3/clients/${clientId}/update`, data);
};

export const updateAssessmentBatch = async (payload) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.put(`/v2/client/assessment-batch`, payload);
};

/**
 * The function `getIntakeFlowData` retrieves intake flow progress for a specific client.
 * @param {string} clientId - The unique identifier for the client.
 * @returns {Promise<{data: IntakeFlow}>} A Promise that resolves to the result of
 * making a GET request to the endpoint `/v2/customers/[clientID]/intake-flow`
 */
export const getIntakeFlowData = async (clientId) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.get(`/v2/customers/${clientId}/intake-flow`, {});
};

export const saveIntakeProgress = async (clientId, payload) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.post(`/v2/customers/${clientId}/intake-flow`, payload);
};

export const startIntakeFlow = async (clientId) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.put(`/v2/customers/${clientId}/start-intake-flow`, {});
};

/**
 * The function `completeIntakeFlow` sends a PUT request to complete an intake flow for a specific client
 * ID.
 * @param {string} clientId - The unique identifier for the client.
 * @returns {Promise} A promise that resolves with the response from the PUT request to
 * `/v2/customers/[clientId]/complete-intake-flow` .
 */
export const completeIntakeFlow = async (clientId) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.put(`/v2/customers/${clientId}/complete-intake-flow`, {});
};

/**
 * Retrieves Assessments for a specific client.
 *
 * @param {String|Number} clientId - The unique identifier for the client..
 * @returns {Promise<{data: AssessmentClient[]}>} A promise that resolves with the list of assessments associated with the client.
 */
export const getClientAssessments = async (clientId) => {
  if (!clientId) {
    throw new Error("Client ID is required.");
  }

  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return Request.get(`/v2/client/${clientId}/assessments`);
};

/**
 * The function `getFinancialGuarantor` makes an asynchronous request to retrieve financial guarantor
 * information for a specific client ID.
 * @param {number|string} clientId - The `clientId` parameter is the unique identifier of the client for whom you want
 * to retrieve the financial guarantor information.
 * @returns {Promise} The `getFinancialGuarantor` function is returning a Promise that resolves to the result of
 * making a GET request to the endpoint `/v3/clients/[cliendId]/financial-guarantor` for the specified
 * `clientId`.
 */
export const getFinancialGuarantor = async (clientId) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return Request.get(`/v3/clients/${clientId}/financial-guarantor`);
};

/**
 * This function creates the financial guarantor information for a specific client.
 * @param {FinancialGuarantor} payload - The `payload` parameter is an object containing data needed to create the financial
 * guarantor for a specific client.
 * @returns {Promise} A Promise that resolves to the result of a POST request made to the endpoint:
 * `/v3/clients/[cliendId]/financial-guarantor`.
 */
export const createFinancialGuarantor = async (payload) => {
  const { clientId } = payload;
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return Request.post(`/v3/clients/${clientId}/financial-guarantor`, payload);
};

/**
 * This function updates the financial guarantor information for a specific client.
 * @param {FinancialGuarantor} payload - The `payload` parameter is an object containing data needed to update the financial
 * guarantor for a specific client.
 * @returns {Promise} A Promise that resolves to the result of a PATCH request made to the endpoint:
 * `/v3/clients/[cliendId]/financial-guarantor`.
 */
export const updateFinancialGuarantor = async (payload) => {
  const { clientId } = payload;
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return Request.patch(`/v3/clients/${clientId}/financial-guarantor`, payload);
};
