import React from "react";
import { TextField } from "@mui/material";
import RequestedEdits from "../Tools/RequestedEdits";
import TimesheetSessionDetails from "./TimesheetSessionDetails";
import CptHeader from "../NoteDetails/CptHeader";

export default function EditTimesheetNote(props) {
  const {
    classes,
    requestedEdits,
    needsEdits,
    noteRef,
    locked,
    note,
    onChange,
    noteError,
    setNoteError,
  } = props;

  return (
    <div className={classes.billableTimeContent}>
      <TimesheetSessionDetails {...props} />
      {needsEdits && <RequestedEdits requestedEdits={requestedEdits} />}
      <CptHeader {...props} />
      <div className={classes.noteDetail} ref={noteRef}>
        <TextField
          fullWidth
          multiline={true}
          variant="outlined"
          color="primary"
          minRows={5}
          value={note}
          onChange={onChange("note")}
          disabled={locked}
          size="medium"
          error={!!noteError}
          placeholder={"Describe the Activity"}
          onFocus={() => setNoteError(false)}
          helperText={noteError ? "Enter description of the activity" : null}
        />
      </div>
    </div>
  );
}
