const createPubSub = () => {
  const subscribers = {};
  return {
    subscribe: (event, callback) => {
      subscribers[event] = subscribers[event] || [];
      subscribers[event].push(callback);
      return () => (subscribers[event] = subscribers[event].filter((cb) => cb !== callback));
    },
    publish: (event, data) => {
      (subscribers[event] || []).forEach((callback) => callback(data));
    },
  };
};

export const datePickerPubSub = createPubSub();
