import React from "react";
import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { DropzoneDialogBase } from "mui-file-dropzone";
import ANFormView from "../../../elements/Forms/ANFormView";
import SectionBox from "../../../elements/SectionBox";
import { Avatar, Stack } from "@mui/material";
import { UploadSimple } from "@phosphor-icons/react";
import { useTheme } from "@mui/styles";
import styles from "../styles";

const Bio = (props) => {
  const {
    photoUrl,
    editMode,
    userPermissions,
    basicInfo,
    appBio,
    onChange,
    fileObjects,
    uploadProfilePhotoOpen,
    openFileDialog,
    closeFileDialog,
    addFileObjects,
    persistFileObjects,
  } = props;
  const theme = useTheme();

  return (
    <SectionBox title="Bio" description={"Account Biography"} columnDirection>
      {editMode ? (
        <>
          <Grid container spacing={7}>
            <Grid item xs={12} sm={4} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
              <Stack gap={theme.spacing(5)}>
                <Avatar src={photoUrl} />
                {userPermissions?.upload_clinician_profile_picture && (
                  <Button
                    startIcon={<UploadSimple />}
                    color={"secondary"}
                    size="medium"
                    onClick={openFileDialog("uploadProfilePhotoOpen")}
                    style={{ margin: "10px 0", width: 212 }}
                  >
                    Upload Profile Picture
                  </Button>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
              <TextField
                id="appBio"
                label="Bio"
                onChange={onChange("appBio")}
                value={appBio}
                rows={7}
                InputLabelProps={{
                  shrink: true,
                  style: { pointerEvents: "auto" },
                }}
                multiline
                fullWidth
              />
            </Grid>
          </Grid>
          <DropzoneDialogBase
            dialogTitle="Upload Profile Photo"
            acceptedFiles={["image/*"]}
            fileObjects={fileObjects}
            filesLimit={1}
            cancelButtonText={"Cancel"}
            submitButtonText={"OK"}
            maxFileSize={15728640}
            open={uploadProfilePhotoOpen}
            onDelete={(deleteFileObj) => {
              addFileObjects([]);
            }}
            onAdd={(newFileObjs) => {
              addFileObjects(newFileObjs);
            }}
            onClose={closeFileDialog("uploadProfilePhotoOpen")}
            onSave={() => {
              persistFileObjects("profilePhotoImage", "uploadProfilePhotoOpen");
            }}
            showPreviews={true}
            showFileNamesInPreview={true}
            dialogProps={{
              style: { zIndex: 40003 },
            }}
          />
        </>
      ) : (
        <ANFormView
          data={{
            appBio: basicInfo?.bio || "",
            photoUrl: basicInfo?.image_url || "",
          }}
          config={[
            { label: "", dataKey: "photoUrl", cols: 12, type: "avatar" },
            {
              label: "Bio",
              dataKey: "appBio",
              cols: 12,
            },
          ]}
        ></ANFormView>
      )}
    </SectionBox>
  );
};

export default withStyles(styles)(Bio);
