import React from "react";
import { Typography, TextField, Tooltip, Zoom } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Signature from "./Signature";
import TechIssues from "./TechIssues";
import { Info } from "@phosphor-icons/react";
import CaregiverSignature from "./CaregiverSignature";

const EditNoteDetails = (props) => {
  const {
    classes,
    note,
    responseNote,
    responseError,
    interventionError,
    noteTipOpen,
    responseTipOpen,
    templateText,
    techIssues,
    setError,
    toggleOpenTip,
    closeTip,
    noteRef,
    responseNoteRef,
    onChange,
    locked,
  } = props;

  return (
    <div className={classes.noteDetailsContainer}>
      {techIssues ? (
        <TechIssues {...props} />
      ) : (
        <>
          {templateText.noteBlockTitle && (
            <div className={classes.noteDetail} ref={noteRef}>
              <div className={classes.noteDetailTitleContainer}>
                <div className={classes.noteDetailTitle}>
                  <Typography component="p" variant="subtitle1">
                    {templateText.noteBlockTitle}
                  </Typography>
                  {templateText.noteBlockExample && !locked && (
                    <Tooltip
                      disableHoverListener
                      disableFocusListener
                      disableTouchListener
                      arrow={false}
                      open={noteTipOpen}
                      TransitionComponent={Zoom}
                      placement="top-end"
                      PopperProps={{
                        disablePortal: true,
                        style: {
                          zIndex: 3005,
                        },
                      }}
                      classes={{ tooltip: classes.tooltip }}
                      title={templateText.noteBlockExample}
                    >
                      <Info
                        size={16}
                        className={classes.tipIcon}
                        onClick={toggleOpenTip("noteTipOpen")}
                        color="#31333A"
                      />
                    </Tooltip>
                  )}
                </div>
                <Typography component="p" variant="body2Secondary">
                  {templateText.noteBlockInstruction}
                </Typography>
              </div>
              <TextField
                fullWidth
                multiline={true}
                variant="outlined"
                color="primary"
                minRows={5}
                value={note}
                onChange={onChange("note")}
                disabled={locked}
                size="medium"
                error={!!interventionError}
                placeholder={templateText.noteBlockPlaceholder}
                onFocus={() => setError("interventionError", false)}
                onBlur={() => {
                  closeTip("noteTipOpen");
                  note.length === 0 || !note
                    ? setError("interventionError", true)
                    : setError("interventionError", false);
                }}
                helperText={
                  interventionError
                    ? "Type the Intervention inside the box for this session note"
                    : null
                }
              />
            </div>
          )}
          {templateText.responseBlockTitle && (
            <div className={classes.noteDetail} ref={responseNoteRef}>
              <div className={classes.noteDetailTitleContainer}>
                <div className={classes.noteDetailTitle}>
                  <Typography component="p" variant="subtitle1">
                    {templateText.responseBlockTitle}
                  </Typography>
                  {templateText.responseBlockExample && !locked && (
                    <Tooltip
                      disableHoverListener
                      disableFocusListener
                      disableTouchListener
                      arrow={false}
                      open={responseTipOpen}
                      TransitionComponent={Zoom}
                      placement="top-end"
                      PopperProps={{
                        disablePortal: true,
                        style: {
                          zIndex: 3005,
                        },
                      }}
                      classes={{ tooltip: classes.tooltip }}
                      title={templateText.responseBlockExample}
                    >
                      <Info
                        size={16}
                        onClick={toggleOpenTip("responseTipOpen")}
                        color="#31333A"
                        className={classes.tipIcon}
                      />
                    </Tooltip>
                  )}
                </div>
                <Typography component="p" variant="body2Secondary">
                  {templateText.responseBlockInstruction}
                </Typography>
              </div>
              <TextField
                fullWidth
                multiline={true}
                minRows={5}
                size="medium"
                variant="outlined"
                color="primary"
                value={responseNote}
                error={!!responseError}
                onChange={onChange("responseNote")}
                placeholder={templateText.responseBlockPlaceholder}
                disabled={locked}
                onFocus={() => setError("responseError", false)}
                onBlur={() => {
                  closeTip("responseTipOpen");
                  responseNote.length === 0 || !responseNote
                    ? setError("responseError", true)
                    : setError("responseError", false);
                }}
                helperText={
                  responseError ? "Type the Response inside the box for this session note" : null
                }
              />
            </div>
          )}
          <Signature {...props} />
          <CaregiverSignature
            billableItem={props.billableItem}
            classes={classes}
            startDate={props.startDate}
            endDate={props.endDate}
            timezone={props.customerDetails?.timezone}
          />
        </>
      )}
    </div>
  );
};

export default EditNoteDetails;
