/**
 * Builds a URL with the given base URL and query parameters, if any.
 *
 * @param {string} baseUrl - The base URL.
 * @param {Object} query - The query parameters.
 * @returns {string} The built URL.
 * @example
 * buildUrl('https://example.com', { foo: 'bar' }); --> 'https://example.com?foo=bar'
 */
export const buildUrl = (baseUrl, query) => {
  if (!query || Object.keys(query).length === 0) return baseUrl;

  const queryString = Object.keys(query)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`)
    .join("&");

  return `${baseUrl}?${queryString}`;
};
