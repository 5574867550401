import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { formattedLogDate } from "./Utils";
import { useTheme } from "@mui/styles";

export default function LogElement({
  showDivider,
  icon,
  logDate,
  logTitle,
  logNote,
  logAuthor,
  endTitleElement,
  showLogWithNoTitle = false,
}) {
  const theme = useTheme();
  const { palette } = theme;

  if (!logTitle && !showLogWithNoTitle) return null;
  return (
    <Box>
      <Stack direction="row" alignItems="center" gap={theme.spacing(4)}>
        <Stack>{icon}</Stack>
        <Stack>
          <Typography variant="body2" color="text.secondary">
            <span style={{ fontWeight: 500, color: palette.text.primary }}>{logAuthor}</span>{" "}
            {logTitle}- {formattedLogDate(logDate)}
            {endTitleElement}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {logNote}
          </Typography>
        </Stack>
      </Stack>
      {showDivider && (
        <Box // custom divider
          sx={{
            height: "24px",
            borderLeft: `1px solid ${palette.secondary.outlinedBorder}`,
            marginLeft: theme.spacing(3),
          }}
        />
      )}
    </Box>
  );
}
