import { createReducer } from "redux-create-reducer";
import actions from "../actions";
import cookie from "react-cookies";

const initialState = {
  loaded: false,
  username: null,
  email: null,
  id: null,
  first_name: null,
  last_name: null,
  customer_id: null,
  conversation_url: null,
  chat_id: null,
  referral_code: null,
  dialogOpen: false,
  alpha: false,
  beta: false,
  subscribed: false,
  expired: false,
  completed_questionnaire: false,
  created_date: null,
  paymentDialogOpen: false,
  isAccountHolder: false,
  video: "",
  can_message: false,
  hours_remaining: -1,
  clinician: {},
  clinicianLoading: false,
  specialties: [],
  specialtiesLoading: false,
  displayClinician: {},
  displaySpecialties: [],
  clinicianSelected: false,
  clinicianReferred: false,
  answers: [],
  saving: false,
  referralEmailSent: false,
  current_question_id: null,
  completed: false,
  dashboard: null,
  dashboardLoading: false,
  referralBannerOpen: false,
  cancelLoading: false,
  cancelSuccess: null,
  cancelReasons: null,
  abaInterestOpen: false,
  treatmentPlan: null,
  insuranceDashboard: null,
  updatingFullCustomer: false,
  fullCustomerUpdated: null,
  roadmap: { steps: [] },
  roadmapStepButtons: [],
  invitationGUID: null,
  eligibilityFields: null,
  eligibility_incomplete: false,
  documentRequests: [],
  isHuman: false,
  recaptchaLoading: false,
  agreeToPolicyLoading: false,
  phi_release_accepted: false,
  physicians: [],
  sendingDocumentRequestSignatureLoading: false,
  sendingDocumentRequestSignatureSuccess: false,
  sendingDocumentRequestSignatureError: false,
  form: {},
  formSaving: false,
  formSuccess: false,
  updatingClientInfo: false,
  updatingClientInfoSuccess: false,
  updatingClientInfoFail: false,
  calendarCalls: [],
  calendarCallsLoading: false,
  dashboardNotifications: [],
  dashboardNotificationsLoading: false,
  staticPageDataLoaded: false,
  staticPageDataError: false,
  staticPageDataErrorMessage: null,
  staticPageVideoKeyLoaded: false,
  staticPageVideoKey: null,
  pendingSignature: [],
};

const reducers = {
  [actions.CLEAR_BASIC_DETAILS](state, { payload }) {
    return { ...initialState };
  },
  [actions.GET_PARENT_INFO](state) {
    return {
      ...state,
      pendingSignature: [],
      loaded: false,
    };
  },
  [actions.SET_PARENT_PENDING_SIGNATURES](state, { payload }) {
    return {
      ...state,
      pendingSignature: payload,
    };
  },
  [actions.GET_PARENT_INFO_SUCCESS](state, { payload }) {
    const expires = new Date();
    expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 14);
    cookie.save("userId", payload.user.id, { path: "/", expires });
    let pendingSignature = payload.user?.pendingSignature;
    if (
      pendingSignature?.length > state.pendingSignature.length &&
      state.pendingSignature.length > 0
    ) {
      pendingSignature = state.pendingSignature;
    }
    return {
      ...state,
      ...payload.user,
      pendingSignature,
      loaded: true,
      saving: false,
    };
  },
  [actions.SHOW_WELCOME](state) {
    const dialogSeen = cookie.load(`communitySeen_${state.id}`);
    if (!dialogSeen) {
      return {
        ...state,
        dialogOpen: true,
      };
    }
    return state;
  },
  [actions.HIDE_WELCOME](state) {
    const expires = new Date();
    expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 14);

    cookie.save(`communitySeen_${state.id}`, true, { path: "/", expires });
    return {
      ...state,
      dialogOpen: false,
    };
  },
  [actions.SHOW_PAYMENT](state) {
    return {
      ...state,
      paymentDialogOpen: true,
    };
  },
  [actions.HIDE_PAYMENT](state) {
    return {
      ...state,
      paymentDialogOpen: false,
    };
  },
  [actions.GET_USER_CLINICIAN](state) {
    return {
      ...state,
      clinicianLoading: true,
    };
  },
  [actions.GET_USER_CLINICIAN_SUCCESS](state, { payload }) {
    return {
      ...state,
      clinicianLoading: false,
      clinician: payload.clinician || {},
    };
  },
  [actions.GET_CLINICIAN_BIO](state) {
    return {
      ...state,
      clinicianLoading: true,
    };
  },
  [actions.GET_CLINICIAN_BIO_SUCCESS](state, { payload }) {
    return {
      ...state,
      clinicianLoading: false,
      displayClinician: payload.clinician,
      displaySpecialties: payload.specialties,
    };
  },
  [actions.GET_CLINICIAN_SPECIALTIES](state) {
    return {
      ...state,
      specialtiesLoading: true,
    };
  },
  [actions.GET_CLINICIAN_SPECIALTIES_SUCCESS](state, { payload }) {
    return {
      ...state,
      specialtiesLoading: false,
      specialties: payload.specialties,
    };
  },
  [actions.CLINICIAN_SELECTED](state) {
    return {
      ...state,
      clinicianSelected: true,
    };
  },
  [actions.CLINICIAN_REFERRED](state) {
    return {
      ...state,
      clinicianSelected: true,
      clinicianReferred: true,
    };
  },
  [actions.CLEAR_CLINICIAN](state) {
    return {
      ...state,
      clinician: {},
      specialties: [],
    };
  },
  [actions.CLEAR_DISPLAY_CLINICIAN](state) {
    return {
      ...state,
      displayClinician: {},
      displaySpecialties: [],
      clinicianSelected: false,
    };
  },
  [actions.SAVE_PROFILE](state) {
    return {
      ...state,
      saving: true,
    };
  },
  [actions.CLEAR_QUESTIONNAIRE_ANSWERS](state) {
    return {
      ...state,
      answers: [],
    };
  },
  [actions.GET_DASHBOARD](state) {
    return {
      ...state,
      dashboardLoading: true,
    };
  },
  [actions.GET_DASHBOARD_SUCCESS](state, { payload }) {
    return {
      ...state,
      dashboard: payload,
      dashboardLoading: false,
    };
  },
  [actions.CLEAR_DASHBOARD](state) {
    return {
      ...state,
      dashboard: null,
    };
  },
  [actions.SHOW_REFERRAL_BANNER](state) {
    const dialogSeen = cookie.load(`referralBannerSeen_${state.id}`);
    if (!dialogSeen) {
      return {
        ...state,
        referralBannerOpen: true,
      };
    }
    return state;
  },
  [actions.HIDE_REFERRAL_BANNER](state) {
    const expires = new Date();
    expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 14);

    cookie.save(`referralBannerSeen_${state.id}`, true, { path: "/", expires });
    return {
      ...state,
      referralBannerOpen: false,
    };
  },
  [actions.CANCEL_SUBSCRIPTION](state) {
    return {
      ...state,
      cancelLoading: true,
    };
  },
  [actions.CANCEL_SUBSCRIPTION_SUCCESS](state) {
    return {
      ...state,
      cancelLoading: false,
      cancelSuccess: true,
    };
  },
  [actions.CANCEL_SUBSCRIPTION_FAIL](state) {
    return {
      ...state,
      cancelLoading: false,
      cancelSuccess: false,
    };
  },
  [actions.GET_CANCEL_REASONS](state) {
    return {
      ...state,
      cancelReasons: null,
    };
  },
  [actions.GET_CANCEL_REASONS_SUCCESS](state, { payload }) {
    return {
      ...state,
      cancelReasons: payload,
    };
  },
  [actions.AGREE_TO_POLICIES](state) {
    return {
      ...state,
      agreeToPolicyLoading: true,
    };
  },
  [actions.AGREE_TO_POLICIES_SUCCESS](state) {
    return {
      ...state,
      policies_read_and_accepted: true,
      agreeToPolicyLoading: false,
    };
  },
  [actions.AGREE_TO_PHI_RELEASE](state) {
    return {
      ...state,
      phi_release_accepted: true,
    };
  },
  [actions.SEND_DOCUMENT_REQUEST_SIGNATURE](state) {
    return {
      ...state,
      sendingDocumentRequestSignatureLoading: true,
    };
  },
  [actions.SEND_DOCUMENT_REQUEST_SIGNATURE_SUCCESS](state) {
    return {
      ...state,
      sendingDocumentRequestSignatureSuccess: true,
      sendingDocumentRequestSignatureLoading: false,
    };
  },
  [actions.SEND_DOCUMENT_REQUEST_SIGNATURE_FAIL](state) {
    return {
      ...state,
      sendingDocumentRequestSignatureError: true,
      sendingDocumentRequestSignatureLoading: false,
    };
  },
  [actions.SHOW_ABA_INTEREST_DIALOG](state) {
    const dialogSeen = cookie.load(`abaInterestSeen_${state.id}`);
    if (!dialogSeen) {
      return {
        ...state,
        abaInterestOpen: true,
      };
    }
    return state;
  },
  [actions.HIDE_ABA_INTEREST_DIALOG](state) {
    const expires = new Date();
    expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 14);

    cookie.save(`abaInterestSeen_${state.id}`, true, { path: "/", expires });
    return {
      ...state,
      abaInterestOpen: false,
    };
  },
  [actions.GET_VIDEO_CALL_INFO_SUCCESS](state, { payload }) {
    return {
      ...state,
      id: payload.user_id,
    };
  },
  [actions.GET_ACTIVE_TREATMENT_PLAN_SUCCESS](state, { payload }) {
    return {
      ...state,
      treatmentPlan: payload,
    };
  },
  [actions.GET_INSURANCE_ROADMAP_SUCCESS](state, { payload }) {
    let stepButtons = [];
    if (payload && payload.currentStepIndex && payload.steps) {
      const { currentStepIndex, steps } = payload;
      stepButtons = steps[currentStepIndex].stepButtons ? steps[currentStepIndex].stepButtons : [];
    }
    return {
      ...state,
      roadmap: payload,
      roadmapStepButtons: stepButtons,
      phi_release_accepted: payload.phi_release_accepted,
    };
  },
  [actions.GET_ELIGIBILITY_FIELDS_SUCCESS](state, { payload }) {
    return {
      ...state,
      eligibilityFields: payload,
    };
  },
  [actions.GET_UNSIGNED_DOCUMENT_REQUESTS_SUCCESS](state, { payload }) {
    return {
      ...state,
      documentRequests: payload,
    };
  },
  [actions.CHECK_RECAPTCHA_VERIFICATION](state, { payload }) {
    return {
      ...state,
      recaptchaLoading: true,
      isHuman: false,
    };
  },
  [actions.CHECK_RECAPTCHA_VERIFICATION_SUCCESS](state, { payload }) {
    return {
      ...state,
      isHuman: true,
      recaptchaLoading: false,
    };
  },
  [actions.CHECK_RECAPTCHA_VERIFICATION_FAIL](state, { payload }) {
    return {
      ...state,
      isHuman: false,
      recaptchaLoading: false,
    };
  },
  [actions.GET_CUSTOMER_PHYSICIANS_SUCCESS](state, { payload }) {
    return {
      ...state,
      physicians: payload,
    };
  },
  [actions.SAVE_SIGNED_CUSTOMER_FORM](state) {
    return {
      ...state,
      formSaving: true,
      formSuccess: false,
    };
  },
  [actions.SAVE_SIGNED_CUSTOMER_FORM_SUCCESS](state) {
    return {
      ...state,
      form: {},
      formSaving: false,
      formSuccess: true,
    };
  },
  [actions.SAVE_SIGNED_CUSTOMER_FORM_FAIL](state) {
    return {
      ...state,
      formSaving: false,
      formSuccess: false,
    };
  },
  [actions.GET_CUSTOMER_SIGNED_FORM](state) {
    return {
      ...state,
      form: {},
      formSuccess: false,
      formSaving: false,
    };
  },
  [actions.GET_CUSTOMER_SIGNED_FORM_SUCCESS](state, { payload }) {
    return {
      ...state,
      form: payload,
    };
  },
  [actions.CLEAR_CUSTOMER_FORM](state) {
    return {
      ...state,
      form: {},
    };
  },
  [actions.UPDATE_CLIENT_INFO](state) {
    return {
      ...state,
      updatingClientInfo: true,
    };
  },
  [actions.UPDATE_CLIENT_INFO_SUCCESS](state) {
    return {
      ...state,
      updatingClientInfo: false,
      updatingClientInfoSuccess: true,
      updatingClientInfoFail: false,
    };
  },
  [actions.UPDATE_CLIENT_INFO_FAIL](state) {
    return {
      ...state,
      updatingClientInfo: false,
      updatingClientInfoSuccess: false,
      updatingClientInfoFail: true,
    };
  },
  [actions.UPDATE_CLIENT_INFO_RESET_STATE](state) {
    return {
      ...state,
      updatingClientInfo: false,
      updatingClientInfoSuccess: false,
      updatingClientInfoFail: false,
    };
  },
  [actions.UPDATE_CAREGIVER_INFO](state) {
    return {
      ...state,
      updatingClientInfo: true,
    };
  },
  [actions.UPDATE_CAREGIVER_INFO_SUCCESS](state) {
    return {
      ...state,
      updatingClientInfo: false,
      updatingClientInfoSuccess: true,
      updatingClientInfoFail: false,
    };
  },
  [actions.UPDATE_CAREGIVER_INFO_FAIL](state) {
    return {
      ...state,
      updatingClientInfo: false,
      updatingClientInfoSuccess: false,
      updatingClientInfoFail: true,
    };
  },
  [actions.GET_CUSTOMER_CALLS_BY_MONTH](state) {
    return {
      ...state,
      calendarCallsLoading: true,
    };
  },
  [actions.GET_CUSTOMER_CALLS_BY_MONTH_SUCCESS](state, { payload }) {
    return {
      ...state,
      calendarCallsLoading: false,
      calendarCalls: payload,
    };
  },
  [actions.GET_CUSTOMER_CALLS_BY_MONTH_FAIL](state) {
    return {
      ...state,
      calendarCallsLoading: false,
    };
  },
  [actions.GET_CUSTOMER_DASHBOARD_NOTIFICATIONS](state) {
    return {
      ...state,
      dashboardNotificationsLoading: true,
    };
  },
  [actions.GET_CUSTOMER_DASHBOARD_NOTIFICATIONS_SUCCESS](state, { payload }) {
    return {
      ...state,
      dashboardNotificationsLoading: false,
      dashboardNotifications: payload,
    };
  },
  [actions.GET_CUSTOMER_DASHBOARD_NOTIFICATIONS_FAIL](state) {
    return {
      ...state,
      dashboardNotificationsLoading: false,
    };
  },
  [actions.GET_CLIENT_STATIC_PAGE](state, { payload }) {
    return {
      ...state,
      staticPageDataLoaded: false,
      staticPageVideoKeyLoaded: false,
      staticPageVideoKey: null,
      staticPageDataError: false,
      staticPageDataErrorMessage: null,
    };
  },
  [actions.GET_CLIENT_STATIC_PAGE_SUCCESS](state, { payload }) {
    return {
      ...state,
      staticPageVideoKey: payload,
      staticPageVideoKeyLoaded: true,
    };
  },
  [actions.GET_CLIENT_STATIC_PAGE_FAIL](state, { payload }) {
    return {
      ...state,
      staticPageDataLoaded: true,
      staticPageDataError: true,
      staticPageDataErrorMessage: payload,
    };
  },
  [actions.SET_STATIC_PAGE_STATE_SUCCESS](state) {
    return {
      ...state,
      staticPageDataLoaded: true,
    };
  },
};

export default createReducer(initialState, reducers);
