import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import { Box, IconButton, Toolbar, Typography } from "@mui/material";
import { DownloadSimple, Printer } from "@phosphor-icons/react";
import styles from "./styles";
import withStyles from "@mui/styles/withStyles";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { useParams, useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { useGetSecureDocument } from "../../hooks/useGetSecureDocument";
import SubHeading from "../../elements/SubHeading";
import actions from "../../actions";
import { useDispatch } from "react-redux";
import { DOCUMENTS_VIEW_SUPPORTED_FILES } from "../../constants";
import DynamicPageError from "../DynamicPageError";
import { useGetUserPermissions } from "hooks/api/useGetUserPermissions";

function DocumentViewer(props) {
  const { classes } = props;
  const { userId, documentId } = useParams();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const role = query.get("role");
  const documentType = query.get("documentType");

  const dispatch = useDispatch();
  const [unsupportedFile, setUnsupportedFile] = useState(true);
  const [canDownload, setCanDownload] = useState(false);

  const {
    data: response,
    isLoading: isDocumentLoading,
    isError: isDocumentError,
  } = useGetSecureDocument({ documentId, role, documentType });
  const { userPermissions, permissionsLoading } = useGetUserPermissions(userId);

  useEffect(() => {
    dispatch(actions.checkAuthState());
  }, []);

  useEffect(() => {
    dispatch(
      actions.setPageDetails({
        pageName: "Document",
      })
    );
  }, []);

  useEffect(() => {
    if (userPermissions && !isDocumentLoading && !isDocumentError) {
      if (DOCUMENTS_VIEW_SUPPORTED_FILES.includes(response?.contentType)) {
        setUnsupportedFile(false);
      } else {
        if (userPermissions?.download_client_documents) {
          handleDownload();
          window.close();
        }
      }
    }
  }, [userPermissions, isDocumentLoading, isDocumentError]);

  useEffect(() => {
    if (userPermissions) {
      setCanDownload(!unsupportedFile && userPermissions?.download_client_documents);
    }
  }, [unsupportedFile, userPermissions]);

  const isError = isDocumentError;

  function handleDownload() {
    const link = document.createElement("a");
    link.href = response?.document;
    link.download = response?.filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  function handlePrint() {
    const iframe = document.getElementById("hiddenPrinter");
    iframe.contentWindow.focus();
    iframe.contentWindow.print();
  }

  const DodViewerBar = () => {
    return (
      <AppBar position="sticky" className={isError ? classes.appBar : classes.appBarFixed}>
        <Toolbar className={classes.toolbar}>
          <Typography variant="body2" className={classes.fileName}>
            {isError ? "" : response?.filename || ""}
          </Typography>
          {canDownload && (
            <Box className={classes.controls}>
              <IconButton
                variant="text"
                color="secondary"
                fontSize="small"
                size="small"
                onClick={() => handlePrint()}
              >
                <Printer weight="duotone" />
              </IconButton>
              <IconButton
                variant="text"
                color="secondary"
                fontSize="small"
                size="small"
                onClick={() => handleDownload()}
              >
                <DownloadSimple weight="duotone" />
              </IconButton>
            </Box>
          )}
        </Toolbar>
      </AppBar>
    );
  };

  const HeaderBar = (state) => {
    if (!state.currentDocument || state.config?.header?.disableFileName) {
      return null;
    }
    return <DodViewerBar></DodViewerBar>;
  };

  if (permissionsLoading || isDocumentLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh" gap={2}>
        <CircularProgress size={30} color="primary" />
        <SubHeading>Loading</SubHeading>
      </Box>
    );
  }

  if (isError || unsupportedFile) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap={2}
        className={classes.contentError}
      >
        <DodViewerBar />
        <DynamicPageError
          title="Document"
          message="Sorry, this file can't be viewed. Please contact customer support for help."
        />
      </Box>
    );
  }
  return (
    <Box display="flex" justifyContent="center" className={classes.content}>
      <iframe id="hiddenPrinter" style={{ display: "none" }} src={response?.document}></iframe>
      <DocViewer
        style={{ height: "100%" }}
        pluginRenderers={DocViewerRenderers}
        prefetchMethod="GET"
        config={{
          header: {
            overrideComponent: HeaderBar,
          },
          csvDelimiter: ",",
          pdfZoom: {
            defaultZoom: 0.6,
            zoomJump: 0.5,
          },
        }}
        theme={{
          disableThemeScrollbar: false,
          primary: "#4C505C",
          secondary: "#4C505C",
          tertiary: "#4C505C",
        }}
        documents={[
          {
            uri: response?.document,
          },
        ]}
      />
    </Box>
  );
}

export default withStyles(styles)(DocumentViewer);
