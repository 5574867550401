import React, { Component } from "react";
import { withStyles } from "@mui/styles";
import { STATES } from "../../constants";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../../actions";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import FormLabel from "@mui/material/FormLabel";
import {
  getCreatePhysicianId,
  getCreatePhysicianLoading,
  getReferringPhysicians,
} from "../../selectors";
import styles from "./styles/createCustomerStyles";
import AddIcon from "@mui/icons-material/Add";
import LoadingButton from "@mui/lab/LoadingButton";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";

const mapStateToProps = (state) => ({
  createPhysicianLoading: getCreatePhysicianLoading(state),
  createPhysicianId: getCreatePhysicianId(state),
  referringPhysicians: getReferringPhysicians(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createReferringPhysician: actions.createReferringPhysician,
    },
    dispatch
  );

const initialState = () => {
  return {
    createPhysicianOpen: false,
    physicianFirstNameError: false,
    physicianLastNameError: false,
    physicianFirstName: "",
    physicianLastName: "",
    physicianBusinessName: "",
    physicianEmail: "",
    physicianFaxNumber: "",
    physicianStreet1: "",
    physicianStreet2: "",
    physicianCity: "",
    physicianState: "",
    physicianZip: "",
    physicianPhone: "",
    physicianSpecialty: "",
    physicianAcceptsInsurance: false,
    physicianWebsite: "",
    emailError: false,
    phoneError: false,
    faxError: false,
    zipError: false,
    websiteError: false,
    physicianId: "",
    physicianType: "",
    physicianIdError: false,
    physicianTypeError: false,
    createPhysicianError: false,
    showPhysicianTypeHelper: false,
  };
};

class AddPhysicianDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState(),
    };
  }

  componentDidMount() {
    if (this.props.physicianSelected?.id) {
      this.setState({
        physicianId: this.props.physicianSelected.id,
        physicianType: this.props.physicianSelected.type,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      (!prevProps.physicianSelected ||
        prevProps.physicianSelected?.id !== this.props.physicianSelected?.id) &&
      this.props.physicianSelected?.id
    ) {
      this.setState({
        physicianId: this.props.physicianSelected.id,
        physicianType: this.props.physicianSelected.type,
      });
    }
    if (
      (prevProps.physicianSelected?.id && !this.props.physicianSelected?.id) ||
      (prevProps.physicianDrawerOpen && !this.props.physicianDrawerOpen)
    ) {
      this.setState({ ...initialState() });
      this.props.setPhysicianSelected(null);
    }
    if (prevProps.createPhysicianLoading && !this.props.createPhysicianLoading) {
      if (this.props.createPhysicianId) {
        this.setState({
          physicianId: this.props.createPhysicianId,
          createPhysicianOpen: false,
          showPhysicianTypeHelper: true,
        });
      } else {
        this.setState({
          createPhysicianError: true,
        });
      }
    }
  }

  onChange = (name) => (e) => {
    const { checked, type, value } = e.target;
    const val = type === "checkbox" ? checked : value;
    this.setState({ [name]: val });
  };

  handlePhoneNumberChange = (name) => (e) => {
    const { value } = e.target;
    let numberValue = value.replace(/[^0-9]/g, "");
    let phone = numberValue.replace(
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/g,
      "($1)-$2-$3"
    );
    this.setState({ [name]: phone });
  };

  setError = (name, error) => {
    this.setState({ [name]: error });
  };

  handleEmailChange = (name) => (e) => {
    const { value } = e.target;
    this.setState({ [name]: value.replace(" ", "") });
  };

  handleZipChange = (name) => (e) => {
    const { value } = e.target;
    this.setState({ [name]: value.replace(/[^0-9]/g, "") });
  };

  validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  validateURL = (url) => {
    const re =
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&=]*)/g;
    return re.test(String(url).toLowerCase());
  };

  onSaveSelectedPhysician = () => {
    const { physicianId, physicianType } = this.state;
    this.setState(() => {
      if (!physicianId || !physicianType) {
        return;
      } else {
        const physician = this.props.referringPhysicians.find(
          (doc) => doc.id == this.state.physicianId
        );
        this.props.onSelectPhysician(
          {
            ...physician,
            type: this.state.physicianType,
          },
          this.props.physicianSelected?.id ? this.props.physicianSelected.id : null
        );
        this.setState({ ...initialState() });
      }
    });
  };

  onSaveNewPhysician = () => {
    const { physicianFirstName, physicianLastName } = this.state;
    this.setState(
      {
        physicianFirstNameError: !physicianFirstName,
        physicianLastNameError: !physicianLastName,
      },
      () => {
        if (this.state.physicianFirstNameError || this.state.physicianLastNameError) {
          return;
        } else {
          this.setState({
            createPhysicianError: false,
          });
          const {
            physicianFirstName: firstName,
            physicianLastName: lastName,
            physicianBusinessName: businessName,
            physicianEmail: email,
            physicianFaxNumber: faxNumber,
            physicianStreet1: physicalAddress,
            physicianStreet2: npiNumber,
            physicianCity: city,
            physicianState: state,
            physicianZip: zipCode,
            physicianPhone: phone,
            physicianSpecialty: specialty,
            physicianAcceptsInsurance: acceptsInsurance,
            physicianWebsite: website,
          } = this.state;
          this.props.createReferringPhysician({
            firstName,
            lastName,
            businessName,
            email,
            faxNumber,
            physicalAddress,
            npiNumber,
            city,
            state,
            zipCode,
            phone,
            specialty,
            acceptsInsurance,
            website,
          });
        }
      }
    );
  };

  render() {
    const {
      createPhysicianOpen,
      physicianFirstName,
      physicianLastName,
      physicianBusinessName,
      physicianEmail,
      physicianFaxNumber,
      physicianStreet1,
      physicianStreet2,
      physicianCity,
      physicianState,
      physicianZip,
      physicianPhone,
      physicianSpecialty,
      physicianAcceptsInsurance,
      physicianWebsite,
      physicianType,
      physicianId,
      phoneError,
      faxError,
      emailError,
      zipError,
      websiteError,
      createPhysicianError,
      physicianFirstNameError,
      physicianLastNameError,
    } = this.state;
    const {
      classes,
      physicianDrawerOpen,
      onTogglePhysicianDrawer,
      referringPhysicians,
      createPhysicianLoading,
    } = this.props;

    return (
      <Drawer
        className={classes.drawer}
        variant="temporary"
        anchor="right"
        open={physicianDrawerOpen}
        onClose={onTogglePhysicianDrawer}
        classes={{
          paper: createPhysicianOpen
            ? classes.physicianDrawerPaperCreate
            : classes.physicianDrawerPaper,
        }}
      >
        {createPhysicianOpen ? (
          <>
            <div className={classes.tabBarContainer}>
              <Typography component="p" className={classes.drawerHeaderText}>
                Physician's Basic Information
              </Typography>

              <IconButton
                onClick={onTogglePhysicianDrawer}
                className={classes.closeDrawerIcon}
                size="small"
              >
                <CloseIcon />
              </IconButton>
              <div />
            </div>
            <div className={classes.physicianDrawerContainer}>
              <Grid
                container
                item
                xs={12}
                sm={12}
                spacing={3}
                // className={classes.profileInfoContainer}
              >
                <Grid item xs={12} sm={6}>
                  <FormControl margin="none" fullWidth>
                    <FormLabel
                      component="legend"
                      style={{
                        marginBottom: 5,
                        fontSize: 14,
                        fontWeight: "500",
                        color: "#545A72",
                        lineHeight: 1.4,
                      }}
                    >
                      Physician's First Name*
                    </FormLabel>
                    <TextField
                      id="physicianFirstName"
                      name="physicianFirstName"
                      autoComplete="off"
                      size="small"
                      value={physicianFirstName}
                      onChange={this.onChange("physicianFirstName")}
                      placeholder="first name"
                      onFocus={() => this.setError("physicianFirstNameError", false)}
                      onBlur={() =>
                        physicianFirstName
                          ? this.setError("physicianFirstNameError", false)
                          : this.setError("physicianFirstNameError", true)
                      }
                      helperText={physicianFirstNameError ? "Please enter first name" : null}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl margin="none" fullWidth>
                    <FormLabel
                      component="legend"
                      style={{
                        marginBottom: 5,
                        fontSize: 14,
                        fontWeight: "500",
                        color: "#545A72",
                        lineHeight: 1.4,
                      }}
                    >
                      Physician's Last Name*
                    </FormLabel>
                    <TextField
                      id="physicianLastName"
                      name="physicianLastName"
                      autoComplete="off"
                      size="small"
                      value={physicianLastName}
                      onChange={this.onChange("physicianLastName")}
                      placeholder="last name"
                      onFocus={() => this.setError("physicianLastNameError", false)}
                      onBlur={() =>
                        physicianLastName
                          ? this.setError("physicianLastNameError", false)
                          : this.setError("physicianLastNameError", true)
                      }
                      helperText={physicianLastNameError ? "Please enter last name" : null}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl margin="none" fullWidth>
                    <FormLabel
                      component="legend"
                      style={{
                        marginBottom: 5,
                        fontSize: 14,
                        fontWeight: "500",
                        color: "#545A72",
                        lineHeight: 1.4,
                      }}
                    >
                      Business Name
                    </FormLabel>
                    <TextField
                      id="physicianBusinessName"
                      name="physicianBusinessName"
                      autoComplete="off"
                      size="small"
                      value={physicianBusinessName}
                      onChange={this.onChange("physicianBusinessName")}
                      placeholder="practice name"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl margin="none" fullWidth>
                    <FormLabel
                      component="legend"
                      style={{
                        marginBottom: 5,
                        fontSize: 14,
                        fontWeight: "500",
                        color: "#545A72",
                        lineHeight: 1.4,
                      }}
                    >
                      Speciality (optional)
                    </FormLabel>
                    <TextField
                      id="physicianSpecialty"
                      name="physicianSpecialty"
                      autoComplete="off"
                      size="small"
                      value={physicianSpecialty}
                      onChange={this.onChange("physicianSpecialty")}
                      placeholder="specialty"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    classes={{ label: classes.checkboxLabel }}
                    control={
                      <Checkbox
                        size="small"
                        checked={physicianAcceptsInsurance}
                        onClick={this.onChange("physicianAcceptsInsurance")}
                      />
                    }
                    label={"Accepts Insurance"}
                  />
                </Grid>
              </Grid>
            </div>

            <div className={classes.tabBarContainer} style={{ marginTop: 10 }}>
              <Typography component="p" className={classes.drawerHeaderText}>
                Physician's Contact Information
              </Typography>
              <div />
            </div>
            <div className={classes.physicianDrawerContainer}>
              <Grid
                container
                item
                xs={12}
                sm={12}
                spacing={3}
                // className={classes.profileInfoContainer}
              >
                <Grid item xs={12} sm={6}>
                  <FormControl margin="none" fullWidth>
                    <FormLabel
                      component="legend"
                      style={{
                        marginBottom: 5,
                        fontSize: 14,
                        fontWeight: "500",
                        color: "#545A72",
                        lineHeight: 1.4,
                      }}
                    >
                      Phone Number
                    </FormLabel>
                    <TextField
                      id="physicianPhone"
                      name="physicianPhone"
                      autoComplete="off"
                      value={physicianPhone}
                      onChange={this.handlePhoneNumberChange("physicianPhone")}
                      variant="outlined"
                      size="small"
                      placeholder="(000)-000-0000"
                      inputProps={{ maxLength: 10 }}
                      onFocus={() => this.setError("phoneError", false)}
                      onBlur={() =>
                        !physicianPhone || physicianPhone.trim().match(/\d/g).length === 10
                          ? this.setError("phoneError", false)
                          : this.setError("phoneError", true)
                      }
                      helperText={phoneError ? "Invalid Phone Number" : null}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl margin="none" fullWidth>
                    <FormLabel
                      component="legend"
                      style={{
                        marginBottom: 5,
                        fontSize: 14,
                        fontWeight: "500",
                        color: "#545A72",
                        lineHeight: 1.4,
                      }}
                    >
                      Fax Number
                    </FormLabel>
                    <TextField
                      id="physicianFaxNumber"
                      name="physicianFaxNumber"
                      autoComplete="off"
                      value={physicianFaxNumber}
                      onChange={this.handlePhoneNumberChange("physicianFaxNumber")}
                      variant="outlined"
                      size="small"
                      placeholder="(000)-000-0000"
                      inputProps={{ maxLength: 10 }}
                      onFocus={() => this.setError("faxError", false)}
                      onBlur={() =>
                        !physicianFaxNumber || physicianFaxNumber.trim().match(/\d/g).length === 10
                          ? this.setError("faxError", false)
                          : this.setError("faxError", true)
                      }
                      helperText={faxError ? "Invalid Phone Number" : null}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl margin="none" fullWidth>
                    <FormLabel
                      component="legend"
                      style={{
                        marginBottom: 5,
                        fontSize: 14,
                        fontWeight: "500",
                        color: "#545A72",
                        lineHeight: 1.4,
                      }}
                    >
                      Email
                    </FormLabel>
                    <TextField
                      id="physicianEmail"
                      name="physicianEmail"
                      autoComplete="off"
                      value={physicianEmail}
                      onChange={this.handleEmailChange("physicianEmail")}
                      variant="outlined"
                      size="small"
                      placeholder="example@gmail.com"
                      onFocus={() => this.setError("emailError", false)}
                      onBlur={() =>
                        this.validateEmail(physicianEmail) || !physicianEmail
                          ? this.setError("emailError", false)
                          : this.setError("emailError", true)
                      }
                      helperText={emailError ? "Invalid Email" : null}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl margin="none" fullWidth>
                    <FormLabel
                      component="legend"
                      style={{
                        marginBottom: 5,
                        fontSize: 14,
                        fontWeight: "500",
                        color: "#545A72",
                        lineHeight: 1.4,
                      }}
                    >
                      Website
                    </FormLabel>
                    <TextField
                      id="physicianWebsite"
                      name="physicianWebsite"
                      autoComplete="off"
                      value={physicianWebsite}
                      onChange={this.onChange("physicianWebsite")}
                      variant="outlined"
                      size="small"
                      placeholder="www.example.com"
                      onFocus={() => this.setError("websiteError", false)}
                      onBlur={() =>
                        this.validateURL(physicianWebsite) || !physicianWebsite
                          ? this.setError("websiteError", false)
                          : this.setError("websiteError", true)
                      }
                      helperText={websiteError ? "Invalid URL" : null}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl margin="none" fullWidth>
                    <FormLabel
                      component="legend"
                      style={{
                        marginBottom: 5,
                        fontSize: 14,
                        fontWeight: "500",
                        color: "#545A72",
                        lineHeight: 1.4,
                      }}
                    >
                      Adress Line 1
                    </FormLabel>
                    <TextField
                      id="physicianStreet1"
                      name="physicianStreet1"
                      autoComplete="off"
                      size="small"
                      value={physicianStreet1}
                      onChange={this.onChange("physicianStreet1")}
                      placeholder="123 Street St"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl margin="none" fullWidth>
                    <FormLabel
                      component="legend"
                      style={{
                        marginBottom: 5,
                        fontSize: 14,
                        fontWeight: "500",
                        color: "#545A72",
                        lineHeight: 1.4,
                      }}
                    >
                      Address Line 2
                    </FormLabel>
                    <TextField
                      id="physicianStreet2"
                      name="physicianStreet2"
                      autoComplete="off"
                      size="small"
                      value={physicianStreet2}
                      onChange={this.onChange("physicianStreet2")}
                      placeholder="optional"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl margin="none">
                    <FormLabel
                      component="legend"
                      style={{
                        marginBottom: 5,
                        fontSize: 14,
                        fontWeight: "500",
                        color: "#545A72",
                        lineHeight: 1.4,
                      }}
                    >
                      City
                    </FormLabel>
                    <TextField
                      id="physicianCity"
                      name="physicianCity"
                      autoComplete="off"
                      size="small"
                      value={physicianCity}
                      onChange={this.onChange("physicianCity")}
                      placeholder="city"
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={12} md={5}>
                  <FormControl margin="none">
                    <FormLabel
                      component="legend"
                      style={{
                        marginBottom: 5,
                        fontSize: 14,
                        fontWeight: 500,
                        color: "#545A72",
                        lineHeight: 1.4,
                      }}
                    >
                      State
                    </FormLabel>

                    <TextField
                      id="physicianState"
                      name="physicianState"
                      autoComplete="off"
                      value={physicianState}
                      onChange={this.onChange("physicianState")}
                      size="small"
                      select
                    >
                      <MenuItem disabled value={""}>
                        state
                      </MenuItem>
                      {Object.keys(STATES).map((key) => {
                        return (
                          <MenuItem key={key} value={key}>
                            {STATES[key]}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid item sm={12} md={7}>
                  <FormControl margin="none">
                    <FormLabel
                      component="legend"
                      style={{
                        marginBottom: 5,
                        fontSize: 14,
                        fontWeight: 500,
                        color: "#545A72",
                        lineHeight: 1.4,
                      }}
                    >
                      ZIP Code
                    </FormLabel>
                    <TextField
                      id="physicianZip"
                      name="physicianZip"
                      autoComplete="off"
                      value={physicianZip}
                      onChange={this.handleZipChange("physicianZip")}
                      inputProps={{ pattern: "[0-9]*", maxLength: 5 }}
                      placeholder="00000"
                      size="small"
                      onFocus={() => this.setError("zipError", false)}
                      onBlur={() =>
                        physicianZip.trim().length === 5 || !physicianZip
                          ? this.setError("zipError", false)
                          : this.setError("zipError", true)
                      }
                      helperText={zipError ? "Invalid ZIP Code" : null}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <div className={classes.drawerButtonContainer}>
                {createPhysicianError && (
                  <Typography className={classes.errorMessage}>
                    An error has occurred. Please try again.
                  </Typography>
                )}
              </div>
              <div className={classes.drawerButtonContainer} style={{ marginTop: 20 }}>
                <Button
                  variant="text"
                  color="default"
                  onClick={() => this.setState({ createPhysicianOpen: false })}
                >
                  Cancel
                </Button>
                <LoadingButton
                  color="secondary"
                  onClick={this.onSaveNewPhysician}
                  loading={createPhysicianLoading}
                  style={{ marginLeft: 30 }}
                >
                  Save
                </LoadingButton>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={classes.tabBarContainer}>
              <Typography component="p" className={classes.drawerHeaderText}>
                Associate an existing physician with this client
              </Typography>

              <IconButton
                onClick={onTogglePhysicianDrawer}
                className={classes.closeDrawerIcon}
                size="small"
              >
                <CloseIcon />
              </IconButton>
              <div />
            </div>

            <div className={classes.physicianDrawerContainer}>
              <Grid container columns={1}>
                <Grid item sm={12} md={12} marginBottom={8}>
                  <FormControl margin="none" fullWidth>
                    <FormLabel
                      component="legend"
                      style={{
                        marginBottom: 5,
                        fontSize: 14,
                        fontWeight: 500,
                        color: "#545A72",
                        lineHeight: 1.4,
                      }}
                    >
                      Physicians Added
                    </FormLabel>

                    <TextField
                      id="physicianId"
                      name="physicianId"
                      autoComplete="off"
                      value={physicianId}
                      onChange={this.onChange("physicianId")}
                      size="small"
                      select
                    >
                      <MenuItem disabled value={""}>
                        select from existing
                      </MenuItem>
                      {referringPhysicians?.map((physician) => {
                        return (
                          <MenuItem key={physician.id} value={physician.id}>
                            {`${physician.first_name} ${physician.last_name}`}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </FormControl>
                </Grid>

                <Grid item sm={12} md={12}>
                  <FormControl margin="none" fullWidth>
                    <FormLabel
                      component="legend"
                      style={{
                        marginBottom: 5,
                        fontSize: 14,
                        fontWeight: 500,
                        color: "#545A72",
                        lineHeight: 1.4,
                      }}
                    >
                      Physician Type
                    </FormLabel>

                    <TextField
                      id="physicianType"
                      name="physicianType"
                      autoComplete="off"
                      value={physicianType}
                      onChange={this.onChange("physicianType")}
                      size="small"
                      select
                    >
                      <MenuItem disabled value={""}>
                        select type
                      </MenuItem>
                      <MenuItem value={"Primary Care"}>Primary Care</MenuItem>
                      <MenuItem value={"Diagnosing"}>Diagnosing</MenuItem>
                    </TextField>
                  </FormControl>
                </Grid>
              </Grid>
            </div>

            <div className={classes.drawerButtonContainer} style={{ marginTop: 20 }}>
              <Button variant="text" onClick={onTogglePhysicianDrawer}>
                Cancel
              </Button>

              <Button
                color="secondary"
                onClick={this.onSaveSelectedPhysician}
                style={{ marginLeft: 40 }}
                disabled={!physicianId || !physicianType}
              >
                Save
              </Button>
            </div>

            <div className={classes.physicianDrawerContainer} />
          </>
        )}
      </Drawer>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddPhysicianDrawer));
