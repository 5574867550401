import React from "react";

const Divider = (props) => {
  const { styles = {} } = props;
  const dividerStyles = {
    width: "100%",
    height: "1px",
    backgroundColor: "#CDD3DA",
  };
  return <div style={{ ...dividerStyles, ...styles }} />;
};

export default Divider;
