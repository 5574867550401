const ACTIONS = {
  INVITE_CLINICIAN: "INVITE_CLINICIAN",
  INVITE_CLINICIAN_SUCCESS: "INVITE_CLINICIAN_SUCCESS",
  INVITE_CLINICIAN_FAIL: "INVITE_CLINICIAN_FAIL",
  CLEAR_INVITE_CLINICIAN: "CLEAR_INVITE_CLINICIAN",

  GET_CLINICIAN_STATUS: "GET_CLINICIAN_STATUS",
  GET_CLINICIAN_STATUS_SUCCESS: "GET_CLINICIAN_STATUS_SUCCESS",
  GET_CLINICIAN_STATUS_FAIL: "GET_CLINICIAN_STATUS_FAIL",

  GET_CLINICIAN_BASIC_INFO: "GET_CLINICIAN_BASIC_INFO",
  GET_CLINICIAN_BASIC_INFO_SUCCESS: "GET_CLINICIAN_BASIC_INFO_SUCCESS",
  SAVE_CLINICIAN_BASIC_INFO: "SAVE_CLINICIAN_BASIC_INFO",
  SAVE_CLINICIAN_BASIC_INFO_SUCCESS: "SAVE_CLINICIAN_BASIC_INFO_SUCCESS",
  SAVE_CLINICIAN_BASIC_INFO_FAIL: "SAVE_CLINICIAN_BASIC_INFO_FAIL",
  SAVE_CLINICIAN_DRIVERS_LICENSE: "SAVE_CLINICIAN_DRIVERS_LICENSE",
  SAVE_CLINICIAN_DRIVERS_LICENSE_SUCCESS: "SAVE_CLINICIAN_DRIVERS_LICENSE_SUCCESS",
  SAVE_CLINICIAN_DRIVERS_LICENSE_FAIL: "SAVE_CLINICIAN_DRIVERS_LICENSE_FAIL",
  CLEAR_CLINICIAN_DRIVERS_LICENSE_INFO: "CLEAR_CLINICIAN_DRIVERS_LICENSE_INFO",

  GET_CLINICIAN_CREDENTIALS: "GET_CLINICIAN_CREDENTIALS",
  GET_CLINICIAN_CREDENTIALS_SUCCESS: "GET_CLINICIAN_CREDENTIALS_SUCCESS",
  SAVE_CLINICIAN_CREDENTIALS: "SAVE_CLINICIAN_CREDENTIALS",
  SAVE_CLINICIAN_CREDENTIALS_SUCCESS: "SAVE_CLINICIAN_CREDENTIALS_SUCCESS",
  SAVE_CLINICIAN_CREDENTIALS_FAIL: "SAVE_CLINICIAN_CREDENTIALS_FAIL",

  GET_CLINICIAN_STATE_LICENSE: "GET_CLINICIAN_STATE_LICENSE",
  GET_CLINICIAN_STATE_LICENSE_SUCCESS: "GET_CLINICIAN_STATE_LICENSE_SUCCESS",
  GET_CLINICIAN_STATE_LICENSE_BY_ID: "GET_CLINICIAN_STATE_LICENSE_BY_ID",
  GET_CLINICIAN_STATE_LICENSE_BY_ID_SUCCESS: "GET_CLINICIAN_STATE_LICENSE_BY_ID_SUCCESS",
  SAVE_CLINICIAN_STATE_LICENSE: "SAVE_CLINICIAN_STATE_LICENSE",
  SAVE_CLINICIAN_STATE_LICENSE_SUCCESS: "SAVE_CLINICIAN_STATE_LICENSE_SUCCESS",
  SAVE_CLINICIAN_STATE_LICENSE_FAIL: "SAVE_CLINICIAN_STATE_LICENSE_FAIL",
  UPDATE_CLINICIAN_STATE_LICENSE: "UPDATE_CLINICIAN_STATE_LICENSE",
  UPDATE_CLINICIAN_STATE_LICENSE_SUCCESS: "UPDATE_CLINICIAN_STATE_LICENSE_SUCCESS",
  UPDATE_CLINICIAN_STATE_LICENSE_FAIL: "UPDATE_CLINICIAN_STATE_LICENSE_FAIL",
  DELETE_CLINICIAN_STATE_LICENSE: "DELETE_CLINICIAN_STATE_LICENSE",
  DELETE_CLINICIAN_STATE_LICENSE_SUCCESS: "DELETE_CLINICIAN_STATE_LICENSE_SUCCESS",
  DELETE_CLINICIAN_STATE_LICENSE_FAIL: "DELETE_CLINICIAN_STATE_LICENSE_FAIL",

  SAVE_CLINICIAN_CERTIFICATION: "SAVE_CLINICIAN_CERTIFICATION",
  SAVE_CLINICIAN_CERTIFICATION_SUCCESS: "SAVE_CLINICIAN_CERTIFICATION_SUCCESS",
  SAVE_CLINICIAN_CERTIFICATION_FAIL: "SAVE_CLINICIAN_CERTIFICATION_FAIL",

  GET_CLINICIAN_INSURANCES: "GET_CLINICIAN_INSURANCES",
  GET_CLINICIAN_INSURANCES_SUCCESS: "GET_CLINICIAN_INSURANCES_SUCCESS",
  GET_CLINICIAN_INSURANCES_FAIL: "GET_CLINICIAN_INSURANCES_FAIL",
  UPDATE_CLINICIAN_INSURANCE: "UPDATE_CLINICIAN_INSURANCE",
  UPDATE_CLINICIAN_INSURANCE_SUCCESS: "UPDATE_CLINICIAN_INSURANCE_SUCCESS",
  UPDATE_CLINICIAN_INSURANCE_FAIL: "UPDATE_CLINICIAN_INSURANCE_FAIL",
  ADD_CLINICIAN_INSURANCE: "ADD_CLINICIAN_INSURANCE",
  ADD_CLINICIAN_INSURANCE_SUCCESS: "ADD_CLINICIAN_INSURANCE_SUCCESS",
  ADD_CLINICIAN_INSURANCE_FAIL: "ADD_CLINICIAN_INSURANCE_FAIL",

  GET_CLINICIAN_AVAILABILITY: "GET_CLINICIAN_AVAILABILITY",
  GET_CLINICIAN_AVAILABILITY_SUCCESS: "GET_CLINICIAN_AVAILABILITY_SUCCESS",
  SAVE_CLINICIAN_AVAILABILITY: "SAVE_CLINICIAN_AVAILABILITY",
  SAVE_CLINICIAN_AVAILABILITY_SUCCESS: "SAVE_CLINICIAN_AVAILABILITY_SUCCESS",
  SAVE_CLINICIAN_AVAILABILITY_FAIL: "SAVE_CLINICIAN_AVAILABILITY_FAIL",

  GET_CLINICIAN_DOCUMENTS: "GET_CLINICIAN_DOCUMENTS",
  GET_CLINICIAN_DOCUMENTS_SUCCESS: "GET_CLINICIAN_DOCUMENTS_SUCCESS",
  SAVE_CLINICIAN_DOCUMENT: "SAVE_CLINICIAN_DOCUMENT",
  SAVE_CLINICIAN_DOCUMENT_SUCCESS: "SAVE_CLINICIAN_DOCUMENT_SUCCESS",
  SAVE_CLINICIAN_DOCUMENT_FAIL: "SAVE_CLINICIAN_DOCUMENT_FAIL",
  REPLACE_CLINICIAN_DOCUMENT: "REPLACE_CLINICIAN_DOCUMENT",
  REPLACE_CLINICIAN_DOCUMENT_SUCCESS: "REPLACE_CLINICIAN_DOCUMENT_SUCCESS",
  REPLACE_CLINICIAN_DOCUMENT_FAIL: "REPLACE_CLINICIAN_DOCUMENT_FAIL",
  REJECT_CLINICIAN_DOCUMENT: "REJECT_CLINICIAN_DOCUMENT",
  REJECT_CLINICIAN_DOCUMENT_SUCCESS: "REJECT_CLINICIAN_DOCUMENT_SUCCESS",
  REJECT_CLINICIAN_DOCUMENT_FAIL: "REJECT_CLINICIAN_DOCUMENT_FAIL",
  APPROVE_CLINICIAN_DOCUMENT: "APPROVE_CLINICIAN_DOCUMENT",
  APPROVE_CLINICIAN_DOCUMENT_SUCCESS: "APPROVE_CLINICIAN_DOCUMENT_SUCCESS",
  APPROVE_CLINICIAN_DOCUMENT_FAIL: "APPROVE_CLINICIAN_DOCUMENT_FAIL",

  UPDATE_CLINICIAN_BIO: "UPDATE_CLINICIAN_BIO",
  UPDATE_CLINICIAN_BIO_SUCCESS: "UPDATE_CLINICIAN_BIO_SUCCESS",
  UPDATE_CLINICIAN_BIO_FAIL: "UPDATE_CLINICIAN_BIO_FAIL",
  UPDATE_CLINICIAN_PICTURE: "UPDATE_CLINICIAN_PICTURE",
  UPDATE_CLINICIAN_PICTURE_SUCCESS: "UPDATE_CLINICIAN_PICTURE_SUCCESS",
  UPDATE_CLINICIAN_PICTURE_FAIL: "UPDATE_CLINICIAN_PICTURE_FAIL",

  APPROVE_CLINICIAN_APPLICATION: "APPROVE_CLINICIAN_APPLICATION",
  APPROVE_CLINICIAN_APPLICATION_SUCCESS: "APPROVE_CLINICIAN_APPLICATION_SUCCESS",
  APPROVE_CLINICIAN_APPLICATION_FAIL: "APPROVE_CLINICIAN_APPLICATION_FAIL",

  REJECT_CLINICIAN_APPLICATION: "REJECT_CLINICIAN_APPLICATION",
  REJECT_CLINICIAN_APPLICATION_SUCCESS: "REJECT_CLINICIAN_APPLICATION_SUCCESS",
  REJECT_CLINICIAN_APPLICATION_FAIL: "REJECT_CLINICIAN_APPLICATION_FAIL",

  SET_CLINICIAN_CREDENTIALED: "SET_CLINICIAN_CREDENTIALED",
  SET_CLINICIAN_CREDENTIALED_SUCCESS: "SET_CLINICIAN_CREDENTIALED_SUCCESS",
  SET_CLINICIAN_CREDENTIALED_FAIL: "SET_CLINICIAN_CREDENTIALED_FAIL",

  UPDATE_CLINICIAN_STATUS: "UPDATE_CLINICIAN_STATUS",
  UPDATE_CLINICIAN_STATUS_SUCCESS: "UPDATE_CLINICIAN_STATUS_SUCCESS",
  UPDATE_CLINICIAN_STATUS_FAIL: "UPDATE_CLINICIAN_STATUS_FAIL",

  SYNC_CLINICIAN_CALENDAR: "SYNC_CLINICIAN_CALENDAR",
  SYNC_CLINICIAN_CALENDAR_SUCCESS: "SYNC_CLINICIAN_CALENDAR_SUCCESS",
  SYNC_CLINICIAN_CALENDAR_FAIL: "SYNC_CLINICIAN_CALENDAR_FAIL",

  GET_CLINICIAN_DASHBOARD: "GET_CLINICIAN_DASHBOARD",
  GET_CLINICIAN_DASHBOARD_SUCCESS: "GET_CLINICIAN_DASHBOARD_SUCCESS",
  GET_CLINICIAN_DASHBOARD_FAIL: "GET_CLINICIAN_DASHBOARD_FAIL",

  GET_CLINICIAN_CLIENTS: "GET_CLINICIAN_CLIENTS",
  GET_CLINICIAN_CLIENTS_SUCCESS: "GET_CLINICIAN_CLIENTS_SUCCESS",
  GET_CLINICIAN_CLIENTS_PAGE: "GET_CLINICIAN_CLIENTS_PAGE",
  GET_CLINICIAN_CLIENTS_PER_PAGE: "GET_CLINICIAN_CLIENTS_PER_PAGE",
  GET_CLINICIAN_CLIENTS_COUNT: "GET_CLINICIAN_CLIENTS_COUNT",

  SET_CLINICIAN_CLIENTS_PAGE: "SET_CLINICIAN_CLIENTS_PAGE",
  SET_CLINICIAN_CLIENTS_PER_PAGE: "SET_CLINICIAN_CLIENTS_PER_PAGE",
  SET_CLINICIAN_CLIENTS_SORT: "SET_CLINICIAN_CLIENTS_SORT",

  GET_CLINICIAN_PAST_CLIENTS: "GET_CLINICIAN_PAST_CLIENTS",
  GET_CLINICIAN_PAST_CLIENTS_SUCCESS: "GET_CLINICIAN_PAST_CLIENTS_SUCCESS",
  SET_CLINICIAN_PAST_CLIENTS_PAGE: "SET_CLINICIAN_PAST_CLIENTS_PAGE",
  SET_CLINICIAN_PAST_CLIENTS_SORT: "SET_CLINICIAN_PAST_CLIENTS_SORT",

  GET_CLINICIAN_PAY_STRUCTURE: "GET_CLINICIAN_PAY_STRUCTURE",
  GET_CLINICIAN_PAY_STRUCTURE_SUCCESS: "GET_CLINICIAN_PAY_STRUCTURE_SUCCESS",
  SAVE_CLINICIAN_PAY_STRUCTURE: "SAVE_CLINICIAN_PAY_STRUCTURE",
  SAVE_CLINICIAN_PAY_STRUCTURE_SUCCESS: "SAVE_CLINICIAN_PAY_STRUCTURE_SUCCESS",
  SAVE_CLINICIAN_PAY_STRUCTURE_FAIL: "SAVE_CLINICIAN_PAY_STRUCTURE_FAIL",
  DELETE_CLINICIAN_BONUS: "DELETE_CLINICIAN_BONUS",
  DELETE_CLINICIAN_BONUS_SUCCESS: "DELETE_CLINICIAN_BONUS_SUCCESS",
  DELETE_CLINICIAN_BONUS_FAIL: "DELETE_CLINICIAN_BONUS_FAIL",

  CLEAR_CLINICIAN_DETAILS: "CLEAR_CLINICIAN_DETAILS",

  REJECT_CLINICIAN_APPLICANT: "REJECT_CLINICIAN_APPLICANT",
  INTERVIEW_CLINICIAN_APPLICANT: "INTERVIEW_CLINICIAN_APPLICANT",

  UPDATE_LIABILITY_EXPIRATION_DATE: "UPDATE_LIABILITY_EXPIRATION_DATE",
  UPDATE_LIABILITY_EXPIRATION_DATE_SUCCESS: "UPDATE_LIABILITY_EXPIRATION_DATE_SUCCESS",
  UPDATE_LIABILITY_EXPIRATION_DATE_FAIL: "UPDATE_LIABILITY_EXPIRATION_DATE_FAIL",

  UPDATE_CLINICIAN_EMAIL: "UPDATE_CLINICIAN_EMAIL",
  UPDATE_CLINICIAN_EMAIL_SUCCESS: "UPDATE_CLINICIAN_EMAIL_SUCCESS",
  UPDATE_CLINICIAN_EMAIL_FAIL: "UPDATE_CLINICIAN_EMAIL_FAIL",

  GET_CLINICIAN_CLIENT_STATUS: "GET_CLINICIAN_CLIENT_STATUS",
  GET_CLINICIAN_CLIENT_STATUS_SUCCESS: "GET_CLINICIAN_CLIENT_STATUS_SUCCESS",
  GET_CLINICIAN_CLIENT_STATUS_FAIL: "GET_CLINICIAN_CLIENT_STATUS_FAIL",

  GET_CLINICIAN_NOTES: "GET_CLINICIAN_NOTES",
  GET_CLINICIAN_NOTES_SUCCESS: "GET_CLINICIAN_NOTES_SUCCESS",
  SAVE_CLINICIAN_NOTE: "SAVE_CLINICIAN_NOTE",
  SAVE_CLINICIAN_NOTE_SUCCESS: "SAVE_CLINICIAN_NOTE_SUCCESS",
  SAVE_CLINICIAN_NOTE_FAIL: "SAVE_CLINICIAN_NOTE_FAIL",
  EDIT_CLINICIAN_NOTE: "EDIT_CLINICIAN_NOTE",
  EDIT_CLINICIAN_NOTE_SUCCESS: "EDIT_CLINICIAN_NOTE_SUCCESS",
  EDIT_CLINICIAN_NOTE_FAIL: "EDIT_CLINICIAN_NOTE_FAIL",

  GET_CLINICIAN_NOTIFICATIONS: "GET_CLINICIAN_NOTIFICATIONS",
  GET_CLINICIAN_NOTIFICATIONS_SUCCESS: "GET_CLINICIAN_NOTIFICATIONS_SUCCESS",
  DISMISS_CLINICIAN_NOTIFICATION: "DISMISS_CLINICIAN_NOTIFICATION",
  DISMISS_CLINICIAN_NOTIFICATION_SUCCESS: "DISMISS_CLINICIAN_NOTIFICATION_SUCCESS",
  DISMISS_CLINICIAN_NOTIFICATION_FAIL: "DISMISS_CLINICIAN_NOTIFICATION_FAIL",

  GET_CLINICIAN_CALLS_NEVER_STARTED: "GET_CLINICIAN_CALLS_NEVER_STARTED",
  GET_CLINICIAN_CALLS_NEVER_STARTED_SUCCESS: "GET_CLINICIAN_CALLS_NEVER_STARTED_SUCCESS",

  GET_CLINICIAN_CREDENTIAL_DATA: "GET_CLINICIAN_CREDENTIAL_DATA",
  GET_CLINICIAN_CREDENTIAL_DATA_SUCCESS: "GET_CLINICIAN_CREDENTIAL_DATA_SUCCESS",
  GET_CLINICIAN_CREDENTIAL_DATA_LOADING: "GET_CLINICIAN_CREDENTIAL_DATA_LOADING",
  GET_CLINICIAN_CREDENTIAL_DATA_ERROR: "GET_CLINICIAN_CREDENTIAL_DATA_ERROR",
  ADD_NEW_INSURANCE_CREDENTIAL: "ADD_NEW_INSURANCE_CREDENTIAL",
  EDIT_INSURANCE_CREDENTIAL: "EDIT_INSURANCE_CREDENTIAL",

  SET_SUCCESSFUL_CALENDAR_SYNC: "SET_SUCCESSFUL_CALENDAR_SYNC",
  SET_SUCCESSFUL_CALENDAR_SYNC_SUCCESS: "SET_SUCCESSFUL_CALENDAR_SYNC_SUCCESS",
  SET_SUCCESSFUL_CALENDAR_SYNC_FAIL: "SET_SUCCESSFUL_CALENDAR_SYNC_FAIL",
};

const ACTION_CREATORS = {
  inviteClinician(details) {
    return {
      type: ACTIONS.INVITE_CLINICIAN,
      payload: details,
    };
  },
  inviteClinicianSuccess() {
    return {
      type: ACTIONS.INVITE_CLINICIAN_SUCCESS,
    };
  },
  inviteClinicianFail(payload) {
    return {
      type: ACTIONS.INVITE_CLINICIAN_FAIL,
      payload,
    };
  },
  clearInviteClinician() {
    return {
      type: ACTIONS.CLEAR_INVITE_CLINICIAN,
    };
  },
  getClinicianStatus(clinicianId) {
    return {
      type: ACTIONS.GET_CLINICIAN_STATUS,
      payload: clinicianId,
    };
  },
  getClinicianStatusSuccess(status) {
    return {
      type: ACTIONS.GET_CLINICIAN_STATUS_SUCCESS,
      payload: status,
    };
  },
  getClinicianStatusFail() {
    return {
      type: ACTIONS.GET_CLINICIAN_STATUS_FAIL,
    };
  },
  getClinicianBasicInfo(clinicianId) {
    return {
      type: ACTIONS.GET_CLINICIAN_BASIC_INFO,
      payload: clinicianId,
    };
  },
  getClinicianBasicInfoSuccess(payload) {
    return {
      type: ACTIONS.GET_CLINICIAN_BASIC_INFO_SUCCESS,
      payload,
    };
  },
  saveClinicianBasicInfo(payload) {
    return {
      type: ACTIONS.SAVE_CLINICIAN_BASIC_INFO,
      payload,
    };
  },
  saveClinicianBasicInfoSuccess() {
    return {
      type: ACTIONS.SAVE_CLINICIAN_BASIC_INFO_SUCCESS,
    };
  },
  saveClinicianBasicInfoFail() {
    return {
      type: ACTIONS.SAVE_CLINICIAN_BASIC_INFO_FAIL,
    };
  },
  saveClinicianDriversLicense(license, fetch) {
    return {
      type: ACTIONS.SAVE_CLINICIAN_DRIVERS_LICENSE,
      payload: { license, fetch },
    };
  },
  saveClinicianDriversLicenseSuccess() {
    return {
      type: ACTIONS.SAVE_CLINICIAN_DRIVERS_LICENSE_SUCCESS,
    };
  },
  saveClinicianDriversLicenseFail() {
    return {
      type: ACTIONS.SAVE_CLINICIAN_DRIVERS_LICENSE_FAIL,
    };
  },
  clearClinicianDriversLicenseInfo() {
    return {
      type: ACTIONS.CLEAR_CLINICIAN_DRIVERS_LICENSE_INFO,
    };
  },
  getClinicianCredentials(clinicianId) {
    return {
      type: ACTIONS.GET_CLINICIAN_CREDENTIALS,
      payload: clinicianId,
    };
  },
  getClinicianCredentialsSuccess(payload) {
    return {
      type: ACTIONS.GET_CLINICIAN_CREDENTIALS_SUCCESS,
      payload,
    };
  },
  saveClinicianCredentials(payload) {
    return {
      type: ACTIONS.SAVE_CLINICIAN_CREDENTIALS,
      payload,
    };
  },
  saveClinicianCredentialsSuccess() {
    return {
      type: ACTIONS.SAVE_CLINICIAN_CREDENTIALS_SUCCESS,
    };
  },
  saveClinicianCredentialsFail() {
    return {
      type: ACTIONS.SAVE_CLINICIAN_CREDENTIALS_FAIL,
    };
  },
  getClinicianStateLicense(clinicianId) {
    return {
      type: ACTIONS.GET_CLINICIAN_STATE_LICENSE,
      payload: clinicianId,
    };
  },
  getClinicianStateLicenseSuccess(payload) {
    return {
      type: ACTIONS.GET_CLINICIAN_STATE_LICENSE_SUCCESS,
      payload,
    };
  },
  getClinicianStateLicenseById(licenseId) {
    return {
      type: ACTIONS.GET_CLINICIAN_STATE_LICENSE_BY_ID,
      payload: licenseId,
    };
  },
  getClinicianStateLicenseByIdSuccess(payload) {
    return {
      type: ACTIONS.GET_CLINICIAN_STATE_LICENSE_BY_ID_SUCCESS,
      payload,
    };
  },
  saveClinicianStateLicense(payload) {
    return {
      type: ACTIONS.SAVE_CLINICIAN_STATE_LICENSE,
      payload,
    };
  },
  saveClinicianStateLicenseSuccess() {
    return {
      type: ACTIONS.SAVE_CLINICIAN_STATE_LICENSE_SUCCESS,
    };
  },
  saveClinicianStateLicenseFail() {
    return {
      type: ACTIONS.SAVE_CLINICIAN_STATE_LICENSE_FAIL,
    };
  },
  updateClinicianStateLicense(payload) {
    return {
      type: ACTIONS.UPDATE_CLINICIAN_STATE_LICENSE,
      payload,
    };
  },
  updateClinicianStateLicenseSuccess() {
    return {
      type: ACTIONS.UPDATE_CLINICIAN_STATE_LICENSE_SUCCESS,
    };
  },
  updateClinicianStateLicenseFail() {
    return {
      type: ACTIONS.UPDATE_CLINICIAN_STATE_LICENSE_FAIL,
    };
  },
  deleteClinicianStateLicense(payload) {
    return {
      type: ACTIONS.DELETE_CLINICIAN_STATE_LICENSE,
      payload,
    };
  },
  deleteClinicianStateLicenseSuccess() {
    return {
      type: ACTIONS.DELETE_CLINICIAN_STATE_LICENSE_SUCCESS,
    };
  },
  deleteClinicianStateLicenseFail() {
    return {
      type: ACTIONS.DELETE_CLINICIAN_STATE_LICENSE_FAIL,
    };
  },
  saveClinicianCertification(payload) {
    return {
      type: ACTIONS.SAVE_CLINICIAN_CERTIFICATION,
      payload,
    };
  },
  saveClinicianCertificationSuccess() {
    return {
      type: ACTIONS.SAVE_CLINICIAN_CERTIFICATION_SUCCESS,
    };
  },
  saveClinicianCertificationFail() {
    return {
      type: ACTIONS.SAVE_CLINICIAN_CERTIFICATION_FAIL,
    };
  },
  getClinicianInsurances(payload) {
    return {
      type: ACTIONS.GET_CLINICIAN_INSURANCES,
      payload,
    };
  },
  getClinicianInsurancesSuccess(payload) {
    return {
      type: ACTIONS.GET_CLINICIAN_INSURANCES_SUCCESS,
      payload,
    };
  },
  getClinicianInsurancesFail() {
    return {
      type: ACTIONS.GET_CLINICIAN_INSURANCES_FAIL,
    };
  },
  updateClinicianInsurance(payload) {
    return {
      type: ACTIONS.UPDATE_CLINICIAN_INSURANCE,
      payload,
    };
  },
  updateClinicianInsuranceSuccess() {
    return {
      type: ACTIONS.UPDATE_CLINICIAN_INSURANCE_SUCCESS,
    };
  },
  updateClinicianInsuranceFail() {
    return {
      type: ACTIONS.UPDATE_CLINICIAN_INSURANCE_FAIL,
    };
  },
  addClinicianInsurance(payload) {
    return {
      type: ACTIONS.ADD_CLINICIAN_INSURANCE,
      payload,
    };
  },
  addClinicianInsuranceSuccess() {
    return {
      type: ACTIONS.ADD_CLINICIAN_INSURANCE_SUCCESS,
    };
  },
  addClinicianInsuranceFail() {
    return {
      type: ACTIONS.ADD_CLINICIAN_INSURANCE_FAIL,
    };
  },
  getClinicianAvailability(clinicianId) {
    return {
      type: ACTIONS.GET_CLINICIAN_AVAILABILITY,
      payload: clinicianId,
    };
  },
  getClinicianAvailabilitySuccess(payload) {
    return {
      type: ACTIONS.GET_CLINICIAN_AVAILABILITY_SUCCESS,
      payload,
    };
  },
  saveClinicianAvailability(payload) {
    return {
      type: ACTIONS.SAVE_CLINICIAN_AVAILABILITY,
      payload,
    };
  },
  saveClinicianAvailabilitySuccess() {
    return {
      type: ACTIONS.SAVE_CLINICIAN_AVAILABILITY_SUCCESS,
    };
  },
  saveClinicianAvailabilityFail() {
    return {
      type: ACTIONS.SAVE_CLINICIAN_AVAILABILITY_FAIL,
    };
  },
  getClinicianDocuments(clinicianId) {
    return {
      type: ACTIONS.GET_CLINICIAN_DOCUMENTS,
      payload: clinicianId,
    };
  },
  getClinicianDocumentsSuccess(payload) {
    return {
      type: ACTIONS.GET_CLINICIAN_DOCUMENTS_SUCCESS,
      payload,
    };
  },
  saveClinicianDocument(payload) {
    return {
      type: ACTIONS.SAVE_CLINICIAN_DOCUMENT,
      payload,
    };
  },
  replaceClinicianDocument(payload) {
    return {
      type: ACTIONS.REPLACE_CLINICIAN_DOCUMENT,
      payload,
    };
  },
  replaceClinicianDocumentSuccess() {
    return {
      type: ACTIONS.REPLACE_CLINICIAN_DOCUMENT_SUCCESS,
    };
  },
  replaceClinicianDocumentFail() {
    return {
      type: ACTIONS.REPLACE_CLINICIAN_DOCUMENT_FAIL,
    };
  },
  rejectClinicianDocument(payload) {
    return {
      type: ACTIONS.REJECT_CLINICIAN_DOCUMENT,
      payload,
    };
  },
  rejectClinicianDocumentSuccess() {
    return {
      type: ACTIONS.REJECT_CLINICIAN_DOCUMENT_SUCCESS,
    };
  },
  rejectClinicianDocumentFail() {
    return {
      type: ACTIONS.REJECT_CLINICIAN_DOCUMENT_FAIL,
    };
  },
  approveClinicianDocument(payload) {
    return {
      type: ACTIONS.APPROVE_CLINICIAN_DOCUMENT,
      payload,
    };
  },
  approveClinicianDocumentSuccess() {
    return {
      type: ACTIONS.APPROVE_CLINICIAN_DOCUMENT_SUCCESS,
    };
  },
  approveClinicianDocumentFail() {
    return {
      type: ACTIONS.APPROVE_CLINICIAN_DOCUMENT_FAIL,
    };
  },
  saveClinicianDocumentSuccess() {
    return {
      type: ACTIONS.SAVE_CLINICIAN_DOCUMENT_SUCCESS,
    };
  },
  saveClinicianDocumentFail() {
    return {
      type: ACTIONS.SAVE_CLINICIAN_DOCUMENT_FAIL,
    };
  },
  updateClinicianBio(payload) {
    return {
      type: ACTIONS.UPDATE_CLINICIAN_BIO,
      payload,
    };
  },
  updateClinicianBioSuccess() {
    return {
      type: ACTIONS.UPDATE_CLINICIAN_BIO_SUCCESS,
    };
  },
  updateClinicianBioFail() {
    return {
      type: ACTIONS.UPDATE_CLINICIAN_BIO_FAIL,
    };
  },
  updateClinicianPicture(payload) {
    return {
      type: ACTIONS.UPDATE_CLINICIAN_PICTURE,
      payload,
    };
  },
  updateClinicianPictureSuccess() {
    return {
      type: ACTIONS.UPDATE_CLINICIAN_PICTURE_SUCCESS,
    };
  },
  updateClinicianPictureFail() {
    return {
      type: ACTIONS.UPDATE_CLINICIAN_PICTURE_FAIL,
    };
  },
  approveClinicianApplication(payload) {
    return {
      type: ACTIONS.APPROVE_CLINICIAN_APPLICATION,
      payload,
    };
  },
  approveClinicianApplicationSuccess() {
    return {
      type: ACTIONS.APPROVE_CLINICIAN_APPLICATION_SUCCESS,
    };
  },
  approveClinicianApplicationFail() {
    return {
      type: ACTIONS.APPROVE_CLINICIAN_APPLICATION_FAIL,
    };
  },
  rejectClinicianApplication(payload) {
    return {
      type: ACTIONS.REJECT_CLINICIAN_APPLICATION,
      payload,
    };
  },
  rejectClinicianApplicationSuccess() {
    return {
      type: ACTIONS.REJECT_CLINICIAN_APPLICATION_SUCCESS,
    };
  },
  rejectClinicianApplicationFail() {
    return {
      type: ACTIONS.REJECT_CLINICIAN_APPLICATION_FAIL,
    };
  },
  setClinicianCredentialed(payload) {
    return {
      type: ACTIONS.SET_CLINICIAN_CREDENTIALED,
      payload,
    };
  },
  setClinicianCredentialedSuccess() {
    return {
      type: ACTIONS.SET_CLINICIAN_CREDENTIALED_SUCCESS,
    };
  },
  setClinicianCredentialedFail() {
    return {
      type: ACTIONS.SET_CLINICIAN_CREDENTIALED_FAIL,
    };
  },
  updateClinicianStatus(payload) {
    return {
      type: ACTIONS.UPDATE_CLINICIAN_STATUS,
      payload,
    };
  },
  updateClinicianStatusSuccess() {
    return {
      type: ACTIONS.UPDATE_CLINICIAN_STATUS_SUCCESS,
    };
  },
  updateClinicianStatusFail() {
    return {
      type: ACTIONS.UPDATE_CLINICIAN_STATUS_FAIL,
    };
  },
  syncClinicianCalendar(payload) {
    return {
      type: ACTIONS.SYNC_CLINICIAN_CALENDAR,
      payload,
    };
  },
  syncClinicianCalendarSuccess() {
    return {
      type: ACTIONS.SYNC_CLINICIAN_CALENDAR_SUCCESS,
    };
  },
  syncClinicianCalendarFail() {
    return {
      type: ACTIONS.SYNC_CLINICIAN_CALENDAR_FAIL,
    };
  },
  getClinicianDashboard(payload) {
    return {
      type: ACTIONS.GET_CLINICIAN_DASHBOARD,
      payload,
    };
  },
  getClinicianDashboardSuccess(payload) {
    return {
      type: ACTIONS.GET_CLINICIAN_DASHBOARD_SUCCESS,
      payload,
    };
  },
  getClinicianDashboardFail() {
    return {
      type: ACTIONS.GET_CLINICIAN_DASHBOARD_FAIL,
    };
  },
  getClinicianClients(payload) {
    return {
      type: ACTIONS.GET_CLINICIAN_CLIENTS,
      payload,
    };
  },
  getClinicianClientsSuccess(clients, count) {
    return {
      type: ACTIONS.GET_CLINICIAN_CLIENTS_SUCCESS,
      payload: {
        clients,
        count,
      },
    };
  },
  getClinicianClientsPage() {
    return {
      type: ACTIONS.GET_CLINICIAN_CLIENTS_PAGE,
    };
  },
  getClinicianClientsPerPage() {
    return {
      type: ACTIONS.GET_CLINICIAN_CLIENTS_PER_PAGE,
    };
  },
  getClinicianClientsCount() {
    return {
      type: ACTIONS.GET_CLINICIAN_CLIENTS_COUNT,
    };
  },
  setClinicianClientsSort(payload) {
    return {
      type: ACTIONS.SET_CLINICIAN_CLIENTS_SORT,
      payload,
    };
  },
  setClinicianClientsPage(page) {
    return {
      type: ACTIONS.SET_CLINICIAN_CLIENTS_PAGE,
      payload: {
        page,
      },
    };
  },
  setClinicianClientsPerPage(rowsPerPage) {
    return {
      type: ACTIONS.SET_CLINICIAN_CLIENTS_PER_PAGE,
      payload: {
        rowsPerPage,
      },
    };
  },
  getClinicianPastClients(payload) {
    return {
      type: ACTIONS.GET_CLINICIAN_PAST_CLIENTS,
      payload,
    };
  },
  getClinicianPastClientsSuccess(clients, count) {
    return {
      type: ACTIONS.GET_CLINICIAN_PAST_CLIENTS_SUCCESS,
      payload: {
        clients,
        count,
      },
    };
  },
  setClinicianPastClientsPage(page) {
    return {
      type: ACTIONS.SET_CLINICIAN_PAST_CLIENTS_PAGE,
      payload: {
        page,
      },
    };
  },
  setClinicianPastClientsSort(payload) {
    return {
      type: ACTIONS.SET_CLINICIAN_PAST_CLIENTS_SORT,
      payload,
    };
  },
  getClinicianPayStructure(clinicianId) {
    return {
      type: ACTIONS.GET_CLINICIAN_PAY_STRUCTURE,
      payload: clinicianId,
    };
  },
  getClinicianPayStructureSuccess(payload) {
    return {
      type: ACTIONS.GET_CLINICIAN_PAY_STRUCTURE_SUCCESS,
      payload,
    };
  },
  saveClinicianPayStructure(payload) {
    return {
      type: ACTIONS.SAVE_CLINICIAN_PAY_STRUCTURE,
      payload,
    };
  },
  saveClinicianPayStructureSuccess() {
    return {
      type: ACTIONS.SAVE_CLINICIAN_PAY_STRUCTURE_SUCCESS,
    };
  },
  saveClinicianPayStructureFail() {
    return {
      type: ACTIONS.SAVE_CLINICIAN_PAY_STRUCTURE_FAIL,
    };
  },
  deleteClinicianBonus(clinicianId) {
    return {
      type: ACTIONS.DELETE_CLINICIAN_BONUS,
      payload: clinicianId,
    };
  },
  deleteClinicianBonusSuccess() {
    return {
      type: ACTIONS.DELETE_CLINICIAN_BONUS_SUCCESS,
    };
  },
  deleteClinicianBonusFail() {
    return {
      type: ACTIONS.DELETE_CLINICIAN_BONUS_FAIL,
    };
  },
  clearClinicianDetails() {
    return {
      type: ACTIONS.CLEAR_CLINICIAN_DETAILS,
    };
  },
  rejectClinicianApplicant(payload) {
    return {
      type: ACTIONS.REJECT_CLINICIAN_APPLICANT,
      payload,
    };
  },
  interviewClinicianApplicant(payload) {
    return {
      type: ACTIONS.INTERVIEW_CLINICIAN_APPLICANT,
      payload,
    };
  },
  updateLiabilityExpirationDate(payload) {
    return {
      type: ACTIONS.UPDATE_LIABILITY_EXPIRATION_DATE,
      payload,
    };
  },
  updateLiabilityExpirationDateSuccess() {
    return {
      type: ACTIONS.UPDATE_LIABILITY_EXPIRATION_DATE_SUCCESS,
    };
  },
  updateLiabilityExpirationDateFail() {
    return {
      type: ACTIONS.UPDATE_LIABILITY_EXPIRATION_DATE_FAIL,
    };
  },
  updateClinicianEmail(payload) {
    return {
      type: ACTIONS.UPDATE_CLINICIAN_EMAIL,
      payload,
    };
  },
  updateClinicianEmailSuccess(payload) {
    return {
      type: ACTIONS.UPDATE_CLINICIAN_EMAIL_SUCCESS,
      payload,
    };
  },
  updateClinicianEmailFail(payload) {
    return {
      type: ACTIONS.UPDATE_CLINICIAN_EMAIL_FAIL,
      payload,
    };
  },
  getClinicianClientStatus(clinicianId) {
    return {
      type: ACTIONS.GET_CLINICIAN_CLIENT_STATUS,
      payload: clinicianId,
    };
  },
  getClinicianClientStatusSuccess(status) {
    return {
      type: ACTIONS.GET_CLINICIAN_CLIENT_STATUS_SUCCESS,
      payload: status,
    };
  },
  getClinicianClientStatusFail() {
    return {
      type: ACTIONS.GET_CLINICIAN_CLIENT_STATUS_FAIL,
    };
  },
  getClinicianNotes(payload) {
    return {
      type: ACTIONS.GET_CLINICIAN_NOTES,
      payload,
    };
  },
  getClinicianNotesSuccess(payload) {
    return {
      type: ACTIONS.GET_CLINICIAN_NOTES_SUCCESS,
      payload,
    };
  },
  saveClinicianNote(payload) {
    return {
      type: ACTIONS.SAVE_CLINICIAN_NOTE,
      payload,
    };
  },
  saveClinicianNoteSuccess() {
    return {
      type: ACTIONS.SAVE_CLINICIAN_NOTE_SUCCESS,
    };
  },
  saveClinicianNoteFail() {
    return {
      type: ACTIONS.SAVE_CLINICIAN_NOTE_FAIL,
    };
  },
  editClinicianNote(payload) {
    return {
      type: ACTIONS.EDIT_CLINICIAN_NOTE,
      payload,
    };
  },
  editClinicianNoteSuccess() {
    return {
      type: ACTIONS.EDIT_CLINICIAN_NOTE_SUCCESS,
    };
  },
  editClinicianNoteFail() {
    return {
      type: ACTIONS.EDIT_CLINICIAN_NOTE_FAIL,
    };
  },
  getClinicianNotifications(payload) {
    return {
      type: ACTIONS.GET_CLINICIAN_NOTIFICATIONS,
      payload,
    };
  },
  getClinicianNotificationsSuccess(payload) {
    return {
      type: ACTIONS.GET_CLINICIAN_NOTIFICATIONS_SUCCESS,
      payload,
    };
  },
  dismissClinicianNotification(payload) {
    return {
      type: ACTIONS.DISMISS_CLINICIAN_NOTIFICATION,
      payload,
    };
  },
  dismissClinicianNotificationSuccess() {
    return {
      type: ACTIONS.DISMISS_CLINICIAN_NOTIFICATION_SUCCESS,
    };
  },
  dismissClinicianNotificationFail() {
    return {
      type: ACTIONS.DISMISS_CLINICIAN_NOTIFICATION_FAIL,
    };
  },
  getClinicianCallsNeverStarted(clinicianId) {
    return {
      type: ACTIONS.GET_CLINICIAN_CALLS_NEVER_STARTED,
      payload: clinicianId,
    };
  },
  getClinicianCallsNeverStartedSuccess(payload) {
    return {
      type: ACTIONS.GET_CLINICIAN_CALLS_NEVER_STARTED_SUCCESS,
      payload,
    };
  },
  getClinicianCredentialData(payload = {}) {
    return {
      type: ACTIONS.GET_CLINICIAN_CREDENTIAL_DATA,
      payload,
    };
  },
  getClinicianCredentialDataSuccess(payload = {}) {
    return {
      type: ACTIONS.GET_CLINICIAN_CREDENTIAL_DATA_SUCCESS,
      payload,
    };
  },
  getClinicianCredentialDataLoading() {
    return {
      type: ACTIONS.GET_CLINICIAN_CREDENTIAL_DATA_LOADING,
    };
  },
  getClinicianCredentialDataError(payload = {}) {
    return {
      type: ACTIONS.GET_CLINICIAN_CREDENTIAL_DATA_ERROR,
      payload,
    };
  },
  addNewInsuranceCredential(payload = {}) {
    return {
      type: ACTIONS.ADD_NEW_INSURANCE_CREDENTIAL,
      payload,
    };
  },
  editInsuranceCredential(payload = {}) {
    return {
      type: ACTIONS.EDIT_INSURANCE_CREDENTIAL,
      payload,
    };
  },
  setSuccessfulCalendarSync(clinicianId) {
    return {
      type: ACTIONS.SET_SUCCESSFUL_CALENDAR_SYNC,
      payload: clinicianId,
    };
  },
  setSuccessfulCalendarSyncSuccess(payload) {
    return {
      type: ACTIONS.SET_SUCCESSFUL_CALENDAR_SYNC_SUCCESS,
      payload,
    };
  },
  setSuccessfulCalendarSyncFail(clinicianId) {
    return {
      type: ACTIONS.SET_SUCCESSFUL_CALENDAR_SYNC_FAIL,
    };
  },
};
export default { ...ACTIONS, ...ACTION_CREATORS };
