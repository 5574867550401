import React from "react";
import FormControl from "@mui/material/FormControl";
import { Controller, useFormContext } from "react-hook-form";
import TextField from "@mui/material/TextField";

const GenericTextField = ({
  name,
  label,
  required,
  helperText,
  className,
  placeholder,
  disabled,
  multiline,
  rows,
}) => {
  const { control, trigger, formState } = useFormContext();
  const { errors } = formState;

  const handleBlur = () => trigger(name);

  const handleFocus = () => {
    if (errors[name]) {
      trigger(name, { shouldFocus: false });
    }
  };

  const getNestedErrorMessage = (errors, path) => {
    return path.split(".").reduce((acc, part) => acc && acc[part], errors)?.message;
  };

  return (
    <FormControl
      fullWidth
      onBlur={handleBlur}
      onFocus={handleFocus}
      error={!!getNestedErrorMessage(errors, name)}
    >
      <Controller
        control={control}
        name={name}
        rules={{ required }}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <TextField
              onChange={onChange}
              multiline={multiline}
              rows={rows}
              value={value}
              className={className}
              error={error ? true : false}
              helperText={error ? helperText : null}
              placeholder={placeholder}
              label={label}
              fullWidth
              disabled={disabled}
            />
          );
        }}
      />
    </FormControl>
  );
};

export default GenericTextField;
