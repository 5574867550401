import React from "react";
import withStyles from "@mui/styles/withStyles";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";

const CustomHeaderCell = (props) => {
  const { classes, label, align, sortDirection, active, sortable, onClick, style } = props;
  return (
    <TableCell
      className={classes.tableColumnTitle}
      sortDirection={sortDirection || false}
      align={align || "left"}
      style={style}
    >
      {sortable ? (
        <TableSortLabel active={active} direction={sortDirection || "asc"} onClick={onClick}>
          {label}
        </TableSortLabel>
      ) : (
        label
      )}
    </TableCell>
  );
};

const styles = (theme) => ({
  tableColumnTitle: {
    color: "#545A72",
    fontWeight: 500,
    fontSize: 16,
    lineHeight: "24px",
    position: "static",
    "& span": {
      position: "static",
    },
  },
});

export default withStyles(styles)(CustomHeaderCell);
