import React from "react";
import withStyles from "@mui/styles/withStyles";
import styles from "../styles";
import LoadingButton from "@mui/lab/LoadingButton";
import { useGlobalToast } from "components/GlobalToastProvider";
import SectionBox from "../../../elements/SectionBox";

const ResetPassword = (props) => {
  const { resetClinicianPassword, sendingResetPassword, resetPasswordSent, basicInfo, isActive } =
    props;

  const resetPasswordUserId = basicInfo?.user_id ?? '';
  const { showToast } = useGlobalToast();

  const onSubmitResetPassword = () => {
    resetClinicianPassword(resetPasswordUserId);
  };

  return (
    <SectionBox
      title="Password"
      description="Send the clinician a link to reset their password"
      columnDirection
    >
      <LoadingButton
        variant="contained"
        color="secondary"
        onClick={() => {
          onSubmitResetPassword();
          showToast({ message: "The link has been successfully sent!" });
        }}
        disabled={!isActive || resetPasswordSent || !resetPasswordUserId}
        loading={sendingResetPassword}
        style={{ width: "max-content", marginTop: "20px" }}
      >
        Send Password Reset Link
      </LoadingButton>
    </SectionBox>
  );
};

export default withStyles(styles)(ResetPassword);
