import React from "react";
import { withRouter } from "react-router";
import withStyles from "@mui/styles/withStyles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import styles from "../../theme/globalStyles";
import { format } from "date-fns";
import { CustomTable } from "elements/DEPRECATED_elements";
import { CustomHeaderCell } from "elements/DEPRECATED_elements";

const Clinicians = (props) => {
  const {
    classes,
    handleChangePage,
    handleChangeRowsPerPage,
    handleSortClick,
    viewClinicianDetails,
    page,
    count,
    sort,
    rowsPerPage,
    direction,
    getStatus,
    userPermissions,
  } = props;

  let rows = props.clinicians;

  return (
    <CustomTable
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      noResultsMessage="No Clinicians Found"
      headerCells={[
        <CustomHeaderCell
          label="ID"
          sortable={true}
          sortDirection={sort === "clinician.id" ? direction : false}
          active={sort === "clinician.id"}
          onClick={() => handleSortClick("clinician.id")}
        />,
        <CustomHeaderCell
          label="Name"
          sortable={true}
          sortDirection={sort === "first_name" ? direction : false}
          active={sort === "first_name"}
          onClick={() => handleSortClick("first_name")}
        />,
        <CustomHeaderCell
          label="Email"
          sortable={true}
          sortDirection={sort === "email" ? direction : false}
          active={sort === "email"}
          onClick={() => handleSortClick("email")}
        />,
        <CustomHeaderCell label="Live?" />,
        <CustomHeaderCell label="Active?" />,
        <CustomHeaderCell label="Status" />,
        <CustomHeaderCell
          label="Invited Date"
          sortable={true}
          sortDirection={sort === "invited_date" ? direction : false}
          active={sort === "invited_date"}
          onClick={() => handleSortClick("invited_date")}
        />,
      ]}
    >
      {rows.length > 0 &&
        rows.map((row) => (
          <TableRow
            key={row.id}
            className={classes.clickableRow}
            onClick={
              userPermissions?.view_clinician_list ? () => viewClinicianDetails(row.id) : null
            }
            hover
          >
            <TableCell component="th" scope="row">
              {row.id}
            </TableCell>
            <TableCell align="left">
              {row.first_name} {row.last_name}
            </TableCell>
            <TableCell align="left">{row.email}</TableCell>
            <TableCell align="left">{row.is_live ? "Yes" : "No"}</TableCell>
            <TableCell align="left">{row.can_login ? "Yes" : "No"}</TableCell>
            <TableCell align="left">{getStatus(row.workflow_status)}</TableCell>
            <TableCell align="left">
              {row.invited_date
                ? format(new Date(row.invited_date), "PP", {
                    awareOfUnicodeTokens: true,
                  })
                : "----"}
            </TableCell>
          </TableRow>
        ))}
    </CustomTable>
  );
};

export default withRouter(withStyles(styles)(Clinicians));
