import actions from "../../../actions";

export const mapDispatchToProps = {
  startVideoCall: actions.startVideoCall,
  endVideoCall: actions.endVideoCall,
  updateVideoRoomLayout: actions.updateVideoRoomLayout,
  getAnimations: actions.getAnimations,
  getActivities: actions.getActivities,
  getCardsActivity: actions.getCardsActivity,
  getVideoCallInfo: actions.getVideoCallInfo,
  sendVideoSignatures: actions.sendVideoSignatures,
  getCustomerInfo: actions.getCustomerInfo,
  setOneTimeVideoInfo: actions.setOneTimeVideoInfo,
  getNotesList: actions.getNotesList,
  saveNotes: actions.saveNotes,
  getInsuranceCodesByUser: actions.getInsuranceCodesByUser,
  getAvailableInsuranceCodes: actions.getAllAvailableInsuranceCodes,
  sendBillableTime: actions.sendBillableTimeForVideo,
  saveSessionNotes: actions.saveSessionNote,
  updateSessionNote: actions.updateSessionNote,
  getSessionNotes: actions.getSessionNotes,
  getSessionNotesByUser: actions.getSessionNotesByUser,
  getOldSessionNotesByUser: actions.getOldSessionNotesByUser,
  getSessionNotesByClinician: actions.getSessionNotesByClinician,
  getComputerPerformance: actions.getComputerPerformance,
  clearComputerPerformance: actions.clearComputerPerformance,
  markClientNoShow: actions.markClientNoShow,
  checkVideoCallAccess: actions.checkVideoCallAccess,
  secondaryClinicianJoinCall: actions.secondaryClinicianJoinCall,
  secondaryClinicianLeaveCall: actions.secondaryClinicianLeaveCall,
  setSecondaryClinicianSessionTimes: actions.setSecondaryClinicianSessionTimes,
  getVideoIsRunning: actions.getVideoIsRunning,
  setVideoRoomId: actions.setVideoRoomId,
  getVideoRoomState: actions.getVideoRoomState,
  sendParentVideoSignatures: actions.sendParentVideoSignatures,
  checkAuthState: actions.checkAuthState,
  getParentInfo: actions.getParentInfo,
  clearVideoInfo: actions.clearVideoInfo,
  getUser: actions.getUser,
  setPageDetails: actions.setPageDetails,
  logError: actions.logError,
  clearOneTimeVideoInfo: actions.clearOneTimeVideoInfo,
  signOut: actions.signOut,
  getCustomerSignedForm: actions.getCustomerSignedForm,
  sendSMSToCaregiver: actions.sendSMSToCaregiver,
  getSMSThreadByClient: actions.getSMSThreadByClient,
};
