import React from "react";
import Drawer from "@mui/material/Drawer";
import Close from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import styles from "../styles";
import { Divider } from "@mui/material";
import Alert from "@mui/material/Alert";
import InfoIcon from "../../../assets/Info-icon.png";

const HostControlsDrawer = (props) => {
  const {
    classes,
    hostControlsDrawerOpen,
    toggleHostControlsDrawer,

    showClientChat,
    onHideClientChat,
    onShowClientChat,

    showClientScreenShare,
    onHideClientScreenShare,
    onShowClientScreenShare,

    handleScheduleCall,

    // controls
    userPermissions,
    isPrimaryClinician,
    togglePassControlsConfirm,
    clinicianInControl,
    requestSentTextOpen,
    makeRequest,
    onOpenSMSFacilityDialog,
    eCommConsentFormSigned,
  } = props;

  const handleSmsMessage = () => {
    onOpenSMSFacilityDialog();
  };

  const canPassControls = () => {
    if (props.isPrimaryClinician) {
      if (props.secondaryClinicianConnected) {
        return true;
      } else {
        return false;
      }
    } else {
      if (props.primaryClinicianConnected) {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <>
      <Drawer
        className={classes.hostControlsDrawer}
        variant="temporary"
        ModalProps={{ hideBackdrop: true }}
        anchor="right"
        open={hostControlsDrawerOpen}
        onClose={toggleHostControlsDrawer}
        classes={{
          paper: classes.hostControlsDrawerPaper,
        }}
      >
        <div className={classes.hostControlsDrawerContainer}>
          <div className={classes.hostControlsDrawerHeader}>
            <b>Host Controls</b>
            <IconButton onClick={toggleHostControlsDrawer} aria-label="Previous Page" size="large">
              <Close />
            </IconButton>
          </div>
          <div className={classes.hostControlsDrawerContent}>
            <FormGroup>
              <FormControlLabel
                control={
                  <div className="switch">
                    <Switch
                      color="primary"
                      checked={showClientChat}
                      onChange={showClientChat ? onHideClientChat : onShowClientChat}
                      name="showChatToggle"
                      size="small"
                      disabled={!clinicianInControl}
                    />
                  </div>
                }
                label="Show Chat"
              />
              <p className="switchDescription">
                Choose to show or hide the chat from the client during the session
              </p>
              <FormControlLabel
                control={
                  <div className="switch">
                    <Switch
                      color="primary"
                      checked={showClientScreenShare}
                      onChange={
                        showClientScreenShare ? onHideClientScreenShare : onShowClientScreenShare
                      }
                      name="screenSharingToggle"
                      size="small"
                      disabled={!clinicianInControl}
                    />
                  </div>
                }
                label="Screen Share"
              />
              <p className="switchDescription">Allow client to screenshare during the session</p>
            </FormGroup>
            <Divider className="divider" />
            <div className="btnGroup">
              <div className="title">Scheduling</div>
              Set up the next session with the client
              <div className="btn">
                <Button
                  onClick={() => {
                    toggleHostControlsDrawer();
                    handleScheduleCall();
                  }}
                  color="secondary"
                  disabled={!clinicianInControl}
                >
                  Schedule next call
                </Button>
              </div>
            </div>
            <div className="btnGroup">
              <div className="title">SMS Message</div>
              Send an SMS message to the client
              <div className="btn">
                <Button
                  onClick={handleSmsMessage}
                  color="secondary"
                  disabled={!clinicianInControl || !eCommConsentFormSigned}
                >
                  Message client
                </Button>
              </div>
              {!eCommConsentFormSigned && (
                <div className={classes.infoAlertContainer}>
                  <div className={classes.infoAlertIconContainer}>
                    <img src={InfoIcon} />
                  </div>
                  <Typography component="p">
                    Client has not yet consented to receiving SMS communications
                  </Typography>
                </div>
              )}
            </div>
            {clinicianInControl && canPassControls() && userPermissions?.pass_leader_controls && (
              <div className="btnGroup">
                <div className="title">Supervisory Clinician</div>
                Pass and request host controls between clinicians
                <div className="btn">
                  <Button onClick={togglePassControlsConfirm} variant="outlined">
                    Pass Controls
                  </Button>
                </div>
              </div>
            )}

            {!clinicianInControl &&
              (userPermissions?.control_activities || userPermissions?.control_reinforcements) && (
                <div className="btnGroup">
                  <div className="title">Supervisory Clinician</div>
                  Request to take controls from {isPrimaryClinician ? " Supervisory " : " Primary "}
                  Clinician
                  <div className="btn">
                    <Button color="secondary" disabled={requestSentTextOpen} onClick={makeRequest}>
                      Request Controls
                    </Button>
                  </div>
                  {requestSentTextOpen && (
                    <Alert
                      variant="outlined"
                      severity="success"
                      className={classes.alertText}
                      style={{ marginTop: 8 }}
                    >
                      Host controls Requested
                    </Alert>
                  )}
                </div>
              )}
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default withStyles(styles)(HostControlsDrawer);
