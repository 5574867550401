import React from "react";
import {
  Stack,
  TextField,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Radio,
  CircularProgress,
  FormGroup,
  Typography,
} from "@mui/material";
import ANFormView from "elements/Forms/ANFormView";

const TherapyHoursForm = ({ editMode, therapyHours, onTherapyHoursChange, loading }) => {
  const formatProtocolModType = (type) => {
    switch (type) {
      case "DIRECT":
        return "Direct Modification";
      case "INDIRECT":
        return "Indirect Modification";
      default:
        return "-";
    }
  };
  return (
    <Stack spacing={7}>
      {editMode ? (
        <>
          <TextField
            label="Assessment Hours"
            type="number"
            placeholder="0 hours each authorization period"
            value={therapyHours?.assessment_hours ?? 0}
            onChange={(e) => onTherapyHoursChange("assessment_hours", parseInt(e.target.value))}
            helperText="Hours each 6 month authorization period"
            fullWidth
          />
          <TextField
            label="Direct Therapy Hours"
            type="number"
            placeholder="0 hours each week"
            value={therapyHours?.direct_therapy_hours ?? 0}
            onChange={(e) => onTherapyHoursChange("direct_therapy_hours", parseInt(e.target.value))}
            helperText="Hours per week"
            fullWidth
          />
          <TextField
            label="Protocol Modification Hours"
            type="number"
            placeholder="0 hours each week"
            value={therapyHours?.protocol_modification_hours ?? 0}
            onChange={(e) =>
              onTherapyHoursChange("protocol_modification_hours", parseInt(e.target.value))
            }
            helperText="Hours per week"
            fullWidth
          />
          <FormGroup>
            <FormLabel>
              <Typography variant="inputLabel" component="label">
                Protocol Modification Type
              </Typography>
            </FormLabel>
            <RadioGroup
              value={therapyHours?.protocol_modification_type ?? 0}
              onChange={(e) => onTherapyHoursChange("protocol_modification_type", e.target.value)}
              orientation="vertical" // Ensures the orientation is vertical
            >
              <FormControlLabel
                value="DIRECT"
                control={<Radio />}
                label={<Typography variant="body2">Direct Modification</Typography>}
              />
              <FormControlLabel
                value="INDIRECT"
                control={<Radio />}
                label={<Typography variant="body2">Indirect Modification</Typography>}
              />
            </RadioGroup>
          </FormGroup>
          <TextField
            label="Caregiver Training Hours"
            type="number"
            placeholder="0 hours each week"
            value={therapyHours?.caregiver_training_hours ?? 0}
            onChange={(e) =>
              onTherapyHoursChange("caregiver_training_hours", parseInt(e.target.value))
            }
            helperText="Hours per week"
            fullWidth
          />
        </>
      ) : !loading ? (
        <ANFormView
          data={{
            assessment: therapyHours?.assessment_hours
              ? `${therapyHours?.assessment_hours} hours`
              : "-",
            directTherapy: therapyHours?.direct_therapy_hours
              ? `${therapyHours?.direct_therapy_hours} hours`
              : "-",
            protocolMod: therapyHours?.protocol_modification_hours
              ? `${therapyHours?.protocol_modification_hours} hours`
              : "-",
            caregiverTraining: therapyHours?.caregiver_training_hours
              ? `${therapyHours?.caregiver_training_hours} hours`
              : "-",
            protocolModificationType: formatProtocolModType(
              therapyHours?.protocol_modification_type
            ),
          }}
          config={[
            { label: "97151 - Assessment", dataKey: "assessment", cols: 12, type: "text" },
            {
              label: "97153 - Direct Therapy by BCBA",
              dataKey: "directTherapy",
              cols: 12,
              type: "text",
            },
            {
              label: "97155 - Protocol Modification and Collaboration",
              dataKey: "protocolMod",
              cols: 12,
              type: "text",
            },
            {
              label: "Protocol Modification Type",
              dataKey: "protocolModificationType",
              cols: 12,
              type: "text",
            },
            {
              label: "97156 - Caregiver Training",
              dataKey: "caregiverTraining",
              cols: 12,
              type: "text",
            },
          ]}
        />
      ) : (
        <CircularProgress size={30} color="primary" style={{ marginBottom: 10 }} />
      )}
    </Stack>
  );
};

export default TherapyHoursForm;
