import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withStyles from "@mui/styles/withStyles";
import actions from "../../actions";
import Button from "@mui/material/Button";
import PageContainer from "elements/PageContainer";
import AddIcon from "@mui/icons-material/Add";
import Menu from "@mui/material/Menu";
import { Snackbar, Stack, Typography } from "@mui/material";
import {
  getAssignableClinicians,
  getAvailableInsuranceCodes,
  getBillingItems,
  getBillingItemsCount,
  getBillingItemsLoading,
  getBillingItemsPage,
  getBillingItemsRowsPerPage,
  getBillingItemsSuccess,
  getCustomerList,
  getHoldReleaseBillableTimeLoading,
  getHoldReleaseBillableTimeSuccess,
  getUserClinicianId,
  getUserId,
  getUserPermissionsList,
  userLoaded,
  getUser,
  getRevertToInProgressLoading,
  getRevertToInProgressSuccess,
} from "selectors";
import styles from "./styles";
import { Select, MenuItem } from "@mui/material";
import VideoBreakdownDialog from "../SessionNotes";
import BillingItemsTable from "./BillingItemsTable";
import TextField from "@mui/material/TextField";
import BillingItemFilters from "./BillingItemFilters";
import { isClinician } from "selectors";
import BulkOperationsHeader from "./BulkOperationsHeader";

const mapStateToProps = (state) => ({
  userPermissions: getUserPermissionsList(state),
  billingItems: getBillingItems(state),
  loading: getBillingItemsLoading(state),
  success: getBillingItemsSuccess(state),
  page: getBillingItemsPage(state),
  rowsPerPage: getBillingItemsRowsPerPage(state),
  count: getBillingItemsCount(state),
  clinicianId: getUserClinicianId(state),
  clinician: getUser(state),
  userId: getUserId(state),
  userLoaded: userLoaded(state),
  clientList: getCustomerList(state),
  clinicianList: getAssignableClinicians(state),
  availableInsuranceCodes: getAvailableInsuranceCodes(state),
  isClinician: isClinician(state),
  holdReleaseLoading: getHoldReleaseBillableTimeLoading(state),
  holdReleaseSuccess: getHoldReleaseBillableTimeSuccess(state),
  revertToInProgressLoading: getRevertToInProgressLoading(state),
  revertToInProgressSuccess: getRevertToInProgressSuccess(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setPageDetails: actions.setPageDetails,
      getBillingItems: actions.getBillingItems,
      setPage: actions.setBillingItemsPage,
      setRowsPerPage: actions.setBillingItemsRowsPerPage,
      getAllClients: actions.getAllActiveClients,
      getCliniciansList: actions.getAssignableClinicians,
      getAvailableInsuranceCodes: actions.getAllAvailableInsuranceCodes,
      releaseBillableTime: actions.releaseBillableTime,
      holdBillableTime: actions.holdBillableTime,
      revertToInProgress: actions.revertBillableItemToInProgress,
    },
    dispatch,
  );

class BillingItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientId: "",
      clinicianUserId: "",
      noteType: "",
      dateCreated: [null, null],
      statusSelection: [],
      noteMenuOptions: { timesheet: [], session: [] },
      isClinician: false,
      sessionNoteDialogOpen: false,
      sessionNoteVideoCall: null,
      currentBreakdown: [],
      enterInsuranceCode: false,
      sessionNotesDialogOpen: false,
      notesMenuView: false,
      viewOnly: false,
      createNew: false,
      timesheetNoteOpen: false,
      serviceTypeSelection: null,
      clearStatusSelection: false,
      snackbarOpen: false,
      snackbarErrorState: false,
      snackbarMessage: "",
      selectedRows: [],
      allRowsSelected: false,
      allRowsIndeterminate: false,
      noteUpdateCount: 1,
      noteUpdateAction: "",
      revertNoteId: null,
    };
  }

  componentDidMount() {
    this.props.setPageDetails({
      pageName: "Billing",
      currentPage: "billingItems",
      menu: "billingItems",
    });
    if (this.props.userPermissions) {
      this.onUserDidLoad();
    }
    if (this.props.availableInsuranceCodes?.length > 0) {
      this.onSetNoteMenuOptions();
    }
    let clientId = "";
    const params = new URLSearchParams(this.props.location.search);
    if (params.get("client_id")) {
      clientId = params.get("client_id");
    }
    this.setState({ clientId });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.userPermissions && this.props.userPermissions) {
      this.onUserDidLoad();
    }
    if (
      prevProps.page !== this.props.page ||
      prevProps.rowsPerPage !== this.props.rowsPerPage ||
      prevState.clientId !== this.state.clientId ||
      prevState.clinicianUserId !== this.state.clinicianUserId ||
      prevState.noteType !== this.state.noteType ||
      // (prevState.dateCreated && !this.state.dateCreated) ||
      (prevState.sessionNoteDialogOpen && !this.state.sessionNoteDialogOpen) ||
      (!prevState.clearStatusSelection && this.state.clearStatusSelection)
    ) {
      this.setState({ clearStatusSelection: false });
      this.getBillingItems();
    }
    if (prevProps.availableInsuranceCodes.length != this.props.availableInsuranceCodes.length) {
      this.onSetNoteMenuOptions();
    }
    if (prevProps.holdReleaseLoading && !this.props.holdReleaseLoading) {
      if (this.props.holdReleaseSuccess) {
        this.getBillingItems();
        this.setState({
          noteUpdateCount: 1,
          noteUpdateAction: "",
          snackbarOpen: true,
          snackbarMessage: `${this.state.noteUpdateCount} notes successfully ${this.state.noteUpdateAction}`,
          selectedRows: [],
          noteUpdateFromMenu: false,
        });
      } else {
        this.setState({ snackbarErrorState: true, snackbarOpen: true });
      }
    }
    if (prevProps.revertToInProgressLoading && !this.props.revertToInProgressLoading) {
      if (this.props.revertToInProgressSuccess) {
        this.getBillingItems();
        this.setState({
          revertNoteId: null,
          snackbarOpen: true,
          snackbarMessage: `Note successfully moved to In Progress`,
        });
      } else {
        this.setState({
          snackbarErrorState: true,
          snackbarOpen: true,
          noteUpdateAction: "in progress",
        });
      }
    }
  }

  onSetNoteMenuOptions = () => {
    const timesheet = [];
    const session = [];
    this.props.availableInsuranceCodes.map((code) => {
      if (!code.video) {
        if (code.time_tracking) {
          timesheet.push(code);
          return;
        }
        session.push(code);
      }
    });
    this.setState({ noteMenuOptions: { timesheet, session } });
  };

  onUserDidLoad = () => {
    if (this.props.userPermissions.view_all_clients_billing_items) {
      this.props.getCliniciansList();
      this.getBillingItems();
    } else {
      this.setState({ clinicianUserId: this.props.userId, isClinician: true }, () => {
        this.getBillingItems();
      });
    }
    this.props.getAvailableInsuranceCodes();
  };

  getBillingItems = () => {
    const { userPermissions } = this.props;
    let startDate = null;
    let endDate = null;
    if (this.state.dateCreated && this.state.dateCreated[0]) {
      startDate = new Date(this.state.dateCreated[0]);
      const year = startDate.getFullYear();
      const month = startDate.getMonth() + 1; // add 1 since January is month 0
      const day = startDate.getDate();
      startDate = `${year}-${("0" + month).slice(-2)}-${("0" + day).slice(-2)}T12:00:00.000Z`;
    }
    if (this.state.dateCreated && this.state.dateCreated[1]) {
      endDate = new Date(this.state.dateCreated[1]);
      const year = endDate.getFullYear();
      const month = endDate.getMonth() + 1; // add 1 since January is month 0
      const day = endDate.getDate();
      endDate = `${year}-${("0" + month).slice(-2)}-${("0" + day).slice(-2)}T12:00:00.000Z`;
    }
    let params = {
      clientId: this.state.clientId || null,
      clinicianUserId: this.state.clinicianUserId || null,
      startDate,
      endDate,
      noteStatus: this.state.statusSelection,
      noteType: this.state.noteType || null,
    };
    this.props.getBillingItems(params);
    this.props.getAllClients(this.state.clinicianUserId);
  };

  clearFilters = () => {
    const { userPermissions } = this.props;
    this.setState({
      clientId: "",
      clinicianUserId: userPermissions?.view_all_clients_billing_items
        ? ""
        : this.state.clinicianUserId,
      noteType: "",
      dateCreated: [null, null],
      statusSelection: [],
      clearStatusSelection: true,
    });
  };

  onChange = (name) => (e) => {
    const value = typeof e === "string" ? e : e.target.value;

    if (name === "statusSelection") {
      this.setState({ statusSelection: value?.filter((e) => e) });
    } else {
      this.setState({ [name]: value });
    }
    this.props.setPage(0);
  };

  onDateChange = (date) => {
    this.setState({ dateCreated: date });
    this.props.setPage(0);
  };

  onChangeSelectedRow = (e, billableTimeId) => {
    const { selectedRows, allRowsSelected } = this.state;
    let updatedSelection = [...selectedRows];
    if (updatedSelection?.includes(billableTimeId)) {
      updatedSelection = updatedSelection.filter((item) => item !== billableTimeId);
    } else {
      updatedSelection.push(billableTimeId);
    }
    this.setState({
      selectedRows: updatedSelection,
      allRowsIndeterminate: allRowsSelected ? true : false,
    });
  };

  onChangeSelectAllRows = () => {
    const { allRowsSelected } = this.state;
    let selectedRows, allRows;
    if (allRowsSelected) {
      selectedRows = this.state.selectedRows.filter(
        (row) => !this.props.billingItems?.some((i) => i.billable_time_id == row.billable_time_id)
      );
      allRows = false;
    } else {
      selectedRows = [...this.state.selectedRows, ...this.props.billingItems];
      allRows = true;
    }
    this.setState({
      selectedRows,
      allRowsSelected: allRows,
      allRowsIndeterminate: false,
    });
  };

  onHoldBillingItems = () => {
    const { selectedRows } = this.state;
    let rows = selectedRows.filter(
      (row) => row.note_completed && !row.enter_health_id && !row.on_hold
    );
    this.setState({
      noteUpdateCount: rows.length,
      noteUpdateAction: "on hold",
    });
    this.props.holdBillableTime(rows.map((row) => row.billable_time_id));
  };

  onReleaseBillingItems = () => {
    const { selectedRows } = this.state;
    let rows = selectedRows.filter(
      (row) => row.note_completed && !row.enter_health_id && !!row.on_hold
    );
    this.setState({
      noteUpdateCount: rows.length,
      noteUpdateAction: "released",
    });
    this.props.releaseBillableTime(rows.map((row) => row.billable_time_id));
  };

  onHoldRelaseBillingItem = (item, action) => {
    this.setState(
      {
        selectedRows: [item],
        noteUpdateAction: action,
        noteUpdateCount: 1,
        noteUpdateFromMenu: true,
      },
      () => {
        if (action === "on hold") {
          this.onHoldBillingItems();
        } else {
          this.onReleaseBillingItems();
        }
      }
    );
  };

  handleDeleteStatus = (e, i) => {
    let statusSelection = [...this.state.statusSelection];
    statusSelection.splice(i, 1);
    this.setState({ statusSelection }, () => {
      this.getBillingItems();
    });
  };

  handleChangePage = (event, page) => {
    this.setState({ allRowsSelected: false, allRowsIndeterminate: false });
    this.props.setPage(page);
  };

  handleChangeRowsPerPage = (event) => {
    this.props.setPage(0);
    this.props.setRowsPerPage(parseInt(event.target.value));
  };

  navToClientDetails = (clientId) => {
    this.props.history.push(`/clients/${clientId}/dashboard`);
  };

  setClinicianListFromClientId = () => {
    let clinicians = this.props.clinicianList;
    if (this.state.clientId && clinicians) {
      let client = this.props.clientList?.find((client) => client.client_id == this.state.clientId);
      let primaryUserId = client?.primary_clinician_user_id;
      let secondaryUserId = client?.secondary_clinician_user_id;
      clinicians = [...this.props.clinicianList].filter(
        (clin) => clin.user_id == primaryUserId || clin.user_id == secondaryUserId
      );
    }
    return clinicians;
  };

  openSessionNoteDialog = async (sessionNote, viewOnly) => {
    const timesheetNote = sessionNote.time_tracking;
    const videoCallId = sessionNote.video_call_id;
    if (!videoCallId && !timesheetNote) {
      this.props.getAllClients(sessionNote.clinician_user_id);
    }
    this.setState(
      {
        viewOnly,
        sessionNoteVideoCall: sessionNote,
        currentBreakdown: [
          {
            ...sessionNote,
            insurance_code: sessionNote.service_type,
            billable_time_id: sessionNote.id,
          },
        ],
      },
      () => {
        this.setState({
          sessionNoteDialogOpen: true,
          timesheetNoteOpen: !!timesheetNote,
        });
      }
    );
  };

  closeSessionNoteDialog = () => {
    this.setState({
      sessionNoteDialogOpen: false,
      sessionNoteVideoCall: null,
      viewOnly: false,
      createNew: false,
      timesheetNoteOpen: false,
    });
  };

  setViewOnly = (viewOnly) => {
    this.setState({ viewOnly });
  };

  notesFocusChange = (e) => {
    this.setState({
      notesMenuView: Boolean(this.state.notesMenuView) ? null : e.currentTarget,
    });
  };

  openCreateNoteDialog = (code, timesheetNote) => {
    this.setState(
      {
        serviceTypeSelection: code,
        createNew: true,
        timesheetNoteOpen: timesheetNote,
        sessionNoteDialogOpen: true,
        currentBreakdown: [],
        notesMenuView: false,
      },
      () => {
        if (!timesheetNote) {
          this.props.getAllClients(this.props.userId);
        }
      }
    );
  };

  addNote = (e, timesheetNoteOpen) => {
    this.setState({
      serviceType: e.target.id,
      timesheetNoteOpen,
      createNew: true,
    });
  };

  snackbarRetryHandler = () => {
    this.setState({ snackbarOpen: false, snackbarErrorState: false });
    if (this.state.noteUpdateAction == "in progress") {
      this.onRevertToInProgress(this.state.revertNoteId);
    } else if (this.state.noteUpdateAction == "on hold") {
      this.onHoldBillingItems();
    } else {
      this.onReleaseBillingItems();
    }
  };

  onCloseSnackbar = () => {
    this.setState({
      snackbarOpen: false,
      snackbarMessage: "",
    });
  };

  onRevertToInProgress = (noteId) => {
    this.setState({
      revertNoteId: noteId,
    });
    this.props.revertToInProgress(noteId);
  };

  render() {
    const {
      classes,
      loading,
      userPermissions,
      userLoaded,
      holdReleaseLoading,
      revertToInProgressLoading,
      clientList,
    } = this.props;
    const {
      sessionNoteDialogOpen,
      sessionNoteVideoCall,
      viewOnly,
      selectedRows,
      noteUpdateFromMenu,
    } = this.state;
    const rows = this.props.billingItems;
    const clinicianList = this.setClinicianListFromClientId();
    return (
      <>
        {selectedRows.length && !noteUpdateFromMenu ? (
          <BulkOperationsHeader
            classes={classes}
            selectedRows={selectedRows}
            onHoldBillingItems={this.onHoldBillingItems}
            onReleaseBillingItems={this.onReleaseBillingItems}
          />
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <BillingItemFilters
              classes={classes}
              userId={this.props.userId}
              clinicianList={clinicianList}
              clientList={clientList}
              userPermissions={userPermissions}
              onChange={this.onChange}
              onDateChange={this.onDateChange}
              handleDeleteStatus={this.handleDeleteStatus}
              getBillingItems={this.getBillingItems}
              clearFilters={this.clearFilters}
              CustomInput={CustomInput}
              CustomSelect={CustomSelect}
              availableInsuranceCodes={this.props.availableInsuranceCodes}
              clientId={this.state.clientId}
              clinicianUserId={this.state.clinicianUserId}
              dateCreated={this.state.dateCreated}
              statusSelection={this.state.statusSelection}
              noteType={this.state.noteType}
            />
            {this.props.isClinician && (
              <Button
                id="basic-button"
                aria-controls={Boolean(this.state.notesMenuView) ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={Boolean(this.state.notesMenuView) ? "true" : undefined}
                color="primary"
                size="small"
                variant="outlined"
                startIcon={<AddIcon color="primary" />}
                onClick={this.notesFocusChange}
                style={{ margin: 32 }}
              >
                Note
              </Button>
            )}
            <Menu
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              getcontentanchorel={null}
              anchorEl={this.state.notesMenuView}
              id="basic-menu"
              open={Boolean(this.state.notesMenuView)}
              onClose={this.notesFocusChange}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              classes={{
                paper: classes.noteMenuPaper,
                list: classes.noteMenuList,
              }}
            >
              <p className={classes.noteMenuTitleText}>Session Notes</p>
              {this.state.noteMenuOptions?.session?.map((code, key) => {
                return (
                  <MenuItem
                    value={code.description}
                    id={code.description}
                    onClick={(e) => this.openCreateNoteDialog(code.description, false)}
                    key={code.description + key}
                    className={classes.noteMenuOptionText}
                  >
                    {code.description}
                  </MenuItem>
                );
              })}

              <p className={classes.noteMenuTitleText}>Timesheet Notes</p>
              {this.state.noteMenuOptions?.timesheet?.map((code, key) => {
                return (
                  <MenuItem
                    value={code.description}
                    id={code.description}
                    onClick={(e) => this.openCreateNoteDialog(code.description, true)}
                    key={code.description}
                    className={classes.noteMenuOptionText}
                  >
                    {code.description}
                  </MenuItem>
                );
              })}
            </Menu>
          </div>
        )}
        <PageContainer
          loading={loading || !userLoaded || holdReleaseLoading || revertToInProgressLoading}
          horizontalPadding={false}
        >
          <BillingItemsTable
            {...this.props}
            {...this.state}
            clearFilters={this.clearFilters}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            navToClientDetails={this.navToClientDetails}
            openSessionNoteDialog={this.openSessionNoteDialog}
            rows={rows}
            onChangeSelectedRow={this.onChangeSelectedRow}
            onChangeSelectAllRows={this.onChangeSelectAllRows}
            onHoldRelaseBillingItem={this.onHoldRelaseBillingItem}
            onRevertToInProgress={this.onRevertToInProgress}
          />
          {sessionNoteDialogOpen && (
            <VideoBreakdownDialog
              open={sessionNoteDialogOpen}
              startDate={
                sessionNoteVideoCall?.video_call_start_date || sessionNoteVideoCall?.start_date
              }
              endDate={sessionNoteVideoCall?.video_call_end_date || sessionNoteVideoCall?.end_date}
              clinicianUserId={sessionNoteVideoCall?.clinician_user_id}
              videoCallId={sessionNoteVideoCall?.video_call_id}
              clientUserId={sessionNoteVideoCall?.user_id}
              clientId={sessionNoteVideoCall?.client_id}
              clientData={{
                child_name: sessionNoteVideoCall?.child_first_name,
                child_last_name: sessionNoteVideoCall?.child_last_name,
              }}
              onCloseCPTCodeDialog={this.closeSessionNoteDialog}
              inVideoCall={false}
              isDemo={false}
              enterInsuranceCode={this.state.enterInsuranceCode}
              sessionNotesOpen={this.state.sessionNotesDialogOpen}
              videoBreakdown={this.state.currentBreakdown}
              billingType={sessionNoteVideoCall?.billing_type}
              sessionNoteId={sessionNoteVideoCall?.session_note_id}
              billableTimeId={sessionNoteVideoCall?.billable_time_id}
              isSecondary={sessionNoteVideoCall?.clinician_user_id != this.props.userId}
              showExtendedInfo={true}
              viewOnly={viewOnly}
              setViewOnly={this.setViewOnly}
              timesheetNote={this.state.timesheetNoteOpen}
              createNew={this.state.createNew}
              serviceTypeOptions={this.state.noteMenuOptions}
              serviceTypeSelection={this.state.serviceTypeSelection}
            />
          )}
        </PageContainer>
        <Snackbar
          open={this.state.snackbarOpen}
          onClose={this.onCloseSnackbar}
          autoHideDuration={this.state.snackbarErrorState ? null : 5000}
        >
          <Stack spacing={!this.state.snackbarErrorState ? 1 : 2} alignItems="center">
            <Typography align="center">
              {!this.state.snackbarErrorState ? this.state.snackbarMessage : "Something went wrong"}
            </Typography>
            {this.state.snackbarErrorState && (
              <Button
                color="primary"
                variant="text"
                onClick={this.snackbarRetryHandler}
                styles={{
                  fontFamily: "Basier Circle",
                  fontSize: "13px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "22px", // You can calculate the percentage value if needed
                  letterSpacing: "0.46px",
                  textTransform: "capitalize",
                }}
              >
                Try again
              </Button>
            )}
          </Stack>
        </Snackbar>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BillingItems));

const StyledInput = withStyles({
  root: {
    borderRadius: 4,
    "& label.Mui-focused": {
      color: "#545A72",
    },
    "& fieldset": {
      borderRadius: 4,
    },
    "& .MuiOutlinedInput-root": {
      color: "#69718F",
      fontSize: 16,
      height: 50,
      "&:hover fieldset": {
        border: "1px solid #A5AABC",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid #A5AABC",
      },
    },
    "& .MuiOutlinedInput-input": {
      "&::placeholder": {
        color: "#69718F",
        fontSize: 16,
      },
    },
    "& .MuiInputLabel-root": {
      fontSize: 15,
    },
    "& .MuiFormHelperText-root": {
      backgroundColor: "#fafafa",
      margin: 0,
      padding: "0 5px",
      fontWeight: 500,
      color: "#B91C1C",
    },
  },
})(TextField);

export const CustomInput = React.forwardRef((props, ref) => {
  return <StyledInput {...props} variant="outlined" size="small" />;
});

const StyledSelect = withStyles({
  root: {
    borderRadius: 4,
    "& fieldset": {
      border: "1px solid #A5AABC",
      borderRadius: 4,
    },
    "& .MuiOutlinedInput-input": {
      padding: "5px 10px",
    },
    "& .MuiFormHelperText-root": {
      margin: 0,
      color: "#B91C1C",
      fontSize: 14,
      fontWeight: 500,
      padding: "0 5px",
    },
  },
})(Select);

const CustomSelect = React.forwardRef((props, ref) => {
  return (
    <StyledSelect
      {...props}
      sx={{
        height: 50,
      }}
      variant="outlined"
      size="small"
    />
  );
});
