import { createReducer } from "redux-create-reducer";
import actions from "../actions";

const initialState = {
  notesOpen: false,
  created_on: null,
  completed_questionnaire_id: null,
  name: "",
  age: null,
  customer_id: "",
  client_id: "",
  notes: [],
  first_name: "",
  last_name: "",
  email: "",
  answers: [],
  notesLoading: false,
  saving: false,
  saved: false,
  savingError: false,
  sessionNote: {},
  sessionNoteLoading: false,
  sessionNotes: [],
  sessionNotesByUser: [],
  oldSessionNotesByUser: [],
};

const reducers = {
  [actions.TOGGLE_NOTES](state) {
    const notesOpen = !state.notesOpen;

    if (notesOpen) {
      return {
        ...state,
        notesOpen: true,
      };
    } else {
      return initialState;
    }
  },
  [actions.GET_NOTES_LIST_SUCCESS](state, { payload }) {
    return {
      ...state,
      notes: payload,
    };
  },
  [actions.SAVE_NOTES](state) {
    return {
      ...state,
      saving: true,
    };
  },
  [actions.SAVE_NOTES_SUCCESS](state, { payload }) {
    return {
      ...state,
      saving: false,
      saved: true,
    };
  },
  [actions.SAVE_NOTES_FAIL](state) {
    return {
      ...state,
      saving: false,
      saved: false,
    };
  },
  [actions.CLEAR_NOTES_SUCCESS](state) {
    return {
      ...state,
      saved: false,
    };
  },
  [actions.GET_SESSION_NOTES](state) {
    return {
      ...state,
      sessionNotes: [],
    };
  },
  [actions.GET_SESSION_NOTES_SUCCESS](state, { payload }) {
    return {
      ...state,
      sessionNotes: payload,
    };
  },
  [actions.GET_SESSION_NOTE](state) {
    return {
      ...state,
      sessionNoteLoading: true,
      sessionNote: {},
    };
  },
  [actions.GET_SESSION_NOTE_SUCCESS](state, { payload }) {
    return {
      ...state,
      sessionNoteLoading: false,
      sessionNote: payload,
    };
  },
  [actions.GET_SESSION_NOTES_BY_USER](state) {
    return {
      ...state,
      sessionNotesByUser: [],
    };
  },
  [actions.GET_SESSION_NOTES_BY_USER_SUCCESS](state, { payload }) {
    return {
      ...state,
      sessionNotesByUser: payload,
    };
  },
  [actions.GET_OLD_SESSION_NOTES_BY_USER](state) {
    return {
      ...state,
      oldSessionNotesByUser: [],
    };
  },
  [actions.GET_OLD_SESSION_NOTES_BY_USER_SUCCESS](state, { payload }) {
    return {
      ...state,
      oldSessionNotesByUser: payload,
    };
  },
  [actions.GET_SESSION_NOTES_BY_CLINICIAN](state) {
    return {
      ...state,
      sessionNotes: [],
    };
  },
  [actions.GET_SESSION_NOTES_BY_CLINICIAN_SUCCESS](state, { payload }) {
    return {
      ...state,
      sessionNotes: payload,
    };
  },
  [actions.SAVE_SESSION_NOTE](state, { payload }) {
    return {
      ...state,
      saved: false,
      savingError: false,
      saving: true,
    };
  },
  [actions.SAVE_SESSION_NOTE_SUCCESS](state) {
    return {
      ...state,
      saving: false,
      saved: true,
    };
  },
  [actions.SAVE_SESSION_NOTE_FAIL](state) {
    return {
      ...state,
      saving: false,
      savingError: true,
    };
  },
  [actions.CLEAR_SESSION_NOTE_SUCCESS](state) {
    return {
      ...state,
      saving: false,
      savingError: false,
      saved: false,
    };
  },
  [actions.UPDATE_SESSION_NOTE](state) {
    return {
      ...state,
      saving: true,
    };
  },
  [actions.UPDATE_SESSION_NOTE_SUCCESS](state) {
    return {
      ...state,
      saving: false,
    };
  },
  [actions.UPDATE_SESSION_NOTE_FAIL](state) {
    return {
      ...state,
      saving: false,
    };
  },
};

export default createReducer(initialState, reducers);
