import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
} from "@mui/material";
import { X } from "@phosphor-icons/react";
import { useFormikContext } from "formik";
import React from "react";
import { useSelector } from "react-redux";
import * as selectors from "selectors";

export default function UpdateEventDialog({
  open,
  onClose,
  rescheduleAllInstances,
  setRescheduleAllInstances,
  showRecurringOptions,
}) {
  const { submitForm } = useFormikContext();
  const scheduleCallLoading = useSelector(selectors.scheduleCallLoading);

  const handleSubmission = () => {
    submitForm();
    onClose();
  };

  return (
    <Dialog maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle>
        Save changes to event
        <IconButton aria-label="close" onClick={onClose}>
          <X />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {showRecurringOptions && (
          <RadioGroup
            aria-label="rescheduleAllInstances"
            name="rescheduleAllInstances"
            value={rescheduleAllInstances}
            onChange={(e) => setRescheduleAllInstances(e.target.value)}
          >
            <FormControlLabel value="false" control={<Radio />} label="This event" />
            <FormControlLabel
              value="true"
              control={<Radio />}
              label="This event and future events"
            />
          </RadioGroup>
        )}
        <Alert severity="warning">Event participants will receive event updates</Alert>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton loading={scheduleCallLoading} onClick={handleSubmission}>
          Save and update event
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
