import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withStyles from "@mui/styles/withStyles";
import PageContainer from "elements/PageContainer";
import Button from "@mui/material/Button";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import styles from "./styles";
import { CircularProgress, IconButton, Typography } from "@mui/material";
import EventNoteIcon from "@mui/icons-material/EventNote";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DownloadIcon from "@mui/icons-material/GetApp";
import moment from "moment-timezone";
import actions from "actions";
import {
  getClientId,
  getLogin,
  getParentInfo,
  getSessionNote,
  getSessionNoteLoading,
  getUserId,
  getVideoBreakdown,
  getVideoBreakdownLoading,
  videoChatInfo,
} from "../../selectors";
import Calendar from "../ClientDashboard/Calendar";
import Auth from "@aws-amplify/auth";

const mapStateToProps = (state) => ({
  login: getLogin(state),
  user: getParentInfo(state),
  clientId: getClientId(state),
  videoChatInfo: videoChatInfo(state),
  userId: getUserId(state),
  videoBreakdown: getVideoBreakdown(state),
  videoBreakdownLoading: getVideoBreakdownLoading(state),
  videoSessionNote: getSessionNote(state),
  videoSessionNoteLoading: getSessionNoteLoading(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getVideoChatInfo: actions.getVideoChatInfo,
      getUser: actions.getParentInfo,
      getClinician: actions.getUserClinician,
      getVideoCallBreakdown: actions.getVideoCallBreakdown,
      getSessionNote: actions.getSessionNote,
    },
    dispatch,
  );

class CallInstructions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displaySessionNotes: false,
      sessionNoteIndex: 0,
    };
  }

  componentDidMount() {
    this.props.getVideoChatInfo(this.props.match.params.id);
    this.props.getVideoCallBreakdown(this.props.match.params.id);
    if (this.props.login) {
      this.props.getUser();
    }
    if (this.props.clientId) {
      this.props.getClinician(this.props.clientId);
    }
    if (this.props.location.pathname.includes("/session-details/")) {
      this.setState({ displaySessionNotes: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.clientId && this.props.clientId) {
      this.props.getClinician(this.props.clientId);
    }
    if (
      prevProps.videoBreakdownLoading &&
      !this.props.videoBreakdownLoading &&
      this.props.videoBreakdown?.length > 0 &&
      this.props.videoBreakdown[this.state.sessionNoteIndex]?.note_id
    ) {
      this.props.getSessionNote(
        this.props.videoBreakdown[this.state.sessionNoteIndex].billable_time_id,
      );
    }
  }

  mapBillingTypeToDisplay = (callType) => {
    switch (callType) {
      case "CAREGIVER_TRAINING":
        return "Caregiver Training";
      case "ORIENTATION":
        return "Orientation Call";
      case "DIRECT_THERAPY":
        return "Direct Therapy Session";
      case "ASSESSMENT":
        return "Assessment";
    }
  };

  mapBillingTypeToInstructions = (callType) => {
    switch (callType) {
      case "CAREGIVER_TRAINING":
        return {
          title: "For this Caregiver Training",
          instructions: [
            "Caregiver(s) must attend but client does not need to be present, unless specifically asked to attend.",
            "Let your clinician know if you have any new concerns or new goals that you'd like addressed.",
            "Please keep in mind, this session can be anywhere from 30 - 60 minutes.",
          ],
        };
      case "ORIENTATION":
        return {
          title: "For this Orientation Call",
          instructions: [
            "Caregiver(s) must attend but typically client doesn't need to be present.",
            "During this session you'll practice navigating the portal, using the video platform, discussing availability, and asking questions about answersNow.",
            "Please bring up any concerns or barriers you foresee with the telehealth process.",
            "Please keep in mind, this session is estimated to be around 30 minutes long.",
          ],
        };
      case "DIRECT_THERAPY":
        return {
          title: "For this Direct Therapy Session",
          instructions: [
            "Client must be present for the duration of the entire session. A caregiver may be recommended to attend to support and/or check in with the BCBA.",
            "Have your assigned materials ready (examples: toys, reinforcers, comm, book, etc).",
            "Let your clinician know if you have any new concerns or new goals that you'd like addressed.",
            "The session will likely range from 30 - 90 minutes, please consult with your BCBA about specific time frames that are agreed upon beforehand.",
          ],
        };
      case "ASSESSMENT":
        return {
          title: "For this Assessment",
          instructions: [
            "Client and Caregiver(s) should both be present for this unless over the age of 18.",
            "Caregivers will be asked questions regarding the client’s history and the client will be directly tested and observed. This is the purpose of the session and how the client will be assessed.",
            "This is a great time to discuss any concerns or goals you have about therapy.",
            "The assessment is either 1 or 2 different sessions depending on the circumstance, each session lasting 1 - 2 hours.",
          ],
        };
    }
  };

  onChangeSessionNoteIndex = (index) => {
    this.setState({ sessionNoteIndex: index }, () => {
      this.props.getSessionNote(
        this.props.videoBreakdown[this.state.sessionNoteIndex].billable_time_id,
      );
    });
  };

  downloadSessionNote = async (billableTimeId) => {
    const baseUrl = process.env.BASE_URL;
    const userSession = await Auth.currentSession();
    location.href = `${baseUrl}/download/${userSession.accessToken.jwtToken}/session-note/${billableTimeId}`;
  };

  downloadAllSessionNotes = async (videoCallId) => {
    const baseUrl = process.env.BASE_URL;
    const userSession = await Auth.currentSession();
    location.href = `${baseUrl}/download/${userSession.accessToken.jwtToken}/all-session-notes/${videoCallId}`;
  };

  render() {
    const {
      classes,
      videoChatInfo,
      user,
      videoBreakdown,
      videoBreakdownLoading,
      videoSessionNote,
      videoSessionNoteLoading,
    } = this.props;
    const { displaySessionNotes, sessionNoteIndex } = this.state;

    let instructions = this.mapBillingTypeToInstructions(videoChatInfo?.billing_type);
    return (
      <PageContainer loading={!videoChatInfo || videoBreakdownLoading}>
        <div className={classes.callInstructionsContainer}>
          <Button
            onClick={() => this.props.history.goBack()}
            variant="text"
            startIcon={<ArrowBackOutlinedIcon style={{ fill: "#000" }} />}
            className={classes.goBackButton}
          >
            Go Back
          </Button>
          <div className={classes.callDetailsContainer}>
            <div className={classes.callDetails}>
              <div className={classes.callTypeContainer}>
                <Typography component="p" className={classes.callType}>
                  {this.mapBillingTypeToDisplay(videoChatInfo?.billing_type)}
                </Typography>
                <Typography component="p" className={classes.callWith}>
                  with{" "}
                  {videoChatInfo?.clinician_name
                    ? `${videoChatInfo.clinician_name}`
                    : `${user?.clinician?.first_name} ${user?.clinician?.last_name}`}
                </Typography>
              </div>
              <div className={classes.callTimeDetails}>
                <div className={classes.callTimeContainer}>
                  <div className={classes.callTimeIcon}>
                    <EventNoteIcon />
                  </div>
                  <Typography component="p">
                    {moment
                      .tz(
                        videoChatInfo?.start_date
                          ? videoChatInfo?.start_date
                          : videoChatInfo?.scheduled_date,
                        videoChatInfo?.call_timezone,
                      )
                      .format("dddd, MMMM Do, YYYY")}
                  </Typography>
                </div>
                <div className={classes.callTimeContainer}>
                  <div className={classes.callTimeIcon}>
                    <AccessTimeIcon />
                  </div>
                  <Typography component="p">
                    {moment
                      .tz(
                        videoChatInfo?.start_date
                          ? videoChatInfo?.start_date
                          : videoChatInfo?.scheduled_date,
                        videoChatInfo?.call_timezone,
                      )
                      .format("h:mm a z")}
                  </Typography>
                </div>
                {!displaySessionNotes && (
                  <div className={classes.callTimeContainer}>
                    <div className={classes.callTimeIcon} />
                    <Typography component="p" style={{ color: "#545A72", fontSize: 14 }}>
                      Arrive by{" "}
                      {moment
                        .tz(videoChatInfo?.scheduled_date, videoChatInfo?.call_timezone)
                        .subtract(5, "minutes")
                        .format("h:mm a")}
                    </Typography>
                  </div>
                )}
                {videoChatInfo?.chat_conversation_id && videoChatInfo?.end_date && (
                  <div className={classes.callTimeContainer}>
                    <Button
                      onClick={() =>
                        this.props.history.push(`/chats/${videoChatInfo.chat_conversation_id}`)
                      }
                      variant="text"
                      size="large"
                      style={{
                        marginLeft: "-11px",
                        fontSize: 16,
                        height: 30,
                      }}
                    >
                      View Session Chat
                    </Button>
                  </div>
                )}
              </div>
            </div>
            <div className={classes.calendarContainer}>
              <Calendar
                calendarCalls={[]}
                calendarDisplayDate={
                  new Date(
                    displaySessionNotes ? videoChatInfo?.start_date : videoChatInfo?.scheduled_date,
                  )
                }
                disableInteraction={true}
                maxHeight={300}
              />
            </div>
          </div>
          {displaySessionNotes ? (
            <div className={classes.instructionsContainer}>
              <div className={classes.sessionNotesTitleContainer}>
                <Typography component="h1">Notes from Session</Typography>
                <div className={classes.sessionNotesArrowsContainer}>
                  <Typography component="h2">
                    {videoBreakdown.length > 0
                      ? `${sessionNoteIndex + 1}/${videoBreakdown?.length}`
                      : "0/0"}
                  </Typography>
                  <div className={classes.sessionNotesArrows}>
                    <IconButton
                      disabled={videoSessionNoteLoading || sessionNoteIndex === 0}
                      onClick={() => this.onChangeSessionNoteIndex(sessionNoteIndex - 1)}
                      size="large"
                    >
                      <ExpandLessIcon />
                    </IconButton>
                    <IconButton
                      disabled={
                        videoSessionNoteLoading ||
                        sessionNoteIndex === videoBreakdown.length - 1 ||
                        videoBreakdown.length === 0
                      }
                      onClick={() => this.onChangeSessionNoteIndex(sessionNoteIndex + 1)}
                      size="large"
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                  </div>
                </div>
              </div>
              {videoSessionNoteLoading ? (
                <div className={classes.sessionNoteLoading}>
                  <CircularProgress />
                </div>
              ) : videoBreakdown.length === 0 ||
                !videoBreakdown[sessionNoteIndex]?.note_completed ? (
                <>
                  <div style={{ marginBottom: 30 }}>
                    <Typography component="h3">
                      Notes are pending for this session. It may take a day or two for your
                      clinician to finish entering this information.
                    </Typography>
                  </div>
                  <div style={{ marginBottom: 30 }} />
                  <div />
                </>
              ) : (
                <>
                  <div style={{ marginBottom: 30 }}>
                    <Typography component="h3">
                      Intervention (Goals addressed, protocols used, service provided, strategies
                      used, etc.):
                    </Typography>
                    <Typography component="h4">{videoSessionNote.note}</Typography>
                  </div>
                  <div style={{ marginBottom: 30 }}>
                    <Typography component="h3">
                      Response (Summary of progress, reaction, data, etc.):
                    </Typography>
                    <Typography component="h4">{videoSessionNote.response}</Typography>
                  </div>
                  <div className={classes.downloadButtonContainer}>
                    {videoSessionNote?.approved && !videoSessionNote?.on_hold && (
                      <Button
                        startIcon={<DownloadIcon />}
                        variant="text"
                        onClick={() => this.downloadSessionNote(videoSessionNote.billable_time_id)}
                        style={{ marginLeft: 0 }}
                      >
                        Download
                      </Button>
                    )}
                    {videoBreakdown?.length > 1 &&
                      !videoBreakdown?.some((note) => note.on_hold || !note.approved) && (
                        <Button
                          startIcon={<DownloadIcon />}
                          variant="text"
                          onClick={() =>
                            this.downloadAllSessionNotes(videoSessionNote.video_call_id)
                          }
                          style={{ marginLeft: 0 }}
                        >
                          Download All
                        </Button>
                      )}
                  </div>
                </>
              )}
            </div>
          ) : (
            <div className={classes.instructionsContainer}>
              <div className={classes.virtualInstructions}>
                <Typography component="h1">Virtual Instructions</Typography>
                <Typography component="h3">Please:</Typography>
                <ul>
                  <li style={{ marginBottom: 5 }}>
                    Arrive 5 minutes prior to the appointment time.
                  </li>
                  <li>
                    Test your video, speakers, microphone, and network{" "}
                    <span style={{ textDecoration: "underline" }}>
                      on the device you plan to use
                    </span>{" "}
                    for the session
                  </li>
                </ul>
                <Button variant="text" onClick={() => this.props.history.push("/test")}>
                  Test Device
                </Button>
              </div>
              <div>
                <Typography component="h1">{instructions?.title}</Typography>
                <ul>
                  {(videoChatInfo?.call_instructions ||
                    videoChatInfo?.master_call_instructions) && (
                    <li>
                      <span style={{ fontWeight: "500" }}>Today's Session Instructions:</span>
                      <ul style={{ marginTop: 10 }}>
                        {videoChatInfo.call_instructions && (
                          <li>{videoChatInfo.call_instructions}</li>
                        )}
                        {videoChatInfo.master_call_instructions && (
                          <li>{videoChatInfo.master_call_instructions}</li>
                        )}
                      </ul>
                    </li>
                  )}

                  {instructions?.instructions.map((bullet) => {
                    return <li>{bullet}</li>;
                  })}
                </ul>
              </div>
              <div>
                <Typography component="h3">Not going to make this session?</Typography>
                <ul>
                  <li>
                    If possible, provide 24 hours notice if you need to reschedule your session
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
      </PageContainer>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CallInstructions));
