import { resetUserPasswordAsync } from "api/user";
import { useGlobalToast } from "components/GlobalToastProvider";
import { useMutation } from "react-query";

export const useResetUserPassword = (options = {}) => {
  const { onError = () => {}, ...otherOptions } = options;
  const { showToast } = useGlobalToast();

  const { mutate, ...rest } = useMutation((userId) => resetUserPasswordAsync(userId), {
    onError: (_, variables, context) => {
      showToast({
        message: "Whoops! Something went wrong",
        errorState: true,
        retryHandler: () => mutate(variables, context),
      });
      onError();
    },
    ...otherOptions,
  });

  return {
    resetUserPasswordMutation: mutate,
    ...rest,
  };
};
