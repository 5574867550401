export default (theme) => ({
  formContainer: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    width: "100%",
    minHeight: "100vh",
    overflowY: "auto",
    zIndex: 3000,
  },
  innerFormContainer: {
    height: "auto",
    padding: `${theme.spacing(11)} ${theme.spacing(7)}`,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#fff",
    minHeight: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.spacing(7)}`,
    },
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    width: 960,
    gap: theme.spacing(7),
    "@media (max-width: 1008px)": {
      width: "100%",
    },
  },
  logoContainer: {
    width: "100%",
    height: 48,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    height: "48px",
    width: "auto",
    display: "flex",
    "@media (max-width: 400px)": {
      display: "none",
    },
  },
  logoMobile: {
    height: "28px",
    width: "auto",
    display: "flex",
    "@media (min-width: 400px)": {
      display: "none",
    },
  },
  formSummaryContainer: {
    display: "flex",
    padding: theme.spacing(9),
    flexDirection: "column",
    alignItems: "flex-start",
    gap: theme.spacing(5),
    alignSelf: "stretch",
    borderRadius: theme.spacing(11),
    backgroundColor: "#F6F7F8",
  },
  formSummary: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: theme.spacing(5),
    wordWrap: "break-word",
  },
  formSummaryIconContainer: {
    height: 36,
    width: 38,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    zIndex: 0,
  },
  formSummaryVector: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "auto",
    zIndex: -1,
  },
  pandaDocContainer: {
    width: "100%",
    height: 746,
    "@media (max-width: 500px)": {
      maxHeight: 600,
    },
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(3),
    "@media (max-width: 1008px)": {
      width: "440px",
      maxWidth: "100%",
      margin: "-20px 0 0 0",
      padding: "0 20px",
    },
  },
  divider: {
    width: "100%",
    height: "1px",
    backgroundColor: "#CDD3DA",
    marginBottom: 8,
    "@media (min-width: 1008px)": {
      display: "none",
    },
  },
});
