import React from "react";
import Modal from "elements/Modal";

const ApproveApplicationDialog = (props) => {
  const { approveApplicationOpen, onToggleDialog, applicationLoading, submitApproveApplication } =
    props;

  return (
    <Modal
      open={approveApplicationOpen}
      onClose={onToggleDialog}
      title="Approve Application"
      titleCentered
      description=" Are you sure you would like to approve this clinician's application?"
      loading={applicationLoading}
      primaryActionText="Submit"
      primaryActionOnClick={submitApproveApplication}
      primaryActionDisabled={applicationLoading}
      secondaryActionText="Cancel"
      secondaryActionOnClick={onToggleDialog}
      secondaryActionDisabled={applicationLoading}
    />
  );
};

export default ApproveApplicationDialog;
