export function formatFilename(filename) {
  // Remove the file extension by splitting on the dot and taking the first part
  let nameWithoutExtension = filename.split(".")[0].toLowerCase();
  // Split the name on underscores, capitalize each part, and join with a space
  let formattedName = nameWithoutExtension
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return formattedName;
}
