/**
 * The ColorIndexManager class manages color indexes, allowing for retrieval, release, resetting, and
 * checking the status of available and used color indexes.
 * @class ColorIndexManager
 * @example
 * const colorManager = new ColorIndexManager(3);
 * console.log(colorManager.get("id1")); // 0
 * console.log(colorManager.get("id2")); // 1
 */
class ColorIndexManager {
  #setOfColors;

  /**
   * The constructor function initializes a *Set* of available colors and a *Map* of used colors based on
   * the specified number.
   * @param {number} [numberOfColors=9] - specifies the total number of colors
   * available for use in the constructor. If no value is provided when creating an
   * instance of the constructor, the default value is 9.
   */
  constructor(numberOfColors = 9) {
    this.#setOfColors = [...Array.from({ length: numberOfColors }, (_, i) => i)];
    /** @type {Set<number>} */
    this.available = new Set(this.#setOfColors);
    /** @type {Map<number|string, number>} */
    this.used = new Map();
  }

  /**
   * The `get` method returns the colorIndex associated with the given `idRef`. If the
   * `idRef` is already in use and mapped to a colorIndex, it returns the colorIndex from the `used` *Map*.
   * If the `idRef` is not in use, it retrieves the first available colorIndex from the `available` *Set*.
   * @method get
   * @param {number|string} idRef - The `idRef` parameter is the reference id for which the colorIndex is
   * being requested.
   * @returns {number|null} The colorIndex associated with the given `idRef`.
   */
  get(idRef) {
    if (this.available.size === 0) {
      console.error("ColorIndexManager: No more colors available");
      return null;
    }

    if (!idRef) {
      console.info("ColorIndexManager: No ID Ref provided");
      return null;
    }

    if (this.used.has(idRef)) {
      return this.used.get(idRef);
    }

    // Get the first available colorIndex
    const iterator = this.available.values();
    const colorIndex = iterator.next().value;

    // Move the colorIndex from available to used
    this.available.delete(colorIndex);
    this.used.set(idRef, colorIndex);

    return colorIndex;
  }

  /**
   * The `find` method checks if a given `idRef` is in use and returns the corresponding value from the
   * `used` *Map* if it exists.
   * @method find
   * @param {number|string} idRef - Is the reference id for which the colorIndex is
   * being requested.
   * @returns {number|null} The colorIndex associated with the given `idRef`.
   */
  find(idRef) {
    if (!idRef) {
      console.info("ColorIndexManager: No ID Ref provided");
      return null;
    }

    if (this.used.has(idRef)) {
      return this.used.get(idRef);
    }

    return null;
  }

  /**
   * The `release` method removes a color index from the `used` *Map* and adds it to the `available` *Set* if
   * it exists.
   * @method release
   * @param {number|string} idRef - The `idRef` parameter is the reference id for which the colorIndex is
   * being released.
   * @returns {number} The `release` method is returning the `colorIndex` that was released back to the
   * available pool.
   */
  release(idRef) {
    if (!this.used.has(idRef)) {
      console.info("ColorIndexManager: Color index not in use");
      return;
    }

    const colorIndex = this.used.get(idRef);

    // Move the colorIndex from used to available
    this.used.delete(idRef);
    this.available.add(colorIndex);

    return colorIndex;
  }

  /**
   * The `reset` method resets the `available` *Set* and `used` *Map* to their initial states.
   * @method reset
   */
  reset() {
    this.available = new Set(this.#setOfColors);
    this.used = new Map();
  }

  /**
   * @method status
   * @returns {{
   *  available: number[],
   *  used: number[]
   * }} The `status` method returns an object containing the `available` and `used` color indexes.
   */
  status() {
    return {
      available: Array.from(this.available),
      used: Array.from(this.used.values()),
    };
  }
}

export default ColorIndexManager;
