import { createReducer } from "redux-create-reducer";
import actions from "../actions";

const initialState = {
  signinUsername: "",
  signinPassword: "",
  signedInState: null,
  signinFailure: false,
  signinFailureMessage: null,
  passwordChangeRequired: false,
  userAttributes: [],
  requiredAttributes: [],
  redirectUrl: "",
  clear: false,
  invitation: null,
  invitationError: null,
  forgotPasswordSent: false,
  resetPasswordSuccess: false,
  resetPasswordError: false,
  needsReset: false,
  notConfirm: false,
  verified: false,
  verificationError: false,
};

const reducers = {
  [actions.START_SIGNIN](state) {
    return {
      ...state,
      signinFailure: false,
      notConfirm: false,
      clear: false,
      signinFailureMessage: null,
    };
  },
  [actions.SET_SIGNIN_DETAILS](state, { payload }) {
    return {
      ...state,
      ...payload,
    };
  },
  [actions.SET_SIGNEDIN_STATE](state, { payload }) {
    return {
      ...state,
      signedInState: payload.authState,
      signInFailure: false,
    };
  },
  [actions.SET_SIGNIN_FAILURE](state, { payload }) {
    return {
      ...state,
      signinFailure: true,
      signinFailureMessage: payload.message,
    };
  },
  [actions.FORCE_PASSWORD_CHANGE](state, { payload }) {
    return {
      ...state,
      passwordChangeRequired: true,
      ...payload,
    };
  },
  [actions.CLEAR_PASSWORD_CHANGE](state) {
    return {
      ...state,
      passwordChangeRequired: false,
      userAttributes: [],
      requiredAttributes: [],
    };
  },
  [actions.FORGOT_PASSWORD_SUCCESS](state) {
    return {
      ...state,
      forgotPasswordSent: true,
    };
  },
  [actions.CLEAR_FORGOT_PASSWORD](state) {
    return {
      ...state,
      forgotPasswordSent: false,
    };
  },
  [actions.RESET_PASSWORD_SUCCESS](state) {
    return {
      ...state,
      resetPasswordSuccess: true,
      resetPasswordError: false,
    };
  },
  [actions.RESET_PASSWORD_ERROR](state, { payload }) {
    return {
      ...state,
      resetPasswordSuccess: false,
      resetPasswordError: true,
      resetPasswordErrorMessage: payload?.message,
    };
  },
  [actions.CLEAR_RESET_PASSWORD](state) {
    return {
      ...state,
      resetPasswordSuccess: false,
      resetPasswordError: false,
    };
  },
  [actions.SET_NEEDS_RESET](state) {
    return {
      ...state,
      needsReset: true,
    };
  },
  [actions.SET_NOT_CONFIRM](state) {
    return {
      ...state,
      notConfirm: true,
    };
  },
  [actions.SET_VERIFIED](state) {
    return {
      ...state,
      verified: true,
    };
  },
  [actions.SET_VERIFICATION_ERROR](state) {
    return {
      ...state,
      verificationError: true,
    };
  },
  [actions.SET_USER_ATTRIBUTES](state, { payload }) {
    return {
      ...state,
      userAttributes: payload.userAttributes,
    };
  },
  [actions.SET_REDIRECT](state, { payload }) {
    return {
      ...state,
      redirectUrl: payload.url,
    };
  },
  [actions.SIGN_OUT](state, { payload }) {
    return {
      ...state,
      clear: !!payload.clear,
    };
  },
  [actions.GET_INVITATION_SUCCESS](state, { payload }) {
    return {
      ...state,
      invitation: payload,
    };
  },
  [actions.GET_INVITATION_FAIL](state, { payload }) {
    return {
      ...state,
      invitationError: payload,
    };
  },
  [actions.CLEAR_SIGNIN_FAILURE](state) {
    return {
      ...state,
      signinFailure: false,
    };
  },
};

export default createReducer(initialState, reducers);
