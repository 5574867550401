import React from "react";
import Modal from "elements/Modal";

const ApproveDocumentDialog = (props) => {
  const {
    approveDocumentOpen,
    onToggleApproveDocument,
    documentsLoading,
    updateDocument,
    onApproveDocument,
  } = props;
  return (
    <Modal
      open={approveDocumentOpen}
      onClose={() => onToggleApproveDocument()}
      title={` Approve Document - ${updateDocument?.display_name}`}
      titleCentered
      description="Are you sure you would like to approve this document?"
      loading={documentsLoading}
      primaryActionText="Submit"
      primaryActionOnClick={() => onApproveDocument(updateDocument)}
      secondaryActionText="Cancel"
      secondaryActionOnClick={() => onToggleApproveDocument()}
    />
  );
};

export default ApproveDocumentDialog;
