import { createReducer } from "redux-create-reducer";
import actions from "../actions";

const initialState = {
  email: "",
  password: "",
  connection: null,
  currentChannel: null,
  channelList: [],
  currentChatUrl: null,
};

const reducers = {
  [actions.SET_CHAT_DETAILS](state, { payload }) {
    return {
      ...state,
      ...payload,
    };
  },
  [actions.GET_CHAT_CONNECTION_SUCCESS](state, { payload }) {
    return {
      ...state,
      connection: payload.connection,
    };
  },
  [actions.DISCONNECT_SENDBIRD](state) {
    return {
      ...initialState,
    };
  },
  [actions.SET_CURRENT_CHANNEL_URL](state, { payload }) {
    return {
      ...state,
      channelUrl: payload,
    };
  },
};

export default createReducer(initialState, reducers);
