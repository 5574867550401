import { createReducer } from "redux-create-reducer";
import actions from "../actions";

const initialState = {
  billingItems: [],
  billingItemsLoading: false,
  billingItemsSuccess: false,
  page: 0,
  rowsPerPage: 25,
  count: 0,
  editAssociatedTimesLoading: false,
  editAssociatedTimesSuccess: false,
  holdReleaseLoading: false,
  holdReleaseSuccess: false,
  approveTimeLoading: false,
  approveTimeSuccess: false,
  requestEditsLoading: false,
  requestEditsSuccess: false,
  timesheetLoading: false,
  timesheetSuccess: false,
  timesheetError: false,
  deleteNoteLoading: false,
  deleteNoteSuccess: false,
  deleteNoteError: false,
  deleteNoteCreatedBillableId: null,
  billableItem: {},
  billableItemSuccess: false,
  revertToInProgressLoading: false,
  revertToInProgressSuccess: false,
};

const reducers = {
  [actions.GET_BILLING_ITEMS](state) {
    return {
      ...state,
      billingItemsLoading: true,
      billingItemsSuccess: false,
      deleteNoteCreatedBillableId: null,
      deleteNoteLoading: false,
      deleteNoteSuccess: false,
      deleteNoteError: false,
      deleteNoteCreatedBillableId: null,
      billableItem: {},
      billableItemSuccess: false,
    };
  },
  [actions.GET_BILLING_ITEMS_SUCCESS](state, { payload }) {
    return {
      ...state,
      billingItems: payload,
      count: payload.length > 0 ? payload[0].count : 0,
      billingItemsLoading: false,
      billingItemsSuccess: true,
      billableItem: {},
    };
  },
  [actions.GET_BILLING_ITEMS_FAIL](state, { payload }) {
    return {
      ...state,
      billingItemsLoading: false,
    };
  },
  [actions.SET_BILLING_ITEMS_PAGE](state, { payload }) {
    return {
      ...state,
      page: payload,
    };
  },
  [actions.SET_BILLING_ITEMS_ROWS_PER_PAGE](state, { payload }) {
    return {
      ...state,
      rowsPerPage: payload,
    };
  },
  [actions.SET_ASSOCIATED_BILLABLE_TIMES](state) {
    return {
      ...state,
      editAssociatedTimesLoading: true,
      editAssociatedTimesSuccess: false,
      deleteNoteSuccess: false,
    };
  },
  [actions.SET_ASSOCIATED_BILLABLE_TIMES_SUCCESS](state) {
    return {
      ...state,
      editAssociatedTimesLoading: false,
      editAssociatedTimesSuccess: true,
    };
  },
  [actions.SET_ASSOCIATED_BILLABLE_TIMES_FAIL](state) {
    return {
      ...state,
      editAssociatedTimesLoading: false,
    };
  },
  [actions.HOLD_BILLABLE_TIME](state) {
    return {
      ...state,
      holdReleaseLoading: true,
      holdReleaseSuccess: false,
    };
  },
  [actions.HOLD_BILLABLE_TIME_SUCCESS](state) {
    return {
      ...state,
      holdReleaseLoading: false,
      holdReleaseSuccess: true,
    };
  },
  [actions.HOLD_BILLABLE_TIME_FAIL](state) {
    return {
      ...state,
      holdReleaseLoading: false,
    };
  },
  [actions.RELEASE_BILLABLE_TIME](state) {
    return {
      ...state,
      holdReleaseLoading: true,
      holdReleaseSuccess: false,
    };
  },
  [actions.RELEASE_BILLABLE_TIME_SUCCESS](state) {
    return {
      ...state,
      holdReleaseLoading: false,
      holdReleaseSuccess: true,
    };
  },
  [actions.RELEASE_BILLABLE_TIME_FAIL](state) {
    return {
      ...state,
      holdReleaseLoading: false,
    };
  },
  [actions.APPROVE_BILLABLE_TIME](state) {
    return {
      ...state,
      approveTimeLoading: true,
      approveTimeSuccess: false,
      requestEditsSuccess: false,
    };
  },
  [actions.APPROVE_BILLABLE_TIME_SUCCESS](state) {
    return {
      ...state,
      approveTimeLoading: false,
      approveTimeSuccess: true,
    };
  },
  [actions.APPROVE_BILLABLE_TIME_FAIL](state) {
    return {
      ...state,
      approveTimeLoading: false,
    };
  },
  [actions.REQUEST_BILLABLE_TIME_EDITS](state) {
    return {
      ...state,
      requestEditsLoading: true,
      requestEditsSuccess: false,
      approveTimeSuccess: false,
    };
  },
  [actions.REQUEST_BILLABLE_TIME_EDITS_SUCCESS](state) {
    return {
      ...state,
      requestEditsLoading: false,
      requestEditsSuccess: true,
    };
  },
  [actions.REQUEST_BILLABLE_TIME_EDITS_FAIL](state) {
    return {
      ...state,
      requestEditsLoading: false,
    };
  },
  [actions.SEND_TIMESHEET_BILLABLE_TIME](state) {
    return {
      ...state,
      timesheetLoading: true,
      timesheetSuccess: false,
      timesheetError: false,
    };
  },
  [actions.SEND_TIMESHEET_BILLABLE_TIME_SUCCESS](state) {
    return {
      ...state,
      timesheetLoading: false,
      timesheetSuccess: true,
    };
  },
  [actions.SEND_TIMESHEET_BILLABLE_TIME_FAIL](state) {
    return {
      ...state,
      timesheetLoading: false,
      timesheetError: true,
    };
  },
  [actions.DELETE_BILLABLE_TIME](state) {
    return {
      ...state,
      deleteNoteLoading: true,
      deleteNoteSuccess: false,
      deleteNoteError: false,
    };
  },
  [actions.DELETE_BILLABLE_TIME_SUCCESS](state, { payload }) {
    return {
      ...state,
      deleteNoteLoading: false,
      deleteNoteSuccess: true,
      deleteNoteCreatedBillableId: payload.billableTimeId,
    };
  },
  [actions.DELETE_BILLABLE_TIME_FAIL](state) {
    return {
      ...state,
      deleteNoteLoading: false,
      deleteNoteError: true,
    };
  },
  [actions.GET_BILLABLE_ITEM_BY_ID](state) {
    return {
      ...state,
      billableItemSuccess: false,
      deleteNoteSuccess: false,
    };
  },
  [actions.GET_BILLABLE_ITEM_BY_ID_SUCCESS](state, { payload }) {
    return {
      ...state,
      billableItemSuccess: true,
      billableItem: payload,
    };
  },
  [actions.REVERT_BILLABLE_ITEM_TO_IN_PROGRESS](state) {
    return {
      ...state,
      revertToInProgressLoading: true,
      revertToInProgressSuccess: false,
    };
  },
  [actions.REVERT_BILLABLE_ITEM_TO_IN_PROGRESS_SUCCESS](state) {
    return {
      ...state,
      revertToInProgressLoading: false,
      revertToInProgressSuccess: true,
    };
  },
  [actions.REVERT_BILLABLE_ITEM_TO_IN_PROGRESS_FAIL](state) {
    return {
      ...state,
      revertToInProgressLoading: false,
    };
  },
};

export default createReducer(initialState, reducers);
