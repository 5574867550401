import React, { useEffect } from "react";
import Modal from "elements/Modal";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import ReassignClientsBeforeDeactivationDialog from "./ReassignClientsBeforeDeactivationDialog";
import { useGlobalToast } from "components/GlobalToastProvider";
import { Alert, AlertTitle } from "@mui/material";
import { WarningCircle } from "@phosphor-icons/react";

const DeactivateUserDialog = (props) => {
  const {
    isActive,
    isRoleClinician,
    clinicianDeactivationReasons,
    numberOfClients,
    hasScheduledCall,
    userPermissions,
    toggleClinicianActivation,
    basicInfo,
  } = props;

  
  const id = basicInfo?.user_id || "";
  const clinicianId = basicInfo?.id || "";
  let currentUser = isRoleClinician ? "clinician" : "customer";
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [reason, setReason] = React.useState("");
  const [note, setNote] = React.useState("");
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [message, setMessage] = React.useState("");

  const { showToast } = useGlobalToast();

  const reactivationReq = !isActive && userPermissions.reactivate_clients;
  const deactivationReq = isActive && userPermissions.disable_client;

  const handleDialogOpen = (open) => {
    setDialogOpen(open);
  };

  const handleOnChangeReason = (e) => {
    setReason(e.target.value);
  };

  const handleOnChangeInfo = (e) => {
    setNote(e.target.value);
  };

  useEffect(() => {
    if (reason && note) {
      setIsDisabled(false);
    }
  }, [reason, note]);

  useEffect(() => {
    setMessage(`The account was successfully ${isActive ? "deactivated" : "reactivated"}`);
  }, [isActive]);

  return (
    <>
      <Button
        variant={isActive ? "contained" : "contained"}
        color={isActive ? "error" : "secondary"}
        onClick={() => {
          handleDialogOpen(true);
        }}
        fullWidth
        disabled={!reactivationReq && !deactivationReq}
      >
        {isActive ? "Deactivate Clinician" : "Reactivate Clinician"}
      </Button>
      {
        <>
          {numberOfClients > 0 ? (
            <ReassignClientsBeforeDeactivationDialog
              open={dialogOpen}
              onClose={() => handleDialogOpen(false)}
            />
          ) : (
            <>
              {isActive ? (
                <Modal
                  showCloseOnTitle
                  showBordersOnContent
                  titleStyles={{
                    lineHeight: "160%",
                    letterSpacing: "0.12px",
                    fontSize: 18,
                    fontWeight: 500,
                    marginBottom: "0",
                  }}
                  open={dialogOpen}
                  onClose={() => {
                    handleDialogOpen(false);
                  }}
                  title={`Deactivate Clinician`}
                  content={
                    <div style={{ width: "600px", maxWidth: "95%", margin: "auto" }}>
                      <TextField
                        select
                        fullWidth
                        label="Deactivation Reason"
                        id="reason-menu"
                        value={reason}
                        name="reason"
                        style={{ margin: "15px 0" }}
                        onChange={handleOnChangeReason}
                      >
                        <MenuItem disabled value="" />
                        {clinicianDeactivationReasons?.map((el) => (
                          <MenuItem key={el.id} value={el.reason}>
                            {el.reason}
                          </MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        fullWidth
                        id="reason-note"
                        name="note"
                        label="Notes"
                        value={note}
                        onChange={handleOnChangeInfo}
                        style={{ marginTop: 5 }}
                        multiline={true}
                        rows={3}
                        placeholder="Add notes to the deactivation"
                      />
                    </div>
                  }
                  error={numberOfClients > 0 || hasScheduledCall || isDisabled}
                  errorMessage={
                    numberOfClients > 0
                      ? " This clinician is currently assigned to a client."
                      : hasScheduledCall
                        ? " The Clinician has scheduled call/s. Please, cancel all calls before deactivation."
                        : isDisabled
                          ? "All fields must be filled"
                          : null
                  }
                  primaryActionText="Cancel"
                  primaryActionOnClick={() => {
                    handleDialogOpen(false);
                  }}
                  primaryActionOutlined={true}
                  secondaryButtonStyles={{ backgroundColor: "#D32F2F", color: "white" }}
                  primaryButtonStyles={{ color: "#31333A", borderColor: "#C0C8D0" }}
                  secondaryActionText="Deactivate Clinician"
                  secondaryActionDisabled={
                    numberOfClients > 0 || hasScheduledCall || isDisabled || !reason || !note
                  }
                  secondaryActionOnClick={() => {
                    if (!reason || !note) {
                      setIsDisabled(true);
                    } else {
                      toggleClinicianActivation({ id, reason, note, clinicianId });
                      showToast({ message });
                    }
                  }}
                />
              ) : (
                <Modal
                  open={dialogOpen}
                  onClose={() => {
                    handleDialogOpen(false);
                  }}
                  content={
                    <div style={{ width: "444px", maxWidth: "95%", margin: "auto" }}>
                      <Alert severity={"warning"} icon={<WarningCircle />}>
                        <AlertTitle>Reactivate Clinician?</AlertTitle>
                        Reactivating the clinician account will return the account status to the
                        previous state before deactivation.
                      </Alert>
                    </div>
                  }
                  primaryActionText="Cancel"
                  primaryActionOutlined={true}
                  primaryButtonStyles={{ color: "#31333A", borderColor: "#C0C8D0" }}
                  primaryActionOnClick={() => {
                    handleDialogOpen(false);
                  }}
                  secondaryButtonStyles={{ backgroundColor: "#8E42CA", color: "white" }}
                  secondaryActionText="Reactivate Clinician"
                  secondaryActionOnClick={() => {
                    showToast({ message });
                    toggleClinicianActivation({ id, clinicianId });
                  }}
                />
              )}
            </>
          )}
        </>
      }
    </>
  );
};

export default DeactivateUserDialog;
