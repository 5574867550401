import { TIMEZONES } from "constants";
import { formatTimeZoneToAbbreviation } from "./formatTimeZoneToAbbreviation";

/**
 * Transforms time zone data into a list of objects, each containing a label and value.
 * The label is a combination of the time zone abbreviation and the original time zone identifier,
 * making it easier for users to understand the context of each abbreviation.
 *
 * @returns {Object[]} An array of objects with 'label' and 'value' properties.
 */
export const getTimeZoneOptions = () => {
  return Object.entries(TIMEZONES)
    .map(([key, description]) => ({
      label: `${description} (${formatTimeZoneToAbbreviation(key)})`,
      value: key,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
};
