import React, { Component } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import styles from "./styles";

class GroupDescriptionDialog extends Component {
  getChannelDescription = (channel) => {
    if (!channel.data) {
      return null;
    }

    const data = JSON.parse(channel.data);
    return data.description || null;
  };
  render() {
    const { handleToggleDescription, currentChannel, openDescription, classes } = this.props;
    return (
      <Dialog
        open={openDescription}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        disableEscapeKeyDown={true}
        PaperProps={{
          style: {
            maxWidth: "95%",
            width: 350,
          },
        }}
      >
        <DialogTitle id="form-dialog-title" align="center">
          Community Info
        </DialogTitle>

        <DialogContent>
          <Typography component="div" align="center">
            <Typography component="p" className={classes.confirmationMessage}>
              {currentChannel ? this.getChannelDescription(currentChannel) : null}
            </Typography>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleToggleDescription}
            variant="contained"
            color="primary"
            className={classes.confirmationAgree}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles, { withTheme: true })(GroupDescriptionDialog);
