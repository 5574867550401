import React from "react";
import Checkbox from "@mui/material/Checkbox";
import withStyles from "@mui/styles/withStyles";

const CustomCheckbox = withStyles({
  root: {
    color: "#7f7e81",
    "& checked": {
      color: "#4d3371",
    },
  },
})((props) => <Checkbox color="primary" {...props} />);

export default CustomCheckbox;
