import React, { useState } from "react";
import { Box, Checkbox, Typography, IconButton, Select, MenuItem, Tooltip } from "@mui/material";
import { Plus, CopySimple, Minus } from "@phosphor-icons/react";
import moment from "moment-timezone";
import { CopyAvailabilityMenu } from "components/ClinicianWorkingHours";
import { formatTimeZoneToAbbreviation } from "utils/formatTimeZoneToAbbreviation";

const defaultStartTime = "08:00 AM";
const defaultEndTime = "05:00 PM";

export const WorkingHoursSelector = ({
  day,
  isActive,
  timeRanges,
  updateDayData,
  copyToDays,
  editMode,
  clinicianTimeZone,
}) => {
  const defaultNewRange = {
    start: moment.tz(defaultStartTime, "h:mm A", clinicianTimeZone).toISOString(),
    end: moment.tz(defaultEndTime, "h:mm A", clinicianTimeZone).toISOString(),
  };

  const [copyMenuOpen, setCopyMenuOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  if (isActive && timeRanges.length === 0) {
    timeRanges = [defaultNewRange];
  }

  const handleMenuOpen = (event) => {
    setCopyMenuOpen(true);
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setCopyMenuOpen(false);
    setMenuAnchorEl(null);
  };

  const generateTimeOptions = () => {
    const times = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        times.push(moment({ hour, minute }).format("h:mm A"));
      }
    }
    return times;
  };

  const formatTime = (dateString) => {
    const date = moment.tz(dateString, clinicianTimeZone);
    return date.format("h:mm A");
  };

  const handleTimeChange = (index, field, value) => {
    const updatedRanges = timeRanges.map((range, i) => {
      if (i === index) {
        let newValue = moment.tz(value, "h:mm A", clinicianTimeZone);
        const todayInClinicianTimeZone = moment.tz(clinicianTimeZone).format("YYYY-MM-DD");
        newValue = moment
          .tz(
            `${todayInClinicianTimeZone} ${newValue.format("HH:mm")}`,
            "YYYY-MM-DD HH:mm",
            clinicianTimeZone
          )
          .toISOString();

        let newRange = { ...range, [field]: newValue };
        let startMoment = moment.tz(newRange.start, clinicianTimeZone);
        let endMoment = moment.tz(newRange.end, clinicianTimeZone);

        if (field === "start" && endMoment.isBefore(startMoment)) {
          newRange.end = newRange.start;
        } else if (field === "end" && startMoment.isAfter(endMoment)) {
          newRange.start = newRange.end;
        }

        return newRange;
      }
      return range;
    });

    updateDayData(day, isActive, updatedRanges);
    return updatedRanges;
  };

  const addTimeRange = (index) => {
    const updatedTimeRanges = [
      ...timeRanges.slice(0, index + 1),
      defaultNewRange,
      ...timeRanges.slice(index + 1),
    ];

    updateDayData(day, isActive, updatedTimeRanges);
  };

  const removeTimeRange = (index) => {
    const newTimeRanges = timeRanges.filter((_, i) => i !== index);
    updateDayData(day, isActive, newTimeRanges);
  };

  const timeOptions = generateTimeOptions();

  return (
    <Box display="flex" flexDirection="column" paddingTop={"8px"}>
      <Box display="flex" alignItems="flex-start">
        {editMode && (
          <Box style={{ display: "flex", alignItems: "center", height: "40px" }}>
            <Checkbox
              checked={isActive}
              onChange={() => {
                updateDayData(day, !isActive, !timeRanges.length ? [defaultNewRange] : timeRanges);
              }}
            />
          </Box>
        )}
        <Typography
          variant="body2"
          style={{
            display: "flex",
            alignItems: "center",
            width: "128px",
            height: editMode ? "40px" : "20px",
          }}
        >
          {`${day.charAt(0).toUpperCase() + day.slice(1)}`}
        </Typography>
        {!isActive && (
          <Typography
            variant="subtitle2"
            style={{
              display: "flex",
              alignItems: "center",
              width: "128px",
              height: editMode ? "40px" : "20px",
            }}
          >
            Unavailable
          </Typography>
        )}
        {isActive && (
          <Box>
            {timeRanges.map((timeRange, index) =>
              editMode ? (
                <Box key={index} display="flex" alignItems="center" paddingBottom={"8px"}>
                  <Select
                    value={formatTime(timeRange.start)}
                    onChange={(e) => handleTimeChange(index, "start", e.target.value)}
                    style={{ marginRight: "10px", minWidth: "128px" }}
                  >
                    {timeOptions.map((time, i) => (
                      <MenuItem key={i} value={time}>
                        {time}
                      </MenuItem>
                    ))}
                  </Select>

                  <Typography variant="body2">to</Typography>

                  <Select
                    value={formatTime(timeRange.end)}
                    onChange={(e) => handleTimeChange(index, "end", e.target.value)}
                    style={{ marginLeft: "10px", minWidth: "128px" }}
                  >
                    {timeOptions.map((time, i) => (
                      <MenuItem key={i} value={time}>
                        {time}
                      </MenuItem>
                    ))}
                  </Select>

                  {index > 0 ? (
                    <Box>
                      <Tooltip
                        title={`Remove interval for ${day.charAt(0).toUpperCase() + day.slice(1)}`}
                      >
                        <IconButton
                          size="medium"
                          onClick={() => removeTimeRange(index)}
                          style={{ marginLeft: "16px" }}
                        >
                          <Minus />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        title={`New interval for ${day.charAt(0).toUpperCase() + day.slice(1)}`}
                      >
                        <IconButton
                          size="medium"
                          onClick={(e) => {
                            addTimeRange(index);
                          }}
                          style={{ marginLeft: "16px" }}
                        >
                          <Plus />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  ) : (
                    <Box sx={{ height: editMode ? "40px" : "20px" }}>
                      <Tooltip
                        title={`New interval for ${day.charAt(0).toUpperCase() + day.slice(1)}`}
                      >
                        <IconButton
                          size="medium"
                          onClick={(e) => {
                            addTimeRange(index);
                          }}
                          style={{ marginLeft: "16px" }}
                        >
                          <Plus />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        title={`Copy ${day.charAt(0).toUpperCase() + day.slice(1)}'s Availability`}
                      >
                        <IconButton
                          size="medium"
                          onClick={handleMenuOpen}
                          style={{ marginLeft: "16px" }}
                        >
                          <CopySimple />
                        </IconButton>
                      </Tooltip>
                      <CopyAvailabilityMenu
                        anchorEl={menuAnchorEl}
                        isMenuOpen={copyMenuOpen}
                        handleMenuClose={handleMenuClose}
                        copyToDays={copyToDays}
                        chosenDay={day}
                      />
                    </Box>
                  )}
                </Box>
              ) : (
                <Box key={index} display="flex" flexDirection={"row"} paddingBottom={"8px"}>
                  <Typography
                    variant="subtitle2"
                    style={{
                      height: editMode ? "40px" : "20px",
                      display: "flex",
                      alignItems: "baseline",
                      minWidth: "128px",
                    }}
                  >
                    {`${formatTime(timeRange.start)} to ${formatTime(
                      timeRange.end
                    )} (${formatTimeZoneToAbbreviation(clinicianTimeZone)})`}
                  </Typography>
                </Box>
              )
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};
