import React from "react";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { getColumnDefinitions } from "./ColumnDefinitions";
export const Table = (props) => {
  const {
    width,
    theme,
    // handlers
    handleEdit,
    setSortModel,
    setPaginationModel,
    // values
    rows,
    totalRowCount,
    userPermissions,
    clinicianCredentialDataLoading,
    sortModel,
    paginationModel,
  } = props;
  const processRowData = (row) => {
    return {
      ...row,
      insuranceCompany: row.insurance_name,
      insuranceCompanyLink: {
        label: row.insurance_name,
        link: `/libraries/insurance/${row.insurance_id}`,
      },
      insuranceCompanyId: row.insurance_id,
      submittedDate: row.submitted,
      approvalDate: row.approved,
      insurancePlan: row.insurance_plan_name,
      insurancePlanLink: {
        label: row.insurance_plan_name,
        link: `/libraries/insurance/plan/${row.insurance_plan_id}`,
      },
      insurancePlanId: row.insurance_plan_id,
      initialCredentialedDate: row.initial_credentialed_date,
      reCredentialedDate: row.re_credentialed_date,
      termDate: row.term_date,
      status: row.credential_status,
    };
  };

  const canModifyInsurance = !!userPermissions?.edit_clinician_insurances;
  const hasRows = rows && rows.length > 0;

  return (
    <Box
      sx={{
        height: !hasRows && 200,
        width: width ?? "100%",
        "& .header": {
          width: "100%",
          backgroundColor: (theme) => theme.palette.grey[50],
        },
        "& .insuranceCompanyLink": {
          color: (theme) => theme.palette.primary.main,
          textDecoration: "none",
        },
        "& .insurancePlanLink": {
          color: (theme) => theme.palette.text.primary,
          textDecoration: "none",
        },
      }}
    >
      <DataGrid
        columnHeader
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        rows={rows.map(processRowData)}
        columns={getColumnDefinitions(handleEdit, canModifyInsurance, theme)}
        loading={clinicianCredentialDataLoading}
        rowCount={totalRowCount}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        paginationMode="server"
        pageSizeOptions={[5, 10, 20]}
        page={paginationModel.page}
        TablePaginationActionsWrapped
        sortModel={sortModel}
        onSortModelChange={setSortModel}
        sortMode="server"
        initialState={{
          pagination: {
            paginationModel: {
              paginationModel,
            },
            paginationMode: "server",
          },
          sorting: {
            sortModel: {
              sortModel,
            },
            sortMode: "server",
          },
          columns: {
            columnVisibilityModel: {
              action: canModifyInsurance,
            },
          },
        }}
      />
    </Box>
  );
};
