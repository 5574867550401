import React from "react";
import withStyles from "@mui/styles/withStyles";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import SubHeading from "./SubHeading";

const SectionContainer = (props) => {
  const {
    classes,
    loading,
    halfWidth,
    minHeight,
    maxHeight,
    noPadding,
    columnDirection,
    overflow,
    disableBoxShadow,
  } = props;
  let style = {};
  if (minHeight) {
    style.minHeight = minHeight;
  }
  if (maxHeight) {
    style.maxHeight = maxHeight;
  }
  if (noPadding) {
    style.padding = 0;
  }
  if (overflow) {
    style.overflow = overflow;
  }
  if (disableBoxShadow) {
    style.boxShadow = "none";
  }
  return (
    <div
      className={halfWidth ? classes.halfWidthContainer : classes.fullWidthContainer}
      style={style}
    >
      <div className={columnDirection ? classes.columnContentContainer : classes.contentContainer}>
        {loading ? (
          <div className={classes.loading} style={minHeight ? { minHeight } : null}>
            <CircularProgress size={30} color="primary" style={{ margin: 10 }} />
            <SubHeading>Loading</SubHeading>
          </div>
        ) : (
          <React.Fragment>{props.children}</React.Fragment>
        )}
      </div>
    </div>
  );
};

const styles = (theme) => ({
  fullWidthContainer: {
    width: "100%",
    height: "fit-content",
    borderRadius: 6,
    margin: "20px 0",
    padding: "20px 50px",
    boxShadow: "0 0 5px 0 rgba(0,0,0,.4)",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    overflow: "hidden",
    "& p": {
      letterSpacing: ".8px",
    },
  },
  halfWidthContainer: {
    width: "50%",
    height: "fit-content",
    borderRadius: 6,
    margin: "20px 0",
    padding: "20px",
    boxShadow: "0 0 5px 0 rgba(0,0,0,.4)",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    overflow: "hidden",
    "& p": {
      letterSpacing: ".8px",
    },
  },
  contentContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  columnContentContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  loading: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
});

export default withStyles(styles)(SectionContainer);
