import React, { Component } from "react";
import { withRouter } from "react-router";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { CustomTable } from "elements/DEPRECATED_elements";
import { CustomHeaderCell } from "elements/DEPRECATED_elements";
import Button from "@mui/material/Button";

import { convertTokens } from "@date-fns/upgrade/v2";

class VideoCalls extends Component {
  render() {
    const { videoCalls, downloadVideoCall, userPermissions } = this.props;
    let rows = videoCalls;

    return (
      <CustomTable
        noResultsMessage="No Video Calls Found"
        headerCells={[
          <CustomHeaderCell label="Parent Name" />,
          <CustomHeaderCell label="Child Name" />,
          <CustomHeaderCell label="Clinician Name" />,
          <CustomHeaderCell label="Call Started" />,
          <CustomHeaderCell label="Call Ended" />,
          <CustomHeaderCell label="Customer Details" />,
          <CustomHeaderCell label="Download" />,
        ]}
      >
        {rows.map((row) => (
          <TableRow key={row.id}>
            <TableCell component="th" scope="row">
              {row.parent_name}
            </TableCell>
            <TableCell component="th" scope="row">
              {row.name}
            </TableCell>
            <TableCell component="th" scope="row">
              {row.clinician_name}
            </TableCell>

            <TableCell component="th" scope="row">
              {format(new Date(row.start_date), convertTokens("MMM DD, YYYY h:mm a"))}
            </TableCell>
            <TableCell component="th" scope="row">
              {format(new Date(row.end_date), convertTokens("MMM DD, YYYY h:mm a"))}
            </TableCell>
            <TableCell>
              <Button
                color="secondary"
                onClick={() => this.props.history.push(`/clients/${row.client_id}/dashboard`)}
                disabled={!userPermissions?.view_client_list_by_workflow_state}
              >
                View Customer
              </Button>
            </TableCell>
            <TableCell>
              <Button
                onClick={() => downloadVideoCall(row.id)}
                disabled={!userPermissions?.download_signed_video_report}
              >
                Download
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </CustomTable>
    );
  }
}

export default withRouter(VideoCalls);
