import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import {
  Alert,
  AlertTitle,
  Box,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  Switch,
} from "@mui/material";
import ANDatePicker from "elements/ANDatePicker";
import { X } from "@phosphor-icons/react";

const NewAuthorization = (props) => {
  const {
    classes,
    newAuthorizationCode,
    newAuthorizationEndDate,
    newAuthorizationStartDate,
    newAuthorizationClinicianId,
    clinicianList,
    onChange,
    onChangeDate,
    onToggleNewAuthorization,
    saveNewAuthorization,
    onAddApprovedUnit,
    onRemoveApprovedUnit,
    newApprovedUnits,
    onChangeApprovedUnitCode,
    onChangeApprovedUnitAmount,
    availableInsuranceCodes,
    newAuthorizationType,
    authorizationCodes,
    newPendingAuth,
    open,
  } = props;

  const [sortedClinicians, setSortedClinicians] = useState([]);

  useEffect(() => {
    if (clinicianList) {
      const sortedList = [...clinicianList].sort((a, b) =>
        a.first_name.localeCompare(b.first_name)
      );
      setSortedClinicians(sortedList);
    }
  }, [clinicianList]);

  const currentPreAuths = authorizationCodes?.filter(
    (code) =>
      new Date(code.start_date) < new Date() &&
      new Date(code.end_date) > new Date() &&
      code.authorization_type === "pre_auth"
  );

  let newApprovedUnitsHaveMissingInformation = newApprovedUnits?.some(
    (unit) => !unit.code || !unit.units || !unit.hours || !unit.codeId
  );

  const saveAuthDisabled =
    (!newAuthorizationCode && !newPendingAuth) ||
    !newAuthorizationStartDate ||
    !newAuthorizationEndDate ||
    !newAuthorizationType ||
    (newAuthorizationType !== "secondary_auth" &&
      (!newAuthorizationClinicianId ||
        newApprovedUnits.length == 0 ||
        newApprovedUnitsHaveMissingInformation));
  const authCodes =
    newAuthorizationType === "re_auth"
      ? availableInsuranceCodes.filter((code) => !!code.re_auth)
      : availableInsuranceCodes;

  return (
    <Dialog
      maxWidth="md"
      open={open}
      onClose={onToggleNewAuthorization}
      aria-labelledby="new-authorization-title"
    >
      <DialogTitle id="new-authorization-title">
        Add Authorization Period
        <IconButton aria-label="close" onClick={onToggleNewAuthorization}>
          <X />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ zIndex: 2010 }}>
        <Grid
          container
          justifyContent="space-between"
          spacing={"16px"}
          classes={{ root: classes.gridRoot }}
        >
          {newAuthorizationType !== "secondary_auth" && (
            <>
              <Grid item xs={6} align="left">
                <TextField
                  select
                  label="Authorization Type"
                  id="auth-type"
                  onChange={onChange("newAuthorizationType")}
                  value={newAuthorizationType}
                  fullWidth
                >
                  <MenuItem disabled value="">
                    Select Type
                  </MenuItem>
                  <MenuItem value={"pre_auth"}>Pre-Authorization</MenuItem>
                  <MenuItem value={"auth"}>Ongoing Authorization</MenuItem>
                  <MenuItem value={"secondary_auth"}>Secondary Authorization</MenuItem>
                  {!currentPreAuths?.length && (
                    <MenuItem value={"re_auth"}>Re-Authorization</MenuItem>
                  )}
                </TextField>
              </Grid>
              <Grid item xs={6} align="left">
                {newAuthorizationType !== "secondary_auth" && (
                  <>
                    <TextField
                      select
                      label="Approved Clinician"
                      id="auth-clinician"
                      onChange={onChange("newAuthorizationClinicianId")}
                      value={newAuthorizationClinicianId}
                      fullWidth
                      disabled={!newAuthorizationType}
                    >
                      <MenuItem disabled value={""}>
                        Select clinician
                      </MenuItem>
                      {sortedClinicians?.map((clinician) => {
                        return (
                          <MenuItem key={clinician.clinician_id} value={clinician.clinician_id}>
                            {clinician.first_name} {clinician.last_name}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </>
                )}
              </Grid>
              <Grid item xs={12} align="left" margin={"8px 0"}>
                <Alert
                  action={
                    <Switch
                      checked={newPendingAuth}
                      onChange={onChange("newPendingAuth")}
                      inputProps={{
                        "aria-label": "Temporary Authorization and Pending Credentials",
                      }}
                    />
                  }
                  severity="info"
                >
                  <AlertTitle>Temporary Authorization and Pending Credentials</AlertTitle>
                  Turning on Temporary Authorization and Pending Credentials allows AnswersNow to
                  see the client while authorizations and BCBA credentials are pending approvals
                </Alert>
              </Grid>
              <Grid item xs={4} align="left">
                <ANDatePicker
                  label="Start Date"
                  value={newAuthorizationStartDate ? new Date(newAuthorizationStartDate) : null}
                  onChange={(date) => onChangeDate("newAuthorizationStartDate", date)}
                  placeholder="Pick a date"
                  format="PP" //"MMM d, yyyy"
                  disabled={!newAuthorizationType}
                  slotProps={{
                    field: { fullWidth: true },
                    popper: { disablePortal: false, style: { zIndex: 4000 } },
                  }}
                />
              </Grid>
              <Grid item xs={4} align="left">
                <ANDatePicker
                  label="End Date"
                  value={newAuthorizationEndDate ? new Date(newAuthorizationEndDate) : null}
                  onChange={(date) => onChangeDate("newAuthorizationEndDate", date)}
                  placeholder="Pick a date"
                  format="PP" //"MMM d, yyyy"
                  fullWidth
                  disabled={!newAuthorizationType}
                  slotProps={{
                    field: { fullWidth: true },
                    popper: { disablePortal: false, style: { zIndex: 4000 } },
                  }}
                />
              </Grid>
              <Grid item xs={4} align="left">
                <TextField
                  label="Authorization Number"
                  id="authorizationCode"
                  placeholder="Enter authorization code"
                  onChange={onChange("newAuthorizationCode")}
                  value={newAuthorizationCode}
                  variant="outlined"
                  fullWidth
                  disabled={!newAuthorizationType || newPendingAuth}
                />
              </Grid>
            </>
          )}

          {newAuthorizationType === "secondary_auth" && (
            <>
              <Grid item xs={6} align="left">
                <TextField
                  select
                  label="Authorization Type"
                  id="auth-type"
                  onChange={onChange("newAuthorizationType")}
                  value={newAuthorizationType}
                  fullWidth
                >
                  <MenuItem disabled value="">
                    Select Type
                  </MenuItem>
                  <MenuItem value={"pre_auth"}>Pre-Authorization</MenuItem>
                  <MenuItem value={"auth"}>Ongoing Authorization</MenuItem>
                  <MenuItem value={"secondary_auth"}>Secondary Authorization</MenuItem>
                  {!currentPreAuths?.length && (
                    <MenuItem value={"re_auth"}>Re-Authorization</MenuItem>
                  )}
                </TextField>
              </Grid>
              <Grid item xs={6} align="left">
                <TextField
                  label="Authorization Number"
                  id="authorizationCode"
                  placeholder="Enter authorization code"
                  onChange={onChange("newAuthorizationCode")}
                  value={newAuthorizationCode}
                  variant="outlined"
                  fullWidth
                  disabled={!newAuthorizationType || newPendingAuth}
                />
              </Grid>
              <Grid item xs={6} sx={{ marginTop: "24px" }}>
                <ANDatePicker
                  label="Start Date"
                  value={newAuthorizationStartDate}
                  onChange={(date) => onChangeDate("newAuthorizationStartDate", date)}
                  slotProps={{
                    field: { fullWidth: true },
                    popper: { disablePortal: false, style: { zIndex: 4000 } },
                  }}
                />
              </Grid>
              <Grid item xs={6} sx={{ marginTop: "24px" }}>
                <ANDatePicker
                  label="End Date"
                  value={newAuthorizationEndDate}
                  onChange={(date) => onChangeDate("newAuthorizationEndDate", date)}
                  slotProps={{
                    field: { fullWidth: true },
                    popper: { disablePortal: false, style: { zIndex: 4000 } },
                  }}
                />
              </Grid>
            </>
          )}
        </Grid>

        {newAuthorizationType !== "secondary_auth" && newApprovedUnits.length > 0 && (
          <>
            <Card variant="outlined" style={{ margin: "24px 0" }}>
              <Grid container style={{ backgroundColor: "#f2f2f2" }}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={3} style={{ display: "flex", alignItems: "center" }}>
                      <Typography variant="subtitle1" style={{ padding: "16px" }}>
                        Billing Code
                      </Typography>
                    </Grid>
                    <Grid item xs={3} style={{ display: "flex", alignItems: "center" }}>
                      <Typography variant="subtitle1" style={{ padding: "16px" }}>
                        Number of Units
                      </Typography>
                    </Grid>
                    <Grid item xs={3} style={{ display: "flex", alignItems: "center" }}>
                      <Typography variant="subtitle1" style={{ padding: "16px" }}>
                        Number of Hours
                      </Typography>
                    </Grid>
                    <Grid item xs={3} style={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        variant="subtitle1"
                        style={{ padding: "16px", paddingLeft: "32px" }}
                      >
                        Action
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {newApprovedUnits.map((unit, i) => (
                <React.Fragment key={`code-${i}`}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={3} align="left">
                          <TextField
                            select
                            id="type"
                            onChange={(e) => onChangeApprovedUnitCode(i, e)}
                            value={unit.code}
                            fullWidth
                            style={{ padding: "16px 0 16px 16px" }}
                            disabled={!newAuthorizationType}
                          >
                            <MenuItem disabled value="">
                              Select a code
                            </MenuItem>
                            {authCodes.map((code, index) => (
                              <MenuItem key={index} value={code.insurance_code}>
                                {code.insurance_code}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={3} align="left">
                          <TextField
                            id="outlined-helperText"
                            disabled={!unit.code}
                            placeholder="Enter number of units"
                            value={unit.units}
                            type="number"
                            fullWidth
                            style={{ padding: "16px 0 16px 16px" }}
                            InputProps={{ inputProps: { min: 0 } }}
                            onChange={(e) => onChangeApprovedUnitAmount(i, e)}
                          />
                        </Grid>
                        <Grid item xs={3} align="left">
                          <TextField
                            id="outlined-helperText"
                            disabled
                            style={{ padding: "16px 0 16px 16px" }}
                            placeholder="Enter number of hours"
                            value={unit.hours}
                            type="number"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={3} align="left">
                          <Box style={{ padding: "16px 0 16px 16px" }}>
                            <Button
                              color="error"
                              variant="text"
                              onClick={() => onRemoveApprovedUnit(i)}
                            >
                              Delete
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider />
                </React.Fragment>
              ))}
            </Card>
          </>
        )}

        <div className={classes.newAuthorizationButtonContainer}>
          {newAuthorizationType !== "secondary_auth" ? (
            <Button
              onClick={onAddApprovedUnit}
              variant="contained"
              color="secondary"
              startIcon={<AddIcon />}
              disabled={!newAuthorizationType}
            >
              Add Billing Code
            </Button>
          ) : (
            <div />
          )}
        </div>
        <Box width="100%" display="flex" flexDirection="row" justifyContent="flex-end">
          <Button
            onClick={onToggleNewAuthorization}
            style={{ marginRight: "8px" }}
            variant="contained"
            color="secondary"
          >
            Cancel
          </Button>
          <Button disabled={saveAuthDisabled} onClick={saveNewAuthorization}>
            Add Authorization Period
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default NewAuthorization;
