import React from "react";
import withStyles from "@mui/styles/withStyles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import Avatar from "elements/Avatar";
import Typography from "@mui/material/Typography";

const AssignClinicianConfirmDialog = (props) => {
  const {
    classes,
    open,
    closeDialog,
    assignedRole,
    submitAssignClinician,
    selectedClinician,
    confirmSent,
  } = props;
  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      aria-labelledby="form-dialog-title"
      key="switch-clinician-dialog"
      PaperProps={{
        style: {
          margin: 0,
          padding: "20px",
          boxSizing: "border-box",
        },
      }}
    >
      <DialogContent className={classes.dialogContent}>
        <div className={classes.infoContainer}>
          <Avatar imageUrl={selectedClinician.image_url} />
          <div className={classes.textContainer}>
            <Typography component="p" style={{ fontWeight: 500, fontSize: 16 }}>
              You have selected {selectedClinician.name} to be the
              {assignedRole === "primary" ? " Primary" : " Supervisory"} Clinician
            </Typography>
            <Typography className={classes.confirmText}>Please confirm this action.</Typography>
          </div>
        </div>
      </DialogContent>
      <DialogActions style={{ margin: "0px 24px" }}>
        <div style={{ width: "50px" }} />
        <Button
          onClick={submitAssignClinician}
          variant="text"
          className={classes.dialogAction}
          style={{ fontWeight: 500 }}
          disabled={confirmSent}
        >
          Confirm
        </Button>
        <div style={{ flex: "1 0 0" }} />
        <Button onClick={closeDialog} variant="text" className={classes.dialogAction}>
          Cancel
        </Button>
        <div style={{ width: "50px" }} />
      </DialogActions>
    </Dialog>
  );
};

const styles = (theme) => ({
  dialogContent: {
    width: 600,
    height: "fit-content",
    maxHeight: "95%",
    maxWidth: "100%",
    color: "#404040",
  },
  infoContainer: {
    display: "flex",
    alignItems: "flex-start",
  },
  textContainer: {
    width: 450,
    padding: "15px 0 0 10px",
    boxSizing: "border-box",
  },
  confirmText: {
    fontSize: 17,
    fontWeight: 500,
    margin: "20px 0",
  },
  dialogAction: {
    fontSize: 17,
    fontWeight: 500,
  },
});

export default withStyles(styles)(AssignClinicianConfirmDialog);
