import React from "react";
import Modal from "elements/Modal";
import { Typography } from "@mui/material";

const StartDemoCallConfirmDialog = (props) => {
  const { startDemoOpen, toggleStartDemoCallDialog, startDemoCall, setDemoCallLoading } = props;
  return (
    <Modal
      open={startDemoOpen}
      onClose={toggleStartDemoCallDialog}
      title="Start a Demo Call"
      content={
        <div style={{ width: 500 }}>
          <Typography>Are you sure you want to start a demo call?</Typography>
        </div>
      }
      primaryActionText="Start Now"
      primaryActionOnClick={startDemoCall}
      primaryActionDisabled={setDemoCallLoading}
      secondaryActionText="Cancel"
      secondaryActionOnClick={toggleStartDemoCallDialog}
    />
  );
};
export default StartDemoCallConfirmDialog;
