const ACTIONS = {
  SET_BASIC_DETAILS: "SET_BASIC_DETAILS",
  CLEAR_BASIC_DETAILS: "CLEAR_BASIC_DETAILS",
  GET_USER: "GET_USER",
  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  GET_USER_PERMISSIONS: "GET_USER_PERMISSIONS",
  GET_USER_PERMISSIONS_SUCCESS: "GET_USER_PERMISSIONS_SUCCESS",
  UPDATE_USER_ROLES: "UPDATE_USER_ROLES",
  UPDATE_USER_ROLES_SUCCESS: "UPDATE_USER_ROLES_SUCCESS",
  UPDATE_USER_ROLES_FAIL: "UPDATE_USER_ROLES_FAIL",
  CLEAR_UPDATE_USER_ROLES: "CLEAR_UPDATE_USER_ROLES",
  RESET_USER_PASSWORD: "RESET_USER_PASSWORD",
  RESET_USER_PASSWORD_SUCCESS: "RESET_USER_PASSWORD_SUCCESS",
  CLEAR_RESET_USER_PASSWORD_INFO: "CLEAR_RESET_USER_PASSWORD_INFO",
  GET_SEARCH_USER_DETAILS: "GET_SEARCH_USER_DETAILS",
  GET_SEARCH_USER_DETAILS_SUCCESS: "GET_SEARCH_USER_DETAILS_SUCCESS",
  GET_SEARCH_USER_DETAILS_FAIL: "GET_SEARCH_USER_DETAILS_FAIL",
  TOGGLE_USER_ACTIVATION: "TOGGLE_USER_ACTIVATION",
  TOGGLE_USER_ACTIVATION_SUCCESS: "TOGGLE_USER_ACTIVATION_SUCCESS",
  CLEAR_TOGGLE_USER_ACTIVATION: "CLEAR_TOGGLE_USER_ACTIVATION",
};

const ACTION_CREATORS = {
  setBasicDetails(payload) {
    return {
      type: ACTIONS.SET_BASIC_DETAILS,
      payload,
    };
  },
  clearBasicDetails() {
    return {
      type: ACTIONS.CLEAR_BASIC_DETAILS,
    };
  },
  getUser() {
    return {
      type: ACTIONS.GET_USER,
    };
  },
  getUserSuccess(user) {
    return {
      type: ACTIONS.GET_USER_SUCCESS,
      payload: {
        user,
      },
    };
  },
  getUserPermissions(userId) {
    return {
      type: ACTIONS.GET_USER_PERMISSIONS,
      payload: userId,
    };
  },
  getUserPermissionsSuccess(payload) {
    return {
      type: ACTIONS.GET_USER_PERMISSIONS_SUCCESS,
      payload,
    };
  },
  getUserPermissions(userId) {
    return {
      type: ACTIONS.GET_USER_PERMISSIONS,
      payload: userId,
    };
  },
  getUserPermissionsSuccess(payload) {
    return {
      type: ACTIONS.GET_USER_PERMISSIONS_SUCCESS,
      payload,
    };
  },
  updateUserRoles(payload) {
    return {
      type: ACTIONS.UPDATE_USER_ROLES,
      payload,
    };
  },
  updateUserRolesSuccess() {
    return {
      type: ACTIONS.UPDATE_USER_ROLES_SUCCESS,
    };
  },
  updateUserRolesFail() {
    return {
      type: ACTIONS.UPDATE_USER_ROLES_FAIL,
    };
  },
  clearUpdateUserRoles() {
    return {
      type: ACTIONS.CLEAR_UPDATE_USER_ROLES,
    };
  },
  resetUserPassword(userId) {
    return {
      type: ACTIONS.RESET_USER_PASSWORD,
      payload: userId,
    };
  },
  resetUserPasswordSuccess() {
    return {
      type: ACTIONS.RESET_USER_PASSWORD_SUCCESS,
    };
  },
  clearResetUserPasswordInfo() {
    return {
      type: ACTIONS.CLEAR_RESET_USER_PASSWORD_INFO,
    };
  },
  getSearchUserDetails(userId) {
    return {
      type: ACTIONS.GET_SEARCH_USER_DETAILS,
      payload: userId,
    };
  },
  getSearchUserDetailsSuccess(payload) {
    return {
      type: ACTIONS.GET_SEARCH_USER_DETAILS_SUCCESS,
      payload,
    };
  },
  getSearchUserDetailsFail() {
    return {
      type: ACTIONS.GET_SEARCH_USER_DETAILS_FAIL,
    };
  },
  toggleUserActivation(payload) {
    return {
      type: ACTIONS.TOGGLE_USER_ACTIVATION,
      payload,
    };
  },
  toggleUserActivationSuccess() {
    return {
      type: ACTIONS.TOGGLE_USER_ACTIVATION_SUCCESS,
    };
  },
  clearToggleUserActivation(payload) {
    return {
      type: ACTIONS.CLEAR_TOGGLE_USER_ACTIVATION,
      payload,
    };
  },
};

export default { ...ACTIONS, ...ACTION_CREATORS };
