import SecureRequest from "../utils/securerequest";
import { select } from "redux-saga/effects";
import { getBillingItemsPage, getBillingItemsRowsPerPage } from "../selectors";

export function* getBillingItems(billingParams) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  const page = yield select(getBillingItemsPage);
  const size = yield select(getBillingItemsRowsPerPage);
  const params = {
    page,
    size,
    ...billingParams,
  };
  return yield Request.get(`v2/billable-time/billing-items`, { params });
}

export function* editAssociatedBillableTimes(billableItems) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return yield Request.put(`/v2/billable-time/associated-billable-times`, {
    billableItems,
  });
}

export function* approveBillableTime(timeId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return yield Request.put(`/v2/billable-time/${timeId}/approve`, {});
}

export function* holdBillableTime(timeIds) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return yield Request.put(`/v2/billable-time/hold`, { timeIds });
}

export function* releaseBillableTime(timeIds) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return yield Request.put(`/v2/billable-time/release`, { timeIds });
}

export function* requestBillableTimeEdits(billableTime) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return yield Request.put(`/v2/billable-time/${billableTime.timeId}/request-edits`, billableTime);
}

export function* deleteBillableTime(billableTimeId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return yield Request.delete(`/v2/billable-time/${billableTimeId}`);
}

export function* getBillableItemById(data) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  let params = "";
  if (data.viewAllByVideoCall) {
    params = `?viewAllByVideoCall=true`;
  }
  return yield Request.get(`/v2/billable-time/${data.billableTimeId}${params}`);
}

export function* revertToInProgress(billableTimeId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return yield Request.put(`/v2/billable-time/${billableTimeId}/in-progress`, {});
}
