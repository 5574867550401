export default (theme) => ({
  calendarContainer: {
    width: "100%",
  },
  eventTitleContainer: {
    color: "#F0F1F4",
    padding: "20px 0px 20px calc(10% +  10px)",
    "& h5": {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: "20px",
    },
    "& p": {
      fontSize: 13,
      fontWeight: 400,
      lineHeight: "18px",
    },
  },
  eventTimeContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: 10,
    color: "#3F4456",
    "& p": {
      fontSize: 12,
      fontWeight: 500,
      lineHeight: "18px",
    },
  },
  didNotOccurContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: 10,
    paddingLeft: 37,
    color: "#3F4456",
    "& p": {
      fontSize: 14,
      fontWeight: 500,
      color: "#B91C1C",
    },
  },
  eventTime: {
    width: "71%",
    "& p": {
      fontSize: 13,
      fontWeight: 500,
      lineHeight: "24px",
    },
    "& h5": {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: "18px",
    },
  },
  icon: {
    width: "10%",
    fontSize: 14,
    color: "#545a72",
  },
  deleteIcon: {
    alignSelf: "flex-end",
    color: "#ef4444",
    fontSize: 20,
    cursor: "pointer",
  },
  divider: {
    width: "80%",
    margin: "10px 0px 10px 10%",
    border: "0.5px solid #878DA5",
  },
  instructionsContainer: {
    width: "80%",
    marginLeft: "10%",
    color: "#3f4456",
    "& h5": {
      fontSize: 13,
      fontWeight: 500,
      lineHeight: "18px",
    },
    "& p": {
      fontSize: 12,
      fontWeight: 500,
      lineHeight: "18px",
      marginBottom: 7,
    },
  },
  buttonsContainer: {
    width: "80%",
    display: "flex",
    justifyContent: "space-between",
    margin: "15px 0px 5px 10%",
    "& button": {
      height: 35,
      fontSize: 13,
    },
  },
  snackbarRoot: {
    width: "100%",
    height: 50,
    zIndex: 3001,
    top: 64,
    "& .MuiSnackbarContent-root": {
      width: "100%",
      backgroundColor: "#D1FAE5",
      boxShadow: "0px 1px 3px rgba(55, 55, 55, 0.15)",
      color: "#047857",
      fontWeight: 500,
      lineHeight: "18px",
      fontSize: 14,
      letterSpacing: "0.05em",
    },
    "& .MuiSnackbarContent-message": {
      paddingLeft: "5%",
    },
  },
  noSessionNotesDialogTitle: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: "24px",
    marginBottom: 8,
    [theme.breakpoints.down("xl")]: {
      marginTop: 10,
      textAlign: "center",
    },
  },
  noSessionNotesDialogContent: {
    height: "fit-content",
    maxHeight: "95%",
    maxWidth: "100%",
    color: "#404040",
    padding: "20px 20px 16px !important",
  },
  noSessionNotesDialogContentText: {
    fontSize: 15,
    fontWeight: 500,
    lineHeight: "20px",
    color: "#69718F",
  },
  noSessionNotesDialogActionsContainer: {
    backgroundColor: "#F0F1F4",
    padding: "12px 20px",
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
    position: "relative",
    [theme.breakpoints.down("xl")]: {
      display: "flex",
      flexDirection: "column",
    },
    "& button": {
      height: 35,
      fontSize: 13,
    },
  },
  allCalendarSessions: theme.bigCalendarCustomStyle,
});
