import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import MenuItem from "@mui/material/MenuItem";
import withStyles from "@mui/styles/withStyles";
import styles from "../styles";
import ANDatePicker from "elements/ANDatePicker";
import { isDischargeReason, isDropReason } from "../../../utils/reason";
import { useGlobalToast } from "components/GlobalToastProvider";
import { Box } from "@mui/material";
import DeactivateUserDialog from "../Dialogs/DeactivateUserDialog";
import ReassignClientsBeforeDeactivationDialog from "../Dialogs/ReassignClientsBeforeDeactivationDialog";

const ClinicianActivation = (props) => {
  const {
    onToggleActivationDialog,
    onSubmitToggleActivation,
    reason,
    basicInfo,
    dropReason,
    churnReason: dischargeReason,
    departedTo,
    customerDepartureOptions,
    disabledDate,
    onChangeDeactivationInfo,
    onClearDeactivationInfo,
    customerDeactivationReasons,
    isActive,
    isRoleClinician,
    clinicianDeactivationReasons,
    customerDropReasons,
    customerChurnReasons: customerDischargeReasons,
    numberOfClients,
    hasScheduledCall,
    userPermissions,
    handleToggleDeactivateApplicant,
    toggleUserActivation,
  } = props;
  // State for dialog visibility
  const [dialogOpen, setDialogOpen] = useState(false);

  // Dialog open/close handlers
  const handleDialogClose = () => setDialogOpen(false);

  // Deactivation reasons based on role
  const deactivationReasons = isRoleClinician
    ? clinicianDeactivationReasons
    : customerDeactivationReasons;

  const dropReasons = customerDropReasons ?? [];
  const dischargeReasons = customerDischargeReasons ?? [];
  const departureOptions = customerDepartureOptions ?? [];

  const enableDropReasons = (dropReasons && isDropReason(reason)) ?? false;
  const enableDischargeReasons =
    (!enableDropReasons && dischargeReasons && isDischargeReason(reason)) ?? false;

  const { showToast } = useGlobalToast();
  const activationHandler = () => {
    try {
      onSubmitToggleActivation();
      onClearDeactivationInfo();
      handleDialogClose();
      showToast({
        message: `Success! Clinician account ${isActive ? "deactivated" : "activated"}`,
      });
    } catch (e) {
      console.error(e);
      showToast({
        errorState: true,
        message: `Whoops! Clinician account ${isActive ? "deactivation" : "activation"} failed`,
      });
    }
  };

  return (
    <Box minWidth={"max-content"}>
      <DeactivateUserDialog
        isActive={basicInfo?.is_active}
        activationDialogOpen={dialogOpen}
        clinicianDeactivationReasons={deactivationReasons}
        {...props}
      />
    </Box>
  );
};

export default withStyles(styles)(ClinicianActivation);
