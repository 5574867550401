import React from "react";
import { Box, DialogTitle, IconButton } from "@mui/material";
import { X } from "@phosphor-icons/react";

/**
 * VideoPlayerHeader component displays the header for the video player, including the title
 * and a close button. The header's visibility is controlled by the `controlsVisible` prop.
 *
 * @param {Object} props - The props for the component.
 * @param {string} props.videoPlayerTitle - The title to display in the video player header.
 * @param {boolean} props.controlsVisible - Flag to control the visibility of the header.
 * @param {Object} props.modalControl - Control object for the modal, including the close function.
 * @returns {JSX.Element} The rendered component.
 */
const VideoPlayerHeader = ({ videoPlayerTitle, controlsVisible, modalControl }) => {
  return (
    <Box
      className="controls"
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        backgroundColor: "white",
        opacity: controlsVisible ? 1 : 0,
        transition: "opacity 0.3s",
        padding: "24px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <DialogTitle
        id="form-dialog-title"
        align="center"
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: 0,
          padding: 0,
        }}
      >
        {/* Close button positioned on the left */}
        <IconButton
          onClick={modalControl?.closeModal}
          sx={{
            position: "absolute",
            left: "24px",
          }}
        >
          <X fontSize={"medium"} />
        </IconButton>
        <span>{videoPlayerTitle}</span>
      </DialogTitle>
    </Box>
  );
};

export default VideoPlayerHeader;
