import {
  createUnsignedRequestAsync,
  unlinkPhysicianAsync,
  updateLinkPhysicianAsync,
  updateLinkedPhysician,
} from "../../../../api/customers";
import { useMutation } from "react-query";

const useProvidersFormDialogMutations = (props) => {
  const createUnsignedRequestMutation = useMutation({
    mutationFn: createUnsignedRequestAsync,
  });
  const linkPhysicianMutation = useMutation({
    mutationFn: updateLinkPhysicianAsync,
  });
  const updatePhysicianMutation = useMutation({
    mutationFn: updateLinkedPhysician,
  });
  const unlinkPhysicianMutation = useMutation({
    mutationFn: unlinkPhysicianAsync,
  });

  return {
    createUnsignedRequestMutation,
    linkPhysicianMutation,
    updatePhysicianMutation,
    unlinkPhysicianMutation,
  };
};

export default useProvidersFormDialogMutations;
