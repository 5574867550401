import React from "react";

import ANFormView from "elements/Forms/ANFormView";
import insuranceDetailConfig from "./InsuranceDetailsConfig";

const InsuranceDetailsView = ({ insurance }) => {
  return (
    <>
      <ANFormView data={insurance} config={insuranceDetailConfig} />
    </>
  );
};

export default InsuranceDetailsView;
