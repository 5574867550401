import { getTeamRoles } from "api/team";
import { GET_TEAM_ROLES } from "constants/reactQueryKeys";
import { useQuery } from "react-query";
import { queryParamsObjIsValid } from "utils/queryParamsObjIsValid";

export const useGetTeamRoles = (query) => {
  const { data, isLoading, ...rest } = useQuery(
    [GET_TEAM_ROLES, { query }],
    () => getTeamRoles(query),
    {
      enabled: queryParamsObjIsValid(query),
    }
  );
  return {
    teamRoles: data?.data || null,
    teamRolesLoading: isLoading,
    ...rest,
  };
};
