import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Typography,
  Box,
  Divider,
} from "@mui/material";
import moment from "moment";
import { X } from "@phosphor-icons/react";

const ViewAuthorization = ({ open, onClose, data = {} }) => {
  // Extract and format the start and end dates
  const formattedStartDate = moment(data.start_date).utc().format("M/D/YYYY");
  const formattedEndDate = moment(data.end_date).utc().format("M/D/YYYY");

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle>
        {formattedStartDate} - {formattedEndDate}
        <IconButton aria-label="close" onClick={onClose}>
          <X />
        </IconButton>
      </DialogTitle>
      {data.approvedUnits && data.approvedUnits.length > 0 ? (
        <Table aria-label="authorization details">
          <TableHead>
            <TableRow>
              <TableCell>Billing Code</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Units</TableCell>
              <TableCell>Authorized Hours</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.approvedUnits.map((unit) => (
              <TableRow key={unit.id}>
                <TableCell component="th" scope="row">
                  {unit.code.split(" - ")[0]}
                </TableCell>
                <TableCell>{unit.code.split(" - ")[1]}</TableCell>
                <TableCell>{unit.approved_units}</TableCell>
                <TableCell>{unit.approved_time}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Box>
          <Divider />
          <Box
            minHeight={"100px"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
          >
            <Typography variant="subtitle1" align="center">
              No approved units.
            </Typography>
          </Box>
          <Divider />
        </Box>
      )}
      <Box sx={{ display: "flex", justifyContent: "flex-end", margin: "16px" }}>
        <Button onClick={onClose} color="default" variant="outlined">
          Close
        </Button>
      </Box>
    </Dialog>
  );
};

export default ViewAuthorization;
