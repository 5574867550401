import {
  getClinicianClientsPage,
  getClinicianClientsPerPage,
  getClinicianClientsSort,
  getClinicianClientsSortDirection,
  getClinicianPastClientsPage,
  getClinicianPastClientsSort,
  getClinicianPastClientsSortDirection,
  getUserId,
} from "../selectors";

import { select } from "redux-saga/effects";
import SecureRequest from "../utils/securerequest";
/**
 * @typedef {import("api/typesDef").License} License
 * @typedef {import("api/typesDef").Certification} Certification
 * @typedef {import("api/typesDef").LiabilityInsurance} LiabilityInsurance
 */

export function* getClinicianStatus(clinicianId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/v2/clinician/${clinicianId}/status`);
}

export function* getClinicianBasicInfo(clinicianId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/v2/clinician/${clinicianId}/basic`);
}

/**
 * @deprecated
 * This function has been deprecated, in favor of updateClinicianProfileAsync
 * to be used in a useQuery.
 */
export function* saveClinicianBasicInfo(basicInfo) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.put(`/v2/clinician/${basicInfo.clinicianId}/basic`, basicInfo);
}

export function* saveClinicianDriversLicense(licenseData) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.put(`/v2/clinician/${licenseData.clinicianId}/drivers-license`, licenseData);
}

/**
 * @deprecated
 * This function has been deprecated, in favor of getClinicianCredentialsAsync
 * to be used in a useQuery.
 */
export function* getClinicianCredentials(clinicianId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/v2/clinician/${clinicianId}/credentials`);
}

export async function getClinicianCredentialsAsync(clinicianId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.get(`/v2/clinician/${clinicianId}/credentials`);
}

/**
 * @deprecated
 * This function has been deprecated, in favor of saveClinicianCredentialsAsync
 * to be used in a mutation.
 */
export function* saveClinicianCredentials(credentialData) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.put(
    `/v2/clinician/${credentialData.clinicianId}/credentials`,
    credentialData
  );
}

export async function saveClinicianCredentialsAsync(credentialData) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.put(
    `/v2/clinician/${credentialData.clinicianId}/credentials`,
    credentialData
  );
}

/**
 * @deprecated
 * This function has been deprecated, in favor of getClinicianStateLicenseAsync
 * to be used in a useQuery.
 */
export function* getClinicianStateLicense(clinicianId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/v2/clinician/${clinicianId}/state-license`);
}

/**
 * @param {String} clinicianId - The clinician id
 * @returns {Promise<{data: License[]}>} The promise object of the API request
 */
export async function getClinicianStateLicenseAsync(clinicianId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.get(`/v2/clinician/${clinicianId}/state-license`);
}

export function* getClinicianStateLicenseById(license) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(
    `/v2/clinician/${license.clinicianId}/state-license/${license.licenseId}`
  );
}

/**
 * @deprecated
 * This function has been deprecated, in favor of saveClinicianStateLicenseAsync
 * to be used in a useQuery.
 */
export function* saveClinicianStateLicense(licenseData) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.post(`/v2/clinician/${licenseData.clinicianId}/state-license`, licenseData);
}

export async function saveClinicianStateLicenseAsync(licenseData) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.post(`/v2/clinician/${licenseData.clinicianId}/state-license`, licenseData);
}

/**
 * @deprecated
 * This function has been deprecated, in favor of updateClinicianStateLicenseAsync
 * to be used in a useQuery.
 */
export function* updateClinicianStateLicense(licenseData) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.put(
    `/v2/clinician/${licenseData.clinicianId}/state-license/${licenseData.licenseId}`,
    licenseData
  );
}

export async function updateClinicianStateLicenseAsync(licenseData) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.put(
    `/v2/clinician/${licenseData.clinicianId}/state-license/${licenseData.licenseId}`,
    licenseData
  );
}

/**
 * @deprecated
 * This function has been deprecated, in favor of deleteClinicianStateLicenseAsync
 * to be used in a useQuery.
 */
export function* deleteClinicianStateLicense(licenseData) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.delete(
    `/v2/clinician/${licenseData.clinicianId}/state-license/${licenseData.licenseId}`
  );
}

export async function deleteClinicianStateLicenseAsync(licenseData) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.delete(
    `/v2/clinician/${licenseData.clinicianId}/state-license/${licenseData.licenseId}`
  );
}

/**
 * @param {String} clinicianId - The clinician id
 * @returns {Promise<{data: Certification[]}>} The promise object of the API request
 */
export async function getClinicianCertificationAsync(clinicianId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.get(`/v2/clinician/${clinicianId}/certification`);
}

/**
 * @deprecated
 * This function has been deprecated, in favor of saveClinicianCertificationAsync
 * to be used in a useQuery.
 */
export function* saveClinicianCertification(certData) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.put(`/v2/clinician/${certData.clinicianId}/certification`, certData);
}

export async function saveClinicianCertificationAsync(certData) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.post(`/v2/clinician/${certData.clinicianId}/certification`, certData);
}

export async function updateClinicianCertificationAsync(certData) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.put(
    `/v2/clinician/${certData.clinicianId}/certification/${certData.id}`,
    certData
  );
}

export async function deleteClinicianCertificationAsync(certData) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.delete(`/v2/clinician/${certData.clinicianId}/certification/${certData.id}`);
}

export function* getClinicianInsurances(clinicianId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/v2/clinician/${clinicianId}/insurance`);
}

/**
 *
 * @param {{
 *
 * state,
 * insuranceId,
 * insurancePlanId,
 * initialCredentialDate,
 * approvalDate,
 * recredentialDate,
 * termDate,
 * submittedDate,
 * documents --> array of strings ["key_1", "key_2"]
 *
 * }} data
 */
export function* updateClinicianInsurance(insuranceData) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.put(
    `/v2/clinician/${insuranceData.clinicianId}/insurance/${insuranceData.clinicianInsuranceId}`,
    insuranceData
  );
}

/**
 *
 * @param {{
 *
 * state,
 * insuranceId,
 * insurancePlanId,
 * initialCredentialDate,
 * approvalDate,
 * recredentialDate,
 * termDate,
 * submittedDate,
 * documents --> array of strings ["key_1", "key_2"]
 *
 * }} data
 */
export function* addClinicianInsurance(insuranceData) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.post(`/v2/clinician/${insuranceData.clinicianId}/insurance`, insuranceData);
}

export function* getClinicianAvailability(clinicianId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/v2/clinician/${clinicianId}/availability`);
}

export function* saveClinicianAvailability(availabilityData) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.put(
    `/v2/clinician/${availabilityData.clinicianId}/availability`,
    availabilityData
  );
}

export function* getClinicianDocuments(clinicianId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/v2/clinician/${clinicianId}/documents`);
}

/**
 * @deprecated
 * This function has been deprecated, in favor of saveClinicianDocumentAsync
 * to be used in a useQuery.
 */
export function* saveClinicianDocument(documentData) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.post(`/v2/clinician/${documentData.clinicianId}/upload`, documentData);
}

export async function saveClinicianDocumentAsync(documentData) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.post(`/v2/clinician/${documentData.clinicianId}/upload`, documentData);
}

/**
 * @deprecated
 * This function has been deprecated, in favor of replaceClinicianDocumentAsync
 * to be used in a useQuery.
 */
export function* replaceClinicianDocument(documentData) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.put(
    `/v2/clinician/${documentData.clinicianId}/document/${documentData.documentId}/replace`,
    documentData
  );
}

export async function replaceClinicianDocumentAsync(documentData) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.put(
    `/v2/clinician/${documentData.clinicianId}/document/${documentData.id}/replace`,
    documentData
  );
}

export async function deleteClinicianDocumentAsync(documentData) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.delete(
    `/v2/clinician/${documentData.clinicianId}/document/${documentData.id}`
  );
}

export function* rejectClinicianDocument(documentData) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.put(
    `/v2/clinician/${documentData.clinicianId}/document/${documentData.documentId}/reject`,
    documentData
  );
}

export function* approveClinicianDocument(documentData) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.put(
    `/v2/clinician/${documentData.clinicianId}/document/${documentData.documentId}/approve`,
    {}
  );
}

/**
 * @deprecated
 * This function has been deprecated, in favor of updateClinicianProfileAsync
 * to be used in a useQuery.
 */
export function* updateClinicianBio(clinician) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.put(`/v2/clinician/${clinician.clinicianId}/bio`, clinician);
}

export function* updateClinicianPicture(clinician) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.put(`/v2/clinician/${clinician.clinicianId}/photo`, clinician);
}

export function* approveClinicianApplication(clinicianId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.put(`/v2/clinician/${clinicianId}/approve`, {});
}

export function* rejectClinicianApplication(clinicianData) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.put(
    `/v2/clinician/${clinicianData.clinicianId}/request-changes`,
    clinicianData
  );
}

export function* setClinicianCredentialed(clinicianId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.put(`/v2/clinician/${clinicianId}/credentialed`, {});
}

export function* updateClinicianStatus(clinician) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.put(`/v2/clinician/${clinician.clinicianId}/status`, clinician);
}

export function* syncClinicianCalendar(clinicianId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.post(`/v2/clinician/${clinicianId}/sync-calendar`, {});
}

export function* getClinicianDashboard(clinicianId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/v2/clinician/${clinicianId}/dashboard`);
}

export function* getClinicianClients(id) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  const page = yield select(getClinicianClientsPage);
  const size = yield select(getClinicianClientsPerPage);
  const sort = yield select(getClinicianClientsSort);
  const direction = yield select(getClinicianClientsSortDirection);
  const userId = id ? id : yield select(getUserId);
  const params = {
    page,
    size,
    sort,
    direction,
  };
  return yield Request.get(`v2/clinician/${userId}/clients`, { params });
}

export function* getClinicianPastClients(id) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  const page = yield select(getClinicianPastClientsPage);
  const size = yield select(getClinicianClientsPerPage);
  const sort = yield select(getClinicianPastClientsSort);
  const direction = yield select(getClinicianPastClientsSortDirection);
  const userId = id ? id : yield select(getUserId);
  const params = {
    page,
    size,
    sort,
    direction,
  };

  return yield Request.get(`/v2/clinician/${userId}/clients/inactive`, {
    params,
  });
}

export function* getClinicianPayStructure(clinicianId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/v2/clinician/${clinicianId}/pay-structure`);
}

export function* saveClinicianPayStructure(structureData) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.post(
    `/v2/clinician/${structureData.clinicianId}/pay-structure`,
    structureData
  );
}

export function* deleteClinicianBonus(clinicianId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.put(`/v2/clinician/${clinicianId}/pay-structure/bonus`, clinicianId);
}

export function* inviteClinician(clinicianId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.post(`/v2/clinician/${clinicianId}/invite`, {});
}

export function* sendBackgroundCheckSignature(data) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.post(`/v2/clinician/${data.clinicianId}/background-check-signature`, data);
}

export function* rejectClinicianApplicant(clinician) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.put(`/v2/clinician/${clinician.clinicianId}/reject`, clinician);
}

export function* interviewClinicianApplicant(clinicianId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.put(`/v2/clinician/${clinicianId}/interview`, {});
}

export function* updateLiabilityExpirationDate(data) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.put(`/v2/clinician/${data.clinicianId}/liability-expiration`, data);
}

/**
 * @deprecated
 * This function has been deprecated, in favor of updateClinicianProfileAsync
 * to be used in a useQuery.
 */
export function* updateClinicianEmail(data) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.put(`/v2/clinician/${data.clinicianId}/email`, data);
}

export function* getClinicianClientStatus(clinicianId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/v2/clinician/${clinicianId}/clients/status`);
}

export function* getClinicianNotes(clinicianId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/v2/clinician/${clinicianId}/notes`);
}

export function* saveClinicianNote(data) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.post(`/v2/clinician/${data.clinicianId}/note`, data);
}

export function* editClinicianNote(data) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.put(`/v2/clinician/${data.noteId}/note`, data);
}

export function* getClinicianNotifications(clinicianId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`/v2/clinician/${clinicianId}/notifications`);
}

export function* dismissClinicianNotification(data) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  let params = "";
  if (data.clientId) {
    params = `?clientid=${data.clientId}`;
  }
  return yield Request.put(`/v2/clinician/${data.clinicianId}/dismiss-notification${params}`, data);
}

export function* getClinicianCallsNeverStarted(clinicianId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get(`v2/clinician/${clinicianId}/calls-never-started`);
}

/**
 *
 * @param {{
 *   page: Number,
 *   size: Number,
 *   state: String,
 *   insuranceId: String
 * }} data
 * @returns {{
 *   credentials: {}[],
 *   credentialed_count: Number,
 *   state_count: Number,
 *   count: Numeber
 * }} res
 */
export const getClinicianCredentialData = async (params) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const { clinicianId } = params;
  return await Request.get(`/v2/clinician/${clinicianId}/insurance-credentials`, {
    params,
  });
};

export const getClinicianWorkingHours = async (clinicianId) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.get(`/v2/clinician/${clinicianId}/working-hours`);
};

export const getAppointmentAvailability = async (params) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.get(`/v2/clinician/${params?.clinicianId}/appointment-availability`, {
    params,
  });
};

export const updateClinicianWorkingHours = async (params) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const { clinicianId } = params;
  return await Request.post(`/v2/clinician/${clinicianId}/working-hours`, params);
};

export const syncClinicianCalendarToOnSched = async (params) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.post(`/v2/clinician/${params.clinicianId}/calendar-sync`, params);
};

export const setSuccessfulCalendarSync = async (clinicianId) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.put(`/v2/clinician/${clinicianId}/calendar-synced`, {});
};

/**
 * @param {String} clinicianId - The clinician id
 * @returns {Promise<{data: LiabilityInsurance[]}>} The promise object of the API request
 */
export async function getClinicianLiabilityInsurance(clinicianId) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.get(`/v2/clinician/${clinicianId}/liability_insurance`);
}

/**
 * updateClinicianProfileAsync updates a clinician's profile using a PUT request to
 * `/v3/clinician/[clinicianId]/profile` endpoint.
 * @param {Object} payload - The `payload` object contains the data needed to update a clinician's profile.
 * @param {String} payload.clinicianId - The clinician id
 * @returns {Promise} The promise object of the API request
 */
export async function updateClinicianProfileAsync(payload) {
  const { clinicianId } = payload;
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return await Request.put(`/v3/clinician/${clinicianId}/profile`, payload);
}
