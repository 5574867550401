import React from "react";
import withStyles from "@mui/styles/withStyles";
import styles from "./styles";

const StatusIndicator = (props) => {
  const { classes, onlineStatus } = props;

  return <div className={onlineStatus ? classes.indicatorOnline : classes.indicatorOffline} />;
};

export default withStyles(styles, { withTheme: true })(StatusIndicator);
