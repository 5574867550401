import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withStyles from "@mui/styles/withStyles";
import actions from "../../actions";
import styles from "../Signin/styles";
import ResetPassword from "../ResetPassword/ResetPassword";
import {
  getSignedinState,
  getPasswordChange,
  getUserAttributes,
  isLoaded,
  getLogin,
  getResetPasswordSuccess,
  getResetPasswordError,
  getResetPasswordErrorMessage,
  getUser,
  isClinician,
  isCaregiver,
} from "selectors";

const mapStateToProps = (state) => ({
  signedInState: getSignedinState(state),
  passwordChange: getPasswordChange(state),
  userAttributes: getUserAttributes(state),
  loaded: isLoaded(state),
  login: getLogin(state),
  username: getLogin(state),
  resetPasswordSuccess: getResetPasswordSuccess(state),
  resetPasswordError: getResetPasswordError(state),
  resetPasswordErrorMessage: getResetPasswordErrorMessage(state),
  isClinician: isClinician(state),
  isClient: isCaregiver(state),
  user: getUser(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      checkAuthState: actions.checkAuthState,
      changeUserPassword: actions.changeUserPassword,
      getUser: actions.getUser,
      setUserAttributes: actions.setUserAttributes,
      clearResetPassword: actions.clearResetPassword,
      setPageDetails: actions.setPageDetails,
    },
    dispatch
  );

function getInitialState(props) {
  return {
    username: props.username || "",
    currentPassword: "",
    verificationCode: "ignoreme",
    success: false,
  };
}

class ChangePasswordContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...getInitialState(this.props),
    };
  }

  componentDidMount() {
    this.props.setPageDetails({
      pageName: "Change Password?",
    });
    this.props.clearResetPassword();
    this.props.checkAuthState();

    if (this.props.login) {
      this.props.getUser();
    }
    if (this.props.loaded) {
      this.props.setUserAttributes({ username: this.props.username });
      this.setState(getInitialState(this.props));
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.loaded && this.props.loaded) {
      this.props.setUserAttributes({ username: this.props.username });
      this.setState(getInitialState(this.props));
    }
    if (!prevProps.resetPasswordSuccess && this.props.resetPasswordSuccess) {
      this.goToDashboard();
    }
  }

  goToSignin() {
    this.props.history.push("/");
  }

  goToDashboard = () => {
    this.props.history.push("/dashboard");
  };

  onChange = (name) => (e) => {
    const { checked, type, value } = e.target;
    const val = type === "checkbox" ? checked : value;

    this.setState({ [name]: val });
  };

  onSubmit = (values) => {
    this.props.changeUserPassword({
      ...this.state,
      ...values,
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.container}>
        <ResetPassword
          {...this.props}
          {...this.state}
          onChange={this.onChange}
          onSubmit={this.onSubmit}
          change={true}
        />
      </div>
    );
  }
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(ChangePasswordContainer)
);
