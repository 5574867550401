// hooks/useUpdateSupervisor.js
import { useMutation, useQueryClient } from "react-query";
import { updateSupervisorApi } from "api/clinicians";

export const useUpdateSupervisor = () => {
  const queryClient = useQueryClient();

  const { mutate, isLoading, isError, error, isSuccess, data } = useMutation(
    ({ clinicianId, supervisorUserId }) => {
      updateSupervisorApi({ clinicianId, supervisorUserId });
    },
    {
      onSuccess: () => {
        // Invalidate queries that may be affected by this mutation
        queryClient.invalidateQueries("clinicians");
      },
      onError: (error) => {
        console.error("Error updating supervisor:", error);
      },
    }
  );

  return {
    updateSupervisor: mutate,
    isLoading,
    isError,
    error,
    isSuccess,
    data,
  };
};
