import React from "react";
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Hidden from "@mui/material/Hidden";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import LockIcon from "@mui/icons-material/Lock";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import styles from "./styles";
import StatusIndicator from "./StatusIndicator";
import getChannelName from "./utils/getChannelName";
import getChannelUsers from "./utils/getChannelUsers";
import getChannelInfo from "./utils/getChannelInfo";
import getChannelParentName from "./utils/getChannelParentName";
import { sortBy, partition } from "lodash";
import Logo from "../../assets/an_purple_gray.png";

const StyledBadge = withStyles((theme) => ({
  badge: {
    top: "50%",
    right: "-15px",
    zIndex: 0,
  },
}))(Badge);

const ChatList = (props) => {
  const {
    classes,
    theme,
    chatType,
    userId,
    handleDrawerToggle,
    handleDrawerClose,
    openGroupDialog,
    attemptToLeave,
    attemptToJoin,
    mobileOpen,
    connection,
    userList,
    goToChannel,
    getChannel,
    currentChannel,
    displayBack,
    onBackClick,
    privateChannels,
    publicChannelsJoined,
    publicChannelsUnjoined,
    canJoinPremiumCommunity,
    userViewing,
    screenWidth,
    isSupport,
    isSupervisor,
    isDirector,
    primaryClinicianUserId,
  } = props;
  let partitionedChannelList = (channels) => {
    let sortedChannels = partition(channels, (channel) => {
      if (!channel.data) {
        return false;
      }
      let data = JSON.parse(channel.data);
      return data.bcbaName ? true : false;
    });
    return sortedChannels;
  };
  const currentUser = connection && connection.currentUser ? connection.currentUser : null;
  const isBcba = currentUser && currentUser.metaData && currentUser.metaData.isBCBA ? true : false;
  const goBack = () => {
    if (displayBack) {
      onBackClick();
    }
  };
  let isSupportChannelList = [];
  let bcbaChatChannelList = [];
  isSupportChannelList = partitionedChannelList(privateChannels)[0];
  bcbaChatChannelList = partitionedChannelList(privateChannels)[1].filter(
    (channel) => channel.name === "BCBA Chat",
  );
  let privateChannelList =
    isBcba || isSupport || isSupervisor || isDirector || privateChannels.length <= 1
      ? partitionedChannelList(privateChannels)[1].filter((channel) => channel.name !== "BCBA Chat")
      : sortBy(privateChannels, (item) => {
          return item.members.find((member) => member.metaData.isBCBA) ? 0 : 1;
        });
  const getChannelSecurity = (channel) => {
    if (!channel.data) {
      return false;
    }

    const data = JSON.parse(channel.data);
    return (data.isPremium && data.isPremium === "true") || false;
  };
  const drawer = (
    <div>
      <div className={classes.toolbar} onClick={goBack}>
        <img className={classes.logo} src={Logo} />
      </div>
      <Divider />
      {displayBack && (
        <List>
          <ListItem button key="back" onClick={onBackClick}>
            <ListItemText
              primary={<span className={classes.channelName}>&lt; Back</span>}
              primaryTypographyProps={{ variant: "subtitle2" }}
            />
          </ListItem>
          <Divider />
        </List>
      )}

      <div
        style={{
          overflowY: "auto",
          flex: 1,
          height: displayBack ? "80vh" : "90vh",
        }}
      >
        {(isSupportChannelList.length > 0 || bcbaChatChannelList.length > 0) && (
          <List
            subheader={
              <ListSubheader className={classes.subheader}>
                {isSupport ? "Clinicians" : "Internal Channels"}
              </ListSubheader>
            }
          >
            {isSupportChannelList.map((channel) => {
              const selectChat = () => {
                getChannel(channel.url);
                handleDrawerClose();
              };

              const users = getChannelUsers(channel, userId);
              const channelImage = users.find((user) => user.profileUrl);
              const data = JSON.parse(channel.data);
              return (
                <React.Fragment key={channel.url}>
                  <ListItem
                    button
                    disableGutters={true}
                    onClick={selectChat}
                    selected={currentChannel ? currentChannel.url === channel.url : false}
                    classes={{
                      container: classes.chatList,
                    }}
                  >
                    <ListItemText
                      primary={
                        <StyledBadge badgeContent={channel.unreadMessageCount} color="error">
                          {!isBcba && (users[0].profileUrl || channelImage) ? (
                            <ListItemAvatar
                              style={{
                                minWidth: 0,
                                marginRight: 10,
                                marginLeft: 10,
                              }}
                            >
                              <Avatar
                                alt={users[0].nickname}
                                src={channelImage ? channelImage.profileUrl : users[0].profileUrl}
                              />
                            </ListItemAvatar>
                          ) : (
                            <ListItemAvatar
                              style={{
                                minWidth: 0,
                                marginLeft: 10,
                                marginRight: 10,
                              }}
                            >
                              <Avatar>
                                <PersonRoundedIcon
                                  color="action"
                                  style={{
                                    fontSize: 50,
                                    marginTop: 8,
                                  }}
                                />
                              </Avatar>
                            </ListItemAvatar>
                          )}

                          <div
                            className={classes.privateChannelName}
                            title={isBcba ? channel.name : `Chat with ${data.bcbaName}`}
                            alt={getChannelName(channel, userId)}
                          >
                            {isBcba ? channel.name : `Chat with ${data.bcbaName}`}{" "}
                            <Typography className={classes.childInfo} component="div">
                              {channel.lastMessage ? channel.lastMessage.message : " "}
                            </Typography>
                          </div>
                        </StyledBadge>
                      }
                      primaryTypographyProps={{
                        variant: "subtitle2",
                      }}
                    />
                    <ListItemSecondaryAction></ListItemSecondaryAction>
                  </ListItem>
                </React.Fragment>
              );
            })}
            {bcbaChatChannelList.map((channel) => {
              const selectChat = () => {
                getChannel(channel.url);
                handleDrawerClose();
              };

              const users = getChannelUsers(channel, userId);
              const channelImage = users.find((user) => user.profileUrl);
              return (
                <React.Fragment key={channel.url}>
                  <ListItem
                    button
                    disableGutters={true}
                    onClick={selectChat}
                    selected={currentChannel ? currentChannel.url === channel.url : false}
                    classes={{
                      container: classes.chatList,
                    }}
                  >
                    <ListItemText
                      primary={
                        <StyledBadge badgeContent={channel.unreadMessageCount} color="error">
                          <ListItemAvatar
                            style={{
                              minWidth: 0,
                              marginLeft: 10,
                              marginRight: 10,
                            }}
                          >
                            <Avatar>
                              <PersonRoundedIcon
                                color="action"
                                style={{
                                  fontSize: 50,
                                  marginTop: 8,
                                }}
                              />
                            </Avatar>
                          </ListItemAvatar>

                          <div
                            className={classes.privateChannelName}
                            title={channel.name}
                            alt={getChannelName(channel, userId)}
                          >
                            {channel.name}
                            <Typography className={classes.childInfo} component="div">
                              {channel.lastMessage ? channel.lastMessage.message : " "}
                            </Typography>
                          </div>
                        </StyledBadge>
                      }
                      primaryTypographyProps={{
                        variant: "subtitle2",
                      }}
                    />
                    <ListItemSecondaryAction></ListItemSecondaryAction>
                  </ListItem>
                </React.Fragment>
              );
            })}
          </List>
        )}
        {(chatType === "all" || chatType === "private") && (
          <List
            subheader={
              (isSupport || isBcba || isDirector || isSupervisor) &&
              privateChannelList.length > 0 ? (
                <ListSubheader className={classes.subheader}>My Clients</ListSubheader>
              ) : null
            }
          >
            {privateChannelList &&
              privateChannelList.map((channel) => {
                const selectChat = () => {
                  getChannel(channel.url);
                  handleDrawerClose();
                };
                let users = getChannelUsers(channel, userId);
                if (users.length > 0) {
                  users = users.reverse();
                  const hasChildInfo = channel.data || users[0].metadata;
                  let childInfo = hasChildInfo
                    ? getChannelInfo(channel.data || users[0].metadata, channel.isPublic)
                    : "";
                  let parentName = isSupport
                    ? getChannelParentName(channel.data, channel.isPublic)
                    : null;
                  let userBCBAS = users.filter((user) => user.metaData.isBCBA);
                  let userBCBA = userBCBAS ? userBCBAS[0] : null;
                  if (primaryClinicianUserId && userBCBAS) {
                    userBCBA = userBCBAS.find((user) => user.userId == primaryClinicianUserId);
                  }
                  return (
                    <React.Fragment key={channel.url}>
                      {!isSupport && !isBcba && !isDirector && !isSupervisor && (
                        <ListSubheader className={classes.subheader}>
                          {userBCBA && userBCBA.metaData.isBCBA ? "My Clinician" : "Care Manager"}
                        </ListSubheader>
                      )}

                      <ListItem
                        button
                        disableGutters={true}
                        onClick={selectChat}
                        selected={currentChannel ? currentChannel.url === channel.url : false}
                        classes={{
                          container: classes.chatList,
                        }}
                      >
                        <ListItemText
                          primary={
                            <StyledBadge badgeContent={channel.unreadMessageCount} color="error">
                              {!isBcba && userBCBA && userBCBA.profileUrl ? (
                                <ListItemAvatar
                                  style={{
                                    minWidth: 0,
                                    marginRight: 10,
                                    marginLeft: 10,
                                  }}
                                >
                                  <Avatar alt={userBCBA.nickname} src={userBCBA.profileUrl} />
                                </ListItemAvatar>
                              ) : (
                                <ListItemAvatar
                                  style={{
                                    minWidth: 0,
                                    marginLeft: 10,
                                    marginRight: 10,
                                  }}
                                >
                                  <Avatar>
                                    <PersonRoundedIcon
                                      color="action"
                                      style={{
                                        fontSize: 50,
                                        marginTop: 8,
                                      }}
                                    />
                                  </Avatar>
                                </ListItemAvatar>
                              )}

                              <div
                                className={classes.privateChannelName}
                                title={
                                  isBcba || isSupervisor || isDirector
                                    ? channel.name
                                    : `Chat with ${users[0].nickname}`
                                }
                                alt={getChannelName(channel, userId)}
                              >
                                {isBcba ||
                                isSupervisor ||
                                isDirector ||
                                (!currentUser.metaData.isBCBA && !userBCBA) ||
                                isSupport
                                  ? isSupport
                                    ? `Support for ${parentName}`
                                    : channel.name
                                  : `Chat with ${userBCBA.nickname}`}{" "}
                                {isBcba ||
                                  (users[0].metaData.isBCBA && (
                                    <StatusIndicator
                                      onlineStatus={users[0].connectionStatus === "online"}
                                    />
                                  ))}
                                {hasChildInfo && (isBcba || isSupport) ? (
                                  <Typography className={classes.childInfo} component="div">
                                    {childInfo}
                                  </Typography>
                                ) : (
                                  <Typography className={classes.childInfo} component="div">
                                    {channel.lastMessage ? channel.lastMessage.message : " "}
                                  </Typography>
                                )}
                              </div>
                            </StyledBadge>
                          }
                          primaryTypographyProps={{
                            variant: "subtitle2",
                          }}
                        />
                        <ListItemSecondaryAction></ListItemSecondaryAction>
                      </ListItem>
                    </React.Fragment>
                  );
                }
              })}
          </List>
        )}
        {chatType !== "private" && (
          <React.Fragment>
            <List
              subheader={
                <ListSubheader className={classes.subheader}>My Communities</ListSubheader>
              }
            >
              {publicChannelsJoined &&
                publicChannelsJoined.map((channel) => {
                  const selectChat = () => {
                    getChannel(channel.url);
                    handleDrawerClose();
                  };
                  const users = getChannelUsers(channel, userId);
                  if (users.length > 0) {
                    return (
                      <ListItem
                        button
                        disableGutters={true}
                        key={channel.url}
                        onClick={selectChat}
                        selected={currentChannel ? currentChannel.url === channel.url : false}
                        classes={{
                          container: classes.chatList,
                        }}
                      >
                        <ListItemText
                          primary={
                            <StyledBadge badgeContent={channel.unreadMessageCount} color="error">
                              {channel && channel.coverUrl && (
                                <ListItemAvatar
                                  style={{
                                    minWidth: 0,
                                    marginLeft: 10,
                                    marginRight: 10,
                                  }}
                                >
                                  <Avatar alt={channel.name} src={channel.coverUrl} />
                                </ListItemAvatar>
                              )}
                              <div
                                className={classes.channelName}
                                title={getChannelName(channel, userId, channel.isPublic)}
                                alt={getChannelName(channel, userId)}
                              >
                                {getChannelName(channel, userId, channel.isPublic)}
                              </div>
                            </StyledBadge>
                          }
                          primaryTypographyProps={{
                            variant: "subtitle2",
                          }}
                        />
                        {!isBcba && (
                          <ListItemSecondaryAction>
                            <Button
                              size="small"
                              style={screenWidth < 600 ? { display: "inline-block" } : null}
                              variant="contained"
                              color="inherit"
                              onClick={attemptToLeave(channel)}
                              className={classes.leaveButton}
                            >
                              Leave
                            </Button>
                          </ListItemSecondaryAction>
                        )}
                      </ListItem>
                    );
                  }
                })}
            </List>
            {!isBcba && (
              <List>
                {publicChannelsUnjoined &&
                  publicChannelsUnjoined.map((channel) => {
                    const selectChat = () => {
                      getChannel(channel.url);
                    };
                    const isPremium = getChannelSecurity(channel);
                    const users = getChannelUsers(channel, userId);
                    if (users.length > 0) {
                      const hasChildInfo = channel.data || users[0].metadata;
                      let childInfo = hasChildInfo
                        ? getChannelInfo(channel.data || users[0].metadata, channel.isPublic)
                        : "";
                      return (
                        <ListItem
                          button
                          disableGutters={true}
                          key={channel.url}
                          onClick={attemptToJoin(channel, isPremium)}
                          selected={currentChannel ? currentChannel.url === channel.url : false}
                          classes={{
                            container: classes.chatListToJoin,
                          }}
                        >
                          <ListItemText
                            primary={
                              <StyledBadge badgeContent={channel.unreadMessageCount} color="error">
                                {channel && channel.coverUrl && (
                                  <ListItemAvatar
                                    style={{
                                      minWidth: 0,
                                      marginLeft: 10,
                                      marginRight: 10,
                                    }}
                                  >
                                    <Avatar alt={channel.name} src={channel.coverUrl} />
                                  </ListItemAvatar>
                                )}
                                <div
                                  className={classes.channelName}
                                  title={getChannelName(channel, userId, channel.isPublic)}
                                  alt={getChannelName(channel, userId)}
                                >
                                  {getChannelName(channel, userId, channel.isPublic)}
                                  {hasChildInfo && (
                                    <Typography className={classes.childInfo} component="div">
                                      {childInfo}
                                    </Typography>
                                  )}
                                </div>
                              </StyledBadge>
                            }
                            primaryTypographyProps={{
                              variant: "subtitle2",
                            }}
                          />
                          <ListItemSecondaryAction>
                            <Button
                              size="small"
                              variant="contained"
                              color="primary"
                              onClick={attemptToJoin(channel, isPremium)}
                              className={classes.joinButton}
                            >
                              Join
                              {isPremium && !canJoinPremiumCommunity && (
                                <LockIcon className={classes.lockIcon} />
                              )}
                            </Button>
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    }
                  })}
              </List>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );

  return (
    <nav className={classes.drawer}>
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={mobileOpen && userViewing}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden lgDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
          ModalProps={{ position: "relative" }}
        >
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
};

export default withStyles(styles, { withTheme: true })(ChatList);
